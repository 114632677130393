import ApiService from '../../../../../services/api.service';

class SPT15IndukService extends ApiService {
  name = 'SPT15Induk';
  path = 'pasal15/induk';
  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api={
    update: async (data) => {
      var res = await this.http.put(`${this.apiLocation}/${this.path}`, data, {
        params: {
          blockBunifikasiMonth: data.blockBunifikasiMonth,
          blockBunifikasiYear: data.blockBunifikasiYear
        }
      })
      return res;
    },
    getSigner: async (data)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/signer`, {
        params: data
      });
      return res.data;
    },
    getBySptId: async(sptId)=> {
      return await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/spt`)
    },
    saveAndCallculate: async(sptId, d)=> {
      return await this.http.post(`${this.apiLocation}/${this.path}/${sptId}/calculate`, d);
    },
    cetak: async (esptId) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${esptId}/spt/report`)
      return res;
    },
    cetakBulk: async (ids) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/report/bulk`, ids, {
        responseType: 'blob'
      })

      return res;
    },
    calculate: async(sptId, d)=> {
      return await this.http.get(`${this.apiLocation}/${this.path}/calculate/${sptId}`, d);
    },
  }
}


export default new SPT15IndukService();
