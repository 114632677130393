import ApiService from '../../../../services/api.service';

class LogSummaryService extends ApiService {
  name= 'LogSummary';
  path= 'log/summary';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    findByPasal: async (params)=> {
      var url = null;
      if(params.pasal === '21'){
        url = `${this.apiLocation}/log/summary`
      } else if(params.pasal === '22'){
        url = `${this.apiLocation}/pasal22/log/summary`
      } else if(params.pasal === '15'){
        url = `${this.apiLocation}/pasal15/log/summary`
      } else if(params.pasal === '4'){
        url = `${this.apiLocation}/pasal4/log/summary`
      } else if(params.pasal === '23'){
        url = `${this.apiLocation}/pasal23/log/summary`
      }
      var res = await this.http.get(`${url}`, {
        params: params
      })
      return res;
    },
    deletePerPasal: async (id, pasal)=> {
      var url = null;
      if(pasal === '21'){
        url = `${this.apiLocation}/log/summary/${id}`
      } else if(pasal === '22'){
        url = `${this.apiLocation}/pasal22/log/summary/${id}`
      } else if(pasal === '15'){
        url = `${this.apiLocation}/pasal15/log/summary/${id}`
      } else if(pasal === '4'){
        url = `${this.apiLocation}/pasal4/log/summary/${id}`
      } else if(pasal === '23'){
        url = `${this.apiLocation}/pasal23/log/summary/${id}`
      }
      var res = await this.http.delete(`${url}`)
      return res;
    },
    deleteAllPerPasal: async (ids, pasal)=> {
      var url = null;
      if(pasal === '21'){
        url = `${this.apiLocation}/log/summary/delete`
      } else if(pasal === '22'){
        url = `${this.apiLocation}/pasal22/log/summary/delete`
      } else if(pasal === '15'){
        url = `${this.apiLocation}/pasal15/log/summary/delete`
      } else if(pasal === '4'){
        url = `${this.apiLocation}/pasal4/log/summary/delete`
      } else if(pasal === '23'){
        url = `${this.apiLocation}/pasal23/log/summary/delete`
      }
      var res = await this.http.post(`${url}`, ids)
      return res;
    },
    downloadPerPasal: async (id, pasal)=> {
      var url = null;
      if(pasal === '21'){
        url = `${this.apiLocation}/log/summary/${id}/download`
      } else if(pasal === '22'){
        url = `${this.apiLocation}/pasal22/log/summary/${id}/download`
      } else if(pasal === '15'){
        url = `${this.apiLocation}/pasal15/log/summary/${id}/download`
      } else if(pasal === '4'){
        url = `${this.apiLocation}/pasal4/log/summary/${id}/download`
      } else if(pasal === '23'){
        url = `${this.apiLocation}/pasal23/log/summary/${id}/download`
      }
      var res = await this.http.get(`${url}`)
      return res;
    }
  }
}


export default new LogSummaryService();
