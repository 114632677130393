import React, { Component } from 'react';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import { validation, Textfield, TextfieldMask, Datepicker, convert } from '../../../../../components/form';
import Service from './ImportSigner21.service';
import ImportDialogTemplateView from './../Import/Import.dialog.template.view';

@reduxForm({form: 'ImportSigner21-form', destroyOnUnmount: false})
export default class ImportSigner21Dialog extends ImportDialogTemplateView {
  service=Service
}
