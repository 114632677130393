import ApiService from "../../../../../../services/api.service";

class Tarif15ervice extends ApiService {
  name = 'Master15Tarif';
  path = 'pasal15/rates';

  constructor() {
    super();
    this.init()
  }

  api = {
    byCompany: async (data) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/company`, {
        params: data
      });
      return res.data;
    },
    getMaster: async () => {
      var res = await this.http.get(`${this.apiLocation}/spt15/master/tarif/all`);
      return res.data;
    },
    getBySPT: async (esptId)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${esptId}/spt`);
      return res.data;
    }
  }
}

export default new Tarif15ervice();
