import React, { Component } from 'react'; import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {TabsContainer, Tabs, Tab, Chip} from 'react-md';
import { Field } from 'redux-form';
import moment from 'moment'

import Bupot26HistoryService from './Bupot26History.service';
import ListView from '../../../../../components/entity/ListViewEbupot';
import AuthService from '../../../../../services/auth.service'
import ListViewEbupot from '../../../../../components/entity/ListViewEbupot';

@connect((state)=> ({
  ...Bupot26HistoryService.stateConnectSetting()(state)
}), Bupot26HistoryService.actionConnectSetting())
export default class Bupot26HistoryView extends ListViewEbupot {
  service=Bupot26HistoryService;

  rootPath = this.props.match.url
  editDialog = false;
  addDialog = false;

  columns = [
    {label: "word.id", searchField: "id", value: "entity.id", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
    {label: "word.revisionNumber", searchField: "metadata.requiredRevisionNumber", value: "metadata.requiredRevisionNumber", type: "number", isDefaultSort:true, isSortable:true, show:true, isSearchable:true},
    {label: "word.noBp", searchField: "no", value: "entity.no", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.rev", searchField: "rev", value: "entity.rev", type: 'number', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {
      searchField: "status",
      label: "word.status",
      type: 'func',
      value: (d)=> {
        var style = {
          background: 'grey'
        }
        switch (d.entity.status) {
          case 'CREATED':
            style.background = 'grey'
            break;
          case 'PROCESS':
            style.background = 'orange'
            break;
          case 'FAILED':
            style.background = 'orangered'
            break;
          case 'FINISH':
            style.background = 'limegreen'
            break;
          case 'COMPLETED':
            style.background = 'dodgerblue'
            break;
          case 'DELETED':
            style.background = 'darkred'
            break;
          case 'UPLOADING':
            style.background = 'yellow'
            break;
          default:
            break;
        }
        return <Chip label={d.entity.status} style={style} />
      },
      className: "mpk-font-size-S", show:true, isSearchable:true},
    {label: "word.report", searchField: "report", value: "entity.report", type: 'boolean', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.npwpProfiles", searchField: "spt.organization.npwp", value: "entity.spt.npwp", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.masa", searchField: "spt.masa", value: "entity.spt.month", type: 'number', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.tahun", searchField: "spt.tahun", value: (d)=> { return d.entity.spt.year + "" }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.pembetulan", searchField: "spt.pembetulan", value: "entity.spt.rev", type: 'number', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.message", searchField: "message", value: "entity.message", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.tin", searchField: "tin", value: "entity.tin", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.nama", searchField: "nama", value: "entity.nama", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.email", searchField: "email", value: "entity.email", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.code", searchField: "code", type:'func', value: (val)=> { return <div style={{width: 100}}>{val.entity.kode}</div> }, isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.bruto", searchField: "bruto", type: "number", value: "entity.bruto", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.pph", searchField: "pph", type: "number", value: "entity.pph", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.nSkdDtp", searchField: "nSkbDtp", type: "func", value: (d)=> {
      var nomor = ""
      if(d.entity.fasilitas == 0){
        nomor = "N"
      } else if(d.entity.fasilitas == 1){
        nomor = "SKD"
      } else if (d.entity.fasilitas == 2){
        nomor = "DTP"
      }
      return nomor
    }, isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.refXmlId", searchField: "refXmlId", value: "entity.refXmlId", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.tgl", searchField: "tgl", type:'func', value: (val)=> { return <div style={{width: 100}}>{val.entity.tgl}</div> },  isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.createdBy", searchField: "createdBy", value: "entity.createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.createdDate", searchField: "createdDate", value: (d)=> {
      if(moment(d.entity.createdDate).isValid()){
        return moment(d.entity.createdDate).format('DD/MM/YYYY HH:MM')
      } else {
        return "-"
      }
    }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.lastModifiedBy", searchField: "lastModifiedBy", value: "entity.lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.lastModifiedDate", searchField: "lastModifiedDate", value: (d)=> {
      if(moment(d.entity.lastModifiedDate).isValid()){
        return moment(d.entity.lastModifiedDate).format('DD/MM/YYYY HH:MM')
      } else {
        return "-"
      }
    }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
  ]

  fetchOption() {
    const historyId = this.props.match.params.id
    return {
      path: `${Bupot26HistoryService.path}/${historyId}/history`
    }
  }

  editItem = (item) => {
    if(this.editDialog) {
      this.showFormDialog(item);
    } else {
      if(this.service.cloud){
        this.props.history.push(this.props.location.pathname+"/"+item.entity.spt.id+"/"+item.entity.id);
      } else if(this.rootPath) {
        this.props.history.push(this.rootPath+"/"+item.entity.spt.id+"/"+item.entity.id)
      } else {
        this.props.history.push('/'+this.service.name+"/"+item.entity.spt.id+"/"+item.entity.id);
      }
    }
  }

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    },
    // {label:"divider", iconClassName:"-"},
    // {
    //   label:"Delete",
    //   iconClassName:"mdi mdi-delete",
    //   onClick:(item, callback) => this.deleteItem(item, callback),
    //   confirmation:{
    //     title:"sentence.custom.deleteItem",
    //     message:"sentence.custom.deleteItemConfirmation"
    //   }
    // }
  ]

  backHistory(){

    this.props.history.goBack()
  }

  _barActions = [
    {
      label:'word.back',
      iconClassName:'mdi mdi-arrow-left',
      onClick:() => this.backHistory()
    },
    // {
    //   label:'word.delete',
    //   iconClassName:'mdi mdi-delete',
    //   onClick:() => {
    //     this.setState({showDialogConfirmDeleteSelected:true})
    //   },
    //   disabledFunc:() => this.props.table.selected.length === 0
    // },
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => this.fetchData()
    },
  ]

}
