import ApiService from '../../../../../services/api.service';

class LogCetakLaporanPOService extends ApiService {
  name= 'LogCetakLaporanPO';
  path= 'ebilling/report/billing';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    generate: async (data) => {
      var res = await this.http.post(`${this.apiLocation + "/" + this.path}`, data)

      return res.data;
    },
    download: async (id) => {
      let res = await this.http.get(`${this.apiLocation + "/" + this.path}/download/${id}`, {
        responseType: 'blob'
      })

      return res;
    },
  }
}

export default new LogCetakLaporanPOService();
