import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  Tab,
  Tabs,
  SelectionControlGroup,
  Checkbox
} from 'react-md';

import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Radio, convert} from '../../../../../components/form';
import UtilService from '../../../../../services/utils.service'
import FormView from '../../../../../components/entity/form.view';
import DialogView from '../../../../../components/entity/dialog.view';
import JenisDokumenService from './JenisDokumen.service'
import moment from 'moment'
import counterpart from 'counterpart'
import UserService from './../User/User.service'

@reduxForm({form: 'JenisDokumenForm', destroyOnUnmount: true})
@connect((state) => ({
  formData:state.form.JenisDokumenForm,
  user: state.auth.user
}))
export default class JenisDokumenFormView extends FormView {
  service=JenisDokumenService
  viewType=2;

  formView() {
    return (
        <Card>
            <div className='md-grid'>
                <Field
                    label='Id'
                    name='id'
                    className="md-cell md-cell--6"
                    component={TextfieldMask}
                    maskFormat="##"
                    mask="_"
                    validate={validation.required}
                />
                <Field
                    label='Kode'
                    name='kode'
                    className="md-cell md-cell--6"
                    component={TextfieldMask}
                    maskFormat="##"
                    mask="_"
                    validate={validation.required}
                />
                <Field
                    label='Name'
                    name='name'
                    className="md-cell md-cell--6"
                    component={Textfield}
                    validate={validation.required}
                />
            </div>
      </Card>
    )
  }
}
