import ApiService from '../../../../../services/api.service';

class ALLBP21SATUMASAService extends ApiService {
  name= 'ALL_BP_21_SATU_MASA';
  path= 'spt21/sm';
  constructor() {
    super();
    this.init()
  }

  api= {
    email: async (data, companyId) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/mail`, data, {
        params: {
          companyId: companyId
        }
      });
      return res;
    },
    report: async (data) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/report-list`, data);
      return res;
    },
    export: async (data) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/export`, data);
      return res;
    }
  }
}

export default new ALLBP21SATUMASAService();
