import React, { Component } from 'react';
import { Grid, Cell } from 'react-md';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import { validation, Textfield, TextfieldMask, Datepicker, convert } from '../../../../../components/form';
import DialogView from '../../../../../components/entity/dialog.view';
import Master21UpahHarianGlobalService from './Master21UpahHarianGlobal.service';

@reduxForm({form: 'master-21-upah-harianGlobal', destroyOnUnmount: false})
export default class Master21UpahHarianGlobalDialogView extends DialogView {
  service=Master21UpahHarianGlobalService
  initialData = {
  }

  formView() {
    return (
      <Grid style={{padding:'none'}}>
        <Field
          label='Start'
          name='startDate'
          className="md-cell md-cell--6"
          component={Datepicker}
          inline={true}
          validate={validation.required}
        />
        <Field
          label='End'
          name='endDate'
          className="md-cell md-cell--6"
          component={Datepicker}
          inline={true}
          validate={validation.required}
        />
        <Field
          label='Jumlah'
          name='total'
          className="md-cell md-cell--6"
          component={TextfieldMask}
          money={true}
          normalize={convert.money}
          validate={validation.required}
        />
      </Grid>
    )
  }
}
