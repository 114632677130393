import React from 'react'
import OrganizationService from './Organization.service'
import { connect } from 'react-redux'
import ListView from './../../../components/entity/listView'
import moment from 'moment'
import {validation, FileInputNext, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Radio, convert, FileInput5, Multiselect} from './../../../components/form'
import PropTypes from 'prop-types'
import { Field, reduxForm, initialize } from 'redux-form'
import izitoast from 'izitoast'
import counterpart from 'counterpart'

@connect((state)=> ({
    user: state.auth.user,
    ...OrganizationService.stateConnectSetting()(state)
  }), OrganizationService.actionConnectSetting())
export default class OrganizationView extends ListView {
    service=OrganizationService
    apiPath="getAll"

    static contextTypes = {
        showDialog: PropTypes.func.isRequired,
    };

    async beforeFetch(params){
        params.user = this.props.user
    }

    async componentDidMount(){
        let res = await OrganizationService.api.getAll({
            user: this.props.user
        });
    }

    searchForm() {
      return (
        <this.SearchContainer>
          <this.ig.Field
            label='Npwp'
            name='npwp'
            className="md-cell md-cell--12"
            component={this.ig.Textfield}
          />
          <this.ig.Field
            label='Nama'
            name='name'
            className="md-cell md-cell--12"
            component={this.ig.Textfield}
          />
          <this.ig.Field
            label='Email'
            name='email'
            className="md-cell md-cell--12"
            component={this.ig.Textfield}
          />
        </this.SearchContainer>
      )
    }

    defaultColumns = []

    async uploadCertificate(item){
        var res = await this.context.showDialog(()=> ({
          title: "Upload Certificate",
          initialValue: {
            npwp: item.npwp,
            password: null
          },
          okText: "Upload",
          width: 800,
          text: (
            <div>
              <Field
                label='Npwp'
                name='npwp'
                className="md-cell md-cell--12"
                component={this.ig.Textfield}
                disabled={true}
              />
              <Field
                label='Password'
                name='password'
                className="md-cell md-cell--12"
                component={this.ig.Textfield}
                validate={validation.required}
              />
              <br />
              <Field
                label='Certificate'
                name='file'
                className="md-cell md-cell--12"
                component={FileInputNext}
                validate={validation.required}
              />
            </div>
          )
        }))
        if(res){ 
          let formData = new FormData();
          formData.append("file", res.file)
          formData.append("npwp", res.npwp)
          formData.append("password", res.password)
          try {
            let upload = await this.service.api.certUpload(formData);
            izitoast.success({
              title: 'Import File', 
              message: counterpart('custom.doneUpload') 
            })
          } catch(e){
            var message = ''
            try {
              message = e.response.data
            } catch(e){}
            izitoast.error({
              title: 'Import File', 
              message: `${counterpart('custom.failUpload')}, dengan pesan ${message}` 
            })
          }
        }
    }

    async toSigner(item){
      this.props.history.push(`/main-support/signer/${item.npwp}`)
    }

    _tableActions = [
        // {
        //     label:"More Info",
        //     iconClassName:"mdi mdi-bell",
        //     onClick: (item) => this.editItem(item)
        // },
        {
            label:"Add Certificate",
            iconClassName:"mdi mdi-bell",
            onClick: (item) => this.uploadCertificate(item)
        },
        {
          label:"Add Signer",
          iconClassName:"mdi mdi-bell",
          onClick: (item) => this.toSigner(item)
      },
    ]

    _barActions=[
        {
          label:'word.refresh',
          iconClassName:'mdi mdi-refresh',
          onClick:() => {
            window.location.hash = window.location.hash.split('?')[0]
            this.fetchData()
          }
        },
    ]

    columns = [
        {label: "word.id", searchField: "id", value: "id", isDefaultSort:false, isSortable:true, show:false, isSearchable:false},
        {label: "word.name", searchField: "name", value: "name", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
        {label: "word.npwp", searchField: "npwp", value: "npwp", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
        {label: "word.email", searchField: "email", value: "email", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
        {label: "word.phone", searchField: "picPhone", value: "picPhone", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
        {label: "word.city", searchField: "city", value: "city", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
        {label: "word.postalCode", searchField: "postalCode", value: "postalCode", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
        {label: "word.address", searchField: "address", value: "address", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
        {label: "word.createdBy", searchField: "createdBy", value: "createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:false},
        {label: "word.createdDate", searchField: "createdDate", value: (d)=> {
          if(d){
            return moment(d.createdDate).format('DD/MM/YYYY HH:mm:ss')
          } else {
            return "-"
          }
        }, type: 'func', isDefaultSort:true, isSortable:true, show:true, isSearchable:true},
        {label: "word.lastModifiedBy", searchField: "lastModifiedBy", value: "lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:false},
        {label: "word.lastModifiedDate", searchField: "lastModifiedDate", value: (d)=> {
          if(d){
            if(d.lastModifiedDate){ return moment(d.lastModifiedDate).format('DD/MM/YYYY HH:mm:ss') } else { return "-" }
          } else {
            return "-"
          }
        }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:false},
      ]

}

// ddress: "Jl. Sukaesih 02"
// aliasEmail: "entah+1@mail.com"
// city: "Bandung"
// companyId: 7
// createdAt: "2019-03-21T18:05:07.709"
// createdBy: "rifani"
// email: "test01@mail.com"
// id: 1
// name: "Org Test 01"
// npwp: "317109876543001"
// phone: "0227212200"
// picEmail: "entah@mail.com"
// picName: "Entah Sifulan"
// picPhone: "0227212200"
// picPosition: "direktur"
// postalCode: "40235"
// updatedAt: "2019-03-21T18:05:07.709"
// updatedBy: "rifani"