import ApiService from '../../../../../../services/api.service';

class AllSSP25Service extends ApiService {
  name= 'AllSSP25';
  path= 'pasal25/ssp';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {}

}

export default new AllSSP25Service();
