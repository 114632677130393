import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Button, Chip, Switch} from 'react-md';

//import FormDialog from './Organization.dialog.view';
import ListView from '../../../../../../components/entity/listView';
import UtilService from '../../../../../../services/utils.service';
import download from 'downloadjs';
import uuid from 'uuid';
import iziToast from 'izitoast';
import ListViewSpt from '../../../../../../components/entity/listViewSpt';
import BP15PPHPasal24Service from './BP15PPhPasal24.service';
import moment from "moment";

@connect((state)=> ({
  ...BP15PPHPasal24Service.stateConnectSetting()(state),
  spt: state.authEppt.currentSPT
}), BP15PPHPasal24Service.actionConnectSetting())
export default class BP15PPHPasal24View extends ListViewSpt {
  service=BP15PPHPasal24Service
  FormDialog=()=> <div/>

  constructor(props){
    super(props);
    this.state = {
      ...this.state,
      total: {},
      cetak: false,
      cetakDaftar: false
    }
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Negara'
          name='negara__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
      </this.SearchContainer>
    )
  }

  addDialog=false
  editDialog=false

  fetchOption() {
    return {
      path: BP15PPHPasal24Service.path+'/'+this.props.spt.data.id+'/spt'
    }
  }

  columns=[
    /*{isSortable: true, label: "word.reference",  value: "referensi", show:true, isSearchable:true},*/
    {isSortable: true, label: "word.negaraSumberPenghasilan",  value: "negara", show:true, isSearchable:true},
    {isSortable: true, label: "word.bruto",  value: "gross", show:true, isSearchable:true, type: 'number'},
    {isSortable: true, label: "word.pphDibayarLuarNegeri",  value: "pphLuarNegeri", show:true, isSearchable:true, type: 'number'},
    {isSortable: true, label: "word.pphDiperhitungkan",  value: "pphDiperhitungkan", show:true, isSearchable:true, type: 'number'},
    {isSortable: true, label: "word.keterangan",  value: "desc", show:true, isSearchable:true},
    {isSortable:true, label: "word.createdUsername",  value: "createdUsername", show:true, isSearchable:true},
    {isSortable:true, label: "word.createdBy",  value: "createdBy", show:true, isSearchable:true},
    {isSortable:true, label: "word.createdDate", value: "createdDate", show:true, isSearchable:true, type:"date", isDefaultSort: true},
    {isSortable:true, label: "word.modifiedUsername",  value: "modifiedUsername", show:true, isSearchable:true},
    {isSortable:true, label: "word.lastModifiedBy",  value: "lastModifiedBy", show:true, isSearchable:true},
    {isSortable:true, label: "word.lastModifiedDate", value: (d)=> {
      if(d.lastModifiedDate) {
        return <div className='mpk-font-size-S'>{moment(d.lastModifiedDate).format('lll')}</div>
      } else {
        return null;
      }
    }, show:true, isSearchable:true, type:"func", searchField: 'lastModifiedDate', isDefaultSort: true}
  ]
pphLuarNegeri
  async componentDidMount() {
    this.rootPath = this.props.match.url+'/BP15PPHPasal24';

    var sptId = this.props.spt.data.id
    var total = await BP15PPHPasal24Service.api.total(sptId)
    this.setState({
      total: total
    })
  }

  totalBruto() {
    var total = 0;
    if(this.state.total.gross){
      total = this.state.total.gross;
    }
    return total;
  }

  totalPph() {
    var total = 0;
    if(this.state.total.pph){
      total = this.state.total.pph;
    }
    return total;
  }

  async handleCetak(){
    this.setState({ cetak: true })
    if(this.props.table.selected.length === 0){
      iziToast.warning({
        title: 'Pemberitahuan !',
        message: 'Pilih salah satu data yang akan dicetak',
      });
      this.setState({ cetak: false })
    } else {
      try {
        var selected = this.props.table.selected
        var ids = [];
        selected.map(function (i) {
          ids.push(i.id)
        })
        var sptId = this.props.spt.data.id
        try {
          var report10 = await Promise.all(ids.map((id)=> {
            return new Promise(async (resolve, reject)=> {
              try {
                var report = await this.service.api.cetakBulk(sptId, id)
                resolve(report)
              } catch(e){
                resolve({
                  data: false
                })
              }
            })
          }))
          report10.map((d)=> {
            if(d.data && d.data.url){
              window.open(d.data.url)
            }
          })
        } catch(e){
          this.setState({cetak: false})
        }
        this.setState({cetak: false})
      } catch (e) {
        this.setState({cetak: false})
      }
    }
  }

  async handleCetakDaftar(){
    this.setState({ cetakDaftar: true })
    try {
      var sptId = this.props.spt.data.id
      var report = await BP15PihakLainDipotongService.api.cetakDaftar(sptId)
      window.open(report.data.url)
      // var filename = report.headers.filename
      // download(report.data, filename);
      this.setState({ cetakDaftar: false })
    } catch (e){
      this.setState({ cetakDaftar: false })
    }
  }

  async deleteSelectedDataItems(){
    var selected = this.props.table.selected
    var ids = [];
    selected.map(function (i){
      ids.push(i.id)
    })
    var res = await this.service.api.deleteBulk(ids)
    this.fetchData()
  }

  _barItem() {
    return (
      <div>
        <Button disabled={this.state.cetakDaftar} primary raised style={{margin: 5}} onClick={this.handleCetakDaftar.bind(this)}>Cetak Daftar</Button>
        <Switch
          style={{float: "right"}}
          id="switch-table-setting"
          name="Switch Table Setting"
          label=""
          checked={this.state.showTableSetting}
          onChange={() => {
            this.setState({
              showTableSetting:!this.state.showTableSetting
            })
          }}
        />
      </div>
    )
  }

  footer() {
    return <div className="flex-none mpk-border thin solid top dark">
      <div className="mpk-padding-N left right">
        <table style={{width: '100%'}}>
          <tfoot className="md-table-footer">
            <tr className="md-table-column md-text-left md-table-column--data md-table-column--adjusted md-text ">
              <td>Total Bruto</td>
              <td className=" md-text-right">{UtilService.number.format(this.totalBruto(), {precision: 0, thousand: '.', decimal: ','})}</td>
            </tr>
            <tr className="md-table-column md-text-left md-table-column--data md-table-column--adjusted md-text ">
              <td>Total PPH</td>
              <td className=" md-text-right">{UtilService.number.format(this.totalPph(), {precision: 0, thousand: '.', decimal: ','})}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  }

}
