import React, { Component } from 'react';
import { Grid, Cell } from 'react-md';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import { validation, Textfield, TextfieldMask, convert } from '../../../../components/form';
import DialogView from '../../../../components/entity/dialog.view';
import MasterBiayaJabatanService from './MasterBiayaJabatan.service';

@reduxForm({form: 'master-biaya-jabatan-dialog', destroyOnUnmount: false})
export default class MasterBiayaJabatanDialogView extends DialogView {
  service=MasterBiayaJabatanService
  initialData = {
  }

  formView() {
    return (
      <Grid style={{padding:'none'}}>
        <Field
          label='Code'
          name='code'
          id="master-biaya-jabatan-code"
          className="md-cell md-cell--12"
          component={Textfield}
          validate={validation.required}
        />

        <Field
          label='Maximal Number'
          name='maximalNumber'
          id="master-biaya-jabatan-maximal-number"
          className="md-cell md-cell--6"
          component={TextfieldMask}
          money={true}
          normalize={convert.money}
          validate={validation.required}
        />

        <Field
          label='Max Percentage (%)'
          name='maximumPercentage'
          id="master-biaya-jabatan-maximum-percentage"
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
          type="number"
          max="100"
        />
      </Grid>
    )
  }
}
