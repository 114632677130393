import ApiService from '../../../../services/api.service';

class LogExportPdfService extends ApiService {
  name= 'LogExportPdf';
  path= 'log/export-pdf';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    findByPasal: async (params)=> {
      var url = null;
      if(params.pasal == '21'){
        url = `${this.apiLocation}/log/export-pdf`
      } else if(params.pasal == '22'){
        url = `${this.apiLocation}/pasal-22/log/export-pdf/`
      } else if(params.pasal == '15'){
        url = `${this.apiLocation}/pasal-15/log/export-pdf/`
      } else if(params.pasal == '4'){
        url = `${this.apiLocation}/pasal-4/log/export-pdf/`
      } else if(params.pasal == '23'){
        url = `${this.apiLocation}/pasal-23/log/export-pdf/`
      }
      var res = await this.http.get(`${url}`, {
        params: params
      })
      return res;
    },
    deletePerPasal: async (id, pasal)=> {
      var url = null;
      if(pasal == '21'){
        url = `${this.apiLocation}/log/export-pdf/${id}`
      } else if(pasal == '22'){
        url = `${this.apiLocation}/pasal-22/log/export-pdf/${id}`
      } else if(pasal == '15'){
        url = `${this.apiLocation}/pasal-15/log/export-pdf/${id}`
      } else if(pasal == '4'){
        url = `${this.apiLocation}/pasal-4/log/export-pdf/${id}`
      } else if(pasal == '23'){
        url = `${this.apiLocation}/pasal-23/log/export-pdf/${id}`
      }
      var res = await this.http.delete(`${url}`)
      return res;
    },
    deleteAllPerPasal: async (ids, pasal)=> {
      var url = null;
      if(pasal == '21'){
        url = `${this.apiLocation}/log/export-pdf/delete`
      } else if(pasal == '22'){
        url = `${this.apiLocation}/pasal-22/log/export-pdf/delete`
      } else if(pasal == '15'){
        url = `${this.apiLocation}/pasal-15/log/export-pdf/delete`
      } else if(pasal == '4'){
        url = `${this.apiLocation}/pasal-4/log/export-pdf/delete`
      } else if(pasal == '23'){
        url = `${this.apiLocation}/pasal-23/log/export-pdf/delete`
      }
      var res = await this.http.post(`${url}`, ids)
      return res;
    },
    downloadPerPasal: async (id, pasal)=> {
      var url = null;
      if(pasal == '21'){
        url = `${this.apiLocation}/log/export-pdf/${id}/download`
      } else if(pasal == '22'){
        url = `${this.apiLocation}/pasal-22/log/export-pdf/${id}/download`
      } else if(pasal == '15'){
        url = `${this.apiLocation}/pasal-15/log/export-pdf/${id}/download`
      } else if(pasal == '4'){
        url = `${this.apiLocation}/pasal-4/log/export-pdf/${id}/download`
      } else if(pasal == '23'){
        url = `${this.apiLocation}/pasal-23/log/export-pdf/${id}/download`
      }
      var res = await this.http.get(`${url}`)
      return res;
    }
  }
}


export default new LogExportPdfService();
