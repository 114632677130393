import React from 'react'

export default class SignerImage extends React.Component {
    render(){
        return(
            <div>
                <h1>123123123</h1>
            </div>
        )
    }
}