import ApiService from '../../../../services/api.service';

class FileHeaderService extends ApiService {
  name= 'FileHeader';
  path = 'ebilling/fileHeaders';

  api = {
    createFileHeaderByCorporate: async (data) => {
      var res = await this.http.post(`${this.apiLocation + "/" + this.path}/byCorporate`, data)

      return res.data;
    },
    createFileHeaderByCorporateAll: async (data) => {
      var res = await this.http.post(`${this.apiLocation + "/" + this.path}/byCorporate/all`, data)

      return res.data;
    },
    checkFileHeaderByCorporate: async (data) => {
      var res = await this.http.post(`${this.apiLocation + "/" + this.path}/byCorporate/check`, data)

      return res.data;
    },
    checkTotalJumlahSetorByChecklist: async (data) => {
      var res = await this.http.post(`${this.apiLocation + "/" + this.path}/byCorporate/check/total/checked`, data)

      return res.data;
    },
    checkFileHeaderTotalJumlahSetor: async (data) => {
      var res = await this.http.post(`${this.apiLocation + "/" + this.path}/byCorporate/check/total`, data)

      return res.data;
    },
    findOneById: async (id) => {
      var res = await this.http.get(`${this.apiLocation + "/" + this.path}/show/` + id)

      return res.data;
    },
    findByInvoice: async (id) => {
      var res = await this.http.get(`${this.apiLocation + "/" + this.path}/invoice/` + id)

      return res.data;
    },
  }

  constructor() {
    super()
    this.init()
    this.initApi()
  }
}

export default new FileHeaderService();
