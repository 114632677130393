import ApiService from '../../../../../services/api.service';
import http from "../../../../../services/http.service";

class SSP15SetorSendiriService extends ApiService {
  name= 'SSP15SetorSendiri';
  path= 'pasal15/ssp-dibayar-sendiri';

  api= {	
    calculate: async (param) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/calculation/pph15/f`, param)

      return res.data;
    },
    total: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/total`)

      return res.data;

      return {};
    },
    cetak: async (id) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/report/${id}`, {
        responseType: 'blob'
      })

      return res;
    },
    cetakDaftar: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/spt/report-list`)
      
      return res;
    },
    downloadCsv: async (sptId)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/csv/${sptId}`, {
        responseType: 'blob'
      })

      return res;
    },
    email: async (category, ids) => {
      return await this.http.post(`${this.apiLocation}/${this.path}/email?category=${category}`, ids)
    },
    deleteBulk: async (sptId, ids)=> {
      let res = await http.post(`${this.apiLocation}/${this.path}/delete`, ids);

      return res;
    },
  }

  constructor() {
    super()
    this.init()
    this.initApi()
  }
}


export default new SSP15SetorSendiriService();
