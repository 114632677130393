import React from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import counterpart from 'counterpart';
import { Field, reduxForm, getFormValues} from 'redux-form';
import moment from 'moment';
import download from 'downloadjs';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress,
  List,
  ListItem,
  FontIcon,
  DialogContainer
} from 'react-md';
import FormView from '../../../components/entity/form.view';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox} from '../../../components/form';
import UtilService from '../../../services/utils.service'
import DashboardService from './Dashboard.service';
import OrganizationService from '../Administrator/Organization/Organization.service';
import { OrganizationView } from '../Administrator/Organization/Organization.view';
import iziToast from 'izitoast';

@connect(OrganizationService.stateConnectSetting(), OrganizationService.actionConnectSetting())
class OrganizationPicker extends OrganizationView {
  viewType=2;

  _barActions = [
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    },
  ]

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    }
  ]

  _viewContainer(props) {
    return <div>
      {props.children}
    </div>
  }
}

@reduxForm({form: 'dashboard', destroyOnUnmount: true, initialValues: {organizations: []}})
@connect((state) => ({
  ...OrganizationService.stateConnectSetting()(state),
  access      : state.entity.access.api.find,
  organization: state.authEppt.currentOrganization,
  spt         : state.authEppt.currentSPT,
  formData    : getFormValues('dashboard')(state)
}))
export default class DashboardForm extends FormView {
  service  = DashboardService
  viewType = 2;

  translate = false

  titleHeader() {
    return '';
  }

  initialData={
  }

  constructor(props) {
    super(props);

    this.state= {
      ...this.state,
      tahunOptions: [],
      result: {
        pasal21s: [],
        pasal23s: [],
        pasal26s: [],
        pasal4a2s: [],
        pasal15s: [],
        pasal22s: [],
      },
      showOrganizationPicker: false,
    }
  }

  async handleCalculate() {
    let formData = this.props.formData

    let fromYear = formData.fromYear
    let toYear = formData.toYear
    let fromMonth = formData.fromMonth
    let toMonth = formData.toMonth

    let organizationIds = [];

    formData.organizations.forEach(x => {
      organizationIds.push(x.id);
    })

    let statuses = []

    if(formData.process) {
      statuses.push('PROCESS')
    }

    if(formData.waiting) {
      statuses.push('WAITING')
    }

    if(formData.finish) {
      statuses.push('FINISH')
    }

    let data = {
      organizationIds,
      fromYear,
      toYear,
      fromMonth,
      toMonth,
      statuses
    }

    try {
      var response = await this.service.api.calculate(data)

      this.setState({result: response.data});
    } catch (e) {
      /*let message = 'Gagal hitung dashboard.'

      iziToast.warning({
        title: 'Gagal',
        message: message
      })*/
    }
  }

  async initData() {
    this.props.initialize({organizations:[]});
  }

  tahunOptions = [];

  masaOptions = [
    {id:1, name: 'Januari'}, {id:2, name: 'Februari'}, {id:3, name: 'Maret'}, {id:4, name: 'April'}, {id:5, name: 'Mei'}, {id:6, name: 'Juni'},
    {id:7, name: 'Juli'}, {id:8, name: 'Agustus'}, {id:9, name: 'September'}, {id:10, name: 'Oktober'}, {id:11, name: 'November'}, {id:12, name: 'Desember'}
  ]

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;

    let selectedOrganizations = this.props.formData.organizations

    return (
      <div>
        {/* <Button secondary raised primary onClick={() => this.handleCalculate()} style={{marginRight: 16}} disabled={selectedOrganizations.length === 0}>Hitung</Button> */}
      </div>
    )
  }

  async handleMultiSelectOrganization() {
    var formData = this.props.formData;
    var organizations = formData.organizations;
    this.props.table.selected.forEach((d, i)=> {
      var check = _.find(organizations, {id: d.id});
      if(!check) {
        organizations.push(d);
      }
    })

    this.props.change('organizations', organizations);
  }

  async handleDelete(i) {
    var formData = this.props.formData;
    var organizations = formData.organizations;
    organizations.splice(i, 1);
    this.props.change('organizations', organizations);
  }

  formView() {
    var tahun = new Date().getFullYear();
    var tahunOptions = [];

    for (let index = 0; index < 11; index++) {
      tahunOptions.push({
        id: tahun - index,
        name: tahun - index
      })
    }

    var formData                      = {};
    if (this.props.formData) formData = this.props.formData.values

    var result = {}
    if (this.state.result) result = this.state.result

    return (
      <div>
        {/* <Card>
          <div className='md-grid'>
            <Field
              className="md-cell md-cell--6"
              label     = 'Dari Masa'
              name      = 'fromMonth'
              component = {Searchfield}
              options   = {this.masaOptions}
            />
            <Field
              className="md-cell md-cell--6"
              label     = 'Sampai Masa'
              name      = 'toMonth'
              component = {Searchfield}
              options   = {this.masaOptions}
            />

            <Field
              className="md-cell md-cell--6"
              label     = 'Dari Tahun'
              name      = 'fromYear'
              component = {Searchfield}
              options   = {tahunOptions}
            />

            <Field
              className="md-cell md-cell--6"
              label     = 'Sampai Tahun'
              name      = 'toYear'
              component = {Searchfield}
              options   = {tahunOptions}
            />

          </div>

          <b style={{margin: '20px 15px'}}>STATUS</b>
          <Divider  />
          <div className="md-grid">
            <Field
              className="md-cell md-cell--6"
              label     = 'PROCESS'
              name      = 'process'
              component = {Checkbox}
            />
            <Field
              className="md-cell md-cell--6"
              label     = 'WAITING'
              name      = 'waiting'
              component = {Checkbox}
            />
            <Field
              className="md-cell md-cell--6"
              label     = 'FINISH'
              name      = 'finish'
              component = {Checkbox}
            />
          </div>

          <div className="md-grid">
            <Button raised primary onClick={()=> {
              this.setState({showOrganizationPicker: true})
            }} >Multi Select Organization</Button>

            <DialogContainer
              id="simple-action-dialog"
              width={800}
              // height={586}
              contentStyle={{padding:0}}
              visible={this.state.showOrganizationPicker}
              onHide={()=> {
                this.setState({showOrganizationPicker: false})
              }}
              actions={[
                <Button flat onClick={async ()=> {
                  this.setState({showOrganizationPicker: false})
                }}>Close</Button>,
                <Button raised primary onClick={async ()=> {
                  await this.handleMultiSelectOrganization();
                  this.setState({showOrganizationPicker: false})
                }}>Confirm</Button>

              ]}
            >
              <OrganizationPicker />
            </DialogContainer>
            <List className="md-cell--12 md-paper md-paper--1">
              <Subheader primaryText={<div>Selected </div>} ></Subheader>
              {this.props.formData.organizations.map((d, i)=> {
                return <ListItem
                  key={i}
                  rightIcon={<FontIcon onClick={()=> this.handleDelete(i)}>delete</FontIcon>}
                  primaryText={d.name}
                  secondaryText={d.npwp}
                />
              })}
            </List>

            {result.pasal21s.length > 0 &&
            <div className="md-cell--12 md-paper md-paper--1">
              <br/>

              <b style={{margin: '20px 15px'}}>PASAL 21</b>

              <Divider  />

              <DataTable plain>
                <TableHeader>
                  <TableRow>
                    <TableColumn>NPWP</TableColumn>
                    <TableColumn>Nama</TableColumn>
                    <TableColumn>Nama Alias</TableColumn>
                    <TableColumn>Bruto</TableColumn>
                    <TableColumn>PPh</TableColumn>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {result.pasal21s.map((pasal21, i) => (
                    <TableRow key={i}>
                      <TableColumn>{pasal21.npwp}</TableColumn>
                      <TableColumn>{pasal21.name}</TableColumn>
                      <TableColumn>{pasal21.aliasName}</TableColumn>
                      <TableColumn>{UtilService.number.format(pasal21.bruto, {precision: 0, thousand: '.', decimal: ','})}</TableColumn>
                      <TableColumn>{UtilService.number.format(pasal21.pph, {precision: 0, thousand: '.', decimal: ','})}</TableColumn>
                    </TableRow>
                  ))}
                </TableBody>
              </DataTable>
            </div>
            }

            {result.pasal23s.length > 0 &&
            <div className="md-cell--12 md-paper md-paper--1">
              <br/>

              <b style={{margin: '20px 15px'}}>PASAL 23</b>

              <Divider  />

              <DataTable plain>
                <TableHeader>
                  <TableRow>
                    <TableColumn>NPWP</TableColumn>
                    <TableColumn>Nama</TableColumn>
                    <TableColumn>Nama Alias</TableColumn>
                    <TableColumn>Bruto</TableColumn>
                    <TableColumn>PPh</TableColumn>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {result.pasal23s.map((pasal23, i) => (
                    <TableRow key={i}>
                      <TableColumn>{pasal23.npwp}</TableColumn>
                      <TableColumn>{pasal23.name}</TableColumn>
                      <TableColumn>{pasal23.aliasName}</TableColumn>
                      <TableColumn>{UtilService.number.format(pasal23.bruto, {precision: 0, thousand: '.', decimal: ','})}</TableColumn>
                      <TableColumn>{UtilService.number.format(pasal23.pph, {precision: 0, thousand: '.', decimal: ','})}</TableColumn>
                    </TableRow>
                  ))}
                </TableBody>
              </DataTable>
            </div>
            }

            {result.pasal26s.length > 0 &&
            <div className="md-cell--12 md-paper md-paper--1">
              <br/>

              <b style={{margin: '20px 15px'}}>PASAL 26</b>

              <Divider  />

              <DataTable plain>
                <TableHeader>
                  <TableRow>
                    <TableColumn>NPWP</TableColumn>
                    <TableColumn>Nama</TableColumn>
                    <TableColumn>Nama Alias</TableColumn>
                    <TableColumn>Bruto</TableColumn>
                    <TableColumn>PPh</TableColumn>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {result.pasal26s.map((pasal26, i) => (
                    <TableRow key={i}>
                      <TableColumn>{pasal26.npwp}</TableColumn>
                      <TableColumn>{pasal26.name}</TableColumn>
                      <TableColumn>{pasal26.aliasName}</TableColumn>
                      <TableColumn>{UtilService.number.format(pasal26.bruto, {precision: 0, thousand: '.', decimal: ','})}</TableColumn>
                      <TableColumn>{UtilService.number.format(pasal26.pph, {precision: 0, thousand: '.', decimal: ','})}</TableColumn>
                    </TableRow>
                  ))}
                </TableBody>
              </DataTable>
            </div>
            }

            {result.pasal4a2s.length > 0 &&
            <div className="md-cell--12 md-paper md-paper--1">
              <br/>

              <b style={{margin: '20px 15px'}}>PASAL 4 (2)</b>

              <Divider  />

              <DataTable plain>
                <TableHeader>
                  <TableRow>
                    <TableColumn>NPWP</TableColumn>
                    <TableColumn>Nama</TableColumn>
                    <TableColumn>Nama Alias</TableColumn>
                    <TableColumn>Bruto</TableColumn>
                    <TableColumn>PPh</TableColumn>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {result.pasal4a2s.map((pasal4a2, i) => (
                    <TableRow key={i}>
                      <TableColumn>{pasal4a2.npwp}</TableColumn>
                      <TableColumn>{pasal4a2.name}</TableColumn>
                      <TableColumn>{pasal4a2.aliasName}</TableColumn>
                      <TableColumn>{UtilService.number.format(pasal4a2.bruto, {precision: 0, thousand: '.', decimal: ','})}</TableColumn>
                      <TableColumn>{UtilService.number.format(pasal4a2.pph, {precision: 0, thousand: '.', decimal: ','})}</TableColumn>
                    </TableRow>
                  ))}
                </TableBody>
              </DataTable>
            </div>
            }

            {result.pasal22s.length > 0 &&
            <div className="md-cell--12 md-paper md-paper--1">
              <br/>

              <b style={{margin: '20px 15px'}}>PASAL 22</b>

              <Divider  />

              <DataTable plain>
                <TableHeader>
                  <TableRow>
                    <TableColumn>NPWP</TableColumn>
                    <TableColumn>Nama</TableColumn>
                    <TableColumn>Nama Alias</TableColumn>
                    <TableColumn>Bruto</TableColumn>
                    <TableColumn>PPh</TableColumn>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {result.pasal22s.map((pasal22, i) => (
                    <TableRow key={i}>
                      <TableColumn>{pasal22.npwp}</TableColumn>
                      <TableColumn>{pasal22.name}</TableColumn>
                      <TableColumn>{pasal22.aliasName}</TableColumn>
                      <TableColumn>{UtilService.number.format(pasal22.bruto, {precision: 0, thousand: '.', decimal: ','})}</TableColumn>
                      <TableColumn>{UtilService.number.format(pasal22.pph, {precision: 0, thousand: '.', decimal: ','})}</TableColumn>
                    </TableRow>
                  ))}
                </TableBody>
              </DataTable>
            </div>
            }

            {result.pasal15s.length > 0 &&
            <div className="md-cell--12 md-paper md-paper--1">
              <br/>

              <b style={{margin: '20px 15px'}}>PASAL 15</b>

              <Divider  />

              <DataTable plain>
                <TableHeader>
                  <TableRow>
                    <TableColumn>NPWP</TableColumn>
                    <TableColumn>Nama</TableColumn>
                    <TableColumn>Nama Alias</TableColumn>
                    <TableColumn>Bruto</TableColumn>
                    <TableColumn>PPh</TableColumn>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {result.pasal15s.map((pasal15, i) => (
                    <TableRow key={i}>
                      <TableColumn>{pasal15.npwp}</TableColumn>
                      <TableColumn>{pasal15.name}</TableColumn>
                      <TableColumn>{pasal15.aliasName}</TableColumn>
                      <TableColumn>{UtilService.number.format(pasal15.bruto, {precision: 0, thousand: '.', decimal: ','})}</TableColumn>
                      <TableColumn>{UtilService.number.format(pasal15.pph, {precision: 0, thousand: '.', decimal: ','})}</TableColumn>
                    </TableRow>
                  ))}
                </TableBody>
              </DataTable>
            </div>
            }
          </div>
        </Card> */}
      </div> 
    )
  }

}
