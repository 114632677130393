import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  Tab,
  Tabs,
  SelectionControlGroup,
  Checkbox,
  Button
} from 'react-md';

import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Radio, convert} from '../../../../../components/form';
import UtilService from '../../../../../services/utils.service'
import DialogView from '../../../../../components/entity/dialog.view';
import ApplicationOrganizationService from './ApplicationOrganization.service'
import moment from 'moment'
import counterpart from 'counterpart'
import uuid from 'uuid';
import OrganizationService from '../Organization/Organization.service';

@reduxForm({form: 'ApplicationOrganizationForm', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  formData:getFormValues('ApplicationOrganizationForm')(state)
}))
export default class ApplicationOrganizationDialog extends DialogView {
  service=ApplicationOrganizationService
  initialData={}

  transform(value) {
    value.application = {
      id: this.props.appId
    }
    return value;
  }

  formView() {
    return (
      <div className='md-grid'>
        <Field
          label='Organization'
          name='organization'
          className="md-cell md-cell--12"
          viewField="nama"
          component={Searchfield}
          itemTemplate={ function(d) {
            return {
              primaryText: d.nama,
              secondaryText: d.npwp,
              onClick: () => {
                if(!this.props.disabled){
                  this.handleItemClick(d); this.hide()
                }
              }
            }
          }}
          valueField='parent'
          service={OrganizationService}
          validate={validation.required}
        />
      </div>
    )
  }
}
