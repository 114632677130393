import React, { Component } from 'react';
import { Grid, Cell } from 'react-md';
import { connect } from 'react-redux';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import { validation, Textfield, TextfieldMask, Searchfield, Switch, Multiselect, convert } from '../../../../components/form';
import DialogView from '../../../../components/entity/dialog.view';
import UserService from './User.service';
import CompanyService from '../Company/Company.service';

@connect(state => ({
  isLoginIn: state.authEppt.isLoginIn,
  user: state.authEppt.user,
  menuType: state.authEppt.menuType,
  company: state.authEppt.currentCompany
}))
@reduxForm({form: 'user', destroyOnUnmount: false})
export default class UserDialogView extends DialogView {
  service=UserService
  initialData = {
  }

  initData(props) {

    if(props.formData.id) {
      this.props.initialize(props.formData);
    } else {
      if(this.props.location.pathname && this.props.location.pathname.search('open/user') >= 0) {
        this.initialData.authorities = [{name: 'ROLE_EPPT_USER', id: "ROLE_EPPT_USER"}];
      }
      if(this.props.location.pathname && this.props.location.pathname.search('open/owner') >= 0) {
        this.initialData.authorities= [{name: 'ROLE_EPPT_OWNER', id: "ROLE_EPPT_OWNER"}];
      }
      this.initialData.company = this.props.company;
      this.props.initialize(this.initialData);
    }
  }

  transform(value) {
    if(!Array.isArray(value.authorities)){
      value.authorities = [value.authorities]
    }
    return value;
  }

  formView() {

    var authoritieOptions= [
      {name: 'ROLE_EPPT_ADMIN', id: "ROLE_EPPT_ADMIN"},
      {name: 'ROLE_EPPT_USER', id: "ROLE_EPPT_USER"},
      {name: 'ROLE_EPPT_OWNER', id: "ROLE_EPPT_OWNER"}
    ]

    var hideAuth = false;
    if(this.props.location.pathname && this.props.location.pathname.search('open/user') >= 0) {
      authoritieOptions= [{name: 'ROLE_EPPT_USER', id: "ROLE_EPPT_USER"}]
      hideAuth = true;
    }

    if(this.props.location.pathname && this.props.location.pathname.search('open/owner') >= 0) {
      authoritieOptions= [ {name: 'ROLE_EPPT_OWNER', id: "ROLE_EPPT_OWNER"}]
      hideAuth = true;
    }

    return (
      <Grid style={{padding:'none'}}>
        {!this.props.company.id &&
          <Field
            label='Company'
            name='company'
            className="md-cell md-cell--12"
            apiPath="page"
            viewField="nama"
            valueField="parent"
            disabled={this.props.company.id}
            component={Searchfield}
            service={CompanyService}
            validate={validation.required}
          />
        }


        <Field
          label='Username'
          name='login'
          id="user-login"
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
        />
        {!this.props.formData.id &&
          <Field
            label='Password'
            type='password'
            name='password'
            id="user-password"
            className="md-cell md-cell--6"
            component={Textfield}
          />
        }

        <Field
          label='Email'
          name='email'
          id="user-email"
          className="md-cell md-cell--12"
          component={Textfield}
          validate={validation.required}
        />
        <Field
          label='First Name'
          name='firstName'
          id="user-firstName"
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
        />
        <Field
          label='Last Name'
          name='lastName'
          id="user-lastName"
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
        />
        <Field
          label='Language'
          name='langKey'
          id="user-langKey"
          className="md-cell md-cell--6"
          component={Searchfield}
          options={[
            {name: 'English', id: "en"},
            {name: 'Indonesia', id: "id"},
          ]}
          validate={validation.required}
        />
        <Field
          label='Active'
          name='activated'
          id="user-activated"
          className="md-cell md-cell--6"
          component={Switch}
          validate={validation.required}
        />
        {!hideAuth &&
          <Field
          label='Authorities'
          name='authorities'
          id="user-authorities"
          className="md-cell md-cell--12"
          valueField="parent"
          component={Multiselect}
          options={authoritieOptions}
          validate={validation.required}
        />
        }

      </Grid>
    )
  }
}
