import React, { Component } from 'react';
import { connect } from 'react-redux';

import authService from '../../../../../services/auth.service';
import * as constant from '../../../../../config/constant';
import ListViewSptRoot from '../../../../../components/entity/ListViewSptRoot';
import SPT25Service from './SPT25.service';

@connect((state)=> ({
  ...SPT25Service.stateConnectSetting()(state),
  user: state.authEppt.user,
  orgAccess: state.authEppt.orgAccess,
  company: state.authEppt.currentCompany
}), SPT25Service.actionConnectSetting())
export default class SPT25View extends ListViewSptRoot {
  service=SPT25Service
  FormDialog=()=> <div/>

  addDialog=false
  editDialog=false

  permissionName="SPT_25"
  urlPath="SPT_25"
  formTypeName="PPH 25"
  shortPasalName="25"

}
