import ApiService from '../../../../../services/api.service';
import http from "../../../../../services/http.service";

class BP21SSPService extends ApiService {
  name= 'BP_21_SSP';
  path= 'spt/ssp';
  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    cetak: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/spt/ssp/${sptId}/report-list`)

      return res;
    },
    downloadCsv: async (sptId)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/spt/export`)

      return res;
    },
    cetakBulk: async (ids) => {
      var res = await this.http.post(`${this.apiLocation}/spt21/ssp/report/bulk`, ids, {
        responseType: 'blob'
      })

      return res;
    },
    cetakBulkMerge: async (ids) => {
      var res = await this.http.post(`${this.apiLocation}/spt21/ssp/report/bulk/merge`, ids, {
        responseType: 'blob'
      })

      return res;
    },
    deleteBulk: async (sptId, ids)=> {
      let res = await http.post(`${this.apiLocation}/${this.path}/${sptId}/delete`, ids);

      return res;
    },
  }
}


export default new BP21SSPService();
