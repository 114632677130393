import ApiService from '../../../../../services/api.service'
import http from '../../../../../services/http.service'
import moment from 'moment'

class OrganizationService extends ApiService {
  name= 'Organization'
  path= 'ebupot/organization'
  advancedSearch=true

  constructor(){
    super()
    this.init()
    this.initApi()
  }

  columns = [
    {label: "word.id", searchField: "id", value: "id", isDefaultSort:false, isSortable:true, show:false, isSearchable:false},
    {label: "word.name", searchField: "name", value: "name", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    // {isSortable:true, label: "word.active", value: "active", type: 'boolean', onClick:() => {}, show:true, isSearchable:true},
    {label: "word.npwp", searchField: "npwp", value: "npwp", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.email", searchField: "email", value: "email", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.phone", searchField: "phone", value: "phone", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.city", searchField: "city", value: "city", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.postalCode", searchField: "postalCode", value: "postalCode", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.address", searchField: "address", value: "address", isDefaultSort:false, isSortable:true, show:true, isSearchable:false},
    {label: "word.createdBy", searchField: "createdBy", value: "createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:false},
    {label: "word.createdDate", searchField: "createdDate", value: (d)=> {
      if(d){
        return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
      } else {
        return "-"
      }
    }, type: 'func', isDefaultSort:true, isSortable:true, show:true, isSearchable:true},
    {label: "word.lastModifiedBy", searchField: "lastModifiedBy", value: "lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:false},
    {label: "word.lastModifiedDate", searchField: "lastModifiedDate", value: (d)=> {
      if(d.lastModifiedDate){
        return moment(d.lastModifiedDate).format('DD/MM/YYYY HH:MM')
      } else {
        return "-"
      }
    }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:false},
  ]

  api = {
    getOrgByNpwp: async (npwp)=> {
      let res = await http.get(`/api/${this.path}?npwp.equals=${npwp}`)
      return res
    },
    getAll: async (params)=> {
      var user = params.user
      delete params.user
      let res = await http.get('/api/iams/organization/all', {
          headers: {
            'x-user-profile': JSON.stringify({
                username: user.username,
                id: user.id,
                email: user.email,
                role: user.role.name,
                isAdmin: user.isAdmin + ""
            }),
            params: JSON.stringify(params)
          }
      });
      return res
    },
    getOrganizationHost: async (params)=> {
      let res = await this.http.get('/organizations', {
        params: {
          page: 1,
          size: 10000,
          field: params.field,
          query: params.query,
          order: "DESC",
          column: "npwp"
        }
      })
      return res
    }
  }

}

export default new OrganizationService();
