import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  Tab,
  Tabs,
  SelectionControlGroup,
  Checkbox,
  Button
} from 'react-md';

import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Radio, convert} from '../../../../../components/form';
import UtilService from '../../../../../services/utils.service'
import DialogView from '../../../../../components/entity/dialog.view';
import KodeBuktiPotong26Service from './KodeBuktiPotong26.service'
import moment from 'moment'
import counterpart from 'counterpart'
import uuid from 'uuid';

@reduxForm({form: 'KodeBuktiPotong26Form', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  formData:getFormValues('KodeBuktiPotong26Form')(state)
}))
export default class KodeBuktiPotong26Dialog extends DialogView {
  service=KodeBuktiPotong26Service
  initialData={}


  formView() {
    return (
      <div className='md-grid'>

        <Field
          label='Nama'
          name='name'
          className="md-cell md-cell--12"
          component={Textfield}
          validate={validation.required}
        />
       
      </div>
    )
  }
}
