/**
 * Created by dwiargo on 2/6/18.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import navService from '../../services/navService';
import { Paper, LinearProgress, Button } from 'react-md';
import counterpart from 'counterpart';
import * as toastActions from '../../redux/actions/toastActions';

import './ContainerForm.scss';
import { bindActionCreators } from 'redux';

const Form = ({fitContent, footer, children, actions, onSubmitAction, isEdit, disabledForm, onSubmit, onProgress, showSubmit=true, submitLabel }) => {
  let formContent = (
    <div className={`${fitContent ? 'mpk-layout column mpk-full height' : ''}`}>
      <div className="flex mpk-padding-N padding-all">{children}</div>
      {footer ? (
        <footer className="md-dialog-footer mpk-margin-N margin-top mpk-layout flex-none mpk-padding-NS padding-all">
          {footer}
        </footer>
      ) : (
        <footer 
          className="md-dialog-footer mpk-margin-N margin-top mpk-layout flex-none mpk-padding-NS padding-all"
          style={{
            display: showSubmit ? 'flex' : 'none'
          }}
        >
          <div className="flex mpk-align-right">
            {actions.map((d, i) => (
              <Button
                key={i}
                raised className="mpk-margin-S right"
                onClick={() => onSubmitAction(d)}
                >
                {d.label}
              </Button>
            ))}
          </div>
          <div className="flex-none mpk-layout">
            {isEdit ? (null) : (
              <Button className="mpk-margin-S right" flat onClick={() => navService.back()}>
                {counterpart.translate('word.cancel')}
              </Button>
            )}
            {disabledForm ? (
                <Button
                  flat secondary
                  onClick={onSubmit}
                  disabled={onProgress}
                  style={{display: showSubmit ? 'inherit' : 'none'}}
                >
                {submitLabel ? submitLabel : (isEdit ? counterpart.translate('word.save') : counterpart.translate('word.submit'))}
              </Button>
            ) : (
                <Button
                  flat primary
                  disabled={onProgress}
                  type="submit"
                  style={{display: showSubmit ? 'inherit' : 'none'}}
                >
                {submitLabel ? submitLabel : (isEdit ? counterpart.translate('word.save') : counterpart.translate('word.submit'))}
              </Button>
            )}
          </div>
        </footer>
      )}
    </div>
  )
  
  return disabledForm ? (
    <div className={`${fitContent ? 'mpk-full height' : ''}`}>
      {formContent}
    </div>
  ) : (
    <form onSubmit={onSubmit} className={`${fitContent ? 'mpk-full height' : ''}`}>
      {formContent}
    </form>
  )
}

const MainContent = props => (
  <div className={`${props.fitContent ? 'mpk-full height' : ''}`}>
    {props.onProgress || props.isLoading ? (
      <LinearProgress id="container-paper-progress"/>
    ) : (null)}
    <div className="mpk-full height">
      {props.headerText || props.deck ? (
        <div className="header mpk-layout column">
          <h2 className="decorative-text">{ props.headerText }</h2>
          <p>{props.deck}</p>
        </div>
      ) : (null)}
      {/* {props.isError ? (
        <StatusInfo
          status="error"
          message={props.message}
          global={props.global}
          />
      ) : (null)} */}
      {/* {props.isSuccess ? (
        <StatusInfo
          status="success"
          message={props.message}
          global={props.global}
          />
      ) : (null)} */}
      <div className={`children ${props.fitContent ? 'mpk-full height' : ''}`}>
        <Form
          children={props.children}
          isEdit={props.isEdit}
          isSuccess={props.isSuccess}
          onSubmit={props.onSubmit}
          submitLabel={props.submitLabel}
          actions={props.actions}
          onSubmitAction={props.onSubmitAction}
          fitContent={props.fitContent}
          disabledForm={props.disabledForm}
          onProgress={props.onProgress}
          footer={props.footer}
          showSubmit={props.showSubmit}
          />
      </div>
      {/* {props.footer ? (
        <div className="footer">
          {props.footer}
        </div>
      ):(null)} */}
    </div>
  </div>
)

const defaultState = () => ({
  onProgress:false,
  isError:false,
  message:{
    id:"",
    en:""
  },
  isSuccess:false
})

class ContainerForm extends Component{
  constructor(){
    super();
    this.state = defaultState();
  }

  onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      onProgress:true,
      isError:false,
      isSuccess:false
    });
    this.props.onSubmit(this.finishedCallback)
  };

  onSubmitAction = (action) => {
    if(action.disabledProgress){
      action.onClick();
    } else {
      this.setState({
        onProgress:true,
        isError:false,
        isSuccess:false
      })
      action.onClick(this.finishedCallback)
    }
  }

  finishedCallback = (isError, message) => {
    let {global} = this.props;
    if(message !== undefined){
      let msg = typeof message === 'string' ? message : (global ? message[global.localeCode] : 'message is an object but global props is not found');
      if(isError){
        // this.props.toastActions.izi(counterpart.translate('word.failed'), msg, 'warning')
      } else {
        // this.props.toastActions.izi(counterpart.translate('word.success'), msg, 'success')
      }
    }

    this.setState({
      onProgress: false,
      isError:isError,
      message:message
    });
  }


  render(){
    const { children, headerText, deck, size, footer, flexHeight, global, isEdit, isLoading, submitLabel, actions, className, style, fitContent, disabledForm, showSubmit, header} = this.props;
    const { isError, message, isSuccess, onProgress } = this.state

    return(
      <div
        className={`mpk-container-form ${flexHeight ? "flex-height " : " "} ${fitContent ? "mpk-full height" : " "} ${size?size:''}`}
        >
        <div className={`container ${fitContent ? "mpk-full height" : " "}`}>
          <div className={`cb ${fitContent ? "mpk-full height" : " "}`}>
            <Paper
              className={`mpk-full height ${className ? className : ''}`}
              style={style ? style : {}}
              >
              {header}
              <MainContent
                children={children}
                footer={footer}
                deck={deck}
                headerText={headerText}
                global={global}
                isError={isError}
                message={message}
                onProgress={onProgress}
                isSuccess={isSuccess}
                isLoading={isLoading}
                isEdit={isEdit}
                onSubmit={this.onSubmit}
                submitLabel={submitLabel}
                actions={actions || []}
                onSubmitAction={this.onSubmitAction}
                fitContent={fitContent}
                disabledForm={disabledForm}
                showSubmit={showSubmit}
              />
            </Paper>
          </div>
        </div>
      </div>
    )
  }
}

ContainerForm.defaultProps = {
  isEdit: true
}

const mapStateToProps = state => ({
  global:state.global
})

const mapDispatchToProps = dispatch => ({
  toastActions:bindActionCreators(toastActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ContainerForm);
