import ApiService from '../../../../../services/api.service';
import http from "../../../../../services/http.service";

class BP22Service extends ApiService {
  name= 'BP22';
  path= 'pasal22/bp';

  api= {
    save: async (data) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}`, data, {
        params: {
          blockBunifikasiMonth: data.blockBunifikasiMonth,
          blockBunifikasiYear: data.blockBunifikasiYear
        }
      })
      return res;
    },
    update: async (data) => {
      var res = await this.http.put(`${this.apiLocation}/${this.path}`, data, {
        params: {
          blockBunifikasiMonth: data.blockBunifikasiMonth,
          blockBunifikasiYear: data.blockBunifikasiYear
        }
      })
      return res;
    },
    calculate: async (param) => {
      var res = await this.http.post(this.apiLocation+'/pasal22/calculation/pph22/bp-22', param)

      return res.data;
    },
    downloadCsv: async (sptId, category)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/spt/export`, {
        params: {
          type: category
        }
      })

      return res;
    },
    total: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/total`)

      return res.data;

    },
    cetak: async (ids) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${ids[0]}/report`)

      return res;
    },
    cetakBulk: async (sptId, ids) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/report`, ids)

      return res;
    },
    cetakBulkPisah: async (sptId, ids) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/report/pisah`, ids)
      return res;
    },
    cetakBulkMerge: async (ids) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/report/bulk/merge`, ids, {
        responseType: 'blob'
      })

      return res;
    },
    cetakDaftar: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/spt/report-list`)

      return res;
    },
    email: async (category, ids) => {
      return await this.http.post(`${this.apiLocation}/pasal22/email?category=${category}`, ids)
    },
    emailSpt: async (category, sptId, ids, companyId) => {
      if(ids.length == 1){
        return await this.http.get(`${this.apiLocation}/pasal22/bp/${ids[0]}/mail`, {
          params: {
            companyId: companyId
          }
        })
      } else {
        return await this.http.post(`${this.apiLocation}/pasal22/bp/mail`, ids, {
          params: {
            companyId: companyId
          }
        })
      }
    },
    deleteBulk: async (sptId, ids)=> {
      let res = await http.post(`${this.apiLocation}/${this.path}/delete`, ids);

      return res;
    },
  }

  // apiMockup= {
  //   find: [{
  //     id: 1,
  //     status: 'Process',
  //     npwp: '023945840000',
  //     name: 'PT. A',
  //     pasal: '23/26',
  //     year: '2018',
  //     organization: {name: 'PT. A', npwp: '123123'},
  //     month: 'Januari',
  //     pembetulan: '0'
  //   }],
  //   findOne: {data: {
  //     id: 1,
  //     status: 'Process',
  //     npwp: '023945840000',
  //     name: 'PT. A',
  //     pasal: '23/26',
  //     year: '2018',
  //     organization: {name: 'PT. A', npwp: '123123'},
  //     month: 'Januari',
  //     pembetulan: '0'
  //   }}
  // }

  constructor() {
    super()
    this.init()
    this.initApi()
  }
}


export default new BP22Service();
