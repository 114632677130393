import ApiService from '../../../../../../../services/api.service'
import http from '../../../../../../../services/http.service'

class EbupotKjsService extends ApiService {
  name= 'EbupotKjs';
  path= 'ebupot/kjs';
  advancedSearch=true
  
  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    lookUp: async (kapId)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${kapId}/kap`)
      return res.data
    }
  }
}


export default new EbupotKjsService();
