import React from 'react';
import { Route, Switch } from 'react-router-dom';

export default (props) => (
  <Switch>
    <Route path={props.match.path+'/SSP_15_Sendiri/:id'} component={require('./SSPSetorSendiri/SSP15SetorSendiri.form').default} />
    <Route path={props.match.path+'/SSP_15_Sendiri'} component={require('./SSPSetorSendiri/SSP15SetorSendiri.view').default} />

    <Route path={props.match.path+'/BP_15/BP15DipotongPihakLain/:id'} component={require('./BP15/DipotongPihakLain/BP15DipotongPihakLain.form').default} />
    <Route path={props.match.path+'/BP_15/BP15PihakLainDipotong/:id'} component={require('./BP15/PihakLainDipotong/BP15PihakLainDipotong.form').default} />
    <Route path={props.match.path+'/BP_15/BP15PPHPasal24/:id'} component={require('./BP15/PPHPasal24/BP15PPhPasal24.form').default} />
    <Route path={props.match.path+'/BP_15'} component={require('./BP15/BP15.main').default} />

    <Route path={props.match.path+'/PBK15/:id'} component={require('./PBK/PBK15.form').default} />
    <Route path={props.match.path+'/PBK15'} component={require('./PBK/PBK15.view').default} />

    <Route path={props.match.path+'/SSP15/:id'} component={require('./SSP/SSP15.form').default} />
    <Route path={props.match.path+'/SSP15'} component={require('./SSP/SSP15.view').default} />

    <Route path={props.match.path+'/EXPORT_CSV_15'} component={require('./Export/ExportCsv15/ExportCsv15.view').default} />

    <Route path={props.match.path+'/FILE_LAPOR_15'} component={require('./FileLapor/FileLapor15.view').default} />

    <Route path={props.match.path+'/SPT15INDUK'} component={require('./Induk/Spt15Induk.form').default} />

    <Route path={props.match.path+'/FILE_LAPOR_15'} component={require('./FileLapor/FileLapor15.view').default} />

    <Route path={props.match.path+"/IMPORT/BP_15"} component={require('./Import/ImportBp15/ImportBp15.view').default} />
    <Route path={props.match.path+"/IMPORT/BP_24"} component={require('./Import/ImportBp24/ImportBp24.view').default} />
    <Route path={props.match.path+"/IMPORT/SSP_15_SETOR_SENDIRI"} component={require('./Import/ImportSSPSetorSendiri/ImportSSP15SetorSendiri.view').default} />
    <Route path={props.match.path+"/IMPORT/SSP_15"} component={require('./Import/ImportSSP/ImportSSP15.view').default} />
    <Route path={props.match.path+"/IMPORT/PBK_15"} component={require('./Import/ImportPBK/ImportPBK15.view').default} />
    <Route path={props.match.path+"/IMPORT/LK_15"} component={require('./Import/ImportLK/ImportLK15.view').default} />

    {/* SETTING 15 */}
    <Route path={props.match.path+"/SETTING_NO_BUPOT_15"} component={require('./SettingNoBupot15/SettingNoBupot15.form').default} />
    <Route path={props.match.path+"/SETTING_TARIF_15"} component={require('./company/tarif/Tarif15.form').default} />
    {/* END SETTING 15 */}

    <Route path={props.match.path+'/'} component={function() {return <div/>}} />
  </Switch>
)
