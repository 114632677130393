import React, { Component } from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm , propTypes, formnameSelector } from 'redux-form';

import UserAccessService from './UserAccess.service';
import ListView from '../../../../../components/entity/listView';
import ColumnService from '../../../../../services/column.service';
import { Chip, Card, Button } from 'react-md'
import {validation, Textfield, Searchfield, Checkbox, Searchfieldv2} from '../../../../../components/form';
import iziToast from 'izitoast';
import companyService from '../../../../../services/companyService'

@reduxForm({form: 'SettingAccess', destroyOnUnmount: false})
@connect((state)=> ({
  ...UserAccessService.stateConnectSetting()(state),
  company: state.auth.company,
  formData:state.form.SettingAccess
}), UserAccessService.actionConnectSetting())
export default class UserAccessView extends ListView {
  service=UserAccessService

  async initData(companyId){
    if(companyId){
      try {
        let res = await this.service.api.getSettingAccess(companyId)
        this.props.initialize(res.data);
      } catch(e){
        console.log(e, "TELL ME ABOUT IT")
      }
    }
  }

  async updateSetting(values){
    try {
      try {
        values.companyId = values.perusahaan.id
        values.company = values.perusahaan
      } catch(e){}
      let res = await this.service.api.putSettingAccess(values) 
      iziToast.success({
        title: "Setting Access",
        message: "Setting berhasil disimpan"
      })
    } catch(e){
      iziToast.error({
        title: "Setting Access",
        message: "Setting gagal disimpan " + e.message
      })
    }
  }

  additionalTopContent(){
    return (
      <div className="mpk-padding-N all">
        <Card style={{padding: "24px"}}>
          <div class="md-grid">
            <Field
                label         = 'Perushaan'
                name          = 'perusahaan'
                className     = "md-cell md-cell--12"
                component     = {Searchfieldv2}
                valueField    = 'parent'
                remoteSearch  = {true}
                apiPath       = 'get'
                params        = {{ user: this.props.user }}
                service       = {companyService}
                renderFirst   = {true}
                coreSearch    = {true}
                npwpSearch    = {true}
                onChange      = { async (values)=> {
                  console.log(values.id, "TELL ME ABOUT IT")
                  await this.initData(values.id)
                }}
                itemTemplate  = {function(d) {
                  let nameAndAlias = ""
                  if (d.name)
                    nameAndAlias = d.name
                  if (d.aliasName)
                    nameAndAlias += " - " + d.aliasName
                  return {
                    primaryText  : nameAndAlias,
                    secondaryText: d.npwp,
                    onClick      : () => {
                      if(!this.props.disabled){
                        this.handleItemClick(d); this.hide()
                      }
                    }
                  }
                }}
            />
            <Field
              label='Enable Role Access'
              name='active'
              component={Checkbox}
              className = "md-cell md-cell--2"
            />
            <div className="md-cell md-cell--10">
              <Button raised primary onClick={this.props.handleSubmit(this.updateSetting.bind(this))}>Update Setting</Button>
            </div>
          </div>
        </Card>
      </div>
    )
  }

  barActions(){
    var _barActions = [
      {
        label:'word.create',
        iconClassName:'mdi mdi-plus',
        onClick:() => this.addItem()
      },
      {
        label:'word.delete',
        iconClassName:'mdi mdi-delete',
        onClick:() => {
          this.setState({showDialogConfirmDeleteSelected:true})
        },
        disabledFunc:() => this.props.table.selected.length === 0
      },
      {
        label:'word.refresh',
        iconClassName:'mdi mdi-refresh',
        onClick:() => {
  
          window.location.hash = window.location.hash.split('?')[0]
          this.fetchData()
        }
      },
    ]
    if(this.props.formData && this.props.formData.values && this.props.formData.values.active){
     
    } else {
      _barActions.splice(0, 1)
      return _barActions
    }
    return _barActions
  }

  rootPath = this.props.match.url
  editDialog = false;
  addDialog = false;

  columns=[
    {isSortable: true, label: "word.id", searchField: "id", value: function(d){ return d.id }, type: "func",  show:false, isSearchable:false},
    {isSortable: true, label: "word.npwp", searchField: "npwp", value: "npwp", show:true, isSearchable:true},
    {isSortable: true, label: "word.username", searchField: "username", value: "username",  show:true, isSearchable:true},
    {isSortable: true, label: "word.accesses", searchField: "accesses", value: function(d){
      var accesses = []
      if(d && d.accesses && d.accesses.length > 0){
        accesses = d.accesses
      }
      return (
        <div>
          {accesses.length > 0 &&
            accesses.map((c, i)=> {
            return <Chip style={{margin: 5}} key={i} label={c.name}></Chip>
            })
          }
        </div>
      )
    }, type: 'func',  show:true, isSearchable:false},
  ]   
}
