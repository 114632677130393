import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, propTypes, formnameSelector } from 'redux-form';
import {
  Avatar, Button,
  Divider,
  FontIcon,
  List,
  ListItem,
  Subheader,
} from 'react-md';

import FormView from '../../../../../components/entity/form.view';
import BlokBunifikasiService from './BlokBunifikasi.service';
import { validation, Textfield, Searchfield, Checkbox, Fileinput, Searchfieldv2, Switch } from '../../../../../components/form';
import izitoast from 'izitoast';
import OrganizationService from './../../../Administrator/Organization/Organization.service'
import companyService from '../../../../../services/companyService'
import counterpart from 'counterpart';

@reduxForm({ form: 'BlokBunifikasiForm', destroyOnUnmount: false })
@connect((state) => ({
  access: state.entity.access.api.find,
  organization: state.authEppt.currentOrganization,
  formData: state.form['BlokBunifikasiForm'],
  // EPPT CORE
  auth: state.auth.company,
  user: state.authEppt.user,
  userCore: state.auth.user,
  // EPPT CORE
}))
export default class BlokBunifikasiForm extends FormView {
  service = BlokBunifikasiService

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      show: false
    }
  }

  async beforeSave(val) {
    try {
      console.log(val, "TELL ME WHY")
      if(parseInt(val.bulan) > 4 && parseInt(val.tahun) >= 2022){
        izitoast.info({
          message: "Pengaturan Blok Bunifikasi Maksimal Bulan 4 tahun 2022"
        })
        return false
      } else {
        val.tahun = 2022
        return val
      }
    } catch(e){
      izitoast.error({
        title: "Error",
        message: e.message
      })
      return false
    }
  }

  redirectTo=false

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;
    window.hahaha = counterpart
    var saveWord = counterpart.translate('word.save');
    if(this.props.match.params.id == 'new') saveWord = counterpart.translate('word.create')
    if(this.props.match.params.sptId == 'new') saveWord = counterpart.translate('word.create')

    return (
      <div>
        {/* <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>{counterpart.translate('word.back')}</Button> */}

        <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>{saveWord}</Button>
      </div>
    )
  }

  componentDidMount() {
    // createdBy	"ipulrvs"
    // createdDate	"2022-02-15 11:03:03"
    // lastModifiedBy	"ipulrvs"
    // lastModifiedDate	"2022-02-15 11:03:03"
    // id	"23e2bce6-c48b-416e-a084-47783a55a7a0"
    // companyId	56
    // name	"BLOCK_BUNIFIKASI"
    // description	null
    // active	false
    // tahun	2022
    // bulan	4
  }

  async initData(id, values) {
    let res = await BlokBunifikasiService.api.getSettingAccess(id)
    res.data.perusahaan = values
    this.props.initialize(res.data);
    this.setState({ show: true })
  }

  formView() {
    var formData = {}
    if (this.props.formData) formData = this.props.formData.values;
    var org = []
    if (this.props.auth && this.props.auth.organizations) org = this.props.auth.organizations
    return (
      <div className="md-grid">
        <Field
          label='Perusahaan'
          name='perusahaan'
          className="md-cell md-cell--12"
          component={Searchfieldv2}
          valueField='parent'
          remoteSearch={true}
          apiPath='get'
          params={{ user: this.props.user }}
          service={companyService}
          renderFirst={true}
          coreSearch={true}
          npwpSearch={true}
          onChange={async (values) => {
            await this.initData(values.id, values)
          }}
          itemTemplate={function (d) {
            let nameAndAlias = ""
            if (d.name)
              nameAndAlias = d.name
            if (d.aliasName)
              nameAndAlias += " - " + d.aliasName
            return {
              primaryText: nameAndAlias,
              secondaryText: d.npwp,
              onClick: () => {
                if (!this.props.disabled) {
                  this.handleItemClick(d); this.hide()
                }
              }
            }
          }}
        />
        {/* <Field
          className="md-cell md-cell--12"
          label='Tahun'
          name='tahun'
          component={Textfield}
          validate={validation.required}
        /> */}
        <Field
          className="md-cell md-cell--12"
          label='Bulan'
          name='bulan'
          component={Searchfield}
          options={[
            {id: 1, name: 'Januari'},
            {id: 2, name: 'Februari'},
            {id: 3, name: 'Maret'},
            {id: 4, name: 'April'},
            // {id: 5, name: 'Mei'},
            // {id: 6, name: 'Juni'},
            // {id: 7, name: 'Juli'},
            // {id: 8, name: 'Agustus'},
            // {id: 9, name: 'September'},
            // {id: 10, name: 'Oktober'},
            // {id: 11, name: 'November'},
            // {id: 12, name: 'Desember'}
          ]}
          validate={validation.required}
        />
        <Field
          className="md-cell md-cell--12"
          label='Aktif'
          name='active'
          component={Switch}
        />
      </div>
    )
  }

}
