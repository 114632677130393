

export const ROLE_EPPT_ADMIN = 'ROLE_EPPT_ADMIN';
export const ROLE_EPPT_USER = 'ROLE_EPPT_USER';
export const ROLE_EPPT_OWNER = 'ROLE_EPPT_OWNER';

export const LEVEL = {
  ROLE_EPPT_ADMIN: 1,
  ROLE_EPPT_OWNER: 5,
  ROLE_EPPT_USER: 10
}

