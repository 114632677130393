import ApiService from '../../../../../services/api.service';
import http from "../../../../../services/http.service";

class PBK22Service extends ApiService {
  name= 'PBK22';
  path= 'pasal22/pbk';

  // apiMockup = {
  //   find: [{
  //     id: 1,
  //     status: 'Process',
  //     npwp: '023945840000',
  //     name: 'PT. A',
  //     pasal: '23/26',
  //     year: '2018',
  //     organization: {name: 'PT. A', npwp: '123123'},
  //     month: 'Januari',
  //     pembetulan: '0'
  //   }],
  //   findOne: {data: {
  //     id: 1,
  //     status: 'Process',
  //     npwp: '023945840000',
  //     name: 'PT. A',
  //     pasal: '23/26',
  //     year: '2018',
  //     organization: {name: 'PT. A', npwp: '123123'},
  //     month: 'Januari',
  //     pembetulan: '0'
  //   }}
  // }

  api = {
    save: async(data)=> {
      var res = await this.http.post(`${this.apiLocation}/${this.path}`, data, {
        params: {
          blockBunifikasiMonth: data.blockBunifikasiMonth,
          blockBunifikasiYear: data.blockBunifikasiYear
        }
      })
      return res;
    },
    update: async (data) => {
      var res = await this.http.put(`${this.apiLocation}/${this.path}`, data, {
        params: {
          blockBunifikasiMonth: data.blockBunifikasiMonth,
          blockBunifikasiYear: data.blockBunifikasiYear
        }
      })
      return res;
    },
    total: async (sptId)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/total`)

      return res;
    },
    downloadCsv: async (sptId)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/spt/export`)

      return res;
    },
    deleteBulk: async (sptId, ids)=> {
      let res = await http.post(`${this.apiLocation}/${this.path}/${sptId}/delete`, ids);

      return res;
    },
    deleteItem: async (sptId, ids)=> {
      let res = await http.delete(`${this.apiLocation}/${this.path}/${ids[0]}`);

      return res;
    }
  }

  constructor() {
    super()
    this.init()
    this.initApi()
  }
}


export default new PBK22Service();
