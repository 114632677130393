import React, { Component } from 'react';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import { validation, Textfield, TextfieldMask, Datepicker, convert } from '../../../components/form';
import Service from './ImportAll4a2.service';
import ImportDialogTemplateView from '../../../components/entity/import.dialog.template.view2';

@reduxForm({form: 'ImportAll4-form', destroyOnUnmount: false})
export default class ImportAll4DialogView extends ImportDialogTemplateView {
  service=Service
  pasal='pasal4'
}