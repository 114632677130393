import React from 'react';
import { FontIcon } from 'react-md';
import './PictureProfile.scss';

const PictureProfile = ({src}) => (
  <div className="mpk-picture-profile">
    { src ? (
      <img src={src}/>
    ) : null}
    <div className="default-img mpk-layout align-center justify-center">
      <FontIcon iconClassName="mdi mdi-account"/>
    </div>
  </div>
)

export default PictureProfile;