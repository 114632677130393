import ApiService from '../../../../services/api.service';

class PaymentMethodService extends ApiService {
  name= 'PaymentMethod';
  path = 'ebilling/paymentMethods';

  constructor() {
    super()
    this.init()
    this.initApi()
  }
}

export default new PaymentMethodService();
