import ApiService from '../../../../../services/api.service';
import http from "../../../../../services/http.service";

class PBK23Service extends ApiService {
  name= 'PBK23';
  path= 'pasal23/pbk';

  api= {
    save: async(data)=> {
      var res = await this.http.post(`${this.apiLocation}/${this.path}`, data, {
        params: {
          blockBunifikasiMonth: data.blockBunifikasiMonth,
          blockBunifikasiYear: data.blockBunifikasiYear
        }
      })
      return res;
    },
    update: async(data)=> {
      var res = await this.http.put(`${this.apiLocation}/${this.path}`, data, {
        params: {
          blockBunifikasiMonth: data.blockBunifikasiMonth,
          blockBunifikasiYear: data.blockBunifikasiYear
        }
      })
      return res;
    },
    total: async (sptId)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/total`)

      return res;
    },
    downloadCsv: async (sptId)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/spt/export`)
      return res;
    },
    deleteBulk: async (sptId, ids)=> {
      let res = await http.post(`${this.apiLocation}/${this.path}/${sptId}/delete`, ids);

      return res;
    },
  }
  constructor() {
    super()
    this.init()
    this.initApi()
  }
}


export default new PBK23Service();
