import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Field, reduxForm, getFormValues } from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../../../../components/form';

import ImportLK23Service from './ImportLK23.service';
import ListView from '../../../../../../components/entity/listView';
import ColumnService from '../../../../../../services/column.service';
// import ImportService from './../Import/Import.service';
import axios from 'axios';
import izitoast from 'izitoast';
import FormDialog from './ImportLK23.dialog';
import download from "downloadjs";
import ListViewImport from '../../../../../../components/entity/ListViewImport';

@reduxForm({form: 'ImportLK23Form', destroyOnUnmount: true})
@connect((state) => ({
  ...ImportLK23Service.stateConnectSetting()(state),
  formData: getFormValues('ImportLK23Form')(state),
  // EPPT CORE
  auth: state.auth.company
  // EPPT CORE
}), ImportLK23Service.actionConnectSetting())
export default class ImportLK23View extends ListViewImport {
  service=ImportLK23Service
  FormDialog=FormDialog
  pasal='pasal23'
  category='LT23'
  importPath='/pasal23/upload/csv'
  showAutomatisTab=false
  // viewType=2

  beforeFetch(params) {
    params["category.equals"] = "LT23"
  }

  async handleDownloadTemplate(dialog) {
    var report = await ImportLK23Service.api.downloadTemplate(dialog, 'LT23')
    window.open(report.data.url)
    //download(report.data, `Template_${dialog.category}.csv`);
  }
}
