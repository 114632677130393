import ApiService from '../../../services/api.service';

class ImportAll23Service extends ApiService {
  name= 'ImportAll23';
  path= 'pasal23/log/csv';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    validasi: async (fileId, page) => {
      if(page){
        page = page
      } else {
        page = 0
      }
      var res = await this.http.get(this.apiLocation+'/pasal23/validation-csv', {
        params: {
          fileId : fileId,
          page: page,
          size: 100
        }
      })

      return res.data;
    },
    downloadTemplate: async (category)=> {
      var res = await this.http.get(this.apiLocation+'/pasal23/download/template', {
        params: {
          category: category
        }
      })
      return res;
    }
  }
}


export default new ImportAll23Service();
