import React from 'react'

export default class SignerPasal extends React.Component {
    render(){
        return(
            <div>
                <h1>WWISADSADSAD</h1>
            </div>
        )
    }
}