import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Field, reduxForm, getFormValues } from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../../../components/form';

import ImportBp21SatuMasaTLService from './ImportBP21SatuMasaTL.service';
import ListView from '../../../../../components/entity/listView';
import ColumnService from '../../../../../services/column.service';
import ImportService from './../Import/Import.service';
import axios from 'axios';
import izitoast from 'izitoast';
import FormDialog from './ImportBP21SatuMasaTL.dialog.view';
import download from "downloadjs";
import ListViewImport from '../../../../../components/entity/ListViewImport';

@reduxForm({form: 'IMPORT_21_BP_SATU_MASATL_Form', destroyOnUnmount: true})
@connect((state) => ({
  ...ImportBp21SatuMasaTLService.stateConnectSetting()(state),
  formData: getFormValues('IMPORT_21_BP_SATU_MASATL_Form')(state),
  // EPPT CORE
  auth: state.auth.company
  // EPPT CORE
}), ImportBp21SatuMasaTLService.actionConnectSetting())
export default class ImportBp21SatuMasaTLView extends ListViewImport {
  service=ImportBp21SatuMasaTLService
  FormDialog=FormDialog

  category='SM_TL'
  importPath='/upload/csv'
  showAutomatisTab=false
  showPenomoranAuto=false
  showPembetulan=true
  // viewType=2

  beforeFetch(params) {
    params["category.equals"] = "SM_TL"
  }

  async handleDownloadTemplate() {
    var report = await ImportBp21SatuMasaTLService.api.downloadTemplate()
    window.open(report.data.url, 'TemplateBp21SatuMasaTL.csv');
  }

}
