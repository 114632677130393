import React, { Component } from 'react';
import SelectField, { Paper, Switch, Button, TablePagination, DataTable, MenuButton } from 'react-md';
import counterpart from 'counterpart';
import { reduxForm, Field } from 'redux-form';
import { Commandbar, Table, TableSetting, Empty, DialogConfirm } from 'react-mpk';
import { Textfield, Searchfield, Datepicker, Multiselect, Switch as Switch2, Radio, TextfieldMask, Checkbox } from '../form'
// import TableSettingCustom from '../TableSettingCustom/TableSettingCustom';
import qs from 'qs';
import moment from 'moment'

import ListView from './listView'
import _ from 'lodash'
import izitoast from 'izitoast'

class SearchContainer extends Component {
  render() {
    return (
      <div className="md-grid">
      {this.props.children}
      </div>
    )
  }
}

export default class ListViewEbupot extends ListView {

  componentWillMount() {
    this.buildSearchContainer();
    // this.fetchData();
    this.props.dispatch(
      this.service.tableAction.setProperties({
        index: 0,
        isSelect: false,
        selected: [],
        params: {}
      })
    )
  }

  removeCheckbox(){
    var serviceName = ""
    if(this.service && this.service.name) serviceName = this.service.name
    var dispatchName = serviceName.toUpperCase() + "/TABLE_DELETE_SELECTED_DATA_ITEMS"
    this.props.dispatch(
      {
        type: dispatchName,
      }
    )
    setTimeout(()=> {
      this.fetchData();
    }, 500)
    try {
      var mpkTable = document.getElementsByClassName('mpk-full height width')[1]
      var mpkRow = mpkTable.getElementsByClassName('md-table-row--active')
      var mpkCounter = 0
      while(mpkCounter < mpkRow.length){
          try {
            var checkbox = mpkRow[mpkCounter]
            checkbox.classList.remove("md-table-row--active")
            var checkboxIcon = checkbox.getElementsByClassName('md-icon')[0]
            checkboxIcon.classList.remove("md-text--inherit")
            checkbox.classList.remove("md-text--theme-secondary")
            checkboxIcon.innerText = 'check_box_outline_blank'
            mpkCounter++;
          } catch(e){
            mpkCounter++;
          }
      }
    } catch(e){}
  }

  defaultSearchForm(){
    return (
      <div className="md-grid">

      </div>
    )
  }

  searchForm() {
    return (
      <this.SearchContainer >
        {this.defaultSearchForm()}
        {/* <this.ig.Field
          label='Search by'
          name='type'
          className="md-cell md-cell--12"
          component={this.ig.Searchfield}
          options={[
            {id: "AdvancedSearch", name: 'Advanced Search'}
          ]}
        /> */}
      </this.SearchContainer>
    )
  }

  beforeFetch(params) {
      delete params.startDate
      delete params.endDate
      delete params.column
      delete params.total
      delete params.keyword
      // delete params.sortBy
      // params.sortBy = 'id'
      // params.sort = 1
  }

  // extra part
  _tableActionsFunc(i){
    return this._tableActions
  }
  // extra part

  _tableRow(i){
    var tableActions = this._tableActionsFunc(i);
    if(this.tableActions) tableActions= tableActions;
    var _rows = this.state.data
    return _rows[i];
  }

  _tableColumns(){
    var columns = Object.assign([], this.columns)
    return columns
  }

  plainTable(){ return false }

  _tableView(props) {
    var columns = this._columns;
    var tableActions = this._tableActionsFunc();
    if(this.columns) columns = this._tableColumns()
    if(this.tableActions) tableActions= this.tableActions;

    var newColumns = _.uniqBy(columns, 'value')
    columns = newColumns

    return (
    <Paper className="flex mpk-layout column">
      {this.header()}
      <Table
        connect={{
          properties: this.props.table,
          actions: this.props.tableActions
        }}
        plain={this.plainTable()}
        fullWidth={false}
        isPaging={true}
        translate={true}
        columns={columns}
        leftAction={true}
        itemActions={tableActions}
        fetchData={this.fetchData}
        footer={this.footer()}
      />
    </Paper>
    )
  }

}
