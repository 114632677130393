import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button, SVGIcon } from 'react-md';
import FormDialog from './Master21Pasal17Global.dialog.view';
import Master21Pasal17Service from './Master21Pasal17Global.service';
import ListView from '../../../../../components/entity/listView';
import ColumnService from '../../../../../services/column.service';

@connect((state)=> ({
  ...Master21Pasal17Service.stateConnectSetting()(state),
  spt: state.authEppt.currentSPT
}), Master21Pasal17Service.actionConnectSetting())
export default class Master21Pasal17GlobalView extends ListView {
  service=Master21Pasal17Service
  FormDialog=()=> <div/>

  addDialog=false
  editDialog=false

  /*fetchOption() {
    const npwp = this.props.spt.data.npwp
    return {
      path: Master21Pasal17Service.path+'/'+npwp+'/company'
    }
  }*/

  _barActions = [
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {
        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    },
  ]

  editItem = (item) => {
    this.props.history.push(`${this.props.location.pathname}/${item.id}`)
  }

  openProgresif = (item) => {
    this.props.history.push(`${this.props.location.pathname}/${item.id}/progresif`)
  }

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    },
    {
      label:"Detail",
      iconClassName:"mdi mdi-open-in-new",
      onClick: (item) => this.openProgresif(item)
    },
  ]

  columns=[
    {isSortable: true, label: "word.id", searchField: "id", value: "id", show:false, isSearchable:true},
    {isSortable: true, label: "word.start", searchField: "startDate", value: "startDate", show:true, isSearchable:true},
    {isSortable: true, label: "word.end", searchField: "endDate", value: "endDate", show:true, isSearchable:true},
  ]
}
