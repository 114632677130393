import React, { Component } from 'react';
import { Grid, Cell } from 'react-md';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import { validation, Textfield, TextfieldMask, convert } from '../../../../components/form';
import DialogView from '../../../../components/entity/dialog.view';
import MasterPtkpService from './MasterPtkp.service';

@reduxForm({form: 'master-ptkp', destroyOnUnmount: false})
export default class MasterPtkpDialogView extends DialogView {
  service=MasterPtkpService
  initialData = {
  }

  formView() {
    return (
      <Grid style={{padding:'none'}}>
         <Field
          label='PTKP'
          name='ptkp'
          id="master-ptkp-ptkp"
          className="md-cell md-cell--12"
          component={TextfieldMask}
          money={true}
          normalize={convert.money}
          validate={validation.required}
        />
        <Field
          label='Start From'
          name='start'
          id="master-ptkp-start"
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
        />
        <Field
          label='To'
          name='to'
          id="master-ptkp-to"
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
        />
        <Field
          label='Dependents'
          name='dependents'
          id="master-ptkp-dependents"
          className="md-cell md-cell--6"
          component={TextfieldMask}
          money={true}
          normalize={convert.money}
          validate={validation.required}
        />
      </Grid>
    )
  }
}
