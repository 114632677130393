/**
 * Created by dwiargo on 12/13/17.
 */

import React,{ Component } from 'react';
import PropTypes from 'prop-types';
import { Paper } from 'react-md';
import './CardArticleThumbnail.scss';

class CardArticleThumbnail extends Component {
  render() {
    let { title, img, info, fixedPotrait } = this.props;
    return (
      <Paper className={"mpk-card-article-thumbnail " + (fixedPotrait ?  "column" : "mpk-layout row column-xs")}>
        { fixedPotrait ? (
          <div className="image"><img src={img} role="presentation"/></div>
        ) : (
          <div
            className="image"
            style={{
              background:"url("+img+") center center no-repeat",
              backgroundSize:"cover"
            }}
          />
        )}
        <div className="flex content mpk-layout column justify-between">
          <div className="title mpk-link-on-hover">{title}</div>
          <div className="info">{info}</div>
        </div>
      </Paper>
    )
  }
}

CardArticleThumbnail.propTypes = {
  title: PropTypes.string,
  info:PropTypes.string,
  img: PropTypes.string,
  fixedPotrait:PropTypes.bool
};

export default CardArticleThumbnail;