import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import {Button, Card, FontIcon, List, ListItem, Subheader} from 'react-md';
import _ from 'lodash';
import FormView from '../../../../components/entity/form.view';
import {Datepicker, Switch, Textfield} from '../../../../components/form';
import SettingEmailScheduleService from './SettingEmailSchedule.service';
import SettingEmailScheduleDetailsService from '../SettingEmailScheduleDetails/SettingEmailScheduleDetails.service';
import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

@reduxForm({
  form: 'SettingEmailSchedule_Form',
  destroyOnUnmount: true,
  initialValues: {active: false, limitPerDay: 0, settingEmailScheduleDetails: []}
})
@connect((state) => ({
  access: state.entity.access.api.find,
  organization: state.authEppt.currentOrganization,
  spt: state.authEppt.currentSPT,
  formData: state.form.SettingEmailSchedule_Form,
  user: state.authEppt.user
}))
export default class SettingEmailScheduleForm extends FormView {
  service = SettingEmailScheduleService
  settingEmailScheduleDetailsService = SettingEmailScheduleDetailsService
  viewType = 2;

  constructor(props) {
    super(props)
    this.state = {
      ...this.state
    }
  }

  _barItem() {
    const {handleSubmit, submitting, valid, pristine} = this.props;
    var companyId = this.props.match.params.companyId

    if (companyId) {
      return (
        <div>
          <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Save</Button>
        </div>
      )
    } else {
      return (
        <div>
          <Button secondary flat onClick={() => {
            this.props.history.goBack()
          }} style={{marginRight: 16}}>Back</Button>
          <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Save</Button>
        </div>
      )
    }
  }

  async initData() {
    var companyId = this.props.match.params.companyId

    if (!companyId) {
      // Try in url
      var paramInurl = this.props.location.pathname.split("/")

      if (paramInurl[2]) {
        companyId = paramInurl[2]
      }
    }

    if (this.props.user.company && this.props.user.company.id) companyId = this.props.user.company.id;

    try {
      let res = await this.service.api.getByCompany(companyId);

      let data = res.data

      let resDetails = await this.settingEmailScheduleDetailsService.api.getByEmailSchedule(data.id)

      data.settingEmailScheduleDetails = resDetails.data

      this.props.initialize(data);
    } catch (e) {
      this.toast.warning({
        title: e.name,
        message: e.message
      })
    }
  }

  async handleSave(value) {
    try {
      let res = await this.service.api.updateByCompany(value)
    } catch (e) {
      this.toast.warning({
        title: e.name,
        message: e.message
      })
    }
  }

  async handleSelectTanggal(v) {
    let tanggal = v.getDate();
    let bulan = v.getMonth() + 1;

    let settingEmailScheduleDetail = {tanggal, bulan};

    let formData = this.props.formData;
    let settingEmailScheduleDetails = formData.values.settingEmailScheduleDetails;
    let check = _.find(settingEmailScheduleDetails, {
      tanggal: settingEmailScheduleDetail.tanggal,
      bulan: settingEmailScheduleDetail.bulan
    });

    if (!check) {
      settingEmailScheduleDetails.push(settingEmailScheduleDetail);

      this.props.change('settingEmailScheduleDetails', settingEmailScheduleDetails);
    }
  }

  async handleDeleteTanggal(i) {
    let formData = this.props.formData;
    let settingEmailScheduleDetails = formData.values.settingEmailScheduleDetails;

    settingEmailScheduleDetails.splice(i, 1);

    this.props.change('settingEmailScheduleDetails', settingEmailScheduleDetails);
  }

  formView() {
    return (
      <div>
        <Card>
          <div className='md-grid'>
            <div className="md-cell md-cell--6">
              <Field
                label='Aktif'
                name='active'
                component={Switch}
              />
            </div>

            <div className="md-cell md-cell--6">
              <Field
                label='Batas Per Hari'
                name='limitPerDay'
                type='number'
                component={Textfield}
              />
            </div>

            <div className="md-cell md-cell--6">
              <Field
                label='Encrypt File A1'
                name='encryptA1'
                component={Switch}
              />
            </div>

            {this.props.formData.values.encryptA1 &&
              <div className="md-cell md-cell--6">
                <Field
                  label='Password A1'
                  name='passwordA1'
                  type="password"
                  component={Textfield}
                />
              </div>
            }

            <div className="md-cell md-cell--12">
              <Field
                label='Tanggal'
                name='tanggal'
                inline={true}
                component={Datepicker}
                onDateChange={(v) => {
                  this.handleSelectTanggal(v)
                }}
                autoComplete="off"
              />
            </div>

            <div className="md-cell md-cell--12">
              <List className="md-paper md-paper--1">
                <Subheader primaryText={<div>Selected </div>}></Subheader>

                {this.props.formData.values.settingEmailScheduleDetails.map((d, i) => {

                  return <ListItem
                    key={i}
                    rightIcon={<FontIcon onClick={() => this.handleDeleteTanggal(i)}>delete</FontIcon>}
                    primaryText={'Tanggal: ' + d.tanggal + ' Bulan: ' + d.bulan}
                  />
                })}
              </List>
            </div>
          </div>
        </Card>
        <br/>
      </div>
    )
  }

}
