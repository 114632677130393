import React, { Component } from 'react'; import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {TabsContainer, Tabs, Tab, Button, DialogContainer} from 'react-md';
import { Field, reduxForm } from 'redux-form';
import axios from 'axios'
import moment from 'moment'

import LogSubmitService from './LogSubmit.service';
import ListView from '../../../../../components/entity/ListViewEbupot';
import AuthService from '../../../../../services/auth.service'
import ListViewEbupot from '../../../../../components/entity/ListViewEbupot';
import OrganizationService from '../Organization/Organization.service'
import {validation, FileInputNext, Searchfieldv2, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Radio, convert, CheckboxSelection} from '../../../../../components/form';
import iziToast from 'izitoast';

@reduxForm({form: 'LogSubmitForm', destroyOnUnmount: true})
@connect((state)=> ({
  ...LogSubmitService.stateConnectSetting()(state)
}), LogSubmitService.actionConnectSetting())
export default class LogSubmitView extends ListViewEbupot {
  service=LogSubmitService;
  addDialog=false
  editDialog=false

  beforeFetch(params) {
    delete params.startDate
    delete params.endDate
    delete params.column
    delete params.total
    delete params.keyword
    
    
    // params.sortBy = 'id'
    // params.sort = 1
  }
  
  columns=LogSubmitService.columns

  _barActions = [
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => this.fetchData()
    },
  ]

  async downloadFile(item) {
    try {
      var res = await this.service.api.download(item.id)
      var filename = res.headers.filename
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url; 
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      iziToast.success({
        title: "Download File",
        message: "sedang di proses"
      })
    } catch(e){
      iziToast.error({
        title: "Failed Download File",
        message: e.message
      })
    }
  }

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    },
    {
      label:"Download File",
      iconClassName:"mdi mdi-download",
      onClick: (item) => this.downloadFile(item)
    }
  ]

}