import ApiService from '../../../../../services/api.service';

class Master21Pasal17GlobalService extends ApiService {
  name= 'Master21Pasal17Global';
  path= 'rates/pasal17-global';

  constructor() {
    super();
    this.init()
    this.initApi()
  }

  api= {
    getData: async (npwp) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${npwp}/all`)
      return res;
    }
  }
}


export default new Master21Pasal17GlobalService();

