import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button, SVGIcon } from 'react-md';
import AllBp21SatuTahunService from './ALL_BP_21_SATU_TAHUN.service';
import ListView from '../../../../../components/entity/listView';
import ColumnService from '../../../../../services/column.service';
import {Switch} from 'react-md';
import iziToast from 'izitoast';
import download from "downloadjs";
import BP21SatuTahunService from "../BP_21_SATU_TAHUN/BP_21_SATU_TAHUN.service";
import LogExportService from "modules/Main/Export/LogExport.service";
import {Checkbox} from "../../../../../components/form";
import moment from "moment";

@connect(AllBp21SatuTahunService.stateConnectSetting(), AllBp21SatuTahunService.actionConnectSetting())
export default class AllBp21SatuTahunView extends ListView {
  service=AllBp21SatuTahunService

  constructor(props){
    super(props);
    this.state = {
      showTableSetting:false,
      showDialogConfirmDeleteSelected:false,
      showForm:false,
      formData: {},
      data: []
    }
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Nama'
          name='name__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NPWP'
          name='npwp__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NPWP Organisasi'
          name='sptNpwpProfile__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Dari Bulan'
          name='month__greaterOrEqualThan'
          className="md-cell md-cell--6"
          component={this.ig.Searchfield}
          options={[
            {id: 1, name: "1"},
            {id: 2, name: "2"},
            {id: 3, name: "3"},
            {id: 4, name: "4"},
            {id: 5, name: "5"},
            {id: 6, name: "6"},
            {id: 7, name: "7"},
            {id: 8, name: "8"},
            {id: 9, name: "9"},
            {id: 10, name: "10"},
            {id: 11, name: "11"},
            {id: 12, name: "12"}
          ]}
        />
        <this.ig.Field
          label='Sampai Bulan'
          name='month__lessOrEqualThan'
          className="md-cell md-cell--6"
          component={this.ig.Searchfield}
          options={[
            {id: 1, name: "1"},
            {id: 2, name: "2"},
            {id: 3, name: "3"},
            {id: 4, name: "4"},
            {id: 5, name: "5"},
            {id: 6, name: "6"},
            {id: 7, name: "7"},
            {id: 8, name: "8"},
            {id: 9, name: "9"},
            {id: 10, name: "10"},
            {id: 11, name: "11"},
            {id: 12, name: "12"}
          ]}
        />

        <this.ig.Field
          label='Bulan'
          name='month__equals'
          className="md-cell md-cell--12"
          component={this.ig.Searchfield}
          options={[
            {id: 1, name: "1"},
            {id: 2, name: "2"},
            {id: 3, name: "3"},
            {id: 4, name: "4"},
            {id: 5, name: "5"},
            {id: 6, name: "6"},
            {id: 7, name: "7"},
            {id: 8, name: "8"},
            {id: 9, name: "9"},
            {id: 10, name: "10"},
            {id: 11, name: "11"},
            {id: 12, name: "12"}
          ]}
        />

        <this.ig.Field
          label='Tahun'
          name='year__equals'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Pembetulan'
          name='rev__equals'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Kode Pajak'
          name='taxObjectCode__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
      </this.SearchContainer>
    )
  }

  editDialog = false;
  _barActions = [];

  editItem = (item) => {
    if(this.editDialog) {
      this.showFormDialog(item);
    } else {
      if(this.rootPath) {
        this.props.history.push(this.rootPath+"/"+item.id)
      } else {
        this.props.history.push('/'+this.service.name+"/"+item.id);
      }
    }
  }

  async handleSendEmail(){
    try {
      let category = "BP_21_SATU_TAHUN"
      var selected = this.props.table.selected
      var ids = [];
      selected.map(function (i){
        ids.push(i.id)
      })
      await this.service.api.email(category, ids)
      iziToast.info({
        title: "Kirim Email",
        message: "email sedang diproses silahkan cek log untuk lebih detil"
      })
    } catch (e){
      iziToast.warning({
        title: e.name,
        message: e.message
      })
    }
  }

  async handleCetak() {
    this.setState({cetak: true})
    if (this.props.table.selected.length === 0) {
      iziToast.warning({
        title: 'Pemberitahuan !',
        message: 'Pilih salah satu data yang akan dicetak',
      });
    }

    else if (this.props.table.selected.length > 1) {
      try {
        var selected = this.props.table.selected
        var ids = [];
        selected.map(function (i) {
          ids.push(i.spt21.id)
        })

        var report = await BP21SatuTahunService.api.cetakBulk(ids)
        var filename = report.headers.filename
        download(report.data, filename);
        this.setState({cetak: false})
      } catch (e) {
        this.setState({cetak: false})
      }
    }

    else {
      try {
        var selection = this.props.table.selected[0]
        var sptId = selection.spt21.id
        var report = await BP21SatuTahunService.api.cetakDaftar(sptId)
        var filename = report.headers.filename
        download(report.data, filename);
        this.setState({cetak: false})
      } catch (e) {
        this.setState({cetak: false})
      }
    }
  }

  // columns=[
  //   {isSortable: true, label: "word.npwpProfile",  value:"spt21.organization.npwp", show:true, isSearchable:true},
  //   {isSortable: true, label: "word.createdDate",  value:"createdDate", type:"date", show:true, isSearchable:true},
  //   {isSortable: true, label: "word.createdBy",  value:"createdBy", show:true, isSearchable:true},
  //   {isSortable: true, label: "word.year",  searchField: "year", value: (d)=> d.spt21.year, type: "func", show:true, isSearchable:true},
  //   {isSortable: true, label: "word.month",  value: "spt21.month", searchField:'masa', type: "number", show:true, isSearchable:true},
  //   {isSortable: true, label: "word.pembetulan",  value: "spt21.pembetulan", type: "number", show:true, isSearchable:true},
  //   /*{isSortable: true, label: "word.reference",  value: "referensi", show:false, isSearchable:true},*/
  //   {isSortable: true, label: "word.npwp",  value: "npwp", show:true, isSearchable:true},
  //   {isSortable: true, label: "word.nik",  value: "nik", show:false, isSearchable:true},
  //   {isSortable: true, label: "word.name",  value: "nama", show:true, isSearchable:true},
  //   {isSortable: true, label: "word.taxCode",  value: "code", show:true, isSearchable:true},
  //   {isSortable: true, label: "word.bruto",  value: "bruto", show:true, isSearchable:true, type: 'number'},
  //   {isSortable: true, label: "word.pph",  value: "pph", show:true, isSearchable:true, type: 'number'},
  //   {isSortable: true, label: "word.date",  value: "date", show:true, isSearchable:true},
  // ]

  defaultColumns = [
    /*{isSortable:true, label: "word.id",  value: "id", show:false, isSearchable:true, isDefaultSort: false},*/
    {isSortable:true, label: "word.createdUsername",  value: "createdUsername", show:true, isSearchable:true},
    {isSortable:true, label: "word.createdBy",  value: "createdBy", show:true, isSearchable:true},
    {isSortable:true, label: "word.createdDate", value: "createdDate", show:true, isSearchable:true, type:"date", isDefaultSort: true},
    {isSortable:true, label: "word.modifiedUsername",  value: "modifiedUsername", show:true, isSearchable:true},
    {isSortable:true, label: "word.lastModifiedBy",  value: "lastModifiedBy", show:true, isSearchable:true},
    {isSortable:true, label: "word.lastModifiedDate", value: (d)=> {
        if(d.lastModifiedDate) {
          return <div className='mpk-font-size-S'>{moment(d.lastModifiedDate).format('lll')}</div>
        } else {
          return null;
        }
      }, show:true, isSearchable:true, type:"func", searchField: 'lastModifiedDate', isDefaultSort: true}
  ]

  columns=[
    {isSortable: true, label: "word.npwpProfile", value:"spt.npwp", searchField: "spt.npwp", show:true, isSearchable:true},
    {isSortable: true, label: "word.year", value: (d)=> d.spt.year, searchField: "spt.year", type: "func", show:true, isSearchable:true},
    {isSortable: true, label: "word.month", value: (d)=> d.spt.month, searchField: "spt.month", type: "func", show:true, isSearchable:true},
    {isSortable: true, label: "word.pembetulan", value: (d)=> d.spt.rev, searchField: "spt.rev", type: "func", show:true, isSearchable:true},
    {isSortable: true, label: "word.npwp",  value: "npwp", searchField: "npwp", show:true, isSearchable:true},
    {isSortable: true, label: "word.name",  value: "name", searchField: "name", show:true, isSearchable:true},
    {isSortable: true, label: "word.taxCode",  value: "taxObjectCode", searchField: "taxObjectCode", show:true, isSearchable:true},
    {isSortable: true, label: "word.bruto",  value: "bruto", searchField: "bruto", show:true, isSearchable:true, type: 'number'},
    {isSortable: true, label: "word.pph",  value: "pph", searchField: "pph", show:true, isSearchable:true, type: 'number'},
    {isSortable: true, label: "word.date",  value: "cuttingDate", searchField: "cuttingDate", show:true, isSearchable:true},
  ]

  _tableActions = [
    // {
    //   label:"More Info",
    //   iconClassName:"mdi mdi-bell",
    //   onClick: (item) => this.editItem(item)
    // }
  ]

}
