import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import {Button, Card, Grid, Cell} from 'react-md';
import PaymentOrderService from './PaymentOrder.service';
import {Textfield, TextfieldMask} from '../../../../components/form';
import FormView from '../../../../components/entity/form.view';

@reduxForm({form: 'PaymentOrder_Form', destroyOnUnmount: true})
@connect((state) => ({
  access: state.entity.access.api.find,
  formData: state.form.PaymentOrder_Form
}))
export default class PaymentOrderForm extends FormView {
  service = PaymentOrderService
  viewType = 2;

  initialData = {}

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      npwpNotValid: true
    }
  }

  async beforeSave(value) {
    return true;
  }

  async initData() {
    let res = await this.service.api.findOne(this.props.match.params.id);
    this.props.initialize(res.data);
  }

  _barItem() {
    return (
      <div>
        <Button secondary flat onClick={()=> {this.props.history.goBack()}}>Back</Button>
      </div>
    )
  }

  formView() {
    var formData = {};
    if (this.props.formData && this.props.formData.values) formData = this.props.formData.values
    return (
      <div>
        <Card>
          <Grid>
            <Cell size={6}>
              <Field
                label='NPWP'
                name='ssp.npwp'
                id="PaymentOrderForm-npwp"
                component={TextfieldMask}
                maskFormat="##.###.###.#-###-###"
                mask="_"
                length={15}
                disabled={true}
              />

              <Field
                label='Nama'
                name='ssp.name'
                component={Textfield}
                disabled={true}
              />

              <Field
                label='Kota'
                name='ssp.city'
                component={Textfield}
                disabled={true}
              />

              <Field
                label='Alamat'
                name='ssp.address'
                component={Textfield}
                disabled={true}
              />

              <Field
                label='Kode SSP'
                name='ssp.code'
                component={Textfield}
                disabled={true}
              />

              <Field
                label='ID Billing'
                name='idBilling'
                component={Textfield}
                disabled={true}
              />
            </Cell>

            <Cell size={6}>
              <Field
                label='Jenis Pajak'
                name='ssp.jenisPajak.code'
                component={Textfield}
                disabled={true}
              />

              <Field
                label='Jenis Setoran'
                name='ssp.jenisSetoran.code'
                component={Textfield}
                disabled={true}
              />

              <Field
                label='Bulan 1'
                name='ssp.bulan1.code'
                component={Textfield}
                disabled={true}
              />

              <Field
                label='Bulan 2'
                name='ssp.bulan2.code'
                component={Textfield}
                disabled={true}
              />

              <Field
                label='Tahun'
                name='ssp.year'
                component={Textfield}
                disabled={true}
              />

              <Field
                label='Jumlah Setor'
                name='ssp.amount'
                component={Textfield}
                disabled={true}
              />

              <Field
                label='Aktif S.d'
                name='activeUntil'
                component={Textfield}
                disabled={true}
              />
            </Cell>
          </Grid>
        </Card>
      </div>
    )
  }

}
