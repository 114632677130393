import ApiService from '../../../services/api.service';
import http from './../../../services/http.service'

class NpwpService extends ApiService {
  name= 'NpwpValidator';
  path= 'check-npwp';

  api= {
    getCertStatus : async(npwp) => {
      var res = await http.get(`/api/ebupot/cert/search/${npwp}`, {params: {npwp}})

      return res
    },
    validate: async (npwp) => {
      var res = await this.http.get(this.apiLocation+'/npwp-check', {
          params: {
            npwp: npwp
          }
      })

      return res.data;
    },
    validate22: async (npwp) => {
      var res = await this.http.get(this.apiLocation+'/pasal22/util/npwp-check', {
          params: {
            npwp: npwp
          }
      })

      return res.data;
    },
    validate4: async (npwp) => {
      var res = await this.http.get(this.apiLocation+'/pasal4/util/npwp-check', {
          params: {
            npwp: npwp
          }
      })

      return res.data;
    },
    validate15: async (npwp) => {
      var res = await this.http.get(this.apiLocation+'/pasal15/util/npwp-check', {
          params: {
            npwp: npwp
          }
      })

      return res.data;
    },
  }
  
  constructor() {
    super()
    this.init()
    this.initApi()
  }
}


export default new NpwpService();
