import ApiService from '../../../../../services/api.service'
import moment from 'moment'
import React from 'react'
import { Chip } from 'react-md'

class AllEbupot23Service extends ApiService {
  name= 'AllEbupot23'
  path= 'ebupot/bp23'
  advancedSearch=true

  constructor(){
    super()
    this.init()
    this.initApi()
  }

  columns=[
    {label: "word.id", searchField: "id", value: "id", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
    {label: "word.noBp", searchField: "no", value: "no", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {
      searchField: "status",
      label: "word.statusSpt",
      type: 'func',
      value: (d)=> {
        var style = {
          background: 'grey'
        }
        switch (d.status) {
          case 'CREATED':
            style.background = 'grey'
            break;
          case 'SET':
            style.background = '#2196F3'
            break;
          case 'PROCESS':
            style.background = '#D32F2F'
            break;
          case 'FAILED':
            style.background = '#FFEB3B'
            break;
          case 'FINISH':
            style.background = '#4CAF50'
            break;
          case 'DELETED':
            style.background = 'red'
            break;
          default:
            break;
        }
        return <Chip label={d.status} style={style} />
      },
      className: "mpk-font-size-S", show:true, isSearchable:true},
    {label: "word.message", searchField: "message", value: "message", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.npwp", searchField: "npwp", value: "bagA.npwp", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.nik", searchField: "nik", value: "bagA.nik", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.nama", searchField: "nama", value: "bagA.nama", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.email", searchField: "email", value: "bagA.email", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.code", searchField: "code", type:'func', value: (val)=> { return <div style={{width: 100}}>{val.bagB.kode}</div> }, isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.bruto", searchField: "bruto", type: "number", value: "bagB.bruto", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.pph", searchField: "pph", type: "number", value: "bagB.pph", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.nSkbDtp", searchField: "nSkbDtp", type: "func", value: (d)=> {
      var nomor = ""
      if(d.fasilitas == 0){
        nomor = "N"
      } else if(d.fasilitas == 1){
        nomor = "SKB"
      } else if (d.fasilitas == 2){
        nomor = "DTP"
      }
      return nomor
    }, isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.refXmlId", searchField: "refXmlId", value: "refXmlId", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.tgl", searchField: "tgl", type:'func', value: (val)=> { return <div style={{width: 100}}>{val.tgl}</div> },  isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.createdBy", searchField: "createdBy", value: "createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.createdDate", searchField: "createdDate", value: (d)=> {
      if(d){
        return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
      } else {
        return "-"
      }
    }, type: 'func', isDefaultSort:true, isSortable:true, show:true, isSearchable:true},
    {label: "word.lastModifiedBy", searchField: "lastModifiedBy", value: "lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.lastModifiedDate", searchField: "lastModifiedDate", value: (d)=> {
      if(d.lastModifiedDate){
        return moment(d.lastModifiedDate).format('DD/MM/YYYY HH:MM')
      } else {
        return "-"
      }
    }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
  ]

}

export default new AllEbupot23Service();
