import ApiService from '../../../../services/api.service';

class PaymentNotificationService extends ApiService {
  name= 'PaymentNotification';
  path= 'ebilling/order';

  api = {
    validate: async (data) => {
      var res = await this.http.post(`${this.apiLocation + "/" + this.path}/validate`, data)

      return res.data;
    },
    create: async (data) => {
      var res = await this.http.post(`${this.apiLocation + "/" + this.path}`, data)

      return res.data;
    },
    validateAll: async (data) => {
      var res = await this.http.get(`${this.apiLocation + "/" + this.path}/validate/all`, { params: data })

      return res.data;
    },
    createAll: async (data) => {
      var res = await this.http.get(`${this.apiLocation + "/" + this.path}/all`, { params: data })

      return res.data;
    },
    show: async (id) => {
      var res = await this.http.get(`${this.apiLocation + "/" + this.path}/${id}`)

      return res.data;
    },
    findBillingsByOrderId: async (params) => {
      let invoiceId = params.invoiceId

      delete params.invoiceId

      if(params.page)
        params.page = params.page - 1
      else
        params.page = 0

      var res = await this.http.get(`${this.apiLocation + "/" + this.path}/billing/${invoiceId}`, { params })

      return res;
    },
    findOrderFileByOrderId: async (id) => {
      var res = await this.http.get(`${this.apiLocation + "/" + this.path}/order-file/${id}`)

      return res.data;
    },
    resendOrderFileById: async (id) => {
      var res = await this.http.post(`${this.apiLocation + "/" + this.path}/order-file/${id}/resend`)

      return res.data;
    },
    confirmOrder: async (id) => {
      var res = await this.http.get(`${this.apiLocation + "/" + this.path}/confirm/${id}`)

      return res.data;
    },
    pullNtpn: async (data) => {
      var res = await this.http.post(`${this.apiLocation + "/" + this.path}/pull-ntpn`, data)

      return res.data;
    },
    print: async (id) => {
      var res = await this.http.get(`${this.apiLocation + "/" + this.path}/print/${id}`, {
        responseType: 'blob'
      })

      return res;
    },
    printBulk: async (data) => {
      var res = await this.http.post(`${this.apiLocation + "/" + this.path}/print-bulk`, data)

      return res.data;
    },
    printBpn: async (data) => {
      var res = await this.http.post(`${this.apiLocation + "/" + this.path}/print-bpn`, data)

      return res.data;
    },
    printBpnGabung: async (data) => {
      var res = await this.http.post(`${this.apiLocation + "/" + this.path}/print-bpn-gabung`, data)

      return res.data;
    },
    printReport: async (data) => {
      var res = await this.http.post(`${this.apiLocation + "/" + this.path}/print-report`, data)

      return res.data;
    },
    deleteSelected: async (data) => {
      var res = await this.http.post(`${this.apiLocation + "/" + this.path}/delete/checked`, data)

      return res.data;
    },
  }

  constructor() {
    super()
    this.init()
    this.initApi()
  }
}


export default new PaymentNotificationService();
