import ApiService from '../../../../../services/api.service';

class SPT25Service extends ApiService {
  name= 'SPT_25';
  // EPPT CORE
  cloud=true
  path= 'pasal25/spt';
  // EPPT CORE
  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api = {
    pembetulan: async(id, dispatch=()=>{})=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${id}/rev`)

      return res;
    },
    pembetulanBulk: async(id, dispatch=()=>{})=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${id}/rev`)

      return res;
    },

    downloadLapor: async(id, dispatch=()=>{})=> {
      var res = await this.http.get(`${this.apiLocation}/pasal25/master/files/download/${id}`,{
        responseType: 'blob'
      })

      return res;
    },

    lapor: async(sptId) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/submit`)

      return res;
    },

    laporAll: async(data) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/lapor`, data)

      return res;
    },

    laporAllWithSummary: async(data) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/generate/summary`, data)

      return res;
    },

    laporAllWithSummaryParam: async(data) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/generate/summary/param`, {params: data})

      return res;
    },

    pengajuanApprovalBulk: async(data, dispatch=()=>{})=> {
      var res = await this.http.post(`${this.apiLocation}/pasal25/mainFlow/pengajuan/bulk`, data)

      return res;
    },
    getSummary: async(sptId)=> {
      return await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/debt`)
    },

    getInfo: async(sptId)=> {
      return await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/info`)
    },
  }
}


export default new SPT25Service();
