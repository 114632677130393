import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {Field, getFormValues, reduxForm} from 'redux-form';
import {Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab, Chip} from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Multiselectv2, Switch as Switch2} from '../../../../components/form';

import LogExportCsvService from './LogExportCsv.service';
import ListView from '../../../../components/entity/listView';
import ColumnService from '../../../../services/column.service';
import axios from 'axios';
import izitoast from 'izitoast';
import download from "downloadjs";

@reduxForm({form: 'LogExportCsv', destroyOnUnmount: true})
@connect((state) => ({
  ...LogExportCsvService.stateConnectSetting()(state),
  formData: getFormValues('LogExportCsv')(state),
  user: state.auth.user,
  // EPPT CORE
  auth: state.auth.company
  // EPPT CORE
}), LogExportCsvService.actionConnectSetting())
export default class LogExportCsv extends ListView {
    service=LogExportCsvService
    minusPage=true

    static contextTypes = {
        showDialog: PropTypes.func.isRequired,
    };

    apiPath='findByPasal'

    beforeFetch(params) {
        params.pasal = this.props.pasal;
        delete params.column
        delete params.keyword
        delete params.startDate
        delete params.endDate
        params.sort = `${params.sortBy},${params.sort}`
        try {
            if(params['status.in']){
                params['status.in'] == this.props.tableFilter2.status__in
            }
        } catch(e){}
        return params
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.pasal != prevProps.pasal){
            this.fetchData();
        }
    }

    deleteSelected = async (items, callback) => {
        try {
          let ids = []

          items.forEach(item => {
            ids.push(item.id)
          })

          var deleteAll = await this.service.api.deleteAllPerPasal(ids, this.props.pasal)
          await this.removeCheckbox();

          callback()
          await this.fetchData()
        } catch(e) {
          callback(e, e)
        }
    }

    async downloadSelected() {
        let selected = this.props.table.selected

        if (selected.length) {
          selected.forEach(data => {
            this.downloadItem(data)
          })
        } else {
          izitoast.warning({
            title: 'Download',
            message: 'Hanya yang berstatus FINISH yang dapat didownload.'
          })
        }
    }

    async downloadItem(item){
        let res = await this.service.api.downloadPerPasal(item.id, this.props.pasal)

        let url = res.data.url
        let win = window.open(url, '_blank');
        win.focus();
    }

    deleteItem = async (item, callback) => {
        try {
          await this.service.api.deletePerPasal(item.id, this.props.pasal);
          callback()
          await this.fetchData()
        } catch(e) {
          callback(e, e)
        }
    }

    _tableActions = [
        {
          label:"Download",
          iconClassName:"mdi mdi-file",
          onClick: (item) => this.downloadItem(item)
        },
        {label:"divider", iconClassName:"-"},
        {
          label:"Delete",
          iconClassName:"mdi mdi-delete",
          onClick:(item, callback) => this.deleteItem(item, callback),
          confirmation:{
            title:"sentence.custom.deleteItem",
            message:"sentence.custom.deleteItemConfirmation"
          }
        }
    ]

    defaultSearchForm(){
        return (
            <div style={{ width: '100%' }}>
                <Field
                    label     = 'Npwp'
                    name      = 'sptNpwp__contains'
                    className = "md-cell md-cell--12"
                    component = {Textfield}
                />
                <Field
                    label     = 'Tahun'
                    name      = 'sptYear__equals'
                    className = "md-cell md-cell--12"
                    component = {Textfield}
                />
                <Field
                    label     = 'Masa'
                    name      = 'sptMonth__equals'
                    className = "md-cell md-cell--12"
                    component = {Searchfield}
                    options={[
                        {id: 1, name: "1"},
                        {id: 2, name: "2"},
                        {id: 3, name: "3"},
                        {id: 4, name: "4"},
                        {id: 5, name: "5"},
                        {id: 6, name: "6"},
                        {id: 7, name: "7"},
                        {id: 8, name: "8"},
                        {id: 9, name: "9"},
                        {id: 10, name: "10"},
                        {id: 11, name: "11"},
                        {id: 12, name: "12"}
                    ]}
                />
                <Field
                    label     = 'Rev'
                    name      = 'sptRev__equals'
                    className = "md-cell md-cell--12"
                    component = {Textfield}
                    type      = 'number'
                />
                <this.ig.Field
                    label='WAITING'
                    name='status__in_WAITING'
                    className="md-cell md-cell--6"
                    component={this.ig.Checkbox}
                />
                <this.ig.Field
                    label='PROCESS'
                    name='status__in_PROCESS'
                    className="md-cell md-cell--6"
                    component={this.ig.Checkbox}
                />
                <this.ig.Field
                    label='FINISH'
                    name='status__in_FINISH'
                    className="md-cell md-cell--6"
                    component={this.ig.Checkbox}
                />
                <this.ig.Field
                    label='FAILED'
                    name='status__in_FAILED'
                    className="md-cell md-cell--6"
                    component={this.ig.Checkbox}
                />
            </div>
        )
    }

    _barActions = [
        {
          label:'word.delete',
          iconClassName:'mdi mdi-delete',
          onClick:() => {
            this.setState({showDialogConfirmDeleteSelected:true})
          },
          disabledFunc:() => this.props.table.selected.length === 0
        },
        {
          label:'word.download',
          iconClassName:'mdi mdi-file',
          onClick:() => this.downloadSelected(),
          disabledFunc:() => this.props.table.selected.length === 0
        },
        {
          label:'word.refresh',
          iconClassName:'mdi mdi-refresh',
          onClick:() => {

            window.location.hash = window.location.hash.split('?')[0]
            this.fetchData()
          }
        },
    ]

    columns=[
      {isSortable: true, label: "word.id",  value: "id", isDefaultSort:false, show:false, isSearchable:true},
      {isSortable: true, label: "word.npwp", value: "spt.npwp", show:true, isSearchable:true},
      {isSortable: true, label: "word.name", value: "spt.name", show:true, isSearchable:true},
      {isSortable: true, label: "word.month",  value: "spt.month", show:true, isSearchable:true, type: 'number'},
      {isSortable: true, label: "word.year",  value: (d)=> d.spt.year, show:true, isSearchable:true, type: 'func'},
      {isSortable: true, label: "word.pembetulan",  value: "spt.rev", show:true, isSearchable:true, type: 'number'},
      {
        label: "word.status",
        type: 'func',
        value: (d)=> {
          var style = {
            color: 'white',
            backgroundColor: 'grey'
          }
          switch (d.status) {
            case 'PROCESS':
              style.backgroundColor = '#2196F3'
              break;
            case 'FINISH':
              style.backgroundColor = '#4CAF50'
              break;
            case 'FAILED':
              style.backgroundColor = '#f44336'
              break;
            default:
              break;
          }
          return <Button flat swapTheming label={d.status} style={style}/>
      }, className: "mpk-font-size-S", show:true, isSearchable:true},
      {isSortable: true, label: "word.message",  value: "message", isDefaultSort:false, show:true, isSearchable:true},
      {isSortable: true, label: "word.filename",  value: "fileName", isDefaultSort:false, show:false, isSearchable:true},
      {isSortable: true, label: "word.fileSize",  value: "size", isDefaultSort:false, show:true, isSearchable:true},
      {isSortable: true, label: "word.username",  value: "username", isDefaultSort:false, show:true, isSearchable:true},
    ]
}
