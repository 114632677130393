import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Field, reduxForm } from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../../../../components/form';

import ListView from '../../../../../../components/entity/listView';
import ColumnService from '../../../../../../services/column.service';
// import ImportService from './../Import/Import.service';
import axios from 'axios';
import izitoast from 'izitoast';
import FormDialog from './ImportBp24.dialog';
import ImportBp24service from './ImportBp24.service';
import download from "downloadjs";
import ListViewImport from '../../../../../../components/entity/ListViewImport';

@reduxForm({form: 'ImportBp24Form', destroyOnUnmount: true})
@connect(ImportBp24service.stateConnectSetting(), ImportBp24service.actionConnectSetting())
export default class ImportBp24View extends ListViewImport {
  service=ImportBp24service
  FormDialog=FormDialog
  pasal='pasal15'
  category='BP24'
  importPath='/pasal15/upload/csv/bp'
  showAutomatisTab=false
  showPembetulan=true
  // viewType=2

  beforeFetch(params) {
    params["category.equals"] = "BP24"
  }

  async handleDownloadTemplate() {
    var report = await ImportBp24service.api.downloadTemplate('BP24')
    window.open(report.data.url)
  }

}
