import download from 'downloadjs';
import iziToast from 'izitoast';
import React from 'react';
import { Button, Card, CardTitle, DataTable, Divider, TableBody, TableColumn, TableRow } from 'react-md/lib';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import ListView from '../../../../../components/entity/listView';
import ColumnService from '../../../../../services/column.service';
import FileLapor15Service from './FileLapor15.service';

@reduxForm({form: 'FILE_LAPOR_15', destroyOnUnmount: true})
@connect((state)=> ({
  ...FileLapor15Service.stateConnectSetting()(state),
  spt: state.authEppt.currentSPT
}), FileLapor15Service.actionConnectSetting())
export default class FileLapor15View extends ListView {
  service=FileLapor15Service

  constructor(props){
    super(props);
    this.state = {
      showTableSetting:false,
      showDialogConfirmDeleteSelected:false,
      showForm:false,
      formData: {
        pbk: 0,
        pphTerhutang: 0,
        dipotongPihakLain: 0,
        ssp: 0,
        sspDibayarSendiri: 0,
        total: 0
      },
      data: [],
      cetak: false
    }
  }

  columns=[]
  _barActions=[]
  _barItem() {}

  async componentDidMount(){
    var sptId = this.props.spt.data.id
    var res = await this.service.api.getSummary(sptId);
    this.setState({formData : res.data});
    this.props.initialize(res.data)
  }

  async handleCetak(){
    this.setState({ cetak: true })

    var report = null;

    var sptId = this.props.spt.data.id
    var res = await this.service.api.cetakTest(sptId);
    if(res.data.success) {
      report = await this.service.api.cetak(res.data.id)
    } else {
      iziToast.warning({"title": "Warning", "message": res.data.message})
    }

    if(report) {
      try {
        var filename = report.headers.filename;
        download(report.data, filename);
        this.setState({ cetak: false })
      } catch (e){
        console.log(e)
        window.haahha = e
        this.setState({ cetak: false })
        iziToast.warning({"title": e.name, "message": e.message})
      }
    }
  }

  _barItem() {
    return (
      <div>
        {/* <Button disabled={this.state.cetak} primary raised style={{margin: 5}} onClick={this.handleCetak.bind(this)}>Unduh File Lapor</Button> */}
      </div>
    )
  }

  _tableView(props) {
      console.log(this.state.formData)
      return (
          <Card className='flex' style={{marginBottom: 32, marginRight: 10}}>
            <CardTitle title='' style={{padding: '10px 16px'}} subtitle='Detail'/>
            <Divider/>
              <DataTable plain baseId="simple-selectable-table">
                  <TableBody>
                    <TableRow>
                      <TableColumn>Jumlah PPh Terutang</TableColumn>
                      <TableColumn className='md-text-right'>
                        { ColumnService.money2(this.state.formData.pphTerhutang)  }
                      </TableColumn>
                    </TableRow>

                    <TableRow>
                      <TableColumn>Dipotong Pihak Lain</TableColumn>
                      <TableColumn className='md-text-right'>
                        { ColumnService.money2(this.state.formData.dipotongPihakLain)  }
                      </TableColumn>
                    </TableRow>

                    <TableRow>
                      <TableColumn>Jumlah SSP Dibayar Sendiri</TableColumn>
                      <TableColumn className='md-text-right'>
                        { ColumnService.money2(this.state.formData.sspDibayarSendiri)  }
                      </TableColumn>
                    </TableRow>

                    <TableRow>
                      <TableColumn>Jumlah SSP Yang Telah Dibayar</TableColumn>
                      <TableColumn className='md-text-right'>
                        { ColumnService.money2(this.state.formData.ssp)  }
                      </TableColumn>
                    </TableRow>

                    <TableRow>
                      <TableColumn>Jumlah PBK</TableColumn>
                      <TableColumn className='md-text-right'>
                        { ColumnService.money2(this.state.formData.pbk)  }
                      </TableColumn>
                    </TableRow>

                    <TableRow style={{borderTop: 'solid 3px #555'}}>
                      <TableColumn><b>Total</b></TableColumn>
                      <TableColumn className='md-text-right'>
                        <b>{ ColumnService.money2(this.state.formData.total)  }</b>
                      </TableColumn>
                    </TableRow>
                  </TableBody>
              </DataTable>
          </Card>
      )

    }
}
