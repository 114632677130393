import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Button, Chip, Switch} from 'react-md';

//import FormDialog from './Organization.dialog.view';
import ListView from '../../../../../../components/entity/listView';
import UtilService from '../../../../../../services/utils.service';
import download from 'downloadjs';
import uuid from 'uuid';
import iziToast from 'izitoast';
import Master23TarifLainService from './Master23TarifLain.service';
import FormDialog from './Master23TarifLain.dialog';


@connect((state)=> ({
  ...Master23TarifLainService.stateConnectSetting()(state),
  spt: state.authEppt.currentSPT
}), Master23TarifLainService.actionConnectSetting())
export default class Master23LainView extends ListView {
  service=Master23TarifLainService
  FormDialog=FormDialog

  rootPath = '/master/tarif-23-lain'

  defaultColumns = [
    {isSortable:true, label: "word.id",  value: "id", show:false, isSearchable:true, isDefaultSort: false},
  ]

  _barActions = [
    {
      label:'word.create',
      iconClassName:'mdi mdi-plus',
      onClick:() => this.addItem()
    },
    // {
    //   label:'word.delete',
    //   iconClassName:'mdi mdi-delete',
    //   onClick:() => {
    //     this.setState({showDialogConfirmDeleteSelected:true})
    //   },
    //   disabledFunc:() => this.props.table.selected.length === 0
    // },
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    },
  ]

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    },
    // {label:"divider", iconClassName:"-"},
    // {
    //   label:"Delete",
    //   iconClassName:"mdi mdi-delete",
    //   onClick:(item, callback) => this.deleteItem(item, callback),
    //   confirmation:{
    //     title:"sentence.custom.deleteItem",
    //     message:"sentence.custom.deleteItemConfirmation"
    //   }
    // }
  ]

  columns=[
    {isSortable: true, label: "word.code",  value: "code", show:true, isSearchable:true, isDefaultSort: true},
    {isSortable: true, label: "word.name",  value: "name", show:true, isSearchable:true},
    {isSortable: true, label: "word.tarif",  value: "rates", show:true, isSearchable:true, type: 'number'},
  ]
}
