import React, { Component } from 'react';
import { connect } from 'react-redux';

//import FormDialog from './Organization.dialog.view';
import SPT2126Service from './21_26.spt.service';
import authService from '../../../../../services/auth.service';
import * as constant from '../../../../../config/constant';
import ListViewSptRoot from '../../../../../components/entity/ListViewSptRoot';

@connect((state)=> ({
  ...SPT2126Service.stateConnectSetting()(state),
  user: state.authEppt.user,
  orgAccess: state.authEppt.orgAccess,
  company: state.authEppt.currentCompany
}), SPT2126Service.actionConnectSetting())
export default class SPT2126View extends ListViewSptRoot {
  service=SPT2126Service
  FormDialog=()=> <div/>

  addDialog=false
  editDialog=false

  permissionName="SPT_21"
  urlPath="SPT_21"
  formTypeName="PPH 21"
  shortPasalName="21"

  // EPPT CORE
  // fetchOption() {
  //   var userAuth = authService.api.getAuth(this.props.user);
  //   var path = null;
  //   if(userAuth === constant.ROLE_EPPT_OWNER) {
  //     path =  SPT2126Service.path+'/company/page/'+this.props.company.id
  //   }
  //   if(this.props.match.params.companyId) path =  SPT2126Service.path+'/company/page/'+this.props.match.params.companyId;
    
  //   // if(this.props.user.company && this.props.user.company.id) path =  SPT2126Service.path+'/company/page/'+this.props.user.company.id;
  //   return {
  //     path: path
  //   }
  // }
  // EPPT CORE
}
