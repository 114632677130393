import React, {Component} from 'react';
import {connect} from 'react-redux';

import {Button, SVGIcon} from 'react-md';
import ALLBP21FService from './ALL_BP_21_F.service';
import BP21FService from '../BP_21_F/BP_21_F.service';
import ListView from '../../../../../components/entity/listView';
import ColumnService from '../../../../../services/column.service';
import {Switch} from 'react-md';
import iziToast from 'izitoast';
import download from "downloadjs";
import LogExportService from "modules/Main/Export/LogExport.service";
import ParamService from "../../../../../services/param.service";
import {Checkbox, TextfieldMask} from "../../../../../components/form";
import SptService from "modules/Main/pph/21_26/spt/21_26.spt.service";
import moment from "moment";
import {Field} from "redux-form";

@connect(ALLBP21FService.stateConnectSetting(), ALLBP21FService.actionConnectSetting())
export default class ALLBP21FView extends ListView {
  service = ALLBP21FService
  sptService=SptService

  constructor(props) {
    super(props);
    this.state = {
      showTableSetting: false,
      showDialogConfirmDeleteSelected: false,
      showForm: false,
      formData: {},
      data: []
    }
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Nama'
          name='name__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NPWP'
          name='npwp__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Email'
          name='email__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Nomor Bukti Potong'
          name='no__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Nomor Bukti Potong (Dari)'
          name='noNumeric__greaterOrEqualThan'
          className="md-cell md-cell--12"
          // component={this.ig.Textfield}
          component={TextfieldMask}
          maskFormat="#.#-##.##-#######"
        />
        <this.ig.Field
          label='Nomor Bukti Potong (Sampai)'
          name='noNumeric__lessOrEqualThan'
          className="md-cell md-cell--12"
          // component={this.ig.Textfield}
          component={TextfieldMask}
          maskFormat="#.#-##.##-#######"
        />
        <this.ig.Field
          label='NIK'
          name='nik__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NPWP Organisasi'
          name='sptNpwpProfile__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Dari Bulan'
          name='month__greaterOrEqualThan'
          className="md-cell md-cell--6"
          component={this.ig.Searchfield}
          options={[
            {id: 1, name: "1"},
            {id: 2, name: "2"},
            {id: 3, name: "3"},
            {id: 4, name: "4"},
            {id: 5, name: "5"},
            {id: 6, name: "6"},
            {id: 7, name: "7"},
            {id: 8, name: "8"},
            {id: 9, name: "9"},
            {id: 10, name: "10"},
            {id: 11, name: "11"},
            {id: 12, name: "12"}
          ]}
        />
        <this.ig.Field
          label='Sampai Bulan'
          name='month__lessOrEqualThan'
          className="md-cell md-cell--6"
          component={this.ig.Searchfield}
          options={[
            {id: 1, name: "1"},
            {id: 2, name: "2"},
            {id: 3, name: "3"},
            {id: 4, name: "4"},
            {id: 5, name: "5"},
            {id: 6, name: "6"},
            {id: 7, name: "7"},
            {id: 8, name: "8"},
            {id: 9, name: "9"},
            {id: 10, name: "10"},
            {id: 11, name: "11"},
            {id: 12, name: "12"}
          ]}
        />

        <this.ig.Field
          label='Bulan'
          name='month__equals'
          className="md-cell md-cell--12"
          component={this.ig.Searchfield}
          options={[
            {id: 1, name: "1"},
            {id: 2, name: "2"},
            {id: 3, name: "3"},
            {id: 4, name: "4"},
            {id: 5, name: "5"},
            {id: 6, name: "6"},
            {id: 7, name: "7"},
            {id: 8, name: "8"},
            {id: 9, name: "9"},
            {id: 10, name: "10"},
            {id: 11, name: "11"},
            {id: 12, name: "12"}
          ]}
        />

        <this.ig.Field
          label='Tahun'
          name='year__equals'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Pembetulan'
          name='rev__equals'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Kode Pajak'
          name='taxObjectCode__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
      </this.SearchContainer>
    )
  }

  editDialog = false;
  _barActions = [];

  editItem = (item) => {
    // if (this.editDialog) {
    //   this.showFormDialog(item);
    // } else {
    //   if (this.rootPath) {
    //     this.props.history.push(this.rootPath + "/" + item.id)
    //   } else {
    //     this.props.history.push('/' + this.service.name + "/" + item.id);
    //   }
    // }
    this.props.history.push(`/company/${this.props.match.params.companyId}/${this.props.match.params.subscriptionId}/init/ALL_BP_21_F/${item.id}`)
  }

  async handleSendEmail() {
    try {
      let category = "BP_21_F"
      var selected = this.props.table.selected
      var ids = [];
      selected.map(function (i) {
        ids.push(i.id)
      })
      await this.service.api.email(ids, this.props.match.params.companyId)
      iziToast.info({
        title: "Kirim Email",
        message: "email sedang diproses silahkan cek log untuk lebih detil"
      })
    } catch (e) {
      iziToast.warning({
        title: e.name,
        message: e.message
      })
    }
  }

  async handleCetak() {
    this.setState({cetak: true})

    let selected = this.props.table.selected

    if (selected.length > 0) {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export PDF',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: 'Apakah anda yakin akan meng-export PDF data yang diceklis?'
      }));

      if(dialog) {
        let ids = [];

        selected.map(function (i) {
          ids.push(i.id)
        });

        if(ids.length == 1){
          var report = await this.service.api.reportPisah(ids)
          window.open(report.data.url);
        } else if(ids.length > 1 && ids.length <= 20) {
          var report = await this.service.api.report(ids)
          window.open(report.data.url);
        } else {
          let data = {
            pasal: '21',
            category: 'BP_21_F',
            merge: true,
            ids: ids,
          }
          var report = await this.sptService.api.cetakBulkLog(data)

          iziToast.success({
            title: 'Sukses !',
            message: report.data.message,
          });
        }

        this.setState({cetak: false})
      }
    }
    else {
      iziToast.info({
        title: "Info",
        message: "Silahkan pilih data yang akan dicetak."
      })
    }
  }

  async handleCetakPisah() {
    this.setState({cetakPisah: true})

    let selected = this.props.table.selected

    if (selected.length > 0) {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export PDF',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: 'Apakah anda yakin akan meng-export PDF data yang diceklis?'
      }));

      if(dialog) {
        let ids = [];

        selected.map(function (i) {
          ids.push(i.id)
        });

        if(ids.length == 1){
          var report = await this.service.api.reportPisah(ids)
          window.open(report.data.url);
        } else if(ids.length > 1 && ids.length <= 20) {
          var report = await this.service.api.reportPisah(ids)
          window.open(report.data.url);
        } else {
          let data = {
            pasal: '21',
            category: 'BP_21_F',
            merge: false,
            ids: ids,
          }
          var report = await this.sptService.api.cetakBulkLog(data)

          iziToast.success({
            title: 'Sukses !',
            message: report.data.message,
          });
        }

        this.setState({cetakPisah: false})
      }
    } else {
      iziToast.info({
        title: "Info",
        message: "Silahkan pilih data yang akan dicetak."
      })
    }
  }

  async handleCetakMerge() {
    this.setState({cetak: true})

    let selected = this.props.table.selected

    if (selected.length > 0) {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export PDF Merge',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: 'Apakah anda yakin akan meng-export PDF data yang diceklis?'
      }));

      if(dialog) {
        let ids = [];

        selected.map(function (i) {
          ids.push(i.id)
        });

        let res = await LogExportService.api.exportBulkReportMerge('spt21/f', ids);

        let filename = res.headers.filename;

        download(res.data, filename);

        this.setState({cetak: false})
      }
    }
    else {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export PDF Merge',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: (
          <div>
            <this.ig.Field
              label='Nama'
              name='nama__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='NPWP'
              name='npwp__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Email'
              name='email__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Nomor Bukti Potong'
              name='no__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='NIK'
              name='nik__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='NPWP Organisasi'
              name='orgNpwp__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Bulan'
              name='masa__equals'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Tahun'
              name='tahun__equals'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Pembetulan'
              name='pembetulan__equals'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Kode Pajak'
              name='kodeObjekPajak__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
          </div>
        )
      }));

      if (dialog) {
        let param = ParamService.convert(dialog)

        let res = await LogExportService.api.exportByParamReportMerge('spt21/f', param);

        let filename = res.headers.filename;

        download(res.data, filename);

        this.setState({cetak: false})
      }
    }
  }

  async handleExportAll() {
    let selected = this.props.table.selected

    if (selected.length > 0) {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export CSV',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: 'Apakah anda yakin akan meng-export CSV data yang diceklis?'
      }));

      if(dialog) {
        let ids = [];

        selected.map(function (i) {
          ids.push(i.id)
        });

        let res = await this.service.api.export(ids);

        window.open(res.data.url);
      }
    }
    else {
      iziToast.info({
        title: "Info",
        message: "Silahkan pilih data yang akan dicetak."
      })
      // let dialog = await this.context.showDialog((props, res, rej) =>({
      //   title: 'Export CSV',
      //   initialValue: {},
      //   height: 'auto',
      //   width: 400,
      //   okText: 'Ya',
      //   text: (
      //     <div>
      //       <this.ig.Field
      //         label='Nama'
      //         name='nama__contains'
      //         className="md-cell md-cell--12"
      //         component={this.ig.Textfield}
      //       />
      //       <this.ig.Field
      //         label='NPWP'
      //         name='npwp__contains'
      //         className="md-cell md-cell--12"
      //         component={this.ig.Textfield}
      //       />
      //       <this.ig.Field
      //         label='Email'
      //         name='email__contains'
      //         className="md-cell md-cell--12"
      //         component={this.ig.Textfield}
      //       />
      //       <this.ig.Field
      //         label='Nomor Bukti Potong'
      //         name='no__contains'
      //         className="md-cell md-cell--12"
      //         component={this.ig.Textfield}
      //       />
      //       <this.ig.Field
      //         label='NIK'
      //         name='nik__contains'
      //         className="md-cell md-cell--12"
      //         component={this.ig.Textfield}
      //       />
      //       <this.ig.Field
      //         label='NPWP Organisasi'
      //         name='orgNpwp__contains'
      //         className="md-cell md-cell--12"
      //         component={this.ig.Textfield}
      //       />
      //       <this.ig.Field
      //         label='Bulan'
      //         name='masa__equals'
      //         className="md-cell md-cell--12"
      //         component={this.ig.Textfield}
      //       />
      //       <this.ig.Field
      //         label='Tahun'
      //         name='tahun__equals'
      //         className="md-cell md-cell--12"
      //         component={this.ig.Textfield}
      //       />
      //       <this.ig.Field
      //         label='Pembetulan'
      //         name='pembetulan__equals'
      //         className="md-cell md-cell--12"
      //         component={this.ig.Textfield}
      //       />
      //       <this.ig.Field
      //         label='Kode Pajak'
      //         name='kodeObjekPajak__contains'
      //         className="md-cell md-cell--12"
      //         component={this.ig.Textfield}
      //       />
      //     </div>
      //   )
      // }));

      // if (dialog) {
      //   let param = ParamService.convert(dialog)

      //   let res = await LogExportService.api.exportByParamCsv('spt21/f', param);

      //   let filename = res.headers.filename;

      //   download(res.data, filename);
      // }
    }
  }

  barActions() {
    return [
      ...this._barActions,
      {
        label:'word.exportCsv',
        iconClassName:'mdi mdi-file-excel',
        // disabledFunc:() => this.props.table.selected.length === 0,
        onClick:() => this.handleExportAll()
      },
      {
        label:'word.exportPdf',
        iconClassName:'mdi mdi-file-pdf',
        // disabledFunc:() => this.props.table.selected.length === 0,
        onClick:() => this.handleCetak()
      },
      {
        label:'word.exportPdfPisah',
        iconClassName:'mdi mdi-file-pdf',
        // disabledFunc:() => this.props.table.selected.length === 0,
        onClick:() => this.handleCetakPisah()
      },
      {
        label:'word.email',
        iconClassName:'mdi mdi-email',
        disabledFunc:() => this.props.table.selected.length === 0,
        onClick:() => this.handleSendEmail()
      }
    ]
  }

  // columns = [
  //   {isSortable: true, label: "word.npwpProfile", value: "spt21.organization.npwp", show: true, isSearchable: true},
  //   {isSortable: true, label: "word.createdDate", value: "createdDate", type: "date", show: true, isSearchable: true},
  //   {isSortable: true, label: "word.createdBy", value: "createdBy", show: true, isSearchable: true},
  //   {
  //     isSortable: true,
  //     label: "word.year",
  //     searchField: "year",
  //     value: (d) => d.spt21.year,
  //     type: "func",
  //     show: true,
  //     isSearchable: true
  //   },
  //   {isSortable: true, label: "word.month", value: "spt21.month", searchField: "masa", type: "number", show: true, isSearchable: true},
  //   {
  //     isSortable: true,
  //     label: "word.pembetulan",
  //     value: "spt21.pembetulan",
  //     type: "number",
  //     show: true,
  //     isSearchable: true
  //   },
  //   {isSortable: true, label: "word.reference", value: "referensi", show: false, isSearchable: true},
  //   {isSortable: true, label: "word.npwp", value: "bagA.npwp", show: true, isSearchable: true},
  //   {isSortable: true, label: "word.nik", value: "bagA.nik", show: false, isSearchable: true},
  //   {isSortable: true, label: "word.name", value: "bagA.nama", show: true, isSearchable: true},
  //   {isSortable: true, label: "word.email", value: "bagA.email", show: true, isSearchable: true},
  //   {isSortable: true, label: "word.noBp", value: "no", show: true, isSearchable: true},
  //   {isSortable: true, label: "word.date", value: "bagC.tanggal", show: true, isSearchable: true},
  //   {isSortable: true, label: "word.taxCode", value: "bagB.kode", show: true, isSearchable: true},
  //   {isSortable: true, label: "word.bruto", value: "bagB.bruto", show: true, isSearchable: true, type: 'number'},
  //   {isSortable: true, label: "word.pph", value: "bagB.pph", show: true, isSearchable: true, type: 'number'},
  // ]

  defaultColumns = [
    /*{isSortable:true, label: "word.id",  value: "id", show:false, isSearchable:true, isDefaultSort: false},*/
    {isSortable:true, label: "word.createdUsername",  value: "createdUsername", show:true, isSearchable:true},
    {isSortable:true, label: "word.createdBy",  value: "createdBy", show:true, isSearchable:true},
    {isSortable:true, label: "word.createdDate", value: "createdDate", show:true, isSearchable:true, type:"date", isDefaultSort: true},
    {isSortable:true, label: "word.modifiedUsername",  value: "modifiedUsername", show:true, isSearchable:true},
    {isSortable:true, label: "word.lastModifiedBy",  value: "lastModifiedBy", show:true, isSearchable:true},
    {isSortable:true, label: "word.lastModifiedDate", value: (d)=> {
        if(d.lastModifiedDate) {
          return <div className='mpk-font-size-S'>{moment(d.lastModifiedDate).format('lll')}</div>
        } else {
          return null;
        }
      }, show:true, isSearchable:true, type:"func", searchField: 'lastModifiedDate', isDefaultSort: true}
  ]

  columns=[
    {isSortable: true, label: "word.npwpProfile", value:"spt.npwp", searchField: "spt.npwp", show:true, isSearchable:true},
    {isSortable: true, label: "word.year", value: (d)=> d.spt.year, searchField: "spt.year", type: "func", show:true, isSearchable:true},
    {isSortable: true, label: "word.month", value: (d)=> d.spt.month, searchField: "spt.month", type: "func", show:true, isSearchable:true},
    {isSortable: true, label: "word.pembetulan", value: (d)=> d.spt.rev, searchField: "spt.rev", type: "func", show:true, isSearchable:true},
    {isSortable: true, label: "word.reference",  value: "referensi", searchField: "referensi", show:false, isSearchable:true},
    {isSortable: true, label: "word.npwp",  value: "npwp", searchField: "npwp", show:true, isSearchable:true},
    {isSortable: true, label: "word.nik",  value: "nik", searchField: "nik", show:false, isSearchable:true},
    {isSortable: true, label: "word.name",  value: "name", searchField: "name", show:true, isSearchable:true},
    {isSortable: true, label: "word.email",  value: "email", searchField: "email", show:true, isSearchable:true},
    {isSortable: true, label: "word.noBp",  value: "no", searchField: "no", show:true, isSearchable:true},
    {isSortable: true, label: "word.noNumericBp", type: "func",  value: (d) => d.noNumeric, show:false, isSearchable:true},
    {isSortable: true, label: "word.date",  value: "cuttingDate", searchField: "cuttingDate", show:true, isSearchable:true},
    {isSortable: true, label: "word.taxCode",  value: "taxObjectCode", searchField: "taxObjectCode", show:true, isSearchable:true},
    {isSortable: true, label: "word.bruto",  value: "bruto", searchField: "bruto", show:true, isSearchable:true, type: 'number'},
    {isSortable: true, label: "word.pph",  value: "pph", searchField: "pph", show:true, isSearchable:true, type: 'number'},
  ]

  _tableActions = [
    {
      label: "More Info",
      iconClassName: "mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    }
  ]

}
