import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Field, reduxForm } from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../../components/form';

import ListView from '../../../../components/entity/listView';
import axios from 'axios';
import izitoast from 'izitoast';
import download from "downloadjs";
import KeyService from './Key.service';

@reduxForm({form: 'KEY_FORM', destroyOnUnmount: true})
@connect((state)=> ({
  ...KeyService.stateConnectSetting()(state),
  user: state.authEppt.user,
  company: state.authEppt.currentCompany
}),KeyService.actionConnectSetting())
export default class KeyView extends ListView {
  service=KeyService
  translate=false
  FormDialog=()=> <div/>

  titleHeader() {
    var pasal = this.props.match.params.pasal;
    if(pasal === '4a2') pasal = '4 (2)'
    return `Key ${pasal}`;
  }

  fetchOption() {
    console.log(this.props.company)
    var path = '';
    // if(this.props.match.params.pasal == '21') {
    path = `spt${this.props.match.params.pasal}/key/${this.props.company.id}/company`
    // }

    return {
      path
    }
  }

  beforeFetch(params) {
    // params["category.equals"] = "EFILING"
  }


  columns() {
    console.log(this.props.match.params.pasal)
    if(this.props.match.params.pasal === '21') {
      return [
        {label: "word.npwp",  value: "npwp", show:true, isSearchable:true},
        {label: "word.exp",  value: "exp", show:true, isSearchable:true},
        {label: "word.tf",  value: "tf", show:true, isSearchable:true, type: 'number'},
        {label: "word.f",  value: "f", show:true, isSearchable:true,  type: 'number'},
        {label: "word.a1",  value: "a1", show:true, isSearchable:true,  type: 'number'},
        {label: "word.a2",  value: "a2", show:true, isSearchable:true, type: 'number'},
        {label: "word.sm",  value: "sm", show:true, isSearchable:true, type: 'number'}
      ]
    } else if(this.props.match.params.pasal === '23') {
      return [
        {label: "word.npwp",  value: "npwp", show:true, isSearchable:true},
        {label: "word.exp",  value: "exp", show:true, isSearchable:true},
        {label: "word.bp23",  value: "bp23", show:true, isSearchable:true, type: 'number'},
        {label: "word.bp26",  value: "bp26", show:true, isSearchable:true,  type: 'number'},
      ]
    } else if(this.props.match.params.pasal === '4a2') {
      return [
        {label: "word.npwp",  value: "npwp", show:true, isSearchable:true},
        {label: "word.exp",  value: "exp", show:true, isSearchable:true},
        {label: "word.bp4a2",  value: "bp4a2", show:true, isSearchable:true, type: 'number'}
      ]
    } else if(this.props.match.params.pasal === '22') {
      return [
        {label: "word.npwp",  value: "npwp", show:true, isSearchable:true},
        {label: "word.exp",  value: "exp", show:true, isSearchable:true},
        {label: "word.bp22",  value: "bp22", show:true, isSearchable:true, type: 'number'}
      ]
    } else if(this.props.match.params.pasal === '15') {
      return [
        {label: "word.npwp",  value: "npwp", show:true, isSearchable:true},
        {label: "word.exp",  value: "exp", show:true, isSearchable:true},
      ]
    }
  }

  _tableActions = []

  _barActions = [{
    label:'Update Key',
    iconClassName:'mdi mdi-key',
    disabledFunc:() => this.props.table.selected.length === 0,
    onClick: () => this.handleUpdateKey()
  },{
    label:'Refresh',
    iconClassName:'mdi mdi-refresh',
    onClick:() => {

      window.location.hash = window.location.hash.split('?')[0]
      this.fetchData()
    }
  },]

  async handleUpdateKey() {
    var item = this.props.table.selected[0];
    var dialog = await this.context.showDialog((props, res, rej) =>({
      initialValue: {},
      height: 'auto',
      width: 600,
      contentStyle: {padding:0, overflowY: 'hidden'},
      okText: 'Update',
      text: (
        <div>
          <div className='mpk-commandbar mpk-layout align-center justify-between mpk-border bottom solid dark'>
          Update Key
          </div>

          <div className="md-grid">
            <Field
              label='Kode Aktivasi'
              name='activationKey'
              rows={4}
              className="md-cell md-cell--12"
              component={Textfield}
              validate={validation.required}
            />
          </div>
        </div>
      )
    }));

    if(dialog) {
      await this.service.api.topUp(`/api/spt${this.props.match.params.pasal}/key/${item.id}/topup`, {
        key: dialog.activationKey,
        npwp: item.npwp
      })

      izitoast.success({
        title:  'success',
        message: 'Key telah di update'
      })
    }
  }
}
