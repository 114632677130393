import ApiService from '../../../../../../services/api.service';

class AllSsp22PembelianBarangService extends ApiService {
  name= 'AllSsp22PembelianBarang';
  path= 'pasal22/ssp-bendaharawan';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    email: async (data, companyId) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/mail`, data, {
        params: {
          companyId: companyId
        }
      });
      return res;
    },
    report: async (data) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/report`, data);
      return res;
    },
    export: async (data) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/export`, data);
      return res;
    }
  }

}

export default new AllSsp22PembelianBarangService();
