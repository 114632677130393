import React, { Component } from 'react';
import { connect } from 'react-redux';

import FormDialog from './MasterUUPajakPenghasilan.dialog.view';
import MasterUUPajakPenghasilanService from './MasterUUPajakPenghasilan.service';
import ListView from '../../../../components/entity/listView';
import ColumnService from '../../../../services/column.service';

@connect(MasterUUPajakPenghasilanService.stateConnectSetting(), MasterUUPajakPenghasilanService.actionConnectSetting())
export default class MasterUUPajakPenghasilanView extends ListView {
  service=MasterUUPajakPenghasilanService
  FormDialog=FormDialog

  columns=[
    {label: "word.year",  value: function(d){ return ColumnService.money(d.year) }, type: "func", isDefaultSort:true, show:true, isSearchable:true},
  ]
}
