import React from 'react';
import {connect} from 'react-redux';
import {Field, getFormValues, reduxForm} from 'redux-form';
import {DataTable, TableBody, TableColumn, TableHeader, TableRow} from 'react-md';
import FormView from '../../../../../../components/entity/form.view';
import {Datepicker, Textfield, TextfieldMask, validation} from '../../../../../../components/form';
import Tarif15Service from './Tarif15.service';
import iziToast from 'izitoast';


@reduxForm({form: 'Tarif15Form', destroyOnUnmount: true})
@connect(state => ({
  company: state.authEppt.currentCompany,
  formData: getFormValues('Tarif15Form')(state),
  spt: state.authEppt.currentSPT,
}))
export default class Tarif15Form extends FormView {
  service = Tarif15Service
  viewType = 2

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      activeTab: 0
    }
  }

  async handleSave(value) {

    var beforeSave = await this.beforeSave(value);
    var res = null;
    if(beforeSave) {
      if(typeof beforeSave === 'object') value = beforeSave;
      try {
        this.setState({onProgress: true})
        if(value.id) {
          res = await this.service.api.update(value)
        } else {
          res = await this.service.api.save(value)
        }
        this.setState({onProgress: false})
        this.afterSave(res, value)
        iziToast.success({
          title: "Setting Rate",
          message: "berhasil disimpan !"
        })
      } catch(e) {
        var msg = e.message;
        if(e.response) msg = e.response.data.message;
        this.setState({
          isError: true,
          onProgress: false,
          errorMessage: msg
        })
      }
    }
  }

  async componentDidMount() {
    try {
      let res = await this.service.api.getBySPT(this.props.spt.data.id);
      this.props.initialize(res);
    } catch(e){
      console.log(e)
    }
  }

  formView() {
    return (
      <div className="mpk-layout column fill mpk-tab-clear">
        <div className="flex">
          {this.pasal15Form()}
        </div>
      </div>
    )
  }

  pasal15Form() {
    return <div style={{marginBottom: 20}}>
      <div className='md-card md-paper md-paper md-paper--1 md-card md-background--card'>

        <div className="md-grid">
          {/* <Field
            className="md-cell md-cell--6"
            label='Start Date'
            name='start'
            inline={true}
            component={Datepicker}
            validate={validation.required}
          />

          <Field
            className='md-cell md-cell--6'
            label='End Date'
            name='end'
            inline={true}
            component={Datepicker}
            validate={validation.required}
          /> */}

          <Field
            className='md-cell md-cell--12'
            label='Penerbangan Dalam Negeri'
            name='ratesPbDn'
            component={TextfieldMask}
            money     = {','}
            // validate={validation.required}
          />

          <Field
            className='md-cell md-cell--12'
            label='Pelayaran Dalam Negeri'
            name='ratesPlDn'
            component={TextfieldMask}
            money     = {','}
            // validate={validation.required}
          />

          <Field
            className='md-cell md-cell--12'
            label='Pelayaran & Penerbangan Luar Negeri'
            name='ratesPlPbLn'
            component={TextfieldMask}
            money     = {','}
            // validate={validation.required}
          />
        </div>
      </div>
    </div>
  }
}
