import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Field, reduxForm } from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../../../../components/form';

import ListView from '../../../../../../components/entity/listView';
import ColumnService from '../../../../../../services/column.service';
// import ImportService from './../Import/Import.service';
import axios from 'axios';
import izitoast from 'izitoast';
import FormDialog from './ImportSSP4a2.dialog';
import ImportSSP4a2service from './ImportSSP4a2service';
import download from "downloadjs";
import ListViewImport from '../../../../../../components/entity/ListViewImport';

@reduxForm({form: 'ImportSSP4a2Form', destroyOnUnmount: true})
@connect(ImportSSP4a2service.stateConnectSetting(), ImportSSP4a2service.actionConnectSetting())
export default class ImportSSP4a2View extends ListViewImport {
  service=ImportSSP4a2service
  FormDialog=FormDialog
  pasal='pasal4'
  category='SSP'
  importPath='/pasal4/upload/csv/etc'
  showAutomatisTab=false
  showPembetulan=true
  // viewType=2

  beforeFetch(params) {
    params["category.equals"] = "SSP"
  }

  async handleDownloadTemplate() {
    var report = await ImportSSP4a2service.api.downloadTemplate(null, "SSP")
    window.open(report.data.url)
  }

}
