import ApiService from '../../../../services/api.service';

class SettingImportService extends ApiService {
  name= 'SettingImport';
  path= 'setting/import/page';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api = {
    getByCompany: async (companyId)=> {
      return await this.http.get(`${this.apiLocation}/setting/import/company/`+companyId)
    },
    updateByCompany: async (formData)=> {
      return await this.http.put(`${this.apiLocation}/setting/import`, formData)
    }
  }
}

export default new SettingImportService();
