import React from 'react';
import TextField from "react-md/lib/TextFields/TextField";

export default class TextFieldCustom extends React.Component {
  render() {
    let { input, meta: { touched, error }, ...others } = this.props;
    let idProps = Math.random().toString(36).substring(7)+this.props.name;

    if(this.props.meta.error === 'Required') others.label = this.props.label+' (*)'

    var type = "text"
    if(this.props.type){
      this.props.type
    }

    return <TextField id={idProps} type={type} {...input} {...others} error={touched && !!error} errorText={error} />
  }
}
