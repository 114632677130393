import ApiService from '../../../../services/api.service';

class AccessControl extends ApiService {
  name= 'AccessControl';
  path= 'access';

  api= {
    permissions: async () => {
      var res = await this.http.get(this.apiLocation+'/permission')
      return res.data;
    }
  }
  constructor() {
    super()
    this.init();
    this.initApi()
  }
}


export default new AccessControl();
