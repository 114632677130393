import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  Tab,
  Tabs,
  SelectionControlGroup,
  Checkbox,
  Button
} from 'react-md';

import FormView from '../../../../../components/entity/form.view';
import FormViewSpt from '../../../../../components/entity/form.view.spt';
import {validation, Textfield, Searchfield, Switch, Datepicker, TextfieldMask, convert, Searchfieldv2} from '../../../../../components/form';
import { SearchField } from 'react-mpk';
import SettingEmailService from './SettingEmail.service';
// import CompanyService from '../../../Administrator/Company/Company.service';
import iziToast from 'izitoast';
import OrganizationService from '../../../Administrator/Organization/Organization.service';
import CompanyService from './../../../../../services/companyService'

@reduxForm({form: 'SettingEmail_Form', destroyOnUnmount: true})
@connect((state) => ({
  access: state.entity.access.api.find,
  organization: state.authEppt.currentOrganization,
  spt: state.authEppt.currentSPT,
  formData: state.form.SettingEmail_Form,
  user: state.authEppt.user,
  company: state.auth.company,
  users: state.auth.user,
}))
export default class SettingEmailForm extends FormView {
  service=SettingEmailService
  viewType =2;

  constructor(props){
    super(props)
    this.state = {
      ...this.state
    }
  }

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;
    var companyId = null
    if(this.props.company && this.props.company.id){
      companyId = this.props.company.id
    }

    if(companyId){
      return (
        <div>
          <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Save</Button>
        </div>
      )
    } else {
      return (
        <div>
          <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>Back</Button>
          <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Save</Button>
        </div>
      )
    }
  }

  async handleCompany(d){
    let res = await this.service.api.getByCompany(d.id);
    res.data.company = d
    this.props.initialize(res.data);
  }

  async initData() {
    var isSupport = false
    if(this.props.users && this.props.users.role && this.props.users.role.name == "SUPPORT_AGENT") isSupport = true
    if(isSupport){

    } else {
      var companyId = null
      if(this.props.company && this.props.company.id){
        companyId = this.props.company.id
      }
      try {
        let res = await this.service.api.getByCompany(companyId);
        this.props.initialize(res.data);
      } catch (e){
        this.toast.warning({
          title: e.name,
          message: e.message
        })
      }
    }
    
  }

  async handleSave(value) {
    try {
      if(value.port){
        value.port = parseInt(value.port)
      }
      let res = await this.service.api.updateByCompany(value)
      iziToast.success({
        title: "Setting Email",
        message: "Berhasil di simpan !"
      })
    } catch (e){
      iziToast.error({
        title: e.response.data.title,
        message: e.response.data.detail
      })
    }
  }

  formView() {
    var _this = this
    var isSupport = false
    if(this.props.users && this.props.users.role && this.props.users.role.name == "SUPPORT_AGENT") isSupport = true
    return (
      <div>
        <Card>
          {/* <div className='md-grid'>
            <div className="md-cell md-cell--12">
              <Field
                label='Company'
                name='company'
                className="md-cell md-cell--12"
                apiPath="page"
                viewField="nama"
                valueField="parent"
                component={Searchfield}
                service={CompanyService}
                validate={validation.required}
              />
            </div>
          </div> */}
          <div className='md-grid'>
            {isSupport &&
              <Field
                  label         = 'Company'
                  name          = 'company'
                  className     = "md-cell md-cell--12"
                  component     = {Searchfieldv2}
                  valueField    = 'parent'
                  remoteSearch  = {true}
                  apiPath       = 'get'
                  searchField   = {['name']}
                  params        = {{ user: this.props.users }}
                  service       = {CompanyService}
                  validate      = {validation.required}
                  renderFirst   = {true}
                  validate      = {validation.required}
                  coreSearch    = {true}
                  npwpSearch    = {true}
                  itemTemplate  = {function(d) {
                    let nameAndAlias = ""
                    if (d.name)
                      nameAndAlias = d.name
                    if (d.aliasName)
                      nameAndAlias += " - " + d.aliasName
                    return {
                      primaryText  : nameAndAlias,
                      secondaryText: d.npwp,
                      onClick      : async () => {
                        if(!this.props.disabled){
                          this.handleItemClick(d); this.hide()
                          _this.handleCompany(d)
                        }
                      }
                    }
                  }}
              />
            }
            <div className="md-cell md-cell--6">
              <Field
                label='Host'
                name='host'
                component={Textfield}
                validate={validation.required}
              />
              <Field
                label='Port'
                name='port'
                type="number"
                component={Textfield}
                validate={validation.required}
              />
              <Field
                label='Alamat Email'
                name='from'
                component={Textfield}
                validate={validation.required}
              />
              <Field
                label='Protokol'
                name='protocol'
                component={Textfield}
                validate={validation.required}
              />
            </div>

            <div className="md-cell md-cell--6">
              <Field
                label='Username'
                name='username'
                component={Textfield}
              />
              <Field
                label='Password'
                name='password'
                type='password'
                component={Textfield}
              />
              <Field
                label='Enable TLS'
                name='tls'
                component={Switch}
              />
              <Field
                label='Enable Auth'
                name='auth'
                component={Switch}
              />
            </div>
          </div>
        </Card>
        <br/>
      </div>
    )
  }

}
