import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues} from 'redux-form';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox} from '../../../../../components/form';
import UtilService from '../../../../../services/utils.service'
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress
} from 'react-md';

import FormViewSpt from '../../../../../components/entity/form.view.spt';
import NpwpService from './../../NpwpService';
import SSPImportBankDevisaService from './SSPImportBankDevisa.service';
import LawanTransaksiDialog from './../../../../../components/entity/LawanTransaksi.dialog';
import MasterLawanTransaksi22Service from '../../../Master/MasterLawanTransaksi22/MasterLawanTransaksi22.service';
import {terbilang as terbilangService, terbilangAsync22} from '../../../../../services/terbilang.service';

@reduxForm({form: 'SSPImportBankDevisa_Form', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  access      : state.entity.access.api.find,
  organization: state.authEppt.currentOrganization,
  spt         : state.authEppt.currentSPT,
  formData    : getFormValues('SSPImportBankDevisa_Form')(state)
}))
export default class sspOlehBankDevisaSSPImportBankDevisaForm extends FormViewSpt {
  service  = SSPImportBankDevisaService
  viewType = 2;

  typingObj     = null;
  typingTimeout = 1000;


  initialData={
    bagA: {},
    bagB: {bruto: 0},
    bagC: {},
    nopNonApi: 0,
    nopApi: 0,
    pphNonApi: 0,
    pphApi: 0
  }

  constructor(props) {
    super(props);

    this.state= {
      ...this.state,
      showCalculateProgress: false,
      npwpNotValid         : true,
      lkDialog             : false
    }
  }

  async npwpValidation(evt, value){

    if(this.typingObj) clearTimeout(this.typingObj);

    this.typingObj = setTimeout(async ()=> {
      if(value){
        var res = await NpwpService.api.validate22(value)
        if(res.valid){
          this.setState({npwpNotValid: false})
          var lk = await MasterLawanTransaksi22Service.api.find({
            'npwp.equals': value,
            //'organizationId.equals': this.props.spt.data.organization.id
          })

          var formData = {...this.props.formData};

          if(lk.data) {
            if(lk.data.length > 0) {
              var d             = lk.data[0];
              formData = {
                ...formData,
                "npwp"  : d.npwp,
                "nik"   : d.nik,
                "nama"  : d.name,
                "alamat": d.address,
                "email" : d.email
              };
            } else {
              formData.npwp = value
              this.toast.info({title: "Info", message: `Lawan Transaksi dengan npwp ${value} tidak ditemukan`})
            }
          }
          if(formData.npwp === '000000000000000' || formData.npwp === "") formData.non = true;
          console.log('executed', formData)
          this.props.initialize(formData);
        } else {
          this.setState({npwpNotValid: true})
          this.toast.warning({title: "Validasi Npwp", message: "npwp tidak valid"})
        }
      }
    }, this.typingTimeout);
  }

  async beforeSave(value) {
    // if(value.bruto == 0 || value.bruto == '') {
    //   this.toast.warning({title:'Warning', message:'Isi bruto harus lebih besar dari 0'})
    //   return false;
    // }


    var date;
    if(typeof value.tgl === 'string') {
      if(value.tgl.search('/') != -1) {
        date = moment(value.tgl, 'DD/MM/YYYY');
      } else {
        date = moment(value.tgl, 'YYYY-MM-DD');
      }
    } else if(typeof value.tgl === 'object') {
      date = moment(value.tgl)
    }

    // Remapping Objek
    var mapping = {
      "address"         : value.alamat,
      "name"            : value.nama,
      "nopApi"          : value.nopApi ? value.nopApi : 0,
      "nopNonApi"       : value.nopNonApi ? value.nopNonApi : 0,
      "npwp"            : value.npwp,
      "ntpn"            : value.ntpn,
      "pphApi"          : value.pphApi ? value.pphApi : 0,
      "pphNonApi"       : value.pphNonApi ? value.pphNonApi : 0,
      "ref"             : value.referensi,
      "signAddress"     : value.alamatPemotong,
      "signName"        : value.namaPemotong,
      "signNpwp"        : value.npwpPemotong,
      "tgl"             : value.tgl,
      "totalGross"      : value.jmlBruto ? value.jmlBruto : 0,
      "totalPph"        : value.jmlPph ? value.jmlPph : 0,
      "words"           : value.terbilang ? value.terbilang : "-",
      spt               : this.props.spt.data
    }
    
    if(value.id && value.id != "new") mapping.id = value.id
    if(value.consumeId) mapping.consumeId = value.consumeId
    if(typeof mapping.tgl == "object") mapping.tgl = moment(mapping.tgl).format("YYYY-MM-DD")

    value = mapping;
    // Remapping Objek

    // if(!(date.year() == this.props.spt.data.year && date.month()+1 == this.props.spt.data.month)) {
    //   this.toast.warning({title:'Warning', message:'Tanggal bukti potong harus sesuai dengan masa SPT'})
    //   return false;
    // }


    return mapping;
  }

  async calculate(e, v, vp, f, d) {
    if(this.typingObj) clearTimeout(this.typingObj);

    this.typingObj = setTimeout(async ()=> {
      if(v) {
        var form = {...this.props.formData};
        var d    = form;


        if(v) {
          d[f] = v;

          if(!d.nopApi) d.nopApi = 0;
          if(!d.pphApi) d.pphApi = 0;
          if(!d.nopNonApi) d.nopNonApi = 0;
          if(!d.pphNonApi) d.pphNonApi = 0;

          d.nopApi = parseFloat(d.nopApi);
          d.pphApi = parseFloat(d.pphApi);
          d.nopNonApi = parseFloat(d.nopNonApi);
          d.pphNonApi = parseFloat(d.pphNonApi);


          this.props.change('jmlBruto',  d.nopApi+d.nopNonApi);
          this.props.change('jmlPph',  d.pphApi+d.pphNonApi);
          var terbilangRes = '';
          try {
            terbilangRes = await terbilangAsync22(d.pphApi+d.pphNonApi);
          } catch (error) {}

          this.props.change('terbilang', terbilangRes);
        }
      }
    }, this.typingTimeout);

  }

  async initData() {
    var tarif = {};
    if(this.props.match.params.id == 'new') {
      let organization = this.props.organization

      if (!organization) {
        organization = this.props.spt.data.organization
      } else {
        if (!organization.npwp) {
          organization = this.props.spt.data.organization
        }
      }

      this.initialData = {
        ...this.initialData,                        //.data.no,
        namaPemotong   : this.props.organization.name,
        npwpPemotong   : this.props.organization.npwp,
        alamatPemotong : this.props.organization.address,
        spt            : this.props.spt.data,
        jmlPph         : 0,
        tgl            : new Date(),
        jmlBruto       : 0,
        terbilang      : "",
        auto           : true,
        non            : false,

        F113303: {}
      }
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);

      // Remapping Objek
      var data = res.data
      var remapping = {
        id                : data.id,
        alamat            : data.address,
        nama              : data.name,
        nopApi            : data.nopApi,
        nopNonApi         : data.nopNonApi,
        npwp              : data.npwp,
        ntpn              : data.ntpn,
        pphApi            : data.pphApi,
        pphNonApi         : data.pphNonApi,
        referensi         : data.ref,
        alamatPemotong    : data.signAddress,
        namaPemotong      : data.signName,
        npwpPemotong      : data.signNpwp,
        tgl               : data.tgl,
        jmlBruto          : data.totalGross,
        jmlPph            : data.totalPph,
        terbilang         : data.words,
        spt: data.spt
      }
      res.data = remapping;
      // Remapping Objek

      this.props.initialize(res.data);
      // this.npwpValidation(null, res.data.bagA.npwp)
      this.setState({npwpNotValid: false})
    }
  }

  formView() {
    var formData = {bagB: {}};
    if  (this.props.formData) formData = this.props.formData;

    return (
      <div>
        <LawanTransaksiDialog
          spt      = {this.props.spt.data}
          onSelect = {(d)=> {
            var formData = {
              ...this.props.formData,
              "npwp"   : d.npwp,
              "nik"    : d.nik,
              "nama"   : d.name,
              "alamat" : d.address,
              "telepon": d.phone,
              "kodePos": d.postalCode,
              "email"  : d.email
            };
            if(formData.npwp === '000000000000000' || formData.npwp === "") formData.non = true;
            this.props.initialize(formData);

          }}
          service = {MasterLawanTransaksi22Service}
          visible = {this.state.lkDialog}
          onHide  = {()=> this.setState({lkDialog: false})}
        />
        <Card>
          <div className="md-grid">
            <Field
              label     = 'NTPN'
              name      = 'ntpn'
              className = "md-cell md-cell--6"
              component = {Textfield}
              mask="_"
              length={16}
              maxLength={16}
              maskFormat="################"
              validate={validation.required}
            />

            <Field
              label        = 'Tanggal'
              name         = 'tgl'
              className    = "md-cell md-cell--6"
              onDateChange = {async (d, e) => this.changeTarif(d)}
              component    = {Datepicker}
              validate     = {validation.required}
            />

            <Field
              label     = 'Referensi'
              name      = 'referensi'
              id        = "BP21FForm-referensi"
              className = "md-cell md-cell--12"
              component = {Textfield}
            />
          </div>
        </Card>
        <br/>

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='A. LAWAN TRANSAKSI ' />
          <Divider/>
          <div className="md-grid">
            <div className='md-cell md-cell--6'>
            <div className='mpk-layout'>
              <Field
                label      = 'NPWP'
                className  = 'flex'
                name       = 'npwp'
                component  = {TextfieldMask}
                maskFormat = "##.###.###.#-###-###"
                mask       = "_"
                length     = {15}
                onChange   = {this.npwpValidation.bind(this)}
                validate   = {validation.required}
              />
              <Button icon primary swapTheming style={{marginTop: 10}} onClick={()=> this.setState({lkDialog: true})}>search</Button>
              </div>
              <Field
                label     = 'Nama'
                name      = 'nama'
                disabled  = {this.state.npwpNotValid}
                component = {Textfield}
                validate  = {validation.required}
              />
              {/* <Field
                label     = 'NIK'
                name      = 'penerimaPenghasilan.nik'
                disabled  = {this.state.npwpNotValid}
                component = {Textfield}
              />
              <Field
                label     = 'Telepon'
                name      = 'telepon'
                id        = "BP21FForm-name"
                component = {Textfield}
                /> */}

            </div>
            <div className='md-cell md-cell--6'>
              {/* <Field
                label     = 'Kode Poss'
                name      = 'kodePoss'
                id        = "BP21FForm-codePoss"
                component = {Textfield}
              /> */}
              <Field
                label = 'Alamat'
                name  = 'alamat'

                disabled  = {this.state.npwpNotValid}
                component = {Textfield}
                validate  = {validation.required}
              />
              {/* <Field
                label = 'Email'
                name  = 'email'

                disabled  = {this.state.npwpNotValid}
                component = {Textfield}
              /> */}
            </div>
          </div>
        </Card>

        <br/>

        {this.state.showCalculateProgress && <LinearProgress id='lin_pro' style={{margin: 0}} />}
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='B. PPH PASAL 22 YANG DIPUNGUT' />
          <Divider/>
          <div>
            <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
              <TableHeader>
                <TableRow>
                  <TableColumn>No.</TableColumn>
                  <TableColumn>Uraian</TableColumn>
                  <TableColumn className='md-text-right'>Nilai Impor (Rp)</TableColumn>
                  <TableColumn className='md-text-right'>PPH Yang Dipungut (Rp)</TableColumn>
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow>
                  <TableColumn>1.</TableColumn>
                  <TableColumn>Impor Menggunakan API</TableColumn>
                  <TableColumn className='md-text-right'>
                    <Field
                      // label='Bruto'
                      name      = 'nopApi'
                      component = {TextfieldMask}
                      className = 'md-text-right'
                      onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'nopApi')}
                      money     = {','}
                    />
                  </TableColumn>
                  <TableColumn className='md-text-right'>
                    <Field
                      // label='Bruto'
                      name      = 'pphApi'
                      component = {TextfieldMask}
                      className = 'md-text-right'
                      onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'pphApi')}
                      money     = {','}
                    />
                  </TableColumn>
                </TableRow>
                <TableRow>
                  <TableColumn>2.</TableColumn>
                  <TableColumn>Impor Tidak Menggunakan API</TableColumn>
                  <TableColumn className='md-text-right'>
                    <Field
                      // label='Bruto'
                      name      = 'nopNonApi'
                      component = {TextfieldMask}
                      className = 'md-text-right'
                      onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'nopNonApi')}
                      money     = {','}
                    />
                  </TableColumn>
                  <TableColumn className='md-text-right'>
                    <Field
                      // label='Bruto'
                      name      = 'pphNonApi'
                      component = {TextfieldMask}
                      className = 'md-text-right'
                      onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'pphNonApi')}
                      money     = {','}
                    />
                  </TableColumn>
                </TableRow>
                <TableRow>
                  <TableColumn></TableColumn>
                  <TableColumn><b>Total</b></TableColumn>
                  <TableColumn className='md-text-right'>
                    <Field
                      // label='Bruto'
                      name      = 'jmlBruto'
                      component = {TextfieldMask}
                      className = 'md-text-right'
                      disabled
                      // onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'pphNonApi')}
                      money     = {','}
                    />
                  </TableColumn>
                  <TableColumn className='md-text-right'>
                    <Field
                      // label='Bruto'
                      name      = 'jmlPph'
                      component = {TextfieldMask}
                      className = 'md-text-right'
                      disabled
                      // onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'pphNonApi')}
                      money     = {','}
                    />
                  </TableColumn>
                </TableRow>
              </TableBody>
            </DataTable>
          </div>
          {/* {this.jenisFormSwithcer()} */}
        </Card>

        <br/>

        <Card>
          <div className='md-grid'>
            <Field
              label     = 'Nilai Impor (Rp)'
              name      = 'jmlBruto'
              component = {TextfieldMask}
              className = 'md-text-right'
              disabled
              money = {','}
            />
            <Field
              label     = 'PPh yang dipungut'
              name      = 'jmlPph'
              component = {TextfieldMask}
              className = 'md-text-right'
              disabled
              money = {','}
            />
            <Field
              label     = 'Terbilang'
              name      = 'terbilang'
              component = {Textfield}
              disabled
            />
          </div>
        </Card>

        <br />

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='C. WAJIB PAJAK ' />
          <Divider/>

          <div className="md-grid">
          <Field
            label     = 'NPWP'
            name      = 'npwpPemotong'
            className = "md-cell md-cell--6"
            disabled
            component  = {TextfieldMask}
            maskFormat = "##.###.###.#-###-###"
            mask       = "_"
            validate   = {validation.required}
          />
          <Field
            label     = 'Nama'
            name      = 'namaPemotong'
            className = "md-cell md-cell--6"
            disabled
            component = {Textfield}
            validate  = {validation.required}
          />
          <Field
            label     = 'Nama'
            name      = 'alamatPemotong'
            className = "md-cell md-cell--12"
            disabled
            component = {Textfield}
            validate  = {validation.required}
          />


          </div>
        </Card>
      </div>
    )
  }

}
