import ApiService from '../../../../../../services/api.service';

class Master4a2KapService extends ApiService {
  name= 'Master4a2Kap';
  path= 'pasal4/spt/kap';

  constructor() {
    super();
    this.init()
  }
}


export default new Master4a2KapService();

