import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-md';
import LogginInService from './LogginIn.service';
import ListView from '../../../../components/entity/listView';

@connect((state)=> ({
  ...LogginInService.stateConnectSetting()(state),
  user: state.authEppt.user,
  company: state.authEppt.currentCompany,
}), LogginInService.actionConnectSetting())
export default class LogginInView extends ListView {
  service=LogginInService
  editDialog=false

  fetchOption() {

    if(this.props.user.company) {
      return {
        path: 'logged-in/'+this.props.user.company.id+'/company'
      }
    }

    return {
    }
  }

  _barActions = [
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => this.fetchData()
    }
  ]

  _tableActions = [
    {
      label:"Delete",
      iconClassName:"mdi mdi-delete",
      onClick:(item, callback) => this.deleteItem(item, callback),
      confirmation:{
        title:"sentence.custom.deleteItem",
        message:"sentence.custom.deleteItemConfirmation"
      }
    }
  ]

  defaultColumns = []

  columns=[
    {isDefaultSort: true, isSortable: true, label: "word.id", value:'id', searchField: "id", show:false, isSearchable:true},
    {isSortable: true, label: "word.login", value: 'login', isSearchable:true, show: true},
    {isSortable: true, label: "word.company", value: 'companyName', isSearchable:true, show: true},
    {isSortable: true, label: "word.company", value: 'companyId', isSearchable:true, show: false},
    {isSortable: true, label: "word.lastLogin", value: 'lastLogin', type:'date', isSearchable:true, show: true}
    ,
    {isSortable: true, label: "word.clientId", value: 'clientId', isSearchable:true, show: false}
  ]
}
