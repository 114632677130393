import React from 'react';
import { LoaderInfo, rdxConnect, authService } from 'react-mpk';

class IDBill extends React.Component{
  state = {
    isLoading: true
  }

  componentDidMount(){
    let count = 0
    this.tick = setInterval(() => {
      count++;
      if(window.idBill) {
        this.clearTick();
        this.init();
      }
      if(count === 20){
        this.clearTick();
        this.props.toastActions.izi(
          'FAILED', 
          'ID-Bill widget js interface is not installed correctly. Please contact administrator',
          'warning'
        )
      }
    }, 500)
  }

  componentWillUnmount(){
    this.clearTick();
  }

  init = () => {
    let { widgetInterface } = this.props.global;
    this.setState({isLoading: false}, () => {
       window.idBill.setOnMessageCallback((message) => {
        let { type } = message;
        switch(type){
          case 'on-get-new-access-token':
            this.props.auth.getNewAccessToken();
            break;
          default:
          return;
        }
      })

      window.idBill.render('id-bill-container', {
        accessToken: authService.getAccessToken(),
        widgetClientId: this.props.widgetClientId,
        // additionalId: 1,
        // additionalUsername: 'sinatria'
      }, () => {});
    })
  }

  clearTick = () => {
    if(this.tick) clearInterval(this.tick);
  }

  render(){
    return (
      <div id="id-bill-container" className="mpk-full width height">
        {this.state.isLoading && <LoaderInfo/>}
      </div>
    )
  }
}

export default rdxConnect(IDBill);