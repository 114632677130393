import React, { Component } from 'react';
import { connect } from 'react-redux';

import {Field, getFormValues, reduxForm} from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../../../../components/form';

import ListView from '../../../../../../components/entity/listView';
import ColumnService from '../../../../../../services/column.service';
// import ImportService from './../Import/Import.service';
import axios from 'axios';
import izitoast from 'izitoast';
import FormDialog from './ImportBp15.dialog';
import ImportBp15service from './ImportBp15.service';
import download from "downloadjs";
import ListViewImport from '../../../../../../components/entity/ListViewImport';
import ImportBp15Service from "../../../15/Import/ImportBp15/ImportBp15.service";

@reduxForm({form: 'ImportBp15Form', destroyOnUnmount: true})
@connect((state) => ({
  ...ImportBp15Service.stateConnectSetting()(state),
  formData    : getFormValues('ImportBp15Form')(state)
}), ImportBp15Service.actionConnectSetting())
@connect(ImportBp15service.stateConnectSetting(), ImportBp15service.actionConnectSetting())
export default class ImportBp15View extends ListViewImport {
  service=ImportBp15service
  FormDialog=FormDialog
  pasal='pasal15'
  category='BP'
  importPath='/pasal15/upload/csv/bp'
  showAutomatisTab=false
  showFormatEspt=true
  showPembetulan=true
  showPenomoranAuto=true
  // viewType=2

  beforeFetch(params) {
    params["category.equals"] = "BP"
  }

  async beforeSave(value){
    value.numbering = true
    return value
  }

  async handleDownloadTemplate() {
    var report = await ImportBp15service.api.downloadTemplate('BP')
    window.open(report.data.url)
  }

}
