import React, { Component } from 'react'; import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {TabsContainer, Tabs, Tab, Button, DialogContainer} from 'react-md';
import { Field, reduxForm, initialize } from 'redux-form';
import axios from 'axios'
import moment from 'moment'

import LogFileCsvService from './LogFileCsv.service';
import ListView from '../../../../../components/entity/ListViewEbupot';
import AuthService from '../../../../../services/auth.service'
import ListViewEbupot from '../../../../../components/entity/ListViewEbupot';
import OrganizationService from '../Organization/Organization.service'
import {validation, FileInputNext, Searchfieldv2, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Radio, convert, CheckboxSelection} from '../../../../../components/form';
import iziToast from 'izitoast';
import LogFileValidasi from '../LogFileValidasi/LogFileValidasi.view'

@reduxForm({form: 'LogFileCsvForm', destroyOnUnmount: true})
@connect((state)=> ({
  ...LogFileCsvService.stateConnectSetting()(state)
}), LogFileCsvService.actionConnectSetting())
export default class LogFileCsvView extends ListViewEbupot {
  service=LogFileCsvService;
  addDialog=false
  editDialog=false

  constructor(props){
    super(props)
    this.state = {
      ...this.state,
      showLogValidasi: false,
      showLogValidasiParam: null
    }
  }

  addItem = () => {
    var orgId = this.props.match.params.id
    this.props.history.push(orgId+"/new");
  }

  editItem = (item) => {
    this.setState({
      showLogValidasi: true,
      showLogValidasiParam: item
    })
  }

  // fetchOption() {
  //   return {
  //     path: LogFileCsvService.path+'/'+this.props.match.params.id+'/organization'
  //   }
  // }

  beforeFetch(params) {
    delete params.startDate
    delete params.endDate
    delete params.column
    delete params.total
    delete params.keyword
    
    
    // params.sortBy = 'id'
    // params.sort = 1
  }

  hideDialogLogValidasi(){
    this.setState({ showLogValidasi: false })
  }

  belowTopBar(){
    return (
      <DialogContainer
        id="LogFileCsvValidasi"
        visible={this.state.showLogValidasi}
        onHide={this.hideDialogLogValidasi.bind(this)}
        height={500}
        width={800}
      >  
        <LogFileValidasi dialogParam={this.state.showLogValidasiParam} dialogHide={this.hideDialogLogValidasi.bind(this)} />
      </DialogContainer>
    )
  }

  columns=LogFileCsvService.columns

  // _barItem(){
  //   return <div></div>
  // }

  _barActions = [
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => this.fetchData()
    },
  ]

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item),
      ifCon: 'item.success == false'
    },
    {
      label:"Delete",
      iconClassName:"mdi mdi-delete",
      onClick:(item, callback) => this.deleteItem(item, callback),
      confirmation:{
        title:"sentence.custom.deleteItem",
        message:"sentence.custom.deleteItemConfirmation"
      }
    }
  ]

  // yg ditambah
  tabWidth = 280
  tabs= ["import", "search", "column"]

  tabComponents() {
    var obj = this;
    return {
      import: this.import.bind(obj)
    }
  }
   // end yg ditambah

  handleSave(value){
    let formData = new FormData();
    formData.append("file", value.file)
    formData.append("npwp", value.npwp)
    axios.post('/api/ebupot/upload/excel', formData).then(function (res){
      iziToast.success({
        title: "Upload",
        message: "Upload berhasil silahkan klik tombol refresh untuk mengecek log"
      })
    }).catch(function (e){
      iziToast.error({
        title: "Upload",
        message: "Upload gagal " + e.message
      })
    })
  }

  import(){
    const { handleSubmit, submitting, valid, pristine } = this.props;
    return (
        <div className='mpk-layout column fill'>
          <div className='flex'>
            <div className='md-grid'>
              <div className="md-cell md-cell--12">
                <Field
                  label='Select a File'
                  name='file'
                  twoLines
                  component={FileInputNext}
                  validate={validation.required}
                />
              </div>
              <Field
                label     = 'Organization'
                name      = 'npwp'
                className = "md-cell md-cell--12"
                component={Searchfieldv2}
                valueField='npwp'
                viewField='nama'
                service={OrganizationService}
                remoteSearch
                validate={validation.required}
              />
              <div className="md-cell md-cell--12">
                  <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Import</Button>
              </div>
            </div>
          </div>
        </div>
    )
  }

  // NEW TAB MENU
  async hapusPencarian(){
    try {
      try {
        localStorage.removeItem(`search_${this.service.name}`)
      } catch(e){}
      var params = Object.assign({}, params=this.props.table.params)
      this.fetchData(params)
      await this.props.dispatch(initialize(this.service.name+'_search', {}));
    } catch(e){
      console.log(e, "Search Error ...")
    }
  }

  async handleSearching(values){
    try {
      var formData = this.props.tableFilter2
      var params = Object.assign({}, params=this.props.table.params)

      if(formData.originalFilename){
        params['originalFilename.contains'] = formData.originalFilename
      } else { delete params['originalFilename.contains'] }

      if(formData.contentType){
        params['contentType.contains'] = formData.contentType
      } else { delete params['contentType.contains'] }

      if(formData.md5){
        params['md5.contains'] = formData.md5
      } else { delete params['md5.contains'] }

      if(formData.message){
        params['message.contains'] = formData.message
      } else { delete params['message.contains'] }

      if(formData.createdBy){
        params['createdBy.contains'] = formData.createdBy
      } else { delete params['createdBy.contains'] }

      if(formData.lastModifiedBy){
        params['lastModifiedBy.contains'] = formData.lastModifiedBy
      } else { delete params['lastModifiedBy.contains'] }

      if(formData.createdDate1){
        params['createdDate.greaterOrEqualThan'] = moment(formData.createdDate1).format()
      } else { delete params['createdDate.greaterOrEqualThan'] }

      if(formData.createdDate2){
        params['createdDate.lessOrEqualThan'] = moment(formData.createdDate2).format()
      } else { delete params['createdDate.lessOrEqualThan'] }

      if(formData.lastModifiedDate1){
        params['lastModifiedDate.greaterOrEqualThan'] = moment(formData.lastModifiedDate1).format()
      } else { delete params['lastModifiedDate.greaterOrEqualThan'] }

      if(formData.lastModifiedDate2){
        params['lastModifiedDate.lessOrEqualThan'] = moment(formData.lastModifiedDate2).format()
      } else { delete params['lastModifiedDate.lessOrEqualThan'] }
      params.onSearch = true
      localStorage.setItem("formData_search", JSON.stringify(formData))
      this.fetchData(params)
    } catch(e){
      iziToast.info({
        title: "Pencarian",
        message: "Data tidak ditemukan, " + e.message
      })
    }
  }

  defaultSearchForm(){
    const { handleSubmit, submitting, valid, pristine } = this.props;
    return (
      <div className="md-grid" style={{ overflow: 'auto' }}>
        <Field
          label     = 'Filename'
          name      = 'originalFilename'
          className = "md-cell md-cell--12"
          component = {Textfield}
        />
        <Field
          label     = 'Tipe Konten'
          name      = 'contentType'
          className = "md-cell md-cell--12"
          component = {Textfield}
        />
        <Field
          label     = 'MD5'
          name      = 'md5'
          className = "md-cell md-cell--12"
          component = {Textfield}
        />
        <Field
          label     = 'Message'
          name      = 'message'
          className = "md-cell md-cell--12"
          component = {Textfield}
        />
        <Field
          label     = 'Dibuat Oleh'
          name      = 'createdBy'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'Diubah Oleh'
          name      = 'lastModifiedBy'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'Dibuat dari'
          name      = 'createdDate1'
          className = "md-cell md-cell--12"
          component = {Datepicker}
        />
        <Field
          label     = 'Dibuat ke'
          name      = 'createdDate2'
          className = "md-cell md-cell--12"
          component = {Datepicker}
        />
        <Field
          label     = 'Diubah dari'
          name      = 'lastModifiedDate1'
          className = "md-cell md-cell--12"
          component = {Datepicker}
        />
        <Field
          label     = 'Diubah ke'
          name      = 'lastModifiedDate2'
          className = "md-cell md-cell--12"
          component = {Datepicker}
        />
        <Button raised primary className="md-cell md-cell--12" onClick={handleSubmit(this.handleSearching.bind(this))}>Cari</Button>
        <hr />
        <Button raised primary className="md-cell md-cell--12" onClick={handleSubmit(this.hapusPencarian.bind(this))}>Hapus</Button>
      </div>
    )
  }
  // yg ditambah
  // NEW TAB MENU

}