/**
 * Created by dwiargo on 11/29/17.
 */

import React from 'react';
import { Route, Switch } from 'react-router-dom';

import SPT23MainView            from "./../Main/Modules/EbupotModule/PPh/SPT23In/main";
import SPT23FormView            from './../Main/Modules/EbupotModule/PPh/SPT23/SPT23.form.view';
import SPT23View                from './../Main/Modules/EbupotModule/PPh/SPT23/SPT23.view';
import UserFormView             from './../Main/Modules/EbupotModule/User/User.form.view';
import UserView                 from './../Main/Modules/EbupotModule/User/User.view';
import ApplicationFormView      from './../Main/Modules/EbupotModule/Application/Application.form.view';
import ApplicationView          from './../Main/Modules/EbupotModule/Application/Application.view';
import ApplicationEmailFormView from './../Main/Modules/EbupotModule/ApplicationEmail/ApplicationEmail.form.view';
import ClientView               from './../Main/Modules/EbupotModule/Client/Client.view';
import OrganizationFormView     from './../Main/Modules/EbupotModule/Organization/Organization.form.view';
import OrganizationView         from './../Main/Modules/EbupotModule/Organization/Organization.view';
// import OrganizationUserView     from './Administrator/Organization/UserEbupot/OrganizationUserEbupot.view';
// import OrganizationUserForm     from './Administrator/Organization/UserEbupot/OrganizationUserEbupot.form';
import LogSubmitFormView        from './../Main/Modules/EbupotModule/LogSubmit/LogSubmit.form.view';
import LogSubmitView            from './../Main/Modules/EbupotModule/LogSubmit/LogSubmit.view';
import LogCheckFormView         from './../Main/Modules/EbupotModule/LogCheck/LogCheck.form.view';
import LogCheckView             from './../Main/Modules/EbupotModule/LogCheck/LogCheck.view';
import LogFileValidasiView      from './../Main/Modules/EbupotModule/LogFileValidasi/LogFileValidasi.view';
import LogFileCsvView           from './../Main/Modules/EbupotModule/LogFileCsv/LogFileCsv.view';
import LogFileView              from './../Main/Modules/EbupotModule/LogFile/LogFile.view';
import SignerFormView           from './../Main/Modules/EbupotModule/Signer/Signer.form.view';
import SignerView               from './../Main/Modules/EbupotModule/Signer/Signer.view';
import KodeNegaraFormView       from './../Main/Modules/EbupotModule/KodeNegara/KodeNegara.form.view';
import KodeNegaraView           from './../Main/Modules/EbupotModule/KodeNegara/KodeNegara.view';
import ProvincesFormView        from './../Main/Modules/EbupotModule/Provinces/Provinces.form.view';
import ProvincesView            from './../Main/Modules/EbupotModule/Provinces/Provinces.view';
import KodeBuktiPotongFormView  from './../Main/Modules/EbupotModule/KodeBuktiPotong/KodeBuktiPotong.form.view';
import KodeBuktiPotongView      from './../Main/Modules/EbupotModule/KodeBuktiPotong/KodeBuktiPotong.view';
import JenisDokumenFormView     from './../Main/Modules/EbupotModule/JenisDokumen/JenisDokumen.form.view';
import JenisDokumenView         from './../Main/Modules/EbupotModule/JenisDokumen/JenisDokumen.view';
import RegencyFormView          from './../Main/Modules/EbupotModule/Regencies/Regencies.form.view';
import RegencyView              from './../Main/Modules/EbupotModule/Regencies/Regencies.view';
import DistrictFormView         from './../Main/Modules/EbupotModule/Districts/Districts.form.view';
import DistrictView             from './../Main/Modules/EbupotModule/Districts/Districts.view';
import VillagesFormView         from './../Main/Modules/EbupotModule/Villages/Villages.form.view';
// const AsyncHome = asyncComponent(() => import("./Dashboard/Blank"));
import VillageView              from './../Main/Modules/EbupotModule/Villages/Villages.view';
import KodeBuktiPotong26View    from './../Main/Modules/EbupotModule/KodeBuktiPotong26/KodeBuktiPotong.view';
import AllEbupot23View          from './../Main/Modules/EbupotModule/AllEbupot23/AllEbupot23.view';
import AllEbupot26View          from './../Main/Modules/EbupotModule/AllEbupot26/AllEbupot26.view';
import Bupot23FormView          from './../Main/Modules/EbupotModule/Bupot23/Bupot23.form.view';
import Bupot26FormViewView      from './../Main/Modules/EbupotModule/Bupot26/Bupot26.form.view';
import Bupot23View              from './../Main/Modules/EbupotModule/Bupot23/Bupot23.view';
import Bupot26View              from './../Main/Modules/EbupotModule/Bupot26/Bupot26.view';
import Bupot23HistoryView       from './../Main/Modules/EbupotModule/Bupot23History/Bupot23History.view';
import Bupot26HistoryView       from './../Main/Modules/EbupotModule/Bupot26History/Bupot26History.view';
import Bupot23HistoryFormView   from './../Main/Modules/EbupotModule/Bupot23History/Bupot23History.form.view';
import Bupot26HistoryFormView   from './../Main/Modules/EbupotModule/Bupot26History/Bupot26History.form.view';
import Bupot23EmailView         from './../Main/Modules/EbupotModule/Bupot23Email/Bupot23Email.view';
import Bupot26EmailView         from './../Main/Modules/EbupotModule/Bupot26Email/Bupot26Email.view';
import Bupot23EmailFormView     from './../Main/Modules/EbupotModule/Bupot23Email/Bupot23Email.form.view';
import Bupot26EmailFormView     from './../Main/Modules/EbupotModule/Bupot26Email/Bupot26Email.form.view';
import LawanTransaksi26FormView from './../Main/Modules/EbupotModule/LawanTransaksi26/LawanTransaksi26.form.view';
import LawanTransaksi26View     from './../Main/Modules/EbupotModule/LawanTransaksi26/LawanTransaksi26.view';
import LawanTransaksi23FormView from './../Main/Modules/EbupotModule/LawanTransaksi23/LawanTransaksi23.form.view';
import LawanTransaksi23View     from './../Main/Modules/EbupotModule/LawanTransaksi23/LawanTransaksi23.view';
import Dashboard                from './../Main/Modules/EbupotModule/Dashboard/Blank';
import BpeView                  from './../Main/Modules/EbupotModule/PPh/Bpe/Bpe.view';
import BpeFormView              from './../Main/Modules/EbupotModule/PPh/Bpe/Bpe.form.view';
import SPT23HistoryView         from './../Main/Modules/EbupotModule/PPh/SPT23History/SPT23History.view';
import SPT23HistoryFormView     from './../Main/Modules/EbupotModule/PPh/SPT23History/SPT23History.form.view';


import Bp23EditFormView         from './../Main/Modules/EbupotModule/PPh/SPT23In/Bupot23InEdit/Bupot23InEdit.form.view';
import Bp23EditView             from './../Main/Modules/EbupotModule/PPh/SPT23In/Bupot23InEdit/Bupot23InEdit.view';
import Bp23PembetulanFormView   from './../Main/Modules/EbupotModule/PPh/SPT23In/Bupot23InPembetulan/Bupot23InPembetulan.form.view';
import Bp23PembetulanView       from './../Main/Modules/EbupotModule/PPh/SPT23In/Bupot23InPembetulan/Bupot23InPembetulan.view';
import Bp26EditFormView         from './../Main/Modules/EbupotModule/PPh/SPT23In/Bupot26InEdit/Bupot26InEdit.form.view';
import Bp26EditView             from './../Main/Modules/EbupotModule/PPh/SPT23In/Bupot26InEdit/Bupot26InEdit.view';
import Bp26PembetulanFormView   from './../Main/Modules/EbupotModule/PPh/SPT23In/Bupot26InPembetulan/Bupot26InPembetulan.form.view';
import Bp26PembetulanView       from './../Main/Modules/EbupotModule/PPh/SPT23In/Bupot26InPembetulan/Bupot26InPembetulan.view';

import LogExportView            from './../Main/Export/LogExport.view';
import SummaryForm              from './../Main/Summary/Summary.form';
import EbillingWidget           from './../Main/EbillingWidget/EbillingWidget';
import LogExportCsvView         from './../Main/Merge/LogExportCsv/LogExportCsvAll.view';
import LogExportPdfView         from './../Main/Merge/LogExportPdf/LogExportPdfAll.view';
import LogExportPdfChecklistView         from './../Main/Merge/LogExportPdfChecklist/LogExportPdfChecklistAll.view';
import LogSummaryView         from './../Main/Merge/LogSummary/LogSummaryAll.view';

export default (actions, global) => (
  <Switch>
      <Route path="/main-support/enable-deposito" exact component={require('./../Main/pph/21_26/UserAccess/PPh4a2EnableDeposito.view').default} />
      <Route path="/main-support/access-control" exact component={require('./../Main/pph/21_26/UserAccess/UserAccess.view').default} />
      <Route path="/main-support/access-control/:id" exact component={require('./../Main/pph/21_26/UserAccess/UserAccess.form.view').default} />

      <Route path="/main-support/block-buni" exact component={require('./../Main/pph/21_26/BlokBunifikasi/BlokBunifikasi.form.view').default} />
      <Route path="/main-support/block-buni/:id" exact component={require('./../Main/pph/21_26/BlokBunifikasi/BlokBunifikasi.form.view').default} />

      <Route path={`/main-support/LOG_EXPORT_CSV`} component={LogExportCsvView} />
      <Route path={`/main-support/LOG_EXPORT_PDF`} component={LogExportPdfView} />
      <Route path={`/main-support/LOG_EXPORT_PDF_CHECKLIST`} component={LogExportPdfChecklistView} />
      <Route path={`/main-support/LOG_SUMMARY`} component={LogSummaryView} />
      <Route path={`/main-support/ebilling-widget`} component={EbillingWidget} />
      <Route path={`/main-support/LOG_EMAIL`} component={require('./../Main/pph/21_26/Email21Log/Email21Log.view').default} />
      <Route path={`/main-support/EFILING_LOG`} exact component={require('./../Main/Efiling/LogEfiling/LogEfiling.view').default}/>
      <Route path={`/main-support/EFILING_LOG4`} exact component={require('./../Main/Efiling/LogEfiling4/LogEfiling4.view').default}/>
      <Route path={`/main-support/spt-1771`} component={require('./../Main/Spt1771/Spt1771Widget').default} />
      <Route path={`/main-support/tarra-lapor`} component={require('./../Main/TarraLapor/TarraLapor.view').default} />
      <Route path={`/main-support/summary-all`} component={require('./../Main/Summary/Summary.view').default} />
      <Route path={`/main-support/summary/:pasal`} render={(props) => ( <SummaryForm key={props.match.params.pasal} {...props} /> )} />
      <Route path={`/main-support/lawan-transaksi-all`} component={require('./../Main/Merge/LawanTransaksiAll.view').default} />
      <Route path={`/main-support/signer-all`} component={require('./../Main/Merge/SignerAll.view').default} />
      <Route path={`/main-support/signer-image-all`} component={require('./../Main/Merge/SignerImageAll.view').default} />
      <Route path={`/main-support/import-all`} component={require('./../Main/Merge/ImportAll.view').default} />

      {/* SETTING */}
      <Route path={`/main-support/SETTING_EMAIL`} component={require('./../Main/pph/21_26/SettingEmail/SettingEmail.view').default} />
      <Route path={`/main-support/SETTING_EMAIL_CONTENT`} component={require('./../Main/Setting/SettingEmailContent/SettingEmailContent.view').default} />
      <Route path={`/main-support/SETTING_EMAIL_SCHEDULE`} component={require('./../Main/Setting/SettingEmailSchedule/SettingEmailSchedule.view').default} />
      <Route path={`/main-support/SETTING_IMPORT`} component={require('./../Main/Setting/SettingImport/SettingImport.view').default} />
      <Route path={`/main-support/SETTING_EXPORT`} component={require('./../Main/Setting/SettingExport/SettingExport.view').default} />

      <Route path={`/main-support/SETTING_EMAIL/:companyId`} component={require('./../Main/pph/21_26/SettingEmail/SettingEmail.view').default} />
      <Route path={`/main-support/SETTING_EMAIL_CONTENT/:companyId`} component={require('./../Main/Setting/SettingEmailContent/SettingEmailContent.view').default} />
      <Route path={`/main-support/SETTING_EMAIL_SCHEDULE/:companyId`} component={require('./../Main/Setting/SettingEmailSchedule/SettingEmailSchedule.view').default} />
      <Route path={`/main-support/SETTING_IMPORT/:companyId`} component={require('./../Main/Setting/SettingImport/SettingImport.view').default} />
      <Route path={`/main-support/SETTING_EXPORT/:companyId`} component={require('./../Main/Setting/SettingExport/SettingExport.view').default} />

      <Route path={`/main-support/SETTING_EMAIL_ALL`} component={require('./../Main/pph/21_26/SettingEmail/SettingEmail.view.all').default} />
      <Route path={`/main-support/SETTING_EMAIL_CONTENT_ALL`} component={require('./../Main/Setting/SettingEmailContent/SettingEmailContent.view.all').default} />
      <Route path={`/main-support/SETTING_EMAIL_SCHEDULE_ALL`} component={require('./../Main/Setting/SettingEmailSchedule/SettingEmailSchedule.view.all').default} />
      <Route path={`/main-support/SETTING_IMPORT_ALL`} component={require('./../Main/Setting/SettingImport/SettingImport.view.all').default} />
      <Route path={`/main-support/SETTING_EXPORT_ALL`} component={require('./../Main/Setting/SettingExport/SettingExport.view.all').default} />

      <Route path={"/main-support/logged-in"} component={require('./../Main/Setting/LogginIn/LogginIn.view').default} />
      {/* END SETTING */}

      {/* Ebilling */}
      <Route path={`/main-support/EBILLING/SETTING`} exact component={require('./../Main/Ebilling/SettingEbilling/SettingEbilling.view').default} />
      <Route path={`/main-support/EBILLING/IMPORT/INQUIRY`} exact component={require('./../Main/Ebilling/ImportInquiry/ImportInquiry.view').default}/>
      <Route path={`/main-support/EBILLING/INQUIRY/:id`} component={require('./../Main/Ebilling/DataInquiry/DataInquiry.form').default}/>
      <Route path={`/main-support/EBILLING/INQUIRY`} exact component={require('./../Main/Ebilling/DataInquiry/DataInquiry.view').default}/>
      <Route path={`/main-support/EBILLING_DataESSP`} exact component={require('./../Main/Ebilling/DataESSP/DataESSP.view').default} />
      <Route path={`/main-support/EBILLING/DataESSP/hitung`} exact component={require('./../Main/Ebilling/DataESSP/DataESSPHitung.form').default} />
      <Route path={`/main-support/EBILLING/DataESSP/hitung/ebupot`} exact component={require('./../Main/Ebilling/DataESSP/DataESSPHitungEbupot.form').default} />
      <Route path={`/main-support/EBILLING/DataESSP/duplicate`} exact component={require('./../Main/Ebilling/DataESSP/DataESSPDuplicate.view').default} />
      <Route path={`/main-support/EBILLING/DataESSP4`} exact component={require('./../Main/Ebilling/DataESSP4/DataESSP4.view').default} />
      <Route path={`/main-support/EBILLING/DataESSP4/hitung`} exact component={require('./../Main/Ebilling/DataESSP4/DataESSPHitung4.form').default} />
      <Route path={`/main-support/EBILLING/DataESSP4/duplicate`} exact component={require('./../Main/Ebilling/DataESSP4/DataESSPDuplicate4.view').default} />
      <Route path={`/main-support/EBILLING/DataESSP23`} exact component={require('./../Main/Ebilling/DataESSP23/DataESSP23.view').default} />
      <Route path={`/main-support/EBILLING/DataESSP23/hitung`} exact component={require('./../Main/Ebilling/DataESSP23/DataESSPHitung23.form').default} />
      <Route path={`/main-support/EBILLING/DataESSP23/duplicate`} exact component={require('./../Main/Ebilling/DataESSP23/DataESSPDuplicate23.view').default} />
      <Route path={`/main-support/EBILLING/paymentOrder/bniDirect`} exact component={require('./../Main/Ebilling/PaymentOrder/BniDirect.form').default}/>
      <Route path={`/main-support/EBILLING/paymentOrder/briCms`} exact component={require('./../Main/Ebilling/PaymentOrder/BriCms.form').default}/>
      <Route path={`/main-support/EBILLING/paymentOrder/mandiriCm`} exact component={require('./../Main/Ebilling/PaymentOrder/MandiriCm.form').default}/>
      <Route path={`/main-support/EBILLING/paymentOrder/:id`} exact component={require('./../Main/Ebilling/PaymentOrder/PaymentOrder.form').default}/>
      <Route path={`/main-support/EBILLING_paymentOrder`} exact component={require('./../Main/Ebilling/PaymentOrder/PaymentOrder.view').default}/>
      <Route path={`/main-support/EBILLING_akunPembayaran`} exact component={require('./../Main/Ebilling/AkunPembayaran/AkunPembayaran.view').default}/>
      <Route path={`/main-support/EBILLING/paymentNotification/:id/orderFile`} exact component={require('./../Main/Ebilling/PaymentNotification/PaymentNotification.orderFile.form').default}/>
      <Route path={`/main-support/EBILLING/paymentNotification/:id/bpn`} exact component={require('./../Main/Ebilling/PaymentNotification/PaymentNotification.bpn.form').default}/>
      <Route path={`/main-support/EBILLING/paymentNotification/:id`} exact component={require('./../Main/Ebilling/PaymentNotification/PaymentNotification.detail.view').default}/>
      <Route path={`/main-support/EBILLING_paymentNotification`} exact component={require('./../Main/Ebilling/PaymentNotification/PaymentNotification.view').default}/>
      <Route path={`/main-support/EBILLING/IMPORT/ESSP`} exact component={require('./../Main/Ebilling/ImportEssp/ImportEssp.view').default}/>

      <Route path={"/main-support/master/pasal-21-pasal-17-berlapis"} exact component={require('./../Main/pph/21_26/Master21Pasal17Global/Master21Pasal17Global.view').default} />
      <Route path={"/main-support/master/pasal-21-pasal-17-berlapis/:id"} exact component={require('./../Main/pph/21_26/Master21Pasal17Global/Master21Pasal17Global.form.view').default} />
      <Route path={"/main-support/master/pasal-21-pasal-17-berlapis/:pasal17Id/progresif"} exact component={require('./../Main/pph/21_26/Master21Pasal17Global/Master21Pasal17ProgGlobal.view').default} />
      <Route path={"/main-support/master/pasal-21-pasal-17-berlapis/:pasal17Id/progresif/:id"} exact component={require('./../Main/pph/21_26/Master21Pasal17Global/Master21Pasal17ProgGlobal.form.view').default} />
      <Route path={"/main-support/master/pasal-21-biaya-jabatan"} exact component={require('./../Main/pph/21_26/Master21BiayaJabatanGlobal/Master21BiayaJabatanGlobal.view').default} />
      <Route path={"/main-support/master/pasal-21-biaya-pensiun"} exact component={require('./../Main/pph/21_26/Master21BiayaPensiunGlobal/Master21BiayaPensiunGlobal.view').default} />
      <Route path={"/main-support/master/pasal-21-ptkp"} exact component={require('./../Main/pph/21_26/Master21PtkpGlobal/Master21PtkpGlobal.view').default} />
      <Route path={"/main-support/master/pasal-21-pensiun"} exact component={require('./../Main/pph/21_26/Master21PensiunGlobal/Master21PensiunGlobal.view').default} />
      <Route path={`/main-support/master/pasal-21-upah-harian`} exact component={require('./../Main/pph/21_26/Master21UpahHarianGlobal/Master21UpahHarianGlobal.view').default} />
      <Route path={`/main-support/master/pasal-21-upah-kumulatif`} exact component={require('./../Main/pph/21_26/Master21UpahKumulatifGlobal/Master21UpahKumulatifGlobal.view').default} />
      <Route path={"/main-support/master/pasal-21-final-pesangon"} exact component={require('./../Main/pph/21_26/Master21FinalPesangonGlobal/Master21FinalPesangonGlobal.view').default} />
      <Route path={"/main-support/master/pasal-21-final-pesangon/:id"} exact component={require('./../Main/pph/21_26/Master21FinalPesangonGlobal/Master21FinalPesangonGlobal.form.view').default} />
      <Route path={"/main-support/master/pasal-21-final-pesangon/:pesangonId/progresif"} exact component={require('./../Main/pph/21_26/Master21FinalPesangonGlobal/Master21FinalPesangonProgGlobal.view').default} />
      <Route path={"/main-support/master/pasal-21-final-pesangon/:pesangonId/progresif/:id"} exact component={require('./../Main/pph/21_26/Master21FinalPesangonGlobal/Master21FinalPesangonProgGlobal.form.view').default} />
      <Route path={"/main-support/master/pasal-21-honor-pns"} exact component={require('./../Main/pph/21_26/Master21HonorPnsGlobal/Master21HonorPnsGlobal.view').default} />

      {/* Log Ebilling */}
      <Route path={`/main-support/LogExportDataInquiry`} exact component={require('./../Main/Ebilling/log/LogExportDataInquiry/LogExportDataInquiry.view').default}/>
      <Route path={`/main-support/LogExportDataSSP`} exact component={require('./../Main/Ebilling/log/LogExportDataSSP/LogExportDataSSP.view').default}/>
      <Route path={`/main-support/LogCetakPaymentOrder`} exact component={require('./../Main/Ebilling/log/LogCetakPaymentOrder/LogCetakPaymentOrder.view').default}/>
      <Route path={`/main-support/LogCetakPaymenNotification`} exact component={require('./../Main/Ebilling/log/LogCetakPaymentNotification/LogCetakPaymentNotification.view').default}/>
      <Route path={`/main-support/LogCetakBPN`} exact component={require('./../Main/Ebilling/log/LogCetakBPN/LogCetakBPN.view').default}/>
      <Route path={`/main-support/LogCetakLaporanPO`} exact component={require('./../Main/Ebilling/log/LogCetakLaporanPO/LogCetakLaporanPO.view').default}/>
      <Route path={`/main-support/LogCetakLaporanBPN`} exact component={require('./../Main/Ebilling/log/LogCetakLaporanBPN/LogCetakLaporanBPN.view').default}/>
      {/*<Route path={`/main-support/LogCetakLaporanPN`} exact component={require('./../Main/Ebilling/log/LogCetakLaporanPN/LogCetakLaporanPN.view').default}/>*/}

      {/* 25 */}
      <Route path={`/main-support/SPT_25`} exact component={require('./../Main/pph/25/SPT/SPT25.view').default}/>
      <Route path={`/main-support/SPT_25/:sptId`} exact component={require('./../Main/pph/25/SPT/SPT25.form').default}/>
      <Route path={`/main-support/SPT_25/:sptId/open`} component={require('./../Main/pph/25/main').default}/>
      <Route path={`/main-support/AllSSP25`} component={require('./../Main/pph/25/All/All_SSP25/AllSSP25.view').default}/>
      <Route path={`/main-support/AllBP25`} component={require('./../Main/pph/25/All/All_BP_25/AllBP25.view').default}/>

      {/* 25 SPT In */}
      <Route path={`/main-support/SPT_25/:sptId/EXPORT_CSV_25`} component={require('./../Main/pph/25/Export/ExportCsv25/ExportCsv25.view').default} />
      <Route path={`/main-support/SPT_25/:sptId/BP_25/:id`} component={require('./../Main/pph/25/BP_25/BP_25.form').default} />
      <Route path={`/main-support/SPT_25/:sptId/BP_25`} component={require('./../Main/pph/25/BP_25/BP_25.view').default} />
      <Route path={`/main-support/SPT_25/:sptId/SSP25/:id`} component={require('./../Main/pph/25/SSP/SSP25.form').default} />
      <Route path={`/main-support/SPT_25/:sptId/SSP25`} component={require('./../Main/pph/25/SSP/SSP25.view').default} />

      {/* 15 */}
      <Route path={`/main-support/SPT_15`} exact component={require('./../Main/pph/15/SPT/Spt15.view').default}/>
      <Route path={`/main-support/SPT_15/:sptId`} exact component={require('./../Main/pph/15/SPT/Spt15.form').default}/>
      <Route path={`/main-support/SPT_15/:sptId/open`} component={require('./../Main/pph/15/main').default}/>
      <Route path={`/main-support/SIGNER_15`} component={require('./../Main/pph/15/SPT15Signer/SPT15Signer.view').default} />
      <Route path={`/main-support/SIGNER_15_IMAGE`} component={require('./../Main/Setting/FileUpload15/FileUpload15.view').default} />
      <Route path={`/main-support/IMPORT/BP_15`} component={require('./../Main/pph/15/Import/ImportBp15/ImportBp15.view').default} />
      <Route path={`/main-support/IMPORT/BP_24`} component={require('./../Main/pph/15/Import/ImportBp24/ImportBp24.view').default} />
      <Route path={`/main-support/IMPORT/SSP_15_SETOR_SENDIRI`} component={require('./../Main/pph/15/Import/ImportSSPSetorSendiri/ImportSSP15SetorSendiri.view').default} />
      <Route path={`/main-support/IMPORT/SSP_15`} component={require('./../Main/pph/15/Import/ImportSSP/ImportSSP15.view').default} />
      <Route path={`/main-support/IMPORT/PBK_15`} component={require('./../Main/pph/15/Import/ImportPBK/ImportPBK15.view').default} />
      <Route path={`/main-support/IMPORT/LK_15`} component={require('./../Main/pph/15/Import/ImportLK/ImportLK15.view').default} />
      <Route path={`/main-support/ALLBP15DipotongPihakLain`} component={require('./../Main/pph/15/All/AllDipotongPihakLain/AllBP15DipotongPihakLain.view').default} />
      <Route path={`/main-support/ALLBP15PihakLainDipotong`} component={require('./../Main/pph/15/All/AllPihakLainDipotong/AllBP15PihakLainDipotong.view').default} />
      <Route path={`/main-support/ALLBP24`} component={require('./../Main/pph/15/All/AllPasal24/AllBP15Pasal24.view').default} />
      <Route path={`/main-support/ALLSSP15`} component={require('./../Main/pph/15/All/AllSsp/AllSsp15.view').default} />
      <Route path={`/main-support/ALLSSP15SETORSENDIRI`} component={require('./../Main/pph/15/All/AllSsp15SetorSendiri/AllSsp15SetorSendiri.view').default} />

      {/* 15 Spt In */}
      <Route path={`/main-support/SPT_15/:sptId/SSP_15_Sendiri/:id`} component={require('./../Main/pph/15/SSPSetorSendiri/SSP15SetorSendiri.form').default} />
      <Route path={`/main-support/SPT_15/:sptId/SSP_15_Sendiri`} component={require('./../Main/pph/15/SSPSetorSendiri/SSP15SetorSendiri.view').default} />
      <Route path={`/main-support/SPT_15/:sptId/BP_15/BP15DipotongPihakLain/:id`} component={require('./../Main/pph/15/BP15/DipotongPihakLain/BP15DipotongPihakLain.form').default} />
      <Route path={`/main-support/SPT_15/:sptId/BP_15/BP15PihakLainDipotong/:id`} component={require('./../Main/pph/15/BP15/PihakLainDipotong/BP15PihakLainDipotong.form').default} />
      <Route path={`/main-support/SPT_15/:sptId/BP_15/BP15PPHPasal24/:id`} component={require('./../Main/pph/15/BP15/PPHPasal24/BP15PPhPasal24.form').default} />
      <Route path={`/main-support/SPT_15/:sptId/BP_15`} component={require('./../Main/pph/15/BP15/BP15.main').default} />
      <Route path={`/main-support/SPT_15/:sptId/PBK15/:id`} component={require('./../Main/pph/15/PBK/PBK15.form').default} />
      <Route path={`/main-support/SPT_15/:sptId/PBK15`} component={require('./../Main/pph/15/PBK/PBK15.view').default} />
      <Route path={`/main-support/SPT_15/:sptId/SSP15/:id`} component={require('./../Main/pph/15/SSP/SSP15.form').default} />
      <Route path={`/main-support/SPT_15/:sptId/SSP15`} component={require('./../Main/pph/15/SSP/SSP15.view').default} />
      <Route path={`/main-support/SPT_15/:sptId/EXPORT_CSV_15`} component={require('./../Main/pph/15/Export/ExportCsv15/ExportCsv15.view').default} />
      <Route path={`/main-support/SPT_15/:sptId/FILE_LAPOR_15`} component={require('./../Main/pph/15/FileLapor/FileLapor15.view').default} />
      <Route path={`/main-support/SPT_15/:sptId/SPT15INDUK`} component={require('./../Main/pph/15/Induk/Spt15Induk.form').default} />
      <Route path={`/main-support/SPT_15/:sptId/FILE_LAPOR_15`} component={require('./../Main/pph/15/FileLapor/FileLapor15.view').default} />
      <Route path={`/main-support/SPT_15/:sptId/IMPORTBP_15`} component={require('./../Main/pph/15/Import/ImportBp15/ImportBp15.view').default} />
      <Route path={`/main-support/SPT_15/:sptId/IMPORTBP_24`} component={require('./../Main/pph/15/Import/ImportBp24/ImportBp24.view').default} />
      <Route path={`/main-support/SPT_15/:sptId/IMPORTSSP_15_SETOR_SENDIRI`} component={require('./../Main/pph/15/Import/ImportSSPSetorSendiri/ImportSSP15SetorSendiri.view').default} />
      <Route path={`/main-support/SPT_15/:sptId/IMPORTSSP_15`} component={require('./../Main/pph/15/Import/ImportSSP/ImportSSP15.view').default} />
      <Route path={`/main-support/SPT_15/:sptId/IMPORTPBK_15`} component={require('./../Main/pph/15/Import/ImportPBK/ImportPBK15.view').default} />
      <Route path={`/main-support/SPT_15/:sptId/IMPORTLK_15`} component={require('./../Main/pph/15/Import/ImportLK/ImportLK15.view').default} />
      <Route path={`/main-support/SPT_15/:sptId/SETTING_NO_BUPOT_15`} component={require('./../Main/pph/15/SettingNoBupot15/SettingNoBupot15.form').default} />
      <Route path={`/main-support/SPT_15/:sptId/SETTING_TARIF_15`} component={require('./../Main/pph/15/company/tarif/Tarif15.form').default} />

      {/* 22 */}
      <Route path={`/main-support/SPT_22`} exact component={require('./../Main/pph/22/SPT/SPT22.view').default}/>
      <Route path={`/main-support/SPT_22/:sptId`} exact component={require('./../Main/pph/22/SPT/SPT22.form').default}/>
      <Route path={`/main-support/SPT_22/:sptId/open`} component={require('./../Main/pph/22/main').default}/>
      <Route path={`/main-support/SIGNER_22`} component={require('./../Main/pph/22/SPT22Signer/SPT22Signer.view').default} />
      <Route path={`/main-support/SIGNER_22_IMAGE`} component={require('./../Main/Setting/FileUpload22/FileUpload22.view').default} />
      <Route path={`/main-support/IMPORT/BP_22`} component={require('./../Main/pph/22/Import/ImportBP22AtasImpor/ImportBP22AtasImpor.view').default} />
      <Route path={`/main-support/IMPORT/BP_22_industri_eksportir`} component={require('./../Main/pph/22/Import/ImportBP22IndustriEksportir/ImportBP22IndustriEksportir.view').default} />
      <Route path={`/main-support/IMPORT/BP_22_industri_importir`} component={require('./../Main/pph/22/Import/ImportBP22IndustriImportir/ImportBP22IndustriImportir.view').default} />
      <Route path={`/main-support/IMPORT/BP_22_badan_tertentu`} component={require('./../Main/pph/22/Import/ImportBP22BadanTertentu/ImportBP22BadanTertentu.view').default} />
      <Route path={`/main-support/IMPORT/SSP_22_dibayar_sendiri`} component={require('./../Main/pph/22/Import/ImportSSP22DibayarSendiri/ImportSSP22DibayarSendiri.view').default} />
      <Route path={`/main-support/IMPORT/SSP_22_bank_devisa`} component={require('./../Main/pph/22/Import/ImportSSP22BankDevisa/ImportSSP22BankDevisa.view').default} />
      <Route path={`/main-support/IMPORT/SSP_22_bendaharawan`} component={require('./../Main/pph/22/Import/ImportSSP22Bendaharawan/ImportSSP22Bendarawan.view').default} />
      <Route path={`/main-support/IMPORT/SSP_22`} component={require('./../Main/pph/22/Import/ImportSSP/ImportSSP22.view').default} />
      <Route path={`/main-support/IMPORT/PBK_22`} component={require('./../Main/pph/22/Import/ImportPBK/ImportPBK22.view').default} />
      <Route path={`/main-support/IMPORT/LK_22`} component={require('./../Main/pph/22/Import/ImportLK/ImportLK22.view').default} />
      <Route path={`/main-support/ALLBP22`} component={require('./../Main/pph/22/All/AllBp22/AllBp22View').default} />
      <Route path={`/main-support/ALL_SSP22_PEMBELIAN_BARANG`} component={require('./../Main/pph/22/All/AllSsp22PembelianBarang/AllSsp22PembelianBarang.view').default} />
      <Route path={`/main-support/ALL_SSP22_OLEH_BANK_DEVISA`} component={require('./../Main/pph/22/All/AllSsp22ImportBankDevisa/AllSsp22ImportBankDevisa.view').default} />
      <Route path={`/main-support/ALL_SSP22_DIBAYAR_SENDIRI`} component={require('./../Main/pph/22/All/AllSsp22DibayarSendiri/AllSsp22DibayarSendiri.view').default} />
      <Route path={`/main-support/ALLSSP22`} component={require('./../Main/pph/22/All/AllSsp/AllSsp22.view').default} />

      {/* 22 Spt In */}
      <Route path={`/main-support/SPT_22/:sptId/BP_22/:id/:type`} component={require('./../Main/pph/22/BP22/BP22.form').default} />
      <Route path={`/main-support/SPT_22/:sptId/BP_22`} component={require('./../Main/pph/22/BP22/BP22.view').default} />
      <Route path={`/main-support/SPT_22/:sptId/ssp_pasal22_pembelian_barang/:id`} component={require('./../Main/pph/22/SSPPembelianBarang/SSPPembelianBarang.form').default} />
      <Route path={`/main-support/SPT_22/:sptId/ssp_pasal22_pembelian_barang`} component={require('./../Main/pph/22/SSPPembelianBarang/SSPPembelianBarang.view').default} />
      <Route path={`/main-support/SPT_22/:sptId/ssp_bank_devisa/:id`} component={require('./../Main/pph/22/SSPImportBankDevisa/SSPImportBankDevisa.form').default} />
      <Route path={`/main-support/SPT_22/:sptId/ssp_bank_devisa`} component={require('./../Main/pph/22/SSPImportBankDevisa/SSPImportBankDevisa.view').default} />
      <Route path={`/main-support/SPT_22/:sptId/ssp_dibayar_sendiri/:id`} component={require('./../Main/pph/22/SSPDibayarSendiri/SSPDibayarSendiri.form').default} />
      <Route path={`/main-support/SPT_22/:sptId/ssp_dibayar_sendiri`} component={require('./../Main/pph/22/SSPDibayarSendiri/SSPDibayarSendiri.view').default} />
      <Route path={`/main-support/SPT_22/:sptId/SSP22/:id`} component={require('./../Main/pph/22/SSP/SSP22.form').default} />
      <Route path={`/main-support/SPT_22/:sptId/SSP22`} component={require('./../Main/pph/22/SSP/SSP22.view').default} />
      <Route path={`/main-support/SPT_22/:sptId/PBK22/:id`} component={require('./../Main/pph/22/PBK/PBK22.form').default} />
      <Route path={`/main-support/SPT_22/:sptId/PBK22`} component={require('./../Main/pph/22/PBK/PBK22.view').default} />
      <Route path={`/main-support/SPT_22/:sptId/SPT22INDUK`} component={require('./../Main/pph/22/Induk/SPT22.induk.form').default} />
      <Route path={`/main-support/SPT_22/:sptId/FILE_LAPOR_22`} component={require('./../Main/pph/22/FileLapor/FileLapor22.view').default} />
      <Route path={`/main-support/SPT_22/:sptId/EXPORT_CSV_22`} component={require('./../Main/pph/22/Export/ExportCsv22/ExportCsv22.view').default} />
      <Route path={`/main-support/SPT_22/:sptId/IMPORTBP_22`} component={require('./../Main/pph/22/Import/ImportBP22AtasImpor/ImportBP22AtasImpor.view').default} />
      <Route path={`/main-support/SPT_22/:sptId/IMPORTSSP_22_dibayar_sendiri`} component={require('./../Main/pph/22/Import/ImportSSP22DibayarSendiri/ImportSSP22DibayarSendiri.view').default} />
      <Route path={`/main-support/SPT_22/:sptId/IMPORTSSP_22`} component={require('./../Main/pph/22/Import/ImportSSP/ImportSSP22.view').default} />
      <Route path={`/main-support/SPT_22/:sptId/IMPORTPBK_22`} component={require('./../Main/pph/22/Import/ImportPBK/ImportPBK22.view').default} />
      <Route path={`/main-support/SPT_22/:sptId/IMPORTLK_22`} component={require('./../Main/pph/22/Import/ImportLK/ImportLK22.view').default} />
      <Route path={`/main-support/SPT_22/:sptId/SETTING_NO_BUPOT_22`} component={require('./../Main/pph/22/SettingNoBupot22/SettingNoBupot22.form').default} />
      <Route path={`/main-support/SPT_22/:sptId/master/tarif-22`} exact component={require('./../Main/pph/22/master/tarif/Master22Tarif.form').default} />

      {/* 4a2 */}
      <Route path={`/main-support/SPT_4a2`} exact component={require('./../Main/pph/4a2/SPT/Spt4a2.view').default}/>
      <Route path={`/main-support/SPT_4a2/:sptId`} exact component={require('./../Main/pph/4a2/SPT/SPt4a2.form').default}/>
      <Route path={`/main-support/SPT_4a2/:sptId/open`} component={require('./../Main/pph/4a2/main').default}/>
      <Route path={`/main-support/ALLBP4A2/:id`} component={require('./../Main/pph/4a2/BP4a2/BP4a2.form').default} />
      <Route path={`/main-support/ALLBP4A2`} component={require('./../Main/pph/4a2/all/AllBp4a2/AllBp4a2View').default} />
      <Route path={`/main-support/ALLSSP4A2/:id`} component={require('./../Main/pph/4a2/SSP/SSP4a2.view').default} />
      <Route path={`/main-support/ALLSSP4A2`} component={require('./../Main/pph/4a2/all/AllSsp/AllSsp4a2.view').default} />
      <Route path={`/main-support/ALLSPT4a2Induk/:id`} component={require('./../Main/pph/4a2/Induk/Spt4a2Induk.form').default} />
      <Route path={`/main-support/ALLSPT4a2Induk`} component={require('./../Main/pph/4a2/all/AllInduk/AllSpt4a2Induk.view').default} />
      <Route path={`/main-support/ALLPBK4A2`} component={require('./../Main/pph/4a2/all/AllPbk4a2/AllPbk4a2.view').default} />
      <Route path={`/main-support/ALLPBK4A2/:id`} component={require('./../Main/pph/4a2/PBK/PBK4a2.form').default} />
      <Route path={`/main-support/master/tarif-4a2/:id`} exact component={require('./../Main/pph/4a2/master/tarif/Tarif4a2.form').default} />
      <Route path={`/main-support/master/tarif-4a2`} exact component={require('./../Main/pph/4a2/master/tarif/Tarif4a2.view').default} />
      <Route path={`/main-support/SIGNER_4`} component={require('./../Main/pph/4a2/SPT4Signer/SPT4Signer.view').default} />
      <Route path={`/main-support/SIGNER_4_IMAGE`} component={require('./../Main/Setting/FileUpload4/FileUpload4.view').default} />

      {/* 4a2 Spt In */}
      <Route path={`/main-support/SPT_4a2/:sptId/BP_4a2/:id`} component={require('./../Main/pph/4a2/BP4a2/BP4a2.form').default} />
      <Route path={`/main-support/SPT_4a2/:sptId/BP_4a2`} component={require('./../Main/pph/4a2/BP4a2/BP4a2.view').default} />
      <Route path={`/main-support/SPT_4a2/:sptId/daftar_pemotong`} component={require('./../Main/pph/4a2/TabunganDeposito/BP4a2TabunganDeposito.form').default} />
      <Route path={`/main-support/SPT_4a2/:sptId/sewa_tanah/:id`} component={require('./../Main/pph/4a2/DSSewaTanah/BP4a2DSSewaTanah.form').default} />
      <Route path={`/main-support/SPT_4a2/:sptId/sewa_tanah`} component={require('./../Main/pph/4a2/DSSewaTanah/BP4a2DSSewaTanah.view').default} />
      <Route path={`/main-support/SPT_4a2/:sptId/jasa_konstruksi/:id`} component={require('./../Main/pph/4a2/DSJasaKonstruksi/BP4a2DSJasaKonstruksi.form').default} />
      <Route path={`/main-support/SPT_4a2/:sptId/jasa_konstruksi`} component={require('./../Main/pph/4a2/DSJasaKonstruksi/BP4a2DSJasaKonstruksi.view').default} />
      <Route path={`/main-support/SPT_4a2/:sptId/hak_atas_tanah/:id`} component={require('./../Main/pph/4a2/DSPengalihanTanah/BP4a2DSPengalihanTanah.form').default} />
      <Route path={`/main-support/SPT_4a2/:sptId/hak_atas_tanah`} component={require('./../Main/pph/4a2/DSPengalihanTanah/BP4a2DSPengalihanTanah.view').default} />
      <Route path={`/main-support/SPT_4a2/:sptId/SSP4a2/:id`} component={require('./../Main/pph/4a2/SSP/SSP24a2.form').default} />
      <Route path={`/main-support/SPT_4a2/:sptId/SSP4a2`} component={require('./../Main/pph/4a2/SSP/SSP4a2.view').default} />
      <Route path={`/main-support/SPT_4a2/:sptId/PBK4a2/:id`} component={require('./../Main/pph/4a2/PBK/PBK4a2.form').default} />
      <Route path={`/main-support/SPT_4a2/:sptId/PBK4a2`} component={require('./../Main/pph/4a2/PBK/PBK4a2.view').default} />
      <Route path={`/main-support/SPT_4a2/:sptId/SPT4a2INDUK`} component={require('./../Main/pph/4a2/Induk/Spt4a2Induk.form').default} />
      <Route path={`/main-support/SPT_4a2/:sptId/FILE_LAPOR_4a2`} component={require('./../Main/pph/4a2/Export/ExportCsv4a2/ExportCsv4a2.view').default} />
      <Route path={`/main-support/SPT_4a2/:sptId/EXPORT_CSV_4a2`} component={require('./../Main/pph/4a2/Export/ExportCsv4a2/ExportCsv4a2.view').default} />
      <Route path={`/main-support/SPT_4a2/:sptId/LOG_EXPORT_CSV_4a2`} component={require('./../Main/pph/4a2/Export/ExportCsv4a2Log/ExportCsv4a2Log.view').default} />
      <Route path={`/main-support/SPT_4a2/:sptId/IMPORTBP_4a2`} component={require('./../Main/pph/4a2/Import/ImportBP4a2/ImportBP4a2.view').default} />
      <Route path={`/main-support/SPT_4a2/:sptId/IMPORTDAFTAR_PEMOTONGAN_4a2`} component={require('./../Main/pph/4a2/Import/ImportDaftarPemotongan4a2/ImportDaftarPemotongan4a2.view').default} />
      <Route path={`/main-support/SPT_4a2/:sptId/IMPORTPBK_4a2`} component={require('./../Main/pph/4a2/Import/ImportPBK/ImportPBK4a2.view').default} />
      <Route path={`/main-support/SPT_4a2/:sptId/IMPORTINDUK_PT_4a2`} component={require('./../Main/pph/4a2/Import/ImportInduk4a2PenghasilanTertentu/ImportInduk4a2PenghasilanTertentu.view').default} />
      <Route path={`/main-support/SPT_4a2/:sptId/IMPORTSSP_4a2`} component={require('./../Main/pph/4a2/Import/ImportSSP/ImportSSP4a2.view').default} />
      <Route path={`/main-support/SPT_4a2/:sptId/IMPORTLK_4a2`} component={require('./../Main/pph/4a2/Import/ImportLK/ImportLK4a2.view').default} />
      <Route path={`/main-support/SPT_4a2/:sptId/mastertarif-4a2`} exact component={require('./../Main/pph/4a2/master/tarif/Tarif4a2.form').default} />
      <Route path={`/main-support/SPT_4a2/:sptId/SETTING_NO_BUPOT_4A2`} component={require('./../Main/pph/4a2/SettingNoBupot4a2/SettingNoBupot4a2.form').default} />

      {/* 23_26 */}
      <Route path={`/main-support/SPT_23`} exact component={require('./../Main/pph/23_26/SPT/23_26.spt.view').default}/>
      <Route path={`/main-support/SPT_23/:sptId`} exact component={require('./../Main/pph/23_26/SPT/23_26.spt.form').default}/>
      <Route path={`/main-support/SPT_23/:sptId/open`} component={require('./../Main/pph/23_26/main').default}/>
      <Route path={`/main-support/ALLBP23/:id`} component={require('./../Main/pph/23_26/BP_23/BP_23.form').default} />
      <Route path={`/main-support/ALLBP23`} component={require('./../Main/pph/23_26/all/ALL_BP_23/ALLBP23.view').default} />
      <Route path={`/main-support/ALLBP26/:id`} component={require('./../Main/pph/23_26/BP_26/BP_26.form').default} />
      <Route path={`/main-support/ALLBP26`} component={require('./../Main/pph/23_26/all/ALL_BP_26/ALLBP26.view').default} />
      <Route path={`/main-support/ALLSSP23/:id`} component={require('./../Main/pph/23_26/SSP/SSP23.form').default} />
      <Route path={`/main-support/ALLSSP23`} component={require('./../Main/pph/23_26/all/ALL_SSP/ALLSSP23.view').default} />
      <Route path={`/main-support/ALLSPT23Induk/:id`} component={require('./../Main/pph/23_26/Induk/Spt23Induk.form').default} />
      <Route path={`/main-support/ALLSPT23Induk`} component={require('./../Main/pph/23_26/all/ALL_Induk/ALLSPT23Indu.view').default} />
      <Route path={`/main-support/SIGNER_23`} component={require('./../Main/pph/23_26/SPT23Signer/SPT23Signer.view').default} />
      <Route path={`/main-support/SIGNER_23_IMAGE`} component={require('./../Main/Setting/FileUpload23/FileUpload23.view').default} />

      {/* 23_26 SPT IN */}
      <Route path={`/main-support/SPT_23/:sptId/BP_23/:id`} component={require('./../Main/pph/23_26/BP_23/BP_23.form').default} />
      <Route path={`/main-support/SPT_23/:sptId/BP_23`} component={require('./../Main/pph/23_26/BP_23/BP_23.view').default} />
      <Route path={`/main-support/SPT_23/:sptId/BP_26/:id`} component={require('./../Main/pph/23_26/BP_26/BP_26.form').default} />
      <Route path={`/main-support/SPT_23/:sptId/BP_26`} component={require('./../Main/pph/23_26/BP_26/BP_26.view').default} />
      <Route path={`/main-support/SPT_23/:sptId/PBK23/:id`} component={require('./../Main/pph/23_26/PBK/PBK23.form').default} />
      <Route path={`/main-support/SPT_23/:sptId/PBK23`} component={require('./../Main/pph/23_26/PBK/PBK23.view').default} />
      <Route path={`/main-support/SPT_23/:sptId/SSP23/:id`} component={require('./../Main/pph/23_26/SSP/SSP23.form').default} />
      <Route path={`/main-support/SPT_23/:sptId/SSP23`} component={require('./../Main/pph/23_26/SSP/SSP23.view').default} />
      <Route path={`/main-support/SPT_23/:sptId/SPT23INDUK`} component={require('./../Main/pph/23_26/Induk/Spt23Induk.form').default} />
      <Route path={`/main-support/SPT_23/:sptId/FILE_LAPOR_23`} component={require('./../Main/pph/23_26/FileLapor/FileLapor23.view').default} />
      <Route path={`/main-support/SPT_23/:sptId/EXPORT_CSV_23`} component={require('./../Main/pph/23_26/Export/ExportCsv23/ExportCsv23.view').default} />
      <Route path={`/main-support/SPT_23/:sptId/LOG_EXPORT_CSV_23`} component={require('./../Main/pph/23_26/Export/ExportCsv23Log/ExportCsv23Log.view').default} />
      <Route path={`/main-support/SPT_23/:sptId/IMPORTBP_23`} component={require('./../Main/pph/23_26/Import/ImportBP23/ImportBP23.view').default} />
      <Route path={`/main-support/SPT_23/:sptId/IMPORTBP_26`} component={require('./../Main/pph/23_26/Import/ImportBP26/ImportBP26.view').default} />
      <Route path={`/main-support/SPT_23/:sptId/IMPORTIMPORT_PBK`} component={require('./../Main/pph/23_26/Import/ImportPBK/ImportPBK23.view').default} />
      <Route path={`/main-support/SPT_23/:sptId/IMPORTSSP_23`} component={require('./../Main/pph/23_26/Import/ImportSSP/ImportSSP23.view').default} />
      <Route path={`/main-support/SPT_23/:sptId/IMPORTLT_23`} component={require('./../Main/pph/23_26/Import/ImportLK/ImportLK23.view').default} />
      <Route path={`/main-support/SPT_23/:sptId/logExport/:pasal`} render={(props) => ( <LogExportView key={props.match.params.pasal} {...props} /> )} />
      <Route path={`/main-support/SPT_23/:sptId/mastertarif-23`} component={require('./../Main/pph/23_26/master/Tarif23/Master23Tarif.form').default} />
      <Route path={`/main-support/SPT_23/:sptId/mastertarif-23-lain`} component={require('./../Main/pph/23_26/master/Tarif23/Master23TarifLain.view').default} />
      <Route path={`/main-support/SPT_23/:sptId/mastertarif-26`} component={require('./../Main/pph/23_26/master/Tarif23/Master26Tarif.form').default} />
      <Route path={`/main-support/SPT_23/:sptId/SETTING_NO_BUPOT_2326`} component={require('./../Main/pph/23_26/SettingNoBupot2326/SettingNoBupot2326.form').default} />
      <Route path={`/main-support/SPT_23/:sptId/SETTING_SIGNER_2326`} component={require('./../Main/pph/23_26/Signer/SPT23Signer.view').default} />

      {/* 21_26 */}
      <Route path={`/main-support/SPT_21`} exact component={require('./../Main/pph/21_26/spt/21_26.spt.view').default}/>
      <Route path={`/main-support/SPT_21/:sptId`} exact component={require('./../Main/pph/21_26/spt/21_26.spt.form').default}/>
      <Route path={`/main-support/SPT_21/:sptId/open`} component={require('./../Main/pph/21_26/main').default}/>
      <Route path={`/main-support/SIGNER_21`} component={require('./../Main/pph/21_26/SPT21Signer/SPT21Signer.view').default} />
      <Route path={`/main-support/SIGNER_21_IMAGE`} component={require('./../Main/Setting/FileUpload/FileUpload.view').default} />

      {/* 21_26 ALL */}
      <Route path={`/main-support/ALL_BP_21_F/:id`} component={require('./../Main/pph/21_26/BP_21_F/BP_21_F.form').default} />
      <Route path={`/main-support/BP_21_F`} component={require('./../Main/pph/21_26/ALL_BP_21_F/ALL_BP_21_F.view').default} />
      <Route path={`/main-support/ALL_BP_21_TF/:id`} component={require('./../Main/pph/21_26/BP_21_TF/BP_21_TF.form').default} />
      <Route path={`/main-support/BP_21_TF`} component={require('./../Main/pph/21_26/ALL_BP_21_TF/ALL_BP_21_TF.view').default} />
      <Route path={`/main-support/ALL_BP_21_A1/:id`} component={require('./../Main/pph/21_26/BP_21_A1/BP_21_A1.form').default} />
      <Route path={`/main-support/BP_21_A1`} component={require('./../Main/pph/21_26/ALL_BP_21_A1/ALL_BP_21_A1.view').default} />
      <Route path={`/main-support/ALL_BP_21_A2/:id`} component={require('./../Main/pph/21_26/BP_21_A2/BP_21_A2.form').default} />
      <Route path={`/main-support/BP_21_A2`} component={require('./../Main/pph/21_26/ALL_BP_21_A2/ALL_BP_21_A2.view').default} />
      <Route path={`/main-support/ALL_BP_21_SSP/:id`} component={require('./../Main/pph/21_26/BP_21_SSP/BP_21_SSP.form').default} />
      <Route path={`/main-support/ALL_BP_21_SSP`} component={require('./../Main/pph/21_26/ALL_BP_21_SSP/ALL_BP_21_SSP.view').default} />
      <Route path={`/main-support/ALL_BP_21_SATU_MASA/:id`} component={require('./../Main/pph/21_26/BP_21_SATU_MASA/BP_21_SATU_MASA.form').default} />
      <Route path={`/main-support/ALL_BP_21_SATU_MASA`} component={require('./../Main/pph/21_26/ALL_BP_21_SATU_MASA/ALL_BP_21_SATU_MASA.view').default} />
      <Route path={`/main-support/ALL_BP_21_DAFTAR_BIAYA/:sptId`} component={require('./../Main/pph/21_26/BP_21_DAFTAR_BIAYA/BP_21_DAFTAR_BIAYA.form').default} />
      <Route path={`/main-support/DAFTAR_BIAYA_21`} component={require('./../Main/pph/21_26/ALL_BP_21_DAFTAR_BIAYA/ALL_BP_21_DAFTAR_BIAYA.view').default} />
      <Route path={`/main-support/BP_21_SATU_TAHUN`} component={require('./../Main/pph/21_26/ALL_BP_21_SATU_TAHUN/ALL_BP_21_SATU_TAHUN.view').default} />
      <Route path={`/main-support/ALL_BP_21_INDUK/:sptId`} component={require('./../Main/pph/21_26/SPT_INDUK_21/SPT_INDUK_21.form').default} />
      <Route path={`/main-support/SPT_INDUK_21`} component={require('./../Main/pph/21_26/ALL_BP_21_INDUK/ALL_BP_21_INDUK.view').default} />

      {/* 21_26 SPT In */}
      <Route path={`/main-support/SPT_21/:sptId/BP_21_F/:id`} component={require('./../Main/pph/21_26/BP_21_F/BP_21_F.form').default} />
      <Route path={`/main-support/SPT_21/:sptId/BP_21_F`} component={require('./../Main/pph/21_26/BP_21_F/BP_21_F.view').default} />
      <Route path={`/main-support/SPT_21/:sptId/BP_21_TF/:id`} component={require('./../Main/pph/21_26/BP_21_TF/BP_21_TF.form').default} />
      <Route path={`/main-support/SPT_21/:sptId/BP_21_TF`} component={require('./../Main/pph/21_26/BP_21_TF/BP_21_TF.view').default} />
      <Route path={`/main-support/SPT_21/:sptId/BP_21_A1/:id`} component={require('./../Main/pph/21_26/BP_21_A1/BP_21_A1.form').default} />
      <Route path={`/main-support/SPT_21/:sptId/BP_21_A1`} component={require('./../Main/pph/21_26/BP_21_A1/BP_21_A1.view').default} />
      <Route path={`/main-support/SPT_21/:sptId/BP_21_A2/:id`} component={require('./../Main/pph/21_26/BP_21_A2/BP_21_A2.form').default} />
      <Route path={`/main-support/SPT_21/:sptId/BP_21_A2`} component={require('./../Main/pph/21_26/BP_21_A2/BP_21_A2.view').default} />
      <Route path={`/main-support/SPT_21/:sptId/BP_21_SATU_MASA/:id`} component={require('./../Main/pph/21_26/BP_21_SATU_MASA/BP_21_SATU_MASA.form').default} />
      <Route path={`/main-support/SPT_21/:sptId/BP_21_SATU_MASA`} component={require('./../Main/pph/21_26/BP_21_SATU_MASA/BP_21_SATU_MASA.view').default} />
      <Route path={`/main-support/SPT_21/:sptId/DAFTAR_BIAYA_21`} component={require('./../Main/pph/21_26/BP_21_DAFTAR_BIAYA/BP_21_DAFTAR_BIAYA.form').default} />
      <Route path={`/main-support/SPT_21/:sptId/DAFTAR_SSP_21/:id`} component={require('./../Main/pph/21_26/BP_21_SSP/BP_21_SSP.form').default} />
      <Route path={`/main-support/SPT_21/:sptId/DAFTAR_SSP_21`} component={require('./../Main/pph/21_26/BP_21_SSP/BP_21_SSP.view').default} />
      <Route path={`/main-support/SPT_21/:sptId/BP_21_SATU_TAHUN`} component={require('./../Main/pph/21_26/BP_21_SATU_TAHUN/BP_21_SATU_TAHUN.view').default} />
      <Route path={`/main-support/SPT_21/:sptId/SPT_INDUK_21`} component={require('./../Main/pph/21_26/SPT_INDUK_21/SPT_INDUK_21.form').default} />
      <Route path={`/main-support/SPT_21/:sptId/FILE_LAPOR_21`} component={require('./../Main/pph/21_26/FileLapor21/FileLapor21.view').default} />
      <Route path={`/main-support/SPT_21/:sptId/LOG_REPORT_21`} component={require('./../Main/pph/21_26/LogReport21/LogReport21.view').default} />
      <Route path={`/main-support/SPT_21/:sptId/IMPORT_21`} component={require('./../Main/pph/21_26/Import/Import.form').default} />
      <Route path={`/main-support/SPT_21/:sptId/IMPORTBP_21_TF`} component={require('./../Main/pph/21_26/ImportBp21TF/ImportBp21TF.view').default} />
      <Route path={`/main-support/SPT_21/:sptId/IMPORTBP_21_F`} component={require('./../Main/pph/21_26/ImportBp21F/ImportBp21F.view').default} />
      <Route path={`/main-support/SPT_21/:sptId/IMPORTBP_21_A1`} component={require('./../Main/pph/21_26/ImportBp21A1/ImportBp21A1.view').default} />
      <Route path={`/main-support/SPT_21/:sptId/IMPORTBP_21_A2`} component={require('./../Main/pph/21_26/ImportBp21A2/ImportBp21A2.view').default} />
      <Route path={`/main-support/SPT_21/:sptId/IMPORTDAFTAR_BIAYA_21`} component={require('./../Main/pph/21_26/ImportBp21DaftarBiaya/ImportBp21DaftarBiaya.view').default} />
      <Route path={`/main-support/SPT_21/:sptId/IMPORTBP_21_SATU_MASA`} component={require('./../Main/pph/21_26/ImportBP21SatuMasa/ImportBP21SatuMasa.view').default} />
      <Route path={`/main-support/SPT_21/:sptId/IMPORTBP_21_SATU_MASA_TIDAK_LEBIH`} component={require('./../Main/pph/21_26/ImportBP21SatuMasaTL/ImportBP21SatuMasaTL.view').default} />
      <Route path={`/main-support/SPT_21/:sptId/IMPORTSSP_21`} component={require('./../Main/pph/21_26/ImportBp21Ssp/ImportBp21Ssp.view').default} />
      <Route path={`/main-support/SPT_21/:sptId/IMPORTINDUK_21`} component={require('./../Main/pph/21_26/ImportBp21Induk/ImportBp21Induk.view').default} />
      <Route path={`/main-support/SPT_21/:sptId/masterpasal-21-upah-harian`} exact component={require('./../Main/pph/21_26/Master21UpahHarian/Master21UpahHarian.form.view').default} />
      <Route path={`/main-support/SPT_21/:sptId/masterpasal-21-upah-kumulatif`} exact component={require('./../Main/pph/21_26/Master21UpahKumulatif/Master21UpahKumulatif.form.view').default} />
      <Route path={`/main-support/SPT_21/:sptId/masterpasal-21-golongan-a2`} exact component={require('./../Main/pph/21_26/Master21GolonganA2/Master21GolonganA2.view').default} />
      <Route path={`/main-support/SPT_21/:sptId/masterpasal-21-ssp-description`} exact component={require('./../Main/pph/21_26/Master21SspDescription/Master21SspDescription.view').default} />
      <Route path={`/main-support/SPT_21/:sptId/masterpasal-21-status-karyawan-a1`} exact component={require('./../Main/pph/21_26/Master21StatusKaryawanA1/Master21StatusKaryawanA1.view').default} />
      <Route path={`/main-support/SPT_21/:sptId/masterpasal-21-status-karyawan-a2`} exact component={require('./../Main/pph/21_26/Master21StatusKaryawanA2/Master21StatusKaryawanA2.view').default} />
      <Route path={`/main-support/SPT_21/:sptId/masterpasal-21-kap`} exact component={require('./../Main/pph/21_26/Master21Kap/Master21Kap.view').default} />
      <Route path={`/main-support/SPT_21/:sptId/masterpasal-21-kjs`} exact component={require('./../Main/pph/21_26/Master21Kjs/Master21Kjs.view').default} />
      <Route path={`/main-support/SPT_21/:sptId/masterpasal-21-pasal-17-berlapis/:id`} exact component={require('./../Main/pph/21_26/Master21Pasal17/Master21Pasal17.form.view').default} />
      <Route path={`/main-support/SPT_21/:sptId/masterpasal-21-pasal-17-berlapis`} exact component={require('./../Main/pph/21_26/Master21Pasal17/Master21Pasal17.view').default} />
      <Route path={`/main-support/SPT_21/:sptId/masterpasal-21-biaya-jabatan`} exact component={require('./../Main/pph/21_26/Master21BiayaJabatan/Master21BiayaJabatan.form.view').default} />
      <Route path={`/main-support/SPT_21/:sptId/masterpasal-21-biaya-pensiun`} exact component={require('./../Main/pph/21_26/Master21BiayaPensiun/Master21BiayaPensiun.form.view').default} />
      <Route path={`/main-support/SPT_21/:sptId/masterpasal-21-pensiun`} exact component={require('./../Main/pph/21_26/Master21Pensiun/Master21Pensiun.form.view').default} />
      <Route path={`/main-support/SPT_21/:sptId/masterpasal-21-ptkp/:id`} exact component={require('./../Main/pph/21_26/Master21Ptkp/Master21Ptkp.form.view').default} />
      <Route path={`/main-support/SPT_21/:sptId/masterpasal-21-ptkp`} exact component={require('./../Main/pph/21_26/Master21Ptkp/Master21Ptkp.view').default} />
      <Route path={`/main-support/SPT_21/:sptId/masterpasal-21-honor-pns`} exact component={require('./../Main/pph/21_26/Master21HonorPns/Master21HonorPns.form.view').default} />
      <Route path={`/main-support/SPT_21/:sptId/masterpasal-21-final-pesangon/:id`} exact component={require('./../Main/pph/21_26/Master21FinalPesangon/Master21FinalPesangon.form.view').default} />
      <Route path={`/main-support/SPT_21/:sptId/masterpasal-21-final-pesangon`} exact component={require('./../Main/pph/21_26/Master21FinalPesangon/Master21FinalPesangon.view').default} />
      <Route path={`/main-support/SPT_21/:sptId/EXPORT_CSV_21`} component={require('./../Main/pph/21_26/ExportCsv21/ExportCsv21.view').default} />
      <Route path={`/main-support/SPT_21/:sptId/logExport/:pasal`} render={(props) => ( <LogExportView key={props.match.params.pasal} {...props} /> )} />
      <Route path={`/main-support/SPT_21/:sptId/LOG_EXPORT_CSV_21`} component={require('./../Main/pph/21_26/ExportCsv21Log/ExportCsv21Log.view').default} />
      <Route path={`/main-support/SPT_21/:sptId/LOG_EMAIL`} component={require('./../Main/pph/21_26/Email21Log/Email21Log.view').default} />
      <Route path={`/main-support/SPT_21/:sptId/LOG_FILE_LAPOR_21`} component={require('./../Main/pph/21_26/FileLapor21Log/FileLapor21Log.view').default} />
      <Route path={`/main-support/SPT_21/:sptId/SETTING_NO_BUPOT_21`} component={require('./../Main/pph/21_26/SettingNoBupot21/SettingNoBupot21.form').default} />

      {/* Module Ebupot Spt In */}
      <Route path={`/main-support/ebupot-23/:sptId/bpe`} component={require('./../Main/Modules/EbupotModule/PPh/Bpe/Bpe.view').default} />
      <Route path={`/main-support/ebupot-23/:sptId/log-file`} component={require('./../Main/Modules/EbupotModule/LogFile/LogFile.view').default} />
      <Route path={`/main-support/ebupot-23/:sptId/bp-26-edit/:id`}      component={require('./../Main/Modules/EbupotModule/PPh/SPT23In/Bupot26InEdit/Bupot26InEdit.form.view').default} />
      <Route path={`/main-support/ebupot-23/:sptId/bp-26-edit`}      component={require('./../Main/Modules/EbupotModule/PPh/SPT23In/Bupot26InEdit/Bupot26InEdit.view').default} />
      <Route path={`/main-support/ebupot-23/:sptId/bp-23-edit/:id`}      component={require('./../Main/Modules/EbupotModule/PPh/SPT23In/Bupot23InEdit/Bupot23InEdit.form.view').default} />
      <Route path={`/main-support/ebupot-23/:sptId/bp-23-edit`}      component={require('./../Main/Modules/EbupotModule/PPh/SPT23In/Bupot23InEdit/Bupot23InEdit.view').default} />
      <Route path={`/main-support/ebupot-23/:sptId/bp-26-pembetulan/:id`}      component={require('./../Main/Modules/EbupotModule/PPh/SPT23In/Bupot26InPembetulan/Bupot26InPembetulan.form.view').default} />
      <Route path={`/main-support/ebupot-23/:sptId/bp-26-pembetulan`}      component={require('./../Main/Modules/EbupotModule/PPh/SPT23In/Bupot26InPembetulan/Bupot26InPembetulan.view').default} />
      <Route path={`/main-support/ebupot-23/:sptId/bp-23-pembetulan/:id`}      component={require('./../Main/Modules/EbupotModule/PPh/SPT23In/Bupot23InPembetulan/Bupot23InPembetulan.form.view').default} />
      <Route path={`/main-support/ebupot-23/:sptId/bp-23-pembetulan`}      component={require('./../Main/Modules/EbupotModule/PPh/SPT23In/Bupot23InPembetulan/Bupot23InPembetulan.view').default} />
      <Route path={`/main-support/ebupot-23/:sptId/bp-23/:id`}      component={require('./../Main/Modules/EbupotModule/PPh/SPT23In/Bupot23In/Bupot23In.form.view').default} />
      <Route path={`/main-support/ebupot-23/:sptId/bp-23`}      component={require('./../Main/Modules/EbupotModule/PPh/SPT23In/Bupot23In/Bupot23In.view').default} />
      <Route path={`/main-support/ebupot-23/:sptId/bp-26/:id`}      component={require('./../Main/Modules/EbupotModule/PPh/SPT23In/Bupot26In/Bupot26In.form.view').default} />
      <Route path={`/main-support/ebupot-23/:sptId/bp-26`}      component={require('./../Main/Modules/EbupotModule/PPh/SPT23In/Bupot26In/Bupot26In.view').default} />
      <Route path={`/main-support/ebupot-23/:sptId/ssp/:id`}      component={require('./../Main/Modules/EbupotModule/PPh/SPT23In/ssp/ssp.form.view').default} />
      <Route path={`/main-support/ebupot-23/:sptId/ssp`}      component={require('./../Main/Modules/EbupotModule/PPh/SPT23In/ssp/ssp.view').default} />
      <Route path={`/main-support/ebupot-23/:sptId/pbk/:id`}      component={require('./../Main/Modules/EbupotModule/PPh/SPT23In/pbk/pbk.form.view').default} />
      <Route path={`/main-support/ebupot-23/:sptId/pbk`}      component={require('./../Main/Modules/EbupotModule/PPh/SPT23In/pbk/pbk.view').default} />
      <Route path={`/main-support/ebupot-23/:sptId/induk`}      component={require('./../Main/Modules/EbupotModule/PPh/SPT23In/induk/induk.form.view').default} />

      {/* Module Ebupot */}
      <Route path="/main-support/bp-26-pembetulan/:sptId/:id"   component={Bp26PembetulanFormView} />
      <Route path="/main-support/bp-26-pembetulan/:id"   component={Bp26PembetulanFormView} />
      <Route path="/main-support/bp-26-pembetulan"       component={Bp26PembetulanView} />
      <Route path="/main-support/bp-26-edit/:sptId/:id"         component={Bp26EditFormView} />
      <Route path="/main-support/bp-26-edit/:id"         component={Bp26EditFormView} />
      <Route path="/main-support/bp-26-edit"             component={Bp26EditView} />
      <Route path="/main-support/bp-23-pembetulan/:sptId/:id"   component={Bp23PembetulanFormView} />
      <Route path="/main-support/bp-23-pembetulan/:id"   component={Bp23PembetulanFormView} />
      <Route path="/main-support/bp-23-pembetulan"       component={Bp23PembetulanView} />
      <Route path="/main-support/bp-23-edit/:sptId/:id"         component={Bp23EditFormView} />
      <Route path="/main-support/bp-23-edit/:id"         component={Bp23EditFormView} />
      <Route path="/main-support/bp-23-edit"             component={Bp23EditView} />
      <Route path="/main-support/ebupot-23/spt-history/form/:id/:createdDate" component={SPT23HistoryFormView} />
      <Route path="/main-support/ebupot-23/spt-history/:sptId/:createdDate"   component={SPT23HistoryView} />
      <Route path="/main-support/ebupot-23/bpe/form/:id" component={BpeFormView} />
      <Route path="/main-support/ebupot-23/bpe/:sptId"   component={BpeView} />
      <Route path="/main-support/ebupot-23/:sptId/open"  component={SPT23MainView} />
      <Route path="/main-support/ebupot-23/:sptId"       component={SPT23FormView} />
      <Route path="/main-support/ebupot-23"              component={SPT23View} />
      <Route path="/main-support/users/:id"              component={UserFormView} />
      <Route path="/main-support/users"                  component={UserView} />
      <Route path="/main-support/application-email"      component={ApplicationEmailFormView} />
      <Route path="/main-support/application/:id"        component={ApplicationFormView} />
      <Route path="/main-support/application"            component={ApplicationView} />
      <Route path="/main-support/client/:appId"          component={ClientView} />
      {/* <Route pa/main-supportinit/ebupot/organization-users/:id" component={OrganizationUserForm} />
      <Route path="/main-support/ebupot/organization-users" component={OrganizationUserView} /> */}
      <Route path="/main-support/ebupot/organization/:id"component={OrganizationFormView} />
      <Route path="/main-support/ebupot/organization"    component={OrganizationView} />
      <Route path="/main-support/LogSubmit/:id"          component={LogSubmitFormView} />
      <Route path="/main-support/log-submit"             component={LogSubmitView} />
      <Route path="/main-support/LogCheck/:id"           component={LogCheckFormView} />
      <Route path="/main-support/log-check"              component={LogCheckView} />
      <Route path="/main-support/log-file-validasi/:id/:type/:status"      component={LogFileValidasiView} />
      <Route path="/main-support/log-file-validasi"      component={LogFileValidasiView} />
      <Route path="/main-support/log-file-csv"           component={LogFileCsvView} />
      <Route path="/main-support/log-file"               component={LogFileView} />
      <Route path="/main-support/ebupot/signer/:org/:id"        component={SignerFormView} />
      <Route path="/main-support/ebupot/signer/:id"             component={SignerView} />
      <Route path="/main-support/kodeNegara/:id"         component={KodeNegaraFormView} />
      <Route path="/main-support/kodeNegara"             component={KodeNegaraView} />
      <Route path="/main-support/provinces/:id"          component={ProvincesFormView} />
      <Route path="/main-support/provinces"              component={ProvincesView} />
      <Route path="/main-support/kodeBuktiPotong/:id"    component={KodeBuktiPotongFormView} />
      <Route path="/main-support/kodeBuktiPotong"        component={KodeBuktiPotongView} />
      <Route path="/main-support/jenisDokumen/:id"       component={JenisDokumenFormView} />
      <Route path="/main-support/jenisDokumen"           component={JenisDokumenView} />
      <Route path="/main-support/regencies/:id"          component={RegencyFormView} />
      <Route path="/main-support/regencies"              component={RegencyView} />
      <Route path="/main-support/districts/:id"          component={DistrictFormView} />
      <Route path="/main-support/districts"              component={DistrictView} />
      <Route path="/main-support/villages/:id"           component={VillagesFormView} />
      <Route path="/main-support/villages"               component={VillageView} />
      <Route path="/main-support/kode-bukti-potong-26"   component={KodeBuktiPotong26View} />
      <Route path="/main-support/all-ebupot-23"          component={AllEbupot23View} />
      <Route path="/main-support/all-ebupot-26"          component={AllEbupot26View} />
      <Route path="/main-support/bupot23/history/:bpId/:sptId/:id" component={Bupot23HistoryFormView} />
      <Route path="/main-support/bupot26/history/:bpId/:sptId/:id" component={Bupot26HistoryFormView} />
      <Route path="/main-support/bupot23/history/:id"    component={Bupot23HistoryView} />
      <Route path="/main-support/bupot26/history/:id"    component={Bupot26HistoryView} />
      <Route path="/main-support/bupot23/email/:bpId/:id" component={Bupot23EmailFormView} />
      <Route path="/main-support/bupot26/email/:bpId/:id" component={Bupot26EmailFormView} />
      <Route path="/main-support/bupot23/email/:id"       component={Bupot23EmailView} />
      <Route path="/main-support/bupot26/email/:id"       component={Bupot26EmailView} />
      <Route path="/main-support/bp-23/:sptId/:id"              component={Bupot23FormView} />
      <Route path="/main-support/bp-26/:sptId/:id"              component={Bupot26FormViewView} />
      <Route path="/main-support/bp-23/:id"              component={Bupot23FormView} />
      <Route path="/main-support/bp-26/:id"              component={Bupot26FormViewView} />
      <Route path="/main-support/bp-23"                  component={Bupot23View} />
      <Route path="/main-support/bp-26"                  component={Bupot26View} />
      <Route path="/main-support/lawan-transaksi-26/:id" component={LawanTransaksi26FormView} />
      <Route path="/main-support/lawan-transaksi-26"     component={LawanTransaksi26View} />
      <Route path="/main-support/lawan-transaksi-23/:id" component={LawanTransaksi23FormView} />
      <Route path="/main-support/lawan-transaksi-23"     component={LawanTransaksi23View} />
      <Route path="/main-support/ebupot-dashboard" component={Dashboard}/>

      <Route path="/main-support/dashboard" component={require('./Dashboard/Dashboard.view').default} />
      <Route path="/main-support/company" component={require('./Company/Company.view').default} />
      <Route path="/main-support/orgs" component={require('./Organization/Organization.view').default} />
      <Route path="/main-support/signer/:npwp/:id" component={require('./Signer/Signer.form.view').default} />
      <Route path="/main-support/signer/:npwp" component={require('./Signer/Signer.view').default} />
      <Route path="/" component={require('./Dashboard/Dashboard.view').default} />
  </Switch>
)





