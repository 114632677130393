import ApiService from '../../../../../services/api.service'
import moment from 'moment'

class LawanTransaksi23Service extends ApiService {
  name= 'LawanTransaksi23'
  path= 'ebupot/lt23'
  advancedSearch=true
  rootpath= 'ebupot/lawan-transaksi-23'

  constructor(){
    super()
    this.init()
    this.initApi()
  }

  columns=[
    {label: "word.id", searchField: "id", value: "id", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
    {label: "word.npwpProfile", searchField: "npwpProfile", value: "npwpProfile", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.nama", searchField: "nama", value: "nama", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.nik", searchField: "nik", value: "nik", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.npwp", searchField: "npwp", value: "npwp", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.email", searchField: "email", value: "email", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.alamat", searchField: "alamat", value: "alamat", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.provinsi", searchField: "provinsi", value: "provinsi", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.kabupaten", searchField: "kabupaten", value: "kabupaten", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.kecamatan", searchField: "kecamatan", value: "kecamatan", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.kelurahan", searchField: "kelurahan", value: "kelurahan", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.kodePos", searchField: "kodePos", value: "kodePos", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.noTelp", searchField: "noTelp", value: "noTelp", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {width: 150, label: "word.createdBy", searchField: "createdBy", value: "createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.createdDate", searchField: "createdDate", value: (d)=> {
      if(d){
        return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
      } else {
        return "-"
      }
    }, type: 'func', isDefaultSort:true, isSortable:true, show:true, isSearchable:true},
    {width: 150, label: "word.lastModifiedBy", searchField: "lastModifiedBy", value: "lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.lastModifiedDate", searchField: "lastModifiedDate", value: (d)=> {
      if(d.lastModifiedDate){
        return moment(d.lastModifiedDate).format('DD/MM/YYYY HH:MM')
      } else {
        return "-"
      }
    }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
  ]

}

export default new LawanTransaksi23Service();
