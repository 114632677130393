import React, { Component } from 'react';
import { connect } from 'react-redux';

import LogReport21Service from './LogReport21.service';
import ListView from '../../../../../components/entity/listView';
import ColumnService from '../../../../../services/column.service';
import iziToast from 'izitoast';
import download from 'downloadjs'
import uuid from 'uuid/v4'

@connect(LogReport21Service.stateConnectSetting(), LogReport21Service.actionConnectSetting())
export default class LogReport21View extends ListView {
  service=LogReport21Service
  apiPath='list'

  defaultColumns=[]

  _barActions = [
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    },
  ]

  async downloadItem(item){
    try {
        let report = await this.service.api.downloadItem(item.id);
        download(report.data, "bulk_report_" + uuid() + ".zip");
        iziToast.success({
            title: "Download",
            message: "report berhasil di download"
        })
      } catch(e) {
        iziToast.error({
            title: "Download Report",
            message: e.message
        })
      }
  }

  deleteItem = async (item, callback) => {
    try {
      await this.service.api.deleteItem(item.id);
      callback()
      await this.fetchData()
    } catch(e) {
      callback(e, e)
    }
  }

  _tableActions = [
    {
      label:"Download File",
      iconClassName:"mdi mdi-file",
      onClick: (item) => this.downloadItem(item)
    },
    {label:"divider", iconClassName:"-"},
    {
      label:"Delete",
      iconClassName:"mdi mdi-delete",
      onClick:(item, callback) => this.deleteItem(item, callback),
      confirmation:{
        title:"sentence.custom.deleteItem",
        message:"sentence.custom.deleteItemConfirmation"
      }
    }
  ]

  columns=[
    {isSortable: true, label: "word.id", searchField: "id",  value: function(d){ return d.id }, type: "func",  show:false, isSearchable:true},
    {isSortable: true, label: "word.module", searchField: "module",  value: 'module', show:true, isSearchable:true},
    {isSortable: true, label: "word.status", searchField: "status",  value: 'status', show:true, isSearchable:true},
    {isSortable: true, label: "word.size", searchField: "size",  value: 'size', type: 'number', show:true, isSearchable:true},
    {isSortable: true, label: "word.total", searchField: "total",  value: 'total', type: 'number', show:true, isSearchable:true}
  ]   
}
