import * as CONSTANT from '../../config/constant';

const mainMenus = [
  {
    label: {
      id: 'Main',
      en: 'Main'
    },
    children: [{
      label: {
        id: 'Home',
        en: 'Home'
      },
      iconClassName: 'mdi mdi-view-grid',
      path: '/dashboard',
    }]
  },

  // {
  //   label: {
  //     id: 'E-Faktur Lapor & SPT 1771',
  //     en: 'E-Faktur Lapor & SPT 1771'
  //   },
  //   children: [
  //     {
  //       label: {
  //         id: 'Tarra Lapor (PPN)',
  //         en: 'Tarra Lapor (PPN)'
  //       },
  //       iconClassName: 'mdi mdi-view-grid',
  //       path: '/tarra-lapor',
  //     },
  //     {
  //       label: {
  //         id: 'SPT 1771',
  //         en: 'SPT 1771'
  //       },
  //       iconClassName: 'mdi mdi-view-grid',
  //       path: '/spt-1771',
  //     }
  //   ]
  // },

  {
    label: {
      id: 'Ebupot 23/26',
      en: 'Ebupot 23/26'
    },
    children: [
      {
        label: {
          id: 'Daftar Organisasi',
          en: 'Organization List'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/ebupot/organization',
        permission: ''
      },
      {
        label: {
          id: 'Daftar SPT',
          en: 'SPT List'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/ebupot-23',
        permission: ''
      },
      {
        label: {
          id: 'BP 23',
          en: 'BP 23'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/bp-23',
        permission: ''
      },
      {
        label: {
          id: 'BP 26',
          en: 'BP 26'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/bp-26',
        permission: ''
      },
      {
        label: {
          id: 'Lawan Transaksi 23',
          en: 'Lawan Transaksi 23'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/lawan-transaksi-23',
      },
      {
        label: {
          id: 'Lawan Transaksi 26',
          en: 'Lawan Transaksi 26'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/lawan-transaksi-26',
      },
      {
        label: {
          id: 'Upload File',
          en: 'Upload File'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/log-file',
        permission: ''
      },
      // {
      //   label: {
      //     id: 'Kode Bukti Potong',
      //     en: 'Kode Bukti Potong'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/kodeBuktiPotong'
      // },
      // {
      //   label: {
      //     id: 'Kode Bukti Potong 26',
      //     en: 'Kode Bukti Potong 26'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/kode-bukti-potong-26'
      // },
      // {
      //   label: {
      //     id: 'Jenis Dokumen',
      //     en: 'Jenis Dokumen'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/jenisDokumen'
      // },
      // {
      //   label: {
      //     id: 'Kode Negara',
      //     en: 'Country Code'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/kodeNegara'
      // },
      // {
      //   label: {
      //     id: 'Propinsi',
      //     en: 'Provinces'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/provinces'
      // },
      // {
      //   label: {
      //     id: 'Regensi',
      //     en: 'Regencies'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/regencies'
      // },
      // {
      //   label: {
      //     id: 'Distrik',
      //     en: 'Districts'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/districts'
      // },
      // {
      //   label: {
      //     id: 'Villages',
      //     en: 'Villages'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/Villages'
      // },
      // {
      //   label: {
      //     id: 'Excel',
      //     en: 'Excel'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/log-file',
      //   permission: ''
      // },
      // {
      //   label: {
      //     id: 'Csv',
      //     en: 'Csv'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/log-file-csv',
      //   permission: ''
      // }
    ]
  },

  // PPH21
  {
    label: {
      id: 'PPh 21',
      en: 'PPh 21'
    },
    children: [{
      label: {
        id: 'Daftar SPT',
        en: 'SPT List'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/SPT_21',
      permission: 'SPT_21',
      level: CONSTANT.LEVEL.ROLE_EPPT_USER,
      user: true
    },
      {
        label: {
          id: 'BP Final',
          en: 'BP Final'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/BP_21_F',
        permission: 'BP_21_F',
        level: CONSTANT.LEVEL.ROLE_EPPT_USER,
        user: true
      },
      {
        label: {
          id: 'BP Tidak Final',
          en: 'BP Tidak Final'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/BP_21_TF',
        permission: 'BP_21_TF',
        level: CONSTANT.LEVEL.ROLE_EPPT_USER,
        user: true
      },
      {
        label: {
          id: 'BP 1721 A1',
          en: 'BP 1721 A1'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/BP_21_A1',
        permission: 'BP_21_A1',
        level: CONSTANT.LEVEL.ROLE_EPPT_USER,
        user: true
      },
      // {
      //   label: {
      //     id: 'BP 1721 A2',
      //     en: 'BP 1721 A2'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/BP_21_A2',
      //   permission: 'BP_21_A2',
      //   level: CONSTANT.LEVEL.ROLE_EPPT_USER,
      //   user: true
      // },
      {
        label: {
          id: 'BP Satu Masa',
          en: 'BP Satu Masa'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/ALL_BP_21_SATU_MASA',
        permission: 'BP_21_SATU_MASA',
        level: CONSTANT.LEVEL.ROLE_EPPT_USER,
        user: true
      },
      {
        label: {
          id: 'BP Satu Tahun',
          en: 'BP Satu Tahun'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/BP_21_SATU_TAHUN',
        permission: 'BP_21_SATU_TAHUN',
        level: CONSTANT.LEVEL.ROLE_EPPT_USER,
        user: true
      },
      // {
      //   label: {
      //     id: 'Daftar Biaya',
      //     en: 'Daftar Biaya'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/DAFTAR_BIAYA_21',
      //   permission: 'DAFTAR_BIAYA_21',
      //   level: CONSTANT.LEVEL.ROLE_EPPT_USER,
      //   // decemberOnly: true,
      //   user: true
      // },
      {
        label: {
          id: 'Daftar SSP',
          en: 'Daftar SSP'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/ALL_BP_21_SSP',
        permission: 'DAFTAR_SSP_21',
        level: CONSTANT.LEVEL.ROLE_EPPT_USER,
        user: true
      },
      // {
      //   label: {
      //     id: 'SPT Induk',
      //     en: 'SPT Induk'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/SPT_INDUK_21',
      //   permission: 'SPT_INDUK_21',
      //   level: CONSTANT.LEVEL.ROLE_EPPT_USER,
      //   user: true
      // }
    ]
  },

  // PPH23
  {
    label: {
      id: 'PPh 23/26',
      en: 'PPh 23/26'
    },
    children: [{
      label: {
        id: 'Daftar SPT',
        en: 'SPT List'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/SPT_23',
      permission: 'SPT_23',
      level: CONSTANT.LEVEL.ROLE_EPPT_USER,
      user: true
    },
      {
        label: {
          id: 'BP 23',
          en: 'BP 23'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/ALLBP23',
        permission: 'BP_23',
        level: CONSTANT.LEVEL.ROLE_EPPT_USER,
        user: true
      },
      {
        label: {
          id: 'BP 26',
          en: 'BP 26'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/ALLBP26',
        permission: 'BP_26',
        level: CONSTANT.LEVEL.ROLE_EPPT_USER,
        user: true
      },
      // {
      //   label: {
      //     id: 'SPT 23 Induk',
      //     en: 'SPT 23 Induk'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/ALLSPT23Induk',
      //   permission: 'SSP_23',
      //   level: CONSTANT.LEVEL.ROLE_EPPT_USER,
      //   user: true
      // },
      {
        label: {
          id: 'SSP 23',
          en: 'SSP 23'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/ALLSSP23',
        permission: 'SSP_23',
        level: CONSTANT.LEVEL.ROLE_EPPT_USER,
        user: true
      },
    ]
  },
  // PPH23

  // PPh4a2
  {
    label: {
      id: 'PPh 4 (2)',
      en: 'PPh 4 (2)'
    },
    children: [{
      label: {
        id: 'Daftar SPT',
        en: 'SPT List'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/SPT_4a2',
      permission: 'SPT_4a2',
      level: CONSTANT.LEVEL.ROLE_EPPT_USER,
      user: true
    },
      {
        label: {
          id: 'BP 4(2)',
          en: 'BP 4(2)'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/ALLBP4A2',
        permission: 'BP_4A2',
        level: CONSTANT.LEVEL.ROLE_EPPT_USER,
        user: true
      },
      {
        label: {
          id: 'SSP 4(2)',
          en: 'SSP 4(2)'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/ALLSSP4A2',
        permission: 'SSP_4a2',
        level: CONSTANT.LEVEL.ROLE_EPPT_USER,
        user: true
      },
      // {
      //   label: {
      //     id: 'PBK 4(2)',
      //     en: 'PBK 4(2)'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/ALLPBK4A2',
      //   permission: 'PBK_4a2',
      //   level: CONSTANT.LEVEL.ROLE_EPPT_USER,
      //   user: true
      // },
      // {
      //   label: {
      //     id: 'SPT 4(2) Induk',
      //     en: 'SPT 4(2) Induk'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/ALLSPT4a2Induk',
      //   permission: 'PBK_4a2',
      //   level: CONSTANT.LEVEL.ROLE_EPPT_USER,
      //   user: true
      // },
    ]
  },
  // PPh4a2

  // PPH22
  {
    label: {
      id: 'PPh 22',
      en: 'PPh 22'
    },
    children: [
      {
        label: {
          id: 'Daftar SPT',
          en: 'SPT List'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/SPT_22',
        permission: 'SPT_22',
        level: CONSTANT.LEVEL.ROLE_EPPT_USER,
        user: true
      },
      {
        label: {
          id: 'Bukti Pungut 22',
          en: 'Bukti Pungut 22'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/ALLBP22',
        permission: 'SPT_22',
        level: CONSTANT.LEVEL.ROLE_EPPT_USER,
        user: true
      },
      {
        label: {
          id: 'Atas Pembelian Barang Oleh Bendaharawan',
          en: 'Atas Pembelian Barang Oleh Bendaharawan'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/ALL_SSP22_PEMBELIAN_BARANG',
        permission: 'SPT_22',
        level: CONSTANT.LEVEL.ROLE_EPPT_USER,
        user: true
      },
      {
        label: {
          id: 'Oleh Bank Devisa',
          en: 'Oleh Bank Devisa'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/ALL_SSP22_OLEH_BANK_DEVISA',
        permission: 'SPT_22',
        level: CONSTANT.LEVEL.ROLE_EPPT_USER,
        user: true
      },
      {
        label: {
          id: 'Dibayar Sendiri',
          en: 'Dibayar Sendiri'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/ALL_SSP22_DIBAYAR_SENDIRI',
        permission: 'SPT_22',
        level: CONSTANT.LEVEL.ROLE_EPPT_USER,
        user: true
      },
      {
        label: {
          id: 'SSP 22',
          en: 'SSP 22'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/ALLSSP22',
        permission: 'SPT_22',
        level: CONSTANT.LEVEL.ROLE_EPPT_USER,
        user: true
      },
    ]
  },

  // PPH15
  {
    label: {
      id: 'PPh 15',
      en: 'PPh 15'
    },
    children: [
      {
        label: {
          id: 'Daftar SPT',
          en: 'SPT List'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/SPT_15',
        permission: 'SPT_15',
        level: CONSTANT.LEVEL.ROLE_EPPT_USER,
        user: true
      },
      {
        label: {
          id: 'BP 15 Dipotong Pihak Lain',
          en: 'BP 15 Dipotong Pihak Lain'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/ALLBP15DipotongPihakLain',
        permission: 'ALLBP15DipotongPihakLain',
        level: CONSTANT.LEVEL.ROLE_EPPT_USER,
        user: true
      },
      {
        label: {
          id: 'BP 15 Pihak Lain Dipotong',
          en: 'BP 15 Pihak Lain Dipotong'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/ALLBP15PihakLainDipotong',
        permission: 'ALLBP15PihakLainDipotong',
        level: CONSTANT.LEVEL.ROLE_EPPT_USER,
        user: true
      },
      {
        label: {
          id: 'BP 24',
          en: 'BP 24'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/ALLBP24',
        permission: 'ALLBP24',
        level: CONSTANT.LEVEL.ROLE_EPPT_USER,
        user: true
      },
      {
        label: {
          id: 'SSP Setor Sendiri',
          en: 'SSP Setor Sendiri'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/ALLSSP15SETORSENDIRI',
        permission: 'ALLSSP15SETORSENDIRI',
        level: CONSTANT.LEVEL.ROLE_EPPT_USER,
        user: true
      },
      {
        label: {
          id: 'SSP 15',
          en: 'SSP 15'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/ALLSSP15',
        permission: 'ALLSSP15',
        level: CONSTANT.LEVEL.ROLE_EPPT_USER,
        user: true
      },
    ]
  },
  // PPH15

  // PPH25
  {
    label: {
      id: 'PPh 25',
      en: 'PPh 25'
    },
    children: [
      {
        label: {
          id: 'Daftar SPT',
          en: 'SPT List'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/SPT_25',
        permission: 'SPT_25',
        level: CONSTANT.LEVEL.ROLE_EPPT_USER,
        user: true
      },
      {
        label: {
          id: 'Daftar Angsuran 25',
          en: 'Daftar Angsuran 25'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/ALLBP25',
        permission: 'ALLBP25',
        level: CONSTANT.LEVEL.ROLE_EPPT_USER,
        user: true
      },
      {
        label: {
          id: 'SSP 25',
          en: 'SSP 25'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/ALLSSP25',
        permission: 'ALLSSP25',
        level: CONSTANT.LEVEL.ROLE_EPPT_USER,
        user: true
      },
    ]
  },
  // PPH25

  // // EFLIING
  // {
  //   label: {
  //     id: 'eBilling',
  //     en: 'eBilling'
  //   },
  //   children: [
  //     /*{
  //       label: {
  //         id: 'Pengaturan',
  //         en: 'Pengaturan'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/EBILLING/SETTING',
  //       // permission: 'EBILLING_IMPORT_INQUIRY',
  //       user: true
  //     },
  //     {
  //       label: {
  //         id: 'Import Inquiry',
  //         en: 'Import Inquiry'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/EBILLING/IMPORT/INQUIRY',
  //       // permission: 'EBILLING_IMPORT_INQUIRY',
  //       user: true
  //     },
  //     {
  //       label: {
  //         id: 'Data Inquiry',
  //         en: 'Data Inquiry'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/EBILLING/INQUIRY',
  //       // permission: 'EBILLING_IMPORT_INQUIRY',
  //       user: true
  //     },
  //     {
  //       label: {
  //         id: 'Import ESSP',
  //         en: 'Import ESSP'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/EBILLING/IMPORT/ESSP',
  //       // permission: 'EBILLING_IMPORT_INQUIRY',
  //       user: true
  //     },*/
  //     // {
  //     //     label: {
  //     //         id: 'EBilling Widget',
  //     //         en: 'EBilling Widget'
  //     //     },
  //     //     iconClassName: 'mdi mdi-view-grid',
  //     //     path: '/ebilling-widget',
  //     // },
  //     {
  //       label: {
  //         id: 'Data ESSP',
  //         en: 'Data ESSP'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/EBILLING/DataESSP',
  //       // permission: 'EBILLING_IMPORT_INQUIRY',
  //       user: true
  //     },
  //     /*{
  //       label: {
  //         id: 'Data ESSP 23',
  //         en: 'Data ESSP 23'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/EBILLING/DataESSP23',
  //       // permission: 'EBILLING_IMPORT_INQUIRY',
  //       user: true
  //     },
  //     {
  //       label: {
  //         id: 'Data ESSP 4',
  //         en: 'Data ESSP 4'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/EBILLING/DataESSP4',
  //       // permission: 'EBILLING_IMPORT_INQUIRY',
  //       user: true
  //     },*/
  //     {
  //       label: {
  //         id: 'Billing',
  //         en: 'Billing'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/EBILLING/paymentOrder',
  //       // permission: 'EBILLING_IMPORT_INQUIRY',
  //       user: true
  //     },
  //     {
  //       label: {
  //         id: 'Order',
  //         en: 'Order'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/EBILLING/paymentNotification',
  //       // permission: 'EBILLING_IMPORT_INQUIRY',
  //       user: true
  //     },
  //     {
  //       label: {
  //         id: 'Payment Account',
  //         en: 'Payment Account'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/EBILLING/akunPembayaran',
  //       // permission: 'EBILLING_IMPORT_INQUIRY',
  //       user: true
  //     }
  //   ]
  // },

  // {
  //   label: {
  //     id: 'Log eBilling',
  //     en: 'Log eBilling'
  //   },
  //   children: [/*{
  //     label: {
  //       id: 'Log Ekspor Data Inquiry',
  //       en: 'Log Ekspor Data Inquiry'
  //     },
  //     iconClassName: 'mdi mdi-view-list',
  //     path: '/EBILLING/LOG/LogExportDataInquiry',
  //     user: true
  //   },{
  //     label: {
  //       id: 'Log Ekspor Data SSP',
  //       en: 'Log Ekspor Data SSP'
  //     },
  //     iconClassName: 'mdi mdi-view-list',
  //     path: '/EBILLING/LOG/LogExportDataSSP',
  //     user: true
  //   },*/{
  //     label: {
  //       id: 'Log Cetak Billing',
  //       en: 'Log Cetak Billing'
  //     },
  //     iconClassName: 'mdi mdi-view-list',
  //     path: '/EBILLING/LOG/LogCetakPaymentOrder',
  //     user: true
  //   },{
  //     label: {
  //       id: 'Log Cetak Order',
  //       en: 'Log Cetak Order'
  //     },
  //     iconClassName: 'mdi mdi-view-list',
  //     path: '/EBILLING/LOG/LogCetakPaymenNotification',
  //     user: true
  //   },{
  //     label: {
  //       id: 'Log Cetak BPN',
  //       en: 'Log Cetak BPN'
  //     },
  //     iconClassName: 'mdi mdi-view-list',
  //     path: '/EBILLING/LOG/LogCetakBPN',
  //     user: true
  //   },{
  //     label: {
  //       id: 'Log Laporan Billing',
  //       en: 'Log Laporan Billing'
  //     },
  //     iconClassName: 'mdi mdi-view-list',
  //     path: '/EBILLING/LOG/LogCetakLaporanPO',
  //     user: true
  //   },{
  //     label: {
  //       id: 'Log Laporan BPN',
  //       en: 'Log Laporan BPN'
  //     },
  //     iconClassName: 'mdi mdi-view-list',
  //     path: '/EBILLING/LOG/LogCetakLaporanBPN',
  //     user: true
  //   },/*{
  //     label: {
  //       id: 'Log Cetak Laporan PN',
  //       en: 'Log Cetak Laporan PN'
  //     },
  //     iconClassName: 'mdi mdi-view-list',
  //     path: '/EBILLING/LOG/LogCetakLaporanPN',
  //     user: true
  //   }*/]
  // },

  {
    label: {
      id: 'Setting & Import',
      en: 'Setting & Import'
    },
    children: [
      {
          label: {
              id: 'Summary',
              en: 'Summary'
          },
          iconClassName: 'mdi mdi-view-grid',
          path: '/merge/summary-all',
      },
      {
          label: {
              id: 'Lawan Transaksi',
              en: 'Lawan Transaksi'
          },
          iconClassName: 'mdi mdi-view-grid',
          path: '/merge/lawan-transaksi-all',
      },
      {
          label: {
              id: 'Signer',
              en: 'Signer'
          },
          iconClassName: 'mdi mdi-view-grid',
          path: '/merge/signer-all',
      },
      {
          label: {
              id: 'Signer Image',
              en: 'Signer Image'
          },
          iconClassName: 'mdi mdi-view-grid',
          path: '/merge/signer-image-all',
      },
      {
          label: {
              id: 'Import',
              en: 'Import'
          },
          iconClassName: 'mdi mdi-view-grid',
          path: '/merge/import-all',
      },
      // {
      //     label: {
      //       id: "User Akses",
      //       en: "User Access"
      //     },
      //     iconClassName: "mdi mdi-view-grid",
      //     path: "/access-control"
      // },
      {
        label: {
            id: 'Setting Email',
            en: 'Setting Email'
        },
        iconClassName: 'mdi mdi-view-grid',
        path: '/SETTING_EMAIL',
    },
    ]
  },

  {
    label: {
      id: 'Logs',
      en: 'Logs'
    },
    children: [
      {
        label: {
          id: 'Log eFiling',
          en: 'eFiling Log'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/EFILING/LOG',
        // permission: 'SPT_21',
        level: CONSTANT.LEVEL.ROLE_EPPT_USER,
        user: true
      },
      {
        label: {
          id: 'Log Email',
          en: 'Email Log'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/LOG_EMAIL',
        // permission: 'SPT_21',
        level: CONSTANT.LEVEL.ROLE_EPPT_USER,
        user: true
      },
      {
        label: {
          id: 'Log Ekspor CSV',
          en: 'Log Ekspor CSV'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/LOG_EXPORT_CSV',
        // permission: 'SPT_21',
        level: CONSTANT.LEVEL.ROLE_EPPT_USER,
        user: true
      },
      {
        label: {
          id: 'Log Ekspor PDF',
          en: 'Log Ekspor PDF'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/LOG_EXPORT_PDF',
        // permission: 'SPT_21',
        level: CONSTANT.LEVEL.ROLE_EPPT_USER,
        user: true
      },
      {
        label: {
          id: 'Log Ekspor PDF Ceklis',
          en: 'Log Ekspor PDF Ceklis'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/LOG_EXPORT_PDF_CHECKLIST',
        // permission: 'SPT_21',
        level: CONSTANT.LEVEL.ROLE_EPPT_USER,
        user: true
      },
      {
        label: {
          id: 'Log Ekspor Ebupot',
          en: 'Log Ekspor Ebupot'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/LOG_EXPORT_EBUPOT',
        // permission: 'SPT_21',
        level: CONSTANT.LEVEL.ROLE_EPPT_USER,
        user: true
      },
      {
        label: {
          id: 'Log Summary',
          en: 'Log Summary'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/LOG_SUMMARY',
        // permission: 'SPT_21',
        level: CONSTANT.LEVEL.ROLE_EPPT_USER,
        user: true
      },
      // {
      //   label: {
      //     id: 'Log eFiling 4',
      //     en: 'eFiling Log 4'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/EFILING/LOG4',
      //   // permission: 'SPT_21',
      //   level: CONSTANT.LEVEL.ROLE_EPPT_USER,
      //   user: true
      // },
    ]
  },

  // MENU BARU
  // {
  //   label: {
  //     id: 'PPh 22',
  //     en: 'PPh 22'
  //   },
  //   children: [{
  //     label: {
  //       id: 'Daftar SPT',
  //       en: 'SPT List'
  //     },
  //     iconClassName: 'mdi mdi-view-list',
  //     path: '/SPT_22',
  //     permission: 'SPT_22',
  //     level: CONSTANT.LEVEL.ROLE_EPPT_USER,
  //     user: true
  //   },{
  //     label: {
  //       id: 'Bukti Pungut 22',
  //       en: 'Bukti Pungut 22'
  //     },
  //     iconClassName: 'mdi mdi-view-list',
  //     path: '/ALLBP22',
  //     permission: 'SPT_22',
  //     level: CONSTANT.LEVEL.ROLE_EPPT_USER,
  //     user: true
  //   },{
  //     label: {
  //       id: 'SSP 22',
  //       en: 'SSP 22'
  //     },
  //     iconClassName: 'mdi mdi-view-list',
  //     path: '/ALLSSP22',
  //     permission: 'SPT_22',
  //     level: CONSTANT.LEVEL.ROLE_EPPT_USER,
  //     user: true
  //   },{
  //     label: {
  //       id: 'Atas Pembelian Barang Oleh Bendaharawan',
  //       en: 'Atas Pembelian Barang Oleh Bendaharawan'
  //     },
  //     iconClassName: 'mdi mdi-view-list',
  //     path: '/ALL_SSP22_PEMBELIAN_BARANG',
  //     permission: 'SPT_22',
  //     level: CONSTANT.LEVEL.ROLE_EPPT_USER,
  //     user: true
  //   },{
  //     label: {
  //       id: 'Oleh Bank Devisa',
  //       en: 'Oleh Bank Devisa'
  //     },
  //     iconClassName: 'mdi mdi-view-list',
  //     path: '/ALL_SSP22_OLEH_BANK_DEVISA',
  //     permission: 'SPT_22',
  //     level: CONSTANT.LEVEL.ROLE_EPPT_USER,
  //     user: true
  //   },{
  //     label: {
  //       id: 'Dibayar Sendiri',
  //       en: 'Dibayar Sendiri'
  //     },
  //     iconClassName: 'mdi mdi-view-list',
  //     path: '/ALL_SSP22_DIBAYAR_SENDIRI',
  //     permission: 'SPT_22',
  //     level: CONSTANT.LEVEL.ROLE_EPPT_USER,
  //     user: true
  //   }]
  // },
  // {
  //   label: {
  //     id: 'PPh 23/26',
  //     en: 'PPh 23/26'
  //   },
  //   children: [{
  //     label: {
  //       id: 'Daftar SPT',
  //       en: 'SPT List'
  //     },
  //     iconClassName: 'mdi mdi-view-list',
  //     path: '/SPT_23',
  //     permission: 'SPT_23',
  //     level: CONSTANT.LEVEL.ROLE_EPPT_USER,
  //     user: true
  //   },
  //     {
  //       label: {
  //         id: 'BP 23',
  //         en: 'BP 23'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/ALLBP23',
  //       permission: 'BP_23',
  //       level: CONSTANT.LEVEL.ROLE_EPPT_USER,
  //       user: true
  //     },
  //     {
  //       label: {
  //         id: 'BP 26',
  //         en: 'BP 26'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/ALLBP26',
  //       permission: 'BP_26',
  //       level: CONSTANT.LEVEL.ROLE_EPPT_USER,
  //       user: true
  //     },
  //     // {
  //     //   label: {
  //     //     id: 'SPT 23 Induk',
  //     //     en: 'SPT 23 Induk'
  //     //   },
  //     //   iconClassName: 'mdi mdi-view-list',
  //     //   path: '/ALLSPT23Induk',
  //     //   permission: 'SSP_23',
  //     //   level: CONSTANT.LEVEL.ROLE_EPPT_USER,
  //     //   user: true
  //     // },
  //     {
  //       label: {
  //         id: 'SSP 23',
  //         en: 'SSP 23'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/ALLSSP23',
  //       permission: 'SSP_23',
  //       level: CONSTANT.LEVEL.ROLE_EPPT_USER,
  //       user: true
  //     },
  //   ]
  // },

  // {
  //   label: {
  //     id: 'PPh 4 (2)',
  //     en: 'PPh 4 (2)'
  //   },
  //   children: [{
  //     label: {
  //       id: 'Daftar SPT',
  //       en: 'SPT List'
  //     },
  //     iconClassName: 'mdi mdi-view-list',
  //     path: '/SPT_4a2',
  //     permission: 'SPT_4a2',
  //     level: CONSTANT.LEVEL.ROLE_EPPT_USER,
  //     user: true
  //   },
  //     {
  //       label: {
  //         id: 'BP 4(2)',
  //         en: 'BP 4(2)'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/ALLBP4A2',
  //       permission: 'BP_4A2',
  //       level: CONSTANT.LEVEL.ROLE_EPPT_USER,
  //       user: true
  //     },
  //     {
  //       label: {
  //         id: 'SSP 4(2)',
  //         en: 'SSP 4(2)'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/ALLSSP4A2',
  //       permission: 'SSP_4a2',
  //       level: CONSTANT.LEVEL.ROLE_EPPT_USER,
  //       user: true
  //     },
  //     // {
  //     //   label: {
  //     //     id: 'PBK 4(2)',
  //     //     en: 'PBK 4(2)'
  //     //   },
  //     //   iconClassName: 'mdi mdi-view-list',
  //     //   path: '/ALLPBK4A2',
  //     //   permission: 'PBK_4a2',
  //     //   level: CONSTANT.LEVEL.ROLE_EPPT_USER,
  //     //   user: true
  //     // },
  //     // {
  //     //   label: {
  //     //     id: 'SPT 4(2) Induk',
  //     //     en: 'SPT 4(2) Induk'
  //     //   },
  //     //   iconClassName: 'mdi mdi-view-list',
  //     //   path: '/ALLSPT4a2Induk',
  //     //   permission: 'PBK_4a2',
  //     //   level: CONSTANT.LEVEL.ROLE_EPPT_USER,
  //     //   user: true
  //     // },
  //   ]
  // },
  // {
  //   label: {
  //     id: 'PPh 15',
  //     en: 'PPh 15'
  //   },
  //   children: [{
  //     label: {
  //       id: 'Daftar SPT',
  //       en: 'SPT List'
  //     },
  //     iconClassName: 'mdi mdi-view-list',
  //     path: '/SPT_15',
  //     permission: 'SPT_15',
  //     level: CONSTANT.LEVEL.ROLE_EPPT_USER,
  //     user: true
  //   },{
  //     label: {
  //       id: 'BP 15 Dipotong Pihak Lain',
  //       en: 'BP 15 Dipotong Pihak Lain'
  //     },
  //     iconClassName: 'mdi mdi-view-list',
  //     path: '/ALLBP15DipotongPihakLain',
  //     permission: 'BP_15',
  //     level: CONSTANT.LEVEL.ROLE_EPPT_USER,
  //     user: true
  //   },{
  //     label: {
  //       id: 'BP 15 Pihak Lain Dipotong',
  //       en: 'BP 15 Pihak Lain Dipotong'
  //     },
  //     iconClassName: 'mdi mdi-view-list',
  //     path: '/ALLBP15PihakLainDipotong',
  //     permission: 'BP_15',
  //     level: CONSTANT.LEVEL.ROLE_EPPT_USER,
  //     user: true
  //   },{
  //     label: {
  //       id: 'BP 24',
  //       en: 'BP 24'
  //     },
  //     iconClassName: 'mdi mdi-view-list',
  //     path: '/ALLBP24',
  //     permission: 'BP_15',
  //     level: CONSTANT.LEVEL.ROLE_EPPT_USER,
  //     user: true
  //   }, {
  //     label: {
  //       id: 'SSP Setor Sendiri',
  //       en: 'SSP Setor Sendiri'
  //     },
  //     iconClassName: 'mdi mdi-view-list',
  //     path: '/ALLSSP15SETORSENDIRI',
  //     permission: 'BP_15',
  //     level: CONSTANT.LEVEL.ROLE_EPPT_USER,
  //     user: true
  //   }, {
  //     label: {
  //       id: 'SSP 15',
  //       en: 'SSP 15'
  //     },
  //     iconClassName: 'mdi mdi-view-list',
  //     path: '/ALLSSP15',
  //     permission: 'BP_15',
  //     level: CONSTANT.LEVEL.ROLE_EPPT_USER,
  //     user: true
  //   }
  // ]},

  // {
  //   label: {
  //     id: 'Import PPh 23/26',
  //     en: 'Import PPh 23/26'
  //   },
  //   children: [
  //     {
  //       label: {
  //         id: 'BP 23',
  //         en: 'BP 23'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/IMPORT/BP_23',
  //       permission: 'IMPORT_BP_23',
  //       user: true
  //     },
  //     {
  //       label: {
  //         id: 'BP 26',
  //         en: 'BP 26'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/IMPORT/BP_26',
  //       permission: 'IMPORT_BP_26',
  //       user: true
  //     },
  //     {
  //       label: {
  //         id: 'SSP Pasal 23/26',
  //         en: 'SSP Pasal 23/26'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/IMPORT/SSP_23',
  //       permission: 'IMPORT_SSP_23',
  //       user: true
  //     },
  //     {
  //       label: {
  //         id: 'PBK 23/26',
  //         en: 'PBK 23/26'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/IMPORT/IMPORT_PBK',
  //       permission: 'IMPORT_PBK',
  //       user: true
  //     },
  //     {
  //       label: {
  //         id: 'Lawan Transaksi 23/26',
  //         en: 'Lawan Transaksi 23/26'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/IMPORT/IMPORT_PBK23',
  //       permission: 'IMPORT_PBK',
  //       user: true
  //     }
  //   ]
  // },

  // {
  //   label: {
  //     id: 'Import PPh 4(2)',
  //     en: 'Import PPh 4(2)'
  //   },
  //   children: [
  //     {
  //       label: {
  //         id: 'BP 4(2)',
  //         en: 'BP 4(2)'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/IMPORT/BP_4a2',
  //       permission: 'IMPORT_BP_4a2',
  //       user: true
  //     },
  //     {
  //       label: {
  //         id: 'Daftar Pemotongan 4 (2)',
  //         en: 'Daftar Pemotongan 4 (2)'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/IMPORT/DAFTAR_PEMOTONGAN_4a2',
  //       permission: 'IMPORT_BP_4a2',
  //       user: true
  //     },
  //     {
  //       label: {
  //         id: 'SSP Pasal 4(2)',
  //         en: 'SSP Pasal 4(2)'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/IMPORT/SSP_4a2',
  //       permission: 'IMPORT_SSP_4a2',
  //       user: true
  //     },
  //     {
  //       label: {
  //         id: 'PBK 4(2)',
  //         en: 'PBK 4(2)'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/IMPORT/PBK_4a2',
  //       permission: 'IMPORT_4a2',
  //       user: true
  //     },
  //     {
  //       label: {
  //         id: 'Induk 4(2) Penghasilan Tertentu',
  //         en: 'Induk 4(2) Penghasilan Tertentu'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/IMPORT/INDUK_PT_4a2',
  //       permission: 'IMPORT_4a2',
  //       user: true
  //     },
  //     {
  //       label: {
  //         id: 'Lawan Transaksi 4(2)',
  //         en: 'Lawan Transaksi 4(2)'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/IMPORT/LK_4a2',
  //       permission: 'IMPORT_4a2',
  //       user: true
  //     }
  //   ]
  // },

  // {
  //   label: {
  //     id: 'Import PPh 22',
  //     en: 'Import PPh 22'
  //   },
  //   children: [
  //     {
  //       label: {
  //         id: 'Bukti Pungut ',
  //         en: 'Bukti Pungut '
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/IMPORT/BP_22',
  //       permission: 'BP_22',
  //       user: true
  //     },
  //     // {
  //     //   label: {
  //     //     id: 'Bukti Pungut Industri Eksportir',
  //     //     en: 'Bukti Pungut Industri Eksportir'
  //     //   },
  //     //   iconClassName: 'mdi mdi-view-list',
  //     //   path: '/IMPORT/BP_22_industri_eksportir',
  //     //   permission: 'BP_22_industri_eksportir',
  //     //   user: true
  //     // },
  //     // {
  //     //   label: {
  //     //     id: 'Bukti Pungut Industri Importir',
  //     //     en: 'Bukti Pungut Industri Importir'
  //     //   },
  //     //   iconClassName: 'mdi mdi-view-list',
  //     //   path: '/IMPORT/BP_22_industri_importir',
  //     //   permission: 'BP_22_industri_eksportir',
  //     //   user: true
  //     // },
  //     // {
  //     //   label: {
  //     //     id: 'Bukti Pungut Badan Tertentu',
  //     //     en: 'Bukti Pungut Badan Tertentu'
  //     //   },
  //     //   iconClassName: 'mdi mdi-view-list',
  //     //   path: '/IMPORT/BP_22_badan_tertentu',
  //     //   permission: 'BP_22_badan_tertentu',
  //     //   user: true
  //     // },
  //     {
  //       label: {
  //         id: 'SSP Dibayar Sendiri',
  //         en: 'SSP Dibayar Sendiri'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/IMPORT/SSP_22_dibayar_sendiri',
  //       permission: 'SSP_22_dibayar_sendiri',
  //       user: true
  //     },
  //     // {
  //     //   label: {
  //     //     id: 'SSP Bank Devisa',
  //     //     en: 'SSP Bank Devisa'
  //     //   },
  //     //   iconClassName: 'mdi mdi-view-list',
  //     //   path: '/IMPORT/SSP_22_bank_devisa',
  //     //   permission: 'SSP_22_bank_devisa',
  //     //   user: true
  //     // },
  //     // {
  //     //   label: {
  //     //     id: 'SSP Bendaharawan',
  //     //     en: 'SSP Bendaharawan'
  //     //   },
  //     //   iconClassName: 'mdi mdi-view-list',
  //     //   path: '/IMPORT/SSP_22_bendaharawan',
  //     //   permission: 'SSP_22_bendaharawan',
  //     //   user: true
  //     // },
  //     {
  //       label: {
  //         id: 'SSP 22',
  //         en: 'SSP 22'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/IMPORT/SSP_22',
  //       permission: 'SSP_22',
  //       user: true
  //     },
  //     {
  //       label: {
  //         id: 'PBK 22',
  //         en: 'PBK 22'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/IMPORT/PBK_22',
  //       permission: 'PBK_22',
  //       user: true
  //     },
  //     {
  //       label: {
  //         id: 'Lawan Transaksi 22',
  //         en: 'Lawan Transaksi 22'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/IMPORT/LK_22',
  //       permission: 'LK_22',
  //       user: true
  //     }
  //   ]
  // },

  // {
  //   label: {
  //     id: 'Import PPh 15',
  //     en: 'Import PPh 15'
  //   },
  //   children: [
  //     {
  //       label: {
  //         id: 'Bukti Potong 15',
  //         en: 'Bukti Potong 15'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/IMPORT/BP_15',
  //       permission: 'BP_15',
  //       user: true
  //     },
  //     {
  //       label: {
  //         id: 'Bukti Potong 24',
  //         en: 'Bukti Potong 24'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/IMPORT/BP_24',
  //       permission: 'BP_24',
  //       user: true
  //     },
  //     {
  //       label: {
  //         id: 'SSP Setor Sendiri',
  //         en: 'SSP Setor Sendiri'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/IMPORT/SSP_15_SETOR_SENDIRI',
  //       permission: 'SSP_15',
  //       user: true
  //     },
  //     {
  //       label: {
  //         id: 'SSP 15',
  //         en: 'SSP 15'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/IMPORT/SSP_15',
  //       permission: 'SSP_15',
  //       user: true
  //     },
  //     {
  //       label: {
  //         id: 'PBK 15',
  //         en: 'PBK 15'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/IMPORT/PBK_15',
  //       permission: 'PBK_15',
  //       user: true
  //     },
  //     {
  //       label: {
  //         id: 'Lawan Transaksi 15',
  //         en: 'Lawan Transaksi 15'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/IMPORT/LK_15',
  //       permission: 'LK_15',
  //       user: true
  //     }
  //   ]
  // },

  // // {
  // //   label: {
  // //     id: 'eFiling',
  // //     en: 'eFiling'
  // //   },
  // //   children: [{
  // //     label: {
  // //       id: 'PPh 21',
  // //       en: 'PPh 21'
  // //     },
  // //     iconClassName: 'mdi mdi-file-multiple',
  // //     path: '/eFiling/21',
  // //   },{
  // //     label: {
  // //       id: 'PPh 23/26',
  // //       en: 'PPh 23/26'
  // //     },
  // //     iconClassName: 'mdi mdi-file-multiple',
  // //     path: '/eFiling/23',
  // //   },{
  // //     label: {
  // //       id: 'PPh 4(2)',
  // //       en: 'PPh 4(2)'
  // //     },
  // //     iconClassName: 'mdi mdi-file-multiple',
  // //     path: '/eFiling/4A2',
  // //   },
  //     // {
  //     //   label: {
  //     //     id: 'PPh 15',
  //     //     en: 'PPh 15'
  //     //   },
  //     //   iconClassName: 'mdi mdi-file-multiple',
  //     //   path: '/eFiling/15',
  //     // }
  // //   ]
  // // },

  // {
  //   label: {
  //     id: 'Log',
  //     en: 'Log'
  //   },
  //   children: [
  //     {
  //       label: {
  //         id: 'Log CSV',
  //         en: 'Log CSV'
  //       },
  //       iconClassName: 'mdi mdi-file-multiple',
  //       path: '/logExport/CSV',
  //       user: true
  //     },
  //     {
  //       label: {
  //         id: 'Log PDF',
  //         en: 'Log PDF'
  //       },
  //       iconClassName: 'mdi mdi-file-multiple',
  //       path: '/logExport/PDF',
  //       user: true
  //     },
  //     {
  //       label: {
  //         id: 'Log eFiling',
  //         en: 'Log eFiling'
  //       },
  //       iconClassName: 'mdi mdi-file-multiple',
  //       path: '/eFiling',
  //       user: true
  //     },
  //     {
  //       label: {
  //         id: 'Log Email',
  //         en: 'Log Email'
  //       },
  //       iconClassName: 'mdi mdi-file-multiple',
  //       path: '/LOG_EMAIL',
  //       permission: 'LOG_EMAIL',
  //       user: true
  //     }
  //   ]
  // },
  // {
  //   label: {
  //     id: 'Master Data Tarif Pajak Pasal 21',
  //     en: 'Master Data Tarif Pajak Pasal 21'
  //   },
  //   children: [
  //     {
  //       label: {
  //         id: 'Pasal 17 Berlapis',
  //         en: 'Pasal 17 Berlapis'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/master/pasal-21-pasal-17-berlapis',
  //       permission: '',
  //       admin: true,
  //       level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
  //     },
  //     {
  //       label: {
  //         id: 'Biaya Jabatan',
  //         en: 'Biaya Jabatan'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/master/pasal-21-biaya-jabatan',
  //       permission: '',
  //       admin: true,
  //       level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
  //     },
  //     {
  //       label: {
  //         id: 'Biaya Pensiun',
  //         en: 'Biaya Pensiun'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/master/pasal-21-biaya-pensiun',
  //       permission: '',
  //       admin: true,
  //       level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
  //     },
  //     {
  //       label: {
  //         id: 'PTKP',
  //         en: 'PTKP'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/master/pasal-21-ptkp',
  //       permission: '',
  //       admin: true,
  //       level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
  //     },
  //     {
  //       label: {
  //         id: 'Tarif Pensiun',
  //         en: 'Tarif Pensiun'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/master/pasal-21-pensiun',
  //       permission: '',
  //       admin: true,
  //       level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
  //     },
  //     {
  //       label: {
  //         id: 'Upah Harian',
  //         en: 'Upah Harian'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/master/pasal-21-upah-harian',
  //       permission: '',
  //       admin: true,
  //       level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
  //     },
  //     {
  //       label: {
  //         id: 'Upah Kumulatif',
  //         en: 'Upah Kumulatif'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/master/pasal-21-upah-kumulatif',
  //       permission: '',
  //       admin: true,
  //       level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
  //     },
  //     {
  //       label: {
  //         id: 'Final Pesangon',
  //         en: 'Final Pesangon'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/master/pasal-21-final-pesangon',
  //       permission: '',
  //       admin: true,
  //       level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
  //     },
  //     {
  //       label: {
  //         id: 'Honor/Imbalan PNS',
  //         en: 'Honor/Imbalan PNS'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/master/pasal-21-honor-pns',
  //       permission: '',
  //       admin: true,
  //       level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
  //     }
  //   ]
  // },
  // {
  //   label: {
  //     id: 'Master Data Kode SPT Pasal 21',
  //     en: 'Master Data Kode SPT Pasal 21'
  //   },
  //   children: [
  //     {
  //       label: {
  //         id: 'Status Karyawan A1',
  //         en: 'Status Karyawan A1'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/master/pasal-21-status-karyawan-a1',
  //       permission: '',
  //       admin: true,
  //       level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
  //     },
  //     {
  //       label: {
  //         id: 'Status Karyawan A2',
  //         en: 'Status Karyawan A2'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/master/pasal-21-status-karyawan-a2',
  //       permission: '',
  //       admin: true,
  //       level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
  //     },
  //     {
  //       label: {
  //         id: 'KAP',
  //         en: 'KAP'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/master/pasal-21-kap',
  //       permission: '',
  //       admin: true,
  //       level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
  //     },
  //     {
  //       label: {
  //         id: 'KJS',
  //         en: 'KJS'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/master/pasal-21-kjs',
  //       permission: '',
  //       admin: true,
  //       level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
  //     },
  //     {
  //       label: {
  //         id: 'SSP Description',
  //         en: 'SSP Description'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/master/pasal-21-ssp-description',
  //       permission: '',
  //       admin: true,
  //       level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
  //     },
  //     {
  //       label: {
  //         id: 'Golongan A2',
  //         en: 'Golongan A2'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/master/pasal-21-golongan-a2',
  //       permission: '',
  //       admin: true,
  //       level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
  //     }
  //   ]
  // },
  // {
  //   label: {
  //     id: 'Master Data 23/26',
  //     en: 'Master Data 23/26'
  //   },
  //   children: [
  //     {
  //       label: {
  //         id: 'KAP',
  //         en: 'KAP'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/master/pasal-23-kap',
  //       permission: '',
  //       admin: true,
  //       level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
  //     },
  //     {
  //       label: {
  //         id: 'KJS',
  //         en: 'KJS'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/master/pasal-23-kjs',
  //       permission: '',
  //       admin: true,
  //       level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
  //     },
  //     {
  //       label: {
  //         id: 'Tarif 23 Lain',
  //         en: 'Tarif 23 Lain'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/master/tarif-23-lain',
  //       permission: '',
  //       admin: true,
  //       level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
  //     },
  //     {
  //       label: {
  //         id: 'Tarif 23',
  //         en: 'Tarif 23'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/master/tarif-23',
  //       permission: '',
  //       owner: true,
  //       level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
  //     },
  //     {
  //       label: {
  //         id: 'Tarif 26',
  //         en: 'Tarif 26'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/master/tarif-26',
  //       permission: '',
  //       owner: true,
  //       level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
  //     }
  //   ]
  // },
  // {
  //   label: {
  //     id: 'Master Data 4 (2)',
  //     en: 'Master Data 4 (2)'
  //   },
  //   children: [
  //     {
  //       label: {
  //         id: 'Tarif 4 (2)',
  //         en: 'Tarif 4 (2)'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/master/tarif-4a2',
  //       permission: '',
  //       owner: true,
  //       level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
  //     },
  //   ]
  // },
  // {
  //   label: {
  //     id: 'Master Data 22',
  //     en: 'Master Data 22'
  //   },
  //   children: [
  //     {
  //       label: {
  //         id: 'Komoditi 22',
  //         en: 'Komoditi 22'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/master/komoditi-22',
  //       permission: '',
  //       admin: true,
  //       level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
  //     },
  //     {
  //       label: {
  //         id: 'Tarif 22',
  //         en: 'Tarif 22'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/master/tarif-22',
  //       permission: '',
  //       admin: true,
  //       level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
  //     },
  //   ]
  // },
  // /*{
  //   label: {
  //     id: 'Master Data 15',
  //     en: 'Master Data 15'
  //   },
  //   children: [
  //     {
  //       label: {
  //         id: 'Tarif 15',
  //         en: 'Tarif 15'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/master/tarif-15',
  //       permission: '',
  //       admin: true,
  //       level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
  //     },
  //   ]
  // },*/
  // {
  //   label: {
  //     id: 'Master Data 22',
  //     en: 'Master Data 22'
  //   },
  //   children: [
  //     {
  //       label: {
  //         id: 'Tarif 22',
  //         en: 'Tarif 22'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/company/tarif-22',
  //       permission: '',
  //       owner: true,
  //       level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
  //     },
  //   ]
  // },
  // {
  //   label: {
  //     id: 'Master Data 15',
  //     en: 'Master Data 15'
  //   },
  //   children: [
  //     {
  //       label: {
  //         id: 'Tarif 15',
  //         en: 'Tarif 15'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/company/tarif-15',
  //       permission: '',
  //       owner: true,
  //       level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
  //     },
  //   ]
  // },
  // {
  //   label: {
  //     id: 'Lawan Transaksi',
  //     en: 'Lawan Transaksi'
  //   },
  //   children: [
  //     {
  //       label: {
  //         id: 'PPH 21 A1',
  //         en: 'PPH 21 A1'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/lawanTransaksi/pph21/a1',
  //       permission: '',
  //       user: true,
  //       // owner: true,
  //       level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
  //     },
  //     {
  //       label: {
  //         id: 'PPH 21 A2',
  //         en: 'PPH 21 A2'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/lawanTransaksi/pph21/a2',
  //       permission: '',
  //       user: true,
  //       // owner: true,
  //       level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
  //     },
  //     {
  //       label: {
  //         id: 'PPH 21 Pp',
  //         en: 'PPH 21 Pp'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/lawanTransaksi/pph21/pp',
  //       permission: '',
  //       user: true,
  //       // owner: true,
  //       level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
  //     },
  //     {
  //       label: {
  //         id: 'PPH 23/26',
  //         en: 'PPH 23/26'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/lawanTransaksi/pph23',
  //       permission: '',
  //       user: true,
  //       // owner: true,
  //       level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
  //     },
  //     {
  //       label: {
  //         id: 'PPH 4(2)',
  //         en: 'PPH 4(2)'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/lawanTransaksi/4a2',
  //       permission: '',
  //       user: true,
  //       // owner: true,
  //       level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
  //     },
  //     {
  //       label: {
  //         id: 'PPH 22',
  //         en: 'PPH 22'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/lawanTransaksi/22',
  //       permission: '',
  //       user: true,
  //       // owner: true,
  //       level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
  //     },
  //     {
  //       label: {
  //         id: 'PPH 15',
  //         en: 'PPH 15'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/lawanTransaksi/15',
  //       permission: '',
  //       user: true,
  //       // owner: true,
  //       level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
  //     },
  //   ]
  // },
  // {
  //   label: {
  //     id: 'Pengaturan',
  //     en: 'Pengaturan'
  //   },
  //   children: [
  //     {
  //       label: {
  //         id: 'Export/Email',
  //         en: 'Export/Email'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/SETTING_EXPORT',
  //       permission: '',
  //       user: true,
  //       // owner: true,
  //       level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
  //     },
  //   ]
  // },

  /*{
    label: {
      id: 'Kalkulasi Induk',
      en: 'Kalkulasi Induk'
    },
    children: [{
      label: {
        id: 'Pasal 21',
        en: 'Pasal 21'
      },
      iconClassName: 'mdi mdi-file-multiple',
      path: '/kalkulasiInduk/21',
      admin: true
    },{
      label: {
        id: 'Pasal 23',
        en: 'Pasal 23'
      },
      iconClassName: 'mdi mdi-file-multiple',
      path: '/kalkulasiInduk/23',
      admin: true
    },{
      label: {
        id: 'Pasal 4(2)',
        en: 'Pasal 4(2)'
      },
      iconClassName: 'mdi mdi-file-multiple',
      path: '/kalkulasiInduk/4A2',
      admin: true
    }]
  },*/

  {
    label: {
      id: 'Pengaturan Nomor BP',
      en: 'Pengaturan Nomor BP'
    },
    children: [
      {
        label: {
          id: 'Nomor BP 21',
          en: 'Nomor BP 21'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/nomorBp/21',
        permission: '',
        // user: true,
        owner: true,
        level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
      },{
        label: {
          id: 'Nomor BP 23',
          en: 'Nomor BP 23'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/nomorBp/23',
        permission: '',
        // user: true,
        owner: true,
        level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
      },{
        label: {
          id: 'Nomor BP 4(2)',
          en: 'Nomor BP 4(2)'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/nomorBp/4a2',
        permission: '',
        // user: true,
        owner: true,
        level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
      },{
        label: {
          id: 'Nomor BP 22',
          en: 'Nomor BP 22'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/nomorBp/22',
        permission: '',
        // user: true,
        owner: true,
        level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
      },{
        label: {
          id: 'Nomor BP 15',
          en: 'Nomor BP 15'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/nomorBp/15',
        permission: '',
        // user: true,
        owner: true,
        level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
      },
    ]
  },
  // ADMIN
  {
    label: {
      id: 'Administrator',
      en: 'Administrator'
    },
    children: [{
      label: {
        id: 'Flow Approval',
        en: 'Flow Approval'
      },
      iconClassName: 'mdi mdi-contacts',
      path: '/mainFlow',
      admin: true,
      level: CONSTANT.LEVEL.ROLE_EPPT_OWNER
    },{
      label: {
        id: 'User',
        en: 'User'
      },
      iconClassName: 'mdi mdi-contacts',
      path: '/user',
      admin: true,
      level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
    },
    {
      label: {
        id: 'Master KPP',
        en: 'Master KPP'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/master/kpp',
      permission: '',
      admin: true,
      level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
    },
      // {
      //   label: {
      //     id: 'Organisasi',
      //     en: 'Organization'
      //   },
      //   iconClassName: 'mdi mdi-contacts',
      //   path: '/organization',
      //   admin: true,
      //   level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
      // },
      {
        label: {
          id: 'Permissions',
          en: 'Perizinan'
        },
        iconClassName: 'mdi mdi-contacts',
        path: '/permissions',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
      },
      // {
      //   label: {
      //     id: 'Akses',
      //     en: 'Access'
      //   },
      //   iconClassName: 'mdi mdi-contacts',
      //   path: '/access',
      //   admin: true
      // },
      {
        label: {
          id: 'Pengaturan Akses',
          en: 'Akses Configuration'
        },
        iconClassName: 'mdi mdi-contacts',
        path: '/access-control',
        admin: true,
        level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
      },
      {
        label: {
          id: 'Role Template',
          en: 'Template Role'
        },
        iconClassName: 'mdi mdi-contacts',
        admin: true,
        path: '/templateRole'
      },
      {
        label: {
          id: 'Logging Events',
          en: 'Logging Events'
        },
        iconClassName: 'mdi mdi-contacts',
        admin: true,
        path: '/loggingEvent'
      },
      {
        label: {
          id: 'Log Aplikasi',
          en: 'Log Aplikasi'
        },
        iconClassName: 'mdi mdi-contacts',
        admin: true,
        path: '/logApp'
      },
      {
        label: {
          id: 'Log eFiling',
          en: 'Log eFiling'
        },
        iconClassName: 'mdi mdi-contacts',
        admin: true,
        path: '/logEfiling',
      }
    ]
  },
  {
    label: {
      id: 'Pengaturan',
      en: 'Setting'
    },
    children: [
      {
        label: {
          id: 'E-Mail',
          en: 'E-Mail'
        },
        owner: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_EMAIL',
        permission: 'SETTING_EMAIL'
      },
      {
        label: {
          id: 'Konten E-Mail',
          en: 'E-Mail Content'
        },
        owner: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_EMAIL_CONTENT',
        permission: 'SETTING_EMAIL_CONTENT'
      },
      {
        label: {
          id: 'Jadwal E-Mail',
          en: 'E-Mail Schedule'
        },
        owner: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_EMAIL_SCHEDULE',
        permission: 'SETTING_EMAIL_SCHEDULE'
      },
      {
        label: {
          id: 'E-Filing',
          en: 'E-Filing'
        },
        owner: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_EFILING',
        permission: 'SETTING_EFILING'
      },
      {
        label: {
          id: 'Impor',
          en: 'Import'
        },
        owner: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_IMPORT',
        permission: 'SETTING_IMPORT'
      },
      {
        label: {
          id: 'Role Template',
          en: 'Template Role'
        },
        iconClassName: 'mdi mdi-settings',
        owner: true,
        path: '/templateRole'
      },
      {
        label: {
          id: 'About',
          en: 'About'
        },
        iconClassName: 'mdi mdi-settings',
        owner: true,
        path: '/aboutCompany'
      }
    ]
  },
  {
    label: {
      id: 'Pengaturan',
      en: 'Setting'
    },
    children: [
      {
        label: {
          id: 'E-Mail',
          en: 'E-Mail'
        },
        admin: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_EMAIL_ALL',
        permission: 'SETTING_EMAIL_ALL'
      },
      {
        label: {
          id: 'Konten E-Mail',
          en: 'E-Mail Content'
        },
        admin: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_EMAIL_CONTENT_ALL',
        permission: 'SETTING_EMAIL_CONTENT_ALL'
      },
      {
        label: {
          id: 'Jadwal E-Mail',
          en: 'E-Mail Schedule'
        },
        admin: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_EMAIL_SCHEDULE_ALL',
        permission: 'SETTING_EMAIL_SCHEDULE_ALL'
      },
      {
        label: {
          id: 'E-Filing',
          en: 'E-Filing'
        },
        admin: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_EFILING_ALL',
        permission: 'SETTING_EFILING_ALL'
      },
      {
        label: {
          id: 'Impor',
          en: 'Import'
        },
        admin: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_IMPORT_ALL',
        permission: 'SETTING_IMPORT_ALL'
      },
    ]
  },
]

const companyMenu = [
  {
    label: {
      id: 'Main',
      en: 'Main'
    },
    children: [{
      label: {
        id: 'Dasboard',
        en: 'Dasboard'
      },
      iconClassName: 'mdi mdi-view-grid',
      path: '/'
    }]
  },
  {
    label: {
      id: 'Organisasi',
      en: 'Organization'
    },
    children: [{
      label: {
        id: 'Organisasi',
        en: 'Organization'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/organization',
      permission: 'organization'
    }]
  },
  {
    label: {
      id: 'Pengguna',
      en: 'User'
    },
    children: [{
      label: {
        id: 'User',
        en: 'User'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/user',
      permission: 'user'
    },{
      label: {
        id: 'Owner',
        en: 'Owner'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/owner',
      permission: 'owner'
    }]
  },
  // IMPORT OWNER
  {
    label: {
      id: 'Import',
      en: 'Import'
    },
    children: [
      {
        label: {
          id: 'Pengguna',
          en: 'User'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/USER',
        level: CONSTANT.LEVEL.ROLE_EPPT_OWNER,
        user: true
      },
      {
        label: {
          id: 'Penandatangan PPh 21',
          en: 'Signer PPh 21'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SIGNER21',
        // permission: 'IMPORT_BP_21_TF',
        level: CONSTANT.LEVEL.ROLE_EPPT_OWNER,
        user: true
      },
      {
        label: {
          id: 'Penandatangan PPh 23',
          en: 'Signer PPh 23'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SIGNER23',
        // permission: 'IMPORT_BP_21_TF',
        level: CONSTANT.LEVEL.ROLE_EPPT_OWNER,
        user: true
      },
      {
        label: {
          id: 'Penandatangan PPh 4(2)',
          en: 'Signer PPh 4(2)'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SIGNER4A2',
        // permission: 'IMPORT_BP_21_TF',
        level: CONSTANT.LEVEL.ROLE_EPPT_OWNER,
        user: true
      },
      {
        label: {
          id: 'Penandatangan PPh 22',
          en: 'Signer PPh 22'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SIGNER22',
        // permission: 'IMPORT_BP_21_TF',
        level: CONSTANT.LEVEL.ROLE_EPPT_OWNER,
        user: true
      },
      {
        label: {
          id: 'Penandatangan PPh 15',
          en: 'Signer PPh 15'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SIGNER15',
        // permission: 'IMPORT_BP_21_TF',
        level: CONSTANT.LEVEL.ROLE_EPPT_OWNER,
        user: true
      },
    ]
  },
  {
    label: {
      id: 'PPh 21',
      en: 'PPh 21'
    },
    children: [{
      label: {
        id: 'Daftar SPT',
        en: 'SPT List'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/SPT_21',
      permission: 'SPT_21',
      user: true
    }]
  },


  // {
  //   label: {
  //     id: 'eFiling',
  //     en: 'eFiling'
  //   },
  //   children: [{
  //     label: {
  //       id: 'PPh 21',
  //       en: 'PPh 21'
  //     },
  //     iconClassName: 'mdi mdi-file-multiple',
  //     path: '/eFiling/21',
  //   },{
  //     label: {
  //       id: 'PPh 23/26',
  //       en: 'PPh 23/26'
  //     },
  //     iconClassName: 'mdi mdi-file-multiple',
  //     path: '/eFiling/23',
  //   },{
  //     label: {
  //       id: 'PPh 4(2)',
  //       en: 'PPh 4(2)'
  //     },
  //     iconClassName: 'mdi mdi-file-multiple',
  //     path: '/eFiling/4A2',
  //   },
      // {
      //   label: {
      //     id: 'PPh 15',
      //     en: 'PPh 15'
      //   },
      //   iconClassName: 'mdi mdi-file-multiple',
      //   path: '/eFiling/15',
      // }
  //   ]
  // },

  {
    label: {
      id: 'Log',
      en: 'Log'
    },
    children: [{
      label: {
        id: 'Log CSV',
        en: 'Log CSV'
      },
      iconClassName: 'mdi mdi-file-multiple',
      path: '/logExport/all',
      user: true
    },
      {
        label: {
          id: 'Log PDF',
          en: 'Log PDF'
        },
        iconClassName: 'mdi mdi-file-multiple',
        path: '/logExport/PDF',
        user: true
      },
      {
        label: {
          id: 'Log eFiling',
          en: 'Log eFiling'
        },
        iconClassName: 'mdi mdi-file-multiple',
        path: '/eFiling',
        user: true
      },
      // {
      //   label: {
      //     id: 'PPh 23/26',
      //     en: 'PPh 23/26'
      //   },
      //   iconClassName: 'mdi mdi-file-multiple',
      //   path: '/logExport/23',
      // },{
      //   label: {
      //     id: 'PPh 4(2)',
      //     en: 'PPh 4(2)'
      //   },
      //   iconClassName: 'mdi mdi-file-multiple',
      //   path: '/logExport/4A2',
      // },
      // {
      //   label: {
      //     id: 'PPh 15',
      //     en: 'PPh 15'
      //   },
      //   iconClassName: 'mdi mdi-file-multiple',
      //   path: '/logExport/15',
      // }
    ]
  },

  // {
  //   label: {
  //     id: 'Log',
  //     en: 'Log'
  //   },
  //   children: [
  //     // {
  //     //   label: {
  //     //     id: 'Log File Lapor',
  //     //     en: 'Log File Lapor'
  //     //   },
  //     //   iconClassName: 'mdi mdi-file-multiple',
  //     //   path: '/LOG_FILE_LAPOR_21',
  //     //   permission: 'LOG_FILE_LAPOR_21'
  //     // },
  //     {
  //       label: {
  //         id: 'Log Ekspor CSV',
  //         en: 'Log Ekspor CSV'
  //       },
  //       iconClassName: 'mdi mdi-file-multiple',
  //       path: '/LOG_EXPORT_CSV_21',
  //       permission: 'LOG_EXPORT_CSV_21'
  //     },
  //     {
  //       label: {
  //         id: 'Log Email',
  //         en: 'Log Email'
  //       },
  //       iconClassName: 'mdi mdi-file-multiple',
  //       path: '/LOG_EMAIL',
  //       permission: 'LOG_EMAIL'
  //     }
  //   ]
  // },
  {
    label: {
      id: 'Master Data 23/26',
      en: 'Master Data 23/26'
    },
    children: [
      {
        label: {
          id: 'Tarif 23',
          en: 'Tarif 23'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/tarif-23',
        permission: '',
        level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
      },
      {
        label: {
          id: 'Tarif 23 Lain',
          en: 'Tarif 23 Lain'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/tarif-23-lain',
        permission: '',
        owner: true,
        level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
      },
      {
        label: {
          id: 'Tarif 26',
          en: 'Tarif 26'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/tarif-26',
        permission: '',
        owner: true,
        level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
      }
    ]
  },
  {
    label: {
      id: 'Master Data 4 (2)',
      en: 'Master Data 4 (2)'
    },
    children: [
      {
        label: {
          id: 'Tarif 4 (2)',
          en: 'Tarif 4 (2)'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/tarif-4a2',
        permission: '',
        level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
      },
    ]
  },
  // END IMPORT OWNER
  {
    label: {
      id: 'Lawan Transaksi',
      en: 'Lawan Transaksi'
    },
    children: [
      {
        label: {
          id: 'PPH 21 A1',
          en: 'PPH 21 A1'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/lawanTransaksi/pph21/a1',
        permission: '',
        level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
      },
      {
        label: {
          id: 'PPH 21 A2',
          en: 'PPH 21 A2'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/lawanTransaksi/pph21/a2',
        permission: '',
        level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
      },
      {
        label: {
          id: 'PPH 21 Pp',
          en: 'PPH 21 Pp'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/lawanTransaksi/pph21/pp',
        permission: '',
        level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
      },
      {
        label: {
          id: 'PPH 23/26',
          en: 'PPH 23/26'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/lawanTransaksi/pph23',
        permission: '',
        level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
      },
      {
        label: {
          id: 'PPH 4(2)',
          en: 'PPH 4(2)'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/lawanTransaksi/4a2',
        permission: '',
        level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
      },
      {
        label: {
          id: 'PPH 22',
          en: 'PPH 22'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/lawanTransaksi/22',
        permission: '',
        level: CONSTANT.LEVEL.ROLE_EPPT_ADMIN
      },
    ]
  },
  {
    label: {
      id: 'Pengaturan',
      en: 'Setting'
    },
    children: [
      {
        label: {
          id: 'E-Mail',
          en: 'E-Mail'
        },
        admin: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_EMAIL',
        permission: 'SETTING_EMAIL'
      },
      {
        label: {
          id: 'Konten E-Mail',
          en: 'E-Mail Content'
        },
        admin: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_EMAIL_CONTENT',
        permission: 'SETTING_EMAIL_CONTENT'
      },
      {
        label: {
          id: 'Jadwal E-Mail',
          en: 'E-Mail Schedule'
        },
        admin: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_EMAIL_SCHEDULE',
        permission: 'SETTING_EMAIL_SCHEDULE'
      },
      {
        label: {
          id: 'E-Filing',
          en: 'E-Filing'
        },
        admin: true,
        iconClassName: 'mdi mdi-settings',
        path: '/SETTING_EFILING',
        permission: 'SETTING_EFILING'
      },
      {
        label: {
          id: 'Role Template',
          en: 'Template Role'
        },
        iconClassName: 'mdi mdi-settings',
        admin: true,
        path: '/templateRole'
      },
      {
        label: {
          id: 'About',
          en: 'About'
        },
        iconClassName: 'mdi mdi-settings',
        path: '/aboutCompany'
      }
    ]
  }
]

const sptPph21Menus = [
  {
    label: {
      id: 'Dashboard',
      en: 'Dashboard'
    },
    children: [
      {
        label: {
          id: 'Dashboard',
          en: 'Dashboard'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/EXPORT_CSV_21',
        permission: 'EXPORT_CSV_21'
      }
    ]
  },
  {
    label: {
      id: 'Bukti Potong',
      en: 'Bukti Potong'
    },
    children: [{
      label: {
        id: 'Tidak Final',
        en: 'Tidak Final'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/BP_21_TF',
      permission: 'BP_21_TF'
    },
      {
        label: {
          id: 'Final',
          en: 'Final'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/BP_21_F',
        permission: 'BP_21_F'
      },
      {
        label: {
          id: 'BP 1721 A1',
          en: 'BP 1721 A1'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/BP_21_A1',
        permission: 'BP_21_A1',
        decemberOnly: true
      },
      // {
      //   label: {
      //     id: 'BP 1721 A2',
      //     en: 'BP 1721 A2'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/BP_21_A2',
      //   permission: 'BP_21_A2',
      //   decemberOnly: true,
      //   bendaharaOnly: true
      // }
    ]
  },
  {
    label: {
      id: 'Pemotongan',
      en: 'Pemotongan'
    },
    children: [{
      label: {
        id: 'Satu Masa',
        en: 'Satu Masa'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/BP_21_SATU_MASA',
      permission: 'BP_21_SATU_MASA'
    },
      {
        label: {
          id: 'Satu Tahun',
          en: 'Satu Tahun'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/BP_21_SATU_TAHUN',
        permission: 'BP_21_SATU_TAHUN',
        decemberOnly: true
      },
    ]
  },
  {
    label: {
      id: 'Lainnya',
      en: 'Lainnya'
    },
    children: [{
      label: {
        id: 'Daftar Biaya',
        en: 'Daftar Biaya'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/DAFTAR_BIAYA_21',
      permission: 'DAFTAR_BIAYA_21',
      decemberOnly: true
    },
      {
        label: {
          id: 'Daftar SSP',
          en: 'Daftar SSP'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/DAFTAR_SSP_21',
        permission: 'DAFTAR_SSP_21'
      },
      {
        label: {
          id: 'SPT Induk',
          en: 'SPT Induk'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/SPT_INDUK_21',
        permission: 'SPT_INDUK_21'
      },
    ]
  },
  // {
  //   label: {
  //     id: 'Log Report',
  //     en: 'Log Report'
  //   },
  //   children: [{
  //     label: {
  //       id: 'Log Report',
  //       en: 'Log Report'
  //     },
  //     iconClassName: 'mdi mdi-view-list',
  //     path: '/LOG_REPORT_21',
  //     permission: 'SPT_21',
  //     user: true
  //   }]
  // },
  /*{
    label: {
      id: 'Master Data Tarif',
      en: 'Master Data Tarif'
    },
    children: [
      {
        label: {
          id: 'Pasal 17 Berlapis',
          en: 'Pasal 17 Berlapis'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-pasal-17-berlapis',
        permission: ''
      },
      {
        label: {
          id: 'Biaya Jabatan',
          en: 'Biaya Jabatan'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-biaya-jabatan',
        permission: ''
      },
      {
        label: {
          id: 'Biaya Pensiun',
          en: 'Biaya Pensiun'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-biaya-pensiun',
        permission: ''
      },
      {
        label: {
          id: 'PTKP',
          en: 'PTKP'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-ptkp',
        permission: ''
      },
      {
        label: {
          id: 'Tarif Pensiun',
          en: 'Tarif Pensiun'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-pensiun',
        permission: ''
      },
      {
        label: {
          id: 'Upah Harian',
          en: 'Upah Harian'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-upah-harian',
        permission: ''
      },
      {
        label: {
          id: 'Upah Kumulatif',
          en: 'Upah Kumulatif'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-upah-kumulatif',
        permission: ''
      },
      {
        label: {
          id: 'Final Pesangon',
          en: 'Final Pesangon'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-final-pesangon',
        permission: ''
      },
      {
        label: {
          id: 'Honor/Imbalan PNS',
          en: 'Honor/Imbalan PNS'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-honor-pns',
        permission: ''
      }
    ]
  },*/
  // Import 21 Here
  {
    label: {
      id: 'Import PPh 21',
      en: 'Import PPh 21'
    },
    children: [
      {
        label: {
          id: 'BP Tidak Final',
          en: 'BP Tidak Final'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_21_TF',
        permission: 'IMPORT_BP_21_TF',
        level: CONSTANT.LEVEL.ROLE_EPPT_USER,
        user: true
      },
      {
        label: {
          id: 'BP Final',
          en: 'BP Final'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_21_F',
        permission: 'IMPORT_BP_21_F',
        level: CONSTANT.LEVEL.ROLE_EPPT_USER,
        user: true
      },
      {
        label: {
          id: 'BP 1721 A1',
          en: 'BP 1721 A1'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_21_A1',
        permission: 'IMPORT_BP_21_A1',
        level: CONSTANT.LEVEL.ROLE_EPPT_USER,
        user: true
      },
      {
        label: {
          id: 'BP 1721 A1 Auto',
          en: 'BP 1721 A1 Auto'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_21_A1_AUTO',
        permission: 'IMPORT_BP_21_A1',
        level: CONSTANT.LEVEL.ROLE_EPPT_USER,
        user: true
      },
      // {
      //   label: {
      //     id: 'BP 1721 A2',
      //     en: 'BP 1721 A2'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/IMPORT/BP_21_A2',
      //   permission: 'IMPORT_BP_21_A2',
      //   level: CONSTANT.LEVEL.ROLE_EPPT_USER,
      //   user: true
      // },
      {
        label: {
          id: 'Pemotongan Satu Masa Pajak',
          en: 'Pemotongan Satu Masa Pajak'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_21_SATU_MASA',
        permission: 'IMPORT_BP_21_SATU_MASA',
        level: CONSTANT.LEVEL.ROLE_EPPT_USER,
        user: true
      },
      {
        label: {
          id: 'Pemotongan Satu Masa Pajak TL',
          en: 'Pemotongan Satu Masa Pajak TL'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_21_SATU_MASA_TIDAK_LEBIH',
        permission: 'IMPORT_BP_21_SATU_MASA',
        level: CONSTANT.LEVEL.ROLE_EPPT_USER,
        user: true
      },
      {
        label: {
          id: 'SSP Pasal 21',
          en: 'SSP Pasal 21'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SSP_21',
        permission: 'IMPORT_SSP_21',
        level: CONSTANT.LEVEL.ROLE_EPPT_USER,
        user: true
      },
      {
        label: {
          id: 'Daftar Biaya Pasal 21',
          en: 'Daftar Biaya Pasal 21'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/DAFTAR_BIAYA_21',
        permission: 'IMPORT_DAFTAR_BIAYA_21',
        level: CONSTANT.LEVEL.ROLE_EPPT_USER,
        user: true
      },
      // {
      //   label: {
      //     id: 'Induk Pasal 21',
      //     en: 'Induk Pasal 21'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/IMPORT/INDUK_21',
      //   permission: 'IMPORT_INDUK_21',
      //   level: CONSTANT.LEVEL.ROLE_EPPT_USER,
      //   user: true
      // },
      // {
      //   label: {
      //     id: 'Lawan Transaksi 21',
      //     en: 'Lawan Transaksi 21'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/IMPORT/LK_21',
      //   permission: 'IMPORT_LK_21'
      // },
    ]
  },
  {
    label: {
      id: 'Pengaturan',
      en: 'Setting'
    },
    children: [
      {
        label: {
          id: 'Nomor Bukti Potong',
          en: 'Nomor Bukti Potong'
        },
        iconClassName: 'mdi mdi-file-multiple',
        path: '/SETTING_NO_BUPOT_21',
        user: true
      },
      // {
      //   label: {
      //     id: 'Signer',
      //     en: 'Signer'
      //   },
      //   iconClassName: 'mdi mdi-file-multiple',
      //   path: '/SETTING_SIGNER_2326',
      //   user: true
      // },
    ]
  },
  // {
  //   label: {
  //     id: 'Import',
  //     en: 'Import'
  //   },
  //   children: [
  //     {
  //       label: {
  //         id: 'BP Tidak Final',
  //         en: 'BP Tidak Final'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/IMPORT/BP_21_TF',
  //       permission: 'IMPORT_BP_21_TF'
  //     },
  //   ]
  // }
  //     {
  //       label: {
  //         id: 'BP Final',
  //         en: 'BP Final'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/IMPORT/BP_21_F',
  //       permission: 'IMPORT_BP_21_F'
  //     },
  //     {
  //       label: {
  //         id: 'BP 1721 A1',
  //         en: 'BP 1721 A1'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/IMPORT/BP_21_A1',
  //       permission: 'IMPORT_BP_21_A1',
  //       decemberOnly: true
  //     },
  //     {
  //       label: {
  //         id: 'BP 1721 A2',
  //         en: 'BP 1721 A2'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/IMPORT/BP_21_A2',
  //       permission: 'IMPORT_BP_21_A2',
  //       decemberOnly: true,
  //       bendaharaOnly: true
  //     },
  //     {
  //       label: {
  //         id: 'Pemotongan Satu Masa Pajak',
  //         en: 'Pemotongan Satu Masa Pajak'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/IMPORT/BP_21_SATU_MASA',
  //       permission: 'IMPORT_BP_21_SATU_MASA'
  //     },
  //     {
  //       label: {
  //         id: 'Pemotongan Satu Masa Pajak TL',
  //         en: 'Pemotongan Satu Masa Pajak TL'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/IMPORT/BP_21_SATU_MASA_TIDAK_LEBIH',
  //       permission: 'BP_21_SATU_MASA_TIDAK_LEBIH'
  //     },
  //     {
  //       label: {
  //         id: 'SSP Pasal 21',
  //         en: 'SSP Pasal 21'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/IMPORT/SSP_21',
  //       permission: 'IMPORT_SSP_21'
  //     },
  //     {
  //       label: {
  //         id: 'Daftar Biaya Pasal 21',
  //         en: 'Daftar Biaya Pasal 21'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/IMPORT/DAFTAR_BIAYA_21',
  //       permission: 'IMPORT_DAFTAR_BIAYA_21'
  //     },
  //     {
  //       label: {
  //         id: 'Induk Pasal 21',
  //         en: 'Induk Pasal 21'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/IMPORT/INDUK_21',
  //       permission: 'IMPORT_INDUK_21'
  //     },
  //     {
  //       label: {
  //         id: 'Lawan Transaksi 21',
  //         en: 'Lawan Transaksi 21'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/IMPORT/LK_21',
  //       permission: 'IMPORT_LK_21'
  //     },
  //   ]
  // },
  // {
  //   label: {
  //     id: 'Export',
  //     en: 'Export'
  //   },
  //   children: [
  //     {
  //       label: {
  //         id: 'Export CSV',
  //         en: 'Export CSV'
  //       },
  //       iconClassName: 'mdi mdi-print',
  //       path: '/EXPORT_CSV_21',
  //       permission: 'EXPORT_CSV_21'
  //     }
  //   ]
  // },
  // {
  //   label: {
  //     id: 'Log',
  //     en: 'Log'
  //   },
  //   children: [
  //     {
  //       label: {
  //         id: 'Log CSV',
  //         en: 'Log CSV'
  //       },
  //       iconClassName: 'mdi mdi-file-multiple',
  //       path: '/logExport/CSV',
  //       user: true
  //     },
  //     {
  //       label: {
  //         id: 'Log PDF',
  //         en: 'Log PDF'
  //       },
  //       iconClassName: 'mdi mdi-file-multiple',
  //       path: '/logExport/PDF',
  //       user: true
  //     },
  //     {
  //       label: {
  //         id: 'Log eFiling',
  //         en: 'Log eFiling'
  //       },
  //       iconClassName: 'mdi mdi-file-multiple',
  //       path: '/eFiling',
  //       user: true
  //     },
  //     {
  //       label: {
  //         id: 'Log Email',
  //         en: 'Log Email'
  //       },
  //       iconClassName: 'mdi mdi-file-multiple',
  //       path: '/LOG_EMAIL',
  //       permission: 'LOG_EMAIL',
  //       user: true
  //     }
  //   ]
  // },
  // {
  //   label: {
  //     id: 'Pengaturan',
  //     en: 'Setting'
  //   },
  //   children: [
  //     {
  //       label: {
  //         id: 'Nomor Bukti Potong',
  //         en: 'Nomor Bukti Potong'
  //       },
  //       iconClassName: 'mdi mdi-file-multiple',
  //       path: '/SETTING_NO_BUPOT_21',
  //       user: true
  //     },
  //   ]
  // },
]

const sptPph22Menus = [
  {
    label: {
      id: 'Dashboard',
      en: 'Dashboard'
    },
    children: [
      {
        label: {
          id: 'Dashboard',
          en: 'Dashboard'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/EXPORT_CSV_22',
        permission: 'EXPORT_CSV_22'
      },
      // {
      //   label: {
      //     id: 'Export CSV',
      //     en: 'Export CSV'
      //   },
      //   iconClassName: 'mdi mdi-print',
      //   path: '/EXPORT_CSV_22',
      //   permission: 'EXPORT_CSV_22'
      // }
    ]
  },
  {
    label: {
      id: 'Bukti Pungut',
      en: 'Bukti Pungut'
    },
    children: [{
      label: {
        id: 'Pph 22',
        en: 'Pph 22'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/BP_22',
      permission: 'BP_22',
      pemungut: true
    }
    ]
  },
  {
    label: {
      id: 'Daftar SSP PPh 22',
      en: 'Daftar SSP PPh 22'
    },
    children: [{
      label: {
        id: 'Atas Pembelian Barang Oleh Bendaharawan',
        en: 'Atas Pembelian Barang Oleh Bendaharawan'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/ssp_pasal22_pembelian_barang',
      permission: 'ssp-pasal22-pembelian-barang'
    }
    ]
  },
  {
    label: {
      id: 'Daftar SSP PPh 22 Impor',
      en: 'Daftar SSP PPh 22 Impor'
    },
    children: [{
      label: {
        id: 'Oleh Bank Devisa dan Bendaharawan/Badan Tertentu Yang Ditunjuk',
        en: 'Oleh Bank Devisa dan Bendaharawan/Badan Tertentu Yang Ditunjuk'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/ssp_bank_devisa',
      permission: 'ssp_bank_devisa'
    },
    {
      label: {
        id: 'Yang Dibayar Sendiri',
        en: 'Yang Dibayar Sendiri'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/ssp_dibayar_sendiri',
      permission: 'ssp_dibayar_sendiri'
    }
    ]
  },
  {
    label: {
      id: 'Lainnya',
      en: 'Lainnya'
    },
    children: [
      /*{
        label: {
          id: 'Daftar Biaya',
          en: 'Daftar Biaya'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/DAFTAR_BIAYA_22',
        permission: 'DAFTAR_BIAYA_22',
        decemberOnly: true
      },*/
      {
        label: {
          id: 'Daftar SSP',
          en: 'Daftar SSP'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/SSP22',
        permission: 'SSP22'
      },
      {
        label: {
          id: 'Daftar PBK',
          en: 'Daftar PBK',
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/PBK22',
        permission: 'PBK22'
      },
      {
        label: {
          id: 'SPT Induk',
          en: 'SPT Induk'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/SPT22INDUK',
        permission: 'SPT22INDUK'
      },
    ]
  },
  // {
  //   label: {
  //     id: 'Master Data Tarif',
  //     en: 'Master Data Tarif'
  //   },
  //   children: [
  //     {
  //       label: {
  //         id: 'Tarif 22',
  //         en: 'Tarif 22'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/master/tarif-22',
  //       permission: '',
  //     },
  //   ]
  // },
  {
    label: {
      id: 'Import',
      en: 'Import'
    },
    children: [
      {
        label: {
          id: 'Bukti Pungut ',
          en: 'Bukti Pungut '
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_22',
        permission: 'BP_22',
        user: true,
        pemungut: true
      },
      // {
      //   label: {
      //     id: 'Bukti Pungut Industri Eksportir',
      //     en: 'Bukti Pungut Industri Eksportir'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/IMPORT/BP_22_industri_eksportir',
      //   permission: 'BP_22_industri_eksportir',
      //   user: true
      // },
      // {
      //   label: {
      //     id: 'Bukti Pungut Industri Importir',
      //     en: 'Bukti Pungut Industri Importir'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/IMPORT/BP_22_industri_importir',
      //   permission: 'BP_22_industri_eksportir',
      //   user: true
      // },
      // {
      //   label: {
      //     id: 'Bukti Pungut Badan Tertentu',
      //     en: 'Bukti Pungut Badan Tertentu'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/IMPORT/BP_22_badan_tertentu',
      //   permission: 'BP_22_badan_tertentu',
      //   user: true
      // },
      {
        label: {
          id: 'SSP Dibayar Sendiri',
          en: 'SSP Dibayar Sendiri'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SSP_22_dibayar_sendiri',
        permission: 'SSP_22_dibayar_sendiri',
        user: true
      },
      // {
      //   label: {
      //     id: 'SSP Bank Devisa',
      //     en: 'SSP Bank Devisa'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/IMPORT/SSP_22_bank_devisa',
      //   permission: 'SSP_22_bank_devisa',
      //   user: true
      // },
      // {
      //   label: {
      //     id: 'SSP Bendaharawan',
      //     en: 'SSP Bendaharawan'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/IMPORT/SSP_22_bendaharawan',
      //   permission: 'SSP_22_bendaharawan',
      //   user: true
      // },
      {
        label: {
          id: 'SSP 22',
          en: 'SSP 22'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SSP_22',
        permission: 'SSP_22',
        user: true
      },
      {
        label: {
          id: 'PBK 22',
          en: 'PBK 22'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/PBK_22',
        permission: 'PBK_22',
        user: true
      },
      {
        label: {
          id: 'Lawan Transaksi 22',
          en: 'Lawan Transaksi 22'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/LK_22',
        permission: 'LK_22',
        user: true
      }
    ]
  },
  {
    label: {
      id: 'Pengaturan',
      en: 'Setting'
    },
    children: [
      {
        label: {
          id: 'Nomor Bukti Potong',
          en: 'Nomor Bukti Potong'
        },
        iconClassName: 'mdi mdi-file-multiple',
        path: '/SETTING_NO_BUPOT_22',
        user: true
      },
    ]
  },
  // {
  //   label: {
  //     id: 'Log',
  //     en: 'Log'
  //   },
  //   children: [
  // {
  //   label: {
  //     id: 'Log File Lapor',
  //     en: 'Log File Lapor'
  //   },
  //   iconClassName: 'mdi mdi-file-multiple',
  //   path: '/LOG_FILE_LAPOR_21',
  //   permission: 'LOG_FILE_LAPOR_21'
  // },
  // {
  //   label: {
  //     id: 'Log Ekspor CSV',
  //     en: 'Log Ekspor CSV'
  //   },
  //   iconClassName: 'mdi mdi-file-multiple',
  //   path: '/LOG_EXPORT_CSV_23',
  //   permission: 'LOG_EXPORT_CSV_23'
  // },
  // {
  //   label: {
  //     id: 'Log Email',
  //     en: 'Log Email'
  //   },
  //   iconClassName: 'mdi mdi-file-multiple',
  //   path: '/LOG_EMAIL_23',
  //   permission: 'LOG_EMAIL_23'
  // }
  //   ]
  // },
];

const sptEbupot23Menus = [
  {
    label: {
      id: 'Bukti Potong',
      en: 'Bukti Potong'
    },
    children: [{
      label: {
        id: 'Bupot 23',
        en: 'Bupot 23'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/bp-23',
      permission: ''
    },
      {
        label: {
          id: 'Bupot 26',
          en: 'Bupot 26'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/bp-26',
        permission: ''
      }
    ]
  },
  {
    label: {
      id: 'Lainnya',
      en: 'Lainnya'
    },
    children: [
      {
        label: {
          id: 'Daftar SSP',
          en: 'Daftar SSP'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/ssp',
        permission: ''
      },
      {
        label: {
          id: 'Daftar PBK',
          en: 'Daftar PBK'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/pbk',
        permission: ''
      },
      {
        label: {
          id: 'SPT Induk',
          en: 'SPT Induk'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/induk',
        permission: ''
      },
      {
        label: {
          id: 'List BPE',
          en: 'List BPE'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/bpe',
        permission: ''
      }
    ]
  },
  {
    label: {
      id: 'Upload',
      en: 'Upload'
    },
    children: [
      {
        label: {
          id: 'Upload File',
          en: 'Upload File'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/log-file',
        permission: ''
      },
      // {
      //   label: {
      //     id: 'Csv',
      //     en: 'Csv'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/log-file-csv',
      //   permission: ''
      // }
    ]
  },
]

const sptPph23Menus = [
  {
    label: {
      id: 'Dashboard',
      en: 'Dashboard'
    },
    children: [
      {
        label: {
          id: 'Dashboard',
          en: 'Dashboard'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/FILE_LAPOR_23',
        permission: 'FILE_LAPOR_23'
      }
    ]
  },
  {
    label: {
      id: 'Bukti Potong',
      en: 'Bukti Potong'
    },
    children: [{
      label: {
        id: 'Pph 23',
        en: 'Pph 23'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/BP_23',
      permission: 'BP_23'
    },
      {
        label: {
          id: 'Pph 26',
          en: 'Pph 26'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/BP_26',
        permission: 'BP_26'
      }
    ]
  },
  {
    label: {
      id: 'Lainnya',
      en: 'Lainnya'
    },
    children: [
      /*{
        label: {
          id: 'Daftar Biaya',
          en: 'Daftar Biaya'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/DAFTAR_BIAYA_23',
        permission: 'DAFTAR_BIAYA_23',
        decemberOnly: true
      },*/
      {
        label: {
          id: 'Daftar SSP',
          en: 'Daftar SSP'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/SSP23',
        permission: 'SSP23'
      },
      {
        label: {
          id: 'Daftar PBK',
          en: 'Daftar PBK',
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/PBK23',
        permission: 'PBK23'
      },
      {
        label: {
          id: 'SPT Induk',
          en: 'SPT Induk'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/SPT23INDUK',
        permission: 'SPT23INDUK'
      },
    ]
  },
  {
    label: {
      id: 'Master Data Tarif',
      en: 'Master Data Tarif'
    },
    children: [
      // {
      //   label: {
      //     id: 'KAP',
      //     en: 'KAP'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/master/pasal-23-kap',
      //   permission: '',
      // },
      // {
      //   label: {
      //     id: 'KJS',
      //     en: 'KJS'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/master/pasal-23-kjs',
      //   permission: '',
      // },
      {
        label: {
          id: 'Tarif 23 Lain',
          en: 'Tarif 23 Lain'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/tarif-23-lain',
        permission: '',
      },
      {
        label: {
          id: 'Tarif 23',
          en: 'Tarif 23'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/tarif-23',
        permission: '',
      },
      {
        label: {
          id: 'Tarif 26',
          en: 'Tarif 26'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/tarif-26',
        permission: '',
      }
    ]
  },
  {
    label: {
      id: 'Import',
      en: 'Import'
    },
    children: [
      // {
      //   label: {
      //     id: 'Log File Import',
      //     en: 'Log File Import'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/IMPORT/BP_23',
      //   permission: 'IMPORT_BP_23'
      // }
      {
        label: {
          id: 'BP 23',
          en: 'BP 23'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_23',
        permission: 'IMPORT_BP_23'
      },
      {
        label: {
          id: 'BP 26',
          en: 'BP 26'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_26',
        permission: 'IMPORT_BP_26'
      },
      {
        label: {
          id: 'SSP Pasal 23/26',
          en: 'SSP Pasal 23/26'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SSP_23',
        permission: 'IMPORT_SSP_23'
      },
      {
        label: {
          id: 'PBK 23/26',
          en: 'PBK 23/26'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/IMPORT_PBK',
        permission: 'IMPORT_PBK'
      },
      {
        label: {
          id: 'Lawan Transaksi 23/26',
          en: 'Lawan Transaksi 23/26'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/LT_23',
        permission: 'LT_23'
      },
    ]
    // children: [
    //   {
    //     label: {
    //       id: 'BP 23',
    //       en: 'BP 23'
    //     },
    //     iconClassName: 'mdi mdi-view-list',
    //     path: '/IMPORT/BP_23',
    //     permission: 'IMPORT_BP_23'
    //   },
    //   {
    //     label: {
    //       id: 'BP 26',
    //       en: 'BP 26'
    //     },
    //     iconClassName: 'mdi mdi-view-list',
    //     path: '/IMPORT/BP_26',
    //     permission: 'IMPORT_BP_26'
    //   },
    //   {
    //     label: {
    //       id: 'SSP Pasal 23/26',
    //       en: 'SSP Pasal 23/26'
    //     },
    //     iconClassName: 'mdi mdi-view-list',
    //     path: '/IMPORT/SSP_23',
    //     permission: 'IMPORT_SSP_23'
    //   },
    //   {
    //     label: {
    //       id: 'PBK 23/26',
    //       en: 'PBK 23/26'
    //     },
    //     iconClassName: 'mdi mdi-view-list',
    //     path: '/IMPORT/IMPORT_PBK',
    //     permission: 'IMPORT_PBK'
    //   },
    // ]
  },
  // {
  //   label: {
  //     id: 'Export',
  //     en: 'Export'
  //   },
  //   children: [
  //     {
  //       label: {
  //         id: 'Export CSV',
  //         en: 'Export CSV'
  //       },
  //       iconClassName: 'mdi mdi-print',
  //       path: '/EXPORT_CSV_23',
  //       permission: 'EXPORT_CSV_23'
  //     }
  //   ]
  // },
  // {
  //   label: {
  //     id: 'Log',
  //     en: 'Log'
  //   },
  //   children: [
  //     {
  //       label: {
  //         id: 'Log CSV',
  //         en: 'Log CSV'
  //       },
  //       iconClassName: 'mdi mdi-file-multiple',
  //       path: '/logExport/CSV',
  //       user: true
  //     },
  //     {
  //       label: {
  //         id: 'Log PDF',
  //         en: 'Log PDF'
  //       },
  //       iconClassName: 'mdi mdi-file-multiple',
  //       path: '/logExport/PDF',
  //       user: true
  //     },
  //     {
  //       label: {
  //         id: 'Log eFiling',
  //         en: 'Log eFiling'
  //       },
  //       iconClassName: 'mdi mdi-file-multiple',
  //       path: '/eFiling',
  //       user: true
  //     },
  //     {
  //       label: {
  //         id: 'Log Email',
  //         en: 'Log Email'
  //       },
  //       iconClassName: 'mdi mdi-file-multiple',
  //       path: '/LOG_EMAIL',
  //       permission: 'LOG_EMAIL',
  //       user: true
  //     }
  //   ]
  // },
  {
    label: {
      id: 'Pengaturan',
      en: 'Setting'
    },
    children: [
      {
        label: {
          id: 'Nomor Bukti Potong',
          en: 'Nomor Bukti Potong'
        },
        iconClassName: 'mdi mdi-file-multiple',
        path: '/SETTING_NO_BUPOT_2326',
        user: true
      },
      // {
      //   label: {
      //     id: 'Signer',
      //     en: 'Signer'
      //   },
      //   iconClassName: 'mdi mdi-file-multiple',
      //   path: '/SETTING_SIGNER_2326',
      //   user: true
      // },
    ]
  },
];

const sptPph4a2Menus = [
  {
    label: {
      id: 'Dashboard',
      en: 'Dashboard'
    },
    children: [
      {
        label: {
          id: 'Dashboard',
          en: 'Dashboard'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/EXPORT_CSV_4a2',
        permission: 'EXPORT_CSV_4a2'
      }
    ]
  },
  {
    label: {
      id: 'Bukti Potong',
      en: 'Bukti Potong'
    },
    children: [{
      label: {
        id: 'Pph 4 (2)',
        en: 'Pph 4 (2)'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/BP_4a2',
      permission: 'BP_4a2'
    },
      {
        label: {
          id: 'Daftar Pemotongan 4 (2)',
          en: 'Daftar Pemotongan 4 (2)'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/daftar_pemotong',
        permission: 'daftar_pemotong'
      }
    ]
  },
  {
    label: {
      id: 'PPh 4 (2) Disetor Sendiri',
      en: 'PPh 4 (2) Disetor Sendiri'
    },
    children: [{
      label: {
        id: 'Sewa Tanah/Bangunan',
        en: 'Sewa Tanah/Bangunan'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/sewa_tanah',
      permission: 'sewa_tanah'
    },
      {
        label: {
          id: 'Usaha Jasa Konstruksi',
          en: 'Usaha Jasa Konstruksi'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/jasa_konstruksi',
        permission: 'jasa_konstruksi'
      },
      {
        label: {
          id: 'WP Melakukan Pengalihan Hak Atas Tanah/Bangunan',
          en: 'WP Melakukan Pengalihan Hak Atas Tanah/Bangunan'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/hak_atas_tanah',
        permission: 'hak_atas_tanah'
      }
    ]
  },
  {
    label: {
      id: 'Lainnya',
      en: 'Lainnya'
    },
    children: [
      {
        label: {
          id: 'Daftar SSP',
          en: 'Daftar SSP'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/SSP4a2',
        permission: 'SSP4a2'
      },
      {
        label: {
          id: 'Daftar PBK',
          en: 'Daftar PBK',
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/PBK4a2',
        permission: 'PBK4a2'
      },
      {
        label: {
          id: 'SPT Induk',
          en: 'SPT Induk'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/SPT4a2INDUK',
        permission: 'SPT4a2INDUK'
      },
    ]
  },
  // {
  //   label: {
  //     id: 'Lapor',
  //     en: 'Lapor'
  //   },
  //   children: [
  //     {
  //       label: {
  //         id: 'File Lapor',
  //         en: 'File Lapor'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/FILE_LAPOR_4a2',
  //       permission: 'FILE_LAPOR_4a2'
  //     }
  //   ]
  // },
  {
    label: {
      id: 'Master Data Tarif',
      en: 'Master Data Tarif'
    },
    children: [
      {
        label: {
          id: 'Tarif 4a2',
          en: 'Tarif 4a2'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/tarif-4a2',
        permission: '',
      },
    ]
  },
  {
    label: {
      id: 'Import',
      en: 'Import'
    },
    children: [
      {
        label: {
          id: 'BP 4(2)',
          en: 'BP 4(2)'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_4a2',
        permission: 'IMPORT_BP_4a2',
        user: true
      },
      // {
      //   label: {
      //     id: 'Daftar Pemotongan 4 (2)',
      //     en: 'Daftar Pemotongan 4 (2)'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/IMPORT/DAFTAR_PEMOTONGAN_4a2',
      //   permission: 'IMPORT_BP_4a2',
      //   user: true
      // },
      {
        label: {
          id: 'SSP Pasal 4(2)',
          en: 'SSP Pasal 4(2)'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SSP_4a2',
        permission: 'IMPORT_SSP_4a2',
        user: true
      },
      {
        label: {
          id: 'PBK 4(2)',
          en: 'PBK 4(2)'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/PBK_4a2',
        permission: 'IMPORT_4a2',
        user: true
      },
      // {
      //   label: {
      //     id: 'Induk 4(2) Penghasilan Tertentu',
      //     en: 'Induk 4(2) Penghasilan Tertentu'
      //   },
      //   iconClassName: 'mdi mdi-view-list',
      //   path: '/IMPORT/INDUK_PT_4a2',
      //   permission: 'IMPORT_4a2',
      //   user: true
      // },
      {
        label: {
          id: 'Lawan Transaksi 4(2)',
          en: 'Lawan Transaksi 4(2)'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/LK_4a2',
        permission: 'IMPORT_4a2',
        user: true
      }
    ]
  },
  // {
  //   label: {
  //     id: 'Log',
  //     en: 'Log'
  //   },
  //   children: [
  //     {
  //       label: {
  //         id: 'Log CSV',
  //         en: 'Log CSV'
  //       },
  //       iconClassName: 'mdi mdi-file-multiple',
  //       path: '/logExport/CSV',
  //       user: true
  //     },
  //     {
  //       label: {
  //         id: 'Log PDF',
  //         en: 'Log PDF'
  //       },
  //       iconClassName: 'mdi mdi-file-multiple',
  //       path: '/logExport/PDF',
  //       user: true
  //     },
  //     {
  //       label: {
  //         id: 'Log eFiling',
  //         en: 'Log eFiling'
  //       },
  //       iconClassName: 'mdi mdi-file-multiple',
  //       path: '/eFiling',
  //       user: true
  //     },
  //     {
  //       label: {
  //         id: 'Log Email',
  //         en: 'Log Email'
  //       },
  //       iconClassName: 'mdi mdi-file-multiple',
  //       path: '/LOG_EMAIL',
  //       permission: 'LOG_EMAIL',
  //       user: true
  //     }
  //   ]
  // },
  {
    label: {
      id: 'Pengaturan',
      en: 'Setting'
    },
    children: [
      {
        label: {
          id: 'Nomor Bukti Potong',
          en: 'Nomor Bukti Potong'
        },
        iconClassName: 'mdi mdi-file-multiple',
        path: '/SETTING_NO_BUPOT_4A2',
        user: true
      },
    ]
  },
];

const pph21 = [
  {
  label: {
    id: 'PPh 21',
    en: 'PPh 21'
  },
  children: [{
      label: {
        id: 'Daftar SPT',
        en: 'SPT List'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/SPT_21',
      permission: 'SPT_21'
    },
    {
      label: {
        id: 'BP Final',
        en: 'BP Final'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/BP_21_F',
      permission: 'BP_21_F',
      user: true
    },
    {
      label: {
        id: 'BP Tidak Final',
        en: 'BP Tidak Final'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/BP_21_TF',
      permission: 'BP_21_TF',
      user: true
    },
    {
      label: {
        id: 'BP 1721 A1',
        en: 'BP 1721 A1'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/BP_21_A1',
      permission: 'BP_21_A1',
      user: true
    },
    // {
    //   label: {
    //     id: 'BP 1721 A2',
    //     en: 'BP 1721 A2'
    //   },
    //   iconClassName: 'mdi mdi-view-list',
    //   path: '/BP_21_A2',
    //   permission: 'BP_21_A2',
    //   user: true
    // },
    {
      label: {
        id: 'BP Satu Masa',
        en: 'BP Satu Masa'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/ALL_BP_21_SATU_MASA',
      permission: 'BP_21_SATU_MASA',
      user: true
    },
    {
      label: {
        id: 'BP Satu Masa',
        en: 'BP Satu Masa'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/ALL_BP_21_SATU_MASA',
      permission: 'BP_21_SATU_MASA',
      user: true
    },
    {
      label: {
        id: 'BP Satu Tahun',
        en: 'BP Satu Tahun'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/BP_21_SATU_TAHUN',
      permission: 'BP_21_SATU_TAHUN',
      user: true
    },
    {
      label: {
        id: 'Daftar Biaya',
        en: 'Daftar Biaya'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/DAFTAR_BIAYA_21',
      permission: 'DAFTAR_BIAYA_21',
      decemberOnly: true,
      user: true
    },
    {
      label: {
        id: 'Daftar SSP',
        en: 'Daftar SSP'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/ALL_BP_21_SSP',
      permission: 'DAFTAR_SSP_21',
      user: true
    },
    {
      label: {
        id: 'SPT Induk',
        en: 'SPT Induk'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/SPT_INDUK_21',
      permission: 'SPT_INDUK_21',
      user: true
    }
  ]
  },
  {
    label: {
      id: 'Import PPh 21',
      en: 'Import PPh 21'
    },
    children: [
      {
        label: {
          id: 'BP Tidak Final',
          en: 'BP Tidak Final'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_21_TF',
        permission: 'IMPORT_BP_21_TF',
        user: true
      },
      {
        label: {
          id: 'BP Final',
          en: 'BP Final'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_21_F',
        permission: 'IMPORT_BP_21_F',
        user: true
      },
      {
        label: {
          id: 'BP 1721 A1',
          en: 'BP 1721 A1'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_21_A1',
        permission: 'IMPORT_BP_21_A1',
        user: true
      },
      {
        label: {
          id: 'BP 1721 A1 Auto',
          en: 'BP 1721 A1 Auto'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_21_A1_AUTO',
        permission: 'IMPORT_BP_21_A1',
        user: true
      },
      {
        label: {
          id: 'BP 1721 A2',
          en: 'BP 1721 A2'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_21_A2',
        permission: 'IMPORT_BP_21_A2',
        user: true
      },
      {
        label: {
          id: 'Pemotongan Satu Masa Pajak',
          en: 'Pemotongan Satu Masa Pajak'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_21_SATU_MASA',
        permission: 'IMPORT_BP_21_SATU_MASA',
        user: true
      },{
        label: {
          id: 'Pemotongan Satu Masa Pajak TL',
          en: 'Pemotongan Satu Masa Pajak TL'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_21_SATU_MASA_TIDAK_LEBIH',
        permission: 'IMPORT_BP_21_SATU_MASA_TIDAK_LEBIH',
        user: true
      },
      {
        label: {
          id: 'SSP Pasal 21',
          en: 'SSP Pasal 21'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SSP_21',
        permission: 'IMPORT_SSP_21',
        user: true
      },
      {
        label: {
          id: 'Daftar Biaya Pasal 21',
          en: 'Daftar Biaya Pasal 21'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/DAFTAR_BIAYA_21',
        permission: 'IMPORT_DAFTAR_BIAYA_21',
        user: true
      },
      {
        label: {
          id: 'Induk Pasal 21',
          en: 'Induk Pasal 21'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/INDUK_21',
        permission: 'IMPORT_INDUK_21',
        user: true
      },
      {
        label: {
          id: 'Lawan Transaksi 21',
          en: 'Lawan Transaksi 21'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/LK_21',
        permission: 'IMPORT_LK_21'
      },
    ]
  }
]

const setup = [{
  label: {
    id: 'Master Data Tarif Pajak Pasal 21',
    en: 'Master Data Tarif Pajak Pasal 21'
  },
  children: [
    {
      label: {
        id: 'Pasal 17 Berlapis',
        en: 'Pasal 17 Berlapis'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/master/pasal-21-pasal-17-berlapis',
      permission: '',
      admin: true
    },
    {
      label: {
        id: 'Biaya Jabatan',
        en: 'Biaya Jabatan'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/master/pasal-21-biaya-jabatan',
      permission: '',
      admin: true
    },
    {
      label: {
        id: 'Biaya Pensiun',
        en: 'Biaya Pensiun'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/master/pasal-21-biaya-pensiun',
      permission: '',
      admin: true
    },
    {
      label: {
        id: 'PTKP',
        en: 'PTKP'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/master/pasal-21-ptkp',
      permission: '',
      admin: true
    },
    {
      label: {
        id: 'Pensiun',
        en: 'Pensiun'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/master/pasal-21-pensiun',
      permission: '',
      admin: true
    },
    {
      label: {
        id: 'Upah Harian',
        en: 'Upah Harian'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/master/pasal-21-upah-harian',
      permission: '',
      admin: true
    },
    {
      label: {
        id: 'Upah Kumulatif',
        en: 'Upah Kumulatif'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/master/pasal-21-upah-kumulatif',
      permission: '',
      admin: true
    },
    {
      label: {
        id: 'Final Pesangon',
        en: 'Final Pesangon'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/master/pasal-21-final-pesangon',
      permission: '',
      admin: true
    },
    {
      label: {
        id: 'Honor/Imbalan PNS',
        en: 'Honor/Imbalan PNS'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/master/pasal-21-honor-pns',
      permission: '',
      admin: true
    }
  ]
  },
  {
    label: {
      id: 'Master Data Kode SPT Pasal 21',
      en: 'Master Data Kode SPT Pasal 21'
    },
    children: [
      {
        label: {
          id: 'Status Karyawan A1',
          en: 'Status Karyawan A1'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-status-karyawan-a1',
        permission: '',
        admin: true
      },
      {
        label: {
          id: 'Status Karyawan A2',
          en: 'Status Karyawan A2'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-status-karyawan-a2',
        permission: '',
        admin: true
      },
      {
        label: {
          id: 'KAP',
          en: 'KAP'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-kap',
        permission: '',
        admin: true
      },
      {
        label: {
          id: 'KJS',
          en: 'KJS'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-kjs',
        permission: '',
        admin: true
      },
      {
        label: {
          id: 'SSP Description',
          en: 'SSP Description'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-ssp-description',
        permission: '',
        admin: true
      },
      {
        label: {
          id: 'Golongan A2',
          en: 'Golongan A2'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/master/pasal-21-golongan-a2',
        permission: '',
        admin: true
      }
    ]
  },
  {
    label: {
      id: 'Administrator',
      en: 'Administrator'
    },
    children: [{
        label: {
          id: 'User',
          en: 'User'
        },
        iconClassName: 'mdi mdi-contacts',
        path: '/user',
        admin: true
      },
      {
        label: {
          id: 'Permissions',
          en: 'Perizinan'
        },
        iconClassName: 'mdi mdi-contacts',
        path: '/permissions',
        admin: true
      },
      // {
      //   label: {
      //     id: 'Akses',
      //     en: 'Access'
      //   },
      //   iconClassName: 'mdi mdi-contacts',
      //   path: '/access',
      //   admin: true
      // },
      {
        label: {
          id: 'Akses Kontrol',
          en: 'Access Control'
        },
        iconClassName: 'mdi mdi-contacts',
        path: '/access-control',
        admin: true
      }
    ]
  }
]

const organization = [{
  label: {
    id: 'Organisasi',
    en: 'Organization'
  },
  children: [{
    label: {
      id: 'Perusahaan',
      en: 'Company'
    },
    iconClassName: 'mdi mdi-contacts',
    path: '/company',
    admin: true
  },{
    label: {
      id: 'Organisasi',
      en: 'Organization'
    },
    iconClassName: 'mdi mdi-contacts',
    path: '/organization',
    admin: true
  },{
    label: {
      id: 'Flow Approval',
      en: 'Flow Approval'
    },
    iconClassName: 'mdi mdi-contacts',
    path: '/mainFlow',
    admin: true
  }]
  },
  // ORGANISASI OWNER
  {
    label: {
      id: 'Organisasi',
      en: 'Organization'
    },
    children: [{
        label: {
          id: 'Organisasi',
          en: 'Organization'
        },
        iconClassName: 'mdi mdi-view-list',
        rootPath: '/company',
        path: '/organization',
        permission: 'organization',
        owner: true
    },{
      label: {
        id: 'Flow Approval',
        en: 'Flow Approval'
      },
      iconClassName: 'mdi mdi-contacts',
      path: '/mainFlow',
      owner: true
    }]
  },
  {
  label: {
    id: 'Pengguna',
    en: 'User'
  },
  children: [{
      label: {
        id: 'Pengguna',
        en: 'User'
      },
      rootPath: '/company',
      iconClassName: 'mdi mdi-view-list',
      path: '/user',
      permission: 'user',
      owner: true
  },{
    label: {
      id: 'Pemilik',
      en: 'Owner'
    },
    iconClassName: 'mdi mdi-view-list',
    rootPath: '/company',
    path: '/owner',
    permission: 'owner',
    owner: true
  }]
}]

const sptPph15Menus = [
  {
    label: {
      id: 'Dashboard',
      en: 'Dashboard'
    },
    children: [
      {
        label: {
          id: 'Dashboard',
          en: 'Dashboard'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/EXPORT_CSV_15',
        permission: 'EXPORT_CSV_15'
      }
    ]
  },
  {
    label: {
      id: 'Bukti Potong',
      en: 'Bukti Potong'
    },
    children: [{
      label: {
        id: 'Pph 15',
        en: 'Pph 15'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/BP_15',
      permission: 'BP_15'
    },
    {
      label: {
        id: 'SSP Pasal 15 Disetor Sendiri',
        en: 'SSP Pasal 15 Disetor Sendiri'
      },
      iconClassName: 'mdi mdi-view-list',
      path: '/SSP_15_Sendiri',
      permission: 'SSP_15_Sendiri'
    }
    ]
  },
  {
    label: {
      id: 'Lainnya',
      en: 'Lainnya'
    },
    children: [
      {
        label: {
          id: 'Daftar SSP',
          en: 'Daftar SSP'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/SSP15',
        permission: 'SSP15'
      },
      {
        label: {
          id: 'Daftar PBK',
          en: 'Daftar PBK',
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/PBK15',
        permission: 'PBK15'
      },
      {
        label: {
          id: 'SPT Induk',
          en: 'SPT Induk'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/SPT15INDUK',
        permission: 'SPT15INDUK'
      },
    ]
  },
  // {
  //   label: {
  //     id: 'Lapor',
  //     en: 'Lapor'
  //   },
  //   children: [
  //     {
  //       label: {
  //         id: 'File Lapor',
  //         en: 'File Lapor'
  //       },
  //       iconClassName: 'mdi mdi-view-list',
  //       path: '/FILE_LAPOR_15',
  //       permission: 'FILE_LAPOR_15'
  //     }
  //   ]
  // },
  {
    label: {
      id: 'Import',
      en: 'Import'
    },
    children: [
      {
        label: {
          id: 'BP 15',
          en: 'BP 15'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_15',
        permission: 'IMPORT_BP_15',
        user: true
      },
      {
        label: {
          id: 'BP 24',
          en: 'BP 24'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/BP_24',
        permission: 'IMPORT_BP_24',
        user: true
      },
      {
        label: {
          id: 'SSP Pasal 15',
          en: 'SSP Pasal 15'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SSP_15',
        permission: 'IMPORT_SSP_15',
        user: true
      },
      {
        label: {
          id: 'SSP Sendiri Pasal 15',
          en: 'SSP Sendiri Pasal 15'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/SSP_15_SETOR_SENDIRI',
        permission: 'IMPORT_SSP_15',
        user: true
      },
      {
        label: {
          id: 'PBK 15',
          en: 'PBK 15'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/PBK_15',
        permission: 'IMPORT_15',
        user: true
      },
      {
        label: {
          id: 'Lawan Transaksi 15',
          en: 'Lawan Transaksi 15'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/IMPORT/LK_15',
        permission: 'IMPORT_15',
        user: true
      }
    ]
  },
  {
    label: {
      id: 'Pengaturan',
      en: 'Setting'
    },
    children: [
      {
        label: {
          id: 'Nomor Bukti Potong',
          en: 'Nomor Bukti Potong'
        },
        iconClassName: 'mdi mdi-file-multiple',
        path: '/SETTING_NO_BUPOT_15',
        user: true
      },
      {
        label: {
          id: 'Pengaturan Tarif',
          en: 'Pengaturan Tarif'
        },
        iconClassName: 'mdi mdi-file-multiple',
        path: '/SETTING_TARIF_15',
        user: true
      },
    ]
  },
  // {
  //   label: {
  //     id: 'Log',
  //     en: 'Log'
  //   },
  //   children: [
  // {
  //   label: {
  //     id: 'Log File Lapor',
  //     en: 'Log File Lapor'
  //   },
  //   iconClassName: 'mdi mdi-file-multiple',
  //   path: '/LOG_FILE_LAPOR_21',
  //   permission: 'LOG_FILE_LAPOR_21'
  // },
  // {
  //   label: {
  //     id: 'Log Ekspor CSV',
  //     en: 'Log Ekspor CSV'
  //   },
  //   iconClassName: 'mdi mdi-file-multiple',
  //   path: '/LOG_EXPORT_CSV_4a2',
  //   permission: 'LOG_EXPORT_CSV_4a2'
  // },
  // {
  //   label: {
  //     id: 'Log Email',
  //     en: 'Log Email'
  //   },
  //   iconClassName: 'mdi mdi-file-multiple',
  //   path: '/LOG_EMAIL_4a2',
  //   permission: 'LOG_EMAIL_4a2'
  // }
  //   ]
  // },
];

const sptPph25Menus = [
  {
    label: {
      id: 'Dashboard',
      en: 'Dashboard'
    },
    children: [
      {
        label: {
          id: 'Dashboard',
          en: 'Dashboard'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/EXPORT_CSV_25',
        permission: 'EXPORT_CSV_25'
      }
    ]
  },
  {
    label: {
      id: 'Daftar Angsuran',
      en: 'Daftar Angsuran'
    },
    children: [
      {
        label: {
          id: 'Pph 25',
          en: 'Pph 25'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/BP_25',
        permission: 'BP_25'
      }
    ]
  },
  {
    label: {
      id: 'Lainnya',
      en: 'Lainnya'
    },
    children: [
      {
        label: {
          id: 'Daftar SSP',
          en: 'Daftar SSP'
        },
        iconClassName: 'mdi mdi-view-list',
        path: '/SSP25',
        permission: 'SSP25'
      }
    ]
  },
];

const none = [
  //   {
  //   label: {
  //     id: 'Home',
  //     en: 'Home'
  //   },
  //   children: [{
  //     label: {
  //       id: 'Dashboard',
  //       en: 'Dashboard'
  //     },
  //     iconClassName: 'mdi mdi-view-list',
  //     path: '/',
  //     permission: ''
  //   }]
  // }
]

export default {
  mainMenus,

  //company
  companyMenu,

  //spt
  sptPph21Menus,
  sptPph22Menus,
  sptPph23Menus,
  sptEbupot23Menus,
  sptPph4a2Menus,
  sptPph15Menus,
  sptPph25Menus
};
