import React from 'react'
import { TabsContainer, Tabs, Tab } from 'react-md';
import LawanTransaksiPP from './../../Main/Master/MasterLawanTransaksiPp/MasterLawanTransaksiPp.view'
import LawanTransaksiA1 from './../../Main/Master/MasterLawanTransaksiA1/MasterLawanTransaksiA1.view'
import LawanTransaksi23 from './../../Main/Master/MasterLawanTransaksi23/MasterLawanTransaksi23.view'
import LawanTransaksi42 from './../../Main/Master/MasterLawanTransaksi4a2/MasterLawanTransaksi4a2.view'
import LawanTransaksi22 from './../../Main/Master/MasterLawanTransaksi22/MasterLawanTransaksi22.view'
import LawanTransaksi15 from './../../Main/Master/MasterLawanTransaksi15/MasterLawanTransaksi15.view'
import UserAccess21 from './../pph/21_26/UserAccess/UserAccess.service'

export default class LawanTransaksiAllView extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            pasal: '21 PP',
            filter21Menu: [],
            filter21IsTrue: false,
            enableA1: true,
            enablePP: true
        }
    }

    async componentDidMount(){
        try {
            var companyId = this.props.match.params.companyId
            let res21 = await UserAccess21.api.getSettingAccess(companyId)
            if(res21.data.active == true){
              this.setState({ filter21IsTrue: true })
              let per21 = await UserAccess21.api.cekAccess()
              this.setState({
                filter21Menu: per21.data
              })
              var c = ["SPT_21_TF", "SPT_21_F", "SPT_21_SM", "SPT_21_A1", "SPT_21_SATU_TAHUN"]
              c.map((d)=> {
                var accessLength = per21.data.filter((d2)=> {
                    return d2.access == d
                })
                if(accessLength.length > 0 && d == "SPT_21_A1"){
                    this.setState({ enableA1: true })
                } else {
                    this.setState({ enableA1: false })
                }
                if(accessLength.length > 0 && d == "SPT_21_TF"){
                    this.setState({ enablePP: true })
                } else {
                    this.setState({ enablePP: false })
                }
                if(accessLength.length > 0 && d == "SPT_21_F"){
                    this.setState({ enablePP: true })
                } else {
                    this.setState({ enablePP: false })
                }
                if(accessLength.length > 0 && d == "SPT_21_SM"){
                    this.setState({ enablePP: true })
                } else {
                    this.setState({ enablePP: false })
                }
                if(accessLength.length > 0 && d == "SPT_21_SATU_TAHUN"){
                    this.setState({ enablePP: true })
                } else {
                    this.setState({ enablePP: false })
                }
              })

            }
        } catch(e){
            console.log(e, "GET Error")
        }
    }

    render(){
        var pasal21 = true
        if(localStorage.getItem("p21") && localStorage.getItem("p21") == "true"){ pasal21 = true } else { pasal21 = false }
        var pasal22 = true
        if(localStorage.getItem("p22") && localStorage.getItem("p22") == "true"){ pasal22 = true } else { pasal22 = false }
        var pasal23 = true
        if(localStorage.getItem("p23") && localStorage.getItem("p23") == "true"){ pasal23 = true } else { pasal23 = false }
        var pasal15 = true
        if(localStorage.getItem("p15") && localStorage.getItem("p15") == "true"){ pasal15 = true } else { pasal15 = false }
        var pasal4  = true
        if(localStorage.getItem("p4") && localStorage.getItem("p4") == "true"){ pasal4 = true } else { pasal4 = false }
        return (
            <div style={{ width: '100%', height: '100%' }}>
                <TabsContainer colored>
                    <Tabs tabId="simple-tab">
                        {(this.state.enablePP && pasal21) &&
                            <Tab onClick={()=> this.setState({ pasal: '21 PP' })} label="21 PP"></Tab>
                        }
                        {(this.state.enableA1 && pasal21) &&
                            <Tab onClick={()=> this.setState({ pasal: '21 A1' })} label="21 A1"></Tab>
                        }
                        {pasal23 && <Tab onClick={()=> this.setState({ pasal: '23/26' })} label="23/26"></Tab>}
                        {pasal4 && <Tab onClick={()=> this.setState({ pasal: '4(2)' })} label="4(2)"></Tab>}
                        {pasal22 && <Tab onClick={()=> this.setState({ pasal: '22' })} label="22"></Tab>}
                        {pasal15 && <Tab onClick={()=> this.setState({ pasal: '15' })} label="15"></Tab>}
                    </Tabs>
                </TabsContainer>
                <div style={{ width: '100%', height: '94%' }}>
                    {this.state.pasal == '21 PP' &&
                        <LawanTransaksiPP />
                    }
                    {this.state.pasal == '21 A1' &&
                        <LawanTransaksiA1 />
                    }
                    {this.state.pasal == '23/26' &&
                        <LawanTransaksi23 />
                    }
                    {this.state.pasal == '4(2)' &&
                        <LawanTransaksi42 />
                    }
                    {this.state.pasal == '22' &&
                        <LawanTransaksi22 />
                    }
                    {this.state.pasal == '15' &&
                        <LawanTransaksi15 />
                    }
                </div>
            </div>
        )
    }
}