import ApiService from '../../../../services/api.service';

class MasterKppService extends ApiService {
  name= 'masterKpp';
  path= 'master/kpp';
  constructor() {
    super();
    this.init()
  }
}


export default new MasterKppService();
