import React, { Component } from 'react';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import { validation, Textfield, TextfieldMask, Datepicker, convert } from '../../../../../../components/form';
import ImportDialogTemplateView from '../../../../../../components/entity/import.dialog.template.view2';
import ImportSigner4a2Service from './ImportSigner4a2.service';
ImportDialogTemplateView


@reduxForm({form: 'ImportSigner4a2-form', destroyOnUnmount: false})
export default class ImportSigner4a2DialogView extends ImportDialogTemplateView {
  pasal="pasal4"
  service=ImportSigner4a2Service
}
