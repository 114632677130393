import ApiService from '../../../../../services/api.service'
import http from '../../../../../services/http.service'
import moment from 'moment'

class LogFileService extends ApiService {
  name= 'LogFile'
  path= 'ebupot/log/file'
  // repoType="QueryDsl"
  advancedSearch=true

  constructor(){
    super()
    this.init()
    this.initApi()
  }

  columns = [
    {label: "word.id", searchField: "id", value: "id", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
    {label: "word.success", searchField: "success", value: "success", type: 'boolean', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.status", searchField: "status", value: "status", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.originalFilename", searchField: "originalFilename", value: "originalFilename", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    /*{label: "word.md5", searchField: "md5", value: "md5", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},*/
    {label: "word.fileSize", searchField: "fileSize", value: (d)=> {
      var bytes = d.fileSize
      var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes == 0) return '0 Byte';
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.message", searchField: "message", value: "message", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.createdBy", searchField: "createdBy", value: "createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.createdDate", searchField: "createdDate", value: (d)=> {
      if(d){
        return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
      } else {
        return "-"
      }
    }, type: 'func', isDefaultSort:true, isSortable:true, show:true, isSearchable:true},
    {label: "word.lastModifiedBy", searchField: "lastModifiedBy", value: "lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.lastModifiedDate", searchField: "lastModifiedDate", value: (d)=> {
      if(d.lastModifiedDate){
        return moment(d.lastModifiedDate).format('DD/MM/YYYY HH:MM')
      } else {
        return "-"
      }
    }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
  ]

  api = {
    logValidasi: async(fileId)=> {
      let res = await http.get(`/ebupot/log-validasi/${fileId}/log-file`)
      return res.data
    },
    logValidasiCheck: async(fileId)=> {
      let res = await http.get(`/ebupot/log-check-validasi/${fileId}/log-file`)
      return res.data
    },
    downloadTemplate: async() => {
      let res = await http.get(`/ebupot/download/template/excel`,  {
        responseType: 'blob'
      })
      return res
    },
    downloadExcel: async (param)=> {
      let res = await http.get(`/ebupot/download/excel`, {
        params: param
      })
      return res
    }
  }

}

export default new LogFileService();
