import ApiService from '../../../../../services/api.service'
import moment from 'moment'

class UserService extends ApiService {
  name= 'User'
  path= 'ebupot/user'
  advancedSearch = true

  constructor(){
    super()
    this.init()
    this.initApi()
  }

  columns = [
    {label: "word.id", searchField: "id", value: "id", isDefaultSort:false, isSortable:true, show:false, isSearchable:false},
    {label: "word.login", searchField: "login", value: "login", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.firstName", searchField: "firstName", value: "firstName", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.lastName", searchField: "lastName", value: "lastName", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.email", searchField: "email", value: "email", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.activated", searchField: "activated", value: "activated", type: 'boolean', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.langKey", searchField: "langKey", value: "langKey", isDefaultSort:false, isSortable:true, show:true, isSearchable:false},
    {label: "word.createdBy", searchField: "createdBy", value: "createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.createdDate", searchField: "createdDate", value: (d)=> {
      if(d){
        return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
      } else {
        return "-"
      }
    }, type: 'func', isDefaultSort:true, isSortable:true, show:true, isSearchable:false},
    {label: "word.lastModifiedBy", searchField: "lastModifiedBy", value: "lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:false},
    {label: "word.lastModifiedDate", searchField: "lastModifiedDate", value: (d)=> {
      if(d.lastModifiedDate){
        return moment(d.lastModifiedDate).format('DD/MM/YYYY HH:MM')
      } else {
        return "-"
      }
    }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:false},
  ]

  api = {
    lookup: async (login, filter, options)=>{
      return await this.http.get(`${this.apiLocation}/${this.path}/search`, {
        params: {
          login: login
        }
      })
    }
  }

}

export default new UserService();
