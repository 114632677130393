import ApiService from "../../../../../../services/api.service";

class Master22TarifService extends ApiService {
  name= 'Master22Tarif';
  path= 'pasal22/rates';

  constructor() {
    super();
    this.init()
  }

  api = {
    byCompany: async (data) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/company`, {
        params: data
      });
      return res.data;
    },
    getMaster: async (esptId) => {
      var res = await this.http.get(`${this.apiLocation}/pasal22/rates/${esptId}/spt`);
      return res.data;
    }
  }
}


export default new Master22TarifService();
