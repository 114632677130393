import ApiService from "../../../../../../services/api.service";

class Tarif4a2ervice extends ApiService {
  name = 'Master4a2Tarif';
  path = 'pasal4/rates/bp4a2';

  constructor() {
    super();
    this.init()
  }

  api = {
    byCompany: async (data) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}`);
      return res.data;
    },
    getMaster: async () => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}`);
      return res;
    },
    save: async (formData)=> {
      var res = await this.http.put(`${this.apiLocation}/${this.path}`, formData);
      return res;
    }
  }
}


export default new Tarif4a2ervice();
