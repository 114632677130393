import React, { Component } from 'react';
import {connect} from 'react-redux';
import { Grid } from 'react-md';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { validation, Textfield, TextfieldMask, Searchfieldv2  } from '../../../../components/form';
import { Searchfield, Switch } from "src/components/form";
import DialogView from '../../../../components/entity/dialog.view';
import MasterLawanTransaksiPpService from './MasterLawanTransaksiPp.service';
import NegaraService from '../CountryCode/CountryCode.service';
import OrganizationService from "../../../Main/Administrator/Organization/Organization.service";

@reduxForm({form: 'master21LtPp', destroyOnUnmount: false})
@connect((state) => ({
  form:getFormValues('master21LtPp')(state),
  organization: state.authEppt.currentOrganization,
  user: state.auth.user,
  // EPPT CORE
  auth: state.auth.company
  // EPPT CORE
}))
export default class MasterLawanTransaksiPpDialogView extends DialogView {
  service=MasterLawanTransaksiPpService
  initialData = {
  }

  formView() {
    var npwpNotValid = this.state.npwpNotValid
    var hideNegara = false;
    if(this.props.form){
      if(this.props.form){
        if(this.props.form.wpLuarNegeri){
          hideNegara = true
        }
      }
    }
    var org = []
    if(this.props.auth && this.props.auth.organizations) org = this.props.auth.organizations
    var _this = this
    var isSupport = false
    if(this.props.user && this.props.user.role && this.props.user.role.name == "SUPPORT_AGENT") isSupport = true
    return (
      <Grid style={{padding:'none'}}>
        {/* <Field
          label     = 'Organization'
          name      = 'organization'
          id        = "spt2126-organization"
          className = "md-cell md-cell--12"
          component = {Searchfield}
          valueField = 'parent'
          options   = {org}
          itemTemplate = {function(d) {
            let nameAndAlias = ""
            if (d.name)
              nameAndAlias = d.name
            if (d.aliasName)
              nameAndAlias += " - " + d.aliasName
            return {
              primaryText  : nameAndAlias,
              secondaryText: d.npwp,
              onClick      : () => {
                if(!this.props.disabled){
                  _this.props.change("npwpProfile", d.npwp)
                  this.handleItemClick(d); this.hide()
                }
              }
            }
          }}
        /> */}
        {!isSupport &&
              <Field
                label     = 'Organization'
                name      = 'organization'
                id        = "spt2126-organization"
                className = "md-cell md-cell--12"
                component = {Searchfield}
                valueField = 'parent'
                options   = {org}
                validate  = {validation.required}
                itemTemplate = {function(d) {
                  let nameAndAlias = ""
                  if (d.name)
                    nameAndAlias = d.name
                  if (d.aliasName)
                    nameAndAlias += " - " + d.aliasName
                  return {
                    primaryText  : nameAndAlias,
                    secondaryText: d.npwp,
                    onClick      : () => {
                      if(!this.props.disabled){
                        _this.props.change("npwpProfile", d.npwp)
                        this.handleItemClick(d); this.hide()
                      }
                    }
                  }
                }}
              />
              // <Field
              //     label         = 'Organization'
              //     name          = 'organization'
              //     className     = "md-cell md-cell--12"
              //     component     = {Searchfieldv2}
              //     valueField    = 'parent'
              //     remoteSearch  = {true}
              //     apiPath       = 'getOrganizationHost'
              //     params        = {{ user: this.props.user }}
              //     service       = {OrganizationService}
              //     validate      = {validation.required}
              //     renderFirst   = {true}
              //     validate      = {validation.required}
              //     coreSearch    = {true}
              //     npwpSearch    = {true}
              //     itemTemplate  = {function(d) {
              //       let nameAndAlias = ""
              //       if (d.name)
              //         nameAndAlias = d.name
              //       if (d.aliasName)
              //         nameAndAlias += " - " + d.aliasName
              //       return {
              //         primaryText  : nameAndAlias,
              //         secondaryText: d.npwp,
              //         onClick      : () => {
              //           if(!this.props.disabled){
              //             this.handleItemClick(d); this.hide()
              //           }
              //         }
              //       }
              //     }}
              // />
            }
        {isSupport &&
          <Field
              label         = 'Organization'
              name          = 'organization'
              className     = "md-cell md-cell--12"
              component     = {Searchfieldv2}
              valueField    = 'parent'
              remoteSearch  = {true}
              apiPath       = 'getAll'
              params        = {{ user: this.props.user }}
              service       = {OrganizationService}
              validate      = {validation.required}
              renderFirst   = {true}
              validate      = {validation.required}
              coreSearch    = {true}
              npwpSearch    = {true}
              itemTemplate  = {function(d) {
                let nameAndAlias = ""
                if (d.name)
                  nameAndAlias = d.name
                if (d.aliasName)
                  nameAndAlias += " - " + d.aliasName
                return {
                  primaryText  : nameAndAlias,
                  secondaryText: d.npwp,
                  onClick      : () => {
                    if(!this.props.disabled){
                      _this.props.change("npwpProfile", d.npwp)
                      this.handleItemClick(d); this.hide()
                    }
                  }
                }
              }}
          />
        }
        {/*<Field
          label='NPWP Profile'
          name='npwpProfile'
          className="md-cell md-cell--6"
          component={TextfieldMask}
          maskFormat="##.###.###.#-###-###"
          mask="_"
          length={15}
          validate={validation.required}
          disabled={true}
        />*/}

        <Field
          label='NPWP'
          name='npwp'
          className="md-cell md-cell--6"
          component={TextfieldMask}
          maskFormat="##.###.###.#-###-###"
          mask="_"
          length={15}
          validate={validation.required}
        />

        <Field
          label='NIK'
          name='nik'
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
        />

        <Field
          label='Name'
          name='name'
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
        />

        <Field
          label='Alamat'
          name='address'
          className="md-cell md-cell--6"
          component={Textfield}
        />

        <Field
          label='Email'
          name='email'
          className="md-cell md-cell--6"
          component={Textfield}
        />

        <Field
          label='Warga Negara Asing'
          name='foreignEmployee'
          component={Switch}
          className="md-cell md-cell--6"
          valueField="foreignEmployee"
          disabled={npwpNotValid}
        />

        {hideNegara &&
          <Field
            label='Kode Negara Domisili'
            name='countryCode'
            component={Searchfield}
            service={NegaraService}
            disabled={npwpNotValid}
            valueField="code"
            className="md-cell md-cell--6"
          />
        }
      </Grid>
    )
  }
}
