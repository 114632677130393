import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues} from 'redux-form';
import moment from 'moment';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress
} from 'react-md';

import FormView from '../../../../../components/entity/form.view';
import BP25Service from './BP_25.service';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox} from '../../../../../components/form';
import FormViewSpt from '../../../../../components/entity/form.view.spt';
import { terbilangAsync25 } from '../../../../../services/terbilang.service';

@reduxForm({form: 'BP_25_Form', destroyOnUnmount: true})
@connect((state) => ({
  access      : state.entity.access.api.find,
  organization: state.authEppt.currentOrganization,
  spt         : state.authEppt.currentSPT,
  formData    : getFormValues('BP_25_Form')(state)
}))
export default class BP25Form extends FormViewSpt {
  service  = BP25Service
  viewType = 2;

  async initData() {
    if(this.props.match.params.id == 'new') {
      let organization = this.props.organization
      this.initialData = {
        ...this.initialData,
        no             : null,
        name           : organization.name,
        npwp           : organization.npwp,
        address        : organization.address,
        email          : organization.email,
        spt            : this.props.spt.data,
        pph            : 0,
        terbilang      : '-'
      }
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);
      this.props.initialize(res.data);
    }
  }

  async handleChange(e, value){
    let res = await terbilangAsync25(value)
    this.props.change("words", res)
  }

  formView() {
    var noSK = false
    if(this.props.formData && this.props.formData.basedOn && this.props.formData.basedOn != 'SPT') noSK = true;
    return (
      <div>
        <Card>
          <div className="md-grid">
            <Field
              label='Berdasarkan'
              name='basedOn'
              component={Searchfield}
              options={[
                { id: 'SPT', name: 'SPT' },
                { id: 'STP', name: 'STP' },
                { id: 'SKPKB', name: 'SKPKB' },
                { id: 'SKPKBT', name: 'SKPKBT' }
              ]}
              className = "md-cell md-cell--12"
              validate={validation.required}
            />
            {noSK &&
              <Field
                label='No SK'
                name='no'
                component={Textfield}
                className = "md-cell md-cell--12"
                validate={validation.required}
              />
            }
          </div>
        </Card>
        <br />
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='A. WAJIB PAJAK ' />
          <div className="md-grid">
            <Field
              label     = 'NPWP'
              name      = 'npwp'
              className = "md-cell md-cell--12"
              disabled
              component  = {TextfieldMask}
              maskFormat = "##.###.###.#-###-###"
              mask       = "_"
              validate   = {validation.required}
            />
            <Field
              label     = 'Nama'
              name      = 'name'
              className = "md-cell md-cell--12"
              disabled
              component = {Textfield}
              validate  = {validation.required}
            />
            <Field
              label     = 'Alamat'
              name      = 'address'
              className = "md-cell md-cell--12"
              disabled
              component = {Textfield}
              validate  = {validation.required}
            />
            <Field
              label     = 'email'
              name      = 'email'
              className = "md-cell md-cell--12"
              disabled
              component = {Textfield}
              validate  = {validation.required}
            />
          </div>
        </Card>
        <br />
        <Card>
          <div className="md-grid">
              <CardTitle title='' style={{padding: '10px 16px'}} subtitle='B. DETAIL ' />
              <DataTable plain className="md-cell md-cell--12">
                <TableHeader>
                  <TableRow>
                    <TableColumn>No.</TableColumn>
                    <TableColumn>PPH(Rp)</TableColumn>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  <TableRow>
                    <TableColumn>1</TableColumn>
                    <TableColumn>
                      <Field
                        label     = ''
                        name      = 'pph'
                        className = "md-cell md-cell--12"
                        component = {TextfieldMask}
                        money
                        validate  = {validation.required}
                        normalize = {(e)=> parseFloat(e)}
                        onChange  = {this.handleChange.bind(this)}
                      />
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Terbilang</TableColumn>
                    <TableColumn>
                      <Field
                        label     = ''
                        name      = 'words'
                        className = "md-cell md-cell--12"
                        component = {Textfield}
                        validate  = {validation.required}
                        disabled
                      />
                    </TableColumn>
                  </TableRow>
                </TableBody>
              </DataTable>
          </div>
        </Card>
        <br />
      </div>
    )
  }

}
