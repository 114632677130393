import ApiService from '../../../../../../../services/api.service'
import http from '../../../../../../../services/http.service'

class SspEbupotService extends ApiService {
  name= 'SspEbupot';
  path= 'ebupot/ssp';
  advancedSearch=true

  columns=[
    // {isSortable: true, label: "word.jenisSetoran", value: "kjs.code", show:true, isSearchable:true},
    {isSortable: true, searchField: 'kjs.kap.code', label: "word.kap", value: "kjs.kap.code", show:true, isSearchable:true},
    {isSortable: true, searchField: 'kjs.code', label: "word.kjs", type: "number", value: "kjs.code", show:true, isSearchable:true},
    {isSortable: true, searchField: 'date', label: "word.date",  value: "date", show:true, isSearchable:true},
    {isSortable: true, searchField: 'ntpn', label: "word.ntpn",  value: "ntpn", show:true, isSearchable:true},
    {isSortable: true, searchField: 'total', label: "word.total", value: "total", show:true, isSearchable:true, type: 'number'},
    {isSortable: true, searchField: 'sending', label: "word.sending", value: "sending", show:true, isSearchable:true, type: 'boolean'},
    {isSortable: true, searchField: 'message', label: "word.message", value: "message", show:true, isSearchable:true},
    {isSortable: true, searchField: 'createdBy', label: "word.createdBy",  value:"createdBy", show:true, isSearchable:true},
    {isSortable: true, searchField: 'createdDate', label: "word.createdDate",  value:"createdDate", type:"date", show:true, isSearchable:true},
  ]
  
  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    djp: async(esptId)=> {
      var res = await this.http.get(`${this.apiLocation}/ebupot/spt/${esptId}/djp`)

      return res
    },
    summary: async (esptId) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${esptId}/spt/summary`)

      return res.data;
    },
    cetak: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/ebupot/ssp/report/${sptId}`, {
        responseType: 'blob'
      })

      return res;
    },
    downloadCsv: async (sptId)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/csv/${sptId}`, {
        responseType: 'blob'
      })

      return res;
    },
    deleteBulk: async (sptId, ids)=> {
      let res = await http.post(`${this.apiLocation}/${this.path}/${sptId}/delete`, ids);

      return res;
    },
    getSummary: async(id)=> {
      let res = await http.get(`${this.apiLocation}/ebupot/spt/${id}/info`);

      return res
    }
  }
}


export default new SspEbupotService();
