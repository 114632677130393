import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Field, reduxForm } from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab, Avatar, FontIcon, List, ListItem, Subheader } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../../../components/form';

import ListView from '../../../../../components/entity/listView';
import ColumnService from '../../../../../services/column.service';
import axios from 'axios';
import izitoast from 'izitoast';
import LogCetakLaporanPOService from './LogCetakLaporanPO.service';
import EbillingAccountService from '../../EbillingAccountService';
import download from 'downloadjs';

@connect((state)=> ({
  ...LogCetakLaporanPOService.stateConnectSetting()(state),
  spt: state.authEppt.currentSPT,
  user: state.authEppt.user
}), LogCetakLaporanPOService.actionConnectSetting())
export default class LogCetakLaporanPOView extends ListView {
  service=LogCetakLaporanPOService
  ebillingAccountService=EbillingAccountService
  // viewType=2

  constructor(props){
    super(props);
    this.state = {
      showTableSetting:true,
      showDialogConfirmDeleteSelected:false,
      showForm:false,
      formData: {},
      data: [],
      onProgress: false,
      isError: false,
      errorMessage: ''
    }

    this.state.ebillingUser = {}
  }

  beforeFetch(params) {
    if(params["sortBy"] === "createdAt") {
      params["sortBy"] = "createdDate"
      params["sort"] = "DESC"
    }
  }

  _barActions = [
    {
      label:'word.delete',
      iconClassName:'mdi mdi-delete',
      onClick:() => {
        this.setState({showDialogConfirmDeleteSelected:true})
      },
      disabledFunc:() => this.props.table.selected.length === 0
    },
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    },
  ]

  _tableActions = [
    {
      label:"Download",
      iconClassName:"mdi mdi-download",
      onClick: (item) => this.download(item)
    }
  ]

  columns=[
    {isSortable: false, label: "word.npwp", value: "npwp", show: true, isSearchable: true},
    {isSortable: false, label: "word.jenisPajak", value: "jenisPajak.code", show: true, isSearchable: true},
    {isSortable: false, label: "word.jenisSetoran", value: "jenisSetoran.code", show: true, isSearchable: true},
    {isSortable: false, label: "word.month1", value: "bulan1.code", show: true, isSearchable: true},
    {isSortable: false, label: "word.month2", value: "bulan2.code", show: true, isSearchable: true},
    {isSortable: false, label: "word.year", value: "year", show: true, isSearchable: true},
    {isSortable: true, label: "word.count", type: "number", value: "totalItem", show: true, isSearchable: true},
    {isSortable: true, label: "word.status", value: "status", show: true, isSearchable: true},
    {isSortable: true, label: "word.pesan", value: "message", show: true, isSearchable: true},
  ]

  async checkConnection() {
   /*let res = await this.ebillingAccountService.api.me()

    this.setState({ebillingUser: res.data});*/
  }

  async componentDidMount() {
    this.rootPath = this.props.match.url;

    try {
      this.checkConnection()
    } catch (e){
      this.toast.warning({
        title: e.name,
        message: e.message
      })
    }
  }

  async download(item) {
    if (item.status === 'FINISH') {
      var report = await this.service.api.download(item.id)
      var filename = report.headers.filename

      download(report.data, filename);
    } else {
      izitoast.error({
        title: 'Error',
        message: 'Data yang bisa di-download hanya yang berstatus = FINISH'
      })
    }
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Status'
          name='status'
          className="md-cell md-cell--12"
          component={this.ig.Searchfield}
          options={[
            {id: null, name: 'ALL'},
            {id: 'WAITING', name: 'WAITING'},
            {id: 'ON_PROCESS', name: 'ON_PROCESS'},
            {id: 'COMPLETED', name: 'COMPLETED'},
            {id: 'FAILED', name: 'FAILED'}
          ]}
        />
      </this.SearchContainer>
    )
  }

  // right bar
  tabWidth = 280
  /*tabs= ["status", "search", "column"]

  tabComponents() {
    var obj = this;
    return {
      status: this.status.bind(obj)
    }
  }

  status() {
    const { handleSubmit, submitting, valid, pristine } = this.props;
    return (
      <div className='mpk-layout column fill'>
        <div className='flex'>
          <List className="">
            <Subheader primaryText="Status Koneksi" />

            { this.state.ebillingUser.login &&
              <ListItem
                leftAvatar={<Avatar  suffix="green" icon={<FontIcon>done</FontIcon>} />}
                primaryText="Terhubung"
                secondaryText={"Terhubung sebagai " + this.state.ebillingUser.login + "."}
              />
            }

            { !this.state.ebillingUser.login &&
              <ListItem
                leftAvatar={<Avatar suffix="red" icon={<FontIcon>clear</FontIcon>} />}
                primaryText="Tidak Terhubung"
                secondaryText="Status Koneksi"
              />
            }

            {/!*<Subheader primaryText="Status Sistem" />

            <ListItem
              leftAvatar={<Avatar  suffix="green" icon={<FontIcon>done</FontIcon>} />}
              primaryText="Sistem Active"
              secondaryText="Status Sistem"
            />*!/}
          </List>
        </div>
      </div>
    )
  }*/

}
