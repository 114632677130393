import ApiService from '../../../../services/api.service';

class AkunPembayaranService extends ApiService {
  name= 'AkunPembayaran';
  path = 'ebilling/payment-account';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api = {
    findByOrderTypeId: async (id) => {
      var res = await this.http.get(`${this.apiLocation + "/" + this.path}/allByOrderType/${id}`)

      return res.data;
    },
  }
}

export default new AkunPembayaranService();
