import ApiService from '../../../../services/api.service';

class LogEfilingService extends ApiService {
  name= 'LogEfiling';
  path= 'pasal4/efiling/logs';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api={
    downloadBpe: async (params)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/download/bpe`, {
        responseType: 'blob',
        params
      })

      return res;
    },
    downloadBpeChecked: async (datas)=> {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/download/bpe`, datas, {
        responseType: 'blob',
      })

      return res;
    },
    checkStatusChecked: async (ids)=> {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/status`, ids)

      return res.data;
    },
    setFailedChecked: async (datas)=> {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/failed/bulk`, datas)

      return res.data;
    },
    upload: async(data, sptId) => {
      var res = await this.http.get(`${this.apiLocation}/pasal4/spt/${sptId}/submit`)

      return res.data;
    },

    exportBulkExcel: async (data) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/excel/bulk`, data, {
        responseType: 'blob'
      })
      return res;
    },

    exportByParamExcel: async (data) => {
      let res = await this.http.get(`${this.apiLocation}/${this.path}/excel/param`, {
        params: data,
        responseType: 'blob'
      });
      return res;
    },
  }
}


export default new LogEfilingService();
