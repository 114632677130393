import ApiService from '../../../../../services/api.service'
import moment from 'moment'

class ApplicationOrganizationService extends ApiService {
  name= 'ApplicationOrganization'
  path= 'ebupot/application/organization'
  advancedSearch=true

  constructor(){
    super()
    this.init()
    this.initApi()
  }

  columns = [
    {label: "word.id", searchField: "id", value: "id", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
    {label: "word.name", searchField: "nama", value: "organization.nama", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {isSortable:true, label: "word.active", value: "organization.active", type: 'boolean', onClick:() => {}, show:true, isSearchable:true},
    {label: "word.npwp", searchField: "npwp", value: "organization.npwp", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.email", searchField: "email", value: "organization.email", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.phone", searchField: "noTelp", value: "organization.noTelp", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.city", searchField: "kota", value: "organization.kota", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.postalCode", searchField: "kodePos", value: "organization.kodePos", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.address", searchField: "alamat", value: "organization.alamat", isDefaultSort:false, isSortable:true, show:true, isSearchable:false},
    {label: "word.createdBy", searchField: "createdBy", value: "createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.createdDate", searchField: "createdDate", value: (d)=> {
      if(d){
        return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
      } else {
        return "-"
      }
    }, type: 'func', isDefaultSort:true, isSortable:true, show:true, isSearchable:true},
    {label: "word.lastModifiedBy", searchField: "lastModifiedBy", value: "lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.lastModifiedDate", searchField: "lastModifiedDate", value: (d)=> {
      if(d.lastModifiedDate){
        return moment(d.lastModifiedDate).format('DD/MM/YYYY HH:MM')
      } else {
        return "-"
      }
    }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
  ]

}

export default new ApplicationOrganizationService();
