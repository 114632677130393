import React, { Component } from 'react';
import {connect} from 'react-redux';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress
} from 'react-md';
import { Field, reduxForm, getFormValues} from 'redux-form';

import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox} from '../../../../../../components/form';
import terbilangService from '../../../../../../services/terbilang.service';

@connect((state) => ({
  formData: getFormValues('BP_4a2_Form')(state)
}))
export default class BP4a2PenjualanSahamForm extends Component {

  componentDidMount() {
    this.props.connectCmp(this.reCalculate.bind(this))
  }

  async reCalculate() {
    console.log('penjualanSaham')
    var form = {...this.props.formData}
    var d    = form.penjualanSaham;

    Object.keys(d).forEach((k, i) => {
      if(!(k == 'jmlBruto' || k == 'jmlPph' || k == 'terbilang' || k == 'lastModifiedBy' || k == 'lastModifiedDate' || k == 'id' || k == 'createdDate' || k == 'createdBy')) {
        var x = d[k];

        if(typeof x == 'object') {
          if(x.nilaiTransaksiPenjualan) x.pph = x.nilaiTransaksiPenjualan*x.tarif/100
        }
      }
    })

    await this.props.initialize(form);
    await this.calculateTotal()
  }

  // async calculate(e,v,pv,f, parent) {
  //   var form = this.props.formData;
  //   var d    = form.penjualanSaham[parent];

  //   var bruto = parseFloat(d.nilaiTransaksiPenjualan);
  //   var tarif = parseFloat(d.tarif);

  //   if(f.search('nilaiTransaksiPenjualan') >= 0) {
  //     bruto = parseFloat(v)
  //   } else if(f.search('tarif') >= 0) {
  //     tarif = parseFloat(v)
  //   }

  //   if(!bruto) bruto = 0;
  //   if(!tarif) tarif = 0;


  //   await this.props.change(`penjualanSaham.${parent}.pph`, bruto*tarif/100);

  //   this.calculateTotal()
  // }

  // async calculateTotal() {
  //   var form = this.props.formData;
  //   var d    = form.penjualanSaham;

  //   var jmlPph   = 0;
  //   var jmlBruto = 0;
  //   Object.keys(d).forEach((k, i) => {
  //     if(!(k == 'jmlBruto' || k == 'jmlPph' || k == 'terbilang')) {
  //       var x = d[k];

  //       jmlPph   = jmlPph+ parseFloat(x.pph);
  //       jmlBruto = jmlBruto+ parseFloat(x.nilaiTransaksiPenjualan)
  //     }
  //   })

  //   this.props.change('bruto', jmlBruto)
  //   this.props.change('pph', jmlPph);

  //   this.props.change('terbilang', terbilangService(parseInt(jmlPph)));

  // }

  async calculate(e,v,pv,f, parent) {
    var form = this.props.formData;
    var d    = form.penjualanSaham[parent];

    var bruto = parseFloat(d.nilaiTransaksiPenjualan);
    var tarif = parseFloat(d.tarif);

    if(f.search('nilaiTransaksiPenjualan') >= 0) {
      bruto = parseFloat(v)
    } else if(f.search('tarif') >= 0) {
      tarif = parseFloat(v)
    }

    if(!bruto) bruto = 0;
    if(!tarif) tarif = 0;

    var pph = Math.floor(bruto * tarif / 100)

    await this.props.change(`penjualanSaham.${parent}.pph`, pph);

    this.calculateTotal()
  }

  async calculateTotal() {
    var form = this.props.formData;
    var d    = form.penjualanSaham;

    var jmlPph   = 0;
    var jmlBruto = 0;
    Object.keys(d).forEach((k, i) => {
      if(k === 'sahamPendiri' || k === 'bukanSahamPendiri') {
        var x = d[k];

        jmlPph   = jmlPph+ parseFloat(x.pph);
        jmlBruto = jmlBruto+ parseFloat(x.nilaiTransaksiPenjualan)
      }
    })

    jmlBruto = Math.floor(jmlBruto)
    jmlPph = Math.floor(jmlPph)

    this.props.change('bruto', jmlBruto)
    this.props.change('pph', jmlPph);

    this.props.change('terbilang', terbilangService(parseInt(jmlPph)));

  }

  render() {
    return <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
      <TableHeader>
        <TableRow>
          <TableColumn>No.</TableColumn>
          <TableColumn>Uraiian</TableColumn>
          <TableColumn className='md-text-right'>Nilai Transaksi Penjualan(Rp)</TableColumn>
          <TableColumn className='md-text-right'>Tarif(%)</TableColumn>
          <TableColumn className='md-text-right'>PPH Di Potong(Rp)</TableColumn>
        </TableRow>
      </TableHeader>
      <TableBody>
        <TableRow>
          <TableColumn>1.</TableColumn>
          <TableColumn>Saham Pendiri</TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'penjualanSaham.sahamPendiri.nilaiTransaksiPenjualan'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'sahamPendiri')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'penjualanSaham.sahamPendiri.tarif'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'sahamPendiri')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'penjualanSaham.sahamPendiri.pph'
              component = {TextfieldMask}
              className = 'md-text-right'
              disabled
              money = {','}
            />
          </TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn>2.</TableColumn>
          <TableColumn>Bukan Saham Pendiri</TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'penjualanSaham.bukanSahamPendiri.nilaiTransaksiPenjualan'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'bukanSahamPendiri')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'penjualanSaham.bukanSahamPendiri.tarif'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'bukanSahamPendiri')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'penjualanSaham.bukanSahamPendiri.pph'
              component = {TextfieldMask}
              className = 'md-text-right'
              disabled
              money = {','}
            />
          </TableColumn>
        </TableRow>

        <TableRow style={{background: '#ddd', borderTop: '3px solid black'}}>
          <TableColumn></TableColumn>
          <TableColumn>
            Jumlah
          </TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'bruto'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
              disabled
            />
          </TableColumn>
          <TableColumn>
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'pph'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
              disabled
            />
          </TableColumn>
        </TableRow>

        <TableRow style={{background: '#ddd'}}>
          <TableColumn></TableColumn>
          <TableColumn>
            Terbilang
          </TableColumn>
          <TableColumn colSpan={3}>
            <Field
              // label='Bruto'
              name      = 'terbilang'
              component = {Textfield}
              disabled
            />
          </TableColumn>
        </TableRow>
      </TableBody>
    </DataTable>
  }
}
