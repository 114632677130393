import React from 'react'
import Spt1771 from './Spt1771'
import env from 'env'

export default class EbillingWidget extends React.Component {

    componentDidMount(){
        var url = ''
        if(env.mode == 'prod'){
            url = 'https://1771.pajakku.com/static/libs/xml-1771-widget-pajakku.min.js'
        } else {
            url = 'https://1771.staging.pajakku.com/static/libs/xml-1771-widget-pajakku.min.js'
        }
        const script = document.createElement("script");
        script.src = url;
        script.async = true;
        document.body.appendChild(script);
    }
    
    render(){
        var widgetClientId = ''
        if(env.mode == 'prod'){
            widgetClientId = "DwZauBo82AKGUdw4DByWXMXIJ12Jlupxm2xdVerN"
        } else {
            widgetClientId = "24bkLtFEcWkNU7yl69b3DPOfCmDMOmzSCU2jUHt3"
        }
        return (
            <Spt1771 widgetClientId={widgetClientId} />
        )
    }
}