import ApiService from '../../../../../services/api.service';

class Master21HonorPnsGlobalService extends ApiService {
  name= 'Master21HonorPnsGlobal';
  path= 'rates/honor-pns-global';

  constructor() {
    super();
    this.init()
    this.initApi()
  }

  api= {
    getData: async () => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}`)
      return res;
    }
  }

}


export default new Master21HonorPnsGlobalService();
