import ApiService from '../../../../../services/api.service';
import http from "../../../../../services/http.service";

class SSP25Service extends ApiService {
  name= 'SSP25';
  path= 'pasal25/ssp';
  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    getTotal: async(sptId)=> {
      return await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/total`)
    },
    getSptInfo: async(sptId)=> {
      return await this.http.get(`${this.apiLocation}/pasal25/spt/${sptId}/info`)
    },
    cetak: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/pasal25/spt25/ssp/report/${sptId}`, {
        responseType: 'blob'
      })

      return res;
    },
    downloadCsv: async (sptId)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/spt/export`)

      return res;
    },
    deleteBulk: async (sptId, ids)=> {
      let res = await http.post(`${this.apiLocation}/${this.path}/${sptId}/delete`, ids);

      return res;
    },
    deleteItem: async (sptId, ids)=> {
      let res = await http.delete(`${this.apiLocation}/${this.path}/${ids[0]}`);

      return res;
    }
  }
}


export default new SSP25Service();
