import React, { Component } from 'react';
import { Button, DialogContainer, TextField,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  TablePagination,
} from 'react-md';

export default class ImportDialogResult extends Component {
  static defaultProps = {
    dataResults: [],
    visible: false,
    onHide: ()=> {}
  }

  render() {
    return (
      <DialogContainer
        id="simple-action-dialog"
        visible={this.props.visible}
        onHide={this.props.onHide}
        width={600}
        style={{width: 600}}
        actions={
          <Button flat primary onClick={this.props.onHide}>Close</Button>
        }
        title="Status File"
        >
          <DataTable
            plain
            baseId="tableResult-data"
            className="data-tables__fixed-table"
            fixedHeader
            fixedFooter
            fixedHeight={300}
          >
            <TableHeader>
              <TableRow>
                <TableColumn tooltipLabel={'Filename'}>Filename</TableColumn>
                <TableColumn tooltipLabel={'Status'}>Status</TableColumn>
                <TableColumn tooltipLabel={'Message'}>Message</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              {this.props.dataResults.map((d, i)=> {
                return <TableRow key={i}>
                  <TableColumn>{d.upload && <div>{d.upload.originalFilename}</div> }</TableColumn>
                  <TableColumn>{d.error && <div>Error</div>} {!d.error && <div>Success</div>}</TableColumn>
                  <TableColumn>{d.message}</TableColumn>
                </TableRow>
              })}

            </TableBody>
          </DataTable>

        </DialogContainer>
    )
  }
}
