/**
 * Created by dwiargo on 11/29/17.
 */

import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Main from './modules/Main/Main';
import MainSupport from './modules/Support/Main';
import MainOri from './modules/Main/MainOri';
import Test from './modules/Main/Test'
import Error from './modules/Error/Error'
import Account from './modules/Account/Account'
import ResetPassword from './modules/Account/ResetPassword'
import Log from './modules/Main/pph/15/Export/ExportCsv15Log/ExportCsv15Log.view'

export default actions => (
  <Switch>
    <Route path="/account" component={Account}/>
    <Route path="/reset/finish" component={ResetPassword}/>
    <Route path="/main" component={Main}/>
    <Route path="/main-support" component={MainSupport} />
    <Route path="/" exact render={() => (
      <Redirect to="/main"/>
    )}/>
    <Route path="/error" render={(props) => (
      <Error router={props}/>
    )}/>
    <Route path="/company/:companyId/:subscriptionId/init" component={MainOri}/>
    <Route path="/xxx" component={Log} />
  </Switch>
);
