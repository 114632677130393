import React, { Component } from 'react';
import { Grid, Cell } from 'react-md';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import {validation, Textfield} from '../../../../../../components/form';
import DialogView from '../../../../../../components/entity/dialog.view';
import Master22TarifService from './Master22Tarif.service';

@reduxForm({form: '22-master-tarif-dialog', destroyOnUnmount: false})
export default class Master22TarifDialogView extends DialogView {
  service=Master22TarifService;
  initialData = {
    kode: null,
    tarif: 0.0,
    seq: 0
  }

  formView() {
    return (
      <Grid style={{padding:'none'}}>
        <Field
          label='Kode'
          name='kode'
          className="md-cell md-cell--12"
          component={Textfield}
          validate={validation.required}
        />

        <Field
          label='Jenis Penghasilan'
          name='jenisPenghasilan'
          className="md-cell md-cell--12"
          component={Textfield}
          validate={validation.required}
        />

        <Field
          label='Tarif'
          name='tarif'
          className="md-cell md-cell--12"
          component={Textfield}
          validate={validation.required}
        />

        <Field
          label='Seq'
          name='seq'
          className="md-cell md-cell--12"
          component={Textfield}
          validate={validation.required}
        />
      </Grid>
    )
  }
}
