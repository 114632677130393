import React, { Component } from 'react';
import { Grid, Cell } from 'react-md';
import {Field, reduxForm, propTypes, formValueSelector, getFormValues} from 'redux-form';
import { validation, Textfield, TextfieldMask, convert } from '../../../../components/form';
import DialogView from '../../../../components/entity/dialog.view';
import MasterLawanTransaksiA1Service from './MasterLawanTransaksiA1.service';
import OrganizationService from "../../../Main/Administrator/Organization/Organization.service";
import {Searchfield, Switch} from "src/components/form";
import CountryCodeService from "../CountryCode/CountryCode.service";
import {connect} from "react-redux";

@reduxForm({form: 'master21LtA1', destroyOnUnmount: false})
@connect((state) => ({
  form:getFormValues('master21LtA1')(state),
  organization: state.authEppt.currentOrganization,
  // EPPT CORE
  auth: state.auth.company
  // EPPT CORE
}))
export default class MasterLawanTransaksiA1DialogView extends DialogView {
  service=MasterLawanTransaksiA1Service
  initialData = {
    jenisKelamin: 'L',
    jmlTanggungan: 0
  }

  formView() {
    var npwpNotValid = this.state.npwpNotValid
    var hideNegara = false;
    if(this.props.form){
      if(this.props.form){
        if(this.props.form.wpLuarNegeri){
          hideNegara = true
        }
      }
    }

    var org = []
    if(this.props.auth && this.props.auth.organizations) org = this.props.auth.organizations
    var _this = this
    return (
      <Grid style={{padding:'none'}}>
        {/* <Field

          label='Organization'
          name='organization'
          id="spt2126-organization"
          className="md-cell md-cell--12"
          valueField='parent'
          service={OrganizationService}
          searchField  = {['name', 'aliasName', 'npwp']}
          component={Searchfield}
          itemTemplate={function(d) {
            let nameAndAlias = ""

            if (d.name)
              nameAndAlias = d.name

            if (d.aliasName)
              nameAndAlias += " - " + d.aliasName

            return {
              primaryText  : nameAndAlias,
              secondaryText: d.npwp,
              onClick: () => {
                if(!this.props.disabled){
                  this.handleItemClick(d); this.hide()
                }
              }
            }
          }}
          validate={validation.required}
        /> */}
        <Field
          label     = 'Organization'
          name      = 'organization'
          id        = "spt2126-organization"
          className = "md-cell md-cell--12"
          component = {Searchfield}
          valueField = 'parent'
          options   = {org}
          itemTemplate = {function(d) {
            let nameAndAlias = ""
            if (d.name)
              nameAndAlias = d.name
            if (d.aliasName)
              nameAndAlias += " - " + d.aliasName
            return {
              primaryText  : nameAndAlias,
              secondaryText: d.npwp,
              onClick      : () => {
                if(!this.props.disabled){
                  _this.props.change("npwpProfile", d.npwp)
                  this.handleItemClick(d); this.hide()
                }
              }
            }
          }}
        />
        {/*<Field
          label='NPWP Profile'
          name='npwpProfile'
          className="md-cell md-cell--6"
          component={TextfieldMask}
          maskFormat="##.###.###.#-###-###"
          mask="_"
          length={15}
          validate={validation.required}
          disabled={true}
        />*/}
        <Field
          label='NPWP'
          name='npwp'
          className="md-cell md-cell--6"
          component={TextfieldMask}
          maskFormat="##.###.###.#-###-###"
          mask="_"
          length={15}
          validate={validation.required}
        />
        <Field
          label='NIK'
          name='nik'
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
        />
        <Field
          label='Name'
          name='name'
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
        />
        <Field
          label='Alamat'
          name='address'
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
        />

        <Field
          label='Jenis Kelamin'
          name='gender'
          className="md-cell md-cell--6"
          disabled={this.state.npwpNotValid}
          component={Searchfield}
          options={[{id: 'L', name: 'Laki-laki'}, {id:'P', name: 'Perempuan'}]}
        />
        <Field
          label='Status PTKP'
          name='ptkpStatus'
          className="md-cell md-cell--6"
          disabled={this.state.npwpNotValid}
          component={Searchfield}
          options={[{id: 'TK', name: 'TK'}, {id: 'K', name: 'K'}, {id: 'K/I', name: 'K/I'}, {id: 'HB', name: 'HB'}]}
          validate={validation.required}
        />
        <Field
          label='Jumlah Tanggungan'
          name='ptkpCount'
          className="md-cell md-cell--6"
          disabled={this.state.npwpNotValid}
          component={Searchfield}
          options={[{id: 0, name: '0'}, {id: 1, name: '1'}, {id: 2, name: '2'}, {id: 3, name: '3'}]}
        />
        <Field
          label='Jabatan'
          name='positionName'
          className='md-cell md-cell--6'
          id="BP21A2Form-jabatan"
          disabled={this.state.npwpNotValid}
          component={Textfield}
          validate={validation.required}
        />
        <Field
          label='Email'
          name='email'
          className="md-cell md-cell--6"
          component={Textfield}
        />

        <Field
          label='Warga Negara Asing'
          name='foreignEmployee'
          component={Switch}
          className="md-cell md-cell--6"
          valueField="foreignEmployee"
          disabled={npwpNotValid}
        />

        {hideNegara &&
        <Field
          label='Kode Negara Domisili'
          name='kodeNegara'
          component={Searchfield}
          service={CountryCodeService}
          disabled={npwpNotValid}
          valueField="id"
          className="md-cell md-cell--6"
        />
        }
      </Grid>
    )
  }
}
