import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-md';
import SettingExportService from './SettingExport.service';
import ListView from '../../../../components/entity/listView';
import ColumnService from '../../../../services/column.service';

@connect(SettingExportService.stateConnectSetting(), SettingExportService.actionConnectSetting())
export default class SettingExportView extends ListView {
  service=SettingExportService
  editDialog=false

  _barActions = [
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => this.fetchData()
    }
  ]

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    }
  ]

  editItem = (item) => {
    this.props.history.push("SETTING_IMPORT"+"/"+item.user.id)
  }

  columns=[
    {isSortable: true, label: "word.id", searchField: "id", value: function(d){ return d.id }, type: "func",  show:false, isSearchable:true},
    {isSortable: true, label: "word.user", searchField: "user", value: function(d){ return d.user.login }, type: "func",  show:true, isSearchable:true},
    {isSortable: true, label: "word.encryptA1", type: "func", value: (d) => {
      if (d.encryptA1) {
        return <Button flat swapTheming style={{backgroundColor: '#4caf50', color: 'white'}}>Iya</Button>
      }

      return <Button flat swapTheming style={{backgroundColor: '#B71C1C', color: 'white'}}>Tidak</Button>
    }, show: true, isSearchable: true},
  ]
}
