import React, { Component } from 'react';
import { Grid, Cell, List,
  ListItem,
  ListItemControl,
  FontIcon,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Tabs,
  TabsContainer,
  Tab
} from 'react-md';
import { connect } from 'react-redux';
import { Field, reduxForm , propTypes, formValueSelector , getFormValues} from 'redux-form';
import { validation, Textfield, TextfieldMask, convert, Searchfield } from '../../../../components/form';
import DialogView from '../../../../components/entity/dialog.view';
import MainFlowService from './MainFlow.service';
import UserService from '../../Administrator/User/User.service';
import _ from 'lodash';


@reduxForm({form: 'flow_action_dialog_bulk', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  formState: getFormValues('flow_action_dialog_bulk')(state)
}))
export default class FlowActionBulkDialog extends DialogView {
  service=MainFlowService
  size='normal'
  translate=false
  formStyle={
    padding: 0
  }

  typingObj=null;
  typingTimeout=1000;

  titleHeader() {
    if(this.props.isEditDialog) {
      return this.props.dialogType
    } else {
      return this.props.dialogType
    }
  }

  initialData = {

  }

  initData(props) {
    var value = {};
    if(props.formData.extras) value = _.cloneDeep(props.formData);
    if(value.id) {
      this.props.initialize(value);
    } else {
      this.props.initialize(this.initialData);
    }
  }

  componentWillReceiveProps(nextProps) {
    var value = {};
    var nextValue = {}
    if(this.props.formData) value = this.props.formData;
    if(nextProps.formData) nextValue = nextProps.formData;
    if(value.id != nextValue.id) {
      this.initData(nextProps)
    }
  }

  async handleSave(callback, value) {
    this.props.onSubmit(value, callback);
    this.props.initialize({});
  }

  formView() {
    return (
      <TabsContainer panelClassName="md-grid" colored>
        <Tabs tabId="simple-tab" style={{overflow: 'hidden'}}>
          <Tab label="Batch">
            <div style={{width: '100%', minHeight: 300}}>
            {this.buildBatchPick()}
            </div>
          </Tab>
          <Tab label="Satuan" style={{overflow: 'hidden'}}>

              <DataTable className='animated fadeInDown' plain fixedHeader fixedHeight={280}>
              <TableHeader>
                <TableRow>
                  <TableColumn>Npwp</TableColumn>
                  <TableColumn>Nama</TableColumn>
                  {/* <TableColumn>Status</TableColumn> */}
                  <TableColumn>Masa</TableColumn>
                  <TableColumn>Tahun</TableColumn>
                  <TableColumn>Pembetulan</TableColumn>

                  <TableColumn>State</TableColumn>

                  {this.props.dialogType !== 'Pengajuan' &&
                  <TableColumn>Action</TableColumn>
                  }
                  <TableColumn style={{width: 300}}>Note</TableColumn>

                </TableRow>
              </TableHeader>
              <TableBody>
                  {this.props.approvalList.map((el, i) => {
                    var flowState = '';
                    if(el.flowState) flowState = el.flowState.name;

                    var options = [];
                    if(this.props.stateMap[el.flowState]) options = this.props.stateMap[el.flowState].actions;
                    return <TableRow>
                      <TableColumn>{el.npwp}</TableColumn>
                      <TableColumn>{el.nama}</TableColumn>
                      {/* <TableColumn>{el.status}</TableColumn> */}
                      <TableColumn>{el.masa}</TableColumn>
                      <TableColumn>{el.tahun}</TableColumn>
                      <TableColumn>{el.pembetulan}</TableColumn>

                      <TableColumn>{el.currentState}</TableColumn>

                      {this.props.dialogType !== 'Pengajuan' &&
                        <Field
                          style={{width: 200}}
                          // label='Actions'
                          name={el.id+'.actionId'}
                          options={
                            options
                          }
                          className="md-cell md-cell--12"
                          component={Searchfield}
                        />
                      }
                      <TableColumn>
                        <Field
                          style={{width: 300}}
                          // label='Note'
                          name={el.id+'.note'}
                          className="md-cell md-cell--12"
                          component={Textfield}
                        />
                      </TableColumn>
                    </TableRow>
                  })}
              </TableBody>
            </DataTable>

          </Tab>
        </Tabs>
      </TabsContainer>
    )
  }

  buildBatchPick() {
    return <div className='md-grid'>
      {this.props.dialogType !== 'Pengajuan' &&
      this.props.stateList.map((d)=> {
        return <Field
          label = {'Flow state '+d.name}
          key= {d.id}
          style={{width: 200}}
          // label='Actions'
          name={'globalAction.'+d.id}
          options={
            d.actions
          }
          className="md-cell md-cell--12"
          component={Searchfield}
          onChange={this.actionOnChange.bind(this)}
        />
      })
      }

      <Field
        label='Note'
        name={'globalNote'}
        className="md-cell md-cell--12"
        component={Textfield}
        onChange={this.noteOnChange.bind(this)}
      />
    </div>
  }

  noteOnChange(e, v) {
    if(this.typingObj) clearTimeout(this.typingObj);

    this.typingObj = setTimeout(async ()=> {
      var formState = {...this.props.formState}
      this.props.approvalList.forEach((d, i)=> {
        if(!formState[d.id]) formState[d.id] = {}
        formState[d.id].note = v;
      })

      this.props.initialize(formState);
    }, this.typingTimeout);
  }

  actionOnChange(e, v, pv, f) {
    var flowStateId = f.split(".")[1];

    var formState = {...this.props.formState}
    this.props.approvalList.forEach((d, i)=> {
      if(d.flowState === flowStateId) {
        if(!formState[d.id]) formState[d.id] = {}
        formState[d.id].actionId = v;
      }
    });

    this.props.initialize(formState);
  }

}
