import React from 'react'
import LogExportEbupotView from './LogExportEbupot.view'
import { TabsContainer, Tabs, Tab } from 'react-md';

export default class LogExportEbupotAll extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            pasal: 'bp'
        }
    }

    render(){
        return (
            <div style={{ width: '100%', height: '100%' }}>
                <TabsContainer colored>
                    <Tabs tabId="simple-tab">
                        <Tab onClick={()=> this.setState({ pasal: 'bp' })} label="BP"></Tab>
                        <Tab onClick={()=> this.setState({ pasal: 'pdfBp' })} label="PDF BP"></Tab>
                        <Tab onClick={()=> this.setState({ pasal: 'pdfBpe' })} label="PDF BPE"></Tab>
                        <Tab onClick={()=> this.setState({ pasal: 'pdfInduk' })} label="PDF Induk"></Tab>
                    </Tabs>
                </TabsContainer>
                <div style={{ width: '100%', height: '94%' }}>
                    <LogExportEbupotView pasal={this.state.pasal} />
                </div>
            </div>
        )
    }
}
