/**
 * Created by dwiargo on 11/29/17.
 */

import {Connect, connect} from 'react-redux';
import React, {Component} from 'react';
import ContainerPaper from '../components/ContainerPaper'
import counterpart from 'counterpart';
import {Button, FontIcon, TextField, Tooltipped} from 'react-md';
import './ResetPassword.scss';
import AuthService from '../../../services/auth.service';
import izitoast from 'izitoast';

@connect()
class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showForgotPasswordView: false,
      signature: {},
      formData: {},
      validation: {},
      error: {},
      status: 'wait',
      version: '',
      serverStatus: {
        status: 'UP',
        class: 'md-avatar--green'
      }

    }
  }

  async componentDidMount() {
    var version = await AuthService.http.get('/management/info');
    if (version.data && version.data.build) {
      this.setState({version: version.data.build.version})
    }

    // status
    await this.checkStatus();
    setInterval(async () => {
      await this.checkStatus();
    }, 120000)
  }

  async checkStatus() {
    try {
      var status = await AuthService.http.get('/management/health');
      var className = 'md-avatar--red';
      if (status.data.status == 'UP') className = 'md-avatar--green';
      this.setState({
        serverStatus: {
          status: status.data.status,
          class: className
        }
      })
    } catch (ex) {
      this.setState({
        serverStatus: {
          status: 'Down',
          class: 'md-avatar--red'
        }
      })
    }
  }

  goLogin() {
    this.props.history.push('/account/login')
  }

  async resetPassword() {
    let search = this.props.location.search
    let key = search.replace('?key=', '')
    var newPassword = this.refs.password.value;
    var passwordConfirmation = this.refs.passwordConfirmation.value;

    if (newPassword !== passwordConfirmation) {
      izitoast.error({title: 'Error', message: 'Password doesnt match.'})

      return
    }

    let data = {key, newPassword}

    try {
      let res = await AuthService.api.forgotPasswordFinish(data);
      this.props.history.push('/account/login')
    } catch (ex) {
      // izitoast.error({title: 'Error', message: 'Failed to reset password.'})
    }

  }

  enter(e) {
    if (e.key === 'Enter') {
      this.login()
    }
  }

  render() {
    let {validation, error, status, formData} = this.state;

    return (
      <ContainerPaper
        status={status}
        validation={validation}
        headerText={<div>
          {this.state.version}
        </div>}
        deck="Pengolahan Pajak Terpadu"
        footer={
          <div className="mpk-margin-N top mpk-layout justify-between mpk-font-weight-M">
              <span
                className="mpk-link mpk-font-weight-M"
                onClick={() => this.goLogin()}
              >
                {counterpart.translate('word.login')}
              </span>
            <span
              className="mpk-link mpk-font-weight-M"
              onClick={() => navService.redirectTo('/account/register')}
            >
                {counterpart.translate('word.createAccount')}
                </span>
          </div>
        }
      >
        <div className="body" onSubmit={(e) => {
          e.preventDefault();
          this.resetPassword()
        }
        }>
          <Tooltipped label="Empty element with tooltip">
            <div className={'md-inline-block md-avatar ' + this.state.serverStatus.class} style={{
              position: 'fixed',
              top: 14.5,
              right: 40,
              height: 18,
              width: 18
            }}>

              <div className="md-avatar-content"></div>

            </div>
          </Tooltipped>

          <div className="mpk-flex">
            <TextField
              ref="password"
              id="user-password"
              label="password"
              placeholder="Enter your password"
              lineDirection="center"
              type="password"
              onKeyPress={(e) => this.enter(e)}
              passwordIcon={(
                <FontIcon
                  iconClassName="mdi mdi-eye"
                  style={{fontSize: 16}}
                />
              )}
              required={true}
            />
          </div>

          <TextField
            ref="passwordConfirmation"
            id="user-password-confirmation"
            label="password confirmation"
            placeholder="Enter your password confirmation"
            lineDirection="center"
            type="password"
            onKeyPress={(e) => this.enter(e)}
            passwordIcon={(
              <FontIcon
                iconClassName="mdi mdi-eye"
                style={{fontSize: 16}}
              />
            )}
            required={true}
          />
        </div>
        <div onClick={() => this.resetPassword()} className="action mpk-flex-none">
          <Button raised secondary type="submit">Reset</Button>
        </div>
      </ContainerPaper>
    )
  }
}

export default ResetPassword;
