import ApiService from '../../../../../services/api.service';

class Master21KapService extends ApiService {
  name= 'Master21Kap';
  path= 'spt/kap?pasal=21';

  constructor() {
    super();
    this.init()
  }
}


export default new Master21KapService();

