import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Field, reduxForm } from 'redux-form';
import {Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab, Chip} from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../../../components/form';

import Email21LogService from './Email21Log.service';
import ListView from '../../../../../components/entity/listView';
import ColumnService from '../../../../../services/column.service';
import ImportService from './../Import/Import.service';
import axios from 'axios';
import izitoast from 'izitoast';
import download from "downloadjs";
import FormDialog from './Email21Log.dialog.view'

@reduxForm({form: 'FILE_LAPOR_21', destroyOnUnmount: true})
@connect((state)=> ({
  ...Email21LogService.stateConnectSetting()(state),
  spt: state.authEppt.currentSPT,
  user: state.authEppt.user
}), Email21LogService.actionConnectSetting())
export default class Email21Log extends ListView {
  FormDialog=FormDialog
  service=Email21LogService
  // viewType=2

  constructor(props){
    super(props);
    this.state = {
      showTableSetting:true,
      showDialogConfirmDeleteSelected:false,
      showForm:false,
      formData: {},
      data: [],
      onProgress: false,
      isError: false,
      errorMessage: ''
    }
  }

  fetchOption() {
    var compId = this.props.match.params.companyId
    return {
      path: `log/mail/${compId}/company`
    }
  }

  beforeFetch(params) {
    // params["category.equals"] = "BP_21_A1"
    // if(this.props.match.params.companyId) params["companyId.equals"] = this.props.match.params.companyId;
    // if(this.props.user.company && this.props.user.company.id) params["companyId.equals"] = this.props.user.company.id;
  }

  async handleExportAll() {

    if (this.props.table.selected.length > 0) {
      var selected = this.props.table.selected
      let separator = ';'

      let csvContent = ''

      let header = [
        'No', 'Tipe', 'Kategori', 'Status', 'Judul', 'Penerima', 'Pesan', 'Nama File', 'Schedule', 'Tanggal Jadwal', 'Eksekusi Jadwal',
        'Username', 'Author', 'Dibuat', 'Diubah Oleh', 'Diubah'
      ].join(separator)

      csvContent += header + "\r\n"

      selected.forEach((obj, index) => {
        let message = '-'

        if (obj.message) {
          message = obj.message.replace(/[\r\n]+/g, " ")
          message = message.replace(/[;]+/g, ",")
        }

        let row = [
          index + 1, obj.type, obj.category, obj.status, obj.subject, obj.to ? obj.to : '', message, obj.filename,
          obj.schedule ? 'YA' : 'TIDAK', obj.scheduledDate ? obj.scheduledDate : '', obj.executedDate ? obj.executedDate : '',
          obj.username, obj.createdBy, obj.createdDate, obj.lastModifiedBy, obj.lastModifiedDate
        ].join(separator)
        csvContent += row + "\r\n"
      })

      let now = new Date()
      let month = '' + (now.getMonth() + 1)
      let day = '' + now.getDate()
      let year = now.getFullYear()
      let hours = '' + now.getHours();
      let minutes = '' + now.getMinutes();
      let seconds = '' + now.getSeconds();

      if (month.length < 2) month = '0' + month
      if (day.length < 2) day = '0' + day
      if (hours.length < 2) hours = '0' + hour
      if (minutes.length < 2) minutes = '0' + minutes
      if (seconds.length < 2) seconds = '0' + seconds
      let timestamp = `${year}${month}${day}_${hours}${minutes}${seconds}`

      let filename = 'LOG_EMAIL_' + timestamp + '.csv'

      var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename);
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", filename);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    }
  }

  async handleResend() {
    if (this.props.table.selected.length > 0) {
      var selected = this.props.table.selected
      var ids = [];

      selected.map(function (i) {
        ids.push(i.id)
      })

      var res = await this.service.api.resend(ids);

      let {message} = res.data

      iziToast.success({
        title: 'Sukses',
        message: message
      })
    }
  }

  _barActions = [
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => this.fetchData()
    },
    {
      label:'word.exportCsv',
      iconClassName:'mdi mdi-file-excel',
      disabledFunc:() => this.props.table.selected.length === 0,
      onClick:() => this.handleExportAll()
    },
    // {
    //   label:'word.resend',
    //   iconClassName:'mdi mdi-send',
    //   disabledFunc:() => this.props.table.selected.length === 0,
    //   onClick:() => this.handleResend()
    // },
  ]

  // async moreInfo(item){
  //   let res = await this.service.api.logEmailDetail(this.props.match.params.companyId, item.id)
  //   var data = res.data

  // }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Username'
          name='username__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Judul'
          name='subject__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Penerima'
          name='to__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='WAITING'
          name='status__in_WAITING'
          className="md-cell md-cell--6"
          component={this.ig.Checkbox}
        />
        {/*<this.ig.Field
          label='PROCESS'
          name='status__in_PROCESS'
          className="md-cell md-cell--6"
          component={this.ig.Checkbox}
        />*/}
        <this.ig.Field
          label='FAILED'
          name='status__in_FAILED'
          className="md-cell md-cell--6"
          component={this.ig.Checkbox}
        />
        <this.ig.Field
          label='COMPLETED'
          name='status__in_COMPLETED'
          className="md-cell md-cell--6"
          component={this.ig.Checkbox}
        />
      </this.SearchContainer>
    )
  }

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    },
  ]

  columns=[
    {isSortable: true, label: "word.type",  value: "type", isDefaultSort:false, show:true, isSearchable:true},
    {isSortable: true, label: "word.category",  value: "category", isDefaultSort:false, show:true, isSearchable:true},
    {isSortable: true, label: "word.status",  type: 'func', value: (d)=> {
          var style = {
            background: 'grey'
          }
          switch (d.status) {
              case 'PROCESS':
                  style.background = '#2196F3'
                  break;
              case 'COMPLETED':
                  style.background = '#4CAF50'
                  break;
              case 'FAILED':
                  style.background = '#f44336'
                  break;
              default:
                  break;
          }
          return <div style={{width: 200}}>
            <Chip label={d.status} style={style} />
          </div>
      }, isDefaultSort:false, show:true, isSearchable:true},
    {isSortable: true, label: "word.subject",  value: "subject", isDefaultSort:false, show:true, isSearchable:true},
    {isSortable: true, label: "word.recipient",  value: "to", isDefaultSort:false, show:true, isSearchable:true},
    {isSortable: true, label: "word.message",  value: "message", isDefaultSort:false, show:true, isSearchable:true},
    // {isSortable: true, label: "word.content",  value: "content", isDefaultSort:false, show:false, isSearchable:true},
    // {isSortable: true, label: "word.filename",  value: "filename", isDefaultSort:false, show:false, isSearchable:true},
    {isSortable: true, label: "word.scheduled",  value: "scheduled", type: 'boolean', isDefaultSort:false, show:true, isSearchable:true},
    {isSortable: true, label: "word.scheduledDate",  value: "scheduledDate", isDefaultSort:false, show:true, isSearchable:true},
    {isSortable: true, label: "word.executedDate",  value: "executedDate", isDefaultSort:false, show:true, isSearchable:true},
    // {isSortable: true, label: "word.bucket",  value: "bucket", isDefaultSort:false, show:false, isSearchable:true},
    {isSortable: true, label: "word.refId",  value: "refId", isDefaultSort:false, show:false, isSearchable:true},
    {isSortable: true, label: "word.companyId",  value: "companyId", type: 'number', isDefaultSort:false, show:false, isSearchable:true},
    {isSortable: true, label: "word.username",  value: "username", isDefaultSort:false, show:true, isSearchable:true},
]}


