import ApiService from '../../../../services/api.service';

class PengaturanNoBPService extends ApiService {
  name= 'PengaturanNoBP';
  path= 'spt21/nobupot/page';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api = {
  }
}

export default new PengaturanNoBPService();
