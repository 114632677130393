import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Button,
  Chip,
  Switch,
  List,
  ListItem,
  Subheader,
  FontIcon,
  Avatar,
  Divider
} from 'react-md';

import {validation, Textfield, Searchfield, TextfieldMask, Datepicker, Radio, Checkbox} from '../../../../components/form';
import Bluebird from 'bluebird';
import { Field, reduxForm, getFormValues, change  } from 'redux-form';
//import FormDialog from './Organization.dialog.view';
import UtilService from '../../../../services/utils.service';
import download from 'downloadjs';
import iziToast from 'izitoast';
import ListView from '../../../../components/entity/listView';
import DataESSPDuplicateService from './DataESSPDuplicate.service';

@connect((state)=> ({
  ...DataESSPDuplicateService.stateConnectSetting()(state),
}), DataESSPDuplicateService.actionConnectSetting())
export default class DataESSPDuplicateView extends ListView {
  service=DataESSPDuplicateService
  FormDialog=()=> <div/>

  constructor(props){
    super(props);
    this.state = {
      ...this.state
    }
  }

  addDialog=false
  editDialog=false

  columns=[
    {isSortable: true, label: "word.reference", value: "reference", show:false, isSearchable: true},
    {isSortable: true, label: "word.duplicate", value: "duplicate", show: true, isSearchable: true},
    {isSortable: true, label: "word.npwp", value: "bagA.npwp", show: true, isSearchable: true},
    {isSortable: true, label: "word.name", value: "bagA.nama", show: true, isSearchable: true},
    {isSortable: true, label: "word.pasal", value: "pasal", show: true, isSearchable: true},
    {isSortable: true, label: "entities.DataESSP.kodeMap", value: "kodeMap", show: true, isSearchable: true},
    {isSortable: true, label: "entities.DataESSP.kodeSetoran", value: "kodeSetoran", show: true, isSearchable: true},
    {isSortable: true, label: "entities.DataESSP.masaPajak", value: "masaPajak", show: true, isSearchable: true},
    {isSortable: true, label: "entities.DataESSP.pajakTerutang", value: "bagB.pajakTerutang", show: true, isSearchable: true, type: "number"},
    {isSortable: true, label: "word.type", value: "type", show: true, isSearchable: true},


  ]

  _barItem() {
    return (
      <div className='actions mpk-layout'>
        <Button icon tooltipLabel="Generate" onClick={()=> {}}>play_arrow</Button>
        <Button icon tooltipLabel="Save" onClick={()=> {}}>save</Button>
        <Button icon primary tooltipLabel="Batal" onClick={()=> {}}>delete</Button>
        <Switch
          style={{float: "right"}}
          id="switch-table-setting"
          name="Switch Table Setting"
          label=""
          checked={this.state.showTableSetting}
          onChange={() => {
            this.setState({
              showTableSetting:!this.state.showTableSetting
            })
          }}
        />
      </div>
    )
  }

  // right bar
  tabWidth = 280
  tabs= ["status", "search", "column"]

  tabComponents() {
    var obj = this;
    return {
      status: this.status.bind(obj)
    }
  }

  status() {
    const { handleSubmit, submitting, valid, pristine } = this.props;
    return (
        <div className='mpk-layout column fill'>
          <div className='flex'>
            <List className="">
              <ListItem
                leftAvatar={<Avatar suffix="orange" icon={<FontIcon>warning</FontIcon>} />}
                leftNodeStyle={{height: 100}}
                tileStyle={{height: 'auto'}}
                secondaryTextStyle={{whiteSpace: 'normal', height: 100}}
                primaryText="Warning"
                secondaryText="Terdapat data SSP yang sudah pernah disubmit, silahkan ceklis semua data yang ingin anda submit kemudian klik tombol Generate atau Save."
              />

              <Subheader primaryText="Status Koneksi" />

              <ListItem
                leftAvatar={<Avatar suffix="red" icon={<FontIcon>clear</FontIcon>} />}
                primaryText="Tidak Terhubung"
                secondaryText="Status Koneksi"
              />

              {/*<Subheader primaryText="Status Sistem" />

              <ListItem
                leftAvatar={<Avatar  suffix="green" icon={<FontIcon>done</FontIcon>} />}
                primaryText="Sistem Active"
                secondaryText="Status Sistem"
              />*/}
            </List>
          </div>
        </div>
    )
  }
}
