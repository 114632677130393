import download from "downloadjs";
import LogExportService from "modules/Main/Export/LogExport.service";
import React from 'react';
import { connect } from 'react-redux';
import ListView from '../../../../../../components/entity/listView';
import ColumnService from '../../../../../../services/column.service';
import ParamService from "../../../../../../services/param.service";
import AllSSP25Service from './AllSSP25.service';
import {Checkbox} from "../../../../../../components/form";
import moment from "moment";

@connect(AllSSP25Service.stateConnectSetting(), AllSSP25Service.actionConnectSetting())
export default class AllSSP25View extends ListView {
  service=AllSSP25Service

  constructor(props){
    super(props);
    this.state = {
      showTableSetting:false,
      showDialogConfirmDeleteSelected:false,
      showForm:false,
      formData: {},
      data: []
    }
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='NTPN'
          name='ntpn__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
      </this.SearchContainer>
    )
  }

  editDialog = false;
  _barActions = [];

  editItem = (item) => {
    if(this.editDialog) {
      this.showFormDialog(item);
    } else {
      if(this.rootPath) {
        this.props.history.push(this.rootPath+"/"+item.id)
      } else {
        this.props.history.push('/'+this.service.name+"/"+item.id);
      }
    }
  }

  defaultColumns = [
    /*{isSortable:true, label: "word.id",  value: "id", show:false, isSearchable:true, isDefaultSort: false},*/
    {isSortable:true, label: "word.createdUsername",  value: "createdUsername", show:true, isSearchable:true},
    {isSortable:true, label: "word.createdBy",  value: "createdBy", show:true, isSearchable:true},
    {isSortable:true, label: "word.createdDate", value: "createdDate", show:true, isSearchable:true, type:"date", isDefaultSort: true},
    {isSortable:true, label: "word.modifiedUsername",  value: "modifiedUsername", show:true, isSearchable:true},
    {isSortable:true, label: "word.lastModifiedBy",  value: "lastModifiedBy", show:true, isSearchable:true},
    {isSortable:true, label: "word.lastModifiedDate", value: (d)=> {
        if(d.lastModifiedDate) {
          return <div className='mpk-font-size-S'>{moment(d.lastModifiedDate).format('lll')}</div>
        } else {
          return null;
        }
      }, show:true, isSearchable:true, type:"func", searchField: 'lastModifiedDate', isDefaultSort: true}
  ]

  columns=[
    {isSortable: true, label: "word.kjs", searchField: "kjs", value: function(d){
      try {
        return d.kjs.code
      } catch(e){
        return '-'
      }
    }, type: "func", show:true, isSearchable:true},
    {isSortable: true, label: "word.date",  value: "date", show:true, isSearchable:true},
    {isSortable: true, label: "word.ntpn",  value: "ntpn", show:true, isSearchable:true},
    {isSortable: true, label: "word.total", searchField: "total", value: function(d){ return ColumnService.money(d.total) }, type: "func", show:true, isSearchable:true},
    {isSortable: true, label: "word.createdDate",  value:"createdDate", type:"date", show:true, isSearchable:true},
    {isSortable: true, label: "word.createdBy",  value:"createdBy", show:true, isSearchable:true},
  ]

  _tableActions = []

}
