import ApiService from '../../../../../services/api.service';

class Master21PtkpService extends ApiService {
  name= 'Master21Ptkp';
  path= 'rates/ptkp';

  constructor() {
    super();
    this.init()
    this.initApi()
  }

  api= {
    getData: async (npwp) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${npwp}/company`)
      return res;
    }
  }
}


export default new Master21PtkpService();
