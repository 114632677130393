import ApiService from '../../../../../services/api.service';

class SPT15Service extends ApiService {
  name= 'SPT_15';
  // EPPT CORE
  cloud=true
  path= 'pasal15/spt';
  // EPPT CORE
  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api = {
    save: async(data)=> {
      var res = await this.http.post(`${this.apiLocation}/${this.path}`, data, {
        params: {
          blockBunifikasiMonth: data.blockBunifikasiMonth,
          blockBunifikasiYear: data.blockBunifikasiYear
        }
      })
      return res;
    },
    pembetulan: async(id, dispatch=()=>{})=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${id}/rev`)

      return res;
    },
    pembetulanBulk: async(ids, dispatch=()=>{})=> {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/pembetulan`, ids)

      return res;
    },

    downloadLapor: async(id, dispatch=()=>{})=> {
      var res = await this.http.get(`${this.apiLocation}/pasal15/master/files/download/${id}`,{
        responseType: 'blob'
      })

      return res;
    },

    laporAll: async(data) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/lapor`, data)

      return res;
    },

    laporAllWithSummary: async(data) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/generate/summary`, data)

      return res;
    },

    laporAllWithSummaryParam: async(data) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/generate/summary/param`, {params: data})

      return res;
    },

    pengajuanApprovalBulk: async(data, dispatch=()=>{})=> {
      var res = await this.http.post(`${this.apiLocation}/pasal15/mainFlow/pengajuan/bulk`, data)

      return res;
    },

    getSummary: async(sptId)=> {
      return await this.http.get(`${this.apiLocation}/pasal15/spt/${sptId}/debt`)
    },

    lapor: async (sptId)=> {
      return await this.http.get(`${this.apiLocation}/pasal15/spt/${sptId}/submit`)
    },

    exportCsvSelected: async(ids, dispatch=()=>{})=> {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/export/csv`, ids)

      return res;
    },

    exportPdfSelected: async(ids, dispatch=()=>{})=> {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/export/pdf`, ids)

      return res;
    },

    exportPdfMergeSelected: async(ids, dispatch=()=>{})=> {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/export/pdf/merge`, ids)

      return res;
    },

    summaryDaftarSpt: async (params, dispatch=()=>{})=> {
      if(params) {
        for (let key in params) {
          let value = params[key]
          let fixedKey = key.replace('__', '.')

          params[fixedKey] = value

          delete params[key]
        }
      }

      var res = await this.http.get(`${this.apiLocation}/${this.path}/export/excel/daftar-spt`, {
        params: params,
        responseType: 'blob'
      })

      return res;
    },
    cetakBulkLog: async (data) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/report/log`, data)
      return res;
    },
  }
}


export default new SPT15Service();
