import ApiService from '../../../../services/api.service';

class DataESSPDuplicateService extends ApiService {
  name= 'DataESSPDuplicate';
  path= 'dataESSPDuplicate';

  apiMockup= {
    find: [{
      id: 1,
      status: 'Process',
      npwp: '023945840000',
      name: 'PT. A',
      pasal: '21/26',
      year: '2018',
      month: 'Januari',
      pembetulan: '0'
    }],
    findOne: {
      id: 1,
      status: 'Process',
      npwp: '023945840000',
      name: 'PT. A',
      pasal: '21/26',
      year: '2018',
      month: 'Januari',
      pembetulan: '0'
    }
  }

  constructor() {
    super()
    this.init()
    this.initApi()
  }
}


export default new DataESSPDuplicateService();
