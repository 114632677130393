import React from 'react';
import {connect} from 'react-redux';
import {Field, getFormValues, reduxForm} from 'redux-form';
import {Avatar, Card, DataTable, Divider, FontIcon, List, ListItem, TableBody, TableColumn, TableRow} from 'react-md';

import FormView from '../../../../../components/entity/form.view';
import NomorBPService from '../nomorBP.service';
import {convert, Radio, Switch, Textfield, TextfieldMask, validation} from '../../../../../components/form';
import izitoast from 'izitoast'

@reduxForm({form: 'SettingNoBupot21.Form', destroyOnUnmount: true})
@connect((state) => ({
  access: state.entity.access.api.find,
  organization: state.authEppt.currentOrganization,
  spt: state.authEppt.currentSPT,
  formData: getFormValues('SettingNoBupot21.Form')(state)
}))
export default class SettingNoBupot21Form extends FormView {
  service = NomorBPService
  viewType = 2;

  titleHeader() {
    return `entities.SettingNoBupot21.title`;
  }

  async initData() {
    let sptId = this.props.match.params.sptId
    let res = await NomorBPService.api.get(sptId)
    console.log(res, "TELL ME")
    this.props.initialize(res.data);
  }

  async handleSave(value) {
    try {
      this.setState({onProgress: true})

      let organization = this.props.spt.data.organization
      value.organization = organization

      if (value.id) {
        await this.service.api.update(value)
      } else {
        await this.service.api.save(value)
      }

      this.setState({onProgress: false})

      izitoast.success({title: 'Success', message: 'Penomoran berhasil disimpan'})
    } catch (e) {
      let msg = e.message;

      if (e.response) msg = e.response.data.message;

      this.setState({
        isError: true,
        onProgress: false,
        errorMessage: msg
      })
    }
  }

  formView() {
    return (
      <div>
        <Card>
          <div className='md-grid'>
            <div className="md-cell md-cell--6">
              <Field
                label='Tidak Final'
                name='tf'
                component={Textfield}
                validate={validation.required}
              />
            </div>

            <div className="md-cell md-cell--6">
              <Field
                label='Final'
                name='f'
                component={Textfield}
                validate={validation.required}
              />
            </div>

            <div className="md-cell md-cell--6">
              <Field
                label='A1'
                name='a1'
                component={Textfield}
                validate={validation.required}
              />
            </div>

            <div className="md-cell md-cell--6">
              <Field
                label='A2'
                name='a2'
                component={Textfield}
                validate={validation.required}
              />
            </div>
          </div>
        </Card>
        <br/>
      </div>
    )
  }

}
