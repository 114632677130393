import React, { Component } from 'react';
import { connect } from 'react-redux';

import FormDialog from './Permissions.dialog.view';
import PermissionsService from './Permissions.service';
import ListView from '../../../../components/entity/listView';
import ColumnService from '../../../../services/column.service';

@connect(PermissionsService.stateConnectSetting(), PermissionsService.actionConnectSetting())
export default class PermissionsView extends ListView {
  service=PermissionsService
  FormDialog=FormDialog

  beforeFetch(params) {
    params.sortBy = null
  }

  defaultColumns = [
    {isSortable:true, label: "word.id",  value: "id", show:false, isSearchable:true, isDefaultSort: false},
  ]

  columns=[
    {label: "word.id",  value: function(d){ return d.id }, type: "func", isDefaultSort:true, show:false, isSearchable:true},
    {label: "word.name",  value: function(d){ return d.name }, type: "func", isDefaultSort:true, show:true, isSearchable:true},
    {label: "word.description",  value: function(d){ return d.description }, type: "func", isDefaultSort:true, show:true, isSearchable:true},
  ]   
}
