import ApiService from '../../../../../services/api.service';
import http from "../../../../../services/http.service";

class SSP23Service extends ApiService {
  name= 'SSP23';
  path= 'pasal23/ssp';
  cloud= true;
  
  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    save: async(data)=> {
      var res = await this.http.post(`${this.apiLocation}/${this.path}`, data, {
        params: {
          blockBunifikasiMonth: data.blockBunifikasiMonth,
          blockBunifikasiYear: data.blockBunifikasiYear
        }
      })
      return res;
    },
    update: async(data)=> {
      var res = await this.http.put(`${this.apiLocation}/${this.path}`, data, {
        params: {
          blockBunifikasiMonth: data.blockBunifikasiMonth,
          blockBunifikasiYear: data.blockBunifikasiYear
        }
      })
      return res;
    },
    getTotal: async(sptId)=> {
      return await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/total`)
    },
    getSptInfo: async(sptId)=> {
      return await this.http.get(`${this.apiLocation}/pasal23/spt/${sptId}/info`)
    },
    getSummary: async(sptId)=> {
      return await this.http.get(`${this.apiLocation}/${this.path}/${sptId}`)
    },
    cetak: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/pasal23/spt23/ssp-23/report/${sptId}`, {
        responseType: 'blob'
      })

      return res;
    },
    downloadCsv: async (sptId)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/spt/export`)
      return res;
    },
    deleteBulk: async (sptId, ids)=> {
      let res = await http.post(`${this.apiLocation}/${this.path}/${sptId}/delete`, ids);

      return res;
    },
  }
}


export default new SSP23Service();
