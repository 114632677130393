import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Field, reduxForm } from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../../../../components/form';

import ListView from '../../../../../../components/entity/listView';
import ColumnService from '../../../../../../services/column.service';
// import ImportService from './../Import/Import.service';
import axios from 'axios';
import izitoast from 'izitoast';
import ImportSigner4a2Service from './ImportSigner4a2.service';
import ImportSigner4a2DialogView from './ImportSigner4a2.dialog';
import download from "downloadjs";
import ListViewImport from '../../../../../../components/entity/ListViewImport';



@reduxForm({form: 'ImportSigner4a2Form', destroyOnUnmount: true})
@connect((state)=> ({
  ...ImportSigner4a2Service.stateConnectSetting()(state),
  user: state.authEppt.user,
  company: state.authEppt.currentCompany
}), ImportSigner4a2Service.actionConnectSetting())
export default class ImportSigner4a2View extends ListViewImport {
  service=ImportSigner4a2Service
  FormDialog=ImportSigner4a2DialogView
  pasal='pasal4'
  category='SIGNER_4A2'
  importPath='/pasal4/upload/csv/etc'
  importPathFunc() {
    return `/pasal4/api/import/company/${this.props.company.id}`;
  }
  showAutomatisTab=false
  // viewType=2

  beforeFetch(params) {
    params["category.equals"] = "Signer"
  }

  async handleDownloadTemplate() {
    var report = await ImportSigner4a2Service.api.downloadTemplate()
    download(report.data, 'Signer4a2.csv');
  }

}
