import React, { Component } from 'react';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import { validation, Textfield, TextfieldMask, Datepicker, convert } from '../../../../../../components/form';
import ImportDialogTemplateView from '../../../../../../components/entity/Import.dialog.template.view';
import ImportSigner23Service from './ImportSigner23.service';
ImportDialogTemplateView


@reduxForm({form: 'ImportSigner23-form', destroyOnUnmount: false})
export default class ImportSigner23DialogView extends ImportDialogTemplateView {
  service=ImportSigner23Service
}
