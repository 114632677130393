import _ from 'lodash';

export default class ParamService {

  static convert(values) {
    let param = {}

    let inData = {}

    _.forOwn(values, function(value, key) {
      let n = key.split('__');

      if(n.length === 2) {
        if(n[1].search('in') >= 0 && n[1] !== 'contains') {
          if(value) {
            if(!inData[n[0]]) {
              inData[n[0]] = '';
            }

            inData[n[0]] += n[1].split('_')[1] + ", ";
          }
        } else {
          param[n[0]+'.'+n[1]] = value;
        }
      } else {
        param[n] = value;
      }
    });

    Object.keys(inData).forEach((v, i)=> {
      param[v+'.in'] = inData[v];
    })

    return param;
  }

}
