import ApiService from '../../../../../../../services/api.service'
import http from '../../../../../../../services/http.service'
import moment from 'moment'
import React from 'react'
import { Chip } from 'react-md'

class BP26Service extends ApiService {
  name= 'BP26';
  path= 'ebupot/bp26';
  rootPath=true
  advancedSearch=true

  columns=[
    {label: "word.id", searchField: "id", value: "id", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
    {label: "word.noBp", searchField: "no", value: "no", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.rev", searchField: "rev", value: "rev", type: 'number', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.tin", searchField: "bagA.tin", value: "bagA.tin", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {
      searchField: "status",
      label: "word.statusSpt",
      type: 'func',
      value: (d)=> {
        var style = {
          background: 'grey'
        }
        switch (d.status) {
          case 'CREATED':
            style.background = 'grey'
            break;
          case 'SET':
            style.background = '#2196F3'
            break;
          case 'PROCESS':
            style.background = '#D32F2F'
            break;
          case 'FAILED':
            style.background = '#FFEB3B'
            break;
          case 'FINISH':
            style.background = '#4CAF50'
            break;
          case 'DELETED':
            style.background = 'red'
            break;
          default:
            break;
        }
        return <Chip label={d.status} style={style} />
      },
      className: "mpk-font-size-S", show:true, isSearchable:true},
    {label: "word.message", searchField: "message", value: "message", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.nama", searchField: "bagA.nama", value: "bagA.nama", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.email", searchField: "bagA.email", value: "bagA.email", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.bruto", searchField: "bagB.bruto", type: "number", value: "bagB.bruto", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.pph", searchField: "bagB.pph", type: "number", value: "bagB.pph", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.nSkdDtp", searchField: "nSkbDtp23", type: "func", value: (d)=> {
      var nomor = ""
      if(d.no) nomor + d.nomor
      if(d.noSkd) nomor + "/" + d.noSkd
      if(d.noDtp) nomor + "/" + d.noDtp
      return nomor
    }, isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.refXmlId", searchField: "refXmlId", value: "refXmlId", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.createdBy", searchField: "createdBy", value: "createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.createdDate", searchField: "createdDate", value: (d)=> {
      if(d){
        return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
      } else {
        return "-"
      }
    }, type: 'func', isDefaultSort:true, isSortable:true, show:true, isSearchable:true},
    {label: "word.lastModifiedBy", searchField: "lastModifiedBy", value: "lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.lastModifiedDate", searchField: "lastModifiedDate", value: (d)=> {
      if(d.lastModifiedDate){
        return moment(d.lastModifiedDate).format('DD/MM/YYYY HH:MM')
      } else {
        return "-"
      }
    }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
  ]

  api= {
    summary: async (esptId) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${esptId}/spt/summary`)

      return res.data;
    },
    calculate: async (param) => {
      var res = await this.http.post(this.apiLocation+'/calculation/pph23/f', param)

      return res.data;
    },
    total: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/total/${sptId}`)

      return res.data;
    },
    cetak: async (id) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/report/${id}`, {
        responseType: 'blob'
      })

      return res;
    },
    cetakBulk: async (sptId, ids) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/${sptId}/report/bulk`, ids, {
        responseType: 'blob'
      })

      return res;
    },
    cetakBulkMerge: async (ids) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/report/bulk/merge`, ids, {
        responseType: 'blob'
      })

      return res;
    },
    cetakDaftar: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/spt23/bp-23/daftar/report/${sptId}`, {
        responseType: 'blob'
      })

      return res;
    },
    downloadCsv: async (sptId)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/csv/${sptId}`, {
        responseType: 'blob'
      })

      return res;
    },
    email: async (category, ids) => {
      return await this.http.post(`${this.apiLocation}/email?category=${category}`, ids)
    },
    emailSpt: async (category, sptId, ids) => {
      return await this.http.post(`${this.apiLocation}/email?category=${category}&sptId=${sptId}`, ids)
    },
    deleteBulk: async (sptId, ids)=> {
      let res = await http.post(`${this.apiLocation}/${this.path}/${sptId}/delete`, ids);

      return res;
    },
  }

  constructor() {
    super()
    this.init()
    this.initApi()
  }
}


export default new BP26Service();
