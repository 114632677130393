import http from '../../../../services/http.service';

export default {
  api: {
    async get(sptId) {
      return await http.get(`/pasal15/no/${sptId}/spt`);
    },
    async save(data) {
      return await http.post('/pasal15/spt/no', data);
    },
    async update(data) {
      return await http.put('/pasal15/no', data);
    },
  }
}


