import React from 'react'
import _ from 'lodash';
import {
  Grid,
  Cell,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  Button,
  LinearProgress,
  List,
  ListItem,
  TablePagination
} from 'react-md';
import DialogView from '../../../../components/entity/dialog.view';
import websocketService from '../../../../services/websocket.service';
import ImportEbilling from '../../Ebilling/ImportEbilling/ImportEbilling.service';

export default class ImportDialogTemplateView extends DialogView {
  service=ImportEbilling
  initialData = {
  }

  formStyle={
    height: '100%',
    overflow: 'hidden'
  }

  submitLabel="Close"

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      data: [],
      pageNumber: 1,
      size: 30,
      total: 0,
      page: {},
      progress: {}
    }
  }

  // tambahan websocket
  async initData(props) {
    if(props.formData.id) {
      this.props.initialize(props.formData);

      await this.fetchData(props);

      //subcribe
      this.setState({progress: props.formData})
      this.subcribe(props.formData.fileId);
    } else {
      this.props.initialize(this.initialData);

      //validasi
      this.setState({ data: [] })
    }
  }

  async fetchData(props) {
    var params = {
      page: this.state.pageNumber,
      size: this.state.size,
      sort: 'line',
      fileId: props.formData.fileId
    }

    var res = await this.service.api.find(params)

    res.result = _.sortBy(res.result, ['line'])

    this.setState({ data: res.data, total: parseInt(res.headers['x-total-count']) })
  }

  subcribe(topic) {
    if(this.state.progress.status !== 'FINISH') {
      websocketService.subscribe(topic, (d)=> {
        this.setState({progress: JSON.parse(d.body)})
      })
    }
  }
  // end tambahan websocket

  handlePagination = async (start, size, pageNumber) => {
    await this.setState({size});
    await this.setState({pageNumber});
    await this.fetchData(this.props);
  }

  handleClose() {
    websocketService.unsubscribe();
    this.props.onClose()
  }

  handleSave() {
    websocketService.unsubscribe();
    this.props.onClose()
  }

  _addons(){
    var x = document.getElementsByClassName('md-dialog-footer');
    if(x){
      if(x[0]){
        if(x[0].children){
          if(x[0].children.length == 2){
            x[0].children[0].remove()
          }
        }
      }
    }
  }

  async handelLoadMore(){
    var fileId = this.props.formData.fileId
    var page = (this.state.page.number + 1)
    var size =  page * this.state.page.size
    var total = this.state.page.totalPages * this.state.page.size

    var params = {
      page,
      size,
      sort: 'line',
      fileId: fileId
    }

    if(total != size){
      var res = await this.service.api.find(params)
      var curretData = this.state.data
      var merge = curretData.concat(res.result)

      this.setState({ data: merge, page: res.page })
    }
  }

  formView() {
    this._addons()

    return (
      <div className='mpk-layout column' style={{padding:'none', height: '100%'}}>
        {/* tambahan websocket */}
        {/* <br/> */}
        <div className='mpk-progress'>
          <div className='mpk-layout'>
            <div className='md-font-light'>File ID: <b>{this.state.progress.fileId}</b></div>
            <div className='flex'/>
            <div className='md-font-light'>Status: <b>{this.state.progress.status}</b></div>
          </div>
          <LinearProgress style={{position: 'relative'}} query={true} value={Math.round(this.state.progress.count/this.state.progress.total*100)} />
          <div className='mpk-layout'>
            <div className='md-font-light'>Step <b>{this.state.progress.step}</b>/2 - <b>{this.state.progress.count}</b>/{this.state.progress.total}</div>
            <div className='flex'/>
            <div className='md-font-light'><b>{Math.round(this.state.progress.count/this.state.progress.total*100)}%</b></div>
          </div>
        </div>
        {/* end of tambahan websocket */}

        <br/>

        <DataTable plain
                   fixedHeader
                   fixedFooter
                   fixedHeight={400}
        >
          <TableHeader>
            <TableRow>
              <TableColumn>Line</TableColumn>
              <TableColumn>Messages</TableColumn>
            </TableRow>
          </TableHeader>

          <TableBody>
            {this.state.data.map(function (i, index){
              return (
                <TableRow key={index}>
                  <TableColumn>{i.line}</TableColumn>

                  <TableColumn style={{'vertical-align': 'middle'}}>
                    {
                      i.messages.map(function (i2, index2) {
                        return <p key={index2}>{i2.message}</p>
                      })
                    }
                  </TableColumn>
                </TableRow>
              )
            })}
          </TableBody>

          <TablePagination
            rowsPerPageLabel={!true ? 'Rows' : 'Rows per page'}
            page={this.state.pageNumber}
            rows={this.state.total}
            rowsPerPage={this.state.size}
            onPagination={this.handlePagination}
          />
        </DataTable>
      </div>
    )
  }
}
