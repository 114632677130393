import React, { Component } from 'react';
import Bluebird from 'bluebird';
import axios from 'axios';
import izitoast from 'izitoast';
import download from 'downloadjs';

import ListView from './listView';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../form';
import { Field, reduxForm } from 'redux-form';

import ImportDialogResult from './import.dialog.result';
import ValidationCsvService from '../../modules/Main/Import/ValidationCsv.service';
import OrganizationService from "../../modules/Main/Administrator/Organization/Organization.service";
import BlokBunifikasiService from './../../modules/Main/pph/21_26/BlokBunifikasi/BlokBunifikasi.service'

export default class ListViewImport extends ListView {
  pasal=''
  category='BP_21_TF'
  importPath='/api/import/spt/21'
  importPathFunc() {
    return this.importPath;
  }
  showAutomatisTab=false
  showPenomoranAuto=false
  showFormatEspt=false
  showPembetulan=false

  ImportDialogResult=ImportDialogResult
  LinearProgress=LinearProgress

  constructor(props){
    super(props);
    this.state = {
      showTableSetting:true,
      showDialogConfirmDeleteSelected:false,
      showForm:false,
      formData: {},
      data: [],
      onProgress: false,
      isError: false,
      errorMessage: '',

      showImportResult: false,
      importResults: []
    }
  }

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => {
        this.editItem(item)
      }
    }
  ]

  tableActions = (d) => {
    var items = [{
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    }]
    // if(d.status === 'FAILED') {
    //   items.push({
    //     label:"Download CSV",
    //     iconClassName:"mdi mdi-bell",
    //     onClick: (item) => this.downloadCsv(item)
    //   })
    // }

    if(d.status === 'FAILED' || d.status === 'FINISH' || d.status === 'COMPLETED') {
      items.push({
        label:"Delete",
        iconClassName:"mdi mdi-delete",
        onClick: (item) => this.handleDeleteImport(item)
      })
    }

    return items;
  }

  async handleDeleteImport(d) {
    try {
      var dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Delete',
        initialValue: {},
        width: 400,
        text: 'Apakah anda yakin akan menghapus data ini ?',
      }));
      if(dialog) {
        if(this.pasal == "pasal22"){
          await axios.delete(`${this.pasal}/log/csv/${d.id}`);
        } else {
          await axios.delete(`${this.pasal}/upload/csv/${d.id}`);
        }
        izitoast.success({
          message: 'Data berhasil dihapus'
        })
        this.fetchData()
      }
    } catch(e){
      console.log(e)
    }
  }

  async handleDeleteImportSelected() {
    var dialog = await this.context.showDialog((props, res, rej) =>({
      title: 'Delete',
      initialValue: {},
      height: 'auto',
      width: 400,
      okText: 'Ya',
      text: 'Apakah anda yakin akan menghapus data yang terpilih?'
    }));

    if(dialog) {

      var ids = this.props.table.selected.reduce((p, c)=> {
        if(c.status == 'FINISH' || c.status == 'FAILED') {
          p.push(c.id)
        }
        return p;
      }, []);

      await Bluebird.mapSeries(ids, async (d, i)=> {
        if(this.pasal == "pasal22"){
          await axios.delete(`${this.pasal}/log/csv/${d}`);
        } else {
          await axios.delete(`${this.pasal}/upload/csv/${d}`);
        }
      })

      izitoast.success({
        title: "",
        message: "Data berhasil dihapus"
      })
      this.fetchData()

    }
  }

  async downloadCsv(d) {
    var file = await ValidationCsvService.api.downloadCsv(d.upload.id);

    var filename = file.headers.filename
    download(file.data, filename);
  }

  _barActions = [
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => this.fetchData()
    },{
      label:'word.delete',
      iconClassName:'mdi mdi-delete',
      onClick: async () => {
        await this.handleDeleteImportSelected()
      },
      disabledFunc:() => this.props.table.selected.length === 0
    },
  ]

  _barItem() {
    return (
      <div>
        <Button primary raised style={{margin: 5}} onClick={this.handleDownloadTemplate.bind(this)}>Template</Button>
        <Switch
          style={{float: "right"}}
          id="switch-table-setting"
          name="Switch Table Setting"
          label=""
          checked={this.state.showTableSetting}
          onChange={() => {
            this.setState({
              showTableSetting:!this.state.showTableSetting
            })
          }}
        />
      </div>
    )
  }

  columns=[
    {isSortable: true, label: "word.id",  value: "id", isDefaultSort:false, show:false, isSearchable:true},
    {isSortable: true, label: "word.category",  value: "category", show:true, isSearchable:true},
    {isSortable: true, label: "word.filename",  value: "originalFilename", show:true, isSearchable:true, searchField: 'filename'},
    // {isSortable: true, label: "word.startDate",  value: "startDate",  type: "date", show:true, isSearchable:true},
    // {isSortable: true, label: "word.endDate",  value: "endDate", type: "date", isDefaultSort:false, show:true, isSearchable:true},
    {isSortable: true, label: "word.status", searchField: 'status', type:'func',  value: (d)=> {
      if(d.status === 'SUCCESS') {
        return <Button flat swapTheming primary style={{backgroundColor: '#4CAF50'}}>SUCCESS</Button>
      } else if(d.status === 'FAILED') {
        return <Button tooltipLabel="Click to download csv" onClick={()=> this.downloadCsv(d)} flat swapTheming primary style={{backgroundColor: '#F44336'}}>FAILED</Button>
      } else {
        return <Button flat>{d.status}</Button>
      }
    }, isDefaultSort:false, show:true, isSearchable:false},
    // {isSortable: true, label: "word.message",  value: "message", show:true, isSearchable:true},
    // {isSortable: true, label: "word.step",  value: "step", isDefaultSort:false, show:true, isSearchable:true, type: "number"},
    // {isSortable: true, label: "word.count",  value: "count", isDefaultSort:false, show:true, isSearchable:true, type: "number"},
    {isSortable: true, label: "word.total",  value: "total", isDefaultSort:false, show:true, isSearchable:true, type: "number"},
    {isSortable: true, label: "word.message",  value: "message", isDefaultSort:false, show:true, isSearchable:false},
  ]

  async checkCategory() {
    return true
  }

  async beforeSave(value){
    return true
  }

  async handleSave(value) {
    var insideBP = true
    try {
      if(this.props.match.url.indexOf('open') != -1){
        insideBP = false
      }
    } catch(e){}

    // if (value.formatEspt && insideBP) {
    //   if (!value.npwp) {
    //     izitoast.warning({title: 'Import ESPT', message: 'Anda harus memilih organization ketika impor format ESPT.'})

    //     return
    //   }
    // }

    var beforeSave = await this.beforeSave(value)
    if(typeof beforeSave == 'object'){
      value = beforeSave
    }

    let isCheckCategory = await this.checkCategory()

    if (isCheckCategory) {
      var _this = this

      if(this.importPath) {
        try {
          this.setState({onProgress: true, showImportResult: true, importResults: []})
          await Bluebird.mapSeries(value.file, async (d, i)=> {
            let formData = new FormData();

            if(value.category == value.type){
              formData.append("category", value.category)
              formData.append("type", value.type)
            } else {
              formData.append("type", value.type)
              if(this.category === 'BP_4A2' || this.category === 'BP_22' || this.category === 'LK_21') {
                formData.append("category", value.category)
              } else {
                formData.append("category", this.category)
              }
            }

            formData.append("file", d)
            formData.append("numbering", value.no)

            if (value.migration) {
              formData.append("migration", value.migration)
            }

            if (value.replace) {
              formData.append("replace", value.replace)
            }

            // formData.append("formatEspt", value.formatEspt)
            // formData.append("pembetulan", value.pembetulan)
            formData.append("npwp", value.npwp)
            
            setTimeout(async ()=> {
              var blokBuni = {
                blockBunifikasiMonth: 4,
                blockBunifikasiYear: 2022,
              }
              try {
                var companyId = this.props.match.params.companyId
                var getRes = await BlokBunifikasiService.api.getSettingAccess(companyId)
                if(getRes.data.active){
                  blokBuni = {
                    blockBunifikasiMonth: getRes.data.bulan,
                    blockBunifikasiYear: getRes.data.tahun
                  }
                }
              } catch(e){
              }
              return axios.post(this.importPathFunc(), formData, {
                params: blokBuni
              }).then(function (res){
                _this.setState(function(state,props) {
                  state.importResults.push(res.data)
                  return {
                    importResults: state.importResults
                  }
                })
                // _this.fetchData()
              }).catch(function (e){
                // _this.fetchData()
                var msg = e.message;
                if(e.response) msg = e.response.data.message;
                _this.setState({
                  isError: true,
                  onProgress: false,
                  errorMessage: msg
                })
  
                _this.setState(function(state,props) {
                  state.importResults.push({
                    upload: {
                      originalFilename: d.filename,
                    },
                    error: true,
                    message: msg
                  })
                  return {
                    importResults: state.importResults
                  }
                })
              })
            }, 10000 * i)

          })

          // _this.fetchData()
          _this.setState({onProgress: false})
          izitoast.info({title: 'Import File', message: 'sedang diproses cek log untuk lebih detil'})
        } catch(e) {
          // _this.fetchData()
          var msg = e.message;
          if (e.response) msg = e.response.data.message;
          _this.setState({
            isError: true,
            onProgress: false,
            errorMessage: msg
          })
        }
      }
    } else {
      // bukan format espt
      if (!value.formatEspt) {
        izitoast.warning({title: 'Kategori', message: 'Silahkan pilih kategori terlebih dahulu.'})
      }
      // format espt
      else {
        let category = this.category

        // bp 4 (2)
        if (category === 'BP_4A2')  {
          var _this = this

          if(this.importPath) {
            try {
              this.setState({onProgress: true, showImportResult: true, importResults: []})
              await Bluebird.mapSeries(value.file, async (d, i)=> {
                let formData = new FormData();
                formData.append("type", value.type)
                if(this.category === 'BP_4A2' || this.category === 'BP_22' || this.category === 'LK_21') {
                  formData.append("category", value.category)
                } else {
                  formData.append("category", this.category)
                }

                formData.append("file", d)
                formData.append("no", value.no)

                if (value.migration) {
                  formData.append("migration", value.migration)
                }

                if (value.replace) {
                  formData.append("replace", value.replace)
                }

                formData.append("formatEspt", value.formatEspt)
                formData.append("pembetulan", value.pembetulan)
                formData.append("npwp", value.npwp)
                var blokBuni = {
                  blockBunifikasiMonth: 4,
                  blockBunifikasiYear: 2022,
                }
                try {
                  var companyId = this.props.match.params.companyId
                  var getRes = await BlokBunifikasiService.api.getSettingAccess(companyId)
                  if(getRes.data.active){
                    blokBuni = {
                      blockBunifikasiMonth: getRes.data.bulan,
                      blockBunifikasiYear: getRes.data.tahun
                    }
                  }
                } catch(e){
                  console.log(e, "ERROR GET SETTING BLOK BUNI")
                }
                return axios.post(this.importPathFunc(), formData, {
                  params: blokBuni
                }).then(function (res){
                  _this.setState(function(state,props) {
                    state.importResults.push(res.data)
                    return {
                      importResults: state.importResults
                    }
                  })
                  // _this.fetchData()
                }).catch(function (e){
                  // _this.fetchData()
                  var msg = e.message;
                  if(e.response) msg = e.response.data.message;
                  _this.setState({
                    isError: true,
                    onProgress: false,
                    errorMessage: msg
                  })

                  _this.setState(function(state,props) {
                    state.importResults.push({
                      upload: {
                        originalFilename: d.filename,
                      },
                      error: true,
                      message: msg
                    })
                    return {
                      importResults: state.importResults
                    }
                  })
                })
              })

              _this.fetchData()
              _this.setState({onProgress: false})
              izitoast.info({title: 'Import File', message: 'sedang diproses cek log untuk lebih detil'})
            } catch(e) {
              _this.fetchData()
              var msg = e.message;
              if (e.response) msg = e.response.data.message;
              _this.setState({
                isError: true,
                onProgress: false,
                errorMessage: msg
              })
            }
          }
        }
        // bukan bp 4 (2)
        else {
          izitoast.warning({title: 'Kategori', message: 'Silahkan pilih kategori terlebih dahulu.'})
        }
      }
    }
  }

  async handleDownloadTemplate() {

  }

  componentDidMount(){
    try {
      let path = this.props.match.path.split("/")
      let params = path[2];
      this.props.change("category", params)
      this.props.change("type", "M")
      this.props.change("no", false)
      this.props.change("migration", false)
      this.props.change("replace", false)
      this.props.change("formatEspt", true)
      this.props.change("pembetulan", false)
      this.props.change("npwp", null)
    } catch(e){}
  }

  handleTabChange(newActiveTabIndex){
    if(newActiveTabIndex == 0){
      this.props.change("type", "M")
    } else {
      this.props.change("type", "A")
    }
  }

  // yg ditambah
  tabWidth = 280
  tabs= ["import", "search", "column"]

  tabComponents() {
    var obj = this;
    return {
      import: this.import.bind(obj)
    }
  }
   // end yg ditambah

  import(){
    const { handleSubmit, submitting, valid, pristine } = this.props;

    let formatEspt = false;

    if (this.props.formData) {
      formatEspt = this.props.formData.formatEspt
    }

    var org = []
    if(this.props.auth && this.props.auth.organizations) org = this.props.auth.organizations
    var insideBP = true
    try {
      if(this.props.match.url.indexOf('open') != -1){
        insideBP = false
      }
    } catch(e){}

    return (
        <div className='mpk-layout column fill'>

          <ImportDialogResult dataResults={this.state.importResults} visible={this.state.showImportResult} onHide={()=> {this.setState({showImportResult: false})}} />

          <div className='flex'>
            <div className='md-grid'>
              <div className="md-cell md-cell--12">
                <Field
                  label='Select a File'
                  name='file'
                  multiple
                  twoLines
                  component={Fileinput}
                  validate={validation.required}
                />
              </div>

              {this.showPenomoranAuto &&
                <div className="md-cell md-cell--12">
                  <Field
                    label='Aktifkan Penomoran Bukti Potong Otomatis ?'
                    name='no'
                    component={Switch2}
                    // validate={validation.required}
                  />
                </div>
              }

              {/* <div className="md-cell md-cell--12">
                <Field
                  label='Import Migrasi'
                  name='migration'
                  component={Switch2}
                  // validate={validation.required}
                />
              </div>

              {this.showPembetulan &&
                <div className="md-cell md-cell--12">
                  <Field
                    label='Pembetulan'
                    name='pembetulan'
                    component={Switch2}
                    // validate={validation.required}
                  />
                </div>
              }

              {this.showFormatEspt &&
                <div className="md-cell md-cell--12">
                  <Field
                    label='Format ESPT'
                    name='formatEspt'
                    component={Switch2}
                    // validate={validation.required}
                  />
                </div>
              } */}

              {/* {insideBP &&
                <div className="md-cell md-cell--12">
                  <Field
                    label     = 'Organization'
                    name      = 'npwp'
                    id        = "spt2126-organization"
                    className = "md-cell md-cell--12"
                    component = {Searchfield}
                    valueField='npwp'
                    options   = {org}
                    validate  = {validation.required}
                    itemTemplate = {function(d) {
                      let nameAndAlias = ""
                      if (d.name)
                        nameAndAlias = d.name
                      if (d.aliasName)
                        nameAndAlias += " - " + d.aliasName
                      return {
                        primaryText  : nameAndAlias,
                        secondaryText: d.npwp,
                        onClick      : () => {
                          if(!this.props.disabled){
                            this.handleItemClick(d); this.hide()
                          }
                        }
                      }
                    }}
                  />
                </div>
              } */}

              {/* { formatEspt &&
                <div className="md-cell md-cell--12">
                  <Field
                    label='Organization'
                    name='npwp'
                    valueField='npwp'
                    params={{ size: 50 }}
                    remoteSearch={true}
                    service={OrganizationService}
                    searchField  = {['name', 'aliasName', 'npwp']}
                    component={Searchfield}
                    itemTemplate={function(d) {
                      let nameAndAlias = ""

                      if (d.name)
                        nameAndAlias = d.name

                      if (d.aliasName)
                        nameAndAlias += " - " + d.aliasName

                      return {
                        primaryText  : nameAndAlias,
                        secondaryText: d.npwp,
                        onClick      : () => {
                          if(!this.props.disabled){
                            this.handleItemClick(d); this.hide()
                          }
                        }
                      }
                    }}
                  />
                </div>
              } */}
              <div className="md-cell md-cell--12">
                  <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Import</Button>
              </div>
            </div>
          </div>
          {this.state.onProgress ? (
              <LinearProgress style={{margin: 0}} query={true} />
            ) : (null)}

          {this.showAutomatisTab &&
          <Tabs className='mpk-tab-white' onTabChange={this.handleTabChange.bind(this)} tabId="ImportTab">
            <Tab label="Manual">

            </Tab>
            <Tab label="Otomatis">

            </Tab>
          </Tabs>
          }
        </div>
    )
  }
}
