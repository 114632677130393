import React, { Component } from 'react';
import Bluebird from 'bluebird';
import izitoast from 'izitoast';
import counterpart from 'counterpart';

import { Commandbar , DialogConfirm} from 'react-mpk';
import { CardTitle, Avatar, Button, Card, FontIcon, TabsContainer, Tab, Tabs, ExpansionList, ExpansionPanel, DataTable, TableHeader, TableBody, TableRow, TableColumn, Switch, Checkbox } from 'react-md';
import { Field, reduxForm , propTypes, formValueSelector ,getFormValues} from 'redux-form';
import {validation, Textfield, Searchfield, TextfieldMask, Switch as SwitchField, Datepicker} from '../../../../components/form';
import { connect } from 'react-redux';

import OrganizationService from './Organization.service';
import OrganizationUserService from './User/Organization.user.service';
import OrganizationUserAccessService from './Access/Organization.user.access.service';
import AccessService from '../Access/Access.service';

import FormView from '../../../../components/entity/form.view';
import OrganizationUserView from './User/Organization.user.view';


import _ from 'lodash'
import SPT21SignerService from '../../pph/21_26/SPT21Signer/SPT21Signer.service';
import SPT21SignerView from '../../pph/21_26/SPT21Signer/SPT21Signer.view';
import CompanyService from '../Company/Company.service';
import SPT23SignerView from '../../pph/23_26/Signer/SPT23Signer.view';
import SPT4a2SignerView from '../../pph/4a2/Signer/SPT4a2Signer.view';
import SPT22SignerView from '../../pph/22/Signer/SPT22Signer.view';
import SPT15SignerView from '../../pph/15/Signer/SPT15Signer.view';
import MasterKppService from "../../Master/MasterKpp/MasterKpp.service";


@reduxForm({form: 'organization_form', destroyOnUnmount: false})
@connect((state) => ({
  access: state.entity.access.api.find,
  formData:getFormValues('organization_form')(state)
}))
export default class OrganizationFormView extends FormView {
  service = OrganizationService;
  viewType=2
  initialData = {
    activationKey: Math.random().toString(36).substring(7),
    active: false,
    isPemungut: false,
    signer21: {},
    groups: 'test'
  }

  defaultPermission = {
    1: {id: 1,value: false},
    2: {id: 2,value: false},
    3: {id: 3,value: false},
    4: {id: 4,value: false},
    5: {id: 5,value: false},
    6: {id: 6,value: false},
    7: {id: 7,value: false},
    8: {id: 8,value: false},
    9: {id: 9,value: false}
  }

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      showForm:false,
      showDialogConfirmDeleteSelected: false,
      activeTab: 0,
      signerTab: 0,
      users: [],
      userAccess: {}
    }
  }

  componentDidMount() {
    this.initData()
  }

  // method ------------------------------------------------------------
  async handleUserSave(user, i) {

    var access = Object.keys(user.accessMap).map((objectKey, index) => {
      return user.accessMap[objectKey];
    });

    await Bluebird.map(access, async (e)=> {
      var userAccessPermission = [];
      Object.keys(e.permissions).forEach((objectKey, index) => {
        var permission = e.permissions[objectKey];
        if(permission.value) {
          userAccessPermission.push({
            permission: {
              id: permission.id
            }
          })
        }
      })

      var access = {...e}
      delete access.permissions;
      let res = await OrganizationUserAccessService.api.save({
        access: access,
        organizationUserId: user.id,
        userAccessPermission
      })
    })

    izitoast.success({
      title:  'success',
      message: 'Data has been updated'
    })
  }

  async handleSave(value) {
    try {
      this.setState({onProgress: true})
      value.groups = null
      if(value.id) {
        await this.service.api.update(value)

        // let signer21 = value.signer21;
        // signer21.organization = {
        //   id: value.id
        // }
        // await SPT21SignerService.api.update(signer21);

      } else {
        await this.service.api.save(value)
      }
      this.setState({onProgress: false})

      var pathArray = this.props.location.pathname.split('/');
      var path = '';
      pathArray.forEach((d, i)=> {
        if(pathArray.length-1 != i) {
          path = path+d;
          if(i != pathArray.length-2) path = path+'/'
        }
      })

      this.props.history.push(path)
    } catch(e) {
      var msg = e.message;
      if(e.response) msg = e.response.data.message;
      this.setState({
        isError: true,
        onProgress: false,
        errorMessage: msg
      })
    }
  }

  handleChangeTab(activeTabIndex) {
    this.setState({
      activeTab:activeTabIndex
    })
  }

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;

    var saveWord = counterpart.translate('word.save');
    if(this.props.match.params.id == 'new') saveWord = counterpart.translate('word.create')
    if(this.props.match.params.sptId == 'new') saveWord = counterpart.translate('word.create')

    return (
      <div>
        <Button primary flat onClick={()=> {this.handleUpdateKey()}} style={{marginRight: 16}}>Update Key</Button>

        <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>{counterpart.translate('word.back')}</Button>
      </div>
    )
  }


  commandBar() {
    return (
      <div>
        {this._commandBar()}
        <div className='mpk-commandbar mpk-layout align-center justify-between mpk-border bottom solid dark' style={{height: 48, background: '#f8f8f8'}}>
        <Tabs tabId="organization-tab" inactiveTabClassName="md-text--secondary" onTabChange={this.handleChangeTab.bind(this)} >
          <Tab label="Details" />
          <Tab label="Signer" />
          <Tab label="User" />
        </Tabs>
        </div>

        {this.state.activeTab === 1 &&
          <div>
            <div className='mpk-commandbar mpk-layout align-center justify-between mpk-border top solid dark' style={{height: 48, background: '#f8f8f8'}}>
            <Tabs tabId="signer-tab" inactiveTabClassName="md-text--secondary" onTabChange={this.handleSignerTab.bind(this)} >
              <Tab label="PPH 21" />
              <Tab label="PPH 23/26" />
              <Tab label="PPH 4 (2)" />
              <Tab label="PPH 22" />
              <Tab label="PPH 15" />
            </Tabs>
            </div>
          </div>
        }
      </div>
    )
  }


  formView() {
    return (
      <div className="mpk-layout column fill mpk-tab-clear">
        <div className="flex">
        {this.tabContent()}
        </div>
      </div>
    )
  }

  tabContent() {
    if(this.state.activeTab == 0) {
      return this.detailView()
    } else if(this.state.activeTab == 1) {
      return this.signerView()
    } else {
      return this.manageUserView()
    }
  }

  detailView() {
    const { handleSubmit, submitting, valid, pristine } = this.props;
    return (
      <div className='md-card md-paper md-paper md-paper--1 md-card md-background--card'>
      <div className="md-grid">
        {this.errorView()}
        <Field
          label='Company'
          name='company'
          className="md-cell md-cell--12"
          disabled={true}
          apiPath="page"
          viewField="nama"
          valueField="parent"
          component={Searchfield}
          service={CompanyService}
          validate={validation.required}
        />

        <Field
          label='NPWP'
          name='npwp'
          id="organization-npwp"
          className="md-cell md-cell--6"
          disabled={true}
          component={Textfield}
          validate={validation.required}
        />
        <Field
          label='Name'
          name='name'
          id="organization-npwp"
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
        />

        <Field
          label='Alias Name'
          name='aliasName'
          id="organization-alias-name"
          className="md-cell md-cell--6"
          component={Textfield}
        />
        <Field
          label='City'
          name='city'
          id="organization-npwp"
          className="md-cell md-cell--6"
          validate={validation.required}
          component={Textfield}
        />

        <Field
          label='Postal Code'
          name='postalCode'
          id="organization-npwp"
          className="md-cell md-cell--6"
          validate={validation.required}
          component={Textfield}
        />
        <Field
          label='Email'
          name='email'
          id="organization-npwp"
          className="md-cell md-cell--6"
          validate={validation.required}
          component={Textfield}
        />

        <Field
          label='Phone'
          name='phone'
          id="organization-npwp"
          className="md-cell md-cell--6"
          validate={validation.required}
          component={Textfield}
        />

        <Field
          label='KPP'
          name='kpp'
          className="md-cell md-cell--6"
          valueField="parent"
          component={Searchfield}
          remoteSearch={true}
          service={MasterKppService}
          validate={validation.required}
        />

        <Field
          label='Pemungut'
          name='isPemungut'
          className="md-cell md-cell--6"
          // validate={validation.required}
          component={SwitchField}
        />

        <Field
          label='Active'
          name='active'
          className="md-cell md-cell--6"
          // validate={validation.required}
          component={SwitchField}
        />

        <Field
          label='Address'
          name='address'
          rows={4}
          id="organization-npwp"
          className="md-cell md-cell--12"
          validate={validation.required}
          component={Textfield}
        />



        {/* <Field
          label='Kode Aktivasi'
          name='activationKey'
          rows={4}
          className="md-cell md-cell--12"
          component={Textfield}
          validate={validation.required}
        /> */}

        <Button style={{marginLeft: 10}} raised primary onClick={handleSubmit(this.handleSave.bind(this))}>{counterpart.translate('word.save')}</Button>
      </div>


      </div>
    )
  }

  manageUserView() {
    return (
      <OrganizationUserView ref="organizationUserView" organizationId={this.props.match.params.id} />
    )
  }

  async handleUpdateKey() {
    var dialog = await this.context.showDialog((props, res, rej) =>({
      initialValue: {},
      height: 'auto',
      width: 600,
      contentStyle: {padding:0, overflowY: 'hidden'},
      okText: 'Update',
      text: (
        <div>
          <div className='mpk-commandbar mpk-layout align-center justify-between mpk-border bottom solid dark'>
          Update Key
          </div>

          <div className="md-grid">
            <Field
              label='Kode Aktivasi'
              name='activationKey'
              rows={4}
              className="md-cell md-cell--12"
              component={Textfield}
              validate={validation.required}
            />
          </div>
        </div>
      )
    }));

    if(dialog) {
      await this.service.api.updateKey({
        key: dialog.activationKey,
        npwp: this.props.formData.npwp
      })

      izitoast.success({
        title:  'success',
        message: 'Key telah di update'
      })
    }
  }

  handleSignerTab(activeTabIndex) {
    this.setState({
      signerTab:activeTabIndex
    })
  }

  bottomBar() {
    // if(this.state.activeTab === 1) {
    //   return (

    //   )
    // }
    return null;
  }

  signerView() {
    if(this.state.signerTab == 0) {
      return <SPT21SignerView organizationId={this.props.match.params.id} companyId={this.props.formData.company.id} />
    } else if(this.state.signerTab == 1) {
      return <SPT23SignerView organizationId={this.props.match.params.id} companyId={this.props.formData.company.id} />
    } else if(this.state.signerTab == 2) {
      return <SPT4a2SignerView organizationId={this.props.match.params.id} companyId={this.props.formData.company.id} />
    } else if(this.state.signerTab == 3) {
      return <SPT22SignerView organizationId={this.props.match.params.id} companyId={this.props.formData.company.id} />
    } else {
      return <SPT15SignerView organizationId={this.props.match.params.id} companyId={this.props.formData.company.id} />
    }
  }
}
