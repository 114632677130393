import React, { Component } from 'react';
import {connect} from 'react-redux';
import { Grid, Cell } from 'react-md';
import { Field, reduxForm , propTypes, formValueSelector, getFormValues } from 'redux-form';
import { validation, Textfield, TextfieldMask, Datepicker, convert, Fileinput,  Switch, Searchfield } from '../../../../../components/form';
import DialogView from '../../../../../components/entity/dialog.view';
import SPT23SignerService from './SPT23Signer.service';
import izitoast from 'izitoast';
import axios from 'axios';
import http from './../../../../../services/http.service'
import FileUploadService from './../../../Setting/FileUpload/FileUpload.service';

@reduxForm({form: 'SPT23Signer_dialog', destroyOnUnmount: true})
@connect((state) => ({
  formValue:getFormValues('SPT23Signer_dialog')(state),
  organization: state.auth.company,
  // EPPT CORE
  auth: state.auth.company
  // EPPT CORE
}))
export default class SPT23SignerDialogView extends DialogView {
  service=SPT23SignerService
  initialData = {
    active: false
  }

  transform(val) {
    val.npwpProfile = val.organization.npwp
    return {
      ...val,
      organization: {
        id: this.props.organizationId
      }
    }
  }

  constructor(props){
    super(props)
    this.state = {
      ...this.state,
      file1: null,
      file2: null
    }
  }

  getImageBp(imageBp){
    var _this = this
    http.get('/api/upload/image/'+imageBp, {
      responseType: "blob"
    }).then(function (res){
      var image  = new Image();
      var encode = URL.createObjectURL(res.data);
      _this.setState({ file2: encode })
    })
  }

  getImageInduk(imageInduk){
    var _this = this
    http.get('/api/upload/image/'+imageInduk, {
      responseType: "blob"
    }).then(function (res){
      console.log(res, 'resinduk')
      var image  = new Image();
      var encode = URL.createObjectURL(res.data);
      _this.setState({ file1: encode })
    })
  }

  initData(props) {
    if(props.formData.id) {
      var org = []
      if(this.props.auth && this.props.auth.organizations) org = this.props.auth.organizations
      var getOrg = org.filter((d)=> {
        return d.npwp == props.formData.npwpProfile
      })
      props.formData.organization = getOrg[0]
      this.props.initialize(props.formData);
      var imageBp = null
      var imageInduk = null
      if(props.formData){
        // if(props.formData.imagePenandaTanganBp){
          imageBp = props.formData.imagePenandaTanganBp
          imageInduk = props.formData.imagePenandaTanganSpt
        // }
      }
      // if(imageBp) this.getImageBp(imageBp)
      // if(imageInduk) this.getImageInduk(imageInduk)
    } else {
      this.props.initialize(this.initialData);
    }
    if(this.state.file1 == null){
      var formValue = {}
      if(this.props.formValue) formValue = this.props.formValue
      var imageBp = null
      var imageInduk = null
      if(formValue){
        // if(formValue.imagePenandaTanganBp){
          imageBp = formValue.imagePenandaTanganBp
          imageInduk = formValue.imagePenandaTanganSpt
        // }
      }
      // if(imageBp) this.getImageBp(imageBp)
      // if(imageInduk) this.getImageInduk(imageInduk)
    }
  }

  async handleSave(callback, value) {
    var _this = this
    var newVal = this.transform(value);
    try {

      // // INDUK
      // if(value.file){
      //   let firstImage = new FormData();
      //   firstImage.append("type", "INDUK")
      //   firstImage.append("category", "SIGNER")
      //   firstImage.append("file", value.file)
      //   let bpImage = await http.post(`/api/upload/image`, firstImage).then(function (res){
      //     izitoast.success({title: 'Signer Bukti Potong', message: 'Berhasil diupload'})
      //     return res
      //   }).catch(function (e){
      //     izitoast.error({title: 'Signer Bukti Potong', message: 'Gagal diupload'})
      //   });
      //   if(bpImage){ newVal.imagePenandaTanganSpt = bpImage.data.id }
      //   if(bpImage){
      //     await http.get('/api/upload/image/'+bpImage.data.id, {
      //       responseType: "blob"
      //     }).then(function (res){
      //       var image  = new Image();
      //       var encode = URL.createObjectURL(res.data);
      //       _this.setState({ file1: encode })
      //     })
      //   }
      // }
      // BP
      // if(value.file2){
      //   let secondImage = new FormData();
      //   secondImage.append("type", "BP")
      //   secondImage.append("category", "SIGNER")
      //   secondImage.append("file", value.file2)
      //   let indukImage = await http.post(`/api/upload/image`, secondImage).then(function (res){
      //     izitoast.success({title: 'Signer SPT Induk', message: 'Berhasil diupload'})
      //     return res
      //   }).catch(function (e){
      //     izitoast.success({title: 'Signer SPT Induk', message: 'Gagal diupload'})
      //   });
      //   if(indukImage){ newVal.imagePenandaTanganBp = indukImage.data.id }
      //   if(indukImage){
      //     await http.get('/api/upload/image/'+indukImage.data.id, {
      //       responseType: "blob"
      //     }).then(function (res){
      //       var image  = new Image();
      //       var encode = URL.createObjectURL(res.data);
      //       _this.setState({ file2: encode })
      //     })
      //   }
      // }

      if(value.id) {
        await this.service.api.update(newVal)
        this.props.change(this.props.form, value)
      } else {
        await this.service.api.save(newVal)
        this.props.reset(this.props.form)
      }
      callback()
      this.props.onSuccess()
      this.props.onClose()
    } catch(e) {
      var msg = e.message;
      if(e.response) msg = e.response.data.message
      callback(true, msg)
    }
  }

  formView() {
    var formValue = {}
    if(this.props.formValue) formValue = this.props.formValue
    var org = []
    if(this.props.auth && this.props.auth.organizations) org = this.props.auth.organizations
    var _this = this
    return (
      <Grid style={{padding:'none'}}>
          <div className="md-cell md-cell--12">
            <Field
              label     = 'Organization'
              name      = 'organization'
              className = "md-cell md-cell--12"
              component = {Searchfield}
              valueField = 'parent'
              options   = {org}
              validate  = {validation.required}
              itemTemplate = {function(d) {
                let nameAndAlias = ""
                if (d.name)
                  nameAndAlias = d.name
                if (d.aliasName)
                  nameAndAlias += " - " + d.aliasName
                return {
                  primaryText  : nameAndAlias,
                  secondaryText: d.npwp,
                  onClick      : () => {
                    if(!this.props.disabled){
                      this.handleItemClick(d); this.hide()
                    }
                  }
                }
              }}
            />
          </div>
          <div className="md-cell md-cell--6">
            <Field
              label='Nama Penandatangan BP'
              name='namaPenandaTanganBp'
              component={Textfield}
            />
            <Field
              label='Jabatan Penandatangan BP'
              name='jabatanPenandaTanganBp'
              component={Textfield}
            />

          </div>
          <div className="md-cell md-cell--6">
            <Field
              label='Nama Penandatangan SPT'
              name='namaPenandaTanganSpt'
              component={Textfield}
            />
            <Field
              label='NPWP Penandatangan SPT Induk'
              name='npwpPenandaTanganSpt'
              component={TextfieldMask}
              maskFormat="##.###.###.#-###-###"
            />
            <Field
              label='Active'
              name='active'
              component={Switch}
            />
          </div>

          <div className="md-cell md-cell--12">
            <Field
              label='Nama Kuasa'
              name='namaKuasa'
              component={Textfield}
            />
            <Field
              label='NPWP Kuasa'
              name='npwpKuasa'
              component={TextfieldMask}
              maskFormat="##.###.###.#-###-###"
            />
          </div>

          {/* <div className="md-cell md-cell--6">
            <Field
              label='Start Date'
              name='start'
              inline={true}
              component={Datepicker}
            />
            <Field
              label='End Date'
              name='end'
              inline={true}
              component={Datepicker} />
          </div> */}

          {/* <Field
            label     = 'Image Penanda Tangan Induk'
            name      = 'imagePenandaTanganSpt'
            className = "md-cell md-cell--12"
            // disabled={this.props.organization.id != null}
            valueField = 'id'
            viewField = 'fileName'
            fetchOption = {{
              path : `file/upload/${this.props.companyId}/company`
            }}
            remoteSearch = {true}
            service      = {FileUploadService}
            component    = {Searchfield}
            itemTemplate = {function(d) {
              if(d.data) {
                return {
                  primaryText  : d.fileName,
                  secondaryText: <img src={'data:image/png;base64, '+d.data} style={{width: 'auto', height: 100}}/>,
                  onClick      : () => {
                    if(!this.props.disabled){
                      this.handleItemClick(d); this.hide()
                    }
                  }
                }
              } else {
                return {
                  primaryText  : d.nama,
                  onClick      : () => {
                    if(!this.props.disabled){
                      this.handleItemClick(d); this.hide()
                    }
                  }
                }
              }

            }}
            />

            <Field
            label     = 'Image Penanda Tangan BP'
            name      = 'imagePenandaTanganBp'
            className = "md-cell md-cell--12"
            // disabled={this.props.organization.id != null}
            valueField = 'id'
            viewField = 'fileName'
            fetchOption = {{
              path : `file/upload/${this.props.companyId}/company`
            }}
            remoteSearch = {true}
            service      = {FileUploadService}
            component    = {Searchfield}
            itemTemplate = {function(d) {
              if(d.data) {
                return {
                  primaryText  : d.fileName,
                  secondaryText: <img src={'data:image/png;base64, '+d.data} style={{width: 'auto', height: 100}}/>,
                  onClick      : () => {
                    if(!this.props.disabled){
                      this.handleItemClick(d); this.hide()
                    }
                  }
                }
              } else {
                return {
                  primaryText  : d.nama,
                  onClick      : () => {
                    if(!this.props.disabled){
                      this.handleItemClick(d); this.hide()
                    }
                  }
                }
              }

            }}
            /> */}

          <br />

          <div className="md-cell md-cell--12">
            <p>GAMBAR PENANDATANGAN SPT INDUK (Ukuran Gambar 600x400px)</p>
            <Field
              label='Select a File'
              name='file'
              component={Fileinput}
              onChange={(e, value)=> {
                let formData = new FormData();
                formData.append("file", value)
                http.post(`/upload/image`, formData).then(function (res){
                  _this.props.change("imagePenandaTanganSpt", res.data.id)
                })
              }}
            />
            {this.state.file1 &&
              <img src={this.state.file1} alt="GAMBAR PENANDATANGAN SPT INDUK" style={{width: 600, height: 400}}/>
            }
            <p>PENANDATANGAN BUKTI POTONG (Ukuran Gambar 600x400px)</p>
            <Field
              label='Select a File'
              name='file2'
              component={Fileinput}
              onChange={(e, value)=> {
                let formData = new FormData();
                formData.append("file", value)
                http.post(`/upload/image`, formData).then(function (res){
                  _this.props.change("imagePenandaTanganBp", res.data.id)
                })
              }}
            />
            {this.state.file2 &&
              <img src={this.state.file2} alt="PENANDATANGAN BUKTI POTONG" style={{width: 600, height: 400}}/>
            }
          </div>

      </Grid>
    )
  }
}
