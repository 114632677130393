import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Divider,
  Chip,
  Card,
  CardTitle,
  CardActions,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Switch
} from 'react-md';
import { Field, reduxForm } from 'redux-form';
import {validation, Textfield, Searchfield} from '../../../../../components/form';
import ColumnService from '../../../../../services/column.service';
import download from 'downloadjs';
import uuid from 'uuid';
import iziToast from 'izitoast';

//import FormDialog from './Organization.dialog.view';
import BP21SSPService from './BP_21_SSP.service';
import ListView from '../../../../../components/entity/listView';
import ListViewSpt from '../../../../../components/entity/listViewSpt';
import FileLapor21Service from '../FileLapor21/FileLapor21.service';
import moment from "moment";

@reduxForm({form: 'BP_21_SSP_view', destroyOnUnmount: false})
@connect((state)=> ({
  ...BP21SSPService.stateConnectSetting()(state),
  spt: state.authEppt.currentSPT,
  formData: state.form.BP_21_SSP_view
}), BP21SSPService.actionConnectSetting())
export default class BP21SSPView extends ListViewSpt {
  service=BP21SSPService
  FormDialog=()=> <div/>

  addDialog=false
  editDialog=false

  constructor(props){
    super(props);
    this.state = {
      ...this.state,
      cetak: false,
      formData: {}
    }
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Kode Setoran'
          name='kjs__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NTPN'
          name='ntpn__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
      </this.SearchContainer>
    )
  }

  viewType=2

  header() {
    return (
      <div>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle='DATA SSP TELAH DIBAYAR' />
        <Divider/>
      </div>
    )
  }

  fetchOption() {
    const sptId = this.props.match.params.sptId
    return {
      path: BP21SSPService.path+'/'+sptId+'/spt'
    }
  }

  columns=[
    {isSortable: true, label: "word.kjs", searchField: "kjs", value: function(d){ return d.kjs.code }, type: "func", show:true, isSearchable:true},
    {isSortable: true, label: "word.date",  value: "date", show:true, isSearchable:true},
    {isSortable: true, label: "word.ntpn",  value: "ntpn", show:true, isSearchable:true},
    {isSortable: true, label: "word.total", searchField: "total", value: function(d){ return ColumnService.money(d.total) }, type: "func", show:true, isSearchable:true},
    {isSortable: true, label: "word.description", searchField: "description", value: function(d){ return d.description.name }, type: "func", show:true, isSearchable:true},
    {isSortable:true, label: "word.createdUsername",  value: "createdUsername", show:true, isSearchable:true},
    {isSortable:true, label: "word.createdBy",  value: "createdBy", show:true, isSearchable:true},
    {isSortable:true, label: "word.createdDate", value: "createdDate", show:true, isSearchable:true, type:"date", isDefaultSort: true},
    {isSortable:true, label: "word.modifiedUsername",  value: "modifiedUsername", show:true, isSearchable:true},
    {isSortable:true, label: "word.lastModifiedBy",  value: "lastModifiedBy", show:true, isSearchable:true},
    {isSortable:true, label: "word.lastModifiedDate", value: (d)=> {
      if(d.lastModifiedDate) {
        return <div className='mpk-font-size-S'>{moment(d.lastModifiedDate).format('lll')}</div>
      } else {
        return null;
      }
    }, show:true, isSearchable:true, type:"func", searchField: 'lastModifiedDate', isDefaultSort: true}
  ]

  async componentDidMount() {
    const sptId = this.props.match.params.sptId

    this.rootPath = this.props.match.url;

    var res = await FileLapor21Service.api.getSummary(sptId);
    this.setState({formData: res.data})
  }

  async handleCetakDaftar(){
    this.setState({ cetak: true })
    try {
      const sptId = this.props.match.params.sptId
      var report = await this.service.api.cetak(sptId)
      window.open(report.data.url)
      this.setState({ cetak: false })
    } catch (e){
      this.setState({ cetak: false })
    }
  }

  async deleteSelectedDataItems(){
    var selected = this.props.table.selected

    var ids = [];

    selected.map(function (i){
      /*ids.push({
        id: i.id,
        consumeId: i.consumeId
      })*/

      ids.push(i.id)
    })

    const sptId = this.props.match.params.sptId

    /*var res = await this.service.api.deleteBulk(sptId, { ids: ids, url: 'ssp'})*/
    var res = await this.service.api.deleteBulk(sptId, ids)
    if(res.data.status == 200){
      iziToast.success({
        title: "Success",
        message: "data berhasil dihapus !"
      })
    } else if(res.data.status == 500){
      iziToast.error({
        title: "Failed",
        message: res.data.message
      })
    }

    this.fetchData()
  }

  _barItem() {
    return (
      <div>
         <Button disabled={this.state.cetak} primary raised style={{margin: 5}} onClick={this.handleCetakDaftar.bind(this)}>Cetak Daftar</Button>
        <Switch
          style={{float: "right"}}
          id="switch-table-setting"
          name="Switch Table Setting"
          label=""
          checked={this.state.showTableSetting}
          onChange={() => {
            this.setState({
              showTableSetting:!this.state.showTableSetting
            })
          }}
        />
      </div>
    )
  }

  additionalTopContent() {
    return (
      <div>
        <br/>
        <Card style={{marginBottom: 32}}>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='DAFTAR SURAT SETORAN PAJAK'/>
          <Divider/>
            <DataTable plain baseId="simple-selectable-table" indeterminate>
                <TableHeader>
                <TableRow>
                    <TableColumn>Uraian</TableColumn>
                    <TableColumn>PPH Terutang</TableColumn>
                    <TableColumn>SSP yang disetor</TableColumn>
                    {/*<TableColumn>Request ID Biling</TableColumn>*/}
                </TableRow>
                </TableHeader>
                <TableBody>
                <TableRow>
                    <TableColumn>PPH Tidak Final (KJS : 100)</TableColumn>
                    <TableColumn className='md-text-right'>
                      { ColumnService.money(this.state.formData.pphTF100)  }
                    </TableColumn>
                    <TableColumn className='md-text-right'>
                      { ColumnService.money(this.state.formData.sspTF100)  }
                    </TableColumn>
                    {/*<TableColumn>-</TableColumn>*/}
                </TableRow>
                <TableRow>
                    <TableColumn>PPH Tidak Final (KJS : 104)</TableColumn>
                    <TableColumn className='md-text-right'>
                      { ColumnService.money(this.state.formData.pphTF104)  }
                    </TableColumn>
                    <TableColumn className='md-text-right'>
                      { ColumnService.money(this.state.formData.sspTF104)  }
                    </TableColumn>
                    {/*<TableColumn>-</TableColumn>*/}
                </TableRow>
                <TableRow>
                    <TableColumn>PPH Final (KJS :401)</TableColumn>
                    <TableColumn className='md-text-right'>
                      { ColumnService.money(this.state.formData.pphF401)  }
                    </TableColumn>
                    <TableColumn className='md-text-right'>
                      { ColumnService.money(this.state.formData.sspF401)  }
                    </TableColumn>
                    {/*<TableColumn>-</TableColumn>*/}
                </TableRow>
                <TableRow>
                    <TableColumn>PPH Final (KJS : 402)</TableColumn>
                    <TableColumn className='md-text-right'>
                      { ColumnService.money(this.state.formData.pphF402)  }
                    </TableColumn>
                    <TableColumn className='md-text-right'>
                      { ColumnService.money(this.state.formData.sspF402)  }
                    </TableColumn>
                    {/*<TableColumn>-</TableColumn>*/}
                </TableRow>
                <TableRow>
                  <TableColumn><strong>TOTAL</strong></TableColumn>
                  <TableColumn className='md-text-right'>
                    <strong>{ ColumnService.money(this.state.formData.totalTerutang)  }</strong>
                  </TableColumn>
                  <TableColumn className='md-text-right'>
                    <strong>{ ColumnService.money(this.state.formData.totalSetor)  }</strong>
                  </TableColumn>
                  {/*<TableColumn>-</TableColumn>*/}
                </TableRow>
                </TableBody>
            </DataTable>
        </Card>
      </div>
    )

  }

}
