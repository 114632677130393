import React, { Component } from 'react';
import { Grid, Cell } from 'react-md';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import { validation, Textfield, TextfieldMask, Datepicker, convert } from '../../../../../components/form';
import DialogView from '../../../../../components/entity/dialog.view';
import Master21UpahKumulatifService from './Master21UpahKumulatif.service';

@reduxForm({form: 'master-21-upah-kumulatif', destroyOnUnmount: false})
export default class Master21UpahKumulatifDialogView extends DialogView {
  service=Master21UpahKumulatifService
  initialData = {
  }

  formView() {
    return (
      <Grid style={{padding:'none'}}>
        <Field
          label='Dari'
          name='start'
          className="md-cell md-cell--6"
          component={Datepicker}
          inline={true}
          normalize={convert.date}
          validate={validation.required}
        />
        <Field
          label='Sampai'
          name='end'
          className="md-cell md-cell--6"
          component={Datepicker}
          inline={true}
          normalize={convert.date}
          validate={validation.required}
        />
        <Field
          label='Jumlah'
          name='jumlah'
          className="md-cell md-cell--12"
          component={TextfieldMask}
          money={true}
          normalize={convert.money}
          validate={validation.required}
        />
      </Grid>
    )
  }
}
