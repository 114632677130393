import ApiService from "../../../../../../services/api.service";

class Master23TarifService extends ApiService {
  name= 'Master23Tarif';
  path= 'pasal23/rates/bp23';

  constructor() {
    super();
    this.init()
  }

  api = {
    byCompany: async (data) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}`);
      return res.data;
    },
    getMaster: async () => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}`);
      return res;
    },
    save: async (formData)=> {
      var res = await this.http.put(`${this.apiLocation}/${this.path}`, formData);
      return res;
    }
  }
}


export default new Master23TarifService();
