import React, { Component } from 'react'; import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {TabsContainer, Tabs, Tab} from 'react-md';
import { Field } from 'redux-form';
import moment from 'moment'

import SignerService from './Signer.service';
import ListView from '../../../../../components/entity/ListViewEbupot';
import AuthService from '../../../../../services/auth.service'
import ListViewEbupot from '../../../../../components/entity/ListViewEbupot';

@connect((state)=> ({
  ...SignerService.stateConnectSetting()(state)
}), SignerService.actionConnectSetting())
export default class SignerView extends ListViewEbupot {
  service=SignerService;
  addDialog=false
  editDialog=false

  addItem = () => {
    var orgId = this.props.match.params.id
    this.props.history.push(orgId+"/new");
  }

  editItem = (item) => {
    var orgId = this.props.match.params.id
    this.props.history.push(orgId+"/"+item.id);
  }

  fetchOption() {
    return {
      path: SignerService.path+'/'+this.props.match.params.id+'/organization'
    }
  }

  beforeFetch(params) {
    delete params.startDate
    delete params.endDate
    delete params.column
    delete params.total
    delete params.keyword
    
    
    params.sortBy = "id"
    params.sort = "id,ASC"
}

  columns=SignerService.columns

}
