import React, { Component } from 'react';
import {connect} from 'react-redux';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress
} from 'react-md';
import { Field, reduxForm, getFormValues} from 'redux-form';

import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox} from '../../../../../../components/form';
import {terbilang as terbilangService, terbilangAsync, terbilangAsync22} from '../../../../../../services/terbilang.service';

@connect((state) => ({
  formData: getFormValues('BP_22_Form')(state)
}))
export default class F113304AForm extends Component {
  typingTimeout=1000;
  typingObj=null;

  componentDidMount() {
    this.props.connectCmp(this.reCalculate.bind(this))
  }

  async reCalculate() {
    var form = {...this.props.formData}
    var d    = form.F113304A;

    Object.keys(d).forEach((k, i) => {
      if(!(k == 'jmlBruto' || k == 'jmlPph' || k == 'terbilang' || k == 'lastModifiedBy' || k == 'lastModifiedDate' || k == 'id' || k == 'createdDate' || k == 'createdBy')) {
        var x = d[k];

        if(typeof x == 'object') {
          if(x.bruto) x.pph = x.bruto*x.tarif/100
        }
      }
    })

    await this.props.initialize(form);
    await this.calculateTotal()
  }

  async calculate(e,v,pv,f, parent) {
    if(this.typingObj) clearTimeout(this.typingObj);

    this.typingObj = setTimeout(async ()=> {
      var form = this.props.formData;
      var d    = form;

      var bruto = 0;
      var tarif = 0;

      if(typeof parent == 'string') {
        bruto = parseFloat(d['bruto'+parent]);
        tarif = parseFloat(d['tarif'+parent]);
      } else {
        bruto = parseFloat(d['bruto'+parent+'3']);
        tarif = parseFloat(d['tarif'+parent+'5']);
      }

      if(f.search('bruto') >= 0) {
        bruto = parseFloat(v)
      } else if(f.search('tarif') >= 0) {
        tarif = parseFloat(v)
      }

      if(!bruto) bruto = 0;
      if(!tarif) tarif = 0;

      var nonNpwp = 1;

      if(d.non) nonNpwp = 2;


      if(typeof parent == 'string') {
        await this.props.change(`pph${parent}`, bruto*tarif*nonNpwp/100);
      } else {
        await this.props.change(`pph${parent}6`, bruto*tarif*nonNpwp/100);
      }

      this.calculateTotal()
    }, this.typingTimeout);
  }

  async calculateTotal() {
    var form = this.props.formData;
    var d    = form;

    var jmlPph   = 0;
    var jmlBruto = 0;
    Object.keys(d).forEach((k, i) => {
      if(!(k == 'jmlBruto' || k == 'jmlPph' || k == 'terbilang' || k == 'lastModifiedBy' || k == 'lastModifiedDate' || k == 'id' || k == 'createdDate' || k == 'createdBy')) {
        var x = d[k];


        if(k.search('bruto') >= 0) {
          if(d[k]) jmlBruto += parseFloat(d[k]);
        }
        if(k.search('pph') >= 0) {
          if(d[k]) jmlPph += parseFloat(d[k]);
        }
      }

    })

    this.props.change('jmlBruto', jmlBruto)
    this.props.change('jmlPph', jmlPph);

    var terbilangRes = '';
    try {
      terbilangRes = await terbilangAsync22(jmlPph);
    } catch (error) {}

    this.props.change('terbilang', terbilangRes);

  }

  render() {
    return <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
      <TableHeader>
        <TableRow>
          <TableColumn>No.</TableColumn>
          <TableColumn>Uraian</TableColumn>
          <TableColumn className='md-text-right'>Harga (Rp)</TableColumn>
          <TableColumn className='md-text-right'>Tanpa NPWP</TableColumn>
          <TableColumn className='md-text-right'>Tarif(%)</TableColumn>
          <TableColumn className='md-text-right'>Pajak yg Dipungut(Rp)</TableColumn>
        </TableRow>
      </TableHeader>
      <TableBody>
        <TableRow>
          <TableColumn></TableColumn>
          <TableColumn><b>Jenis Industri:</b></TableColumn>
          <TableColumn><b>Penjualan Bruto:</b></TableColumn>
          <TableColumn className='md-text-right'></TableColumn>
          <TableColumn className='md-text-right'></TableColumn>
          <TableColumn className='md-text-right'></TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn>1.</TableColumn>
          <TableColumn>Semen</TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'brutoSemen'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'Semen')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'non'
              disabled
              component = {Switch}
              style={{float: 'right'}}
              className = 'md-text-right'
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'tarifSemen'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'Semen')}
              money     = {','}
              disabled={true}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'pphSemen'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
              disabled={true}
            />
          </TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn>2.</TableColumn>
          <TableColumn>Kertas</TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'brutoKertas'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'Kertas')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'non'
              disabled
              component = {Switch}
              style={{float: 'right'}}
              className = 'md-text-right'
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'tarifKertas'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'Kertas')}
              money     = {','}
              disabled={true}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'pphKertas'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
              disabled={true}
            />
          </TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn>3.</TableColumn>
          <TableColumn>Baja</TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'brutoBaja'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'Baja')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'non'
              disabled
              component = {Switch}
              style={{float: 'right'}}
              className = 'md-text-right'
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'tarifBaja'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'Baja')}
              money     = {','}
              disabled={true}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'pphBaja'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
              disabled={true}
            />
          </TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn>4.</TableColumn>
          <TableColumn>Otomotif</TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'brutoOtomotif'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'Otomotif')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'non'
              disabled
              component = {Switch}
              style={{float: 'right'}}
              className = 'md-text-right'
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'tarifOtomotif'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'Otomotif')}
              money     = {','}
              disabled={true}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'pphOtomotif'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
              disabled={true}
            />
          </TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn>5.</TableColumn>
          <TableColumn><Field
              // label='Bruto'
              name      = 'uraian52'
              component = {Textfield}
            /></TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'bruto53'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 5)}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'non'
              disabled
              component = {Switch}
              style={{float: 'right'}}
              className = 'md-text-right'
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'tarif55'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 5)}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'pph56'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
              disabled={true}
            />
          </TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn>6.</TableColumn>
          <TableColumn><Field
              // label='Bruto'
              name      = 'uraian62'
              component = {Textfield}
            /></TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'bruto63'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 6)}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'non'
              disabled
              component = {Switch}
              style={{float: 'right'}}
              className = 'md-text-right'
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'tarif65'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 6)}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'pph66'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
              disabled={true}
            />
          </TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn></TableColumn>
          <TableColumn><b>Penjualan Barang Sangat Mewah:</b></TableColumn>
          <TableColumn><b>Harga Jual:</b></TableColumn>
          <TableColumn></TableColumn>
          <TableColumn></TableColumn>
          <TableColumn></TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn>7.</TableColumn>
          <TableColumn><Field
              // label='Bruto'
              name      = 'uraianMewah'
              component = {Textfield}
            /></TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'brutoMewah'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'Mewah')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'non'
              disabled
              component = {Switch}
              style={{float: 'right'}}
              className = 'md-text-right'
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'tarifMewah'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'Mewah')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'pphMewah'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
              disabled={true}
            />
          </TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn></TableColumn>
          <TableColumn><b>Industri/Eksportir:</b></TableColumn>
          <TableColumn><b>Pembelian Bruto:</b></TableColumn>
          <TableColumn></TableColumn>
          <TableColumn></TableColumn>
          <TableColumn></TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn>8.</TableColumn>
          <TableColumn>Sektor<Field
              // label='Bruto'
              name      = 'uraianSektorA'
              component = {Textfield}
            /></TableColumn>
          <TableColumn>

            <Field
              // label='Bruto'
              name      = 'brutoSektorA'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'SektorA')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'non'
              disabled
              component = {TextfieldMask}
              className = 'md-text-right'
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'tarifSektorA'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'SektorA')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'pphSektorA'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
              disabled={true}
            />
          </TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn>9.</TableColumn>
          <TableColumn>Sektor<Field
              // label='Bruto'
              name      = 'uraianSektorB'
              component = {Textfield}
            /></TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'brutoSektorB'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'SektorB')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'non'
              disabled
              component = {Switch}
              style={{float: 'right'}}
              className = 'md-text-right'
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'tarifSektorB'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'SektorB')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'pphSektorB'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
              disabled={true}
            />
          </TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn></TableColumn>
          <TableColumn><b>Badan Tertentu Lainnya:</b></TableColumn>
          <TableColumn></TableColumn>
          <TableColumn></TableColumn>
          <TableColumn></TableColumn>
          <TableColumn></TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn>10.</TableColumn>
          <TableColumn>BUMN Tertentu</TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'brutoBumnTertentu'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'BumnTertentu')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'non'
              disabled
              component = {Switch}
              style={{float: 'right'}}
              className = 'md-text-right'
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'tarifBumnTertentu'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'BumnTertentu')}
              money     = {','}
              disabled={true}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'pphBumnTertentu'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
              disabled={true}
            />
          </TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn>11.</TableColumn>
          <TableColumn><Field
              // label='Bruto'
              name      = 'uraianLainya'
              component = {Textfield}
            /></TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'brutoLainya'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'Lainya')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'non'
              disabled
              component = {Switch}
              style={{float: 'right'}}
              className = 'md-text-right'
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'tarifLainya'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'Lainya')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'pphLainya'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
              disabled={true}
            />
          </TableColumn>
        </TableRow>
      </TableBody>
    </DataTable>
  }
}
