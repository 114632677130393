import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Field, reduxForm, getFormValues } from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../../../../components/form';

import ListView from '../../../../../../components/entity/listView';
import ColumnService from '../../../../../../services/column.service';
// import ImportService from './../Import/Import.service';
import axios from 'axios';
import izitoast from 'izitoast';
import FormDialog from './ImportLK22.dialog';
import ImportLK22Service from './ImportLK22.service';
import download from "downloadjs";
import ListViewImport from '../../../../../../components/entity/ListViewImport';

@reduxForm({form: 'ImportLK22Form', destroyOnUnmount: true})
@connect((state) => ({
  ...ImportLK22Service.stateConnectSetting()(state),
  formData: getFormValues('ImportLK22Form')(state),
  // EPPT CORE
  auth: state.auth.company
  // EPPT CORE
}), ImportLK22Service.actionConnectSetting())
export default class ImportLK22View extends ListViewImport {
  service=ImportLK22Service
  FormDialog=FormDialog
  pasal='pasal22'
  category='LT'
  importPath='/pasal22/upload/csv/etc'
  showAutomatisTab=false
  // viewType=2

  beforeFetch(params) {
    params["category.equals"] = "LT"
  }

  async handleDownloadTemplate() {
    var report = await ImportLK22Service.api.downloadTemplate('LT')
    window.open(report.data.url)
  }

}
