import ApiService from '../../../../../../services/api.service';

class Master25KapService extends ApiService {
  name= 'Master25Kap';
  path= 'pasal25/spt/kap';

  constructor() {
    super();
    this.init();
    this.initApi();
  }

  api={
    getPasal25: async ()=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/25/pasal`)

      return res;
    }
  }

}


export default new Master25KapService();

