import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues  } from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  Tab,
  Tabs,
  SelectionControlGroup,
  Checkbox,
  Button
} from 'react-md';

import FormView from '../../../../../components/entity/form.view';
import Master21FinalPesangonProgService from './Master21FinalPesangonProgGlobal.service';
import {validation, Textfield, Searchfield, Switch, Radio, convert, TextfieldMask} from '../../../../../components/form';
import download from 'downloadjs';
import uuid from 'uuid';
import iziToast from 'izitoast';

@reduxForm({form: 'Master21FinalPesangonProgForm', destroyOnUnmount: true})
@connect((state) => ({
  access: state.entity.access.api.find,
  organization: state.authEppt.currentOrganization,
  spt: state.authEppt.currentSPT,
  formData: getFormValues('Master21FinalPesangonProgForm')(state)
}))
export default class Master21FinalPesangonProgForm extends FormView {
    service=Master21FinalPesangonProgService
    viewType =2;

    async initData() {
      var id = this.props.match.params.id
      let res = await this.service.api.findOne(id);
      this.props.initialize(res.data);
    }

    redirectTo=true

    async afterSave(res, val) {
      iziToast.success({
        title: "Success",
        message: "Setting tarif berhasil disimpan."
      })
    }

    formView(){
        var progresif = null
        if(this.props.formData && this.props.formData.progresifs){
            progresif = this.props.formData.progresifs
        }
        return (
            <div>
                {progresif}
              <Card>
                <div className='md-grid'>
                  <Field
                    label= 'Layer'
                    name = 'layer'
                    className = 'md-cell md-cell--6'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                  <Field
                    label= 'Tarif'
                    name = 'rates'
                    className = 'md-cell md-cell--6'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                  <Field
                    label= 'Dimulai'
                    name = 'early'
                    className = 'md-cell md-cell--6'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                  <Field
                    label= 'Sampai'
                    name = 'ends'
                    className = 'md-cell md-cell--6'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                  <Field
                    label= 'Akhir'
                    name = 'isEnd'
                    className = 'md-cell md-cell--6'
                    component = {Switch}
                  />
                </div>
              </Card>
            </div>
        )
    }
}
