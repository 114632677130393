
import React, { Component } from 'react'; import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import routes from './routes';

import AuthService from './../../../../../../services/auth.service'
import ESPT23Service from './../SPT23/SPT23.service'
import OrganizationService from './../../Organization/Organization.service'

@connect(state => ({
  organization: state.authEppt.currentOrganization,
  user: state.authEppt.user,
  spt: state.authEppt.currentSPT,
  auth: state.auth.company,
  userCore: state.auth.user
}))
export default class Ebupot23Main extends Component{
  async componentDidMount() {
    try {
      let spt = await ESPT23Service.api.findOne(this.props.match.params.sptId);
      AuthService.api.changeSPT({
        type: '23_26',
        data: spt.data
      }, this.props.dispatch)
      AuthService.api.changeMenuType('ebupot', this.props.dispatch)
      if(spt.data && spt.data.npwp){
        var orgNpwp = spt.data.npwp
        var organization = {}
        var organizations = []
        if(this.props.auth && this.props.auth.organizations) organizations = this.props.auth.organizations
        // SUPPORT
        if(!this.props.auth){
          if(this.props.userCore){
            if(this.props.userCore && this.props.userCore.isSupport){
              let getOrgSupport = await OrganizationService.api.getAll({
                user: this.props.userCore,
                field: 'npwp',
                query: orgNpwp
              })
              organizations = getOrgSupport.data
            }
          }
        }
        // SUPPORT
        var filtered = organizations.filter((org)=> {
          return org.npwp == orgNpwp
        })
        // console.log(filtered)
        if(filtered.length > 0){
          organization = filtered[0]
        }
        // console.log(organization)
        AuthService.api.changeOrganization(organization, this.props.dispatch)      
      }
    } catch(e){ console.log(e) }
  }
  render() {
    return(
        <div className="mpk-layout column fill">
          {routes(this.props)}
        </div>
    )
  }
}
