import ApiService from '../../../../../services/api.service';

class SPT22SignerService extends ApiService {
  name= 'SPT22Signer';
  path= 'pasal22/signer';
  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    getSigner: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/spt4/${sptId}`);
      return res.data;
    },
    getSignerByDate: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/spt4/${sptId}`);
      return res.data;
    }
  }
}


export default new SPT22SignerService();
