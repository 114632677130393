import React, { Component } from 'react';
import { connect } from 'react-redux';

import {Field, getFormValues, reduxForm} from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../../../components/form';

import ImportBp21A2Service from './ImportBp21A2.service';
import ListView from '../../../../../components/entity/listView';
import ColumnService from '../../../../../services/column.service';
import ImportService from './../Import/Import.service';
import axios from 'axios';
import izitoast from 'izitoast';
import FormDialog from './ImportBp21A2.dialog.view';
import download from "downloadjs";
import ListViewImport from '../../../../../components/entity/ListViewImport';

@reduxForm({form: 'IMPORT_21_BP_A2_Form', destroyOnUnmount: true})
@connect((state) => ({
  ...ImportBp21A2Service.stateConnectSetting()(state),
  formData: getFormValues('IMPORT_21_BP_A2_Form')(state)
}), ImportBp21A2Service.actionConnectSetting())
export default class ImportBp21A2View extends ListViewImport {
  service=ImportBp21A2Service
  FormDialog=FormDialog

  category='BP_21_A2'
  importPath='/api/import/spt/21'
  showAutomatisTab=false
  showPenomoranAuto=true
  showFormatEspt=true
  showPembetulan=true
  // viewType=2

  beforeFetch(params) {
    params["category.equals"] = "BP_21_A2"
  }

  async handleDownloadTemplate() {
    var report = await ImportBp21A2Service.api.downloadTemplate()
    download(report.data, 'TemplateBp21A2.csv');
  }

}
