import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues} from 'redux-form';
import moment from 'moment';
import { ExpansionList, ExpansionPanel, Slider } from 'react-md';

import FormView from '../../../../../components/entity/form.view'
import { validation, Textfield, Searchfield, Searchfieldv2, TextfieldMask, Switch, Datepicker, Radio, Checkbox, CheckboxSelection, convert } from '../../../../../components/form'
import Service from './LawanTransaksi23.service'
import ProvincesService from '../Provinces/Provinces.service';
import KotaService from '../Regencies/Regencies.service'
import KecamatanService from '../Districts/Districts.service'
import KelurahanService from '../Villages/Villages.service'
import RegenciesService from '../Regencies/Regencies.service';
import DistrictsService from '../Districts/Districts.service';
import OrganizationService from '../Organization/Organization.service'
import iziToast from 'izitoast';

@reduxForm({form: 'LawanTransaksi23Form', destroyOnUnmount: true})
@connect((state) => ({
  //access      : state.entity.access.api.find,
  //organization: state.auth.currentOrganization,
  spt         : state.auth.currentSPT,
  formData    : getFormValues('LawanTransaksi23Form')(state),
  // EPPT CORE
  auth: state.auth.company
  // EPPT CORE
}))
export default class LawanTransaksi23Form extends FormView {
    service=Service
    viewType=2;

    constructor(props){
        super(props)
        this.state = {
            ...this.state,
            isNik: false,
            propinsiId: "",
            kabupatenId: "",
            kecamatanId: "",
            kelurahanId: ""
        }
    }

    initialData={
        identity: false
    }

    async initData() {
        if(this.props.match.params.id == 'new') {
          var initData = this.initialData
          this.props.initialize(initData);
        } else {
          this.setState({
              edit: true
          })
          let res = await this.service.api.findOne(this.props.match.params.id);
          res.data.agree = true
          this.props.initialize(res.data);
          try {
            let getPropinsi = await ProvincesService.api.getByName('name.contains='+res.data.provinsi)
            this.setState({  propinsiId: getPropinsi.id,  })
          } catch(e){}
          try {
            let getRegency = await RegenciesService.api.getByName('name.contains='+res.data.kabupaten)
            this.setState({ kabupatenId: getRegency.id })
          } catch(e){}
          try {
            let getDistrict = await DistrictsService.api.getByName('name.contains='+res.data.kecamatan)
            this.setState({ kecamatanId: getDistrict.id })
          } catch(e){}
        }
    }

    handleNik(e, value){
        if(value == false){
            this.setState({ isNik: false })
        } else {
            this.setState({ isNik: true })
        }
    }

    handleIsNik(e, value){
        this.props.change('npwp', " ")
        this.props.change('nik', value)
    }

    handleIsNpwp(e, value){
        this.props.change('npwp', value)
        this.props.change('nik', " ")
    }

    async handleChange(f, e, val){
        var value = val
        if(f == 'provinsi'){
            try {
                let getPropinsi = await ProvincesService.api.getByName('name.contains='+value)
                this.setState({ propinsiId: getPropinsi.id })
            } catch(e){  }
        }
        if(f == 'kabupaten'){
            try {
                let getRegency = await RegenciesService.api.getByName('name.contains='+value)
                this.setState({ kabupatenId: getRegency.id })
            } catch(e){ }
        }
        if(f == 'kecamatan'){
            try {
                let getDistrict = await DistrictsService.api.getByName('name.contains='+value)
                this.setState({ kecamatanId: getDistrict.id })
            } catch(e){ }
        }
    }

    formView(){
        var nik = this.state.isNik
        if(this.props.formData && this.props.formData && this.props.formData.identity) nik = this.props.formData.identity
        var propinsiId = null
        var kabupatenId = null
        var kecamatanId = null
        if(this.state.propinsiId){
            propinsiId = { "provinceId.contains": this.state.propinsiId }
        }
        if(this.state.kabupatenId){
            kabupatenId = { "regencyId.contains": this.state.kabupatenId }
        }
        if(this.state.kecamatanId){
            kecamatanId = { "districtId.contains": this.state.kecamatanId }
        }
        var _this = this
        var disableOnEdit = false
        if(this.props.match.params.id != "new") disableOnEdit = true
        var org = []
        if(this.props.auth && this.props.auth.organizations) org = this.props.auth.organizations
        return (
            <ExpansionList>
                <ExpansionPanel label="Identitas Wajib Pajak yang Dipotong" defaultExpanded={true} footer={false}>
                    <div className="md-grid">
                        <Field
                            label     = 'Organization'
                            name      = 'npwpProfile'
                            className = "md-cell md-cell--12"
                            component = {Searchfield}
                            valueField = 'npwp'
                            options   = {org}
                            validate  = {validation.required}
                            itemTemplate = {function(d) {
                                let nameAndAlias = ""
                                if (d.name)
                                nameAndAlias = d.name
                                if (d.aliasName)
                                nameAndAlias += " - " + d.aliasName
                                return {
                                primaryText  : nameAndAlias,
                                secondaryText: d.npwp,
                                onClick      : () => {
                                    if(!this.props.disabled){
                                    this.handleItemClick(d); this.hide()
                                    }
                                }
                                }
                            }}
                        />
                        <Field
                            label     = 'Identitas'
                            name      = 'identity'
                            className = "md-cell md-cell--6"
                            component = {CheckboxSelection}
                            options  = {[
                                {value: true, label: "NPWP"},
                                {value: false, label: "NIK"}
                            ]}
                            validate  = {validation.required2}
                            onChange  = {this.handleNik.bind(this)}
                            formData  = {this.props.formData}
                            skipValues= {true}
                            disabled={disableOnEdit}
                        />
                        {(nik == false) &&
                            <Field
                                label     = 'NIK'
                                name      = 'nik'
                                className = "md-cell md-cell--6"
                                component={TextfieldMask}
                                maskFormat="################"
                                mask="_"
                                length="16"
                                validate  = {validation.required}
                                onChange  = {this.handleIsNik.bind(this)}
                                disabled={disableOnEdit}
                            />
                        }
                        {(nik == true) &&
                            <Field
                                label     = 'Npwp'
                                name      = 'npwp'
                                className = "md-cell md-cell--6"
                                component = {TextfieldMask}
                                maskFormat= "##.###.###.#-###-###"
                                validate  = {validation.required}
                                onChange  = {this.handleIsNpwp.bind(this)}
                                disabled={disableOnEdit}
                            />
                        }
                        {(nik == false) &&
                            <Field
                                label     = 'Nama'
                                name      = 'nama'
                                className = "md-cell md-cell--12"
                                component = {Textfield}
                                validate={validation.required}
                                disabled={disableOnEdit}
                            />
                        }
                        {(nik == false) &&
                            <Field
                                label='Alamat'
                                name='alamat'
                                className="md-cell md-cell--12"
                                component={Textfield}
                                validate={validation.required}
                                disabled={disableOnEdit}
                            />
                        }
                        <Field
                            label     = 'Email'
                            name      = 'email'
                            className = "md-cell md-cell--6"
                            component = {Textfield}
                            validate={validation.email}
                        />
                        {(nik == false) &&
                            <Field
                                label='No Telp'
                                name='noTelp'
                                className="md-cell md-cell--6"
                                component={Textfield}
                                disabled={disableOnEdit}
                            />
                        }
                        {(nik == false) &&
                            <Field
                                label='Propinsi'
                                name='provinsi'
                                className="md-cell md-cell--6"
                                component={Searchfieldv2}
                                valueField='name'
                                service={ProvincesService}
                                remoteSearch
                                onChange={this.handleChange.bind(this, 'provinsi')}
                                disabled={disableOnEdit}
                                validate={validation.required}
                            />
                        }
                        {(nik == false) &&
                            <Field
                                label='Kota/Kabupaten'
                                name='kabupaten'
                                className="md-cell md-cell--6"
                                component={Searchfieldv2}
                                valueField='name'
                                service={KotaService}
                                remoteSearch
                                params={{"provinceId.contains": this.state.propinsiId}}
                                onChange={this.handleChange.bind(this, 'kabupaten')}
                                disabled={disableOnEdit}
                                validate={validation.required}
                            />
                        }
                        {/* {(nik == true && disableOnEdit) &&
                            <Field
                                label     = 'Propinsi'
                                name      = 'provinsi'
                                className = "md-cell md-cell--6"
                                component={Searchfieldv2}
                                valueField='name'
                                service={ProvincesService}
                                remoteSearch
                                onChange={this.handleChange.bind(this, 'provinsi')}
                                disabled={disableOnEdit}
                            />
                        }
                        {(nik == true && disableOnEdit) &&
                            <Field
                                label     = 'Kota/Kabupaten'
                                name      = 'kabupaten'
                                className = "md-cell md-cell--6"
                                component ={Searchfieldv2}
                                valueField='name'
                                service={KotaService}
                                remoteSearch
                                params={{ "provinceId.contains": this.state.propinsiId }}
                                onChange={this.handleChange.bind(this, 'kabupaten')}
                                disabled={disableOnEdit}
                            />
                        } */}
                        {(nik == false) &&
                            <Field
                                label='Kecamatan'
                                name='kecamatan'
                                className="md-cell md-cell--6"
                                component={Searchfieldv2}
                                valueField='name'
                                service={KecamatanService}
                                remoteSearch
                                params={{"regencyId.contains": this.state.kabupatenId}}
                                onChange={this.handleChange.bind(this, 'kecamatan')}
                                disabled={disableOnEdit}
                            />
                        }
                        {(nik == false) &&
                            <Field
                                label='Kelurahan'
                                name='kelurahan'
                                className="md-cell md-cell--6"
                                component={Searchfieldv2}
                                valueField='name'
                                service={KelurahanService}
                                remoteSearch
                                params={{"districtId.contains": this.state.kecamatanId}}
                                disabled={disableOnEdit}
                            />
                        }
                        {(nik == false) &&
                            <Field
                                label='Kode Pos'
                                name='kodePos'
                                className="md-cell md-cell--6"
                                component={Textfield}
                                disabled={disableOnEdit}
                            />
                        }
                    </div>
                </ExpansionPanel>
            </ExpansionList>
        )
    }

}
