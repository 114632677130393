/**
 * Created by dwiargo on 2/22/18.
 */

import { httpService } from 'react-mpk';
import utilsService from './utilsService';
import hosts from './hosts';

const companyService = {
	// get:(params, addDataItem) => {
	// 	let host = hosts.getAll().iam.host;
	// 	params = utilsService.mapUrlParams(params);
	// 	return httpService.streamGet({
	// 		url:`${host}${hosts.baseUrl}/company`,
	// 		params:params
	// 	}, item => {
	// 		addDataItem(item);
	// 	})
	// },
	api: {
		get: async (params) => {
			var x = Object.assign({}, params)
			let host = hosts.getAll().iam.host;
			params = utilsService.mapUrlParams(params);
			params.query = x.query
			params.field = "name"
			params.size = 20000
			let res = await httpService.get({
				url:`${host}/api/user_console/company_support`,
				params:params
			})
			res.data = res.data.split("}{").join("},{")
			res.data =  "[" + res.data + "]"
			res.data = JSON.parse(res.data)
			return res
		}
	},
	get: (params, onAddDataItem) => {
		let host = hosts.getAll().iam.host;
		params = utilsService.mapUrlParams(params);
		return httpService.streamGet({
			url:`${host}/api/user_console/company_support`,
			params:params
		}, onAddDataItem)
	},
	getById:(id) => {
		return httpService.get({
		url:`${hosts.getAll().iam.host}${hosts.baseUrl}/company/${id}`,
				config:{
					headers:{
						'X-COMPANY-ID':id
					}
				}
		})
	},
	meAsUser: (id, subscriptionId ) => {
		return httpService.get({
			url: `/api/iams/company/${id}/${subscriptionId}/me`,
			params: {
				companyId: id
			}
		})
	},
	me: (id, as="support" ) => {
		return httpService.get({
			url: `/api/iams/company/${id}/me/${as}`
		})
	},
	permission: (subscriptionId) => {
		return httpService.get({
			url: `/api/iams/me/permission/${subscriptionId}`
		})
	},
	refreshToken: (refreshToken) => {
		return httpService.get({
			url: `/api/iams/refreshToken/${refreshToken}`
		})
	}
};

export default companyService;