import ApiService from '../../../../../../services/api.service';

class ALLBP26Service extends ApiService {
  name= 'ALLBP26';
  path= 'pasal23/bp26';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    email: async (data, companyId) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/mail`, data, {
        params: {
          companyId: companyId
        }
      });
      return res;
    },
    report: async (data) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/report`, data);
      return res;
    },
    reportPisah: async (data) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/report/pisah`, data);
      return res;
    },
    export: async (data) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/export`, data);
      return res;
    }
  }

}

export default new ALLBP26Service();
