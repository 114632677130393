import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import {Button, Card, FontIcon, List, ListItem, Subheader} from 'react-md';
import _ from 'lodash';
import FormView from '../../../../components/entity/form.view';
import {Datepicker, Radio, Switch, Textfield} from '../../../../components/form';
import SettingExportService from './SettingExport.service';
import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

@reduxForm({
  form: 'SettingExport_Form',
  destroyOnUnmount: true,
  initialValues: {encryptA1: false}
})
@connect((state) => ({
  access: state.entity.access.api.find,
  organization: state.authEppt.currentOrganization,
  spt: state.authEppt.currentSPT,
  formData: state.form.SettingExport_Form,
  user: state.authEppt.user
}))
export default class SettingExportForm extends FormView {
  service = SettingExportService
  viewType = 2;

  constructor(props) {
    super(props)
    this.state = {
      ...this.state
    }
  }

  _barItem() {
    const {handleSubmit, submitting, valid, pristine} = this.props;
    var userId = this.props.match.params.userId

    if (userId) {
      return (
        <div>
          <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Save</Button>
        </div>
      )
    } else {
      return (
        <div>
          <Button secondary flat onClick={() => {
            this.props.history.goBack()
          }} style={{marginRight: 16}}>Back</Button>
          <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Save</Button>
        </div>
      )
    }
  }

  async initData() {
    var userId = this.props.user.id

    if (!userId) {
      // Try in url
      var paramInurl = this.props.location.pathname.split("/")

      if (paramInurl[2]) {
        userId = paramInurl[2]
      }
    }

    if (this.props.user.user && this.props.user.user.id) userId = this.props.user.user.id;

    try {
      let res = await this.service.api.getByUser(userId);

      let data = res.data

      this.props.initialize(data);
    } catch (e) {
      this.toast.warning({
        title: e.name,
        message: e.message
      })
    }
  }

  async handleSave(value) {
    try {
      let res = await this.service.api.updateByUser(value)
    } catch (e) {
      this.toast.warning({
        title: e.name,
        message: e.message
      })
    }
  }

  formView() {
    return (
      <div>
        <Card>
          <div className='md-grid'>
            <div className="md-cell md-cell--6">
              <Field
                label='Encrypt File Email A1'
                name='encryptA1'
                component={Switch}
              />
            </div>

            {this.props.formData.values.encryptA1 &&
              <div className="md-cell md-cell--6">
                <Field
                  label='Password File Email A1'
                  name='passwordA1'
                  type="password"
                  component={Textfield}
                />
              </div>
            }
          </div>
        </Card>
        <br/>
      </div>
    )
  }

}
