import ApiService from '../../../../services/api.service';

class SettingEmailScheduleDetailsService extends ApiService {
  name= 'SettingEmailScheduleDetails';
  path= 'setting/emailScheduleDetails';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api = {
    getByEmailSchedule: async (emailScheduleId)=> {
      return await this.http.get(`${this.apiLocation}/setting/emailScheduleDetails/emailSchedule/`+emailScheduleId)
    },
  }
}

export default new SettingEmailScheduleDetailsService();
