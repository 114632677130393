import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues} from 'redux-form';
import moment from 'moment';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress
} from 'react-md';

import FormView from '../../../../../components/entity/form.view';
// import NomorBPService from '../nomorBP.service';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox} from '../../../../../components/form';
import UtilService from '../../../../../services/utils.service'
// import SPT21SignerService from '../SPT21Signer/SPT21Signer.service';
import NpwpService from './../../NpwpService';
import FormViewSpt from '../../../../../components/entity/form.view.spt';
import terbilangService, {terbilangAsync15} from '../../../../../services/terbilang.service';
import SSP15SetorSendiriService from './SSP15SetorSendiri.service';
import LawanTransaksiDialog from '../../../../../components/entity/LawanTransaksi.dialog';
import MasterLawanTransaksi15Service from '../../../Master/MasterLawanTransaksi15/MasterLawanTransaksi15.service';

@reduxForm({form: 'BP15BSSewaTanahForm', destroyOnUnmount: true})
@connect((state) => ({
  access: state.entity.access.api.find,
  organization: state.authEppt.currentOrganization,
  spt: state.authEppt.currentSPT,
  formData:getFormValues('BP15BSSewaTanahForm')(state)
}))
export default class BP15BSSewaTanahForm extends FormViewSpt {
  service=SSP15SetorSendiriService
  viewType =2;

  initialData={
    bagA: {},
    bagB: {bruto: 0},
    bagC: {}
  }

  constructor(props) {
    super(props);

    this.state= {
      ...this.state,
      showCalculateProgress: false,
      npwpNotValid: true,
      lkDialog: false
    }
  }

  async npwpValidation(evt, value){
    if(value){
      var res = await NpwpService.api.validate15(value)
      if(res.valid){
        this.setState({npwpNotValid: false})
      } else {
        this.setState({npwpNotValid: true})
        this.toast.warning({title: "Validasi Npwp", message: "npwp tidak valid"})
      }

      var nonNpwp = 0;
      if(value === '000000000000000' || value == '' || !value) {
        nonNpwp = 1;
      }

      var form = this.props.formData;
      var d = form.bagB;

      Object.keys(d).forEach((k, i) => {
        if(!(k == 'jmlBruto' || k == 'jmlPph' || k == 'terbilang')) {
          var x = d[k];

          x.nonNpwp = nonNpwp
        }
      })

      await this.props.change('bagB', d)
    }
  }

  async beforeSave(value) {
    console.log(value)
    var date;
    if(typeof value.tgl === 'string') {
      if(value.tgl.search('/') != -1) {
        date = moment(value.tgl, 'DD/MM/YYYY');
      } else {
        date = moment(value.tgl, 'YYYY-MM-DD');
      }
    } else if(typeof value.tgl === 'object') {
      date = moment(value.tgl)
    }
    // Remapping Objek
    var mapping = {
      "gross"           : value.bruto ? parseFloat(value.bruto) : 0,
      "ntpn"            : value.ntpn,
      "pph"             : value.pph ? parseFloat(value.pph) : 0,
      "ref"             : value.referensi,
      "signAddress"     : value.alamatPemotong,
      "signName"        : value.namaPemotong,
      "signNpwp"        : value.npwpPemotong,
      "tgl"             : value.tgl,
      "words"           : value.terbilang ? value.terbilang : "-",
      spt               : this.props.spt.data
    }
    
    if(value.id && value.id != "new") mapping.id = value.id
    if(value.consumeId) mapping.consumeId = value.consumeId
    if(typeof mapping.tgl == "object") mapping.tgl = moment(mapping.tgl).format("YYYY-MM-DD")

    value = mapping;
    // Remapping Objek
    return mapping;
  }

  async initData() {
    let organization = this.props.organization

    if (!organization) {
      organization = this.props.spt.data.organization
    } else {
      if (!organization.npwp) {
        organization = this.props.spt.data.organization
      }
    }

    if(this.props.match.params.id == 'new') {
      let nomorBPReq = 0; //await nomorBP23Service.api.get(this.props.spt.data.organization.id)
      var nomorBP = "";

      // if(nomorBPReq.data.mode === 'PREF_SUF') {
      //   nomorBP = nomorBPReq.data.prefix+nomorBPReq.data.suffix;
      // } else {
      //   nomorBP = nomorBPReq.data.suffix+nomorBPReq.data.prefix;
      // }
      let signer = {}; //await SPT21SignerService.api.getSigner(this.props.spt.data.id);

      this.initialData = {
        ...this.initialData,
        namaPemotong   : organization.name,
        npwpPemotong   : organization.npwp,
        alamatPemotong : organization.address,
        tgl: new Date(),
        spt: this.props.spt.data,
      }
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);

      res.data.namaPemotong = organization.name
      res.data.npwpPemotong = organization.npwp
      res.data.alamatPemotong = organization.address
      res.data.spt = this.props.spt.data

      // Remapping Objek
      var data = res.data
      var remapping = {
        id                : data.id,
        bruto             : data.gross ? data.gross : 0,
        ntpn              : data.ntpn,
        pph               : data.pph ? data.pph : 0,
        referensi         : data.ref,
        alamatPemotong    : data.signAddress,
        namaPemotong      : data.signName,
        npwpPemotong      : data.signNpwp,
        tgl               : data.tgl,
        terbilang         : data.words,
        spt               : data.spt
      }
      res.data = remapping
      // Remapping Objek

      this.props.initialize(res.data);
      // this.npwpValidation(null, res.data.bagA.npwp)
    }
  }

  async calculate(e, v) {
    if(this.typingObj) clearTimeout(this.typingObj);

    this.typingObj = setTimeout(async ()=> {
      if(v) {
        var terbilangRes = '';
        try {
          terbilangRes = await terbilangAsync15(v);
        } catch (error) {}

        this.props.change('terbilang', terbilangRes);
      }
    }, this.typingTimeout);
  }

  formView() {
    var formData = {bagA: {}};
    if(this.props.formData && this.props.formData.values) formData = this.props.formData.values
    return (
      <div>
        <Card>
          <div className="md-grid">
            <Field
              label='NTPN'
              name='ntpn'
              className="md-cell md-cell--6"
              component={Textfield}
              mask="_"
              length={16}
              maxLength={16}
              maskFormat="################"
              validate={validation.required}
            />
            <Field
              label='Tanggal'
              name='tgl'
              className="md-cell md-cell--6"
              component={Datepicker}
              validate={validation.required}
            />
            <Field
              label='Referensi'
              name='referensi'
              id="BP21FForm-referensi"
              className="md-cell md-cell--12"
              component={Textfield}
            />
          </div>
        </Card>

        <br/>

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='B. WAJIB PAJAK' />
          <Divider/>
          <div className="md-grid">
            <Field
              label     = 'NPWP'
              name      = 'npwpPemotong'
              className = "md-cell md-cell--6"
              disabled
              component  = {TextfieldMask}
              maskFormat = "##.###.###.#-###-###"
              mask       = "_"
              validate   = {validation.required}
            />
            <Field
              label     = 'Nama'
              name      = 'namaPemotong'
              className = "md-cell md-cell--6"
              disabled
              component = {Textfield}
              validate  = {validation.required}
            />

            <Field
              label     = 'Alamat'
              name      = 'alamatPemotong'
              className = "md-cell md-cell--12"
              disabled
              component = {Textfield}
              validate  = {validation.required}
            />
          </div>
        </Card>

        <br/>

        {this.state.showCalculateProgress && <LinearProgress id='lin_pro' style={{margin: 0}} />}
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='B. DETAIL' />

          <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
            <TableHeader>
              <TableRow>
                <TableColumn>No.</TableColumn>
                <TableColumn className='md-text-right'>Jumlah Bruto Penjualan(Rp)</TableColumn>
                <TableColumn className='md-text-right'>PPH(Rp)</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableColumn>1.</TableColumn>
                <TableColumn>
                  <Field
                    name='bruto'
                    component={TextfieldMask}
                    className='md-text-right'
                    money={','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    name='pph'
                    component={TextfieldMask}
                    className='md-text-right'
                    money={','}
                    onChange  = {(e, v) => this.calculate(e, v)}
                  />
                </TableColumn>
              </TableRow>
            </TableBody>
          </DataTable>

          <Divider/>
          <div className="md-grid">
            <Field
              label     = 'Terbilang'
              name      = 'terbilang'
              className='md-cell md-cell--12'
              component = {Textfield}
              disabled
            />
          </div>
        </Card>

        <br />
      </div>
    )
  }

}
