import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {connect} from 'react-redux';

import LogAppService from './LogApp.service';
import ListView from '../../../../components/entity/listView';
import {DataTable, TableBody, TableColumn, TableHeader, TableRow} from "react-md";
import download from "downloadjs";
import izitoast from "izitoast";

@reduxForm({form: 'LOG_APP', destroyOnUnmount: true})
@connect((state) => ({
  ...LogAppService.stateConnectSetting()(state)
}), LogAppService.actionConnectSetting())
export default class LogApp extends ListView {
  service = LogAppService
  // viewType=2

  constructor(props) {
    super(props);

    this.state = {
      showTableSetting: true,
      showDialogConfirmDeleteSelected: false,
      showForm: false,
      formData: {},
      data: [],
      onProgress: false,
      isError: false,
      errorMessage: ''
    }
  }

  _barActions = [
  ]

  async download(item) {
    var report = await LogAppService.api.download(item.filename)
    download(report.data, item.filename);
  }

  _tableActions = [
    {
      label:"Download",
      iconClassName:"mdi mdi-download",
      onClick: (item) => this.download(item)
    },
  ]

  columns = [
    {isSortable: true, label: "word.filename",  value: "filename", isDefaultSort:true, show:true, isSearchable:false},
  ]

  defaultColumns = [
  ]

}
