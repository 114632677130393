/**
 * Created by dwiargo on 12/5/17.
 */

import * as types from '../actions/actionTypes';

const initialState = {
  appInfo:{
    name:'Pajakku',
    version:'0.0.0'
  },
  appConf:{},
  appbarLeftActionToggled:true,
  appbarLeftActionVisible:true,
  currentPath:'/',
  localeCode:'',
  localeCodes:[],
  timestamp: new Date(),
  supportUrl:{}
};

export default (state = initialState, action = {}) => {
  switch (action.type){
    case types.GLOBAL_SET_PROPERTIES:
      return Object.assign({}, state, action.properties);

    case types.GLOBAL_TOGGLE_PROPERTY:{
      let obj = {};
      obj[action.key] = !state[action.key];
      return Object.assign({}, state, obj);
    }

    default:
      return state;
  }
}