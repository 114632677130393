import React from 'react';
import { SelectionControlGroup, SelectionControl, Checkbox, Switch } from 'react-md';

export default class RadioCustom extends React.Component {
    changeValue(value){
        this.props.input.onChange(value)
    }
    render() {
        let { input, meta: { touched, error }, ...others } = this.props;
        let array = []
        let defaultValue = null
        let name = ""
        let id = ""
        let controlStyle = null
        if(this.props.controls){
            array = this.props.controls
        }
        if(this.props.name){
            id = this.props.name + Math.random().toString(36).substring(7)
            name = this.props.name
        }
        if(this.props.input.value){
            defaultValue = this.props.input.value
        }
        if(this.props.controlStyle){
            controlStyle = this.props.controlStyle
        }
        // controls = [{label: value:}]
        return <SelectionControlGroup
            controlStyle={controlStyle}
            id={id}
            name={name}
            type="radio"
            defaultValue={defaultValue}
            controls={array}
            onChange={this.changeValue.bind(this)} />
    }
}
