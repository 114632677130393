import React from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  Tab,
  Tabs,
  SelectionControlGroup,
  Button
} from 'react-md';
import download from 'downloadjs';
import uuid from 'uuid';
import iziToast from 'izitoast';

import FormView from '../../../../../../../components/entity/form.view';
import IndukEbupotService from './induk.service';
import {validation, Textfield, Searchfield, Switch, Checkbox, Datepicker, TextfieldMask} from '../../../../../../../components/form';
import columnService from '../../../../../../../services/column.service';
import terbilangService from '../../../../../../../services/terbilang.service';
import Espt23Service from './../../SPT23/SPT23.service'

@reduxForm({form: 'IndukEbupot', destroyOnUnmount: true, initialValues: {jasaLains: []}})
@connect((state) => ({
  spt         : state.authEppt.currentSPT,
  formData    : getFormValues('IndukEbupot')(state)
}))
export default class Spt23IndukForm extends FormView {
  service  = IndukEbupotService
  viewType = 2;

  constructor(props){
    super(props);
    this.state = {
      ...this.state,
      cetak: false,
    }
  }

  async initData() {
    if(this.props.match.params.id == 'new') {
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.getInduk(this.props.match.params.sptId);
      res.data.spt = this.props.spt.data
      this.props.initialize(res.data);
    }
  }

  _barActions = [
    {
      label:'word.reportInduk',
      iconClassName:'mdi mdi-file-pdf',
      onClick:() => this.handleCetakDaftarBp(),
    },
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => this.initData()
    },
  ]

  async handleCetakDaftarBp(){
    this.setState({ cetak: true })
    try {
      var report = await Espt23Service.api.cetakSptInduk(this.props.match.params.sptId)
      window.open(report.data.url)
      this.setState({cetak: false})
    } catch (e) {
      console.log(e, "error")
      this.setState({cetak: false})
    }
  }

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;

    return (
      <div>
        <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 11}}>Back</Button>

        <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Save</Button>
      </div>
    )
  }

  redirectTo = false

  formView() {
    return (
      <div>
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='Identitas SPT' />
          <Divider/>

          <div className='md-grid'>
            <div className="md-cell md-cell--6">
                <Field
                  label     = 'Masa Pajak'
                  name      = 'spt.month'
                  component = {Textfield}
                  disabled  = {true}
                />
            </div>
            {/* <div className="md-cell md-cell--6">
                <Field
                  label     = 'Pembetulan'
                  name      = 'spt.pembetulan'
                  component = {Textfield}
                  disabled  = {true}
                />
            </div> */}
            <div className="md-cell md-cell--6">
                <Field
                  label     = 'Tahun'
                  name      = 'spt.year'
                  component = {Textfield}
                  disabled  = {true}
                />
            </div>
          </div>
        </Card>
        <br />
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='Bagian B. Identitas Pemotong' />
          <Divider/>

          <div className='md-grid'>
            <div className="md-cell md-cell--6">
                <Field
                  label     = 'Npwp Pemotong'
                  name      = 'spt.npwp'
                  component = {Textfield}
                  disabled  = {true}
                />
            </div>
            <div className="md-cell md-cell--6">
                <Field
                  label     = 'Nama Pemotong'
                  name      = 'spt.name'
                  component = {Textfield}
                  disabled  = {true}
                />
            </div>
            <div className="md-cell md-cell--12">
                <Field
                  label     = 'Alamat Pemotong'
                  name      = 'spt.address'
                  component = {Textfield}
                  disabled  = {true}
                />
            </div>
          </div>
        </Card>
        <br />
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='Bagian B. Objek Pajak' />
          <Divider/>

          <div className='md-grid'>
            <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
              <TableHeader>
                <TableRow>
                  <TableColumn>No.</TableColumn>
                  <TableColumn>Uraian</TableColumn>
                  <TableColumn>Kode Objek Pajak</TableColumn>
                  <TableColumn>Jumlah Penghasilan Bruto (Rp)</TableColumn>
                  <TableColumn>Jumlah PPh yang Dipotong/DTP (Rp)</TableColumn>
                </TableRow>
              </TableHeader>
              <TableHeader>
                <TableRow>
                  <TableColumn></TableColumn>
                  <TableColumn>I. PPh Pasal 23</TableColumn>
                  <TableColumn></TableColumn>
                  <TableColumn></TableColumn>
                  <TableColumn></TableColumn>
                </TableRow>
              </TableHeader>
              <TableRow>
                <TableColumn>1.</TableColumn>
                <TableColumn>Dividen</TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b1Kode'
                    component = {Textfield}
                    disabled  = {true}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b1Bruto'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b1Pph'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>2.</TableColumn>
                <TableColumn>Bunga</TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b2Kode'
                    component = {Textfield}
                    disabled  = {true}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b2Bruto'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b2Pph'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>3.</TableColumn>
                <TableColumn>Royalti</TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b3Kode'
                    component = {Textfield}
                    disabled  = {true}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b3Bruto'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b3Pph'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>4.</TableColumn>
                <TableColumn>Hadiah, Penghargaan, Bonus dan Lainnya</TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b4Kode'
                    component = {Textfield}
                    disabled  = {true}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b4Bruto'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b4Pph'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>5.</TableColumn>
                <TableColumn>Sewa dan Penghasilan Lain Sehubungan dengan Penggunaan Harta</TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b5Kode'
                    component = {Textfield}
                    disabled  = {true}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b5Bruto'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b5Pph'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>6.</TableColumn>
                <TableColumn>Imbalan Sehubungan Dengan</TableColumn>
                <TableColumn>
                  {/* <Field
                    className = 'md-text-right'
                    name      = 'b6Kode'
                    component = {Textfield}
                    disabled  = {true}
                  /> */}
                </TableColumn>
                <TableColumn>
                  {/* <Field
                    className = 'md-text-right'
                    name      = 'b6Bruto'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  /> */}
                </TableColumn>
                <TableColumn>
                  {/* <Field
                    className = 'md-text-right'
                    name      = 'b6Pph'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  /> */}
                </TableColumn>
              </TableRow>
              <TableHeader>
                <TableRow>
                  <TableColumn></TableColumn>
                  <TableColumn>6a Jasa Teknik</TableColumn>
                  <TableColumn>
                    <Field
                      className = 'md-text-right'
                      name      = 'b6aKode'
                      component = {Textfield}
                      disabled  = {true}
                    />
                  </TableColumn>
                  <TableColumn>
                    <Field
                      className = 'md-text-right'
                      name      = 'b6aBruto'
                      component = {TextfieldMask}
                      money     = {true}
                      disabled  = {false}
                    />
                  </TableColumn>
                  <TableColumn>
                    <Field
                      className = 'md-text-right'
                      name      = 'b6aPph'
                      component = {TextfieldMask}
                      money     = {true}
                      disabled  = {false}
                    />
                  </TableColumn>
                </TableRow>
              </TableHeader>
              <TableHeader>
                <TableRow>
                  <TableColumn></TableColumn>
                  <TableColumn>6b Jasa Manajemen</TableColumn>
                  <TableColumn>
                    <Field
                      className = 'md-text-right'
                      name      = 'b6bKode'
                      component = {Textfield}
                      disabled  = {true}
                    />
                  </TableColumn>
                  <TableColumn>
                    <Field
                      className = 'md-text-right'
                      name      = 'b6bBruto'
                      component = {TextfieldMask}
                      money     = {true}
                      disabled  = {false}
                    />
                  </TableColumn>
                  <TableColumn>
                    <Field
                      className = 'md-text-right'
                      name      = 'b6bPph'
                      component = {TextfieldMask}
                      money     = {true}
                      disabled  = {false}
                    />
                  </TableColumn>
                </TableRow>
              </TableHeader>
              <TableHeader>
                <TableRow>
                  <TableColumn></TableColumn>
                  <TableColumn>6c Jasa Konsultan</TableColumn>
                  <TableColumn>
                    <Field
                      className = 'md-text-right'
                      name      = 'b6cKode'
                      component = {Textfield}
                      disabled  = {true}
                    />
                  </TableColumn>
                  <TableColumn>
                    <Field
                      className = 'md-text-right'
                      name      = 'b6cBruto'
                      component = {TextfieldMask}
                      money     = {true}
                      disabled  = {false}
                    />
                  </TableColumn>
                  <TableColumn>
                    <Field
                      className = 'md-text-right'
                      name      = 'b6cPph'
                      component = {TextfieldMask}
                      money     = {true}
                      disabled  = {false}
                    />
                  </TableColumn>
                </TableRow>
              </TableHeader>
              <TableHeader>
                <TableRow>
                  <TableColumn></TableColumn>
                  <TableColumn>6d Jasa Lain</TableColumn>
                  <TableColumn></TableColumn>
                  <TableColumn>
                    <Field
                      className = 'md-text-right'
                      name      = 'b6dBruto'
                      component = {TextfieldMask}
                      money     = {true}
                      disabled  = {false}
                    />
                  </TableColumn>
                  <TableColumn>
                    <Field
                      className = 'md-text-right'
                      name      = 'b6dPph'
                      component = {TextfieldMask}
                      money     = {true}
                      disabled  = {false}
                    />
                  </TableColumn>
                </TableRow>
              </TableHeader>
              <TableRow>
                <TableColumn>7.</TableColumn>
                <TableColumn>Jumlah Pasal PPh 23 ( Jumlah Angka 1 S.D 6 )</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b7Bruto'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b7Pph'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>8.</TableColumn>
                <TableColumn>Jumlah Pasal PPh 23 Yang Kurang Disetor Setelah Dikurangi PPh Pasal 23 Ditanggung Pemerintah</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b8Bruto'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b8Pph'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>9.</TableColumn>
                <TableColumn>Jumlah Pasal PPh 23 Yang Kurang Disetor Sehubungan Dengan Penambahan dan Pembetulan bukti pemotongan</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b9Bruto'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b9Pph'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
              </TableRow>
              <TableHeader>
                <TableRow>
                  <TableColumn></TableColumn>
                  <TableColumn>II. PPh Pasal 26</TableColumn>
                  <TableColumn></TableColumn>
                  <TableColumn></TableColumn>
                  <TableColumn></TableColumn>
                </TableRow>
              </TableHeader>
              <TableRow>
                <TableColumn>10.</TableColumn>
                <TableColumn>Dividen</TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b10Kode'
                    component = {Textfield}
                    disabled  = {true}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b10Bruto'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b10Pph'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>11.</TableColumn>
                <TableColumn>Bunga</TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b11Kode'
                    component = {Textfield}
                    disabled  = {true}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b11Bruto'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b11Pph'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>12.</TableColumn>
                <TableColumn>Royalti</TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b12Kode'
                    component = {Textfield}
                    disabled  = {true}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b12Bruto'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b12Pph'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>13.</TableColumn>
                <TableColumn>Sewa dan Penghasilan Sehubungan Dengan Penghasilan Harta</TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b13Kode'
                    component = {Textfield}
                    disabled  = {true}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b13Bruto'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b13Pph'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>14.</TableColumn>
                <TableColumn>Imbalan Sehubungan Dengan Penghasilan Jasa Pekerjaan dan Kegiatan</TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b14Kode'
                    component = {Textfield}
                    disabled  = {true}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b14Bruto'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b14Pph'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>15.</TableColumn>
                <TableColumn>Hadiah dan Penghargaan</TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b15Kode'
                    component = {Textfield}
                    disabled  = {true}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b15Bruto'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b15Pph'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>16.</TableColumn>
                <TableColumn>Pensiunan dan Pembayaran Berkala Lainnya</TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b16Kode'
                    component = {Textfield}
                    disabled  = {true}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b16Bruto'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b16Pph'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>17.</TableColumn>
                <TableColumn>Premi Swap dan Transaksi Lindung Lainnya</TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b17Kode'
                    component = {Textfield}
                    disabled  = {true}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b17Bruto'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b17Pph'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>18.</TableColumn>
                <TableColumn>Keuntungan Karena Pembebasan Hutang</TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b18Kode'
                    component = {Textfield}
                    disabled  = {true}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b18Bruto'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b18Pph'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>19.</TableColumn>
                <TableColumn>Penghasilan Dari Penjualan Atau Pengalihan Harta Di Indonesia</TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b19Kode'
                    component = {Textfield}
                    disabled  = {true}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b19Bruto'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b19Pph'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>20.</TableColumn>
                <TableColumn>Premi Asuransi / Reasuransi</TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b20Kode'
                    component = {Textfield}
                    disabled  = {true}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b20Bruto'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b20Pph'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>21.</TableColumn>
                <TableColumn>Penghasilan dari Penjualan atau Pengalihan Saham</TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b21Kode'
                    component = {Textfield}
                    disabled  = {true}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b21Bruto'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b21Pph'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>22.</TableColumn>
                <TableColumn>Penghasilan Kena Pajak BUT Setelah Pajak</TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b22Kode'
                    component = {Textfield}
                    disabled  = {true}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b22Bruto'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b22Pph'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>23.</TableColumn>
                <TableColumn>Jumlah PPh Pasal 26 ( Jumlah Angka 10 S.D 22 )</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b23Bruto'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b23Pph'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>24.</TableColumn>
                <TableColumn>Jumlah PPh Pasal 26 Yang Kurang Disetor Setelah Dikurangi Pasal PPh Pasal 26 Ditanggung Pemerintah (DTP)</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b24Bruto'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b24Pph'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>25.</TableColumn>
                <TableColumn>Jumlah PPh Pasal 26 Yang Kurang Disetor Sehubungan dengan Penambahan dan Pembetulan</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b25Bruto'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'b25Pph'
                    component = {TextfieldMask}
                    money     = {true}
                    disabled  = {false}
                  />
                </TableColumn>
              </TableRow>
            </DataTable>
          </div>
        </Card>
        <br />
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='BAGIAN C. LAMPIRAN' />
          <Divider/>

          <div className='md-grid'>
            <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
              <TableRow>
                <TableColumn>C.1</TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'c1Count'
                    component = {Textfield}
                    disabled  = {true}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'c1Check'
                    component = {Checkbox}
                    disabled  = {true}
                  />
                </TableColumn>
                <TableColumn>
                  Bukti Pemotongan PPh Pasal 23 dan/atau Pasal 26
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>C.2</TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'c2Count'
                    component = {Textfield}
                    disabled  = {true}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'c2Check'
                    component = {Checkbox}
                    disabled  = {true}
                  />
                </TableColumn>
                <TableColumn>
                  Surat Setoran Pajak/Bukti Penerimaan Negara
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>C.3</TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'c3Count'
                    component = {Textfield}
                    disabled  = {true}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'c3Check'
                    component = {Checkbox}
                    disabled  = {true}
                  />
                </TableColumn>
                <TableColumn>
                  Bukti Pemindahbukuan
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>C.4</TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'c4Count'
                    component = {Textfield}
                    disabled  = {true}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'c4Check'
                    component = {Checkbox}
                    disabled  = {true}
                  />
                </TableColumn>
                <TableColumn>
                  Surat Kuasa Khusus
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>C.5</TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'c5Count'
                    component = {Textfield}
                    disabled  = {true}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'c5Check'
                    component = {Checkbox}
                    disabled  = {true}
                  />
                </TableColumn>
                <TableColumn>
                  Fotokopi Surat Keterangan Bebas Yang Telah Dilegalisasi
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>C.6</TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'c6Count'
                    component = {Textfield}
                    disabled  = {true}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'c6Check'
                    component = {Checkbox}
                    disabled  = {true}
                  />
                </TableColumn>
                <TableColumn>
                  Fotokopi Surat Keterangan Domisili
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>C.7</TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'c7Count'
                    component = {Textfield}
                    disabled  = {true}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    className = 'md-text-right'
                    name      = 'c7Check'
                    component = {Checkbox}
                  />
                </TableColumn>
                <TableColumn>
                  Fotokopi SPT Masa PPh Pasal 23 dan/atau Pasal 26 yang termasuk lampiran dan Bukti Penerimaan Surat
                </TableColumn>
              </TableRow>
            </DataTable>
          </div>
        </Card>
      </div>
    )
  }
}
