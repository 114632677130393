import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Field, reduxForm } from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../../../../components/form';

import ListView from '../../../../../../components/entity/listView';
import ColumnService from '../../../../../../services/column.service';
// import ImportService from './../Import/Import.service';
import axios from 'axios';
import izitoast from 'izitoast';
import FormDialog from './ImportLK15.dialog';
import ImportLK15Service from './ImportLK15.service';
import download from "downloadjs";
import ListViewImport from '../../../../../../components/entity/ListViewImport';

@reduxForm({form: 'ImportLK15Form', destroyOnUnmount: true})
@connect(ImportLK15Service.stateConnectSetting(), ImportLK15Service.actionConnectSetting())
export default class ImportLK15View extends ListViewImport {
  service=ImportLK15Service
  FormDialog=FormDialog
  pasal='pasal15'
  category='LT'
  importPath='/pasal15/upload/csv/bp'
  showAutomatisTab=false
  // viewType=2

  beforeFetch(params) {
    params["category.equals"] = "LT"
  }

  async handleDownloadTemplate() {
    var report = await ImportLK15Service.api.downloadTemplate('LT')
    window.open(report.data.url)
  }

}
