import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Button, Chip} from 'react-md';

//import FormDialog from './Organization.dialog.view';
import SPT4SignerService from '../SPT4Signer/SPT4Signer.service'
import ListView from '../../../../../components/entity/listView';
import SPT4SignerDialogView from './SPT4Signer.dialog';
import moment from "moment";

@connect(SPT4SignerService.stateConnectSetting(), SPT4SignerService.actionConnectSetting())
export default class SPT4SignerView extends ListView {
  service=SPT4SignerService
  FormDialog=SPT4SignerDialogView

  beforeFetch(param) {
    if(this.props.organizationId) {
      if(this.props.organizationId != 'new') {
        param['organizationId.equals'] = this.props.organizationId;
      }
    }
  }

  header() {
    return this.commandBar()
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='NPWP Organisasi'
          name='npwpProfile__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Nama TTD BP'
          name='namaPenandaTanganBp__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Jabatan TTD BP'
          name='jabatanPenandaTanganBp__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NPWP TTD Induk'
          name='npwpPenandaTanganSpt__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Nama TTD Induk'
          name='namaPenandaTanganSpt__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Berlaku Mulai'
          name='activeFrom__equals'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Berlaku Sampai'
          name='activeTo__equals'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
      </this.SearchContainer>
    )
  }

  render() {
    return (
      <this.viewContainer>

        <div className="flex mpk-layout" style={{ padding: 16 }}>
          {this.tableView()}
          {this.tableSetting()}

          <this.FormDialog
            organizationId={this.props.organizationId}
            companyId={this.props.companyId}
            height={400}
            visible={this.state.showForm}
            add={this.props.tableActions.addDataItem}
            formData={this.state.formData}
            onSuccess={()=> this.fetchData()}
            onClose={() => this.setState({showForm:false})}
          />

          {this.dialogConfirm()}
        </div>
      </this.viewContainer>
    )
  }

  defaultColumns = [
    /*{isSortable:true, label: "word.id",  value: "id", show:false, isSearchable:true, isDefaultSort: false},*/
    {isSortable:true, label: "word.createdUsername",  value: "createdUsername", show:true, isSearchable:true},
    {isSortable:true, label: "word.createdBy",  value: "createdBy", show:true, isSearchable:true},
    {isSortable:true, label: "word.createdDate", value: "createdDate", show:true, isSearchable:true, type:"date", isDefaultSort: true},
    {isSortable:true, label: "word.modifiedUsername",  value: "modifiedUsername", show:true, isSearchable:true},
    {isSortable:true, label: "word.lastModifiedBy",  value: "lastModifiedBy", show:true, isSearchable:true},
    {isSortable:true, label: "word.lastModifiedDate", value: (d)=> {
        if(d.lastModifiedDate) {
          return <div className='mpk-font-size-S'>{moment(d.lastModifiedDate).format('lll')}</div>
        } else {
          return null;
        }
      }, show:true, isSearchable:true, type:"func", searchField: 'lastModifiedDate', isDefaultSort: true}
  ]

  columns=[
    {isSortable: true, label: "word.npwpProfile",  value: "npwpProfile", isDefaultSort:false, show:true, isSearchable:true},
    {isSortable: true, label: "entities.SPT21Signer.active", type: 'boolean', value: "active",  show:true, isSearchable:true},
    {isSortable: true, label: "entities.SPT21Signer.activeFrom",  value: "activeFrom", show:true, isSearchable:true},
    {isSortable: true, label: "entities.SPT21Signer.activeTo",  value: "activeTo", show:true, isSearchable:true},
    {isSortable: true, label: "entities.SPT21Signer.npwpPenandaTanganBp", value: "npwpProfile",  show:true, isSearchable:true},
    {isSortable: true, label: "entities.SPT21Signer.namaPenandaTanganBp",  value: "namaPenandaTanganBp", show:true, isSearchable:true, className: "mpk-font-size-S"},
    {isSortable: true, label: "entities.SPT21Signer.jabatanPenandaTanganBp",  value: "jabatanPenandaTanganBp", show:true, isSearchable:true},
    {isSortable: true, label: "entities.SPT21Signer.npwpPenandaTanganSptInduk",  value: "npwpPenandaTanganSpt", show:true, isSearchable:true},
    {isSortable: true, label: "entities.SPT21Signer.namaPenandaTanganSptInduk",  value: "namaPenandaTanganSpt", show:true, isSearchable:true},
    {isSortable: true, label: "entities.SPT21Signer.imagePenandaTanganBp",  value: "imagePenandaTanganBp", show:true, isSearchable:true},
    {isSortable: true, label: "entities.SPT21Signer.imagePenandaTanganInduk",  value: "imagePenandaTanganInduk", show:true, isSearchable:true},
  ]
}
