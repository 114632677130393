import React, { Component } from 'react';
import { connect } from 'react-redux';

import FormDialog from './Master21BiayaJabatanGlobal.dialog.view';
import Master21BiayaJabatanGlobalService from './Master21BiayaJabatanGlobal.service';
import ListView from '../../../../../components/entity/listView';
import ColumnService from '../../../../../services/column.service';

@connect(Master21BiayaJabatanGlobalService.stateConnectSetting(), Master21BiayaJabatanGlobalService.actionConnectSetting())
export default class Master21BiayaJabatanGlobalView extends ListView {
  service=Master21BiayaJabatanGlobalService
  FormDialog=FormDialog

  columns=[
    {isSortable: true, label: "word.id", searchField: "id",  value: function(d){ return d.id }, type: "func",  show:false, isSearchable:true},
    {isSortable: true, label: "word.start", searchField: "startDate",  value: function(d){ return d.startDate }, type: "func",  show:true, isSearchable:true},
    {isSortable: true, label: "word.end", searchField: "endDate", value: function(d){ return d.endDate }, type: "func",  show:true, isSearchable:true},
    {isSortable: true, label: "word.tarif", searchField: "rates", value: function(d){ return d.rates }, type: "func",  show:true, isSearchable:true},
    {isSortable: true, label: "word.jumlah", searchField: "total", value: function(d){ return ColumnService.money(d.total) }, type: "func",  show:true, isSearchable:true}
  ]
}
