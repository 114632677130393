import ApiService from '../../../../../../services/api.service';

class ImportPBK22Service extends ApiService {
  name= 'ImportPBK22';
  path= 'pasal22/log/csv';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  // apiMockup= {
  //   find: [{
  //     id: 1,
  //     status: 'Process',
  //     npwp: '023945840000',
  //     name: 'PT. A',
  //     pasal: '23/26',
  //     year: '2018',
  //     organization: {name: 'PT. A', npwp: '123123'},
  //     month: 'Januari',
  //     pembetulan: '0'
  //   }],
  //   findOne: {data: {
  //     id: 1,
  //     status: 'Process',
  //     npwp: '023945840000',
  //     name: 'PT. A',
  //     pasal: '23/26',
  //     year: '2018',
  //     organization: {name: 'PT. A', npwp: '123123'},
  //     month: 'Januari',
  //     pembetulan: '0'
  //   }}
  // }


  api= {
    validasi: async (fileId, page) => {
      if(page){
        page = page
      } else {
        page = 0
      }
      var res = await this.http.get(this.apiLocation+'/pasal22/validation-csv', {
        params: {
          fileId : fileId,
          page: page,
          size: 100
        }
      })

      return res.data;
    },
    downloadTemplate: async (category)=> {
      var res = await this.http.get(`${this.apiLocation}/pasal22/download/csv/template`, {
        params: {
          category: category
        }
      })
      return res;
    }
  }
}


export default new ImportPBK22Service();
