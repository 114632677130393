import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Field, reduxForm } from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';
import ListView from '../../../../../../components/entity/listView';
import axios from 'axios';
import izitoast from 'izitoast';
import ExportCsv4a2LogService from './ExportCsv4a2Log.service';

@reduxForm({form: 'FILE_LAPOR_4a2', destroyOnUnmount: true})
@connect((state)=> ({
  ...ExportCsv4a2LogService.stateConnectSetting()(state),
  spt: state.authEppt.currentSPT,
  user: state.authEppt.user
}), ExportCsv4a2LogService.actionConnectSetting())
export default class ExportCsv21Log extends ListView {
  service=ExportCsv4a2LogService
  // viewType=2

  constructor(props){
    super(props);
    this.state = {
      showTableSetting:true,
      showDialogConfirmDeleteSelected:false,
      showForm:false,
      formData: {},
      data: [],
      onProgress: false,
      isError: false,
      errorMessage: ''
    }
  }

  beforeFetch(params) {
    // params["category.equals"] = "BP_21_A1"
    if(this.props.match.params.companyId) params["companyId.equals"] = this.props.match.params.companyId;
    if(this.props.user.company && this.props.user.company.id) params["companyId.equals"] = this.props.user.company.id;
  }

  _barActions = [
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => this.fetchData()
    }
  ]

  _tableActions = []
  columns=[
    {isSortable: true, label: "word.sptId",  value: "sptId", isDefaultSort:false, show:true, isSearchable:true},
    {isSortable: true, label: "word.startDate",  value: "startDate",  type: "date", show:true, isSearchable:true},
    {isSortable: true, label: "word.endDate",  value: "endDate", type: "date", isDefaultSort:false, show:true, isSearchable:true},
    {isSortable: true, label: "word.status",  value: "status", isDefaultSort:false, show:true, isSearchable:false},
    {isSortable: true, label: "word.step",  value: "step", isDefaultSort:false, show:true, isSearchable:true, type: "number"},
    {isSortable: true, label: "word.count",  value: "count", isDefaultSort:false, show:true, isSearchable:true, type: "number"},
    {isSortable: true, label: "word.total",  value: "total", isDefaultSort:false, show:true, isSearchable:true, type: "number"},
  ]

}
