import ApiService from '../../../../../services/api.service'
import moment from 'moment'

class ApplicationEmailService extends ApiService {
  name= 'ApplicationEmail'
  path= 'ebupot/mail'
  repoType='QueryDsl'
  advancedSearch=true

  constructor(){
    super()
    this.init()
    this.initApi()
  }

  columns = [
    {label: "word.id", searchField: "id", value: "id", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
    {label: "word.host", searchField: "host", value: "host", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.port", searchField: "port", value: "port", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.active", searchField: "active", value: "active", type: 'boolean', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.createdBy", searchField: "createdBy", value: "createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.createdDate", searchField: "createdDate", value: (d)=> {
      if(d){
        return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
      } else {
        return "-"
      }
    }, type: 'func', isDefaultSort:true, isSortable:true, show:true, isSearchable:true},
    {label: "word.lastModifiedBy", searchField: "lastModifiedBy", value: "lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.lastModifiedDate", searchField: "lastModifiedDate", value: (d)=> {
      if(d.lastModifiedDate){
        return moment(d.lastModifiedDate).format('DD/MM/YYYY HH:MM')
      } else {
        return "-"
      }
    }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
  ]

  api = {
    getOne: async (item)=> {
      let res = await this.http.get(`${this.apiLocation}/${this.path}/${item}/application`);
      return res
    },
    getApp: async (item)=> {
      let res = await this.http.get(`${this.apiLocation}/ebupot/application`);
      return res
    }
    // save: async (data, dispatch=()=>{})=> {
    //   let res = await this.http.post(`${this.apiLocation}/${this.path}/${data.userId}/user`, data);
    //   dispatch({
    //     type: `${this.name.toUpperCase()}_SAVE`,
    //     data: res.data
    //   })

    //   return res;
    // }
  }

}

export default new ApplicationEmailService();
