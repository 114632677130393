import ApiService from '../../../../../services/api.service';

class ALLBP21DaftarBiayaService extends ApiService {
  name= 'ALL_BP_21_DAFTAR_BIAYA';
  path= 'spt21/db/organization';
  constructor() {
    super();
    this.init()
  }
}

export default new ALLBP21DaftarBiayaService();
