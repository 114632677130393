/**
 * Created by dwiargo on 2/22/18.
 */

const allHosts = {
  iam:{
    host:'',
    credentials:{
      client_id:'',
      redirect_uri:'',
      state:''
    }
  }
};

const hosts = {
  setHost:(hostsObject = {}) => {
    let keys = Object.keys(hostsObject);
    for(var i = 0 ; i < keys.length ; i++){
      let key = keys[i];
      allHosts[key] = hostsObject[key]
    }
  },
  getAll:() => {
    return allHosts;
  }
}

export default hosts;