import ApiService from '../../../services/api.service';

class ImportEbillingService extends ApiService {
  name= 'ImportEbilling';
  path= 'ebilling/import-logs/page';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    getData: async (id, param, pasal)=> {
      var res;
      res = await this.http.get(`${this.apiLocation}/ebilling/import-log-validations/${id}`, {params: param})
      return res;
    },
    downloadTemplate: async (category)=> {
      var res = await this.http.get(`${this.apiLocation}/ebilling/import/download-template/${category}`)
      return res;
    },
  }
}

export default new ImportEbillingService();
