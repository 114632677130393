import ApiService from '../../../../../services/api.service';

class SPT23IndukService extends ApiService {
  name = 'SPT23Induk';
  path = 'pasal23/induk';
  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api={
    update: async (data) => {
      var res = await this.http.put(`${this.apiLocation}/${this.path}`, data, {
        params: {
          blockBunifikasiMonth: data.blockBunifikasiMonth,
          blockBunifikasiYear: data.blockBunifikasiYear
        }
      })
      return res;
    },
    getSigner: async (npwpProfile, date)=> {
      var res = await this.http.get(`${this.apiLocation}/pasal23/signer/${npwpProfile}/active`, {params: { date }})
      return res.data;
    },
    getJasaLin: async(sptId, sptIndukId) => {
      return await this.http.get(`${this.apiLocation}/pasal23/induk/${sptId}/others`, {
        params: {
          page: 0
        }
      })
    },
    getBySptId: async(sptId)=> {
      return await this.http.get(`${this.apiLocation}/pasal23/induk/${sptId}/spt`)
    },
    calculate: async(sptId, d)=> {
      return await this.http.get(`${this.apiLocation}/pasal23/spt23/induk/calculate/${sptId}`, d);
    },
    cetak: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/pasal23/induk/${sptId}/spt/report`)
      return res;
    },
    cetakBulk: async (ids) => {
      var res = await this.http.post(`${this.apiLocation}/pasal23/spt23/induk/report/bulk`, ids, {
        responseType: 'blob'
      })

      return res;
    },
  }
}


export default new SPT23IndukService();
