import ApiService from '../../../../../services/api.service';

class OrganizationUserAccessService extends ApiService {
  name= 'organizationUserAccess';
  path= 'organization/user/access';

  constructor() {
    super()
    this.init();
    this.initApi()
  }

  api={
    getAccess: async (id)=> {
      let res = await this.http.get(`${this.apiLocation}/${this.path}/${id}`);
      return res;
    },

    updateBulk: async (orgUserId, data)=> {
      let res = await this.http.post(`${this.apiLocation}/organization/${orgUserId}/add`, data);
      return res;
    }
  }

}


export default new OrganizationUserAccessService();
