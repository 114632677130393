import ApiService from '../../../../../services/api.service'
import http from '../../../../../services/http.service'
import moment from 'moment'
import React from 'react'
import { Chip } from 'react-md'
import Bupot26Service from './../Bupot26/Bupot26.service'

var columns = Object.assign([], Bupot26Service.columns)
columns.map((d)=> {
  if(d.value == "rev") d.isDefaultSort = true
})

class Bupot26HistoryService extends ApiService {
  name= 'Bupot26History';
  path= 'ebupot/bp26';
  advancedSearch=true

  columns=columns

  api= {

  }

  constructor() {
    super()
    this.init()
    this.initApi()
  }
}


export default new Bupot26HistoryService();
