import ApiService from '../../../../services/api.service';

class TemplateRoleService extends ApiService {
  name= 'templateRole';
  path= 'templateRole';

  constructor() {
    super();
    this.init()
  }

}


export default new TemplateRoleService();
