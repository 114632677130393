import ApiService from '../../../../../services/api.service';
import http from "../../../../../services/http.service";

class BP15Service extends ApiService {
  name = 'BP15';
  path = 'spt15/bp-15';

  api = {
    downloadCsv: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/csv/${sptId}`, {
        responseType: 'blob'
      })

      return res;
    },
    deleteBulk: async (sptId, ids)=> {
      let res = await http.post(`${this.apiLocation}/${this.path}/${sptId}/delete`, ids);

      return res;
    },
  }

  constructor() {
    super()
    this.init()
    this.initApi()
  }
}

export default new BP15Service();
