import ApiService from '../../../../../services/api.service';

class SPT4a2IndukService extends ApiService {
  name = 'SPT4a2Induk';
  path = 'pasal4/induk';
  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api={
    update: async (data) => {
      var res = await this.http.put(`${this.apiLocation}/${this.path}`, data, {
        params: {
          blockBunifikasiMonth: data.blockBunifikasiMonth,
          blockBunifikasiYear: data.blockBunifikasiYear
        }
      })
      return res;
    },
    getSigner: async (npwpProfile)=> {
      var res = await this.http.get(`${this.apiLocation}/pasal4/signer/${npwpProfile}/active`);
      return res.data;
    },
    getBySptId: async(sptId)=> {
      return await this.http.get(`${this.apiLocation}/pasal4/induk/${sptId}/spt`)
    },
    saveAndCallculate: async(sptId, d)=> {
      return await this.http.post(`${this.apiLocation}/pasal4/spt4a2/induk/${sptId}/calculate`, d);
    },
    cetak: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/pasal4/induk/${sptId}/spt/report`)

      return res;
    },
    cetakBulk: async (ids) => {
      var res = await this.http.post(`${this.apiLocation}/pasal4/spt4a2/induk/report/bulk`, ids, {
        responseType: 'blob'
      })

      return res;
    },
    calculate: async(sptId, d)=> {
      return await this.http.get(`${this.apiLocation}/pasal4/spt4a2/induk/calculate/${sptId}`, d);
    },
  }
}


export default new SPT4a2IndukService();
