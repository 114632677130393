import React, { Component } from 'react';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import { validation, Textfield, TextfieldMask, Datepicker, convert } from '../../../../../../components/form';
import Service from './ImportSSP15SetorSendiri.service';
import ImportDialogTemplateView from '../../../../../../components/entity/import.dialog.template.view2';
ImportDialogTemplateView

@reduxForm({form: 'ImportSSP15SetorSendiri-form', destroyOnUnmount: false})
export default class ImportSSP15SetorSendiriDialogView extends ImportDialogTemplateView {
  pasal="pasal15"
  service=Service
}
