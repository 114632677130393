import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button, SVGIcon } from 'react-md';
import FormDialog from './Master21FinalPesangonProgGlobal.dialog.view';
import Master21FinalPesangonProgService from './Master21FinalPesangonProgGlobal.service';
import ListView from '../../../../../components/entity/listView';
import ColumnService from '../../../../../services/column.service';
import counterpart from "counterpart";

@connect((state)=> ({
  ...Master21FinalPesangonProgService.stateConnectSetting()(state),
  spt: state.authEppt.currentSPT
}), Master21FinalPesangonProgService.actionConnectSetting())
export default class Master21FinalPesangonProgGlobalView extends ListView {
  service=Master21FinalPesangonProgService
  FormDialog=()=> <div/>

  addDialog=false
  editDialog=false

  fetchOption() {
    let pesangonId = this.props.match.params.pesangonId
    return {
      path: Master21FinalPesangonProgService.path+'/'+pesangonId+'/all'
    }
  }

  _barActions = [
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    },
  ]

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;
    return (
      <div>
        <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>{counterpart.translate('word.back')}</Button>
      </div>
    )
  }

  editItem = (item) => {
    this.props.history.push(`${this.props.location.pathname}/${item.id}`)
  }

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    }
  ]

  columns=[
    {isSortable: true, label: "word.id", searchField: "id", value: "id", show:false, isSearchable:true},
    {isSortable: true, label: "word.tarif", searchField: "rates", value: "rates", type: "number", show:true, isSearchable:true},
    {isSortable: true, label: "word.start", searchField: "early", value: "early", type: "number", show:true, isSearchable:true},
    {isSortable: true, label: "word.end", searchField: "ends", value: "ends", type: "number", show:true, isSearchable:true},
    {isSortable: true, label: "word.akhir", searchField: "isEnd", value: "isEnd", type: "boolean", show:true, isSearchable:true},
  ]
}
