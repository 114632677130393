import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {Field, getFormValues, reduxForm} from 'redux-form';
import {Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab, Chip} from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Multiselectv2, Switch as Switch2} from '../../../../components/form';

import LogExportPdfChecklistService from './LogExportPdfChecklist.service';
import ListView from '../../../../components/entity/listView';
import ColumnService from '../../../../services/column.service';
import axios from 'axios';
import izitoast from 'izitoast';
import download from "downloadjs";

@reduxForm({form: 'LogExportPdfChecklist', destroyOnUnmount: true})
@connect((state) => ({
  ...LogExportPdfChecklistService.stateConnectSetting()(state),
  formData: getFormValues('LogExportPdfChecklist')(state),
  user: state.auth.user,
  // EPPT CORE
  auth: state.auth.company
  // EPPT CORE
}), LogExportPdfChecklistService.actionConnectSetting())
export default class LogExportPdfChecklist extends ListView {
    service=LogExportPdfChecklistService
    minusPage=true

    static contextTypes = {
        showDialog: PropTypes.func.isRequired,
    };

    apiPath='findByPasal'

    beforeFetch(params) {
        params.pasal = this.props.pasal;
        try {
            if(params['status.in']){
                params['status.in'] == this.props.tableFilter2.status__in
            }
        } catch(e){}
        return params
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.pasal != prevProps.pasal){
            this.fetchData();
        }
    }

    deleteSelected = async (items, callback) => {
        try {
          let ids = []

          items.forEach(item => {
            ids.push(item.id)
          })

          var deleteAll = await this.service.api.deleteAllPerPasal(ids, this.props.pasal)
          await this.removeCheckbox();

          callback()
          await this.fetchData()
        } catch(e) {
          callback(e, e)
        }
    }

    async downloadSelected() {
      let selected = this.props.table.selected

      if (selected.length) {
        selected.forEach(data => {
          if (data.status === 'FINISH') {
            this.downloadItem(data)
          }
        })
      } else {
        izitoast.warning({
          title: 'Download',
          message: 'Hanya yang berstatus FINISH yang dapat didownload.'
        })
      }
    }

    async downloadItem(item){
        let res = await this.service.api.downloadPerPasal(item.id, this.props.pasal)

        let url = res.data.url
        let win = window.open(url, '_blank');
        win.focus();
    }

    deleteItem = async (item, callback) => {
        try {
          await this.service.api.deletePerPasal(item.id, this.props.pasal);
          callback()
          await this.fetchData()
        } catch(e) {
          callback(e, e)
        }
    }

    _tableActions = [
        {
          label:"Download",
          iconClassName:"mdi mdi-file",
          onClick: (item) => this.downloadItem(item)
        },
        {label:"divider", iconClassName:"-"},
        {
          label:"Delete",
          iconClassName:"mdi mdi-delete",
          onClick:(item, callback) => this.deleteItem(item, callback),
          confirmation:{
            title:"sentence.custom.deleteItem",
            message:"sentence.custom.deleteItemConfirmation"
          }
        }
    ]

    defaultSearchForm(){
        return (
            <div style={{ width: '100%' }}>
                <Field
                    label     = 'Kategori'
                    name      = 'category__contains'
                    className = "md-cell md-cell--12"
                    component = {Textfield}
                />
                <Field
                    label     = 'Jumlah Data'
                    name      = 'dataCount__equals'
                    className = "md-cell md-cell--12"
                    component = {Textfield}
                />
                <this.ig.Field
                    label='WAITING'
                    name='status__in_WAITING'
                    className="md-cell md-cell--6"
                    component={this.ig.Checkbox}
                />
                <this.ig.Field
                    label='PROCESS'
                    name='status__in_PROCESS'
                    className="md-cell md-cell--6"
                    component={this.ig.Checkbox}
                />
                <this.ig.Field
                    label='FINISH'
                    name='status__in_FINISH'
                    className="md-cell md-cell--6"
                    component={this.ig.Checkbox}
                />
                <this.ig.Field
                    label='FAILED'
                    name='status__in_FAILED'
                    className="md-cell md-cell--6"
                    component={this.ig.Checkbox}
                />
            </div>
        )
    }

    _barActions = [
        {
          label:'word.delete',
          iconClassName:'mdi mdi-delete',
          onClick:() => {
            this.setState({showDialogConfirmDeleteSelected:true})
          },
          disabledFunc:() => this.props.table.selected.length === 0
        },
        {
          label:'word.download',
          iconClassName:'mdi mdi-file',
          onClick:() => this.downloadSelected(),
          disabledFunc:() => this.props.table.selected.length === 0
        },
        {
          label:'word.refresh',
          iconClassName:'mdi mdi-refresh',
          onClick:() => {
            window.location.hash = window.location.hash.split('?')[0]
            this.fetchData()
          }
        },
    ]

    columns=[
      {isSortable: true, label: "word.pasal",  value: "pasal", isDefaultSort:false, show:true, isSearchable:false},
      {isSortable: true, label: "word.category",  value: "category", isDefaultSort:false, show:true, isSearchable:true},
      {isSortable: true, label: "word.dataCount", value: "dataCount", type: "number", isDefaultSort:false, show:true, isSearchable:true},
      {isSortable: true, label: "word.merge", type: "func", value: (d) => {
        if (d.merge) {
          return <Button flat swapTheming style={{backgroundColor: '#4caf50', color: 'white'}}>YA</Button>
        }

        return <Button flat swapTheming style={{backgroundColor: '#0D47A1', color: 'white'}}>TIDAK</Button>
      }, show: true, isSearchable: true},
    {
      label: "word.status",
      type: 'func',
      value: (d)=> {
        var style = {
          color: 'white',
          backgroundColor: 'grey'
        }
        switch (d.status) {
          case 'PROCESS':
            style.backgroundColor = '#2196F3'
            break;
          case 'FINISH':
            style.backgroundColor = '#4CAF50'
            break;
          case 'FAILED':
            style.backgroundColor = '#f44336'
            break;
          default:
            break;
        }
        return <Button flat swapTheming label={d.status} style={style}/>
      }, className: "mpk-font-size-S", show:true, isSearchable:true},
      {isSortable: true, label: "word.message",  value: "message", isDefaultSort:false, show:true, isSearchable:false},
      {isSortable: true, label: "word.fileSize",  type: "func",  value: (d) => {
        if (d.size) return d.size

        return "-"
      }, isDefaultSort:false, show:true, isSearchable:false},
      {isSortable: true, label: "word.username",  value: "username", isDefaultSort:false, show:true, isSearchable:true},
    ]
}
