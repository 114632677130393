import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button, SVGIcon } from 'react-md';
import ListView from '../../../../../../components/entity/listView';
import {Switch} from 'react-md';
import iziToast from 'izitoast';
import AllPbk4a2Service from './AllPbk4a2.service';
import Pbk4a2Service from "../../PBK/PBK4a2.service";
import download from "downloadjs";
import moment from "moment";

@connect(AllPbk4a2Service.stateConnectSetting(), AllPbk4a2Service.actionConnectSetting())
export default class AllPbk4a2View extends ListView {
  service = AllPbk4a2Service

  constructor(props){
    super(props);
    this.state = {
      showTableSetting:false,
      showDialogConfirmDeleteSelected:false,
      showForm:false,
      formData: {},
      data: []
    }
  }

  editDialog = false;
  _barActions = [];

  editItem = (item) => {
    if(this.editDialog) {
      this.showFormDialog(item);
    } else {
      if(this.rootPath) {
        this.props.history.push(this.rootPath+"/"+item.id)
      } else {
        this.props.history.push('/'+this.service.name+"/"+item.spt.id);
      }
    }
  }

  async handleSendEmail(){
    try {
      let category = "BP_4A2"
      var selected = this.props.table.selected
      var ids = [];
      selected.map(function (i){
        ids.push(i.id)
      })
      await this.service.api.email(category, ids)
      iziToast.info({
        title: "Kirim Email",
        message: "email sedang diproses silahkan cek log untuk lebih detil"
      })
    } catch (e){
      iziToast.warning({
        title: e.name,
        message: e.message
      })
    }
  }

  barActions() {
    return [
      ...this._barActions,
      {
        label:'word.exportCsv',
        iconClassName:'mdi mdi-file-excel',
        disabledFunc:() => this.props.table.selected.length === 0,
        onClick:() => this.handleExportAll()
      }
    ]
  }

  defaultColumns = [
    /*{isSortable:true, label: "word.id",  value: "id", show:false, isSearchable:true, isDefaultSort: false},*/
    {isSortable:true, label: "word.createdUsername",  value: "createdUsername", show:true, isSearchable:true},
    {isSortable:true, label: "word.createdBy",  value: "createdBy", show:true, isSearchable:true},
    {isSortable:true, label: "word.createdDate", value: "createdDate", show:true, isSearchable:true, type:"date", isDefaultSort: true},
    {isSortable:true, label: "word.modifiedUsername",  value: "modifiedUsername", show:true, isSearchable:true},
    {isSortable:true, label: "word.lastModifiedBy",  value: "lastModifiedBy", show:true, isSearchable:true},
    {isSortable:true, label: "word.lastModifiedDate", value: (d)=> {
        if(d.lastModifiedDate) {
          return <div className='mpk-font-size-S'>{moment(d.lastModifiedDate).format('lll')}</div>
        } else {
          return null;
        }
      }, show:true, isSearchable:true, type:"func", searchField: 'lastModifiedDate', isDefaultSort: true}
  ]

  columns=[
    /*{isSortable: true, label: "word.reference",  value: "referensi", show:false, isSearchable:true},*/
    {isSortable: true, label: "word.year",  searchField: "year", value: (d)=> d.spt.year, type: "func", show:true, isSearchable:true},
    {isSortable: true, label: "word.month", searchField:'masa',  value: "spt.month", type: "number", show:true, isSearchable:true},
    {isSortable: true, label: "word.pembetulan",  value: "spt.pembetulan", type: "number", show:true, isSearchable:true},
    {isSortable: true, label: "word.npwp",  value: "bagA.npwp", show:true, isSearchable:true},
    {isSortable: true, label: "word.nik",  value: "bagA.nik", show:false, isSearchable:true},
    {isSortable: true, label: "word.name",  value: "bagA.nama", show:true, isSearchable:true},

  ]

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    }
  ]
}
