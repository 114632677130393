import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Button, Chip, Switch} from 'react-md';

//import FormDialog from './Organization.dialog.view';
import BP25Service from './BP_25.service';
import ListView from '../../../../../components/entity/listView';
import UtilService from '../../../../../services/utils.service';
import download from 'downloadjs';
import uuid from 'uuid';
import iziToast from 'izitoast';
import ListViewSpt from '../../../../../components/entity/listViewSpt';
import moment from "moment";

@connect((state)=> ({
  ...BP25Service.stateConnectSetting()(state),
  spt: state.authEppt.currentSPT
}), BP25Service.actionConnectSetting())
export default class BP25FView extends ListViewSpt {
  service=BP25Service
  FormDialog=()=> <div/>

  constructor(props){
    super(props);
    this.state = {
      ...this.state,
      total: {},
      cetak: false,
      cetakDaftar: false
    }
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Nama'
          name='name__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NPWP'
          name='npwp__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Email'
          name='email__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Nomor Bukti Potong'
          name='no__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        {/*<this.ig.Field
          label='NIK'
          name='nik__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />*/}
      </this.SearchContainer>
    )
  }

  addDialog=false
  editDialog=false

  fetchOption() {
    return {
      path: BP25Service.path+'/'+this.props.spt.data.id+'/spt'
    }
  }

  columns=[
    {isSortable: true, label: "word.reference",  value: "rev", show:false, isSearchable:true},
    {isSortable: true, label: "word.npwp",  value: "npwp", show:true, isSearchable:true},
    {isSortable: true, label: "word.name",  value: "name", show:true, isSearchable:true},
    {isSortable: true, label: "word.email",  value: "email", show:true, isSearchable:true},
    {isSortable: true, label: "word.address",  value: "address", show:true, isSearchable:true},
    {isSortable: true, label: "word.noSk",  value: "no", show:true, isSearchable:true},
    {isSortable: true, label: "word.pph",  value: "pph", show:true, isSearchable:true, type: 'number'},
    {isSortable:true, label: "word.createdUsername",  value: "createdUsername", show:true, isSearchable:true},
    {isSortable:true, label: "word.createdBy",  value: "createdBy", show:true, isSearchable:true},
    {isSortable:true, label: "word.createdDate", value: "createdDate", show:true, isSearchable:true, type:"date", isDefaultSort: true},
    {isSortable:true, label: "word.modifiedUsername",  value: "modifiedUsername", show:true, isSearchable:true},
    {isSortable:true, label: "word.lastModifiedBy",  value: "lastModifiedBy", show:true, isSearchable:true},
    {isSortable:true, label: "word.lastModifiedDate", value: (d)=> {
      if(d.lastModifiedDate) {
        return <div className='mpk-font-size-S'>{moment(d.lastModifiedDate).format('lll')}</div>
      } else {
        return null;
      }
    }, show:true, isSearchable:true, type:"func", searchField: 'lastModifiedDate', isDefaultSort: true}
  ]

  async componentDidMount() {
    this.rootPath = this.props.match.url;

    var sptId = this.props.spt.data.id
    var total = await BP25Service.api.total(sptId)
    this.setState({
      total: total
    })
  }

  totalPph() {
    var total = 0;
    if(this.state.total.total){
      total = this.state.total.total;
    }
    return total;
  }

  _barItem() {
    return (
      <div>
        <Switch
          style={{float: "right"}}
          id="switch-table-setting"
          name="Switch Table Setting"
          label=""
          checked={this.state.showTableSetting}
          onChange={() => {
            this.setState({
              showTableSetting:!this.state.showTableSetting
            })
          }}
        />
      </div>
    )
  }

  footer() {
    return <div className="flex-none mpk-border thin solid top dark">
      <div className="mpk-padding-N left right">
        <table style={{width: '100%'}}>
          <tfoot className="md-table-footer">
            <tr className="md-table-column md-text-left md-table-column--data md-table-column--adjusted md-text ">
              <td>Total Jumlah PPH Dipotong</td>
              <td className=" md-text-right">{UtilService.number.format(this.totalPph(), {precision: 0, thousand: '.', decimal: ','})}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  }

}
