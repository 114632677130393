import React, { Component } from 'react';
import ApiService from '../../../../../services/api.service'
import { Button } from 'react-md';
import http from '../../../../../services/http.service'
import moment from 'moment'

class LogConvertedExcelService extends ApiService {
  name= 'LogConvertedExcel'
  path= 'ebupot/excel-files'

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api = {
    logValidasi: async(fileId)=> {
      let res = await http.get(`/api/ebupot/log-validasi/${fileId}/log-file`)
      return res.data
    },
    download: async(data)=> {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/download`, data)
      return res
    },
  }

}

export default new LogConvertedExcelService();
