import React from 'react';
import {Button} from 'react-md';
import './Pagination.scss';

const Pagination = ({page, total, size, limit,  onChangePage, onChange, onChangeLimit, limitOptions=[10, 20, 50, 100, 250, 500]}) => {
  onChange = onChangePage || onChange;
  return(
    <div className="mpk-pagination">
      {/* <div className="limit">
        <div>Items per page</div>
        <div className="mpk-select">
          <select
            value={limit}
            onChange={(e) => onChangeLimit(e.target.value)}
          >
            {limitOptions.map((d, i) => (
              <option value={d} key={i}>{d}</option>
            ))}
          </select>
        </div>
      </div> */}
      <div className="pagination-info md-table-pagination--label">{`${((page - 1)*(size || limit)) + 1} - ${(total < page*(size || limit) ? total : page*(size || limit))} of ${total}`}</div>    
      <div className="actions mpk-layout">
        <Button 
          disabled={page===1}
          onClick={() => onChange( page - 1)}
          icon
          iconClassName="mdi mdi-chevron-left"
        />
        <Button 
          disabled={(page*(size || limit)) >= total}
          onClick={() => onChange( page + 1)}
          icon
          iconClassName="mdi mdi-chevron-right"
        />
      </div>
    </div>
  )
}

export default Pagination;