import React, { Component } from 'react'; import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  Divider,
  Chip,
  Card,
  CardTitle,
  CardActions,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Switch
} from 'react-md';
import { Field, reduxForm, getFormValues, initialize } from 'redux-form';
import {validation, FileInputNext, Searchfieldv2, Textfield, Searchfield, TextfieldMask, Datepicker, Radio, convert, CheckboxSelection, Multiselect} from '../../../../../../../src/components/form';
import ColumnService from '../../../../../../services/column.service';
import download from 'downloadjs';
import uuid from 'uuid';
import iziToast from 'izitoast';
import UtilService from '../../../../../../services/utils.service'
import SPT23HistoryService from './SPT23History.service';
import ListView from '../../../../../../components/entity/ListViewEbupot';
import moment from 'moment';

@reduxForm({form: 'SPT23History', destroyOnUnmount: false})
@connect((state)=> ({
  ...SPT23HistoryService.stateConnectSetting()(state),
  spt: state.auth.currentSPT,
  formData: getFormValues('SPT23History')(state)
}), SPT23HistoryService.actionConnectSetting())
export default class SPT23HistoryView extends ListView {
  service=SPT23HistoryService
  FormDialog=()=> <div/>

  addDialog=false
  editDialog=false

  fetchOption() {
    const sptId = this.props.match.params.sptId
    return {
        path: SPT23HistoryService.path+'/'+sptId+'/history'
    }
  }

  _barActions = [
    {
      label:'word.back',
      iconClassName:'mdi mdi-arrow-left',
      onClick:() => {
        this.props.history.goBack()
      }
    },
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {
        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    },
  ]

  _tableActions = []

  columns=SPT23HistoryService.columns

}
