import Application_C             from './../../modules/Main/Modules/EbupotModule/Application/Application.service';
import User_C                    from './../../modules/Main/Modules/EbupotModule/User/User.service';
import Client_C                  from './../../modules/Main/Modules/EbupotModule/Client/Client.service';
import Organization_C            from './../../modules/Main/Modules/EbupotModule/Organization/Organization.service';
import ApplicationOrganization_C from './../../modules/Main/Modules/EbupotModule/ApplicationOrganization/ApplicationOrganization.service';
import Espt23_C                  from './../../modules/Main/Modules/EbupotModule/PPh/SPT23/SPT23.service';
import LogFile_C                 from './../../modules/Main/Modules/EbupotModule/LogFile/LogFile.service';
import LogFileCsv_C              from './../../modules/Main/Modules/EbupotModule/LogFileCsv/LogFileCsv.service';
import KodeBuktiPotong_C         from './../../modules/Main/Modules/EbupotModule/KodeBuktiPotong/KodeBuktiPotong.service';
import JenisDokumen_C            from './../../modules/Main/Modules/EbupotModule/JenisDokumen/JenisDokumen.service';
import KodeNegara_C              from './../../modules/Main/Modules/EbupotModule/KodeNegara/KodeNegara.service';
import Provinces_C               from './../../modules/Main/Modules/EbupotModule/Provinces/Provinces.service';
import Regencies_C               from './../../modules/Main/Modules/EbupotModule/Regencies/Regencies.service';
import Districts_C               from './../../modules/Main/Modules/EbupotModule/Districts/Districts.service';
import Villages_C                from './../../modules/Main/Modules/EbupotModule/Villages/Villages.service';
import Signer_C                  from './../../modules/Main/Modules/EbupotModule/Signer/Signer.service';
import AllEbupot26_C             from './../../modules/Main/Modules/EbupotModule/AllEbupot26/AllEbupot26.service';
import BP26_C                    from './../../modules/Main/Modules/EbupotModule/PPh/SPT23In/bp26/bp26.service';
import Bupot26_C                 from './../../modules/Main/Modules/EbupotModule/Bupot26/Bupot26.service';
import Bupot26In_C               from './../../modules/Main/Modules/EbupotModule/PPh/SPT23In/Bupot26In/Bupot26In.service';
import AllEbupot23_C             from './../../modules/Main/Modules/EbupotModule/AllEbupot23/AllEbupot23.service';
import BP23_C                    from './../../modules/Main/Modules/EbupotModule/PPh/SPT23In/bp23/bp23.service';
import Bupot23_C                 from './../../modules/Main/Modules/EbupotModule/Bupot23/Bupot23.service';
import Bupot23In_C               from './../../modules/Main/Modules/EbupotModule/PPh/SPT23In/Bupot23In/Bupot23In.service';
import PbkEbupot_C               from './../../modules/Main/Modules/EbupotModule/PPh/SPT23In/pbk/pbk.service';
import SspEbupot_C               from './../../modules/Main/Modules/EbupotModule/PPh/SPT23In/ssp/ssp.service';
import LogFileValidasi_C         from './../../modules/Main/Modules/EbupotModule/LogFileValidasi/LogFileValidasi.service';
import LogCheck_C                from './../../modules/Main/Modules/EbupotModule/LogCheck/LogCheck.service';
import LogSubmit_C               from './../../modules/Main/Modules/EbupotModule/LogSubmit/LogSubmit.service';
import LawanTransaksi23_C        from './../../modules/Main/Modules/EbupotModule/LawanTransaksi23/LawanTransaksi23.service';
import LawanTransaksi26_C        from './../../modules/Main/Modules/EbupotModule/LawanTransaksi26/LawanTransaksi26.service';
import Bpe_C                     from './../../modules/Main/Modules/EbupotModule/PPh/Bpe/Bpe.service';
import Bupot23History_C           from './../../modules/Main/Modules/EbupotModule/Bupot23History/Bupot23History.service';
import Bupot26History_C           from './../../modules/Main/Modules/EbupotModule/Bupot26History/Bupot26History.service';
import Bupot23Email_C           from './../../modules/Main/Modules/EbupotModule/Bupot23Email/Bupot23Email.service';
import Bupot26Email_C           from './../../modules/Main/Modules/EbupotModule/Bupot26Email/Bupot26Email.service';
import LogConvertedExcel_C      from './../../modules/Main/Modules/EbupotModule/LogConvertedExcel/LogConvertedExcel.service'

const Application              = Object.assign([], Application_C.columns)
const User                     = Object.assign([], User_C.columns)
const Client                   = Object.assign([], Client_C.columns)
const Organization             = Object.assign([], Organization_C.columns)
const ApplicationOrganization  = Object.assign([], ApplicationOrganization_C.columns)
const Espt23                   = Object.assign([], Espt23_C.columns)
const LogFile                  = Object.assign([], LogFile_C.columns)
const LogFileCsv               = Object.assign([], LogFileCsv_C.columns)
const KodeBuktiPotong          = Object.assign([], KodeBuktiPotong_C.columns)
const JenisDokumen             = Object.assign([], JenisDokumen_C.columns)
const KodeNegara               = Object.assign([], KodeNegara_C.columns)
const Provinces                = Object.assign([], Provinces_C.columns)
const Regencies                = Object.assign([], Regencies_C.columns)
const Districts                = Object.assign([], Districts_C.columns)
const Villages                 = Object.assign([], Villages_C.columns)
const Signer                   = Object.assign([], Signer_C.columns)
const AllEbupot26              = Object.assign([], AllEbupot26_C.columns)
const BP26                     = Object.assign([], BP26_C.columns)
const Bupot26                  = Object.assign([], Bupot26_C.columns)
const Bupot26In                = Object.assign([], Bupot26In_C.columns)
const AllEbupot23              = Object.assign([], AllEbupot23_C.columns)
const BP23                     = Object.assign([], BP23_C.columns)
const Bupot23                  = Object.assign([], Bupot23_C.columns)
const Bupot23In                = Object.assign([], Bupot23In_C.columns)
const PbkEbupot                = Object.assign([], PbkEbupot_C.columns)
const SspEbupot                = Object.assign([], SspEbupot_C.columns)
const LogFileValidasi          = Object.assign([], LogFileValidasi_C.columns)
const LogCheck                 = Object.assign([], LogCheck_C.columns)
const LogSubmit                = Object.assign([], LogSubmit_C.columns)
const LawanTransaksi23         = Object.assign([], LawanTransaksi23_C.columns)
const LawanTransaksi26         = Object.assign([], LawanTransaksi26_C.columns)
const Bpe                      = Object.assign([], BP23_C.columns)
const Bupot23History           = Object.assign([], Bupot23History_C.columns)
const Bupot26History           = Object.assign([], Bupot26History_C.columns)
const Bupot23Email           = Object.assign([], Bupot23Email_C.columns)
const Bupot26Email           = Object.assign([], Bupot26Email_C.columns)
const LogConvertedExcel      = Object.assign([], LogConvertedExcel_C.columns)

export default {
  Application               : Application,
  User                      : User,
  Client                    : Client,
  Organization              : Organization,
  ApplicationOrganization   : ApplicationOrganization,
  Espt23                    : Espt23,
  LogFile                   : LogFile,
  LogFileCsv                : LogFileCsv,
  KodeBuktiPotong           : KodeBuktiPotong,
  JenisDokumen              : JenisDokumen,
  KodeNegara                : KodeNegara,
  Provinces                 : Provinces,
  Regencies                 : Regencies,
  Districts                 : Districts,
  Villages                  : Villages,
  Signer                    : Signer,
  AllEbupot26               : AllEbupot26,
  BP26                      : BP26,
  Bupot26                   : Bupot26,
  Bupot26In                 : Bupot26In,
  Bupot26InPembetulan       : Bupot26In,
  Bupot26InEdit             : Bupot26In,
  AllEbupot23               : AllEbupot23,
  BP23                      : BP23,
  Bupot23                   : Bupot23,
  Bupot23In                 : Bupot23In,
  Bupot23InPembetulan       : Bupot23In,
  Bupot23InEdit             : Bupot23In,
  PbkEbupot                 : PbkEbupot,
  SspEbupot                 : SspEbupot,
  LogFileValidasi           : LogFileValidasi,
  LogCheck                  : LogCheck,
  LogSubmit                 : LogSubmit,
  LawanTransaksi23          : LawanTransaksi23,
  LawanTransaksi26          : LawanTransaksi26,
  Bpe                       : Bpe,
  Bupot23History            : Bupot23History,
  Bupot26History            : Bupot26History,
  Bupot23Email              : Bupot23Email,
  Bupot26Email              : Bupot26Email,
  LogConvertedExcel         : LogConvertedExcel,
}
