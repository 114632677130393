import React, { Component } from 'react';
import { Grid, Cell } from 'react-md';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import { validation, Textfield, TextfieldMask, Datepicker, convert } from '../../../../../../components/form';
import DialogView from '../../../../../../components/entity/dialog.view';
import Master23TarifLainService from './Master23TarifLain.service';

@reduxForm({form: 'master-23-tarif-lain', destroyOnUnmount: false})
export default class Master23TarifLainDialogView extends DialogView {
  service=Master23TarifLainService
  initialData = {
  }

  formView() {
    return (
      <Grid style={{padding:'none'}}>
        <Field
          label='Kode'
          name='code'
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
        />
        <Field
          label='Nama'
          name='name'
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
        />
        <Field
          label='Tarif'
          name='rates'
          className="md-cell md-cell--12"
          component={TextfieldMask}
          normalize={convert.money}
          validate={validation.required}
        />
      </Grid>
    )
  }
}
