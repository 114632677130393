import ApiService from '../../../services/api.service';
import http from '../../../services/http.service';

class SignerSupportService extends ApiService {
  name= 'SignerSupport';
  path= '';

  api= {
    getAll: async (params)=> {
      let res = await http.get('/api/iams/signer/ebupot/page', {
        params: params
      })
      return res
    },
    putUpdate: async (data)=> {
      let res = await http.put(`/api/iams/signer/ebupot/${data.id}`, data)
      return res
    },
    postSave: async (data)=> {
      let res = await http.post('/api/iams/signer/ebupot', data)
      return res
    },
    deleteDelete: async (data)=> {
      let res = await http.delete(`/api/iams/signer/ebupot/${data.id}`, data)
      return res
    },
    getOne: async(id)=> {
      let res = await http.get(`/api/iams/signer/ebupot/${id}`)
      return res
    }
  }

  constructor() {
    super()
    this.init()
    this.initApi()
  }
}


export default new SignerSupportService();
