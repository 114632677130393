import React, { Component } from 'react';
import { connect } from 'react-redux';

import FormDialog from './Master21PtkpGlobal.dialog.view';
import Master21PtkpGlobalService from './Master21PtkpGlobal.service';
import ListView from '../../../../../components/entity/listView';
import ColumnService from '../../../../../services/column.service';

@connect(Master21PtkpGlobalService.stateConnectSetting(), Master21PtkpGlobalService.actionConnectSetting())
export default class Master21PtkpGlobalView extends ListView {
  service=Master21PtkpGlobalService
  FormDialog=FormDialog

  columns=[
    {isSortable: true, label: "word.id", searchField: "id",  value: function(d){ return d.id }, type: "func",  show:false, isSearchable:true},
    {isSortable: true, label: "word.start", searchField: "startDate",  value: function(d){ return d.startDate }, type: "func",  show:true, isSearchable:true},
    {isSortable: true, label: "word.end", searchField: "endDate", value: function(d){ return d.endDate }, type: "func",  show:true, isSearchable:true},
    {isSortable: true, label: "word.personal", searchField: "personal", value: function(d){ return ColumnService.money(d.personal) }, type: "func",  show:true, isSearchable:true},
    {isSortable: true, label: "word.dependents", searchField: "dependents", value: function(d){ return ColumnService.money(d.dependents) }, type: "func",  show:true, isSearchable:true}
  ]
}
