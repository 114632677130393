import ApiService from '../../../../../../services/api.service';

class ExportCsv22LogService extends ApiService {
  name= 'ExportCsv22Log';
  path= 'log-export-csv/page';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    validasi: async (fileId, page) => {
      if(page){
        page = page
      } else {
        page = 0
      }
      var res = await this.http.get(this.apiLocation+'/validation-csv', {
        params: {
          fileId : fileId,
          page: page,
          size: 100
        }
      })

      return res.data;
    },
    getInfo: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/pasal22/spt/${sptId}/count`);
      return res;
    },
    getInfoFileLapor: async(sptId)=> {
      return await this.http.get(`${this.apiLocation}/pasal22/spt/${sptId}/info`)
    },
    cetak: async (sptId) => {
      return await this.http.get(`${this.apiLocation}/pasal22/spt/${sptId}/submit`)
    },
  }
}


export default new ExportCsv22LogService();
