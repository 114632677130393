import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {connect} from 'react-redux';

import LoggingEventService from './LoggingEvent.service';
import LoggingEventExceptionService from './LoggingEventException.service';
import LoggingEventPropertyService from './LoggingEventProperty.service';
import ListView from '../../../../components/entity/listView';
import PaymentMethodService from "../../Ebilling/PaymentMethod/PaymentMethod.service";
import Constants from "../../Ebilling/Constants/Constants";
import {DataTable, TableBody, TableColumn, TableHeader, TableRow} from "react-md";
import utilsService from "../../../../services/utils.service";

@reduxForm({form: 'LOGGING_EVENT', destroyOnUnmount: true})
@connect((state) => ({
  ...LoggingEventService.stateConnectSetting()(state)
}), LoggingEventService.actionConnectSetting())
export default class LoggingEvent extends ListView {
  service = LoggingEventService
  loggingEventExceptionService = LoggingEventExceptionService
  loggingEventPropertyService = LoggingEventPropertyService
  // viewType=2

  constructor(props) {
    super(props);

    this.state = {
      showTableSetting: true,
      showDialogConfirmDeleteSelected: false,
      showForm: false,
      formData: {},
      data: [],
      onProgress: false,
      isError: false,
      errorMessage: ''
    }
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Event ID'
          name='eventId__equals'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Message'
          name='formattedMessage__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Logger Name'
          name='loggerName__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Level'
          name='levelString__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Flag'
          name='referenceFlag__equals'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Filename'
          name='callerFilename__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Class'
          name='callerClass__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Method'
          name='callerMethod__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Line'
          name='callerLine__equals'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
      </this.SearchContainer>
    )
  }

  async handleDeleteAll() {
    let selected = this.props.table.selected

    if (selected.length > 0) {
      let eventIds = [];

      selected.map(function (i) {
        eventIds.push(i.eventId)
      })

      let res = await this.service.api.deleteSelected(eventIds);

      this.fetchData()
    }
  }

  async showException(item) {
    let eventId = item.eventId

    let data = await this.loggingEventExceptionService.api.findByEventId(eventId)

    let dialog = await this.context.showDialog((props, res, rej) =>({
      title: 'Event Exceptions',
      initialValue:{},
      okText: 'Ok',
      width: 600,
      text: (
        <div>
          <DataTable plain>
            <TableHeader>
              <TableRow>
                <TableColumn>Line</TableColumn>
                <TableColumn>Trace</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              {
                data.map((d, i)=> {
                  return <TableRow key={i}>
                    <TableColumn>{d.i}</TableColumn>
                    <TableColumn>{d.traceLine}</TableColumn>
                  </TableRow>
                })
              }
            </TableBody>
          </DataTable>
        </div>
      )
    }));
  }

  async showProperties(item) {
    let eventId = item.eventId

    let data = await this.loggingEventPropertyService.api.findByEventId(eventId)

    let dialog = await this.context.showDialog((props, res, rej) =>({
      title: 'Event Properties',
      initialValue:{},
      okText: 'Ok',
      width: 600,
      text: (
        <div>
          <DataTable plain>
            <TableHeader>
              <TableRow>
                <TableColumn>Mapped Key</TableColumn>
                <TableColumn>Mapped Value</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              {
                data.map((d, i)=> {
                  return <TableRow key={i}>
                    <TableColumn>{d.mappedKey}</TableColumn>
                    <TableColumn>{d.mappedValue}</TableColumn>
                  </TableRow>
                })
              }
            </TableBody>
          </DataTable>
        </div>
      )
    }));
  }

  _barActions = [
    {
      label: 'word.refresh',
      iconClassName: 'mdi mdi-refresh',
      onClick: () => this.fetchData()
    },
    {
      label: 'word.delete',
      iconClassName: 'mdi mdi-delete',
      disabledFunc: () => this.props.table.selected.length === 0,
      onClick: () => this.handleDeleteAll()
    },
  ]

  _tableActions = [
    {
      label:"Exception",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.showException(item)
    },
    {
      label:"Properties",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.showProperties(item)
    },
  ]

  columns = [
    {isSortable: true, label: "word.eventId",  value: "eventId", type: "number", isDefaultSort:true, show:true, isSearchable:false},
    {isSortable: true, label: "word.timestmp",  value: "timestmp", type: "date", isDefaultSort:false, show:true, isSearchable:false},
    {isSortable: true, label: "word.formattedMessage",  value: "formattedMessage", isDefaultSort:false, show:true, isSearchable:false},
    {isSortable: true, label: "word.loggerName",  value: "loggerName", isDefaultSort:false, show:true, isSearchable:false},
    {isSortable: true, label: "word.levelString",  value: "levelString", isDefaultSort:false, show:true, isSearchable:false},
    {isSortable: true, label: "word.threadName",  value: "threadName", isDefaultSort:false, show:false, isSearchable:false},
    {isSortable: true, label: "word.referenceFlag",  value: "referenceFlag", type: "number", isDefaultSort:false, show:false, isSearchable:false},
    {isSortable: true, label: "word.arg0",  value: "arg0", isDefaultSort:false, show:false, isSearchable:false},
    {isSortable: true, label: "word.arg1",  value: "arg1", isDefaultSort:false, show:false, isSearchable:false},
    {isSortable: true, label: "word.arg2",  value: "arg2", isDefaultSort:false, show:false, isSearchable:false},
    {isSortable: true, label: "word.arg3",  value: "arg3", isDefaultSort:false, show:false, isSearchable:false},
    {isSortable: true, label: "word.callerFilename",  value: "callerFilename", isDefaultSort:false, show:true, isSearchable:false},
    {isSortable: true, label: "word.callerClass",  value: "callerClass", isDefaultSort:false, show:true, isSearchable:false},
    {isSortable: true, label: "word.callerMethod",  value: "callerMethod", isDefaultSort:false, show:true, isSearchable:false},
    {isSortable: true, label: "word.callerLine",  value: "callerLine", isDefaultSort:false, show:true, isSearchable:false},
  ]

  defaultColumns = [
  ]

}
