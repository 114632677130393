import ApiService from '../../../../../services/api.service';

class Master21FinalPesangonProgGlobalService extends ApiService {
  name= 'Master21FinalPesangonProgGlobal';
  path= 'rates/pesangon-prog-global';

  constructor() {
    super();
    this.init()
    this.initApi()
  }

  api= {
    getData: async (pesangonId) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${pesangonId}`)
      return res;
    }
  }
}


export default new Master21FinalPesangonProgGlobalService();

