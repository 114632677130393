import React, { Component } from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm , propTypes, formnameSelector } from 'redux-form';
import {
  Avatar, Button,
  Divider,
  FontIcon,
  List,
  ListItem,
  Subheader,
} from 'react-md';

import FormView from '../../../../../components/entity/form.view';
import UserAccessService from './UserAccess.service';
import {validation, Textfield, Searchfield, Checkbox, Fileinput, Searchfieldv2} from '../../../../../components/form';
import izitoast from 'izitoast';
import OrganizationService from './../../../Administrator/Organization/Organization.service'

@reduxForm({form: 'UserAccessForm', destroyOnUnmount: false})
@connect((state) => ({
  access      : state.entity.access.api.find,
  organization: state.authEppt.currentOrganization,
  formData    : state.form['UserAccessForm'],
  // EPPT CORE
  auth: state.auth.company,
  user: state.authEppt.user,
  userCore: state.auth.user,
  // EPPT CORE
}))
export default class UserAccessForm extends FormView {
  service     = UserAccessService

  constructor(props){
    super(props)
    this.state = {
      ...this.state,
      access: [],
      isUploadDisabled: false,
    }
  }

  initialData = {
    access_SPT_21_TF: false,
    access_SPT_21_F: false,
    access_SPT_21_SM: false,
    access_SPT_21_A1: false,
    access_SPT_21_SATU_TAHUN: false
  }

  async beforeSave(val) {
    val.accesses = []
    if(val.access_SPT_21_TF){
      try {
        this.state.access.map((d)=> {
          if(d.name.indexOf("SPT_21_TF") != -1){
            val.accesses.push(d)
          }
        })
      } catch(e){}
    }
    if(val.access_SPT_21_F){
      try {
        this.state.access.map((d)=> {
          if(d.name.indexOf("SPT_21_F") != -1){
            val.accesses.push(d)
          }
        })
      } catch(e){}
    }
    if(val.access_SPT_21_SM){
      this.state.access.map((d)=> {
        if(d.name.indexOf("SPT_21_SM") != -1){
          val.accesses.push(d)
        }
      })
    }
    if(val.access_SPT_21_A1){
      this.state.access.map((d)=> {
        if(d.name.indexOf("SPT_21_A1") != -1){
          val.accesses.push(d)
        }
      })
    }
    if(val.access_SPT_21_SATU_TAHUN){
      this.state.access.map((d)=> {
        if(d.name.indexOf("SPT_21_SATU_TAHUN") != -1){
          val.accesses.push(d)
        }
      })
    }
    return val;
  }

  async initData() {
    if(this.props.match.params.id == 'new') {
      this.props.initialize(this.initialData);
      let getAccess = await this.service.api.getAccess()
      this.setState({
        access: getAccess.data
      })
    } else {
      let getAccess = await this.service.api.getAccess()
      this.setState({
        access: getAccess.data
      })
      let res = await this.service.api.findOne(this.props.match.params.id);
      try {
        res.data.accesses.map((d)=> {

          if(d.name.indexOf("SPT_21_TF") != -1){
            res.data.access_SPT_21_TF = true
          }
          if(d.name.indexOf("SPT_21_F") != -1){
            res.data.access_SPT_21_F = true
          }
          if(d.name.indexOf("SPT_21_SM") != -1){
            res.data.access_SPT_21_SM = true
          }
          if(d.name.indexOf("SPT_21_A1") != -1){
            res.data.access_SPT_21_A1 = true
          }
          if(d.name.indexOf("SPT_21_SATU_TAHUN") != -1){
            res.data.access_SPT_21_SATU_TAHUN = true
          }
        })
      } catch(e){
        console.log(e)
      }
      this.props.initialize(res.data);
    }
  }

  async metUpdate(res, value){
    res = await this.service.api.updateOk(value, value.id)
  }

  async metSave(res, value){
    res = await this.service.api.save(value)
  }

  async readFile() {
    let stateAccess = this.state.access
    let file = this.props.formData.values.file
    let api = this.service.api
    let t = this

    t.setState({isUploadDisabled: true})

    let reader = new FileReader();
    reader.onload = function () {
      let { result } = reader

      let allRows = result.split(/\r?\n|\r/)
      let data = []

      allRows.forEach(row => {
        if (row !== 'NPWP;Email;Akses') {
          let cols = row.split(';')

          if (cols.length === 3) {
            let npwp = cols[0]
            let username = cols[1]
            let accesses = []
            let inputAccesses = cols[2].replaceAll(' ', '_').split('|')

            inputAccesses.forEach(access => {
              if (access.trim() !== '') {
                stateAccess.forEach(stateAcc => {
                  if (access === stateAcc.name) {
                    accesses.push(stateAcc)
                  }
                })
              }
            })

            data.push({npwp, username, accesses})
          }
        }
      })

      if (data.length === 0) {
        izitoast.error({
          title: 'Impor',
          message: 'Tidak ada data yang dapat diimpor, mohon cek kembali csv anda.'
        })

        t.setState({isUploadDisabled: false})

        return
      }

      data.forEach(x => {
        api.save(x)
      })

      t.setState({isUploadDisabled: false})

      izitoast.success({
        title: 'Impor',
        message: 'Proses impor sudah selesai.'
      })
    };

    reader.readAsBinaryString(file);
  }

  formView() {
    var formData                             = {}
    if  (this.props.formData) formData       = this.props.formData.values;
    var org = []
    if(this.props.auth && this.props.auth.organizations) org = this.props.auth.organizations
    return (
      <div className="md-grid">
        {/* <Field
          label     = 'Organization'
          name      = 'npwp'
          id        = "UserAccess-organization"
          className = "md-cell md-cell--12"
          component = {Searchfield}
          valueField = 'npwp'
          options   = {org}
          // validate  = {validation.required}
          itemTemplate = {function(d) {
            let nameAndAlias = ""
            if (d.name)
              nameAndAlias = d.name
            if (d.aliasName)
              nameAndAlias += " - " + d.aliasName
            return {
              primaryText  : nameAndAlias,
              secondaryText: d.npwp,
              onClick      : () => {
                if(!this.props.disabled){
                  this.handleItemClick(d); this.hide()
                }
              }
            }
          }}
        /> */}
        <Field
            valueField    = "npwp"
            label         = 'Organization'
            name          = 'npwp'
            className     = "md-cell md-cell--12"
            component     = {Searchfieldv2}
            valueField    = 'parent'
            remoteSearch  = {true}
            apiPath       = 'getAll'
            params        = {{ user: this.props.userCore }}
            service       = {OrganizationService}
            validate      = {validation.required}
            renderFirst   = {true}
            validate      = {validation.required}
            coreSearch    = {true}
            npwpSearch    = {true}
            itemTemplate  = {function(d) {
              let nameAndAlias = ""
              if (d.name)
                nameAndAlias = d.name
              if (d.aliasName)
                nameAndAlias += " - " + d.aliasName
              return {
                primaryText  : nameAndAlias,
                secondaryText: d.npwp,
                onClick      : () => {
                  if(!this.props.disabled){
                    this.handleItemClick(d.npwp); this.hide()
                  }
                }
              }
            }}
        />
        <Field
          label='Username'
          name='username'
          component={Textfield}
          className = "md-cell md-cell--12"
        />

        {this.state.access.length > 0 &&
          <div className = "md-cell md-cell--12">
            <p>Pilih Akses</p>
            {this.state.access.map((i)=> {
              return (
                <Field
                  label={i.name.split("_").join(" ")}
                  name={`access_${i.name}`}
                  component={Checkbox}
                  className = "md-cell md-cell--12"
                />
              )
            })}
          </div>
        }

        {this.state.access.length > 0 &&
          <div className = "md-cell md-cell--12">
            <Field
              label='Select a File'
              className = "md-cell md-cell--12"
              name='file'
              twoLines
              component={Fileinput}
            />
          </div>
        }

        {this.state.access.length > 0 &&
          <div className = "md-cell md-cell--12">
            <Button flat style={{marginLeft: 6}} onClick={this.readFile.bind(this)} disabled={this.state.isUploadDisabled}>Upload File</Button>
          </div>
        }
      </div>
    )
  }

}
