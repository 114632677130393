import React from 'react';
import {connect} from 'react-redux';
import Bluebird from 'bluebird';
import { Field, reduxForm, getFormValues} from 'redux-form';
import moment from 'moment';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress,
  Tab,
  Tabs
} from 'react-md';
import FormView from '../../../../../../components/entity/form.view';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox} from '../../../../../../components/form';
import Master26TarifService from './Master26Tarif.service';
import iziToast from 'izitoast';


@reduxForm({form: 'Master26TarifForm', destroyOnUnmount: true, initialValues: {
  pasal23: {},
  pasal23Lain: [],
  pasal26: {},
  lain: {}
}})
@connect(state => ({
  company: state.authEppt.currentCompany,
  formData:getFormValues('Master26TarifForm')(state)
}))
export default class Master23TarifForm extends FormView {
  service=Master26TarifService
  viewType=2

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      activeTab: 0
    }
  }

  async initData() {
    // if(this.props.match.params.id == 'new') {

    //   var master = await Master26TarifService.api.getMaster();
    //   if(master) {
    //     delete master.id;
    //   }

    //   var initialData = {
    //     company: this.props.company,
    //     ...master
    //   }

    //   this.props.initialize(initialData);
    // } else {
    //   let res = await this.service.api.findOne(this.props.match.params.id);
    //   this.props.initialize(res.data);
    // }
    var res = await Master26TarifService.api.getMaster();
    this.props.initialize(res);
  }

  redirectTo = false
  async handleSave(value) {
    var beforeSave = await this.beforeSave(value);
    var res = null;
    if(beforeSave) {
      if(typeof beforeSave === 'object') value = beforeSave;
      try {
        this.setState({onProgress: true})
        if(value.id) {
          res = await this.service.api.update(value)
          iziToast.success({
            title: "Success",
            message: "Pengaturan berhasil disimpan !"
          })
        } else {
          res = await this.service.api.save(value)
          iziToast.success({
            title: "Success",
            message: "Pengaturan berhasil disimpan !"
          })
        }
        this.setState({onProgress: false})
        this.afterSave(res, value)

        var pathArray = this.props.location.pathname.split('/');
        var path = '';
        pathArray.forEach((d, i)=> {
          if(pathArray.length-1 != i) {
            path = path+d;
            if(i != pathArray.length-2) path = path+'/'
          }
        })

        if(this.redirectTo){
          this.props.history.push(path)
        }
      } catch(e) {
        var msg = e.message;
        if(e.response) msg = e.response.data.message;
        this.setState({
          isError: true,
          onProgress: false,
          errorMessage: msg
        })
      }
    }
  }

  formView() {
    return (
      <div className="mpk-layout column fill mpk-tab-clear">
        <div className="flex">
        {this.pasal26Form()}
        </div>
      </div>
    )
  }

  pasal26Form() {
    return <div className='md-card md-paper md-paper md-paper--1 md-card md-background--card' style={{marginBottom: 20}}>

    {/* <div className="md-grid">
      <Field
        className="md-cell md-cell--6"
        label='Start Date'
        name='start'
        inline={true}
        component={Datepicker}
        />
      <Field
        className='md-cell md-cell--6'
        label='End Date'
        name='end'
        inline={true}
        component={Datepicker} />
    </div> */}
    <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
       <TableHeader>
         <TableRow>
           <TableColumn>No.</TableColumn>
           <TableColumn>Jenis Penghasilan</TableColumn>
           <TableColumn>Tarif(%)</TableColumn>
         </TableRow>
       </TableHeader>
       <TableBody>
         <TableRow>
           <TableColumn>
             1.
           </TableColumn>
           <TableColumn>Dividen</TableColumn>
           <TableColumn>
             <Field
               // label='Tarif'
               name='b1'
               component={TextfieldMask}
               className='md-text-right'
             />
           </TableColumn>
         </TableRow>
         <TableRow>
           <TableColumn>
             2.
           </TableColumn>
           <TableColumn>Bunga</TableColumn>
           <TableColumn>
             <Field
               // label='Tarif'
               name='b2'
               component={TextfieldMask}
               className='md-text-right'
             />
           </TableColumn>
         </TableRow>
         <TableRow>
           <TableColumn>
             3.
           </TableColumn>
           <TableColumn>Royalti</TableColumn>
           <TableColumn>
             <Field
               // label='Tarif'
               name='b3'
               component={TextfieldMask}
               className='md-text-right'
             />
           </TableColumn>
         </TableRow>
         <TableRow>
           <TableColumn>
             4.
           </TableColumn>
           <TableColumn>Sewa dan Penghasilan Lain</TableColumn>
           <TableColumn>
             <Field
               // label='Tarif'
               name='b4'
               component={TextfieldMask}
               className='md-text-right'
             />
           </TableColumn>
         </TableRow>
         <TableRow>
           <TableColumn>
             5.
           </TableColumn>
           <TableColumn>Imbalan</TableColumn>
           <TableColumn>
             <Field
               // label='Tarif'
               name='b5'
               component={TextfieldMask}
               className='md-text-right'
             />
           </TableColumn>
         </TableRow>
         <TableRow>
           <TableColumn>
             6.
           </TableColumn>
           <TableColumn>Hadiah dan Penghargaan</TableColumn>
           <TableColumn>
             <Field
               // label='Tarif'
               name='b6'
               component={TextfieldMask}
               className='md-text-right'
             />
           </TableColumn>
         </TableRow>
         <TableRow>
           <TableColumn>
             7.
           </TableColumn>
           <TableColumn>Pensiun dan Pembayaran Berkala</TableColumn>
           <TableColumn>
             <Field
               // label='Tarif'
               name='b7'
               component={TextfieldMask}
               className='md-text-right'
             />
           </TableColumn>
         </TableRow>
         <TableRow>
           <TableColumn>
             8.
           </TableColumn>
           <TableColumn>Premi Swap</TableColumn>
           <TableColumn>
             <Field
               // label='Tarif'
               name='b8'
               component={TextfieldMask}
               className='md-text-right'
             />
           </TableColumn>
         </TableRow>
         <TableRow>
           <TableColumn>
             9.
           </TableColumn>
           <TableColumn>Pembebasan Utang</TableColumn>
           <TableColumn>
             <Field
               // label='Tarif'
               name='b9'
               component={TextfieldMask}
               className='md-text-right'
             />
           </TableColumn>
         </TableRow>
         <TableRow>
           <TableColumn>
             10.
           </TableColumn>
           <TableColumn>Penjualan Harta</TableColumn>
           <TableColumn>
             <Field
               // label='Tarif'
               name='b10'
               component={TextfieldMask}
               className='md-text-right'
             />
           </TableColumn>
         </TableRow>
         <TableRow>
           <TableColumn>
             11.
           </TableColumn>
           <TableColumn>Premi Asuransi</TableColumn>
           <TableColumn>
             <Field
               // label='Tarif'
               name='b11'
               component={TextfieldMask}
               className='md-text-right'
             />
           </TableColumn>
         </TableRow>
         <TableRow>
           <TableColumn>
             12.
           </TableColumn>
           <TableColumn>Penghasilan Penjualan Saham</TableColumn>
           <TableColumn>
             <Field
               // label='Tarif'
               name='b12'
               component={TextfieldMask}
               className='md-text-right'
             />
           </TableColumn>
         </TableRow>
         <TableRow>
           <TableColumn>
             13.
           </TableColumn>
           <TableColumn>Penghasilan Kena Pajak BUT Sesudah Dikurangi Pajak</TableColumn>
           <TableColumn>
             <Field
               // label='Tarif'
               name='b13'
               component={TextfieldMask}
               className='md-text-right'
             />
           </TableColumn>
         </TableRow>
       </TableBody>
     </DataTable>
   </div>
  }
}
