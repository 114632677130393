import ApiService from '../../../../../services/api.service';
import { date } from '../../../../../components/form/convert';

class SPTINDUK21Service extends ApiService {
  name = 'SPT_INDUK_21';
  path = 'spt21/induk';
  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api={
    getSPT: async (sptId)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/spt`)
      return res
    },
    getSigner: async (sptId, date)=> {
      // var res = await this.http.get(`${this.apiLocation}/${this.path}/signer`, {
      //   params: data
      // });
      var res = await this.http.get(`${this.apiLocation}/spt21/signer/${sptId}/spt`, {
        params: {
          date: date
        }
      })
      return res.data;
    },
    getBagB: async(sptId)=> {
      return await this.http.get(`${this.apiLocation}/spt21/st/b/${sptId}`)
    },
    updateBagA: async(d)=> {
      return await this.http.put(`${this.apiLocation}/spt21/st/b`, d);
    },
    calculate: async(sptId, d)=> {
      return await this.http.get(`${this.apiLocation}/spt21/induk/${sptId}/spt`);
    },
    calculateTemp: async(sptId, d)=> {
      return await this.http.get(`${this.apiLocation}/spt21/induk/${sptId}/temp`);
    },
    cetak: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/spt21/induk/${sptId}/report-list`)

      return res;
    },
    cetakBulk: async (ids) => {
      var res = await this.http.post(`${this.apiLocation}/spt21/induk/report/bulk`, ids, {
        responseType: 'blob'
      })

      return res;
    },
    downloadCsv: async (sptId)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/spt/export`)

      return res;
    },
  }
}


export default new SPTINDUK21Service();
