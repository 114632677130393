import React, { Component } from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm , propTypes, formnameSelector } from 'redux-form';
import {
  Avatar,
  Divider,
  FontIcon,
  List,
  ListItem,
  Subheader,
} from 'react-md';

import FormView from '../../../../../components/entity/form.view';
import {validation, Textfield, Searchfield} from '../../../../../components/form';
import OrganizationService from '../../../Administrator/Organization/Organization.service';
import SPT25Service from './SPT25.service';

@reduxForm({form: 'SPT_25_Form', destroyOnUnmount: false})
@connect((state) => ({
  access: state.entity.access.api.find,
  organization: state.authEppt.currentOrganization,
  formData: state.form['SPT_25_Form'],
  // EPPT CORE
  auth: state.auth.company
  // EPPT CORE
}))
export default class SPT25Form extends FormView {
  service=SPT25Service
  initialData={
    status: 'PROCESS',
    pembetulan: 0,
    organization: {}
  }

  constructor(props) {
    super(props);

    var date = new Date();
    let yearOptions = [];
    for(var i=0; i<11; i++) {
      yearOptions.push({
        id: date.getFullYear()-i,
        name: date.getFullYear()-i
      })
    }

    this.state = {
      ...this.state,
      bulanOptions: [
        {id: 1, name: 'Januari'},
        {id: 2, name: 'Februari'},
        {id: 3, name: 'Maret'},
        {id: 4, name: 'April'},
        {id: 5, name: 'Mei'},
        {id: 6, name: 'Juni'},
        {id: 7, name: 'Juli'},
        {id: 8, name: 'Agustus'},
        {id: 9, name: 'September'},
        {id: 10, name: 'Oktober'},
        {id: 11, name: 'November'},
        {id: 12, name: 'Desember'}
      ],
      yearOptions
    }
  }

  componentDidMount() {
    this.initData()
  }

  async initData() {
    if(this.props.match.params.sptId == 'new') {
      this.initialData.organization = this.props.organization;
      this.initialData.pasal = '21/26'
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.sptId);
      this.props.initialize(res.data);
    }
  }

  async beforeSave(val) {
    val.npwp = val.organization.npwp
    val.name = val.organization.name
    val.address = val.organization.address
    val.phone = val.organization.phone
    val.email = val.organization.email
    val.city = val.organization.city
    val.pasal = {
      id: "e1d6d621-3898-4253-99fb-86a6c9ad49d0",
      name: "21"
    }
    return true;
  }

  formView() {
    var formData                             = {}
    if  (this.props.formData) formData       = this.props.formData.values;
    var organization                         = {};
    if  (formData.organization) organization = formData.organization;
    var org = []
    if(this.props.auth && this.props.auth.organizations) org = this.props.auth.organizations
    return (
      <div className="md-grid">
        <Field
          label     = 'Organization'
          name      = 'organization'
          id        = "spt2126-organization"
          className = "md-cell md-cell--12"
          component = {Searchfield}
          valueField = 'parent'
          options   = {org}
          validate  = {validation.required}
          itemTemplate = {function(d) {
            let nameAndAlias = ""
            if (d.name)
              nameAndAlias = d.name
            if (d.aliasName)
              nameAndAlias += " - " + d.aliasName
            return {
              primaryText  : nameAndAlias,
              secondaryText: d.npwp,
              onClick      : () => {
                if(!this.props.disabled){
                  this.handleItemClick(d); this.hide()
                }
              }
            }
          }}
        />
        <div className="md-cell md-cell--6">
          <Field
            label='Bulan'
            name='month'
            id="spt2126-pasal"
            component={Searchfield}
            options={this.state.bulanOptions}
            validate={validation.required}
          />
          <Field
            label='Tahun'
            name='year'
            id="spt2126-pasal"
            component={Searchfield}
            options={this.state.yearOptions}
            validate={validation.required}
          />
        </div>

        <div className="md-cell md-cell--6">
          <List className="md-paper md-paper--1" >
            <Subheader primaryText="ORGANISASI" />
            <ListItem
              secondaryText   = {organization.name}
              primaryText = "NAME"
            />
            <ListItem
              secondaryText   = {organization.npwp}
              primaryText = "NPWP"
            />
            <ListItem
              secondaryText   = {organization.city}
              primaryText = "CITY"
            />
            <ListItem
              secondaryText   = {organization.postalCode}
              primaryText = "POSTAL CODE"
            />
            <ListItem
              secondaryText   = {organization.email}
              primaryText = "EMAIL"
            />
            <ListItem
              secondaryText   = {organization.phone}
              primaryText = "PHONE"
            />
            <ListItem
              secondaryText   = {organization.address}
              primaryText = "ADDRESS"
            />
            <ListItem
              secondaryText   = {organization.picName}
              primaryText = "PIC NAME"
            />
            <ListItem
              secondaryText   = {organization.picPhone}
              primaryText = "PIC PHONE"
            />
            <ListItem
              secondaryText   = {organization.picPosition}
              primaryText = "PIC POSITION"
            />
            {/* <ListItem
              secondaryText   = {(organization.isPemungut) ? 'YES' : 'NO'}
              primaryText = "PEMUNGUT"
            /> */}
          </List>
        </div>
      </div>
    )
  }

}
