import React, { Component } from 'react'; import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {TabsContainer, Tabs, Tab} from 'react-md';
import { Field } from 'redux-form';
import moment from 'moment'

import KodeBuktiPotongService from './KodeBuktiPotong.service';
import ListView from '../../../../../components/entity/ListViewEbupot';
import AuthService from '../../../../../services/auth.service'
import ListViewEbupot from '../../../../../components/entity/ListViewEbupot';

@connect((state)=> ({
  ...KodeBuktiPotongService.stateConnectSetting()(state)
}), KodeBuktiPotongService.actionConnectSetting())
export default class KodeBuktiPotongView extends ListViewEbupot {
  service=KodeBuktiPotongService;
  editDialog = false;
  addDialog = false;

  beforeFetch(params) {
    delete params.startDate
    delete params.endDate
    delete params.column
    delete params.total
    delete params.keyword
    params.sortBy = "id"
    params.sort = "id,ASC"
    // params.sortBy = 'id'
    // params.sort = 1
}

  columns=KodeBuktiPotongService.columns

  _barActions = [
    {
      label:'word.create',
      iconClassName:'mdi mdi-plus',
      onClick:() => this.addItem()
    },
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => this.fetchData()
    },
  ]

  addItem = () => {
    if(this.addDialog) {
      this.setState({showForm:true, formData: {}})
    } else {
      if(this.rootPath) {
        this.props.history.push(this.rootPath+"/new");
      } else {
        this.props.history.push(''+'KodeBuktiPotong'+"/new");
      }

    }
  }

  editItem = (item) => {
    if(this.editDialog) {
      this.showFormDialog(item);
    } else {
      if(this.rootPath) {
        this.props.history.push(this.rootPath+"/"+item.id)
      } else {
        this.props.history.push(''+'KodeBuktiPotong'+"/"+item.id);
      }
    }
  }

}
