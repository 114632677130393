import ApiService from '../../../../services/api.service';

class SettingEbillingService extends ApiService {
  name= 'SettingEbilling';
  path= 'ebilling/setting/page';

  constructor() {
    super();
    this.init();
    this.initApi()
  }

  api = {
    get: async ()=> {
      return await this.http.get(`${this.apiLocation}/ebilling/setting`)
    },
    update: async (formData)=> {
      return await this.http.put(`${this.apiLocation}/ebilling/setting`, formData)
    }
  }
}

export default new SettingEbillingService();
