import ApiService from '../../../services/api.service';

class EbillingAccountService extends ApiService {
  name= 'EbillingAccount';
  path= 'ebilling';

  constructor() {
    super();
    this.init();
    this.initApi()
  }

  api = {
    me: async () => {
      return await this.http.get(`${this.apiLocation}/ebilling/me`)
    },
    countLicenseByPermission: async (permissionCode) => {
      let params = { permissionCode }

      return await this.http.get(`${this.apiLocation}/ebilling/countLicenseByPermission`, { params })
    },
    getRabbitStatus: async () => {
      return await this.http.get(`${this.apiLocation}/ebilling/rabbitStatus`)
    },
  }
}

export default new EbillingAccountService();
