import React from 'react';
import { TimelineMax, TweenMax, TweenLite, Linear, gsap} from 'gsap';
import _ from 'lodash';

class Item extends React.Component{
  constructor(){
    super();
    this.state = {ch: 0}
  }

  componentWillMount(){
    this.id = `news-ticker-item-${new Date().valueOf()}${Math.floor(Math.random()*4)}`;
  }

  componentDidMount() {
    const content = document.getElementById(this.id);
      const cw = content.clientWidth;
      const ch = content.clientHeight;
      const sw = window.innerWidth ;
      this.setState({ch})
      let tl = new TimelineMax({repeat: 0});
      let hasInitialOther = false;
      tl.add(TweenLite.to(content, ((sw+cw)*this.props.duration)/2400, {
        transform: `translate3d(-${sw + cw}px, 0, 0)`, 
        ease: Linear.easeNone, 
        onComplete: () => {
          let { onComplete, id } = this.props;
          onComplete(id);
        },
        onUpdate: () => {
          if(!hasInitialOther && gsap.getProperty(`#${this.id}`, 'x') < (cw*(-1))){
            this.props.initialItem(true);
            hasInitialOther = true;
          }
        }
      }));
  }

  render(){
    return(
      <div 
        id={this.id}
        ref="content" 
        style={{
          left: `100%`,
          position: 'absolute',
          whiteSpace:'nowrap',
          top: '50%',
          marginTop: `-${this.state.ch/2}px`
        }}
      >
        {this.props.value}
      </div>
    )
  }
}

class Marquee extends React.Component{
  constructor(){
    super();
    this.state = {
      idx: 0,
      visibleItem:[]
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.contentItems.length > 0 && this.props.contentItems.length === 0){
      this.initialItem();
    }
  }

  componentDidMount(){
    setTimeout(this.initialItem, 500);
  }

  initialItem = (isAdd=false) => {
    let { idx, visibleItem } = this.state;
    let { contentItems } = this.props;

    if(isAdd){
      idx = idx === contentItems.length - 1 ? 0 : idx + 1;
    }
    visibleItem.push({
      id: new Date().valueOf(),
      content: contentItems[idx]
    });
    this.setState({visibleItem, idx})
  }

  onCompleteItem = (id) => {
    let { visibleItem } = this.state;
    let item = _.find(visibleItem, {id});
    if(item){
      visibleItem.splice(visibleItem.indexOf(item), 1);
      this.setState({visibleItem});
    }
  }
  
  render() {
    let { visibleItem } = this.state;
    let { style, duration, className } = this.props;
    return (
      <div 
        className={`gn-marquee ${className}`}
        onMouseOver={this.onMouseOver}
        style={_.merge({
          flex: 'none', 
          height: 24, 
          position: 'relative',
          background: 'transparent',
          width: '100%',
          overflow: 'hidden'
        }, style)}
      >
        {visibleItem.map(d => (
          <Item 
            key={`marquee-item-${d.id}`}
            id={d.id}
            value={d.content}
            onComplete={this.onCompleteItem} 
            initialItem={this.initialItem}
            duration={duration}
          />
        ))}
      </div>
    );
  }
}

Marquee.defaultProps = {
  contentItems:[
    'lorem ipsm dolor sit amet',
    'tah naha ai nu ieu mah bisa',
    'da kumaha deui atuh kuduna oge ieu geus jalan sorangan'
  ],
  className:'',
  style:{},
  duration: 48
}

export default Marquee;