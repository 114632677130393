import ApiService from '../../../../services/api.service';

class ImportEbillingService extends ApiService {
  name = 'ImportEbilling';
  path = 'ebilling/log-import/validations';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

}


export default new ImportEbillingService();
