import React, { Component } from 'react';
import { connect } from 'react-redux';

import {Field, getFormValues, reduxForm} from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../../../../components/form';

import ListView from '../../../../../../components/entity/listView';
import ColumnService from '../../../../../../services/column.service';
// import ImportService from './../Import/Import.service';
import axios from 'axios';
import izitoast from 'izitoast';
import FormDialog from './ImportBP22AtasImpor.dialog';
import ImportBP22AtasImporService from './ImportBP22AtasImpor.service';
import download from "downloadjs";
import ListViewImport from '../../../../../../components/entity/ListViewImport';
import ImportDialogResult from '../../../../../../components/entity/import.dialog.result';
import OrganizationService from "../../../../Administrator/Organization/Organization.service";

@reduxForm({form: 'ImportBP22AtasImporForm', destroyOnUnmount: true})
@connect((state) => ({
  ...ImportBP22AtasImporService.stateConnectSetting()(state),
  formData: getFormValues('ImportBP22AtasImporForm')(state),
  // EPPT CORE
  auth: state.auth.company
  // EPPT CORE
}), ImportBP22AtasImporService.actionConnectSetting())
export default class ImportBP22AtasImporView extends ListViewImport {
  service=ImportBP22AtasImporService
  FormDialog=FormDialog
  pasal='pasal22'
  category='A'
  importPath='/pasal22/upload/csv/bp'
  showAutomatisTab=false
  showPenomoranAuto=true
  showFormatEspt=true
  showPembetulan=true
  // viewType=2

  constructor(props){
    super(props);
    this.state = {
      ...this.state,
      showTableSetting:true,
      showDialogConfirmDeleteSelected:false,
      showForm:false,
      formData: {},
      data: [],
      onProgress: false,
      isError: false,
      errorMessage: '',
      type: 'M'
    }
  }

  options1 = [
    {
      name: 'BUKTI PUNGUT PPH 22 ATAS IMPORT',
      id: 'BP-ATAS-IMPOR'
    },
    {
      name: 'BUKTI PUNGUT PPH 22 INDUSTRI EKSPORTIR',
      id: 'BP-INDUSTRI-EKSPORTIR'
    },
    {
      name: 'BUKTI PUNGUT PPH 22 INDUSTRI IMPORTIR',
      id: 'BP-INDUSTRI-IMPORTIR'
    },
    {
      name: 'BUKTI PUNGUT PPH 22 BADAN TERTENTU',
      id: 'BP-ATAS-BADAN'
    }
  ]

  beforeFetch(params) {
    params["category.in"] = "BP-ATAS-IMPOR, BP-INDUSTRI-EKSPORTIR, BP-INDUSTRI-IMPORTIR, BP-ATAS-BADAN"
  }

  async beforeSave(value){
    value.type = value.category
    return value
  }

  async handleDownloadTemplate() {
    if(this.props.formData.category == "BP-ATAS-IMPOR"){
      var report = await ImportBP22AtasImporService.api.downloadTemplate('BP-ATAS-IMPOR')
      window.open(report.data.url)
    } else if(this.props.formData.category == "BP-INDUSTRI-EKSPORTIR"){
      var report = await ImportBP22AtasImporService.api.downloadTemplate("BP-INDUSTRI-EKSPORTIR")
      window.open(report.data.url)
    } else if(this.props.formData.category == "BP-INDUSTRI-IMPORTIR"){
      var report = await ImportBP22AtasImporService.api.downloadTemplate("BP-INDUSTRI-IMPORTIR")
      window.open(report.data.url)
    } else if(this.props.formData.category == "BP-ATAS-BADAN"){
      var report = await ImportBP22AtasImporService.api.downloadTemplate("BP-ATAS-BADAN")
      window.open(report.data.url)
    } else {
      izitoast.info({
        title: "Info",
        message: "Pilih salah satu kategori template"
      })
    }
  }

  componentDidMount(){
    let path = this.props.match.path.split("/")
    let params = path[2];
    this.props.change("category", params)
    this.props.change("type", "M")
    this.props.change("no", false)
    // this.props.change("migration", false)
    // this.props.change("formatEspt", false)
    // this.props.change("pembetulan", false)
    this.props.change("npwp", null)
  }

  handleTabChange(newActiveTabIndex){
    if(newActiveTabIndex == 0){
      this.props.change("type", "M");
      this.setState({type: 'M'})
    } else {
      this.props.change("type", "A")
      this.setState({type: 'A'})
    }
  }

  // yg ditambah
  tabWidth = 280
  tabs= ["import", "search", "column"]

  tabComponents() {
    var obj = this;
    return {
      import: this.import.bind(obj)
    }
  }
   // end yg ditambah

  async checkCategory() {
    let category = this.props.formData.category

    if (!category)
      return false

    if (category === 'BP_22')
      return false

    return true
  }

  import(){
    const { handleSubmit, submitting, valid, pristine } = this.props;

    let formatEspt = false;

    if (this.props.formData) {
      formatEspt = this.props.formData.formatEspt
    }

    var org = []
    if(this.props.auth && this.props.auth.organizations) org = this.props.auth.organizations

    var insideBP = true
    if(this.props.match.url.indexOf('open') != -1){
      insideBP = false
    }

    return (
        <div className='mpk-layout column fill'>
        <ImportDialogResult dataResults={this.state.importResults} visible={this.state.showImportResult} onHide={()=> {this.setState({showImportResult: false})}} />
          <div className='flex'>
            <div className='md-grid'>
              <div className="md-cell md-cell--12">
                <Field
                  label='Kategori'
                  name='category'
                  twoLines
                  component={Searchfield}
                  options={this.options1}
                  // validate={validation.required}
                />
              </div>
             <div className="md-cell md-cell--12">
                <Field
                  label='Select a File'
                  name='file'
                  multiple
                  twoLines
                  component={Fileinput}
                  validate={validation.required}
                />
              </div>
              <div className="md-cell md-cell--12">
                <Field
                  label='Aktifkan Penomoran Bukti Potong Otomatis ?'
                  name='no'
                  component={Switch2}
                  // validate={validation.required}
                />
              </div>
              {/* <div className="md-cell md-cell--12">
                <Field
                  label='Import Migrasi'
                  name='migration'
                  component={Switch2}
                  // validate={validation.required}
                />
              </div> */}

              {/* {this.showPembetulan &&
                <div className="md-cell md-cell--12">
                  <Field
                    label='Pembetulan'
                    name='pembetulan'
                    component={Switch2}
                    // validate={validation.required}
                  />
                </div>
              } */}

              {/* {this.showFormatEspt &&
              <div className="md-cell md-cell--12">
                <Field
                  label='Format ESPT'
                  name='formatEspt'
                  component={Switch2}
                  // validate={validation.required}
                />
              </div>
              } */}

              {/* {insideBP &&
                <div className="md-cell md-cell--12">
                  <Field
                    label     = 'Organization'
                    name      = 'npwp'
                    id        = "spt2126-organization"
                    className = "md-cell md-cell--12"
                    component = {Searchfield}
                    valueField='npwp'
                    options   = {org}
                    validate  = {validation.required}
                    itemTemplate = {function(d) {
                      let nameAndAlias = ""
                      if (d.name)
                        nameAndAlias = d.name
                      if (d.aliasName)
                        nameAndAlias += " - " + d.aliasName
                      return {
                        primaryText  : nameAndAlias,
                        secondaryText: d.npwp,
                        onClick      : () => {
                          if(!this.props.disabled){
                            this.handleItemClick(d); this.hide()
                          }
                        }
                      }
                    }}
                  />
                </div>
              } */}

              { formatEspt &&
              <div className="md-cell md-cell--12">
                <Field
                  label='Organization'
                  name='npwp'
                  valueField='npwp'
                  params={{ size: 50 }}
                  remoteSearch={true}
                  service={OrganizationService}
                  searchField  = {['name', 'aliasName', 'npwp']}
                  component={Searchfield}
                  itemTemplate={function(d) {
                    let nameAndAlias = ""

                    if (d.name)
                      nameAndAlias = d.name

                    if (d.aliasName)
                      nameAndAlias += " - " + d.aliasName

                    return {
                      primaryText  : nameAndAlias,
                      secondaryText: d.npwp,
                      onClick      : () => {
                        if(!this.props.disabled){
                          this.handleItemClick(d); this.hide()
                        }
                      }
                    }
                  }}
                />
              </div>
              }
              <div className="md-cell md-cell--12">
                  <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Import</Button>
              </div>
            </div>
          </div>
          {this.state.onProgress ? (
              <LinearProgress style={{margin: 0}} query={true} />
            ) : (null)}
        </div>
    )
  }

}
