import ApiService from '../../../services/api.service';

class EditPasswordService extends ApiService {
  name= 'editPassword';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api={
    update: async (data) => {
      var res = await this.http.post(`${this.apiLocation}/account/change_password`, data)

      return res;
    },
  }
}

export default new EditPasswordService();
