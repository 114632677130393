import React, { Component } from 'react';
import { Grid, Cell } from 'react-md';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import { validation, Textfield, TextfieldMask, Datepicker, convert, Switch, Searchfield } from '../../../../../components/form';
import DialogView from '../../../../../components/entity/dialog.view';
import Master21FinalPesangonProgService from './Master21FinalPesangonProg.service';
import Master21FinalPesangonService from './../Master21FinalPesangon/Master21FinalPesangon.service';
import TextField from 'react-md/lib/TextFields/TextField';

@reduxForm({form: 'master-21-final-pesangon-prog', destroyOnUnmount: false})
export default class Master21FinalPesangonProgDialogView extends DialogView {
  service=Master21FinalPesangonProgService
  initialData = {
  }

  componentDidMount() {
    var paramUrl = window.location.pathname.split("/")
    var param  = paramUrl[paramUrl.length - 1]
    this.initialData = {
      tarif21FinalPesangon: {
        id: param
      }
    }
    this.initData(this.props);
  }

  formView() {
    return (
      <Grid style={{padding:'none'}}>
        <Field
          label='Final Pesangon'
          name='tarif21FinalPesangon'
          className="md-cell md-cell--6"
          component={Searchfield}
          service={Master21FinalPesangonService}
          valueField='parent'
          viewField='id'
          disabled={true}
          validate={validation.required}
        />
        <Field
          label='Awal'
          name='awal'
          className="md-cell md-cell--6"
          component={TextfieldMask}
          money={true}
          normalize={convert.money}
          validate={validation.required}
        />
        <Field
          label='Akhir'
          name='akhir'
          className="md-cell md-cell--6"
          component={TextfieldMask}
          money={true}
          normalize={convert.money}
          validate={validation.required}
        />
        <Field
          label='Lapis'
          name='lapis'
          className="md-cell md-cell--6"
          component={TextfieldMask}
          validate={validation.required}
        />
        <Field
          label='Tarif'
          name='tarif'
          className="md-cell md-cell--6"
          component={TextfieldMask}
          validate={validation.required}
        />
        <Field
          label='Ada Akhir'
          name='adaAkhir'
          className="md-cell md-cell--6"
          component={Switch}
          validate={validation.required}
        />
      </Grid>
    )
  }
}
