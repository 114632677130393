import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {Field, getFormValues, reduxForm} from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../components/form';

import ImportAll4Service from './ImportAll4a2.service';
import ListView from '../../../components/entity/listView';
import ColumnService from '../../../services/column.service';
import axios from 'axios';
import izitoast from 'izitoast';
import download from "downloadjs";
import ListViewImport from '../../../components/entity/ListViewImport';
import formDialog from './ImportAll4a2.dialog.view'

@reduxForm({form: 'IMPORT_4_Form', destroyOnUnmount: true})
@connect((state) => ({
  ...ImportAll4Service.stateConnectSetting()(state),
  formData: getFormValues('IMPORT_4_Form')(state),
  user: state.auth.user,
  // EPPT CORE
  auth: state.auth.company
  // EPPT CORE
}), ImportAll4Service.actionConnectSetting())
export default class ImportAll4View extends ListViewImport {
    service=ImportAll4Service
    FormDialog=formDialog
    importPath='/pasal4/upload/csv/bp'
    pasal='pasal4'
    importPathFunc() {
      if(this.props.formData && this.props.formData.category){
        var category = this.props.formData.category
        if(category == 'SENDIRI_SEWA_TANAH' || category == 'SENDIRI_JASA_KONSTRUKSI' || category == 'WP_HAK_ATAS_TANAH' || category == 'SSP' || category == 'PBK' || category == 'LT'){
          return '/pasal4/upload/csv/etc'
        } else  {
          return this.importPath;
        }
      } else {
        return this.importPath;
      }
    }

    static contextTypes = {
        showDialog: PropTypes.func.isRequired,
    };

    componentDidMount(){
      this.props.change('no', false)
      this.props.change('migration', false)
      this.props.change('replace', false)
    }

    async handleDownloadTemplate() {
        var showDialog = await this.context.showDialog(()=> ({
          title: 'Download Template',
          initialValue: {
              category: null
          },
          width: 800,
          height: 600,
          contentStyle: {
            height: 600
          },
          okText: 'Download',
          text: (
            <div>
                <this.ig.Field
                    label='Kategori'
                    name='category'
                    className="md-cell md-cell--12"
                    component={this.ig.Searchfield}
                    options={[
                        { id: 'BUNGA_DEPOSITO', name: 'Bunga Deposito/Tabungan, Diskonto SBI, Jasa Giro,' },
                        { id: 'BUNGA_DISKONTO', name: 'Bunga dan/atau Diskonto Obligasi dan Surat Berharga Negara (SBN)' },
                        { id: 'BUNGA_SIMPANAN', name:'Bunga Simpanan yang dibayarkan oleh Koperasi kepada Anggota Koperasi Orang Pribadi' },
                        { id: 'DIVIDEN_DITERIMA', name: 'Dividen yang Diterima atau Diperoleh Wajib Pajak Orang Pribadi Dalam Negri' },
                        { id: 'HADIAH_UNDIAN', name: 'Hadiah Undian' },
                        { id: 'JASA_KONSTRUKSI', name: 'Penghasilan dari Usaha jasa Kontruksi' },
                        { id: 'TRANSAKSI_DERIVATIF', name: 'Penghasilan dari Transaksi Derivatif berupa Kontrak Berjangka yang Diperdagangkan di Bursa' },
                        { id: 'PENJUALAN_SAHAM', name: 'Penghasilan dari Transaksi Penjualan Saham yang Diperdagangkan di Bursa Efek' },
                        { id: 'PERSEWAAN_TANAH', name: 'Penghasilan dari persewaan Tanah dan/atau Bangunan' },
                        { id: 'WP_PEREDARAN_TERTENTU', name: 'Penghasilan Wajib Pajak yang Memiliki Peredaran Bruto Tertentu' },
                        { id: 'SENDIRI_SEWA_TANAH', name: 'Sewa Tanah/Bangunan (PPh 4 (2) Disetor Sendiri)' },
                        { id: 'SENDIRI_JASA_KONSTRUKSI', name: 'Usaha Jasa Konstruksi (PPh 4 (2) Disetor Sendiri)' },
                        { id: 'WP_HAK_ATAS_TANAH', name: 'WP Melakukan Pengalihan Hak Atas Tanah/Bangunan (PPh 4 (2) Disetor Sendiri)' },
                        { id: 'SSP', name: 'SSP Pasal 4' },
                        { id: 'PBK', name: 'PBK Pasal 4' },
                        { id: 'LT', name: 'Lawan Transaksi' },
                        { id: 'INDUK', name: 'Induk' },
                        { id: 'SIGNER_4A2', name: 'Signer Pasal 4 (2)' },
                    ]}
                    validate={validation.required}
                />
            </div>
          )
        }))
        if(showDialog){
            var report = await this.service.api.downloadTemplate(showDialog.category)
            window.open(report.data.url, `TemplatePasal4a2${showDialog.category}.csv`);
        }
    }

    searchForm() {
        return (
          <this.SearchContainer>
            <this.ig.Field
              label='Kategori'
              name='category__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Filename'
              name='filename__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Pesan'
              name='message__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Status'
              name='status__contains'
              className="md-cell md-cell--12"
              component={this.ig.Searchfield}
              options={[
                  { id: 'COMPLETED', name: 'COMPLETED' },
                  { id: 'FAILED', name: 'FAILED' }
              ]}
            />
          </this.SearchContainer>
        )
    }

    import(){
        var isBp = false
        var isSigner = false
        if(this.props.formData && this.props.formData.category){
            var category = this.props.formData.category
            if(category === 'BUNGA_DEPOSITO' || category === 'BUNGA_DISKONTO' || category === 'BUNGA_SIMPANAN' || category === 'DIVIDEN_DITERIMA' || category === 'HADIAH_UNDIAN' || category === 'JASA_KONSTRUKSI' || category === 'PENJUALAN_SAHAM' || category === 'PERSEWAAN_TANAH') isBp = true
            if(category === 'SIGNER_4A2') isSigner = true
        }
        const { handleSubmit, submitting, valid, pristine } = this.props;
        return (
            <div className='mpk-layout column fill'>
              <this.ImportDialogResult dataResults={this.state.importResults} visible={this.state.showImportResult} onHide={()=> {this.setState({showImportResult: false})}} />
              <div className='flex'>
                <div className='md-grid'>
                  <div className="md-cell md-cell--12">
                    <Field
                      label='Select a File'
                      name='file'
                      multiple
                      twoLines
                      component={Fileinput}
                      validate={validation.required}
                    />
                  </div>
                  <this.ig.Field
                    label='Kategori'
                    name='category'
                    className="md-cell md-cell--12"
                    component={this.ig.Searchfield}
                    options={[
                        { id: 'BUNGA_DEPOSITO', name: 'Bunga Deposito/Tabungan, Diskonto SBI, Jasa Giro,' },
                        { id: 'BUNGA_DISKONTO', name: 'Bunga dan/atau Diskonto Obligasi dan Surat Berharga Negara (SBN)' },
                        { id: 'BUNGA_SIMPANAN', name:'Bunga Simpanan yang dibayarkan oleh Koperasi kepada Anggota Koperasi Orang Pribadi' },
                        { id: 'DIVIDEN_DITERIMA', name: 'Dividen yang Diterima atau Diperoleh Wajib Pajak Orang Pribadi Dalam Negri' },
                        { id: 'HADIAH_UNDIAN', name: 'Hadiah Undian' },
                        { id: 'JASA_KONSTRUKSI', name: 'Penghasilan dari Usaha jasa Kontruksi' },
                        { id: 'TRANSAKSI_DERIVATIF', name: 'Penghasilan dari Transaksi Derivatif berupa Kontrak Berjangka yang Diperdagangkan di Bursa' },
                        { id: 'PENJUALAN_SAHAM', name: 'Penghasilan dari Transaksi Penjualan Saham yang Diperdagangkan di Bursa Efek' },
                        { id: 'PERSEWAAN_TANAH', name: 'Penghasilan dari persewaan Tanah dan/atau Bangunan' },
                        { id: 'WP_PEREDARAN_TERTENTU', name: 'Penghasilan Wajib Pajak yang Memiliki Peredaran Bruto Tertentu' },
                        { id: 'SENDIRI_SEWA_TANAH', name: 'Sewa Tanah/Bangunan (PPh 4 (2) Disetor Sendiri)' },
                        { id: 'SENDIRI_JASA_KONSTRUKSI', name: 'Usaha Jasa Konstruksi (PPh 4 (2) Disetor Sendiri)' },
                        { id: 'WP_HAK_ATAS_TANAH', name: 'WP Melakukan Pengalihan Hak Atas Tanah/Bangunan (PPh 4 (2) Disetor Sendiri)' },
                        { id: 'SSP', name: 'SSP Pasal 4' },
                        { id: 'PBK', name: 'PBK Pasal 4' },
                        { id: 'LT', name: 'Lawan Transaksi' },
                        { id: 'INDUK', name: 'Induk' },
                        { id: 'SPT', name: 'SPT' },
                        { id: 'SIGNER_4A2', name: 'Signer Pasal 4 (2)' },
                    ]}
                    validate={validation.required}
                    onChange={(e, val)=> {
                      this.props.change('type', val)
                    }}
                  />
                  {isBp &&
                    <Field
                      label='Aktifkan Penomoran Bukti Potong Otomatis ?'
                      name='no'
                      component={Switch2}
                      className="md-cell md-cell--12"
                    />
                  }
                  <Field
                    label='Import Migrasi'
                    name='migration'
                    component={Switch2}
                    className="md-cell md-cell--12"
                  />
                  {isSigner &&
                    <Field
                      label='Replace'
                      name='replace'
                      component={Switch2}
                      className="md-cell md-cell--12"
                    />
                  }
                  <div className="md-cell md-cell--12">
                      <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Import</Button>
                  </div>
                </div>
              </div>
              {this.state.onProgress ? (
                  <this.LinearProgress style={{margin: 0}} query={true} />
                ) : (null)}
              {this.showAutomatisTab &&
                <Tabs className='mpk-tab-white' onTabChange={this.handleTabChange.bind(this)} tabId="ImportTab">
                    <Tab label="Manual">

                    </Tab>
                    <Tab label="Otomatis">

                    </Tab>
                </Tabs>
              }
            </div>
        )
      }

}
