import ApiService from '../../../../../../services/api.service';

class ImportSSP22DibayarSendiriService extends ApiService {
  name= 'ImportSSP22DibayarSendiri';
  path= 'pasal22/log/csv';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    validasi: async (fileId, page) => {
      if(page){
        page = page
      } else {
        page = 0
      }
      var res = await this.http.get(this.apiLocation+'/pasal22/validation-csv', {
        params: {
          fileId : fileId,
          page: page,
          size: 100
        }
      })

      return res.data;
    },
    downloadTemplate: async (category) => {
      var res = await this.http.get(`${this.apiLocation}/pasal22/download/csv/template`, {
        params: {
          category: category
        }
      })
      return res;
    }, 
  }
}


export default new ImportSSP22DibayarSendiriService();
