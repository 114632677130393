import ApiService from '../../../../../services/api.service';
import http from "../../../../../services/http.service";
import bluebird from 'bluebird'

class BP4a2DSSewaTanahService extends ApiService {
  name= 'BP4a2DSSewaTanah';
  path= 'pasal4/bp-self/sewa-tanah-bangunan';

  api= {
    calculate: async (param) => {
      var res = await this.http.post(this.apiLocation+'/pasal4/calculation/pph23/f', param)

      return res.data;
    },
    total: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/total`)

      return res.data;

      return {};
    },
    cetak: async (id) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/report/${id}`, {
        responseType: 'blob'
      })

      return res;
    },
    cetakDaftar: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/spt/report`)

      return res;
    },
    downloadCsv: async (sptId)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/spt/export`)

      return res;
    },
    email: async (category, ids) => {
      return await this.http.post(`${this.apiLocation}/pasal4/email?category=${category}`, ids)
    },
    deleteBulk: async (sptId, ids)=> {
      // let res = await http.post(`${this.apiLocation}/${this.path}/${sptId}/delete`, ids);
      let res = await bluebird.each(ids, async(id)=> {
        await http.delete(`${this.apiLocation}/${this.path}/${id}`);
        return id
      })

      return res;
    },
  }

  constructor() {
    super()
    this.init()
    this.initApi()
  }
}


export default new BP4a2DSSewaTanahService();
