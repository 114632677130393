import React from 'react';
import { Route, Switch } from 'react-router-dom';

export default (props) => (
  <Switch>
    <Route path={props.match.path+'/BP_EBUPOT_23/:id'}  component={require('./BP_Ebupot_23/BP_Ebupot_23.form').default} />
    <Route path={props.match.path+'/BP_EBUPOT_23'}      component={require('./BP_Ebupot_23/BP_Ebupot_23.view').default} />

    <Route path={props.match.path+'/BP_EBUPOT_26/:id'}  component={require('./BP_Ebupot_26/BP_Ebupot_26.form').default} />
    <Route path={props.match.path+'/BP_EBUPOT_26'}      component={require('./BP_Ebupot_26/BP_Ebupot_26.view').default} />

    <Route path={props.match.path+'/'} component={function() {return <div></div>}} />
  </Switch>
)
