import ApiService from "../../../../../../services/api.service";

class Master23TarifLainService extends ApiService {
  name= 'Master23TarifLain';
  path= 'pasal23/rates/bp23/other';

  constructor() {
    super();
    this.init()
  }

  api = {
    byCompany: async (params) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}`);

      return res;
    },
    lookup: async() => {
      return this.http.get(`${this.apiLocation}/${this.path}`, {
        params: {
          page: 0,
          size: 1000
        }
      })
    }
  }
}


export default new Master23TarifLainService();
