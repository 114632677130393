import ApiService from '../../../../../../services/api.service';

class Master15KapService extends ApiService {
  name= 'Master15Kap';
  path= 'pasal15/spt/kap';

  constructor() {
    super();
    this.init();
    this.initApi();
  }

  api={
    getPasal15: async ()=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}`)

      return res;
    },
    findByKap: async (kapId)=> {
      var res = await this.http.get(`${this.apiLocation}/pasal15/spt/kjs/${kapId}/kap`)
  
      return res.data;
    }
  }

}


export default new Master15KapService();

