import ApiService from '../../../../../../services/api.service';

class Master22KapService extends ApiService {
  name= 'Master22Kap';
  path= 'pasal22/spt/kap';

  constructor() {
    super();
    this.init();
    this.initApi();
  }

  api={
    getPasal22: async ()=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/22/pasal`)

      return res;
    }
  }

}


export default new Master22KapService();

