
import React, { Component } from 'react';
import { connect } from 'react-redux';
import routes from './routes';

import AuthService from '../../../../services/auth.service';
import SPT2326Service from './../23_26/SPT/23_26.spt.service'

@connect(state => ({
  organization: state.authEppt.currentOrganization,
  user: state.authEppt.user,
//   spt: state.authEppt.currentSPT
}))
export default class Ebupot23Main extends Component{
  async componentDidMount() {
    let spt = await SPT2326Service.api.findOne(this.props.match.params.sptId);
    console.log(spt)
    AuthService.api.changeSPT({
      type: '23_26',
      data: spt.data
    }, this.props.dispatch)
    AuthService.api.changeMenuType('EBUPOT_23', this.props.dispatch)

    AuthService.api.changeOrganization(spt.data.organization, this.props.dispatch)
  }
  render() {
    return(
        <div className="mpk-layout column fill">
          {routes(this.props)}
        </div>
    )
  }
}
