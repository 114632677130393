import ApiService from "../../../../../../services/api.service";

class Tarif15ervice extends ApiService {
  name = 'Master15Tarif';
  path = 'pasal15/rates';

  constructor() {
    super();
    this.init()
  }

  api = {
    byCompany: async (compId, data) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${compId}/check`, {
        params: data
      });
      return res.data;
    },
    getMaster: async (esptId) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${esptId}/spt`);
      return res.data;
    },
    getMasterDetail: async (esptId) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${esptId}/spt/detail`);
      return res.data;
    }

  }
}


export default new Tarif15ervice();
