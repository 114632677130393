import ApiService from '../../../../services/api.service';

class MasterUUPajakPenghasilanService extends ApiService {
  name= 'masterUUPajakPenghasilan';
  path= 'master/uu-pajak-penghasilan';

  constructor() {
    super();
    this.init()
  }
}


export default new MasterUUPajakPenghasilanService();
