import React, { Component } from 'react';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import { validation, Textfield, TextfieldMask, Datepicker, convert } from '../../../../../../components/form';
import Service from './ImportInduk4a2PenghasilanTertentu.service';
import ImportDialogTemplateView from '../../../../../../components/entity/import.dialog.template.view2';
ImportDialogTemplateView

@reduxForm({form: 'ImportInduk4a2PenghasilanTertentu-form', destroyOnUnmount: false})
export default class ImportInduk4a2PenghasilanTertentuDialogView extends ImportDialogTemplateView {
  pasal="pasal4"
  service=Service
}
