import React, { Component } from 'react';
import { Grid, Cell } from 'react-md';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import { validation, Textfield, TextfieldMask, convert } from '../../../../components/form';
import DialogView from '../../../../components/entity/dialog.view';
import MasterLawanTransaksiA2Service from './MasterLawanTransaksiA2.service';
import {Searchfield} from "src/components/form";
import Master21GolonganA2Service from "modules/Main/pph/21_26/Master21GolonganA2/Master21GolonganA2.service";
import OrganizationService from "../../../Main/Administrator/Organization/Organization.service";

@reduxForm({form: 'master-lawan-transaksi-A2-dialog', destroyOnUnmount: false})
export default class MasterLawanTransaksiA2DialogView extends DialogView {
  service=MasterLawanTransaksiA2Service
  initialData = {
    jenisKelamin: 'L',
    jmlTanggungan: 0
  }
  constructor(props){
    super(props);
    this.state = {
      onProgress: false,
      master:{}
    };
  }

  async initData(props) {
    var master = await Master21GolonganA2Service.api.find()
    if (master.data) {
      var d = master.data.reduce((p, d) => {
        p[d.code] = d.name
        return p
      },{})
      this.setState({
        master : d
      })
    }
    if(props.formData.id) {
      this.props.initialize(props.formData);
    } else {
      this.props.initialize(this.initialData);
    }
  }

  formView() {
    return (
      <Grid style={{padding:'none'}}>
        <Field

          label='Organization'
          name='organization'
          id="spt2126-organization"
          className="md-cell md-cell--12"
          valueField='parent'
          service={OrganizationService}
          searchField  = {['name', 'aliasName', 'npwp']}
          component={Searchfield}
          itemTemplate={function(d) {
            let nameAndAlias = ""

            if (d.name)
              nameAndAlias = d.name

            if (d.aliasName)
              nameAndAlias += " - " + d.aliasName

            return {
              primaryText  : nameAndAlias,
              secondaryText: d.npwp,
              onClick: () => {
                if(!this.props.disabled){
                  this.handleItemClick(d); this.hide()
                }
              }
            }
          }}
          validate={validation.required}
        />

        <Field
          label='NPWP'
          name='npwp'
          className="md-cell md-cell--6"
          component={TextfieldMask}
          maskFormat="##.###.###.#-###-###"
          mask="_"
          length={15}
          validate={validation.required}
        />
        <Field
          label='NIK'
          name='nik'
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
        />
        <Field
          label='NIP/NIRP'
          name='nip'
          className="md-cell md-cell--6"
          disabled={this.state.npwpNotValid}
          component={Textfield}
          validate={validation.required}
        />
        <Field
          label='Name'
          name='nama'
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
        />
        <Field
          label='Alamat'
          name='alamat'
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
        />
        <Field
          label='Email'
          name='email'
          className="md-cell md-cell--6"
          component={Textfield}
        />

        <Field
          label='Jenis Kelamin'
          name='jenisKelamin'
          className="md-cell md-cell--6"
          disabled={this.state.npwpNotValid}
          component={Searchfield}
          options={[{id: 'L', name: 'Laki-laki'}, {id:'P', name: 'Perempuan'}]}
        />
        <Field
          label='Status PTKP'
          name='statusPtkp'
          className="md-cell md-cell--6"
          disabled={this.state.npwpNotValid}
          component={Searchfield}
          options={[{id: 'TK', name: 'TK'}, {id: 'K', name: 'K'}, {id: 'K/I', name: 'K/I'}, {id: 'HB', name: 'HB'}]}
          validate={validation.required}
        />
        <Field
          label='Jumlah Tanggungan'
          name='jmlTanggungan'
          className="md-cell md-cell--6"
          disabled={this.state.npwpNotValid}
          component={Searchfield}
          options={[{id: 0, name: '0'}, {id: 1, name: '1'}, {id: 2, name: '2'}, {id: 3, name: '3'}]}
        />
        <Field
          className="md-cell md-cell--6"
          label='Golongan'
          name='golongan'
          component={Searchfield}
          onChange={(e, v) => {
            this.props.change('pangkat', this.state.master[v])
          }}
          valueField='code'
          viewField='code'
          disabled={this.state.npwpNotValid}
          validate={validation.required}
          service={Master21GolonganA2Service}
        />
        <Field
          className='md-cell md-cell--6'
          label='Pangkat'
          name='pangkat'
          component={Textfield}
          disabled={true}
        />
        <Field
          label='Jabatan'
          name='jabatan'
          className='md-cell md-cell--6'
          id="BP21A2Form-jabatan"
          disabled={this.state.npwpNotValid}
          component={Textfield}
          validate={validation.required}
        />
      </Grid>
    )
  }
}
