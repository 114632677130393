import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  Tab,
  Tabs,
  SelectionControlGroup,
  Checkbox,
  Button
} from 'react-md';

import FormView from '../../../../../components/entity/form.view';
import BP21SSPService from './BP_21_SSP.service';
import {validation, Textfield, Searchfield, Switch, Datepicker, TextfieldMask, convert} from '../../../../../components/form';
import { SearchField } from 'react-mpk';
import Master21KapService from './../Master21Kap/Master21Kap.service';
import Master21KjsService from './../Master21Kjs/Master21Kjs.service';
import Master21SspDescriptionService from './../Master21SspDescription/Master21SspDescription.service';
import FormViewSpt from '../../../../../components/entity/form.view.spt';
import FileLapor21Service from '../FileLapor21/FileLapor21.service';
import ColumnService from '../../../../../services/column.service';

@reduxForm({form: 'BP_21_SSP_Form', destroyOnUnmount: true})
@connect((state) => ({
  access: state.entity.access.api.find,
  organization: state.authEppt.currentOrganization,
  spt: state.authEppt.currentSPT,
  formData: state.form.BP_21_SSP_Form
}))
export default class BP21SSPForm extends FormViewSpt {
  service=BP21SSPService
  viewType =2;

  constructor(props){
    super(props)
    this.state = {
      ...this.state,
      kjs: [],
      formData: {}
    }
  }

  _barItem() {
    return (
        <div>
            <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>Back</Button>
        </div>
    )
  }

  async initData() {
    if(this.props.match.params.id == 'new') {
      // await this.getSummary()
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);
      res.data.kap = res.data.kjs.kap
      // await this.getSummary()
      this.props.initialize(res.data);
    }
  }

  async getSummary() {
    const sptId = this.props.match.params.sptId;
    var res = await FileLapor21Service.api.getSummary(sptId);
    this.setState({formData: res.data})
  }

  async handleSave(value) {
    try {
      this.setState({onProgress: true})
      if(value.id) {
        await this.service.api.update(value)
      } else {
        value.spt = { id: this.props.spt.data.id }
        await this.service.api.save(value)
      }
      this.setState({onProgress: false})

      var pathArray = this.props.location.pathname.split('/');
      var path = '';
      pathArray.forEach((d, i)=> {
        if(pathArray.length-1 != i) {
          path = path+d;
          if(i != pathArray.length-2) path = path+'/'
        }
      })

      this.props.history.push(path)
    } catch(e) {
      var msg = e.message;
      if(e.response) msg = e.response.data.message;
      this.setState({
        isError: true,
        onProgress: false,
        errorMessage: msg
      })
    }
  }

  async findKjs(evt, value){
    var kapId = value.id;
    var kjs = await Master21KjsService.api.findByKap(kapId);
    this.setState({ kjs: kjs })
  }

  formView() {
    var isEdit = false
    if(this.props.match.params.id != "new"){
      isEdit = true
    }
    return (
      <div>
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='PPH TERUTNG YANG TELAH DIBAYAR' />
          <Divider/>

          <div className='md-grid'>
            <div className="md-cell md-cell--6">
              <Field
                label='Kode Jenis Pajak'
                name='kap'
                component={Searchfield}
                service={Master21KapService}
                validate={validation.required}
                onChange={this.findKjs.bind(this)}
                viewField="code"
                valueField="parent"

                itemTemplate={function(d) {
                  return {
                    primaryText: `${d.code}`,
                    onClick: () => {
                      if(!this.props.disabled){
                        this.handleItemClick(d); this.hide()
                      }
                    }
                  }
                }}
              />
              <Field
                label='Kode Jenis Setoran'
                name='kjs'
                component={Searchfield}
                validate={validation.required}
                rerender={true}
                options={this.state.kjs}
                viewField="code"
                valueField="parent"

                itemTemplate={function(d) {
                  return {
                    primaryText: `${d.code} - ${d.name}`,
                    onClick: () => {
                      if(!this.props.disabled){
                        this.handleItemClick(d); this.hide()
                      }
                    }
                  }
                }}
              />
              <Field
                label='Tanggal'
                name='date'
                component={Datepicker}
                validate={validation.required}
              />
            </div>

            <div className="md-cell md-cell--6">
                <Field
                    label='NPTN'
                    name='ntpn'
                    component={Textfield}
                    mask="_"
                    length={16}
                    maxLength={16}
                    maskFormat="################"
                    validate={validation.required}
                    disabled={isEdit}
                />
                <Field
                    label='Jumlah Disetor'
                    name='total'
                    component={TextfieldMask}
                    money={true}
                    normalize={convert.money}
                    validate={validation.required}
                />
                <Field
                    label='Keterangan'
                    name='description'
                    component={Searchfield}
                    valueField="parent"
                    viewField="name"
                    service={Master21SspDescriptionService}
                    validate={validation.required2}
                    itemTemplate={function(d) {
                      return {
                        primaryText: `${d.code} - ${d.name}`,
                        onClick: () => {
                          if(!this.props.disabled){
                            this.handleItemClick(d); this.hide()
                          }
                        }
                      }
                    }}
                />
            </div>

            <div className="md-cell md-cell--12">
                <ul>
                  <li>0 : Untuk SSP</li>
                  <li>1 : Untuk SSP PPh Pasal 21 Ditanggung Pemerintah</li>
                  <li>2 : Untuk PBK</li>
                </ul>
            </div>
          </div>
        </Card>
        <br/>
      </div>
    )
  }

}
