import React, { Component } from 'react';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import { validation, Textfield, TextfieldMask, Datepicker, convert } from '../../../components/form';
import ImportEbillingService from './ImportEbilling.service';
import ImportDialogTemplateView from '../../../components/entity/import.dialog.template.view2';

@reduxForm({form: 'ImportEbilling-form', destroyOnUnmount: false})
export default class ImportEbillingDialogView extends ImportDialogTemplateView {
  service=ImportEbillingService
  pasal='ebilling'

  async fetchData(props) {
    var param = {
      page: this.state.pageNumber - 1,
      size: this.state.size,
      sortBy: 'line',
      sort: 'line,asc',
      'uploadId.equals': props.formData.id,
    }

    let id = props.formData.id

    var res = await ImportEbillingService.api.getData(id, param, this.pasal)

    res.result = _.sortBy(res.result, ['line'])

    this.setState({ data: res.data, total: parseInt(res.headers['x-total-count']) })
  }
}
