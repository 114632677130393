import ApiService from '../../../../../services/api.service';

class UserAccess extends ApiService {
  name= 'UserAccess';
  path= 'access/npwp';
  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    updateOk: async(res, id)=> {
      var res = await this.http.put(`${this.apiLocation}/access/npwp/${id}`, res);
      return res;
    },
    getAccess: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/access`);
      return res;
    },
    cekAccess: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/access/check`);
      return res;
    },
    putSettingAccess: async (form)=> {
      var res = await this.http.put(`${this.apiLocation}/setting/access`, form);
      return res;
    },
    getSettingAccess: async (companyId)=> {
      if(companyId){
        var res = await this.http.get(`${this.apiLocation}/setting/access/${companyId}/company`);
        return res;
      } else {
        return null
      }
    }
  }
}

export default new UserAccess();
