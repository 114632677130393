import ApiService from '../../../../../services/api.service';
import http from "../../../../../services/http.service";

class BP4a2Service extends ApiService {
  name= 'BP4a2';
  path= 'pasal4/bp';

  api= {
    save: async (data) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}`, data, {
        params: {
          blockBunifikasiMonth: data.blockBunifikasiMonth,
          blockBunifikasiYear: data.blockBunifikasiYear
        }
      })
      return res;
    },
    update: async (data) => {
      var res = await this.http.put(`${this.apiLocation}/${this.path}`, data, {
        params: {
          blockBunifikasiMonth: data.blockBunifikasiMonth,
          blockBunifikasiYear: data.blockBunifikasiYear
        }
      })
      return res;
    },
    getTarif: async () => {
      return await this.http.get(`${this.apiLocation}/pasal4/rates/bp4a2`)
    },
    getNumberBP: async (sptId, type) => {
      return await http.get(`/pasal4/spt/no/${sptId}`);
    },
    calculate: async (param) => {
      var res = await this.http.post(this.apiLocation+'/pasal4/calculation/bp-4a2', param)

      return res.data;
    },
    total: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/total`)
      return res.data;
      // return {};
    },
    cetak: async (ids) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${ids[0]}/report`)

      return res;
    },
    cetakBulk: async (sptId, ids) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/report`, ids)

      return res;
    },
    cetakBulkPisah: async (sptId, ids) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/report/pisah`, ids)
      return res;
    },
    cetakBulkMerge: async (ids) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/report/bulk/merge`, ids, {
        responseType: 'blob'
      })

      return res;
    },
    downloadCsv: async (sptId, type)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/spt/export`, {
        params: {
          type : type
        }
      })

      return res;
    },
    cetakDaftar: async (sptId, type) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/spt/report-list`)

      return res;
    },
    email: async (category, ids) => {
      return await this.http.post(`${this.apiLocation}/pasal4/email?category=${category}`, ids)
    },
    emailSpt: async (category, sptId, ids, companyId) => {
      if(ids.length == 1){
        return await this.http.get(`${this.apiLocation}/pasal4/bp/${ids[0]}/mail`, {
          params: {
            companyId: companyId
          }
        })
      } else {
        return await this.http.post(`${this.apiLocation}/pasal4/bp/mail`, ids, {
          params: {
            companyId: companyId
          }
        })
      }
    },
    deleteBulk: async (sptId, ids)=> {
      let res = await http.post(`${this.apiLocation}/${this.path}/delete`, ids);

      return res;
    },
  }

  constructor() {
    super()
    this.init()
    this.initApi()
  }
}


export default new BP4a2Service();
