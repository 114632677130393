import React from 'react';
import { SearchField, CompanyCard, Companies, LoaderInfo, rdxConnect, Pagination, navService } from 'react-mpk';
import hosts from './../../../services/hosts'
import companyService from './../../../services/companyService'
import { SelectField } from 'react-md';
import t from 'counterpart';
import _ from 'lodash';

class SearchCompany extends React.Component{
  constructor(){
    super();
    this.state = {
      data: [],
      hasMore: true,
      isLoading: false,
      query:{
        page: 1,
        size: 20,
        column: 'name',
        keyword: ''
      }
    }
  }

  componentDidMount(){
    this.fetchData();
  }

  onSelectCompany = item => {
    if(item.subscriptions.length > 1){
        navService.redirectTo(`/company/${item.id}/init`)
    } else if(item.subscriptions.length == 1) {
        navService.redirectTo(`/company/${item.id}/${item.subscriptions[0].id}/init`)
    }
  }

  fetchData = async (newQuery={}, clearData=true, isNext=false) => {
    let { query } = this.state;
    let data = [];
    query = _.merge(query, newQuery);
    // if(isNext) query.page += 1;
    // if(clearData) {
    //   query.page = 1;
    //   data = []
    // }
    this.setState({query, isLoading: true, data}, async () => {
      try{
        let res = await companyService.get(query, item => {
          data.push(item);
          this.setState({data})
        })
        query.total = Number(res.headers['x-pagination-count']);
        setTimeout(() => this.setState({isLoading: false, query}),1000);
        
      }catch(error){
        this.props.toastActions.izi(
          t.translate('word.failed'),
          error.message[this.props.global.localeId],
          'warning'
        )
      }
    });
  }

  onChangeColumn = value => {
    let { query } = this.state;
    query.column = value;
    this.setState({query});
  }

  render(){
    let { host } = hosts.getAll().iam;
    let { query, data, hasMore, isLoading } = this.state;
    let searchbar = (
      <div 
        className="mpk-padding-N padding-all flex-none" 
        style={{background: 'white'}}
      >
        <div className="mpk-layout mpk-center-container flex">
          <SelectField
            id="select-company-filter"
            className="min-width-N mpk-margin-N margin-right"
            label={t.translate('word.searchBy')}
            value={query.column}
            onChange={ value => this.onChangeColumn(value)}
            menuItems={[
              {label: 'Nama', value: 'name'},
              {label:'NPWP', value: 'npwp'}
            ]}
          />
          <SearchField 
            className="flex"
            label={t.translate('word.keyword')}
            placeholder={t.translate('custom.sentences.searchCompany')}
            onSubmit={ keyword => this.fetchData({keyword, page: 1})}
          />
          <Pagination
            onChange={ page => this.fetchData({page})}
            {...query}
          />
        </div>
      </div>
    )

    let subscriptions = (
      <div 
        className="flex mpk-content" 
        id="subs-container"
      >
        <div 
          className="mpk-center-container"
        >
          { isLoading ? (<LoaderInfo/>) : (
            <Companies 
              className="mpk-animation slide-in"
              onSelectCompany={this.onSelectCompany}
              companies={data}
              ignoreSelectSubscription={false}
              footer={null}
              showGreeting={false}
            />
          )}
        </div>
      </div>
    )

    return(
      <div className="search-company mpk-full height width mpk-layout column">
        {searchbar}
        {subscriptions}
      </div>
    )
  }
}

export default rdxConnect(SearchCompany);