import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {Field, getFormValues, reduxForm} from 'redux-form';
import {Card, Switch, Button, LinearProgress, Chip} from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Multiselectv2, Switch as Switch2} from '../../../../../components/form';

import LogConvertedExcelService from './LogConvertedExcel.service';
import ListView from '../../../../../components/entity/listView';
import ColumnService from '../../../../../services/column.service';
import axios from 'axios';
import izitoast from 'izitoast';
import download from "downloadjs";

@reduxForm({form: 'LogConvertedExcel', destroyOnUnmount: true})
@connect((state) => ({
  ...LogConvertedExcelService.stateConnectSetting()(state),
  formData: getFormValues('LogConvertedExcel')(state),
  user: state.auth.user,
  // EPPT CORE
  auth: state.auth.company
  // EPPT CORE
}), LogConvertedExcelService.actionConnectSetting())
export default class LogConvertedExcel extends ListView {
    service=LogConvertedExcelService
    minusPage=true

    static contextTypes = {
        showDialog: PropTypes.func.isRequired,
    };

    beforeFetch(params) {
        params.pasal = this.props.pasal;
        delete params.column
        delete params.keyword
        delete params.startDate
        delete params.endDate
        params.sort = `${params.sortBy},${params.sort}`
        try {
            if(params['status.in']){
                params['status.in'] == this.props.tableFilter2.status__in
            }
        } catch(e){}
        return params
    }

    _tableActions = [
        {
          label:"Download",
          iconClassName:"mdi mdi-file",
          onClick: (item) => this.downloadItem(item)
        },
        {label:"divider", iconClassName:"-"},
        {
          label:"Delete",
          iconClassName:"mdi mdi-delete",
          onClick:(item, callback) => this.deleteItem(item, callback),
          confirmation:{
            title:"sentence.custom.deleteItem",
            message:"sentence.custom.deleteItemConfirmation"
          }
        }
    ]

    defaultSearchForm(){
        return (
            <div style={{ width: '100%' }}>
                <Field
                    label     = 'NPWP'
                    name      = 'npwpOrganisasi__contains'
                    className = "md-cell md-cell--12"
                    component = {Textfield}
                />
                <Field
                    label     = 'Tahun'
                    name      = 'years__equals'
                    className = "md-cell md-cell--12"
                    component = {Textfield}
                />
                <Field
                    label     = 'Masa'
                    name      = 'month__equals'
                    className = "md-cell md-cell--12"
                    component = {Searchfield}
                    options={[
                        {id: 1, name: "1"},
                        {id: 2, name: "2"},
                        {id: 3, name: "3"},
                        {id: 4, name: "4"},
                        {id: 5, name: "5"},
                        {id: 6, name: "6"},
                        {id: 7, name: "7"},
                        {id: 8, name: "8"},
                        {id: 9, name: "9"},
                        {id: 10, name: "10"},
                        {id: 11, name: "11"},
                        {id: 12, name: "12"}
                    ]}
                />
                <Field
                    label     = 'Rev'
                    name      = 'pembetulan__equals'
                    className = "md-cell md-cell--12"
                    component = {Textfield}
                    type      = 'number'
                />
                <this.ig.Field
                    label='WAITING'
                    name='status__in_WAITING'
                    className="md-cell md-cell--6"
                    component={this.ig.Checkbox}
                />
                <this.ig.Field
                    label='PROCESS'
                    name='status__in_PROCESS'
                    className="md-cell md-cell--6"
                    component={this.ig.Checkbox}
                />
                <this.ig.Field
                    label='FINISH'
                    name='status__in_FINISH'
                    className="md-cell md-cell--6"
                    component={this.ig.Checkbox}
                />
                <this.ig.Field
                    label='FAILED'
                    name='status__in_FAILED'
                    className="md-cell md-cell--6"
                    component={this.ig.Checkbox}
                />
            </div>
        )
    }

    async goBack() {
        this.props.history.push(`/company/${this.props.match.params.companyId}/${this.props.match.params.subscriptionId}/init/log-file`)
    }

    async downloadSelected() {
        let selected = this.props.table.selected

        if (selected.length) {
            let ids = []

            selected.forEach(sel => {
                ids.push(sel.id)
            })

            let res = await this.service.api.download(ids)

            res.data.forEach(x => {
                let win = window.open(x.url, '_blank');
                win.focus();
            })
        } else {
            izitoast.warning({
                title: 'Download',
                message: 'Silahkan pilih minimal 1 data yang ingin diunduh.'
            })
        }
    }

    _barActions = [
      {
        label:'word.back',
        iconClassName:'mdi mdi-arrow-left',
        onClick:() => this.goBack()
      },
      {
        label:'word.refresh',
        iconClassName:'mdi mdi-refresh',
        onClick:() => this.fetchData()
      },
      {
        label:'word.download',
        iconClassName:'mdi mdi-file',
        onClick:() => this.downloadSelected(),
        disabledFunc:() => this.props.table.selected.length === 0
      },
    ]

    columns=[
        {isSortable: true, label: "word.npwp", searchField: 'npwpOrganisasi', type: "func", value: (d) => {
            return d.npwpOrganisasi;
          }, show: true, isSearchable: true},
        {isSortable: true, label: "word.year", searchField: 'years', type: "func", value: (d) => {
            return d.years;
          }, show: true, isSearchable: true},
        {isSortable: true, label: "word.month", searchField: 'month', type: "func", value: (d) => {
            return d.month;
          }, show: true, isSearchable: true},
        {isSortable: true, label: "word.pembetulan", searchField: 'pembetulan', type: "func", value: (d) => {
            return d.pembetulan;
          }, show: true, isSearchable: true},
        {
          label: "word.status",
          type: 'func',
          value: (d)=> {
            var style = {
              color: 'white',
              backgroundColor: 'grey'
            }
            switch (d.status) {
              case 'PROCESS':
                style.backgroundColor = '#2196F3'
                break;
              case 'FINISH':
                style.backgroundColor = '#4CAF50'
                break;
              case 'FAILED':
                style.backgroundColor = '#f44336'
                break;
              default:
                break;
            }
            return <Button flat swapTheming label={d.status} style={style}/>
          }, className: "mpk-font-size-S", show:true, isSearchable:true},
        {isSortable: true, label: "word.message", value: "message", searchField: 'message', show: true, isSearchable: true},
        {isSortable: true, label: "word.fileName", value: "fileName", searchField: 'fileName', show: true, isSearchable: true},
        {isSortable: true, label: "word.fileSize", value: "fileSize", searchField: 'fileSize', show: true, isSearchable: true},
        {isSortable: true, label: "word.filePath", value: "filePath", searchField: 'filePath', show: false, isSearchable: true},
        {isSortable: true, label: "word.username", searchField: 'username', type:'func',  value: (d)=> {
            return d.logCsv.username;
          }, isDefaultSort:false, show:true, isSearchable:false},
    ]
}
