import React, { Component } from 'react';
import { connect } from 'react-redux';

import ListView from '../../../../../components/entity/listView';
import ColumnService from '../../../../../services/column.service';
import TextField, { Button, Divider, Chip, Card, CardTitle, CardActions, DataTable, TableHeader, TableBody, TableRow, TableColumn, Switch, Paper } from 'react-md/lib';
import {validation, Searchfield, TextfieldMask, Datepicker} from '../../../../../components/form';
import { Field, reduxForm } from 'redux-form';
import download from 'downloadjs';
import uuid from 'uuid';
import iziToast from 'izitoast';
import FileLapor23Service from './FileLapor23.service';
import ExportCsv23View from './../Export/ExportCsv23/ExportCsv23.view'

@reduxForm({form: 'FILE_LAPOR_23', destroyOnUnmount: true})
@connect((state)=> ({
  ...FileLapor23Service.stateConnectSetting()(state),
  spt: state.authEppt.currentSPT
}), FileLapor23Service.actionConnectSetting())
export default class FileLapor23View extends ListView {
  service=FileLapor23Service

  constructor(props){
    super(props);
    this.state = {
      showTableSetting:false,
      showDialogConfirmDeleteSelected:false,
      showForm:false,
      formData: {
        "jumlah23": 0,
        "jumlah26": 0,
        "jumlahTerbayar23": 0,
        "jumlahTerbayar26": 0,
        "ssp23s": [
          // {
          //   "jumlah": 0,
          //   "map": "string",
          //   "terbayar": 0
          // }
        ],
        "ssp26": [
          // {
          //   "jumlah": 0,
          //   "map": "string",
          //   "terbayar": 0
          // }
        ]
      },
      data: [],
      cetak: false
    }
  }

  columns=[]
  _barActions=[]
  _barItem() {}

  async componentDidMount(){
    var sptId = this.props.spt.data.id
    var res = await this.service.api.getSummary(sptId);
    this.setState({formData : res.data});
    this.props.initialize(res.data)
  }

  async handleCetak(){
    this.setState({ cetak: true })

    var report = null;

    var sptId = this.props.spt.data.id
    var res = await this.service.api.cetakTest(sptId);
    if(res.data.success) {
      report = await this.service.api.cetak(res.data.id)
    } else {
      iziToast.warning({"title": "Warning", "message": res.data.message})
    }

    if(report) {
      try {
        var filename = report.headers.filename;
        download(report.data, filename);
        this.setState({ cetak: false })
      } catch (e){
        console.log(e)
        window.haahha = e
        this.setState({ cetak: false })
        iziToast.warning({"title": e.name, "message": e.message})
      }
    }
  }

  _barItem() {
    return (
      <div>
        {/* <Button disabled={this.state.cetak} primary raised style={{margin: 5}} onClick={this.handleCetak.bind(this)}>Unduh File Lapor</Button> */}
      </div>
    )
  }

  _tableView(props) {
      return (
        // <div className='mpk-layout row'>
        //   <br/>
        <div className='flex'>
          <Card style={{marginBottom: 32, marginRight: 10}}>
            {/* <CardTitle title='' style={{padding: '10px 16px'}} subtitle=''/> */}
            <Divider/>
              <DataTable plain baseId="simple-selectable-table">
                  <TableBody>
                    <TableRow>
                      <TableColumn>Jumlah PPh Terutang</TableColumn>
                      <TableColumn className='md-text-right'>
                        { ColumnService.money(this.state.formData.pph)  }
                      </TableColumn>
                    </TableRow>

                    <TableRow>
                      <TableColumn>Jumlah SSP Yang Telah Dibayar</TableColumn>
                      <TableColumn className='md-text-right'>
                        { ColumnService.money(this.state.formData.ssp)  }
                      </TableColumn>
                    </TableRow>

                    <TableRow>
                      <TableColumn>Jumlah PBK</TableColumn>
                      <TableColumn className='md-text-right'>
                        { ColumnService.money(this.state.formData.pbk)  }
                      </TableColumn>
                    </TableRow>

                    <TableRow style={{borderTop: 'solid 3px #555'}}>
                      <TableColumn><b>Total</b></TableColumn>
                      <TableColumn className='md-text-right'>
                        <b>{ ColumnService.money(this.state.formData.total)  }</b>
                      </TableColumn>
                    </TableRow>
                  </TableBody>
              </DataTable>
              <ExportCsv23View></ExportCsv23View>
            </Card>
          </div>
      )
  }
}
