import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  Tab,
  Tabs,
  SelectionControlGroup,
  Button
} from 'react-md';

import {validation, Textfield, Searchfield, Checkbox, TextfieldMask, Switch, Datepicker, Radio, convert, CheckboxSelection} from '../../../../../components/form';
import UtilService from '../../../../../services/utils.service'
import FormView from '../../../../../components/entity/form.view';
import DialogView from '../../../../../components/entity/dialog.view';
import LogSubmitService from './LogSubmit.service'
import moment from 'moment'
import counterpart from 'counterpart'
import UserService from './../User/User.service'

@reduxForm({form: 'LogSubmitForm', destroyOnUnmount: true})
@connect((state) => ({
  formData:state.form.LogSubmitForm,
  user: state.auth.user
}))
export default class LogSubmitFormView extends FormView {
  service=LogSubmitService
  viewType=2;

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;

    return (
      <div>
        <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>Back</Button>
      </div>
    )
  }

  formView() {
    return (
        <Card>
            <div className='md-grid'>
                <Field
                    label='XML ID'
                    name='xmlId'
                    className="md-cell md-cell--6"
                    component={Textfield}
                    validate={validation.required}
                    disabled={true}
                />
                <Field
                    label='SPT ID'
                    name='esptId'
                    className="md-cell md-cell--6"
                    component={Textfield}
                    validate={validation.required}
                    disabled={true}
                />
                <Field
                    label='File'
                    name='file'
                    className="md-cell md-cell--6"
                    component={Textfield}
                    validate={validation.required}
                    disabled={true}
                />
                <Field
                    label='Log File Id'
                    name='logFileId'
                    className="md-cell md-cell--6"
                    component={TextfieldMask}
                    maskFormat="##.###.###.#-###-###"
                    mask="_"
                    length="15"
                    validate={validation.required}
                    disabled={true}
                />
                <Field
                    label='Response Code'
                    name='responseCode'
                    className="md-cell md-cell--6"
                    component={Textfield}
                    validate={validation.required}
                    disabled={true}
                />
                {this.props.formData && this.props.formData.values && this.props.formData.values.success &&
                  <Field
                      label='Success'
                      name='success'
                      className="md-cell md-cell--3"
                      component={Checkbox}
                      validate={validation.required}
                      disabled={true}
                  />
                } 
                {this.props.formData && this.props.formData.values && !this.props.formData.values.success &&
                  <Field
                      label='Failed'
                      name='Failed'
                      className="md-cell md-cell--3"
                      component={Checkbox}
                      validate={validation.required}
                      disabled={true}
                  />
                } 
                <Field
                    label='Response Message'
                    name='responseMsg'
                    className="md-cell md-cell--12"
                    component={Textfield}
                    validate={validation.required}
                    disabled={true}
                />
            </div>
        </Card>
    )
  }
}
