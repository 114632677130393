import ApiService from '../../../../../../services/api.service';

class Master4a2KjsService extends ApiService {
  name= 'Master4a2Kjs';
  path= 'pasal4/spt/kjs';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    findByKap: async (kapId) => {
      var res = await this.http.get(`${this.apiLocation}/pasal4/spt/kjs/${kapId}/kap`)

      return res.data;
    }
  }
}


export default new Master4a2KjsService();

