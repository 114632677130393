import ApiService from '../../../../services/api.service';

class LogExportEbupotService extends ApiService {
  name= 'LogExportEbupot';
  path= 'log/export-pdf';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    findByPasal: async (params)=> {
      var url = null;
      if(params.pasal == 'bp'){
        url = `${this.apiLocation}/ebupot/log/export-bp`
      } else if(params.pasal == 'pdfBp'){
        url = `${this.apiLocation}/ebupot/log/export-pdf-bp`
      } else if(params.pasal == 'pdfBpe'){
        url = `${this.apiLocation}/ebupot/log/export-pdf-bpe`
      } else if(params.pasal == 'pdfInduk') {
        url = `${this.apiLocation}/ebupot/log/export-pdf-induk`
      }
      var res = await this.http.get(`${url}`, {
        params: params
      })
      return res;
    },
    deletePerPasal: async (id, pasal)=> {
      var url = null;
      if(pasal == 'bp'){
        url = `${this.apiLocation}/ebupot/log/export-bp/${id}`
      } else if(pasal == 'pdfBp'){
        url = `${this.apiLocation}/ebupot/log/export-pdf-bp/${id}`
      } else if(pasal == 'pdfBpe'){
        url = `${this.apiLocation}/ebupot/log/export-pdf-bpe/${id}`
      } else if(pasal == 'pdfInduk') {
        url = `${this.apiLocation}/ebupot/log/export-pdf-induk/${id}`
      }
      var res = await this.http.delete(`${url}`)
      return res;
    },
    deleteAllPerPasal: async (ids, pasal)=> {
      var url = null;
      if(pasal == 'bp'){
        url = `${this.apiLocation}/ebupot/log/export-bp/delete`
      } else if(pasal == 'pdfBp'){
        url = `${this.apiLocation}/ebupot/log/export-pdf-bp/delete`
      } else if(pasal == 'pdfBpe'){
        url = `${this.apiLocation}/ebupot/log/export-pdf-bpe/delete`
      } else if(pasal == 'pdfInduk') {
        url = `${this.apiLocation}/ebupot/log/export-pdf-induk/delete`
      }
      var res = await this.http.post(`${url}`, ids)
      return res;
    },
    downloadPerPasal: async (pasal, data)=> {
      var url = null;
      if(pasal == 'bp'){
        url = `${this.apiLocation}/ebupot/log/export-bp/download`
      } else if(pasal == 'pdfBp'){
        url = `${this.apiLocation}/ebupot/log/export-pdf-bp/download`
      } else if(pasal == 'pdfBpe'){
        url = `${this.apiLocation}/ebupot/log/export-pdf-bpe/download`
      } else if(pasal == 'pdfInduk') {
        url = `${this.apiLocation}/ebupot/log/export-pdf-induk/download`
      }
      var res = await this.http.post(`${url}`, data)
      return res;
    }
  }
}


export default new LogExportEbupotService();
