import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues} from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress,
  List,
  ListItem,
  FontIcon,
  DialogContainer
} from 'react-md';
import FormView from '../../../components/entity/form.view';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox} from '../../../components/form/index';
import EditPasswordService from './EditPassword.service';
import iziToast from 'izitoast';

@reduxForm({form: 'editPassword', destroyOnUnmount: true})
@connect((state) => ({
  access      : state.entity.access.api.find,
  formData    : getFormValues('editPassword')(state)
}))
export default class EditPasswordForm extends FormView {
  service  = EditPasswordService
  viewType = 2;

  translate = false

  titleHeader() {
    return 'Edit Password';
  }

  initialData={
  }

  constructor(props) {
    super(props);

    this.state= {
      ...this.state,
    }
  }

  async handleSave() {
    let formData = this.props.formData

    try {
      let res = await this.service.api.update(formData)

      iziToast.success({
        title: 'Sukses',
        message: 'Password sukses diubah.'
      })
    } catch (e) {
      let message = 'Password gagal diubah.'

      iziToast.warning({
        title: 'Gagal',
        message: message
      })
    }
  }

  async initData() {
    this.props.initialize({});
  }

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;

    return (
      <div>
        <Button raised primary onClick={() => this.handleSave()}>Save</Button>
      </div>
    )
  }

  formView() {
    var formData                      = {};

    if (this.props.formData) formData = this.props.formData.values

    return (
      <div>
        <Card>
          <div className='md-grid'>
            <div className="md-cell md-cell--6">
              <Field
                label='Password'
                name='password'
                type='password'
                component={Textfield}
                validate={validation.required}
              />

              <Field
                label='Konfirmasi Password'
                name='passwordConfirmation'
                type='password'
                component={Textfield}
                validate={validation.required}
              />
            </div>
          </div>
        </Card>
      </div>
    )
  }

}
