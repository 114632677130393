import React, { Component } from 'react';
import { connect } from 'react-redux';
import Bluebird from 'bluebird';
import moment from 'moment';

import { Field, reduxForm } from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2, Searchfieldv2} from '../../../../components/form';

import ListView from '../../../../components/entity/listView';
import axios from 'axios';
import izitoast from 'izitoast';
import download from "downloadjs";
import FileUpload15Service from './FileUpload15.service';
import OrganizationService from '../../../Main/Administrator/Organization/Organization.service';

@reduxForm({form: 'FileUpload15_Form', destroyOnUnmount: true})
@connect((state)=> ({
  ...FileUpload15Service.stateConnectSetting()(state),
  // user: state.authEppt.user,
  company: state.authEppt.currentCompany,
  // EPPT CORE
  user: state.auth.user,
  auth: state.auth.company
  // EPPT CORE
}),FileUpload15Service.actionConnectSetting())
export default class FileUpload15View extends ListView {
  service=FileUpload15Service
  translate=false
  FormDialog=()=> <div/>

  titleHeader() {
    return `Signer Image`;
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='ID'
          name='id__equals'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Nama File'
          name='originalFilename__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
      </this.SearchContainer>
    )
  }

  defaultColumns = [
    {isSortable:true, label: "word.id",  value: "id", show:true, isSearchable:true, isDefaultSort: false},
    {isSortable:true, label: "word.createdBy",  value: "createdBy", show:true, isSearchable:true},
    {isSortable:true, label: "word.createdDate", value: "createdDate", show:true, isSearchable:true, type:"date", isDefaultSort: true},
    {isSortable:true, label: "word.lastModifiedBy",  value: "lastModifiedBy", show:true, isSearchable:true},
    {isSortable:true, label: "word.lastModifiedDate", value: (d)=> {
      if(d.lastModifiedDate) {
        return <div className='mpk-font-size-S'>{moment(d.lastModifiedDate).format('lll')}</div>
      } else {
        return null;
      }
    }, show:true, isSearchable:true, type:"func", searchField: 'lastModifiedDate', isDefaultSort: true}
  ]

  // fetchOption() {
  //   return {
  //     path: `file/upload/${this.props.company.id}/company`
  //   }
  // }

  beforeFetch(params) {
    // params["category.equals"] = "EFILING"
  }

  async uploadForm() {
    var org = []
    if(this.props.auth && this.props.auth.organizations) org = this.props.auth.organizations
    var isSupport = false
    if(this.props.user && this.props.user.role && this.props.user.role.name == "SUPPORT_AGENT") isSupport = true
    var dialog = await this.context.showDialog((props, res, rej) =>({
      title: 'Upload',
      initialValue: {
        category: 'TTD'
      },
      height: 'auto',
      width: 400,
      okText: 'Ya',
      text: (
        <div className="md-grid">
          {/*{!isSupport &&
            <Field
              label     = 'Organization'
              name      = 'organization'
              id        = "spt2126-organization"
              className = "md-cell md-cell--12"
              component = {Searchfield}
              valueField = 'parent'
              options   = {org}
              validate  = {validation.required}
              itemTemplate = {function(d) {
                let nameAndAlias = ""
                if (d.name)
                  nameAndAlias = d.name
                if (d.aliasName)
                  nameAndAlias += " - " + d.aliasName
                return {
                  primaryText  : nameAndAlias,
                  secondaryText: d.npwp,
                  onClick      : () => {
                    if(!this.props.disabled){
                      this.handleItemClick(d); this.hide()
                    }
                  }
                }
              }}
            />
          }
          {isSupport &&
            <Field
                label         = 'Organization'
                name          = 'organization'
                className     = "md-cell md-cell--12"
                component     = {Searchfieldv2}
                valueField    = 'parent'
                remoteSearch  = {true}
                apiPath       = 'getAll'
                params        = {{ user: this.props.user }}
                service       = {OrganizationService}
                validate      = {validation.required}
                renderFirst   = {true}
                validate      = {validation.required}
                coreSearch    = {true}
                npwpSearch    = {true}
                itemTemplate  = {function(d) {
                  let nameAndAlias = ""
                  if (d.name)
                    nameAndAlias = d.name
                  if (d.aliasName)
                    nameAndAlias += " - " + d.aliasName
                  return {
                    primaryText  : nameAndAlias,
                    secondaryText: d.npwp,
                    onClick      : () => {
                      if(!this.props.disabled){
                        this.handleItemClick(d); this.hide()
                      }
                    }
                  }
                }}
            />
          }*/}
          <Field
            label='Select a File'
            name='files'
            multiple
            twoLines
            component={Fileinput}
            validate={validation.required}
          />
        </div>
      )
    }));

    if(dialog && dialog.files) {

      var files = []
      for (let index = 0; index < dialog.files.length; index++) {
        const element = dialog.files[index];
        files.push(element)
      }

      await Bluebird.mapSeries(files, async (d)=> {
        let formData = new FormData();
        formData.append("category", "TTD")
        // formData.append("npwp", dialog.organization.npwp)
        formData.append("file", d);

        await this.service.api.upload(formData);
      })
      await this.fetchData()
    }
  }

  async afterFetch(data){
    try {
      var getImages = await Promise.all(data.map((d)=> {
        return new Promise( async (resolve, reject)=> {
          var getImage = await this.service.api.getImage(d.id)
          var image = new Buffer(getImage.data, 'binary').toString('base64')
          d.image = image
          resolve(d)
        })
      }))
      getImages.then((values)=> {
        return values
      }).catch((e)=> {
        return data
      })
    } catch(e){
      return data
    }
  }

  columns = [
    {label: "word.img",  value: (d)=> {
      // var image  = new Image();
      // var encode = URL.createObjectURL(d.data);
      return <img src={ "data:"+d.contentType+";base64, " + d.image } style={{width: 'auto', height: 100}}/>
      //return "sadsad"
    }, show:true, isSearchable:true, type: 'func'},
    {label: "word.filename",  value: "originalFilename", show:true, isSearchable:true},
    {label: "word.fileType",  value: "contentType", show:true, isSearchable:true},
    // {label: "word.npwpProfile",  value: "npwpProfile", show:true, isSearchable:true},

  ]

  _barActions = [{
    label:'Upload',
    iconClassName:'mdi mdi-upload',
    onClick:() => {
      this.uploadForm()
    }
  },
  // {
  //   label:'word.delete',
  //   iconClassName:'mdi mdi-delete',
  //   onClick:() => {
  //     this.setState({showDialogConfirmDeleteSelected:true})
  //   },
  //   disabledFunc:() => this.props.table.selected.length === 0
  // },
  {
    label:'Refresh',
    iconClassName:'mdi mdi-refresh',
    onClick:() => {

      window.location.hash = window.location.hash.split('?')[0]
      this.fetchData()
    }
  },]

  tableActions= []
}
