import ApiService from '../../../../services/api.service';

class FileUpload15Service extends ApiService {
  name= 'FileUpload15';
  path= 'pasal15/upload/image';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api = {
    multiUpload: async (data)=> {
      var res = await this.http.post(this.apiLocation+`/pasal15/file/upload-multiple`, data);
      return res;
    },
    upload: async (data)=> {
      var res = await this.http.post(this.apiLocation+`/pasal15/upload/image`, data);
      return res;
    },
    getImage: async (id)=> {
      var res = await this.http.get(`${this.apiLocation}/pasal15/upload/image/${id}`, {
        responseType: 'arraybuffer'
      })
      return res
    }
  }
}

export default new FileUpload15Service();
