import React, { Component } from 'react';
import { connect } from 'react-redux';

import SettingEmailContentService from './SettingEmailContent.service';
import ListView from '../../../../components/entity/listView';
import ColumnService from '../../../../services/column.service';

@connect(SettingEmailContentService.stateConnectSetting(), SettingEmailContentService.actionConnectSetting())
export default class SettingEmailContentView extends ListView {
  service=SettingEmailContentService
  editDialog=false

  _barActions = [
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => this.fetchData()
    }
  ]

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    }
  ]

  editItem = (item) => {
    this.props.history.push("SETTING_EMAIL_CONTENT"+"/"+item.company.id)
  }

  columns=[
    {isSortable: true, label: "word.id", searchField: "id", value: function(d){ return d.id }, type: "func",  show:false, isSearchable:true},
    {isSortable: true, label: "word.company", searchField: "company", value: function(d){ return d.company.nama }, type: "func",  show:true, isSearchable:true},
    {isSortable: true, label: "word.subject", searchField: "subject", value: function(d){ return d.subject }, type: "func",  show:true, isSearchable:true},
    {isSortable: true, label: "word.limitPerDay", value: "limitPerDay", type: "number", show: true, isSearchable: true},
  ]
}
