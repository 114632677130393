import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues} from 'redux-form';
import moment from 'moment';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress,
} from 'react-md';
import { ExpansionList, ExpansionPanel, Slider } from 'react-md';

import FormView from '../../../../../components/entity/form.view'
import { validation, Textfield, Searchfield, Searchfieldv2, TextfieldMask, Switch, Datepicker, Radio, Checkbox, CheckboxSelection, convert } from '../../../../../components/form'
import Service from './Bupot23.service'
import DokumenForm from './Bupot23.dokumen.form.view'
import KodeBuktiPotongService from '../KodeBuktiPotong/KodeBuktiPotong.service'
import ProvincesService from '../Provinces/Provinces.service';
import KotaService from '../Regencies/Regencies.service'
import KecamatanService from '../Districts/Districts.service'
import KelurahanService from '../Villages/Villages.service'
import Signer from '../Signer/Signer.service'
import RegenciesService from '../Regencies/Regencies.service';
import DistrictsService from '../Districts/Districts.service';
import ESPT23Service from '../PPh/SPT23/SPT23.service'
import iziToast from 'izitoast';
import OrganizationService from '../Organization/Organization.service';
import LT23Service from '../LawanTransaksi23/LawanTransaksi23.service'
import LawanTransaksi23View from '../LawanTransaksi23/LawanTransaksi23.view'
import LawanTransaksi23Service from '../LawanTransaksi23/LawanTransaksi23.service';

// import NomorBPService from '../nomorBP.service';
// import SPT21SignerService from '../SPT21Signer/SPT21Signer.service';
// import NpwpService from './../../NpwpService';
// import FormViewSpt from '../../../../../components/entity/form.view.spt';
// import terbilangService from '../../../../../services/terbilang.service';
// import nomorBP23Service from './../nomorBPEbupot23.service'
// import LawanTransaksiDialog from '../../../../../components/entity/LawanTransaksi.dialog';
// import MasterLawanTransaksi23Service from '../../../Master/MasterLawanTransaksi23/MasterLawanTransaksi23.service';
// import Master23TarifService from '../../23_23/master/Tarif23/Master23Tarif.service';
// import BPDokumenForm from './BP_Ebupot_23.dokumen.form'

@reduxForm({form: 'Bupot_23_Form', destroyOnUnmount: true})
@connect((state) => ({
  //access      : state.entity.access.api.find,
  //organization: state.auth.currentOrganization,
  spt         : state.auth.currentSPT,
  // EPPT CORE
  user: state.auth.user,
  auth: state.auth.company,
  // EPPT CORE
  formData    : getFormValues('Bupot_23_Form')(state)
}))
export default class Bupot23Form extends FormView {
    service=Service
    viewType=2;

    constructor(props){
        super(props)
        this.state = {
            ...this.state,
            tahun: [],
            masa: [],
            kodes: [],
            isNik: false,
            propinsiId: "",
            kabupatenId: "", 
            kecamatanId: "",
            kelurahanId: "",
            note: "",
            organizationId: "",
            formSpt: true,
            edit: false
        }
    }

    masa = [
        { id: 1, name: '1' },
        { id: 2, name: '2' },
        { id: 3, name: '3' },
        { id: 4, name: '4' },
        { id: 5, name: '5' },
        { id: 6, name: '6' },
        { id: 7, name: '7' },
        { id: 8, name: '8' },
        { id: 9, name: '9' },
        { id: 10, name: '10' },
        { id: 11, name: '11' },
        { id: 12, name: '12' }
    ]

    initialData={
        identity: false,
        spt: {
            pembetulan: 0
        },
        fasilitas: 0
    }

    async beforeSave(val) {
        var newValue = Object.assign({}, val)
        var split = newValue.tgl.split('-')
        var tahun = split[0]
        var bulan = split[1]
        var tahunValidasi = newValue.spt.year + ""
        var masaValidasi = newValue.spt.month + ""
        if(bulan.split('')[0] == "0"){
            bulan = bulan.split('')[1]
        }
        if(tahunValidasi != tahun){
            iziToast.info({
                title: "Validasi",
                message: "Tahun pemotong tidak sesuai dengan tahun SPT seharusnya tahun = " + tahunValidasi
            })
            return false
        } else if(masaValidasi != bulan){
            iziToast.info({
                title: "Validasi",
                message: "Masa pemotong tidak sesuai dengan masa SPT seharusnya masa = " + masaValidasi
            })
            return false
        } else {
            // newValue.tgl = moment(newValue.tgl).format("YYYY-DD-MM")
            if(newValue.tarif == null){
                newValue.tarif = 0
            } 
            if(newValue.fasilitas == 0){
                newValue.noDtp = " "
                newValue.ntpn = " "
                newValue.noSkb = " "
                // newValue.tgl = moment(new Date()).format('YYYY-MM-DD')
            } else if(newValue.fasilitas == 1) {
                newValue.noDtp = " "
                newValue.ntpn = " "
            } else if(newValue.fasilitas == 2) {
                newValue.noSkb = " "
                // newValue.tgl = moment(new Date()).format('YYYY-MM-DD')
            }
            if(newValue.id){
    
            } else {
                newValue.seq = null
                newValue.rev = null
                newValue.no = null
                newValue.referensi = null
            }
            if(newValue.refs && newValue.refs.length == 0){
                izitoast.info({
                    title: "Validasi",
                    message: "dasar pemotongan wajib diisi."
                })
                return false
            } else {
                return newValue;
            }
        }
    }

    componentDidMount() {
        this.initData()
        // var tahun = new Date().getFullYear()
        // var tahuns = [
        //     { id: tahun, name: tahun + '' },
        //     { id: tahun - 1, name: tahun - 1 + '' },
        //     { id: tahun - 2, name: tahun - 2 + '' }
        // ]
        // var masa = []
        // var kodes = []
        // // kodeObjectPajak.map((d, i)=> {
        // //     kodes.push({ id: d.id, name: d.name })
        // // })        
        // this.setState({
        //     tahun: tahuns,
        //     masa: masa,
        //     kodes: kodes
        // })
        // try {
        //     this.props.change('spt.masa', this.props.spt.data.masa)
        //     this.props.change('spt.tahun', this.props.spt.data.tahun)
        // } catch(e){
        //     console.log(e)
        // }
    }
    
    async initData() {
        var spt = {}
        try {
            if(this.props.match.params.id != 'new'){
                spt = await ESPT23Service.api.findOne(this.props.match.params.sptId);
                this.setState({ organizationId: spt.data.organization.id })
            }
        } catch(e){}
        if(this.props.match.params.id == 'new') {
          var initData = this.initialData
          try {
            let getSigner = await Signer.api.getByOrganization(spt.data.organization.id)
            initData.npwpPenandatangan = getSigner.npwpPenandaTangan
            initData.namaPenandatangan = getSigner.namaPenandaTangan 
            initData.signAs = getSigner.signAs
          } catch(e){}
          this.props.initialize(initData);
        } else {
          this.setState({
              formSpt: false,
              edit: true
          })
          let res = await this.service.api.findOne(this.props.match.params.id);
          res.data.agree = true
          spt.data.pembetulan = res.data.spt.rev
          spt.data.masa = res.data.spt.month
          spt.data.tahun = res.data.spt.year
          spt.data.organization = {
              nama: res.data.spt.name,
              npwp: res.data.spt.npwp
          }
          this.props.initialize(res.data);
          try {
            let getPropinsi = await ProvincesService.api.getByName('name.contains='+res.data.provinsi)
            this.setState({  propinsiId: getPropinsi.id,  })
          } catch(e){}
          try {
            let getRegency = await RegenciesService.api.getByName('name.contains='+res.data.kabupaten)
            this.setState({ kabupatenId: getRegency.id })
          } catch(e){}
          try {
            let getDistrict = await DistrictsService.api.getByName('name.contains='+res.data.kecamatan)
            this.setState({ kecamatanId: getDistrict.id })
          } catch(e){}
        }
        this.props.change('spt', spt.data)
    }
    
    handleTahun(e, value){
        if(value == new Date().getFullYear()){
            this.setState({
                masa: [
                    { id: 1, name: '1' },
                    { id: 2, name: '2' },
                ]
            })
        } else {
            this.setState({ masa: this.masa })
        }
    }

    async handleNik(e, value){
        if(value == false){
            this.setState({ isNik: false })
        } else {
            this.setState({ isNik: true })
        }
        this.props.change('kode', null)
        this.props.change('tarif', 0)
    }

    async handleKode(e, value){
        try {
            let getKode = await KodeBuktiPotongService.api.getByKode('kode.contains='+value)
            if(this.props.formData && this.props.formData.identity){
                this.props.change('tarif', getKode.tarif)
            } else {
                this.props.change('tarif', getKode.tarif * 2)
            }
            this.setState({note: getKode.name})
        } catch(e){  console.log(e) }
    }

    async handlePenandatanganan(e, value){
        try {
            let ttd = await Signer.api.getByOrganizationQuery({
                active: true,
                'nama.contains': value,
                organizationId: this.state.organizationId
            })
            this.props.change('npwpPenandatangan', ttd.data[0].npwpPenandaTangan)
            this.props.change('signAs', ttd.data[0].signAs)
        } catch(e){  console.log(e) }
    }

    handleBruto(e, value){
        var tarif = 0
        if(this.props.formData && this.props.formData && this.props.formData && this.props.formData.tarif){
            tarif = this.props.formData.tarif
        }
        var pph = value * (tarif/100)
        this.props.change('pph', Math.floor(pph))
    }

    handleIsNik(e, value){
        this.props.change('npwp', " ")
        this.props.change('nik', value)
    }

    handleIsNpwp(e, value){
        this.props.change('npwp', value)
        this.props.change('nik', " ")
    }

    handleFasilitas(e, value){
        // if(value == 0){
        //     this.props.change('noDtp', " ")
        //     this.props.change('ntpn', " ")
        //     this.props.change('noSkb', " ")
        //     this.props.change('tgl', "2019-02-28")
        // } else if(value == 1) {
        //     this.props.change('noDtp', " ")
        //     this.props.change('ntpn', " ")
        // } else if(value == 2) {
        //     this.props.change('noSkb', " ")
        //     this.props.change('tgl', "2019-02-28")
        // }
    }

    async handleChange(f, e, val){
        var value = val
        if(f == 'provinsi'){
            try {
                let getPropinsi = await ProvincesService.api.getByName('name.contains='+value)
                this.setState({ propinsiId: getPropinsi.id })
            } catch(e){  }
        }
        if(f == 'kabupaten'){
            try {
                let getRegency = await RegenciesService.api.getByName('name.contains='+value)
                this.setState({ kabupatenId: getRegency.id })
            } catch(e){ }
        }
        if(f == 'kecamatan'){
            try {
                let getDistrict = await DistrictsService.api.getByName('name.contains='+value)
                this.setState({ kecamatanId: getDistrict.id })
            } catch(e){ }
        }
    }

    _barItem() {
        const { handleSubmit, submitting, valid, pristine } = this.props;
        var edit = false
        if(this.props.match.params.id != 'new') edit = true
        if(this.props.formData && this.props.formData.status && this.props.formData.status == 'FAILED') edit = false
        return (
          <div>
            <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>Back</Button>
    
            {!edit &&
                <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Save</Button>
            }
          </div>
        )
    }

    async cariSpt(){
        try {
            var organizationId = this.props.formData.organization.npwp
            // let getOrganization = await OrganizationService.api.getOrgByNpwp(this.props.formData.spt.npwp)
            // organizationId = getOrganization.data[0].id
            try {
                let cekSpt = await ESPT23Service.api.querySpt23({
                    organizationId: organizationId,
                    masa: this.props.formData.spt.masa,
                    tahun: this.props.formData.spt.tahun,
                    pembetulan: this.props.formData.spt.pembetulan
                })
                var data = cekSpt.data[0]
                if(data.status == 'FINISH'){
                    iziToast.info({
                        title: "Info", 
                        message: "Spt dengan status FINISH tidak dapat menambah bukti potong baru"
                    })
                } else {
                    var sptData = {
                        ...data,
                        ...this.props.formData.spt
                    }
                    this.props.change('spt', sptData)
                    this.setState({
                        formSpt: false
                    })
                    let getSigner = await Signer.api.getByOrganization(organizationId)
                    this.props.change('npwpPenandatangan', getSigner.npwpPenandaTangan)
                    this.props.change('namaPenandatangan', getSigner.namaPenandaTangan)
                    this.props.change('signAs', getSigner.signAs)
                }
            } catch(e){
                console.log(e)
                iziToast.info({
                    title: "Info",
                    message: "Spt tidak ditemukan, " + e.message
                })
            }
        } catch(e){
            console.log(e)
            iziToast.info({
                title: "Info",
                message: "Organisasi tidak ditemukan, " + e.message
            })
        }
    }

    async lawanTransaksiDialog(){
        var res = await this.context.showDialog((props, resolve, reject)=> ({
            title: null,
            initialValue: {},
            okText: ' ',
            cancelText: 'Tutup',
            width: 800,
            height: 600,
            text: (
              <div style={{height: 498}}>
                <LawanTransaksi23View parent={this} dialogProps={props} dialogMode={true} match={this.props.match} service={LawanTransaksi23Service} />
              </div>
            )
        }))
    }
  
    formView(){
        var nik = this.state.isNik
        if(this.props.formData && this.props.formData && this.props.formData.identity) nik = this.props.formData.identity
        var fasilitas = 0
        if(this.props.formData && this.props.formData.fasilitas) fasilitas = this.props.formData.fasilitas
        var refs = []
        if(this.props.formData && this.props.formData.refs) refs = this.props.formData.refs
        var propinsiId = null
        var kabupatenId = null
        var kecamatanId = null
        if(this.state.propinsiId){
            propinsiId = { "provinceId.contains": this.state.propinsiId }
        }
        if(this.state.kabupatenId){
            kabupatenId = { "regencyId.contains": this.state.kabupatenId }
        }
        if(this.state.kecamatanId){
            kecamatanId = { "districtId.contains": this.state.kecamatanId }
        }
        var date = new Date();
        let yearOptions = [];
        for(var i=0; i<11; i++) {
            yearOptions.push({
                id: date.getFullYear()-i,
                name: date.getFullYear()-i
            })
        }
        // disabledAll
        var formSptExpanded = this.state.formSpt
        var formOther = false
        if(formSptExpanded){
            formOther = false
        } else {
            formOther = true
        }
        // disabledAll
        var _this = this
        // isFormAdd
        var isFormEdit = false
        if(this.props.match.params.id != 'new') isFormEdit = true
        // isFormAdd
        var org = []
        if(this.props.auth && this.props.auth.organizations) org = this.props.auth.organizations
        var qqDisabled = false
        if(this.props.formData && this.props.formData.qq) qqDisabled = this.props.formData.qq
        var isSupport = false
        if(this.props.user && this.props.user.role && this.props.user.role.name == "SUPPORT_AGENT") isSupport = true
        var organizationNpwp = ""
        if(this.props.formData && this.props.formData.organization && this.props.formData.organization.npwp) organizationNpwp = this.props.formData.organization.npwp
        return (
            <ExpansionList>
                <ExpansionPanel label="Form SPT" defaultExpanded={formSptExpanded} footer={false}>
                    <div className="md-grid">
                        {!isSupport &&
                            <Field
                                label     = 'Organization'
                                name      = 'organization'
                                id        = "spt2126-organization"
                                className = "md-cell md-cell--12"
                                component = {Searchfield}
                                valueField = 'parent'
                                options   = {org}
                                validate  = {validation.required}
                                itemTemplate = {function(d) {
                                    let nameAndAlias = ""
                                    if (d.name)
                                    nameAndAlias = d.name
                                    if (d.aliasName)
                                    nameAndAlias += " - " + d.aliasName
                                    return {
                                    primaryText  : nameAndAlias,
                                    secondaryText: d.npwp,
                                    onClick      : () => {
                                        let nameAndAlias = ""
                                        if (d.name)
                                        nameAndAlias = d.name
                                        if (d.alias)
                                        nameAndAlias += " - " + d.alias
                                        _this.props.change('spt.organization.npwp', d.npwp)
                                        _this.props.change('spt.organization.nama', nameAndAlias)
                                        _this.setState({ organizationId: d.id })
                                        if(!this.props.disabled){
                                        this.handleItemClick(d); this.hide()
                                        }
                                    }
                                    }
                                }}
                            />
                        }
                        {isSupport &&
                            <Field
                                label         = 'Organization'
                                name          = 'organization'
                                className     = "md-cell md-cell--12"
                                component     = {Searchfieldv2}
                                valueField    = 'parent'
                                remoteSearch  = {true}
                                apiPath       = 'getAll'
                                params        = {{ user: this.props.user }}
                                service       = {OrganizationService}
                                validate      = {validation.required}
                                renderFirst   = {true}
                                validate      = {validation.required}
                                coreSearch    = {true}
                                npwpSearch    = {true}
                                itemTemplate = {function(d) {
                                    let nameAndAlias = ""
                                    if (d.name)
                                    nameAndAlias = d.name
                                    if (d.aliasName)
                                    nameAndAlias += " - " + d.aliasName
                                    return {
                                    primaryText  : nameAndAlias,
                                    secondaryText: d.npwp,
                                    onClick      : () => {
                                        let nameAndAlias = ""
                                        if (d.name)
                                        nameAndAlias = d.name
                                        if (d.alias)
                                        nameAndAlias += " - " + d.alias
                                        _this.props.change('spt.organization.npwp', d.npwp)
                                        _this.props.change('spt.organization.nama', nameAndAlias)
                                        _this.setState({ organizationId: d.id })
                                        if(!this.props.disabled){
                                        this.handleItemClick(d); this.hide()
                                        }
                                    }
                                    }
                                }}
                            />
                        }
                        {/* <Field
                            label='Organization'
                            name='organization'
                            id="spt2126-organization"
                            className="md-cell md-cell--12"
                            // disabled={this.props.organization.id != null}
                            params     = {{
                                size: 50
                            }}
                            remoteSearch = {true}
                            valueField='parent'
                            service={OrganizationService}
                            searchField  = {['nama']}
                            component={Searchfield}
                            itemTemplate={function(d) {
                                let nameAndAlias = ""
                                if (d.nama)
                                nameAndAlias = d.nama
                                if (d.aliasNama)
                                nameAndAlias += " - " + d.aliasNama
                                return {
                                    primaryText  : nameAndAlias,
                                    secondaryText: d.npwp,
                                    onClick: async () => {
                                        let nameAndAlias = ""
                                        if (d.nama)
                                        nameAndAlias = d.nama
                                        if (d.aliasNama)
                                        nameAndAlias += " - " + d.aliasNama
                                        _this.props.change('spt.organization.npwp', d.npwp)
                                        _this.props.change('spt.organization.nama', nameAndAlias)
                                        _this.setState({ organizationId: d.id })
                                        if(!this.props.disabled){
                                            this.handleItemClick(d); this.hide()
                                        }
                                    }
                                }
                            }}
                        /> */}
                        <Field
                            label='Npwp'
                            name='spt.organization.npwp'
                            className="md-cell md-cell--12"
                            component={TextfieldMask}
                            maskFormat="##.###.###.#-###-###"
                            mask="_"
                            length="15"
                            validate={validation.required}
                            disabled={formOther}
                        />
                        <Field
                            label='Nama'
                            name='spt.organization.nama'
                            className="md-cell md-cell--6"
                            component={Textfield}
                            validate={validation.required}
                            disabled={formOther}
                        />
                        <Field
                            label='Pembetulan'
                            name='spt.pembetulan'
                            className="md-cell md-cell--6"
                            component={Searchfield}
                            validate={validation.required2}
                            options={[
                                {id: 0, name: '0'},
                                {id: 1, name: '1'},
                                {id: 2, name: '2'},
                                {id: 3, name: '3'},
                                {id: 4, name: '4'},
                                {id: 5, name: '5'},
                                {id: 6, name: '6'},
                                {id: 7, name: '7'},
                                {id: 8, name: '8'},
                                {id: 9, name: '9'},
                                {id: 10, name: '10'},
                                {id: 11, name: '11'},
                                {id: 12, name: '12'}
                            ]}
                        />
                        <Field
                            label='Masa'
                            name='spt.masa'
                            className="md-cell md-cell--6"
                            component={Searchfield}
                            validate={validation.required}
                            disabled={formOther}
                            options={[
                                {id: 1, name: 'Januari'},
                                {id: 2, name: 'Februari'},
                                {id: 3, name: 'Maret'},
                                {id: 4, name: 'April'},
                                {id: 5, name: 'Mei'},
                                {id: 6, name: 'Juni'},
                                {id: 7, name: 'Juli'},
                                {id: 8, name: 'Agustus'},
                                {id: 9, name: 'September'},
                                {id: 10, name: 'Oktober'},
                                {id: 11, name: 'November'},
                                {id: 12, name: 'Desember'}
                            ]}
                        />
                        <Field
                            label='Tahun'
                            name='spt.tahun'
                            className="md-cell md-cell--6"
                            component={Searchfield}
                            validate={validation.required}
                            options={yearOptions}
                            disabled={formOther}
                        />
                        {!this.state.edit && 
                            <div className="md-cell md-cell--12">
                                <Button raised primary onClick={this.props.handleSubmit(this.cariSpt.bind(this))}>Cari SPT</Button>
                                <Button raised style={{ marginLeft: '10px' }} onClick={()=> {
                                    this.props.initialize(this.initialData)
                                    this.setState({
                                        formSpt: true
                                    })
                                }}>Hapus</Button>
                            </div>
                        }
                    </div>
                </ExpansionPanel>
                <br />
                <ExpansionPanel style={isFormEdit ? { display: 'block' } : { display: 'none' }} label="Detail Bupot" expanded={formOther} footer={false}>
                    <div className="md-grid">
                        <Field
                            label     = 'Nomor Bupot'
                            name      = 'no'
                            className = "md-cell md-cell--6"
                            component = {Textfield}
                            disabled  = {true} 
                        />
                        <Field
                            label     = 'Rev'
                            name      = 'rev'
                            className = "md-cell md-cell--6"
                            component = {Textfield}
                            type      = 'number'
                            disabled  = {true} 
                        />
                    </div>
                </ExpansionPanel>
                <br/>
                <ExpansionPanel label="Identitas Wajib Pajak yang Dipotong" expanded={formOther} footer={false}>
                    <div className="md-grid">
                        <Field
                            label='Pilih Lawan Transaksi'
                            name='lt23'
                            className="md-cell md-cell--10"
                            component={Searchfield}
                            searchField={['nama']}
                            viewField="nama"
                            valueField='npwp'
                            service={LT23Service}
                            parent={_this}
                            itemTemplate={function (d) {
                                var isNik = ""
                                if (d.identity) {
                                    isNik = d.npwp
                                } else {
                                    isNik = d.nik
                                }
                                return {
                                    primaryText: d.nama,
                                    secondaryText: isNik,
                                    onClick: () => {
                                        try { this.props.parent.props.change("nama", d.nama) } catch (e) { }
                                        try { this.props.parent.props.change("alamat", d.alamat) } catch (e) { }
                                        try { this.props.parent.props.change("npwp", d.npwp) } catch (e) { }
                                        try { this.props.parent.props.change("nik", d.nik) } catch (e) { }
                                        try { this.props.parent.props.change("identity", d.identity) } catch (e) { }
                                        try { this.props.parent.props.change("email", d.email) } catch (e) { }
                                        try { this.props.parent.props.change("noTelp", d.noTelp) } catch (e) { }
                                        try { this.props.parent.props.change("kodePos", d.kodePos) } catch (e) { }
                                        try { this.props.parent.props.change("provinsi", d.provinsi) } catch (e) { }
                                        try { this.props.parent.props.change("kabupaten", d.kabupaten) } catch (e) { }
                                        try { this.props.parent.props.change("kecamatan", d.kecamatan) } catch (e) { }
                                        try { this.props.parent.props.change("kelurahan", d.kelurahan) } catch (e) { }
                                        this.handleItemClick(d); this.hide()
                                    }
                                }
                            }}
                            remoteSearch
                        />
                        <div className="md-cell md-cell--2">
                            <Button style={{ marginTop: 20 }} onClick={this.lawanTransaksiDialog.bind(this)} fullWidth raised primary label="Search" iconChildren="search"></Button>
                        </div>
                        <Field
                            label='Identitas'
                            name='identity'
                            className="md-cell md-cell--6"
                            component={CheckboxSelection}
                            options={[
                                { value: true, label: "NPWP" },
                                { value: false, label: "NIK" }
                            ]}
                            validate={validation.required2}
                            onChange={this.handleNik.bind(this)}
                            formData={this.props.formData}
                            skipValues={true}
                        />
                        {(nik == false) &&
                            <Field
                                label='NIK'
                                name='nik'
                                className="md-cell md-cell--6"
                                component={TextfieldMask}
                                maskFormat="################"
                                mask="_"
                                length="16"
                                validate={validation.required}
                                onChange={this.handleIsNik.bind(this)}
                            />
                        }
                        {(nik == true) &&
                            <Field
                                label='Npwp'
                                name='npwp'
                                className="md-cell md-cell--6"
                                component={TextfieldMask}
                                maskFormat="##.###.###.#-###-###"
                                validate={validation.required}
                                onChange={this.handleIsNpwp.bind(this)}
                            />
                        }
                        {(nik == true) &&
                            <Field
                                label='qq'
                                name='qq'
                                className="md-cell md-cell--6"
                                component={Switch}
                                onChange={(e, value) => {
                                    if (value) {

                                    } else {
                                        this.props.change('qqName', null)
                                    }
                                }}
                            />
                        }
                        {(nik == true) &&
                            <Field
                                label=''
                                name='qqName'
                                className="md-cell md-cell--6"
                                component={Textfield}
                                disabled={!qqDisabled}
                            />
                        }
                        {(isFormEdit || nik == false) &&
                            <Field
                                label='Nama'
                                name='nama'
                                className="md-cell md-cell--12"
                                component={Textfield}
                                disabled={isFormEdit && nik == false}
                            />
                        }
                        {isFormEdit &&
                            <Field
                                label='Alamat'
                                name='alamat'
                                className="md-cell md-cell--12"
                                component={Textfield}
                                disabled
                            />
                        }
                        <Field
                            label='Email'
                            name='email'
                            className="md-cell md-cell--12"
                            component={Textfield}
                        />
                        {isFormEdit &&
                            <Field
                                label='No Telp'
                                name='noTelp'
                                className="md-cell md-cell--6"
                                component={Textfield}
                                disabled
                            />
                        }
                        {(nik == false) &&
                            <Field
                                label='Propinsi'
                                name='provinsi'
                                className="md-cell md-cell--6"
                                component={Searchfieldv2}
                                valueField='name'
                                service={ProvincesService}
                                remoteSearch
                                onChange={this.handleChange.bind(this, 'provinsi')}
                                validate={validation.required}
                                disabled={isFormEdit}
                            />
                        }
                        {(nik == false) &&
                            <Field
                                label='Kota/Kabupaten'
                                name='kabupaten'
                                className="md-cell md-cell--6"
                                component={Searchfieldv2}
                                valueField='name'
                                service={KotaService}
                                remoteSearch
                                params={{ "provinceId.contains": this.state.propinsiId }}
                                onChange={this.handleChange.bind(this, 'kabupaten')}
                                validate={validation.required}
                                disabled={isFormEdit}
                            />
                        }
                        {(nik == true && isFormEdit) &&
                            <Field
                                label='Propinsi'
                                name='provinsi'
                                className="md-cell md-cell--6"
                                component={Searchfieldv2}
                                valueField='name'
                                service={ProvincesService}
                                remoteSearch
                                onChange={this.handleChange.bind(this, 'provinsi')}
                                disabled
                            />
                        }
                        {(nik == true && isFormEdit) &&
                            <Field
                                label='Kota/Kabupaten'
                                name='kabupaten'
                                className="md-cell md-cell--6"
                                component={Searchfieldv2}
                                valueField='name'
                                service={KotaService}
                                remoteSearch
                                params={{ "provinceId.contains": this.state.propinsiId }}
                                onChange={this.handleChange.bind(this, 'kabupaten')}
                                disabled
                            />
                        }
                        {isFormEdit &&
                            <Field
                                label='Kecamatan'
                                name='kecamatan'
                                className="md-cell md-cell--6"
                                component={Searchfieldv2}
                                valueField='name'
                                service={KecamatanService}
                                remoteSearch
                                params={{ "regencyId.contains": this.state.kabupatenId }}
                                onChange={this.handleChange.bind(this, 'kecamatan')}
                                disabled
                            />
                        }
                        {isFormEdit &&
                            <Field
                                label='Kelurahan'
                                name='kelurahan'
                                className="md-cell md-cell--6"
                                component={Searchfieldv2}
                                valueField='name'
                                service={KelurahanService}
                                remoteSearch
                                params={{ "districtId.contains": this.state.kecamatanId }}
                                disabled
                            />
                        }
                        {isFormEdit &&
                            <Field
                                label='Kode Pos'
                                name='kodePos'
                                className="md-cell md-cell--6"
                                component={Textfield}
                            />
                        }
                    </div>
                </ExpansionPanel>
                <br />
                <ExpansionPanel label="Dokumen Referensi" expanded={formOther} footer={false}>
                    <DokumenForm parent={this} formDataParent={refs} />
                    <div className="md-grid">
                        <Field
                            label     = 'Fasilitas'
                            name      = 'fasilitas'
                            className = "md-cell md-cell--12"
                            component = {CheckboxSelection}
                            options  = {[
                                {value: 0, label: "Tanpa Fasilitas"},
                                {value: 1, label: "PPh Pasal 23 Dibebaskan dari pemotongan berdasarkan Surat Keterangan Bebas (SKB)"},
                                {value: 2, label: "PPh Pasal 23 Ditanggung Oleh Pemerintah berdasarkan"}
                            ]}
                            validate={validation.required2}
                            onChange={this.handleFasilitas.bind(this)}
                            formData={this.props.formData}
                            skipValues= {true}
                        />
                        {fasilitas == 1 &&
                            <Field
                                label     = 'Nomor SKB'
                                name      = 'noSkb'
                                className = "md-cell md-cell--6"
                                component = {Textfield}
                                validate={validation.required}
                            />
                        }
                        {fasilitas == 1 &&
                            <Field
                                label     = 'Tanggal'
                                name      = 'tglSkb'
                                className = "md-cell md-cell--6"
                                component={Datepicker}
                                inline={true}
                                validate={validation.required}
                            />
                        }
                        {fasilitas == 2 &&
                            <Field
                                label     = 'Dokumen PPh DTP'
                                name      = 'noDtp'
                                className = "md-cell md-cell--6"
                                component={Textfield}
                                validate={validation.required}
                            />
                        }
                        {fasilitas == 2 &&
                            <Field
                                label     = 'Nomor NTPN'
                                name      = 'ntpn'
                                className = "md-cell md-cell--6"
                                component={Textfield}
                                validate={validation.required}
                            />
                        }
                    </div>
                </ExpansionPanel>
                <br />
                <ExpansionPanel label="Pajak Penghasilan yang dipotong" expanded={formOther} footer={false}>
                    <div className="md-grid">
                        <Field
                            label='Kode Object Pajak'
                            name='kode'
                            className="md-cell md-cell--12"
                            component={Searchfieldv2}
                            searchField={['name']}
                            valueField='kode'
                            viewField='kode'
                            viewField2='name'
                            service={KodeBuktiPotongService}
                            remoteSearch
                            params={{ 'jenis.contains': '23', size: 1000 }}
                            validate={validation.required}
                            onChange={this.handleKode.bind(this)}
                        />
                        <div className="md-cell md-cell--12">
                            <p>{this.state.note}</p>
                        </div>
                        <Field
                            label='Jumlah Penghasilan Bruto'
                            name='bruto'
                            className="md-cell md-cell--12"
                            component={TextfieldMask}
                            money
                            validate={validation.required}
                            onChange={this.handleBruto.bind(this)}
                            normalize={(e)=> parseFloat(e)}
                        />
                        <Field
                            label='Tarif ( % )'
                            name='tarif'
                            className="md-cell md-cell--6"
                            component={TextfieldMask}
                            money
                            disabled
                            normalize={(e)=> parseInt(e)}
                        />
                        <Field
                            label='PPh yang Dipotong / DTP'
                            name='pph'
                            className="md-cell md-cell--6"
                            component={TextfieldMask}
                            money
                            disabled
                            normalize={(e)=> parseFloat(e)}
                        />
                    </div>
                </ExpansionPanel>
                <br />
                <ExpansionPanel label="Identitas Pemotong Pajak" expanded={formOther} footer={false}>
                    <div className="md-grid">
                        <Field
                            label='Nama Penandatanganan'
                            name='namaPenandatangan'
                            className="md-cell md-cell--6"
                            component={Searchfieldv2}
                            valueField='nama'
                            viewField='nama'
                            viewField2='npwp'
                            service={Signer}
                            remoteSearch
                            params={{ 'active': true, organizationId: organizationNpwp  }}
                            validate={validation.required}
                            apiPath="getByOrganizationQuery"
                            onChange={this.handlePenandatanganan.bind(this)}
                        />
                        <div className="md-cell md-cell--6">
                            <p>Penandatanganan</p>
                            <hr />
                            <Field
                                label='Bertindak Sebagai'
                                name='signAs'
                                component={Searchfield}
                                options={[
                                    {id: true, name: 'Wajib Pajak / Wakil Wajib Pajak'},
                                    {id: false, name: 'Kuasa'}
                                ]}
                            />
                        </div>
                        <Field
                            label='Npwp Penandatanganan'
                            name='npwpPenandatangan'
                            className="md-cell md-cell--6"
                            component={TextfieldMask}
                            maskFormat="##.###.###.#-###-###"
                            validate={validation.required}
                        />
                        {/* <Field
                            label='Nama'
                            name='rev'
                            className="md-cell md-cell--6"
                            component={Searchfield}
                            options={[
                                {id: '0', name: 'Kosong'}
                            ]}
                        /> */}
                        <Field
                            label='Tanggal Pemotong'
                            name='tgl'
                            className="md-cell md-cell--6"
                            component={Datepicker}
                            inline={true}
                            validate={validation.required}
                        />
                        <Field
                            className="md-cell md-cell--12"
                            label='Dengan menyadari sepenuhnya akan segala akibatnya termasuk sanksi-saksi sesuai dengan ketentuan perundang undangan yang berlaku,
                            saya menyatakan bahwa apa yang telah saya beritahukan di atas beserta lampiran-lampirannya adalah benar, lengkap dan jelas'
                            name='agree'
                            component={Checkbox}
                            validate={validation.required}
                        />    
                        <br />
                        <br />
                        <br />      
                        <br />
                        <br />
                    </div>
                </ExpansionPanel>
            </ExpansionList>
        )
    }

}

// {
//     "bagA": {
//       "alamat": "string",
//       "email": "string",
//       "identity": true,
//       "kabupaten": "string",
//       "kecamatan": "string",
//       "kelurahan": "string",
//       "kodePos": "string",
//       "nama": "string",
//       "nik": "string",
//       "noTelp": "string",
//       "npwp": "string",
//       "provinsi": "string"
//     },
//     "bagB": {
//       "bruto": 0,
//       "kode": "string",
//       "pph": 0,
//       "tarif": 0
//     },  
//     "fasilitas": 0,
//     "namaPenandatangan": "string",
//     "no": "string",
//     "noDtp": "string",
//     "noSkb": "string",
//     "npwpPenandatangan": "string",
//     "ntpn": "string",
//     "referensi": "string",
//     "refs": [
//       {
//         "bupot23": {},
//         "id": "string",
//         "jenis": "string",
//         "noDok": "string",
//         "tgl": "2019-02-28"
//       }
//     ],
//     "rev": 0,
//     "seq": 0,
//     "signAs": true,
//     "spt": {
//       "id": "string",
//       "masa": 0,
//       "nama": "string",
//       "npwp": "string",
//       "organizationId": "string",
//       "pembetulan": 0,
//       "sebelumnya": "string",
//       "status": "string",
//       "tahun": 0
//     },
//     "status": "CREATED",
//     "tgl": "2019-02-28"
//   }
