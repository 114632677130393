import React from 'react'

export default class DashboardView extends React.Component {
    render(){
        return (
            <div>
                
            </div>
        )
    }
}