/**
 * Created by dwiargo on 12/11/17.
 */

import React, { Component } from 'react';
import { Button } from 'react-md';
import './Footbar.scss';

const year = new Date().getFullYear();
const media = [
  {
    iconClassName:'mdi mdi-instagram',
    url:'https://www.instagram.com/sobatpajak.id/'
  },
  {
    iconClassName:'mdi mdi-linkedin',
    url:'https://www.linkedin.com/company/pt-nebula-surya-corpora'
  }
];

class Footbar extends Component{
  render(){
    return(
      <div className="mpk-footbar mpk-layout align-center column-xs justify-between justify-center-xs">
        <div>
          &copy; {year} <span className="mpk-font-weight-M">PT Nebula Surya Corpora</span> All Right Reserved
        </div>
        <div className="mpk-layout align-center">
          <div className="$mpk-font-size-S mpk-font-color-L3">Follow us </div>
          { media.map((d, i) => (
            <Button
              key={i} icon
              iconClassName={d.iconClassName}
              onClick={() => window.open(d.url, "blank")}
            />
          ))}
        </div>
      </div>
    )
  }
}

export default Footbar;