import React from 'react';
import {connect} from 'react-redux';
import Bluebird from 'bluebird';
import { Field, reduxForm, getFormValues} from 'redux-form';
import moment from 'moment';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress,
  Tab,
  Tabs
} from 'react-md';
import FormView from '../../../../../../components/entity/form.view';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox} from '../../../../../../components/form';
import iziToast from 'izitoast';
import Master22TarifService from './Master22Tarif.service';


@reduxForm({form: 'Master22TarifForm', destroyOnUnmount: true})
@connect(state => ({
  company: state.authEppt.currentCompany,
  formData:getFormValues('Master22TarifForm')(state)
}))
export default class Master22TarifForm extends FormView {
  service=Master22TarifService
  viewType=2

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      activeTab: 0
    }
  }

  redirectTo = false
  async handleSave(value) {
    var beforeSave = await this.beforeSave(value);
    var res = null;
    if(beforeSave) {
      if(typeof beforeSave === 'object') value = beforeSave;
      try {
        this.setState({onProgress: true})
        if(value.id) {
          res = await this.service.api.update(value)
          iziToast.success({
            title: "Success",
            message: "Pengaturan berhasil disimpan !"
          })
        } else {
          res = await this.service.api.save(value)
          iziToast.success({
            title: "Success",
            message: "Pengaturan berhasil disimpan !"
          })
        }
        this.setState({onProgress: false})
        this.afterSave(res, value)

        var pathArray = this.props.location.pathname.split('/');
        var path = '';
        pathArray.forEach((d, i)=> {
          if(pathArray.length-1 != i) {
            path = path+d;
            if(i != pathArray.length-2) path = path+'/'
          }
        })

        if(this.redirectTo){
          this.props.history.push(path)
        }
      } catch(e) {
        var msg = e.message;
        if(e.response) msg = e.response.data.message;
        this.setState({
          isError: true,
          onProgress: false,
          errorMessage: msg
        })
      }
    }
  }

  async initData() {
    // if(this.props.match.params.id == 'new') {

    //   var master = await Master22TarifService.api.getMaster();
    //   if(master) {
    //     delete master.id;
    //   }

    //   var initialData = {
    //     company: this.props.company,
    //     ...master
    //   }

    //   this.props.initialize(initialData);
    // } else {
    //   let res = await this.service.api.findOne(this.props.match.params.id);
    //   this.props.initialize(res.data);
    // }
    var res = await Master22TarifService.api.getMaster(this.props.match.params.sptId);
    let data = res.data
    this.props.initialize(data);
  }

  formView() {
    return (
      <div className="mpk-layout column fill mpk-tab-clear">
        <div className="flex">
        {this.pasal4a2Form()}
        </div>
      </div>
    )
  }

  pasal4a2Form() {
    return <div style={{marginBottom: 20}}>
      <div className='md-card md-paper md-paper md-paper--1 md-card md-background--card'>

        {/* <div className="md-grid">
          <Field
            className="md-cell md-cell--6"
            label='Start Date'
            name='start'
            inline={true}
            component={Datepicker}
            />
          <Field
            className='md-cell md-cell--6'
            label='End Date'
            name='end'
            inline={true}
            component={Datepicker} />
        </div> */}
        <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
          <TableHeader>
            <TableRow>
              <TableColumn>No.</TableColumn>
              <TableColumn>Jenis Penghasilan</TableColumn>
              <TableColumn>Tarif(%)</TableColumn>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableColumn>
                1.
              </TableColumn>
              <TableColumn>Hadiah Undian</TableColumn>
              <TableColumn>
                <Field
                  // label='Tarif'
                  name='r1'
                  component={TextfieldMask}
                  className='md-text-right'
                />
              </TableColumn>
            </TableRow>
            <TableRow>
              <TableColumn>
                2.
              </TableColumn>
              <TableColumn>Bunga Deposito</TableColumn>
              <TableColumn>
                <Field
                  // label='Tarif'
                  name='r2'
                  component={TextfieldMask}
                  className='md-text-right'
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>
                3.
              </TableColumn>
              <TableColumn>Penjualan Saham Pendiri</TableColumn>
              <TableColumn>
                <Field
                  // label='Tarif'
                  name='r3'
                  component={TextfieldMask}
                  className='md-text-right'
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>
                4.
              </TableColumn>
              <TableColumn>Penjualan Saham Bukan Sendiri</TableColumn>
              <TableColumn>
                <Field
                  // label='Tarif'
                  name='r4'
                  component={TextfieldMask}
                  className='md-text-right'
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>
                5.
              </TableColumn>
              <TableColumn>Sewa Tanah dan Bangunan</TableColumn>
              <TableColumn>
                <Field
                  // label='Tarif'
                  name='r5'
                  component={TextfieldMask}
                  className='md-text-right'
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>
                6.
              </TableColumn>
              <TableColumn>Penyedia Jasa Konstruksi Kualifikasi</TableColumn>
              <TableColumn>
                <Field
                  // label='Tarif'
                  name='r6'
                  component={TextfieldMask}
                  className='md-text-right'
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>
                7.
              </TableColumn>
              <TableColumn>Penyedia Jasa Konstruksi Tidak Punya Kualifikasi</TableColumn>
              <TableColumn>
                <Field
                  // label='Tarif'
                  name='r7'
                  component={TextfieldMask}
                  className='md-text-right'
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>
                8.
              </TableColumn>
              <TableColumn>Penyedia Jasa Konstruksi Lain</TableColumn>
              <TableColumn>
                <Field
                  // label='Tarif'
                  name='r8'
                  component={TextfieldMask}
                  className='md-text-right'
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>
                9.
              </TableColumn>
              <TableColumn>Jasa Perancangan Punya Kualifikasi</TableColumn>
              <TableColumn>
                <Field
                  // label='Tarif'
                  name='r9'
                  component={TextfieldMask}
                  className='md-text-right'
                />
              </TableColumn>
            </TableRow>


            <TableRow>
              <TableColumn>
                10.
              </TableColumn>
              <TableColumn>Jasa Perancangan Tidak Punya Kualifikasi</TableColumn>
              <TableColumn>
                <Field
                  // label='Tarif'
                  name='r10'
                  component={TextfieldMask}
                  className='md-text-right'
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>
                11.
              </TableColumn>
              <TableColumn>Bunga dan Diskonto Obligasi</TableColumn>
              <TableColumn>
                <Field
                  // label='Tarif'
                  name='r11'
                  component={TextfieldMask}
                  className='md-text-right'
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>
                12.
              </TableColumn>
              <TableColumn>Bunga Simpanan</TableColumn>
              <TableColumn>
                <Field
                  // label='Tarif'
                  name='r12'
                  component={TextfieldMask}
                  className='md-text-right'
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>
                13.
              </TableColumn>
              <TableColumn>Penghasilan Transaksi Deviatif</TableColumn>
              <TableColumn>
                <Field
                  // label='Tarif'
                  name='r13'
                  component={TextfieldMask}
                  className='md-text-right'
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>
                14.
              </TableColumn>
              <TableColumn>Dividen Diterima</TableColumn>
              <TableColumn>
                <Field
                  // label='Tarif'
                  name='r14'
                  component={TextfieldMask}
                  className='md-text-right'
                />
              </TableColumn>
            </TableRow>

          </TableBody>
        </DataTable>
      </div>
      <br/>
      {/* <div className='md-card md-paper md-paper md-paper--1 md-card md-background--card'>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle='Tarif Jasa Lain ex Peraturan Menteri Keuangan No.244/PMK/03/2008
                    ' />
        <Divider/>

        <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
          <TableHeader>
            <TableRow>
              <TableColumn>Kode Jasa.</TableColumn>
              <TableColumn>Nama Jasa</TableColumn>
              <TableColumn>Tarif(%)</TableColumn>
            </TableRow>
          </TableHeader>
          <TableBody>
            {this.props.formData.pasal23Lain.map((d, i)=> {
              return <TableRow>
                <TableColumn>
                  {i+1}.
                </TableColumn>
                <TableColumn>{d.nama}</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name={'lain.'+d.id+'.tarif'}
                    component={TextfieldMask}
                    className='md-text-right'
                  />
                </TableColumn>
              </TableRow>
            })}

          </TableBody>
        </DataTable>
      </div> */}
    </div>
  }

}
