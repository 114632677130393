import React from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  Tab,
  Tabs,
  SelectionControlGroup,
  Button
} from 'react-md';
import download from 'downloadjs';
import uuid from 'uuid';
import izitoast from 'izitoast';

import FormView from '../../../../../components/entity/form.view';
import {validation, Textfield, Searchfield, Switch, Checkbox, Datepicker, TextfieldMask} from '../../../../../components/form';
import columnService from '../../../../../services/column.service';
import terbilangService from '../../../../../services/terbilang.service';
import Spt22IndukService from './SPT22.induk.service';
import BlokBunifikasiService from '../../21_26/BlokBunifikasi/BlokBunifikasi.service';


@reduxForm({form: 'SPT_INDUK_22_Form', destroyOnUnmount: true, initialValues: {jasaLains: []}})
@connect((state) => ({
  access      : state.entity.access.api.find,
  organization: state.authEppt.currentOrganization,
  spt         : state.authEppt.currentSPT,
  formData    : getFormValues('SPT_INDUK_22_Form')(state)
}))
export default class Spt22IndukForm extends FormView {
  service  = Spt22IndukService
  viewType = 2;

  initialData={
    bagB1: {},
    bagB2: {},
    bagC : {},
  }

  constructor(props){
    super(props);
    this.state = {
      ...this.state,
      cetak    : false,
      calculate: false,

      signer: {},
    }
  }

  async beforeSave(value) {
    // if(value.bruto == 0 || value.bruto == '') {
    //   this.toast.warning({title:'Warning', message:'Isi bruto harus lebih besar dari 0'})
    //   return false;
    // }


    var date;
    if(typeof value.cuttingDate === 'string') {
      if(value.cuttingDate.search('/') != -1) {
        date = moment(value.cuttingDate, 'DD/MM/YYYY');
      } else {
        date = moment(value.cuttingDate, 'YYYY-MM-DD');
      }
    } else if(typeof value.cuttingDate === 'object') {
      date = moment(value.cuttingDate)
    }

    // Remapping Objek
    var mapping = {
      "c01"             : value.c1 ? true : false,
      "c02"             : value.c2 ? true : false,
      "c02Count"        : value.qty2,
      "c03"             : value.c3 ? true : false,
      "c03Count"        : value.qty3,
      "c04"             : value.c4 ? true : false,
      "c05"             : value.c5 ? true : false,
      "c06"             : value.c6 ? true : false,
      "c07"             : value.c7 ? true : false,
      "c08"             : value.c8 ? true : false,
      "cuttingDate"     : value.cuttingDate,
      "desc7"           : value.uraian7,
      "nop14"           : value.nop14 ? value.nop14 : 0,
      "nop24"           : value.nop24 ? value.nop24 : 0,
      "nop34"           : value.nop34 ? value.nop34 : 0,
      "nop44a"          : value.nop44a ? value.nop44a : 0,
      "nop44b"          : value.nop44b ? value.nop44b : 0,
      "nop54"           : value.nop54 ? value.nop54 : 0,
      "nop64a"          : value.nop64a ? value.nop64a : 0,
      "nop64b"          : value.nop64b ? value.nop64b : 0,
      "nop7"            : value.nop7 ? value.nop7 : 0,
      "pph15"           : value.pph15 ? value.pph15 : 0,
      "pph25"           : value.pph25 ? value.pph25 : 0,
      "pph35"           : value.pph35 ? value.pph35 : 0,
      "pph45a"          : value.pph45a ? value.pph45a : 0,
      "pph45b"          : value.pph45b ? value.pph45b : 0,
      "pph55"           : value.pph55 ? value.pph55 : 0,
      "pph65a"          : value.pph65a ? value.pph65a : 0,
      "pph65b"          : value.pph65b ? value.pph65b : 0,
      "pph7"            : value.pph7 ? value.pph7 : 0,
      "totalNop"        : value.jmlNop ? value.jmlNop : 0,
      "totalPph"        : value.jmlPph ? value.jmlPph : 0,
      "words"           : value.terbilang ? value.terbilang : "-",
      spt               : this.props.spt.data
    }
    
    if(value.id && value.id != "new") mapping.id = value.id
    if(value.consumeId) mapping.consumeId = value.consumeId
    if(typeof mapping.cuttingDate == "object") mapping.cuttingDate = moment(mapping.cuttingDate).format("YYYY-MM-DD")
    value = mapping;
    // Remapping Objek

    // if(!(date.year() == this.props.spt.data.year && date.month()+1 == this.props.spt.data.month)) {
    //   this.toast.warning({title:'Warning', message:'Tanggal bukti potong harus sesuai dengan masa SPT'})
    //   return false;
    // }
    value.blockBunifikasiMonth = 4;
    value.blockBunifikasiYear = 2022;
    try {
      let res = await BlokBunifikasiService.api.getSettingAccess(this.props.match.params.companyId)
      if(res.data.active) {
        value.blockBunifikasiMonth = res.data.bulan
        value.blockBunifikasiYear = res.data.tahun
      }
    } catch(e) {}

    return mapping;
  }

  async handleSave(value) {
    try {
      var beforeSave = await this.beforeSave(value);
      var res = null;
      if(beforeSave) {
        if(typeof beforeSave === 'object') value = beforeSave; 
      }
      this.setState({onProgress: true})

      var res                            = await this.service.api.update(value)
      izitoast.success({title: 'Success', message: 'Data berhasil disimpan.'})
      if  (!res.data.bagD) res.data.bagD = {}

      // signer
      let signer = null;
      try {
        signer = await this.service.api.getSigner({
          orgId: this.props.spt.data.organization.id,
          tgl  : res.data.tgl
        });
      } catch (error) {

      }
      if(signer) {
        this.setState({signer: signer})
        if(res.data.bagD.kuasa) {
          res.data.bagD.npwp = signer.npwpKuasa;
          res.data.bagD.nama = signer.namaKuasa;
        } else {
          res.data.bagD.npwp = signer.npwpPenandaTanganSpt;
          res.data.bagD.nama = signer.namaPenandaTanganSpt;
        }
      }

      this.props.initialize(res.data);

      this.setState({onProgress: false})
    } catch(e) {
      var msg              = e.message;
      if  (e.response) msg = e.response.data.message;
      this.setState({
        isError     : true,
        onProgress  : false,
        errorMessage: msg
      })
    }
  }

  async initData() {
    let res = await this.service.api.getBySptId(this.props.spt.data.id);

    // Remapping Objek
    var data = res.data;
    var remapping = {
      id              : data.id,
      c1              : data.c01,
      c2              : data.c02,
      qty2            : data.c02Count,
      c3              : data.c03,
      qty3            : data.c03Count,
      c4              : data.c04,
      c5              : data.c05,
      c6              : data.c06,
      c7              : data.c07,
      c8              : data.c08,
      cuttingDate     : data.cuttingDate,
      uraian7         : data.desc7,
      nop14           : data.nop14,
      nop24           : data.nop24,
      nop34           : data.nop34,
      nop44a          : data.nop44a,
      nop44b          : data.nop44b,
      nop54           : data.nop54,
      nop64a          : data.nop64a,
      nop64b          : data.nop64b,
      nop7            : data.nop7,
      pph15           : data.pph15,
      pph25           : data.pph25,
      pph35           : data.pph35,
      pph45a          : data.pph45a,
      pph45b          : data.pph45b,
      pph55           : data.pph55,
      pph65a          : data.pph65a,
      pph65b          : data.pph65b,
      pph7            : data.pph7,
      jmlNop          : data.totalNop,
      jmlPph          : data.totalPph,
      terbilang       : data.words,
      spt             : data.spt
    }

    res.data = remapping;
    // Remapping Objek

    if(!res.data.bagD) res.data.bagD = {}

    // signer
    let signer = null;
    try {
      signer = await this.service.api.getSigner({
        orgId: this.props.spt.data.organization.id,
        tgl  : res.data.tgl
      });
    } catch (error) {

    }
    if(signer) {
      this.setState({signer: signer})
      if(res.data.bagD.kuasa) {
        res.data.bagD.npwp = signer.npwpKuasa;
        res.data.bagD.nama = signer.namaKuasa;
      } else {
        res.data.bagD.npwp = signer.npwpPenandaTanganSpt;
        res.data.bagD.nama = signer.namaPenandaTanganSpt;
      }
    }

    this.props.initialize(res.data);
  }

  async changeSigner() {
    if(this.state.signer.id) {
      let formData                       = this.initialData
      if  (this.props.formData) formData = this.props.formData;
      if(formData.bagD.kuasa) {
        this.props.change('bagD.npwp', this.state.signer.npwpKuasa);
        this.props.change('bagD.nama', this.state.signer.namaKuasa);
      } else {
        this.props.change('bagD.npwp', this.state.signer.npwpPenandaTanganSpt);
        this.props.change('bagD.nama', this.state.signer.namaPenandaTanganSpt);
      }
    }
  }

  async calculate() {
    await this.setState({calculate: true})
    await this.props.dispatch({
      type: 'TOGGLE_GLOBAL_PROGRESS',
      data: true
    });

    const sptId = this.props.match.params.sptId

    try {
      var res = await this.service.api.calculate(sptId)

      if(res.data) {
        this.props.initialize(res.data);
      }
    } catch(e) {

    }


    this.setState({calculate: false})
    await this.props.dispatch({
      type: 'TOGGLE_GLOBAL_PROGRESS',
      data: false
    });
  }

  async handleCetak(){
    this.setState({ cetak: true })
    try {
      const sptId    = this.props.match.params.sptId
      var   report   = await this.service.api.cetak(sptId)
      window.open(report.data.url)
      // var   filename = report.headers.filename;
      // download(report.data, filename);
      this.setState({ cetak: false })
    } catch (e){
      this.setState({ cetak: false })
    }
  }

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;
    let   statusSPT                                     = "";
    let   statusInSPT                                   = false;
    if(this.props.spt){
        if(this.props.spt.data){
            if(this.props.spt.data.status){
                statusSPT   = this.props.spt.data.status;
                statusInSPT = true
            }
        }
    }

    if(statusSPT == "FINISH" || statusSPT == "WAITING"){
        return (
            <div>
              <Button disabled={this.state.cetak} primary raised style={{marginRight: 5}} onClick={this.handleCetak.bind(this)}>Cetak</Button>
            </div>
        )
    } else {
        if(statusInSPT){
            return (
              <div>
                <Button disabled={this.state.cetak} primary raised style={{marginRight: 5}} onClick={this.handleCetak.bind(this)}>Cetak</Button>
                {/* <Button disabled={this.state.calculate} primary raised style={{marginRight: 5}} onClick={this.calculate.bind(this)}>calculate</Button> */}
                <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Save and Calculate</Button>
              </div>
            )
        } else {
            return (
                <div>
                    {/* <Button disabled={this.state.calculate} primary raised style={{marginRight: 5}} onClick={this.calculate.bind(this)}>calculate</Button> */}
                    <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>Back</Button>
                </div>
            )
        }
    }
  }

  async calculateTotal(e,v,pv,f) {
    var form = this.props.formData;
    var d    = form.bagB23;

    await this.props.change(f, v);

    var jmlPph   = 0;
    var jmlBruto = 0;
    Object.keys(d).forEach((k, i) => {
      if(!(k == 'jmlBruto' || k == 'jmlPph' || k == 'terbilang' || k == 'createdBy' || k == 'createdDate' || k == 'id' || k == 'lastModifiedBy' || k == 'lastModifiedDate')) {
        var x = d[k];

        var pph            = x.pph;
        var bruto          = x.bruto;
        if  (!bruto) bruto = 0;
        if  (!pph) pph     = 0;

        jmlPph   = jmlPph+ parseFloat(pph);
        jmlBruto = jmlBruto+ parseFloat(bruto)
      }
    })

    this.props.change('bagB23.jmlBruto', jmlBruto)
    this.props.change('bagB23.jmlPph', jmlPph);

    this.props.change('bagB23.terbilang', terbilangService(parseInt(jmlPph)));
  }

  formView() {
    let formData                                                     = this.initialData
    if  (this.props.formData && this.props.formData.values) formData = this.props.formData.values

    var masaOptions = [
      {id:1, name: 1}, {id:2, name: 2}, {id:3, name: 3}, {id:4, name: 4}, {id:5, name: 5}, {id:6, name: 6},
      {id:7, name: 7}, {id:8, name: 8}, {id:9, name: 9}, {id:10, name: 10}, {id:11, name: 11}, {id:12, name: 12}
    ]
    return (
      <div>
        <Card>
          {/* <CardTitle title='' style={{padding: '10px 16px'}} subtitle='A. IDENTITAS PEMOTONG' />
          <Divider/> */}

          <div className='md-grid'>
            <Field
              label        = 'Tanggal Lapor'
              className    = "md-cell md-cell--12"
              name         = 'cuttingDate'
              component    = {Datepicker}
              validate     = {validation.required}
              onDateChange = { async (d)=> {
                let signer = null;

                try {
                  signer = await this.service.api.getSigner({
                    orgId: this.props.spt.data.organization.id,
                    tgl  : moment(d).format("YYYY-MM-DD")
                  })

                  await this.setState({signer: signer})
                } catch (error) {
                }

                await this.changeSigner()
              }}
              />
          </div>
        </Card>
        <br/>

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='PPH PASAL 22 YANG TELAH DIPOTONG' />
          <Divider/>
          <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
            <TableHeader>
              <TableRow>
                <TableColumn>No.</TableColumn>
                <TableColumn>Uraian</TableColumn>
                <TableColumn>MAP/KJS</TableColumn>
                <TableColumn className='md-text-right'>Nilai Objek Pajak (Rp)</TableColumn>
                <TableColumn className='md-text-right'>PPH Yang Dipungut (Rp)</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableColumn>1.</TableColumn>
                <TableColumn>Badan Usaha Industri/Eksportir</TableColumn>
                <TableColumn>
                411122/100
                </TableColumn>
                <TableColumn>
                  <Field
                    name = 'nop14'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'pph15'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>2.</TableColumn>
                <TableColumn>Penjualan Barang yang Tergolong Sangat Mewah</TableColumn>
                <TableColumn>
                411122/403
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'nop24'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'pph25'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>3.</TableColumn>
                <TableColumn>Pembelian Barang Oleh Bendaharawan/Badan Tertentu Yang Ditunjuk</TableColumn>
                <TableColumn>
                411122/100
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'nop34'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'pph35'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>4.</TableColumn>
                <TableColumn>Nilai Impor Bank Devisa/Ditjen Bea Cukai</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>a. Nilai Impor Bank Devisa/Ditjen Bea dan Cukai API</TableColumn>
                <TableColumn>
                411122/100
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'nop44a'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'pph45a'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>b. Nilai Impor Bank Devisa/Ditjen Bea dan Cukai Non API</TableColumn>
                <TableColumn>
                411122/100
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'nop44b'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'pph45b'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>5.</TableColumn>
                <TableColumn>Hasil Lelang (Ditjen Bea dan Cukai)</TableColumn>
                <TableColumn>
                411122/100
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'nop54'
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'pph55'
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>6.</TableColumn>
                <TableColumn>Penjualan Migas Oleh Pertamina/Badan Usaha Selain Pertamina</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>a. Penjualan Migas Oleh Pertamina / Badan Usaha Selain Pertamina SPBU/Agen/Penyalur (Final)</TableColumn>
                <TableColumn>
                411122/401
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'nop64a'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'pph65a'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>b. Penjualan Migas Oleh Pertamina / Badan Usaha Selain Pertamina SPBU/Agen/Penyalur/ (Tidak Final)</TableColumn>
                <TableColumn>
                411122/100
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'nop64b'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'pph65b'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>7.</TableColumn>
                <TableColumn><Field
                    // label='Tarif'
                    name = 'uraian7'
                    component = {Textfield}
                  /></TableColumn>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'nop7'
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'pph7'
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow style={{background: '#ddd'}}>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  Jumlah
                </TableColumn>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'jmlNop'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    disabled
                    money = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'jmlPph'
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    disabled
                    money = {','}
                  />
                </TableColumn>
              </TableRow>



              <TableRow style={{background: '#ddd'}}>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  Terbilang
                </TableColumn>
                <TableColumn colSpan={3}>
                  <Field
                    // label='Bruto'
                    name      = 'terbilang'
                    component = {Textfield}
                    disabled
                    money = {','}
                  />
                </TableColumn>
              </TableRow>
            </TableBody>

          </DataTable>
        </Card>

        <br/>


         <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='LAMPIRAN' />
          <Divider/>
          <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
            <TableHeader>
              <TableRow>
                <TableColumn>Cek</TableColumn>
                <TableColumn>Keterangan</TableColumn>
                <TableColumn>Lembar</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>

              <TableRow>
                <TableColumn>
                  <Field
                    name      = 'c1'
                    component = {Checkbox}
                  />
                </TableColumn>
                <TableColumn>
                  Daftar Surat Setoran Pajak PPh Pasal 22 (Khusus untuk Bank Devisa, Bendaharawan/Badan Tertentu Yang Ditunjuk, dan Pertamina/Badan Usaha Selain Pertamina)
                </TableColumn>
                <TableColumn>
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  <Field
                    name      = 'c2'
                    component = {Checkbox}
                  />
                </TableColumn>

                <TableColumn>
                Surat Setoran Pajak (SSP) yg disetor oleh Importir atau Pembeli Barang (Khusus untuk Bank Devisa,Bendaharawan/Badan Tertentu yg Ditunjuk, dan Pertamina/Badan Usaha Selain Pertamina)
                </TableColumn>
                <TableColumn>
                  <Field
                    name      = 'qty2'
                    component = {Textfield}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  <Field
                    name      = 'c3'
                    component = {Checkbox}
                  />
                </TableColumn>

                <TableColumn>
                SSP yang disetor oleh Pemungut Pajak (Khusus untuk Badan Usaha Industri/Eksportir Tertentu, Ditjen Bea dan Cukai)
                </TableColumn>
                <TableColumn>
                  <Field
                    name      = 'qty3'
                    component = {Textfield}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  <Field
                    name      = 'c4'
                    component = {Checkbox}
                  />
                </TableColumn>

                <TableColumn>
                Daftar Bukti Pemungutan PPh Pasal 22 (Khusus untuk Badan Usaha Industri/Eksportir Tertentu dan Ditjen Bea dan Cukai)
                </TableColumn>
                <TableColumn></TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  <Field
                    name      = 'c5'
                    component = {Checkbox}
                  />
                </TableColumn>

                <TableColumn>
                Bukti Pemungutan PPh Pasal 22 (Khusus untuk Badan Usaha Industri/Eksportir Tertentu dan Ditjen Bea Cukai)
                </TableColumn>
                <TableColumn></TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  <Field
                    name      = 'c6'
                    component = {Checkbox}
                  />
                </TableColumn>

                <TableColumn>
                Daftar rincian penjualan dan retur penjualan (dalam hal ada penjualan retur)
                </TableColumn>
                <TableColumn></TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  <Field
                    name      = 'c7'
                    component = {Checkbox}
                  />
                </TableColumn>

                <TableColumn>
                Risalah Lelang (Dalam Hal Pelaksanaan Lelang)
                </TableColumn>
                <TableColumn></TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  <Field
                    name      = 'c8'
                    component = {Checkbox}
                  />
                </TableColumn>

                <TableColumn>
                Surat Kuasa Khusus
                </TableColumn>
                <TableColumn></TableColumn>
              </TableRow>

            </TableBody>
          </DataTable>
        </Card>


        <br />


      </div>
    )
  }
}
