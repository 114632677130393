import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  Tab,
  Tabs,
  SelectionControlGroup,
  Checkbox
} from 'react-md';

import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Radio, convert, CheckboxSelection} from '../../../../../components/form';
import UtilService from '../../../../../services/utils.service'
import FormView from '../../../../../components/entity/form.view';
import DialogView from '../../../../../components/entity/dialog.view';
import SignerService from './Signer.service'
import moment from 'moment'
import counterpart from 'counterpart'
import UserService from './../User/User.service'

@reduxForm({form: 'SignerForm', destroyOnUnmount: true})
@connect((state) => ({
  formData:state.form.SignerForm,
  user: state.auth.user,
  // EPPT CORE
  auth: state.auth.company
  // EPPT CORE
}))
export default class SignerFormView extends FormView {
  service=SignerService
  viewType=2;

  componentDidMount() {
    this.initData()
    this.props.change('signAs', true)
    this.props.change('active', false)
    this.props.change('organization.id', this.props.match.params.org)
  }

  formView() {
    var org = []
    if(this.props.auth && this.props.auth.organizations) org = this.props.auth.organizations
    return (
        <Card>
            <div className='md-grid'>
                <Field
                  label     = 'Organization'
                  name      = 'npwpProfile'
                  id        = "spt2126-organization"
                  className = "md-cell md-cell--12"
                  component = {Searchfield}
                  valueField = 'npwp'
                  options   = {org}
                  validate  = {validation.required}
                  itemTemplate = {function(d) {
                    let nameAndAlias = ""
                    if (d.name)
                      nameAndAlias = d.name
                    if (d.aliasName)
                      nameAndAlias += " - " + d.aliasName
                    return {
                      primaryText  : nameAndAlias,
                      secondaryText: d.npwp,
                      onClick      : () => {
                        if(!this.props.disabled){
                          this.handleItemClick(d); this.hide()
                        }
                      }
                    }
                  }}
                />
                <Field
                    label='NPWP'
                    name='npwpPenandaTangan'
                    className="md-cell md-cell--6"
                    component={TextfieldMask}
                    maskFormat="##.###.###.#-###-###"
                    validate={validation.required}
                />
                <Field
                    label='Nama'
                    name='namaPenandaTangan'
                    className="md-cell md-cell--6"
                    component={Textfield}
                    validate={validation.required}
                />
                <Field
                    label='Active'
                    name='active'
                    className="md-cell md-cell--6"
                    component={Switch}
                />
                <Field
                    label     = 'Sign As'
                    name      = 'signAs'
                    className = "md-cell md-cell--6"
                    component = {CheckboxSelection}
                    options  = {[
                        {value: true, label: "Wajib Pajak"},
                        {value: false, label: "Kuasa"}
                    ]}
                    formData={this.props.formData}
                />
            </div>
      </Card>
    )
  }
}
