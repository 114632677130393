import ApiService from '../../../../../services/api.service';
import http from "../../../../../services/http.service";
import service from 'axios'

class BP21TFService extends ApiService {
  name= 'BP_21_TF';
  path= 'spt21/tf';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    calculate: async (param) => {
      var res = await this.http.post(this.apiLocation+'/spt21/tf/calculation', param)

      return res.data;
    },
    calculateAkumulasi: async (data)=> {
      let res = await http.post(`${this.apiLocation}/spt21/tf/calculation/accumulation`, data);

      return res;
    },
    total: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/spt21/tf/summary/${sptId}`)

      return res.data;
    },
    cetak: async (sptId, ids) => {
      var res = await this.http.get(`${this.apiLocation}/spt21/tf/${ids[0]}/report`)

      return res;
    },
    cetakBulk: async (sptId, ids) => {
      var res = await this.http.post(`${this.apiLocation}/spt21/tf/report`, ids)
      return res;
    },
    cetakBulkPisah: async (sptId, ids) => {
      var res = await this.http.post(`${this.apiLocation}/spt21/tf/report/pisah`, ids)
      return res;
    },
    cetakBulkMerge: async (ids) => {
      var res = await this.http.post(`${this.apiLocation}/spt21/tf/report/bulk/merge`, ids, {
        responseType: 'blob'
      })

      return res;
    },
    cetakDaftar: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/spt21/tf/${sptId}/report-list`)

      return res;
    },
    downloadCsv: async (sptId)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/spt/export`)

      return res;
    },
    email: async (category, ids) => {
      return await this.http.post(`${this.apiLocation}/email?category=${category}`, ids)
    },
    cekEmail: async(companyId)=> {
      return await this.http.get(`${this.apiLocation}/setting/mail/${companyId}/company/check`)
    },
    emailSpt: async (category, sptId, ids, companyId) => {
      if(ids.length == 1){
        return await this.http.get(`${this.apiLocation}/spt21/tf/${ids[0]}/mail`, {
          params: {
            companyId: companyId
          }
        })
      } else {
        return await this.http.post(`${this.apiLocation}/spt21/tf/mail`, ids, {
          params: {
            companyId: companyId
          }
        })
      }
    },
    deleteBulk: async (sptId, ids)=> {
      let res = await http.post(`/api/eppt/delete/bp`, ids);

      return res;
    },
  }
}


export default new BP21TFService();
