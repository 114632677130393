import ApiService from '../../../../../services/api.service';

class ImportService extends ApiService {
  name= 'Import21';
  path= 'import';
  pathImportUser= 'import/user';
  pathImportOrganization= 'import/organization';
  pathEbillingImport= 'ebilling/import';

  constructor() {
    super();
    this.init()
  }
}


export default new ImportService();
