import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  Tab,
  Tabs,
  SelectionControlGroup,
  Checkbox
} from 'react-md';

import {validation, Textfield, Searchfield, Searchfieldv2, TextfieldMask, Switch, Datepicker, Radio, convert} from '../../../../../components/form';
import UtilService from '../../../../../services/utils.service'
import FormView from '../../../../../components/entity/form.view';
import DialogView from '../../../../../components/entity/dialog.view';
import DistrictsService from './Districts.service'
import moment from 'moment'
import counterpart from 'counterpart'
import UserService from './../User/User.service'
import RegencyService from './../Regencies/Regencies.service'

@reduxForm({form: 'DistrictsForm', destroyOnUnmount: true})
@connect((state) => ({
  formData:state.form.DistrictsForm,
  user: state.auth.user
}))
export default class DistrictsFormView extends FormView {
  service=DistrictsService
  viewType=2;
  
  formView() {
    return (
        <Card>
            <div className='md-grid'>
                <Field
                    label='id'
                    name='id'
                    className="md-cell md-cell--6"
                    component={TextfieldMask}
                    maskFormat="##"
                    mask="_"
                    validate={validation.required}
                />
                <Field
                    label='Regency Id'
                    name='regencyId'
                    className="md-cell md-cell--6"
                    service={RegencyService}
                    valueField="id"
                    remoteSearch={true}
                    component={Searchfieldv2}
                    validate={validation.required}
                />
                <Field
                    label='Name'
                    name='name'
                    className="md-cell md-cell--6"
                    component={Textfield}
                    validate={validation.required}
                />
               
            </div>
      </Card>
    )
  }
}
