import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';

import FormDialog from './Organization.dialog.view';
import OrganizationService from './Organization.service';
import ListView from '../../../../components/entity/listView';
import iziToast from 'izitoast';
import download from "downloadjs";
import LogExportService from "../../Export/LogExport.service";
import ParamService from "../../../../services/param.service";

export class OrganizationView extends ListView{
  service=OrganizationService
  FormDialog=FormDialog

  editDialog=false

  async handleExportOrganization() {
    let selected = this.props.table.selected

    if (selected.length > 0) {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export CSV Organization',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: 'Apakah anda yakin akan meng-export CSV data yang diceklis?'
      }));

      if(dialog) {
        var ids = [];
        selected.map(function (i) {
          ids.push(i.id)
        })

        var res = await LogExportService.api.exportBulk(this.service.path, ids);
        var filename = res.headers.filename
        download(res.data, filename);
      }
    }
    else {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export CSV Organization',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: (
          <div>
            <this.ig.Field
              label='Nama'
              name='name__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Nama Alias'
              name='aliasName__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='NPWP'
              name='npwp__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Alamat'
              name='address__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Kota'
              name='city__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Email'
              name='email__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
          </div>
        )
      }));

      if (dialog) {
        let param = ParamService.convert(dialog)

        let res = await LogExportService.api.exportByParamCsv(this.service.path, param);

        let filename = res.headers.filename;

        download(res.data, filename);
      }
    }
  }

  async handleExportSigner21() {
    let selected = this.props.table.selected

    if (selected.length > 0) {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export CSV Signer 21',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: 'Apakah anda yakin akan meng-export CSV data yang diceklis?'
      }));

      if(dialog) {
        var ids = [];
        selected.map(function (i) {
          ids.push(i.id)
        })

        var res = await LogExportService.api.exportBulk(this.service.path + "/signer21", ids);
        var filename = res.headers.filename
        download(res.data, filename);
      }
    }
    else {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export CSV Signer 21',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: (
          <div>
            <this.ig.Field
              label='Nama'
              name='name__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Nama Alias'
              name='aliasName__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='NPWP'
              name='npwp__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Alamat'
              name='address__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Kota'
              name='city__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Email'
              name='email__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
          </div>
        )
      }));

      if (dialog) {
        let param = ParamService.convert(dialog)

        let res = await LogExportService.api.exportByParamCsv(this.service.path + "/signer21", param);

        let filename = res.headers.filename;

        download(res.data, filename);
      }
    }
  }

  async handleExportSigner23() {
    let selected = this.props.table.selected

    if (selected.length > 0) {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export CSV Signer 23',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: 'Apakah anda yakin akan meng-export CSV data yang diceklis?'
      }));

      if(dialog) {
        var ids = [];
        selected.map(function (i) {
          ids.push(i.id)
        })

        var res = await LogExportService.api.exportBulk(this.service.path + "/signer23", ids);
        var filename = res.headers.filename
        download(res.data, filename);
      }
    }
    else {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export CSV Signer 23',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: (
          <div>
            <this.ig.Field
              label='Nama'
              name='name__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Nama Alias'
              name='aliasName__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='NPWP'
              name='npwp__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Alamat'
              name='address__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Kota'
              name='city__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Email'
              name='email__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
          </div>
        )
      }));

      if (dialog) {
        let param = ParamService.convert(dialog)

        let res = await LogExportService.api.exportByParamCsv(this.service.path + "/signer23", param);

        let filename = res.headers.filename;

        download(res.data, filename);
      }
    }
  }

  async handleExportSigner4a2() {
    let selected = this.props.table.selected

    if (selected.length > 0) {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export CSV Signer 4 (2)',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: 'Apakah anda yakin akan meng-export CSV data yang diceklis?'
      }));

      if(dialog) {
        var ids = [];
        selected.map(function (i) {
          ids.push(i.id)
        })

        var res = await LogExportService.api.exportBulk(this.service.path + "/signer4a2", ids);
        var filename = res.headers.filename
        download(res.data, filename);
      }
    }
    else {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export CSV Signer 4 (2)',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: (
          <div>
            <this.ig.Field
              label='Nama'
              name='name__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Nama Alias'
              name='aliasName__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='NPWP'
              name='npwp__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Alamat'
              name='address__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Kota'
              name='city__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Email'
              name='email__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
          </div>
        )
      }));

      if (dialog) {
        let param = ParamService.convert(dialog)

        let res = await LogExportService.api.exportByParamCsv(this.service.path + "/signer4a2", param);

        let filename = res.headers.filename;

        download(res.data, filename);
      }
    }
  }

  async handleExportSigner15() {
    let selected = this.props.table.selected

    if (selected.length > 0) {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export CSV Signer 15',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: 'Apakah anda yakin akan meng-export CSV data yang diceklis?'
      }));

      if(dialog) {
        var ids = [];
        selected.map(function (i) {
          ids.push(i.id)
        })

        var res = await LogExportService.api.exportBulk(this.service.path + "/signer15", ids);
        var filename = res.headers.filename
        download(res.data, filename);
      }
    }
    else {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export CSV Signer 15',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: (
          <div>
            <this.ig.Field
              label='Nama'
              name='name__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Nama Alias'
              name='aliasName__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='NPWP'
              name='npwp__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Alamat'
              name='address__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Kota'
              name='city__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Email'
              name='email__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
          </div>
        )
      }));

      if (dialog) {
        let param = ParamService.convert(dialog)

        let res = await LogExportService.api.exportByParamCsv(this.service.path + "/signer15", param);

        let filename = res.headers.filename;

        download(res.data, filename);
      }
    }
  }

  async handleExportSigner22() {
    let selected = this.props.table.selected

    if (selected.length > 0) {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export CSV Signer 22',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: 'Apakah anda yakin akan meng-export CSV data yang diceklis?'
      }));

      if(dialog) {
        var ids = [];
        selected.map(function (i) {
          ids.push(i.id)
        })

        var res = await LogExportService.api.exportBulk(this.service.path + "/signer22", ids);
        var filename = res.headers.filename
        download(res.data, filename);
      }
    }
    else {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export CSV Signer 22',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: (
          <div>
            <this.ig.Field
              label='Nama'
              name='name__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Nama Alias'
              name='aliasName__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='NPWP'
              name='npwp__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Alamat'
              name='address__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Kota'
              name='city__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Email'
              name='email__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
          </div>
        )
      }));

      if (dialog) {
        let param = ParamService.convert(dialog)

        let res = await LogExportService.api.exportByParamCsv(this.service.path + "/signer22", param);

        let filename = res.headers.filename;

        download(res.data, filename);
      }
    }
  }

  async handleExportOrganizationUser() {
    let selected = this.props.table.selected

    if (selected.length > 0) {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export CSV Organization User',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: 'Apakah anda yakin akan meng-export CSV data yang diceklis?'
      }));

      if(dialog) {
        var ids = [];
        selected.map(function (i) {
          ids.push(i.id)
        })

        var res = await LogExportService.api.exportBulk(this.service.path + "/user", ids);
        var filename = res.headers.filename
        download(res.data, filename);
      }
    }
    else {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export CSV Organization User',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: (
          <div>
            <this.ig.Field
              label='Nama'
              name='name__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Nama Alias'
              name='aliasName__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='NPWP'
              name='npwp__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Alamat'
              name='address__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Kota'
              name='city__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Email'
              name='email__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
          </div>
        )
      }));

      if (dialog) {
        let param = ParamService.convert(dialog)

        let res = await LogExportService.api.exportByParamCsv(this.service.path + "/user", param);

        let filename = res.headers.filename;

        download(res.data, filename);
      }
    }
  }

  async handleExportSummaryOrganization() {
    let selected = this.props.table.selected

    if (selected.length > 0) {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export Summary Organization',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: 'Apakah anda yakin akan meng-export CSV data yang diceklis?'
      }));

      if(dialog) {
        var ids = [];
        selected.map(function (i) {
          ids.push(i.id)
        })

        var res = await LogExportService.api.exportBulk(this.service.path + "/summary", ids);
        var filename = res.headers.filename
        download(res.data, filename);
      }
    }
    else {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export Summary Organization',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: (
          <div>
            <this.ig.Field
              label='Nama'
              name='name__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Nama Alias'
              name='aliasName__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='NPWP'
              name='npwp__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Alamat'
              name='address__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Kota'
              name='city__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Email'
              name='email__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
          </div>
        )
      }));

      if (dialog) {
        let param = ParamService.convert(dialog)

        let res = await LogExportService.api.exportByParamCsv(this.service.path + "/summary", param);

        let filename = res.headers.filename;

        download(res.data, filename);
      }
    }
  }

  barActions() {
    return [
      ...this._barActions,
      {
        label:'word.exportCsv',
        iconClassName:'mdi mdi-file-excel',
        // disabledFunc:() => this.props.table.selected.length === 0,
        onClick:() => this.handleExportOrganization()
      },
      {
        label:'word.exportCsvSigner21',
        iconClassName:'mdi mdi-file-document',
        // disabledFunc:() => this.props.table.selected.length === 0,
        onClick:() => this.handleExportSigner21()
      },
      {
        label:'word.exportCsvSigner23',
        iconClassName:'mdi mdi-file-document',
        // disabledFunc:() => this.props.table.selected.length === 0,
        onClick:() => this.handleExportSigner23()
      },
      {
        label:'word.exportCsvSigner4a2',
        iconClassName:'mdi mdi-file-document',
        // disabledFunc:() => this.props.table.selected.length === 0,
        onClick:() => this.handleExportSigner4a2()
      },
      {
        label:'word.exportCsvSigner15',
        iconClassName:'mdi mdi-file-document',
        // disabledFunc:() => this.props.table.selected.length === 0,
        onClick:() => this.handleExportSigner15()
      },
      {
        label:'word.exportCsvSigner22',
        iconClassName:'mdi mdi-file-document',
        // disabledFunc:() => this.props.table.selected.length === 0,
        onClick:() => this.handleExportSigner22()
      },
      {
        label:'word.exportCsvOrganizationUser',
        iconClassName:'mdi mdi-account-group',
        // disabledFunc:() => this.props.table.selected.length === 0,
        onClick:() => this.handleExportOrganizationUser()
      },
      {
        label:'word.exportReportOrganization',
        iconClassName:'mdi mdi-note',
        // disabledFunc:() => this.props.table.selected.length === 0,
        onClick:() => this.handleExportSummaryOrganization()
      },
    ]
  }

  columns=[
    {isSortable:true, label: "word.name",  value: "name", show:true, isSearchable:true, searchAction: 'contains'},
    {isSortable:true, label: "word.aliasName",  value: "aliasName", show:true, isSearchable:true, searchAction: 'contains'},
    {isSortable:true, label: "word.npwp", value: "npwp", className: "mpk-font-size-S", show:true, isSearchable:true, searchAction: 'contains'},
    {isSortable:true, label: "word.active", value: "active", type: 'boolean', onClick:() => {}, show:true, isSearchable:true},
    {isSortable:true, label: "word.address", value: "address", className: "mpk-font-size-S", show:false, isSearchable:true, searchAction: 'contains'},
    {isSortable:true, label: "word.city", value: "city", className: "mpk-font-size-S", show:true, isSearchable:true, searchAction: 'contains'},
    {isSortable:true, label: "word.postalCode", value: "postalCode", className: "mpk-font-size-S", show:false, isSearchable:true},
    {isSortable:true, label: "word.phone", value: "phone", className: "mpk-font-size-S", show:false, isSearchable:true},
    {isSortable:true, label: "word.email", value: "email", className: "mpk-font-size-S", show:false, isSearchable:true, searchAction: 'contains'}
  ]
}

export default connect(OrganizationService.stateConnectSetting(), OrganizationService.actionConnectSetting())(OrganizationView)
