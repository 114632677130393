/**
 * Created by dwiargo on 1/2/18.
 */
import hosts from './hosts';
import jwt from 'jsonwebtoken';
import moment from 'moment';

let reqOptions = {
  companyId: null, 
  subscriptionId:null,
  baseUrl: null
}

const utilsService = {

  // '4/30/2018' to 1525021200000
  slashDateToMillis: (str,isStart) => {
    var h = 59, m = 59, s = 59, ms = 999;
    if(isStart){
      h = 0; m = 0; s = 0; ms = 0;
    }
    let p = str.split('/')
    let d = new Date(parseInt(p[2], 0), parseInt(p[0], 0)-1, parseInt(p[1], 0), h, m, s, ms)
    return d.getTime()
  },

  mapUrlParams: params => {
    let newParams = {};
    let switcher = {
      page: "page",
      column: "field",
      sortBy: "column",
      sort: "order",
      size: "size",
      keyword: "query",
      startDate: "startDate",
      endDate: "endDate"
    };

    let keys = Object.keys(switcher);
    for(let i = 0 ; i < keys.length ; i++){
      let key=keys[i];
      if(params[key]){
        if(key==='startDate'){
          newParams[switcher[key]] = utilsService.slashDateToMillis( params[key], true );
        }else if(key==='endDate'){
          newParams[switcher[key]] = utilsService.slashDateToMillis( params[key], false );
        }else{
          newParams[switcher[key]] = params[key];
        }
      }
    }

    return newParams;
  },
  cloneObject: object => {
    return JSON.parse(JSON.stringify(object));
  },
  parseNPWP:(npwp) => {
    let result = String(npwp).split('');
    result.splice(2, 0, '.');
    result.splice(6, 0, '.');
    result.splice(10, 0, '.');
    result.splice(12, 0, '-');
    result.splice(16, 0, '.');
    return result.toString().replace(/,/g,'');
  },
  setReqOptionsBaseUrl: baseUrl => {
    reqOptions.baseUrl = baseUrl;
  },
  setReqOptionsCompanyId: id => {
    reqOptions.companyId = id;
  },
  setReqOptionsSubscriptionId: id => {
    reqOptions.subscriptionId = id;
  },
  generateGatewayOptions:(url, config={headers:{}}, enabledHost=true, useDefaultBaseUrl=true) => {
    let { host, baseUrl, env } = hosts.getAll().apiGateway;
    config = [ null, undefined ].indexOf(config) >= 0 ? {headers:{}} : config;
    if(!config.headers) config.headers = {};
    if(reqOptions.companyId) config.headers['X-COMPANY-ID'] = reqOptions.companyId;
    if(reqOptions.subscriptionId) config.headers['X-SUBSCRIPTION-ID'] = reqOptions.subscriptionId;
    // config.headers.Host = hostName;
    // ON DEVELOPMENT ONLY
    if(env === "development"){
      let jwtData = {
        iss: 'iss',
        aud: ['aud'],
        jti: 'jti',
        isValid: true,
        exp: moment().add(1,'day').valueOf(),
        iat: new Date().valueOf(),
        sub: 'jhon',
        metadata: {
          id:'1',
          name:'Jhon',
          email:'jhon@system.com',
          username:'jhon',
          npwp:'123456789012345',
          mobilePhone:'0192039821023',
          role:'user',
          isAdmin: 'true'
        }
      };
      
      config.headers['X-Pajakku-Profile'] = jwt.sign(jwtData, '2ykCBn9zaEDQHDuyUrbLhqo2kRBiV0wy4vRCPrkw');
    }

    return {
      url:enabledHost ? `${host}${reqOptions.baseUrl || (useDefaultBaseUrl ? baseUrl : '')}${url}` : url, 
      config
    }
  }
};

export default utilsService;