import ApiService from '../../../../services/api.service';

class CountryCodeService extends ApiService {
  name= 'countryCode';
  path= 'master/negara';
  constructor() {
    super();
    this.init()
  }
}


export default new CountryCodeService();
