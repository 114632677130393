/**
 * Created by dwiargo on 11/29/17.
 */
import * as CONSTANT from './../../config/constant'
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Router from 'react-router-dom/Router';
import _ from 'lodash';
import {
  Avatar,
  Divider,
  FontIcon,
  List,
  ListItem,
  Subheader,
  Button,
  Card,
  CardTitle,
  DialogContainer,
  CircularProgress
} from 'react-md';
import { Field, reduxForm, getFormValues  } from 'redux-form';
import { Appbar, Notification, Sidebar, rdxConnect } from 'react-mpk';
import * as globalActions from 'react-mpk/redux/actions/globalActions';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Radio} from '../../components/form';
import routes from './routes';
import AuthService from '../../services/auth.service';
import OrganizationSwitcher from '../../components/OrganizationSwitcher/OrganizationSwitcher';
import defaultMenu from './defaultMenu';
import modularMenu from './modularMenu';
import * as constant from '../../config/constant';
import UserBar from '../../components/UserBar/UserBar';
import ModuleMenu from '../../components/ModuleMenu/ModuleMenu';
import authService from '../../services/auth.service';
import websocketService from '../../services/websocket.service';
import izitoast from 'izitoast'
import companyService from './../../services/companyService'
import PropTypes from 'prop-types';
import UserAccess21 from './pph/21_26/UserAccess/UserAccess.service'
import { filter } from 'bluebird';
import SupportMPK from './../../components/support-mpk-master/index'
import OrganizationService from './Administrator/Organization/Organization.service'

@connect(state => ({
  global:state.global,
  isLoginIn: state.authEppt.isLoginIn,
  user: state.authEppt.user,
  showGlobalProgress: state.authEppt.showGlobalProgress,
  organization: state.authEppt.currentOrganization,
  access: state.authEppt.access,
  spt: state.authEppt.currentSPT,
  currentModule: state.authEppt.currentModule,
  company: state.authEppt.currentCompany,
  setting: state.authEppt.setting,
  menuType: state.authEppt.menuType,
  auth: state.auth.user,
  company: state.auth.company,
  authData: state.auth
}), dispatch => ({
  dispatch: dispatch,
  globalActions:bindActionCreators(globalActions, dispatch)
}))
class Main extends Component{
  constructor(props) {
    super(props);
    this.state = {
      version: '',
      filter21Menu: [],
      filter21IsTrue: false,
    }
  }

  static contextTypes = {
    showDialog: PropTypes.func
  };

  async logout() {
    this.props.history.push('/main')
    await AuthService.api.logout(this.props.dispatch);
  }

  // CORE EPPT
  async componentDidMount(){
    try {
      var companyId = this.props.match.params.companyId
      let res21 = await UserAccess21.api.getSettingAccess(companyId)
      if(res21.data.active == true){
        this.setState({ filter21IsTrue: true })
        let per21 = await UserAccess21.api.cekAccess()
        console.log(per21)
        this.setState({
          filter21Menu: per21.data
        })
      }
    } catch(e){
      console.log(e)
    }
    try {
      var companyId = this.props.match.params.companyId
      var subscriptionId = this.props.match.params.subscriptionId
      let res = await companyService.meAsUser(companyId, subscriptionId);
      var user = res.data
      let { company } = res.data;
      let orgs = {
        data: []
      }
      try {
        orgs = await OrganizationService.api.getOrganizationHost({
          field: null,
          query: null
        })
      } catch(e){}
      company.organizations = orgs.data
      this.props.authActions.setProperties({
        company,
        meInCompany: res.data,
      });
      // DISPATCH USER
      // console.log(res.data, "->")
      var userEppt = {
        id: user.id,
        login: user.username,
        firstName: user.name,
        lastName: '',
        email: user.email,
        imageUrl: '',
        activated: true,
        langKey: 'en',
        authorities: [{ name: 'ROLE_EPPT_USER' }],
        company: {
          id: company.id,
          nama: company.name,
          alamat: company.address,
          email: company.email,
          telp: '',
          sn: '',
          active: true
        }
      }
      var token = {
        access_token: 'cb7e9d78-1fa3-4593-ab10-e4ae20a32f92',
        token_type: 'bearer',
        refresh_token: '735121f5-4e61-4ce3-a8f0-068a6d159fd1',
        expires_in: 86399,
        scope: 'read write',
        apps: 'eppt'
      }
      var user = await AuthService.api.getCurrentUserCore(userEppt, this.props.dispatch);
      var token = await AuthService.api.forceLogin(token, this.props.dispatch);
      var company = await AuthService.api.changeCompany(company.company, this.props.dispatch);
      // DISPATCH USER
    } catch(error){
      izitoast.error({
        title: "GET COMPANY",
        message: "Failed get company properties"
      })
      setTimeout(()=> {
        this.props.history.push('/main')
      }, 2000)
    }
  }
  // CORE EPPT

  async componentWillMount() {
    // try {
    //   // init user
    //   // var res = await AuthService.api.getCurrentUser(this.props.dispatch);
    //   // CORE
    //   // AuthService.api.changeMenuType('main', this.props.dispatch)
    //   // try {
    //   //   var userAuth = AuthService.api.getAuth(this.props.user);
    //   //   if(userAuth === constant.ROLE_EPPT_OWNER) {
    //   //     if(this.props.user.company) {
    //   //       AuthService.api.changeCompany(this.props.user.company, this.props.dispatch);
    //   //     }
    //   //   } else {
    //   //     AuthService.api.changeCompany({}, this.props.dispatch)
    //   //   }
    //   // } catch(e){ }

    //   // get access
    //   // try {
    //   //   // AuthService.api.getOrgAccess(this.props.dispatch)
    //   // } catch(e){ }

    //   // connect to websocket
    //   // try {
    //   //   websocketService.connect();
    //   // } catch (e){ }

    //   /*websocketService.connected.promise.then(()=> {
    //     websocketService.subscribe('/user/notification', (d)=> {
    //       try {
    //         console.log(d)
    //       } catch (error) {
    //         console.log(error)
    //       }

    //     }, true)
    //   })*/

    //   // var version = await AuthService.http.get('/management/info');
    //   // if(version.data && version.data.build) {
    //   //   this.setState({version: version.data.build.version})
    //   // }
    // } catch(e) {
    //   await this.logout(this.props.dispatch)
    // }
  }

  componentWillReceiveProps(nextProps) {
    // change menu from spt -> main
    if(nextProps.location.pathname != this.props.location.pathname && nextProps.location.pathname.search('/open') == -1) {
      AuthService.api.changeSPT({}, this.props.dispatch)
    }
  }

  buildMainMenus() {
    let defMenus = modularMenu[this.props.currentModule];
    if(!defMenus) defMenus = defaultMenu['none']

    if(this.props.setting.menuType === 'Default') {
      defMenus = defaultMenu.mainMenus;
    }

    let customDefMenus = Object.assign([], defMenus)

    try {
      var permissions  = []
      try {
        var companyCore = this.props.company
        companyCore.subscriptions.map((d)=> {
          var permission = d.permission
          permission.map((c)=> {
            permissions.push(c)
          })
        })
      } catch(e){}
      var getSpt1771 = permissions.filter((d)=> { return d.resourceUri == '/spt1771' })
      if(getSpt1771.length != 0){ 
        localStorage.setItem("p1771", "true")
        customDefMenus.map((d)=> {
          if(d.label.id == "E-Faktur Lapor & SPT 1771" || d.label.id == "E-Faktur Lapor"){
            d.label.id = "E-Faktur Lapor & SPT 1771"
            d.label.en = "E-Faktur Lapor & SPT 1771"
            d.children = [
              {
                label: {
                  id: 'Tarra Lapor (PPN)',
                  en: 'Tarra Lapor (PPN)'
                },
                iconClassName: 'mdi mdi-view-grid',
                path: '/tarra-lapor',
              },  
              {
                label: {
                  id: 'SPT 1771',
                  en: 'SPT 1771'
                },
                iconClassName: 'mdi mdi-view-grid',
                path: '/spt-1771',
              }
            ]
          }
        })
      } else { 
        localStorage.setItem("p1771", "false"); 
        customDefMenus.map((d)=> {
          if(d.label.id == "E-Faktur Lapor & SPT 1771"){
            d.label.id = "E-Faktur Lapor"
            d.label.en = "E-Faktur Lapor"
            d.children = [
              {
                label: {
                  id: 'Tarra Lapor (PPN)',
                  en: 'Tarra Lapor (PPN)'
                },
                iconClassName: 'mdi mdi-view-grid',
                path: '/tarra-lapor',
              },  
            ]
          }
        })
      }
    } catch(e){}

    try {
      var companyId = this.props.match.params.companyId
      if(this.state.filter21IsTrue){
        var pph21Index = 3
        customDefMenus[pph21Index].children = []
        customDefMenus[pph21Index].children.push({
          label: {
            id: 'Daftar SPT',
            en: 'SPT List'
          },
          iconClassName: 'mdi mdi-view-list',
          path: '/SPT_21',
          permission: 'SPT_21',
          level: CONSTANT.LEVEL.ROLE_EPPT_USER,
          user: true
        })
        var c = ["SPT_21_TF", "SPT_21_F", "SPT_21_SM", "SPT_21_A1", "SPT_21_SATU_TAHUN"]
        c.map((d)=> {
          var accessLength = this.state.filter21Menu.filter((d2)=> {
            return d2.access == d
          })
          if(accessLength.length > 0 && d == "SPT_21_TF"){
            customDefMenus[pph21Index].children.push({
              label: {
                id: 'BP Tidak Final',
                en: 'BP Tidak Final'
              },
              iconClassName: 'mdi mdi-view-list',
              path: '/BP_21_TF',
              permission: 'BP_21_TF',
              level: CONSTANT.LEVEL.ROLE_EPPT_USER,
              user: true
            })
          }
          if(accessLength.length > 0 && d == "SPT_21_F"){
            customDefMenus[pph21Index].children.push({
              label: {
                id: 'BP Final',
                en: 'BP Final'
              },
              iconClassName: 'mdi mdi-view-list',
              path: '/BP_21_F',
              permission: 'BP_21_F',
              level: CONSTANT.LEVEL.ROLE_EPPT_USER,
              user: true
            })
          }
          if(accessLength.length > 0 && d == "SPT_21_SM"){
            customDefMenus[pph21Index].children.push({
              label: {
                id: 'BP Satu Masa',
                en: 'BP Satu Masa'
              },
              iconClassName: 'mdi mdi-view-list',
              path: '/ALL_BP_21_SATU_MASA',
              permission: 'BP_21_SATU_MASA',
              level: CONSTANT.LEVEL.ROLE_EPPT_USER,
              user: true
            })
          }
          if(accessLength.length > 0 && d == "SPT_21_A1"){
            customDefMenus[pph21Index].children.push({
              label: {
                id: 'BP 1721 A1',
                en: 'BP 1721 A1'
              },
              iconClassName: 'mdi mdi-view-list',
              path: '/BP_21_A1',
              permission: 'BP_21_A1',
              level: CONSTANT.LEVEL.ROLE_EPPT_USER,
              user: true
            })
          }
          if(accessLength.length > 0 && d == "SPT_21_SATU_TAHUN"){
            customDefMenus[pph21Index].children.push({
              label: {
                id: 'BP Satu Tahun',
                en: 'BP Satu Tahun'
              },
              iconClassName: 'mdi mdi-view-list',
              path: '/BP_21_SATU_TAHUN',
              permission: 'BP_21_SATU_TAHUN',
              level: CONSTANT.LEVEL.ROLE_EPPT_USER,
              user: true
            })
          }
        })
        // customDefMenus[16].children.push({
        //   label: {
        //     id: 'SSP Pasal 21',
        //     en: 'SSP Pasal 21'
        //   },
        //   iconClassName: 'mdi mdi-view-list',
        //   path: '/IMPORT/SSP_21',
        //   permission: 'IMPORT_SSP_21',
        //   level: CONSTANT.LEVEL.ROLE_EPPT_USER,
        //   user: true
        // })
        // customDefMenus[16].children.push({
        //   label: {
        //     id: 'Daftar Biaya Pasal 21',
        //     en: 'Daftar Biaya Pasal 21'
        //   },
        //   iconClassName: 'mdi mdi-view-list',
        //   path: '/IMPORT/DAFTAR_BIAYA_21',
        //   permission: 'IMPORT_DAFTAR_BIAYA_21',
        //   level: CONSTANT.LEVEL.ROLE_EPPT_USER,
        //   user: true
        // })
        // customDefMenus[12].children.push({
        //   label: {
        //     id: 'SSP Pasal 21',
        //     en: 'SSP Pasal 21'
        //   },
        //   iconClassName: 'mdi mdi-view-list',
        //   path: '/IMPORT/SSP_21',
        //   permission: 'IMPORT_SSP_21',
        //   level: CONSTANT.LEVEL.ROLE_EPPT_USER,
        //   user: true
        // })
        // customDefMenus[12].children.push({
        //   label: {
        //     id: 'Daftar Biaya Pasal 21',
        //     en: 'Daftar Biaya Pasal 21'
        //   },
        //   iconClassName: 'mdi mdi-view-list',
        //   path: '/IMPORT/DAFTAR_BIAYA_21',
        //   permission: 'IMPORT_DAFTAR_BIAYA_21',
        //   level: CONSTANT.LEVEL.ROLE_EPPT_USER,
        //   user: true
        // })
        // customDefMenus[12].children = []
      }
      // console.log(this.props)
      //console.log(customDefMenus, "TELL ME MENUS")
      return this.checkAndCreateMenus(customDefMenus);
    } catch(e){
      return this.checkAndCreateMenus(customDefMenus);
    }
  }

  buildSPTMenus() {
    var companyId = this.props.match.params.companyId
    var subscriptionId = this.props.match.params.subscriptionId
    var spt21CustomMenu = Object.assign([], defaultMenu.sptPph21Menus)
    try {
      if(this.state.filter21IsTrue){
        spt21CustomMenu[1].children = []
        spt21CustomMenu[2].children = []
        spt21CustomMenu[5].children = [
          {
            "label": {
              "id": "SSP Pasal 21",
              "en": "SSP Pasal 21"
            },
            "iconClassName": "mdi mdi-view-list",
            "path": "/IMPORT/SSP_21",
            "permission": "IMPORT_SSP_21",
            "level": 10,
            "user": true
          },
          {
            "label": {
              "id": "Daftar Biaya Pasal 21",
              "en": "Daftar Biaya Pasal 21"
            },
            "iconClassName": "mdi mdi-view-list",
            "path": "/IMPORT/DAFTAR_BIAYA_21",
            "permission": "IMPORT_DAFTAR_BIAYA_21",
            "level": 10,
            "user": true
          }
        ]
        var orgNpwp = this.props.organization.npwp
        var filterByOrgNpwp = this.state.filter21Menu.filter((d)=> {
          return d.npwp == orgNpwp
        })
        filterByOrgNpwp.map((d)=> {
          if(d.access == "SPT_21_TF"){
            spt21CustomMenu[1].children.push({
              label: {
                id: 'Tidak Final',
                en: 'Tidak Final'
              },
              iconClassName: 'mdi mdi-view-list',
              path: '/BP_21_TF',
              permission: 'BP_21_TF'
            })
            spt21CustomMenu[5].children.push({
              "label": {
                "id": "BP Tidak Final",
                "en": "BP Tidak Final"
              },
              "iconClassName": "mdi mdi-view-list",
              "path": "/IMPORT/BP_21_TF",
              "permission": "IMPORT_BP_21_TF",
              "level": 10,
              "user": true
            })
          } else if(d.access == "SPT_21_F"){
            spt21CustomMenu[1].children.push({
              label: {
                id: 'Final',
                en: 'Final'
              },
              iconClassName: 'mdi mdi-view-list',
              path: '/BP_21_F',
              permission: 'BP_21_F'
            })
            spt21CustomMenu[5].children.push({
              "label": {
                "id": "BP Final",
                "en": "BP Final"
              },
              "iconClassName": "mdi mdi-view-list",
              "path": "/IMPORT/BP_21_F",
              "permission": "IMPORT_BP_21_F",
              "level": 10,
              "user": true
            })
          } else if(d.access == "SPT_21_SM"){
            spt21CustomMenu[2].children.push({
              label: {
                id: 'Satu Masa',
                en: 'Satu Masa'
              },
              iconClassName: 'mdi mdi-view-list',
              path: '/BP_21_SATU_MASA',
              permission: 'BP_21_SATU_MASA'
            })
            spt21CustomMenu[5].children.push({
              "label": {
                "id": "Pemotongan Satu Masa Pajak",
                "en": "Pemotongan Satu Masa Pajak"
              },
              "iconClassName": "mdi mdi-view-list",
              "path": "/IMPORT/BP_21_SATU_MASA",
              "permission": "IMPORT_BP_21_SATU_MASA",
              "level": 10,
              "user": true
            },)
            spt21CustomMenu[5].children.push({
              "label": {
                "id": "Pemotongan Satu Masa Pajak TL",
                "en": "Pemotongan Satu Masa Pajak TL"
              },
              "iconClassName": "mdi mdi-view-list",
              "path": "/IMPORT/BP_21_SATU_MASA_TIDAK_LEBIH",
              "permission": "IMPORT_BP_21_SATU_MASA",
              "level": 10,
              "user": true
            })
          } else if(d.access == "SPT_21_A1"){
            spt21CustomMenu[1].children.push({
              label: {
                id: 'BP 1721 A1',
                en: 'BP 1721 A1'
              },
              iconClassName: 'mdi mdi-view-list',
              path: '/BP_21_A1',
              permission: 'BP_21_A1',
              decemberOnly: true
            })
            spt21CustomMenu[5].children.push({
              "label": {
                "id": "BP 1721 A1",
                "en": "BP 1721 A1"
              },
              "iconClassName": "mdi mdi-view-list",
              "path": "/IMPORT/BP_21_A1",
              "permission": "IMPORT_BP_21_A1",
              "level": 10,
              "user": true
            })
          } else if(d.access == "SPT_21_SATU_TAHUN"){
            spt21CustomMenu[2].children.push({
              label: {
                id: 'Satu Tahun',
                en: 'Satu Tahun'
              },
              iconClassName: 'mdi mdi-view-list',
              path: '/BP_21_SATU_TAHUN',
              permission: 'BP_21_SATU_TAHUN',
              decemberOnly: true
            })
          }
        })
      }
    } catch(e){
      console.log(e, "ASDSADSD")
    }

    if(this.props.menuType === 'SPT_21') {
      return this.checkAndCreateMenus(spt21CustomMenu, `/company/${companyId}/${subscriptionId}/init/SPT_21`);
    } else if(this.props.menuType === 'SPT_23') {
      return this.checkAndCreateMenus(defaultMenu.sptPph23Menus, `/company/${companyId}/${subscriptionId}/init/SPT_23`);
    } else if(this.props.menuType === 'ebupot') {
      return this.checkAndCreateMenus(defaultMenu.sptEbupot23Menus, `/company/${companyId}/${subscriptionId}/init/ebupot-23`);
    } else if(this.props.menuType === 'SPT_4a2') {
      return this.checkAndCreateMenus(defaultMenu.sptPph4a2Menus, `/company/${companyId}/${subscriptionId}/init/SPT_4a2`);
    } else if(this.props.menuType === 'SPT_15') {
      return this.checkAndCreateMenus(defaultMenu.sptPph15Menus, `/company/${companyId}/${subscriptionId}/init/SPT_15`);
    } else if(this.props.menuType === 'SPT_22') {
      return this.checkAndCreateMenus(defaultMenu.sptPph22Menus, `/company/${companyId}/${subscriptionId}/init/SPT_22`);
    } else if(this.props.menuType === 'SPT_25') {
      return this.checkAndCreateMenus(defaultMenu.sptPph25Menus, `/company/${companyId}/${subscriptionId}/init/SPT_25`);
    }
  }

  buildCompanyMenus() {
    return this.checkAndCreateMenus(defaultMenu.companyMenu, '/company');
  }

  buildMenus() {
    // switch between spt menu and main menu
    if(this.props.menuType.search('SPT') >= 0) {
      return this.buildSPTMenus()
    } else if(this.props.menuType.search('ebupot') >= 0) {
      return this.buildSPTMenus()
    } else if(this.props.menuType === 'company') {
      return this.buildCompanyMenus()
    } else {
      return this.buildMainMenus()
    }
  }

  checkAndCreateMenus(defMenu=[], sptPath) {
    var userAuth = constant.ROLE_EPPT_USER;
    if(this.props.user.authorities) {
      this.props.user.authorities.forEach((d, i) => {
        if(d.name === constant.ROLE_EPPT_ADMIN) {
          userAuth = constant.ROLE_EPPT_ADMIN;
        }

        if(userAuth !== constant.ROLE_EPPT_ADMIN) {
          if(d.name === constant.ROLE_EPPT_OWNER) {
            userAuth = constant.ROLE_EPPT_OWNER;
          }
        }
      })
    }

    var isDecember = null
    if(this.props.spt.data){
      if(this.props.spt.data.month == 12){
        isDecember = true
      }
    }

    var mainMenus = [];
    defMenu.forEach((d, i)=> {
      var hm = {
        label: d.label,
        children: []
      };

      d.children.forEach((c, j)=> {
        var shouldPush = false;
        if(sptPath) {
          c.onClick = ()=> {
            if(this.props.menuType.search('SPT') >= 0) {
              this.props.history.push(sptPath+'/'+this.props.spt.data.id+'/open'+c.path)
            } else if(this.props.menuType.search('ebupot') >= 0) {
              this.props.history.push(sptPath+'/'+this.props.spt.data.id+'/open'+c.path)
            } else if(this.props.menuType === 'company') {
              this.props.history.push(sptPath+'/'+this.props.company.id+'/open'+c.path)
            }
          }
        }

        if(c.rootPath) {
          c.onClick = ()=> {
            if(userAuth === constant.ROLE_EPPT_OWNER) {
              this.props.history.push(c.rootPath+'/'+this.props.company.id+'/open'+c.path)
            }
          }
        }

        if(userAuth === constant.ROLE_EPPT_ADMIN) {
          if(!c.owner && !c.user) {
            if(c.decemberOnly){
              if(isDecember){
                shouldPush = true;
              }
            } else {
              shouldPush = true;
            }
          }
        } else if(userAuth === constant.ROLE_EPPT_OWNER) {
          if(!c.admin && !c.user) {
            if(c.decemberOnly){
              if(isDecember){
                shouldPush = true;
              }
            } else {
              shouldPush = true;
            }
          }
        } else {
          if(!c.admin && !c.owner) {
            if(c.permission) {
              // check  permission
              // if(this.props.access[c.permission]) {
              //   hm.children.push(c);
              // }
              if(c.decemberOnly){
                if(isDecember){
                  shouldPush = true;
                }
              } else {
                shouldPush = true;
              }
            } else {
              if(c.decemberOnly){
                if(isDecember){
                  shouldPush = true;
                }
              } else {
                shouldPush = true;
              }
            }
          }
        }

        if(sptPath === '/SPT_22') {
          var organization = this.props.organization;
          if(c.pemungut && !organization.isPemungut) {
            shouldPush = false;
          }
        }

        if(shouldPush) {
          var companyId = this.props.match.params.companyId
          var subscriptionId = this.props.match.params.subscriptionId
          var newPath = `/company/${companyId}/${subscriptionId}/init` + c.path
          var newChild = Object.assign({}, c)
          newChild.path = newPath
          hm.children.push(newChild)
        }
      })
      if(hm.children.length > 0) mainMenus.push(hm);
    })

    var indexOf = 0

    // Handle Permission Per Pasal
    try {
      var permissions  = []
      try {
        var companyCore = this.props.company
        companyCore.subscriptions.map((d)=> {
          var permission = d.permission
          permission.map((c)=> {
            permissions.push(c)
          })
        })
      } catch(e){}
      var uniquePermissions = _.uniq(permissions)
      // Limit Fitur 21 // /fitur21
      var getPasal21 = permissions.filter((d)=> { return d.resourceUri == '/fitur21' })
      if(getPasal21.length != 0){ localStorage.setItem("p21", "true") } else { localStorage.setItem("p21", "false"); mainMenus = mainMenus.filter((d)=> { return d.label.id != "PPh 21" }) }
      // Limit Fitur 22 // /fitur22
      var getPasal22 = permissions.filter((d)=> { return d.resourceUri == '/fitur22' })
      if(getPasal22.length != 0){ localStorage.setItem("p22", "true") } else { localStorage.setItem("p22", "false"); mainMenus = mainMenus.filter((d)=> { return d.label.id != "PPh 22" }) }
      // Limit Fitur 23 // /fitur23
      var getPasal23 = permissions.filter((d)=> { return d.resourceUri == '/fitur23' })
      if(getPasal23.length != 0){ localStorage.setItem("p23", "true") } else { localStorage.setItem("p23", "false"); mainMenus = mainMenus.filter((d)=> { return d.label.id != "PPh 23/26" }) }
      // Limit Fitur 4 // /fitur4
      var getPasal4 = permissions.filter((d)=> { return d.resourceUri == '/fitur4' })
      if(getPasal4.length  != 0){ localStorage.setItem("p4", "true") } else { localStorage.setItem("p4", "false"); mainMenus = mainMenus.filter((d)=> { return d.label.id != "PPh 4 (2)" }) }
      // Limit Fitur 15 // /fitur15
      var getPasal15 = permissions.filter((d)=> { return d.resourceUri == '/fitur15' })
      if(getPasal15.length != 0){ localStorage.setItem("p15", "true") } else { localStorage.setItem("p15", "false"); mainMenus = mainMenus.filter((d)=> { return d.label.id != "PPh 15" }) }
      // Limit Fitur 25 // /fitur25
      var getPasal25 = permissions.filter((d)=> { return d.resourceUri == '/fitur25' })
      if(getPasal25.length != 0){ localStorage.setItem("p25", "true") } else { localStorage.setItem("p25", "false"); mainMenus = mainMenus.filter((d)=> { return d.label.id != "PPh 25" }) }
      // Limit Fitur 25 // /fitur25
      var getPasalEbupot = permissions.filter((d)=> { return d.resourceUri == '/fiturEbupot' })
      if(getPasalEbupot.length != 0){ localStorage.setItem("pEbupot", "true") } else { localStorage.setItem("pEbupot", "false"); mainMenus = mainMenus.filter((d)=> { return d.label.id != "Ebupot 23/26" }) }
      // Limit Fitur 25 // /fitur25
      var getSpt1771 = permissions.filter((d)=> { return d.resourceUri == '/spt1771' })
      if(getSpt1771.length != 0){ localStorage.setItem("p1771", "true") } else { 
        localStorage.setItem("p1771", "false"); 
        mainMenus.forEach((d, index)=> {
          if(d.label.id == "E-Faktur Lapor & SPT 1771"){
            d.label.id = "E-Faktur Lapor"
            d.label.en = "E-Faktur Lapor"
            indexOf = index
          }
        })
      }
    } catch(e){
      console.log(e, "ERROR E")
    }
    // Handle Permission Per Pasal
    return mainMenus;
  }

  editProfile = () => {
    this.props.history.push("/EDIT_PROFILE")
  }

  editPassword = () => {
    this.props.history.push("/EDIT_PASSWORD")
  }

  render(){
    var halonaurl = []
    if(window.location.href.indexOf('demo') != -1){
        halonaurl = [
            {
              label:"Halona Cloud",
              materialIcon:'dashboard',
              url: "http://halona.sobatpajak.com"
            }
        ]
    } else {
        halonaurl = [
            {
                label:"Halona Cloud",
                materialIcon:'dashboard',
                // url: "http://cloud.halonasoft.com"
                url: "http://halona.sobatpajak.com"
            }
        ]
    }
    return(
      <div className="mpk-layout column fill">
        {this.props.showGlobalProgress && <div className='animated fadeIn' style={{height: '100%', width: '100%',
          background: 'rgba(0,0,0,.7)', position: 'absolute',
          zIndex: 1000, display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'}}>
          <CircularProgress id='global_progress'  />
        </div>
        }

        {/* <Appbar
          appName="eppt"
          version={this.state.version}
          fixedVersion={true}
          showVersion
          onLogout={()=> this.logout()}
          notification={<Notification newFeeds={0}/>}
          profileMenu={[
            {
              label:"Profile",
              materialIcon:'perm_identity',
              onClick:() => this.editProfile()
            },
            {
              label:"Password",
              materialIcon:'lock',
              onClick:() => this.editPassword()
            },
            {
              label:"Setting",
              materialIcon:'settings',
              onClick: async ()=> {
                var d = await this.context.showDialog((props, res, rej) =>({
                  title: 'App Settings',
                  initialValue: _.cloneDeep(this.props.setting),
                  okText: 'Save',
                  text: (
                    <div className='md-grid'>
                      <Field
                        label='Screen size'
                        name='size'
                        className="md-cell md-cell--12"
                        component={Textfield}
                      />
                      <Field
                        label='Menu Type'
                        name='menuType'
                        className="md-cell md-cell--12"
                        options={[{
                          id: 'Default', name: 'Default'
                        },{
                          id: 'Modular', name: 'Modular'
                        }]}
                        component={Searchfield}
                      />
                      <Field
                        label='Menu Collapsible'
                        name='collapsible'
                        className="md-cell md-cell--12"
                        component={Switch}
                      />
                    </div>

                  )
                }))

                if(d) {
                  localStorage.setItem('settings', JSON.stringify(d));
                  location.reload()
                }
              }
            }
          ]}
          translate={true}
        /> */}
        <Appbar
          notification={<Notification newFeeds={0}/>}
          appName="eppt"
          showVersion fixedVersion={false}
          version={this.state.version}
          profileMenu={[
            {
              label:"Company",
              materialIcon:'launch',
              onClick:() => {
                this.props.history.push('/main')
              }
            }
          ]}
          appsShortcut={halonaurl}
          consoleShortcut={Object.keys(this.props.global.appConsole).map(key => {
            return this.props.global.appConsole[key];
          })}
          translate={true}
        /> 
        <div className="mpk-layout fill mpk-relative-position mpk-content">
          <Sidebar
            accordionMode={true}
            menuClass='animated fadeInLeft'
            collapsible={this.props.setting.collapsible}
            headerElements={this.topSideBar()}
            menus={this.buildMenus()}
            getValueByLocaleCode={true}
          />
          <SupportMPK 
             kbs={[
              // {
              //      type: 'troubleshoot',
              //      code: 'epptcore',
              //      label: 'Troubleshoot'
              // },
              // {
              //      type: 'documentation',
              //      code: 'EPPT-CORE',
              //      label: 'Documentation'
              // }
          ]}
          />
          {this.props.user.id &&
            routes()
          }
        </div>
      </div>
    )
  }

  topSideBar() {
    var companyId = this.props.match.params.companyId
    var subscriptionId = this.props.match.params.subscriptionId
    if(this.props.menuType.search('SPT') >= 0 && this.props.spt.data) {
      return (
        <div>
          <OrganizationSwitcher/>
          <List style={{background: '#F44336'}}>
            <ListItem

              primaryTextStyle={{paddingLeft: 58, color: 'rgba(255, 255, 255, 0.87)'}}
              secondaryTextStyle={{paddingLeft: 58, color: 'rgba(255, 255, 255, 0.47)'}}
              tileStyle={{height: 'auto', margin: '10px 0px'}}
              rightIcon={<FontIcon tooltipLabel="Tutup"   className='close-menu' onClick={() => {authService.api.changeMenuType('main', this.props.dispatch); this.props.history.push(`/company/${companyId}/${subscriptionId}/init/${this.props.menuType}`)}}>close</FontIcon>}
              primaryText={this.props.spt.data.year+' - '+this.props.spt.data.month}
              secondaryText={'Pembetulan '+this.props.spt.data.rev}
              />
          </List>
        </div>
      )
    } else if(this.props.menuType.search('ebupot') >= 0 && this.props.spt.data) {
      return (
        <div>
          <OrganizationSwitcher/>
          <List style={{background: '#F44336'}}>
            <ListItem

              primaryTextStyle={{paddingLeft: 58, color: 'rgba(255, 255, 255, 0.87)'}}
              secondaryTextStyle={{paddingLeft: 58, color: 'rgba(255, 255, 255, 0.47)'}}
              tileStyle={{height: 'auto', margin: '10px 0px'}}
              rightIcon={<FontIcon tooltipLabel="Tutup"   className='close-menu' onClick={() => {authService.api.changeMenuType('main', this.props.dispatch);this.props.history.push(`/company/${companyId}/${subscriptionId}/init/ebupot-23`)}}>close</FontIcon>}
              primaryText={this.props.spt.data.year+' - '+this.props.spt.data.month}
              secondaryText={'Pembetulan '+this.props.spt.data.rev}
              />
          </List>
        </div>
      )
    } else if(this.props.menuType === 'company' && this.props.company) {
      var companyName = "A";
      if(this.props.company && this.props.company.nama)  companyName = this.props.company.nama;
      return <div>
          <List className="md-list md-dark-theme" style={{background: 'none'}}>
            <ListItem
              leftAvatar={<Avatar suffix="green">{companyName.charAt(0)}</Avatar>}
              tileStyle={{height: 'auto', margin: '10px 0px'}}
              rightIcon={<FontIcon tooltipLabel="Tutup" className='close-menu' onClick={() => {authService.api.changeMenuType('main', this.props.dispatch); this.props.history.push('/company')}}>close</FontIcon >}
              primaryText={this.props.company.nama}
              secondaryText={this.props.company.email}
              />
          </List>
        </div>
    } else {
      return (
        <div>
          <UserBar />
          {this.props.setting.menuType !== 'Default' &&
          <ModuleMenu />
          }

        </div>

      )
    }
  }
}

export default rdxConnect(Main);
