import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Field, reduxForm } from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../../components/form';

import ImportOrganizationService from './ImportOrganization.service';
import ListView from '../../../../components/entity/listView';
import ColumnService from '../../../../services/column.service';
import ImportService from '../../pph/21_26/Import/Import.service';
import axios from 'axios';
import izitoast from 'izitoast';
import FormDialog from './ImportOrganization.dialog.view';
import download from "downloadjs";
import ListViewImport from '../../../../components/entity/ListViewImport';

@reduxForm({form: 'IMPORT_ORGANIZATION_Form', destroyOnUnmount: true})
@connect((state)=> ({
  ...ImportOrganizationService.stateConnectSetting()(state),
  user: state.authEppt.user,
  company: state.authEppt.currentCompany
}),ImportOrganizationService.actionConnectSetting())
export default class ImportOrganizationView extends ListViewImport {
  service=ImportOrganizationService
  FormDialog=FormDialog

  category='ORGANIZATION'
  importPath='/api/import/company/'
  importPathFunc() {
    return `/api/import/company/${this.props.company.id}`;
  }
  showAutomatisTab=false
  // viewType=2

  beforeFetch(params) {
    params["category.equals"] = "ORGANIZATION"
  }

  tableActions = (data) => {
    let actions = [
      {
        label:"More Info",
        iconClassName:"mdi mdi-bell",
        onClick: (item) => this.editItem(item)
      }
    ]

    let divider = {
      label:"divider",
        iconClassName:"-"
    }

    if(data.status == 'FINISH' || data.status == 'FAILED') {
      let deleteAction = {
        label:"Delete",
        iconClassName:"mdi mdi-delete",
        onClick:(item, callback) => this.deleteItem(item, callback),
        confirmation:{
          title:"sentence.custom.deleteItem",
          message:"sentence.custom.deleteItemConfirmation"
        }
      }

      actions.push(divider)
      actions.push(deleteAction)
    }

    return actions
  }

  _barActions = [
    {
      label:'word.delete',
      iconClassName:'mdi mdi-delete',
      onClick:() => {
        console.log('this',this)
        this.setState({showDialogConfirmDeleteFinishFailedSelected:true})
      },
      disabledFunc:() => {
        let selected = this.props.table.selected
        let deleteable = []

        selected.forEach(data => {
          if(data.status == 'FINISH' || data.status == 'FAILED') {
            deleteable.push(data.id)
          }
        })

        return deleteable.length === 0
      }
    },
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => this.fetchData()
    }
  ]

  async handleDownloadTemplate() {
    var report = await ImportOrganizationService.api.downloadTemplate('ORGANIZATION')
    download(report.data, 'ORGANIZATION.csv');
  }

}
