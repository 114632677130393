import ApiService from '../../../../services/api.service';

class OrganizationService extends ApiService {
  name= 'organization';
  path= 'organization';

  constructor() {
    super();
    this.init()
  }

  api= {
    cetakDaftar: async (id) => {
      var res = await this.http.get(`${this.apiLocation}/report/${id}`, {
        responseType: 'blob'
      })

      return res;
    },
    cetakBulk: async (ids) => {
      var res = await this.http.post(`${this.apiLocation}/spt21/report/bulk`, ids, {
        responseType: 'blob'
      })

      return res;
    },
    updateKey: async (data) => {
      var res = await this.http.post(`${this.apiLocation}/key/update`, data)

      return res;
    },
    getAll: async (params)=> {
      var user = params.user
      delete params.user
      let res = await this.http.get('/api/iams/organization/all', {
          headers: {
            'x-user-profile': JSON.stringify({
                username: user.username,
                id: user.id,
                email: user.email,
                role: user.role.name,
                isAdmin: user.isAdmin + ""
            }),
            params: JSON.stringify(params)
          }
      });
      return res
    },
    getOrganizationHost: async (params)=> {
      let res = await this.http.get('/organizations', {
        params: {
          page: 1,
          size: 10000,
          field: params.field,
          query: params.query,
          order: "DESC",
          column: "npwp"
        }
      })
      return res
    }
  }
}


export default new OrganizationService();
