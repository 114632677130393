import download from "downloadjs";
import LogExportService from "modules/Main/Export/LogExport.service";
import React from 'react';
import { connect } from 'react-redux';
import ListView from '../../../../../../components/entity/listView';
import ColumnService from '../../../../../../services/column.service';
import ParamService from "../../../../../../services/param.service";
import AllSsp22Service from '../AllSsp/AllSsp22.service';
import {Checkbox} from "../../../../../../components/form";
import moment from "moment";

@connect(AllSsp22Service.stateConnectSetting(), AllSsp22Service.actionConnectSetting())
export default class AllSsp22View extends ListView {
  service=AllSsp22Service

  constructor(props){
    super(props);
    this.state = {
      showTableSetting:false,
      showDialogConfirmDeleteSelected:false,
      showForm:false,
      formData: {},
      data: []
    }
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='NPWP Organisasi'
          name='sptNpwpProfile__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Dari Bulan'
          name='sptMonth__greaterOrEqualThan'
          className="md-cell md-cell--6"
          component={this.ig.Searchfield}
          options={[
            {id: 1, name: "1"},
            {id: 2, name: "2"},
            {id: 3, name: "3"},
            {id: 4, name: "4"},
            {id: 5, name: "5"},
            {id: 6, name: "6"},
            {id: 7, name: "7"},
            {id: 8, name: "8"},
            {id: 9, name: "9"},
            {id: 10, name: "10"},
            {id: 11, name: "11"},
            {id: 12, name: "12"}
          ]}
        />
        <this.ig.Field
          label='Sampai Bulan'
          name='sptMonth__lessOrEqualThan'
          className="md-cell md-cell--6"
          component={this.ig.Searchfield}
          options={[
            {id: 1, name: "1"},
            {id: 2, name: "2"},
            {id: 3, name: "3"},
            {id: 4, name: "4"},
            {id: 5, name: "5"},
            {id: 6, name: "6"},
            {id: 7, name: "7"},
            {id: 8, name: "8"},
            {id: 9, name: "9"},
            {id: 10, name: "10"},
            {id: 11, name: "11"},
            {id: 12, name: "12"}
          ]}
        />

        <this.ig.Field
          label='Bulan'
          name='sptMonth__equals'
          className="md-cell md-cell--12"
          component={this.ig.Searchfield}
          options={[
            {id: 1, name: "1"},
            {id: 2, name: "2"},
            {id: 3, name: "3"},
            {id: 4, name: "4"},
            {id: 5, name: "5"},
            {id: 6, name: "6"},
            {id: 7, name: "7"},
            {id: 8, name: "8"},
            {id: 9, name: "9"},
            {id: 10, name: "10"},
            {id: 11, name: "11"},
            {id: 12, name: "12"}
          ]}
        />

        <this.ig.Field
          label='Tahun'
          name='sptYear__equals'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Pembetulan'
          name='sptRev__equals'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Kode Setoran'
          name='kjsCode__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NTPN'
          name='ntpn__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
      </this.SearchContainer>
    )
  }

  editDialog = false;
  _barActions = [];

  editItem = (item) => {
    if(this.editDialog) {
      this.showFormDialog(item);
    } else {
      if(this.rootPath) {
        this.props.history.push(this.rootPath+"/"+item.id)
      } else {
        this.props.history.push('/'+this.service.name+"/"+item.id);
      }
    }
  }

  async handleExportAll() {
    let selected = this.props.table.selected

    if (selected.length > 0) {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export CSV',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: 'Apakah anda yakin akan meng-export CSV data yang diceklis?'
      }));

      if(dialog) {
        let ids = [];

        selected.map(function (i) {
          ids.push(i.id)
        });

        let res = await this.service.api.export(ids);

        window.open(res.data.url);
      }
    }
    else {
      iziToast.info({
        title: "Info",
        message: "Silahkan pilih data yang akan dicetak."
      })
      // let dialog = await this.context.showDialog((props, res, rej) =>({
      //   title: 'Export CSV',
      //   initialValue: {},
      //   height: 'auto',
      //   width: 400,
      //   okText: 'Ya',
      //   text: (
      //     <div>
      //       <this.ig.Field
      //         label='NPWP Organisasi'
      //         name='npwpProfile__contains'
      //         className="md-cell md-cell--12"
      //         component={this.ig.Textfield}
      //       />
      //       <this.ig.Field
      //         label='Bulan'
      //         name='month__equals'
      //         className="md-cell md-cell--12"
      //         component={this.ig.Textfield}
      //       />
      //       <this.ig.Field
      //         label='Tahun'
      //         name='year__equals'
      //         className="md-cell md-cell--12"
      //         component={this.ig.Textfield}
      //       />
      //       <this.ig.Field
      //         label='Pembetulan'
      //         name='rev__equals'
      //         className="md-cell md-cell--12"
      //         component={this.ig.Textfield}
      //       />
      //       <this.ig.Field
      //         label='Kode Setoran'
      //         name='kjs__contains'
      //         className="md-cell md-cell--12"
      //         component={this.ig.Textfield}
      //       />
      //       <this.ig.Field
      //         label='NTPN'
      //         name='ntpn__contains'
      //         className="md-cell md-cell--12"
      //         component={this.ig.Textfield}
      //       />
      //     </div>
      //   )
      // }));

      // if (dialog) {
      //   let param = ParamService.convert(dialog)

      //   let res = await LogExportService.api.exportByParamCsv('spt22/ssp-22', param);

      //   let filename = res.headers.filename;

      //   download(res.data, filename);
      // }
    }
  }

  barActions() {
    return [
      ...this._barActions,
      {
        label:'word.exportCsv',
        iconClassName:'mdi mdi-file-excel',
        // disabledFunc:() => this.props.table.selected.length === 0,
        onClick:() => this.handleExportAll()
      }
    ]
  }

  defaultColumns = [
    /*{isSortable:true, label: "word.id",  value: "id", show:false, isSearchable:true, isDefaultSort: false},*/
    {isSortable:true, label: "word.createdUsername",  value: "createdUsername", show:true, isSearchable:true},
    {isSortable:true, label: "word.createdBy",  value: "createdBy", show:true, isSearchable:true},
    {isSortable:true, label: "word.createdDate", value: "createdDate", show:true, isSearchable:true, type:"date", isDefaultSort: true},
    {isSortable:true, label: "word.modifiedUsername",  value: "modifiedUsername", show:true, isSearchable:true},
    {isSortable:true, label: "word.lastModifiedBy",  value: "lastModifiedBy", show:true, isSearchable:true},
    {isSortable:true, label: "word.lastModifiedDate", value: (d)=> {
        if(d.lastModifiedDate) {
          return <div className='mpk-font-size-S'>{moment(d.lastModifiedDate).format('lll')}</div>
        } else {
          return null;
        }
      }, show:true, isSearchable:true, type:"func", searchField: 'lastModifiedDate', isDefaultSort: true}
  ]

  columns=[
    {isSortable: true, label: "word.npwpProfile", value:"spt.npwp", searchField: "spt.npwp", show:true, isSearchable:true},
    {isSortable: true, label: "word.year", value: (d)=> d.spt.year, searchField: "spt.year", type: "func", show:true, isSearchable:true},
    {isSortable: true, label: "word.month", value: (d)=> d.spt.month, searchField: "spt.month", type: "func", show:true, isSearchable:true},
    {isSortable: true, label: "word.pembetulan", value: (d)=> d.spt.rev, searchField: "spt.rev", type: "func", show:true, isSearchable:true},
    {isSortable: true, label: "word.kjs", searchField: "kjs.code", value: function(d){ return d.kjs.code }, type: "func", show:true, isSearchable:true},
    {isSortable: true, label: "word.date",  value: "date", searchField: "date", show:true, isSearchable:true},
    {isSortable: true, label: "word.ntpn",  value: "ntpn", searchField: "ntpn", show:true, isSearchable:true},
    {isSortable: true, label: "word.total", searchField: "total", value: function(d){ return ColumnService.money(d.total) }, type: "func", show:true, isSearchable:true}

  ]

  _tableActions = [
    // {
    //   label:"More Info",
    //   iconClassName:"mdi mdi-bell",
    //   onClick: (item) => this.editItem(item)
    // }
  ]

}
