import React, { Component } from 'react';
import { Grid, Cell } from 'react-md';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import { validation, Textfield, TextfieldMask, Datepicker, convert } from '../../../../components/form';
import DialogView from '../../../../components/entity/dialog.view';
import PermissionsService from './Permissions.service';

@reduxForm({form: 'permissions', destroyOnUnmount: false})
export default class PermissionsDialogView extends DialogView {
  service=PermissionsService
  initialData = {
  }

  formView() {
    return (
      <Grid style={{padding:'none'}}>
        <Field
          label='Nama'
          name='name'
          className="md-cell md-cell--12"
          component={Textfield}
          validate={validation.required}
        />
        <Field
          label='Keterangan'
          name='description'
          className="md-cell md-cell--12"
          component={Textfield}
          validate={validation.required}
        />
      </Grid>
    )
  }
}
