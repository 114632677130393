import ApiService from '../../../../../../services/api.service'
import http from '../../../../../../services/http.service'
import moment from 'moment'
import React from 'react'
import { Chip } from 'react-md'

class BpeService extends ApiService {
  name= 'Bpe';
  path= 'ebupot/bpe';
  advancedSearch=true

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  columns=[
    {isSortable: true, label: "word.id", searchField: "organization.id", value: "organization.id", show:false, isSearchable:true},
    {isSortable: true, label: "word.esptIdDjp", searchField: "esptIdDjp", type: 'number', value: "esptIdDjp", show:false, isSearchable:true},
    // {
    //   searchField: "status",
    //   label: "word.statusSpt",
    //   type: 'func',
    //   value: (d)=> {
    //     var style = {
    //       background: 'grey'
    //     }
    //     switch (d.status) {
    //       case 'CREATED':
    //         style.background = 'grey'
    //         break;
    //       case 'SUBMIT':
    //         style.background = '#2196F3'
    //         break;
    //       case 'POSTING':
    //         style.background = '#D32F2F'
    //         break;
    //       case 'SENDING':
    //         style.background = '#FFEB3B'
    //         break;
    //       case 'FINISH':
    //         style.background = '#4CAF50'
    //         break;
    //       default:
    //         break;
    //     }
    //     return <Chip label={d.status} style={style} />
    //   },
    //   className: "mpk-font-size-S", show:true, isSearchable:true},
    {isSortable: true, label: "word.ntte", searchField: "ntte", value: "ntte", show:true, isSearchable:true},
    {isSortable: true, label: "word.nominal", searchField: "nominal", type: 'number', value: "nominal", show:true, isSearchable:true},
    {isSortable: true, label: "word.status", searchField: "statusSpt", type: 'func', value: (d)=>{
      if(d.statusSpt && d.statusSpt == 0){
        return "Nihil"
      } else if(d.statusSpt && d.statusSpt == 1){
        return "Kurang Bayar"
      } else if(d.statusSpt && d.statusSpt == 2){
        return "Lebih Bayar"
      }
    }, show:true, isSearchable:true},
    {isSortable: true, label: "word.tgl", searchField: "tgl", value: "tgl", show:true, isSearchable:true},
    {isSortable: true, label: "word.npwp", searchField: "npwpProfile", value: "npwpProfile", show:true, isSearchable:true},
    {isSortable: true, label: "word.pembetulan", searchField: "pembetulan", value: "pembetulan", show:true, isSearchable:true, type: 'number'},
    {isSortable: true, label: "word.month", searchField: "masa", value: "masa", show:true, isSearchable:true, type: 'number'},
    {isSortable: true, label: "word.year",  searchField: "tahun", value: (d)=> d.tahun, show:true, isSearchable:true, type: 'func'},
    // {isSortable: true, label: "word.pembetulan", searchField: "pembetulan", value: "pembetulan", show:true, isSearchable:true, type: 'number'},
    {label: "word.createdBy", searchField: "createdBy", value: "createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.createdDate", searchField: "createdDate", value: (d)=> {
      if(d){
        return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
      } else {
        return "-"
      }
    }, type: 'func', isDefaultSort:true, isSortable:true, show:true, isSearchable:true},
    {label: "word.lastModifiedBy", searchField: "lastModifiedBy", value: "lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.lastModifiedDate", searchField: "lastModifiedDate", value: (d)=> {
      if(d.lastModifiedDate){
        return moment(d.lastModifiedDate).format('DD/MM/YYYY HH:MM')
      } else {
        return "-"
      }
    }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
  ]

  api = {
    getOne: async (id)=>{
      var res = await this.http(`${this.apiLocation}/bpe/${id}`)
      return res
    },
    cetakBpe: async (id) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${id}/report`, {
        responseType: 'blob'
      })

      return res;
    },
    requestBpe: async (id) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${id}/request`, {
        responseType: 'blob'
      })

      return res;
    },
    bpeReports: async (ids) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/report`, ids, {
        responseType: 'blob'
      })

      return res;
    },
  }

}


export default new BpeService();
