import React, { Component } from 'react';
import { Grid, Cell, Switch } from 'react-md';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import { validation, Textfield, TextfieldMask, Datepicker, convert } from '../../../../../components/form';
import DialogView from '../../../../../components/entity/dialog.view';
import Email21LogService from './Email21Log.service';

@reduxForm({form: 'Email21Log', destroyOnUnmount: false})
export default class Email21LogDialogView extends DialogView {
  service=Email21LogService

  submitLabel=" "

  formView() {
    return (
      <Grid style={{padding:'none'}}>
        <Field
          label='Username'
          name='username'
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
          disabled
        />
        <Field
          label='Tipe'
          name='type'
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
          disabled
        />
        <Field
          label='Kategori'
          name='category'
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
          disabled
        />
        <Field
          label='Status'
          name='status'
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
          disabled
        />
        <Field
          label='Subjek'
          name='subject'
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
          disabled
        />
        <Field
          label='To'
          name='to'
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
          disabled
        />
        <Field
          label='Pesan'
          name='message'
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
          disabled
        />
        <Field
          label='Konten'
          name='content'
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
          disabled
        />
        <Field
          label='Nama File'
          name='filename'
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
          disabled
        />
        <Field
          label='Schedule'
          name='scheduled'
          className="md-cell md-cell--6"
          component={Switch}
          validate={validation.required}
          disabled
        />
        <Field
          label='Schedule Date'
          name='scheduledDate'
          className="md-cell md-cell--6"
          component={Datepicker}
          validate={validation.required}
          disabled
        />
        <Field
          label='Executed Date'
          name='executedDate'
          className="md-cell md-cell--6"
          component={Datepicker}
          validate={validation.required}
          disabled
        />
      </Grid>
    )
  }
}