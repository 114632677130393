import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button, SVGIcon } from 'react-md';
import FormDialog from './Master21Ptkp.dialog.view';
import Master21PtkpService from './Master21Ptkp.service';
import ListView from '../../../../../components/entity/listView';
import ColumnService from '../../../../../services/column.service';

@connect((state)=> ({
  ...Master21PtkpService.stateConnectSetting()(state),
  spt: state.authEppt.currentSPT
}), Master21PtkpService.actionConnectSetting())
export default class Master21PtkpView extends ListView {
  service=Master21PtkpService
  FormDialog=()=> <div/>

  addDialog=false
  editDialog=false

  fetchOption() {
    const npwp = this.props.spt.data.npwp
    return {
      path: Master21PtkpService.path+'/'+npwp+'/company'
    }
  }

  _barActions = [
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    },
  ]

  editItem = (item) => {
    this.props.history.push(`${this.props.location.pathname}/${item.id}`)
  }

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    }
  ]

  async afterFetch(data){
    return data.details
  }

  columns=[
    {isSortable: true, label: "word.id", searchField: "id", value: "id", show:false, isSearchable:true},
    {isSortable: true, label: "word.start", searchField: "start", value: "start", show:true, isSearchable:true},
    {isSortable: true, label: "word.end", searchField: "personal", value: "personal", type: "number", show:true, isSearchable:true},
    {isSortable: true, label: "word.akhir", searchField: "dependents", value: "dependents", type: "number", show:true, isSearchable:true},
  ]   
}