import React, { Component } from 'react';
import { connect } from 'react-redux';
import {UserView} from '../../Administrator/User/User.view';
import UserService from '../../Administrator/User/User.service';

@connect(UserService.stateConnectSetting(), UserService.actionConnectSetting())
export default class OwnerCompanyView extends UserView {
  titleHeader() {
    return `entities.${this.service.name}.owner`;
  }

  userPath = 'owner'

  fetchOption() {
    return {
      path: UserService.path+'/owner/'+this.props.match.params.companyId
    }
  }
}
