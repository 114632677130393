import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Button, Chip, Switch} from 'react-md';

//import FormDialog from './Organization.dialog.view';
import BP21FService from './BP_21_F.service';
import ListView from '../../../../../components/entity/listView';
import UtilService from '../../../../../services/utils.service';
import download from 'downloadjs';
import uuid from 'uuid';
import iziToast from 'izitoast';
import ListViewSpt from '../../../../../components/entity/listViewSpt';
import SptService from "modules/Main/pph/21_26/spt/21_26.spt.service";
import moment from "moment";
import {TextfieldMask} from "src/components/form";

@connect((state)=> ({
  ...BP21FService.stateConnectSetting()(state),
  spt: state.authEppt.currentSPT,
  company: state.auth.company
}), BP21FService.actionConnectSetting())
export default class BP21FView extends ListViewSpt {
  service=BP21FService
  sptService=SptService
  FormDialog=()=> <div/>

  constructor(props){
    super(props);
    this.state = {
      ...this.state,
      total: {},
      cetak: false,
      cetakPisah: false,
      cetakDaftar: false
    }
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Nama'
          name='nama__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NPWP'
          name='npwp__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Email'
          name='email__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Nomor Bukti Potong'
          name='no__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Nomor Bukti Potong (Dari)'
          name='noNumeric__greaterOrEqualThan'
          className="md-cell md-cell--12"
          // component={this.ig.Textfield}
          component={TextfieldMask}
          maskFormat="#.#-##.##-#######"
        />
        <this.ig.Field
          label='Nomor Bukti Potong (Sampai)'
          name='noNumeric__lessOrEqualThan'
          className="md-cell md-cell--12"
          // component={this.ig.Textfield}
          component={TextfieldMask}
          maskFormat="#.#-##.##-#######"
        />
        <this.ig.Field
          label='NIK'
          name='nik__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
      </this.SearchContainer>
    )
  }

  addDialog=false
  editDialog=false

  fetchOption() {
    return {
      path: BP21FService.path+'/'+this.props.spt.data.id+'/spt'
    }
  }

  columns=[
    {isSortable: true, label: "word.reference",  value: "referensi", show:false, isSearchable:true},
    {isSortable: true, label: "word.npwp",  value: "npwp", show:true, isSearchable:true},
    {isSortable: true, label: "word.nik",  value: "nik", show:false, isSearchable:true},
    {isSortable: true, label: "word.name",  value: "name", show:true, isSearchable:true},
    {isSortable: true, label: "word.email",  value: "email", show:true, isSearchable:true},
    {isSortable: true, label: "word.noBp",  value: "no", show:true, isSearchable:true},
    {isSortable: true, label: "word.noNumericBp", type: "func",  value: (d) => d.noNumeric, show:false, isSearchable:true},
    {isSortable: true, label: "word.date",  value: "cuttingDate", show:true, isSearchable:true},
    {isSortable: true, label: "word.taxCode",  value: "taxObjectCode", show:true, isSearchable:true},
    {isSortable: true, label: "word.bruto",  value: "bruto", show:true, isSearchable:true, type: 'number'},
    {isSortable: true, label: "word.pph",  value: "pph", show:true, isSearchable:true, type: 'number'},
    {isSortable:true, label: "word.createdUsername",  value: "createdUsername", show:true, isSearchable:true},
    {isSortable:true, label: "word.createdBy",  value: "createdBy", show:true, isSearchable:true},
    {isSortable:true, label: "word.createdDate", value: "createdDate", show:true, isSearchable:true, type:"date", isDefaultSort: true},
    {isSortable:true, label: "word.modifiedUsername",  value: "modifiedUsername", show:true, isSearchable:true},
    {isSortable:true, label: "word.lastModifiedBy",  value: "lastModifiedBy", show:true, isSearchable:true},
    {isSortable:true, label: "word.lastModifiedDate", value: (d)=> {
      if(d.lastModifiedDate) {
        return <div className='mpk-font-size-S'>{moment(d.lastModifiedDate).format('lll')}</div>
      } else {
        return null;
      }
    }, show:true, isSearchable:true, type:"func", searchField: 'lastModifiedDate', isDefaultSort: true}
  ]

  async componentDidMount() {
    this.rootPath = this.props.match.url;

    var sptId = this.props.spt.data.id
    var total = await BP21FService.api.total(sptId)
    this.setState({
      total: total
    })
  }

  totalBruto() {
    var total = 0;
    if(this.state.total.bruto){
      total = this.state.total.bruto;
    }
    return total;
  }

  totalPph() {
    var total = 0;
    if(this.state.total.pph){
      total = this.state.total.pph;
    }
    return total;
  }

  async handleCetak() {
    this.setState({cetak: true})
    if(this.props.table.selected.length === 0){
      iziToast.warning({
        title: 'Pemberitahuan !',
        message: 'Pilih salah satu data yang akan dicetak',
      });
      this.setState({ cetak: false })
    } else {
      try {
        var selected = this.props.table.selected
        var ids = [];
        selected.map(function (i) {
          ids.push(i.id)
        })

        var sptId = this.props.spt.data.id
        if(ids.length == 1){
          var report = await this.service.api.cetak(sptId, ids)
          window.open(report.data.url);
        } else if(ids.length > 1 && ids.length <= 20) {
          var report = await this.service.api.cetakBulk(sptId, ids)
          window.open(report.data.url);
        } else {
          let data = {
            pasal: '21',
            category: 'BP_21_F',
            merge: true,
            ids: ids,
          }
          var report = await this.sptService.api.cetakBulkLog(data)

          iziToast.success({
            title: 'Sukses !',
            message: report.data.message,
          });
        }
        this.setState({cetak: false})
      } catch (e) {
        console.log(e, "TELLL ME")
        this.setState({cetak: false})
      }
    }
  }

  async handleCetakPisah() {
    this.setState({cetakPisah: true})
    if(this.props.table.selected.length === 0){
      iziToast.warning({
        title: 'Pemberitahuan !',
        message: 'Pilih salah satu data yang akan dicetak',
      });
      this.setState({ cetakPisah: false })
    } else {
      try {
        var selected = this.props.table.selected
        var ids = [];
        selected.map(function (i) {
          ids.push(i.id)
        })

        var sptId = this.props.spt.data.id
        if(ids.length == 1){
          var report = await this.service.api.cetak(sptId, ids)
          window.open(report.data.url);
        } else if(ids.length > 1 && ids.length <= 20) {
          var report = await this.service.api.cetakBulkPisah(sptId, ids)
          window.open(report.data.url);
        } else {
          let data = {
            pasal: '21',
            category: 'BP_21_F',
            merge: false,
            ids: ids,
          }
          var report = await this.sptService.api.cetakBulkLog(data)

          iziToast.success({
            title: 'Sukses !',
            message: report.data.message,
          });
        }
        this.setState({cetakPisah: false})
      } catch (e) {
        console.log(e, "TELLL ME")
        this.setState({cetakPisah: false})
      }
    }
  }

  async handleCetakDaftar(){
    this.setState({ cetakDaftar: true })
    try {
      var sptId = this.props.spt.data.id
      var report = await this.service.api.cetakDaftar(sptId)
      window.open(report.data.url);
      this.setState({ cetakDaftar: false })
    } catch (e){
      this.setState({ cetakDaftar: false })
    }
  }

  async handleSendEmail(){
    try {
      let category = "BP_21_F"
      var selected = this.props.table.selected
      var ids = [];
      selected.map(function (i){
          ids.push(i.id)
      })
      var sptId = this.props.spt.data.id
      // let res = await this.service.api.cekEmail(this.props.company.id)
      var ss = true
      if(ss){
        await this.service.api.emailSpt(category, sptId, ids, this.props.company.id)
        iziToast.info({
          title: "Kirim Email",
          message: "email berhasil terkirim mohon di cek email"
        })
      } else {
        iziToast.info({
          title: "Setting Email",
          message: "email setting belum lengkap mohon di cek kembali"
        })
      }
    } catch (e){
      iziToast.warning({
        title: e.name,
        message: e.message
      })
    }
  }

  // async deleteSelectedDataItems(){
  //   var selected = this.props.table.selected

  //   var ids = [];

  //   selected.map(function (i){
  //     ids.push({
  //       id: i.id,
  //       consumeId: i.consumeId
  //     })
  //   })

  //   const sptId = this.props.match.params.sptId

  //   var res = await this.service.api.deleteBulk(sptId, { ids: ids, url: 'f'})
  //   if(res.data.status == 200){
  //     iziToast.success({
  //       title: "Success",
  //       message: "data berhasil dihapus !"
  //     })
  //   } else if(res.data.status == 500){
  //     iziToast.error({
  //       title: "Failed",
  //       message: res.data.message
  //     })
  //   }

  //   this.fetchData()
  // }

  _barItem() {
    return (
      <div>
        <Button secondary raised style={{margin: 5}} onClick={this.handleSendEmail.bind(this)}>Kirim</Button>
        <Button disabled={this.state.cetakDaftar} primary raised style={{margin: 5}} onClick={this.handleCetakDaftar.bind(this)}>Cetak Daftar</Button>
        <Button disabled={this.state.cetak} primary raised style={{margin: 5}} onClick={this.handleCetak.bind(this)}>Cetak</Button>
        <Button disabled={this.state.cetakPisah} primary raised style={{margin: 5}} onClick={this.handleCetakPisah.bind(this)}>Cetak Pisah</Button>
        <Switch
          style={{float: "right"}}
          id="switch-table-setting"
          name="Switch Table Setting"
          label=""
          checked={this.state.showTableSetting}
          onChange={() => {
            this.setState({
              showTableSetting:!this.state.showTableSetting
            })
          }}
        />
      </div>
    )
  }

  footer() {
    return <div className="flex-none mpk-border thin solid top dark">
      <div className="mpk-padding-N left right">
        <table style={{width: '100%'}}>
          <tfoot className="md-table-footer">
            <tr className="md-table-column md-text-left md-table-column--data md-table-column--adjusted md-text ">
              <td>Total Jumlah Penghasilan Bruto</td>
              <td className=" md-text-right">{UtilService.number.format(this.totalBruto(), {precision: 0, thousand: '.', decimal: ','})}</td>
            </tr>
            <tr className="md-table-column md-text-left md-table-column--data md-table-column--adjusted md-text ">
              <td>Total Jumlah PPH Dipotong</td>
              <td className=" md-text-right">{UtilService.number.format(this.totalPph(), {precision: 0, thousand: '.', decimal: ','})}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  }

}
