import React, { Component } from 'react';
import {
    Avatar,
    Divider,
    FontIcon,
    List,
    ListItem,
    Subheader,
    Button,
    Card,
    CardTitle,
    DialogContainer,
    CircularProgress
  } from 'react-md';
import { Appbar, Notification, rdxConnect, Sidebar, navService, Companies } from 'react-mpk';
import utilsService from './../../services/utilsService';
import companyService from './../../services/companyService'
import t from 'counterpart';
import routes from './routes';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as globalActions from 'react-mpk/redux/actions/globalActions';
import defaultMenu from './../Main/defaultMenu';
import AuthService from '../../services/auth.service';
import authService from '../../services/auth.service';
import OrganizationSwitcher from '../../components/OrganizationSwitcher/OrganizationSwitcher';
import Userbar from './../../components/UserBar/UserBar'

const mainMenus = [
    {
      label: {
        id: 'Main',
        en: 'Main'
      },
      children: [
            {
                label: {
                    id: 'Dashboard',
                    en: 'Dashboard'
                },
                iconClassName: 'mdi mdi-view-grid',
                path: '/main-support/dashboard',
            },
            // {
            //     label: {
            //         id: 'Perusahaan',
            //         en: 'Company'
            //     },
            //     iconClassName: 'mdi mdi-view-grid',
            //     path: '/main-support/company',
            // },
            {
                label: {
                    id: 'Organisasi',
                    en: 'Organization'
                },
                iconClassName: 'mdi mdi-view-grid',
                path: '/main-support/orgs',
            }
        ]
    },
    {
        label: {
          id: 'E-Faktur Lapor & SPT 1771',
          en: 'E-Faktur Lapor & SPT 1771'
        },
        children: [
              {
                  label: {
                      id: 'Tarra Lapor',
                      en: 'Tarra Lapor'
                  },
                  iconClassName: 'mdi mdi-view-grid',
                  path: '/main-support/tarra-lapor',
              },
              {
                label: {
                  id: 'SPT 1771',
                  en: 'SPT 1771'
                },
                iconClassName: 'mdi mdi-view-grid',
                path: '/main-support/spt-1771',
              }
          ]
      },
    {
        label: {
          id: 'Ebupot 23/26',
          en: 'Ebupot 23/26'
        },
        children: [
              {
                  label: {
                      id: 'Spt 23/26',
                      en: 'Spt 23/26'
                  },
                  iconClassName: 'mdi mdi-view-grid',
                  path: '/main-support/ebupot-23',
              },
              {
                  label: {
                      id: 'Bupot 23',
                      en: 'Bupot 23'
                  },
                  iconClassName: 'mdi mdi-view-grid',
                  path: '/main-support/bp-23',
              },
              {
                  label: {
                      id: 'Bupot 26',
                      en: 'Bupot 26'
                  },
                  iconClassName: 'mdi mdi-view-grid',
                  path: '/main-support/bp-26',
              },
              {
                  label: {
                    id: 'Upload File',
                    en: 'Upload File'
                  },
                  iconClassName: 'mdi mdi-view-list',
                  path: '/main-support/log-file',
              },
          ]
      },
      {
        label: {
          id: 'PPh 21',
          en: 'PPh 21'
        },
        children: [
              {
                  label: {
                      id: 'Daftar SPT',
                      en: 'Daftar SPT'
                  },
                  iconClassName: 'mdi mdi-view-grid',
                  path: '/main-support/SPT_21',
              },
              {
                  label: {
                      id: 'BP Final',
                      en: 'BP Final'
                  },
                  iconClassName: 'mdi mdi-view-grid',
                  path: '/main-support/BP_21_F',
              },
              {
                  label: {
                      id: 'BP Tidak Final',
                      en: 'BP Tidak Final'
                  },
                  iconClassName: 'mdi mdi-view-grid',
                  path: '/main-support/BP_21_TF',
              },
              {
                label: {
                    id: 'BP 1721 A1',
                    en: 'BP 1721 A1'
                },
                iconClassName: 'mdi mdi-view-grid',
                path: '/main-support/BP_21_A1',
              },
              {
                label: {
                    id: 'BP Satu Masa',
                    en: 'BP Satu Masa'
                },
                iconClassName: 'mdi mdi-view-grid',
                path: '/main-support/ALL_BP_21_SATU_MASA',
              },
              {
                label: {
                    id: 'SSP',
                    en: 'SSP'
                },
                iconClassName: 'mdi mdi-view-grid',
                path: '/main-support/ALL_BP_21_SSP',
              }
          ]
      },
      {
        label: {
          id: 'PPh 23',
          en: 'PPh 23'
        },
        children: [
              {
                  label: {
                      id: 'Daftar SPT',
                      en: 'Daftar SPT'
                  },
                  iconClassName: 'mdi mdi-view-grid',
                  path: '/main-support/SPT_23',
              },
              {
                  label: {
                      id: 'BP 23',
                      en: 'BP 23'
                  },
                  iconClassName: 'mdi mdi-view-grid',
                  path: '/main-support/ALLBP23',
              },
              {
                  label: {
                      id: 'BP 26',
                      en: 'BP 26'
                  },
                  iconClassName: 'mdi mdi-view-grid',
                  path: '/main-support/ALLBP26',
              },
              {
                label: {
                    id: 'SSP 23',
                    en: 'SSP 23'
                },
                iconClassName: 'mdi mdi-view-grid',
                path: '/main-support/ALLSSP23',
              },
          ]
      },
      {
        label: {
          id: 'PPh 4(2)',
          en: 'PPh 4(2)'
        },
        children: [
              {
                  label: {
                      id: 'Daftar SPT',
                      en: 'Daftar SPT'
                  },
                  iconClassName: 'mdi mdi-view-grid',
                  path: '/main-support/SPT_4a2',
              },
              {
                  label: {
                      id: 'BP 4(2)',
                      en: 'BP 4(2)'
                  },
                  iconClassName: 'mdi mdi-view-grid',
                  path: '/main-support/ALLBP4A2',
              },
              {
                  label: {
                      id: 'SSP 4(2)',
                      en: 'SSP 4(2)'
                  },
                  iconClassName: 'mdi mdi-view-grid',
                  path: '/main-support/ALLSSP4A2',
              },
          ]
      },
      {
        label: {
          id: 'PPh 22',
          en: 'PPh 22'
        },
        children: [
              {
                  label: {
                      id: 'Daftar SPT',
                      en: 'Daftar SPT'
                  },
                  iconClassName: 'mdi mdi-view-grid',
                  path: '/main-support/SPT_22',
              },
              {
                  label: {
                      id: 'BP 22',
                      en: 'BP 22'
                  },
                  iconClassName: 'mdi mdi-view-grid',
                  path: '/main-support/ALLBP22',
              },
              {
                  label: {
                      id: 'Atas Pembelian Barang Oleh Bendaharawan',
                      en: 'Atas Pembelian Barang Oleh Bendaharawan'
                  },
                  iconClassName: 'mdi mdi-view-grid',
                  path: '/main-support/ALL_SSP22_PEMBELIAN_BARANG',
              },
              {
                label: {
                    id: 'Oleh Bank Devisa',
                    en: 'Oleh Bank Devisa'
                },
                iconClassName: 'mdi mdi-view-grid',
                path: '/main-support/ALL_SSP22_OLEH_BANK_DEVISA',
            },
            {
                label: {
                    id: 'Dibayar Sendiri',
                    en: 'Dibayar Sendiri'
                },
                iconClassName: 'mdi mdi-view-grid',
                path: '/main-support/ALL_SSP22_DIBAYAR_SENDIRI',
            },
            {
                label: {
                    id: 'SSP',
                    en: 'SSP'
                },
                iconClassName: 'mdi mdi-view-grid',
                path: '/main-support/ALLSSP22',
            },
          ]
      },
      {
        label: {
          id: 'PPh 15',
          en: 'PPh 15'
        },
        children: [
              {
                  label: {
                      id: 'Daftar SPT',
                      en: 'Daftar SPT'
                  },
                  iconClassName: 'mdi mdi-view-grid',
                  path: '/main-support/SPT_15',
              },
              {
                  label: {
                      id: 'BP 15 Dipotong Pihak Lain',
                      en: 'BP 15 Dipotong Pihak Lain'
                  },
                  iconClassName: 'mdi mdi-view-grid',
                  path: '/main-support/ALLBP15DipotongPihakLain',
              },
              {
                  label: {
                      id: 'BP 15 Pihak Lain Dipotong',
                      en: 'BP 15 Pihak Lain Dipotong'
                  },
                  iconClassName: 'mdi mdi-view-grid',
                  path: '/main-support/ALLBP15PihakLainDipotong',
              },
              {
                label: {
                    id: 'BP 24',
                    en: 'BP 24'
                },
                iconClassName: 'mdi mdi-view-grid',
                path: '/main-support/ALLBP24',
            },
            {
                label: {
                    id: 'SSP Setor Sendiri',
                    en: 'SSP Setor Sendiri'
                },
                iconClassName: 'mdi mdi-view-grid',
                path: '/main-support/ALLSSP15SETORSENDIRI',
            },
            {
                label: {
                    id: 'SSP',
                    en: 'SSP'
                },
                iconClassName: 'mdi mdi-view-grid',
                path: '/main-support/ALLSSP15',
            },
          ]
      },
      {
        label: {
          id: 'PPh 25',
          en: 'PPh 25'
        },
        children: [
              {
                  label: {
                      id: 'Daftar SPT',
                      en: 'Daftar SPT'
                  },
                  iconClassName: 'mdi mdi-view-grid',
                  path: '/main-support/SPT_25',
              },
              {
                  label: {
                      id: 'Daftar Angsuran',
                      en: 'Daftar Angsuran'
                  },
                  iconClassName: 'mdi mdi-view-grid',
                  path: '/main-support/ALLBP25',
              },
              {
                  label: {
                      id: 'SSP',
                      en: 'SSP'
                  },
                  iconClassName: 'mdi mdi-view-grid',
                  path: '/main-support/ALLSSP25',
              },
          ]
      },
      {
        label: {
          id: 'eBilling',
          en: 'eBilling'
        },
        children: [
            // {
            //     label: {
            //         id: 'eBilling Widget',
            //         en: 'eBilling Widget'
            //     },
            //     iconClassName: 'mdi mdi-view-grid',
            //     path: '/main-support/ebilling-widget',
            // },
            {
                label: {
                    id: 'Data ESSP',
                    en: 'Data ESSP'
                },
                iconClassName: 'mdi mdi-view-grid',
                path: '/main-support/EBILLING_DataESSP',
            },
            {
                label: {
                    id: 'Billing',
                    en: 'Billing'
                },
                iconClassName: 'mdi mdi-view-grid',
                path: '/main-support/EBILLING_paymentOrder',
            },
            {
                label: {
                    id: 'Order',
                    en: 'Order'
                },
                iconClassName: 'mdi mdi-view-grid',
                path: '/main-support/EBILLING_paymentNotification',
            },
            {
                label: {
                    id: 'Payment Account',
                    en: 'Payment Account'
                },
                iconClassName: 'mdi mdi-view-grid',
                path: '/main-support/EBILLING_akunPembayaran',
            },
        ]
      },
      {
        label: {
          id: 'Log eBilling',
          en: 'Log eBilling'
        },
        children: [
            {
                label: {
                    id: 'Log Cetak Billing',
                    en: 'Log Cetak Billing'
                },
                iconClassName: 'mdi mdi-view-grid',
                path: '/main-support/LogCetakPaymentOrder',
            },
            {
                label: {
                    id: 'Log Cetak Order',
                    en: 'Log Cetak Order'
                },
                iconClassName: 'mdi mdi-view-grid',
                path: '/main-support/LogCetakPaymenNotification',
            },
            {
                label: {
                    id: 'Log Cetak BPN',
                    en: 'Log Cetak BPN'
                },
                iconClassName: 'mdi mdi-view-grid',
                path: '/main-support/LogCetakBPN',
            },
            {
                label: {
                    id: 'Log Laporan Billing',
                    en: 'Log Laporan Billing'
                },
                iconClassName: 'mdi mdi-view-grid',
                path: '/main-support/LogCetakLaporanPO',
            },
            {
                label: {
                    id: 'Log Laporan BPN',
                    en: 'Log Laporan BPN'
                },
                iconClassName: 'mdi mdi-view-grid',
                path: '/main-support/LogCetakLaporanBPN',
            },
        ]
      },
      {
        label: {
          id: 'Setting & Import',
          en: 'Setting & Import'
        },
        children: [
              {
                  label: {
                      id: 'Summary',
                      en: 'Summary'
                  },
                  iconClassName: 'mdi mdi-view-grid',
                  path: '/main-support/summary-all',
              },
              {
                  label: {
                      id: 'Lawan Transaksi',
                      en: 'Lawan Transaksi'
                  },
                  iconClassName: 'mdi mdi-view-grid',
                  path: '/main-support/lawan-transaksi-all',
              },
              {
                  label: {
                      id: 'Signer',
                      en: 'Signer'
                  },
                  iconClassName: 'mdi mdi-view-grid',
                  path: '/main-support/signer-all',
              },
              {
                  label: {
                      id: 'Signer Image',
                      en: 'Signer Image'
                  },
                  iconClassName: 'mdi mdi-view-grid',
                  path: '/main-support/signer-image-all',
              },
              {
                  label: {
                      id: 'Import',
                      en: 'Import'
                  },
                  iconClassName: 'mdi mdi-view-grid',
                  path: '/main-support/import-all',
              },
              {
                label: {
                    id: "Pengaturan Email",
                    en: "Setting Email"
                },
                iconClassName: "mdi mdi-view-list",
                path: "/main-support/SETTING_EMAIL"
              },
              {
                label: {
                    id: "Akses User",
                    en: "User Access"
                },
                iconClassName: "mdi mdi-view-list",
                path: "/main-support/access-control"
              },
              {
                label: {
                    id: "Blok Bunifikasi",
                    en: "Blok Bunifikasi"
                },
                iconClassName: "mdi mdi-view-list",
                path: "/main-support/block-buni"
              },
              {
                label: {
                  id: 'PPH 4a2 Enable Deposito',
                  en: 'PPH 4a2 Enable Deposito'
                },
                iconClassName: 'mdi mdi-contacts',
                path: '/main-support/enable-deposito'
              }
          ]
      },
      {
        label: {
          id: 'Logs',
          en: 'Logs'
        },
        children: [
              {
                  label: {
                      id: 'Log eFilling',
                      en: 'Log eFilling'
                  },
                  iconClassName: 'mdi mdi-view-grid',
                  path: '/main-support/EFILING_LOG',
              },
              {
                  label: {
                      id: 'Log Email',
                      en: 'Log Email'
                  },
                  iconClassName: 'mdi mdi-view-grid',
                  path: '/main-support/LOG_EMAIL',
              },
              {
                label: {
                  id: 'Log Export CSV',
                  en: 'Log Export CSV'
                },
                iconClassName: 'mdi mdi-view-list',
                path: '/main-support/LOG_EXPORT_CSV',
              },
              {
                label: {
                  id: 'Log Export PDF',
                  en: 'Log Export PDF'
                },
                iconClassName: 'mdi mdi-view-list',
                path: '/main-support/LOG_EXPORT_PDF',
              },
              {
                label: {
                  id: 'Log Ekspor PDF Ceklis',
                  en: 'Log Ekspor PDF Ceklis'
                },
                iconClassName: 'mdi mdi-view-list',
                path: '/main-support/LOG_EXPORT_PDF_CHECKLIST',
              },
              {
                label: {
                  id: 'Log Summary',
                  en: 'Log Summary'
                },
                iconClassName: 'mdi mdi-view-list',
                path: '/main-support/LOG_SUMMARY',
              },
          ]
      },
      {
        label: {
          id: 'Master Tarif 21',
          en: 'Master Tarif 21'
        },
        children: [
          {
            label: {
              id: 'Tarif Pasal 17 Berlapis',
              en: 'Tarif Pasal 17 Berlapis'
            },
            iconClassName: 'mdi mdi-view-list',
            path: '/main-support/master/pasal-21-pasal-17-berlapis',
            permission: ''
          },
          {
            label: {
              id: 'Tarif Biaya Jabatan',
              en: 'Tarif Biaya Jabatan'
            },
            iconClassName: 'mdi mdi-view-list',
            path: '/main-support/master/pasal-21-biaya-jabatan',
            permission: ''
          },
          {
            label: {
              id: 'Tarif Biaya Pensiun',
              en: 'Tarif Biaya Pensiun'
            },
            iconClassName: 'mdi mdi-view-list',
            path: '/main-support/master/pasal-21-biaya-pensiun',
            permission: ''
          },
          {
            label: {
              id: 'Tarif PTKP',
              en: 'Tarif PTKP'
            },
            iconClassName: 'mdi mdi-view-list',
            path: '/main-support/master/pasal-21-ptkp',
            permission: ''
          },
          {
            label: {
              id: 'Tarif Pensiun',
              en: 'Tarif Pensiun'
            },
            iconClassName: 'mdi mdi-view-list',
            path: '/main-support/master/pasal-21-pensiun',
            permission: ''
          },
          {
            label: {
              id: 'Tarif Upah Harian',
              en: 'Tarif Upah Harian'
            },
            iconClassName: 'mdi mdi-view-list',
            path: '/main-support/master/pasal-21-upah-harian',
            permission: ''
          },
          {
            label: {
              id: 'Tarif Upah Kumulatif',
              en: 'Tarif Upah Kumulatif'
            },
            iconClassName: 'mdi mdi-view-list',
            path: '/main-support/master/pasal-21-upah-kumulatif',
            permission: ''
          },
          {
            label: {
              id: 'Tarif Final Pesangon',
              en: 'Tarif Final Pesangon'
            },
            iconClassName: 'mdi mdi-view-list',
            path: '/main-support/master/pasal-21-final-pesangon',
            permission: ''
          },
          {
            label: {
              id: 'Tarif Honor/Imbalan PNS',
              en: 'Tarif Honor/Imbalan PNS'
            },
            iconClassName: 'mdi mdi-view-list',
            path: '/main-support/master/pasal-21-honor-pns',
            permission: ''
          }
        ]
      },
]

@connect(state => ({
    global:state.global,
    isLoginIn: state.authEppt.isLoginIn,
    user: state.authEppt.user,
    showGlobalProgress: state.authEppt.showGlobalProgress,
    organization: state.authEppt.currentOrganization,
    access: state.authEppt.access,
    spt: state.authEppt.currentSPT,
    currentModule: state.authEppt.currentModule,
    company: state.authEppt.currentCompany,
    setting: state.authEppt.setting,
    menuType: state.authEppt.menuType,
    auth: state.auth.user,
    company: state.auth.company
  }), dispatch => ({
    dispatch: dispatch,
    globalActions:bindActionCreators(globalActions, dispatch)
  }))
class MainSupport extends React.Component {

    componentDidMount(){
        authService.api.changeMenuType('main', this.props.dispatch);
    }

    buildMenus(){
        var sptId = null
        try { sptId = this.props.spt.data.id } catch(e){}
        if(this.props.menuType === 'SPT_25') {
            var menus = Object.assign([], defaultMenu.sptPph25Menus);
            menus.map((menu)=> {
                if(menu.children && menu.children.length > 0){
                    menu.children.map((childMenu)=>{
                        if(childMenu.path[0] == "/"){
                            childMenu.path = childMenu.path.split('/').join('')
                        }
                    })
                }
            })
            return menus;
        } else if(this.props.menuType === 'SPT_15') {
            var menus = Object.assign([], defaultMenu.sptPph15Menus);
            menus.map((menu)=> {
                if(menu.children && menu.children.length > 0){
                    menu.children.map((childMenu)=>{
                        if(childMenu.path[0] == "/"){
                            childMenu.path = childMenu.path.split('/').join('')
                        }
                    })
                }
            })
            return menus;
        } else if(this.props.menuType === 'SPT_22') {
            var menus = Object.assign([], defaultMenu.sptPph22Menus);
            menus.map((menu)=> {
                if(menu.children && menu.children.length > 0){
                    menu.children.map((childMenu)=>{
                        if(childMenu.path[0] == "/"){
                            childMenu.path = childMenu.path.split('/').join('')
                        }
                    })
                }
            })
            return menus;
        } else if(this.props.menuType === 'SPT_4a2') {
            var menus = Object.assign([], defaultMenu.sptPph4a2Menus);
            menus.map((menu)=> {
                if(menu.children && menu.children.length > 0){
                    menu.children.map((childMenu)=>{
                        if(childMenu.path[0] == "/"){
                            childMenu.path = childMenu.path.split('/').join('')
                        }
                    })
                }
            })
            return menus;
        } else if(this.props.menuType === 'SPT_21') {
            var menus = Object.assign([], defaultMenu.sptPph21Menus);
            menus.map((menu)=> {
                if(menu.children && menu.children.length > 0){
                    menu.children.map((childMenu)=>{
                        if(childMenu.path[0] == "/"){
                            childMenu.path = childMenu.path.split('/').join('')
                        }
                    })
                }
            })
            return menus;
        } else if(this.props.menuType === 'SPT_23') {
            var menus = Object.assign([], defaultMenu.sptPph23Menus);
            menus.map((menu)=> {
                if(menu.children && menu.children.length > 0){
                    menu.children.map((childMenu)=>{
                        if(childMenu.path[0] == "/"){
                            childMenu.path = childMenu.path.split('/').join('')
                        }
                    })
                }
            })
            return menus;
        } else if(this.props.menuType == 'ebupot'){
            var menus = Object.assign([], defaultMenu.sptEbupot23Menus);
            menus.map((menu)=> {
                if(menu.children && menu.children.length > 0){
                    menu.children.map((childMenu)=>{
                        childMenu.path = childMenu.path.split('/').join('')
                    })
                }
            })
            return menus;
        } else {
            return mainMenus;
        }
    }

    topSideBar() {
        var companyId = this.props.match.params.companyId
        var subscriptionId = this.props.match.params.subscriptionId
        if((this.props.menuType.search('ebupot') >= 0 || this.props.menuType.search('SPT') >= 0) && this.props.spt.data) {
            return (
              <div>
                <OrganizationSwitcher/>
                <List style={{background: '#F44336'}}>
                  <ListItem
                    primaryTextStyle={{paddingLeft: 58, color: 'rgba(255, 255, 255, 0.87)'}}
                    secondaryTextStyle={{paddingLeft: 58, color: 'rgba(255, 255, 255, 0.47)'}}
                    tileStyle={{height: 'auto', margin: '10px 0px'}}
                    rightIcon={<FontIcon tooltipLabel="Tutup"   className='close-menu' onClick={() => {authService.api.changeMenuType('main', this.props.dispatch);this.props.history.push(`/main-support/ebupot-23`)}}>close</FontIcon>}
                    primaryText={this.props.spt.data.year+' - '+this.props.spt.data.month}
                    secondaryText={'Pembetulan '+this.props.spt.data.rev}
                    />
                </List>
              </div>
            )
        }
    }

    render(){
        let { auth, global } = this.props;
        try {
            global.username = auth.user.username;
        } catch(e){}
        var halonaurl = []
        if(window.location.href.indexOf('demo') != -1){
            halonaurl = [
                {
                  label:"Halona Cloud",
                  materialIcon:'dashboard',
                  url: "http://halona.sobatpajak.com"
                }
            ]
        } else {
            halonaurl = [
                {
                    label:"Halona Cloud",
                    materialIcon:'dashboard',
                    // url: "http://cloud.halonasoft.com"
                    url: "http://halona.sobatpajak.com"
                }
            ]
        }
        return (
            <div className="mpk-layout column fill">
                <Appbar
                    notification={<Notification newFeeds={0}/>}
                    appName="eppt"
                    showVersion fixedVersion={false}
                    profileMenu={[]}
                    appsShortcut={halonaurl}
                    consoleShortcut={Object.keys(this.props.global.appConsole).map(key => {
                        return this.props.global.appConsole[key];
                    })}
                    translate={true}
                />
                <div className="mpk-layout fill mpk-relative-position mpk-content">
                    <Sidebar
                        accordionMode={false}
                        menuClass='animated fadeInLeft'
                        collapsible={true}
                        headerElements={<div/>}
                        menus={this.buildMenus()}
                        getValueByLocaleCode={true}
                        headerElements={this.topSideBar()}
                    />
                    {routes()}
                </div>
            </div>
        )
    }
}

export default rdxConnect(MainSupport);
