import ApiService from '../../../../services/api.service';

class FileUpload23Service extends ApiService {
  name= 'FileUpload23';
  path= 'pasal23/upload/image';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api = {
    multiUpload: async (data)=> {
      var res = await this.http.post(this.apiLocation+`/pasal23/file/upload-multiple`, data);
      return res;
    },
    upload: async (data)=> {
      var res = await this.http.post(this.apiLocation+`/pasal23/upload/image`, data);
      return res;
    },
    getImage: async (id)=> {
      var res = await this.http.get(`${this.apiLocation}/pasal23/upload/image/${id}`, {
        responseType: 'arraybuffer'
      })
      return res
    }
  }
}

export default new FileUpload23Service();
