import ApiService from '../../../../services/api.service';

class PaymentOrderService extends ApiService {
  name= 'PaymentOrder';
  path= 'ebilling/payment-order';

  api = {
    retryInquiries: async (data) => {
      var res = await this.http.post(`${this.apiLocation + "/" + this.path}/retryInquiries`, data)

      return res.data;
    },
    retryBillings: async (data) => {
      var res = await this.http.post(`${this.apiLocation + "/" + this.path}/retryBillings`, data)

      return res.data;
    },
    cancel: async (id) => {
      var res = await this.http.put(`${this.apiLocation + "/" + this.path}/cancel/${id}`)

      return res.data;
    },
    cancelChecked: async (data) => {
      var res = await this.http.post(`${this.apiLocation + "/" + this.path}/cancel/checked`, data)

      return res.data;
    },
    deleteChecked: async (data) => {
      var res = await this.http.post(`${this.apiLocation + "/" + this.path}/delete`, data)

      return res.data;
    },
  }

  constructor() {
    super()
    this.init()
    this.initApi()
  }
}


export default new PaymentOrderService();
