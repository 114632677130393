import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {Field, getFormValues, reduxForm} from 'redux-form';
import {Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab, Chip} from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Multiselectv2, Switch as Switch2} from '../../../../components/form';

import LogSummaryService from './LogSummary.service';
import ListView from '../../../../components/entity/listView';
import ColumnService from '../../../../services/column.service';
import axios from 'axios';
import izitoast from 'izitoast';
import download from "downloadjs";

@reduxForm({form: 'LogSummary', destroyOnUnmount: true})
@connect((state) => ({
  ...LogSummaryService.stateConnectSetting()(state),
  formData: getFormValues('LogSummary')(state),
  user: state.auth.user,
  // EPPT CORE
  auth: state.auth.company
  // EPPT CORE
}), LogSummaryService.actionConnectSetting())
export default class LogSummary extends ListView {
    service=LogSummaryService
    minusPage=true

    static contextTypes = {
        showDialog: PropTypes.func.isRequired,
    };

    apiPath='findByPasal'

    beforeFetch(params) {
        params.pasal = this.props.pasal;
        try {
            if(params['status.in']){
                params['status.in'] == this.props.tableFilter2.status__in
            }
        } catch(e){}
        return params
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.pasal != prevProps.pasal){
            this.fetchData();
        }
    }

    deleteSelected = async (items, callback) => {
        try {
          let ids = []

          items.forEach(item => {
            ids.push(item.id)
          })

          var deleteAll = await this.service.api.deleteAllPerPasal(ids, this.props.pasal)
          await this.removeCheckbox();

          callback()
          await this.fetchData()
        } catch(e) {
          callback(e, e)
        }
    }

    async downloadSelected() {
      let selected = this.props.table.selected

      if (selected.length) {
        selected.forEach(data => {
          if (data.status === 'FINISH') {
            this.downloadItem(data)
          }
        })
      } else {
        izitoast.warning({
          title: 'Download',
          message: 'Hanya yang berstatus FINISH yang dapat didownload.'
        })
      }
    }

    async downloadItem(item){
        let res = await this.service.api.downloadPerPasal(item.id, this.props.pasal)

        let url = res.data.url
        let win = window.open(url, '_blank');
        win.focus();
    }

    deleteItem = async (item, callback) => {
        try {
          await this.service.api.deletePerPasal(item.id, this.props.pasal);
          callback()
          await this.fetchData()
        } catch(e) {
          callback(e, e)
        }
    }

    _tableActions = [
        {
          label:"Download",
          iconClassName:"mdi mdi-file",
          onClick: (item) => this.downloadItem(item)
        },
        {label:"divider", iconClassName:"-"},
        {
          label:"Delete",
          iconClassName:"mdi mdi-delete",
          onClick:(item, callback) => this.deleteItem(item, callback),
          confirmation:{
            title:"sentence.custom.deleteItem",
            message:"sentence.custom.deleteItemConfirmation"
          }
        }
    ]

    defaultSearchForm(){
        return (
          <this.SearchContainer>
            <this.ig.Field
              label='Kategori'
              name='category__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Pasal'
              name='pasal__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Status'
              name='status__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Jumlah Organisasi'
              name='npwpCount__equals'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Jumlah SPT'
              name='sptCount__equals'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Jumlah SPT'
              name='sptCount__equals'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Dari Tahun'
              name='fromYear__equals'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Sampai Tahun'
              name='toYear__equals'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Dari Bulan'
              name='fromMonth__equals'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Sampai Bulan'
              name='toMonth__equals'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
          </this.SearchContainer>
        )
    }

    _barActions = [
        {
          label:'word.delete',
          iconClassName:'mdi mdi-delete',
          onClick:() => {
            this.setState({showDialogConfirmDeleteSelected:true})
          },
          disabledFunc:() => this.props.table.selected.length === 0
        },
        {
          label:'word.download',
          iconClassName:'mdi mdi-file',
          onClick:() => this.downloadSelected(),
          disabledFunc:() => this.props.table.selected.length === 0
        },
        {
          label:'word.refresh',
          iconClassName:'mdi mdi-refresh',
          onClick:() => {
            window.location.hash = window.location.hash.split('?')[0]
            this.fetchData()
          }
        },
    ]

    columns=[
      {isSortable: true, label: "word.pasal",  value: "pasal", isDefaultSort:false, show:true, isSearchable:false},
      {isSortable: true, label: "word.category",  value: "category", isDefaultSort:false, show:true, isSearchable:true},
      {isSortable: true, label: "word.fromYear", value: (d)=> d.fromYear, isDefaultSort:false, show:false, isSearchable:true, type: 'func'},
      {isSortable: true, label: "word.toYear", value: (d)=> d.toYear, isDefaultSort:false, show:false, isSearchable:true, type: 'func'},
      {isSortable: true, label: "word.fromMonth", value: (d)=> d.fromMonth, isDefaultSort:false, show:false, isSearchable:true, type: 'func'},
      {isSortable: true, label: "word.toMonth", value: (d)=> d.toMonth, isDefaultSort:false, show:false, isSearchable:true, type: 'func'},
      {isSortable: true, label: "word.isCalculate", type: "func", value: (d) => {
          if (d.calculate) {
            return <Button flat swapTheming style={{backgroundColor: '#4caf50', color: 'white'}}>YA</Button>
          }

          return <Button flat swapTheming style={{backgroundColor: '#0D47A1', color: 'white'}}>TIDAK</Button>
        }, show: true, isSearchable: true},
      {isSortable: true, label: "word.isAllNpwp", type: "func", value: (d) => {
          if (d.allNpwp) {
            return <Button flat swapTheming style={{backgroundColor: '#4caf50', color: 'white'}}>YA</Button>
          }

          return <Button flat swapTheming style={{backgroundColor: '#0D47A1', color: 'white'}}>TIDAK</Button>
        }, show: true, isSearchable: true},
      {isSortable: true, label: "word.npwp", value: "npwpCount", type: "number", isDefaultSort:false, show:true, isSearchable:true},
      {isSortable: true, label: "word.sptCount", value: "sptCount", type: "number", isDefaultSort:false, show:true, isSearchable:true},
      {isSortable: true, label: "word.sptProcessedCount", value: "sptProcessedCount", type: "number", isDefaultSort:false, show:true, isSearchable:true},
      {
        label: "word.status",
        type: 'func',
        value: (d)=> {
          var style = {
            color: 'white',
            backgroundColor: 'grey'
          }
          switch (d.status) {
            case 'PROCESS':
              style.backgroundColor = '#2196F3'
              break;
            case 'FINISH':
              style.backgroundColor = '#4CAF50'
              break;
            case 'FAILED':
              style.backgroundColor = '#f44336'
              break;
            default:
              break;
          }
          return <Button flat swapTheming label={d.status} style={style}/>
      }, className: "mpk-font-size-S", show:true, isSearchable:true},
      {isSortable: true, label: "word.step", type: "func", value: (d) => {
          switch (d.step) {
            case 1:
              return 'Kalkulasi.'
            case 2:
              return 'Get data.'
            case 3:
              return 'Generate excel.'
            default:
              return '-'
          }
        }, isDefaultSort:false, show:true, isSearchable:false},
      {isSortable: true, label: "word.message",  value: "message", isDefaultSort:false, show:true, isSearchable:false},
      {isSortable: true, label: "word.fileSize",  type: "func",  value: (d) => {
        if (d.fileSize) return d.fileSize

        return "-"
      }, isDefaultSort:false, show:true, isSearchable:false},
      {isSortable: true, label: "word.username",  value: "username", isDefaultSort:false, show:true, isSearchable:true},
    ]
}
