import ApiService from '../../../../../../services/api.service';
import { splitDecimal } from 'react-number-format/lib/utils';

class ExportCsv22Service extends ApiService {
  name= 'ExportCsv22';
  path= 'spt22';
  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api={
    getInfo: async (sptId) => {
      return await this.http.get(`${this.apiLocation}/${this.path}/info/export/bp/${sptId}`)
    },
    cetakTest: async (sptId) => {
      return await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/lapor`)
    },
    cetak: async (sptId) => {
      return await this.http.get(`${this.apiLocation}/pasal22/spt/${sptId}/submit`)
    },
    getInfoFileLapor: async(sptId)=> {
      return await this.http.get(`${this.apiLocation}/pasal22/spt/${sptId}/info`)
    },
  }

}

export default new ExportCsv22Service();
