import React, { Component } from 'react';
import {connect} from 'react-redux';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress
} from 'react-md';
import { Field, reduxForm, getFormValues} from 'redux-form';

import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox} from '../../../../../../components/form';
import terbilangService from '../../../../../../services/terbilang.service';

@connect((state) => ({
  formData: getFormValues('BP_4a2_Form')(state)
}))
export default class BP4a2TransaksiDeviratifForm extends Component {

  componentDidMount() {
    this.props.connectCmp(this.reCalculate.bind(this))
  }

  async reCalculate() {
    console.log('transaksiDerivatif')
    var form = {...this.props.formData}
    var d    = form.transaksiDerivatif;

    var pph = d.nilaiMarginAwal*d.tarif/100;
    await this.props.change(`transaksiDerivatif.pph`, pph);

    this.props.change('pph', pph);
    this.props.change('terbilang', terbilangService(parseInt(pph)));
  }

  async calculate(e,v,pv,f, parent) {
    var form = this.props.formData;
    var d    = form.transaksiDerivatif;

    var bruto = parseFloat(d.nilaiMarginAwal);
    var tarif = parseFloat(d.tarif);

    if(f.search('nilaiMarginAwal') >= 0) {
      bruto = parseFloat(v)
    } else if(f.search('tarif') >= 0) {
      tarif = parseFloat(v)
    }

    if(!bruto) bruto = 0;
    if(!tarif) tarif = 0;

    var pph = Math.floor(bruto * tarif / 100);
    await this.props.change(`transaksiDerivatif.pph`, pph);

    this.props.change('bruto', bruto)
    this.props.change('pph', pph);

    this.props.change('terbilang', terbilangService(parseInt(pph)));

  }

  render() {
    return <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
      <TableHeader>
        <TableRow>
          <TableColumn>No.</TableColumn>
          <TableColumn className='md-text-right'>Bruto Dividen(Rp)</TableColumn>
          <TableColumn className='md-text-right'>Tarif(%)</TableColumn>
          <TableColumn className='md-text-right'>PPH Di Potong(Rp)</TableColumn>
        </TableRow>
      </TableHeader>
      <TableBody>
        <TableRow>
          <TableColumn>1.</TableColumn>
          <TableColumn>
            <Field
              name      = 'transaksiDerivatif.nilaiMarginAwal'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'data5')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'transaksiDerivatif.tarif'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'data5')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'transaksiDerivatif.pph'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
            />
          </TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn>Terbilang</TableColumn>
          <TableColumn>
            <Field
              name      = 'terbilang'
              component = {Textfield}
              money     = {','}
              disabled
            />
          </TableColumn>
        </TableRow>
      </TableBody>
    </DataTable>
  }
}
