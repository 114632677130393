import React, { Component } from 'react';
import { Grid, Cell, List,
  ListItem,
  ListItemControl,
  FontIcon,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn
} from 'react-md';
import { connect } from 'react-redux';
import { Field, reduxForm , propTypes, formValueSelector , getFormValues} from 'redux-form';
import { validation, Textfield, TextfieldMask, convert, Searchfield } from '../../../../components/form';
import DialogView from '../../../../components/entity/dialog.view';
import MainFlowService from './MainFlow.service';
import UserService from '../../Administrator/User/User.service';
import _ from 'lodash';


@reduxForm({form: 'flow_action_dialog', destroyOnUnmount: true})
@connect((state) => ({
  formState: getFormValues('flow_action_dialog')(state)
}))
export default class FlowActionDialog extends DialogView {
  service=MainFlowService
  size='normal'
  translate=false
  titleHeader() {
    if(this.props.isEditDialog) {
      return this.props.dialogType
    } else {
      return this.props.dialogType
    }
  }

  initialData = {

  }

  initData(props) {
    var value = {};
    if(props.formData.extras) value = _.cloneDeep(props.formData);
    if(value.id) {
      this.props.initialize(value);
    } else {
      this.props.initialize(this.initialData);
    }
  }

  componentWillReceiveProps(nextProps) {
    var value = {};
    var nextValue = {}
    if(this.props.formData) value = this.props.formData;
    if(nextProps.formData) nextValue = nextProps.formData;
    if(value.id != nextValue.id) {
      this.initData(nextProps)
    }
  }

  async handleSave(callback, value) {
    this.props.onSubmit(value, callback);
  }

  formView() {
    return (
      <Grid>
        {this.props.dialogType !== 'Pengajuan Approval' &&
          <Field
            label='Actions'
            name='actionId'
            options={this.props.spt.flowState.actions}
            className="md-cell md-cell--12"
            component={Searchfield}
          />
        }
        <Field
          label='Note'
          name='note'
          className="md-cell md-cell--12"
          component={Textfield}
        />
      </Grid>
    )
  }


}
