import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button, SVGIcon } from 'react-md';
import ALLBP21DaftarBiayaService from './ALL_BP_21_DAFTAR_BIAYA.service';
import ListView from '../../../../../components/entity/listView';
import {Switch} from 'react-md';
import iziToast from 'izitoast';
import download from "downloadjs";
import BP21DaftarBiayaService from "../BP_21_DAFTAR_BIAYA/BP_21_DAFTAR_BIAYA.service";
import LogExportService from "modules/Main/Export/LogExport.service";

@connect(ALLBP21DaftarBiayaService.stateConnectSetting(), ALLBP21DaftarBiayaService.actionConnectSetting())
export default class ALLBP21DaftarBiayaView extends ListView {
  service=ALLBP21DaftarBiayaService

  constructor(props){
    super(props);
    this.state = {
      showTableSetting:false,
      showDialogConfirmDeleteSelected:false,
      showForm:false,
      formData: {},
      data: []
    }
  }

  editDialog = false;
  _barActions = [];

  beforeFetch(params) {
    delete params.total
  }

  editItem = (item) => {
    if(this.editDialog) {
      this.showFormDialog(item);
    } else {
      if(this.rootPath) {
        this.props.history.push(this.rootPath+"/"+item.spt21.id)
      } else {
        this.props.history.push('/'+this.service.name+"/"+item.spt21.id);
      }
    }
  }

  async handleSendEmail(){
    try {
      let category = "BP_21_DAFTAR_BIAYA"
      var selected = this.props.table.selected
      var ids = [];
      selected.map(function (i){
        ids.push(i.id)
      })
      await this.service.api.email(category, ids)
      iziToast.info({
        title: "Kirim Email",
        message: "email sedang diproses silahkan cek log untuk lebih detil"
      })
    } catch (e){
      iziToast.warning({
        title: e.name,
        message: e.message
      })
    }
  }

  async handleCetak() {
    this.setState({cetak: true})
    if (this.props.table.selected.length === 0) {
      iziToast.warning({
        title: 'Pemberitahuan !',
        message: 'Pilih salah satu data yang akan dicetak',
      });
    }

    else if (this.props.table.selected.length > 1) {
      try {
        var selected = this.props.table.selected
        var ids = [];
        selected.map(function (i) {
          ids.push(i.spt21.id)
        })

        var report = await BP21DaftarBiayaService.api.cetakBulk(ids)
        var filename = report.headers.filename
        download(report.data, filename);
        this.setState({cetak: false})
      } catch (e) {
        this.setState({cetak: false})
      }
    }

    else {
      try {
        var selection = this.props.table.selected[0]
        var sptId = selection.spt21.id
        var report = await BP21DaftarBiayaService.api.cetakDaftar(sptId)
        var filename = report.headers.filename
        download(report.data, filename);
        this.setState({cetak: false})
      } catch (e) {
        this.setState({cetak: false})
      }
    }
  }

  async handleExportAll(type = 'CSV') {

    var res = {};
    var data = this.props.table.selected.reduce((p, c) => {
      if (c.status == 'FINISH') {
        p.push({
          id: c.id,
          rebuild: false
        })
      }
      return p;
    }, []);

    if (type == 'CSV') {
      res = await LogExportService.api.exportBulkCsv('spt21/db', data);
      var filename = res.headers.filename
      download(res.data, filename);
    }
  }

  barActions() {
    return [
      ...this._barActions,
      {
        label:'word.exportCsv',
        iconClassName:'mdi mdi-file-excel',
        disabledFunc:() => this.props.table.selected.length === 0,
        onClick:() => this.handleExportAll()
      },
      {
        label:'word.exportPdf',
        iconClassName:'mdi mdi-file-pdf',
        disabledFunc:() => this.props.table.selected.length === 0,
        onClick:() => this.handleCetak()
      },
      {
        label:'word.email',
        iconClassName:'mdi mdi-email',
        disabledFunc:() => this.props.table.selected.length === 0,
        onClick:() => this.handleSendEmail()
      }
    ]
  }

  columns=[
    {isSortable: true, label: "word.npwpProfile",  value:"spt21.organization.npwp", show:true, isSearchable:true},
    {isSortable: true, label: "word.createdDate",  value:"createdDate", type:"date", show:true, isSearchable:true},
    {isSortable: true, label: "word.createdBy",  value:"createdBy", show:true, isSearchable:true},
    {isSortable: true, label: "word.year",  searchField: "year", value: (d)=> d.spt21.year, type: "func", show:true, isSearchable:true},
    {isSortable: true, label: "word.month",  value: "spt21.month", searchField:'masa', type: "number", show:true, isSearchable:true},
    {isSortable: true, label: "word.pembetulan",  value: "spt21.pembetulan", type: "number", show:true, isSearchable:true},
    {isSortable: true, label: "word.dbno1",  value: "no1", show:true, type:"number", isSearchable:true},
    {isSortable: true, label: "word.dbno2",  value: "no2", show:true, type:"number", isSearchable:true},
    {isSortable: true, label: "word.dbno3",  value: "no3", show:true, type:"number", isSearchable:true},
    {isSortable: true, label: "word.dbno4",  value: "no4", show:true, type:"number", isSearchable:true},
    {isSortable: true, label: "word.dbno5",  value: "no5", show:true, type:"number", isSearchable:true},
    {isSortable: true, label: "word.dbno6",  value: "no6", show:true, type:"number", isSearchable:true},
    {isSortable: true, label: "word.dbno7",  value: "no7", show:true, type:"number", isSearchable:true},
    {isSortable: true, label: "word.dbno8",  value: "no8", show:true, type:"number", isSearchable:true},
    {isSortable: true, label: "word.dbno9",  value: "no9", show:true, type:"number", isSearchable:true},
    {isSortable: true, label: "word.dbno10",  value: "no10", show:true, type:"number", isSearchable:true},
    {isSortable: true, label: "word.dbtotal",  value: "total", show:true, type:"number", isSearchable:true}
  ]

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    }
  ]

}
