import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Field, reduxForm } from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../../../../components/form';

import ListView from '../../../../../../components/entity/listView';
import ColumnService from '../../../../../../services/column.service';
// import ImportService from './../Import/Import.service';
import axios from 'axios';
import izitoast from 'izitoast';
import ImportSigner15Service from './ImportSigner15.service';
import ImportSigner15DialogView from './ImportSigner15.dialog';
import download from "downloadjs";
import ListViewImport from '../../../../../../components/entity/ListViewImport';



@reduxForm({form: 'ImportSigner15Form', destroyOnUnmount: true})
@connect((state)=> ({
  ...ImportSigner15Service.stateConnectSetting()(state),
  user: state.authEppt.user,
  company: state.authEppt.currentCompany
}), ImportSigner15Service.actionConnectSetting())
export default class ImportSigner15View extends ListViewImport {
  service=ImportSigner15Service
  FormDialog=ImportSigner15DialogView
  pasal='pasal15'
  category='SIGNER_15'
  importPath='/pasal15/upload/csv/bp'
  // importPathFunc() {
  //   return `/api/import/company/${this.props.company.id}`;
  // }
  showAutomatisTab=false
  // viewType=2

  beforeFetch(params) {
    params["category.equals"] = "SIGNER_15"
  }

  async handleDownloadTemplate() {
    var report = await ImportSigner15Service.api.downloadTemplate()
    download(report.data, 'Signer15.csv');
  }

}
