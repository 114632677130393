import ApiService from '../../../../../services/api.service';

class Master21FinalPesangonProgService extends ApiService {
  name= 'Master21FinalPesangonProg';
  path= 'master/tarif-21-final-pesangon-prog';
  constructor() {
    super();
    this.init()
  }
}


export default new Master21FinalPesangonProgService();

