import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Field, reduxForm } from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../../components/form';

import ImportEsspService from './ImportEssp.service';
import ListView from '../../../../components/entity/listView';
import ColumnService from '../../../../services/column.service';
import ImportService from '../../pph/21_26/Import/Import.service';
import axios from 'axios';
import izitoast from 'izitoast';
import FormDialog from './ImportEssp.dialog.view';
import download from "downloadjs";

@reduxForm({form: 'IMPORT_EBILLING_ESSP_Form', destroyOnUnmount: true})
@connect(ImportEsspService.stateConnectSetting(), ImportEsspService.actionConnectSetting())
export default class ImportEsspView extends ListView {
  service=ImportEsspService
  FormDialog=FormDialog
  // viewType=2

  constructor(props){
    super(props);
    this.state = {
      showTableSetting:true,
      showDialogConfirmDeleteSelected:false,
      showForm:false,
      formData: {},
      data: [],
      onProgress: false,
      isError: false,
      errorMessage: ''
    }
  }

  beforeFetch(params) {
    params["category.equals"] = "EBILLING_IMPORT_ESSP"

    if(params["sortBy"] === "createdAt") {
      params["sortBy"] = "createdDate"
      params["sort"] = "DESC"
    }
  }

  tableActions = (data) => {
    let actions = [
      {
        label:"More Info",
        iconClassName:"mdi mdi-bell",
        onClick: (item) => this.editItem(item)
      }
    ]

    let divider = {
      label:"divider",
      iconClassName:"-"
    }

    if(data.status == 'FINISH' || data.status == 'FAILED') {
      let deleteAction = {
        label:"Delete",
        iconClassName:"mdi mdi-delete",
        onClick:(item, callback) => this.deleteItem(item, callback),
        confirmation:{
          title:"sentence.custom.deleteItem",
          message:"sentence.custom.deleteItemConfirmation"
        }
      }

      actions.push(divider)
      actions.push(deleteAction)
    }

    return actions
  }

  _barActions = [
    {
      label:'word.delete',
      iconClassName:'mdi mdi-delete',
      onClick:() => {
        console.log('this',this)
        this.setState({showDialogConfirmDeleteFinishFailedSelected:true})
      },
      disabledFunc:() => {
        let selected = this.props.table.selected
        let deleteable = []

        selected.forEach(data => {
          if(data.status == 'FINISH' || data.status == 'FAILED') {
            deleteable.push(data.id)
          }
        })

        return deleteable.length === 0
      }
    },
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => this.fetchData()
    }
  ]

  _barItem() {
    return (
      <div>
        <Button primary raised style={{margin: 5}} onClick={this.handleDownloadTemplate.bind(this)}>Template</Button>
        <Switch
          style={{float: "right"}}
          id="switch-table-setting"
          name="Switch Table Setting"
          label=""
          checked={this.state.showTableSetting}
          onChange={() => {
            this.setState({
              showTableSetting:!this.state.showTableSetting
            })
          }}
        />
      </div>
    )
  }

  async handleDownloadTemplate() {
    var report = await this.service.api.downloadTemplate()
    download(report.data, 'Template Import Essp.csv');
  }

  columns=[
    {isSortable: true, label: "word.fileId", value: "fileId", show: true, isSearchable: true},
    {isSortable: true, label: "word.startDate", value: "startDate", type: "date", show: true, isSearchable: true},
    {isSortable: true, label: "word.endDate", value: "endDate", type: "date", show: true, isSearchable: true},
    {isSortable: true, label: "word.status", value: "status", show: true, isSearchable:false},
    {isSortable: true, label: "word.step", value: "step", show: true, isSearchable: true, type: "number"},
    {isSortable: true, label: "word.count", value: "count", show: true, isSearchable: true, type: "number"},
    {isSortable: true, label: "word.total", value: "total", show: true, isSearchable: true, type: "number"},
  ]

  async handleSave(value) {
    var _this = this
    try {
      this.setState({onProgress: true})
      let formData = new FormData();
      formData.append("type", value.type)
      formData.append("category", "EBILLING_IMPORT_ESSP")
      formData.append("file", value.file)
      formData.append("no", value.no)
      axios.post(`/api/${ImportService.pathEbillingImport}`, formData).then(function (res){
        izitoast.info({title: 'Import File', message: 'sedang diproses cek log untuk lebih detil'})
        _this.setState({onProgress: false})
        _this.fetchData()
      }).catch(function (e){
        _this.fetchData()
        var msg = e.message;
        if(e.response) msg = e.response.data.message;
        _this.setState({
          isError: true,
          onProgress: false,
          errorMessage: msg
        })
      })
    } catch(e) {
      _this.fetchData()
      var msg = e.message;
      if(e.response) msg = e.response.data.message;
      _this.setState({
        isError: true,
        onProgress: false,
        errorMessage: msg
      })
    }
  }

  componentDidMount(){
    let path = this.props.match.path.split("/")
    let params = path[2];
    this.props.change("category", params)
    this.props.change("type", "M")
    this.props.change("no", false)
  }

  handleTabChange(newActiveTabIndex){
    if(newActiveTabIndex == 0){
      this.props.change("type", "M")
    } else {
      this.props.change("type", "A")
    }
  }

  // yg ditambah
  tabWidth = 280
  tabs= ["import", "search", "column"]

  tabComponents() {
    var obj = this;
    return {
      import: this.import.bind(obj)
    }
  }
  // end yg ditambah

  import(){
    const { handleSubmit, submitting, valid, pristine } = this.props;
    return (
      <div className='mpk-layout column fill'>
        <div className='flex'>
          <div className='md-grid'>
            <div className="md-cell md-cell--12">
              <Field
                label='Select a File'
                name='file'
                twoLines
                component={Fileinput}
                validate={validation.required}
              />
            </div>
            <div className="md-cell md-cell--12">
              <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Import</Button>
            </div>
          </div>
        </div>
        {this.state.onProgress ? (
          <LinearProgress style={{margin: 0}} query={true} />
        ) : (null)}
      </div>
    )
  }

}
