import React, { Component } from 'react';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import { validation, Textfield, TextfieldMask, Datepicker, convert } from '../../../../../../components/form';
import ImportDialogTemplateView from '../../../../../../components/entity/import.dialog.template.view2';
import ImportSigner15Service from './ImportSigner15.service';
ImportDialogTemplateView


@reduxForm({form: 'ImportSigner15-form', destroyOnUnmount: false})
export default class ImportSigner15DialogView extends ImportDialogTemplateView {
  pasal="pasal15"
  service=ImportSigner15Service
}
