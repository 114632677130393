import React from 'react';
import { Route, Switch } from 'react-router-dom';

export default (props) => (
  <Switch>
    <Route path={props.match.path+'/BP_22/:id/:type'} component={require('./BP22/BP22.form').default} />
    <Route path={props.match.path+'/BP_22'} component={require('./BP22/BP22.view').default} />

    <Route path={props.match.path+'/ssp_pasal22_pembelian_barang/:id'} component={require('./SSPPembelianBarang/SSPPembelianBarang.form').default} />
    <Route path={props.match.path+'/ssp_pasal22_pembelian_barang'} component={require('./SSPPembelianBarang/SSPPembelianBarang.view').default} />
    <Route path={props.match.path+'/ssp_bank_devisa/:id'} component={require('./SSPImportBankDevisa/SSPImportBankDevisa.form').default} />
    <Route path={props.match.path+'/ssp_bank_devisa'} component={require('./SSPImportBankDevisa/SSPImportBankDevisa.view').default} />
    <Route path={props.match.path+'/ssp_dibayar_sendiri/:id'} component={require('./SSPDibayarSendiri/SSPDibayarSendiri.form').default} />
    <Route path={props.match.path+'/ssp_dibayar_sendiri'} component={require('./SSPDibayarSendiri/SSPDibayarSendiri.view').default} />

    <Route path={props.match.path+'/SSP22/:id'} component={require('./SSP/SSP22.form').default} />
    <Route path={props.match.path+'/SSP22'} component={require('./SSP/SSP22.view').default} />

    <Route path={props.match.path+'/PBK22/:id'} component={require('./PBK/PBK22.form').default} />
    <Route path={props.match.path+'/PBK22'} component={require('./PBK/PBK22.view').default} />

    <Route path={props.match.path+'/SPT22INDUK'} component={require('./Induk/SPT22.induk.form').default} />

    <Route path={props.match.path+'/FILE_LAPOR_22'} component={require('./FileLapor/FileLapor22.view').default} />

    <Route path={props.match.path+'/EXPORT_CSV_22'} component={require('./Export/ExportCsv22/ExportCsv22.view').default} />

    <Route path={props.match.path+"/IMPORT/BP_22"} component={require('./Import/ImportBP22AtasImpor/ImportBP22AtasImpor.view').default} />
    <Route path={props.match.path+"/IMPORT/SSP_22_dibayar_sendiri"} component={require('./Import/ImportSSP22DibayarSendiri/ImportSSP22DibayarSendiri.view').default} />
    <Route path={props.match.path+"/IMPORT/SSP_22"} component={require('./Import/ImportSSP/ImportSSP22.view').default} />
    <Route path={props.match.path+"/IMPORT/PBK_22"} component={require('./Import/ImportPBK/ImportPBK22.view').default} />
    <Route path={props.match.path+"/IMPORT/LK_22"} component={require('./Import/ImportLK/ImportLK22.view').default} />

    {/* SETTING 22 */}
    <Route path={props.match.path+"/SETTING_NO_BUPOT_22"} component={require('./SettingNoBupot22/SettingNoBupot22.form').default} />
    {/* END SETTING 22 */}

    {/* 4a2 */}
    <Route path={props.match.path+"/master/tarif-22"} exact component={require('./master/tarif/Master22Tarif.form').default} />
    {/* END 4a2 */}

    <Route path={props.match.path+'/'} component={function() {return <div/>}} />
  </Switch>
)
