import React from 'react'
import LogFileBpView from './LogFileBp.view'
import LogFileCsvView from './LogFileCsv.view'
import { Commandbar } from 'react-mpk'
import { TabsContainer, Tabs, Tab, DialogContainer } from 'react-md'
import LogFileValidasi from './../LogFileValidasi/LogFileValidasi.view'

export default class LogFile extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      ...this.state,
      showLogValidasi: false,
      showLogValidasiParam: null,
    }
  }

  hideDialogLogValidasi(){
    this.setState({ showLogValidasi: false })
  }

  editItem = (item) => {
    this.setState({
      showLogValidasi: true,
      showLogValidasiParam: item
    })
  }

  render(){
    return (
      <div className="mpk-layout column fill">
        <div className="flex all mpk-content mpk-tab">
          <DialogContainer
            id="LogFileCsvValidasiCsv"
            visible={this.state.showLogValidasi}
            onHide={this.hideDialogLogValidasi.bind(this)}
            height={600}
            width={800}
            contentStyle={{
              height: 600
            }}
          >
            <div>
              <LogFileValidasi dialogParam={this.state.showLogValidasiParam} dialogHide={this.hideDialogLogValidasi.bind(this)} />
            </div>
          </DialogContainer>
          <TabsContainer colored>
            <Tabs tabId="upload-file-tab">
              <Tab label="Import BP">
                <LogFileBpView editItem={this.editItem.bind(this)} history={this.props.history} match={this.props.match} />
              </Tab>
              <Tab label="Import Lainnya">
                <LogFileCsvView editItem={this.editItem.bind(this)} history={this.props.history} match={this.props.match} />
              </Tab>
            </Tabs>
          </TabsContainer>
        </div>
      </div>
    )
  }
}
