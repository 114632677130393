/**
 * Created by dwiargo on 12/4/17.
 */

 import React, { Component } from 'react';
 import SidebarMenuGroup from './SidebarMenuGroup'
 import './SidebarMenu.scss';
 
 
 class SidebarMenu extends Component {
 
   constructor(props) {
     super(props);
 
     this.state = {
       activeMenu: 9999
     }
   }
 
   render() {
     let {menus, getValueByLocaleCode, global, tableActions} = this.props;
 
     return (
       <div className="mpk-sidabar-menu mpk-content flex">
         {menus.map((d, i) => {
           if(i == this.state.activeMenu){
             d.active = true
           } else {
             d.active = false
           }
           return <SidebarMenuGroup
             menuClass={this.props.menuClass}
             collapsible={this.props.collapsible}
             key={i}
             menuData={d}
             menuDataIndex={i}
             nextData={menus[i + 1] ? menus[i + 1] : null}
             getValueByLocaleCode={getValueByLocaleCode}
             global={global}
             tableActions={tableActions}
 
             accordionMode={this.props.accordionMode}
             currentMenu={i}
             activeMenu={this.state.activeMenu}
             handleToggle={(index)=> {
              this.setState({activeMenu: i})
             }}
           />
         })}
       </div>
     )
   }
 }
 
 export default SidebarMenu;
 