import React, { Component } from 'react'; import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {TabsContainer, Tabs, Tab, Button, DialogContainer, List, ListItem } from 'react-md';
import { Field, reduxForm, initialize } from 'redux-form';
import axios from 'axios'
import moment from 'moment'

import LogFileService from './LogFile.service';
import ListView from '../../../../../components/entity/ListViewEbupot';
import AuthService from '../../../../../services/auth.service'
import ListViewEbupot from '../../../../../components/entity/ListViewEbupot';
import OrganizationService from '../Organization/Organization.service'
import {validation, FileInputNext, Searchfieldv2, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Radio, convert, CheckboxSelection} from '../../../../../components/form';
import iziToast from 'izitoast';
import LogFileValidasi from '../LogFileValidasi/LogFileValidasi.view'
import download from 'downloadjs';
import bluebird from 'bluebird';

@reduxForm({form: 'LogFileForm', destroyOnUnmount: true})
@connect((state)=> ({
  ...LogFileService.stateConnectSetting()(state),
  // EPPT CORE
  auth: state.auth.company
  // EPPT CORE
}), LogFileService.actionConnectSetting())
export default class LogFileBpView extends ListViewEbupot {
  service=LogFileService;
  addDialog=false
  editDialog=false

  constructor(props){
    super(props)
    this.state = {
      ...this.state,
      showLogValidasi: false,
      showLogValidasiParam: null,
      cetak: false
    }
  }

  plainTable(){ return true }

  static contextTypes = {
    showDialog: PropTypes.func.isRequired,
  };

  componentDidMount(){
    this.props.initialize({
      rev: '0'
    })
  }

  addItem = () => {
    var orgId = this.props.match.params.id
    this.props.history.push(orgId+"/new");
  }

  editItem = (item) => {
    this.props.history.push(`log-file-validasi/${item.id}/bp/${item.status}`);
  }

  // fetchOption() {
  //   return {
  //     path: LogFileService.path+'/'+this.props.match.params.id+'/organization'
  //   }
  // }

  beforeFetch(params) {
    delete params.startDate
    delete params.endDate
    delete params.column
    delete params.total
    delete params.keyword


    // params.sortBy = 'id'
    // params.sort = 1
  }

  hideDialogLogValidasi(){
    this.setState({ showLogValidasi: false })
  }

  belowTopBar(){
    return (
      <DialogContainer
        id="LogFileValidasi"
        visible={this.state.showLogValidasi}
        onHide={this.hideDialogLogValidasi.bind(this)}
        height={600}
        width={800}
        contentStyle={{
          height: 600
        }}
      >
        <div>
          <LogFileValidasi dialogParam={this.state.showLogValidasiParam} dialogHide={this.hideDialogLogValidasi.bind(this)} />
        </div>
      </DialogContainer>
    )
  }

  columns=LogFileService.columns

  // _barItem(){
  //   return <div></div>
  // }

  async downloadTemplate(){
    try {
      var report = await this.service.api.downloadTemplate()
      var filename = report.headers.filename
      download(report.data, filename);
      this.setState({cetak: false})
      iziToast.success({
        title: "Success",
        message: "download Template sedang diproses"
      })
    } catch (e) {
      iziToast.error({
        title: "Failed",
        message: "download Template gagal, " + e.message
      })
      this.setState({cetak: false})
    }
  }

  _barActions = [
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => this.fetchData()
    },
    {
      label:'word.downloadTemplate',
      iconClassName:'mdi mdi-file-excel',
      onClick:() => this.downloadTemplate()
    },
  ]

  async downloadExcel(item){
    var filename = ""
    try {
      filename = item.originalFilename.split('.')[0] + '.' + item.originalFilename.split('.')[1]
    } catch(e){
      console.log(e)
    }
    // console.log(item)
    // originalFilename: "skema-import (Testuser1).xls.xls"
    try {
      let downloadExcel = await this.service.api.downloadExcel({
        'npwp': item.npwp,
        'name': filename
      })
      window.open(downloadExcel.data.url, '_blank')
      // window.open(downloadExcel.data.url)
      // var filenames = downloadExcel.headers.filename
      // download(downloadExcel.data, filenames);
    } catch(e){
      console.log(e)
    }
  }

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    },
    {
      label:"Download Excel",
      iconClassName:"mdi mdi-file-excel",
      onClick: (item) => this.downloadExcel(item)
    },
    {
      label:"Delete",
      iconClassName:"mdi mdi-delete",
      onClick:(item, callback) => this.deleteItem(item, callback),
      confirmation:{
        title:"sentence.custom.deleteItem",
        message:"sentence.custom.deleteItemConfirmation"
      }
    }
  ]

  // yg ditambah
  tabWidth = 280
  tabs= ["import", "search", "column"]

  tabComponents() {
    var obj = this;
    return {
      import: this.import.bind(obj)
    }
  }
   // end yg ditambah

  handleSaveOneFile(value){
    if(value.rev == undefined) value.rev = 0
    let formData = new FormData();
    formData.append("file", value.file)
    formData.append("npwp", value.npwp)
    formData.append("rev", parseInt(value.rev))
    axios.post('/ebupot/upload/excel', formData).then(function (res){
      iziToast.success({
        title: "Upload",
        message: "Upload berhasil silahkan klik tombol refresh untuk mengecek log"
      })
    }).catch(function (e){
      iziToast.error({
        title: "Upload",
        message: "Upload gagal " + e.message
      })
    })
  }

  async handleSave(value){
    var files = value.file;
    let res = await bluebird.mapSeries(files, (file, index)=> {
      return new Promise( async (resolve, reject)=> {
        if(value.rev == undefined) value.rev = 0
          let formData = new FormData();
          formData.append("file", file)
          formData.append("npwp", file.name.slice(0,15))
          formData.append("rev", parseInt(value.rev))
          await axios.post('/ebupot/upload/excel', formData).then(function (res){
          resolve({
            file: index+1 + ". " + file.name,
            message:  "Upload berhasil silahkan klik tombol refresh untuk mengecek log."
          })
        }).catch(function (e){
          resolve({
            file: index+1 + ". " + file.name,
            message:  "Upload gagal , " + e.message + "."
          })
        })
      })
    })
    if(res.length > 0){
      var finalDialog = await this.context.showDialog(()=> ({
        title: null,
        initialValue: null,
        width: 800,
        height: 600,
        contentStyle: {
          height: 600
        },
        text: (
          <List>
            {res.map((item, index)=> {
              return <ListItem key={index} primaryText={item.file} secondaryText={item.message}></ListItem>
            })}
          </List>
        )
      }))
      if(finalDialog){}
    }
  }

  import(){
    const { handleSubmit, submitting, valid, pristine } = this.props;
    var org = []
    if(this.props.auth && this.props.auth.organizations) org = this.props.auth.organizations
    return (
        <div className='mpk-layout column fill'>
          <div className='flex'>
            <div className='md-grid'>
              <div className="md-cell md-cell--12">
                <Field
                  label='Select a File'
                  name='file'
                  accept=".xls, .xlsx"
                  twoLines
                  component={FileInputNext}
                  validate={validation.required}
                  multiple={true}
                />
              </div>
              {/* <Field
                label     = 'Organization'
                name      = 'npwp'
                className = "md-cell md-cell--12"
                component={Searchfieldv2}
                valueField='npwp'
                viewField='nama'
                service={OrganizationService}
                remoteSearch
                validate={validation.required}
              /> */}
              {/* <Field
                label     = 'Organization'
                name      = 'npwp'
                id        = "spt2126-organization"
                className = "md-cell md-cell--12"
                component = {Searchfield}
                valueField = 'npwp'
                options   = {org}
                validate  = {validation.required}
                itemTemplate = {function(d) {
                  let nameAndAlias = ""
                  if (d.name)
                    nameAndAlias = d.name
                  if (d.aliasName)
                    nameAndAlias += " - " + d.aliasName
                  return {
                    primaryText  : nameAndAlias,
                    secondaryText: d.npwp,
                    onClick      : () => {
                      if(!this.props.disabled){
                        this.handleItemClick(d); this.hide()
                      }
                    }
                  }
                }}
              /> */}
              <Field
                label     = 'Pembetulan'
                name      = 'rev'
                className = "md-cell md-cell--12"
                component = {Searchfieldv2}
                validate={validation.required}
                options   = {[
                  { name: '0', id:  '0' },
                  { name: '1', id:  '1' },
                  { name: '2', id:  '2' },
                  { name: '3', id:  '3' },
                  { name: '4', id:  '4' },
                  { name: '5', id:  '5' },
                  { name: '6', id:  '6' },
                  { name: '7', id:  '7' },
                  { name: '8', id:  '8' },
                  { name: '9', id:  '9' },
                  { name: '10', id: '10' },
                  { name: '11', id: '11' },
                  { name: '12', id: '12' },
                ]}
              />
              <div className="md-cell md-cell--12" style={{ background: "lightyellow", padding: "20px" }}>
                <span>Format untuk upload file 15 digit npwp. contoh: 123456789012345_namafile.xls</span>
              </div>
              <div className="md-cell md-cell--12">
                  <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Import</Button>
              </div>
            </div>
          </div>
        </div>
    )
  }

  // NEW TAB MENU
  async hapusPencarian(){
    try {
      try {
        localStorage.removeItem(`search_${this.service.name}`)
      } catch(e){}
      var params = Object.assign({}, params=this.props.table.params)
      this.fetchData(params)
      await this.props.dispatch(initialize(this.service.name+'_search', {}));
    } catch(e){
      console.log(e, "Search Error ...")
    }
  }

  async handleSearching(values){
    try {
      var formData = this.props.tableFilter2
      var params = Object.assign({}, params=this.props.table.params)

      params = {
        ...params,
        ...formData
      }

      if(formData.originalFilename){
        params['originalFilename.contains'] = formData.originalFilename
        delete params.originalFilename
      } else { delete params['originalFilename.contains'] }

      if(formData.md5){
        params['md5.contains'] = formData.md5
        delete params.md5
      } else { delete params['md5.contains'] }

      if(formData.message){
        params['message.contains'] = formData.message
        delete params.message
      } else { delete params['message.contains'] }

      if(formData.createdBy){
        params['createdBy.contains'] = formData.createdBy
        delete params.createdBy
      } else { delete params['createdBy.contains'] }

      if(formData.lastModifiedBy){
        params['lastModifiedBy.contains'] = formData.lastModifiedBy
        delete params.lastModifiedBy
      } else { delete params['lastModifiedBy.contains'] }

      if(formData.createdDate1){
        params['createdDate.greaterOrEqualThan'] = new Date(moment(formData.createdDate1).format('MM/DD/YYYY')).toISOString();
        delete params.createdDate1
      } else { delete params['createdDate.greaterOrEqualThan'] }

      if(formData.createdDate2){
        params['createdDate.lessOrEqualThan'] = new Date(moment(formData.createdDate2).format('MM/DD/YYYY')).toISOString();
        delete params.createdDate2
      } else { delete params['createdDate.lessOrEqualThan'] }

      if(formData.lastModifiedDate1){
        params['lastModifiedDate.greaterOrEqualThan'] = new Date(moment(formData.lastModifiedDate1).format('MM/DD/YYYY')).toISOString();
        delete params.lastModifiedDate1
      } else { delete params['lastModifiedDate.greaterOrEqualThan'] }

      if(formData.lastModifiedDate2){
        params['lastModifiedDate.lessOrEqualThan'] = new Date(moment(formData.lastModifiedDate2).format('MM/DD/YYYY')).toISOString();
        delete params.lastModifiedDate2
      } else { delete params['lastModifiedDate.lessOrEqualThan'] }
      params.onSearch = true
      localStorage.setItem("formData_search", JSON.stringify(formData))
      this.fetchData(params)
    } catch(e){
      iziToast.info({
        title: "Pencarian",
        message: "Data tidak ditemukan, " + e.message
      })
    }
  }

  defaultSearchForm(){
    const { handleSubmit, submitting, valid, pristine } = this.props;
    return (
      <div className="md-grid" style={{ overflow: 'auto' }}>
        <Field
          label     = 'Filename'
          name      = 'originalFilename'
          className = "md-cell md-cell--12"
          component = {Textfield}
        />
        <Field
          label     = 'MD5'
          name      = 'md5'
          className = "md-cell md-cell--12"
          component = {Textfield}
        />
        <Field
          label     = 'Message'
          name      = 'message'
          className = "md-cell md-cell--12"
          component = {Textfield}
        />
        <Field
          label     = 'Dibuat Oleh'
          name      = 'createdBy'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'Diubah Oleh'
          name      = 'lastModifiedBy'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'Dibuat dari'
          name      = 'createdDate1'
          className = "md-cell md-cell--12"
          component = {Datepicker}
        />
        <Field
          label     = 'Dibuat ke'
          name      = 'createdDate2'
          className = "md-cell md-cell--12"
          component = {Datepicker}
        />
        <Field
          label     = 'Diubah dari'
          name      = 'lastModifiedDate1'
          className = "md-cell md-cell--12"
          component = {Datepicker}
        />
        <Field
          label     = 'Diubah ke'
          name      = 'lastModifiedDate2'
          className = "md-cell md-cell--12"
          component = {Datepicker}
        />
        <Button raised primary className="md-cell md-cell--12" onClick={handleSubmit(this.handleSearching.bind(this))}>Cari</Button>
        <hr />
        <Button raised primary className="md-cell md-cell--12" onClick={handleSubmit(this.hapusPencarian.bind(this))}>Hapus</Button>
      </div>
    )
  }
  // yg ditambah
  // NEW TAB MENU

}
