import ApiService from '../../../../../services/api.service';

class OrganizationService extends ApiService {
  name= 'organizationUser';
  path= 'organization/user';

  constructor() {
    super();
    this.init()
  }
}


export default new OrganizationService();
