import ApiService from '../../../../../services/api.service';

class SPT4a2SignerService extends ApiService {
  name= 'SPT4a2Signer';
  path= 'pasal4/spt4a2/signer';
  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    getSigner: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/spt4a2/${sptId}`);
      return res.data;
    }
  }
}

export default new SPT4a2SignerService();
