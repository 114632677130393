import React from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  Tab,
  Tabs,
  SelectionControlGroup,
  Button
} from 'react-md';
import download from 'downloadjs';
import uuid from 'uuid';
import iziToast from 'izitoast';

import FormView from '../../../../../components/entity/form.view';
import SPTINDUK21Service from './SPT_INDUK_21.service';
import {validation, Textfield, Searchfield, Switch, Checkbox, Datepicker, TextfieldMask} from '../../../../../components/form';
import columnService from '../../../../../services/column.service';
import SPT21SignerService from '../SPT21Signer/SPT21Signer.service';

@reduxForm({form: 'SPT_INDUK_21_Form', destroyOnUnmount: true})
@connect((state) => ({
  access      : state.entity.access.api.find,
  organization: state.authEppt.currentOrganization,
  spt         : state.authEppt.currentSPT,
  formData    : state.form.SPT_INDUK_21_Form,
  user        : state.auth.user,
}))
export default class SPTINDUK21Form extends FormView {
  service  = SPTINDUK21Service
  viewType = 2;

  initialData={
    bagB1: {},
    bagB2: {},
    bagC : {},
    bagD : {},
    bagE : {}
  }

  constructor(props){
    super(props);
    this.state = {
      ...this.state,
      cetak    : false,
      calculate: false,
      signer   : {

      }
    }
  }

  async handleSave(value) {
    value.spt           = value.spt21
    value.a01           = value.bagA.a01
    value.a02           = value.bagA.a02
    value.a03           = value.bagA.a03
    value.a04           = value.bagA.a04
    value.a05           = value.bagA.a05
    value.b01Column4    = value.bagB1.b14
    value.b01Column5    = value.bagB1.b15
    value.b01Column6    = value.bagB1.b16
    value.b02Column4    = value.bagB1.b24
    value.b02Column5    = value.bagB1.b25
    value.b02Column6    = value.bagB1.b26
    value.b03Column4    = value.bagB1.b34
    value.b03Column5    = value.bagB1.b35
    value.b03Column6    = value.bagB1.b36
    value.b04aColumn4   = value.bagB1.b4a4
    value.b04aColumn5   = value.bagB1.b4a5
    value.b04aColumn6   = value.bagB1.b4a6
    value.b04bColumn4   = value.bagB1.b4b4
    value.b04bColumn5   = value.bagB1.b4b5
    value.b04bColumn6   = value.bagB1.b4b6
    value.b04cColumn4   = value.bagB1.b4c4
    value.b04cColumn5   = value.bagB1.b4c5
    value.b04cColumn6   = value.bagB1.b4c6
    value.b04dColumn4   = value.bagB1.b4d4
    value.b04dColumn5   = value.bagB1.b4d5
    value.b04dColumn6   = value.bagB1.b4d6
    value.b04eColumn4   = value.bagB1.b4e4
    value.b04eColumn5   = value.bagB1.b4e5
    value.b04eColumn6   = value.bagB1.b4e6
    value.b04fColumn4   = value.bagB1.b4f4
    value.b04fColumn5   = value.bagB1.b4f5
    value.b04fColumn6   = value.bagB1.b4f6
    value.b05Column4    = value.bagB1.b54
    value.b05Column5    = value.bagB1.b55
    value.b05Column6    = value.bagB1.b56
    value.b06Column4    = value.bagB1.b64
    value.b06Column5    = value.bagB1.b65
    value.b06Column6    = value.bagB1.b66
    value.b07Column4    = value.bagB1.b74
    value.b07Column5    = value.bagB1.b75
    value.b07Column6    = value.bagB1.b76
    value.b08Column4    = value.bagB1.b84
    value.b08Column5    = value.bagB1.b85
    value.b08Column6    = value.bagB1.b86
    value.b09Column4    = value.bagB1.b94
    value.b09Column5    = value.bagB1.b95
    value.b09Column6    = value.bagB1.b96
    value.b10Column4    = value.bagB1.b104
    value.b10Column5    = value.bagB1.b105
    value.b10Column6    = value.bagB1.b106
    value.b11Column4    = value.bagB1.b114
    value.b11Column5    = value.bagB1.b115
    value.b11Column6    = value.bagB1.b116
    value.b12           = value.bagB2.b12
    value.b13Month1     = value.bagB2.b13Month1
    value.b13Month2     = value.bagB2.b13Month2
    value.b13Month3     = value.bagB2.b13Month3
    value.b13Month4     = value.bagB2.b13Month4
    value.b13Month5     = value.bagB2.b13Month5
    value.b13Month6     = value.bagB2.b13Month6
    value.b13Month7     = value.bagB2.b13Month7
    value.b13Month8     = value.bagB2.b13Month8
    value.b13Month9     = value.bagB2.b13Month9
    value.b13Month10    = value.bagB2.b13Month10
    value.b13Month11    = value.bagB2.b13Month11
    value.b13Month12    = value.bagB2.b13Month12
    value.b13Year       = value.bagB2.b13Year
    value.b13           = value.bagB2.b13
    value.b14           = value.bagB2.b14
    value.b15           = value.bagB2.b15
    value.b16           = value.bagB2.b16
    value.b17           = value.bagB2.b17
    value.b18Month      = value.bagB2.b18Month
    value.b18Year       = value.bagB2.b18Year
    value.c01Column4    = value.bagC.c14
    value.c01Column5    = value.bagC.c15
    value.c01Column6    = value.bagC.c16
    value.c02Column4    = value.bagC.c24
    value.c02Column5    = value.bagC.c25
    value.c02Column6    = value.bagC.c26
    value.c03Column4    = value.bagC.c34
    value.c03Column5    = value.bagC.c35
    value.c03Column6    = value.bagC.c36
    value.c04Column4    = value.bagC.c44
    value.c04Column5    = value.bagC.c45
    value.c04Column6    = value.bagC.c46
    value.c05Column4    = value.bagC.c54
    value.c05Column5    = value.bagC.c55
    value.c05Column6    = value.bagC.c56
    value.d01           = value.bagD.d01
    value.d01Lembar     = value.bagD.d01Lembar
    value.d02           = value.bagD.d02
    value.d02Lembar     = value.bagD.d02Lembar
    value.d03           = value.bagD.d03
    value.d03Lembar     = value.bagD.d03Lembar
    value.d04           = value.bagD.d04
    value.d04Lembar     = value.bagD.d04Lembar
    value.d05           = value.bagD.d05
    value.d05Lembar     = value.bagD.d05Lembar
    value.d06           = value.bagD.d06
    value.d07           = value.bagD.d07
    value.d07Lembar     = value.bagD.d07Lembar
    value.d08           = value.bagD.d08
    value.e0102         = value.bagE.e0102
    value.e03           = value.bagE.e03
    value.e04           = value.bagE.e04
    value.e05           = value.bagE.e05
    value.e06           = value.bagE.e06
    // MAPPING
    try {
      this.setState({onProgress: true})
      if(value.id) {
        var res = await this.service.api.update(value)

        if(res.data) {
          this.props.initialize(res.data);
        }
      } else {
        await this.service.api.save(value)
      }
      this.setState({onProgress: false})
      this.toast.success({title: 'Success', message: 'Saved'})
    } catch(e) {
      var msg              = e.message;
      if  (e.response) msg = e.response.data.message;
      this.setState({
        isError     : true,
        onProgress  : false,
        errorMessage: msg
      })
    }
  }

  async initData() {
      let res = await this.service.api.getSPT(this.props.match.params.sptId);

      if(res.data){
        // MAPPING
        res.data.spt21 = res.data.spt
        res.data.bagA  = {
          a01 : res.data.a01,
          a02 : res.data.a02,
          a03 : res.data.a03,
          a04 : res.data.a04,
          a05 : res.data.a05,
        }
        res.data.bagB1 = {
          b14  : res.data.b01Column4,
          b15  : res.data.b01Column5,
          b16  : res.data.b01Column6,
          b24  : res.data.b02Column4,
          b25  : res.data.b02Column5,
          b26  : res.data.b02Column6,
          b34  : res.data.b03Column4,
          b35  : res.data.b03Column5,
          b36  : res.data.b03Column6,
          b4a4 : res.data.b04aColumn4,
          b4a5 : res.data.b04aColumn5,
          b4a6 : res.data.b04aColumn6,
          b4b4 : res.data.b04bColumn4,
          b4b5 : res.data.b04bColumn5,
          b4b6 : res.data.b04bColumn6,
          b4c4 : res.data.b04cColumn4,
          b4c5 : res.data.b04cColumn5,
          b4c6 : res.data.b04cColumn6,
          b4d4 : res.data.b04dColumn4,
          b4d5 : res.data.b04dColumn5,
          b4d6 : res.data.b04dColumn6,
          b4e4 : res.data.b04eColumn4,
          b4e5 : res.data.b04eColumn5,
          b4e6 : res.data.b04eColumn6,
          b4f4 : res.data.b04fColumn4,
          b4f5 : res.data.b04fColumn5,
          b4f6 : res.data.b04fColumn6,
          b54  : res.data.b05Column4,
          b55  : res.data.b05Column5,
          b56  : res.data.b05Column6,
          b64  : res.data.b06Column4,
          b65  : res.data.b06Column5,
          b66  : res.data.b06Column6,
          b74  : res.data.b07Column4,
          b75  : res.data.b07Column5,
          b76  : res.data.b07Column6,
          b84  : res.data.b08Column4,
          b85  : res.data.b08Column5,
          b86  : res.data.b08Column6,
          b94  : res.data.b09Column4,
          b95  : res.data.b09Column5,
          b96  : res.data.b09Column6,
          b104 : res.data.b10Column4,
          b105 : res.data.b10Column5,
          b106 : res.data.b10Column6,
          b114 : res.data.b11Column4,
          b115 : res.data.b11Column5,
          b116 : res.data.b11Column6,
        }
        res.data.bagB2 = {
          b12        : res.data.b12,
          b13Month1  : res.data.b13Month1,
          b13Month2  : res.data.b13Month2,
          b13Month3  : res.data.b13Month3,
          b13Month4  : res.data.b13Month4,
          b13Month5  : res.data.b13Month5,
          b13Month6  : res.data.b13Month6,
          b13Month7  : res.data.b13Month7,
          b13Month8  : res.data.b13Month8,
          b13Month9  : res.data.b13Month9,
          b13Month10 : res.data.b13Month10,
          b13Month11 : res.data.b13Month11,
          b13Month12 : res.data.b13Month12,
          b13Year    : res.data.b13Year,
          b13        : res.data.b13,
          b14        : res.data.b14,
          b15        : res.data.b15,
          b16        : res.data.b16,
          b17        : res.data.b17,
          b18Month   : res.data.b18Month,
          b18Year    : res.data.b18Year,
        }
        res.data.bagC = {
          c14 : res.data.c01Column4,
          c15 : res.data.c01Column5,
          c16 : res.data.c01Column6,
          c24 : res.data.c02Column4,
          c25 : res.data.c02Column5,
          c26 : res.data.c02Column6,
          c34 : res.data.c03Column4,
          c35 : res.data.c03Column5,
          c36 : res.data.c03Column6,
          c44 : res.data.c04Column4,
          c45 : res.data.c04Column5,
          c46 : res.data.c04Column6,
          c54 : res.data.c05Column4,
          c55 : res.data.c05Column5,
          c56 : res.data.c05Column6,
        }
        res.data.bagD = {
          d01       : res.data.d01,
          d01Lembar : res.data.d01Lembar,
          d02       : res.data.d02,
          d02Lembar : res.data.d02Lembar,
          d03       : res.data.d03,
          d03Lembar : res.data.d03Lembar,
          d04       : res.data.d04,
          d04Lembar : res.data.d04Lembar,
          d05       : res.data.d05,
          d05Lembar : res.data.d05Lembar,
          d06       : res.data.d06,
          d07       : res.data.d07,
          d07Lembar : res.data.d07Lembar,
          d08       : res.data.d08,
        }
        res.data.bagE = {
          e0102 : res.data.e0102,
          e03   : res.data.e03,
          e04   : res.data.e04,
          e05   : res.data.e05,
          e06   : res.data.e06,
        }
        // res.data.a01          = res.data.a01
        // res.data.a02          = res.data.a02
        // res.data.a03          = res.data.a03
        // res.data.a04          = res.data.a04
        // res.data.a05          = res.data.a05
        // res.data.b14          = res.data.b01Column4
        // res.data.b15          = res.data.b01Column5
        // res.data.b16          = res.data.b01Column6
        // res.data.b24          = res.data.b02Column4
        // res.data.b25          = res.data.b02Column5
        // res.data.b26          = res.data.b02Column6
        // res.data.b34          = res.data.b03Column4
        // res.data.b35          = res.data.b03Column5
        // res.data.b36          = res.data.b04Column6
        // res.data.b4a4         = res.data.b04aColumn4
        // res.data.b4a5         = res.data.b04aColumn5
        // res.data.b4a6         = res.data.b04aColumn6
        // res.data.b4b4         = res.data.b04bColumn4
        // res.data.b4b5         = res.data.b04bColumn5
        // res.data.b4b6         = res.data.b04bColumn6
        // res.data.b4c4         = res.data.b04cColumn4
        // res.data.b4c5         = res.data.b04cColumn5
        // res.data.b4c6         = res.data.b04cColumn6
        // res.data.b4d4         = res.data.b04dColumn4
        // res.data.b4d5         = res.data.b04dColumn5
        // res.data.b4d6         = res.data.b04dColumn6
        // res.data.b4e4         = res.data.b04eColumn4
        // res.data.b4e5         = res.data.b04eColumn5
        // res.data.b4e6         = res.data.b04eColumn6
        // res.data.b4f4         = res.data.b04fColumn4
        // res.data.b4f5         = res.data.b04fColumn5
        // res.data.b4f6         = res.data.b04fColumn6
        // res.data.b54          = res.data.b05Column4
        // res.data.b55          = res.data.b05Column5
        // res.data.b56          = res.data.b05Column6
        // res.data.b64          = res.data.b06Column4
        // res.data.b65          = res.data.b06Column5
        // res.data.b66          = res.data.b06Column6
        // res.data.b74          = res.data.b07Column4
        // res.data.b75          = res.data.b07Column5
        // res.data.b76          = res.data.b07Column6
        // res.data.b84          = res.data.b08Column4
        // res.data.b85          = res.data.b08Column5
        // res.data.b86          = res.data.b08Column6
        // res.data.b94          = res.data.b09Column4
        // res.data.b95          = res.data.b09Column5
        // res.data.b96          = res.data.b09Column6
        // res.data.b104         = res.data.b10Column4
        // res.data.b105         = res.data.b10Column5
        // res.data.b106         = res.data.b10Column6
        // res.data.b114         = res.data.b11Column4
        // res.data.b115         = res.data.b11Column5
        // res.data.b116         = res.data.b11Column6
        // res.data.b12          = res.data.b12
        // res.data.b13Month1    = res.data.b13Month1
        // res.data.b13Month2    = res.data.b13Month2
        // res.data.b13Month3    = res.data.b13Month3
        // res.data.b13Month4    = res.data.b13Month4
        // res.data.b13Month5    = res.data.b13Month5
        // res.data.b13Month6    = res.data.b13Month6
        // res.data.b13Month7    = res.data.b13Month7
        // res.data.b13Month8    = res.data.b13Month8
        // res.data.b13Month9    = res.data.b13Month9
        // res.data.b13Month10   = res.data.b13Month10
        // res.data.b13Month11   = res.data.b13Month11
        // res.data.b13Month12   = res.data.b13Month12
        // res.data.b13Year      = res.data.b13Year
        // res.data.b13          = res.data.b13
        // res.data.b14          = res.data.b14
        // res.data.b15          = res.data.b15
        // res.data.b16          = res.data.b16
        // res.data.b17          = res.data.b17
        // res.data.b18Month     = res.data.b18Month
        // res.data.b18Year      = res.data.b18Year
        // res.data.c14          = res.data.c01Column4
        // res.data.c15          = res.data.c01Column5
        // res.data.c16          = res.data.c01Column6
        // res.data.c24          = res.data.c02Column4
        // res.data.c25          = res.data.c02Column5
        // res.data.c26          = res.data.c02Column6
        // res.data.c34          = res.data.c03Column4
        // res.data.c35          = res.data.c03Column5
        // res.data.c36          = res.data.c03Column6
        // res.data.c44          = res.data.c04Column4
        // res.data.c45          = res.data.c04Column5
        // res.data.c46          = res.data.c04Column6
        // res.data.c54          = res.data.c05Column4
        // res.data.c55          = res.data.c05Column5
        // res.data.c56          = res.data.c05Column6
        // res.data.d01          = res.data.d01
        // res.data.d01Lembar    = res.data.d01Lembar
        // res.data.d02          = res.data.d02
        // res.data.d02Lembar    = res.data.d02Lembar
        // res.data.d03          = res.data.d03
        // res.data.d03Lembar    = res.data.d03Lembar
        // res.data.d04          = res.data.d04
        // res.data.d04Lembar    = res.data.d04Lembar
        // res.data.d05          = res.data.d05
        // res.data.d05Lembar    = res.data.d05Lembar
        // res.data.d06          = res.data.d06
        // res.data.d07          = res.data.d07
        // res.data.d07Lembar    = res.data.d07Lembar
        // res.data.d08          = res.data.d08
        // res.data.e0102        = res.data.e0102
        // res.data.e03          = res.data.e03
        // res.data.e04          = res.data.e04
        // res.data.e05          = res.data.e05
        // res.data.e06          = res.data.e06
        // MAPPING
      }
      if(this.props.organization){
        res.data.bagA.a01 = this.props.organization.npwp
        res.data.bagA.a02 = this.props.organization.name
        res.data.bagA.a03 = this.props.organization.address
        res.data.bagA.a04 = this.props.organization.phone
        res.data.bagA.a05 = this.props.organization.email
      }
      let signer = null;
      if(res.data) {
        try {
          signer = await this.service.api.getSigner(this.props.spt.data.id, res.data.e05)
        } catch(e) {
          console.log(e, "SIGNER ERROR")
        }
        if(signer) {
          this.setState({signer: signer})
          if(res.data.bagE.e0102) {
            res.data.bagE.e03 = signer.npwpPenandaTanganSptInduk;
            res.data.bagE.e04 = signer.namaPenandaTanganSptInduk;
          } else {
            res.data.bagE.e03 = signer.npwpKuasa;
            res.data.bagE.e04 = signer.namaKuasa;
          }
        }
      }
      this.props.initialize(res.data);
  }

  async calculate() {
    await this.setState({calculate: true})
    await this.props.dispatch({
      type: 'TOGGLE_GLOBAL_PROGRESS',
      data: true
    });

    const sptId = this.props.match.params.sptId

    try {
      var res = await this.service.api.calculate(sptId)

      if(res.data) {
        if(res.data){
          // MAPPING
          res.data.spt21 = res.data.spt
          res.data.bagA  = {
            a01 : res.data.a01, // npwp
            a02 : res.data.a02, // nama
            a03 : res.data.a03, // alamat
            a04 : res.data.a04, // telepon
            a05 : res.data.a05, // email
          }
          res.data.bagB1 = {
            b14  : res.data.b01Column4,
            b15  : res.data.b01Column5,
            b16  : res.data.b01Column6,
            b24  : res.data.b02Column4,
            b25  : res.data.b02Column5,
            b26  : res.data.b02Column6,
            b34  : res.data.b03Column4,
            b35  : res.data.b03Column5,
            b36  : res.data.b03Column6,
            b4a4 : res.data.b04aColumn4,
            b4a5 : res.data.b04aColumn5,
            b4a6 : res.data.b04aColumn6,
            b4b4 : res.data.b04bColumn4,
            b4b5 : res.data.b04bColumn5,
            b4b6 : res.data.b04bColumn6,
            b4c4 : res.data.b04cColumn4,
            b4c5 : res.data.b04cColumn5,
            b4c6 : res.data.b04cColumn6,
            b4d4 : res.data.b04dColumn4,
            b4d5 : res.data.b04dColumn5,
            b4d6 : res.data.b04dColumn6,
            b4e4 : res.data.b04eColumn4,
            b4e5 : res.data.b04eColumn5,
            b4e6 : res.data.b04eColumn6,
            b4f4 : res.data.b04fColumn4,
            b4f5 : res.data.b04fColumn5,
            b4f6 : res.data.b04fColumn6,
            b54  : res.data.b05Column4,
            b55  : res.data.b05Column5,
            b56  : res.data.b05Column6,
            b64  : res.data.b06Column4,
            b65  : res.data.b06Column5,
            b66  : res.data.b06Column6,
            b74  : res.data.b07Column4,
            b75  : res.data.b07Column5,
            b76  : res.data.b07Column6,
            b84  : res.data.b08Column4,
            b85  : res.data.b08Column5,
            b86  : res.data.b08Column6,
            b94  : res.data.b09Column4,
            b95  : res.data.b09Column5,
            b96  : res.data.b09Column6,
            b104 : res.data.b10Column4,
            b105 : res.data.b10Column5,
            b106 : res.data.b10Column6,
            b114 : res.data.b11Column4,
            b115 : res.data.b11Column5,
            b116 : res.data.b11Column6,
          }
          res.data.bagB2 = {
            b12        : res.data.b12,
            b13Month1  : res.data.b13Month1,
            b13Month2  : res.data.b13Month2,
            b13Month3  : res.data.b13Month3,
            b13Month4  : res.data.b13Month4,
            b13Month5  : res.data.b13Month5,
            b13Month6  : res.data.b13Month6,
            b13Month7  : res.data.b13Month7,
            b13Month8  : res.data.b13Month8,
            b13Month9  : res.data.b13Month9,
            b13Month10 : res.data.b13Month10,
            b13Month11 : res.data.b13Month11,
            b13Month12 : res.data.b13Month12,
            b13Year    : res.data.b13Year,
            b13        : res.data.b13,
            b14        : res.data.b14,
            b15        : res.data.b15,
            b16        : res.data.b16,
            b17        : res.data.b17,
            b18Month   : res.data.b18Month,
            b18Year    : res.data.b18Year,
          }
          res.data.bagC = {
            c14 : res.data.c01Column4,
            c15 : res.data.c01Column5,
            c16 : res.data.c01Column6,
            c24 : res.data.c02Column4,
            c25 : res.data.c02Column5,
            c26 : res.data.c02Column6,
            c34 : res.data.c03Column4,
            c35 : res.data.c03Column5,
            c36 : res.data.c03Column6,
            c44 : res.data.c04Column4,
            c45 : res.data.c04Column5,
            c46 : res.data.c04Column6,
            c54 : res.data.c05Column4,
            c55 : res.data.c05Column5,
            c56 : res.data.c05Column6,
          }
          res.data.bagD = {
            d01       : res.data.d01,
            d01Lembar : res.data.d01Lembar,
            d02       : res.data.d02,
            d02Lembar : res.data.d02Lembar,
            d03       : res.data.d03,
            d03Lembar : res.data.d03Lembar,
            d04       : res.data.d04,
            d04Lembar : res.data.d04Lembar,
            d05       : res.data.d05,
            d05Lembar : res.data.d05Lembar,
            d06       : res.data.d06,
            d07       : res.data.d07,
            d07Lembar : res.data.d07Lembar,
            d08       : res.data.d08,
          }
          res.data.bagE = {
            e0102 : res.data.e0102,
            e03   : res.data.e03,
            e04   : res.data.e04,
            e05   : res.data.e05,
            e06   : res.data.e06,
          }
        }
        this.props.initialize(res.data);
      }
    } catch(e) {

    }


    this.setState({calculate: false})
    await this.props.dispatch({
      type: 'TOGGLE_GLOBAL_PROGRESS',
      data: false
    });
  }

  async calculateTemp() {
    await this.setState({calculate: true})
    await this.props.dispatch({
      type: 'TOGGLE_GLOBAL_PROGRESS',
      data: true
    });

    const sptId = this.props.match.params.sptId

    try {
      var res = await this.service.api.calculateTemp(sptId)

      this.initData()
    } catch(e) {

    }

    this.setState({calculate: false})
    await this.props.dispatch({
      type: 'TOGGLE_GLOBAL_PROGRESS',
      data: false
    });
  }

  async changeSigner() {
    if(this.state.signer.id) {
      let formData                                                     = this.initialData
      if  (this.props.formData && this.props.formData.values) formData = this.props.formData.values
      if(formData.bagE.e0102) {
        this.props.change('bagE.e03', this.state.signer.npwpPenandaTanganSptInduk);
        this.props.change('bagE.e04', this.state.signer.namaPenandaTanganSptInduk);
      } else {
        this.props.change('bagE.e03', this.state.signer.npwpKuasa);
        this.props.change('bagE.e04', this.state.signer.namaKuasa);
      }
    }
  }


  async handleCetak(){
    this.setState({ cetak: true })
    try {
      const sptId    = this.props.match.params.sptId
      var   report   = await this.service.api.cetak(sptId)
      window.open(report.data.url)
      this.setState({ cetak: false })
    } catch (e){
      this.setState({ cetak: false })
    }
  }

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;
    let   statusSPT                                     = "";
    let   statusInSPT                                   = true;
    if(this.props.spt){
        if(this.props.spt.data){
            if(this.props.spt.data.status){
                statusSPT   = this.props.spt.data.status;
                statusInSPT = true
            }
        }
    }

    var isSupport = false
    if(this.props.user && this.props.user.role && this.props.user.role.name == "SUPPORT_AGENT") isSupport = true

    if(statusSPT == "FINISH" || statusSPT == "WAITING"){
        return (
            <div>
              <Button disabled={this.state.cetak} primary raised style={{marginRight: 5}} onClick={this.handleCetak.bind(this)}>Cetak</Button>

              {isSupport &&
                <Button disabled={this.state.calculate} primary raised style={{marginLeft: 5}} onClick={this.calculateTemp.bind(this)}>Calculate Temp</Button>
              }
            </div>
        )
    } else {
        if(statusInSPT){
            return (
              <div>
                <Button disabled={this.state.cetak} primary raised style={{marginRight: 5}} onClick={this.handleCetak.bind(this)}>Cetak</Button>
                {/* <Button disabled={this.state.calculate} primary raised style={{marginRight: 5}} onClick={this.calculate.bind(this)}>calculate</Button> */}

                <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Save and Calculate</Button>

                {isSupport &&
                  <Button disabled={this.state.calculate} primary raised style={{marginLeft: 5}} onClick={this.calculateTemp.bind(this)}>Calculate Temp</Button>
                }
              </div>
            )
        } else {
            return (
                <div>
                    <Button disabled={this.state.calculate} primary raised style={{marginRight: 5}} onClick={this.calculate.bind(this)}>calculate</Button>

                    {isSupport &&
                      <Button disabled={this.state.calculate} primary raised style={{marginRight: 5}} onClick={this.calculateTemp.bind(this)}>Calculate Temp</Button>
                    }

                    <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>Back</Button>
                </div>
            )
        }
    }
  }

  formView() {
    let formData                                                     = this.initialData
    let spt = this.props.spt.data
    if  (this.props.formData && this.props.formData.values) formData = this.props.formData.values

    var masaOptions = [
      {id:1, name: 1}, {id:2, name: 2}, {id:3, name: 3}, {id:4, name: 4}, {id:5, name: 5}, {id:6, name: 6},
      {id:7, name: 7}, {id:8, name: 8}, {id:9, name: 9}, {id:10, name: 10}, {id:11, name: 11}, {id:12, name: 12}
    ]

    let no18Disabled = true

    if (formData && spt) {
      if (spt.rev === 0 && formData.bagB2.b15 < 0) {
        no18Disabled = false
      }

      if (spt.rev > 0 && formData.bagB2.b17 < 0) {
        no18Disabled = false
      }
    }

    console.log(formData)

    return (
      <div>
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='A. IDENTITAS PEMOTONG' />
          <Divider/>

          <div className='md-grid'>
            <div className="md-cell md-cell--6">
              <Field
                label      = 'NPWP'
                name       = 'bagA.a01'
                id         = "BP21A1Form-npwp"
                component  = {TextfieldMask}
                maskFormat = "##.###.###.#-###-###"
                validate   = {validation.required}
                disabled
              />
              <Field
                label     = 'Nama'
                name      = 'bagA.a02'
                id        = "BP21A1Form-nama"
                component = {Textfield}
                validate  = {validation.required}
                disabled
              />
            </div>

            <div className="md-cell md-cell--6">
              <Field
                label     = 'No. Telp'
                name      = 'bagA.a04'
                component = {Textfield}
                validate  = {validation.required}
                disabled
              />
              <Field
                label     = 'Email'
                name      = 'bagA.a05'
                component = {Textfield}
                validate  = {validation.required}
                disabled
              />
            </div>

            <div className="md-cell md-cell--12">
              <Field
                label     = 'Alamat'
                name      = 'bagA.a03'
                id        = "BP21A1Form-alamat"
                component = {Textfield}
                validate  = {validation.required}
                disabled
              />
            </div>
          </div>
        </Card>
        <br/>

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='B. OBJEK PAJAK' />
          <Divider/>
          <DataTable plain tableStyle={{minWidth: 1000}}>
            <TableHeader>
              <TableRow>
                <TableColumn className='md-text-center' style={{width: 5}}>NO</TableColumn>
                <TableColumn className='md-text-center'>PENERIMA PENGHASILAN</TableColumn>
                <TableColumn className='md-text-center' style={{width: 130}}>KODE</TableColumn>
                <TableColumn className='md-text-center'>JUMLAH PENERIMA</TableColumn>
                <TableColumn className='md-text-center'>JUMLAH PENGHASILAN BRUTO</TableColumn>
                <TableColumn className='md-text-center'>JUMLAH PAJAK DIPOTONG</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableColumn className='md-text-center' style={{height: 30}}>(1)</TableColumn>
                <TableColumn className='md-text-center' style={{height: 30}}>(2)</TableColumn>
                <TableColumn className='md-text-center' style={{height: 30}}>(3)</TableColumn>
                <TableColumn className='md-text-center' style={{height: 30}}>(4)</TableColumn>
                <TableColumn className='md-text-center' style={{height: 30}}>(5)</TableColumn>
                <TableColumn className='md-text-center' style={{height: 30}}>(6)</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>1.</TableColumn>
                <TableColumn>PEGAWAI TETAP</TableColumn>
                <TableColumn className='md-text-center'>21-100-01</TableColumn>
                <TableColumn className='md-text-right'>{formData.bagB1.b14}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b15)}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b16)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>2.</TableColumn>
                <TableColumn>PENERIMA PENSIUN BERKALA</TableColumn>
                <TableColumn className='md-text-center'>21-100-02</TableColumn>
                <TableColumn className='md-text-right'>{formData.bagB1.b24}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b25)}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b26)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>3.</TableColumn>
                <TableColumn>PEGAWAI TIDAK TETAP ATAU TENAGA KERJA LEPAS</TableColumn>
                <TableColumn className='md-text-center'>21-100-03</TableColumn>
                <TableColumn className='md-text-right'>{formData.bagB1.b34}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b35)}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b36)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>4.</TableColumn>
                <TableColumn>BUKAN PEGAWAI: </TableColumn>
                <TableColumn style={{background: '#DDD'}}></TableColumn>
                <TableColumn style={{background: '#DDD'}}></TableColumn>
                <TableColumn style={{background: '#DDD'}}></TableColumn>
                <TableColumn style={{background: '#DDD'}}></TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>4a. DISTRIBUTOR MULTILEVEL MARKETING (MLM)</TableColumn>
                <TableColumn className='md-text-center'>21-100-04</TableColumn>
                <TableColumn className='md-text-right'>{formData.bagB1.b4a4}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b4a5)}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b4a6)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>4b. PETUGAS DINAS LUAR ASURANSI</TableColumn>
                <TableColumn className='md-text-center'>21-100-05</TableColumn>
                <TableColumn className='md-text-right'>{formData.bagB1.b4b4}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b4b5)}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b4b6)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>4c. PENJAJA BARANG DAGANGAN</TableColumn>
                <TableColumn className='md-text-center'>21-100-06</TableColumn>
                <TableColumn className='md-text-right'>{formData.bagB1.b4c4}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b4c5)}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b4c6)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>4d. TENAGA AHLI</TableColumn>
                <TableColumn className='md-text-center'>21-100-07</TableColumn>
                <TableColumn className='md-text-right'>{formData.bagB1.b4d4}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b4d5)}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b4d6)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>4e. BUKAN PEGAWAI YANG MENERIMA IMBALAN YANG BERSIFAT BERKESINAMBUNGAN</TableColumn>
                <TableColumn className='md-text-center'>21-100-08</TableColumn>
                <TableColumn className='md-text-right'>{formData.bagB1.b4e4}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b4e5)}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b4e6)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>4f. BUKAN PEGAWAI YANG MENERIMA IMBALAN YANG TIDAK BERSIFAT BERKESINAMBUNGAN</TableColumn>
                <TableColumn className='md-text-center'>21-100-09</TableColumn>
                <TableColumn className='md-text-right'>{formData.bagB1.b4f4}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b4f5)}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b4f6)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>5.</TableColumn>
                <TableColumn>ANGGOTA DEWAN KOMISARIS ATAU DEWAN PENGAWAS YANG TIDAK MERANGKAP SEBAGAI PEGAWAI TETAP</TableColumn>
                <TableColumn className='md-text-center'>21-100-10</TableColumn>
                <TableColumn className='md-text-right'>{formData.bagB1.b54}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b55)}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b56)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>6.</TableColumn>
                <TableColumn>MANTAN PEGAWAI YANG MENERIMA JASA PRODUKSI,TANTIEM, BONUS ATAU IMBALAN LAIN</TableColumn>
                <TableColumn className='md-text-center'>21-100-11</TableColumn>
                <TableColumn className='md-text-right'>{formData.bagB1.b64}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b65)}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b66)}</TableColumn>
                </TableRow>
              <TableRow>
                <TableColumn>7.</TableColumn>
                <TableColumn>PEGAWAI YANG MELAKUKAN PENARIKAN DANA PENSIUN</TableColumn>
                <TableColumn className='md-text-center'>21-100-12</TableColumn>
                <TableColumn className='md-text-right'>{formData.bagB1.b74}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b75)}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b76)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>8.</TableColumn>
                <TableColumn>PESERTA KEGIATAN</TableColumn>
                <TableColumn className='md-text-center'>21-100-13</TableColumn>
                <TableColumn className='md-text-right'>{formData.bagB1.b84}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b85)}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b86)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>9.</TableColumn>
                <TableColumn>PENERIMA PENGHASILAN YANG DIPOTONG PPh PASAL 21 TIDAK FINAL LAINNYA</TableColumn>
                <TableColumn className='md-text-center'>21-100-99</TableColumn>
                <TableColumn className='md-text-right'>{formData.bagB1.b94}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b95)}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b96)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>10.</TableColumn>
                <TableColumn>PEGAWAI/PEMBERI JASA/PESERTA KEGIATAN/PENERIMA PENSIUN BERKALA SEBAGAI WAJIB PAJAK LUAR NEGERI</TableColumn>
                <TableColumn className='md-text-center'>27-100-99</TableColumn>
                <TableColumn className='md-text-right'>{formData.bagB1.b104}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b105)}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b106)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>11.</TableColumn>
                <TableColumn>JUMLAH (PENJUMLAHAN ANGKA 1 S.D. 10)</TableColumn>
                <TableColumn style={{background: '#DDD'}}></TableColumn>
                <TableColumn className='md-text-right'>{formData.bagB1.b114}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b115)}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB1.b116)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn style={{background: '#DDD'}}></TableColumn>
                <TableColumn style={{background: '#DDD'}} colSpan={4}>PENGHITUNGAN PPh PASAL 21 DAN/ATAU PASAL 26 YANG KURANG (LEBIH) DISETOR</TableColumn>
                <TableColumn style={{background: '#DDD'}}>JUMLAH (Rp)</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>12.</TableColumn>
                <TableColumn colSpan={4}>STP PPh PASAL 21 DAN/ATAU PASAL 26 (HANYA POKOK PAJAK)</TableColumn>
                <TableColumn className='md-text-right'>
                  <Field
                    // label='Bruto'
                    name      = 'bagB2.b12'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    money     = {','}
                    onChange  = {async (e, v)=> {
                      var bag13 = formData.bagB2.b13;
                      if(!bag13) bag13 = 0;
                      if(!v) v=0;
                      await this.props.change('bagB2.b14', parseFloat(v)+parseFloat(bag13));

                      var b14 = parseFloat(v)+parseFloat(bag13);
                      var b15 = parseFloat(formData.bagB1.b116) - b14;

                      await this.props.change('bagB2.b15', b15)

                      if (spt.rev > 0) {
                        await this.props.change('bagB2.b17', b15 - formData.bagB2.b16)
                      }
                    }}
                    />
                    {/* {formData.bagB2.b12} */}
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>13.</TableColumn>
                <TableColumn colSpan={4}>
                  <p>KELEBIHAN PENYETORAN PPh PASAL 21 DAN/ATAU PASAL 26 DARI: </p>
                  <div>
                    <Field
                      label     = '01'
                      name      = 'bagB2.b13Month1'
                      style     = {{float: "left"}}
                      component = {Checkbox}
                    />
                    <Field
                      label     = '02'
                      name      = 'bagB2.b13Month2'
                      style     = {{float: "left"}}
                      component = {Checkbox}
                    />
                    <Field
                      label     = '03'
                      name      = 'bagB2.b13Month3'
                      style     = {{float: "left"}}
                      component = {Checkbox}
                    />
                    <Field
                      label     = '04'
                      name      = 'bagB2.b13Month4'
                      style     = {{float: "left"}}
                      component = {Checkbox}
                    />
                    <Field
                      label     = '05'
                      name      = 'bagB2.b13Month5'
                      style     = {{float: "left"}}
                      component = {Checkbox}
                    />
                    <Field
                      label     = '06'
                      name      = 'bagB2.b13Month6'
                      style     = {{float: "left"}}
                      component = {Checkbox}
                    />
                    <Field
                      label     = '07'
                      name      = 'bagB2.b13Month7'
                      style     = {{float: "left"}}
                      component = {Checkbox}
                    />
                    <Field
                      label     = '08'
                      name      = 'bagB2.b13Month8'
                      style     = {{float: "left"}}
                      component = {Checkbox}
                    />
                    <Field
                      label     = '09'
                      name      = 'bagB2.b13Month9'
                      style     = {{float: "left"}}
                      component = {Checkbox}
                    />
                    <Field
                      label     = '10'
                      name      = 'bagB2.b13Month10'
                      style     = {{float: "left"}}
                      component = {Checkbox}
                    />
                    <Field
                      label     = '11'
                      name      = 'bagB2.b13Month11'
                      style     = {{float: "left"}}
                      component = {Checkbox}
                    />
                    <Field
                      label     = '12'
                      name      = 'bagB2.b13Month12'
                      style     = {{float: "left"}}
                      component = {Checkbox}
                    />

                    <Field
                      label      = 'Tahun'
                      name       = 'bagB2.b13Year'
                      style      = {{float: "left"}}
                      component  = {TextfieldMask}
                      maskFormat = "####"
                      mask       = "Y"
                    />
                  </div>
                </TableColumn>
                <TableColumn className='md-text-right'>
                  <Field
                    // label='Bruto'
                    name      = 'bagB2.b13'
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                    onChange  = {async (e, v)=> {
                      var bag12 = formData.bagB2.b12;
                      if(!bag12) bag12 = 0;
                      if(!v) v=0;
                      await this.props.change('bagB2.b14', parseFloat(v)+parseFloat(bag12))

                      var b14 = parseFloat(v)+parseFloat(bag12);
                      var b15 = parseFloat(formData.bagB1.b116) - b14;
                      await this.props.change('bagB2.b15', b15)

                      await this.props.change('bagB2.b17', b15 - formData.bagB2.b16)
                    }}
                    />
                  {/* {formData.bagB2.b13} */}
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>14.</TableColumn>
                <TableColumn colSpan={4}>JUMLAH (ANGKA 12 + ANGKA 13)</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB2.b14)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>15.</TableColumn>
                <TableColumn colSpan={4}>PPh PASAL 21 DAN/ATAU PASAL 26 YANG KURANG (LEBIH) DISETOR (ANGKA 11 KOLOM 6 - ANGKA 14)</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB2.b15)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn colSpan={4}>LANJUTKAN PENGISIAN PADA ANGKA 16 & 17 APABILA SPT PEMBETULAN DAN/ATAU PADA ANGKA 18 APABILA LEBIH DISETOR PPh</TableColumn>
                <TableColumn style={{background: '#DDD'}}></TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>16.</TableColumn>
                <TableColumn colSpan={4}>PPh PASAL 21 DAN/ATAU PASAL 26 YANG KURANG (LEBIH) DISETOR PADA SPT YANG DIBETULKAN (PINDAHAN DARI BAGIAN B ANGKA 15 DARI SPT YANG DIBETULKAN)</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB2.b16)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>17.</TableColumn>
                <TableColumn colSpan={4}>PPh PASAL 21 DAN/ATAU PASAL 26 YANG KURANG (LEBIH) DISETOR KARENA PEMBETULAN (ANGKA 15 - ANGKA 16)</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagB2.b17)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>18.</TableColumn>
                <TableColumn colSpan={4}>KELEBIHAN SETOR PADA ANGKA 15 ATAU ANGKA 17 AKAN DIKOMPENSASIKAN KE MASA PAJAK (mm - yyyy)</TableColumn>
                <TableColumn className='md-text-right'>
                  <Field
                    label     ='Bulan'
                    disabled  = {no18Disabled}
                    name      = 'bagB2.b18Month'

                    component = {Textfield}
                    options   = {[
                      {id: 1, name: 1},
                      {id: 2, name: 2},
                      {id: 3, name: 3},
                      {id: 4, name: 4},
                      {id: 5, name: 5},
                      {id: 6, name: 6},
                      {id: 7, name: 7},
                      {id: 8, name: 8},
                      {id: 9, name: 9},
                      {id: 10, name: 10},
                      {id: 11, name: 11},
                      {id: 12, name: 12},
                    ]}
                  />

                  <Field
                    label     ='Tahun'
                    disabled  = {no18Disabled}
                    name      = 'bagB2.b18Year'

                    component = {TextfieldMask}
                  />
                </TableColumn>
              </TableRow>
            </TableBody>
          </DataTable>
        </Card>

        <br/>

        {/* <Card>
          <div className='md-grid'>
            <Field
              label      = 'NPWP Pemotong'
              name       = 'referensi'
              id         = "BP21A1Form-referensi"
              className  = "md-cell md-cell--12"
              component  = {TextfieldMask}
              maskFormat = "##.###.###.#-###-###"
              validate   = {validation.required}
            />
          </div>
        </Card> */}

        <br />

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='C.  OBJEK PAJAK FINAL' />
          <Divider/>
          <DataTable plain tableStyle={{minWidth: 1000}}>
            <TableHeader>
              <TableRow>
                <TableColumn className='md-text-center' style={{width: 5}}>NO</TableColumn>
                <TableColumn className='md-text-center'>PENERIMA PENGHASILAN</TableColumn>
                <TableColumn className='md-text-center' style={{width: 130}}>KODE</TableColumn>
                <TableColumn className='md-text-center'>JUMLAH PENERIMA</TableColumn>
                <TableColumn className='md-text-center'>JUMLAH PENGHASILAN BRUTO</TableColumn>
                <TableColumn className='md-text-center'>JUMLAH PAJAK DIPOTONG</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableColumn>1.</TableColumn>
                <TableColumn>PENERIMA UANG PESANGON YANG DIBAYARKAN SEKALIGUS</TableColumn>
                <TableColumn className='md-text-center'>21-401-01</TableColumn>
                <TableColumn className='md-text-right'>{formData.bagC.c14}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagC.c15)}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagC.c16)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>2.</TableColumn>
                <TableColumn>PENERIMA UANG MANFAAT PENSIUN, TUNJANGAN HARI TUA ATAU JAMINAN HARI TUA DAN PEMBAYARAN SEJENIS YANG DIBAYARKAN SEKALIGUS</TableColumn>
                <TableColumn className='md-text-center'>21-401-02</TableColumn>
                <TableColumn className='md-text-right'>{formData.bagC.c24}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagC.c25)}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagC.c26)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>3.</TableColumn>
                <TableColumn>PEJABAT NEGARA, PEGAWAI NEGERI SIPIL, ANGGOTA TNI/POLRI DAN PENSIUNAN YANG MENERIMA HONORARIUM DAN IMBALAN LAIN YANG DIBEBANKAN KEPADA KEUANGAN NEGARA/DAERAH</TableColumn>
                <TableColumn className='md-text-center'>21-402-01</TableColumn>
                <TableColumn className='md-text-right'>{formData.bagC.c34}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagC.c35)}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagC.c36)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>4.</TableColumn>
                <TableColumn>PENERIMA PENGHASILAN YANG DIPOTONG PPh PASAL 21 FINAL LAINNYA</TableColumn>
                <TableColumn className='md-text-center'>21-499-99</TableColumn>
                <TableColumn className='md-text-right'>{formData.bagC.c44}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagC.c45)}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagC.c46)}</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>5.</TableColumn>
                <TableColumn><b>JUMLAH BAGIAN C</b> (PENJUMLAHAN ANGKA 1 S.D. 5)</TableColumn>
                <TableColumn style={{background: '#DDD'}}></TableColumn>
                <TableColumn className='md-text-right'>{formData.bagC.c54}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagC.c55)}</TableColumn>
                <TableColumn className='md-text-right'>{columnService.money(formData.bagC.c56)}</TableColumn>
              </TableRow>
            </TableBody>
          </DataTable>
        </Card>

        <br />

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='D.  LAMPIRAN' />
          <Divider/>
          <DataTable plain tableStyle={{minWidth: 1000}}>
            <TableBody>
              <TableRow>
                <TableColumn>
                  <Field
                    label     = ''
                    name      = 'bagD.d01'
                    component = {Checkbox}
                  />
                </TableColumn>
                <TableColumn>1. FORMULIR 1721 - I (untuk Satu Masa Pajak)</TableColumn>
                <TableColumn>
                  <Field
                    label     = ''
                    name      = 'bagD.d01Lembar'
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>LEMBAR</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>
                  <Field
                    label     = ''
                    name      = 'bagD.d02'
                    component = {Checkbox}
                  />
                </TableColumn>
                <TableColumn>2. FORMULIR - I (untuk Satu Tahun Pajak)</TableColumn>
                <TableColumn>
                  <Field
                    label     = ''
                    name      = 'bagD.d02Lembar'
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>LEMBAR</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>
                  <Field
                    label     = ''
                    name      = 'bagD.d03'
                    component = {Checkbox}
                  />
                </TableColumn>
                <TableColumn>3. FORMULIR 1721 - II</TableColumn>
                <TableColumn>
                  <Field
                    label     = ''
                    name      = 'bagD.d03Lembar'
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>LEMBAR</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>
                  <Field
                    label     = ''
                    name      = 'bagD.d04'
                    component = {Checkbox}
                  />
                </TableColumn>
                <TableColumn>4. FORMULIR 1721 - III</TableColumn>
                <TableColumn>
                  <Field
                    label     = ''
                    name      = 'bagD.d04Lembar'
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>LEMBAR</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>
                  <Field
                    label     = ''
                    name      = 'bagD.d05'
                    component = {Checkbox}
                  />
                </TableColumn>
                <TableColumn>5. FORMULIR 1721 - IV</TableColumn>
                <TableColumn>
                  <Field
                    label     = ''
                    name      = 'bagD.d05Lembar'
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>LEMBAR</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>
                  <Field
                    label     = ''
                    name      = 'bagD.d06'
                    component = {Checkbox}
                  />
                </TableColumn>
                <TableColumn>6. FORMULIR 1721 - V</TableColumn>
                <TableColumn>
                  {/*<Field
                      label     = ''
                      name      = 'bagD.d06Lembar'
                      component = {Textfield}
                    />*/}
                </TableColumn>
                <TableColumn>
                  {/*LEMBAR*/}
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>
                  <Field
                    label     = ''
                    name      = 'bagD.d07'
                    component = {Checkbox}
                  />
                </TableColumn>
                <TableColumn>7. SURAT SETORAN PAJAK (SSP) DAN/ATAU BUKTI PEMINDAHBUKUAN (Pbk)</TableColumn>
                <TableColumn>
                  <Field
                    label     = ''
                    name      = 'bagD.d07Lembar'
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>LEMBAR</TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>
                  <Field
                    label     = ''
                    name      = 'bagD.d08'
                    component = {Checkbox}
                    onChange  = {async (ev, v)=> {
                      await this.props.change('bagE.e0102', !v);

                      await this.changeSigner()
                    }}
                  />
                </TableColumn>
                <TableColumn>8. SURAT KUASA KHUSUS</TableColumn>
                <TableColumn>
                  {/* <Field
                    label     = ''
                    name      = 'bagD.d08'
                    component = {Textfield}
                  /> */}
                  </TableColumn>
                <TableColumn>
                  {/*LEMBAR*/}
                </TableColumn>
              </TableRow>
            </TableBody>
          </DataTable>
        </Card>

        <br />

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='E.  PERNYATAAN DAN TANDA TANGAN PEMOTONG' />
          <Divider/>
          <div className='md-grid'>
            <div className="md-cell md-cell--12">
              <p style={{textAlign: 'center'}}>Dengan menyadari sepenuhnya atas segala akibatnya termasuk sanksi-sanksi sesuai dengan ketentuan yang berlaku, saya menyatakan bahwa apa yang telah saya beritahukan di atas beserta lampiran-lampirannya adalah benar, lengkap dan jelas.</p>
            </div>
          </div>
          <div className='md-grid'>
            <Field
              className    = "md-cell md-cell--12"
              label        = 'Tanggal Pernyataan Pemotong'
              name         = 'bagE.e05'
              component    = {Datepicker}
              validate     = {validation.required}
              // onDateChange = { async (d)=> {
              //     let signer = null;

              //     try {
              //       signer = await this.service.api.getSigner({
              //         orgId: this.props.spt.data.organization.id,
              //         tgl  : moment(d).format("YYYY-MM-DD")
              //       })

              //       await this.setState({signer: signer})
              //     } catch (error) {
              //     }

              //     await this.changeSigner()
              //   }}
              />
              <Field
                className = "md-cell md-cell--12"
                label     = 'Jenis Penandatangan'
                name      = 'bagE.e0102'
                component = {Searchfield}
                options   = {[
                  {id: true, name: 'Pemotong'},
                  {id: false, name: 'Kuasa'}
                ]}
                onChange={async (e, v)=> {
                  await this.props.change('bagE.e0102', v);

                  await this.changeSigner()
                }}
                //validate = {validation.required}
              />

            <div className="md-cell md-cell--6">
              <Field
                label      = 'NPWP Pernyataan Pemotong'
                name       = 'bagE.e03'
                component  = {TextfieldMask}
                maskFormat = "##.###.###.#-###-###"
                validate   = {validation.required}
                // disabled
              />
              <Field
                label     = 'Nama Pernyataan Pemotong'
                name      = 'bagE.e04'
                component = {Textfield}
                validate  = {validation.required}
                // disabled
              />
            </div>
            <div className="md-cell md-cell--6">
              <Field
                label     = 'Tempat Pernyataan Pemotong'
                name      = 'bagE.e06'
                component = {Textfield}
                validate  = {validation.required}
                // disabled
              />
            </div>
          </div>
        </Card>
        <br />
      </div>
    )
  }
}
