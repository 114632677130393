import React, { Component } from 'react';
import { connect } from 'react-redux';

import ExportCsv21Service from './ExportCsv21.service';
import ListView from '../../../../../components/entity/listView';
import ColumnService from '../../../../../services/column.service';
import TextField, { Button, Divider, Chip, Card, CardTitle, CardActions, DataTable, TableHeader, TableBody, TableRow, TableColumn, Switch, Paper } from 'react-md';
import {validation, Searchfield, TextfieldMask, Datepicker} from '../../../../../components/form';
import { Field, reduxForm } from 'redux-form';
import download from 'downloadjs';
import uuid from 'uuid';
import iziToast from 'izitoast';
import { Commandbar, Table, TableSetting, Empty, DialogConfirm } from 'react-mpk';
import http from '../../../../../services/http.service'
import BP_21_TFService from '../BP_21_TF/BP_21_TF.service';
import BP_21_FService from '../BP_21_F/BP_21_F.service';
import BP_21_A1Service from '../BP_21_A1/BP_21_A1.service';
import BP_21_A2Service from '../BP_21_A2/BP_21_A2.service';
import BP_21_SATU_MASAService from '../BP_21_SATU_MASA/BP_21_SATU_MASA.service';
import BP_21_SSPService from '../BP_21_SSP/BP_21_SSP.service';
import BP_21_DAFTAR_BIAYAService from '../BP_21_DAFTAR_BIAYA/BP_21_DAFTAR_BIAYA.service';
import SPTINDUK21Service from '../SPT_INDUK_21/SPT_INDUK_21.service';

@reduxForm({form: 'EXPORT_CSV_21', destroyOnUnmount: true})
@connect((state)=> ({
  ...ExportCsv21Service.stateConnectSetting()(state),
  spt: state.authEppt.currentSPT
}), ExportCsv21Service.actionConnectSetting())
export default class FileLapor21View extends ListView {
  service=ExportCsv21Service

  constructor(props){
    super(props);
    this.state = {
      showTableSetting:false,
      showDialogConfirmDeleteSelected:false,
      showForm:false,
      formData: {},
      data: [],
      cetak: false,
      showDialog: false,
      category: null,
      totalTF: 0,
      totalF: 0,
      totalA1: 0,
      totalA2: 0,
      totalSM: 0,
      totalSMTL: 1,
      totalSSP: 0,
      totalDaftarBiaya: 1,
      totalInduk: 1,
    }
  }

  columns=[]
  _barActions=[]
  _barItem() {}

  handleExport(category){
      this.setState({ showDialog: true })
      this.setState({ category: category })
  }

  async export(){
    var sptId = this.props.spt.data.id
    var category = this.state.category

    var csv = {};
    try {
      switch (category) {
        case 'BP_21_TF':
          csv = await BP_21_TFService.api.downloadCsv(sptId);
          break;
        case 'BP_21_F':
          csv = await BP_21_FService.api.downloadCsv(sptId);
          break;
        case 'BP_21_A1':
          csv = await BP_21_A1Service.api.downloadCsv(sptId);
          break;
        case 'BP_21_A2':
          csv = await BP_21_A2Service.api.downloadCsv(sptId);
          break;
        case 'BP_21_SM':
          csv = await BP_21_SATU_MASAService.api.downloadCsv(sptId);
          break;
        case 'BP_21_SM_TL':
          csv = await BP_21_SATU_MASAService.api.downloadCsv(sptId);
          break;
        case 'BP_21_DAFTAR_BIAYA':
          csv = await BP_21_DAFTAR_BIAYAService.api.downloadCsv(sptId);
          break;
        case 'BP_21_SSP':
          csv = await BP_21_SSPService.api.downloadCsv(sptId);
          break;
        case 'BP_21_INDUK':
          csv = await SPTINDUK21Service.api.downloadCsv(sptId);
          break;
        default:
          break;
      }
      window.open(csv.data.url)
    } catch (error) {
      console.log(error)
    }
  }

  async componentDidMount(){
    var sptId = this.props.spt.data.id
    let info = await this.service.api.getInfo(sptId)

    var res = await this.service.api.getSummary(sptId);
    this.setState({formData : res.data});
    this.props.initialize(res.data)
    // let totalF = await this.service.api.getFTotal(sptId)
    // let totalA1 = await this.service.api.getA1Total(sptId)
    // let totalA2 = await this.service.api.getA2Total(sptId)

    if(info.data) {
      this.setState({
        totalF: info.data.f,
        totalTF: info.data.tf,
        totalA1: info.data.a1,
        totalA2: info.data.a2,
        totalSM: info.data.sm1,
        totalSSP: info.data.ssp
      })
    }

  }

  view2() {
    return (
      <this.viewContainer>
        {this.belowTopBar()}
        {this.commandBar()}
        <div className="flex all mpk-content">
          {this.additionalTopContent()}
          <div style={{minHeight: 450}} className="mpk-layout">
            {this.tableView()}
            {this.tableSetting()}
          </div>

          {this.additionalContent()}

          <this.FormDialog
            height={400}
            visible={this.state.showForm}
            add={this.props.tableActions.addDataItem}
            formData={this.state.formData}
            onSuccess={()=> this.fetchData()}
            onClose={() => this.setState({showForm:false})}
            match={this.props.match}
          />

          {this.dialogConfirm()}
          {this.dialogConfirmDeleteFinishFailedSelected()}
        </div>
        {this.bottomBar()}
      </this.viewContainer>
    )
  }

  view1() {
    return (
      <this.viewContainer>
        {this.belowTopBar()}
        {this.commandBar()}
        <div className="flex all mpk-content">
          {this.additionalTopContent()}
          <div style={{minHeight: 450}} className="mpk-layout">
            {this.tableView()}
            {this.tableSetting()}
          </div>

          {this.additionalContent()}

          <this.FormDialog
            height={400}
            visible={this.state.showForm}
            add={this.props.tableActions.addDataItem}
            formData={this.state.formData}
            onSuccess={()=> this.fetchData()}
            onClose={() => this.setState({showForm:false})}
            match={this.props.match}
          />

          {this.dialogConfirm()}
          {this.dialogConfirmDeleteFinishFailedSelected()}
        </div>
        {this.bottomBar()}
      </this.viewContainer>
    )
  }

  _tableView(props) {
      const _this = this
      return (
        <div className="mpk-layout column fill">
          <div className="flex all mpk-content mpk-padding-N">
          <Card>
            <CardTitle title='' style={{padding: '10px 16px'}} subtitle='Informasi File Lapor'/>
            <Divider/>
            <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
              <TableHeader>
                <TableRow>
                  <TableColumn></TableColumn>
                  <TableColumn>PPh Terutang</TableColumn>
                  <TableColumn>SSP/Pbk Disetor</TableColumn>
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow>
                  <TableColumn>
                    PPh Tidak Final (KJS : 100)
                  </TableColumn>
                  <TableColumn>
                    { ColumnService.money(this.state.formData.pphTF100)  }
                  </TableColumn>
                  <TableColumn>
                    { ColumnService.money(this.state.formData.sspTF100)  }
                  </TableColumn>
                </TableRow>
                <TableRow>
                  <TableColumn>
                    PPh Tidak Final (KJS : 104)
                  </TableColumn>
                  <TableColumn>
                    { ColumnService.money(this.state.formData.pphTF104)  }
                  </TableColumn>
                  <TableColumn>
                    { ColumnService.money(this.state.formData.sspTF104)  }
                  </TableColumn>
                </TableRow>
                <TableRow>
                  <TableColumn>
                    PPh Final (KJS : 401)
                  </TableColumn>
                  <TableColumn>
                    { ColumnService.money(this.state.formData.pphF401)  }
                  </TableColumn>
                  <TableColumn>
                    { ColumnService.money(this.state.formData.sspF401)  }
                  </TableColumn>
                </TableRow>
                <TableRow>
                  <TableColumn>
                    PPh Final (KJS : 402)
                  </TableColumn>
                  <TableColumn>
                    { ColumnService.money(this.state.formData.pphF402)  }
                  </TableColumn>
                  <TableColumn>
                    { ColumnService.money(this.state.formData.sspF402)  }
                  </TableColumn>
                </TableRow>
                <TableRow>
                  <TableColumn>
                    Total
                  </TableColumn>
                  <TableColumn>
                    { ColumnService.money(this.state.formData.totalTerutang)  }
                  </TableColumn>
                  <TableColumn>
                    { ColumnService.money(this.state.formData.totalSetor)  }
                  </TableColumn>
                </TableRow>
              </TableBody>
            </DataTable>
          </Card>
          <br />
          <Card>
            <DialogConfirm
                title={'word.export'}
                message={'sentence.custom.export'}
                visible={this.state.showDialog}
                onSubmit={(callback) => {
                    _this.export()
                    callback();
                }}
                onCancel={() => this.setState({showDialog:false})}
                translate={true}
            />
            <CardTitle title='' style={{padding: '10px 16px'}} subtitle='Informasi File Export'/>
            <Divider/>
            <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
              <TableHeader>
                <TableRow>
                    <TableColumn>Jumlah Data</TableColumn>
                    <TableColumn></TableColumn>
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow>
                    <TableColumn>{this.state.totalTF} Unit</TableColumn>
                    <TableColumn>
                        <Button disabled={this.state.totalTF == 0} onClick={this.handleExport.bind(this, 'BP_21_TF')} style={{width: '100%'}} primary raised>Export Data BP Tidak Final</Button>
                    </TableColumn>
                </TableRow>
                <TableRow>
                    <TableColumn>{this.state.totalF} Unit</TableColumn>
                    <TableColumn>
                        <Button disabled={this.state.totalF == 0} onClick={this.handleExport.bind(this, 'BP_21_F')} style={{width: '100%'}} primary raised>Export Data BP Final</Button>
                    </TableColumn>
                </TableRow>
                <TableRow>
                    <TableColumn>{this.state.totalA1} Unit</TableColumn>
                    <TableColumn>
                        <Button disabled={this.state.totalA1 == 0} onClick={this.handleExport.bind(this, 'BP_21_A1')} style={{width: '100%'}} primary raised>Export Data BP A1</Button>
                    </TableColumn>
                </TableRow>
                {/* <TableRow>
                    <TableColumn>{this.state.totalA2} Unit</TableColumn>
                    <TableColumn>
                        <Button disabled={this.state.totalA2 == 0} onClick={this.handleExport.bind(this, 'BP_21_A2')} style={{width: '100%'}} primary raised>Export Data BP A2</Button>
                    </TableColumn>
                </TableRow> */}
                <TableRow>
                    <TableColumn>{this.state.totalSM} Unit</TableColumn>
                    <TableColumn>
                        <Button disabled={this.state.totalSM == 0} onClick={this.handleExport.bind(this, 'BP_21_SM')} style={{width: '100%'}} primary raised>Export Data BP Satu Masa</Button>
                    </TableColumn>
                </TableRow>

                <TableRow>
                  <TableColumn>{this.state.totalSMTL} Unit</TableColumn>
                  <TableColumn>
                    <Button disabled={this.state.totalSMTL == 0} onClick={this.handleExport.bind(this, 'BP_21_SM_TL')} style={{width: '100%'}} primary raised>Export Data BP Satu Masa Tidak Lebih</Button>
                  </TableColumn>
                </TableRow>

                <TableRow>
                  <TableColumn>{this.state.totalDaftarBiaya} Unit</TableColumn>
                  <TableColumn>
                    <Button disabled={this.state.totalDaftarBiaya == 0} onClick={this.handleExport.bind(this, 'BP_21_DAFTAR_BIAYA')} style={{width: '100%'}} primary raised>Export Data Daftar Biaya</Button>
                  </TableColumn>
                </TableRow>

                <TableRow>
                    <TableColumn>{this.state.totalSSP} Unit</TableColumn>
                    <TableColumn>
                        <Button disabled={this.state.totalSSP == 0} onClick={this.handleExport.bind(this, 'BP_21_SSP')} style={{width: '100%'}} primary raised>Export Data SSP</Button>
                    </TableColumn>
                </TableRow>

                {/* <TableRow>
                  <TableColumn>{this.state.totalInduk} Unit</TableColumn>
                  <TableColumn>
                    <Button disabled={this.state.totalInduk == 0} onClick={this.handleExport.bind(this, 'BP_21_INDUK')} style={{width: '100%'}} primary raised>Export Data Induk</Button>
                  </TableColumn>
                </TableRow> */}
              </TableBody>
            </DataTable>
          </Card>
          </div>
        </div>
      )
  }
}
