import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Field, reduxForm } from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab, Chip } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../../components/form/index';

import ListView from '../../../../components/entity/listView';
import axios from 'axios';
import izitoast from 'izitoast';
import LogEfilingService from './LogEfiling.service';
import download from 'downloadjs';
import ParamService from "src/services/param.service";


@reduxForm({form: 'LogEfiling_Form', destroyOnUnmount: true})
@connect((state)=> ({
  ...LogEfilingService.stateConnectSetting()(state),
  user: state.authEppt.user,
  company: state.authEppt.currentCompany,
}), LogEfilingService.actionConnectSetting())
export default class LogEfilingView extends ListView {
  service=LogEfilingService
  FormDialog=()=> <div/>
  // viewType=2

  constructor(props){
    super(props);
    this.state = {
      showTableSetting:true,
      showDialogConfirmDeleteSelected:false,
      showForm:false,
      formData: {},
      data: [],
      onProgress: false,
      isError: false,
      errorMessage: ''
    }
  }

  beforeFetch(params) {
    // params["category.equals"] = "ORGANIZATION"
    // params['pasal.equals'] = this.props.match.params.pasal;
  }

  fetchOption() {
    let companyId;
    if(this.props.company) companyId = this.props.company.id;
    if(this.props.match.params.companyId && this.props.match.params.companyId != '') companyId = this.props.match.params.companyId;
    // if(companyId) {
    //   return {
    //     path: this.service.path+'/'+companyId+'/company'
    //   }

    // } else {
    //   return {
    //     path: this.service.path+'/'+this.props.match.params.pasal+'/organization'
    //   }
    // }

  }

  tableActions = (data) => {
    let divider = {
      label:"divider",
        iconClassName:"-"
    }

    let actions = []

    if(data.status === 'UPLOADED' || data.status === 'FAILED') {
      let checkStatusAction = {
        label:'Cek Status',
        iconClassName:'mdi mdi-shuffle',
        onClick: (item) => this.handleCheckStatus(item)
      }

      actions.push(checkStatusAction)

      /*let deleteAction = {
        label:"Delete",
        iconClassName:"mdi mdi-delete",
        onClick:(item, callback) => this.deleteItem(item, callback),
        confirmation:{
          title:"sentence.custom.deleteItem",
          message:"sentence.custom.deleteItemConfirmation"
        }
      }*/

      /*if (actions.length) {
        actions.push(divider)
      }

      actions.push(deleteAction)*/
    }

    return actions
  }

  async handleDownloadChecked() {

    if (this.props.table.selected.length > 0) {
      let selected = this.props.table.selected

      let datas = [];

      selected.map(function (i) {
        if (i.status === 'FINISHED') {
          datas.push(i.id)
        }
      })

      if (datas.length) {
        let fileRes = await this.service.api.downloadBpeChecked(datas);

        /*let filename = fileRes.headers.filename

        download(fileRes.data, filename);*/

        let url = fileRes.data.url
        let win = window.open(url, '_blank');
        win.focus();
      } else {
        izitoast.warning({title: 'Download BPE', message: 'Anda harus menceklis 1 data dengan status = "FINISHED".'})
      }
    }
  }

  async handleCheckStatus(item) {
    let ids = []

    ids.push(item.id)

    var res = await this.service.api.checkStatusChecked(ids);

    izitoast.success({title: 'Cek Status', message: 'Cek status berhasil.'})

    this.fetchData()
  }

  async handleCheckStatusChecked() {

    if (this.props.table.selected.length > 0) {
      var selected = this.props.table.selected

      var ids = [];

      selected.map(function (i) {
        if (i.status === 'UPLOADED' || i.status === 'FAILED') {
          ids.push(i.id)
        }
      })

      if (ids.length === 0) {
        izitoast.warning({title: 'Cek Status', message: 'Hanya log efiling dengan status UPLOADED & FAILED yang dapat di-cek status.'})

        return
      }

      var res = await this.service.api.checkStatusChecked(ids);

      izitoast.success({title: 'Cek Status', message: 'Cek status berhasil.'})

      this.fetchData()
    }
  }

  async handleForceUploadChecked() {
    if (this.props.table.selected.length > 0) {
      var selected = this.props.table.selected

      var ids = [];

      selected.map(function (i) {
        if (i.status === 'FAILED') {
          ids.push(i.id)
        }
      })

      if (ids.length === 0) {
        izitoast.warning({title: 'Force Upload', message: 'Hanya log efiling dengan status FAILED yang dapat di-force upload.'})

        return
      }

      var res = await this.service.api.forceUploadChecked(ids);

      izitoast.success({title: 'Force Upload', message: 'Force upload berhasil.'})

      this.fetchData()
    }
  }

  async handleExportExcel() {
    let selected = this.props.table.selected

    if (selected.length > 0) {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export Excel',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: 'Apakah anda yakin akan meng-export Excel data yang diceklis?'
      }));

      if (dialog === undefined)
        dialog = {}

      if(dialog) {
        let ids = [];

        selected.map(function (i) {
          ids.push(i.id)
        });

        let res = await LogEfilingService.api.exportBulkExcel(ids);

        let filename = res.headers.filename;

        download(res.data, filename);
      }
    }
    else {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export Excel',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: (
          <div>
            <this.ig.Field
              label='Nama'
              name='orgName__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Nama Alias'
              name='orgAliasName__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='NPWP'
              name='orgNpwp__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='PASAL_21'
              name='pasal__in_21'
              className="md-cell md-cell--6"
              component={this.ig.Checkbox}
            />
            <this.ig.Field
              label='PASAL_23'
              name='pasal__in_23'
              className="md-cell md-cell--6"
              component={this.ig.Checkbox}
            />
            <this.ig.Field
              label='PASAL_4A2'
              name='pasal__in_4A2'
              className="md-cell md-cell--6"
              component={this.ig.Checkbox}
            />
            <this.ig.Field
              label='PASAL_15'
              name='pasal__in_15'
              className="md-cell md-cell--6"
              component={this.ig.Checkbox}
            />
            <this.ig.Field
              label='PASAL_22'
              name='pasal__in_22'
              className="md-cell md-cell--6"
              component={this.ig.Checkbox}
            />
          </div>
        )
      }));

      if (dialog) {
        let param = ParamService.convert(dialog)

        let res = await LogEfilingService.api.exportByParamExcel(param);

        let filename = res.headers.filename;

        download(res.data, filename);
      }
    }
  }

  openEfiling() {
    try {
      if(window.location.origin.indexOf("eppt.pajakku") != -1){
        var url = 'https://e-filing.pajakku.com';
        var win = window.open(url, '_blank');
        win.focus();
      } else {
        var url = 'https://efiling.bdg.pajakku.com';
        var win = window.open(url, '_blank');
        win.focus();
      }
    } catch(e){
      var url = 'https://efiling.bdg.pajakku.com';
      var win = window.open(url, '_blank');
      win.focus();
    }
  }

  _barActions = [
    // {
    //   label:'word.delete',
    //   iconClassName:'mdi mdi-delete',
    //   onClick:() => {
    //     console.log('this',this)
    //     this.setState({showDialogConfirmDeleteFinishFailedSelected:true})
    //   },
    //   disabledFunc:() => {
    //     let selected = this.props.table.selected
    //     let deleteable = []

    //     selected.forEach(data => {
    //       if(data.status == 'FINISH' || data.status == 'FAILED') {
    //         deleteable.push(data.id)
    //       }
    //     })

    //     return deleteable.length === 0
    //   }
    // },
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => this.fetchData()
    },
    {
      label:'word.downloadBpe',
      iconClassName:'mdi mdi-download',
      disabledFunc:() => this.props.table.selected.length === 0,
      onClick:() => this.handleDownloadChecked()
    },
    {
      label:'word.checkStatus',
      iconClassName:'mdi mdi-shuffle',
      disabledFunc:() => this.props.table.selected.length === 0,
      onClick:() => this.handleCheckStatusChecked()
    },
    {
      label:'word.forceUpload',
      iconClassName:'mdi mdi-alert-octagon',
      disabledFunc:() => this.props.table.selected.length === 0,
      onClick:() => this.handleForceUploadChecked()
    },
    {
      label:'word.openEfiling',
      iconClassName:'mdi mdi-mixcloud',
      // disabledFunc:() => this.props.table.selected.length === 0,
      onClick:() => this.openEfiling()
    },
  ]

  async showDetail(d) {
    var d = await this.context.showDialog((props, res, rej) =>({
      title: 'Details',
      initialValue: {},

      okText: 'Back',
      text: (
        <div>
        {d.isJoin && <span className='mpk-badge md-green-500--background'>JOIN</span> }
        {!d.isJoin && <span className='mpk-badge md-red-500--background'>JOIN</span> }

        {d.isEncrypt && <span className='mpk-badge md-green-500--background'>ENCRYPT</span> }
        {!d.isEncrypt && <span className='mpk-badge md-red-500--background'>ENCRYPT</span> }

        {d.isWrap && <span className='mpk-badge md-green-500--background'>WRAP</span> }
        {!d.isWrap && <span className='mpk-badge md-red-500--background'>WRAP</span> }

        <br/>

        {d.isComplete && <span className='mpk-badge md-green-500--background'>COMPLETE</span> }
        {!d.isComplete && <span className='mpk-badge md-red-500--background'>COMPLETE</span> }

        {d.isWrap && <span className='mpk-badge md-green-500--background'>WRAP</span> }
        {!d.isWrap && <span className='mpk-badge md-red-500--background'>WRAP</span> }

        {d.isBpe && <span className='mpk-badge md-green-500--background'>BPE</span> }
        {!d.isBpe && <span className='mpk-badge md-red-500--background'>BPE</span> }

        {d.isSendingToASP && <span className='mpk-badge md-green-500--background'>SENDING TO ASP</span> }
        {!d.isSendingToASP && <span className='mpk-badge md-red-500--background'>SENDING TO ASP</span> }

        {d.isReceivedByASP && <span className='mpk-badge md-green-500--background'>RECEIVED BY ASP</span> }
        {!d.isReceivedByASP && <span className='mpk-badge md-red-500--background'>RECEIVED BY ASP</span> }

        <br/>

        {d.isSendingToDJP && <span className='mpk-badge md-green-500--background'>SENDING TO DJP</span> }
        {!d.isSendingToDJP && <span className='mpk-badge md-red-500--background'>SENDING TO DJP</span> }

        {d.isReceivedByDJP && <span className='mpk-badge md-green-500--background'>RECEIVED BY DJP</span> }
        {!d.isReceivedByDJP && <span className='mpk-badge md-red-500--background'>RECEIVED BY DJP</span> }

        {d.isReceivedByDJP && <span className='mpk-badge md-green-500--background'>RECEIVED BY DJP</span> }
        {!d.isReceivedByDJP && <span className='mpk-badge md-red-500--background'>RECEIVED BY DJP</span> }
        </div>
      )
    }));
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='NPWP Organisasi'
          name='npwp__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Dari Bulan'
          name='bulan__greaterOrEqualThan'
          className="md-cell md-cell--6"
          component={this.ig.Searchfield}
          options={[
            {id: 1, name: "1"},
            {id: 2, name: "2"},
            {id: 3, name: "3"},
            {id: 4, name: "4"},
            {id: 5, name: "5"},
            {id: 6, name: "6"},
            {id: 7, name: "7"},
            {id: 8, name: "8"},
            {id: 9, name: "9"},
            {id: 10, name: "10"},
            {id: 11, name: "11"},
            {id: 12, name: "12"}
          ]}
        />
        <this.ig.Field
          label='Sampai Bulan'
          name='bulan__lessOrEqualThan'
          className="md-cell md-cell--6"
          component={this.ig.Searchfield}
          options={[
            {id: 1, name: "1"},
            {id: 2, name: "2"},
            {id: 3, name: "3"},
            {id: 4, name: "4"},
            {id: 5, name: "5"},
            {id: 6, name: "6"},
            {id: 7, name: "7"},
            {id: 8, name: "8"},
            {id: 9, name: "9"},
            {id: 10, name: "10"},
            {id: 11, name: "11"},
            {id: 12, name: "12"}
          ]}
        />

        <this.ig.Field
          label='Bulan'
          name='bulan__equals'
          className="md-cell md-cell--12"
          component={this.ig.Searchfield}
          options={[
            {id: 1, name: "1"},
            {id: 2, name: "2"},
            {id: 3, name: "3"},
            {id: 4, name: "4"},
            {id: 5, name: "5"},
            {id: 6, name: "6"},
            {id: 7, name: "7"},
            {id: 8, name: "8"},
            {id: 9, name: "9"},
            {id: 10, name: "10"},
            {id: 11, name: "11"},
            {id: 12, name: "12"}
          ]}
        />

        <this.ig.Field
          label='Tahun'
          name='tahun__equals'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Pembetulan'
          name='pembetulan__equals'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NTPA'
          name='ntpa__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NTTE'
          name='ntte__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />

        <this.ig.Field
          label='WAITING'
          name='status__in_WAITING'
          className="md-cell md-cell--12"
          component={this.ig.Checkbox}
        />

        <this.ig.Field
          label='PROCESSING'
          name='status__in_PROCESSING'
          className="md-cell md-cell--12"
          component={this.ig.Checkbox}
        />

        <this.ig.Field
          label='UPLOADED'
          name='status__in_UPLOADED'
          className="md-cell md-cell--12"
          component={this.ig.Checkbox}
        />

        <this.ig.Field
          label='FINISHED'
          name='status__in_FINISHED'
          className="md-cell md-cell--12"
          component={this.ig.Checkbox}
        />

        <this.ig.Field
          label='FAILED'
          name='status__in_FAILED'
          className="md-cell md-cell--12"
          component={this.ig.Checkbox}
        />
      </this.SearchContainer>
    )
  }

  columns=[
    {isSortable: true, label: "word.npwp",  searchField: "npwp", value: (d)=> d.npwp, show:true, isSearchable:true, type: 'func'},
    {isSortable: true, label: "word.month",  searchField: "bulan", value: (d)=> d.bulan, show:true, isSearchable:true, type: 'func'},
    {isSortable: true, label: "word.tahun",  searchField: "tahun", value: (d)=> d.tahun, show:true, isSearchable:true, type: 'func'},
    {isSortable: true, label: "word.pembetulan",  searchField: "pembetulan", value: (d)=> d.pembetulan, show:true, isSearchable:true, type: 'func'},

    {isSortable: true, label: "word.pasal",  value: (d)=> {
      var style = {
        background: 'grey'
      }

      switch (d.pasal) {
        case '21':
          style.background = '#4caf50'
          break;
        case '23':
          style.background = '#ff5722'
          break;
        case '4A2':
          style.background = '#2196f3'
          break;
        case '22':
          style.background = '#e91e63'
          break;
        case '15':
          style.background = '#9c27b0'
          break;
        default:
          break;
      }

      return <Chip label={d.pasal} style={style} />
    }, isDefaultSort:false, show:true, isSearchable:false, searchField: "pasal", type: 'func'},

    {
      searchField: "status",
      label: "word.status",
      type: 'func',
      value: (d)=> {
        var style = {
          background: 'grey'
        }

        switch (d.status) {
          case 'PROCESSING':
            style.background = '#FFC107'
            break;
          case 'UPLOADED':
            style.background = '#2196F3'
            break;
          case 'FINISHED':
            style.background = '#4CAF50'
            break;
          case 'FAILED':
            style.background = '#f44336'
            break;
          default:
            break;
        }

        var step = '';
        switch (d.step) {
          case 1:
            step = 'UPLOADING CSV'
            break;
          case 2:
            step = 'UPLOADING PDF'
            break;
          case 3:
            step = 'COMMIT'
            break;
          case 4:
            step = 'GET NTPA'
            break;
          case 5:
            step = 'GET NTTE'
            break;
          case 6:
            step = 'REQUEST DOWNLOAD BPE'
            break;
          case 7:
            step = 'DOWNLOADING BPE'
            break;

          default:
            step = '-'
            break;
        }
        return <div style={{width: 200}}>
            <Chip label={d.status} style={style} />
            <Chip label={step} />
          </div>
      },
      className: "mpk-font-size-S", show:true, isSearchable:true},
      {isSortable: true, label: "word.message",  value: "errorReason", isDefaultSort:false, show:true, isSearchable:false},
      {isSortable: true, label: "word.forceUpload", type: "boolean", value: "forceUpload", show: true, isSearchable: true},
      {isSortable: true, label: "word.ntpa",  value: "ntpa", isDefaultSort:false, show:true, isSearchable:false},
      {isSortable: true, label: "word.ntte",  value: "ntte", isDefaultSort:false, show:true, isSearchable:false},
      {isSortable: true, label: "word.taxType",  value: "taxType", isDefaultSort:false, show:false, isSearchable:false},
      {isSortable: true, label: "word.amount",  value: "amount", isDefaultSort:false, show:false, isSearchable:false},

  ]

  async handleSave(value) {
    var _this = this
    try {
      this.setState({onProgress: true})
      let formData = new FormData();
      formData.append("type", value.type)
      formData.append("category", "ORGANIZATION")
      formData.append("file", value.file)
      formData.append("no", value.no)
      axios.post(`/api/${ImportService.pathImportOrganization}`, formData).then(function (res){
        izitoast.info({title: 'Import File', message: 'sedang diproses cek log untuk lebih detil'})
        _this.setState({onProgress: false})
        _this.fetchData()
      }).catch(function (e){
        _this.fetchData()
        var msg = e.message;
        if(e.response) msg = e.response.data.message;
        _this.setState({
          isError: true,
          onProgress: false,
          errorMessage: msg
        })
      })
    } catch(e) {
      _this.fetchData()
      var msg = e.message;
      if(e.response) msg = e.response.data.message;
      _this.setState({
        isError: true,
        onProgress: false,
        errorMessage: msg
      })
    }
  }

  componentDidMount(){
    let path = this.props.match.path.split("/")
    let params = path[2];
    this.props.change("category", params)
    this.props.change("type", "M")
    this.props.change("no", false)
  }

  handleTabChange(newActiveTabIndex){
    if(newActiveTabIndex == 0){
      this.props.change("type", "M")
    } else {
      this.props.change("type", "A")
    }
  }

}
