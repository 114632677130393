import ApiService from '../../../../../services/api.service';

class LogCetakLaporanPNService extends ApiService {
  name= 'LogCetakLaporanPN';
  path= 'ebilling/log/print/invoiceReports';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    download: async (id) => {
      let res = await this.http.get(`${this.apiLocation + "/" + this.path}/download/${id}`, {
        responseType: 'blob'
      })

      return res;
    },
  }
}

export default new LogCetakLaporanPNService();
