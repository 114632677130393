import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues} from 'redux-form';
import moment from 'moment';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress
} from 'react-md';

import FormView from '../../../../../components/entity/form.view';
import BP26EbupotService from './BP_Ebupot_26.service';
// import NomorBPService from '../nomorBP.service';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox} from '../../../../../components/form';
import UtilService from '../../../../../services/utils.service'
// import SPT21SignerService from '../SPT21Signer/SPT21Signer.service';
import NpwpService from './../../NpwpService';
import FormViewSpt from '../../../../../components/entity/form.view.spt';
import terbilangService from '../../../../../services/terbilang.service';
import nomorBP23Service from './../nomorBPEbupot23.service'
import LawanTransaksiDialog from '../../../../../components/entity/LawanTransaksi.dialog';
import MasterLawanTransaksi23Service from '../../../Master/MasterLawanTransaksi23/MasterLawanTransaksi23.service';
import Master26TarifService from '../../23_26/master/Tarif23/Master26Tarif.service';
import BPDokumenForm from './BP_Ebupot_26.dokumen.form'

@reduxForm({form: 'BP_26_Form', destroyOnUnmount: true})
@connect((state) => ({
  access      : state.entity.access.api.find,
  organization: state.authEppt.currentOrganization,
  spt         : state.authEppt.currentSPT,
  formData    : getFormValues('BP_26_Form')(state)
}))
export default class BP26EbupotForm extends FormViewSpt {
  service  = BP26EbupotService
  viewType = 2;

  typingTimeout=1000;
  typingObj=null;


  initialData={
    bagA: {},
    bagB: {},
    bagC: {}
  }

  constructor(props) {
    super(props);

    this.state= {
      ...this.state,
      showCalculateProgress: false,
      npwpNotValid         : true,

      lkDialog: false,
      tarif26 : {
        "dividen"                    : 20.0,
        "bunga"                      : 20.0,
        "royalti"                    : 20.0,
        "sewaDanPenghasilanLain"     : 20.0,
        "imbalan"                    : 20.0,
        "hadiahDanPenghargaan"       : 20.0,
        "pensiunDanPembayaranBerkala": 20.0,
        "premiSwap"                  : 20.0,
        "pembebasanUtang"            : 20.0,
        "penjualanHarta"             : 20.0,
        "premiAsuransi"              : 20.0,
        "penghasilanPenjualanSaham"  : 20.0,
        "penghasilanKenaPajakBUT"    : 20.0,
      }
    }
  }

  async npwpValidation(evt, value){
    if(this.typingObj) clearTimeout(this.typingObj);

    this.typingObj = setTimeout(async ()=> {
      if(value){
        var res = await NpwpService.api.validate(value)
        if(res.valid){
          this.setState({npwpNotValid: false})

          var lk = await MasterLawanTransaksi23Service.api.page({
            'npwp.equals': value,
            'organizationId.equals': this.props.spt.data.organization.id
          })

          if(lk.data) {
            var formData = {...this.props.formData};

            if(lk.data.length > 0) {
              var d = lk.data[0];
              formData.bagA = {
                "npwp"  : d.npwp,
                "nik"   : d.nik,
                "nama"  : d.nama,
                "alamat": d.alamat,
                "email" : d.email
              };
            } else {
              formData.bagA = {
                "npwp" : value
              }
              this.toast.info({title: "Info", message: `Lawan Transaksi dengan npwp ${value} tidak ditemukan`})
            }
            this.props.initialize(formData);
          }
        } else {
          this.setState({npwpNotValid: true})
          this.toast.warning({title: "Validasi Npwp", message: "npwp tidak valid"})
        }
      }
    }, this.typingTimeout);
  }

  async beforeSave(value) {
    if(value.bagB.bruto == 0 || value.bagB.bruto == '') {
      this.toast.warning({title:'Warning', message:'Isi bruto harus lebih besar dari 0'})
      return false;
    }

    var date;
    if(typeof value.bagC.tanggal === 'string') {
      if(value.bagC.tanggal.search('/') != -1) {
        date = moment(value.bagC.tanggal, 'DD/MM/YYYY');
      } else {
        date = moment(value.bagC.tanggal, 'YYYY-MM-DD');
      }
    } else if(typeof value.bagC.tanggal === 'object') {
      date = moment(value.bagC.tanggal)
    }

    if(!(date.year() == this.props.spt.data.year && date.month()+1 == this.props.spt.data.month)) {
      this.toast.warning({title:'Warning', message:'Tanggal bukti potong harus sesuai dengan masa SPT'})
      return false;
    }


    return true;
  }

  async initData() {

    if(this.props.match.params.id == 'new') {
      // get tarif
      var tarif = {};
      try {
        tarif = await Master26TarifService.api.byCompany({
          compId: this.props.organization.company.id,
          tgl   : moment().format("YYYY-MM-DD")
        });
      } catch (error) {}

      if(tarif) {
        console.log(tarif)
        await this.setState({'tarif26': tarif});
      }

      let nomorBPReq = await nomorBP23Service.api.get(this.props.spt.data.organization.id)
      var nomorBP    = "";
      if(nomorBPReq.data.mode === 'PREF_SUF') {
        nomorBP = nomorBPReq.data.prefix+nomorBPReq.data.suffix;
      } else {
        nomorBP = nomorBPReq.data.suffix+nomorBPReq.data.prefix;
      }
      let signer = {};  //await SPT21SignerService.api.getSigner(this.props.spt.data.id);

      this.initialData = {
        ...this.initialData,
        no  : nomorBP,   //.data.no,
        bagC: {
          tanggal: new Date(),
          nama   : this.props.organization.name,
          npwp   : this.props.organization.npwp,
        },
        "bagB": {
          "bunga": {
              "bruto"  : 0,
              "nama"   : "Bunga",
              "netto"  : 0,
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif26.bunga
          },
          "dividen": {
              "bruto"  : 0,
              "nama"   : "Dividen",
              "netto"  : 0,
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif26.dividen
          },
          "hadiahDanPenghargaan": {
              "bruto"  : 0,
              "nama"   : "Hadiah dan Penghargaan",
              "netto"  : 0,
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif26.hadiahDanPenghargaan
          },
          "imbalan": {
              "bruto"  : 0,
              "nama"   : "Imbalan",
              "netto"  : 0,
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif26.imbalan
          },
          "jmlBruto"       : 0,
          "jmlNetto"       : 0,
          "jmlPph"         : 0,
          "keuntunganUtang": {
              "bruto"  : 0,
              "nama"   : "Keuntungan Utang",
              "netto"  : 0,
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif26.pembebasanUtang
          },
          "penghasilanKenaPajakBut": {
              "bruto"  : 0,
              "nama"   : "Penghasilan Kena Pajak BUT",
              "netto"  : 0,
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif26.penghasilanKenaPajakBUT
          },
          "penghasilanSaham": {
              "bruto"  : 0,
              "nama"   : "Penghasilan Saham",
              "netto"  : 0,
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif26.penghasilanPenjualanSaham
          },
          "penjualanHarta": {
              "bruto"  : 0,
              "nama"   : "Penjualan Harta",
              "netto"  : 0,
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif26.penjualanHarta
          },
          "pensiun": {
              "bruto"  : 0,
              "nama"   : "Pensiun",
              "netto"  : 0,
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif26.pensiunDanPembayaranBerkala
          },
          "premiAsuransi": {
              "bruto"  : 0,
              "nama"   : "Premi Asuransi",
              "netto"  : 0,
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif26.premiAsuransi
          },
          "premiSwap": {
              "bruto"  : 0,
              "nama"   : "Premi Swap",
              "netto"  : 0,
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif26.premiSwap
          },
          "royalti": {
              "bruto"  : 0,
              "nama"   : "Royalti",
              "netto"  : 0,
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif26.royalti
          },
          "sewaDanPenghasilan": {
              "bruto"  : 0,
              "nama"   : "Sewa dan Penghasilan",
              "netto"  : 0,
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif26.sewaDanPenghasilanLain
          },
          "terbilang": ""
        },
        spt: this.props.spt.data
      }
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);
      this.props.initialize(res.data);
      // this.npwpValidation(null, res.data.bagA.npwp)
      this.setState({npwpNotValid: false})
    }
  }

  async changeTarif(d, e) {
    var tarif = await Master26TarifService.api.byCompany({
      compId: this.props.organization.company.id,
      tgl   : moment(d).format("YYYY-MM-DD")
    })

    if(tarif) {
      await this.setState({'tarif26': tarif});
    }

    var form = {...this.props.formData};
    var bagB = form.bagB;

    if(bagB) {
      form.bagB.bunga.tarif                   = this.state.tarif26.bunga;
      form.bagB.dividen.tarif                 = this.state.tarif26.dividen;
      form.bagB.hadiahDanPenghargaan.tarif    = this.state.tarif26.hadiahDanPenghargaan;
      form.bagB.imbalan.tarif                 = this.state.tarif26.imbalan;
      form.bagB.keuntunganUtang.tarif         = this.state.tarif26.pembebasanUtang;
      form.bagB.penghasilanKenaPajakBut.tarif = this.state.tarif26.penghasilanKenaPajakBUT;
      form.bagB.penghasilanSaham.tarif        = this.state.tarif26.penghasilanPenjualanSaham;
      form.bagB.penjualanHarta.tarif          = this.state.tarif26.penjualanHarta;
      form.bagB.pensiun.tarif                 = this.state.tarif26.pensiunDanPembayaranBerkala;
      form.bagB.premiAsuransi.tarif           = this.state.tarif26.premiAsuransi;
      form.bagB.premiSwap.tarif               = this.state.tarif26.premiSwap;
      form.bagB.royalti.tarif                 = this.state.tarif26.royalti;
      form.bagB.sewaDanPenghasilan.tarif      = this.state.tarif26.sewaDanPenghasilanLain;

      await this.props.initialize(form);
      await this.reCalculate()
    }

  }

  async afterSave(res, val) {
    var formData = this.props.formData.values;

    var data = {
      ...formData.bagA,
      organization: this.props.spt.data.organization
    }
    if(data.id) delete data.id;
    await MasterLawanTransaksi23Service.api.findOrCreate(data)
  }

  async reCalculate() {
    var form = {...this.props.formData};
    var d    = form.bagB;

    Object.keys(d).forEach((k, i) => {
      if(!(k == 'jmlBruto' || k == 'jmlPph' || k == 'terbilang' || k == 'jmlNetto' || k == 'lastModifiedBy' || k == 'lastModifiedDate' || k == 'id' || k == 'createdDate' || k == 'createdBy')) {
        var x = d[k];
        if(typeof x == 'object') {

          if(x.bruto) {
            x.pph = x.bruto*x.tarif/100

            if(k == 'penjualanHarta' || k == 'premiAsuransi' || k == 'penghasilanSaham') {
              if(!x.netto) x.netto = 0;
                 x.pph             = x.bruto*(x.netto/100)*(x.tarif/100)
            }
          }

        }
      }
    })

    console.log(form)

    await this.props.initialize(form);

    this.calculateTotal()

  }

  async calculate(e,v,pv,f, parent) {
    var form = this.props.formData;
    var d    = form.bagB[parent];

    var bruto = parseFloat(d.bruto);
    var tarif = parseFloat(d.tarif);

    if(f.search('bruto') >= 0) {
      bruto = parseFloat(v)
    } else if(f.search('tarif') >= 0) {
      tarif = parseFloat(v)
    }

    if(!bruto) bruto = 0;
    if(!tarif) tarif = 0;

    await this.props.change(`bagB.${parent}.pph`, bruto*tarif/100);
    this.calculateTotal()
  }

  async calculateSpecial(e,v,pv,f, parent) {
    var form = this.props.formData;
    var d    = form.bagB[parent];

    var bruto = parseFloat(d.bruto);
    var tarif = parseFloat(d.tarif);
    var netto = parseFloat(d.netto);

    if(f.search('bruto') >= 0) {
      bruto = parseFloat(v)
    } else if(f.search('tarif') >= 0) {
      tarif = parseFloat(v)
    }else if(f.search('netto') >= 0) {
      netto = parseFloat(v)
    }

    if(!bruto) bruto = 0;
    if(!tarif) tarif = 0;
    if(!netto) netto = 0;

    await this.props.change(`bagB.${parent}.pph`, bruto*(netto/100)*(tarif/100));
    this.calculateTotal()
  }

  async calculateTotal() {
    var form = this.props.formData;
    var d    = form.bagB;

    var jmlPph   = 0;
    var jmlBruto = 0;
    var jmlNetto = 0;
    Object.keys(d).forEach((k, i) => {
      if(!(k == 'jmlBruto' || k == 'jmlPph' || k == 'terbilang' || k == 'jmlNetto' || k == 'lastModifiedBy' || k == 'lastModifiedDate' || k == 'id' || k == 'createdDate' || k == 'createdBy')) {
        var x = d[k];

        if(typeof x == 'object') {
          jmlPph   = jmlPph+ parseFloat(x.pph);
          jmlBruto = jmlBruto+ parseFloat(x.bruto)
          jmlNetto = jmlNetto+ (parseFloat(x.netto)*parseFloat(x.bruto)/100)
        }


      }
    })

    this.props.change('bagB.jmlBruto', jmlBruto)
    this.props.change('bagB.jmlPph', jmlPph);
    this.props.change('bagB.jmlNetto', jmlNetto);
    this.props.change('bagB.terbilang', terbilangService(parseInt(jmlPph)));

  }

  formView() {
    var formData                                                     = {bagB: {}};
    if  (this.props.formData && this.props.formData.values) formData = this.props.formData.values
    return (
      <div>
        <LawanTransaksiDialog
          spt      = {this.props.spt.data}
          onSelect = {(d)=> {
            var formData = {...this.props.formData};

            formData.bagA = {
              "npwp"  : d.npwp,
              "nik"   : d.nik,
              "nama"  : d.nama,
              "alamat": d.alamat,
              "email" : d.email
            };

            this.setState({npwpNotValid: false})

            this.props.initialize(formData);

          }}
          service = {MasterLawanTransaksi23Service}
          visible = {this.state.lkDialog}
          onHide  = {()=> this.setState({lkDialog: false})}
        />
        <Card>
          <div className="md-grid">
            <Field
              label     = 'No.Bukti Potong'
              name      = 'no'
              className = "md-cell md-cell--6"
              component = {Textfield}
              validate  = {validation.required}
            />
            <Field
              label        = 'Tanggal Bukti Potong'
              name         = 'bagC.tanggal'
              id           = "BP21FForm-noBp"
              className    = "md-cell md-cell--6"
              component    = {Datepicker}
              validate     = {validation.required}
              onDateChange = {async (d, e) => this.changeTarif(d)}
            />

            <Field
              label     = 'Referensi'
              name      = 'referensi'
              id        = "BP21FForm-referensi"
              className = "md-cell md-cell--12"
              component = {Textfield}
            />
          </div>
        </Card>

        <br/>

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='A. IDENTITAS PENERIMA PENGHASILAN YANG DIPOTONG ' />
          <Divider/>
          <div className="md-grid">
            <div className='md-cell md-cell--6'>
              <div className='mpk-layout'>
                <Field
                  className  = 'flex'
                  label      = 'NPWP'
                  name       = 'bagA.npwp'
                  id         = "BP21FForm-npwp"
                  component  = {TextfieldMask}
                  maskFormat = "##.###.###.#-###-###"
                  mask       = "_"
                  length     = {15}
                  onChange   = {this.npwpValidation.bind(this)}
                  validate   = {validation.required}
                />
                <Button icon primary swapTheming style={{marginTop: 10}} onClick={()=> this.setState({lkDialog: true})}>search</Button>
               </div>
              <Field
                label = 'Nama'
                name  = 'bagA.nama'

                disabled  = {this.state.npwpNotValid}
                component = {Textfield}
                validate  = {validation.required}
              />
              {/* <Field
                label = 'NIK'
                name  = 'bagA.nik'

                disabled  = {this.state.npwpNotValid}
                component = {Textfield}
              /> */}
              {/* <Field
                label     = 'Telepon'
                name      = 'name'
                id        = "BP21FForm-name"
                component = {Textfield} */}
                <Field
                  label = 'Kelurahan/Desa'
                  name  = 'bagA.desa'

                  disabled  = {this.state.npwpNotValid}
                  component = {Textfield}
                  validate  = {validation.required}
                />
                <Field
                  label = 'Kota/Kabupaten'
                  name  = 'bagA.kota'

                  disabled  = {this.state.npwpNotValid}
                  component = {Textfield}
                  validate  = {validation.required}
                />
            </div>
            <div className='md-cell md-cell--6'>
              {/* <Field
                label     = 'Kode Poss'
                name      = 'npwp'
                id        = "BP21FForm-codePoss"
                component = {Textfield}
              /> */}
              <Field
                label = 'Alamat'
                name  = 'bagA.alamat'

                disabled  = {this.state.npwpNotValid}
                component = {Textfield}
                validate  = {validation.required}
              />
              <Field
                label = 'Kecamatan'
                name  = 'bagA.kecamatan'

                disabled  = {this.state.npwpNotValid}
                component = {Textfield}
                validate  = {validation.required}
              />
              <Field
                label = 'Propinsi'
                name  = 'bagA.propinsi'

                disabled  = {this.state.npwpNotValid}
                component = {Textfield}
                validate  = {validation.required}
              />
              <Field
                label = 'Kode Pos'
                name  = 'bagA.kodePos'

                disabled  = {this.state.npwpNotValid}
                component = {Textfield}
                validate  = {validation.required}
              />
              {/* <Field
                label = 'Email'
                name  = 'bagA.email'

                disabled  = {this.state.npwpNotValid}
                component = {Textfield}
              /> */}
            </div>
          </div>
        </Card>

        <br/>

        {this.state.showCalculateProgress && <LinearProgress id='lin_pro' style={{margin: 0}} />}
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='B. DOKUMEN' />
          <Divider/>

          <BPDokumenForm />
        </Card>

        <br />

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='C. PAJAK PENGHASILAN YANG DIPOTONG' />
          <Divider/>

          <div className="md-grid">
            <div className="md-cell md-cell--6">
              <Field
                label='Kode Object Pajak'
                name='bagC.kodeObjekPajak'
                
                component={Searchfield}
                options={[
                  {id: "Kosong", name: 'Kosong'},
                ]}
              />
              <Field
                label     = 'Jumlah Penghasilan Bruto (Rp.)'
                name      = 'bagC.bruto'
                
                component = {TextfieldMask}
                className = 'md-text-right'
                money     = {','}
              />
            </div>
            <div className="md-cell md-cell--6">
              <Field
                label     = 'Tarif'
                name      = 'bagC.tarif'
                
                component = {TextfieldMask}
                className = 'md-text-right'
                money     = {','}
              />
              <Field
                label     = 'PPh Yang Dipotong / DTP (Rp.)'
                name      = 'bagC.pph'
                
                component = {TextfieldMask}
                className = 'md-text-right'
                money     = {','}
              />
            </div>
          </div>
        </Card>

        <br />

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='D. IDENTITAS PEMOTONG ' />
          <Divider/>

          <div className="md-grid">
          <Field
            label     = 'NPWP'
            name      = 'bagC.npwp'
            className = "md-cell md-cell--6"
            disabled
            component  = {TextfieldMask}
            maskFormat = "##.###.###.#-###-###"
            mask       = "_"
            validate   = {validation.required}
          />
          <Field
            label     = 'Nama'
            name      = 'bagC.nama'
            className = "md-cell md-cell--6"
            disabled
            component = {Textfield}
            validate  = {validation.required}
          />
          <Field
            label='Bertindak sebagai '
            name='bagC.nama2'
            className="md-cell md-cell--6"
            component={Searchfield}
            options={[
              {id: "-", name: '-'},
            ]}
          />
          <Field
            className="md-cell md-cell--12"
            label='Dengan menyadari sepenuhnya akan segala akibatnya termasuk sanksi-saksi sesuai dengan ketentuan perundang undangan yang berlaku,
            saya menyatakan bahwa apa yang telah saya beritahukan di atas beserta lampiran-lampirannya adalah benar, lengkap dan jelas'
            name='bagC.approve'
            component={Checkbox}
          />    
          <br />
          <br />
          <br />      
          <br />
          <br />
          </div>
        </Card>

      </div>
    )
  }

}
