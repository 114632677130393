import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  Tab,
  Tabs,
  SelectionControlGroup,
  Checkbox
} from 'react-md';

import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Radio, convert, CheckboxSelection} from '../../../components/form';
import UtilService from '../../../services/utils.service'
import FormView from '../../../components/entity/form.view';
import DialogView from '../../../components/entity/dialog.view';
import SignerService from './Signer.service'
import moment from 'moment'
import counterpart from 'counterpart'

@reduxForm({form: 'SignerForm', destroyOnUnmount: true})
@connect((state) => ({
  formData:state.form.SignerForm,
  user: state.auth.user
}))
export default class SignerFormView extends FormView {
  service=SignerService
  viewType=2;

  componentDidMount() {
    console.log(this.props.match)
    this.props.change('signAs', true)
    this.props.change('active', false)
    this.props.change('npwpProfile', this.props.match.params.npwp)
    this.initData()
  }

  async initData() {
    if(this.props.match.params.id == 'new') {
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.getOne(this.props.match.params.id);
      this.props.initialize(res.data);
    }
  }

  async metUpdate(res, value){
    value.npwpProfile = this.props.match.params.npwp
    try {
      if(!value.npwpProfile){
        value.npwpProfile = this.props.match.params.org
      }
    } catch(e){}
    res = await this.service.api.putUpdate(value)
  }

  async metSave(res, value){
    value.npwpProfile = this.props.match.params.npwp
    try {
      if(!value.npwpProfile){
        value.npwpProfile = this.props.match.params.org
      }
    } catch(e){}
    res = await this.service.api.postSave(value)
  }

  formView() {
    return (
        <Card>
            <div className='md-grid'>
                <Field
                    label='NPWP'
                    name='npwp'
                    className="md-cell md-cell--6"
                    component={TextfieldMask}
                    maskFormat="##.###.###.#-###-###"
                    validate={validation.required}
                />
                <Field
                    label='Nama'
                    name='name'
                    className="md-cell md-cell--6"
                    component={Textfield}
                    validate={validation.required}
                />
                <Field
                    label='Active'
                    name='active'
                    className="md-cell md-cell--6"
                    component={Switch}
                />
                <Field
                    label     = 'Sign As'
                    name      = 'sign_as'
                    className = "md-cell md-cell--6"
                    component = {CheckboxSelection}
                    options  = {[
                        {value: true, label: "Wajib Pajak"},
                        {value: false, label: "Kuasa"}
                    ]}
                    formData={this.props.formData}
                />
            </div>
      </Card>
    )
  }
}