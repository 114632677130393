import ApiService from '../../../../../services/api.service';

class AllBp21SatuTahunService extends ApiService {
  name= 'ALL_BP_21_SATU_TAHUN';
  path= 'spt21/st-bag1';
  constructor() {
    super();
    this.init()
  }
}

export default new AllBp21SatuTahunService();
