import ApiService from '../../../../../services/api.service';

class BP4a2TabunganDepositoService extends ApiService {
  name= 'BP4a2TabunganDeposito';
  path= 'pasal4/daftar-pemotongan';
  
  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    findBySpt: async (SptId) => {
      return await this.http.get(`${this.apiLocation}/${this.path}/${SptId}/spt`)
    },
    calculate: async (sptId) => {
      return await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/spt/recalculate`)
    },
    cetak: async (sptId) => {
      return await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/spt/report`)
    },
    check: async (npwp)=> {
      return await this.http.get(`${this.apiLocation}/pasal4/npwp-enable-daftar-deposito-manual/check/${npwp}`)
    }
  }
}


export default new BP4a2TabunganDepositoService();
