/**
 * Created by dwiargo on 1/2/18.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FileInput } from 'react-md';
import './FilePicker.scss';

class FilePicker extends Component {
  constructor(){
    super();
    this.state = {
      imgSrc:null,
      file:null
    }
  }

  handleFile = (files) => {
    let file = files;
    var reader = new FileReader();
        reader.readAsDataURL(file);

    reader.onloadend = () => {
      let result = reader.result;

      this.setState({
        file:result,
        imgSrc:[result]
      });
    };

    if(this.props.onChange){
      this.props.onChange(file);
    }
  };

  selectFile = () => {
    const inp = document.getElementById('mpk-file-picker-input');
    inp.click();
  };

  render(){
    let { imgSrc } = this.state;
    let { imagePreview, className, accept } = this.props;
    return (
      <div className={"mpk-file-picker "+className}>
        {imagePreview && imgSrc ? (
          <img src={imgSrc} role="presentation"/>
        ) : (null)}
        <FileInput
          id="mpk-file-picker-input"
          type="file"
          ref="mpkFile"
          name="mpk[file]"
          accept={accept}
          onChange={this.handleFile}
        />
      </div>
    )
  }
}

FilePicker.propTypes = {
  accept:PropTypes.string,
  onChange:PropTypes.func,
  imagePreview:PropTypes.bool
};

export default FilePicker;