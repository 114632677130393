import React, { Component } from 'react';
import { connect } from 'react-redux';

import {Field, getFormValues, reduxForm} from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../../../../components/form';

import ListView from '../../../../../../components/entity/listView';
import ColumnService from '../../../../../../services/column.service';
// import ImportService from './../Import/Import.service';
import axios from 'axios';
import izitoast from 'izitoast';
import FormDialog from './ImportSSP22DibayarSendiri.dialog';
import ImportSSP22DibayarSendiriService from './ImportSSP22DibayarSendiri.service';
import download from "downloadjs";
import ListViewImport from '../../../../../../components/entity/ListViewImport';
import ImportDialogResult from '../../../../../../components/entity/import.dialog.result';
import OrganizationService from "../../../../Administrator/Organization/Organization.service";

@reduxForm({form: 'ImportSSP22DibayarSendiriForm', destroyOnUnmount: true})
@connect((state) => ({
  ...ImportSSP22DibayarSendiriService.stateConnectSetting()(state),
  formData: getFormValues('ImportSSP22DibayarSendiriForm')(state),
  // EPPT CORE
  auth: state.auth.company,
  // EPPT CORE
}), ImportSSP22DibayarSendiriService.actionConnectSetting())
export default class ImportSSP22DibayarSendiriView extends ListViewImport {
  service=ImportSSP22DibayarSendiriService
  FormDialog=FormDialog
  pasal='pasal22'
  category='BP_22'
  importPath='/pasal22/upload/csv/etc'
  showAutomatisTab=false
  showPenomoranAuto=true
  showPembetulan=true
  // showFormatEspt=true
  // viewType=2

  constructor(props){
    super(props);
    this.state = {
      ...this.state,
      showTableSetting:true,
      showDialogConfirmDeleteSelected:false,
      showForm:false,
      formData: {},
      data: [],
      onProgress: false,
      isError: false,
      errorMessage: '',
      type: 'M'
    }
  }

  options1 = [
    {
      name: 'SSP PPH 22 DIBAYAR SENDIRI',
      id: 'SSP-SENDIRI'
    },
    {  
      name: 'SSP PPH 22 BENDAHARAWAN',
      id: 'SSP-OLEH-BENDAHARAWAN'
    },
    {
      name: 'SSP PPH 22 BANK DEVISA',
      id: 'SSP-OLEH-BANK-DEVISA'
    }
  ]

  beforeFetch(params) {
    params["category.in"] = "SSP-SENDIRI, SSP-OLEH-BENDAHARAWAN, SSP-OLEH-BANK-DEVISA"
  }

  async handleDownloadTemplate() {
    if(this.props.formData.category == "SSP-SENDIRI"){
      var report = await ImportSSP22DibayarSendiriService.api.downloadTemplate("SSP-SENDIRI")
      window.open(report.data.url)
    } else if(this.props.formData.category == "SSP-OLEH-BENDAHARAWAN"){
      var report = await ImportSSP22DibayarSendiriService.api.downloadTemplate("SSP-OLEH-BENDAHARAWAN")
      window.open(report.data.url)
    } else if(this.props.formData.category == "SSP-OLEH-BANK-DEVISA"){
      var report = await ImportSSP22DibayarSendiriService.api.downloadTemplate("SSP-OLEH-BANK-DEVISA")
      window.open(report.data.url)
    } else {
      izitoast.info({
        title: "Info",
        message: "Pilih salah satu kategori template"
      })
    }
  }

  async beforeSave(value){
    if(value && value.category && value.category == 'A'){
      value.category = value.type
      return value
    } else {
      return true
    }
  }

  componentDidMount(){
    let path = this.props.match.path.split("/")
    let params = path[2];
    this.props.change("category", params)
    // this.props.change("type", "M")
    // this.props.change("no", false)
    // this.props.change("migration", false)
    // this.props.change("formatEspt", false)
    // this.props.change("pembetulan", false)
    // this.props.change("npwp", null)
  }

  handleTabChange(newActiveTabIndex){
    if(newActiveTabIndex == 0){
      this.props.change("type", "M");
      this.setState({type: 'M'})
    } else {
      this.props.change("type", "A")
      this.setState({type: 'A'})
    }
  }

  // yg ditambah
  tabWidth = 280
  tabs= ["import", "search", "column"]

  tabComponents() {
    var obj = this;
    return {
      import: this.import.bind(obj)
    }
  }
   // end yg ditambah

  async checkCategory() {
    let category = this.props.formData.category

    if (!category)
      return false

    if (category === 'SSP_22_dibayar_sendiri')
      return false

    return true
  }

  import(){
    const { handleSubmit, submitting, valid, pristine } = this.props;

    let formatEspt = false;

    if (this.props.formData) {
      formatEspt = this.props.formData.formatEspt
    }

    var insideBP = true
    if(this.props.match.url.indexOf('open') != -1){
      insideBP = false
    }

    var org = []
    if(this.props.auth && this.props.auth.organizations) org = this.props.auth.organizations

    return (
        <div className='mpk-layout column fill'>
        <ImportDialogResult dataResults={this.state.importResults} visible={this.state.showImportResult} onHide={()=> {this.setState({showImportResult: false})}} />
          <div className='flex'>
            <div className='md-grid'>
              <div className="md-cell md-cell--12">
                <Field
                  label='Kategori'
                  name='category'
                  twoLines
                  component={Searchfield}
                  options={this.options1}
                  // validate={validation.required}
                />
              </div>
             <div className="md-cell md-cell--12">
                <Field
                  label='Select a File'
                  name='file'
                  multiple
                  twoLines
                  component={Fileinput}
                  validate={validation.required}
                />
              </div>
              {/* <div className="md-cell md-cell--12">
                <Field
                  label='Aktifkan Penomoran Bukti Potong Otomatis ?'
                  name='no'
                  component={Switch2}
                  // validate={validation.required}
                />
              </div> */}
              {/* <div className="md-cell md-cell--12">
                <Field
                  label='Import Migrasi'
                  name='migration'
                  component={Switch2}
                  // validate={validation.required}
                />
              </div> */}

              {this.showFormatEspt &&
              <div className="md-cell md-cell--12">
                <Field
                  label='Format ESPT'
                  name='formatEspt'
                  component={Switch2}
                  // validate={validation.required}
                />
              </div>
              }

              {/* {insideBP &&
                <div className="md-cell md-cell--12">
                  <Field
                    label     = 'Organization'
                    name      = 'npwp'
                    id        = "spt2126-organization"
                    className = "md-cell md-cell--12"
                    component = {Searchfield}
                    valueField='npwp'
                    options   = {org}
                    validate  = {validation.required}
                    itemTemplate = {function(d) {
                      let nameAndAlias = ""
                      if (d.name)
                        nameAndAlias = d.name
                      if (d.aliasName)
                        nameAndAlias += " - " + d.aliasName
                      return {
                        primaryText  : nameAndAlias,
                        secondaryText: d.npwp,
                        onClick      : () => {
                          if(!this.props.disabled){
                            this.handleItemClick(d); this.hide()
                          }
                        }
                      }
                    }}
                  />
                </div>
              } */}

              { formatEspt &&
              <div className="md-cell md-cell--12">
                <Field
                  label='Organization'
                  name='npwp'
                  valueField='npwp'
                  params={{ size: 50 }}
                  remoteSearch={true}
                  service={OrganizationService}
                  searchField  = {['name', 'aliasName', 'npwp']}
                  component={Searchfield}
                  itemTemplate={function(d) {
                    let nameAndAlias = ""

                    if (d.name)
                      nameAndAlias = d.name

                    if (d.aliasName)
                      nameAndAlias += " - " + d.aliasName

                    return {
                      primaryText  : nameAndAlias,
                      secondaryText: d.npwp,
                      onClick      : () => {
                        if(!this.props.disabled){
                          this.handleItemClick(d); this.hide()
                        }
                      }
                    }
                  }}
                />
              </div>
              }
              <div className="md-cell md-cell--12">
                  <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Import</Button>
              </div>
            </div>
          </div>
          {this.state.onProgress ? (
              <LinearProgress style={{margin: 0}} query={true} />
            ) : (null)}
        </div>
    )
  }

}
