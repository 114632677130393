import ApiService from '../../../../../services/api.service';

class ImportService extends ApiService {
  name= 'Import21';
  path= 'pasal4/import';
  pathImportUser= 'pasal4/import/user';
  pathImportOrganization= 'pasal4/import/organization';
  pathEbillingImport= 'pasal4/ebilling/import';

  constructor() {
    super();
    this.init()
  }
}


export default new ImportService();
