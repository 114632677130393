import http from '../../../../services/http.service';

export default {
  api: {
    async get(sptId) {
      return await http.get(`/pasal4/no/${sptId}/spt`);
    },
    // async get(orgId, type) {
    //   return await http.get('/api/spt4a2/nobupot/org/'+orgId, {
    //     params: { type: type }
    //   });
    // },
    async save(data) {
      return await http.post('/pasal4/api/spt4a2/nobupot', data);
    },
    async update(data) {
      return await http.put('/pasal4/no', data);
    },
  }
}
