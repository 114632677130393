import ApiService from '../../../../../services/api.service';
import http from "../../../../../services/http.service";

class BP21A2Service extends ApiService {
  name= 'BP_21_A2';
  path= 'spt21/a2';
  constructor() {
    super()
    this.init()
    this.initApi()
  }
  api= {
    calculate: async (param) => {
      var res = await this.http.post(this.apiLocation+'/calculation/pph21/a2', param)
      return res.data;
    },
    total: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/spt21/a2/total/${sptId}`)

      return res.data;
    },
    cetak: async (id) => {
      var res = await this.http.get(`${this.apiLocation}/spt21/a2/report/${id}`, {
        responseType: 'blob'
      })

      return res;
    },
    cetakBulk: async (sptId, ids) => {
      var res = await this.http.post(`${this.apiLocation}/spt21/a2/${sptId}/report/bulk`, ids, {
        responseType: 'blob'
      })

      return res;
    },
    cetakBulkMerge: async (ids) => {
      var res = await this.http.post(`${this.apiLocation}/spt21/a2/report/bulk/merge`, ids, {
        responseType: 'blob'
      })

      return res;
    },
    downloadCsv: async (sptId)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/spt/export`)

      return res;
    },
    email: async (category, ids) => {
      return await this.http.post(`${this.apiLocation}/email?category=${category}`, ids)
    },
    emailSpt: async (category, sptId, ids) => {
      return await this.http.post(`${this.apiLocation}/email?category=${category}&sptId=${sptId}`, ids)
    },
    deleteBulk: async (sptId, ids)=> {
      let res = await http.post(`${this.apiLocation}/${this.path}/${sptId}/delete`, ids);

      return res;
    },
  }
}


export default new BP21A2Service();
