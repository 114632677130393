import ApiService from '../../../../../services/api.service';
import http from "../../../../../services/http.service";

class BP23Service extends ApiService {
  name= 'BP_23';
  path= 'pasal23/bp23';

  api= {
    save: async(data)=> {
      var res = await this.http.post(`${this.apiLocation}/${this.path}`, data, {
        params: {
          blockBunifikasiMonth: data.blockBunifikasiMonth,
          blockBunifikasiYear: data.blockBunifikasiYear
        }
      })
      return res;
    },
    update: async(data)=> {
      var res = await this.http.put(`${this.apiLocation}/${this.path}`, data, {
        params: {
          blockBunifikasiMonth: data.blockBunifikasiMonth,
          blockBunifikasiYear: data.blockBunifikasiYear
        }
      })
      return res;
    },
    calculate: async (param) => {
      var res = await this.http.post(this.apiLocation+'/pasal23/calculation/pph23/bp-23', param)

      return res.data;
    },
    downloadCsv: async (sptId)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/spt/export`)
      return res;
    },
    total: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/total`)

      return res.data;

    },
    cetak: async (ids) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${ids[0]}/report`)

      return res;
    },
    cetakBulk: async (sptId, ids) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/report`, ids)

      return res;
    },
    cetakBulkPisah: async (sptId, ids) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/report/pisah`, ids)
      return res;
    },
    cetakBulkMerge: async (ids) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/report/bulk/merge`, ids, {
        responseType: 'blob'
      })

      return res;
    },
    cetakDaftar: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/pasal23/spt/${sptId}/report`)

      return res;
    },
    email: async (category, ids) => {
      return await this.http.post(`${this.apiLocation}/pasal23/email?category=${category}`, ids)
    },
    emailSpt: async (category, sptId, ids, companyId) => {
      if(ids.length == 1){
        return await this.http.get(`${this.apiLocation}/${this.path}/${ids[0]}/mail`, {
          params: {
            companyId: companyId
          }
        })
      } else {
        return await this.http.post(`${this.apiLocation}/${this.path}/mail`, ids, {
          params: {
            companyId: companyId
          }
        })
      }
    },
    deleteBulk: async (sptId, ids)=> {
      let res = await http.post(`${this.apiLocation}/${this.path}/${sptId}/delete`, ids);

      return res;
    },
  }

  constructor() {
    super()
    this.init()
    this.initApi()
  }
}


export default new BP23Service();
