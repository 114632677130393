import React, { Component } from 'react';
import {connect} from 'react-redux';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress
} from 'react-md';
import { Field, reduxForm, getFormValues} from 'redux-form';

import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox} from '../../../../../../components/form';
import terbilangService from '../../../../../../services/terbilang.service';

@connect((state) => ({
  formData: getFormValues('BP_4a2_Form')(state)
}))
export default class BP4a2BungaDepositoForm extends Component {

  componentDidMount() {
    this.props.connectCmp(this.reCalculate.bind(this))
  }

  async reCalculate() {
    console.log('bunga deposito')
    var form = {...this.props.formData}
    var d    = form.bungaDeposito;

    Object.keys(d).forEach((k, i) => {
      if(!(k == 'jmlBruto' || k == 'jmlPph' || k == 'terbilang' || k == 'lastModifiedBy' || k == 'lastModifiedDate' || k == 'id' || k == 'createdDate' || k == 'createdBy')) {
        var x = d[k];

        if(typeof x == 'object') {
          if(x.bruto) x.pph = x.bruto*x.tarif/100
        }
      }
    })

    await this.props.initialize(form);
    await this.calculateTotal()
  }

  // async calculate(e,v,pv,f, parent) {
  //   var form = this.props.formData;
  //   var d    = form.bungaDeposito[parent];

  //   var bruto = parseFloat(d.bruto);
  //   var tarif = parseFloat(d.tarif);

  //   if(f.search('bruto') >= 0) {
  //     bruto = parseFloat(v)
  //   } else if(f.search('tarif') >= 0) {
  //     tarif = parseFloat(v)
  //   }

  //   if(!bruto) bruto = 0;
  //   if(!tarif) tarif = 0;


  //   await this.props.change(`bungaDeposito.${parent}.pph`, bruto*tarif/100);
  //   this.calculateTotal()
  // }

  // async calculateTotal() {
  //   var form = this.props.formData;
  //   var d    = form.bungaDeposito;

  //   var jmlPph   = 0;
  //   var jmlBruto = 0;
  //   Object.keys(d).forEach((k, i) => {
  //     if(!(k == 'jmlBruto' || k == 'jmlPph' || k == 'terbilang' || k == 'lastModifiedBy' || k == 'lastModifiedDate' || k == 'id' || k == 'createdDate' || k == 'createdBy')) {
  //       var x = d[k];

  //       if(typeof x == 'object') {
  //         jmlPph   = jmlPph+ parseFloat(x.pph);
  //         jmlBruto = jmlBruto+ parseFloat(x.bruto)
  //       }
  //     }
  //   })

  //   this.props.change('bruto', jmlBruto)
  //   this.props.change('pph', jmlPph);

  //   this.props.change('terbilang', terbilangService(parseInt(jmlPph)));

  // }

  async calculate(e,v,pv,f, parent) {
    var form = this.props.formData;
    var d    = form.bungaDeposito[parent];

    var bruto = parseFloat(d.bruto);
    var tarif = parseFloat(d.tarif);

    if(f.search('bruto') >= 0) {
      bruto = parseFloat(v)
    } else if(f.search('tarif') >= 0) {
      tarif = parseFloat(v)
    }

    if(!bruto) bruto = 0;
    if(!tarif) tarif = 0;

    let pph =  Math.floor(bruto * tarif / 100);

    await this.props.change(`bungaDeposito.${parent}.pph`, pph);
    this.calculateTotal()
  }

  async calculateTotal() {
    var form = this.props.formData;
    var d    = form.bungaDeposito;

    var jmlPph   = 0;
    var jmlBruto = 0;
    Object.keys(d).forEach((k, i) => {
      if(!(k == 'jmlBruto' || k == 'jmlPph' || k == 'terbilang' || k == 'lastModifiedBy' || k == 'lastModifiedDate' || k == 'id' || k == 'createdDate' || k == 'createdBy')) {
        var x = d[k];

        if(typeof x == 'object') {
          jmlPph   = jmlPph+ parseFloat(x.pph);
          jmlBruto = jmlBruto+ parseFloat(x.bruto)
        }
      }
    })

    jmlBruto = Math.floor(jmlBruto)
    jmlPph = Math.floor(jmlPph)

    this.props.change('bruto', jmlBruto)
    this.props.change('pph', jmlPph);

    this.props.change('terbilang', terbilangService(parseInt(jmlPph)));

  }

  render() {
    return <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
      <TableHeader>
        <TableRow>
          <TableColumn>No.</TableColumn>
          <TableColumn>Jenis Penghasilan</TableColumn>
          <TableColumn>Tempat Penyimpanan</TableColumn>
          <TableColumn className='md-text-right'>Bruto(Rp)</TableColumn>
          <TableColumn className='md-text-right'>Tarif(%)</TableColumn>
          <TableColumn className='md-text-right'>PPH Di Potong(Rp)</TableColumn>
        </TableRow>
      </TableHeader>
      <TableBody>
        <TableRow>
          <TableColumn>1.</TableColumn>
          <TableColumn>Deposito Berjangka</TableColumn>
          <TableColumn></TableColumn>
          <TableColumn></TableColumn>
          <TableColumn></TableColumn>
          <TableColumn></TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn></TableColumn>
          <TableColumn>a. Rupiah</TableColumn>
          <TableColumn>
            <Field
              label     = 'DN/LN'
              name      = 'bungaDeposito.depositoBerjangkaRupiah.dnLn'
              component = {Searchfield}
              options   = {[{id: 'DN', name: 'DN'}, {id: 'LN', name: 'LN'}]}
            />
          </TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'bungaDeposito.depositoBerjangkaRupiah.bruto'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'depositoBerjangkaRupiah')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'bungaDeposito.depositoBerjangkaRupiah.tarif'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'depositoBerjangkaRupiah')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'bungaDeposito.depositoBerjangkaRupiah.pph'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
            />
          </TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn></TableColumn>
          <TableColumn>b. Valuta Asing dengan Premi Forward</TableColumn>
          <TableColumn>
            <Field
              label     = 'DN/LN'
              name      = 'bungaDeposito.depositoBerjangkaValutaAsingPremiForward.dnLn'
              component = {Searchfield}
              options   = {[{id: 'DN', name: 'DN'}, {id: 'LN', name: 'LN'}]}
            />
          </TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'bungaDeposito.depositoBerjangkaValutaAsingPremiForward.bruto'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'depositoBerjangkaValutaAsingPremiForward')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'bungaDeposito.depositoBerjangkaValutaAsingPremiForward.tarif'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'depositoBerjangkaValutaAsingPremiForward')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'bungaDeposito.depositoBerjangkaValutaAsingPremiForward.pph'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
            />
          </TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn></TableColumn>
          <TableColumn>c. Valuta Asing tanpa Premi Forward</TableColumn>
          <TableColumn>
            <Field
              label     = 'DN/LN'
              name      = 'bungaDeposito.depositoBerjangkaValutaAsingTanpaPremiForward.dnLn'
              component = {Searchfield}
              options   = {[{id: 'DN', name: 'DN'}, {id: 'LN', name: 'LN'}]}
            />
          </TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'bungaDeposito.depositoBerjangkaValutaAsingTanpaPremiForward.bruto'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'depositoBerjangkaValutaAsingTanpaPremiForward')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'bungaDeposito.depositoBerjangkaValutaAsingTanpaPremiForward.tarif'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'depositoBerjangkaValutaAsingTanpaPremiForward')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'bungaDeposito.depositoBerjangkaValutaAsingTanpaPremiForward.pph'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
            />
          </TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn>2</TableColumn>
          <TableColumn>Sertifikat Deposito</TableColumn>
          <TableColumn>
            <Field
              label     = 'DN/LN'
              name      = 'bungaDeposito.sertifikatDeposito.dnLn'
              component = {Searchfield}
              options   = {[{id: 'DN', name: 'DN'}, {id: 'LN', name: 'LN'}]}
            />
          </TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'bungaDeposito.sertifikatDeposito.bruto'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'sertifikatDeposito')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'bungaDeposito.sertifikatDeposito.tarif'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'sertifikatDeposito')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'bungaDeposito.sertifikatDeposito.pph'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
            />
          </TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn>3</TableColumn>
          <TableColumn>Tabungan</TableColumn>
          <TableColumn>
            <Field
              label     = 'DN/LN'
              name      = 'bungaDeposito.tabungan.dnLn'
              component = {Searchfield}
              options   = {[{id: 'DN', name: 'DN'}, {id: 'LN', name: 'LN'}]}
            />
          </TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'bungaDeposito.tabungan.bruto'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'tabungan')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'bungaDeposito.tabungan.tarif'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'tabungan')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'bungaDeposito.tabungan.pph'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
            />
          </TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn>4</TableColumn>
          <TableColumn>Sertifikat Bank Indonesia	</TableColumn>
          <TableColumn>

          </TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'bungaDeposito.sertifikatBankIndonesia.bruto'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'sertifikatBankIndonesia')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'bungaDeposito.sertifikatBankIndonesia.tarif'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'sertifikatBankIndonesia')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'bungaDeposito.sertifikatBankIndonesia.pph'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
            />
          </TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn>5</TableColumn>
          <TableColumn>Jasa Giro</TableColumn>
          <TableColumn>

          </TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'bungaDeposito.jasaGiro.bruto'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'jasaGiro')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'bungaDeposito.jasaGiro.tarif'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'jasaGiro')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'bungaDeposito.jasaGiro.pph'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
            />
          </TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn>6</TableColumn>
          <TableColumn>
          <Field
              name      = 'bungaDeposito.no6.jenisPenghasilan'
              component = {Textfield}
            />
          </TableColumn>
          <TableColumn>

          </TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'bungaDeposito.no6.bruto'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'no6')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'bungaDeposito.no6.tarif'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'no6')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'bungaDeposito.no6.pph'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
            />
          </TableColumn>
        </TableRow>
        <TableRow style={{background: '#ddd'}}>
          <TableColumn></TableColumn>
          <TableColumn>Jumlah</TableColumn>
          <TableColumn>
          </TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'bruto'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'pph'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
            />
          </TableColumn>
        </TableRow>
        <TableRow style={{background: '#ddd'}}>
          <TableColumn></TableColumn>
          <TableColumn>Terbilang</TableColumn>
          <TableColumn colSpan={4}>
          <Field
              // label='Bruto'
              name      = 'terbilang'
              component = {Textfield}
              disabled
            />
          </TableColumn>
        </TableRow>
      </TableBody>
    </DataTable>
  }
}
