import React, { Component } from 'react';
import { connect } from 'react-redux';

import FormDialog from './MasterPesangon.dialog.view';
import MasterPesangonService from './MasterPesangon.service';
import ListView from '../../../../components/entity/listView';
import ColumnService from '../../../../services/column.service';

@connect(MasterPesangonService.stateConnectSetting(), MasterPesangonService.actionConnectSetting())
export default class MasterPesangonView extends ListView {
  service=MasterPesangonService
  FormDialog=FormDialog

  columns=[
    {label: "word.code",  value: "code", isDefaultSort:true, show:true, isSearchable:true},
    {label: "word.start",  value: function(d){ return ColumnService.money(d.start) }, type: "func", isDefaultSort:true, show:true, isSearchable:true},
    {label: "word.to",  value: function(d){ return ColumnService.money(d.to) }, type: "func", isDefaultSort:true, show:true, isSearchable:true},
    {label: "word.percent",  value: function(d){ return ColumnService.money(d.percent) }, type: "func", isDefaultSort:true, show:true, isSearchable:true},
  ]
}
