import ApiService from '../../../../../services/api.service'
import http from '../../../../../services/http.service'

class LogFileValidasiService extends ApiService {
  name= 'LogFileValidasi'
  path= 'ebupot/log-validasi'
  repoType= 'QueryDsl'
  advancedSearch=true

  columns=[
    {label: "word.id", searchField: "id", value: "id", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
    {label: "word.sheet", searchField: "sheet", value: "sheet", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.line", searchField: "line", value: "line", type: 'number', isDefaultSort:true, isSortable:true, show:true, isSearchable:true},
    {label: "word.message", searchField: "message", value: "message", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
  ]

  constructor(){
    super()
    this.init()
    this.initApi()
  }

}

export default new LogFileValidasiService();