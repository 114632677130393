import ApiService from '../../../../../services/api.service'
import moment from 'moment'

class LawanTransaksi26Service extends ApiService {
  name= 'LawanTransaksi26'
  path= 'ebupot/lt26'
  rootpath= 'ebupot/lawan-transaksi-26'
  advancedSearch=true

  constructor(){
    super()
    this.init()
    this.initApi()
  }

  columns=[
    {label: "word.id", searchField: "id", value: "id", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
    {label: "word.npwpProfile", searchField: "npwpProfile", value: "npwpProfile", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.nama", searchField: "nama", value: "nama", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.dob", searchField: "dob", value: (d)=> {
      try {
        return d.dob[2] + "-" + d.dob[1] + "-" + d.dob[0]
      } catch(e){
        return "-"
      }
    }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.email", searchField: "email", value: "email", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.tin", searchField: "tin", value: "tin", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.noKitas", searchField: "noKitas", value: "noKitas", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.noPassport", searchField: "noPassport", value: "noPassport", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.negara", searchField: "negara", value: "negara", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.alamat", searchField: "alamat", value: "alamat", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {width: 150, label: "word.createdBy", searchField: "createdBy", value: "createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.createdDate", searchField: "createdDate", value: (d)=> {
      if(d){
        return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
      } else {
        return "-"
      }
    }, type: 'func', isDefaultSort:true, isSortable:true, show:true, isSearchable:true},
    {width: 150, label: "word.lastModifiedBy", searchField: "lastModifiedBy", value: "lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.lastModifiedDate", searchField: "lastModifiedDate", value: (d)=> {
      if(d.lastModifiedDate){
        return moment(d.lastModifiedDate).format('DD/MM/YYYY HH:MM')
      } else {
        return "-"
      }
    }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
  ]

}

export default new LawanTransaksi26Service();
