import ApiService from '../../../../../services/api.service';

class BP21DAFTARBIAYAService extends ApiService {
  name= 'BP_21_DAFTAR_BIAYA';
  path= 'spt21/db';
  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    getData: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/spt21/db/${sptId}/spt`)
      
      return res
    },

    cetakDaftar: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/spt21/db/${sptId}/report-list`)

      return res;
    },
    cetakBulk: async (ids) => {
      var res = await this.http.post(`${this.apiLocation}/spt21/db/daftar/report/bulk`, ids, {
        responseType: 'blob'
      })

      return res;
    },
    downloadCsv: async (sptId)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/spt/export`)

      return res;
    },
  }
}


export default new BP21DAFTARBIAYAService();
