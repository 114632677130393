import ApiService from '../../../../../../services/api.service';

class ImportSSP15SetorSendiriService extends ApiService {
  name= 'ImportSSP15SetorSendiri';
  path= 'pasal15/log/csv';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    validasi: async (fileId, page) => {
      if(page){
        page = page
      } else {
        page = 0
      }
      var res = await this.http.get(this.apiLocation+'/pasal15/validation-csv', {
        params: {
          fileId : fileId,
          page: page,
          size: 100
        }
      })

      return res.data;
    },
    downloadTemplate: async (category)=> {
      var res = await this.http.get(`${this.apiLocation}/pasal15/download/csv/template`, {
        params: {
          category: category
        }
      })
      return res;
    }
  }
}


export default new ImportSSP15SetorSendiriService();
