import ApiService from '../../../../../../services/api.service';
import { splitDecimal } from 'react-number-format/lib/utils';

class ExportCsv15Service extends ApiService {
  name= 'ExportCsv15';
  path= 'pasal15';
  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api={
    // /pasal15/bp/{esptId}/spt/export
    // /pasal15/lt/export
    // /pasal15/ssp/{esptId}/spt/export
    // /pasal15/pbk/{esptId}/spt/export
    // /pasal15/bp-24/{esptId}/spt/export
    getExportLT: async(sptId)=> {
      return await this.http.get(`${this.apiLocation}/${this.path}/lt/export`)
    },
    getExportBP: async(sptId)=> {
      return await this.http.get(`${this.apiLocation}/${this.path}/bp/${sptId}/spt/export`)
    },
    getExportSSPsendiri: async(sptId)=> {
      return await this.http.get(`${this.apiLocation}/${this.path}/ssp-dibayar-sendiri/${sptId}/spt/export`)
    },
    getExportSSP: async(sptId)=> {
      return await this.http.get(`${this.apiLocation}/${this.path}/ssp/${sptId}/spt/export`)
    },
    getExportPBK: async(sptId)=> {
      return await this.http.get(`${this.apiLocation}/${this.path}/pbk/${sptId}/spt/export`)
    },
    getExportBP24: async(sptId)=> {
      return await this.http.get(`${this.apiLocation}/${this.path}/bp-24/${sptId}/spt/export`)
    },
    getInfo: async (sptId) => {
      return await this.http.get(`${this.apiLocation}/${this.path}/spt/${sptId}/count`)
    },
    getSummary: async(sptId)=> {
      return await this.http.get(`${this.apiLocation}/${this.path}/spt/${sptId}/info`)
    },
    getTFTotal: async(sptId)=> {
      return await this.http.get(`${this.apiLocation}/${this.path}/tf/${sptId}/page`, {
        params: {
          page: 0,
        }
      })
    },
    getFTotal: async(sptId)=> {
      return await this.http.get(`${this.apiLocation}/${this.path}/f/${sptId}/page`, {
        params: {
          page: 0,
        }
      })
    },
    getA1Total: async(sptId)=> {
      return await this.http.get(`${this.apiLocation}/${this.path}/a1/${sptId}/page`, {
        params: {
          page: 0,
        }
      })
    },
    getA2Total: async(sptId)=> {
      return await this.http.get(`${this.apiLocation}/${this.path}/a2/${sptId}/page`, {
        params: {
          page: 0,
        }
      })
    }
  }
}

export default new ExportCsv15Service();
