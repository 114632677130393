import ApiService from '../../../../../services/api.service';
import { splitDecimal } from 'react-number-format/lib/utils';

class Email21LogService extends ApiService {
  name= 'Email21Log';
  path= 'log-email/page';
  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api={
    exportSummary: async (data) => {
      var res = await this.http.post(`${this.apiLocation}/log-email/summary`, data, {
        responseType: 'blob'
      })
      return res;
    },
    resend: async (data) => {
      var res = await this.http.post(`${this.apiLocation}/log-email/resend`, data)
      return res;
    },
    logEmailDetail: async (compId, id) => {
      var res = await this.http.get(`${this.apiLocation}/log/mail/${compId}/company/${id}`)
      return res;
    },
  }
}

export default new Email21LogService();
