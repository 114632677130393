import React, { Component } from 'react';
import { connect } from 'react-redux';

import FormDialog from './MasterLawanTransaksi4a2.dialog.view';
import MasterLawanTransaksi4a2Service from './MasterLawanTransaksi4a2.service';
import ListView from '../../../../components/entity/listView';
import ColumnService from '../../../../services/column.service';
import LogExportService from "../../../Main/Export/LogExport.service";
import ParamService from "../../../../services/param.service";
import download from "downloadjs";

@connect(MasterLawanTransaksi4a2Service.stateConnectSetting(), MasterLawanTransaksi4a2Service.actionConnectSetting())
export default class MasterLawanTransaksi4a2View extends ListView {

  service=MasterLawanTransaksi4a2Service
  FormDialog=FormDialog

  columns=[
    {label: "word.npwpProfile",  value: "npwpProfile", isDefaultSort:false, show:true, isSearchable:true},
    {label: "word.npwp",  value: "npwp", isDefaultSort:false, show:true, isSearchable:true},
    {label: "word.name",  value: "name", isDefaultSort:false, show:true, isSearchable:true},
    {label: "word.address",  value: "address", isDefaultSort:false, show:true, isSearchable:true},
  ]

  barActions() {
    return [
      ...this._barActions,
      // {
      //   label:'word.exportCsv',
      //   iconClassName:'mdi mdi-file-excel',
      //   // disabledFunc:() => this.props.table.selected.length === 0,
      //   onClick:() => this.handleExportAll()
      // },
    ]
  }

  async handleExportAll() {
    let selected = this.props.table.selected

    if (selected.length > 0) {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export CSV',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: 'Apakah anda yakin akan meng-export CSV data yang diceklis?'
      }));

      if(dialog) {
        let ids = [];

        selected.map(function (i) {
          ids.push(i.id)
        });

        let res = await LogExportService.api.exportBulkCsv('spt4a2/lt/4a2', ids);

        let filename = res.headers.filename;

        download(res.data, filename);
      }
    }
    else {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Export CSV',
        initialValue: {},
        height: 'auto',
        width: 400,
        okText: 'Ya',
        text: (
          <div>
            <this.ig.Field
              label='Nama'
              name='name__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='NPWP'
              name='npwp__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Email'
              name='email__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
          </div>
        )
      }));

      if (dialog) {
        let param = ParamService.convert(dialog)

        let res = await LogExportService.api.exportByParamCsv('spt4a2/lt/4a2', param);

        let filename = res.headers.filename;

        download(res.data, filename);
      }
    }
  }
}
