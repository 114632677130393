import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {Field, getFormValues, reduxForm} from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../components/form';

import ImportAll21Service from './ImportAll21.service';
import ListView from '../../../components/entity/listView';
import ColumnService from '../../../services/column.service';
import axios from 'axios';
import izitoast from 'izitoast';
import download from "downloadjs";
import ListViewImport from '../../../components/entity/ListViewImport';
import formDialog from './ImportAll21.dialog.view'
import UserAccess21 from './../pph/21_26/UserAccess/UserAccess.service'

@reduxForm({form: 'IMPORT_21_Form', destroyOnUnmount: true})
@connect((state) => ({
  ...ImportAll21Service.stateConnectSetting()(state),
  formData: getFormValues('IMPORT_21_Form')(state),
  user: state.auth.user,
  // EPPT CORE
  auth: state.auth.company
  // EPPT CORE
}), ImportAll21Service.actionConnectSetting())
export default class ImportAll21View extends ListViewImport {
    service=ImportAll21Service
    FormDialog=formDialog
    importPath='/upload/csv'

    static contextTypes = {
        showDialog: PropTypes.func.isRequired,
    };

    constructor(props){
      super(props)
      this.state = {
        enableA1: false,
        enableA1Auto: false,
        enableTf: false,
        enableF: false,
        enableSm: false,
        enableSt: false
      }
    }

    async componentDidMount(){
      try {
          this.props.change('no', false)
          this.props.change('migration', false)
          this.props.change('replace', false)
          var companyId = this.props.match.params.companyId
          let res21 = await UserAccess21.api.getSettingAccess(companyId)
          if(res21.data.active == true){
            this.setState({ filter21IsTrue: true })
            let per21 = await UserAccess21.api.cekAccess()
            this.setState({
              filter21Menu: per21.data
            })
            var c = ["SPT_21_TF", "SPT_21_F", "SPT_21_SM", "SPT_21_A1", "SPT_21_A1_AUTO", "SPT_21_SATU_TAHUN"]
            c.map((d)=> {
              var accessLength = per21.data.filter((d2)=> {
                  return d2.access == d
              })
              if(accessLength.length > 0 && d == "SPT_21_A1"){
                  this.setState({ enableA1: true, enableA1Auto: true })
              } else {
                  this.setState({ enableA1: false, enableA1Auto: false })
              }
              if(accessLength.length > 0 && d == "SPT_21_TF"){
                  this.setState({ enableTf: true })
              } else {
                  this.setState({ enableTf: false })
              }
              if(accessLength.length > 0 && d == "SPT_21_F"){
                  this.setState({ enableF: true })
              } else {
                  this.setState({ enableF: false })
              }
              if(accessLength.length > 0 && d == "SPT_21_SM"){
                  this.setState({ enableSm: true })
              } else {
                  this.setState({ enableSm: false })
              }
              if(accessLength.length > 0 && d == "SPT_21_SATU_TAHUN"){
                  this.setState({ enableSt: true })
              } else {
                  this.setState({ enableSt: false })
              }
            })

          }
      } catch(e){
          console.log(e, "GET Error")
      }
  }

    async handleDownloadTemplate() {
        var showDialog = await this.context.showDialog(()=> ({
          title: 'Download Template',
          initialValue: {
              category: null
          },
          width: 800,
          height: 600,
          contentStyle: {
            height: 600
          },
          okText: 'Download',
          text: (
            <div>
                <this.ig.Field
                    label='Kategori'
                    name='category'
                    className="md-cell md-cell--12"
                    component={this.ig.Searchfield}
                    options={[
                        { id: 'TF', name: 'BP Tidak Final' },
                        { id: 'F', name: 'BP Final' },
                        { id: 'A1', name: 'BP 1721 A1' },
                        { id: 'A1_AUTO', name: 'BP 1721 A1 Auto Kalkulasi' },
                        { id: 'SM', name: 'Pemotongan Satu Masa' },
                        { id: 'SM_TL', name: 'Pemotongan Satu Masa Tidak Lebih dari PTKP' },
                        { id: 'SSP', name: 'SSP' },
                        { id: 'DB', name: 'Daftar Biaya' },
                        { id: 'INDUK', name: 'Induk' },
                        { id: 'SIGNER_21', name: 'Signer Pasal 21' },
                        { id: 'LAWAN_TRANSAKSI_PP_21', name: 'Lawan Transaksi PP' },
                        { id: 'LAWAN_TRANSAKSI_A1_21', name: 'Lawan Transaksi A1' },
                        // { id: 'LAWAN_TRANSAKSI_A2_21', name: 'Lawan Transaksi A2' },
                    ]}
                    validate={validation.required}
                />
            </div>
          )
        }))
        if(showDialog){
            var report = await this.service.api.downloadTemplate(showDialog.category)
            window.open(report.data.url, `TemplatePasal21${showDialog.category}.csv`);
        }
    }

    searchForm() {
        return (
          <this.SearchContainer>
            <this.ig.Field
              label='Kategori'
              name='category__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Filename'
              name='filename__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Pesan'
              name='message__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Status'
              name='status__contains'
              className="md-cell md-cell--12"
              component={this.ig.Searchfield}
              options={[
                  { id: 'COMPLETED', name: 'COMPLETED' },
                  { id: 'FAILED', name: 'FAILED' }
              ]}
            />
          </this.SearchContainer>
        )
    }

    import(){
        var isBp = false
        var isSigner = false
        if(this.props.formData && this.props.formData.category){
            var category = this.props.formData.category
            if(category === 'TF' || category === 'F' || category === 'A1' || category === 'A1_AUTO') isBp = true
            if(category === 'SIGNER_21') isSigner = true
        }
        const { handleSubmit, submitting, valid, pristine } = this.props;

        var category = [
          { id: 'SSP', name: 'SSP' },
          { id: 'DB', name: 'Daftar Biaya' },
          { id: 'INDUK', name: 'Induk' },
          { id: 'SPT', name: 'SPT' },
          { id: 'SIGNER_21', name: 'Signer Pasal 21' },
          { id: 'LAWAN_TRANSAKSI_PP_21', name: 'Lawan Transaksi PP' },
          { id: 'LAWAN_TRANSAKSI_A1_21', name: 'Lawan Transaksi A1' },
          // { id: 'LAWAN_TRANSAKSI_A2_21', name: 'Lawan Transaksi A2' },
        ]

        if(this.state.filter21IsTrue){
            var c = ["SPT_21_TF", "SPT_21_F", "SPT_21_SM", "SPT_21_A1", "SPT_21_A1_AUTO", "SPT_21_SATU_TAHUN"]
            c.map((d)=> {
              if(this.state.filter21Menu && this.state.filter21Menu.length > 0){
              var accessLength = this.state.filter21Menu.filter((d2)=> {
                  return d2.access == d
              })
              if(accessLength.length > 0 && d == "SPT_21_TF"){
                category.unshift({ id: 'TF', name: 'BP Tidak Final' });
              }
              if(accessLength.length > 0 && d == "SPT_21_F"){
                category.unshift({ id: 'F', name: 'BP Final' });
              }
              if(accessLength.length > 0 && d == "SPT_21_SM"){
                category.unshift({ id: 'SM_TL', name: 'Pemotongan Satu Masa Tidak Lebih dari PTKP' });
                category.unshift({ id: 'SM', name: 'Pemotongan Satu Masa' });
              }
              if(accessLength.length > 0 && d == "SPT_21_A1"){
                category.unshift({ id: 'A1', name: 'BP 1721 A1' });
                category.unshift({ id: 'A1_AUTO', name: 'BP 1721 A1 Auto Kalkulasi' });
              }
          }
          })
        } else {
            category.unshift({ id: 'SM_TL', name: 'Pemotongan Satu Masa Tidak Lebih dari PTKP' });
            category.unshift({ id: 'SM', name: 'Pemotongan Satu Masa' });
            category.unshift({ id: 'F', name: 'BP Final' });
            category.unshift({ id: 'TF', name: 'BP Tidak Final' });
            category.unshift({ id: 'A1', name: 'BP 1721 A1' });
            category.unshift({ id: 'A1_AUTO', name: 'BP 1721 A1 Auto Kalkulasi' });
        }

        return (
            <div className='mpk-layout column fill'>
              <this.ImportDialogResult dataResults={this.state.importResults} visible={this.state.showImportResult} onHide={()=> {this.setState({showImportResult: false})}} />
              <div className='flex'>
                <div className='md-grid'>
                  <div className="md-cell md-cell--12">
                    <Field
                      label='Select a File'
                      name='file'
                      multiple
                      twoLines
                      component={Fileinput}
                      validate={validation.required}
                    />
                  </div>
                  <this.ig.Field
                    label='Kategori'
                    name='category'
                    className="md-cell md-cell--12"
                    component={this.ig.Searchfield}
                    options={category}
                    validate={validation.required}
                    onChange={(e, val)=> {
                      this.props.change('type', val)
                    }}
                  />
                  {isBp &&
                    <Field
                      label='Aktifkan Penomoran Bukti Potong Otomatis ?'
                      name='no'
                      component={Switch2}
                      className="md-cell md-cell--12"
                    />
                  }
                  <Field
                    label='Import Migrasi'
                    name='migration'
                    component={Switch2}
                    className="md-cell md-cell--12"
                  />
                  {isSigner &&
                    <Field
                      label='Replace'
                      name='replace'
                      component={Switch2}
                      className="md-cell md-cell--12"
                    />
                  }
                  <div className="md-cell md-cell--12">
                      <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Import</Button>
                  </div>
                </div>
              </div>
              {this.state.onProgress ? (
                  <this.LinearProgress style={{margin: 0}} query={true} />
                ) : (null)}
              {this.showAutomatisTab &&
                <Tabs className='mpk-tab-white' onTabChange={this.handleTabChange.bind(this)} tabId="ImportTab">
                    <Tab label="Manual">

                    </Tab>
                    <Tab label="Otomatis">

                    </Tab>
                </Tabs>
              }
            </div>
        )
      }

}
