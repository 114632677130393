import React from 'react'
import IDBill from './IDBill'
import env from 'env'

export default class EbillingWidget extends React.Component {

    componentDidMount(){
        var url = ''
        if(env.mode == 'prod'){
            url = 'https://ebilling.pajakku.com/static/libs/idbill-widget-pajakku.min.js'
        } else {
            url = 'https://ebilling.bdg.pajakku.com/static/libs/idbill-widget-pajakku.min.js'
        }
        const script = document.createElement("script");
        script.src = url;
        script.async = true;
        document.body.appendChild(script);
    }
    
    render(){
        var widgetClientId = ''
        if(env.mode == 'prod'){
            widgetClientId = "5HRCQqcTbnf6V32U6SMn4zKzEw9xwC9X8sSvhsv8"
        } else {
            widgetClientId = "BdJP5n1hzZNT5o7GAX5GWEQbJnJXvW6nUtNnm5ig"
        }
        return (
            <IDBill widgetClientId={widgetClientId} />
        )
    }
}