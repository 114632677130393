import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Button, Chip} from 'react-md';

//import FormDialog from './Organization.dialog.view';
import ListView from '../../../../../components/entity/listView';
import SPT22SignerService from './SPT22Signer.service';
import SPT22SignerDialogView from './SPT22Signer.dialog';


@connect(SPT22SignerService.stateConnectSetting(), SPT22SignerService.actionConnectSetting())
export default class SPT22SignerView extends ListView {
  service=SPT22SignerService
  FormDialog=SPT22SignerDialogView

  beforeFetch(param) {
    if(this.props.organizationId) {
      if(this.props.organizationId != 'new') {
        param['orgId'] = this.props.organizationId;
        param['organizationId.equals'] = this.props.organizationId;
      }
    }
  }

  fetchOption() {
    return {
      path: this.service.path+'/'+this.props.organizationId+'/organization'
    }
  }

  header() {
    return this.commandBar()
  }

  render() {
    return (
      <this.viewContainer>

        <div className="flex mpk-layout">
          {this.tableView()}
          {this.tableSetting()}

          <this.FormDialog
            organizationId={this.props.organizationId}
            companyId={this.props.companyId}
            height={400}
            visible={this.state.showForm}
            add={this.props.tableActions.addDataItem}
            formData={this.state.formData}
            onSuccess={()=> this.fetchData()}
            onClose={() => this.setState({showForm:false})}
          />

          {this.dialogConfirm()}
        </div>
      </this.viewContainer>
    )
  }

  columns=[
    {isSortable: true, label: "word.npwpProfile",  value: "npwpProfile", isDefaultSort:false, show:true, isSearchable:true},
    {isSortable: true, label: "entities.SPT21Signer.namaPenandaTanganBp",  value: "namaPenandaTanganBp", show:true, isSearchable:true, className: "mpk-font-size-S"},
    {isSortable: true, label: "entities.SPT21Signer.npwpPenandaTanganSptInduk",  value: "npwpPenandaTanganSpt", show:true, isSearchable:true},
    {isSortable: true, label: "entities.SPT21Signer.namaPenandaTanganSptInduk",  value: "namaPenandaTanganSpt", show:true, isSearchable:true},
    {isSortable: true, label: "entities.SPT21Signer.start",  value: "start", show:true, isSearchable:true},
    {isSortable: true, label: "entities.SPT21Signer.end",  value: "end", show:true, isSearchable:true},
    {isSortable: true, label: "entities.SPT21Signer.imagePenandaTanganBp",  value: "imagePenandaTanganBp", show:true, isSearchable:true},
    {isSortable: true, label: "entities.SPT21Signer.imagePenandaTanganInduk",  value: "imagePenandaTanganInduk", show:true, isSearchable:true},
  ]
}
