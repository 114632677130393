import React, { Component } from 'react';
import { connect } from 'react-redux';

import FormDialog from './MasterBiayaJabatan.dialog.view';
import MasterBiayaJabatanService from './MasterBiayaJabatan.service';
import ListView from '../../../../components/entity/listView';
import ColumnService from '../../../../services/column.service';

@connect(MasterBiayaJabatanService.stateConnectSetting(), MasterBiayaJabatanService.actionConnectSetting())
export default class MasterBiayaJabatanView extends ListView {
  service=MasterBiayaJabatanService
  FormDialog=FormDialog

  columns=[
    {label: "word.code",  value: "code", isDefaultSort:true, show:true, isSearchable:true},
    {label: "word.maximumPercentage",  value: function(d){ return ColumnService.money(d.start) }, type: "func", isDefaultSort:true, show:true, isSearchable:true},
    {label: "word.maximalNumber",  value: function(d){ return ColumnService.money(d.to) }, type: "func", isDefaultSort:true, show:true, isSearchable:true},
  ]
}
