import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Avatar
} from 'react-md';
import {Textfield, TextfieldMask, validation, Searchfield} from '../../../../components/form';
import NpwpService from './../../../Main/pph/NpwpService';
import FormView from '../../../../components/entity/form.view';
import PaymentOrderService from './PaymentOrder.service';
import utilsService from '../../../../services/utils.service';
import TextField from 'react-md/lib/TextFields/TextField';
import AkunPembayaranService from "modules/Main/Ebilling/AkunPembayaran/AkunPembayaran.service";
import BankService from "modules/Main/Ebilling/Bank/Bank.service";
import PaymentNotificationService from "modules/Main/Ebilling/PaymentNotification/PaymentNotification.service";
import FileHeaderService from "modules/Main/Ebilling/FileHeader/FileHeader.service";
import Constants from '../Constants/Constants';
import iziToast from 'izitoast';

@reduxForm({form: 'MandiriCmForm', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  access: state.entity.access.api.find,
  formData: getFormValues('MandiriCmForm')(state)
}))
export default class MandiriCmForm extends FormView {
  translate = false
  service = PaymentOrderService
  viewType=2
  akunPembayaranService=AkunPembayaranService
  bankService=BankService
  paymentNotificationService=PaymentNotificationService
  fileHeaderService=FileHeaderService

  titleHeader() {
    return `Proses Pembayaran Mandiri CM`;
  }
  initialData = {}

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      order: {
        type: 'all',
        data: [],
        paymentMethod: {},
      },
      akun:[]
    }
  }

  async process() {
    let order = this.state.order;

    let {paymentAccount, companyId, name, debitAccountNumber} = this.props.formData;

    if(companyId.trim() === '') {
      this.toast.warning({
        title: 'Peringatan',
        message: 'Corporate ID Mandiri CM harus diisi.'
      })

      return
    }

    if(name.trim() === '') {
      this.toast.warning({
        title: 'Peringatan',
        message: 'User Mandiri CM harus diisi.'
      })

      return
    }

    if(debitAccountNumber.trim() === '') {
      this.toast.warning({
        title: 'Peringatan',
        message: 'Nomor Rekening Mandiri CM harus diisi.'
      })

      return
    }

    let billingList = [];
    let sspList = [];
    let totalAmount = 0
    let totalItem = 0
    let approval = 'S'

    let orderDatas = order.data

    orderDatas.forEach(orderData => {
      if (orderData.idBilling) {
        billingList.push(orderData)
      }
      else {
        sspList.push(orderData)
      }

      totalAmount += orderData.amount
      totalItem ++
    })

    let data = {
      billingList,
      sspList,
      totalAmount,
      totalItem,
      paymentAccount: paymentAccount.id,
      approval,
    }

    try {
      let res = null;

      let params = this.props.match.params
      let companyId = params.companyId
      let subscriptionId = params.subscriptionId

      if(this.state.order.type === 'selected') {
        let check = await this.paymentNotificationService.api.validate(data)

        let jumlah = check.totalItem
        let jumlahIdBilling = check.billingCount
        let jumlahTanpaIdBilling = check.sspCount
        let total = utilsService.number.format(check.totalAmount)

        let confirmOrderChecked = this.context.showDialog((props, res, rej) =>({
          title: 'Konfirmasi',
          initialValue:{},
          okText: 'Order',
          width: 400,
          text: (
            <p>
              Jumlah SSP yang akan diorder adalah {jumlah} ({jumlahIdBilling} SSP dengan ID billing dan {jumlahTanpaIdBilling} SSP tanpa ID billing) dengan total jumlah setor  {total}. Apakah anda yakin melanjutkan proses order?
            </p>
          )
        }))

        confirmOrderChecked.then((ok) => {
          if(ok) {
            res = this.paymentNotificationService.api.create(check)

            iziToast.success({
              title: 'Sukses',
              message: 'Transaksi sedang diproses, silahkan cek di halaman order.'
            })

            this.props.history.push(`/company/${companyId}/${subscriptionId}/init/EBILLING/paymentNotification`);
          }
        })
      } else {
        let paymentOrder = JSON.parse(localStorage.getItem('paymentOrder'))

        let data = paymentOrder.criteria
        data.paymentAccount = paymentAccount.id
        data.approval = approval

        let check = await this.paymentNotificationService.api.validateAll(data)

        let jumlah = check.totalItem
        let jumlahIdBilling = check.billingCount
        let jumlahTanpaIdBilling = check.sspCount
        let total = utilsService.number.format(check.totalAmount)

        let confirmOrderAll = this.context.showDialog((props, res, rej) =>({
          title: 'Konfirmasi',
          initialValue:{},
          okText: 'Order',
          width: 400,
          text: (
            <p>
              Jumlah SSP yang akan diorder adalah {jumlah} ({jumlahIdBilling} SSP dengan ID billing dan {jumlahTanpaIdBilling} SSP tanpa ID billing) dengan total jumlah setor  {total}. Apakah anda yakin melanjutkan proses order?
            </p>
          )
        }))

        confirmOrderAll.then((ok) => {
          if(ok) {
            res = this.paymentNotificationService.api.createAll(paymentOrder.criteria)

            iziToast.success({
              title: 'Sukses',
              message: 'Transaksi sedang diproses, silahkan cek di halaman order.'
            })

            this.props.history.push(`/company/${companyId}/${subscriptionId}/init/EBILLING/paymentNotification`);
          }
        })
      }
    } catch (e) {
      iziToast.warning({
        title: e.name,
        message: e.message
      })
    }
  }

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;

    let processDisabled = (!this.props.formData.companyId || !this.props.formData.name || !this.props.formData.debitAccountNumber);

    return (
      <div>
        <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>Back</Button>

        <Button raised primary onClick={()=> this.process()} disabled={processDisabled}>Process</Button>
      </div>
    )
  }

  async refreshPaymentAccount() {
    let paymentOrder = localStorage.getItem('paymentOrder')

    let paymentAccount = []

    if (paymentOrder) {
      paymentOrder = JSON.parse(paymentOrder)

      paymentAccount = await AkunPembayaranService.api.findByOrderTypeId(paymentOrder.paymentMethod.id)
    }
    else {
      paymentAccount = await AkunPembayaranService.api.find()
    }

    this.setState({akun:paymentAccount})
  }

  async initData() {
    //let res = await this.service.api.getFileTransaksi(this.props.match.params.id);

    let paymentOrder = localStorage.getItem('paymentOrder');

    if(paymentOrder) this.setState({'order': JSON.parse(paymentOrder)});

    await this.refreshPaymentAccount();

    this.props.initialize({});
  }

  async onItemClick(d) {
    this.props.initialize(d)
  }

  async addPaymentAccount() {
    let dialog = await this.context.showDialog((props, res, rej) =>({
      title: 'Tambah',
      initialValue:{},
      okText: 'Save',
      width: 400,
      text: (
        <div className='md-grid'>
          <div className='md-grid'>
            <Field
              label='Order Type'
              name='orderType'
              className="md-cell md-cell--12"
              component={Searchfield}
              valueField="id"
              viewField="name"
              service={BankService}
              validate={validation.required}
            />

            <Field
              label='Corporate ID Mandiri CM'
              name='companyId'
              className="md-cell md-cell--12"
              component={Textfield}
              validate={validation.required}
            />

            <Field
              label='User Mandiri CM'
              name='name'
              className="md-cell md-cell--12"
              component={Textfield}
              validate={validation.required}
            />

            <Field
              label='Nomor Rekening Mandiri CM'
              name='accountNumber'
              className="md-cell md-cell--12"
              component={Textfield}
              validate={validation.required}
            />
          </div>
        </div>
      )
    }));

    if (dialog === undefined)
      dialog = {}

    if(dialog) {
      try {
        let res = await this.akunPembayaranService.api.save(dialog)

        iziToast.success({
          title: 'Sukses',
          message: 'Akun pembayaran sukses dibuat.'
        })
      } catch (e) {
        iziToast.warning({
          title: e.name,
          message: e.message
        })
      }
    }
  }

  formView() {
    let formData = {};

    if(this.props.formData) formData = this.props.formData;

    let total = 0;

    return (
      <div>
        {this.state.order.type === 'selected' &&
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='SSP' />
          <Divider/>

          <div>
            <DataTable plain>
              <TableHeader>
                <TableRow>
                  <TableColumn>No.</TableColumn>
                  <TableColumn>Kode SSP</TableColumn>
                  <TableColumn>ID Billing</TableColumn>
                  <TableColumn>NPWP</TableColumn>
                  <TableColumn>Nama</TableColumn>
                  <TableColumn>Jenis Pajak</TableColumn>
                  <TableColumn>Jenis Setoran</TableColumn>
                  <TableColumn>Masa Pajak</TableColumn>
                  <TableColumn>Jumlah Setoran</TableColumn>
                </TableRow>
              </TableHeader>
              <TableBody>
                {
                  this.state.order.data.map((d, i)=> {
                    total += d.amount;
                    return <TableRow key={i}>
                      <TableColumn>{i+1}</TableColumn>
                      <TableColumn>{d.code}</TableColumn>
                      <TableColumn>{d.idBilling}</TableColumn>
                      <TableColumn>{d.npwp}</TableColumn>
                      <TableColumn>{d.name}</TableColumn>
                      <TableColumn>{d.jpCode}</TableColumn>
                      <TableColumn>{d.jsCode}</TableColumn>
                      <TableColumn>{ d.bulan1 + ' - ' + d.bulan2 + ' ' + d.year}</TableColumn>
                      <TableColumn style={{textAlign: 'right'}}>{utilsService.number.format(d.amount)}</TableColumn>
                    </TableRow>
                  })}

                <TableRow style={{borderTop: '2px solid black'}}>
                  <TableColumn></TableColumn>
                  <TableColumn>
                    <strong>Total</strong>
                  </TableColumn>
                  <TableColumn></TableColumn>
                  <TableColumn></TableColumn>
                  <TableColumn></TableColumn>
                  <TableColumn></TableColumn>
                  <TableColumn></TableColumn>
                  <TableColumn></TableColumn>
                  <TableColumn style={{textAlign: 'right'}}>
                    <strong>{utilsService.number.format(total)}</strong>
                  </TableColumn>
                </TableRow>
              </TableBody>
            </DataTable>
          </div>
        </Card>

        }
        <br/>

        <Card>
          {/* <CardTitle title='' style={{padding: '10px 16px'}} subtitle='Informasi Akun Pembayaran' />
          <Divider/> */}
          <div className='mpk-commandbar mpk-layout align-center justify-between mpk-border bottom solid dark'>
            <div className='flex' style={{display: 'flex'}}>
              <Button icon tooltipLabel="Tambah" onClick={()=> {this.addPaymentAccount()}}>add</Button>
              <Button icon tooltipLabel="Refresh" onClick={() => {this.refreshPaymentAccount()}}>refresh</Button>
            </div>
          </div>
          <div className='mpk-layout'>
            <div className='flex'>
              <List>
                {
                  this.state.akun.map((d, i)=> {
                    return <ListItem
                      key={i}
                      primaryText={(d.companyId) ?  d.companyId + ' - ' + d.name : d.name}
                      secondaryText={d.accountNumber}
                      onClick={()=> {this.onItemClick({
                        paymentAccount: d,
                        companyId: d.companyId,
                        name: d.name,
                        debitAccountNumber: d.accountNumber,
                      })}}
                    />
                  })
                }
              </List>

            </div>
            <div className='flex '>
              <div className='md-grid'>
                <Field
                  label='Corporate ID Mandiri CM'
                  name='companyId'
                  className="md-cell md-cell--12"
                  component={Textfield}
                  disabled
                />
                <Field
                  label='User Mandiri CM'
                  name='name'
                  className="md-cell md-cell--12"
                  component={Textfield}
                  disabled
                />
                <Field
                  label='Nomor Rekening Mandiri CM'
                  name='debitAccountNumber'
                  className="md-cell md-cell--12"
                  component={Textfield}
                  disabled
                />
              </div>
            </div>
          </div>

        </Card>
        <br/>
      </div>
    )
  }

}
