
import React, { Component } from 'react';
import { connect } from 'react-redux';
import routes from './routes';

import AuthService from '../../../../services/auth.service';
import SPT2126Service from './spt/21_26.spt.service';
import UserAccessService from './UserAccess/UserAccess.service'
import OrganizationService from './../../Modules/EbupotModule/Organization/Organization.service'

@connect(state => ({
  organization: state.authEppt.currentOrganization,
  user: state.authEppt.user,
  spt: state.authEppt.currentSPT,
  auth: state.auth.company,
  userCore: state.auth.user
}))
export default class Spt21Main extends Component{
  async componentDidMount() {
    let spt = await SPT2126Service.api.findOne(this.props.match.params.sptId);
    AuthService.api.changeSPT({
      type: '21_26',
      data: spt.data
    }, this.props.dispatch)
    AuthService.api.changeMenuType('SPT_21', this.props.dispatch)
    if(spt.data && spt.data.npwp){
      var orgNpwp = spt.data.npwp
      var organization = {}
      var organizations = []
      if(this.props.auth && this.props.auth.organizations) organizations = this.props.auth.organizations
      // SUPPORT
      if(!this.props.auth){
        if(this.props.userCore){
          if(this.props.userCore && this.props.userCore.isSupport){
            let getOrgSupport = await OrganizationService.api.getAll({
              user: this.props.userCore,
              field: 'npwp',
              query: orgNpwp
            })
            organizations = getOrgSupport.data
          }
        }
      }
      // SUPPORT
      var filtered = organizations.filter((org)=> {
        return org.npwp == orgNpwp
      })
      // console.log(filtered)
      if(filtered.length > 0){
        organization = filtered[0]
      }
      // console.log(organization)
      AuthService.api.changeOrganization(organization, this.props.dispatch)      
    }

  }
  render() {
    if(this.props.spt.data) {
      return(
        <div className="mpk-layout column fill">
          {routes(this.props)}
        </div>
      )
    } else {
      return <div/>
    }

  }
}
