import ApiService from '../../../../../services/api.service';
import http from "../../../../../services/http.service";
import service from 'axios'

class LogReport21Service extends ApiService {
  name= 'LogReport21';
  path= 'report-list';

  api= {
    list: async (param)=> {
      let res = await http.get(`${this.apiLocation}/api/eppt/report-list`, {
        params: param
      })
      return res
    },
    downloadItem: async (id)=> {
      let res = await http.get(`${this.apiLocation}/api/eppt/report-list/download/${id}`, {
        responseType: 'blob'
      })
      return res
    },
    deleteItem: async (id)=> {
      let res = await http.delete(`${this.apiLocation}/api/eppt/report-list/${id}`, {
        params: id
      })
      return res
    }
  }

  constructor() {
    super()
    this.init()
    this.initApi()
  }
  
}


export default new LogReport21Service();
