import ApiService from '../../../../../services/api.service';

class PPh4a2EnableDeposito extends ApiService {
  name= 'PPh4a2EnableDeposito';
  path= 'pasal4/npwp-enable-daftar-deposito-manual';
  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    updateOk: async(id)=> {
      var res = await this.http.put(`${this.apiLocation}/${this.path}/${id}`);
      return res;
    },
    getAccess: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}`);
      return res;
    },
    cekAccess: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/check`);
      return res;
    },
    saveSetting: async (form)=> {
      var res = await this.http.post(`${this.apiLocation}/${this.path}`, form);
      return res;
    }
  }
}

export default new PPh4a2EnableDeposito();
