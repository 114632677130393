import ApiService from '../../../../../../services/api.service';

class AllBP25Service extends ApiService {
  name= 'AllBP25';
  path= 'pasal25/bp';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {}

}

export default new AllBP25Service();
