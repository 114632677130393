import ApiService from '../../../../../services/api.service'
import moment from 'moment'

class ClientService extends ApiService {
  name= 'Client'
  path= 'ebupot/client'
  advancedSearch=true

  constructor(){
    super()
    this.init()
    this.initApi()
  }

  columns = [
    {label: "word.id", searchField: "id", value: "id", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
    {label: "word.clientId", searchField: "clientId", value: "clientId", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.clientSecret", searchField: "clientSecret", value: "clientSecret", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.createdBy", searchField: "createdBy", value: "createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.createdDate", searchField: "createdDate", value: (d)=> {
      if(d){
        return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
      } else {
        return "-"
      }
    }, type: 'func', isDefaultSort:true, isSortable:true, show:true, isSearchable:true},
    {label: "word.lastModifiedBy", searchField: "lastModifiedBy", value: "lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.lastModifiedDate", searchField: "lastModifiedDate", value: (d)=> {
      if(d.lastModifiedDate){
        return moment(d.lastModifiedDate).format('DD/MM/YYYY HH:MM')
      } else {
        return "-"
      }
    }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
  ]

  api = {

    save: async (data, dispatch=()=>{})=> {
      let res = await this.http.post(`${this.apiLocation}/${this.path}/${data.appId}/application`, data);

      return res;
    },

		update: async (data, dispatch=()=>{})=> {
      let res = await this.http.put(`${this.apiLocation}/${this.path}/${data.appId}/application`, data);

      return res;
    },
  }

}

export default new ClientService();
