import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Toolbar, List, ListItem, Avatar, DropdownMenu, FontIcon, TextField, Subheader, Divider} from 'react-md';
import {navService} from 'react-mpk';
import OrganizationSearch from './OrganizationSearch';
import authService from '../../services/auth.service';

@connect(state => ({
  isLoginIn: state.authEppt.isLoginIn,
  user: state.authEppt.user,
  organization: state.authEppt.currentOrganization
}))
export default class OrganizationSwitcher extends Component{

  render() {
    var organizationName = this.props.organization.name;
    if(!organizationName) organizationName = 'Select Organization';
    var organizationSuffix = 'S';
    if(organizationName) organizationSuffix = organizationName.charAt(0);

    return (
      // <DropdownMenu
      //   id='organization-switcher'
      //   closeOnOutsideClick={false}
      //   menuItems={<OrganizationSearch/>}
      //   anchor={{
      //     x: DropdownMenu.HorizontalAnchors.CENTER,
      //     y: DropdownMenu.VerticalAnchors.OVERLAP,
      //   }}
      //   position={DropdownMenu.Positions.BELOW}
      //   animationPosition="below"
      //   fullWidth
      //   sameWidth
      // >
        <ul className="md-list md-dark-theme" style={{background: 'none'}}>
          <li>
            <div style={{margin: '10px 0'}} role="button" className="md-fake-btn md-pointer--hover md-fake-btn--no-outline md-list-tile  md-text">
              <div className="md-ink-container">
              </div>
              <div className="md-tile-addon md-tile-addon--avatar">
                <div className="md-inline-block md-avatar md-avatar--green">
                  <div className="md-avatar-content">{organizationSuffix}</div>
                </div>
              </div>
              <div className="md-tile-content md-tile-content--left-avatar md-tile-content--right-padding">
                <div className="md-tile-text--primary md-text">{organizationName}</div>
                <div className="md-tile-text--secondary md-tile-text--three-lines md-text--secondary">
                  {this.props.organization.npwp}
                </div>
              </div>
              <div className="md-tile-addon md-tile-addon--icon" onClick={()=> navService.redirectTo('/organization')}>
                <FontIcon>info</FontIcon>
              </div>
            </div>
          </li>
        </ul>
      // </DropdownMenu>

    )
  }
}
