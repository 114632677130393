import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Button, Chip, Switch} from 'react-md';

//import FormDialog from './Organization.dialog.view';
import ListView from '../../../../../components/entity/listView';
import UtilService from '../../../../../services/utils.service';
import download from 'downloadjs';
import uuid from 'uuid';
import iziToast from 'izitoast';
import ListViewSpt from '../../../../../components/entity/listViewSpt';
import SSPPembelianBarangService from './SSPPembelianBarang.service';
import moment from "moment";

@connect((state)=> ({
  ...SSPPembelianBarangService.stateConnectSetting()(state),
  spt: state.authEppt.currentSPT
}), SSPPembelianBarangService.actionConnectSetting())
export default class SSPPembelianBarangView extends ListViewSpt {
  service=SSPPembelianBarangService
  FormDialog=()=> <div/>

  constructor(props){
    super(props);
    this.state = {
      ...this.state,
      total: {},
      cetak: false,
      cetakDaftar: false
    }
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Nama'
          name='name__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NPWP'
          name='npwp__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Email'
          name='email__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Nomor Bukti Potong'
          name='no__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        {/*<this.ig.Field
          label='NIK'
          name='nik__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />*/}
      </this.SearchContainer>
    )
  }

  addDialog=false
  editDialog=false

  fetchOption() {
    return {
      path: SSPPembelianBarangService.path+'/'+this.props.spt.data.id+'/spt'
    }
  }

  columns=[
    {isSortable: true, label: "word.reference",  value: "ref", show:false, isSearchable:true},
    {isSortable: true, label: "word.npwp",  value: "npwp", show:true, isSearchable:true},
    {isSortable: true, label: "word.name",  value: "name", show:true, isSearchable:true},
    {isSortable: true, label: "word.ntpn",  value: "ntpn", show:true, isSearchable:true},
    {isSortable: true, label: "word.date",  value: "tgl", show:true, isSearchable:true},
    {isSortable: true, label: "word.hargaPembelian",  value: "hargaPembelian", show:true, isSearchable:true, type: 'number'},
    {isSortable: true, label: "word.pph",  value: "pph", show:true, isSearchable:true, type: 'number'},
    {isSortable:true, label: "word.createdUsername",  value: "createdUsername", show:true, isSearchable:true},
    {isSortable:true, label: "word.createdBy",  value: "createdBy", show:true, isSearchable:true},
    {isSortable:true, label: "word.createdDate", value: "createdDate", show:true, isSearchable:true, type:"date", isDefaultSort: true},
    {isSortable:true, label: "word.modifiedUsername",  value: "modifiedUsername", show:true, isSearchable:true},
    {isSortable:true, label: "word.lastModifiedBy",  value: "lastModifiedBy", show:true, isSearchable:true},
    {isSortable:true, label: "word.lastModifiedDate", value: (d)=> {
      if(d.lastModifiedDate) {
        return <div className='mpk-font-size-S'>{moment(d.lastModifiedDate).format('lll')}</div>
      } else {
        return null;
      }
    }, show:true, isSearchable:true, type:"func", searchField: 'lastModifiedDate', isDefaultSort: true}
  ]

  async componentDidMount() {
    this.rootPath = this.props.match.url;

    var sptId = this.props.spt.data.id
    var total = await SSPPembelianBarangService.api.total(sptId)
    this.setState({
      total: total
    })
  }

  totalBruto() {
    var total = 0;
    if(this.state.total.bruto){
      total = this.state.total.bruto;
    }
    return total;
  }

  totalPph() {
    var total = 0;
    if(this.state.total.pph){
      total = this.state.total.pph;
    }
    return total;
  }

  /*async handleCetak(){
    this.setState({ cetak: true })
    if(this.props.table.selected.length === 0){
      iziToast.warning({
        title: 'Pemberitahuan !',
        message: 'Pilih salah satu data yang akan dicetak',
      });
      this.setState({ cetak: false })
    } else {
      try {
        var selected = this.props.table.selected
        var ids = [];
        selected.map(function (i) {
          ids.push(i.id)
        })

        var report = await this.service.api.cetakBulk(ids)
        var filename = report.headers.filename
        download(report.data, filename);
        this.setState({cetak: false})
      } catch (e) {
        this.setState({cetak: false})
      }
    }
  }*/

  async handleCetakDaftar(){
    this.setState({ cetakDaftar: true })
    try {
      var sptId = this.props.spt.data.id
      var report = await SSPPembelianBarangService.api.cetakDaftar(sptId)
      window.open(report.data.url)
      // var filename = report.headers.filename
      // download(report.data, filename);
      this.setState({ cetakDaftar: false })
    } catch (e){
      this.setState({ cetakDaftar: false })
    }
  }

  async handleSendEmail(){
    try {
      let category = "BP_22"
      var selected = this.props.table.selected
      var ids = [];
      selected.map(function (i){
          ids.push(i.id)
      })
      var sptId = this.props.spt.data.id
      await this.service.api.emailSpt(category, sptId, ids)
      iziToast.info({
        title: "Kirim Email",
        message: "email sedang diproses silahkan cek log untuk lebih detil"
      })
    } catch (e){
      iziToast.warning({
        title: e.name,
        message: e.message
      })
    }
  }

  async deleteSelectedDataItems(){
    var selected = this.props.table.selected

    var ids = [];

    selected.map(function (i){
      ids.push(i.id)
    })

    const sptId = this.props.match.params.sptId

    var res = await this.service.api.deleteBulk(sptId, ids)

    this.fetchData()
  }

  _barItem() {
    return (
      <div>
        <Button secondary raised style={{margin: 5}} onClick={this.handleSendEmail.bind(this)}>Kirim</Button>
        <Button disabled={this.state.cetakDaftar} primary raised style={{margin: 5}} onClick={this.handleCetakDaftar.bind(this)}>Cetak Daftar</Button>
        {/*<Button disabled={this.state.cetak} primary raised style={{margin: 5}} onClick={this.handleCetak.bind(this)}>Cetak</Button>*/}
        <Switch
          style={{float: "right"}}
          id="switch-table-setting"
          name="Switch Table Setting"
          label=""
          checked={this.state.showTableSetting}
          onChange={() => {
            this.setState({
              showTableSetting:!this.state.showTableSetting
            })
          }}
        />
      </div>
    )
  }

  footer() {
    return <div className="flex-none mpk-border thin solid top dark">
      <div className="mpk-padding-N left right">
        <table style={{width: '100%'}}>
          <tfoot className="md-table-footer">
            <tr className="md-table-column md-text-left md-table-column--data md-table-column--adjusted md-text ">
              <td>Total Harga Pembelian</td>
              <td className=" md-text-right">{UtilService.number.format(this.totalBruto(), {precision: 0, thousand: '.', decimal: ','})}</td>
            </tr>
            <tr className="md-table-column md-text-left md-table-column--data md-table-column--adjusted md-text ">
              <td>Total PPh</td>
              <td className=" md-text-right">{UtilService.number.format(this.totalPph(), {precision: 0, thousand: '.', decimal: ','})}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  }

}
