import ApiService from './../../../../../../services/api.service'
import http from './../../../../../../services/http.service'
import moment from 'moment'
import React from 'react';
import { Chip } from 'react-md';

class Espt23HistoryService extends ApiService {
  name= 'Espt23History'
  path= 'ebupot/spt'
  advancedSearch=false

  constructor(){
    super()
    this.init()
    this.initApi()
  }

  columns = [
    {isSortable: true, label: "word.id", searchField: "id", value: "id", show:false, isSearchable:true},
    {
      searchField: "status",
      label: "word.statusSpt",
      type: 'func',
      value: (d)=> {
        var style = {
          background: 'grey'
        }
        switch (d.status) {
          case 'CREATED':
            style.background = 'grey'
            break;
          case 'SUBMIT':
            style.background = '#2196F3'
            break;
          case 'POSTING':
            style.background = '#D32F2F'
            break;
          case 'SENDING':
            style.background = '#FFEB3B'
            break;
          case 'FINISH':
            style.background = '#4CAF50'
            break;
          default:
            break;
        }
        return <Chip label={d.entity.status} style={style} />
      },
      className: "mpk-font-size-S", show:true, isSearchable:true},
      {isSortable: true, label: "word.name", searchField: "organization.nama", value: "entity.name", show:true, isSearchable:true},
      {isSortable: true, label: "word.npwp", searchField: "organization.npwp", value: "entity.npwp", show:true, isSearchable:true},
      {isSortable: true, label: "word.month", searchField: "masa", value: "entity.month", show:true, isSearchable:true, type: 'number'},
      {isSortable: true, label: "word.year",  searchField: "tahun", value: (d)=> d.entity.year, show:true, isSearchable:true, type: 'func'},
      {isSortable: true, label: "word.pembetulan", searchField: "pembetulan", value: "entity.rev", show:true, isSearchable:true, type: 'number'},
      {isSortable: true, label: "word.message", searchField: "message", value: "entity.message", show:true, isSearchable:true},
      // {label: "word.createdBy", searchField: "createdBy", value: "createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
      {label: "word.createdDate", searchField: "createdDate", value: (d)=> {
        if(d){
          var createdDate = localStorage.getItem("spt_timestamp");
          return moment(new Date(createdDate)).format('DD/MM/YYYY HH:MM')
        } else {
          return "-"
        }
      }, type: 'func', isDefaultSort:true, isSortable:true, show:true, isSearchable:true},
      // {label: "word.lastModifiedBy", searchField: "lastModifiedBy", value: "lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
      {label: "word.lastModifiedDate", searchField: "lastModifiedDate", value: (d)=> {
        if(d){
          return moment(new Date(d.metadata.delegate.revisionDate)).format('DD/MM/YYYY HH:MM')
        } else {
          return "-"
        }
      }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
  ]

  api = {}

}

export default new Espt23HistoryService();
