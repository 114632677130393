export const money = value => {
    if(value){
        return parseInt(value.split(',').join(''))
    } else {
        return undefined
    }
}

export const date = value => {
    if(value){
        return new Date(value)
    } else {
        return undefined
    }
}

export default {
    money,
    date
}