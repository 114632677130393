import React, { Component } from 'react';
import { connect } from 'react-redux';

import {Field, getFormValues, reduxForm} from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../../../components/form';

import ImportBp21SatuMasaService from './ImportBP21SatuMasa.service';
import ListView from '../../../../../components/entity/listView';
import ColumnService from '../../../../../services/column.service';
import ImportService from './../Import/Import.service';
import axios from 'axios';
import izitoast from 'izitoast';
import FormDialog from './ImportBP21SatuMasa.dialog.view';
import download from "downloadjs";
import ListViewImport from '../../../../../components/entity/ListViewImport';

@reduxForm({form: 'IMPORT_21_BP_SATU_MASA_Form', destroyOnUnmount: true})
@connect((state) => ({
  ...ImportBp21SatuMasaService.stateConnectSetting()(state),
  formData: getFormValues('IMPORT_21_BP_SATU_MASA_Form')(state),
  // EPPT CORE
  auth: state.auth.company
  // EPPT CORE
}), ImportBp21SatuMasaService.actionConnectSetting())
export default class ImportBp21SatuMasaView extends ListViewImport {
  service=ImportBp21SatuMasaService
  FormDialog=FormDialog

  category='SM'
  importPath='/upload/csv'
  showAutomatisTab=false
  showPenomoranAuto=false
  showFormatEspt=true
  showPembetulan=true
// viewType=2

  beforeFetch(params) {
    params["category.equals"] = "SM"
  }

  async handleDownloadTemplate() {
    var report = await ImportBp21SatuMasaService.api.downloadTemplate()
    window.open(report.data.url, 'TemplateBp21SatuMasa.csv');
  }

}
