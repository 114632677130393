import React from 'react';
import {connect} from 'react-redux';
import {Field, getFormValues, reduxForm} from 'redux-form';
import {DataTable, TableBody, TableColumn, TableHeader, TableRow} from 'react-md';
import FormView from '../../../../../../components/entity/form.view';
import {Datepicker, Textfield, TextfieldMask, validation} from '../../../../../../components/form';
import Tarif22Service from './Tarif22.service';


@reduxForm({form: 'Tarif22Form', destroyOnUnmount: true})
@connect(state => ({
  company: state.authEppt.currentCompany,
  formData: getFormValues('Tarif22Form')(state)
}))
export default class Tarif22Form extends FormView {
  service = Tarif22Service
  viewType = 2

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      activeTab: 0
    }
  }

  async initData() {
    if (this.props.match.params.id == 'new') {
      let details = await Tarif22Service.api.getMaster();

      let detailObjects = details.reduce((out, data) => {
        out[data.id] = data

        return out
      }, {});

      let initialData = {
        company: this.props.company,
        detailObjects,
        details,
      }

      this.props.initialize(initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);

      let details = res.data.details

      let detailObjects = details.reduce((out, data) => {
        out[data.id] = data

        return out
      }, {});

      res.data.detailObjects = detailObjects

      this.props.initialize(res.data);
    }
  }

  async handleSave(value) {
    let detailObjects = value.detailObjects
    let details = value.details

    details.forEach((detail, index) => {
      let detailObject = detailObjects[detail.id]

      detail.kode = detailObject.kode
      detail.jenisPenghasilan = detailObject.jenisPenghasilan
      detail.tarif = parseFloat(detailObject.tarif)
      detail.seq = detailObject.seq

      details[index] = detail
    })

    value.details = details

    try {
      this.setState({onProgress: true})

      if (value.id) {
        await this.service.api.update(value)
      } else {
        await this.service.api.save(value)
      }

      this.setState({onProgress: false})

      let pathArray = this.props.location.pathname.split('/');

      let path = '';

      pathArray.forEach((d, i) => {
        if (pathArray.length - 1 != i) {
          path = path + d;

          if (i != pathArray.length - 2) path = path + '/'
        }
      })

      this.props.history.push(path)
    } catch (e) {
      let msg = e.message;

      if (e.response) msg = e.response.data.message;

      this.setState({
        isError: true,
        onProgress: false,
        errorMessage: msg
      })
    }
  }

  formView() {
    return (
      <div className="mpk-layout column fill mpk-tab-clear">
        <div className="flex">
          {this.pasal22Form()}
        </div>
      </div>
    )
  }

  pasal22Form() {
    let details = []

    if (this.props.formData) {
      details = this.props.formData.details
    }

    return <div style={{marginBottom: 20}}>
      <div className='md-card md-paper md-paper md-paper--1 md-card md-background--card'>

        <div className="md-grid">
          <Field
            className="md-cell md-cell--6"
            label='Start Date'
            name='start'
            inline={true}
            component={Datepicker}
            validate={validation.required}
          />

          <Field
            className='md-cell md-cell--6'
            label='End Date'
            name='end'
            inline={true}
            component={Datepicker}
            validate={validation.required}
          />
        </div>

        <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
          <TableHeader>
            <TableRow>
              <TableColumn>No</TableColumn>
              <TableColumn>Kode</TableColumn>
              <TableColumn>Jenis Penghasilan</TableColumn>
              <TableColumn>Tarif(%)</TableColumn>
              <TableColumn>Seq</TableColumn>
            </TableRow>
          </TableHeader>
          <TableBody>
            {
              details.map((detail, index) => {
                return <TableRow key={index}>
                  <TableColumn>{index + 1}</TableColumn>
                  <TableColumn>
                    <Field
                      name={'detailObjects.' + detail.id + '.kode'}
                      component={Textfield}
                      validate={validation.required}
                    />
                  </TableColumn>
                  <TableColumn>
                    <Field
                      name={'detailObjects.' + detail.id + '.jenisPenghasilan'}
                      component={Textfield}
                      validate={validation.required}
                    />
                  </TableColumn>
                  <TableColumn>
                    <Field
                      name={'detailObjects.' + detail.id + '.tarif'}
                      component={TextfieldMask}
                      // validate={validation.required}
                    />
                  </TableColumn>
                  <TableColumn>
                    <Field
                      name={'detailObjects.' + detail.id + '.seq'}
                      component={Textfield}
                      validate={validation.required}
                    />
                  </TableColumn>
                </TableRow>
              })
            }
          </TableBody>
        </DataTable>
      </div>
    </div>
  }
}
