import React, { Component } from 'react';
import { connect } from 'react-redux';

import ListView from '../../../../../../components/entity/listView';
import TextField, { Button, Divider, Chip, Card, CardTitle, CardActions, DataTable, TableHeader, TableBody, TableRow, TableColumn, Switch, Paper } from 'react-md';
import { Field, reduxForm, getFormValues } from 'redux-form';
import download from 'downloadjs';
import uuid from 'uuid';
import iziToast from 'izitoast';
import { Commandbar, Table, TableSetting, Empty, DialogConfirm } from 'react-mpk';
import http from '../../../../../../services/http.service'
import ColumnService from '../../../../../../services/column.service';
import ExportCsv25Service from './ExportCsv25.service';

@reduxForm({form: 'EXPORT_CSV_25', destroyOnUnmount: true})
@connect((state)=> ({
  ...ExportCsv25Service.stateConnectSetting()(state),
  formData    : getFormValues('EXPORT_CSV_25')(state),
  spt: state.authEppt.currentSPT
}), ExportCsv25Service.actionConnectSetting())
export default class FileLapor25View extends ListView {
  service=ExportCsv25Service

  viewType=2;

  constructor(props){
    super(props);
    this.state = {
      showTableSetting:false,
      showDialogConfirmDeleteSelected:false,
      showForm:false,
      formData: {},
      data: [],
      cetak: false,
      showDialog: false,
      category: null,
      bp: 0,
      ssp: 0
    }
  }

  columns=[]
  _barActions=[]
  _barItem() {}

  handleExport(category){
      this.setState({ showDialog: true })
      this.setState({ category: category })
  }

  async export(){
    var sptId = this.props.spt.data.id
    var category = this.state.category
    var csv = {};
    try {
      switch (category) {
        case 'SSP':
          csv = await ExportCsv25Service.api.downloadCsvSSP(sptId);
          break;
        default:
          csv = await ExportCsv25Service.api.downloadCsvBP(sptId);
          break;
      }
      window.open(csv.data.url)
      // var filename = csv.headers.filename
      // download(csv.data, filename);
    } catch (error) {
      console.log(error)
    }
  }

  async componentDidMount(){
    var sptId = this.props.spt.data.id
    // File Lapor
    try {
      var res = await this.service.api.getInfoFileLapor(sptId);
      if(res.data) {
        this.setState({ 
          bp: res.data.bp,
          ssp: res.data.ssp
        });
        this.props.initialize(res.data)
      }
    } catch(e){
      console.log(e, "file lapor")
    }
  }

  async handleCetak(){
    this.setState({ cetak: true })
    var report = null;
    var sptId = this.props.spt.data.id
    try {
      report = await this.service.api.cetak(sptId)
      window.open(report.data.url)
      this.setState({ cetak: false })
    } catch (e){
      console.log(e)
      this.setState({ cetak: false })
      iziToast.warning({"title": e.name, "message": e.message})
    }
  }

  _barItem() {
    return (
      <div>
        {/* <Button disabled={this.state.cetak} primary raised style={{margin: 5}} onClick={this.handleCetak.bind(this)}>Unduh File Lapor</Button> */}
      </div>
    )
  }

  _tableView(props) {
      const _this = this
      return (
        <div className="flex">
          <Card className="">
              <DialogConfirm
                  title={'word.export'}
                  message={'sentence.custom.export'}
                  visible={this.state.showDialog}
                  onSubmit={(callback) => {
                      _this.export()
                      callback();
                  }}
                  onCancel={() => this.setState({showDialog:false})}
                  translate={true}
              />
              <CardTitle title='' style={{padding: '10px 16px'}} subtitle='Informasi File Export'/>
              <Divider/>
              <DataTable plain
              fixedHeader
              fixedFooter>
                <TableHeader>
                  <TableRow>
                      <TableColumn>Jumlah Data</TableColumn>
                      <TableColumn></TableColumn>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  <TableRow>
                      <TableColumn>BP</TableColumn>
                      <TableColumn></TableColumn>
                  </TableRow>
                  <TableRow>
                      <TableColumn>{this.state.bp} Unit</TableColumn>
                      <TableColumn>
                          <Button disabled={this.state.bp == 0} onClick={this.handleExport.bind(this, 'BP')} style={{width: '100%'}} primary raised>Daftar Angsuran</Button>
                      </TableColumn>
                  </TableRow>

                  <TableRow>
                      <TableColumn>SSP</TableColumn>
                      <TableColumn></TableColumn>
                  </TableRow>
                  <TableRow>
                      <TableColumn>{this.state.ssp} Unit</TableColumn>
                      <TableColumn>
                          <Button disabled={this.state.ssp == 0} onClick={this.handleExport.bind(this, 'SSP')} style={{width: '100%'}} primary raised>SSP</Button>
                      </TableColumn>
                  </TableRow>
                </TableBody>
              </DataTable>
          </Card>
        </div>
      )
  }
}
