import React, { Component } from 'react'; import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  Divider,
  Chip,
  Card,
  CardTitle,
  CardActions,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Switch
} from 'react-md';
import { Field, reduxForm, getFormValues, initialize } from 'redux-form';
import {validation, FileInputNext, Searchfieldv2, Textfield, Searchfield, TextfieldMask, Datepicker, Radio, convert, CheckboxSelection, Multiselect} from '../../../../../../../src/components/form';
import ColumnService from '../../../../../../services/column.service';
import download from 'downloadjs';
import uuid from 'uuid';
import iziToast from 'izitoast';
import UtilService from '../../../../../../services/utils.service'
import BpeService from './Bpe.service';
import ListView from '../../../../../../components/entity/ListViewEbupot';
import moment from 'moment';

@reduxForm({form: 'Bpe', destroyOnUnmount: false})
@connect((state)=> ({
  ...BpeService.stateConnectSetting()(state),
  spt: state.auth.currentSPT,
  user: state.auth.user,
  formData: getFormValues('Bpe')(state)
}), BpeService.actionConnectSetting())
export default class BpeView extends ListView {
  service=BpeService
  FormDialog=()=> <div/>

  constructor(props){
    super(props)
    this.state = {
      ...this.state,
      cetak: false
    }
  }

  addDialog=false
  editDialog=false

  fetchOption() {
    const sptId = this.props.match.params.sptId
    return {
        path: BpeService.path+'/'+sptId+'/spt'
    }
  }

  columns=BpeService.columns

  async downloadBulkBPE(){
    var ids = []
    var selected = this.props.table.selected
    selected.map((i)=> {
      ids.push(i.id)
    })
    var desc = "cetak BPE"
      var confirmation = await this.context.showDialog(()=> ({
        title: null,
        width: 500,
        height: 132,
        text: (
          <div>
            <p>Apa anda yakin akan {desc} terpilih ?</p>
          </div>
        )
      }))
      if(confirmation){
        this.setState({ loadingPage: true })
        try {
          var report = await this.service.api.bpeReports(ids)
          // var filename = `BPE_${item.npwpProfile}${item.masa}${item.masa}${item.tahun}${item.pembetulan}.pdf`
          download(report.data, report.headers.filename);
          iziToast.success({
            title: "Success",
            message: "proses "+ desc +" berhasil" 
          })
          await this.removeCheckbox()
          this.fetchData()
          this.setState({ loadingPage: false })
        } catch (e){
          iziToast.error({
            title: "Error",
            message: "proses "+ desc +" gagal, " + e.message
          })
          await this.removeCheckbox()
          this.setState({ loadingPage: false })
        } 
      }
  }

  _barActions = [
    {
      label:"word.report",
      iconClassName:"mdi mdi-file-pdf",
      onClick: (item) => this.downloadBulkBPE(item)
    },
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => this.fetchData()
    },
    // {
    //   label:"word.back",
    //   iconClassName:"mdi mdi-arrow-left",
    //   onClick: (item) => this.props.history.push(`/company/${this.props.match.params.companyId}/${this.props.match.params.subscriptionId}/init/ebupot-23`)
    // },
  ]

  // String fileName = "BPE_" + bpe.getNpwpProfile() + String.format("%02d", bpe.getMasa()) + String.format("%02d", bpe.getMasa()) + bpe.getTahun() + String.format("%02d", bpe.getPembetulan()) + ".pdf";

  async downloadBPE(item){
    try {
      var report = await this.service.api.cetakBpe(item.id)
      console.log(item)
      var filename = `BPE_${item.npwpProfile}${item.masa}${item.masa}${item.tahun}${item.pembetulan}.pdf`
      download(report.data, filename);
      this.setState({cetak: false})
      iziToast.success({
        title: "Success",
        message: "download BPE sedang diproses"
      })
    } catch (e) {
      console.log(e)
      iziToast.error({
        title: "Failed",
        message: "download BPE gagal, " + e.message
      })
      this.setState({cetak: false})
    }
  }

  async requestBpe(item){
    try {
      var report = await this.service.api.requestBpe(item.id)
      iziToast.success({
        title: "Success",
        message: "request BPE sedang diproses"
      })
    } catch (e) {
      console.log(e)
      iziToast.error({
        title: "Failed",
        message: "request BPE gagal, " + e.message
      })
    }
  }

  _tableActionsFunc(){
    var support = false
    if(this.props.user && this.props.user.isSupport) support = this.props.user.isSupport
    if(support){
      return [
        {
          label:"More Info",
          iconClassName:"mdi mdi-bell",
          onClick: (item) => this.props.history.push(`form/${item.id}`)
        },  
        {
          label:"Cetak BPE",
          iconClassName:"mdi mdi-file-pdf",
          onClick: (item) => this.downloadBPE(item)
        },
        {
          label:"Request BPE",
          iconClassName:"mdi mdi-file",
          onClick: (item) => this.requestBpe(item)
        },
      ]
    } else {
      return [
        {
          label:"More Info",
          iconClassName:"mdi mdi-bell",
          onClick: (item) => this.props.history.push(`form/${item.id}`)
        },
        {
          label:"Cetak BPE",
          iconClassName:"mdi mdi-file-pdf",
          onClick: (item) => this.downloadBPE(item)
        },
      ]
    }
  }

  // NEW TAB MENU
  async hapusPencarian(){
    try {
      try {
        localStorage.removeItem(`search_${this.service.name}`)
      } catch(e){}
      var params = Object.assign({}, params=this.props.table.params)
      this.fetchData(params)
      await this.props.dispatch(initialize(this.service.name+'_search', {}));
    } catch(e){
      console.log(e, "Search Error ...")
    }
  }

  async handleSearching(values){
    try {
      var formData = this.props.tableFilter2
      var params = Object.assign({}, params=this.props.table.params)

      if(formData.tgl){
        params['tgl.equals'] = formData.tgl
      } else { delete params['tgl.equals'] }

      if(formData.ntte){
        params['ntte.contains'] = formData.ntte
      } else { delete params['ntte.contains'] }

      if(formData.nominal){
        params['nominal.contains'] = formData.nominal
      } else { delete params['nominal.contains'] }

      if(formData.createdBy){
        params['createdBy.contains'] = formData.createdBy
      } else { delete params['createdBy.contains'] }

      if(formData.lastModifiedBy){
        params['lastModifiedBy.contains'] = formData.lastModifiedBy
      } else { delete params['lastModifiedBy.contains'] }

      if(formData.createdDate1){
        params['createdDate.greaterOrEqualThan'] = moment(formData.createdDate1).format()
      } else { delete params['createdDate.greaterOrEqualThan'] }

      if(formData.createdDate2){
        params['createdDate.lessOrEqualThan'] = moment(formData.createdDate2).format()
      } else { delete params['createdDate.lessOrEqualThan'] }

      if(formData.lastModifiedDate1){
        params['lastModifiedDate.greaterOrEqualThan'] = moment(formData.lastModifiedDate1).format()
      } else { delete params['lastModifiedDate.greaterOrEqualThan'] }

      if(formData.lastModifiedDate2){
        params['lastModifiedDate.lessOrEqualThan'] = moment(formData.lastModifiedDate2).format()
      } else { delete params['lastModifiedDate.lessOrEqualThan'] }
      params.onSearch = true
      localStorage.setItem("formData_search", JSON.stringify(formData))
      this.fetchData(params)
    } catch(e){
      iziToast.info({
        title: "Pencarian",
        message: "Data tidak ditemukan, " + e.message
      })
    }
  }

  defaultSearchForm(){
    const { handleSubmit, submitting, valid, pristine } = this.props;
    return (
      <div className="md-grid" style={{ overflow: 'auto' }}>
        <Field
          label     = 'NTTE'
          name      = 'ntte'
          className = "md-cell md-cell--12"
          component = {Textfield}
        />

        <Field
          label     = 'Nominal'
          name      = 'nominal'
          className = "md-cell md-cell--12"
          component = {Textfield}
        />
       
        <Field
          label     = 'Tanggal'
          name      = 'tgl'
          className = "md-cell md-cell--12"
          component = {Textfield}
        />

        <Field
          label     = 'Dibuat Oleh'
          name      = 'createdBy'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'Diubah Oleh'
          name      = 'lastModifiedBy'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'Dibuat dari'
          name      = 'createdDate1'
          className = "md-cell md-cell--12"
          component = {Datepicker}
        />
        <Field
          label     = 'Dibuat ke'
          name      = 'createdDate2'
          className = "md-cell md-cell--12"
          component = {Datepicker}
        />
        <Field
          label     = 'Diubah dari'
          name      = 'lastModifiedDate1'
          className = "md-cell md-cell--12"
          component = {Datepicker}
        />
        <Field
          label     = 'Diubah ke'
          name      = 'lastModifiedDate2'
          className = "md-cell md-cell--12"
          component = {Datepicker}
        />
        <Button raised primary className="md-cell md-cell--12" onClick={handleSubmit(this.handleSearching.bind(this))}>Cari</Button>
        <hr />
        <Button raised primary className="md-cell md-cell--12" onClick={handleSubmit(this.hapusPencarian.bind(this))}>Hapus</Button>
      </div>
    )
  }
  // yg ditambah
  // NEW TAB MENU

}
