import ApiService from '../../../../../services/api.service';

class SPT2326EbupotService extends ApiService {
  name= 'EBUPOT_23';
  path= 'spt23';
  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api = {
    pembetulan: async(id, dispatch=()=>{})=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/pembetulan/${id}`)

      return res;
    },
    pembetulanBulk: async(ids, dispatch=()=>{})=> {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/pembetulan`, ids)

      return res;
    },

    downloadLapor: async(ids, dispatch=()=>{})=> {
      var res = await this.http.post(`${this.apiLocation}/upload/download`, ids,{
        responseType: 'blob'
      })

      return res;
    },

    laporAll: async(data) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/lapor`, data)

      return res;
    },

    laporAllWithSummary: async(data) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/lapor/summary`, data)

      return res;
    },

    laporAllWithSummaryParam: async(data) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/lapor/summary/param`, {params: data})

      return res;
    },

    pengajuanApprovalBulk: async(data, dispatch=()=>{})=> {
      var res = await this.http.post(`${this.apiLocation}/mainFlow/pengajuan/bulk`, data)

      return res;
    },
    getSummary: async(sptId)=> {
      return await this.http.get(`${this.apiLocation}/spt23/ssp-23-detail/${sptId}`)
    },
  }

  // apiMockup= {
  //   find: [{
  //     id: 1,
  //     status: 'Process',
  //     npwp: '023945840000',
  //     name: 'PT. A',
  //     pasal: '23/26',
  //     year: '2018',
  //     organization: {name: 'PT. A', npwp: '123123'},
  //     month: 'Januari',
  //     pembetulan: '0'
  //   }],
  //   findOne: {data: {
  //     id: 1,
  //     status: 'Process',
  //     npwp: '023945840000',
  //     name: 'PT. A',
  //     pasal: '23/26',
  //     year: '2018',
  //     organization: {name: 'PT. A', npwp: '123123'},
  //     month: 'Januari',
  //     pembetulan: '0'
  //   }}
  // }
}


export default new SPT2326EbupotService();
