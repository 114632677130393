import React, { Component } from 'react';
import { connect } from 'react-redux';

import {Field, getFormValues, reduxForm} from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../../../../components/form';

import ImportBP26Service from './ImportBP26.service';
import ListView from '../../../../../../components/entity/listView';
import ColumnService from '../../../../../../services/column.service';
// import ImportService from './../Import/Import.service';
import axios from 'axios';
import izitoast from 'izitoast';
import FormDialog from './ImportBP26.dialog';
import download from "downloadjs";
import ListViewImport from '../../../../../../components/entity/ListViewImport';

@reduxForm({form: 'ImportBP26Form', destroyOnUnmount: true})
@connect((state) => ({
  ...ImportBP26Service.stateConnectSetting()(state),
  formData: getFormValues('ImportBP26Form')(state),
  // EPPT CORE
  auth: state.auth.company
  // EPPT CORE
}), ImportBP26Service.actionConnectSetting())
export default class ImportBP26View extends ListViewImport {
  service=ImportBP26Service
  FormDialog=FormDialog
  pasal='pasal23'
  category='BP26'
  importPath='/pasal23/upload/csv'
  showAutomatisTab=false
  showPenomoranAuto=true
  showFormatEspt=true
  showPembetulan=true
  // viewType=2

  beforeFetch(params) {
    params["category.equals"] = "BP26"
  }

  async handleDownloadTemplate(dialog) {
    var report = await ImportBP26Service.api.downloadTemplate(dialog, 'BP26')
    window.open(report.data.url)
    //download(report.data, `Template_${dialog.category}.csv`);
  }

}
