import React from 'react';
import {reduxForm} from 'redux-form';
import ImportDialogTemplateView from '../ImportEbilling/ImportEbilling.dialog.template.view';

@reduxForm({form: 'ImportInquiry-form', destroyOnUnmount: false})
export default class ImportInquiryDialog extends ImportDialogTemplateView {

  titleHeader() {
    return `entities.ImportInquiry.title`;
  }

}
