import React from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import counterpart from 'counterpart';
import { Field, reduxForm, getFormValues} from 'redux-form';
import moment from 'moment';
import download from 'downloadjs';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress,
  List,
  ListItem,
  FontIcon,
  DialogContainer
} from 'react-md';
import FormView from '../../../components/entity/form.view';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox, Multiselect} from '../../../components/form';
import UtilService from '../../../services/utils.service'
import SummaryService from './Summary.service';
import OrganizationService from '../Administrator/Organization/Organization.service';
import { OrganizationView } from '../Administrator/Organization/Organization.view';
import iziToast from 'izitoast';

@reduxForm({form: 'summary', destroyOnUnmount: true})
@connect((state) => ({
  ...OrganizationService.stateConnectSetting()(state),
  access      : state.entity.access.api.find,
  organization: state.authEppt.currentOrganization,
  spt         : state.authEppt.currentSPT,
  formData    : getFormValues('summary')(state),
  // EPPT CORE
  auth: state.auth.company
  // EPPT CORE
}))
export default class SummaryForm extends FormView {
  service  = SummaryService
  viewType = 2;
  translate = false

  titleHeader() {
    var title                                               = `Summary Pasal ${this.props.match.params.pasal}`;
    if (this.props.match.params.pasal === 'SSP') title      = 'SSP';
    if (this.props.match.params.pasal === '4a2') title      = 'Summary Pasal 4(2)';
    if (this.props.match.params.pasal === 'SSPPBK') title   = 'Summary SSP/PBK';
    if (this.props.match.params.pasal === 'INDUK_21') title = 'Summary Induk Pasal 21';
    return title;
  }

  initialData={
    calculate : false,
    allNpwp   : false
  }
  initData(){}

  tahunOptions = [];
  masaOptions = [
    {id:1, name: 'Januari'}, {id:2, name: 'Februari'}, {id:3, name: 'Maret'}, {id:4, name: 'April'}, {id:5, name: 'Mei'}, {id:6, name: 'Juni'},
    {id:7, name: 'Juli'}, {id:8, name: 'Agustus'}, {id:9, name: 'September'}, {id:10, name: 'Oktober'}, {id:11, name: 'November'}, {id:12, name: 'Desember'}
  ]

  constructor(props) {
    super(props);
    this.state= {
      ...this.state,
      tahunOptions: [],
    }
  }

  async handleSave(type) {
    let formData      = this.props.formData
    let fromYear      = formData.fromYear
    let toYear        = formData.toYear
    let fromMonth     = formData.fromMonth
    let toMonth       = formData.toMonth
    let calculate     = formData.calculate
    let allNpwp       = formData.allNpwp
    let npwps  = []
    try {
      formData.organization.map((d)=> {
        npwps.push(d.npwp)
      })
    } catch(e){}
    let pasal = this.props.match.params.pasal
    try {
      var summary = await this.service.api.generate({
        npwps: npwps,
        fromYear: fromYear,
        toYear: toYear,
        fromMonth: fromMonth,
        toMonth: toMonth,
        calculate: calculate,
        allNpwp: allNpwp,
        type: type
      }, pasal)
      var filename = summary.headers.filename
      download(summary.data, filename)
    } catch (e) {
      let message = 'File summary gagal dibuat atau tidak ada data.'
      iziToast.warning({
        title: 'Gagal',
        message: message
      })
    }
  }

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;
    let selectedOrganizations = []
    try {
      selectedOrganizations = this.props.formData.organization
    } catch(e){}
    if(!selectedOrganizations){
      selectedOrganizations = []
    }
    return (
      <div>
        {(this.props.match.params.pasal !== 'SSPPBK' && this.props.match.params.pasal !== 'INDUK_21') &&
            <Button secondary raised primary onClick={()=> this.handleSave('GABUNG')} style={{marginRight: 16}}>Gabung</Button>
        }
        {(this.props.match.params.pasal !== 'SSPPBK' && this.props.match.params.pasal !== 'INDUK_21') &&
            <Button secondary raised primary onClick={()=> this.handleSave('PISAH')} style={{marginRight: 16}}>Pisah</Button>
        }
        {(this.props.match.params.pasal !== 'SSPPBK' && this.props.match.params.pasal !== 'INDUK_21') &&
            <Button secondary raised primary onClick={()=> this.handleSave('REKAP')} style={{marginRight: 16}}>Rekap</Button>
        }
        {(this.props.match.params.pasal !== 'SSPPBK' && this.props.match.params.pasal !== 'INDUK_21') &&
            <Button secondary raised primary onClick={()=> this.handleSave('PPH')} style={{marginRight: 16}}>PPh</Button>
        }
        {this.props.match.params.pasal === 'SSPPBK' &&
            <Button secondary raised primary onClick={()=> this.handleSave('SSP')} style={{marginRight: 16}}>SSP</Button>
        }
        {this.props.match.params.pasal === 'SSPPBK' &&
            <Button secondary raised primary onClick={()=> this.handleSave('PBK')} style={{marginRight: 16}}>PBK</Button>
        }
        {this.props.match.params.pasal === 'INDUK_21' &&
            <Button secondary raised primary onClick={() => this.handleSave('INDUK_21')} style={{marginRight: 16}}>Download</Button>
        }
      </div>
    )
  }

  formView() {
    var tahun = new Date().getFullYear();
    var tahunOptions = [];
    for (let index = 0; index < 11; index++) {
      tahunOptions.push({
        id: tahun - index,
        name: tahun - index
      })
    }
    var formData                      = {};
    if (this.props.formData) formData = this.props.formData.values
    var org = []
    if(this.props.auth && this.props.auth.organizations) org = this.props.auth.organizations
    return (
      <div>
        <Card>
          <div className='md-grid'>
            <Field
              label     = 'Organization'
              name      = 'organization'
              id        = "spt2126-organization"
              className = "md-cell md-cell--12"
              component = {Multiselect}
              valueField = 'parent'
              options   = {org}
              itemTemplate = {function(d) {
                let nameAndAlias = ""
                if (d.name)
                  nameAndAlias = d.name
                if (d.aliasName)
                  nameAndAlias += " - " + d.aliasName
                return {
                  primaryText  : nameAndAlias,
                  secondaryText: d.npwp,
                  onClick      : () => {
                    if(!this.props.disabled){
                      this.handleItemClick(d); this.hide()
                    }
                  }
                }
              }}
            />
            <Field
              className = "md-cell md-cell--6"
              label     = 'Dari Masa'
              name      = 'fromMonth'
              component = {Searchfield}
              options   = {this.masaOptions}
              validate  = {validation.required}
            />
            <Field
              className = "md-cell md-cell--6"
              label     = 'Sampai Masa'
              name      = 'toMonth'
              component = {Searchfield}
              options   = {this.masaOptions}
              validate  = {validation.required}
            />
            <Field
              className = "md-cell md-cell--6"
              label     = 'Dari Tahun'
              name      = 'fromYear'
              component = {Searchfield}
              options   = {tahunOptions}
              validate  = {validation.required}
            />
            <Field
              className = "md-cell md-cell--6"
              label     = 'Sampai Tahun'
              name      = 'toYear'
              component = {Searchfield}
              options   = {tahunOptions}
              validate  = {validation.required}
            />
            <Field
              className = "md-cell md-cell--6"
              label     = 'Kalkulasi'
              name      = 'calculate'
              component = {Checkbox}
            />
            <Field
              className = "md-cell md-cell--6"
              label     = 'Semua NPWP'
              name      = 'allNpwp'
              component = {Checkbox}
            />
          </div>
        </Card>
      </div>
    )
  }

}
