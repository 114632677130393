import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues  } from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  Tab,
  Tabs,
  SelectionControlGroup,
  Checkbox,
  Button
} from 'react-md';

import FormView from '../../../../../components/entity/form.view';
import Master21BiayaJabatanService from './Master21BiayaJabatan.service';
import {validation, Textfield, Searchfield, Switch, Radio, convert, TextfieldMask} from '../../../../../components/form';
import download from 'downloadjs';
import uuid from 'uuid';
import iziToast from 'izitoast';

@reduxForm({form: 'Master21BiayaJabatanForm', destroyOnUnmount: true})
@connect((state) => ({
  access: state.entity.access.api.find,
  organization: state.authEppt.currentOrganization,
  spt: state.authEppt.currentSPT,
  formData: getFormValues('Master21BiayaJabatanForm')(state)
}))
export default class Master21BiayaJabatanForm extends FormView {
    service=Master21BiayaJabatanService
    viewType =2;

    async initData() {
      var npwp = this.props.spt.data.npwp
      let res = await this.service.api.getData(npwp);
      this.props.initialize(res.data);
    }

    redirectTo=false

    async afterSave(res, val) {
      iziToast.success({
        title: "Success",
        message: "Setting tarif berhasil disimpan."
      })
    }

    formView(){
        return (
            <div>
              <Card>
                <div className='md-grid'>
                  <Field
                    label= 'Tarif'
                    name = 'rates'
                    className = 'md-cell md-cell--6'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                  <Field
                    label= 'Total'
                    name = 'total'
                    className = 'md-cell md-cell--6'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </div>
              </Card>
            </div>
        )
    }
}
