import React from 'react';
import { Route, Switch } from 'react-router-dom';

export default (props) => (
  <Switch>

    <Route path={props.match.path+'/EXPORT_CSV_25'} component={require('./Export/ExportCsv25/ExportCsv25.view').default} /> */}

    <Route path={props.match.path+'/BP_25/:id'} component={require('./BP_25/BP_25.form').default} />
    <Route path={props.match.path+'/BP_25'} component={require('./BP_25/BP_25.view').default} />

    <Route path={props.match.path+'/SSP25/:id'} component={require('./SSP/SSP25.form').default} />
    <Route path={props.match.path+'/SSP25'} component={require('./SSP/SSP25.view').default} />

    <Route path={props.match.path+'/'} component={function() {return <div/>}} />
  </Switch>
)
