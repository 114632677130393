import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button, SVGIcon } from 'react-md';
import FormDialog from './Master21Pasal17Prog.dialog.view';
import Master21Pasal17ProgService from './Master21Pasal17Prog.service';
import ListView from '../../../../../components/entity/listView';
import ColumnService from '../../../../../services/column.service';

@connect(Master21Pasal17ProgService.stateConnectSetting(), Master21Pasal17ProgService.actionConnectSetting())
export default class Master21Pasal17ProgView extends ListView {
  service=Master21Pasal17ProgService
  FormDialog=FormDialog

  beforeFetch(params) {
    var paramUrl = window.location.pathname.split("/")
    var param  = paramUrl[paramUrl.length - 1]
    params["tarifPasal17Id.equals"] = param
  }

  columns=[
    {isSortable: true, label: "word.id",  searchField: "id", value: function(d){ return d.id }, type: "func",  show:false, isSearchable:true},
    {isSortable: true, label: "word.tarif21Pasal17", searchField: "tarif21Pasal17",  value: function(d){ return d.tarif21Pasal17.id }, type: "func",  show:false, isSearchable:true},
    {isSortable: true, label: "word.awal", searchField: "awal", value: function(d){ return ColumnService.money(d.awal) }, type: "func",  show:true, isSearchable:true},
    {isSortable: true, label: "word.akhir", searchField: "akhir", value: function(d){ return ColumnService.money(d.akhir) }, type: "func",  show:true, isSearchable:true},
    {isSortable: true, label: "word.lapis", searchField: "lapis", value: function(d){ return d.lapis }, type: "func",  show:true, isSearchable:true},
    {isSortable: true, label: "word.tarif", searchField: "tarif", value: function(d){ return d.tarif }, type: "func",  show:true, isSearchable:true},
    {isSortable: true, label: "word.adaAkhir",  value:"adaAkhir", type: "boolean",  show:true, isSearchable:true}
  ]   
}
