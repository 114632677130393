import ApiService from '../../../../../services/api.service';

class Master21PensiunGlobalService extends ApiService {
  name= 'Master21PensiunGlobal';
  path= 'rates/pensiun-global';

  constructor() {
    super();
    this.init()
    this.initApi()
  }

  api= {
    getData: async () => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}`)
      return res;
    }
  }

}


export default new Master21PensiunGlobalService();
