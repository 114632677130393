import React, { Component } from 'react';
import { connect } from 'react-redux';

import FormDialog from './Master21HonorPnsGlobal.dialog.view';
import Master21HonorPnsGlobalService from './Master21HonorPnsGlobal.service';
import ListView from '../../../../../components/entity/listView';
import ColumnService from '../../../../../services/column.service';

@connect(Master21HonorPnsGlobalService.stateConnectSetting(), Master21HonorPnsGlobalService.actionConnectSetting())
export default class Master21HonorPnsGlobalView extends ListView {
  service=Master21HonorPnsGlobalService
  FormDialog=FormDialog

  columns=[
    {isSortable: true, label: "word.id", searchField: "id",  value: function(d){ return d.id }, type: "func",  show:false, isSearchable:true},
    {isSortable: true, label: "word.start", searchField: "startDate",  value: function(d){ return d.startDate }, type: "func",  show:true, isSearchable:true},
    {isSortable: true, label: "word.end", searchField: "endDate", value: function(d){ return d.endDate }, type: "func",  show:true, isSearchable:true},
    {isSortable: true, label: "word.gol1", searchField: "gol1", value: function(d){ return d.gol1 }, type: "func",  show:true, isSearchable:true},
    {isSortable: true, label: "word.gol2", searchField: "gol2", value: function(d){ return d.gol2 }, type: "func",  show:true, isSearchable:true},
    {isSortable: true, label: "word.gol3", searchField: "gol3", value: function(d){ return d.gol3 }, type: "func",  show:true, isSearchable:true},
    {isSortable: true, label: "word.gol4", searchField: "gol4", value: function(d){ return d.gol4 }, type: "func",  show:true, isSearchable:true},
  ]
}
