import ApiService from '../../../../../services/api.service';

class Master21UpahKumulatifGlobalService extends ApiService {
  name= 'Master21UpahKumulatifGlobal';
  path= 'rates/upah-kumulatif-global';

  constructor() {
    super();
    this.init()
    this.initApi()
  }

  api= {
    getData: async () => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}`)
      return res;
    }
  }

}


export default new Master21UpahKumulatifGlobalService();
