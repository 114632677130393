import React from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  Tab,
  Tabs,
  SelectionControlGroup,
  Button
} from 'react-md';
import download from 'downloadjs';
import uuid from 'uuid';
import izitoast from 'izitoast';

import FormView from '../../../../../components/entity/form.view';
import {validation, Textfield, Searchfield, Switch, Checkbox, Datepicker, TextfieldMask} from '../../../../../components/form';
import columnService from '../../../../../services/column.service';
import utilsService from '../../../../../services/utils.service';
import terbilangService from '../../../../../services/terbilang.service';
import Spt15IndukService from './Spt15Induk.service';
import BlokBunifikasiService from '../../21_26/BlokBunifikasi/BlokBunifikasi.service';
@reduxForm({form: 'SPT_INDUK_15_Form', destroyOnUnmount: true, initialValues: {jasaLains: []}})
@connect((state) => ({
  access      : state.entity.access.api.find,
  organization: state.authEppt.currentOrganization,
  spt         : state.authEppt.currentSPT,
  auth        : state.auth.company,
  formData    : getFormValues('SPT_INDUK_15_Form')(state)
}))
export default class Spt15IndukForm extends FormView {
  service  = Spt15IndukService
  viewType = 2;

  initialData={
    bagB1: {},
    bagB2: {},
    bagC : {},
  }

  constructor(props){
    super(props);
    this.state = {
      ...this.state,
      cetak    : false,
      calculate: false,

      signer: {},
    }
  }

  async beforeSave(value) {
    console.log(value, "TELL ME")
    var date;
    if(typeof value.cuttingDate === 'string') {
      if(value.cuttingDate.search('/') != -1) {
        date = moment(value.cuttingDate, 'DD/MM/YYYY');
      } else {
        date = moment(value.cuttingDate, 'YYYY-MM-DD');
      }
    } else if(typeof value.cuttingDate === 'object') {
      date = moment(value.cuttingDate)
    }

    // Remapping Objek
    var mapping = {
      "b13"             : value.b13 ? value.b13 : 0,
      "b14"             : value.b14 ? value.b14 : 0,
      "b15"             : value.b15 ? value.b15 : 0,
      "b2a3"            : value.b2a3 ? value.b2a3 : 0,
      "b2a4"            : value.b2a4 ? value.b2a4 : 0,
      "b2a5"            : value.b2a5 ? value.b2a5 : 0,
      "b2b3"            : value.b2b3 ? value.b2b3 : 0,
      "b2b4"            : value.b2b4 ? value.b2b4 : 0,
      "b2b5"            : value.b2b5 ? value.b2b5 : 0,
      "b2c5"            : value.b2c5 ? value.b2c5 : 0,
      "b2d5"            : value.b2d5 ? value.b2d5 : 0,
      "b2e5"            : value.b2e5 ? value.b2e5 : 0,
      "b33"             : value.b33 ? value.b33 : 0,
      "b34"             : value.b34 ? value.b34 : 0,
      "b35"             : value.b35 ? value.b35 : 0,
      "b4a3"            : value.b4a3 ? value.b4a3 : 0,
      "b4a4"            : value.b4a4 ? value.b4a4 : 0,
      "b4a5"            : value.b4a5 ? value.b4a5 : 0,
      "b4b3"            : value.b4b3 ? value.b4b3 : 0,
      "b4b4"            : value.b4b4 ? value.b4b4 : 0,
      "b4b5"            : value.b4b5 ? value.b4b5 : 0,
      "b53"             : value.b53 ? value.b53 : 0,
      "b54"             : value.b54 ? value.b54 : 0,
      "b55"             : value.b55 ? value.b55 : 0,
      "totalGross"      : value.jmlBruto ? value.jmlBruto : 0,
      "totalPph"        : value.jmlPph ? value.jmlPph : 0,
      "c01Count"        : value.qty1 ? value.qty1 : 0,
      "c03Count"        : value.qty3 ? value.qty3 : 0,
      "c01"             : value.c1 ? true : false,
      "c02"             : value.c2 ? true : false,
      "c03"             : value.c3 ? true : false,
      "c04"             : value.c4 ? true : false,
      "c05"             : value.c5 ? true : false,
      "cuttingDate"     : value.cuttingDate,
      "words"           : value.terbilang ? value.terbilang : "-",
      spt               : this.props.spt.data
    }
    
    if(value.id && value.id != "new") mapping.id = value.id
    if(value.consumeId) mapping.consumeId = value.consumeId
    if(typeof mapping.cuttingDate == "object") mapping.cuttingDate = moment(mapping.cuttingDate).format("YYYY-MM-DD")
    value = mapping;

    value.blockBunifikasiMonth = 4;
    value.blockBunifikasiYear = 2022;
    try {
      let res = await BlokBunifikasiService.api.getSettingAccess(this.props.match.params.companyId)
      if(res.data.active) {
        value.blockBunifikasiMonth = res.data.bulan
        value.blockBunifikasiYear = res.data.tahun
      }
    } catch(e) {}

    return mapping;
  }

  async handleSave(value) {
    try {
      var beforeSave = await this.beforeSave(value);
      if(beforeSave) {
        if(typeof beforeSave === 'object') value = beforeSave; 
      }
      this.setState({onProgress: true})

      var res                            = await this.service.api.update(value)
      // if  (!res.data.bagD) res.data.bagD = {}
      this.props.initialize(res.data);
      izitoast.success({title: 'Success', message: 'Data berhasil disimpan.'})
      this.setState({onProgress: false})
    } catch(e) {
      console.log(e)
      var msg              = e.message;
      if  (e.response) msg = e.response.data.message;
      this.setState({
        isError     : true,
        onProgress  : false,
        errorMessage: msg
      })
    }
  }

  async initData() {
    let res = await this.service.api.getBySptId(this.props.spt.data.id);

    // Remapping Objek
    var data = res.data;
    var remapping = {
      id              : data.id,
      b13             : data.b13,                         
      b14             : data.b14,                         
      b15             : data.b15,                         
      b2a3            : data.b2a3,                         
      b2a4            : data.b2a4,                         
      b2a5            : data.b2a5,                         
      b2b3            : data.b2b3,                         
      b2b4            : data.b2b4,                         
      b2b5            : data.b2b5,                         
      b2c5            : data.b2c5,                         
      b2d5            : data.b2d5,                         
      b2e5            : data.b2e5,                         
      b33             : data.b33,                         
      b34             : data.b34,                         
      b35             : data.b35,                         
      b4a3            : data.b4a3,                         
      b4a4            : data.b4a4,                         
      b4a5            : data.b4a5,                         
      b4b3            : data.b4b3,                         
      b4b4            : data.b4b4,                         
      b4b5            : data.b4b5,                         
      b53             : data.b53,                         
      b54             : data.b54,                         
      b55             : data.b55,                         
      qty1            : data.c01Count,
      qty3            : data.c03Count,
      c1              : data.c01,
      c2              : data.c02,
      c3              : data.c03,
      c4              : data.c04,
      c5              : data.c05,
      jmlBruto        : data.totalGross,
      jmlPph          : data.totalPph,
      cuttingDate     : data.cuttingDate,
      terbilang       : data.words,
      spt             : data.spt
    }
    
    res.data = remapping;
    // Remapping Objek

    if(!res.data.bagD) res.data.bagD = {}

    // signer
    let signer = null;
    try {
      // signer = await this.service.api.getSigner({
      //   orgId: this.props.spt.data.organization.id,
      //   tgl  : res.data.tanggal
      // });
    } catch (error) {

    }
    if(signer) {
      this.setState({signer: signer})
      if(res.data.bagD.kuasa) {
        res.data.bagD.npwp = signer.npwpKuasa;
        res.data.bagD.nama = signer.namaKuasa;
      } else {
        res.data.bagD.npwp = signer.npwpPenandaTanganSpt;
        res.data.bagD.nama = signer.namaPenandaTanganSpt;
      }
    }

    this.props.initialize(res.data);
  }

  async changeSigner() {
    if(this.state.signer.id) {
      let formData                       = this.initialData
      if  (this.props.formData) formData = this.props.formData;
      if(formData.bagD.kuasa) {
        this.props.change('bagD.npwp', this.state.signer.npwpKuasa);
        this.props.change('bagD.nama', this.state.signer.namaKuasa);
      } else {
        this.props.change('bagD.npwp', this.state.signer.npwpPenandaTanganSpt);
        this.props.change('bagD.nama', this.state.signer.namaPenandaTanganSpt);
      }
    }
  }

  async calculate() {
    await this.setState({calculate: true})
    await this.props.dispatch({
      type: 'TOGGLE_GLOBAL_PROGRESS',
      data: true
    });

    const sptId = this.props.match.params.sptId

    try {
      var res = await this.service.api.calculate(sptId)

      if(res.data) {
        this.props.initialize(res.data);
      }
    } catch(e) {

    }


    this.setState({calculate: false})
    await this.props.dispatch({
      type: 'TOGGLE_GLOBAL_PROGRESS',
      data: false
    });
  }

  async handleCetak(){
    this.setState({ cetak: true })
    try {
      const sptId    = this.props.match.params.sptId
      var   report   = await this.service.api.cetak(sptId)
      window.open(report.data.url)
      // var   filename = report.headers.filename;
      // download(report.data, filename);
      this.setState({ cetak: false })
    } catch (e){
      this.setState({ cetak: false })
    }
  }

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;
    let   statusSPT                                     = "";
    let   statusInSPT                                   = false;
    if(this.props.spt){
        if(this.props.spt.data){
            if(this.props.spt.data.status){
                statusSPT   = this.props.spt.data.status;
                statusInSPT = true
            }
        }
    }

    if(statusSPT == "FINISH" || statusSPT == "WAITING"){
        return (
            <div>
              <Button disabled={this.state.cetak} primary raised style={{marginRight: 5}} onClick={this.handleCetak.bind(this)}>Cetak</Button>
            </div>
        )
    } else {
        if(statusInSPT){
            return (
              <div>
                <Button disabled={this.state.cetak} primary raised style={{marginRight: 5}} onClick={this.handleCetak.bind(this)}>Cetak</Button>
                {/* <Button disabled={this.state.calculate} primary raised style={{marginRight: 5}} onClick={this.calculate.bind(this)}>calculate</Button> */}
                <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Save and Calculate</Button>
              </div>
            )
        } else {
            return (
                <div>
                    {/* <Button disabled={this.state.calculate} primary raised style={{marginRight: 5}} onClick={this.calculate.bind(this)}>calculate</Button> */}
                    <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>Back</Button>
                </div>
            )
        }
    }
  }

  async calculateTotal(e,v,pv,f) {
    var form = this.props.formData;
    var d    = form.bagB23;

    await this.props.change(f, v);

    var jmlPph   = 0;
    var jmlBruto = 0;
    Object.keys(d).forEach((k, i) => {
      if(!(k == 'jmlBruto' || k == 'jmlPph' || k == 'terbilang' || k == 'createdBy' || k == 'createdDate' || k == 'id' || k == 'lastModifiedBy' || k == 'lastModifiedDate')) {
        var x = d[k];

        var pph            = x.pph;
        var bruto          = x.bruto;
        if  (!bruto) bruto = 0;
        if  (!pph) pph     = 0;

        jmlPph   = jmlPph+ parseFloat(pph);
        jmlBruto = jmlBruto+ parseFloat(bruto)
      }
    })

    this.props.change('bagB23.jmlBruto', jmlBruto)
    this.props.change('bagB23.jmlPph', jmlPph);

    this.props.change('bagB23.terbilang', terbilangService(parseInt(jmlPph)));
  }

  formView() {
    let formData                                                     = this.initialData
    if  (this.props.formData && this.props.formData) formData = this.props.formData

    var masaOptions = [
      {id:1, name: 1}, {id:2, name: 2}, {id:3, name: 3}, {id:4, name: 4}, {id:5, name: 5}, {id:6, name: 6},
      {id:7, name: 7}, {id:8, name: 8}, {id:9, name: 9}, {id:10, name: 10}, {id:11, name: 11}, {id:12, name: 12}
    ]
    return (
      <div>
        <Card>
          {/* <CardTitle title='' style={{padding: '10px 16px'}} subtitle='A. IDENTITAS PEMOTONG' />
          <Divider/> */}

          <div className='md-grid'>
            <Field
              label        = 'Tanggal Lapor'
              className    = "md-cell md-cell--12"
              name         = 'cuttingDate'
              component    = {Datepicker}
              validate     = {validation.required}
              onDateChange = { async (d)=> {
                let signer = null;

                try {
                  // signer = await this.service.api.getSigner({
                  //   orgId: this.props.spt.data.organization.id,
                  //   tgl  : moment(d).format("YYYY-MM-DD")
                  // })

                  await this.setState({signer: signer})
                } catch (error) {
                }

                await this.changeSigner()
              }}
              />
          </div>
        </Card>
        <br/>

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='PPH PASAL 15 YANG TELAH DIPOTONG' />
          <Divider/>
          <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
            <TableHeader>
              <TableRow>
                <TableColumn>No.</TableColumn>
                <TableColumn>Jenis Penghasilan</TableColumn>
                <TableColumn>KAP/KJS</TableColumn>
                <TableColumn className='md-text-right'>Jumlah Bruto Imbalan (Rp)</TableColumn>
                <TableColumn className='md-text-right'>Tarif</TableColumn>
                <TableColumn className='md-text-right'>PPH Yang Dipotong (Rp)</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableColumn>
                  1.
                </TableColumn>
                <TableColumn>Imbalan Yang Dibayarkan/Terutang Kepada Perusahaan Pelayaran Dalam Negeri: </TableColumn>
                <TableColumn>
                  411128/410
                </TableColumn>
                <TableColumn>
                  <Field
                    name = 'b13'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    name      = 'b14'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    name      = 'b15'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  2.
                </TableColumn>
                <TableColumn>	Imbalan Yang Diterima/Diperoleh Sehubungan Dengan Pengangkutan Orang Dan/Atau Barang Termasuk Penyewaan Kapal Laut Oleh Perusahaan Pelayaran Dalam Negeri: </TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                </TableColumn>
                <TableColumn>a. Penghasilan Dari Indonesia</TableColumn>
                <TableColumn>
                  411128/410
                </TableColumn>
                <TableColumn>
                  <Field
                    name = 'b2a3'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    name      = 'b2a4'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    name      = 'b2a5'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                </TableColumn>
                <TableColumn>b. Penghasilan Dari Luar Indonesia</TableColumn>
                <TableColumn>
                  411128/410
                </TableColumn>
                <TableColumn>
                  <Field
                    name = 'b2b3'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    name      = 'b2b4'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    name      = 'b2b5'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                </TableColumn>
                <TableColumn>c. PPh Pasal 24 Yang Diperhitungkan</TableColumn>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  <Field
                    name      = 'b2c5'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                </TableColumn>
                <TableColumn>d. Pph Yang Dipotong Pihak Lain</TableColumn>
                <TableColumn>
                  411128/410
                </TableColumn>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  <Field
                    name      = 'b2d5'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                </TableColumn>
                <TableColumn>PPh Yang Harus Dibayar Sendiri : (a + b) - (c + d)</TableColumn>
                <TableColumn>
                  411128/410
                </TableColumn>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                </TableColumn>
                <TableColumn className="md-text-right">
                  {/* <Field
                    name      = 'b2e5'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  /> */}
                  {columnService.money(formData.b2e5)}
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  3.
                </TableColumn>
                <TableColumn>Imbalan Charter Kapal Laut Dan / Atau Pesawat Udara Yang Dibayarkan / Terutang Kepada Perusahaan Pelayaran Dan / Atau Penerbangan Luar Negeri</TableColumn>
                <TableColumn>
                  411128/411
                </TableColumn>
                <TableColumn>
                  <Field
                    name = 'b33'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    name      = 'b34'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    name      = 'b35'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  4.
                </TableColumn>
                <TableColumn>Imbalan Yang Diterima Sehub Dgn Pengangkutan Orang Dan/Atau Barang Tmsk Charter Kapal Laut Dan/Atau Pesawat Udara Oleh Perush Pelayaran Dan/Atau Penerbangan Luar Negeri Yang Dipotong Pihak Lain </TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                </TableColumn>
                <TableColumn>a. PPh Yang Disetor Sendiri</TableColumn>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  <Field
                    name = 'b4a3'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    name      = 'b4a4'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    name      = 'b4a5'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                </TableColumn>
                <TableColumn>b. PPh Yang Dipotong Pihak Lain</TableColumn>
                <TableColumn>
                  411128/411
                </TableColumn>
                <TableColumn>
                  <Field
                    name = 'b4b3'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    name      = 'b4b4'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    name      = 'b4b5'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  5.
                </TableColumn>
                <TableColumn>Imbalan Charter Pesawat Udara Yang Dibayarakan / Terutang Kepada Perusahaan Penerbangan DN</TableColumn>
                <TableColumn>
                  411129/101
                </TableColumn>
                <TableColumn>
                  <Field
                    name = 'b53'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    name      = 'b54'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    name      = 'b55'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow style={{background: '#ddd'}}>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  Jumlah
                </TableColumn>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  <Field
                    name      = 'jmlBruto'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    disabled
                    money = {','}
                  />
                </TableColumn>
                <TableColumn>
                </TableColumn>
                <TableColumn className="md-text-right">
                  {/* <Field
                    name      = 'jmlPph'
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    disabled
                    money = {','}
                  /> */}
                  {columnService.money(formData.jmlPph)}
                </TableColumn>
              </TableRow>



              <TableRow style={{background: '#ddd'}}>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  Terbilang
                </TableColumn>
                <TableColumn colSpan={4}>
                  <Field
                    name      = 'terbilang'
                    component = {Textfield}
                    disabled
                    money = {','}
                  />
                </TableColumn>
              </TableRow>



            </TableBody>

          </DataTable>
        </Card>

        <br/>


         <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='LAMPIRAN' />
          <Divider/>
          <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
            <TableHeader>
              <TableRow>
                <TableColumn>Cek</TableColumn>
                <TableColumn>Keterangan</TableColumn>
                <TableColumn>Lembar</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableColumn>
                  <Field
                    name      = 'c1'
                    component = {Checkbox}
                  />
                </TableColumn>

                <TableColumn>
                  Surat Setoran Pajak
                </TableColumn>
                <TableColumn>
                  <Field
                    name      = 'qty1'
                    component = {Textfield}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  <Field
                    name      = 'c2'
                    component = {Checkbox}
                  />
                </TableColumn>

                <TableColumn>
                  Daftar Bukti Pemotongan PPh Final Pasal 15
                </TableColumn>
                <TableColumn>
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>
                  <Field
                    name      = 'c3'
                    component = {Checkbox}
                  />
                </TableColumn>

                <TableColumn>
                  Bukti Pemotongan/Pemungutan PPh Final Pasal 15
                </TableColumn>
                <TableColumn>
                  <Field
                    name      = 'qty3'
                    component = {Textfield}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  <Field
                    name      = 'c4'
                    component = {Checkbox}
                    onChange  = {async (ev, v)=> {
                      await this.props.change('bagD.kuasa', v);

                      await this.changeSigner()
                    }}
                  />
                </TableColumn>

                <TableColumn>
                Surat Kuasa Khusus
                </TableColumn>
                <TableColumn>
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  <Field
                    name      = 'c5'
                    component = {Checkbox}
                  />
                </TableColumn>

                <TableColumn>
                Fotokopi Surat Keterangan Domisili/Certificate Of Residence (COR) yang berlaku tidak lebih dari 1 (satu) tahun dari tanggal pemotongan apabila pemotongan pajak dihitung dengan mempertimbangkan Perjanjian Penghindaran Pajak Berganda (P3B)
                </TableColumn>
                <TableColumn>
                </TableColumn>
              </TableRow>
            </TableBody>
          </DataTable>
        </Card>


        <br />


      </div>
    )
  }
}
