import ApiService from '../../../../services/api.service';

class DataESSPService extends ApiService {
  name = 'DataESSP';
  path = 'ebilling/data-essp';

  api = {
    deleteSelected: async (ids) => {
      var res = await this.http.post(`${this.apiLocation + "/" + this.path}/delete`, ids)

      return res.data;
    },
    calculate: async (pasal, data) => {
      var res = {}

      if (pasal == null) {
        res = await this.http.post(`${this.apiLocation + "/" + this.path}/calculate`, data)
      }
      else {
        res = await this.http.post(`${this.apiLocation + "/" + pasal + "/" + this.path}/calculate`, data)
      }

      return res.data;
    },
    calculateEbupot: async (data) => {
      // var res = await this.http.post(`${this.apiLocation + "/" + this.path}/calculate/ebupot`, data)
      var res = await this.http.post(`${this.apiLocation}/ebupot/ebilling/data-essp/calculate`, data)

      return res.data;
    },
    calculateIsRunning: async () => {
      var res = await this.http.get(`${this.apiLocation + "/" + this.path}/calculate/isRunning`)

      return res.data;
    },
    calculateIsRunningEbupot: async () => {
      // var res = await this.http.get(`${this.apiLocation + "/" + this.path}/calculate/isRunning/ebupot`)
      var res = await this.http.get(`${this.apiLocation}/ebupot/ebilling/data-essp/calculate/is-running`)

      return res.data;
    },

    cetak: async (id) => {
      var res = await this.http.get(`${this.apiLocation}/ebilling/essp/report/${id}`, {
        responseType: 'blob'
      })

      return res;
    },

    csvBySelected: async (ids) => {
      var res = await this.http.post(this.apiLocation+'/ebilling/essp/csv-checked', ids)

      return res.data;
    },

    csvByFilter: async (data) => {
      var res = await this.http.get(this.apiLocation+'/ebilling/essp/csv-filter', {params: data})

      return res.data;
    },
  }

  constructor() {
    super()
    this.init()
    this.initApi()
  }
}

export default new DataESSPService();
