import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Field, reduxForm } from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../../../../components/form';

import ListView from '../../../../../../components/entity/listView';
import ColumnService from '../../../../../../services/column.service';
// import ImportService from './../Import/Import.service';
import axios from 'axios';
import izitoast from 'izitoast';
import ImportSigner23Service from './ImportSigner23.service';
import ImportSigner23DialogView from './ImportSigner23.dialog';
import download from "downloadjs";
import ListViewImport from '../../../../../../components/entity/ListViewImport';



@reduxForm({form: 'ImportSigner23Form', destroyOnUnmount: true})
@connect((state)=> ({
  ...ImportSigner23Service.stateConnectSetting()(state),
  user: state.authEppt.user,
  company: state.authEppt.currentCompany
}), ImportSigner23Service.actionConnectSetting())
export default class ImportSigner23View extends ListViewImport {
  service=ImportSigner23Service
  FormDialog=ImportSigner23DialogView
  pasal='pasal23'
  category='SIGNER_23'
  importPath='/api/import/company'
  importPathFunc() {
    return `/api/import/company/${this.props.company.id}`;
  }
  showAutomatisTab=false
  // viewType=2

  beforeFetch(params) {
    params["category.equals"] = "SIGNER_23"
  }

  async handleDownloadTemplate() {
    var report = await ImportSigner23Service.api.downloadTemplate()
    download(report.data, 'Signer23.csv');
  }

}
