import React, { Component } from 'react';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import { validation, Textfield, TextfieldMask, Datepicker, convert } from '../../../../components/form';
import Service from './ImportUser.service';
import ImportDialogTemplateView from '../../pph/21_26/Import/Import.dialog.template.view';

@reduxForm({form: 'ImportUser-form', destroyOnUnmount: false})
export default class ImportUserDialogView extends ImportDialogTemplateView {
  service=Service
}
