import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Divider,
  Chip,
  Card,
  CardTitle,
  CardActions,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Switch
} from 'react-md';
import { Field, reduxForm, getFormValues } from 'redux-form';
import {validation, Textfield, Searchfield} from '../../../../../components/form';
import ColumnService from '../../../../../services/column.service';
import download from 'downloadjs';
import uuid from 'uuid';
import iziToast from 'izitoast';
import UtilService from '../../../../../services/utils.service'

//import FormDialog from './Organization.dialog.view';
import SSP23Service from './SSP23.service';
import ListView from '../../../../../components/entity/listView';
import ListViewSpt from '../../../../../components/entity/listViewSpt';
import SPT2326Service from '../SPT/23_26.spt.service';
// import FileLapor21Service from '../FileLapor21/FileLapor21.service';
import moment from "moment";

@reduxForm({form: 'SSP23_view', destroyOnUnmount: false})
@connect((state)=> ({
  ...SSP23Service.stateConnectSetting()(state),
  spt: state.authEppt.currentSPT,
  formData: getFormValues('SSP23_view')(state)
}), SSP23Service.actionConnectSetting())
export default class SSP23View extends ListViewSpt {
  service=SSP23Service
  FormDialog=()=> <div/>

  addDialog=false
  editDialog=false

  constructor(props){
    super(props);
    this.state = {
      ...this.state,
      cetak: false,
      formData: {
        "jumlah23": 0,
        "jumlah26": 0,
        "jumlahTerbayar23": 0,
        "jumlahTerbayar26": 0,
        "ssp23s": [
          // {
          //   "jumlah": 0,
          //   "map": "string",
          //   "terbayar": 0
          // }
        ],
        "ssp26s": [
          // {
          //   "jumlah": 0,
          //   "map": "string",
          //   "terbayar": 0
          // }
        ]
      }
    }
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Kode Setoran'
          name='kjs__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NTPN'
          name='ntpn__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
      </this.SearchContainer>
    )
  }

  viewType=2

  header() {
    return (
      <div>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle='DATA SSP TELAH DIBAYAR' />
        <Divider/>
      </div>
    )
  }

  fetchOption() {
    const sptId = this.props.match.params.sptId
    return {
      path: SSP23Service.path+'/'+sptId+'/spt'
    }
  }

  columns=[
    {isSortable: true, label: "word.kjs", searchField: "kjs", value: function(d){ return d.kjs.code }, type: "func", show:true, isSearchable:true},
    {isSortable: true, label: "word.date",  value: "date", show:true, isSearchable:true},
    {isSortable: true, label: "word.ntpn",  value: "ntpn", show:true, isSearchable:true},
    {isSortable: true, label: "word.total", searchField: "total", value: function(d){ return ColumnService.money(d.total) }, type: "func", show:true, isSearchable:true},
    {isSortable:true, label: "word.createdUsername",  value: "createdUsername", show:true, isSearchable:true},
    {isSortable:true, label: "word.createdBy",  value: "createdBy", show:true, isSearchable:true},
    {isSortable:true, label: "word.createdDate", value: "createdDate", show:true, isSearchable:true, type:"date", isDefaultSort: true},
    {isSortable:true, label: "word.modifiedUsername",  value: "modifiedUsername", show:true, isSearchable:true},
    {isSortable:true, label: "word.lastModifiedBy",  value: "lastModifiedBy", show:true, isSearchable:true},
    {isSortable:true, label: "word.lastModifiedDate", value: (d)=> {
      if(d.lastModifiedDate) {
        return <div className='mpk-font-size-S'>{moment(d.lastModifiedDate).format('lll')}</div>
      } else {
        return null;
      }
    }, show:true, isSearchable:true, type:"func", searchField: 'lastModifiedDate', isDefaultSort: true}
  ]

  async componentDidMount() {
    //EPPT CORE
    // const sptId = this.props.match.params.sptId

    // var res = await SSP23Service.api.getSummary(sptId);

    var res = await SSP23Service.api.getSptInfo(this.props.match.params.sptId);

    var resData = {
      jumlah23: res.data.amountBp23,
      jumlah26: res.data.amountBp26,
      jumlahTerbayar23: res.data.paidOfBp23,
      jumlahTerbayar26: res.data.paidOfBp26,
      ssp23s: res.data.ssp23s,
      ssp26s: res.data.ssp26s
    }
    // res.data.ssp23s.forEach(function(ssp23s) {
    //   resData.jumlah23 += ssp23s.amount;
    //   resData.jumlahTerbayar23 += ssp23s.paid;
    // });
    // res.data.ssp26s.forEach(function(ssp26s) {
    //   resData.jumlah26 += ssp26s.amount;
    //   resData.jumlahTerbayar26 += ssp26s.paid;
    // });
    // console.log("RES =");
    // console.log(JSON.stringify(resData));
    //EPPT CORE
    if(res.data) {
      this.setState({formData: resData})
    }

  }

  async beforeFetch(){
    const sptId = this.props.match.params.sptId
    var resTotal = await SSP23Service.api.getTotal(this.props.match.params.sptId)
    this.setState({
      total: resTotal.data.total
    })
  }

  async handleCetakDaftar(){
    this.setState({ cetak: true })
    try {
      const sptId = this.props.match.params.sptId
      var report = await this.service.api.cetak(sptId)
      var filename = report.headers.filename
      download(report.data, filename);
      this.setState({ cetak: false })
    } catch (e){
      this.setState({ cetak: false })
    }
  }

  // async deleteSelectedDataItems(){
  //   var selected = this.props.table.selected

  //   var ids = [];

  //   selected.map(function (i){
  //     ids.push(i.id)
  //   })

  //   const sptId = this.props.match.params.sptId

  //   var res = await this.service.api.deleteBulk(sptId, ids)

  //   this.fetchData()
  // }

  _barItem() {
    return (
      <div>

        {/* <Button disabled={this.state.cetak} primary raised style={{margin: 5}} onClick={this.handleCetakDaftar.bind(this)}>Cetak Daftar</Button> */}

        <Switch
          style={{float: "right"}}
          id="switch-table-setting"
          name="Switch Table Setting"
          label=""
          checked={this.state.showTableSetting}
          onChange={() => {
            this.setState({
              showTableSetting:!this.state.showTableSetting
            })
          }}
        />
      </div>
    )
  }

  getTotal() {
    var total = 0;
    if(this.state.total){
      total = this.state.total;
    }
    return total;
  }

  footer() {
    return <div className="flex-none mpk-border thin solid top dark">
      <div className="mpk-padding-N left right">
        <table style={{width: '100%'}}>
          <tfoot className="md-table-footer">
            <tr className="md-table-column md-text-left md-table-column--data md-table-column--adjusted md-text ">
              <td>Total</td>
              <td className=" md-text-right">{UtilService.number.format(this.getTotal(), {precision: 0, thousand: '.', decimal: ','})}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  }

  additionalTopContent() {
    return (
      <div className='mpk-layout row'>
        <br/>
        <Card className='flex' style={{marginBottom: 32, marginRight: 10}}>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='DAFTAR SSP PASAL 23'/>
          <Divider/>
            <DataTable plain baseId="simple-selectable-table" indeterminate>
                <TableHeader>
                <TableRow>
                    <TableColumn>Uraian</TableColumn>
                    <TableColumn>PPH Terutang</TableColumn>
                    <TableColumn>SSP yang disetor</TableColumn>
                    {/*<TableColumn>Request ID Biling</TableColumn>*/}
                </TableRow>
                </TableHeader>
                <TableBody>
                  {this.state.formData.ssp23s.map((d) => {
                    return <TableRow>
                      <TableColumn>{d.kapKjs}</TableColumn>
                      <TableColumn className='md-text-right'>
                        { ColumnService.money(d.amount)  }
                      </TableColumn>
                      <TableColumn className='md-text-right'>
                        { ColumnService.money(d.paid)  }
                      </TableColumn>
                      {/*<TableColumn>-</TableColumn>*/}
                    </TableRow>
                  })}

                  <TableRow>
                    <TableColumn><strong>TOTAL</strong></TableColumn>
                    <TableColumn className='md-text-right'>
                      <strong>{ ColumnService.money(this.state.formData.jumlah23)  }</strong>
                    </TableColumn>
                    <TableColumn className='md-text-right'>
                      <strong>{ ColumnService.money(this.state.formData.jumlahTerbayar23)  }</strong>
                    </TableColumn>
                    {/*<TableColumn>-</TableColumn>*/}
                  </TableRow>
                </TableBody>
            </DataTable>
        </Card>
        <Card className='flex' style={{marginBottom: 32, marginLeft: 10}}>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='DAFTAR SSP PASAL 26'/>
          <Divider/>
            <DataTable plain baseId="simple-selectable-table" indeterminate>
                <TableHeader>
                <TableRow>
                    <TableColumn>Uraian</TableColumn>
                    <TableColumn>PPH Terutang</TableColumn>
                    <TableColumn>SSP yang disetor</TableColumn>
                    {/*<TableColumn>Request ID Biling</TableColumn>*/}
                </TableRow>
                </TableHeader>
                <TableBody>
                  {this.state.formData.ssp26s.map((d) => {
                    return <TableRow>
                      <TableColumn>{d.kapKjs}</TableColumn>
                      <TableColumn className='md-text-right'>
                        { ColumnService.money(d.amount)  }
                      </TableColumn>
                      <TableColumn className='md-text-right'>
                        { ColumnService.money(d.paid)  }
                      </TableColumn>
                      {/*<TableColumn>-</TableColumn>*/}
                    </TableRow>
                  })}

                  <TableRow>
                    <TableColumn><strong>TOTAL</strong></TableColumn>
                    <TableColumn className='md-text-right'>
                      <strong>{ ColumnService.money(this.state.formData.jumlah26)  }</strong>
                    </TableColumn>
                    <TableColumn className='md-text-right'>
                      <strong>{ ColumnService.money(this.state.formData.jumlahTerbayar26)  }</strong>
                    </TableColumn>
                    {/*<TableColumn>-</TableColumn>*/}
                  </TableRow>
                </TableBody>
            </DataTable>
        </Card>
      </div>
    )

  }

}
