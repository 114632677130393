import ApiService from '../../../../../services/api.service';
import http from "../../../../../services/http.service";

class BP21SATUMASAService extends ApiService {
  name= 'BP_21_SATU_MASA';
  path= 'spt21/sm';
  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    total: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/spt21/sm/summary/${sptId}`)

      return res.data;
    },
    getPegawai: async (sptId)=> {
      return await this.http.get(`${this.apiLocation}/spt21/sm-pegawai/${sptId}/spt`, sptId)
    },
    getPensiun: async (sptId)=> {
      return await this.http.get(`${this.apiLocation}/spt21/sm-pensiun/${sptId}/spt`, sptId)
    },
    updatePegawai: async (data)=> {
      return this.http.put(`${this.apiLocation}/spt21/sm-pegawai`, data);
    },
    updatePensiun: async (data)=> {
      return this.http.put(`${this.apiLocation}/spt21/sm-pensiun`, data);
    },
    downloadCsv: async (sptId)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/spt/export`, {
        responseType: 'blob'
      })

      return res;
    },
    cetakDaftar: async (id) => {
      var res = await this.http.get(`${this.apiLocation}/spt21/sm/${id}/report-list`)

      return res;
    },
    cetakBulk: async (ids) => {
      var res = await this.http.post(`${this.apiLocation}/spt21/sml/report/bulk`, ids, {
        responseType: 'blob'
      })

      return res;
    },
    cetakBulkMerge: async (ids) => {
      var res = await this.http.post(`${this.apiLocation}/spt21/sml/report/bulk/merge`, ids, {
        responseType: 'blob'
      })

      return res;
    },
    deleteBulk: async (sptId, ids)=> {
      let res = await http.post(`/api/eppt/delete/bp`, ids);

      return res;
    },
    downloadCsv: async (sptId)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/spt/export`)

      return res;
    },
  }
}


export default new BP21SATUMASAService();
