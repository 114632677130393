import React, { Component } from 'react';
import { connect } from 'react-redux';

import FormDialog from './MasterPtkp.dialog.view';
import MasterPtkpService from './MasterPtkp.service';
import ListView from '../../../../components/entity/listView';
import ColumnService from '../../../../services/column.service';

@connect(MasterPtkpService.stateConnectSetting(), MasterPtkpService.actionConnectSetting())
export default class MasterPtkpView extends ListView {
  service=MasterPtkpService
  FormDialog=FormDialog

  columns=[
    {label: "word.ptkp",  value: function(d){ return ColumnService.money(d.ptkp) }, type: "func", isDefaultSort:true, show:true, isSearchable:true},
    {label: "word.start",   value: function(d){ return ColumnService.int(d.start) }, type: "func", isDefaultSort:true, show:true, isSearchable:true},
    {label: "word.to", value: function(d){ return ColumnService.int(d.to) }, type: "func",  isDefaultSort:true, show:true, isSearchable:true},
    {label: "word.dependents",  value: function(d){ return ColumnService.money(d.dependents) }, type: "func", isDefaultSort:true, show:true, isSearchable:true},
  ]
}
