import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  Tab,
  Tabs,
  SelectionControlGroup,
  Checkbox
} from 'react-md';

import {validation, Textfield, Searchfield, Searchfieldv2, TextfieldMask, Switch, Datepicker, Radio, convert} from '../../../../../components/form';
import UtilService from '../../../../../services/utils.service'
import FormView from '../../../../../components/entity/form.view';
import DialogView from '../../../../../components/entity/dialog.view';
import RegenciesService from './Regencies.service'
import moment from 'moment'
import counterpart from 'counterpart'
import UserService from './../User/User.service'
import ProvincesService from './../Provinces/Provinces.service'

@reduxForm({form: 'RegenciesForm', destroyOnUnmount: true})
@connect((state) => ({
  formData:state.form.RegenciesForm,
  user: state.auth.user
}))
export default class RegenciesFormView extends FormView {
  service=RegenciesService
  viewType=2;

  formView() {
    return (
        <Card>
            <div className='md-grid'>
                <Field
                    label='Id'
                    name='id'
                    className="md-cell md-cell--6"
                    component={TextfieldMask}
                    maskFormat="####"
                    mask="_"
                    validate={validation.required}
                />
                <Field
                    label='Province Id'
                    name='provinceId'
                    className="md-cell md-cell--6"
                    service={ProvincesService}
                    valueField="id"
                    remoteSearch={true}
                    component={Searchfieldv2}
                    validate={validation.required}
                />
                <Field
                    label='Name'
                    name='name'
                    className="md-cell md-cell--6"
                    component={Textfield}
                    validate={validation.required}
                />
            </div>
      </Card>
    )
  }
}
