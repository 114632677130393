import React, { Component } from 'react';
import { Grid, Cell } from 'react-md';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import { validation, Textfield } from '../../../../components/form';
import DialogView from '../../../../components/entity/dialog.view';
import MasterKppService from './MasterKpp.service';

@reduxForm({form: 'master-kpp-dialog', destroyOnUnmount: false})
export default class MasterKppDialogView extends DialogView {
  service=MasterKppService
  initialData = {
  }

  formView() {
    return (
      <Grid style={{padding:'none'}}>
        <Field
          label='Code'
          name='code'
          id="master-kpp-code"
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
        />

        <Field
          label='Name'
          name='name'
          id="master-kpp-name"
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
        />

        <Field
          label='Address'
          name='address'
          row={5}
          id="master-kpp-address"
          className="md-cell md-cell--12"
          component={Textfield}
        />
      </Grid>
    )
  }
}
