import ApiService from '../../../../../../services/api.service';

class Master23KjsService extends ApiService {
  name= 'Master23Kjs';
  path= 'pasal23/spt/kjs';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    findByKap: async (kapId) => {
      var res = await this.http.get(`${this.apiLocation}/pasal23/spt/kjs/${kapId}/kap`)

      return res.data;
    }
  }
}


export default new Master23KjsService();

