import React, { Component } from 'react';
import { connect } from 'react-redux';

import FormDialog from './Master21StatusKaryawanA1.dialog.view';
import Master21StatusKaryawanA1Service from './Master21StatusKaryawanA1.service';
import ListView from '../../../../../components/entity/listView';
import ColumnService from '../../../../../services/column.service';

@connect(Master21StatusKaryawanA1Service.stateConnectSetting(), Master21StatusKaryawanA1Service.actionConnectSetting())
export default class Master21StatusKaryawanA1View extends ListView {
  service=Master21StatusKaryawanA1Service
  FormDialog=FormDialog

  defaultColumns = [
    {isSortable:true, label: "word.id",  value: "id", show:false, isSearchable:true, isDefaultSort: false},
  ]

  columns=[
    {isSortable: true, label: "word.id",   searchField: "id", value: function(d){ return d.id }, type: "func",  show:false, isSearchable:true},
    {isSortable: true, label: "word.code", searchField: "code", value: function(d){ return d.code }, type: "func",  show:true, isSearchable:true},
    {isSortable: true, label: "word.name", searchField: "name", value: function(d){ return d.name }, type: "func",  show:true, isSearchable:true},
  ]   
}
