import React, { Component } from 'react';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import { validation, Textfield, TextfieldMask, Datepicker, convert } from '../../../components/form';
import Service from './ImportAll21.service';
import ImportDialogTemplateView from './../pph/21_26/Import/Import.dialog.template.view';

@reduxForm({form: 'ImportAll21-form', destroyOnUnmount: false})
export default class ImportAll21DialogView extends ImportDialogTemplateView {
  service=Service
  pasal='pasal21'
}