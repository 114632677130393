/**
 * Created by dwiargo on 11/29/17.
 */

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import LogExportView from './Export/LogExport.view';
import SummaryForm from './Summary/Summary.form';
import KalkulasiIndukForm from './KalkulasiInduk/KalkulasiInduk.form';
import EditProfileForm from './EditProfile/EditProfile.form';
import EditPasswordForm from './EditPassword/EditPassword.form';
import PengaturanNoBPView from './Setting/PengaturanNoBP/PengaturanNoBP.view';
import KeyView from './Setting/Key/Key.view';
import Test from './Test'

import SPT23MainView            from "./Modules/EbupotModule/PPh/SPT23In/main";
import SPT23FormView            from './Modules/EbupotModule/PPh/SPT23/SPT23.form.view';
import SPT23View                from './Modules/EbupotModule/PPh/SPT23/SPT23.view';
import UserFormView             from './Modules/EbupotModule/User/User.form.view';
import UserView                 from './Modules/EbupotModule/User/User.view';
import ApplicationFormView      from './Modules/EbupotModule/Application/Application.form.view';
import ApplicationView          from './Modules/EbupotModule/Application/Application.view';
import ApplicationEmailFormView from './Modules/EbupotModule/ApplicationEmail/ApplicationEmail.form.view';
import ClientView               from './Modules/EbupotModule/Client/Client.view';
import OrganizationFormView     from './Modules/EbupotModule/Organization/Organization.form.view';
import OrganizationView         from './Modules/EbupotModule/Organization/Organization.view';
// import OrganizationUserView     from './Administrator/Organization/UserEbupot/OrganizationUserEbupot.view';
// import OrganizationUserForm     from './Administrator/Organization/UserEbupot/OrganizationUserEbupot.form';
import LogSubmitFormView        from './Modules/EbupotModule/LogSubmit/LogSubmit.form.view';
import LogSubmitView            from './Modules/EbupotModule/LogSubmit/LogSubmit.view';
import LogCheckFormView         from './Modules/EbupotModule/LogCheck/LogCheck.form.view';
import LogCheckView             from './Modules/EbupotModule/LogCheck/LogCheck.view';
import LogFileValidasiView      from './Modules/EbupotModule/LogFileValidasi/LogFileValidasi.view';
import LogFileCsvView           from './Modules/EbupotModule/LogFileCsv/LogFileCsv.view';
import LogConvertedExcelView           from './Modules/EbupotModule/LogConvertedExcel/LogConvertedExcel.view';
import LogFileView              from './Modules/EbupotModule/LogFile/LogFile.view';
// import SignerFormView           from './Modules/EbupotModule/Signer/Signer.form.view';
import SignerView               from './Modules/EbupotModule/Signer/Signer.view';
import SignerFormView           from './../Support/Signer/Signer.form.view';
// import SignerView               from './../Support/Signer/Signer.view';
import KodeNegaraFormView       from './Modules/EbupotModule/KodeNegara/KodeNegara.form.view';
import KodeNegaraView           from './Modules/EbupotModule/KodeNegara/KodeNegara.view';
import ProvincesFormView        from './Modules/EbupotModule/Provinces/Provinces.form.view';
import ProvincesView            from './Modules/EbupotModule/Provinces/Provinces.view';
import KodeBuktiPotongFormView  from './Modules/EbupotModule/KodeBuktiPotong/KodeBuktiPotong.form.view';
import KodeBuktiPotongView      from './Modules/EbupotModule/KodeBuktiPotong/KodeBuktiPotong.view';
import JenisDokumenFormView     from './Modules/EbupotModule/JenisDokumen/JenisDokumen.form.view';
import JenisDokumenView         from './Modules/EbupotModule/JenisDokumen/JenisDokumen.view';
import RegencyFormView          from './Modules/EbupotModule/Regencies/Regencies.form.view';
import RegencyView              from './Modules/EbupotModule/Regencies/Regencies.view';
import DistrictFormView         from './Modules/EbupotModule/Districts/Districts.form.view';
import DistrictView             from './Modules/EbupotModule/Districts/Districts.view';
import VillagesFormView         from './Modules/EbupotModule/Villages/Villages.form.view';
// const AsyncHome = asyncComponent(() => import("./Dashboard/Blank"));
import VillageView              from './Modules/EbupotModule/Villages/Villages.view';
import KodeBuktiPotong26View    from './Modules/EbupotModule/KodeBuktiPotong26/KodeBuktiPotong.view';
import AllEbupot23View          from './Modules/EbupotModule/AllEbupot23/AllEbupot23.view';
import AllEbupot26View          from './Modules/EbupotModule/AllEbupot26/AllEbupot26.view';
import Bupot23FormView          from './Modules/EbupotModule/Bupot23/Bupot23.form.view';
import Bupot26FormViewView      from './Modules/EbupotModule/Bupot26/Bupot26.form.view';
import Bupot23View              from './Modules/EbupotModule/Bupot23/Bupot23.view';
import Bupot26View              from './Modules/EbupotModule/Bupot26/Bupot26.view';
import Bupot23HistoryView       from './Modules/EbupotModule/Bupot23History/Bupot23History.view';
import Bupot26HistoryView       from './Modules/EbupotModule/Bupot26History/Bupot26History.view';
import Bupot23HistoryFormView   from './Modules/EbupotModule/Bupot23History/Bupot23History.form.view';
import Bupot26HistoryFormView   from './Modules/EbupotModule/Bupot26History/Bupot26History.form.view';
import Bupot23EmailView         from './Modules/EbupotModule/Bupot23Email/Bupot23Email.view';
import Bupot26EmailView         from './Modules/EbupotModule/Bupot26Email/Bupot26Email.view';
import Bupot23EmailFormView     from './Modules/EbupotModule/Bupot23Email/Bupot23Email.form.view';
import Bupot26EmailFormView     from './Modules/EbupotModule/Bupot26Email/Bupot26Email.form.view';
import LawanTransaksi26FormView from './Modules/EbupotModule/LawanTransaksi26/LawanTransaksi26.form.view';
import LawanTransaksi26View     from './Modules/EbupotModule/LawanTransaksi26/LawanTransaksi26.view';
import LawanTransaksi23FormView from './Modules/EbupotModule/LawanTransaksi23/LawanTransaksi23.form.view';
import LawanTransaksi23View     from './Modules/EbupotModule/LawanTransaksi23/LawanTransaksi23.view';
import Dashboard                from './Modules/EbupotModule/Dashboard/Blank';
import BpeView                  from './Modules/EbupotModule/PPh/Bpe/Bpe.view';
import BpeFormView              from './Modules/EbupotModule/PPh/Bpe/Bpe.form.view';
import SPT23HistoryView                  from './Modules/EbupotModule/PPh/SPT23History/SPT23History.view';
import SPT23HistoryFormView              from './Modules/EbupotModule/PPh/SPT23History/SPT23History.form.view';

import Bp23EditFormView         from './Modules/EbupotModule/PPh/SPT23In/Bupot23InEdit/Bupot23InEdit.form.view';
import Bp23EditView             from './Modules/EbupotModule/PPh/SPT23In/Bupot23InEdit/Bupot23InEdit.view';
import Bp23PembetulanFormView   from './Modules/EbupotModule/PPh/SPT23In/Bupot23InPembetulan/Bupot23InPembetulan.form.view';
import Bp23PembetulanView       from './Modules/EbupotModule/PPh/SPT23In/Bupot23InPembetulan/Bupot23InPembetulan.view';
import Bp26EditFormView         from './Modules/EbupotModule/PPh/SPT23In/Bupot26InEdit/Bupot26InEdit.form.view';
import Bp26EditView             from './Modules/EbupotModule/PPh/SPT23In/Bupot26InEdit/Bupot26InEdit.view';
import Bp26PembetulanFormView   from './Modules/EbupotModule/PPh/SPT23In/Bupot26InPembetulan/Bupot26InPembetulan.form.view';
import Bp26PembetulanView       from './Modules/EbupotModule/PPh/SPT23In/Bupot26InPembetulan/Bupot26InPembetulan.view';
import EbillingWidget           from './EbillingWidget/EbillingWidget';
import LogExportCsvView         from './Merge/LogExportCsv/LogExportCsvAll.view';
import LogExportPdfView         from './Merge/LogExportPdf/LogExportPdfAll.view';
import LogExportPdfChecklistView         from './Merge/LogExportPdfChecklist/LogExportPdfChecklistAll.view';
import LogExportEbupotView from './Merge/LogExportEbupot/LogExportEbupotAll.view';
import LogSummaryView         from './Merge/LogSummary/LogSummaryAll.view';

var parentPath = "/company/:companyId/:subscriptionId/init"

export default (actions, global) => (
  <Switch>
    <Route path={`/company/:companyId/:subscriptionId/init/LOG_EXPORT_CSV`} component={LogExportCsvView} />
    <Route path={`/company/:companyId/:subscriptionId/init/LOG_EXPORT_PDF`} component={LogExportPdfView} />
    <Route path={`/company/:companyId/:subscriptionId/init/LOG_EXPORT_PDF_CHECKLIST`} component={LogExportPdfChecklistView} />
    <Route path={`/company/:companyId/:subscriptionId/init/LOG_SUMMARY`} component={LogSummaryView} />
    <Route path={`/company/:companyId/:subscriptionId/init/LOG_EXPORT_EBUPOT`} component={LogExportEbupotView} />
    <Route path={`/company/:companyId/:subscriptionId/init/ebilling-widget`} component={EbillingWidget} />
    <Route path={`/company/:companyId/:subscriptionId/init/merge/summary-all`} component={require('./../Main/Summary/Summary.view').default} />
    <Route path={`/company/:companyId/:subscriptionId/init/merge/summary/:pasal`} render={(props) => ( <SummaryForm key={props.match.params.pasal} {...props} /> )} />
    <Route path={`/company/:companyId/:subscriptionId/init/merge/lawan-transaksi-all`} component={require('./../Main/Merge/LawanTransaksiAll.view').default} />
    <Route path={`/company/:companyId/:subscriptionId/init/merge/signer-all`} component={require('./../Main/Merge/SignerAll.view').default} />
    <Route path={`/company/:companyId/:subscriptionId/init/merge/signer-image-all`} component={require('./../Main/Merge/SignerImageAll.view').default} />
    <Route path={`/company/:companyId/:subscriptionId/init/merge/import-all`} component={require('./../Main/Merge/ImportAll.view').default} />

    <Route path="/company/:companyId/:subscriptionId/init/tarra-lapor" component={require('./TarraLapor/TarraLapor.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/spt-1771" component={require('./Spt1771/Spt1771Widget').default} />

    <Route path="/company/:companyId/:subscriptionId/init/SIGNER_PASAL" component={require('./pph/Signer/SignerPasal.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/SIGNER_IMAGE" component={require('./pph/Signer/SignerImage.view').default} />

    <Route path="/company/:companyId/:subscriptionId/init/user" exact component={require('./Administrator/User/User.view').default} />
    {/* <Route path="/company/:companyId/:subscriptionId/init/access-control" exact component={require('./Administrator/AccessControl/AccessControl.form').default} />
    <Route path="/company/:companyId/:subscriptionId/init/access-control/:id" exact component={require('./Administrator/Access/Access.form').default} /> */}
    <Route path="/company/:companyId/:subscriptionId/init/access-control" exact component={require('./pph/21_26/UserAccess/UserAccess.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/access-control/:id" exact component={require('./pph/21_26/UserAccess/UserAccess.form.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/permissions" exact component={require('./Administrator/Permissions/Permissions.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/access" exact component={require('./Administrator/Access/Access.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/templateRole" exact component={require('./Administrator/TemplateRole/TemplateRole.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/loggingEvent" exact component={require('./Administrator/LoggingEvent/LoggingEvent.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/logApp" exact component={require('./Administrator/LogApp/LogApp.view').default} />

    <Route path="/company/:companyId/:subscriptionId/init/company" exact component={require('./Administrator/Company/Company.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/company/:companyId/open" component={require('./Company/main').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/aboutCompany" component={require('./Company/About/About.company.view').default} />

    <Route path="/company/:companyId/:subscriptionId/init/organization" exact component={require('./Administrator/Organization/Organization.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/organization/:id" component={require('./Administrator/Organization/Organization.form.view').default} />

    <Route path="/company/:companyId/:subscriptionId/init/master/kpp" exact component={require('./Master/MasterKpp/MasterKpp.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/master/pesangon" exact component={require('./Master/MasterPesangon/MasterPesangon.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/master/ptkp" exact component={require('./Master/MasterPtkp/MasterPtkp.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/master/biaya-jabatan" exact component={require('./Master/MasterBiayaJabatan/MasterBiayaJabatan.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/master/tarif-pasal-17-berlapis" exact component={require('./Master/MasterTarifPasal17Berlapis/MasterTarifPasal17Berlapis.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/master/uu-pajak-penghasilan" exact component={require('./Master/MasterUUPajakPenghasilan/MasterUUPajakPenghasilan.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/lawanTransaksi/15" exact component={require('./Master/MasterLawanTransaksi15/MasterLawanTransaksi15.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/lawanTransaksi/22" exact component={require('./Master/MasterLawanTransaksi22/MasterLawanTransaksi22.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/lawanTransaksi/4a2" exact component={require('./Master/MasterLawanTransaksi4a2/MasterLawanTransaksi4a2.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/lawanTransaksi/pph23" exact component={require('./Master/MasterLawanTransaksi23/MasterLawanTransaksi23.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/lawanTransaksi/pph21/a1" exact component={require('./Master/MasterLawanTransaksiA1/MasterLawanTransaksiA1.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/lawanTransaksi/pph21/a2" exact component={require('./Master/MasterLawanTransaksiA2/MasterLawanTransaksiA2.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/lawanTransaksi/pph21/pp" exact component={require('./Master/MasterLawanTransaksiPp/MasterLawanTransaksiPp.view').default} />

    <Route path="/company/:companyId/:subscriptionId/init/dashboard" component={require('./Dashboard/Dashboard.view').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/entities" component={require('./Entities/Entities').default}/>

    {/* FLOW */}
    <Route path="/company/:companyId/:subscriptionId/init/mainFlow" exact component={require('./Flow/MainFlow/MainFlow.view').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/mainFlow/:id" component={require('./Flow/MainFlow/MainFlow.form').default}/>
    {/* END FLOW */}

    {/* IMPORT */}
    <Route path="/company/:companyId/:subscriptionId/init/IMPORT/ORGANIZATION" exact component={require('./Import/ImportOrganization/ImportOrganization.view').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/IMPORT/USER" exact component={require('./Import/ImportUser/ImportUser.view').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/IMPORT/ORGANIZATION/USER" exact component={require('./Import/ImportOrganizationUser/ImportOrganizationUser.view').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/IMPORT/SIGNER23" exact component={require('./pph/23_26/Import/ImportSigner/ImportSigner23.view').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/IMPORT/SIGNER21" exact component={require('./pph/21_26/ImportSigner/ImportSigner21.view').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/IMPORT/SIGNER4A2" exact component={require('./pph/4a2/Import/ImportSigner/ImportSigner4a2.view').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/IMPORT/SIGNER22" exact component={require('./pph/22/Import/ImportSigner/ImportSigner22.view').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/IMPORT/SIGNER15" exact component={require('./pph/15/Import/ImportSigner/ImportSigner15.view').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/IMPORT/EFILING" exact component={require('./Import/ImportEfilling/ImportEfilling.view').default}/>
    {/* END IMPORT */}

    {/* 21_26 */}
    <Route path="/company/:companyId/:subscriptionId/init/SPT_21" exact component={require('./pph/21_26/spt/21_26.spt.view').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/SPT_21/:sptId" exact component={require('./pph/21_26/spt/21_26.spt.form').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/SPT_21/:sptId/open" component={require('./pph/21_26/main').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/SIGNER_21" component={require('./pph/21_26/SPT21Signer/SPT21Signer.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/SIGNER_21_IMAGE" component={require('./Setting/FileUpload/FileUpload.view').default} />
    {/* END 21_26 */}

    {/* EFILING */}
    <Route path="/company/:companyId/:subscriptionId/init/EFILING/LOG" exact component={require('./Efiling/LogEfiling/LogEfiling.view').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/EFILING/LOG4" exact component={require('./Efiling/LogEfiling4/LogEfiling4.view').default}/>
    {/* END EFILING */}

    {/* ALL_21_26 */}
    <Route path={"/company/:companyId/:subscriptionId/init/ALL_BP_21_F/:id"} component={require('./pph/21_26/BP_21_F/BP_21_F.form_out').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/BP_21_F"} component={require('./pph/21_26/ALL_BP_21_F/ALL_BP_21_F.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/ALL_BP_21_TF/:id"} component={require('./pph/21_26/BP_21_TF/BP_21_TF.form_out').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/BP_21_TF"} component={require('./pph/21_26/ALL_BP_21_TF/ALL_BP_21_TF.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/ALL_BP_21_A1/:id"} component={require('./pph/21_26/BP_21_A1/BP_21_A1.form_out').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/BP_21_A1"} component={require('./pph/21_26/ALL_BP_21_A1/ALL_BP_21_A1.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/ALL_BP_21_A2/:id"} component={require('./pph/21_26/BP_21_A2/BP_21_A2.form').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/BP_21_A2"} component={require('./pph/21_26/ALL_BP_21_A2/ALL_BP_21_A2.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/ALL_BP_21_SSP/:id"} component={require('./pph/21_26/BP_21_SSP/BP_21_SSP.form_out').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/ALL_BP_21_SSP"} component={require('./pph/21_26/ALL_BP_21_SSP/ALL_BP_21_SSP.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/ALL_BP_21_SATU_MASA/:id"} component={require('./pph/21_26/BP_21_SATU_MASA/BP_21_SATU_MASA.form_out').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/ALL_BP_21_SATU_MASA"} component={require('./pph/21_26/ALL_BP_21_SATU_MASA/ALL_BP_21_SATU_MASA.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/ALL_BP_21_DAFTAR_BIAYA/:sptId"} component={require('./pph/21_26/BP_21_DAFTAR_BIAYA/BP_21_DAFTAR_BIAYA.form').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/DAFTAR_BIAYA_21"} component={require('./pph/21_26/ALL_BP_21_DAFTAR_BIAYA/ALL_BP_21_DAFTAR_BIAYA.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/BP_21_SATU_TAHUN"} component={require('./pph/21_26/ALL_BP_21_SATU_TAHUN/ALL_BP_21_SATU_TAHUN.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/ALL_BP_21_INDUK/:sptId"} component={require('./pph/21_26/SPT_INDUK_21/SPT_INDUK_21.form').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/SPT_INDUK_21"} component={require('./pph/21_26/ALL_BP_21_INDUK/ALL_BP_21_INDUK.view').default} />
    {/* END ALL_21_26 */}

    {/* IMPORT_21 */}
    <Route path="/company/:companyId/:subscriptionId/init/IMPORT_21" exact component={require('./pph/21_26/Import/Import.form').default} />
    <Route path="/company/:companyId/:subscriptionId/init/IMPORT/BP_21_TF" exact component={require('./pph/21_26/ImportBp21TF/ImportBp21TF.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/IMPORT/BP_21_F" exact component={require('./pph/21_26/ImportBp21F/ImportBp21F.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/IMPORT/BP_21_A1" exact component={require('./pph/21_26/ImportBp21A1/ImportBp21A1.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/IMPORT/BP_21_A1_AUTO" exact component={require('./pph/21_26/ImportBp21A1Auto/ImportBp21A1Auto.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/IMPORT/BP_21_A2" exact component={require('./pph/21_26/ImportBp21A2/ImportBp21A2.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/IMPORT/DAFTAR_BIAYA_21" exact component={require('./pph/21_26/ImportBp21DaftarBiaya/ImportBp21DaftarBiaya.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/IMPORT/BP_21_SATU_MASA" exact component={require('./pph/21_26/ImportBP21SatuMasa/ImportBP21SatuMasa.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/IMPORT/BP_21_SATU_MASA_TIDAK_LEBIH" exact component={require('./pph/21_26/ImportBP21SatuMasaTL/ImportBP21SatuMasaTL.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/IMPORT/SSP_21" exact component={require('./pph/21_26/ImportBp21Ssp/ImportBp21Ssp.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/IMPORT/INDUK_21" exact component={require('./pph/21_26/ImportBp21Induk/ImportBp21Induk.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/IMPORT/LK_21" exact component={require('./pph/21_26/ImportLK/ImportLK21.view').default} />
    {/* END IMPORT_21 */}

    {/* MASTER DATA 21_26 */}
    <Route path="/company/:companyId/:subscriptionId/init/master/pasal-21-upah-harian" exact component={require('./pph/21_26/Master21UpahHarian/Master21UpahHarian.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/master/pasal-21-upah-kumulatif" exact component={require('./pph/21_26/Master21UpahKumulatif/Master21UpahKumulatif.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/master/pasal-21-golongan-a2" exact component={require('./pph/21_26/Master21GolonganA2/Master21GolonganA2.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/master/pasal-21-ssp-description" exact component={require('./pph/21_26/Master21SspDescription/Master21SspDescription.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/master/pasal-21-status-karyawan-a1" exact component={require('./pph/21_26/Master21StatusKaryawanA1/Master21StatusKaryawanA1.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/master/pasal-21-status-karyawan-a2" exact component={require('./pph/21_26/Master21StatusKaryawanA2/Master21StatusKaryawanA2.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/master/pasal-21-kap" exact component={require('./pph/21_26/Master21Kap/Master21Kap.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/master/pasal-21-kjs" exact component={require('./pph/21_26/Master21Kjs/Master21Kjs.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/master/pasal-21-pasal-17-berlapis" exact component={require('./pph/21_26/Master21Pasal17/Master21Pasal17.form.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/master/pasal-21-pasal-17-berlapis/:id" exact component={require('./pph/21_26/Master21Pasal17Prog/Master21Pasal17Prog.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/master/pasal-21-biaya-jabatan" exact component={require('./pph/21_26/Master21BiayaJabatan/Master21BiayaJabatan.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/master/pasal-21-biaya-pensiun" exact component={require('./pph/21_26/Master21BiayaPensiun/Master21BiayaPensiun.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/master/pasal-21-pensiun" exact component={require('./pph/21_26/Master21Pensiun/Master21Pensiun.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/master/pasal-21-ptkp" exact component={require('./pph/21_26/Master21Ptkp/Master21Ptkp.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/master/pasal-21-honor-pns" exact component={require('./pph/21_26/Master21HonorPns/Master21HonorPns.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/master/pasal-21-final-pesangon" exact component={require('./pph/21_26/Master21FinalPesangon/Master21FinalPesangon.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/master/pasal-21-final-pesangon/:id" exact component={require('./pph/21_26/Master21FinalPesangonProg/Master21FinalPesangonProg.view').default} />
    {/* END MASTER DATA 21_26 */}

    {/* MASTER DATA 23 */}
    <Route path="/company/:companyId/:subscriptionId/init/master/pasal-23-kap" exact component={require('./pph/23_26/master/Kap/Master23Kap.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/master/pasal-23-kjs" exact component={require('./pph/23_26/master/Kjs/Master23Kjs.view').default} />

    <Route path="/company/:companyId/:subscriptionId/init/master/tarif-23/:id" exact component={require('./pph/23_26/master/Tarif23/Master23Tarif.form').default} />
    <Route path="/company/:companyId/:subscriptionId/init/master/tarif-23" exact component={require('./pph/23_26/master/Tarif23/Master23Tarif.view').default} />

    <Route path="/company/:companyId/:subscriptionId/init/master/tarif-23-lain" exact component={require('./pph/23_26/master/Tarif23/Master23TarifLain.view').default} />

    <Route path="/company/:companyId/:subscriptionId/init/master/tarif-26/:id" exact component={require('./pph/23_26/master/Tarif23/Master26Tarif.form').default} />
    <Route path="/company/:companyId/:subscriptionId/init/master/tarif-26" exact component={require('./pph/23_26/master/Tarif23/Master26Tarif.view').default} />
    {/* MASTER DATA 23 LAIN */}

    {/* MASTER DATA 22 */}
    <Route path="/company/:companyId/:subscriptionId/init/master/komoditi-22" exact component={require('./pph/22/master/komoditi/Master22Komoditi.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/master/tarif-22" exact component={require('./pph/22/master/tarif/Master22Tarif.view').default} />

    <Route path="/company/:companyId/:subscriptionId/init/company/tarif-22/:id" exact component={require('./pph/22/company/tarif/Tarif22.form').default} />
    <Route path="/company/:companyId/:subscriptionId/init/company/tarif-22" exact component={require('./pph/22/company/tarif/Tarif22.view').default} />
    {/* END MASTER DATA 22 */}

    {/* MASTER DATA 15 */}
    <Route path="/company/:companyId/:subscriptionId/init/master/tarif-15" exact component={require('./pph/15/master/tarif/Master15Tarif.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/company/tarif-15/:id" exact component={require('./pph/15/company/tarif/Tarif15.form').default} />
    <Route path="/company/:companyId/:subscriptionId/init/company/tarif-15" exact component={require('./pph/15/company/tarif/Tarif15.view').default} />
    {/* END MASTER DATA 22 */}

    {/* LOG 21 */}
    <Route path={"/company/:companyId/:subscriptionId/init/LOG_EXPORT_CSV_21"} component={require('./pph/21_26/ExportCsv21Log/ExportCsv21Log.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/LOG_EMAIL"} component={require('./pph/21_26//Email21Log/Email21Log.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/LOG_FILE_LAPOR_21"} component={require('./pph/21_26/FileLapor21Log/FileLapor21Log.view').default} />
    {/* END LOG 21 */}


    {/* 23_26 */}
    <Route path="/company/:companyId/:subscriptionId/init/SPT_23" exact component={require('./pph/23_26/SPT/23_26.spt.view').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/SPT_23/:sptId" exact component={require('./pph/23_26/SPT/23_26.spt.form').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/SPT_23/:sptId/open" component={require('./pph/23_26/main').default}/>
    {/* END 21_26 */}

    {/* Module Ebupot */}
    <Route path="/company/:companyId/:subscriptionId/init/bp-26-pembetulan/:sptId/:id"   component={Bp26PembetulanFormView} />
    <Route path="/company/:companyId/:subscriptionId/init/bp-26-pembetulan/:id"   component={Bp26PembetulanFormView} />
    <Route path="/company/:companyId/:subscriptionId/init/bp-26-pembetulan"       component={Bp26PembetulanView} />
    <Route path="/company/:companyId/:subscriptionId/init/bp-26-edit/:sptId/:id"         component={Bp26EditFormView} />
    <Route path="/company/:companyId/:subscriptionId/init/bp-26-edit/:id"         component={Bp26EditFormView} />
    <Route path="/company/:companyId/:subscriptionId/init/bp-26-edit"             component={Bp26EditView} />
    <Route path="/company/:companyId/:subscriptionId/init/bp-23-pembetulan/:sptId/:id"   component={Bp23PembetulanFormView} />
    <Route path="/company/:companyId/:subscriptionId/init/bp-23-pembetulan/:id"   component={Bp23PembetulanFormView} />
    <Route path="/company/:companyId/:subscriptionId/init/bp-23-pembetulan"       component={Bp23PembetulanView} />
    <Route path="/company/:companyId/:subscriptionId/init/bp-23-edit/:sptId/:id"         component={Bp23EditFormView} />
    <Route path="/company/:companyId/:subscriptionId/init/bp-23-edit/:id"         component={Bp23EditFormView} />
    <Route path="/company/:companyId/:subscriptionId/init/bp-23-edit"             component={Bp23EditView} />
    <Route path="/company/:companyId/:subscriptionId/init/ebupot-23/spt-history/form/:id/:createdDate" component={SPT23HistoryFormView} />
    <Route path="/company/:companyId/:subscriptionId/init/ebupot-23/spt-history/:sptId/:createdDate"   component={SPT23HistoryView} />
    <Route path="/company/:companyId/:subscriptionId/init/ebupot-23/bpe/form/:id" component={BpeFormView} />
    <Route path="/company/:companyId/:subscriptionId/init/ebupot-23/bpe/:sptId"   component={BpeView} />
    <Route path="/company/:companyId/:subscriptionId/init/ebupot-23/:sptId/open"  component={SPT23MainView} />
    <Route path="/company/:companyId/:subscriptionId/init/ebupot-23/:sptId"       component={SPT23FormView} />
    <Route path="/company/:companyId/:subscriptionId/init/ebupot-23"              component={SPT23View} />
    <Route path="/company/:companyId/:subscriptionId/init/users/:id"              component={UserFormView} />
    <Route path="/company/:companyId/:subscriptionId/init/users"                  component={UserView} />
    <Route path="/company/:companyId/:subscriptionId/init/application-email"      component={ApplicationEmailFormView} />
    <Route path="/company/:companyId/:subscriptionId/init/application/:id"        component={ApplicationFormView} />
    <Route path="/company/:companyId/:subscriptionId/init/application"            component={ApplicationView} />
    <Route path="/company/:companyId/:subscriptionId/init/client/:appId"          component={ClientView} />
    {/* <Route path="/company/:companyId/:subscriptionId/init/ebupot/organization-users/:id" component={OrganizationUserForm} />
    <Route path="/company/:companyId/:subscriptionId/init/ebupot/organization-users" component={OrganizationUserView} /> */}
    <Route path="/company/:companyId/:subscriptionId/init/ebupot/organization/:id"component={OrganizationFormView} />
    <Route path="/company/:companyId/:subscriptionId/init/ebupot/organization"    component={OrganizationView} />
    <Route path="/company/:companyId/:subscriptionId/init/LogSubmit/:id"          component={LogSubmitFormView} />
    <Route path="/company/:companyId/:subscriptionId/init/log-submit"             component={LogSubmitView} />
    <Route path="/company/:companyId/:subscriptionId/init/LogCheck/:id"           component={LogCheckFormView} />
    <Route path="/company/:companyId/:subscriptionId/init/log-check"              component={LogCheckView} />
    <Route path="/company/:companyId/:subscriptionId/init/log-file-validasi/:id/:type/:status"      component={LogFileValidasiView} />
    <Route path="/company/:companyId/:subscriptionId/init/log-file-validasi"      component={LogFileValidasiView} />
    <Route path="/company/:companyId/:subscriptionId/init/log-file-csv"           component={LogFileCsvView} />
    <Route path="/company/:companyId/:subscriptionId/init/converted-excel"           component={LogConvertedExcelView} />
    <Route path="/company/:companyId/:subscriptionId/init/log-file"               component={LogFileView} />
    <Route path="/company/:companyId/:subscriptionId/init/ebupot/signer/:org/:id"        component={SignerFormView} />
    <Route path="/company/:companyId/:subscriptionId/init/ebupot/signer/:id"             component={SignerView} />
    <Route path="/company/:companyId/:subscriptionId/init/kodeNegara/:id"         component={KodeNegaraFormView} />
    <Route path="/company/:companyId/:subscriptionId/init/kodeNegara"             component={KodeNegaraView} />
    <Route path="/company/:companyId/:subscriptionId/init/provinces/:id"          component={ProvincesFormView} />
    <Route path="/company/:companyId/:subscriptionId/init/provinces"              component={ProvincesView} />
    <Route path="/company/:companyId/:subscriptionId/init/kodeBuktiPotong/:id"    component={KodeBuktiPotongFormView} />
    <Route path="/company/:companyId/:subscriptionId/init/kodeBuktiPotong"        component={KodeBuktiPotongView} />
    <Route path="/company/:companyId/:subscriptionId/init/jenisDokumen/:id"       component={JenisDokumenFormView} />
    <Route path="/company/:companyId/:subscriptionId/init/jenisDokumen"           component={JenisDokumenView} />
    <Route path="/company/:companyId/:subscriptionId/init/regencies/:id"          component={RegencyFormView} />
    <Route path="/company/:companyId/:subscriptionId/init/regencies"              component={RegencyView} />
    <Route path="/company/:companyId/:subscriptionId/init/districts/:id"          component={DistrictFormView} />
    <Route path="/company/:companyId/:subscriptionId/init/districts"              component={DistrictView} />
    <Route path="/company/:companyId/:subscriptionId/init/villages/:id"           component={VillagesFormView} />
    <Route path="/company/:companyId/:subscriptionId/init/villages"               component={VillageView} />
    <Route path="/company/:companyId/:subscriptionId/init/kode-bukti-potong-26"   component={KodeBuktiPotong26View} />
    <Route path="/company/:companyId/:subscriptionId/init/all-ebupot-23"          component={AllEbupot23View} />
    <Route path="/company/:companyId/:subscriptionId/init/all-ebupot-26"          component={AllEbupot26View} />
    <Route path="/company/:companyId/:subscriptionId/init/bupot23/history/:bpId/:sptId/:id" component={Bupot23HistoryFormView} />
    <Route path="/company/:companyId/:subscriptionId/init/bupot26/history/:bpId/:sptId/:id" component={Bupot26HistoryFormView} />
    <Route path="/company/:companyId/:subscriptionId/init/bupot23/history/:id"    component={Bupot23HistoryView} />
    <Route path="/company/:companyId/:subscriptionId/init/bupot26/history/:id"    component={Bupot26HistoryView} />
    <Route path="/company/:companyId/:subscriptionId/init/bupot23/email/:bpId/:id" component={Bupot23EmailFormView} />
    <Route path="/company/:companyId/:subscriptionId/init/bupot26/email/:bpId/:id" component={Bupot26EmailFormView} />
    <Route path="/company/:companyId/:subscriptionId/init/bupot23/email/:id"       component={Bupot23EmailView} />
    <Route path="/company/:companyId/:subscriptionId/init/bupot26/email/:id"       component={Bupot26EmailView} />
    <Route path="/company/:companyId/:subscriptionId/init/bp-23/:sptId/:id"              component={Bupot23FormView} />
    <Route path="/company/:companyId/:subscriptionId/init/bp-26/:sptId/:id"              component={Bupot26FormViewView} />
    <Route path="/company/:companyId/:subscriptionId/init/bp-23/:id"              component={Bupot23FormView} />
    <Route path="/company/:companyId/:subscriptionId/init/bp-26/:id"              component={Bupot26FormViewView} />
    <Route path="/company/:companyId/:subscriptionId/init/bp-23"                  component={Bupot23View} />
    <Route path="/company/:companyId/:subscriptionId/init/bp-26"                  component={Bupot26View} />
    <Route path="/company/:companyId/:subscriptionId/init/lawan-transaksi-26/:id" component={LawanTransaksi26FormView} />
    <Route path="/company/:companyId/:subscriptionId/init/lawan-transaksi-26"     component={LawanTransaksi26View} />
    <Route path="/company/:companyId/:subscriptionId/init/lawan-transaksi-23/:id" component={LawanTransaksi23FormView} />
    <Route path="/company/:companyId/:subscriptionId/init/lawan-transaksi-23"     component={LawanTransaksi23View} />
    <Route path="/company/:companyId/:subscriptionId/init/ebupot-dashboard" component={Dashboard}/>

    {/* EBUPOT 23_26 */}
    <Route path="/company/:companyId/:subscriptionId/init/EBUPOT_23"             exact component={require('./pph/23_26.Ebupot/SPT/23_26.Ebupot.spt.view').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/EBUPOT_23/:sptId"      exact component={require('./pph/23_26.Ebupot/SPT/23_26.Ebupot.spt.form').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/EBUPOT_23/:sptId/open"       component={require('./pph/23_26.Ebupot/main').default}/>
    {/* END EBUPOT 23_26 */}

    {/* ALL EBUPOT 23_26 */}
    <Route path={"/company/:companyId/:subscriptionId/init/ALL_EBUPOT_23/:id"}    component={require('./pph/23_26.Ebupot/BP_Ebupot_23/BP_Ebupot_23.form').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/ALL_EBUPOT_26/:id"}    component={require('./pph/23_26.Ebupot/BP_Ebupot_26/BP_Ebupot_26.form').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/ALL_EBUPOT_23"}        component={require('./pph/23_26.Ebupot/all/ALL_BP_23_EBUPOT/ALLBP23.Ebupot.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/ALL_EBUPOT_26"}        component={require('./pph/23_26.Ebupot/all/ALL_BP_26_EBUPOT/ALLBP26.Ebupot.view').default} />
    {/* ALL EBUPOT 23_26 */}

    {/* BP 22 */}
    <Route path="/company/:companyId/:subscriptionId/init/SPT_22" exact component={require('./pph/22/SPT/SPT22.view').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/SPT_22/:sptId" exact component={require('./pph/22/SPT/SPT22.form').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/SPT_22/:sptId/open" component={require('./pph/22/main').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/SIGNER_22" component={require('./pph/22/SPT22Signer/SPT22Signer.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/SIGNER_22_IMAGE" component={require('./Setting/FileUpload22/FileUpload22.view').default} />
    {/* END OF BP 22   */}

    {/* 4a2 */}
    <Route path="/company/:companyId/:subscriptionId/init/SPT_4a2" exact component={require('./pph/4a2/SPT/Spt4a2.view').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/SPT_4a2/:sptId" exact component={require('./pph/4a2/SPT/SPt4a2.form').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/SPT_4a2/:sptId/open" component={require('./pph/4a2/main').default}/>

    <Route path={"/company/:companyId/:subscriptionId/init/ALLBP4A2/:id"} component={require('./pph/4a2/BP4a2/BP4a2.form').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/ALLBP4A2"} component={require('./pph/4a2/all/AllBp4a2/AllBp4a2View').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/ALLSSP4A2/:id"} component={require('./pph/4a2/SSP/SSP4a2.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/ALLSSP4A2"} component={require('./pph/4a2/all/AllSsp/AllSsp4a2.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/ALLSPT4a2Induk/:id"} component={require('./pph/4a2/Induk/Spt4a2Induk.form').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/ALLSPT4a2Induk"} component={require('./pph/4a2/all/AllInduk/AllSpt4a2Induk.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/ALLPBK4A2"} component={require('./pph/4a2/all/AllPbk4a2/AllPbk4a2.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/ALLPBK4A2/:id"} component={require('./pph/4a2/PBK/PBK4a2.form').default} />

    <Route path="/company/:companyId/:subscriptionId/init/master/tarif-4a2/:id" exact component={require('./pph/4a2/master/tarif/Tarif4a2.form').default} />
    <Route path="/company/:companyId/:subscriptionId/init/master/tarif-4a2" exact component={require('./pph/4a2/master/tarif/Tarif4a2.view').default} />

    <Route path="/company/:companyId/:subscriptionId/init/SIGNER_4" component={require('./pph/4a2/SPT4Signer/SPT4Signer.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/SIGNER_4_IMAGE" component={require('./Setting/FileUpload4/FileUpload4.view').default} />
    {/* END 4a2 */}

    {/* ALL 23_26 */}
    <Route path={"/company/:companyId/:subscriptionId/init/ALLBP23/:id"} component={require('./pph/23_26/BP_23/BP_23.form').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/ALLBP23"} component={require('./pph/23_26/all/ALL_BP_23/ALLBP23.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/ALLBP26/:id"} component={require('./pph/23_26/BP_26/BP_26.form').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/ALLBP26"} component={require('./pph/23_26/all/ALL_BP_26/ALLBP26.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/ALLSSP23/:id"} component={require('./pph/23_26/SSP/SSP23.form').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/ALLSSP23"} component={require('./pph/23_26/all/ALL_SSP/ALLSSP23.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/ALLSPT23Induk/:id"} component={require('./pph/23_26/Induk/Spt23Induk.form').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/ALLSPT23Induk"} component={require('./pph/23_26/all/ALL_Induk/ALLSPT23Indu.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/SIGNER_23"} component={require('./pph/23_26/SPT23Signer/SPT23Signer.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/SIGNER_23_IMAGE"} component={require('./Setting/FileUpload23/FileUpload23.view').default} />
    {/* END ALL 23_26 */}

    {/* 15 */}
    <Route path="/company/:companyId/:subscriptionId/init/SPT_15" exact component={require('./pph/15/SPT/Spt15.view').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/SPT_15/:sptId" exact component={require('./pph/15/SPT/Spt15.form').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/SPT_15/:sptId/open" component={require('./pph/15/main').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/SIGNER_15" component={require('./pph/15/SPT15Signer/SPT15Signer.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/SIGNER_15_IMAGE" component={require('./Setting/FileUpload15/FileUpload15.view').default} />
    {/* 15 */}

    {/* BP 25 */}
    <Route path="/company/:companyId/:subscriptionId/init/SPT_25" exact component={require('./pph/25/SPT/SPT25.view').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/SPT_25/:sptId" exact component={require('./pph/25/SPT/SPT25.form').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/SPT_25/:sptId/open" component={require('./pph/25/main').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/AllSSP25" component={require('./pph/25/All/All_SSP25/AllSSP25.view').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/AllBP25" component={require('./pph/25/All/All_BP_25/AllBP25.view').default}/>
    {/* END OF BP 25   */}

    {/* IMPORT 4a2 */}
    <Route path={"/company/:companyId/:subscriptionId/init/IMPORT/BP_4a2"} component={require('./pph/4a2/Import/ImportBP4a2/ImportBP4a2.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/IMPORT/DAFTAR_PEMOTONGAN_4a2"} component={require('./pph/4a2/Import/ImportDaftarPemotongan4a2/ImportDaftarPemotongan4a2.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/IMPORT/PBK_4a2"} component={require('./pph/4a2/Import/ImportPBK/ImportPBK4a2.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/IMPORT/SSP_4a2"} component={require('./pph/4a2/Import/ImportSSP/ImportSSP4a2.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/IMPORT/INDUK_PT_4a2"} component={require('./pph/4a2/Import/ImportInduk4a2PenghasilanTertentu/ImportInduk4a2PenghasilanTertentu.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/IMPORT/LK_4a2"} component={require('./pph/4a2/Import/ImportLK/ImportLK4a2.view').default} />
    {/* END OF 4a2 */}

    {/* IMPORT 23_26 */}
    <Route path={"/company/:companyId/:subscriptionId/init/IMPORT/BP_23"} component={require('./pph/23_26/Import/ImportBP23/ImportBP23.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/IMPORT/BP_26"} component={require('./pph/23_26/Import/ImportBP26/ImportBP26.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/IMPORT/IMPORT_PBK"} component={require('./pph/23_26/Import/ImportPBK/ImportPBK23.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/IMPORT/SSP_23"} component={require('./pph/23_26/Import/ImportSSP/ImportSSP23.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/IMPORT/LT_23"} component={require('./pph/23_26/Import/ImportLK/ImportLK23.view').default} />
    {/* END OF IMPORT 23_26 */}

    {/* IMPORT 22 */}
    <Route path={"/company/:companyId/:subscriptionId/init/IMPORT/BP_22"} component={require('./pph/22/Import/ImportBP22AtasImpor/ImportBP22AtasImpor.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/IMPORT/BP_22_industri_eksportir"} component={require('./pph/22/Import/ImportBP22IndustriEksportir/ImportBP22IndustriEksportir.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/IMPORT/BP_22_industri_importir"} component={require('./pph/22/Import/ImportBP22IndustriImportir/ImportBP22IndustriImportir.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/IMPORT/BP_22_badan_tertentu"} component={require('./pph/22/Import/ImportBP22BadanTertentu/ImportBP22BadanTertentu.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/IMPORT/SSP_22_dibayar_sendiri"} component={require('./pph/22/Import/ImportSSP22DibayarSendiri/ImportSSP22DibayarSendiri.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/IMPORT/SSP_22_bank_devisa"} component={require('./pph/22/Import/ImportSSP22BankDevisa/ImportSSP22BankDevisa.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/IMPORT/SSP_22_bendaharawan"} component={require('./pph/22/Import/ImportSSP22Bendaharawan/ImportSSP22Bendarawan.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/IMPORT/SSP_22"} component={require('./pph/22/Import/ImportSSP/ImportSSP22.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/IMPORT/PBK_22"} component={require('./pph/22/Import/ImportPBK/ImportPBK22.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/IMPORT/LK_22"} component={require('./pph/22/Import/ImportLK/ImportLK22.view').default} />

    <Route path={"/company/:companyId/:subscriptionId/init/ALLBP22"} component={require('./pph/22/All/AllBp22/AllBp22View').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/ALL_SSP22_PEMBELIAN_BARANG"} component={require('./pph/22/All/AllSsp22PembelianBarang/AllSsp22PembelianBarang.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/ALL_SSP22_OLEH_BANK_DEVISA"} component={require('./pph/22/All/AllSsp22ImportBankDevisa/AllSsp22ImportBankDevisa.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/ALL_SSP22_DIBAYAR_SENDIRI"} component={require('./pph/22/All/AllSsp22DibayarSendiri/AllSsp22DibayarSendiri.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/ALLSSP22"} component={require('./pph/22/All/AllSsp/AllSsp22.view').default} />
    {/* END OF IMPORT 22 */}

    {/* IMPORT 15 */}
    <Route path={"/company/:companyId/:subscriptionId/init/IMPORT/BP_15"} component={require('./pph/15/Import/ImportBp15/ImportBp15.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/IMPORT/BP_24"} component={require('./pph/15/Import/ImportBp24/ImportBp24.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/IMPORT/SSP_15_SETOR_SENDIRI"} component={require('./pph/15/Import/ImportSSPSetorSendiri/ImportSSP15SetorSendiri.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/IMPORT/SSP_15"} component={require('./pph/15/Import/ImportSSP/ImportSSP15.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/IMPORT/PBK_15"} component={require('./pph/15/Import/ImportPBK/ImportPBK15.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/IMPORT/LK_15"} component={require('./pph/15/Import/ImportLK/ImportLK15.view').default} />

    <Route path={"/company/:companyId/:subscriptionId/init/ALLBP15DipotongPihakLain"} component={require('./pph/15/All/AllDipotongPihakLain/AllBP15DipotongPihakLain.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/ALLBP15PihakLainDipotong"} component={require('./pph/15/All/AllPihakLainDipotong/AllBP15PihakLainDipotong.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/ALLBP24"} component={require('./pph/15/All/AllPasal24/AllBP15Pasal24.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/ALLSSP15"} component={require('./pph/15/All/AllSsp/AllSsp15.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/ALLSSP15SETORSENDIRI"} component={require('./pph/15/All/AllSsp15SetorSendiri/AllSsp15SetorSendiri.view').default} />
    {/* END OF IMPORT 15 */}

    {/* PengaturanBP */}
    <Route path={"/company/:companyId/:subscriptionId/init/nomorBp/:pasal"} render={(props) => (
      <PengaturanNoBPView key={props.match.params.pasal} {...props} />
    )} />
    {/* PengaturanBP */}

    <Route path={"/company/:companyId/:subscriptionId/init/file"} component={require('./Setting/FileUpload/FileUpload.view').default} />

    {/* log export */}
    <Route path={"/company/:companyId/:subscriptionId/init/logExport/:pasal"} render={(props) => (
      <LogExportView key={props.match.params.pasal} {...props} />
    )} />
    {/* END log export */}

    {/* Summary */}

    <Route path={"/company/:companyId/:subscriptionId/init/summary/:pasal"} render={(props) => (
      <SummaryForm key={props.match.params.pasal} {...props} />
    )} />

    <Route path={"/company/:companyId/:subscriptionId/init/kalkulasiInduk/:pasal"} render={(props) => (
      <KalkulasiIndukForm key={props.match.params.pasal} {...props} />
    )} />

    {/* SETTING */}
    <Route path={"/company/:companyId/:subscriptionId/init/SETTING_EMAIL"} component={require('./pph/21_26/SettingEmail/SettingEmail.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/SETTING_EMAIL_CONTENT"} component={require('./Setting/SettingEmailContent/SettingEmailContent.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/SETTING_EMAIL_SCHEDULE"} component={require('./Setting/SettingEmailSchedule/SettingEmailSchedule.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/SETTING_IMPORT"} component={require('./Setting/SettingImport/SettingImport.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/SETTING_EXPORT"} component={require('./Setting/SettingExport/SettingExport.view').default} />

    <Route path={"/company/:companyId/:subscriptionId/init/SETTING_EMAIL/:companyId"} component={require('./pph/21_26/SettingEmail/SettingEmail.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/SETTING_EMAIL_CONTENT/:companyId"} component={require('./Setting/SettingEmailContent/SettingEmailContent.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/SETTING_EMAIL_SCHEDULE/:companyId"} component={require('./Setting/SettingEmailSchedule/SettingEmailSchedule.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/SETTING_IMPORT/:companyId"} component={require('./Setting/SettingImport/SettingImport.view').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/SETTING_EXPORT/:companyId"} component={require('./Setting/SettingExport/SettingExport.view').default} />

    <Route path={"/company/:companyId/:subscriptionId/init/SETTING_EMAIL_ALL"} component={require('./pph/21_26/SettingEmail/SettingEmail.view.all').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/SETTING_EMAIL_CONTENT_ALL"} component={require('./Setting/SettingEmailContent/SettingEmailContent.view.all').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/SETTING_EMAIL_SCHEDULE_ALL"} component={require('./Setting/SettingEmailSchedule/SettingEmailSchedule.view.all').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/SETTING_IMPORT_ALL"} component={require('./Setting/SettingImport/SettingImport.view.all').default} />
    <Route path={"/company/:companyId/:subscriptionId/init/SETTING_EXPORT_ALL"} component={require('./Setting/SettingExport/SettingExport.view.all').default} />

    <Route path={"/company/:companyId/:subscriptionId/init/logged-in"} component={require('./Setting/LogginIn/LogginIn.view').default} />
    {/* END SETTING */}

    {/* EBILLING */}
    <Route path="/company/:companyId/:subscriptionId/init/EBILLING/SETTING" exact component={require('./Ebilling/SettingEbilling/SettingEbilling.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/EBILLING/IMPORT/INQUIRY" exact component={require('./Ebilling/ImportInquiry/ImportInquiry.view').default}/>
    <Route path={"/company/:companyId/:subscriptionId/init/EBILLING/INQUIRY/:id"} component={require('./Ebilling/DataInquiry/DataInquiry.form').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/EBILLING/INQUIRY" exact component={require('./Ebilling/DataInquiry/DataInquiry.view').default}/>


    <Route path="/company/:companyId/:subscriptionId/init/EBILLING/DataESSP" exact component={require('../Main/Ebilling/DataESSP/DataESSP.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/EBILLING/DataESSP/hitung" exact component={require('../Main/Ebilling/DataESSP/DataESSPHitung.form').default} />
    <Route path="/company/:companyId/:subscriptionId/init/EBILLING/DataESSP/hitung/ebupot" exact component={require('../Main/Ebilling/DataESSP/DataESSPHitungEbupot.form').default} />
    <Route path="/company/:companyId/:subscriptionId/init/EBILLING/DataESSP/duplicate" exact component={require('./Ebilling/DataESSP/DataESSPDuplicate.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/EBILLING/DataESSP4" exact component={require('../Main/Ebilling/DataESSP4/DataESSP4.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/EBILLING/DataESSP4/hitung" exact component={require('../Main/Ebilling/DataESSP4/DataESSPHitung4.form').default} />
    <Route path="/company/:companyId/:subscriptionId/init/EBILLING/DataESSP4/duplicate" exact component={require('./Ebilling/DataESSP4/DataESSPDuplicate4.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/EBILLING/DataESSP23" exact component={require('../Main/Ebilling/DataESSP23/DataESSP23.view').default} />
    <Route path="/company/:companyId/:subscriptionId/init/EBILLING/DataESSP23/hitung" exact component={require('../Main/Ebilling/DataESSP23/DataESSPHitung23.form').default} />
    <Route path="/company/:companyId/:subscriptionId/init/EBILLING/DataESSP23/duplicate" exact component={require('./Ebilling/DataESSP23/DataESSPDuplicate23.view').default} />

    <Route path="/company/:companyId/:subscriptionId/init/EBILLING/paymentOrder/bniDirect" exact component={require('./Ebilling/PaymentOrder/BniDirect.form').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/EBILLING/paymentOrder/briCms" exact component={require('./Ebilling/PaymentOrder/BriCms.form').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/EBILLING/paymentOrder/mandiriCm" exact component={require('./Ebilling/PaymentOrder/MandiriCm.form').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/EBILLING/paymentOrder/:id" exact component={require('./Ebilling/PaymentOrder/PaymentOrder.form').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/EBILLING/paymentOrder" exact component={require('./Ebilling/PaymentOrder/PaymentOrder.view').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/EBILLING/akunPembayaran" exact component={require('./Ebilling/AkunPembayaran/AkunPembayaran.view').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/EBILLING/paymentNotification/:id/orderFile" exact component={require('./Ebilling/PaymentNotification/PaymentNotification.orderFile.form').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/EBILLING/paymentNotification/:id/bpn" exact component={require('./Ebilling/PaymentNotification/PaymentNotification.bpn.form').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/EBILLING/paymentNotification/:id" exact component={require('./Ebilling/PaymentNotification/PaymentNotification.detail.view').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/EBILLING/paymentNotification" exact component={require('./Ebilling/PaymentNotification/PaymentNotification.view').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/EBILLING/IMPORT/ESSP" exact component={require('./Ebilling/ImportEssp/ImportEssp.view').default}/>
    {/* END EBILLING */}

    {/* LOG EBILLING */}
    <Route path="/company/:companyId/:subscriptionId/init/EBILLING/LOG/LogExportDataInquiry" exact component={require('./Ebilling/log/LogExportDataInquiry/LogExportDataInquiry.view').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/EBILLING/LOG/LogExportDataSSP" exact component={require('./Ebilling/log/LogExportDataSSP/LogExportDataSSP.view').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/EBILLING/LOG/LogCetakPaymentOrder" exact component={require('./Ebilling/log/LogCetakPaymentOrder/LogCetakPaymentOrder.view').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/EBILLING/LOG/LogCetakPaymenNotification" exact component={require('./Ebilling/log/LogCetakPaymentNotification/LogCetakPaymentNotification.view').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/EBILLING/LOG/LogCetakBPN" exact component={require('./Ebilling/log/LogCetakBPN/LogCetakBPN.view').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/EBILLING/LOG/LogCetakLaporanPO" exact component={require('./Ebilling/log/LogCetakLaporanPO/LogCetakLaporanPO.view').default}/>
    <Route path="/company/:companyId/:subscriptionId/init/EBILLING/LOG/LogCetakLaporanBPN" exact component={require('./Ebilling/log/LogCetakLaporanBPN/LogCetakLaporanBPN.view').default}/>
    {/*<Route path="/company/:companyId/:subscriptionId/init/EBILLING/LOG/LogCetakLaporanPN" exact component={require('./Ebilling/log/LogCetakLaporanPN/LogCetakLaporanPN.view').default}/>*/}
    {/* END LOG EBILLING */}

    {/* ACCOUNT */}
    <Route path={"/company/:companyId/:subscriptionId/init/EDIT_PROFILE"} render={(props) => (
      <EditProfileForm {...props} />
    )} />

    <Route path={"/company/:companyId/:subscriptionId/init/EDIT_PASSWORD"} render={(props) => (
      <EditPasswordForm {...props} />
    )} />
    {/* END ACCOUNT */}

    {/* KEY */}
    <Route path={"/company/:companyId/:subscriptionId/init/key/:pasal"} render={(props) => (
      <KeyView key={props.match.params.pasal} {...props} />
    )} />
    {/* END KEY */}

    <Route path={"/company/:companyId/:subscriptionId/init/cop"} component={Test}/>

    <Route path="/company/:companyId/:subscriptionId/init/" component={require('./Dashboard/Dashboard.view').default}/>
  </Switch>
)





