import ApiService from '../../../../services/api.service';

class DataESSP4Service extends ApiService {
  name = 'DataESSP4';
  path = 'pasal4/ebilling/data-essp';

  api = {
    generate: async (data) => {
      var res = await this.http.post(`${this.apiLocation}/pasal4/ebilling/billing/generate`, data)

      return res.data;
    },
    generateByFilter: async (data) => {
      var res = await this.http.get(`${this.apiLocation}/pasal4/ebilling/billing/generate/filter`, { params: data })

      return res.data;
    },
    save: async (data) => {
      var res = await this.http.post(`${this.apiLocation}/pasal4/ebilling/billing/save`, data)

      return res.data;
    },
    saveByFilter: async (data) => {
      var res = await this.http.get(`${this.apiLocation}/pasal4/ebilling/billing/save/filter`, { params: data })

      return res.data;
    },
    deleteSelected: async (ids) => {
      var res = await this.http.post(`${this.apiLocation + "/" + this.path}/delete`, ids)

      return res.data;
    },
    calculate: async (data) => {
      var res = await this.http.post(`${this.apiLocation + "/" + this.path}/calculate`, data)

      return res.data;
    },
    calculateEbupot: async (data) => {
      var res = await this.http.post(`${this.apiLocation + "/" + this.path}/calculate/ebupot`, data)

      return res.data;
    },
    calculateIsRunning: async () => {
      var res = await this.http.get(`${this.apiLocation + "/" + this.path}/calculate/isRunning`)

      return res.data;
    },
    calculateIsRunningEbupot: async () => {
      var res = await this.http.get(`${this.apiLocation + "/" + this.path}/calculate/isRunning/ebupot`)

      return res.data;
    },

    cetak: async (id) => {
      var res = await this.http.get(`${this.apiLocation}/pasal4/ebilling/essp/report/${id}`, {
        responseType: 'blob'
      })

      return res;
    },

    csvBySelected: async (ids) => {
      var res = await this.http.post(this.apiLocation+'/pasal4/ebilling/essp/csv-checked', ids)

      return res.data;
    },

    csvByFilter: async (data) => {
      var res = await this.http.get(this.apiLocation+'/pasal4/ebilling/essp/csv-filter', {params: data})

      return res.data;
    },
  }

  constructor() {
    super()
    this.init()
    this.initApi()
  }
}

export default new DataESSP4Service();
