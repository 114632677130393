import ApiService from '../../../../../services/api.service'
import moment from 'moment'

class VillagesService extends ApiService {
  name= 'Villages'
  path= 'ebupot/villages'
  advancedSearch=true

  constructor(){
    super()
    this.init()
    this.initApi()
  }

  columns = [
    {label: "word.id", searchField: "id", value: "id", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
    {label: "word.districtId", searchField: "districtId", value: "districtId", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {width: 300, label: "word.name", searchField: "name", value: "name", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {width: 150, label: "word.createdBy", searchField: "createdBy", value: "createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.createdDate", searchField: "createdDate", value: (d)=> {
      if(d){
        return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
      } else {
        return "-"
      }
    }, type: 'func', isDefaultSort:true, isSortable:true, show:true, isSearchable:true},
    {width: 150, label: "word.lastModifiedBy", searchField: "lastModifiedBy", value: "lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.lastModifiedDate", searchField: "lastModifiedDate", value: (d)=> {
      if(d.lastModifiedDate){
        return moment(d.lastModifiedDate).format('DD/MM/YYYY HH:MM')
      } else {
        return "-"
      }
    }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
  ]

  api = {
    getByName: async (stringQuery)=> {
      let res = await this.http.get(`${this.apiLocation}/${this.path}?${stringQuery}`)
      return res.data[0]
    },
    searchIDF: async (stringQuery)=> {
      let res = await this.http.get(`${this.apiLocation}/${this.path}?${stringQuery}`)
      return res.data[0]
    }
  }

}

export default new VillagesService();

