import ApiService from '../../../../../services/api.service';
import http from "../../../../../services/http.service";

class SSP15Service extends ApiService {
  name= 'SSP15';
  path= 'pasal15/ssp';
  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    save: async(data)=> {
      var res = await this.http.post(`${this.apiLocation}/${this.path}`, data, {
        params: {
          blockBunifikasiMonth: data.blockBunifikasiMonth,
          blockBunifikasiYear: data.blockBunifikasiYear
        }
      })
      return res;
    },
    update: async (data) => {
      var res = await this.http.put(`${this.apiLocation}/${this.path}`, data, {
        params: {
          blockBunifikasiMonth: data.blockBunifikasiMonth,
          blockBunifikasiYear: data.blockBunifikasiYear
        }
      })
      return res;
    },
    getTotal: async(sptId)=> {
      return await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/total`)
    },
    getSptInfo: async(sptId)=> {
      return await this.http.get(`${this.apiLocation}/pasal15/spt/${sptId}/info`)
    },
    cetak: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/pasal15/spt15/ssp/report/${sptId}`, {
        responseType: 'blob'
      })

      return res;
    },
    downloadCsv: async (sptId)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/csv/${sptId}`, {
        responseType: 'blob'
      })

      return res;
    },
    deleteBulk: async (sptId, ids)=> {
      let res = await http.post(`${this.apiLocation}/${this.path}/${sptId}/delete`, ids);

      return res;
    },
    deleteItem: async (sptId, ids)=> {
      let res = await http.delete(`${this.apiLocation}/${this.path}/${ids[0]}`);

      return res;
    }
  }
}


export default new SSP15Service();
