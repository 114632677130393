import ApiService from '../../../../../../services/api.service';
import http from "../../../../../../services/http.service";

class BP15DipotongPihakLainService extends ApiService {
  name= 'BP15DipotongPihakLain';
  path= 'pasal15/bp-dipotong-pihak-lain';

  api= {
    save: async (data) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}`, data, {
        params: {
          blockBunifikasiMonth: data.blockBunifikasiMonth,
          blockBunifikasiYear: data.blockBunifikasiYear
        }
      })
      return res;
    },
    update: async (data) => {
      var res = await this.http.put(`${this.apiLocation}/${this.path}`, data, {
        params: {
          blockBunifikasiMonth: data.blockBunifikasiMonth,
          blockBunifikasiYear: data.blockBunifikasiYear
        }
      })
      return res;
    },
    calculate: async (param) => {
      var res = await this.http.post(this.apiLocation+'/calculation/pph15/f', param)

      return res.data;
    },
    total: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/total`)

      return res.data;
    },
    cetak: async (ids) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${ids[0]}/report`)

      return res;
    },
    cetakBulk: async (sptId, id) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/report`, ids)

      return res;
    },
    cetakBulkPisah: async (sptId, ids) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/report/pisah`, ids)
      return res;
    },
    cetakDaftar: async (esptId) => {
      var res = await this.http.get(`${this.apiLocation}/pasal15/bp/${esptId}/spt/report-list`)

      return res;
    },
    downloadCsv: async (sptId)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/csv/${sptId}`, {
        responseType: 'blob'
      })

      return res;
    },
    email: async (category, ids) => {
      return await this.http.post(`${this.apiLocation}/email?category=${category}`, ids)
    },
    emailSpt: async (category, sptId, ids, companyId) => {
      if(ids.length == 1){
        return await this.http.get(`${this.apiLocation}/${this.path}/${ids[0]}/mail`, {
          params: {
            companyId: companyId
          }
        })
      } else {
        return await this.http.post(`${this.apiLocation}/${this.path}/mail`, ids, {
          params: {
            companyId: companyId
          }
        })
      }
    },
    deleteBulk: async (ids)=> {
      let res = await http.post(`${this.apiLocation}/${this.path}/delete`, ids);

      return res;
    },
  }

  constructor() {
    super()
    this.init()
    this.initApi()
  }
}


export default new BP15DipotongPihakLainService();
