import ApiService from '../../../../../../services/api.service';

class ALLSPT23IndukService extends ApiService {
  name= 'ALLSPT23Induk';
  path= 'pasal23/spt23/induk/organization';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    email: async (category, ids) => {
      return await this.http.post(`${this.apiLocation}/pasal23/email?category=${category}`, ids)
    }
  }

}

export default new ALLSPT23IndukService();
