import React, { Component } from 'react';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import { validation, Textfield, TextfieldMask, Datepicker, convert } from '../../../../../../components/form';
import Service from './ImportLK22.service';
import ImportDialogTemplateView from '../../../../../../components/entity/import.dialog.template.view2';
ImportDialogTemplateView

@reduxForm({form: 'ImportLK22-form', destroyOnUnmount: false})
export default class ImportLK22DialogView extends ImportDialogTemplateView {
  pasal="pasal22"
  service=Service
}
