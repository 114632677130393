import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Chip,
  Switch,
  List,
  ListItem,
  Subheader,
  FontIcon,
  Avatar,
  Divider
} from 'react-md';

import {validation, Textfield, Searchfield, TextfieldMask, Datepicker, Radio, Checkbox} from '../../../../components/form';
import Bluebird from 'bluebird';
import { Field, reduxForm, getFormValues, change  } from 'redux-form';
// import EbillingAccountService from '../EbillingAccountService';
//import FormDialog from './Organization.dialog.view';
import UtilService from '../../../../services/utils.service';
import download from 'downloadjs';
import iziToast from 'izitoast';
import ListView from '../../../../components/entity/listView';
import AkunPembayaranService from './AkunPembayaran.service';
import AkunPembayaranDialog from './AkunPembayaran.dialog';

@connect((state)=> ({
  ...AkunPembayaranService.stateConnectSetting()(state),
}), AkunPembayaranService.actionConnectSetting())
export default class AkunPembayaranView extends ListView {
  service=AkunPembayaranService
  // ebillingAccountService=EbillingAccountService
  FormDialog=AkunPembayaranDialog

  constructor(props){
    super(props);
    this.state = {
      ...this.state
    }

    this.state.ebillingUser = {}
  }

  beforeFetch(params) {
    if(params["sortBy"] === "createdAt") {
      params["sortBy"] = "createdDate"
      params["sort"] = "DESC"
    }
  }

  /*_barActions = [
    {
      label:'word.create',
      iconClassName:'mdi mdi-plus',
      onClick:() => this.addItem()
    },
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => this.fetchData()
    },
  ]*/

  columns=[
    {isSortable: true, label: "word.bank", value: "orderType.name", show: true, isSearchable: true},
    {isSortable: true, label: "word.idPengguna", value: "name", show: true, isSearchable: true},
    {isSortable: true, label: "word.idPerusahaan", value: "companyId", show: true, isSearchable: true},
    {isSortable: true, label: "word.nomorRekening", value: "accountNumber", show: true, isSearchable: true},
  ]

  async checkConnection() {
    /*let res = await this.ebillingAccountService.api.me()

    this.setState({ebillingUser: res.data});*/
  }

  async componentDidMount() {
    this.rootPath = this.props.match.url;

    try {
      this.checkConnection()
    } catch (e){
      this.toast.warning({
        title: e.name,
        message: e.message
      })
    }
  }

  // right bar
  tabWidth = 280
  /*tabs= ["status", "search", "column"]

  tabComponents() {
    var obj = this;
    return {
      status: this.status.bind(obj)
    }
  }*/

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Name'
          name='name__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Company ID'
          name='companyId__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Account Number'
          name='accountNumber__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
      </this.SearchContainer>
    )
  }

  /*status() {
    const { handleSubmit, submitting, valid, pristine } = this.props;
    return (
      <div className='mpk-layout column fill'>
        <div className='flex'>
          <List className="">
            <Subheader primaryText="Status Koneksi" />

            { this.state.ebillingUser.login &&
              <ListItem
                leftAvatar={<Avatar  suffix="green" icon={<FontIcon>done</FontIcon>} />}
                primaryText="Terhubung"
                secondaryText={"Terhubung sebagai " + this.state.ebillingUser.login + "."}
              />
            }

            { !this.state.ebillingUser.login &&
              <ListItem
                leftAvatar={<Avatar suffix="red" icon={<FontIcon>clear</FontIcon>} />}
                primaryText="Tidak Terhubung"
                secondaryText="Status Koneksi"
              />
            }

            {/!*<Subheader primaryText="Status Sistem" />

            <ListItem
              leftAvatar={<Avatar  suffix="green" icon={<FontIcon>done</FontIcon>} />}
              primaryText="Sistem Active"
              secondaryText="Status Sistem"
            />*!/}
          </List>
        </div>
      </div>
    )
  }*/

}
