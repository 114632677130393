import ApiService from '../../../../../../../services/api.service'
import http from '../../../../../../../services/http.service'

class Bupot26InPembetulanService extends ApiService {
  name= 'Bupot26InPembetulan';
  path= 'ebupot/bp26';
  rootPath=true
  advancedSearch=true

  api= {
    revision: async (data)=> {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/revision`, data)
      
      return res;
    }
    // summary: async (esptId) => {
    //   var res = await this.http.get(`${this.apiLocation}/${this.path}/${esptId}/spt/summary`)

    //   return res.data;
    // },
    // calculate: async (param) => {
    //   var res = await this.http.post(this.apiLocation+'/calculation/pph23/f', param)

    //   return res.data;
    // },
    // total: async (sptId) => {
    //   var res = await this.http.get(`${this.apiLocation}/${this.path}/total/${sptId}`)

    //   return res.data;
    // },
    // cetak: async (id) => {
    //   var res = await this.http.get(`${this.apiLocation}/${this.path}/${id}/report`, {
    //     responseType: 'blob'
    //   })

    //   return res;
    // },
    // cetakBulk: async (sptId, ids) => {
    //   var res = await this.http.post(`${this.apiLocation}/${this.path}/${sptId}/report/bulk`, ids, {
    //     responseType: 'blob'
    //   })

    //   return res;
    // },
    // cetakBulkMerge: async (ids) => {
    //   var res = await this.http.post(`${this.apiLocation}/${this.path}/report/bulk/merge`, ids, {
    //     responseType: 'blob'
    //   })

    //   return res;
    // },
    // cetakDaftar: async (sptId) => {
    //   var res = await this.http.get(`${this.apiLocation}/spt23/bp-23/daftar/report/${sptId}`, {
    //     responseType: 'blob'
    //   })

    //   return res;
    // },
    // downloadCsv: async (sptId)=> {
    //   var res = await this.http.get(`${this.apiLocation}/${this.path}/csv/${sptId}`, {
    //     responseType: 'blob'
    //   })

    //   return res;
    // },
    // email: async (category, ids) => {
    //   return await this.http.post(`${this.apiLocation}/email?category=${category}`, ids)
    // },
    // emailSpt: async (category, sptId, ids) => {
    //   return await this.http.post(`${this.apiLocation}/email?category=${category}&sptId=${sptId}`, ids)
    // },
    // deleteBulk: async (sptId, ids)=> {
    //   let res = await http.post(`${this.apiLocation}/${this.path}/${sptId}/delete`, ids);

    //   return res;
    // },
  }

  constructor() {
    super()
    this.init()
    this.initApi()
  }
}


export default new Bupot26InPembetulanService();
