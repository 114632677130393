import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {Field, getFormValues, reduxForm} from 'redux-form';
import {Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab, Chip} from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Multiselectv2, Switch as Switch2} from '../../../../components/form';

import LogExportEbupotService from './LogExportEbupot.service';
import ListView from '../../../../components/entity/listView';
import ColumnService from '../../../../services/column.service';
import axios from 'axios';
import izitoast from 'izitoast';
import download from "downloadjs";

@reduxForm({form: 'LogExportEbupot', destroyOnUnmount: true})
@connect((state) => ({
  ...LogExportEbupotService.stateConnectSetting()(state),
  formData: getFormValues('LogExportEbupot')(state),
  user: state.auth.user,
  // EPPT CORE
  auth: state.auth.company
  // EPPT CORE
}), LogExportEbupotService.actionConnectSetting())
export default class LogExportEbupot extends ListView {
    service=LogExportEbupotService
    minusPage=true

    static contextTypes = {
        showDialog: PropTypes.func.isRequired,
    };

    apiPath='findByPasal'

    beforeFetch(params) {
        params.pasal = this.props.pasal;
        delete params.column
        delete params.keyword
        delete params.startDate
        delete params.endDate
        params.sort = `${params.sortBy},${params.sort}`
        try {
            if(params['status.in']){
                params['status.in'] == this.props.tableFilter2.status__in
            }
        } catch(e){}
        return params
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.pasal != prevProps.pasal){
            this.fetchData();
        }
    }

    deleteSelected = async (items, callback) => {
        try {
          let ids = []

          items.forEach(item => {
            ids.push(item.id)
          })

          var deleteAll = await this.service.api.deleteAllPerPasal(ids, this.props.pasal)
          await this.removeCheckbox();

          callback()
          await this.fetchData()
        } catch(e) {
          callback(e, e)
        }
    }

    async downloadSelected() {
      let selected = this.props.table.selected

      if (selected.length) {
        let ids = []

        selected.forEach(data => {
          if (data.status === 'FINISH') {
            ids.push(data.id)
          }
        })

        let res = await this.service.api.downloadPerPasal(this.props.pasal, ids)

        let datas = res.data

        datas.forEach(data => {
          let url = data.url
          let win = window.open(url, '_blank');
          win.focus();
        })
      } else {
        izitoast.warning({
          title: 'Download',
          message: 'Hanya yang berstatus FINISH yang dapat didownload.'
        })
      }
    }

    async downloadItem(item){
        let ids = [item.id]
        let res = await this.service.api.downloadPerPasal(this.props.pasal, ids)

        let datas = res.data

        datas.forEach(data => {
          let url = data.url
          let win = window.open(url, '_blank');
          win.focus();
        })
    }

    deleteItem = async (item, callback) => {
        try {
          await this.service.api.deletePerPasal(item.id, this.props.pasal);
          callback()
          await this.fetchData()
        } catch(e) {
          callback(e, e)
        }
    }

    _tableActions = [
        {
          label:"Download",
          iconClassName:"mdi mdi-file",
          onClick: (item) => this.downloadItem(item)
        },
        {label:"divider", iconClassName:"-"},
        {
          label:"Delete",
          iconClassName:"mdi mdi-delete",
          onClick:(item, callback) => this.deleteItem(item, callback),
          confirmation:{
            title:"sentence.custom.deleteItem",
            message:"sentence.custom.deleteItemConfirmation"
          }
        }
    ]

    defaultSearchForm(){
        return (
            <div style={{ width: '100%' }}>
                <Field
                    label     = 'Npwp'
                    name      = 'sptNpwp__contains'
                    className = "md-cell md-cell--12"
                    component = {Textfield}
                />
                <Field
                    label     = 'Tahun'
                    name      = 'sptTahun__equals'
                    className = "md-cell md-cell--12"
                    component = {Textfield}
                />
                <Field
                    label     = 'Masa'
                    name      = 'sptMasa__equals'
                    className = "md-cell md-cell--12"
                    component = {Searchfield}
                    options={[
                        {id: 1, name: "1"},
                        {id: 2, name: "2"},
                        {id: 3, name: "3"},
                        {id: 4, name: "4"},
                        {id: 5, name: "5"},
                        {id: 6, name: "6"},
                        {id: 7, name: "7"},
                        {id: 8, name: "8"},
                        {id: 9, name: "9"},
                        {id: 10, name: "10"},
                        {id: 11, name: "11"},
                        {id: 12, name: "12"}
                    ]}
                />
                <Field
                    label     = 'Rev'
                    name      = 'sptRev__equals'
                    className = "md-cell md-cell--12"
                    component = {Textfield}
                    type      = 'number'
                />
                <this.ig.Field
                    label='WAITING'
                    name='status__in_WAITING'
                    className="md-cell md-cell--6"
                    component={this.ig.Checkbox}
                />
                <this.ig.Field
                    label='PROCESS'
                    name='status__in_PROCESS'
                    className="md-cell md-cell--6"
                    component={this.ig.Checkbox}
                />
                <this.ig.Field
                    label='FINISH'
                    name='status__in_FINISH'
                    className="md-cell md-cell--6"
                    component={this.ig.Checkbox}
                />
                <this.ig.Field
                    label='FAILED'
                    name='status__in_FAILED'
                    className="md-cell md-cell--6"
                    component={this.ig.Checkbox}
                />
            </div>
        )
    }

    _barActions = [
        {
          label:'word.delete',
          iconClassName:'mdi mdi-delete',
          onClick:() => {
            this.setState({showDialogConfirmDeleteSelected:true})
          },
          disabledFunc:() => this.props.table.selected.length === 0
        },
        {
          label:'word.download',
          iconClassName:'mdi mdi-file',
          onClick:() => this.downloadSelected(),
          disabledFunc:() => this.props.table.selected.length === 0
        },
        {
          label:'word.refresh',
          iconClassName:'mdi mdi-refresh',
          onClick:() => {
            window.location.hash = window.location.hash.split('?')[0]
            this.fetchData()
          }
        },
    ]

    columns=[
        {isSortable: true, label: "word.id",  value: "id", isDefaultSort:false, show:false, isSearchable:true},
        {isSortable: true, label: "word.npwp", value: "sptNpwp", show:true, isSearchable:true},
        {isSortable: true, label: "word.name", value: "sptName", show:true, isSearchable:true},
        {isSortable: true, label: "word.month",  value: (d) => d.sptMasa, show:true, isSearchable:true, type: 'func'},
        {isSortable: true, label: "word.year",  value: (d) => d.sptTahun, show:true, isSearchable:true, type: 'func'},
        {isSortable: true, label: "word.pembetulan",  value: (d) => d.sptPembetulan, show:true, isSearchable:true, type: 'func'},
        {
          label: "word.status",
          type: 'func',
          value: (d)=> {
            var style = {
              color: 'white',
              backgroundColor: 'grey'
            }
            switch (d.status) {
              case 'PROCESS':
                style.backgroundColor = '#2196F3'
                break;
              case 'FINISH':
                style.backgroundColor = '#4CAF50'
                break;
              case 'FAILED':
                style.backgroundColor = '#f44336'
                break;
              default:
                break;
            }
            return <Button flat swapTheming label={d.status} style={style}/>
          }, className: "mpk-font-size-S", show:true, isSearchable:true},
        // {isSortable: true, label: "word.status",  value: "status", isDefaultSort:false, show:true, isSearchable:true},
        {isSortable: true, label: "word.message",  value: "message", isDefaultSort:false, show:true, isSearchable:true},
        {isSortable: true, label: "word.filename",  value: "fileName", isDefaultSort:false, show:false, isSearchable:true},
        {isSortable: true, label: "word.fileSize",  value: "size", isDefaultSort:false, show:true, isSearchable:true},
        {isSortable: true, label: "word.username",  value: "username", isDefaultSort:false, show:true, isSearchable:true},
    ]
}
