import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Field, reduxForm, getFormValues } from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../../../../components/form';

import ListView from '../../../../../../components/entity/listView';
import ColumnService from '../../../../../../services/column.service';
// import ImportService from './../Import/Import.service';
import axios from 'axios';
import izitoast from 'izitoast';
import FormDialog from './ImportSSP22.dialog';
import ImportSSP22service from './ImportSSP22.service';
import download from "downloadjs";
import ListViewImport from '../../../../../../components/entity/ListViewImport';

@reduxForm({form: 'ImportSSP22Form', destroyOnUnmount: true})
@connect((state) => ({
  ...ImportSSP22service.stateConnectSetting()(state),
  formData: getFormValues('ImportSSP22Form')(state),
  // EPPT CORE
  auth: state.auth.company
  // EPPT CORE
}), ImportSSP22service.actionConnectSetting())
export default class ImportSSP22View extends ListViewImport {
  service=ImportSSP22service
  FormDialog=FormDialog
  pasal='pasal22'
  category='SSP'
  importPath='/pasal22/upload/csv/etc'
  showAutomatisTab=false
  showPembetulan=true
// viewType=2

  beforeFetch(params) {
    params["category.in"] = "SSP"
  }

  async handleDownloadTemplate() {
    var report = await ImportSSP22service.api.downloadTemplate('SSP')
    window.open(report.data.url)
  }

}
