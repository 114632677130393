import React from 'react'
import SignerService from './Signer.service'
import { connect } from 'react-redux'
import ListView from './../../../components/entity/listView'
import moment from 'moment'
import {validation, FileInputNext, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Radio, convert, FileInput5, Multiselect} from './../../../components/form'
import PropTypes from 'prop-types'
import { Field, reduxForm, initialize } from 'redux-form'
import izitoast from 'izitoast'
import counterpart from 'counterpart'

@connect((state)=> ({
    user: state.auth.user,
    ...SignerService.stateConnectSetting()(state)
  }), SignerService.actionConnectSetting())
export default class SignerView extends ListView {
    service=SignerService
    apiPath="getAll"

    constructor(props){
      super(props);
      this.state = {
        showTableSetting:false,
        showDialogConfirmDeleteSelected:false,
        showDialogConfirmDeleteFinishFailedSelected:false,
        showForm:false,
        formData: {},
        data: []
      }
    }

    static contextTypes = {
        showDialog: PropTypes.func.isRequired,
    };

    async goToOrgs(){
        this.props.history.push('/main-support/orgs')
    }

    beforeFetch(params){
        params.npwpProfile = this.props.match.params.npwp
        if(params.page == 0){

        } else {
          params.page = params.page - 1
        }
    }

    async addItems(){
        this.props.history.push(`/main-support/signer/${this.props.match.params.npwp}/new`)
    }

    _barItem(){
      return <div></div>
    }

    deleteItem = async (item, callback) => {
      try {
        await this.service.api.deleteDelete(item);
        callback()
        await this.fetchData()
      } catch(e) {
        callback(e, e)
      }
    }

    _barActions = [
        {
          label:'word.back',
          iconClassName:'mdi mdi-arrow-left',
          onClick:() => this.goToOrgs()
        },
        {
          label:'word.create',
          iconClassName:'mdi mdi-plus',
          onClick:() => this.addItems()
        },
        // {
        //   label:'word.delete',
        //   iconClassName:'mdi mdi-delete',
        //   onClick:() => {
        //     this.setState({showDialogConfirmDeleteSelected:true})
        //   },
        //   disabledFunc:() => this.props.table.selected.length === 0
        // },
        {
          label:'word.refresh',
          iconClassName:'mdi mdi-refresh',
          onClick:() => {
    
            window.location.hash = window.location.hash.split('?')[0]
            this.fetchData()
          }
        },
    ]

    async editItems(item){
      this.props.history.push(`/main-support/signer/${this.props.match.params.npwp}/${item.id}`)
    }

    _tableActions = [
      {
        label:"More Info",
        iconClassName:"mdi mdi-bell",
        onClick: (item) => this.editItems(item)
      },
      {label:"divider", iconClassName:"-"},
      {
        label:"Delete",
        iconClassName:"mdi mdi-delete",
        onClick:(item, callback) => this.deleteItem(item, callback),
        confirmation:{
          title:"sentence.custom.deleteItem",
          message:"sentence.custom.deleteItemConfirmation"
        }
      }
    ]

    columns = [
      {label: "word.id", searchField: "id", value: "id", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
      {label: "word.npwp", searchField: "npwp", value: "npwp", isDefaultSort:true, isSortable:true, show:true, isSearchable:true},
      {label: "word.name", searchField: "name", value: "name", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
      {label: "word.signAs", searchField: "sign_as", value: "sign_as", type: 'boolean', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
      {label: "word.active", searchField: "active", value: "active", type: 'boolean', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
      {label: "word.createdBy", searchField: "createdBy", value: "createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
      {label: "word.createdDate", searchField: "createdDate", value: (d)=> {
        if(d){
          return moment(d.createdDate).format('DD/MM/YYYY HH:mm:ss')
        } else {
          return "-"
        }
      }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
      {label: "word.lastModifiedBy", searchField: "lastModifiedBy", value: "lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
      {label: "word.lastModifiedDate", searchField: "lastModifiedDate", value: (d)=> {
        if(d){
          if(d.lastModifiedDate){ return moment(d.lastModifiedDate).format('DD/MM/YYYY HH:mm:ss') } else { return "-" }
        } else {
          return "-"
        }
      }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    ]

}