import ApiService from '../../../../../services/api.service';

class FileLapor4a2Service extends ApiService {
  name= 'FileLapor4a2';
  path= 'pasal4/spt';
  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api={
    getSummary: async(sptId)=> {
      // return await this.http.get(`${this.apiLocation}/spt4a2/ssp-4a2/detail/${sptId}`)
      return await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/info`)
    },
    cetakTest: async (sptId) => {
      return await this.http.get(`${this.apiLocation}/${this.path}/lapor/${sptId}`)
    },
    cetak: async (sptId) => {
      return await this.http.get(`${this.apiLocation}/pasal4/download/${sptId}`, {
        responseType: 'blob'
      })
    },
  }
}

export default new FileLapor4a2Service();
