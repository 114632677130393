import React from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import counterpart from 'counterpart';
import {Field, reduxForm, getFormValues, change} from 'redux-form';
import moment from 'moment';
import download from 'downloadjs';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress,
  List,
  ListItem,
  FontIcon,
  DialogContainer
} from 'react-md';
import FormView from '../../../../components/entity/form.view';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox} from '../../../../components/form';
import UtilService from '../../../../services/utils.service'
import DataESSPService from './DataESSP.service';
import OrganizationService from '../../Administrator/Organization/Organization.service';
import { OrganizationView } from '../../Administrator/Organization/Organization.view';
import iziToast from 'izitoast';
import Bluebird from "bluebird";

@connect(OrganizationService.stateConnectSetting(), OrganizationService.actionConnectSetting())
class OrganizationPicker extends OrganizationView {
  viewType=2;

  _barActions = [
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    },
  ]

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    }
  ]

  _viewContainer(props) {
    return <div>
      {props.children}
    </div>
  }
}

@reduxForm({form: 'dataEssp', destroyOnUnmount: true, initialValues: {organizations: []}})
@connect((state) => ({
  ...OrganizationService.stateConnectSetting()(state),
  access      : state.entity.access.api.find,
  organization: state.authEppt.currentOrganization,
  spt         : state.authEppt.currentSPT,
  formData    : getFormValues('dataEssp')(state),
  auth        : state.auth.company,
}))
export default class DataESSPForm extends FormView {
  service  = DataESSPService
  viewType = 2;

  translate = false

  titleHeader() {
    return 'Hitung Data ESSP';
  }

  initialData={
  }

  constructor(props) {
    super(props);

    this.state= {
      ...this.state,
      tahunOptions: [],
      showOrganizationPicker: false,
      organizations: [],
      kodeSetoran23s: [
        '411124 - 100', '411124 - 101', '411124 - 102', '411124 - 103', '411124 - 104',
        '411127 - 100', '411127 - 101', '411127 - 102', '411127 - 103', '411127 - 104', '411127 - 105',
      ],
    }
  }

  async initData() {
    this.props.initialize({organizations:[]});
  }

  masaOptions = [
    {id:1, name: 'Januari'}, {id:2, name: 'Februari'}, {id:3, name: 'Maret'}, {id:4, name: 'April'}, {id:5, name: 'Mei'}, {id:6, name: 'Juni'},
    {id:7, name: 'Juli'}, {id:8, name: 'Agustus'}, {id:9, name: 'September'}, {id:10, name: 'Oktober'}, {id:11, name: 'November'}, {id:12, name: 'Desember'}
  ]

  _barItem() {
    return (
      <div>
        <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>Back</Button>

        <Button secondary raised primary onClick={() => this.handleCalculate()} style={{marginRight: 16}}>Hitung</Button>
      </div>
    )
  }

  async handleMultiSelectOrganization() {
    var formData = this.props.formData;
    var organizations = formData.organizations;
    this.props.table.selected.forEach((d, i)=> {
      var check = _.find(organizations, {id: d.id});
      if(!check) {
        organizations.push(d);
      }
    })

    this.props.change('organizations', organizations);
  }

  async handleDelete(i) {
    var formData = this.props.formData;
    var organizations = formData.organizations;
    organizations.splice(i, 1);
    this.props.change('organizations', organizations);
  }

  formView() {
    let kodeMap = [
      {
        name: 'pasal23',
        label: 'Pasal 23',
        child: this.state.kodeSetoran23s
      },
    ]

    let organizations = []
    if(this.props.auth && this.props.auth.organizations) organizations = this.props.auth.organizations

    return (
      <div>
        <Card>
          <div className="md-grid">
            <Field
              label='Masa'
              name='month'
              className="md-cell md-cell--6"
              component={Searchfield}
              options={[
                {id:1, name: 'Januari'}, {id:2, name: 'Februari'}, {id:3, name: 'Maret'}, {id:4, name: 'April'}, {id:5, name: 'Mei'}, {id:6, name: 'Juni'},
                {id:7, name: 'Juli'}, {id:8, name: 'Agustus'}, {id:9, name: 'September'}, {id:10, name: 'Oktober'}, {id:11, name: 'November'}, {id:12, name: 'Desember'}
              ]}
              validate={validation.required}
            />

            <Field
              label='Tahun'
              name='year'
              className="md-cell md-cell--6"
              component={Textfield}
              validate={validation.required}
            />
          </div>

          <div className='md-grid' style={{padding: 0, margin:0}}>
            <Divider style={{width: '100%'}}/>

            <Field
              label="Semua NPWP"
              name="allNpwp"
              className="md-cell md-cell--12"
              component={Checkbox}
              onChange={async (ev, v) => {
                await Bluebird.map(organizations, async (org)=> {
                  await this.props.dispatch(change('dataEssp', `npwps_${org.npwp}`, v));
                })
              }}
            />

            {organizations.map((org)=> {
              let label = org.npwp + ' - ' + org.name
              let inputName = `npwps_${org.npwp}`

              return <Field
                label={label}
                name={inputName}
                className="md-cell md-cell--4"
                component={Checkbox}
              />
            })}
          </div>

          {kodeMap.map((d)=> {
            return <div className='md-grid' style={{padding: 0, margin:0}}>
              <Divider style={{width: '100%'}}/>
              <Field
                label={d.label}
                name={d.name}
                className="md-cell md-cell--12"
                component={Checkbox}
                onChange={async (ev, v) => {
                  await Bluebird.map(d.child, async (c)=> {
                    let name = c.split(' - ');
                    await this.props.dispatch(change('dataEssp', `${name[0]}_${name[1]}`, v));
                  })
                }}
              />

              {d.child.map((c)=> {
                let name = c.split(' - ');
                return <Field
                  label={c}
                  name={`${name[0]}_${name[1]}`}
                  className="md-cell md-cell--4"
                  component={Checkbox}
                />
              })}
            </div>
          })}
        </Card>
      </div>
    )
  }

  // actions
  async handleCalculate() {
    var formData = this.props.formData

    /*if (!formData.allNpwp) {
      if (formData.organizations.length === 0) {
        iziToast.warning({
          title: 'Validasi',
          message: 'Anda harus memilih minimal 1 organisasi.'
        })

        return
      }
    }*/

    if (!formData.month) {
      iziToast.warning({
        title: 'Validasi',
        message: 'Input masa harus diisi.'
      })

      return
    }

    if (!formData.year) {
      iziToast.warning({
        title: 'Validasi',
        message: 'Input tahun harus diisi.'
      })

      return
    }

    let organizationNpwps = [];
    let kodeSetorans = [];

    for (let key in formData) {
      if(formData[key] === true && key !== 'allNpwp') {
        if (!key.startsWith('pasal')) {
          let npwpKey = 'npwps_'

          if (key.startsWith(npwpKey)) {
            key = key.replace(npwpKey, '')

            organizationNpwps.push(key)
          }
          else {
            kodeSetorans.push(key)
          }
        }
      }
    }

    let data = {
      kodeSetorans: []
    }

    /*data.allNpwp = formData.allNpwp*/
    data.month = formData.month
    data.year = formData.year

    if(organizationNpwps.length) {
      data.organizationNpwps = organizationNpwps
    }

    if(kodeSetorans.length) {
      data.kodeSetorans = kodeSetorans
    }

    try {
      if (data.kodeSetorans.length) {
        let request23 = false

        data.kodeSetorans.forEach(kodeSetoran => {
          let labelKodeSetoran = kodeSetoran.replace('_', ' - ')

          if (this.state.kodeSetoran23s.indexOf(labelKodeSetoran) !== -1) {
            request23 = true
          }
        })

        if (request23)
          this.service.api.calculateEbupot(data)
      }
      else {
        this.service.api.calculateEbupot(data)
      }

      let params = this.props.match.params
      let companyId = params.companyId
      let subscriptionId = params.subscriptionId

      this.props.history.push(`/company/${companyId}/${subscriptionId}/init/EBILLING/DataESSP`)
    } catch (e) {

    }

  }

}
