import ApiService from '../../../../../services/api.service';

class SPT15SignerService extends ApiService {
  name= 'SPT15Signer';
  path= 'spt15/signer';
  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    getSigner: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/spt15/${sptId}`);
      return res.data;
    }
  }
}

export default new SPT15SignerService();
