import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {Field, getFormValues, reduxForm} from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../components/form';

import ImportEbillingService from './ImportEbilling.service';
import ListView from '../../../components/entity/listView';
import ColumnService from '../../../services/column.service';
import axios from 'axios';
import izitoast from 'izitoast';
import download from "downloadjs";
import ListViewImport from '../../../components/entity/ListViewImport';
import formDialog from './ImportEbilling.dialog.view'

@reduxForm({form: 'IMPORT_EBILLING_Form', destroyOnUnmount: true})
@connect((state) => ({
  ...ImportEbillingService.stateConnectSetting()(state),
  formData: getFormValues('IMPORT_EBILLING_Form')(state),
  user: state.auth.user,
  // EPPT CORE
  auth: state.auth.company
  // EPPT CORE
}), ImportEbillingService.actionConnectSetting())
export default class ImportEbillingView extends ListViewImport {
    service=ImportEbillingService
    FormDialog=formDialog
    importPath='/ebilling/import'
    pasal='ebilling'

    static contextTypes = {
        showDialog: PropTypes.func.isRequired,
    };

    componentDidMount(){
      this.props.change('no', false)
    }

    async handleDeleteImport(d) {
        try {
          var dialog = await this.context.showDialog((props, res, rej) =>({
            title: 'Delete',
            initialValue: {},
            width: 400,
            text: 'Apakah anda yakin akan menghapus data ini ?',
          }));
          if(dialog) {
            await axios.delete(`${this.pasal}/import-logs/${d.id}`);
            izitoast.success({
              message: 'Data berhasil dihapus'
            })
            this.fetchData()
          }
        } catch(e){
          console.log(e)
        }
    }

    async handleDeleteImportSelected() {
        var dialog = await this.context.showDialog((props, res, rej) =>({
          title: 'Delete',
          initialValue: {},
          height: 'auto',
          width: 400,
          okText: 'Ya',
          text: 'Apakah anda yakin akan menghapus data yang terpilih?'
        }));
        if(dialog) {
            var ids = this.props.table.selected.reduce((p, c)=> {
              if(c.status == 'FINISH' || c.status == 'FAILED') {
                p.push(c.id)
              }
              return p;
            }, []);

            await axios.post(`${this.pasal}/import-logs/delete`, ids);

            izitoast.success({
              title: "",
              message: "Data berhasil dihapus"
            })
            this.fetchData()
        }
    }

    _barItem() {
        return (
          <div>
            <Button primary raised style={{margin: 5}} onClick={this.handleDownloadTemplate.bind(this)}>Template</Button>
            <Switch
              style={{float: "right"}}
              id="switch-table-setting"
              name="Switch Table Setting"
              label=""
              checked={this.state.showTableSetting}
              onChange={() => {
                this.setState({
                  showTableSetting:!this.state.showTableSetting
                })
              }}
            />
          </div>
        )
      }

    async handleDownloadTemplate() {
        var showDialog = await this.context.showDialog(()=> ({
          title: 'Download Template',
          initialValue: {
              category: null
          },
          width: 800,
          height: 600,
          contentStyle: {
            height: 600
          },
          okText: 'Download',
          text: (
            <div>
                <this.ig.Field
                    label='Kategory'
                    name='category'
                    className="md-cell md-cell--12"
                    component={this.ig.Searchfield}
                    options={[
                      { id: 'DATA_ESSP', name: 'DATA ESSP' },
                      { id: 'DATA_ESSP_WP_PEREDARAN_TERTENTU', name: 'DATA ESSP WP PEREDARAN TERTENTU' }
                    ]}
                    validate={validation.required}
                />
            </div>
          )
        }))
        if(showDialog){
            var report = await this.service.api.downloadTemplate(showDialog.category)
            window.open(report.data.url, '_blank');
        }
    }

    searchForm() {
        return (
          <this.SearchContainer>
            <this.ig.Field
              label='Kategori'
              name='category__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Filename'
              name='filename__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Pesan'
              name='message__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Status'
              name='status__contains'
              className="md-cell md-cell--12"
              component={this.ig.Searchfield}
              options={[
                  { id: 'COMPLETED', name: 'COMPLETED' },
                  { id: 'FAILED', name: 'FAILED' }
              ]}
            />
          </this.SearchContainer>
        )
    }

    import(){
        const { handleSubmit, submitting, valid, pristine } = this.props;
        return (
            <div className='mpk-layout column fill'>
              <this.ImportDialogResult dataResults={this.state.importResults} visible={this.state.showImportResult} onHide={()=> {this.setState({showImportResult: false})}} />
              <div className='flex'>
                <div className='md-grid'>
                  <div className="md-cell md-cell--12">
                    <Field
                      label='Select a File'
                      name='file'
                      multiple
                      twoLines
                      component={Fileinput}
                      validate={validation.required}
                    />
                  </div>
                  <this.ig.Field
                    label='Kategory'
                    name='category'
                    className="md-cell md-cell--12"
                    component={this.ig.Searchfield}
                    options={[
                        { id: 'DATA_ESSP', name: 'DATA ESSP' },
                        { id: 'DATA_ESSP_WP_PEREDARAN_TERTENTU', name: 'DATA ESSP WP PEREDARAN TERTENTU' }
                    ]}
                    validate={validation.required}
                    onChange={(e, val)=> {
                      this.props.change('type', val)
                    }}
                  />
                  <hr />
                  <div className="md-cell md-cell--12">
                      <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Import</Button>
                  </div>
                </div>
              </div>
              {this.state.onProgress ? (
                  <this.LinearProgress style={{margin: 0}} query={true} />
                ) : (null)}
              {this.showAutomatisTab &&
                <Tabs className='mpk-tab-white' onTabChange={this.handleTabChange.bind(this)} tabId="ImportTab">
                    <Tab label="Manual">

                    </Tab>
                    <Tab label="Otomatis">

                    </Tab>
                </Tabs>
              }
            </div>
        )
      }

}
