import ApiService from '../../../../../services/api.service'
import http from '../../../../../services/http.service'
import moment from 'moment'

class LogFileCsvService extends ApiService {
  name= 'LogFileCsv'
  path= 'ebupot/log/file'
  repoType="QueryDsl"
  advancedSearch=true

  constructor(){
    super()
    this.init()
    this.initApi()
  }

  columns = [
    {label: "word.id", searchField: "id", value: "id", isDefaultSort:false, isSortable:true, show:false, isSearchable:true},
    {label: "word.originalFilename", searchField: "originalFilename", value: "originalFilename", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.contentType", searchField: "contentType", value: "contentType", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.md5", searchField: "md5", value: "md5", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.fileSize", searchField: "fileSize", value: "fileSize", type: 'number', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.success", searchField: "success", value: "success", type: 'boolean', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.status", searchField: "status", value: "status", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.message", searchField: "message", value: "message", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.createdBy", searchField: "createdBy", value: "createdBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.createdDate", searchField: "createdDate", value: (d)=> {
      if(d){
        return moment(d.createdDate).format('DD/MM/YYYY HH:MM')
      } else {
        return "-"
      }
    }, type: 'func', isDefaultSort:true, isSortable:true, show:true, isSearchable:true},
    {label: "word.lastModifiedBy", searchField: "lastModifiedBy", value: "lastModifiedBy", isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
    {label: "word.lastModifiedDate", searchField: "lastModifiedDate", value: (d)=> {
      if(d.lastModifiedDate){
        return moment(d.lastModifiedDate).format('DD/MM/YYYY HH:MM')
      } else {
        return "-"
      }
    }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true},
  ]

  api = {
    logValidasi: async(fileId)=> {
      let res = await http.get(`/api/ebupot/log-validasi/${fileId}/log-file`)
      return res.data
    }
  }

}

export default new LogFileCsvService();
