import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues} from 'redux-form';
import moment from 'moment';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress
} from 'react-md';

import FormView from '../../../../../components/entity/form.view';
// import NomorBPService from '../nomorBP.service';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox} from '../../../../../components/form';
import UtilService from '../../../../../services/utils.service'
// import SPT21SignerService from '../SPT21Signer/SPT21Signer.service';
import NpwpService from './../../NpwpService';
import FormViewSpt from '../../../../../components/entity/form.view.spt';
import terbilangService from '../../../../../services/terbilang.service';
import BP4a2DSPengalihanTanahService from './BP4a2DSPengalihanTanah.service';

@reduxForm({form: 'BP4a2BSSewaTanahForm', destroyOnUnmount: true})
@connect((state) => ({
  access: state.entity.access.api.find,
  organization: state.authEppt.currentOrganization,
  spt: state.authEppt.currentSPT,
  formData:getFormValues('BP4a2BSSewaTanahForm')(state)
}))
export default class BP4a2BSSewaTanahForm extends FormViewSpt {
  service=BP4a2DSPengalihanTanahService
  viewType =2;

  initialData={
    bagA: {},
  }

  constructor(props) {
    super(props);

    this.state= {
      ...this.state,
      showCalculateProgress: false,
      npwpNotValid: true
    }
  }

  async npwpValidation(evt, value){
    if(value){
      var res = await NpwpService.api.validate4(value)
      if(res.valid){
        this.setState({npwpNotValid: false})
      } else {
        this.setState({npwpNotValid: true})
        this.toast.warning({title: "Validasi Npwp", message: "npwp tidak valid"})
      }

      var nonNpwp = 0;
      if(value === '000000000000000' || value == '' || !value) {
        nonNpwp = 1;
      }

      var form = this.props.formData;
      var d = form.bagB;

      Object.keys(d).forEach((k, i) => {
        if(!(k == 'jmlBruto' || k == 'jmlPph' || k == 'terbilang')) {
          var x = d[k];

          x.nonNpwp = nonNpwp
        }
      })

      await this.props.change('bagB', d)

      console.log(nonNpwp)
      console.log(this.props.formData)


    }
  }

  async beforeSave(value) {
    if(value.totalPPh == 0 || value.totalPPh == '') {
      this.toast.warning({title:'Warning', message:'Isi bruto harus lebih besar dari 0'})
      return false;
    } else {
      // Remapping Objek
      var mapping = {
        "address"     : value.bagA ? value.bagA.alamat : null,
        "b1Gross"     : value.bruto ? parseFloat(value.bruto) : 0,
        "b1Pph"       : value.pph ? parseFloat(value.pph) : 0,
        "cuttingDate" : value.tanggal ? value.tanggal : new Date(),
        "id"          : value.id ? value.id : null,
        "lokasi"      : value.lokasi ? value.lokasi : null,
        "name"        : value.bagA ? value.bagA.nama : null,
        "nop"         : value.terbilang ? value.terbilang : null,
        "npwp"        : value.bagA ? value.bagA.npwp : null,
        "ntpp"        : value.ntpp ? value.ntpp : null,
        "spt"         : this.props.spt.data
      }
      if(typeof mapping.cuttingDate == "object") mapping.cuttingDate = moment(mapping.cuttingDate).format("YYYY-MM-DD")
      value = mapping
      // Remapping Objek
      return value;
    }
  }

  async initData() {
    if(this.props.match.params.id == 'new') {
      let nomorBPReq = 0; //await nomorBP23Service.api.get(this.props.spt.data.organization.id)
      var nomorBP = "";

      // if(nomorBPReq.data.mode === 'PREF_SUF') {
      //   nomorBP = nomorBPReq.data.prefix+nomorBPReq.data.suffix;
      // } else {
      //   nomorBP = nomorBPReq.data.suffix+nomorBPReq.data.prefix;
      // }
      let signer = {}; //await SPT21SignerService.api.getSigner(this.props.spt.data.id);

      this.initialData = {
        ...this.initialData,
        bagA:{
          nama: this.props.organization.name,
          npwp: this.props.organization.npwp,
          alamat: this.props.organization.address
        },
        tanggal: new Date(),
        spt: this.props.spt.data,
      }
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);
      // Remapping Objek
      var data = res.data
      var remapping = {
        id: data.id,
        spt: data.spt,
        ntpp: data.ntpp,
        bagA: {
          npwp: data.npwp,
          nama: data.name,
          alamat: data.address
        },
        nop: data.terbilang,
        lokasi: data.lokasi,
        bruto: data.b1Gross + "",
        pph: data.b1Pph + "",
        tanggal: data.cuttingDate
      }
      res.data = remapping
      // Remapping Objek
      this.props.initialize(res.data);
      this.npwpValidation(null, res.data.bagA.npwp)
    }
  }

  formView() {
    var formData = {bagB: {}};
    if(this.props.formData && this.props.formData.values) formData = this.props.formData.values
    return (
      <div>
        <Card>
          <div className="md-grid">
            <Field
              label='NTPP'
              name='ntpp'
              className="md-cell md-cell--6"
              component={Textfield}
              mask="_"
              length={16}
              maxLength={16}
              maskFormat="################"
              validate={validation.required}
            />
            <Field
              label='Tanggal Pemotongan'
              name='tanggal'
              className="md-cell md-cell--6"
              component={Datepicker}
              validate={validation.required}
            />
            {/* <Field
              label='Referensi'
              name='referensi'
              id="BP21FForm-referensi"
              className="md-cell md-cell--12"
              component={Textfield}
            /> */}
          </div>
        </Card>

        <br/>

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='A. WAJIB PAJAK' />
          <Divider/>
          <div className="md-grid">
            <div className='md-cell md-cell--6'>
              <Field
                label='NPWP'
                name='bagA.npwp'
                id="BP21FForm-npwp"
                disabled
                component={TextfieldMask}
                maskFormat="##.###.###.#-###-###"
                mask="_"
                length={15}
                // onChange={this.npwpValidation.bind(this)}
                validate={validation.required}
              />
              <Field
                label='Nama'
                name='bagA.nama'

                disabled={this.state.npwpNotValid}
                component={Textfield}
                validate={validation.required}
              />
              {/* <Field
                label='NIK'
                name='bagA.nik'

                disabled={this.state.npwpNotValid}
                component={Textfield}
              /> */}
              {/* <Field
                label='Telepon'
                name='name'
                id="BP21FForm-name"
                component={Textfield} */}

            </div>
            <div className='md-cell md-cell--6'>
              {/* <Field
                label='Kode Poss'
                name='npwp'
                id="BP21FForm-codePoss"
                component={Textfield}
              /> */}
              <Field
                label='Alamat'
                name='bagA.alamat'

                disabled={this.state.npwpNotValid}
                component={Textfield}
                validate={validation.required}
              />
              {/* <Field
                label='Email'
                name='bagA.email'

                disabled={this.state.npwpNotValid}
                component={Textfield}
              /> */}
            </div>
          </div>
        </Card>

        <br/>

        {this.state.showCalculateProgress && <LinearProgress id='lin_pro' style={{margin: 0}} />}
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='B. DETAIL' />
          <Divider/>
          <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
            <TableHeader>
              <TableRow>
                <TableColumn>No.</TableColumn>
                <TableColumn className='md-text-right'>Jumlah Bruto(Rp)</TableColumn>
                <TableColumn className='md-text-right'>PPH(Rp)</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableColumn>1.</TableColumn>
                <TableColumn>
                  <Field
                    name='bruto'
                    component={TextfieldMask}
                    className='md-text-right'
                    money={','}
                    onChange={async (e, v)=> {
                      await this.props.change('totalBruto', v);

                    }}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    name='pph'
                    component={TextfieldMask}
                    className='md-text-right'
                    money={','}
                    onChange={async (e, v)=> {
                      await this.props.change('totalPPh', v);
                      this.props.change('terbilang', terbilangService(parseInt(v)));
                    }}
                  />
                </TableColumn>
              </TableRow>

               <TableRow>
                <TableColumn>Terbilang</TableColumn>
                <TableColumn colSpan={2}>
                  <Field
                    name='terbilang'
                    component={Textfield}
                    disabled
                  />
                </TableColumn>
              </TableRow>
            </TableBody>
          </DataTable>
        </Card>

        <br />
      </div>
    )
  }

}
