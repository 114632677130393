import ApiService from '../../../services/api.service';
import http from '../../../services/http.service';

class OrganizationSupportService extends ApiService {
  name= 'OrganizationSupport';
  path= '';

  api= {
      getAll: async (params)=> {
          var user = params.user
          if(params.name){
            params.field = 'name'
            params.query = params.name
            delete params.name
          }
          if(params.npwp){
            params.field = 'npwp'
            params.query = params.npwp
            delete params.npwp
          }
          if(params.email){
            params.field = 'email'
            params.query = params.email
            delete params.email
          }
          delete params.user
          let res = await http.get('/api/iams/organization/all', {
              headers: {
                'x-user-profile': JSON.stringify({
                    username: user.username,
                    id: user.id,
                    email: user.email,
                    role: user.role.name,
                    isAdmin: user.isAdmin + ""
                }),
                params: JSON.stringify(params)
              }
          });
          return res
      },
      getOrganizationHost: async (params)=> {
        let res = await this.http.get('/organizations', {
          params: {
            page: 1,
            size: 10000,
            field: params.field,
            query: params.query,
            order: "DESC",
            column: "npwp"
          }
        })
        return res
      },
      certUpload: async (formData)=> {
        let res = await http.post('/api/iams/cert/upload', formData)
        return res
      }
  }

  constructor() {
    super()
    this.init()
    this.initApi()
  }
}


export default new OrganizationSupportService();
