import React, { Component } from 'react';
import { Grid, Cell, List,
  ListItem,
  ListItemControl,
  FontIcon,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn
} from 'react-md';
import { connect } from 'react-redux';
import { Field, reduxForm , propTypes, formValueSelector , getFormValues} from 'redux-form';
import { validation, Textfield, TextfieldMask, convert, Searchfield } from '../../../../components/form';
import DialogView from '../../../../components/entity/dialog.view';
import MainFlowService from './MainFlow.service';
import UserService from '../../Administrator/User/User.service';
import _ from 'lodash';


@reduxForm({form: 'mainFlow_dialog', destroyOnUnmount: true})
@connect((state) => ({
  formState: getFormValues('mainFlow_dialog')(state)
}))
export default class MainFlowDialog extends DialogView {
  service=MainFlowService
  size='small'
  translate=false
  titleHeader() {
    if(this.props.isEditDialog) {
      return 'Edit '+this.props.dialogType
    } else {
      return 'Tambah '+this.props.dialogType
    }
  }

  initialData = {

  }

  initData(props) {
    var value = {};
    if(props.formData.extras) value = _.cloneDeep(props.formData.extras);
    if(value.id) {
      this.props.initialize(value);
    } else {
      this.props.initialize(this.initialData);
    }
  }

  componentWillReceiveProps(nextProps) {
    var value = {};
    var nextValue = {}
    if(this.props.formData.extras) value = this.props.formData.extras;
    if(nextProps.formData.extras) nextValue = nextProps.formData.extras;
    if(value.id != nextValue.id) {
      this.initData(nextProps)
    }
  }

  async handleSave(callback, value) {
    this.props.onSubmit(value, callback);
  }

  deleteUser(i) {
    var flowStateUsers = [];
    if(this.props.formState && this.props.formState.flowStateUsers) flowStateUsers = this.props.formState.flowStateUsers
    var newUsers = _.cloneDeep(flowStateUsers);
    console.log(flowStateUsers, i)
    newUsers.splice(i, 1);

    this.props.change('flowStateUsers', newUsers)
  }

  formView() {
    if(this.props.dialogType === 'State') {
      return this.stateForm()
    } else {
      return this.actionForm()
    }
  }

  actionForm() {
    return (
      <Grid>
        <Field
          label='Name'
          name='name'
          className="md-cell md-cell--12"
          component={Textfield}
          validate={validation.required}
        />
      </Grid>
    )
  }

  stateForm() {
    var statusOptions = [{
      id: 'WAITING',
      name: 'WAITING'
    },{
      id: 'PROCESS',
      name: 'PROCESS'
    }, {
      id: 'FINISH',
      name: 'FINISH'
    }]

    var flowStateUsers = [];
    if(this.props.formState && this.props.formState.flowStateUsers) flowStateUsers = this.props.formState.flowStateUsers

    return (
      <div>
        <Grid>
          <Field
            label='Name'
            name='name'
            className="md-cell md-cell--6"
            component={Textfield}
            validate={validation.required}
          />

          <Field
            label='Tipe State'
            name='stateType'
            className="md-cell md-cell--6"
            component={Searchfield}
            options={[{
              id: 'Start',
              name: 'Start'
            }, {
              id: 'Default',
              name: 'Default'
            }, {
              id: 'End',
              name: 'End'
            }]}
            validate={validation.required}
          />
          <Field
            label='Status'
            name='status'
            className="md-cell md-cell--12"
            component={Searchfield}
            options={statusOptions}
            validate={validation.required}
          />
          <Field
            label='Description'
            name='description'
            className="md-cell md-cell--12"
            rows={4}
            component={Textfield}
          />


            <Field
            label='Asign to User'
            name='User'
            className="md-cell md-cell--6"
            service={UserService}
            component={Searchfield}
            viewField='login'
            valueField='parent'
            onChange={(v)=> {
              var newUsers = _.cloneDeep(flowStateUsers);
              var check = _.find(newUsers, (d)=> {
                return d.user.id === v.id
              });
              delete v.authorities;
              if(!check) newUsers.push({
                user:v
              });

              this.props.change('flowStateUsers', newUsers);
            }}
            itemTemplate={function(d) {
              return {
                primaryText: d[this.props.viewField],
                onClick: () => {
                  if(!this.props.disabled){
                    this.handleItemClick(d); this.hide()
                  }
                }
              }
            }}
            />

            <Field
              label='or/and Asign to user with access'
              name='access'
              className="md-cell md-cell--6"
              component={Searchfield}
              options={[
                {id: 'manage', name: 'Manage'},
                {id: 'approve', name: 'Approve'},
                {id: 'release', name: 'Release'},
                {id: 'submit', name: 'Submit'}
              ]}

            />

        </Grid>

        <List className="md-cell md-cell--12 md-list md-cell md-paper md-paper--1">
              {flowStateUsers.map((d, i) => {
                return (<ListItem
                  key={i}
                  rightIcon={<FontIcon onClick={()=> this.deleteUser(i) }>delete</FontIcon>}
                  primaryText={d.user.login}
                  secondaryText={d.user.email}
                />)
              })}
            </List>

      </div>
    )
  }
}
