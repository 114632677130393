import ApiService from '../../../../../services/api.service'
import http from '../../../../../services/http.service'
import moment from 'moment'
import React from 'react'
import { Chip } from 'react-md'
import Bupot23Service from './../Bupot23/Bupot23.service'

var columns = Object.assign([], Bupot23Service.columns)
columns.map((d)=> {
  if(d.value == "rev") d.isDefaultSort = true
})

class Bupot23HistoryService extends ApiService {
  name= 'Bupot23History';
  path= 'ebupot/bp23';
  advancedSearch=true

  columns=columns

  api= {

  }

  constructor() {
    super()
    this.init()
    this.initApi()
  }
}


export default new Bupot23HistoryService();
