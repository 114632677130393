import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import SelectField, { Paper, Switch } from 'react-md';
import counterpart from 'counterpart';
import { reduxForm, Field, initialize } from 'redux-form';
import { Commandbar, Table, TableSetting, Empty, DialogConfirm } from 'react-mpk';
import { Textfield, Searchfield, Datepicker, Multiselect, Switch as Switch2, Radio, TextfieldMask, Checkbox,Fileinput } from '../form'
import TableSettingCustom from '../TableSettingCustom/TableSettingCustom';
import {deleteSelectedDataItems} from "../../redux/actions/imageActions";
import PropTypes from 'prop-types';
import LogExportPdfService from "modules/Main/Merge/LogExportPdf/LogExportPdf.service";

class SearchContainer extends Component {
  render() {
    return (
      <div className="mpk-fill mpk-content">
        <div className='md-grid'>
        {this.props.children}
        </div>
      </div>
    )
  }
}

export default class ListView extends Component {
  rootPath;
  service=null;
  editDialog = true;
  addDialog = true;
  translate = true;
  minusPage=false

  firstCall = true;

  tr = counterpart.translate;

  apiPath = 'find';
  viewType=1;

  ig = {
    Textfield,
    Datepicker,
    Multiselect,
    Switch: Switch2,
    Radio,
    TextfieldMask,
    Checkbox,
    Field,
    Searchfield,
    Fileinput
  }

  SearchContainer=SearchContainer;

  static contextTypes = {
    showDialog: PropTypes.func,
  };

  buildSearchContainer() {
    var cont = reduxForm({form: this.service.name+'_search', destroyOnUnmount: true})(SearchContainer);
    this.SearchContainer = cont;
  }
  componentWillMount() {
    this.buildSearchContainer();

  }

  componentDidMount() {
    this.firstCall = false;
  }

  titleHeader() {
    return `entities.${this.service.name}.title`;
  }

  header() {
    return <div/>
  }

  FormDialog() {
    return <div/>
  }

  constructor(props){
    super(props);
    this.state = {
      showTableSetting:true,
      showDialogConfirmDeleteSelected:false,
      showDialogConfirmDeleteFinishFailedSelected:false,
      showForm:false,
      formData: {},
      data: []
    }
  }

  // view -------------------------------------------------
  render() {
    if(this.viewType === 1) {
      return this.view1();
    } else {
      return this.view2()
    }
  }

  bottomBar() {
    return null;
  }

  belowTopBar() {
    return null;
  }

  view1() {
    return (
      <this.viewContainer>
        {this.belowTopBar()}
        {this.commandBar()}
        {this.additionalTopContent()}
        <div className="flex mpk-layout mpk-padding-N all">
          {this.tableView()}
          {this.tableSetting()}

          <this.FormDialog
            height={400}
            visible={this.state.showForm}
            add={this.props.tableActions.addDataItem}
            formData={this.state.formData}
            onSuccess={()=> this.fetchData()}
            onClose={() => this.setState({showForm:false})}
            match={this.props.match}
          />

          {this.dialogConfirm()}
          {this.dialogConfirmDeleteFinishFailedSelected()}
        </div>
        {this.bottomBar()}
      </this.viewContainer>
    )
  }

  additionalContent() {
    return null;
  }

  additionalTopContent(){
    return null;
  }

  view2() {
    return (
      <this.viewContainer>
        {this.belowTopBar()}
        {this.commandBar()}
        <div className="flex mpk-padding-N all mpk-content">
          {this.additionalTopContent()}
          <div style={{minHeight: 450}} className="mpk-layout">
            {this.tableView()}
            {this.tableSetting()}
          </div>

          {this.additionalContent()}

          <this.FormDialog
            height={400}
            visible={this.state.showForm}
            add={this.props.tableActions.addDataItem}
            formData={this.state.formData}
            onSuccess={()=> this.fetchData()}
            onClose={() => this.setState({showForm:false})}
            match={this.props.match}
          />

          {this.dialogConfirm()}
          {this.dialogConfirmDeleteFinishFailedSelected()}
        </div>
        {this.bottomBar()}
      </this.viewContainer>
    )
  }

  _viewContainer(props) {
    return <div className="mpk-layout column fill">
      {props.children}
    </div>
  }

  viewContainer = (props) => {
    return this._viewContainer(props);
  }

  _barActions = [
    {
      label:'word.create',
      iconClassName:'mdi mdi-plus',
      onClick:() => this.addItem()
    },
    {
      label:'word.delete',
      iconClassName:'mdi mdi-delete',
      onClick:() => {
        this.setState({showDialogConfirmDeleteSelected:true})
      },
      disabledFunc:() => this.props.table.selected.length === 0
    },
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    },
  ]

  removeCheckbox(){
    var serviceName = ""
    if(this.service && this.service.name) serviceName = this.service.name
    var dispatchName = serviceName.toUpperCase() + "/TABLE_DELETE_SELECTED_DATA_ITEMS"
    this.props.dispatch(
      {
        type: dispatchName,
      }
    )
    setTimeout(()=> {
      this.fetchData();
    }, 500)
    try {
      var mpkTable = document.getElementsByClassName('mpk-full height width')[1]
      var mpkRow = mpkTable.getElementsByClassName('md-table-row--active')
      var mpkCounter = 0
      while(mpkCounter < mpkRow.length){
          try {
            var checkbox = mpkRow[mpkCounter]
            checkbox.classList.remove("md-table-row--active")
            var checkboxIcon = checkbox.getElementsByClassName('md-icon')[0]
            checkboxIcon.classList.remove("md-text--inherit")
            checkbox.classList.remove("md-text--theme-secondary")
            checkboxIcon.innerText = 'check_box_outline_blank'
            mpkCounter++;
          } catch(e){
            mpkCounter++;
          }
      }
    } catch(e){}
  }

  _barItem() {
    return (
      <Switch
        id="switch-table-setting"
        name="Switch Table Setting"
        label=""
        checked={this.state.showTableSetting}
        onChange={() => {
          this.setState({
            showTableSetting:!this.state.showTableSetting
          })
        }}
      />
    )
  }

  barItem() {return this._barItem()}

  _commandBar(props) {
    var barActions = this._barActions;
    if(this.barActions) barActions = this.barActions;
    if(typeof this.barActions == 'function'){
      barActions = this.barActions()
    }

    return <Commandbar
    title={this.titleHeader()}
    translate={this.translate}
    actions={barActions}
    rightCorner={this._barItem()}/>
  }

  commandBar(props) {
    return this._commandBar(props);
  }

  _columns = [
    {label: "word.name",  value: "name", isDefaultSort:true, show:true, isSearchable:true}
  ]

  defaultColumns = [
    {isSortable:true, label: "word.id",  value: "id", show:false, isSearchable:true, isDefaultSort: false},
    {isSortable:true, label: "word.createdBy",  value: "createdBy", show:true, isSearchable:true},
    {isSortable:true, label: "word.createdDate", value: "createdDate", show:true, isSearchable:true, type:"date", isDefaultSort: true},
    {isSortable:true, label: "word.lastModifiedBy",  value: "lastModifiedBy", show:true, isSearchable:true},
    {isSortable:true, label: "word.lastModifiedDate", value: (d)=> {
      if(d.lastModifiedDate) {
        return <div className='mpk-font-size-S'>{moment(d.lastModifiedDate).format('lll')}</div>
      } else {
        return null;
      }
    }, show:true, isSearchable:true, type:"func", searchField: 'lastModifiedDate', isDefaultSort: true}
  ]

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    },
    {label:"divider", iconClassName:"-"},
    {
      label:"Delete",
      iconClassName:"mdi mdi-delete",
      onClick:(item, callback) => this.deleteItem(item, callback),
      confirmation:{
        title:"sentence.custom.deleteItem",
        message:"sentence.custom.deleteItemConfirmation"
      }
    }
  ]

  footer() {
    return null;
  }

  _tableView(props) {
    var columns = this._columns;
    var tableActions = this._tableActions;

    if(this.columns) {
      columns = this.columns;
      if(typeof this.columns === 'function') columns = this.columns();
    }

    if(this.tableActions) tableActions= this.tableActions

    var _this = this;
    if(columns[0].addon){
    } else {
      // columns.unshift(
      //   {
      //     addon: true,
      //     label: "word.number",
      //     value: function(d, index){
      //       var current = 0;
      //       if(_this.props){
      //         if(_this.props.table){
      //           if(_this.props.table.params){
      //             current = _this.props.table.params.size * (_this.props.table.params.page-1)
      //           }
      //         }
      //       }
      //       if(index || index == 0){
      //         return (index+1) + current;
      //       } else {
      //         return "-"
      //       }
      //     },
      //     type: "func",
      //     show:true,
      //     isSearchable:true}
      //   )
    }

    columns = [...columns, ...this.defaultColumns]

    return (
    <Paper className="flex mpk-layout column">
      {this.header()}

      <Table
        connect={{
          properties: this.props.table,
          actions: this.props.tableActions
        }}
        isPaging={true}
        translate={true}
        columns={columns}
        leftAction={true}
        itemActions={tableActions}
        fetchData={this.fetchData}
        footer={this.footer()}
      />
    </Paper>
    )
  }

  tableView(props) {return this._tableView(props)}

  // table setting and search
  defaultSearchForm() {

    var columns = [];
    if(this.columns) {
      columns = this.columns;
      if(typeof this.columns === 'function') columns = this.columns();
    }

    var options = columns.map((d, i) => {
      var id = d.value;
      // if(typeof id === 'function') id = id();
      var action = 'equals';
      if(d.searchField) id = d.searchField
      if(d.searchAction) action = d.searchAction;

      return {
        id: id+'.'+action,
        name: counterpart.translate(d.label)
      }
    })

    return (
      <div className='md-grid' style={{margin: 0, padding:0}}>
      <Field
        label='Kolom'
        name='column'
        className="md-cell md-cell--12"
        component={Searchfield}
        options={options}
      />

      <Field
        label='Kata Kunci'
        name='searchKey'
        className="md-cell md-cell--12"
        component={Textfield}
      />
      </div>
    )
  }
  searchForm() {
    return <this.SearchContainer>
      {this.defaultSearchForm()}
    </this.SearchContainer>
  }

  tabWidth= 240
  tabs = ["search", "column"];

  tabComponents() {
    return {}
  }


  _tableSetting() {
    if(this.state.showTableSetting) {
      return <div className="flex-none mpk-layout">
        <Empty width={16} className="flex-none"/>
        <Paper className="flex-none">
          <TableSettingCustom
            dispatch={this.props.dispatch}
            service={this.service}
            tableFilter={this.props.tableFilter}
            tableFilter2={this.props.tableFilter2}
            tableActions={this.props.tableActions}
            table={this.props.table}

            width={this.tabWidth}
            tabs={this.tabs}
            tabComponents={this.tabComponents()}

            searchForm={this.searchForm()}

            onSearch={this.fetchData}
            translate={true}
          />
        </Paper>
      </div>
    }
  }

  tableSetting() {return this._tableSetting()}

  _dialogConfirm() {

    return <DialogConfirm
      title={'word.deletes'}
      message={'sentence.custom.deleteSelectedItems'}
      visible={this.state.showDialogConfirmDeleteSelected}
      onSubmit={async (callback) => {
        let items = this.props.table.selected

        await this.deleteSelected(items, callback)
      }}
      onCancel={() => this.setState({showDialogConfirmDeleteSelected:false})}
      translate={true}
    />
  }

  dialogConfirm() {return this._dialogConfirm()}

  _dialogConfirmDeleteFinishFailedSelected() {
    return <DialogConfirm
      title={'word.deletes'}
      message={'sentence.custom.deleteSelectedItems'}
      visible={this.state.showDialogConfirmDeleteFinishFailedSelected}
      onSubmit={async (callback) => {
        let items = this.props.table.selected
        let deleteable = []

        items.forEach(data => {
          if(data.status == 'FINISH' || data.status == 'FAILED') {
            deleteable.push(data)
          }
        })

        await this.deleteSelected(deleteable, callback)
      }}
      onCancel={() => this.setState({showDialogConfirmDeleteFinishFailedSelected:false})}
      translate={true}
    />
  }

  dialogConfirmDeleteFinishFailedSelected() {return this._dialogConfirmDeleteFinishFailedSelected()}


  // method ----------------------------------------------
  beforeFetch(params) {

  }

  fetchOption() {
    return {}
  }

  getJsonFromUrl() {
    var result = {};

    if(this.props.location && this.props.location.search) {
      var query = this.props.location.search.substr(1);
      query.split("&").forEach(function(part) {
        var item = part.split("=");
        result[item[0]] = decodeURIComponent(item[1]);
      });
    }

    return result;
  }

  async afterFetch(data){
    return data
  }

  fetchData = async (params=this.props.table.params, onSuccess, onError) => {
    params = _.cloneDeep(params);

    var search = this.getJsonFromUrl();
    var filterObj = this.props.filterObj;
    // if(this.firstCall) filterObj = {}
    delete filterObj.size;
    delete filterObj.page;
    delete filterObj.sort;
    delete filterObj.sortBy;

    params ={
      ...params,
      ...search,
      ...filterObj
    }

    if(!onSuccess) {
      this.props.tableActions.setProperties({
        isLoading: true
      })
    }

    try {
      await this.beforeFetch(params)

      if (this.minusPage) {
        params.page = params.page - 1
      }

      var searchName = `search_${this.service.name}`
      if(params.onSearch){
        try {
          params.servicename = this.service.name
          localStorage.setItem(searchName, JSON.stringify(params))
        } catch(e){}
      } else {
        try {
          var paramsSearch = JSON.parse(localStorage.getItem(searchName))
          var paramsSearchFormData = JSON.parse(localStorage.getItem('formData_search'))
          if(paramsSearch.servicename == this.service.name){
            paramsSearch.page = params.page
            paramsSearch.size = params.size
            paramsSearch.sort = params.sort
            paramsSearch.sortBy = params.sortBy
            params = paramsSearch
            await this.props.dispatch(initialize(this.service.name+'_search', paramsSearchFormData));
          }
        } catch(e){
          console.log(e)
        }
      }

      try {
        delete params.onSearch
        delete params.servicename
      } catch(e){}

      var res = await this.service.api[this.apiPath](params, this.props.dispatch, this.fetchOption());
      window.hahahaha = res
      var data = res.data;
      data = await this.afterFetch(data)
      var total = parseInt(res.headers['x-total-count']);
      if(onSuccess) onSuccess(data, total)

      if(!onSuccess) {
        this.props.tableActions.setProperties({
          isLoading: false,
          data,
          params:{
            total
          }
        })
      }

      if(this.service && this.service.tableService) {
        this.props.dispatch({type: this.service.tableService.tableType.TABLE_SELECT_DATA_ITEM, index: 0, isSelect: false})
      }

      this.setState({data})

      return {
        data,
        total
      }
    } catch(e) {
      if(onError) onError(e);
      if(!onError) {
        this.props.tableActions.setProperties({
          isLoading: false,
          error:{
            isError:true,
            title:e.statusText,
            message:e.message
          }
        });
      }
      throw e;
    }
  };

  addItem = () => {
    if(this.addDialog) {
      this.setState({showForm:true, formData: {}})
    } else {
      if(this.service.cloud){
        this.props.history.push(this.props.location.pathname+"/new");
      } else if(this.rootPath) {
        this.props.history.push(this.rootPath+"/new");
      } else {
        this.props.history.push('/'+this.service.name+"/new");
      }
    }
  }

  editItem = (item) => {
    if(this.editDialog) {
      this.showFormDialog(item);
    } else {
      if(this.service.cloud){
        this.props.history.push(this.props.location.pathname+"/"+item.id);
      } else if(this.rootPath) {
        this.props.history.push(this.rootPath+"/"+item.id)
      } else {
        this.props.history.push('/'+this.service.name+"/"+item.id);
      }
    }
  }

  deleteItem = async (item, callback) => {
    try {
      await this.service.api.delete(item.id);
      callback()
      await this.fetchData()
    } catch(e) {
      callback(e, e)
    }
  }

  deleteSelected = async (items, callback) => {
    try {
      let ids = []

      items.forEach(item => {
        ids.push(item.id)
      })

      // EPPT CORE
      var deleteAll = await Promise.all(items.map( async (item)=> {
          return new Promise( async (resolve, reject)=> {
              await this.service.api.delete(item.id, item.consumeId);
              resolve("OK")
          })
      }))
      // EPPT CORE

      // if(this.service.api.deleteSelected) {
      //   await this.service.api.deleteSelected(ids)
      // } else if(this.service.api.deleteAll) {
      //   await this.service.api.deleteAll(ids)
      // }

      callback()
      await this.fetchData()
    } catch(e) {
      callback(e, e)
    }
  }

  showFormDialog = (data) => {
    this.setState({
      showForm: true,
      formData: data
    })
  }
}
