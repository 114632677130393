import React, { Component } from 'react'; import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {Button, Chip, Switch, DialogContainer, List, ListItem, Subheader} from 'react-md';
import moment from 'moment'

//import FormDialog from './Organization.dialog.view';
import Bupot26InService from './Bupot26In.service'
import ListView from '../../../../../../../components/entity/ListViewEbupot'
import UtilService from '../../../../../../../services/utils.service'
import download from 'downloadjs';
import uuid from 'uuid';
import iziToast from 'izitoast';

import { Field, reduxForm, initialize } from 'redux-form';
import {validation, FileInputNext, Searchfieldv2, Textfield, Searchfield, TextfieldMask, Datepicker, Radio, convert, CheckboxSelection, Multiselect} from '../../../../../../../components/form';
import OrganizationService from '../../../Organization/Organization.service';
// import LoadingOverlay from 'react-loading-overlay';
// import Loader from 'react-spinners/ScaleLoader';
import MDSpinner from "react-md-spinner";
import Espt23Service from './../../SPT23/SPT23.service'

@reduxForm({form: 'Bp23ExportForm', destroyOnUnmount: true})
@connect((state)=> ({
  ...Bupot26InService.stateConnectSetting()(state),
  spt: state.auth.currentSPT,
  user: state.auth.user
}), Bupot26InService.actionConnectSetting())
export default class Bupot26InFView extends ListView {
  service=Bupot26InService
  FormDialog=()=> <div/>

  constructor(props){
    super(props);
    this.state = {
      ...this.state,
      total: {},
      dtp: {},
      cetak: false,
      cetakDaftar: false,
      showLoading: false,
      loadingPage: false,
    }
  }

  addDialog=false
  editDialog=false

  addItem = () => {
    this.props.history.push(this.props.match.url + "/new");
  }

  editItem = (item) => {
    this.props.history.push(this.props.match.url + "/" + item.id);
  }

  fetchOption() {
    const sptId = this.props.match.params.sptId
    return {
      path: Bupot26InService.path+'/'+sptId+'/spt'
    }
  }

  columns=Bupot26InService.columns

  async componentDidMount() {
    this.rootPath = this.props.match.url;

    var sptId = this.props.match.params.sptId
    var total = await Bupot26InService.api.summary(sptId)
    var dtp = await Bupot26InService.api.dtp(sptId)

    this.setState({
      total: total,
      dtp: dtp
    })
  }

  totalBruto() {
    var total = 0;
    if(this.state.total.bruto){
      total = this.state.total.bruto;
    }
    return total;
  }

  totalPph() {
    var total = 0;
    if(this.state.total.pph){
      total = this.state.total.pph;
    }
    return total;
  }

  totalBrutoDtp() {
    var total = 0;
    if(this.state.dtp.bruto){
      total = this.state.dtp.bruto;
    }
    return total;
  }

  totalPphDtp() {
    var total = 0;
    if(this.state.dtp.pph){
      total = this.state.dtp.pph;
    }
    return total;
  }

  total() {
    var total = 0;
    try {
      total = this.state.total.pph - this.state.dtp.pph;
    } catch(e){};
    return total
  }

  async handleCetakSingle(){
    this.setState({ cetak: true })
    // if(this.props.table.selected.length === 0){
    //   iziToast.warning({
    //     title: 'Pemberitahuan !',
    //     message: 'Pilih salah satu data yang akan dicetak',
    //   });
    //   this.setState({ cetak: false })
    // } else if(this.props.table.selected.length > 1) {
    //   iziToast.warning({
    //     title: 'Pemberitahuan !',
    //     message: 'Hanya satu data yang dapat dicetak',
    //   });
    //   this.setState({ cetak: false })
    // } else {

    // }
    try {
      var selected = this.props.table.selected
      // alert(JSON.stringify(selected))

      // var ids = [];
      // selected.map(function (i) {
      //   ids.push(i.id)
      // })

      // var sptId = this.props.spt.data.id
      if(selected.length > 1){
        var ids = [];
        selected.map(function (i) {
          ids.push(i.id)
        })
        var report = await this.service.api.cetakReportBulk(ids)
        window.open(report.data.url);
      } else {
        var selectedById = selected[0]
        var report = await this.service.api.cetak(selectedById.id)
        window.open(report.data.url);
      }
      // var filename = report.headers.filename
      // download(report.data, filename);
      this.setState({cetak: false})
    } catch (e) {
      console.log(e, "REPORT GAGAL")
      this.setState({cetak: false})
    }
  }

  async handleCetakPisah(){
    this.setState({ cetak: true })

    try {
      var selected = this.props.table.selected

      if(selected.length > 1){
        /*var data = {
          ids: [],
          npwps: [],
        };
        selected.map(function (i) {
          data.ids.push(i.id)
          data.npwps.push(i.spt.npwp)
        })*/

        let data = []

        selected.map(function (i) {
          console.log({i})
          data.push(i.id)
        })

        var report = await this.service.api.cetakReportBulkPisah(data)
        window.open(report.data.url);
      } else {
        var selectedById = selected[0]
        var report = await this.service.api.cetak(selectedById.id)
        window.open(report.data.url);
      }
      // var filename = report.headers.filename
      // download(report.data, filename);
      this.setState({cetak: false})
    } catch (e) {
      // console.log(e, "REPORT GAGAL")
      this.setState({cetak: false})
    }
  }

  async handleCetak(){
    this.setState({ cetak: true })
    if(this.props.table.selected.length === 0){
      iziToast.warning({
        title: 'Pemberitahuan !',
        message: 'Pilih salah satu data yang akan dicetak',
      });
      this.setState({ cetak: false })
    } else {
      try {
        var selected = this.props.table.selected
        var ids = [];
        selected.map(function (i) {
          ids.push(i.id)
        })

        var sptId = this.props.spt.data.id
        var report = await this.service.api.cetakBulk(sptId, ids)
        var filename = report.headers.filename
        download(report.data, filename);
        this.setState({cetak: false})
      } catch (e) {
        this.setState({cetak: false})
      }
    }
  }

  async handleCetakDaftar(){
    this.setState({ cetakDaftar: true })
    try {
      var sptId = this.props.spt.data.id
      var report = await Bupot26InService.api.cetakDaftar(sptId)
      var filename = report.headers.filename
      download(report.data, filename);
      this.setState({ cetakDaftar: false })
    } catch (e){
      this.setState({ cetakDaftar: false })
    }
  }

  async handleSendEmail(){
    try {
      let category = "BP_26"
      var selected = this.props.table.selected
      var ids = [];
      selected.map(function (i){
          ids.push(i.id)
      })
      var sptId = this.props.spt.data.id
      await this.service.api.emailSpt(category, sptId, ids)
      iziToast.info({
        title: "Kirim Email",
        message: "email sedang diproses silahkan cek log untuk lebih detil"
      })
    } catch (e){
      iziToast.warning({
        title: e.name,
        message: e.message
      })
    }
  }

  async deleteSelectedDataItems(){
    var selected = this.props.table.selected

    var ids = [];

    selected.map(function (i){
      ids.push(i.id)
    })

    const sptId = this.props.match.params.sptId

    var res = await this.service.api.deleteBulk(sptId, ids)

    this.fetchData()
  }

  handlePembetulan(){
    var selected = this.props.table.selected
    var filterFinish = selected.filter((d)=> {
      return d.status != "FINISH"
    })
    if(filterFinish.length > 0){
      iziToast.info({
        title: "Validasi Pembetulan",
        message: "Selain BP dengan status Finish tidak dapat dilakukan pembetulan, aksi ini hanya bisa dilakukan pada bupot dengan status FINISH karena bupot telah dilaporkan SPT nya"
      })
    } else {
      localStorage.setItem("pembetulanBp26", JSON.stringify(selected))
      var dispatchName = this.service.name.toUpperCase() + "/TABLE_SET_PROPERTIES"
      this.props.dispatch(
        {
          type: dispatchName,
          properties: {
            index: 0,
            isSelect: false,
            selected: []
          }
        }
      )
      this.props.history.push('bp-26-pembetulan')
    }
  }

  handleEdit(){
    var selected = this.props.table.selected
    var filterFinish = selected.filter((d)=> {
      return d.status != 'COMPLETED'
    })
    if(filterFinish.length > 0){
      iziToast.info({
        title: "Validasi Edit",
        message: "Selain BP dengan status Complete tidak dapat dilakukan Edit, aksi ini hanya bisa dilakukan pada bupot dengan status COMPLETED karena bupot belum  pernah di laporkan SPT nya"
      })
    } else {
      localStorage.setItem("editBp26", JSON.stringify(selected))
      var dispatchName = this.service.name.toUpperCase() + "/TABLE_SET_PROPERTIES"
      this.props.dispatch(
        {
          type: dispatchName,
          properties: {
            index: 0,
            isSelect: false,
            selected: []
          }
        }
      )
      this.props.history.push('bp-26-edit')
    }
  }

  static contextTypes = {
    showDialog: PropTypes.func.isRequired,
  };

  async handleDelete(){
    var selected = this.props.table.selected
    var filterFinish = selected.filter((d)=> {
      return d.status != "FINISH"
    })
    var filterCompleted = selected.filter((d)=> {
      return d.status != "COMPLETED"
    })
    if(filterFinish.length > 0 && filterCompleted.length > 0){
      iziToast.info({
        title: "Validasi Delete",
        message: "Hanya status BP dengan status Complete dan Finish bisa dilakukan Delete"
      })
    } else {
      // handle delete
      var dispatchName = this.service.name.toUpperCase() + "/TABLE_SET_PROPERTIES"
      this.props.dispatch(
        {
          type: dispatchName,
          properties: {
            index: 0,
            isSelect: false,
            selected: []
          }
        }
      )
      var askSubmit = await this.context.showDialog(()=> ({
        title: null,
        width: 500,
        height: 132,
        text: (
          <div>
            <p>Apa anda yakin akan menghapus BP terpilih ?</p>
          </div>
        )
      }))
      if(askSubmit){
        this.setState({ showLoading: true })
        try {
          var data = selected
          var res = await this.service.api.submitDelete(data)
          await this.removeCheckbox();
          iziToast.success({
            title: "Success",
            message: "proses menghapus success"
          })
          this.setState({ showLoading: false })
          this.fetchData()
        } catch (e){
          iziToast.error({
            title: "Error",
            message: "proses menghapus gagal, " + e.message
          })
          this.setState({ showLoading: false })
        }
      }
      // handle delete
    }
  }

  belowTopBar(){
    return (
      <div>
        <DialogContainer
          visible={this.state.loadingPage}
          dialogStyle={{ width: '200px', height: '200px', borderRadius: '30px', textAlign: 'center', paddingTop: '50px' }}
        >
          <MDSpinner size={45} />
        </DialogContainer>
      </div>
    )
  }

  historyItem(item){
    if(this.props.user.isSupport || this.props.user.isAdmin){
      this.props.history.push(`/main-support/bupot26/history/${item.id}`)
    } else {
      this.props.history.push(`/company/${this.props.match.params.companyId}/${this.props.match.params.subscriptionId}/init/bupot26/history/${item.id}`)
    }
  }

  logEmailItem(item){
    if(this.props.user.isSupport || this.props.user.isAdmin){
      this.props.history.push(`/main-support/bupot26/email/${item.id}`)
    } else {
      this.props.history.push(`/company/${this.props.match.params.companyId}/${this.props.match.params.subscriptionId}/init/bupot26/email/${item.id}`)
    }
  }

  async sendEmail(item){
    try {
      let res = await this.service.api.sendEmail(item.id)
      iziToast.success({
        title: "Success",
        message: "Email berhasil dikirim"
      })
    } catch(e){
      iziToast.error({
        title: "Failed",
        message: "Email gagal dikirim, " +e.message
      })
    }
  }

  async requestCetak(item){
    try {
      let res = await this.service.api.requestCetak(item.id)
      iziToast.success({
        title: "Success",
        message: "Request proses cetak berhasil"
      })
    } catch(e){
      iziToast.error({
        title: "Failed",
        message: "Request proses cetak gagal, " +e.message
      })
    }
  }

  _tableActionsFunc(){
    var support = false
    if(this.props.user && this.props.user.isSupport) support = this.props.user.isSupport
    if(support){
      return [
        {
          label:"More Info",
          iconClassName:"mdi mdi-bell",
          onClick: (item) => this.editItem(item)
        },
        {
          label:"History",
          iconClassName:"mdi mdi-file",
          onClick: (item) => this.historyItem(item)
        },
        {
          label:"Request Cetak",
          iconClassName:"mdi mdi-file-pdf",
          onClick: (item) => this.requestCetak(item)
        },
        {label:"divider", iconClassName:"-"},
        {
          label:"Delete",
          iconClassName:"mdi mdi-delete",
          onClick:(item, callback) => this.deleteItem(item, callback),
          confirmation:{
            title:"sentence.custom.deleteItem",
            message:"sentence.custom.deleteItemConfirmation"
          }
        }
      ]
    } else {
      return [
        {
          label:"More Info",
          iconClassName:"mdi mdi-bell",
          onClick: (item) => this.editItem(item)
        },
        {
          label:"History",
          iconClassName:"mdi mdi-file",
          onClick: (item) => this.historyItem(item)
        },
        // {
        //   label:"Request Cetak",
        //   iconClassName:"mdi mdi-file-pdf",
        //   onClick: (item) => this.requestCetak(item)
        // },
        // {
        //   label:"Send Email",
        //   iconClassName:"mdi mdi-email",
        //   onClick: (item) => this.sendEmail(item)
        // },
        // {
        //   label:"Log Email",
        //   iconClassName:"mdi mdi-email",
        //   onClick: (item) => this.logEmailItem(item)
        // },
        {label:"divider", iconClassName:"-"},
        {
          label:"Delete",
          iconClassName:"mdi mdi-delete",
          onClick:(item, callback) => this.deleteItem(item, callback),
          confirmation:{
            title:"sentence.custom.deleteItem",
            message:"sentence.custom.deleteItemConfirmation"
          }
        }
      ]
    }
  }

  barActions(){
    var _barActions = [
      {
        label:'word.create',
        iconClassName:'mdi mdi-plus',
        onClick:() => this.addItem(),
        disabledFunc:() => {
          try {
            if(this.props.spt && this.props.spt.data && this.props.spt.data.status == 'FINISH' ){
              return true
            } else {
              return false
            }
          } catch(e){
            return true
          }
        }
      },
      {
        label:'word.delete',
        iconClassName:'mdi mdi-delete',
        onClick:() => this.handleDelete(),
        disabledFunc:() => {
          try {
            if(this.props.spt && this.props.spt.data && this.props.spt.data.status == 'FINISH' ){
              return true
            } else {
              if(this.props.table.selected.length > 0){
                var mapFilterFinish = this.props.table.selected.filter((d)=> {
                  return d.status == 'UPLOADING'
                })
                if(mapFilterFinish.length > 0 || this.props.table.selected.length === 0){
                  return true
                } else {
                  return false
                }
              } else {
                return true
              }
            }
          } catch(e){
            return true
          }
        }
      },
      {
        label:'word.edit',
        iconClassName:'mdi mdi-pencil',
        onClick:() => this.handleEdit(),
        disabledFunc:() => {
          try {
            if(this.props.spt && this.props.spt.data && this.props.spt.data.status == 'FINISH' ){
              return true
            } else {
              if(this.props.table.selected.length > 0){
                var mapFilterFinish = this.props.table.selected.filter((d)=> {
                  return d.status == 'UPLOADING'
                })
                if(mapFilterFinish.length > 0 || this.props.table.selected.length === 0){
                  return true
                } else {
                  return false
                }
              } else {
                return true
              }
            }
          } catch(e){
            return true
          }
        }
      },
      {
        label:'word.pembetulan',
        iconClassName:'mdi mdi-note-plus',
        onClick:() => this.handlePembetulan(),
        disabledFunc:() => {
          try {
            if(this.props.spt && this.props.spt.data && this.props.spt.data.status == 'FINISH' ){
              return true
            } else {
              if(this.props.table.selected.length > 0){
                var mapFilterFinish = this.props.table.selected.filter((d)=> {
                  return d.status == 'UPLOADING'
                })
                if(mapFilterFinish.length > 0 || this.props.table.selected.length === 0){
                  return true
                } else {
                  return false
                }
              } else {
                return true
              }
            }
          } catch(e){
            return true
          }
        }
      },
      {
        label:'word.report',
        iconClassName:'mdi mdi-file-pdf',
        onClick:() => this.handleCetakSingle(),
        disabledFunc:() => {
          if(this.props.table.selected.length === 0){
            return true
          } else if(this.state.cetak){
            return true
          } else if(this.props.table.selected.length > 0){
            try {
              if(this.props.table.selected[0].report == true){
                return false
              } else { return true }
            } catch(e){ return true }
          } else {
            return false
          }
        }
      },
      {
        label:'word.reportPisah',
        iconClassName:'mdi mdi-file-pdf',
        onClick:() => this.handleCetakPisah(),
        disabledFunc:() => {
          if(this.props.table.selected.length === 0){
            return true
          } else if(this.state.cetak){
            return true
          } else if(this.props.table.selected.length > 0){
            try {
              if(this.props.table.selected[0].report == true){
                return false
              } else { return true }
            } catch(e){ return true }
          } else {
            return false
          }
        }
      },
      {
        label:'word.reportBulk',
        iconClassName:'mdi mdi-file-pdf',
        onClick:() => this.handleCetakDaftarBp(),
      },
      {
        label:'word.bulkEmail',
        iconClassName:'mdi mdi-email',
        onClick:() => this.handleBulkEmail(),
        disabledFunc:() => {
          if(this.props.table.selected.length === 0){
            return true
          } else { return false }
        }
      },
    ]
    var isSupport = false
    if(this.props.user && this.props.user.role && this.props.user.role.name == "SUPPORT_AGENT") isSupport = true
    if(isSupport){
      _barActions.push({
        label:'word.requestCetak',
        iconClassName:'mdi mdi-upload',
        onClick:() => this.handleRequestCetakAll(),
        disabledFunc:() => {
          if(this.props.table.selected.length === 0){
            return true
          } else { return false }
        }
      })
      _barActions.push({
        label:'word.refresh',
        iconClassName:'mdi mdi-refresh',
        onClick:() => this.fetchData()
      })
      return _barActions
    } else {
      _barActions.push({
        label:'word.refresh',
        iconClassName:'mdi mdi-refresh',
        onClick:() => this.fetchData()
      })
      return _barActions
    }
  }

  async handleRequestCetakAll(){
    var ids = []
    var selected = this.props.table.selected
    selected.map((d)=> { ids.push(d.id) })
    var desc = "request cetak"
    var confirmation = await this.context.showDialog(()=> ({
      title: null,
      width: 500,
      height: 132,
      text: (
        <div>
          <p>Apa anda yakin akan {desc} bp terpilih ?</p>
        </div>
      )
    }))
    if(confirmation){
      this.setState({ loadingPage: true })
      try {
        var res = await this.service.api.requestCetakAll(ids)
        iziToast.success({
          title: "Success",
          message: "proses "+ desc +" bp berhasil"
        })
        this.removeCheckbox()
        this.fetchData()
        this.setState({ loadingPage: false })
      } catch (e){
        iziToast.error({
          title: "Error",
          message: "proses "+ desc +" bp gagal, " + e.message
        })
        this.removeCheckbox()
        this.setState({ loadingPage: false })
      }
    }
  }

  async handleCetakDaftarBp(){
    this.setState({ cetak: true })
    try {
      var report = await Espt23Service.api.cetakDaftarBp(this.props.match.params.sptId)
      window.open(report.data.url)
      this.setState({cetak: false})
    } catch (e) {
      console.log(e, "error")
      this.setState({cetak: false})
    }
  }

  async handleBulkEmail(){
    var companyId = this.props.match.params.companyId;
    var sptId = this.props.match.params.sptId;
    var ids = []
    var selected = this.props.table.selected
    var desc = "mengirim email"
    var confirmation = await this.context.showDialog(()=> ({
      title: null,
      width: 500,
      height: 132,
      text: (
        <div>
          <p>Apa anda yakin akan {desc} bp terpilih ?</p>
        </div>
      )
    }))
    if(confirmation){
      this.setState({ loadingPage: true })
      try {
        var res = await this.service.api.sendEmailBulk(selected, sptId, companyId)
        iziToast.success({
          title: "Success",
          message: "proses "+ desc +" bp berhasil"
        })
        await this.removeCheckbox()
        this.fetchData()
        this.setState({ loadingPage: false })
      } catch (e){
        iziToast.error({
          title: "Error",
          message: "proses "+ desc +" bp gagal, " + e.message
        })
        await this.removeCheckbox()
        this.setState({ loadingPage: false })
      }
    }
  }

  _barItem() {
    // pembetulan = tampil hanya saat status spt finish dan pembetulan > 0
    var pembetulan = true
    if(this.props.spt && this.props.spt.data && this.props.spt.data.id && this.props.spt.data.pembetulan && this.props.spt.data.pembetulan > 0) pembetulan = true
    return (
      <div>
        {/* <Button secondary raised style={{margin: 5}} onClick={this.handleDelete.bind(this)}>Delete</Button>
        <Button secondary raised style={{margin: 5}} onClick={this.handleEdit.bind(this)}>Edit</Button>
        {pembetulan &&
          <Button secondary raised style={{margin: 5}} onClick={this.handlePembetulan.bind(this)}>Pembetulan</Button>
        } */}
        {/* <Button secondary raised style={{margin: 5}} onClick={this.handleSendEmail.bind(this)}>Kirim</Button>
        <Button disabled={this.state.cetakDaftar} primary raised style={{margin: 5}} onClick={this.handleCetakDaftar.bind(this)}>Cetak Daftar</Button>
        <Button disabled={this.state.cetak} primary raised style={{margin: 5}} onClick={this.handleCetakSingle.bind(this)}>Cetak</Button> */}
        <Switch
          style={{float: "right"}}
          id="switch-table-setting"
          name="Switch Table Setting"
          label=""
          checked={this.state.showTableSetting}
          onChange={() => {
            this.setState({
              showTableSetting:!this.state.showTableSetting
            })
          }}
        />
      </div>
    )
  }

  footer() {
    return <div className="flex-none mpk-border thin solid top dark">
      <div className="mpk-padding-N left right">
        <table style={{width: '100%'}}>
          <tfoot className="md-table-footer">
            <tr className="md-table-column md-text-left md-table-column--data md-table-column--adjusted md-text ">
              <td>
                Jumlah Penghasilan Bruto
              </td>
              <td className=" md-text-right">
                {UtilService.number.format(this.totalBruto(), {precision: 0, thousand: '.', decimal: ','})}
              </td>
              <td>
                Jumlah Penghasilan Bruto DTP
              </td>
              <td className=" md-text-right">
                {UtilService.number.format(this.totalBrutoDtp(), {precision: 0, thousand: '.', decimal: ','})}
              </td>
              <td>
                Jumlah PPh Pasal 26 Yang Kurang Disetor
              </td>
              <td className=" md-text-right">
                {UtilService.number.format(this.total(), {precision: 0, thousand: '.', decimal: ','})}
              </td>
            </tr>
            <tr className="md-table-column md-text-left md-table-column--data md-table-column--adjusted md-text ">
              <td style={{ paddingLeft: 15 }}>
                Jumlah PPh Dipotong
              </td>
              <td className=" md-text-right">
                {UtilService.number.format(this.totalPph(), {precision: 0, thousand: '.', decimal: ','})}
              </td>
              <td style={{ paddingLeft: 15 }}>
                Jumlah PPh Pasal 26 DTP
              </td>
              <td className=" md-text-right">
                {UtilService.number.format(this.totalPphDtp(), {precision: 0, thousand: '.', decimal: ','})}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  }

  // NEW TAB MENU
  async hapusPencarian(){
    try {
      try {
        localStorage.removeItem(`search_${this.service.name}`)
      } catch(e){}
      var params = Object.assign({}, params=this.props.table.params)
      this.fetchData(params)
      await this.props.dispatch(initialize(this.service.name+'_search', {}));
    } catch(e){
      console.log(e, "Search Error ...")
    }
  }

  async handleSearching(values){
    try {
      var formData = this.props.tableFilter2
      if(!formData) formData = {}
      var params = Object.assign({}, params=this.props.table.params)

      if(formData.no){
        params['no.contains'] = formData.no
      } else { delete params['no.contains'] }

      if(formData.report){
        if(formData.report == 'TRUE'){
          params['report.equals'] = true
        } else if(formData.report == 'FALSE'){
          params['report.equals'] = false
        } else {
          delete params['report.equals']
        }
      } else { delete params['report.equals'] }

      if(formData.status && formData.status.length > 0){
        var statuss = ""
        formData.status.map((d, index)=> {
          if(index == (formData.status.length - 1)){
            statuss += d
          } else {
            statuss += d  + ","
          }
        })
        params['status.in'] = statuss
      } else { delete params['status.in'] }

      if(formData.tin){
        params['tin.contains'] = formData.tin
      } else { delete params['tin.contains'] }

      if(formData.email){
        params['email.contains'] = formData.email
      } else { delete params['email.contains'] }

      if(formData.nama){
        params['nama.contains'] = formData.nama
      } else { delete params['nama.contains'] }

      if(formData.kode){
        params['kode.contains'] = formData.kode
      } else { delete params['kode.contains'] }

      if(formData.ntpn){
        params['ntpn.contains'] = formData.ntpn
      } else { delete params['ntpn.contains'] }

      if(formData.noDtp){
        params['noDtp.contains'] = formData.noDtp
      } else { delete params['noDtp.contains'] }

      if(formData.noSkd){
        params['noSkd.contains'] = formData.noSkd
      } else { delete params['noSkd.contains'] }

      if(formData.refXmlId){
        params['refXmlId.contains'] = formData.refXmlId
      } else { delete params['refXmlId.contains'] }

      if(formData.createdBy){
        params['createdBy.contains'] = formData.createdBy
      } else { delete params['createdBy.contains'] }

      if(formData.lastModifiedBy){
        params['lastModifiedBy.contains'] = formData.lastModifiedBy
      } else { delete params['lastModifiedBy.contains'] }

      if(formData.createdDate1){
        params['createdDate.greaterOrEqualThan'] = moment(formData.createdDate1).format('YYYY-MM-DD')
      } else { delete params['createdDate.greaterOrEqualThan'] }

      if(formData.createdDate2){
        params['createdDate.lessOrEqualThan'] = moment(formData.createdDate2).format('YYYY-MM-DD')
      } else { delete params['createdDate.lessOrEqualThan'] }

      if(formData.lastModifiedDate1){
        params['lastModifiedDate.greaterOrEqualThan'] = moment(formData.lastModifiedDate1).format('YYYY-MM-DD')
      } else { delete params['lastModifiedDate.greaterOrEqualThan'] }

      if(formData.lastModifiedDate2){
        params['lastModifiedDate.lessOrEqualThan'] = moment(formData.lastModifiedDate2).format('YYYY-MM-DD')
      } else { delete params['lastModifiedDate.lessOrEqualThan'] }
      params.onSearch = true
      localStorage.setItem("formData_search", JSON.stringify(formData))
      this.fetchData(params)
    } catch(e){
      iziToast.info({
        title: "Pencarian",
        message: "Data tidak ditemukan, " + e.message
      })
    }
  }

  defaultSearchForm(){
    const { handleSubmit, submitting, valid, pristine } = this.props;
    return (
      <div className="md-grid" style={{ overflow: 'auto' }}>
        <Field
          label     = 'No Bupot'
          name      = 'no'
          className = "md-cell md-cell--12"
          component = {Textfield}
        />
        <Field
          label     = 'Cetak'
          name      = 'report'
          className = "md-cell md-cell--12"
          component = {Searchfield}
          options   = {[
            { id: null, name: '-' },
            { id: 'TRUE', name: 'TRUE' },
            { id: 'FALSE', name: 'FALSE' }
          ]}
        />
        <Field
          label     = 'Status'
          name      = 'status'
          className = "md-cell md-cell--12"
          component = {Multiselect}
          noChip    = {true}
          options   = {[
            { id: null, name: ' ' },
            { id: 'UPLOADING', name: 'UPLOADING' },
            { id: 'PROCESS', name: 'PROCESS' },
            { id: 'COMPLETED', name: 'COMPLETED' },
            { id: 'FAILED', name: 'FAILED' },
            { id: 'CANCELED', name: 'CANCELED' },
            { id: 'DELETING', name: 'DELETING' },
            { id: 'DELETED', name: 'DELETED' },
            { id: 'FINISH', name: 'FINISH' },
            { id: 'EDITED', name: 'EDITED' },
            { id: 'REVISED', name: 'REVISED' }
          ]}
        />
        <Field
          label     = 'Tin'
          name      = 'tin'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'Email'
          name      = 'email'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'Nama'
          name      = 'nama'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'Kode'
          name      = 'kode'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'NPTN'
          name      = 'ntpn'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'NO DTP'
          name      = 'noDtp'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'NO SKD'
          name      = 'noSkd'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'XML ID'
          name      = 'refXmlId'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'Dibuat Oleh'
          name      = 'createdBy'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'Diubah Oleh'
          name      = 'lastModifiedBy'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'Dibuat dari'
          name      = 'createdDate1'
          className = "md-cell md-cell--12"
          component = {Datepicker}
        />
        <Field
          label     = 'Dibuat ke'
          name      = 'createdDate2'
          className = "md-cell md-cell--12"
          component = {Datepicker}
        />
        <Field
          label     = 'Diubah dari'
          name      = 'lastModifiedDate1'
          className = "md-cell md-cell--12"
          component = {Datepicker}
        />
        <Field
          label     = 'Diubah ke'
          name      = 'lastModifiedDate2'
          className = "md-cell md-cell--12"
          component = {Datepicker}
        />
        <Button raised primary className="md-cell md-cell--12" onClick={handleSubmit(this.handleSearching.bind(this))}>Cari</Button>
        <hr />
        <Button raised primary className="md-cell md-cell--12" onClick={handleSubmit(this.hapusPencarian.bind(this))}>Hapus</Button>
      </div>
    )
  }
  // yg ditambah
  tabWidth = 280
  tabs= ["search", "summary", "column"]

  tabComponents() {
    var obj = this;
    return {
      summary: this.report.bind(obj)
    }
  }
  // end yg ditambah
  async handleExport(value){
    try {
      var desc = "download summary"
      this.setState({ loadingPage: true })
      var formData = {}
      formData.sptId = this.props.match.params.sptId
      var report = await this.service.api.summarySpt(formData)
      window.open(report.data.url);
      // var filename = report.headers.filename
      // download(report.data, filename);
      iziToast.success({
        title: "Success",
        message: "proses "+ desc +" spt berhasil"
      })
      this.setState({ loadingPage: false })
    } catch (e){
      iziToast.error({
        title: "Error",
        message: "proses "+ desc +" spt gagal, " + e.message
      })
      this.setState({ loadingPage: false })
    }
  }
  report(){
    const { handleSubmit, submitting, valid, pristine } = this.props;
    var orgName = "123123"
    var masa = ""
    var tahun = ""
    var pembetulan = ""
    try {
     orgName = this.props.spt.data.organization.nama
     masa = this.props.spt.data.masa
     tahun = this.props.spt.data.tahun
     pembetulan = this.props.spt.data.pembetulan
    } catch(e){
    }
    return (
        <div className='mpk-layout column fill'>
          <div className='flex'>
            <div className='md-grid'>
              <div className="md-cell md-cell--12" style={{ textAlign: 'center' }}>
                   <List className="md-cell md-paper md-paper--1" style={{ width: '95%', marginBottom: 10, textAlign: 'center' }}>
                       <Subheader primaryText={orgName} />
                       <ListItem
                         primaryText={masa + " - " + tahun}
                         secondaryText={`Pembetulan ${pembetulan}`}
                       />
                   </List>
                  <Button raised primary onClick={handleSubmit(this.handleExport.bind(this))}>Export</Button>
              </div>
            </div>
          </div>
        </div>
     )
   }
  // NEW TAB MENU

}
