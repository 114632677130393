import React, { Component } from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm , propTypes, formnameSelector } from 'redux-form';

import PPh4a2EnableDepositoService from './PPh4a2EnableDeposito.service';
import ListView from '../../../../../components/entity/listView';
import ColumnService from '../../../../../services/column.service';
import { Chip, Card, Button } from 'react-md'
import {validation, Textfield, Searchfield, Checkbox, Searchfieldv2} from '../../../../../components/form';
import iziToast from 'izitoast';
import OrganizationService from '../../../Administrator/Organization/Organization.service'
import companyService from '../../../../../services/companyService'
import Bluebird from 'bluebird';

@reduxForm({form: 'PPh4a2EnableDeposito', destroyOnUnmount: false})
@connect((state)=> ({
  ...PPh4a2EnableDepositoService.stateConnectSetting()(state),
  company: state.auth.company,
  formData:state.form.PPh4a2EnableDeposito,
  // EPPT CORE
  user: state.auth.user,
  auth: state.auth.company
  // EPPT CORE
}), PPh4a2EnableDepositoService.actionConnectSetting())
export default class PPh4a2EnableDepositoView extends ListView {
  service=PPh4a2EnableDepositoService

  titleHeader() {
    return `entities.pph4a2EnableDeposito.title`;
  }

  async saveSetting(values){
    try {
      var newDTO = {
        npwp: values.organization.npwp
      }
      let res = await this.service.api.saveSetting(newDTO) 
      this.fetchData();
      iziToast.success({
        title: "Setting",
        message: "Setting berhasil disimpan"
      })
    } catch(e){
      iziToast.error({
        title: "Setting",
        message: "Setting gagal disimpan " + e.message
      })
    }
  }

  

  additionalTopContent(){
    return (
      <div className="mpk-padding-N all">
        <Card style={{padding: "24px"}}>
          <div class="md-grid">
            <p>
              <b>
                
              Hanya masukkan NPWP yang daftar depositonya ingin diinput manual, efek dari fitur ini adalah daftar bunga deposito tidak akan di-kalkulasi secara otomatis (harus isi/kalkulasi manual di halaman daftar bunga deposito).
              </b>
            </p>
            <Field
                label         = 'Organization'
                name          = 'organization'
                className     = "md-cell md-cell--12"
                component     = {Searchfieldv2}
                valueField    = 'parent'
                remoteSearch  = {true}
                apiPath       = 'getAll'
                params        = {{ user: this.props.user }}
                service       = {OrganizationService}
                validate      = {validation.required}
                renderFirst   = {true}
                validate      = {validation.required}
                coreSearch    = {true}
                npwpSearch    = {true}
                itemTemplate  = {function(d) {
                  let nameAndAlias = ""
                  if (d.name)
                    nameAndAlias = d.name
                  if (d.aliasName)
                    nameAndAlias += " - " + d.aliasName
                  return {
                    primaryText  : nameAndAlias,
                    secondaryText: d.npwp,
                    onClick      : () => {
                      if(!this.props.disabled){
                        this.handleItemClick(d); this.hide()
                      }
                    }
                  }
                }}
            />
            <div className="md-cell md-cell--10">
              <Button raised primary onClick={this.props.handleSubmit(this.saveSetting.bind(this))}>Tambah NPWP</Button>
            </div>
          </div>
        </Card>
      </div>
    )
  }

  _tableActions = []

  barActions(){
    var _barActions = [
      {
        label:'word.delete',
        iconClassName:'mdi mdi-delete',
        onClick:() => {
          this.setState({showDialogConfirmDeleteSelected:true})
        },
        disabledFunc:() => this.props.table.selected.length === 0
      },
      {
        label:'word.refresh',
        iconClassName:'mdi mdi-refresh',
        onClick:() => {
  
          window.location.hash = window.location.hash.split('?')[0]
          this.fetchData()
        }
      },
    ]
    return _barActions
  }

  rootPath = this.props.match.url
  editDialog = false;
  addDialog = false;

  columns=[
    {isSortable: true, label: "word.id", searchField: "id", value: function(d){ return d.id }, type: "func",  show:false, isSearchable:false},
    {isSortable: true, label: "word.npwp", searchField: "npwp", value: "npwp", show:true, isSearchable:true}
  ]   
}
