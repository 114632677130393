import React, { Component } from 'react';
import { connect } from 'react-redux';
import {UserView} from '../../Administrator/User/User.view';
import UserService from '../../Administrator/User/User.service';

@connect(UserService.stateConnectSetting(), UserService.actionConnectSetting())
export default class UserCompanyView extends UserView {
  fetchOption() {
    return {
      path: UserService.path+'/user/'+this.props.match.params.companyId
    }
  }
}
