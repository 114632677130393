import React from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  Tab,
  Tabs,
  SelectionControlGroup,
  Checkbox,
  Button
} from 'react-md';

import FormView from '../../../../../components/entity/form.view';
import BP21A1Service from './BP_21_A1.service';
import NomorBPService from '../nomorBP.service';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Radio} from '../../../../../components/form';
import UtilService from '../../../../../services/utils.service'
import Master21StatusKaryawanA1Service from '../Master21StatusKaryawanA1/Master21StatusKaryawanA1.service';
import SPT21SignerService from '../SPT21Signer/SPT21Signer.service';
import CountryCodeService from '../../../Master/CountryCode/CountryCode.service';
import StatusKaryawanA1Service from '../../../Master/StatusKaryawanA1/StatusKaryawanA1.service';
import NpwpService from './../../NpwpService';
import FormViewSpt from '../../../../../components/entity/form.view.spt';
import LawanTransaksiDialog from '../../../../../components/entity/LawanTransaksi.dialog';
import MasterLawanTransaksiA1Service from '../../../Master/MasterLawanTransaksiA1/MasterLawanTransaksiA1.service';
import SPT_INDUK_21Service from '../SPT_INDUK_21/SPT_INDUK_21.service';

@reduxForm({form: 'BP_21_A1_Form', destroyOnUnmount: true})
@connect((state) => ({
  access: state.entity.access.api.find,
  organization: state.authEppt.currentOrganization,
  spt: state.authEppt.currentSPT,
  formData:state.form.BP_21_A1_Form
}))
export default class BP21A1Form extends FormViewSpt {
  service=BP21A1Service
  viewType =2;

  typingTimeout=1000;
  typingObj=null;

  constructor(props) {
    super(props);

    this.state= {
      ...this.state,
      showCalculateProgress: false,
      npwpNotValid: true,

      lkDialog: false
    }
  }

  _barItem() {
    return (
        <div>
            <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>Back</Button>
        </div>
    )
  }

  async npwpValidation(evt, value){

    if(this.typingObj) clearTimeout(this.typingObj);
    //this.typingObj = setTimeout(async ()=> {
      if(value){
        var res = await NpwpService.api.validate(value)
        if(res.valid){
          this.setState({npwpNotValid: false})

          var formData = {...this.props.formData.values};

          var lk = await MasterLawanTransaksiA1Service.api.find({
            'npwp.equals': value,
            'npwpProfile.equals': this.props.organization.npwp
          })

          if(lk.data) {

            if(lk.data.length > 0) {
              var d = lk.data[0];
              formData.bagA = {
                "npwp": d.npwp,
                "nik": d.nik,
                "nama": d.name,
                "alamat": d.address,
                "jenisKelamin": d.gender,
                "statusPtkp": d.ptkpStatus,
                "jmlPtkp": d.ptkpCount,
                "namaJabatan": d.positionName,
                "karyawanAsing": d.foreignEmployee,

              };

              let countryCode = d.countryCode

              if (countryCode) {
                formData.kodeNegara =  countryCode;
                formData.countryCode =  {id: d.countryCode};
              }
            } else {
              formData.bagA = {
                "npwp": value,
                "nik": "",
                "nama": "",
                "alamat": "",
                "jenisKelamin": "",
                "statusPtkp": "TK",
                "jmlPtkp": 0,
                "namaJabatan": "",
                "karyawanAsing": false
              }
              this.toast.warning({title: "Lawan Transaksi", message: `Lawan Transaksi dengan npwp ${value} tidak ditemukan`})
            }

            this.props.initialize(formData);
          }
        } else {
          this.setState({npwpNotValid: true})
          this.toast.warning({title: "Validasi Npwp", message: "npwp tidak valid"})
        }
      }
    //}, 500);

  }

  initialData={
  "no": "",
  "masaPerolehanAwal": 1,
  "masaPerolehanAkhir": 12,
  "npwp": "000000000000000",
  "nama": "",
  "countryCode": null,
  grossUp: false,
  "bagA": {
    "npwp": null,
    "nik": "",
    "nama": "",
    "alamat": "",
    "jenisKelamin": "",
    "statusPtkp": "TK",
    "jmlPtkp": 0,
    "namaJabatan": "",
    "karyawanAsing": false
  },
  "bagB": {
		"kodeObjekPajak": "21-100-01",
		"gajiPensiun": 0,
		"tunjanganPph": 0,
		"tunjanganLainya": 0,
		"honorarium": 0,
		"premiAsuransi": 0,
		"penerimaanNatura": 0,
		"tantiemBonus": 0,
		"jmlBruto": 0,
		"biayaJabatan": 0,
		"iuranPensiun": 0,
		"jmlPengurangan": 0,
		"jmlPenghasilanNetto": 0,
		"penghasilanNettoSebelumnya": 0,
		"penghasilanSetahunkan": 0,
		"ptkp": 0,
		"penghasilanKenaPajak": 0,
		"pph21AtasPenghasilan": 0,
		"pph21DipotongSebelumnya": 0,
		"pph21Terutang":0,
		"pph21Dilunasi": 0
  },
  "bagC": {
    // "npwp": "000000008000000",
    // "nama": "",
    // "tanggal": "2018-02-15",
    // "ttd": ""
  },
  "spt21": {  },
  // "status": {

  // }
  }

  async initData() {
    if(this.props.match.params.id == 'new') {
      //let nomorBP = await NomorBPService.api.get(this.props.spt.data.organization.id, "BP_21_A1")
      let nomorBP = await NomorBPService.api.get(this.props.spt.data.id)
      let signer = {};

      try {
        signer = await SPT_INDUK_21Service.api.getSigner(this.props.spt.data.id)
      } catch(e) {
        
      }

      this.initialData = {
        ...this.initialData,
        no: '1.1-'+UtilService.date.MM(this.props.spt.data.month)+'.'+UtilService.date.YY(this.props.spt.data.year)+'-'+nomorBP.data.a1,
        bagC:{
          nama: signer.namaPenandaTanganBp,
          npwp: signer.npwpPenandaTanganBp,
          tanggal: new Date()
        },
        namaProfil: this.props.organization.name,
        npwpProfil: this.props.organization.npwp,
        spt21: this.props.spt.data
      }
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);

      // Remapping Objek
      res.data.namaProfil                = this.props.organization.name
      res.data.npwpProfil                = this.props.organization.npwp
      res.data.spt21                       = res.data.spt
      res.data.no                          = res.data.no
      res.data.countryCode                 = res.data.countryCode
      res.data.referensi                   = res.data.ref
      res.data.numbering                   = true
      res.data.grossUp                     = res.data.grossUp
      res.data.masaPerolehanAkhir          = res.data.periodFinal
      res.data.masaPerolehanAwal           = res.data.periodInit
      res.data.status                      = res.data.transferStatus
      res.data.bagA = {
        npwp          : res.data.npwp,
        nama          : res.data.name,
        nik           : res.data.nik,
        jenisKelamin  : res.data.gender,
        alamat        : res.data.address,
        email         : res.data.email,
        karyawanAsing : res.data.foreignEmployee,
        namaJabatan   : res.data.positionName,
        jmlPtkp       : res.data.ptkpCount,
        statusPtkp    : res.data.ptkpStatus,
      }
      res.data.bagB = {
        biayaJabatan                : res.data.biayaJabatan,
        gajiPensiun                 : res.data.gajiPensiun,
        honorarium                  : res.data.honorarium,
        iuranPensiun                : res.data.iuranPensiun,
        jmlBruto                    : res.data.jmlBruto,
        jmlPenghasilanNetto         : res.data.jmlPenghasilanNetto,
        jmlPengurangan              : res.data.jmlPengurangan,
        penerimaanNatura            : res.data.penerimaanNatura,
        penghasilanKenaPajak        : res.data.penghasilanKenaPajak,
        penghasilanNettoSebelumnya  : res.data.penghasilanNettoSebelumnya,
        penghasilanSetahunkan       : res.data.penghasilanSetahunkan,
        pph21AtasPenghasilan        : res.data.pph21AtasPenghasilan,
        pph21Dilunasi               : res.data.pph21Dilunasi,
        pph21DipotongSebelumnya     : res.data.pph21DipotongSebelumnya,
        pph21Terutang               : res.data.pph21Terhutang,
        premiAsuransi               : res.data.premiAsuransi,
        ptkp                        : res.data.ptkp,
        tantiemBonus                : res.data.tantiemBonus,
        kodeObjekPajak              : res.data.taxObjectCode,
        tunjanganLainya             : res.data.tunjanganLainya,
        tunjanganPph                : res.data.tunjanganPph
      }
      res.data.bagC = {
        tanggal : res.data.cuttingDate, 
        nama    : res.data.signName,
        npwp    : res.data.signNpwp
      }
      // Remapping Objek

      this.props.initialize(res.data);
      // this.npwpValidation(null, res.data.bagA.npwp)
      this.setState({npwpNotValid: false})
    }
  }

  async calculate(field, value, e, f, d) {
    console.log(field, value, e, f, d)
    var formData = this.props.formData.values;

    if (field === 'masaPerolehanAkhir') {
      let nomorBuktiPotong = formData.no

      let awal = nomorBuktiPotong.substr(0, 6)

      let bulan = '' + value
      bulan = bulan.padStart(2, '0')

      let replace = '1.1-' + bulan

      nomorBuktiPotong = nomorBuktiPotong.replace(awal, replace)

      formData.no = nomorBuktiPotong;
      this.props.initialize(formData)
    }

    if(this.typingObj) clearTimeout(this.typingObj);

    this.typingObj = setTimeout(async ()=> {
      this.setState({showCalculateProgress: true})

      try {
        var execute = true;

        if(field) {
          if(field.search('bagA.') >= 0) {
            formData.bagA[field.replace('bagA.', '')] = value
          } else if (field.search('bagB.') >= 0) {
            if(formData.bagB[field] || formData.bagB[field] === 0) {
              formData.bagB[field] = value;
            }
          } else {
            formData[field] = value;
          }
        }

        var statusCode = null;
        if(formData.status) {
          statusCode = formData.status.code;
        }

        if(!(formData.masaPerolehanAwal === 1 && formData.masaPerolehanAkhir === 12) && !statusCode){
          execute = false;
        }

        var param = {
          objekHitungA1: {
            masaPerolehanAwal: formData.masaPerolehanAwal,
            masaPerolehanAkhir: formData.masaPerolehanAkhir,
            tanpaNpwp: false,
            statusA1: statusCode,
            grossUp: formData.grossUp,
            partA: {
              statusPtkp: formData.bagA.statusPtkp,
              jmlPtkp: formData.bagA.jmlPtkp,
            },
            partB: formData.bagB
          },
          spt21: this.props.spt.data
        }


        if(formData.bagA.npwp === '000000000000000' || formData.bagA.npwp === "") param.objekHitungA1.tanpaNpwp = true;

        //param.spt21.orgId = param.spt21.organization.id;

        if(execute) {
          // // MAPPING
          var mapping = {
            objek: {
              h02Month1: formData.masaPerolehanAwal,
              h02Month2: formData.masaPerolehanAkhir,
              statusA1:  statusCode,
              tanpaNpwp: param.objekHitungA1.tanpaNpwp,
              partA: {
                a06Code: formData.bagA.statusPtkp,
                a06Count: formData.bagA.jmlPtkp
              },
              partB: {
                b00: formData.bagB.kodeObjekPajak,
                b01: formData.bagB.gajiPensiun,
                b02: formData.bagB.tunjanganPph,
                b03: formData.bagB.tunjanganLainya,
                b04: formData.bagB.honorarium,
                b05: formData.bagB.premiAsuransi,
                b06: formData.bagB.penerimaanNatura,
                b07: formData.bagB.tantiemBonus,
                b08: formData.bagB.jmlBruto,
                b09: formData.bagB.biayaJabatan,
                b10: formData.bagB.iuranPensiun,
                b11: formData.bagB.jmlPengurangan,
                b12: formData.bagB.jmlPenghasilanNetto,
                b13: formData.bagB.penghasilanNettoSebelumnya,
                b14: formData.bagB.penghasilanSetahunkan,
                b15: formData.bagB.ptkp,
                b16: formData.bagB.penghasilanKenaPajak,
                b17: formData.bagB.pph21AtasPenghasilan,
                b18: formData.bagB.pph21DipotongSebelumnya,
                b19: formData.bagB.pph21Terutang,
                b20: formData.bagB.pph21Dilunasi,
              },
              grossUp: formData.grossUp
            },
            sptId: this.props.spt.data.id
          }
          if(!mapping.objek.partB.b01 > 0) mapping.objek.partB.b01 = 0
          if(!mapping.objek.partB.b02 > 0) mapping.objek.partB.b02 = 0
          if(!mapping.objek.partB.b03 > 0) mapping.objek.partB.b03 = 0
          if(!mapping.objek.partB.b04 > 0) mapping.objek.partB.b04 = 0
          if(!mapping.objek.partB.b05 > 0) mapping.objek.partB.b05 = 0
          if(!mapping.objek.partB.b06 > 0) mapping.objek.partB.b06 = 0
          if(!mapping.objek.partB.b07 > 0) mapping.objek.partB.b07 = 0
          if(!mapping.objek.partB.b08 > 0) mapping.objek.partB.b08 = 0
          if(!mapping.objek.partB.b09 > 0) mapping.objek.partB.b09 = 0
          if(!mapping.objek.partB.b10 > 0) mapping.objek.partB.b10 = 0
          if(!mapping.objek.partB.b11 > 0) mapping.objek.partB.b11 = 0
          if(!mapping.objek.partB.b12 > 0) mapping.objek.partB.b12 = 0
          if(!mapping.objek.partB.b13 > 0) mapping.objek.partB.b13 = 0
          if(!mapping.objek.partB.b14 > 0) mapping.objek.partB.b14 = 0
          if(!mapping.objek.partB.b15 > 0) mapping.objek.partB.b15 = 0
          if(!mapping.objek.partB.b16 > 0) mapping.objek.partB.b16 = 0
          if(!mapping.objek.partB.b17 > 0) mapping.objek.partB.b17 = 0
          if(!mapping.objek.partB.b18 > 0) mapping.objek.partB.b18 = 0
          if(!mapping.objek.partB.b19 > 0) mapping.objek.partB.b19 = 0
          if(!mapping.objek.partB.b20 > 0) mapping.objek.partB.b20 = 0
          if(formData.gajiPensiun)                  { mapping.objek.partB.b01 = parseFloat(formData.gajiPensiun) }
          if(formData.tunjanganPph)                 { mapping.objek.partB.b02 = parseFloat(formData.tunjanganPph) }
          if(formData.tunjanganLainya)              { mapping.objek.partB.b03 = parseFloat(formData.tunjanganLainya) }
          if(formData.honorarium)                   { mapping.objek.partB.b04 = parseFloat(formData.honorarium) }
          if(formData.premiAsuransi)                { mapping.objek.partB.b05 = parseFloat(formData.premiAsuransi) }
          if(formData.penerimaanNatura)             { mapping.objek.partB.b06 = parseFloat(formData.penerimaanNatura) }
          if(formData.tantiemBonus)                 { mapping.objek.partB.b07 = parseFloat(formData.tantiemBonus) }
          if(formData.jmlBruto)                     { mapping.objek.partB.b08 = parseFloat(formData.jmlBruto) }
          if(formData.biayaJabatan)                 { mapping.objek.partB.b09 = parseFloat(formData.biayaJabatan) }
          if(formData.iuranPensiun)                 { mapping.objek.partB.b10 = parseFloat(formData.iuranPensiun) }
          if(formData.jmlPengurangan)               { mapping.objek.partB.b11 = parseFloat(formData.jmlPengurangan) }
          if(formData.jmlPenghasilanNetto)          { mapping.objek.partB.b12 = parseFloat(formData.jmlPenghasilanNetto) }
          if(formData.penghasilanNettoSebelumnya)   { mapping.objek.partB.b13 = parseFloat(formData.penghasilanNettoSebelumnya) }
          if(formData.penghasilanSetahunkan)        { mapping.objek.partB.b14 = parseFloat(formData.penghasilanSetahunkan) }
          if(formData.ptkp)                         { mapping.objek.partB.b15 = parseFloat(formData.ptkp) }
          if(formData.penghasilanKenaPajak)         { mapping.objek.partB.b16 = parseFloat(formData.penghasilanKenaPajak) }
          if(formData.pph21AtasPenghasilan)         { mapping.objek.partB.b17 = parseFloat(formData.pph21AtasPenghasilan) }
          if(formData.pph21DipotongSebelumnya)      { mapping.objek.partB.b18 = parseFloat(formData.pph21DipotongSebelumnya) }
          if(formData.pph21Terutang)                { mapping.objek.partB.b19 = parseFloat(formData.pph21Terutang) }
          if(formData.pph21Dilunasi)                { mapping.objek.partB.b20 = parseFloat(formData.pph21Dilunasi) }
          // // MAPPING
          var res = await BP21A1Service.api.calculate(mapping);

          if(res) {
            formData.bagB = {
                biayaJabatan                : res.biayaJabatan,
                gajiPensiun                 : res.gajiPensiun,
                honorarium                  : res.honorarium,
                iuranPensiun                : res.iuranPensiun,
                jmlBruto                    : res.jmlBruto,
                jmlPenghasilanNetto         : res.jmlPenghasilanNetto,
                jmlPengurangan              : res.jmlPengurangan,
                penerimaanNatura            : res.penerimaanNatura,
                penghasilanKenaPajak        : res.penghasilanKenaPajak,
                penghasilanNettoSebelumnya  : res.penghasilanNettoSebelumnya,
                penghasilanSetahunkan       : res.penghasilanSetahunkan,
                pph21AtasPenghasilan        : res.pph21AtasPenghasilan,
                pph21Dilunasi               : res.pph21Dilunasi,
                pph21DipotongSebelumnya     : res.pph21DipotongSebelumnya,
                pph21Terutang               : res.pph21Terhutang,
                premiAsuransi               : res.premiAsuransi,
                ptkp                        : res.ptkp,
                tantiemBonus                : res.tantiemBonus,
                kodeObjekPajak              : res.taxObjectCode,
                tunjanganLainya             : res.tunjanganLainya,
                tunjanganPph                : res.tunjanganPph
            }
            //formData.bagB = res.partB;
            this.props.initialize(formData)
          }
        }

      } catch(e) {console.log(e)}

      this.setState({showCalculateProgress: false})
    }, this.typingTimeout)
  }

  async beforeSave(value) {
    if (value.kodeNegara) {
      if (value.kodeNegara.id) {
        value.countryCode = value.kodeNegara
      }
    }

    // Remapping Objek
    value.spt                         = value.spt21
    value.no                          = value.no
    value.countryCode                 = null // value.countryCode
    value.ref                         = value.referensi
    value.numbering                   = false
    value.grossUp                     = value.grossUp
    value.periodFinal                 = value.masaPerolehanAkhir 
    value.periodInit                  = value.masaPerolehanAwal
    value.transferStatus              = value.status
    value.npwp                        = value.bagA.npwp
    value.name                        = value.bagA.nama
    value.nik                         = value.bagA.nik
    value.gender                      = value.bagA.jenisKelamin
    value.address                     = value.bagA.alamat
    value.email                       = value.bagA.email
    value.foreignEmployee             = value.bagA.karyawanAsing
    value.positionName                = value.bagA.namaJabatan
    value.ptkpCount                   = value.bagA.jmlPtkp
    value.ptkpStatus                  = value.bagA.statusPtkp
    value.biayaJabatan                = value.bagB.biayaJabatan
    value.gajiPensiun                 = value.bagB.gajiPensiun
    value.honorarium                  = value.bagB.honorarium
    value.iuranPensiun                = value.bagB.iuranPensiun
    value.jmlBruto                    = value.bagB.jmlBruto
    value.jmlPenghasilanNetto         = value.bagB.jmlPenghasilanNetto
    value.jmlPengurangan              = value.bagB.jmlPengurangan
    value.penerimaanNatura            = value.bagB.penerimaanNatura
    value.penghasilanKenaPajak        = value.bagB.penghasilanKenaPajak
    value.penghasilanNettoSebelumnya  = value.bagB.penghasilanNettoSebelumnya
    value.penghasilanSetahunkan       = value.bagB.penghasilanSetahunkan
    value.pph21AtasPenghasilan        = value.bagB.pph21AtasPenghasilan
    value.pph21Dilunasi               = value.bagB.pph21Dilunasi
    value.pph21DipotongSebelumnya     = value.bagB.pph21DipotongSebelumnya
    value.pph21Terhutang              = value.bagB.pph21Terutang
    value.premiAsuransi               = value.bagB.premiAsuransi
    value.ptkp                        = value.bagB.ptkp
    value.tantiemBonus                = value.bagB.tantiemBonus
    value.taxObjectCode               = value.bagB.kodeObjekPajak
    value.tunjanganLainya             = value.bagB.tunjanganLainya
    value.tunjanganPph                = value.bagB.tunjanganPph
    value.cuttingDate                 = value.bagC.tanggal
    value.signName                    = value.bagC.nama
    value.signNpwp                    = value.bagC.npwp
    // Remapping Objek

    if(typeof value.cuttingDate == "object") value.cuttingDate = moment(value.cuttingDate).format("YYYY-MM-DD");
    if(typeof value.cuttingDate == "string" && value.cuttingDate.indexOf('Z') != -1) value.cuttingDate = moment(value.cuttingDate, 'YYYY-MM-DD');
    
    return true;
  }

  async afterSave(res, val) {
    let lawanTransaksi = {
      npwp: val.bagA.npwp,
      nik: val.bagA.nik,
      name: val.bagA.nama,
      address: val.bagA.alamat,
      foreignEmployee: val.bagA.karyawanAsing,
      countryCode: val.bagA.countryCode,
      gender: val.bagA.jenisKelamin,
      ptkpStatus: val.bagA.statusPtkp,
      ptkpCount: val.bagA.jmlPtkp,
      positionName: val.bagA.namaJabatan,
      email: val.bagA.email,
      npwpProfile: this.props.organization.npwp,
    }

    await MasterLawanTransaksiA1Service.api.findOrCreate(lawanTransaksi)
    // var formData = this.props.formData.values;

    // var data = {
    //   ...formData.bagA,
    //   organization: this.props.spt.data.organization,
    //   wpLuarNegeri: formData.bagA.karyawanAsing,
    //   jabatan: formData.bagA.namaJabatan,
    //   jmlTanggungan: formData.bagA.jmlPtkp,
    // }
    // if(data.id) delete data.id;
    // await MasterLawanTransaksiA1Service.api.findOrCreate(data)
  }

  formView() {
    var formData = {bagB: {}};
    if(this.props.formData && this.props.formData.values) formData = this.props.formData.values

    var masaOptions = [
      {id:1, name: 1}, {id:2, name: 2}, {id:3, name: 3}, {id:4, name: 4}, {id:5, name: 5}, {id:6, name: 6},
      {id:7, name: 7}, {id:8, name: 8}, {id:9, name: 9}, {id:10, name: 10}, {id:11, name: 11}, {id:12, name: 12}
    ]

    var hideNegara = false;
    var status05 = true 
    if(this.props.formData){
      if(this.props.formData.values){
        if(this.props.formData.values.bagA.karyawanAsing){
          hideNegara = true
        }
        if(this.props.formData.values.status && this.props.formData.values.status.code && this.props.formData.values.status.code == "05"){
          status05 = false
        }
      }
    }
    //total bruto
    return (
      <div>
        <Card>
          <div className="md-grid">
            <Field
              label='No.Bukti Potong'
              name='no'
              className="md-cell md-cell--3"
              component={Textfield}
              validate={validation.required}
            />
            <Field
              label='Tanggal Bukti Potong'
              name='bagC.tanggal'
              className="md-cell md-cell--3"
              component={Datepicker}
              validate={validation.required}

              // onDateChange = { async (d)=> {
              //   let signer = null;

              //   try {
              //     signer = await SPT_INDUK_21Service.api.getSigner({
              //       orgId: this.props.spt.data.organization.id,
              //       tgl  : moment(d).format("YYYY-MM-DD")
              //     })
              //   } catch (error) {
              //   }

              //   this.props.change('bagC.nama', signer.namaPenandaTanganBp)
              //   this.props.change('bagC.npwp', signer.npwpPenandaTanganBp)
              // }}
            />
            <Field
              label='Masa Perolehan Awal'
              name='masaPerolehanAwal'
              id="BP21A1Form-dariMasa"
              className="md-cell md-cell--3"
              component={Searchfield}
              validate={validation.required}
              options={masaOptions}
              onChange={(e, v)=> this.calculate('masaPerolehanAwal', v)}
            />
            <Field
              label='Masa Perolehan Akhir'
              name='masaPerolehanAkhir'
              id="BP21A1Form-sampaiMasa"
              className="md-cell md-cell--3"
              component={Searchfield}
              validate={validation.required}
              options={masaOptions}
              onChange={(e, v)=> this.calculate('masaPerolehanAkhir', v)}
            />

            {!(formData.masaPerolehanAwal === 1 && formData.masaPerolehanAkhir === 12) && 
              // <Field
              //   label='Status'
              //   name='status'
              //   className="md-cell md-cell--12"
              //   component={Searchfield}
              //   validate={validation.required}
              //   service={Master21StatusKaryawanA1Service}
              //   valueField='parent'
              //   onChange={(e, v)=> this.calculate('status', v)}
              // />
              <Field
                label='Status'
                name='status'
                className="md-cell md-cell--12"
                component={Searchfield}
                validate={validation.required}
                service={StatusKaryawanA1Service}
                valueField='parent'
                onChange={(e, v)=> this.calculate('status', v)}
              />
            }
            <Field
              label='Referensi'
              name='referensi'
              id="BP21A1Form-sampaiMasa"
              className="md-cell md-cell--12"
              component={Textfield}
            />
          </div>
        </Card>
        <br/>

        <Card>
          <div className='md-grid'>
            <Field
              label='NPWP Pemotong'
              name='npwpProfil'
              className="md-cell md-cell--12"
              component={TextfieldMask}
              disabled={true}
              maskFormat="##.###.###.#-###-###"
              mask="_"
              length="15"
              validate={validation.required}
            />
            <Field
              label='Nama Pemotong'
              name='namaProfil'
              disabled={true}
              className="md-cell md-cell--12"
              component={Textfield}
              validate={validation.required}
            />
          </div>
        </Card>
        <br/>

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='A. IDENTITAS PENERIMA PENGHASILAN YANG DIPOTONG ' />
          <Divider/>

          {/* <LawanTransaksiDialog
            spt={this.props.spt.data}
            onSelect={(d)=> {
              var formData = {...this.props.formData.values};
              if(!d.foreignEmployee) d.foreignEmployee = false;

              formData.bagA = {
                "npwp": d.npwp,
                "nik": d.nik,
                "nama": d.name,
                "alamat": d.address,
                "email": d.email,
                "jenisKelamin": d.gender,
                "statusPtkp": d.ptkpStatus,
                "jmlPtkp": d.ptkpCount,
                "namaJabatan": d.positionName,
                "karyawanAsing": d.foreignEmployee,
              };

              formData.kodeNegara =  {id: d.kodeNegara};
              formData.countryCode =  {id: d.countryCode};

              this.setState({npwpNotValid: false})

              this.props.initialize(formData);

            }}
            service={MasterLawanTransaksiA1Service}
            visible={this.state.lkDialog}
            onHide={()=> this.setState({lkDialog: false})}
          /> */}

          <div className='md-grid'>
            <div className="md-cell md-cell--6">
              <div className='mpk-layout'>
                <Field
                  className='flex'
                  label='NPWP'
                  name='bagA.npwp'
                  // onChange={(e, v)=> this.calculate('bagA.npwp', v)}
                  onChange={async (e, v) => {
                    await this.npwpValidation(e, v);
                    await this.calculate('bagA.npwp', v);
                  }}
                  component={TextfieldMask}
                  maskFormat="##.###.###.#-###-###"
                  mask="_"
                  length="15"
                  validate={validation.required}
                />
                {/* <Button icon primary swapTheming style={{marginTop: 10}} onClick={()=> this.setState({lkDialog: true})}>search</Button> */}
              </div>

              <Field
                label='Nama'
                name='bagA.nama'
                component={Textfield}
                disabled={this.state.npwpNotValid}
                validate={validation.required}
              />
              <Field
                label='NIK'
                name='bagA.nik'
                id="BP21A1Form-nik"
                disabled={this.state.npwpNotValid}
                component={Textfield}
              />
              <Field
                label='Email'
                name='bagA.email'
                id="BP21A1Form-email"
                disabled={this.state.npwpNotValid}
                component={Textfield}
              />
              <Field
                label='Alamat'
                name='bagA.alamat'
                disabled={this.state.npwpNotValid}
                component={Textfield}
              />
            </div>

            <div className="md-cell md-cell--6">
              <Field
                label='Jenis Kelamin'
                name='bagA.jenisKelamin'
                disabled={this.state.npwpNotValid}
                component={Searchfield}
                options={[{id: 'L', name: 'Laki-laki'}, {id:'P', name: 'Perempuan'}]}
              />
              <Field
                label='Status PTKP'
                onChange={(e, v)=> this.calculate('bagA.statusPtkp', v)}
                name='bagA.statusPtkp'
                disabled={this.state.npwpNotValid}
                component={Searchfield}
                options={[{id: 'TK', name: 'TK'}, {id: 'K', name: 'K'}, {id: 'K/I', name: 'K/I'}, {id: 'HB', name: 'HB'}]}
              />
              <Field
                label='Jumlah Tanggungan'
                name='bagA.jmlPtkp'
                onChange={(e, v)=> this.calculate('bagA.jmlPtkp', v)}
                disabled={this.state.npwpNotValid}
                component={Searchfield}
                options={[{id: 0, name: '0'}, {id: 1, name: '1'}, {id: 2, name: '2'}, {id: 3, name: '3'}]}
              />

              <Field
                label='Nama Jabatan'
                name='bagA.namaJabatan'
                disabled={this.state.npwpNotValid}
                component={Textfield}
              />

              <Field
                label='Warga Negara Asing'
                name='bagA.karyawanAsing'
                disabled={this.state.npwpNotValid}
                component={Switch}
              />

              {hideNegara &&
                <Field
                  label='Kode Negara Domisili'
                  name='countryCode'
                  disabled={this.state.npwpNotValid}
                  component={Searchfield}
                  service={CountryCodeService}
                  valueField="parent"
                />
              }

              {/* <Field
                label='Penandatangan dan Kuasa'
                name='penandaTangan'
                id="BP21A1Form-penandaTangan"
                component={Switch}
              /> */}
            </div>
          </div>
        </Card>
        <br/>

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='B. Rincian Penghasilan Dan Perhitungan PPh Pasal 21' />
          <Divider/>
          <DataTable plain>
            <TableHeader>
              <TableRow>
                <TableColumn style={{width: 5}}></TableColumn>
                <TableColumn>URAIAN</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn className='md-text-right'>JUMLAH (Rp)</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableColumn colSpan="2">
                  KODE OBJEK PAJAK:
                </TableColumn>

                <TableColumn>
                  <Field
                    component={Radio}
                    controlStyle={{display: 'inline-block'}}
                    name="bagB.kodeObjekPajak"
                    type="radio"
                    key={formData.bagB.kodeObjekPajak}
                    value={formData.bagB.kodeObjekPajak}
                    controls={[{
                      label: '21-100-01',
                      value: '21-100-01',
                    }, {
                      label: '21-100-02',
                      value: '21-100-02',
                    }]}
                  />
                </TableColumn>
                <TableColumn></TableColumn>
              </TableRow>

              <TableRow><TableColumn colSpan='4'><b>PENGHASILAN BRUTO</b></TableColumn></TableRow>

              <TableRow>
                <TableColumn>1.</TableColumn>
                <TableColumn>Gaji/Pensiun atau THT/JHT</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.gajiPensiun'
                    onChange={(e, v)=> this.calculate('gajiPensiun', v, this)}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>2.</TableColumn>
                <TableColumn>Tunjangan PPH</TableColumn>
                <TableColumn>
                  <Field
                    label='Gross Up'
                    onChange={(e, v)=> this.calculate('grossUp', v)}
                    name='grossUp'
                    component={Switch}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.tunjanganPph'
                    onChange={(e, v)=> this.calculate('tunjanganPph', v)}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>3.</TableColumn>
                <TableColumn>Tunjangan Lainya, Uang Lembur dan sebagainya</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.tunjanganLainya'
                    onChange={(e, v)=> this.calculate('tunjanganLainya', v)}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>4.</TableColumn>
                <TableColumn>Honorarium dan imbalan lain sejenisnya</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.honorarium'
                    onChange={(e, v)=> this.calculate('honorarium', v)}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>5.</TableColumn>
                <TableColumn>Premi asuransi yang dibayar pemberi kerja</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.premiAsuransi'
                    onChange={(e, v)=> this.calculate('premiAsuransi', v)}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>6.</TableColumn>
                <TableColumn>Penerimaan Dalam Bentuk Natura atau Kenikmatan Lainya yang dikenakan pemotongan PPH Pasal 21</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.penerimaanNatura'
                    onChange={(e, v)=> this.calculate('penerimaanNatura', v)}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>7.</TableColumn>
                <TableColumn>Tantiem, Bonus, Gratifikasi, Jasa Produksi dan THR</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.tantiemBonus'
                    onChange={(e, v)=> this.calculate('tantiemBonus', v)}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>8.</TableColumn>
                <TableColumn>Jumlah Penghasilan Bruto (1 + 2 + 3 + 4 + 5 + 6 + 7)</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    disabled={true}
                    name='bagB.jmlBruto'
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow><TableColumn colSpan='4'><b>PENGURANGAN</b></TableColumn></TableRow>

              <TableRow>
                <TableColumn>9.</TableColumn>
                <TableColumn>Biaya Jabatan / Biaya Pensiun</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.biayaJabatan'
                    onChange={(e, v)=> this.calculate('biayaJabatan', v)}
                    className='md-text-right'
                    disabled={true}
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>10.</TableColumn>
                <TableColumn>Iuran Pensiun atau iuran THT/JHT</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.iuranPensiun'
                    onChange={(e, v)=> this.calculate('iuranPensiun', v)}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>11.</TableColumn>
                <TableColumn>Jumlah Pengurangan (9 + 10)</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    disabled={true}
                    name='bagB.jmlPengurangan'
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow><TableColumn colSpan='4'><b>PERHITUNGAN PPh PASAL 21</b></TableColumn></TableRow>
              <TableRow>
                <TableColumn>12.</TableColumn>
                <TableColumn>Jumlah Penghasilan Neto (8 - 11)</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.jmlPenghasilanNetto'
                    disabled={true}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>13.</TableColumn>
                <TableColumn>Penghasilan Neto Masa Sebelumnya</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.penghasilanNettoSebelumnya'
                    disabled={status05}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>14.</TableColumn>
                <TableColumn>Jumlah Penghasilan Neto untuk PPH Pasal 21 (Setahunkan/ Disetahunkan)</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.penghasilanSetahunkan'
                    disabled={true}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>15.</TableColumn>
                <TableColumn>Penghasilan Tidak Kena Pajak (PTKP)</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.ptkp'
                    disabled={true}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>16.</TableColumn>
                <TableColumn>Penghasilan Kena Pajak Setahun/Disetahunkan</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.penghasilanKenaPajak'
                    disabled={true}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>17.</TableColumn>
                <TableColumn>PPH Pasal 21 atas Penghasilan Kena Pajak Setahun/Disetahunkan</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.pph21AtasPenghasilan'
                    disabled={true}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>18.</TableColumn>
                <TableColumn>PPH Pasal 21 yang telah dipotong Masa sebelumnya</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.pph21DipotongSebelumnya'
                    onChange={(e, v)=> this.calculate('pph21DipotongSebelumnya', v)}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>19.</TableColumn>
                <TableColumn>PPH Pasal 21 Terhutang</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.pph21Terutang'
                    disabled={true}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>20.</TableColumn>
                <TableColumn>PPH Pasal 21 dan PPH Pasal 26 yang telah dipotong/dilunasi</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.pph21Dilunasi'
                    disabled={true}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
            </TableBody>
          </DataTable>
        </Card>
        <br/>

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='C. IDENTITAS PEMOTONG' />
          <Divider/>
          <div className='md-grid'>
            <Field
              label='NPWP Pemotong'
              name='bagC.npwp'
              className="md-cell md-cell--12"
              component={TextfieldMask}
              maskFormat="##.###.###.#-###-###"
              mask="_"
              length={15}
              validate={validation.required}
            />
            <Field
              label='Nama Pemotong'
              name='bagC.nama'
              className="md-cell md-cell--12"
              component={Textfield}
              validate={validation.required}
            />
          </div>
        </Card>
      </div>
    )
  }
}
