import download from "downloadjs";
import LogExportService from "modules/Main/Export/LogExport.service";
import React from 'react';
import { connect } from 'react-redux';
import ListView from '../../../../../../components/entity/listView';
import ColumnService from '../../../../../../services/column.service';
import ParamService from "../../../../../../services/param.service";
import AllBP25Service from './AllBP25.service';
import {Checkbox} from "../../../../../../components/form";
import moment from "moment";

@connect(AllBP25Service.stateConnectSetting(), AllBP25Service.actionConnectSetting())
export default class AllBP25View extends ListView {
  service=AllBP25Service

  constructor(props){
    super(props);
    this.state = {
      showTableSetting:false,
      showDialogConfirmDeleteSelected:false,
      showForm:false,
      formData: {},
      data: []
    }
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Npwp'
          name='npwp__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Nama'
          name='name__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Email'
          name='email__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Alamat'
          name='address__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Nomor SK'
          name='noSk__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
      </this.SearchContainer>
    )
  }

  editDialog = false;
  _barActions = [];

  editItem = (item) => {
    if(this.editDialog) {
      this.showFormDialog(item);
    } else {
      if(this.rootPath) {
        this.props.history.push(this.rootPath+"/"+item.id)
      } else {
        this.props.history.push('/'+this.service.name+"/"+item.id);
      }
    }
  }

  defaultColumns = [
    /*{isSortable:true, label: "word.id",  value: "id", show:false, isSearchable:true, isDefaultSort: false},*/
    {isSortable:true, label: "word.createdUsername",  value: "createdUsername", show:true, isSearchable:true},
    {isSortable:true, label: "word.createdBy",  value: "createdBy", show:true, isSearchable:true},
    {isSortable:true, label: "word.createdDate", value: "createdDate", show:true, isSearchable:true, type:"date", isDefaultSort: true},
    {isSortable:true, label: "word.modifiedUsername",  value: "modifiedUsername", show:true, isSearchable:true},
    {isSortable:true, label: "word.lastModifiedBy",  value: "lastModifiedBy", show:true, isSearchable:true},
    {isSortable:true, label: "word.lastModifiedDate", value: (d)=> {
        if(d.lastModifiedDate) {
          return <div className='mpk-font-size-S'>{moment(d.lastModifiedDate).format('lll')}</div>
        } else {
          return null;
        }
      }, show:true, isSearchable:true, type:"func", searchField: 'lastModifiedDate', isDefaultSort: true}
  ]

  columns=[
    {isSortable: true, label: "word.reference",  value: "rev", show:false, isSearchable:true},
    {isSortable: true, label: "word.npwp",  value: "npwp", show:true, isSearchable:true},
    {isSortable: true, label: "word.name",  value: "name", show:true, isSearchable:true},
    {isSortable: true, label: "word.email",  value: "email", show:true, isSearchable:true},
    {isSortable: true, label: "word.address",  value: "address", show:true, isSearchable:true},
    {isSortable: true, label: "word.noSk",  value: "no", show:true, isSearchable:true},
    {isSortable: true, label: "word.pph",  value: "pph", show:true, isSearchable:true, type: 'number'},
    {isSortable: true, label: "word.createdDate",  value:"createdDate", type:"date", show:true, isSearchable:true},
    {isSortable: true, label: "word.createdBy",  value:"createdBy", show:true, isSearchable:true},
  ]

  _tableActions = []

}
