import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Field, reduxForm } from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../../../../components/form';

import ListView from '../../../../../../components/entity/listView';
import ColumnService from '../../../../../../services/column.service';
// import ImportService from './../Import/Import.service';
import axios from 'axios';
import izitoast from 'izitoast';
import ImportSigner22Service from './ImportSigner22.service';
import ImportSigner22DialogView from './ImportSigner22.dialog';
import download from "downloadjs";
import ListViewImport from '../../../../../../components/entity/ListViewImport';

@reduxForm({form: 'ImportSigner22Form', destroyOnUnmount: true})
@connect((state) => ({
  ...ImportSigner22Service.stateConnectSetting()(state),
  formData: getFormValues('ImportSigner22Form')(state),
  user: state.authEppt.user,
  company: state.authEppt.currentCompany,
  // EPPT CORE
  auth: state.auth.company
  // EPPT CORE
}), ImportSigner22Service.actionConnectSetting())
export default class ImportSigner22View extends ListViewImport {
  service=ImportSigner22Service
  FormDialog=ImportSigner22DialogView
  pasal='pasal22'
  category='SIGNER_22'
  importPath='/api/import/spt/22'
  importPathFunc() {
    return `/api/import/company/${this.props.company.id}`;
  }
  showAutomatisTab=false
  // viewType=2

  beforeFetch(params) {
    params["category.equals"] = "SIGNER_22"
  }

  async handleDownloadTemplate() {
    var report = await ImportSigner22Service.api.downloadTemplate()
    download(report.data, 'Signer22.csv');
  }

}
