import ApiService from '../../../services/api.service';

class SummaryService extends ApiService {
  name= 'summary';
  path= 'summary';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api={
    generate: async (data, pasal) => {
      var res = null
      if(pasal === "26"){
        /*res = await this.http.post(`${this.apiLocation}/pasal${pasal}/summary/generate/26`, data, {
          responseType: 'blob'
        })*/

        res = await this.http.post(`${this.apiLocation}/pasal23/summary/generate/26`, data)
      } else if(pasal === "23"){
        /*res = await this.http.post(`${this.apiLocation}/pasal${pasal}/summary/generate/23`, data, {
          responseType: 'blob'
        })*/

        res = await this.http.post(`${this.apiLocation}/pasal${pasal}/summary/generate/23`, data)
      } else {
        /*res = await this.http.post(`${this.apiLocation}/pasal${pasal}/summary/generate`, data, {
          responseType: 'blob'
        })*/

        res = await this.http.post(`${this.apiLocation}/pasal${pasal}/summary/generate`, data)
      }
      return res;
    },
  }
}


export default new SummaryService();
