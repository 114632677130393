import ApiService from '../../../../../services/api.service';

class ALLBP21SSPService extends ApiService {
  name= 'ALL_BP_21_SSP';
  path= 'spt/ssp';
  constructor() {
    super();
    this.init()
  }

  api= {
    export: async (data) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/export`, data);
      return res;
    }
  }
}

export default new ALLBP21SSPService();
