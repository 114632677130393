import ApiService from '../../../../../services/api.service';

class BlokBunifikasi extends ApiService {
  name= 'BlokBunifikasi';
  path= 'setting/block-bunifikasi';
  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api= {
    updateOk: async(res, id)=> {
      var res = await this.http.put(`${this.apiLocation}/block-bunifikasi/npwp/${id}`, res);
      return res;
    },
    getAccess: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/block-bunifikasi`);
      return res;
    },
    cekAccess: async (sptId) => {
      var res = await this.http.get(`${this.apiLocation}/block-bunifikasi/check`);
      return res;
    },
    putSettingAccess: async (form)=> {
      var res = await this.http.put(`${this.apiLocation}/setting/block-bunifikasi`, form);
      return res;
    },
    getSettingAccess: async (companyId)=> {
      if(companyId){
        var res = await this.http.get(`${this.apiLocation}/setting/block-bunifikasi/${companyId}/company`);
        return res;
      } else {
        return null
      }
    }
  }
}

export default new BlokBunifikasi();
