import React from 'react'
import SummaryForm from './Summarys.form'
import { TabsContainer, Tabs, Tab } from 'react-md';

class SummaryView extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            pasal: '21'
        }
    }

    changePasal(){

    }

    render(){
        var pasal21 = true
        if(localStorage.getItem("p21") && localStorage.getItem("p21") == "true"){ pasal21 = true } else { pasal21 = false }
        var pasal22 = true
        if(localStorage.getItem("p22") && localStorage.getItem("p22") == "true"){ pasal22 = true } else { pasal22 = false }
        var pasal23 = true
        if(localStorage.getItem("p23") && localStorage.getItem("p23") == "true"){ pasal23 = true } else { pasal23 = false }
        var pasal15 = true
        if(localStorage.getItem("p15") && localStorage.getItem("p15") == "true"){ pasal15 = true } else { pasal15 = false }
        var pasal4  = true
        if(localStorage.getItem("p4") && localStorage.getItem("p4") == "true"){ pasal4 = true } else { pasal4 = false }
        return (
            <div style={{ width: '100%' }}>
                <SummaryForm pasal={this.state.pasal} {...this.props}>
                    <TabsContainer colored>
                        <Tabs tabId="simple-tab">
                            {pasal21 && <Tab onClick={()=> this.setState({ pasal: '21' })} label="21"></Tab>}
                            {pasal23 && <Tab onClick={()=> this.setState({ pasal: '23' })} label="23"></Tab>}
                            {pasal23 && <Tab onClick={()=> this.setState({ pasal: '26' })} label="26"></Tab>}
                            {pasal4 && <Tab onClick={()=> this.setState({ pasal: '4' })} label="4 (2)"></Tab>}
                            {pasal22 && <Tab onClick={()=> this.setState({ pasal: '22' })} label="22"></Tab>}
                            {pasal15 && <Tab onClick={()=> this.setState({ pasal: '15' })} label="15"></Tab>}
                        </Tabs>
                    </TabsContainer>
                </SummaryForm>
            </div>
        )
    }
}

export default SummaryView;
