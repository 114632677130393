import React, { Component } from 'react';
import { Grid, Cell } from 'react-md';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import { validation, Textfield, Searchfield, TextfieldMask, Datepicker, convert } from '../../../../../../components/form';
import DialogView from '../../../../../../components/entity/dialog.view';
import Master23KjsService from './Master23Kjs.service';
import Master23KapService from '../Kap/Master23Kap.service';

@reduxForm({form: 'master-23-kjs', destroyOnUnmount: false})
export default class Master23KjsDialogView extends DialogView {
  service=Master23KjsService
  initialData = {
  }

  formView() {
    return (
      <Grid style={{padding:'none'}}>
        <Field
          label='Kode'
          name='code'
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
        />
        <Field
          label='Name'
          name='name'
          className="md-cell md-cell--6"
          component={Textfield}
          validate={validation.required}
        />
        <Field
          label='Kap'
          name='kap'
          className="md-cell md-cell--12"
          service="kap"
          component={Searchfield}
          valueField='parent'
          service={Master23KapService}
          validate={validation.required}
        />
        <Field
          label='Description'
          name='description'
          className="md-cell md-cell--12"
          component={Textfield}
          validate={validation.required}
        />
      </Grid>
    )
  }
}
