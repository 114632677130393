
import * as validationM from './validation';
import TextfieldM from './Textfield';
import TextfieldMaskM from './TextfieldMask';
import SearchfieldM from './Searchfield';
import Searchfieldv2M from './Searchfieldv2';
import SwitchM from './Switch';
import CheckboxM from './Checkbox';
import RadioM from './Radio';
import DatepickerM from './Datepicker';
import MultiselectM from './Multiselect'
import Multiselectv2M from './Multiselectv2'
import convertM from './convert';
import FileinputM from './Fileinput';
import FileInputNextM from './FileInputNext';
import CheckboxSelectionM from './CheckboxSelection';

export const validation = validationM;
export const Textfield = TextfieldM;
export const TextfieldMask = TextfieldMaskM;
export const Searchfield = SearchfieldM;
export const Searchfieldv2 = Searchfieldv2M;
export const Switch = SwitchM;
export const Checkbox = CheckboxM;
export const Radio = RadioM;
export const Datepicker = DatepickerM;
export const Multiselect = MultiselectM;
export const Multiselectv2 = Multiselectv2M;
export const convert = convertM;
export const Fileinput = FileinputM;
export const FileInputNext = FileInputNextM;
export const CheckboxSelection = CheckboxSelectionM;
