import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  Tab,
  Tabs,
  SelectionControlGroup,
  Checkbox
} from 'react-md';

import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Radio, convert} from '../../../../../components/form';
import UtilService from '../../../../../services/utils.service'
import FormView from '../../../../../components/entity/form.view';
import DialogView from '../../../../../components/entity/dialog.view';
import ApplicationService from './Application.service'
import moment from 'moment'
import counterpart from 'counterpart'
import UserService from './../User/User.service'
import ApplicationOrganizationView from '../ApplicationOrganization/ApplicationOrganization.view';

@reduxForm({form: 'ApplicationForm', destroyOnUnmount: true})
@connect((state) => ({
  formData:state.form.ApplicationForm,
  user: state.auth.user
}))
export default class ApplicationFormView extends FormView {
  service=ApplicationService
  viewType=2;

  formView() {
    var login = ""
    if(this.props.user && this.props.user.login) login = this.props.user.login
    var showForm = true
    if(this.props.match.params.id == 'new') showForm = false
    return (
      <div>
        <Card>
          <div className='md-grid'>
            {/* <Field
              label={counterpart('word.user')}
              name='userId'
              className="md-cell md-cell--12"
              component={Searchfield}
              remoteSearch={true} service={UserService}
              apiPath="lookup"
              apiParam={login}
              valueField="id"
              viewField="login"
              validate={validation.required}
            /> */}
            <Field
              label={counterpart('word.name')}
              name='name'
              className="md-cell md-cell--12"
              component={Textfield}
              validate={validation.required}
            />
            <Field
              label={counterpart('word.description')}
              name='description'
              className="md-cell md-cell--12"
              component={Textfield}
              validate={validation.required}
            />
          </div>
        </Card>

        <br/>

        <div style={{height: 400}}>
          {showForm &&
            <ApplicationOrganizationView appId={this.props.match.params.id} />
          }
        </div>
      </div>
    )
  }
}
