import http from '../../../../services/http.service';

export default {
  api: {
    async get(sptId) {
      return await http.get(`/pasal23/spt/no/${sptId}`);
    },
    async save(data) {
      return await http.post('/pasal23/spt/no', data);
    },
    async update(data) {
      return await http.put('/pasal23/no', data);
    },
  }
}
