import ApiService from '../../../../services/api.service';

class SettingEmailScheduleService extends ApiService {
  name= 'SettingEmailSchedule';
  path= 'setting/emailSchedule/page';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api = {
    getByCompany: async (companyId)=> {
      return await this.http.get(`${this.apiLocation}/setting/emailSchedule/company/`+companyId)
    },
    updateByCompany: async (formData)=> {
      return await this.http.put(`${this.apiLocation}/setting/emailSchedule`, formData)
    }
  }
}

export default new SettingEmailScheduleService();
