import ApiService from '../../../../../../../services/api.service'
import http from '../../../../../../../services/http.service'

class IndukEbupotService extends ApiService {
  name = 'IndukEbupot';
  path= 'ebupot/induk';
  advancedSearch=true
  
  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api={
    getInduk: async (sptId)=> {
      return await http.get(`${this.apiLocation}/${this.path}/${sptId}/spt`);
    },
  }
}


export default new IndukEbupotService();
