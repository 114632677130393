import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Chip,
  Switch,
  List,
  ListItem,
  ListItemControl,
  Subheader,
  FontIcon,
  Avatar,
  Divider,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
} from 'react-md';

import {validation, Textfield, Searchfield, TextfieldMask, Datepicker, Radio, Checkbox} from '../../../../components/form';
import Bluebird from 'bluebird';
import { Field, reduxForm, getFormValues, change  } from 'redux-form';
// import EbillingAccountService from '../EbillingAccountService';
//import FormDialog from './Organization.dialog.view';
import UtilService from '../../../../services/utils.service';
import download from 'downloadjs';
import iziToast from 'izitoast';
import ListView from '../../../../components/entity/listView';
import PaymentOrderService from './PaymentOrder.service';
import SuratSetoranService from '../SuratSetoran/SuratSetoran.service';
import LogCetakLaporanPOService from '../log/LogCetakLaporanPO/LogCetakLaporanPO.service';
import LogCetakPaymentOrderService from '../log/LogCetakPaymentOrder/LogCetakPaymentOrder.service';
// import RetryService from '../Retry/Retry.service';
import utilsService from '../../../../services/utils.service';
import BankService from '../Bank/Bank.service';
import Constants from '../Constants/Constants';
import moment from 'moment';

@connect((state)=> ({
  ...PaymentOrderService.stateConnectSetting()(state),
}), PaymentOrderService.actionConnectSetting())
export default class PaymentOrderView extends ListView {
  service=PaymentOrderService
  suratSetoranService=SuratSetoranService
  logCetakLaporanPOService=LogCetakLaporanPOService
  logCetakPaymentOrderService=LogCetakPaymentOrderService
  // retryService=RetryService
  // ebillingAccountService=EbillingAccountService
  bankService=BankService

  FormDialog=() => <div/>

  constructor(props){
    super(props);
    this.state = {
      ...this.state
    }

    this.state.ebillingUser = {}
  }

  beforeFetch(params) {
    if(params["sortBy"] === "createdAt") {
      params["sortBy"] = "createdDate"
      params["sort"] = "DESC"
    }
  }

  addDialog=false
  editDialog=false

  _barActions = [
    {
      label:'word.delete',
      iconClassName:'mdi mdi-delete',
      onClick:() => {
        this.setState({showDialogConfirmDeleteSelected:true})
      },
      disabledFunc:() => this.props.table.selected.length === 0
    },
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => this.fetchData()
    },
  ]

  tableActions = (data) => {
    let actions = [
      {
        label:"More Info",
        iconClassName:"mdi mdi-bell",
        onClick: (item) => this.editItem(item)
      },
    ]

    let divider = {
      label:"divider",
      iconClassName:"-"
    }

    actions.push(divider)

    /*if(!data.name) {
      let inquiryAction = {
        label:"Inquiry",
        iconClassName:"mdi mdi-clipboard-account",
        onClick:(data) => this.retryInquiry(data),
      }

      actions.push(inquiryAction)
    }*/

    if(!data.idBilling) {
      let idBillingAction = {
        label:"ID Billing",
        iconClassName:"mdi mdi-play",
        onClick:(item) => this.retryBilling(item),
      }

      actions.push(idBillingAction)
    }

    if(data.idBilling) {
      let printAction = {
        label:"Cetak",
        iconClassName:"mdi mdi-printer",
        onClick:(item, callback) => this.printItem(item, callback),
        confirmation:{
          title:"sentence.custom.print",
          message:"sentence.custom.printConfirmation"
        }
      }

      actions.push(printAction)
    }

    let deleteAction = {
      label:"Delete",
      iconClassName:"mdi mdi-delete",
      onClick:(item, callback) => this.deleteItem(item, callback),
      confirmation:{
        title:"sentence.custom.deleteItem",
        message:"sentence.custom.deleteItemConfirmation"
      }
    }

    actions.push(deleteAction)

    return actions
  }

  columns=[
    {isSortable: true, label: "word.kodeSSP", value: "code", show: true, isSearchable: true},
    {isSortable: false, label: "word.idBilling", value: (data) => {
      if(data.idBilling) {
        return data.idBilling
      }

      if(data.errorBilling) {
        return data.messageBilling
      } else {
        return '-'
      }
    }, type: "func", show: true, isSearchable: true},
    {isSortable: true, label: "word.npwp", value: "npwp", show: true, isSearchable: true},
    {isSortable: false, label: "word.name", value: (data) => {
      if(data.name) {
        return data.name
      }

      if(data.errorInquiry) {
        return data.messageInquiry
      } else {
        return '-'
      }
    }, type: "func", show: true, isSearchable: true},
    {isSortable: true, label: "word.jenisPajak", value: "jpCode", show: true, isSearchable: true},
    {isSortable: true, label: "word.jenisSetoran", value: "jsCode", show: true, isSearchable: true},
    {isSortable: false, label: "word.masaPajak", value: (data) => {
      return data.bulan1 + ' - ' + data.bulan2 + ' ' + data.year;
    }, type: "func", show: true, isSearchable: false},
    {isSortable: true, label: "word.jumlahSetoran", value: "amount", type: "number", show: true, isSearchable: true},
    {isSortable: true, label: "word.aktifSd", value: "activeUntil", show: true, isSearchable: true},
    {isSortable: true, label: "word.username", value: "additionalUsername", show: false, isSearchable: true},
  ]

  async checkConnection() {
    /*let res = await this.ebillingAccountService.api.me()

    this.setState({ebillingUser: res.data});*/
  }

  async componentDidMount() {
    this.rootPath = this.props.match.url;

    try {
      this.checkConnection()
    } catch (e){
      this.toast.warning({
        title: e.name,
        message: e.message
      })
    }
  }

  /*retryInquiry = async (item) => {
    let sspCodeList = []

    if(!item.name) {
      sspCodeList.push(item.code)
    }

    if(sspCodeList.length > 0) {
      try {
        let res = await this.srvice.api.retryInquiries(sspCodeList)

        iziToast.success({
          title: 'Sukses',
          message: 'Inquiry sedang diproses, silahkan klik tombol refresh.'
        })
      } catch (e) {
        iziToast.warning({
          title: e.name,
          message: e.message
        })
      }
    } else {
      iziToast.warning({
        title: 'Gagal',
        message: 'Data sudah terdapat nama.'
      })
    }
  }

  retryInquiryBulk = async () => {
    let selected = this.props.table.selected

    let sspCodeList = []

    selected.forEach(item => {
      if(!item.name) {
        sspCodeList.push(item.code)
      }
    })

    if(sspCodeList.length > 0) {
      try {
        let res = await this.service.api.retryInquiries(sspCodeList)

        iziToast.success({
          title: 'Sukses',
          message: 'Inquiry sedang diproses, silahkan klik tombol refresh.'
        })
      } catch (e) {
        iziToast.warning({
          title: e.name,
          message: e.message
        })
      }
    } else {
      iziToast.warning({
        title: 'Gagal',
        message: 'Anda harus menceklis minimal satu data yang belum terdapat nama.'
      })
    }
  }*/

  retryBilling = async (item) => {
    let sspCodeList = []

    if(!item.idBilling) {
      sspCodeList.push(item.code)
    }

    if(sspCodeList.length > 0) {
      try {
        let res = await this.service.api.retryBillings(sspCodeList)

        iziToast.success({
          title: 'Sukses',
          message: 'Billing sedang diproses, silahkan klik tombol refresh.'
        })
      } catch (e) {
        iziToast.warning({
          title: e.name,
          message: e.message
        })
      }
    } else {
      iziToast.warning({
        title: 'Gagal',
        message: 'Data sudah terdapat billing.'
      })
    }
  }

  retryBillingBulk = async () => {
    let selected = this.props.table.selected

    let sspCodeList = []

    selected.forEach(item => {
      if(!item.idBilling) {
        sspCodeList.push(item.code)
      }
    })

    if(sspCodeList.length > 0) {
      try {
        let res = await this.service.api.retryBillings(sspCodeList)

        iziToast.success({
          title: 'Sukses',
          message: 'Billing sedang diproses, silahkan klik tombol refresh.'
        })
      } catch (e) {
        iziToast.warning({
          title: e.name,
          message: e.message
        })
      }
    } else {
      iziToast.warning({
        title: 'Gagal',
        message: 'Anda harus menceklis minimal satu data yang belum terdapat id billing.'
      })
    }
  }

  printItem = async (item, callback) => {
    try {
      /*if(item.idBilling) {
        let report = await this.suratSetoranService.api.print(item.id)
        let filename = report.headers.filename

        download(report.data, filename);
      } else {
        iziToast.warning({
          title: 'Gagal',
          message: 'Anda hanya bisa mencetak data yang terdapat ID billing.'
        })
      }*/

      let idList = [item.id]

      let res = await this.logCetakPaymentOrderService.api.generateList(idList)

      iziToast.success({
        title: 'Sukses',
        message: 'Silahkan cek di halaman log cetak billing.'
      })
      callback();
    } catch(e) {
      callback(e, e)
    }
  }

  printBilling = async () => {
    let selected = this.props.table.selected

    let idList = []

    selected.forEach(item => {
      if(item.idBilling) {
        idList.push(item.id)
      }
    })

    if (idList.length) {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Cetak Billing',
        okText: 'Cetak',
        width: 600,
        text: 'Apakah anda yakin akan mencetak id billing yang anda ceklis?',
      }));

      if (dialog === undefined) {
        dialog = {}
      }

      if (dialog) {
        try {
          let res = await this.logCetakPaymentOrderService.api.generateList(idList)

          iziToast.success({
            title: 'Sukses',
            message: 'Silahkan cek di halaman log cetak billing.'
          })
        } catch (e) {
          iziToast.warning({
            title: e.name,
            message: e.message
          })
        }
      }
    }
    else {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Cetak Billing',
        okText: 'Cetak',
        width: 600,
        text: (
          <div className='md-grid'>
            <this.ig.Field
              label='NPWP'
              name='npwp'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Jenis Pajak'
              name='jenisPajak'
              className="md-cell md-cell--6"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Jenis Setoran'
              name='jenisSetoran'
              className="md-cell md-cell--6"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Bulan 1'
              name='bulan1'
              className="md-cell md-cell--6"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Bulan 2'
              name='bulan2'
              className="md-cell md-cell--6"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Tahun Pajak'
              name='year'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />

            <div style={{height: 300}}/>
          </div>
        ),
      }));

      if (dialog === undefined) {
        dialog = {}
      }

      if (dialog) {
        try {
          let res = await this.logCetakPaymentOrderService.api.generate(dialog)

          iziToast.success({
            title: 'Sukses',
            message: 'Silahkan cek di halaman log cetak billing.'
          })
        } catch (e) {
          iziToast.warning({
            title: e.name,
            message: e.message
          })
        }
      }
    }
  }

  /*deleteSelected = async (items, callback) => {
    try {
      let idList = []

      items.forEach(item => {
        if (item.id) {
          idList.push(item.id)
        }
      })

      let data = {
        id: idList
      }

      let res = await this.service.api.deleteChecked(data)

      callback()

      await this.fetchData()
    } catch (e) {
      callback(e, e)
    }
  }*/

  printLaporan = async ()=> {
    let dialog = await this.context.showDialog((props, res, rej) =>({
      title: 'Cetak Laporan',
      okText: 'Cetak',
      width: 600,
      text: (
        <div className='md-grid'>
          <this.ig.Field
            label='NPWP'
            name='npwp'
            className="md-cell md-cell--12"
            component={this.ig.Textfield}
          />
          {/*<this.ig.Field
          label='Nama'
          name='name'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
          />*/}
          <this.ig.Field
            label='Jenis Pajak'
            name='jenisPajak'
            className="md-cell md-cell--6"
            component={this.ig.Textfield}
          />
          <this.ig.Field
            label='Jenis Setoran'
            name='jenisSetoran'
            className="md-cell md-cell--6"
            component={this.ig.Textfield}
          />
          <this.ig.Field
            label='Bulan 1'
            name='bulan1'
            className="md-cell md-cell--6"
            component={this.ig.Textfield}
          />
          <this.ig.Field
            label='Bulan 2'
            name='bulan2'
            className="md-cell md-cell--6"
            component={this.ig.Textfield}
          />
          <this.ig.Field
            label='Tahun Pajak'
            name='year'
            className="md-cell md-cell--12"
            component={this.ig.Textfield}
          />

          <div style={{height: 300}}/>
        </div>
      ),
    }));

    if (dialog === undefined) {
      dialog = {}
    }

    if (dialog) {
      try {
        /*let createdDateMulai = dialog.createdDateMulai

        if(createdDateMulai) {
          createdDateMulai = moment(createdDateMulai).format('YYYY-MM-DD')

          dialog.createdDateMulai = createdDateMulai
        }

        let createdDateAkhir = dialog.createdDateAkhir

        if(createdDateAkhir) {
          createdDateAkhir = moment(createdDateAkhir).format('YYYY-MM-DD')

          dialog.createdDateAkhir = createdDateAkhir
        }*/

        let res = await this.logCetakLaporanPOService.api.generate(dialog)

        iziToast.success({
          title: 'Sukses',
          message: 'Silahkan cek di halaman log cetak laporan billing.'
        })
      } catch (e) {
        iziToast.warning({
          title: e.name,
          message: e.message
        })
      }
    }
  }

  async order(type) {
    var selectedData = this.props.table.selected;
    var total = 0;

    if(type === 'all') {
      selectedData = []

      let filter = this.props.tableFilter2

      let criteria = {}

      if (filter) {
        let filterKeys = Object.keys(filter)

        filterKeys.forEach(key => {
          let replacedKey = key.replace('__', '.')

          criteria[replacedKey] = filter[key]
        })
      }

      localStorage.setItem('paymentOrder', JSON.stringify({type: type, data: selectedData, criteria: criteria}));
    }
    else {
      localStorage.setItem('paymentOrder', JSON.stringify({type: type, data: selectedData}));
    }

    let dialog = await this.context.showDialog((props, res, rej) =>({
      title: 'Order',
      initialValue:{},
      okText: 'Confirm',
      width: 600,
      text: (
        <div>
          <div className='md-grid'>
            <Field
              label='Metode Pembayaran'
              name='paymentMethod'
              className="md-cell md-cell--12"
              component={Searchfield}
              valueField="parent"
              viewField="name"
              service={BankService}
              validate={validation.required}
            />
          </div>
          {type === 'selected' &&
          <div>
            <DataTable plain>
              <TableHeader>
                <TableRow>
                  <TableColumn>No.</TableColumn>
                  <TableColumn>Kode SSP</TableColumn>
                  <TableColumn>ID Billing</TableColumn>
                  <TableColumn>NPWP</TableColumn>
                  <TableColumn>Nama</TableColumn>
                  <TableColumn>Jenis Pajak</TableColumn>
                  <TableColumn>Jenis Setoran</TableColumn>
                  <TableColumn>Masa Pajak</TableColumn>
                  <TableColumn>Jumlah Setoran</TableColumn>
                </TableRow>
              </TableHeader>
              <TableBody>
                {
                  selectedData.map((d, i)=> {
                    total += d.amount;
                  return <TableRow key={i}>
                    <TableColumn>{i+1}</TableColumn>
                    <TableColumn>{d.code}</TableColumn>
                    <TableColumn>{d.idBilling}</TableColumn>
                    <TableColumn>{d.npwp}</TableColumn>
                    <TableColumn>{d.name}</TableColumn>
                    <TableColumn>{d.jpCode}</TableColumn>
                    <TableColumn>{d.jsCode}</TableColumn>
                    <TableColumn>{ d.bulan1 + ' - ' + d.bulan2 + ' ' + d.year}</TableColumn>
                    <TableColumn style={{textAlign: 'right'}}>{utilsService.number.format(d.amount)}</TableColumn>
                  </TableRow>
                })}

                <TableRow style={{borderTop: '2px solid black'}}>
                  <TableColumn></TableColumn>
                  <TableColumn>
                    <strong>Total</strong>
                  </TableColumn>
                  <TableColumn></TableColumn>
                  <TableColumn></TableColumn>
                  <TableColumn></TableColumn>
                  <TableColumn></TableColumn>
                  <TableColumn></TableColumn>
                  <TableColumn></TableColumn>
                  <TableColumn style={{textAlign: 'right'}}>
                    <strong>{utilsService.number.format(total)}</strong>
                  </TableColumn>
                </TableRow>
              </TableBody>
            </DataTable>
          </div>
          }
        </div>
      )
    }));

    if (dialog === undefined)
      dialog = {}

    if(dialog) {
      let paymentOrder = JSON.parse(localStorage.getItem('paymentOrder'));

      paymentOrder.paymentMethod = dialog.paymentMethod;

      localStorage.setItem('paymentOrder', JSON.stringify(paymentOrder));

      let params = this.props.match.params
      let companyId = params.companyId
      let subscriptionId = params.subscriptionId

      console.log({dialog})

      // BNI Direct
      if(dialog.paymentMethod.code === 'BNIDIRECT') {
        this.props.history.push(`/company/${companyId}/${subscriptionId}/init/EBILLING/paymentOrder/bniDirect`)
      } else if(dialog.paymentMethod.code === 'BRICMS') {
        this.props.history.push(`/company/${companyId}/${subscriptionId}/init/EBILLING/paymentOrder/briCms`)
      } else if(dialog.paymentMethod.code === 'MANDIRIMCM') {
        this.props.history.push(`/company/${companyId}/${subscriptionId}/init/EBILLING/paymentOrder/mandiriCm`)
      } else {
        console.log('doing something else')
      }
    }
  }


  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Kode SSP'
          name='code__contains'
          className="md-cell md-cell--6"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='ID Billing'
          name='idBilling__contains'
          className="md-cell md-cell--6"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NPWP'
          name='npwp__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        {/*<this.ig.Field
          label='Nama'
          name='name__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />*/}
        <this.ig.Field
          label='Jenis Pajak'
          name='jpCode__contains'
          className="md-cell md-cell--6"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Jenis Setoran'
          name='jsCode__contains'
          className="md-cell md-cell--6"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Bulan 1'
          name='bulan1__contains'
          className="md-cell md-cell--6"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Bulan 2'
          name='bulan2__contains'
          className="md-cell md-cell--6"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Tahun Pajak'
          name='year__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
      </this.SearchContainer>
    )
  }

  _barItem() {
    let selected = this.props.table.selected

    return (
      <div className='actions mpk-layout'>
        <Button icon tooltipLabel="Generate" onClick={() => this.retryBillingBulk()} disabled={selected.length === 0}>play_arrow</Button>
        {/*<Button icon tooltipLabel="Inquiry" onClick={() => this.retryInquiryBulk()} disabled={selected.length === 0}>assignment_ind</Button>*/}
        <Button icon tooltipLabel="Order" onClick={() => {this.order('selected')}} disabled={selected.length === 0}>shop</Button>
        <Button icon tooltipLabel="Order Semua" onClick={() => {this.order('all')}}>shop_two</Button>
        <Button icon tooltipLabel="Cetak Billing" onClick={() => this.printBilling()}>print</Button>
        <Button icon tooltipLabel="Laporan Billing" onClick={() => {this.printLaporan()}}>book</Button>
        <Switch
          style={{float: "right"}}
          id="switch-table-setting"
          name="Switch Table Setting"
          label=""
          checked={this.state.showTableSetting}
          onChange={() => {
            this.setState({
              showTableSetting:!this.state.showTableSetting
            })
          }}
        />
      </div>
    )
  }

  // right bar
  tabWidth = 280
  // tabs= ["status", "search", "column"]

  /*tabComponents() {
    var obj = this;
    return {
      status: this.status.bind(obj)
    }
  }

  status() {
    const { handleSubmit, submitting, valid, pristine } = this.props;
    return (
      <div className='mpk-layout column fill'>
        <div className='flex'>
          <List className="">
            <Subheader primaryText="Status Koneksi" />

            { this.state.ebillingUser.login &&
              <ListItem
                leftAvatar={<Avatar  suffix="green" icon={<FontIcon>done</FontIcon>} />}
                primaryText="Terhubung"
                secondaryText={"Terhubung sebagai " + this.state.ebillingUser.login + "."}
              />
            }

            { !this.state.ebillingUser.login &&
              <ListItem
                leftAvatar={<Avatar suffix="red" icon={<FontIcon>clear</FontIcon>} />}
                primaryText="Tidak Terhubung"
                secondaryText="Status Koneksi"
              />
            }

            {/!*<Subheader primaryText="Status Sistem" />

            <ListItem
              leftAvatar={<Avatar  suffix="green" icon={<FontIcon>done</FontIcon>} />}
              primaryText="Sistem Active"
              secondaryText="Status Sistem"
            />*!/}
          </List>
        </div>
      </div>
    )
  }*/

}
