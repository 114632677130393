import ApiService from '../../../../services/api.service';

class StatusKaryawanA1Service extends ApiService {
  name= 'statusKaryawanA1';
  path= 'master/status-karyawan-a1';
  constructor() {
    super();
    this.init()
  }
}


export default new StatusKaryawanA1Service();
