import ApiService from '../../../../../services/api.service';
import http from "../../../../../services/http.service";

class PBK15Service extends ApiService {
  name= 'PBK15';
  path= 'pasal15/pbk';

  api = {
    save: async(data)=> {
      var res = await this.http.post(`${this.apiLocation}/${this.path}`, data, {
        params: {
          blockBunifikasiMonth: data.blockBunifikasiMonth,
          blockBunifikasiYear: data.blockBunifikasiYear
        }
      })
      return res;
    },
    update: async (data) => {
      var res = await this.http.put(`${this.apiLocation}/${this.path}`, data, {
        params: {
          blockBunifikasiMonth: data.blockBunifikasiMonth,
          blockBunifikasiYear: data.blockBunifikasiYear
        }
      })
      return res;
    },
    total: async (sptId)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/total`)

      return res;
    },
    downloadCsv: async (sptId)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/csv/${sptId}`, {
        responseType: 'blob'
      })

      return res;
    },
    deleteBulk: async (ids)=> {
      let res = await http.post(`${this.apiLocation}/${this.path}/delete`, ids);

      return res;
    },
  }

  constructor() {
    super()
    this.init()
    this.initApi()
  }
}


export default new PBK15Service();
