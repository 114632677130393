import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
  Button,
  Chip,
  Switch,
  List,
  ListItem,
  Subheader,
  FontIcon,
  Avatar,
  Divider
} from 'react-md';
import moment from 'moment'
import {validation, Textfield, Searchfield, TextfieldMask, Datepicker, Radio, Checkbox} from '../../../../components/form';
import Bluebird from 'bluebird';
import { Field, reduxForm, getFormValues, change  } from 'redux-form';
import UtilService from '../../../../services/utils.service';
import download from 'downloadjs';
import iziToast from 'izitoast';
import ListView from '../../../../components/entity/listView';
import PaymentNotificationService from './PaymentNotification.service';
import PaymentOrderService from '../PaymentOrder/PaymentOrder.service';
import BuktiPenerimaanService from '../BuktiPenerimaan/BuktiPenerimaan.service';

@connect((state)=> ({
  ...PaymentOrderService.stateConnectSetting()(state),
}), PaymentOrderService.actionConnectSetting())
export default class PaymentNotificationDetailView extends ListView {
  service=PaymentNotificationService
  buktiPenerimaanService=BuktiPenerimaanService
  FormDialog=() => <div/>

  constructor(props){
    super(props);
    this.state = {
      ...this.state
    }

    this.state.invoice = {}
  }

  titleHeader() {
    return `ebilling.paymentNotification.detail`;
  }

  apiPath = 'findBillingsByOrderId';

  // viewType=2;
  addDialog=false
  editDialog=false

  beforeFetch(params) {
    params.invoiceId = this.props.match.params.id;
  }

  _barActions = [
    /*{
      label:'word.cancel',
      iconClassName:'mdi mdi-cancel',
      onClick: async() => {
        var dialog = await this.context.showDialog((props, res, rej) =>({
          title: 'Cancel',
          initialValue:{},
          okText: 'Ya',
          width: 600,
          text: 'Apakah anda ingin membatalakan data-data terpilih?'
        }));

        if (dialog === undefined)
          dialog = {}

        if(dialog) {
          this.cancelChecked()
        }

      },
      disabledFunc:() => this.props.table.selected.length === 0
    },*/
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => this.refreshData()
    },
  ]

  _barItem() {
    return (
      <div className='actions mpk-layout'>
        <Button icon tooltipLabel="Konfirmasi" onClick={() => this.confirmOrder()} disabled={
          this.state.invoice.status !== 'Order gagal dilakukan.' && this.state.invoice.status !== 'Order duplikasi.'
        }>check</Button>

        <Button icon tooltipLabel="Detail File Transaksi" onClick={()=> this.detailFileTransaksi()} disabled={
          this.state.invoice.status === 'Order gagal dilakukan.' || this.state.invoice.status === 'Order duplikasi.'
        }>subtitles</Button>

        {/*<Button icon tooltipLabel="Cetak Transaksi" onClick={() => this.print()}>print</Button>*/}

        <Button icon tooltipLabel="Back" onClick={()=> {this.props.history.goBack()}}>arrow_back</Button>

        <Switch
          style={{float: "right"}}
          id="switch-table-setting"
          name="Switch Table Setting"
          label=""
          checked={this.state.showTableSetting}
          onChange={() => {
            this.setState({
              showTableSetting:!this.state.showTableSetting
            })
          }}
        />
      </div>
    )
  }

  columns=[
    {isSortable: true, label: "word.kodeSSP", value: "code", show: true, isSearchable: true},
    {isSortable: false, label: "word.idBilling", value: (data) => {
      if(data.idBilling) {
        return data.idBilling
      }

      if(data.errorBilling) {
        return data.messageBilling
      } else {
        return '-'
      }
    }, type: "func", show: true, isSearchable: true},
    {isSortable: true, label: "word.npwp", value: "npwp", show: true, isSearchable: true},
    {isSortable: false, label: "word.name", value: (data) => {
      if(data.name) {
        return data.name
      }

      if(data.errorInquiry) {
        return data.messageInquiry
      } else {
        return '-'
      }
    }, type: "func", show: true, isSearchable: true},
    {isSortable: true, label: "word.jenisPajak", value: "jpCode", show: true, isSearchable: true},
    {isSortable: true, label: "word.jenisSetoran", value: "jsCode", show: true, isSearchable: true},
    {isSortable: false, label: "word.masaPajak", value: (data) => {
        return data.bulan1 + ' - ' + data.bulan2 + ' ' + data.year;
      }, type: "func", show: true, isSearchable: false},
    {isSortable: true, label: "word.jumlahSetoran", value: "amount", type: "number", show: true, isSearchable: true},
    {isSortable: true, label: "word.aktifSd", value: "activeUntil", show: true, isSearchable: true},
    /*{isSortable: true, label: "word.duplikat", type:'func', value: (data)=> {
      if(data.duplicate) {
        return <Button flat swapTheming style={{backgroundColor: '#B71C1C', color: 'white'}}>Ya</Button>
      } else {
        return <Button flat swapTheming style={{backgroundColor: '#4caf50', color: 'white'}}>Tidak</Button>
      }
    }, show: true, isSearchable: true},
    {isSortable: true, label: "word.dnp", type:'func', value: (data)=> {
      if(data.dnp) {
        return <Button flat swapTheming style={{backgroundColor: '#4caf50', color: 'white'}}>Ya</Button>
      } else {
        return <Button flat swapTheming style={{backgroundColor: '#B71C1C', color: 'white'}}>Tidak</Button>
      }
    }, show: true, isSearchable: true},*/
    {isSortable: true, label: "word.status", value: "status", show: true, isSearchable: true},
    {isSortable: true, label: "word.username", value: "additionalUsername", show: false, isSearchable: true},
  ]

  tableActions = (data) => {
    let invoice = this.state.invoice

    let actions = [
      /*{
        label:"More Info",
        iconClassName:"mdi mdi-bell",
        onClick: (item) => this.showItem(item)
      },*/
    ]

    if(invoice.status === 'Order gagal dilakukan.' || invoice.status === 'Order duplikasi.') {
      let divider = {
        label:"divider",
        iconClassName:"-"
      }

      actions.push(divider)

      let cancelAction = {
        label:"Cancel",
        iconClassName:"mdi mdi-cancel",
        onClick:(item, callback) => this.cancelItem(item, callback),
        confirmation:{
          title:"sentence.custom.cancelItem",
          message:"sentence.custom.cancelItemConfirmation"
        }
      }

      actions.push(cancelAction)
    }

    return actions
  }

  async detailFileTransaksi() {
    let params = this.props.match.params
    let companyId = params.companyId
    let subscriptionId = params.subscriptionId

    this.props.history.push(`/company/${companyId}/${subscriptionId}/init/EBILLING/paymentNotification/${this.props.match.params.id}/orderFile`);
  }

  async refreshInvoice() {
    let invoiceId = this.props.match.params.id

    let res = await this.service.api.show(invoiceId)

    this.setState({invoice: res});
  }

  async componentDidMount() {
    this.rootPath = this.props.match.url;

    try {
      await this.refreshInvoice()
    } catch (e){
      iziToast.warning({
        title: e.name,
        message: e.message
      })
    }
  }

  async refreshData() {
    await this.refreshInvoice()

    await this.fetchData()
  }

  confirmOrder = async () => {
    let dialog = await this.context.showDialog((props, res, rej) =>({
      title: 'Konfirmasi Order',
      okText: 'Order',
      width: 600,
      text: 'Apakah anda yakin ingin melanjutkan transaksi?',
    }));

    if (dialog) {
      try {
        let invoice = this.state.invoice

        await this.service.api.confirmOrder(invoice.id)

        await this.refreshData()

        iziToast.success({
          title: 'Sukses',
          message: 'Transaksi sukses dikonfirmasi.'
        })
      } catch (e) {
        iziToast.warning({
          title: e.name,
          message: e.message
        })
      }
    }
  }

  showItem = async (item) => {
    let res = await this.buktiPenerimaanService.api.findByMasterEtax(item.id)

    if(res.buktiPenerimaan) {
      this.props.history.push('/EBILLING/'+this.service.name+"/"+item.id+"/bpn");
    } else {
      this.props.history.push('/EBILLING/'+this.service.name+"/"+item.id);
    }
  }

  cancelItem = async (item, callback) => {
    try {
      await this.service.api.cancel(item.id)

      this.fetchData()

      iziToast.success({
        title: 'Sukses',
        message: 'SSP sukses dibatalkan.'
      })
    } catch (e) {
      iziToast.warning({
        title: e.name,
        message: e.message
      })
    }

    callback()
  }

  cancelChecked = async () => {
    let selected = this.props.table.selected

    let id = []

    selected.forEach(item => {
      id.push(item.id)
    })

    let data = { id }

    if(id.length > 0) {
      try {
        await this.service.api.cancelChecked(data)

        this.fetchData()

        iziToast.success({
          title: 'Sukses',
          message: 'SSP yang dipilih sukses dibatalkan.'
        })
      } catch (e) {
        iziToast.warning({
          title: e.name,
          message: e.message
        })
      }
    } else {
      iziToast.warning({
        title: 'Gagal',
        message: 'Anda harus menceklis minimal satu data.'
      })
    }
  }

  print = async () => {
    let dialog = await this.context.showDialog((props, res, rej) =>({
      title: 'Cetak',
      okText: 'Cetak',
      width: 600,
      text: 'Apakah anda yakin ingin cetak transaksi?',
    }));

    if (dialog) {
      try {
        let invoice = this.state.invoice

        let report = await this.service.api.print(invoice.id)
        let filename = report.headers.filename

        download(report.data, filename);
      } catch (e) {
        iziToast.warning({
          title: e.name,
          message: e.message
        })
      }
    }
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Kode SSP'
          name='code__contains'
          className="md-cell md-cell--6"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='ID Billing'
          name='idBilling__contains'
          className="md-cell md-cell--6"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NPWP'
          name='npwp__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        {/*<this.ig.Field
          label='Nama'
          name='name__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />*/}
        <this.ig.Field
          label='Jenis Pajak'
          name='jpCode__contains'
          className="md-cell md-cell--6"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Jenis Setoran'
          name='jsCode__contains'
          className="md-cell md-cell--6"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Bulan 1'
          name='bulan1__contains'
          className="md-cell md-cell--6"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Bulan 2'
          name='bulan2__contains'
          className="md-cell md-cell--6"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Tahun Pajak'
          name='year__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
      </this.SearchContainer>
    )
  }

  // right bar
  tabWidth = 280
  tabs= ["detail", "search", "column"]

  tabComponents() {
    var obj = this;
    return {
      detail: this.detail.bind(obj)
    }
  }

  detail() {
    const { handleSubmit, submitting, valid, pristine } = this.props;

    let { orderNo, status, createdDate, active, amountTotal } = this.state.invoice

    createdDate = moment(createdDate).format('YYYY-MM-DD HH:mm')
    active = moment(active).format('YYYY-MM-DD HH:mm')

    let paymentAccount = this.state.invoice.paymentAccount

    let bankName = ''
    let paymentAccountName = ''

    if(paymentAccount) {
      bankName = paymentAccount.orderType.name

      paymentAccountName = paymentAccount.name
    }

    return (
      <div className='mpk-layout column fill'>
        <div className='flex'>
          <List className="">
            <ListItem
              // leftAvatar={<Avatar  suffix="green" icon={<FontIcon>done</FontIcon>} />}
              primaryText="No. Transaksi"
              secondaryText={orderNo}
            />
            <ListItem
              primaryText="Status"
              secondaryText={status}
            />
            <ListItem
              primaryText="Tanggal Dibuat"
              secondaryText={createdDate}
            />
            <ListItem
              primaryText="Aktif S.d"
              secondaryText={active}
            />

            <Subheader primaryText="Tujuan Pembayaran" />
            <ListItem
              primaryText="Order Type"
              secondaryText={bankName}
            />
            <ListItem
              primaryText="Nama"
              secondaryText={paymentAccountName}
            />
             <ListItem
              primaryText="Total"
              secondaryText={UtilService.number.format(amountTotal, {precision: 0, thousand: '.', decimal: ','})}
            />
          </List>
        </div>
      </div>
    )
  }

}
