import React, { Component } from 'react';
import { connect } from 'react-redux';

import FileLapor21Service from './FileLapor21.service';
import ListView from '../../../../../components/entity/listView';
import ColumnService from '../../../../../services/column.service';
import TextField, { Button, Divider, Chip, Card, CardTitle, CardActions, DataTable, TableHeader, TableBody, TableRow, TableColumn, Switch, Paper } from 'react-md';
import {validation, Searchfield, TextfieldMask, Datepicker} from '../../../../../components/form';
import { Field, reduxForm } from 'redux-form';
import download from 'downloadjs';
import uuid from 'uuid';
import iziToast from 'izitoast';

@reduxForm({form: 'FILE_LAPOR_21', destroyOnUnmount: true})
@connect((state)=> ({
  ...FileLapor21Service.stateConnectSetting()(state),
  spt: state.authEppt.currentSPT
}), FileLapor21Service.actionConnectSetting())
export default class FileLapor21View extends ListView {
  service=FileLapor21Service

  constructor(props){
    super(props);
    this.state = {
      showTableSetting:false,
      showDialogConfirmDeleteSelected:false,
      showForm:false,
      formData: {},
      data: [],
      cetak: false
    }
  }

  columns=[]
  _barActions=[]
  _barItem() {}

  async componentDidMount(){
    var sptId = this.props.spt.data.id
    var res = await this.service.api.getSummary(sptId);
    this.setState({formData : res.data});
    this.props.initialize(res.data)
  }

  async handleCetak(){
    this.setState({ cetak: true })

    var sptId = this.props.spt.data.id
    var report = await this.service.api.cetak(sptId)

    if(report) {
      try {
        var filename = report.headers.filename;
        download(report.data, filename);
        this.setState({ cetak: false })
      } catch (e){
        this.setState({ cetak: false })
        iziToast.warning({"title": e.name, "message": e.message})
      }
    }

  }

  _barItem() {
    return (
      <div>
        {/* <Button disabled={this.state.cetak} primary raised style={{margin: 5}} onClick={this.handleCetak.bind(this)}>Unduh File Lapor</Button> */}
      </div>
    )
  }

  _tableView(props) {
      console.log(this.state.formData)
      return (
        <Card className="flex mpk-layout">
            <CardTitle title='' style={{padding: '10px 16px'}} subtitle='Informasi File Lapor'/>
            <Divider/>
            <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
              <TableHeader>
                <TableRow>
                  <TableColumn></TableColumn>
                  <TableColumn>PPh Terutang</TableColumn>
                  <TableColumn>SSP/Pbk Disetor</TableColumn>
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow>
                  <TableColumn>
                    PPh Tidak Final (KJS : 100)
                  </TableColumn>
                  <TableColumn>
                    { ColumnService.money(this.state.formData.pphTF100)  }
                  </TableColumn>
                  <TableColumn>
                    { ColumnService.money(this.state.formData.sspTF100)  }
                  </TableColumn>
                </TableRow>
                <TableRow>
                  <TableColumn>
                    PPh Tidak Final (KJS : 104)
                  </TableColumn>
                  <TableColumn>
                    { ColumnService.money(this.state.formData.pphTF104)  }
                  </TableColumn>
                  <TableColumn>
                    { ColumnService.money(this.state.formData.sspTF104)  }
                  </TableColumn>
                </TableRow>
                <TableRow>
                  <TableColumn>
                    PPh Final (KJS : 401)
                  </TableColumn>
                  <TableColumn>
                    { ColumnService.money(this.state.formData.pphF401)  }
                  </TableColumn>
                  <TableColumn>
                    { ColumnService.money(this.state.formData.sspF401)  }
                  </TableColumn>
                </TableRow>
                <TableRow>
                  <TableColumn>
                    PPh Final (KJS : 402)
                  </TableColumn>
                  <TableColumn>
                    { ColumnService.money(this.state.formData.pphF402)  }
                  </TableColumn>
                  <TableColumn>
                    { ColumnService.money(this.state.formData.sspF402)  }
                  </TableColumn>
                </TableRow>
                <TableRow>
                  <TableColumn>
                    Total
                  </TableColumn>
                  <TableColumn>
                    { ColumnService.money(this.state.formData.totalTerutang)  }
                  </TableColumn>
                  <TableColumn>
                    { ColumnService.money(this.state.formData.totalSetor)  }
                  </TableColumn>
                </TableRow>
              </TableBody>
            </DataTable>
        </Card>
      )
  }
}
