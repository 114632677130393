import React, { Component } from 'react';
import { Grid, Cell } from 'react-md';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import { validation, Textfield, TextfieldMask, Datepicker, convert } from '../../../../../components/form';
import DialogView from '../../../../../components/entity/dialog.view';
import Master21HonorPnsGlobalService from './Master21HonorPnsGlobal.service';

@reduxForm({form: 'master-21-honorPnsGlobal', destroyOnUnmount: false})
export default class Master21HonorPnsGlobalDialogView extends DialogView {
  service=Master21HonorPnsGlobalService
  initialData = {
  }

  formView() {
    return (
      <Grid style={{padding:'none'}}>
        <Field
          label='Start'
          name='startDate'
          className="md-cell md-cell--6"
          component={Datepicker}
          inline={true}
          validate={validation.required}
        />
        <Field
          label='End'
          name='endDate'
          className="md-cell md-cell--6"
          component={Datepicker}
          inline={true}
          validate={validation.required}
        />
        <Field
          label='Gol 1'
          name='gol1'
          className="md-cell md-cell--6"
          component={TextfieldMask}
          normalize={convert.money}
          // validate={validation.required}
        />
        <Field
          label='Gol 2'
          name='gol2'
          className="md-cell md-cell--6"
          component={TextfieldMask}
          normalize={convert.money}
          // validate={validation.required}
        />
        <Field
          label='Gol 3'
          name='gol3'
          className="md-cell md-cell--6"
          component={TextfieldMask}
          normalize={convert.money}
          // validate={validation.required}
        />
        <Field
          label='Gol 4'
          name='gol4'
          className="md-cell md-cell--6"
          component={TextfieldMask}
          normalize={convert.money}
          // validate={validation.required}
        />
      </Grid>
    )
  }
}
