import ApiService from "../../../../../../services/api.service";

class Tarif22ervice extends ApiService {
  name = 'Master22Tarif';
  path = 'spt22/tarif';

  constructor() {
    super();
    this.init()
  }

  api = {
    byCompany: async (compId, data) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${compId}/check`, {
        params: data
      });
      return res.data;
    },
    getMaster: async () => {
      var res = await this.http.get(`${this.apiLocation}/spt22/master/tarif-22`);
      return res.data;
    }
  }
}


export default new Tarif22ervice();
