import React from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  Tab,
  Tabs,
  SelectionControlGroup,
  Button
} from 'react-md';
import download from 'downloadjs';
import uuid from 'uuid';
import izitoast from 'izitoast';

import FormView from '../../../../../components/entity/form.view';
import {validation, Textfield, Searchfield, Switch, Checkbox, Datepicker, TextfieldMask} from '../../../../../components/form';
import columnService from '../../../../../services/column.service';
import terbilangService from '../../../../../services/terbilang.service';
import Spt4a2IndukService from './Spt4a2Induk.service';

import BlokBunifikasiService from 'app:modules/Main/pph/21_26/BlokBunifikasi/BlokBunifikasi.service';


@reduxForm({form: 'SPT_INDUK_4a2_Form', destroyOnUnmount: true, initialValues: {jasaLains: []}})
@connect((state) => ({
  access      : state.entity.access.api.find,
  organization: state.authEppt.currentOrganization,
  spt         : state.authEppt.currentSPT,
  formData    : getFormValues('SPT_INDUK_4a2_Form')(state),
  auth: state.auth.company,
}))
export default class Spt4a2IndukForm extends FormView {
  service  = Spt4a2IndukService
  viewType = 2;

  initialData={
    bagB1: {},
    bagB2: {},
    bagC : {},
  }

  constructor(props){
    super(props);
    this.state = {
      ...this.state,
      cetak    : false,
      calculate: false,

      signer: {},
    }
  }

  async beforeSave(value) {
    value.blockBunifikasiMonth = 4;
    value.blockBunifikasiYear = 2022;
    try {
      let res = await BlokBunifikasiService.api.getSettingAccess(this.props.match.params.companyId)
      if(res.data.active){
        value.blockBunifikasiMonth = res.data.bulan
        value.blockBunifikasiYear = res.data.tahun
      }
    } catch(e) {}

    return value;
  }

  async handleSave(value) {
    try {
      this.setState({onProgress: true})

      // Remapping Objek
      var data = value
      var mapping = {
        id                : data.id ? data.id : null,
        spt               : this.props.spt.data,
        cuttingDate       : data.tanggal ? data.tanggal : new Date(),
        gross             : data.totalBruto ? data.totalBruto : 0,
        pph               : data.totalPPh ? data.totalPPh : 0,
        terbilang         : data.words ? data.words : '',
        checkSsp          : data.bagC  ? data.bagC.checkSsp : false,
        countSsp          : data.bagC ? data.bagC.lembarSsp : 0,
        checkDaftar       : data.bagC ? data.bagC.checkDaftarBp : false,
        checkBp           : data.bagC ? data.bagC.checkBp : false,
        countBp           : data.bagC ? data.bagC.lembarBp : 0,
        checkSuratKuasaKhusus   : data.bagC ? data.bagC.checkSuratKuasa : false,
        type                : data.bagD ? data.bagD.kuasa : false,
        signNpwp            : data.bagD ? data.bagD.npwp : '',
        signName            : data.bagD ? data.bagD.nama : '', 
        b1a1Gross           : data.bagB1.bungaDepositoDalamNegeri.bruto,
        b1a1Rates           : data.bagB1.bungaDepositoDalamNegeri.tarif,
        b1a1Pph             : data.bagB1.bungaDepositoDalamNegeri.pph,
        b1a2Gross           : data.bagB1.bungaDepositoLuarNegeri.bruto,
        b1a2Rates           : data.bagB1.bungaDepositoLuarNegeri.tarif,
        b1a2Pph             : data.bagB1.bungaDepositoLuarNegeri.pph,
        b1bGross            : data.bagB1.diskontoSertifikat.bruto,
        b1bPph              : data.bagB1.diskontoSertifikat.pph,
        b1cGross            : data.bagB1.jasaGiro.bruto,
        b1cRates            : data.bagB1.jasaGiro.tarif,
        b1cPph              : data.bagB1.jasaGiro.pph,
        b2aGross            : data.bagB1.transaksiPenjualanSahamSendiri.bruto,
        b2aRates            : data.bagB1.transaksiPenjualanSahamSendiri.tarif,
        b2aPph              : data.bagB1.transaksiPenjualanSahamSendiri.pph,
        b2bGross            : data.bagB1.transaksiPenjualanSahamBukanSahamSendiri.bruto,
        b2bRates            : data.bagB1.transaksiPenjualanSahamBukanSahamSendiri.tarif,
        b2bPph              : data.bagB1.transaksiPenjualanSahamBukanSahamSendiri.pph,
        b3Gross             : data.bagB1.bungaObligasi.bruto,
        b3Rates             : data.bagB1.bungaObligasi.tarif,
        b3Pph               : data.bagB1.bungaObligasi.pph,
        b4Gross             : data.bagB1.hadiahUndian.bruto,
        b4Rates             : data.bagB1.hadiahUndian.tarif,
        b4Pph               : data.bagB1.hadiahUndian.pph,
        b5aGross            : data.bagB1.persewaanTanahPemotongPajak.bruto,
        b5aRates            : data.bagB1.persewaanTanahPemotongPajak.tarif,
        b5aPph              : data.bagB1.persewaanTanahPemotongPajak.pph,
        b5bGross            : data.bagB1.persewaanTanahSendiri.bruto,
        b5bRates            : data.bagB1.persewaanTanahSendiri.tarif,
        b5bPph              : data.bagB1.persewaanTanahSendiri.pph,
        b6a1Gross           : data.bagB2.jkPerencanaKonstruksiPemotongPph.bruto,
        b6a1Rates           : data.bagB2.jkPerencanaKonstruksiPemotongPph.tarif,
        b6a1Pph             : data.bagB2.jkPerencanaKonstruksiPemotongPph.pph,
        b6a2Gross           : data.bagB2.jkPerencanaKonstruksiSendiriPph.bruto,
        b6a2Rates           : data.bagB2.jkPerencanaKonstruksiSendiriPph.tarif,
        b6a2Pph             : data.bagB2.jkPerencanaKonstruksiSendiriPph.pph,
        b6b1Gross           : data.bagB2.jkPelaksanaKonstruksiPemotongPph.bruto,
        b6b1Rates           : data.bagB2.jkPelaksanaKonstruksiPemotongPph.tarif,
        b6b1Pph             : data.bagB2.jkPelaksanaKonstruksiPemotongPph.pph,
        b6b2Gross           : data.bagB2.jkPelaksanaKonstruksiSendiriPph.bruto,
        b6b2Rates           : data.bagB2.jkPelaksanaKonstruksiSendiriPph.tarif,
        b6b2Pph             : data.bagB2.jkPelaksanaKonstruksiSendiriPph.pph,
        b6c1Gross           : data.bagB2.jkPengawasKonstruksiPemotongPph.bruto,
        b6c1Rates           : data.bagB2.jkPengawasKonstruksiPemotongPph.tarif,
        b6c1Pph             : data.bagB2.jkPengawasKonstruksiPemotongPph.pph,
        b6c2Gross           : data.bagB2.jkPengawasKonstruksiSendiriPph.bruto,
        b6c2Rates           : data.bagB2.jkPengawasKonstruksiSendiriPph.tarif,
        b6c2Pph             : data.bagB2.jkPengawasKonstruksiSendiriPph.pph,
        b7Gross             : data.bagB2.wpPengalihanBangunan.bruto,
        b7Rates             : data.bagB2.wpPengalihanBangunan.tarif,
        b7Pph               : data.bagB2.wpPengalihanBangunan.pph,
        b8Gross             : data.bagB2.bungaSimpananKoperasi.bruto,
        b8Rates             : data.bagB2.bungaSimpananKoperasi.tarif,
        b8Pph               : data.bagB2.bungaSimpananKoperasi.pph,
        b9Gross             : data.bagB2.transaksiDerivatif.bruto,
        b9Rates             : data.bagB2.transaksiDerivatif.tarif,
        b9Pph               : data.bagB2.transaksiDerivatif.pph,
        b10Gross            : data.bagB2.dividenDiterima.bruto,
        b10Rates            : data.bagB2.dividenDiterima.tarif,
        b10Pph              : data.bagB2.dividenDiterima.pph,
        b11aKapKjs          : data.bagB2.penghasilanLainA.kapKjs,
        b11aType            : data.bagB2.penghasilanLainA.jenisPenghasilan,
        b11aGross           : data.bagB2.penghasilanLainA.bruto,
        b11aRates           : parseFloat(data.bagB2.penghasilanLainA.tarif),
        b11aPph             : data.bagB2.penghasilanLainA.pph,
        b11bKapKjs          : data.bagB2.penghasilanLainB.kapKjs,
        b11bType            : data.bagB2.penghasilanLainB.jenisPenghasilan,
        b11bGross           : data.bagB2.penghasilanLainB.bruto,
        b11bRates           : parseFloat(data.bagB2.penghasilanLainB.tarif),
        b11bPph             : data.bagB2.penghasilanLainB.pph,
        b11cKapKjs          : data.bagB2.penghasilanLainC.kapKjs,
        b11cType            : data.bagB2.penghasilanLainC.jenisPenghasilan,
        b11cGross           : data.bagB2.penghasilanLainC.bruto,
        b11cRates           : parseFloat(data.bagB2.penghasilanLainC.tarif),
        b11cPph             : data.bagB2.penghasilanLainC.pph,
      }
      value = mapping
      value.blockBunifikasiMonth = 4;
      value.blockBunifikasiYear = 2022;
      try {
        let res = await BlokBunifikasiService.api.getSettingAccess(this.props.match.params.companyId)
        if(res.data.active){
          value.blockBunifikasiMonth = res.data.bulan
          value.blockBunifikasiYear = res.data.tahun
        }
      } catch(e) {}
      // Remapping Objek
      var res                            = await this.service.api.update(value)
      this.setState({onProgress: false})
      izitoast.success({title: 'Success', message: 'Data berhasil disimpan.'})

      if  (!res.data.bagD) res.data.bagD = {}
      // signer
      let signer = null;
      try {
        signer = await this.service.api.getSigner({
          orgId: this.props.spt.data.organization.id,
          tgl  : res.data.tanggal
        });
      } catch (error) {

      }
      if(signer) {
        this.setState({signer: signer})
        if(res.data.bagD.kuasa) {
          res.data.bagD.npwp = signer.npwpKuasa;
          res.data.bagD.nama = signer.namaKuasa;
        } else {
          res.data.bagD.npwp = signer.npwpPenandaTanganSpt;
          res.data.bagD.nama = signer.namaPenandaTanganSpt;
        }
      }

      this.props.initialize(res.data);
    } catch(e) {
      var msg              = e.message;
      if  (e.response) msg = e.response.data.message;
      this.setState({
        isError     : true,
        onProgress  : false,
        errorMessage: msg
      })
    }
  }

  async initData() {
    let res = await this.service.api.getBySptId(this.props.spt.data.id);

    if(!res.data.bagD) res.data.bagD = {}

    // signer
    let signer = null;
    try {
      signer = await this.service.api.getSigner(this.props.organization.npwp);
    } catch (error) {
      console.log(error, "Signer Error")
    }
    if(signer) {
      this.setState({signer: signer})
      // if(res.data.bagD.kuasa) {
      //   res.data.bagD.npwp = signer.npwpKuasa;
      //   res.data.bagD.nama = signer.namaKuasa;
      // } else {
      //   res.data.bagD.npwp = signer.npwpPenandaTanganSpt;
      //   res.data.bagD.nama = signer.namaPenandaTanganSpt;
      // }
      if(res.data.bagD.kuasa) {
          res.data.signNpwp = signer.npwpKuasa;
          res.data.signName = signer.namaKuasa;
        } else {
          res.data.signNpwp = signer.npwpPenandaTanganSpt;
          res.data.signName = signer.namaPenandaTanganSpt;
        }
    }

    // Remapping Objek
    var data = res.data
    var remapping = {
      spt               : this.props.spt.data,
      id                : data.id ? data.id : null,
      tanggal           : data.cuttingDate ? data.cuttingDate : new Date(),
      totalBruto        : data.gross ? data.gross : 0,
      totalPPh          : data.pph ? data.pph : 0,
      words             : data.terbilang ? data.terbilang : '',
      bagC: {
        checkSsp        : data.checkSsp ? data.checkSsp : false,
        lembarSsp       : data.countSsp ? data.countSsp : 0,
        checkDaftarBp   : data.checkDaftar ? data.checkDaftar : false,
        checkBp         : data.checkBp ? data.checkBp : false,
        lembarBp        : data.countBp ? data.countBp : 0,
        checkSuratKuasa : data.checkSuratKuasaKhusus ? data.checkSuratKuasaKhusus : false,
      },
      bagD: {
        kuasa           : data.type ? data.type : false,
        npwp            : data.signNpwp ? data.signNpwp : '',
        nama            : data.signName ? data.signName : '', 
      },
      bagB1: {
        bungaDepositoDalamNegeri: {
          kapKjs  : '411128/404',
          bruto   : data.b1a1Gross ? data.b1a1Gross : 0,
          tarif   : data.b1a1Rates ? data.b1a1Rates : 0,
          pph     : data.b1a1Pph   ? data.b1a1Pph   : 0,
        },
        bungaDepositoLuarNegeri: {
          kapKjs  : '411128/404',
          bruto   : data.b1a2Gross ? data.b1a2Gross : 0,
          tarif   : data.b1a2Rates ? data.b1a2Rates : 0,
          pph     : data.b1a2Pph   ? data.b1a2Pph   : 0,
        },
        diskontoSertifikat: {
          kapKjs  : '411128/404',
          bruto   : data.b1bGross ? data.b1bGross : 0,
          pph     : data.b1bPph   ? data.b1bPph   : 0,
        },
        jasaGiro: {
          kapKjs  : '411128/404',
          bruto   : data.b1cGross ? data.b1cGross : 0,
          tarif   : data.b1cRates ? data.b1cRates : 0,
          pph     : data.b1cPph   ? data.b1cPph   : 0,
        },
        transaksiPenjualanSahamSendiri: {
          kapKjs  : '411128/407',
          bruto   : data.b2aGross ? data.b2aGross : 0,
          tarif   : data.b2aRates ? data.b2aRates : 0,
          pph     : data.b2aPph   ? data.b2aPph   : 0,
        },
        transaksiPenjualanSahamBukanSahamSendiri: {
          kapKjs  : '411128/406',
          bruto   : data.b2bGross ? data.b2bGross : 0,
          tarif   : data.b2bRates ? data.b2bRates : 0,
          pph     : data.b2bPph   ? data.b2bPph   : 0,
        },
        bungaObligasi: {
          kapKjs  : '411128/401',
          bruto   : data.b3Gross ? data.b3Gross : 0,
          tarif   : data.b3Rates ? data.b3Rates : 0,
          pph     : data.b3Pph   ? data.b3Pph   : 0,
        },
        hadiahUndian: {
          kapKjs  : '411128/405',
          bruto   : data.b4Gross ? data.b4Gross : 0,
          tarif   : data.b4Rates ? data.b4Rates : 0,
          pph     : data.b4Pph   ? data.b4Pph   : 0,
        },
        persewaanTanahPemotongPajak: {
          kapKjs  : '411128/403',
          bruto   : data.b5aGross ? data.b5aGross : 0,
          tarif   : data.b5aRates ? data.b5aRates : 0,
          pph     : data.b5aPph   ? data.b5aPph   : 0,
        },
        persewaanTanahSendiri: {
          kapKjs  : '411128/403',
          bruto   : data.b5bGross ? data.b5bGross : 0,
          tarif   : data.b5bRates ? data.b5bRates : 0,
          pph     : data.b5bPph   ? data.b5bPph   : 0,
        }
      },
      bagB2: {
        jkPerencanaKonstruksiPemotongPph: {
          kapKjs  : '411128/409',
          bruto   : data.b6a1Gross ? data.b6a1Gross : 0,
          tarif   : data.b6a1Rates ? data.b6a1Rates : 0,
          pph     : data.b6a1Pph   ? data.b6a1Pph   : 0,
        },
        jkPerencanaKonstruksiSendiriPph: {
          kapKjs  : '411128/409',
          bruto   : data.b6a2Gross ? data.b6a2Gross : 0,
          tarif   : data.b6a2Rates ? data.b6a2Rates : 0,
          pph     : data.b6a2Pph   ? data.b6a2Pph   : 0,
        },
        jkPelaksanaKonstruksiPemotongPph: {
          kapKjs  : '411128/409',
          bruto   : data.b6b1Gross ? data.b6b1Gross : 0,
          tarif   : data.b6b1Rates ? data.b6b1Rates : 0,
          pph     : data.b6b1Pph   ? data.b6b1Pph   : 0,
        },
        jkPelaksanaKonstruksiSendiriPph: {
          kapKjs  : '411128/409',
          bruto   : data.b6b2Gross ? data.b6b2Gross : 0,
          tarif   : data.b6b2Rates ? data.b6b2Rates : 0,
          pph     : data.b6b2Pph   ? data.b6b2Pph   : 0,
        },
        jkPengawasKonstruksiPemotongPph: {
          kapKjs  : '411128/409',
          bruto   : data.b6c1Gross ? data.b6c1Gross : 0,
          tarif   : data.b6c1Rates ? data.b6c1Rates : 0,
          pph     : data.b6c1Pph   ? data.b6c1Pph   : 0,
        },
        jkPengawasKonstruksiSendiriPph: {
          kapKjs  : '411128/409',
          bruto   : data.b6c2Gross ? data.b6c2Gross : 0,
          tarif   : data.b6c2Rates ? data.b6c2Rates : 0,
          pph     : data.b6c2Pph   ? data.b6c2Pph   : 0,
        },
        wpPengalihanBangunan: {
          kapKjs  : '411128/402',
          bruto   : data.b7Gross ? data.b7Gross : 0,
          tarif   : data.b7Rates ? data.b7Rates : 0,
          pph     : data.b7Pph   ? data.b7Pph   : 0,
        },
        bungaSimpananKoperasi: {
          kapKjs  : '411128/417',
          bruto   : data.b8Gross ? data.b8Gross : 0,
          tarif   : data.b8Rates ? data.b8Rates : 0,
          pph     : data.b8Pph   ? data.b8Pph   : 0,
        },
        transaksiDerivatif: {
          kapKjs  : '411128/418',
          bruto   : data.b9Gross ? data.b9Gross : 0,
          tarif   : data.b9Rates ? data.b9Rates : 0,
          pph     : data.b9Pph   ? data.b9Pph   : 0,
        },
        dividenDiterima: {
          kapKjs  : '411128/419',
          bruto   : data.b10Gross ? data.b10Gross : 0,
          tarif   : data.b10Rates ? data.b10Rates : 0,
          pph     : data.b10Pph   ? data.b10Pph   : 0,
        },
        penghasilanLainA: {
          kapKjs            : data.b11aKapKjs  ? data.b11aKapKjs  : '',
          jenisPenghasilan  : data.b11aType  ? data.b11aType : '',
          bruto             : data.b11aGross ? data.b11aGross : 0,
          tarif             : data.b11aRates ? data.b11aRates : 0,
          pph               : data.b11aPph   ? data.b11aPph   : 0,
        },
        penghasilanLainB: {
          kapKjs            : data.b11bKapKjs  ? data.b11bKapKjs  : '',
          jenisPenghasilan  : data.b11bType  ? data.b11bType  : '',
          bruto             : data.b11bGross ? data.b11bGross : 0,
          tarif             : data.b11bRates ? data.b11bRates : 0,
          pph               : data.b11bPph   ? data.b11bPph   : 0,
        },
        penghasilanLainC: {
          kapKjs            : data.b11cKapKjs  ? data.b11cKapKjs  : '',
          jenisPenghasilan  : data.b11cType  ? data.b11cType : '',
          bruto             : data.b11cGross ? data.b11cGross : 0,
          tarif             : data.b11cRates ? data.b11cRates : 0,
          pph               : data.b11cPph   ? data.b11cPph   : 0,
        }
      }
    }
    // Remapping Objek
    res.data = remapping
    this.props.initialize(res.data);
  }

  async changeSigner() {
    if(this.state.signer.id) {
      let formData                       = this.initialData
      if  (this.props.formData) formData = this.props.formData;
      if(formData.bagD.kuasa) {
        this.props.change('bagD.npwp', this.state.signer.npwpKuasa);
        this.props.change('bagD.nama', this.state.signer.namaKuasa);
      } else {
        this.props.change('bagD.npwp', this.state.signer.npwpPenandaTanganSpt);
        this.props.change('bagD.nama', this.state.signer.namaPenandaTanganSpt);
      }
    }
  }

  async calculate() {
    await this.setState({calculate: true})
    await this.props.dispatch({
      type: 'TOGGLE_GLOBAL_PROGRESS',
      data: true
    });

    const sptId = this.props.match.params.sptId

    try {
      var res = await this.service.api.calculate(sptId)

      if(res.data) {
        this.props.initialize(res.data);
      }
    } catch(e) {

    }


    this.setState({calculate: false})
    await this.props.dispatch({
      type: 'TOGGLE_GLOBAL_PROGRESS',
      data: false
    });
  }

  async handleCetak(){
    this.setState({ cetak: true })
    try {
      const sptId    = this.props.match.params.sptId
      var   report   = await this.service.api.cetak(sptId)
      var   filename = report.headers.filename;
      // download(report.data, filename);
      this.setState({ cetak: false })
      window.open(report.data.url);
    } catch (e){
      this.setState({ cetak: false })
    }
  }

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;
    let   statusSPT                                     = "";
    let   statusInSPT                                   = false;
    if(this.props.spt){
        if(this.props.spt.data){
            if(this.props.spt.data.status){
                statusSPT   = this.props.spt.data.status;
                statusInSPT = true
            }
        }
    }

    if(statusSPT == "FINISH" || statusSPT == "WAITING"){
        return (
            <div>
              <Button disabled={this.state.cetak} primary raised style={{marginRight: 5}} onClick={this.handleCetak.bind(this)}>Cetak</Button>
            </div>
        )
    } else {
        if(statusInSPT){
            return (
              <div>
                <Button disabled={this.state.cetak} primary raised style={{marginRight: 5}} onClick={this.handleCetak.bind(this)}>Cetak</Button>
                {/* <Button disabled={this.state.calculate} primary raised style={{marginRight: 5}} onClick={this.calculate.bind(this)}>calculate</Button> */}
                <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Save and Calculate</Button>
              </div>
            )
        } else {
            return (
                <div>
                    {/* <Button disabled={this.state.calculate} primary raised style={{marginRight: 5}} onClick={this.calculate.bind(this)}>calculate</Button> */}
                    <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>Back</Button>
                </div>
            )
        }
    }
  }

  async calculateTotal(e,v,pv,f) {
    var form = this.props.formData;
    var d    = form.bagB23;

    await this.props.change(f, v);

    var jmlPph   = 0;
    var jmlBruto = 0;
    Object.keys(d).forEach((k, i) => {
      if(!(k == 'jmlBruto' || k == 'jmlPph' || k == 'terbilang' || k == 'createdBy' || k == 'createdDate' || k == 'id' || k == 'lastModifiedBy' || k == 'lastModifiedDate')) {
        var x = d[k];

        var pph            = x.pph;
        var bruto          = x.bruto;
        if  (!bruto) bruto = 0;
        if  (!pph) pph     = 0;

        jmlPph   = jmlPph+ parseFloat(pph);
        jmlBruto = jmlBruto+ parseFloat(bruto)
      }
    })

    this.props.change('bagB23.jmlBruto', jmlBruto)
    this.props.change('bagB23.jmlPph', jmlPph);

    this.props.change('bagB23.terbilang', terbilangService(parseInt(jmlPph)));
  }

  async calculatePph(e,v,pv,f, parent) {
    var form = this.props.formData;
    var d    = form.bagB2[parent];

    var bruto = parseFloat(d.bruto);
    var tarif = parseFloat(d.tarif);

    if(f.search('bruto') >= 0) {
      bruto = parseFloat(v)
    } else if(f.search('tarif') >= 0) {
      tarif = parseFloat(v)
    }

    if(!bruto) bruto = 0;
    if(!tarif) tarif = 0;

    await this.props.change(`bagB2.${parent}.pph`, bruto*tarif/100);
    this.calculateTotal()
  }

  formView() {
    let formData                                                     = this.initialData
    if  (this.props.formData && this.props.formData.values) formData = this.props.formData.values

    var masaOptions = [
      {id:1, name: 1}, {id:2, name: 2}, {id:3, name: 3}, {id:4, name: 4}, {id:5, name: 5}, {id:6, name: 6},
      {id:7, name: 7}, {id:8, name: 8}, {id:9, name: 9}, {id:10, name: 10}, {id:11, name: 11}, {id:12, name: 12}
    ]
    return (
      <div>
        <Card>
          {/* <CardTitle title='' style={{padding: '10px 16px'}} subtitle='A. IDENTITAS PEMOTONG' />
          <Divider/> */}

          <div className='md-grid'>
            <Field
              label        = 'Tanggal Lapor'
              className    = "md-cell md-cell--12"
              name         = 'tanggal'
              component    = {Datepicker}
              validate     = {validation.required}
              onDateChange = { async (d)=> {
                let signer = null;

                try {
                  signer = await this.service.api.getSigner({
                    orgId: this.props.spt.data.organization.id,
                    tgl  : moment(d).format("YYYY-MM-DD")
                  })

                  await this.setState({signer: signer})
                } catch (error) {
                }

                await this.changeSigner()
              }}
              />
          </div>
        </Card>
        <br/>

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='PPH PASAL 4 (2) YANG TELAH DIPOTONG' />
          <Divider/>
          <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
            <TableHeader>
              <TableRow>
                <TableColumn>No.</TableColumn>
                <TableColumn>Jenis Penghasilan</TableColumn>
                <TableColumn>KAP/KJS</TableColumn>
                <TableColumn className='md-text-right'>Jumlah Penghasilan Bruto(Rp)</TableColumn>
                <TableColumn className='md-text-right'>Tarif</TableColumn>
                <TableColumn className='md-text-right'>PPH Di Potong(Rp)</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableColumn>
                  1.
                </TableColumn>
                <TableColumn>Bunga Deposito/Tabungan, Diskonto SBI dan Jasa Giro: </TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                </TableColumn>
                <TableColumn>a. Bunga Deposito/Tabungan</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>&nbsp;&nbsp;&nbsp;&nbsp;1) Yang ditempatkan di Dalam negeri</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB1.bungaDepositoDalamNegeri.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB1.bungaDepositoDalamNegeri.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB1.bungaDepositoDalamNegeri.tarif'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB1.bungaDepositoDalamNegeri.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>&nbsp;&nbsp;&nbsp;&nbsp; 2) Yang ditempatkan di Luar negeri</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB1.bungaDepositoLuarNegeri.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB1.bungaDepositoLuarNegeri.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB1.bungaDepositoLuarNegeri.tarif'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB1.bungaDepositoLuarNegeri.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>b. Diskonto Sertifikat Bank Indonesia</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB1.diskontoSertifikat.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB1.diskontoSertifikat.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB1.diskontoSertifikat.tarif'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB1.diskontoSertifikat.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>c. Jasa Giro</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB1.jasaGiro.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB1.jasaGiro.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB1.jasaGiro.tarif'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB1.jasaGiro.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>2</TableColumn>
                <TableColumn>Transaksi Penjualan Saham</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>a. Saham Pendiri</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB1.transaksiPenjualanSahamSendiri.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB1.transaksiPenjualanSahamSendiri.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB1.transaksiPenjualanSahamSendiri.tarif'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB1.transaksiPenjualanSahamSendiri.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>b. Bukan Saham Pendiri</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB1.transaksiPenjualanSahamBukanSahamSendiri.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB1.transaksiPenjualanSahamBukanSahamSendiri.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB1.transaksiPenjualanSahamBukanSahamSendiri.tarif'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB1.transaksiPenjualanSahamBukanSahamSendiri.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>3</TableColumn>
                <TableColumn>Bunga/Diskonto Obligasi dan Surat Berharga Negara</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB1.bungaObligasi.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB1.bungaObligasi.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB1.bungaObligasi.tarif'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB1.bungaObligasi.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>4</TableColumn>
                <TableColumn>Hadiah Undian</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB1.hadiahUndian.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB1.hadiahUndian.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB1.hadiahUndian.tarif'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB1.hadiahUndian.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>5</TableColumn>
                <TableColumn>Persewaan Tanah dan/atau Bangunan</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
              </TableRow>


              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>a. Penyewa sebagai Pemotong Pajak</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB1.persewaanTanahPemotongPajak.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB1.persewaanTanahPemotongPajak.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB1.persewaanTanahPemotongPajak.tarif'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB1.persewaanTanahPemotongPajak.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>b. Orang Pribadi/Badan yang Menyetor Sendiri PPh</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB1.persewaanTanahSendiri.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB1.persewaanTanahSendiri.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB1.persewaanTanahSendiri.tarif'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB1.persewaanTanahSendiri.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>6</TableColumn>
                <TableColumn>Jasa Konstruksi</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>a. Perencana Konstruksi</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>&nbsp;&nbsp;&nbsp;&nbsp;1) Pengguna Jasa sebagai Pemotong PPh</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB2.jkPerencanaKonstruksiPemotongPph.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB2.jkPerencanaKonstruksiPemotongPph.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.jkPerencanaKonstruksiPemotongPph.tarif'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.jkPerencanaKonstruksiPemotongPph.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>&nbsp;&nbsp;&nbsp;&nbsp; 2) Penyedia Jasa yang Menyetor Sendiri PPh	</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB2.jkPerencanaKonstruksiSendiriPph.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB2.jkPerencanaKonstruksiSendiriPph.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.jkPerencanaKonstruksiSendiriPph.tarif'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.jkPerencanaKonstruksiSendiriPph.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>b. Pelaksana Konstruksi</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>&nbsp;&nbsp;&nbsp;&nbsp; 1) Pengguna Jasa sebagai Pemotong PPh</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB2.jkPelaksanaKonstruksiPemotongPph.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB2.jkPelaksanaKonstruksiPemotongPph.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.jkPelaksanaKonstruksiPemotongPph.tarif'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.jkPelaksanaKonstruksiPemotongPph.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>&nbsp;&nbsp;&nbsp;&nbsp;2) Penyedia Jasa yang Menyetor Sendiri PPh</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB2.jkPelaksanaKonstruksiSendiriPph.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB2.jkPelaksanaKonstruksiSendiriPph.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.jkPelaksanaKonstruksiSendiriPph.tarif'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.jkPelaksanaKonstruksiSendiriPph.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>c. Pengawas Konstruksi</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>&nbsp;&nbsp;&nbsp;&nbsp;1) Pengguna Jasa sebagai Pemotong PPh</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB2.jkPengawasKonstruksiPemotongPph.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB2.jkPengawasKonstruksiPemotongPph.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.jkPengawasKonstruksiPemotongPph.tarif'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.jkPengawasKonstruksiPemotongPph.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>&nbsp;&nbsp;&nbsp;&nbsp; 2) Penyedia Jasa yang Menyetor Sendiri PPh</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB2.jkPengawasKonstruksiSendiriPph.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB2.jkPengawasKonstruksiSendiriPph.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.jkPengawasKonstruksiSendiriPph.tarif'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.jkPengawasKonstruksiSendiriPph.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>7</TableColumn>
                <TableColumn>Wajib Pajak yang melakukan pengalihan Hak atas Tanah/Bangunan</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB2.wpPengalihanBangunan.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB2.wpPengalihanBangunan.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.wpPengalihanBangunan.tarif'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.wpPengalihanBangunan.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>8</TableColumn>
                <TableColumn>Bunga Simpanan yang dibayarkan oleh Koperasi kepada Anggota Wajib Pajak Orang Pribadi</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB2.bungaSimpananKoperasi.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB2.bungaSimpananKoperasi.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.bungaSimpananKoperasi.tarif'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.bungaSimpananKoperasi.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>9</TableColumn>
                <TableColumn>Transaksi Derivatif berupa kontrak berjangka yang diperdagangkan di Bursa</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB2.transaksiDerivatif.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB2.transaksiDerivatif.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.transaksiDerivatif.tarif'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.transaksiDerivatif.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>10</TableColumn>
                <TableColumn>Dividen yang Diterima/Diperoleh Wajib Pajak Orang Pribadi Dalam Negeri</TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB2.dividenDiterima.kapKjs'
                    disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB2.dividenDiterima.bruto'
                    disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.dividenDiterima.tarif'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.dividenDiterima.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>11</TableColumn>
                <TableColumn>Penghasilan tertentu lainnya</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>a <Field
                    // label='Tarif'
                    name = 'bagB2.penghasilanLainA.jenisPenghasilan'
                    // disabled
                    component = {Textfield}
                  /></TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB2.penghasilanLainA.kapKjs'
                    // disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB2.penghasilanLainA.bruto'
                    // disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                    onChange  = {(e, v, vp, f) => this.calculatePph(e, v, vp, f, 'penghasilanLainA')}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.penghasilanLainA.tarif'
                    className = 'md-text-right'
                    // disabled
                    component = {TextfieldMask}
                    money     = {','}
                    onChange  = {(e, v, vp, f) => this.calculatePph(e, v, vp, f, 'penghasilanLainA')}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.penghasilanLainA.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>b <Field
                    // label='Tarif'
                    name = 'bagB2.penghasilanLainB.jenisPenghasilan'
                    // disabled
                    component = {Textfield}
                  /></TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB2.penghasilanLainB.kapKjs'
                    // disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB2.penghasilanLainB.bruto'
                    // disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                    onChange  = {(e, v, vp, f) => this.calculatePph(e, v, vp, f, 'penghasilanLainB')}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.penghasilanLainB.tarif'
                    className = 'md-text-right'
                    // disabled
                    component = {TextfieldMask}
                    money     = {','}
                    onChange  = {(e, v, vp, f) => this.calculatePph(e, v, vp, f, 'penghasilanLainB')}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.penghasilanLainB.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>c <Field
                    // label='Tarif'
                    name = 'bagB2.penghasilanLainC.jenisPenghasilan'
                    // disabled
                    component = {Textfield}
                  /></TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name = 'bagB2.penghasilanLainC.kapKjs'
                    // disabled
                    component = {Textfield}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name = 'bagB2.penghasilanLainC.bruto'
                    // disabled
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                    onChange  = {(e, v, vp, f) => this.calculatePph(e, v, vp, f, 'penghasilanLainC')}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.penghasilanLainC.tarif'
                    className = 'md-text-right'
                    // disabled
                    component = {TextfieldMask}
                    money     = {','}
                    onChange  = {(e, v, vp, f) => this.calculatePph(e, v, vp, f, 'penghasilanLainC')}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB2.penghasilanLainC.pph'
                    className = 'md-text-right'
                    disabled
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow style={{background: '#ddd'}}>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  Jumlah
                </TableColumn>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'totalBruto'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    disabled
                    money = {','}
                  />
                </TableColumn>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'totalPPh'
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    disabled
                    money = {','}
                  />
                </TableColumn>
              </TableRow>



              <TableRow style={{background: '#ddd'}}>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  Terbilang
                </TableColumn>
                <TableColumn colSpan={4}>
                  <Field
                    // label='Bruto'
                    name      = 'words'
                    component = {Textfield}
                    disabled
                    money = {','}
                  />
                </TableColumn>
              </TableRow>

            </TableBody>

          </DataTable>
        </Card>

        <br/>


         <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='LAMPIRAN' />
          <Divider/>
          <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
            <TableHeader>
              <TableRow>
                <TableColumn>Cek</TableColumn>
                <TableColumn>Keterangan</TableColumn>
                <TableColumn>Lembar</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableColumn>
                  <Field
                    name      = 'bagC.checkSsp'
                    component = {Checkbox}
                  />
                </TableColumn>

                <TableColumn>
                  Surat Setoran Pajak
                </TableColumn>
                <TableColumn>
                  <Field
                    name      = 'bagC.lembarSsp'
                    component = {Textfield}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  <Field
                    name      = 'bagC.checkDaftarBp'
                    component = {Checkbox}
                  />
                </TableColumn>

                <TableColumn>
                  Daftar Bukti Pemotongan PPh Final Pasal 4 ayat 2
                </TableColumn>
                <TableColumn>
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>
                  <Field
                    name      = 'bagC.checkBp'
                    component = {Checkbox}
                  />
                </TableColumn>

                <TableColumn>
                  Bukti Pemotongan/Pemungutan PPh Final Pasal 4 Ayat 2
                </TableColumn>
                <TableColumn>
                  <Field
                    name      = 'bagC.lembarBp'
                    component = {Textfield}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  <Field
                    name      = 'bagC.checkSuratKuasa'
                    component = {Checkbox}
                    onChange  = {async (ev, v)=> {
                      await this.props.change('bagD.kuasa', v);

                      await this.changeSigner()
                    }}
                  />
                </TableColumn>

                <TableColumn>
                Surat Kuasa Khusus
                </TableColumn>
                <TableColumn>
                </TableColumn>
              </TableRow>
            </TableBody>
          </DataTable>
        </Card>


        <br />

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='TANDA TANGAN PEMOTONG' />
          <Divider/>
          {/* <div className='md-grid'>
            <div className="md-cell md-cell--12">
              <p style={{textAlign: 'center'}}>Dengan menyadari sepenuhnya atas segala akibatnya termasuk sanksi-sanksi sesuai dengan ketentuan yang berlaku, saya menyatakan bahwa apa yang telah saya beritahukan di atas beserta lampiran-lampirannya adalah benar, lengkap dan jelas.</p>
            </div>
          </div> */}
          <div className='md-grid'>
            <Field
              className = "md-cell md-cell--12"
              label     = 'Jenis Penandatangan'
              name      = 'bagD.kuasa'
              component = {Searchfield}
              options   = {[
                {id: false, name: 'Pemotong'},
                {id: true, name: 'Kuasa'}
              ]}
              onChange={async (e, v)=> {
                await this.props.change('bagD.kuasa', v);

                await this.changeSigner()
              }}
              //validate = {validation.required}
            />

            <div className="md-cell md-cell--6">
              <Field
                label      = 'NPWP Pemotong'
                name       = 'bagD.npwp'
                component  = {TextfieldMask}
                maskFormat = "##.###.###.#-###-###"
                validate   = {validation.required}
                // disabled
              />

            </div>
            <div className="md-cell md-cell--6">
              <Field
                label     = 'Nama Pemotong'
                name      = 'bagD.nama'
                component = {Textfield}
                validate  = {validation.required}
                // disabled
              />
            </div>
          </div>
        </Card>
      </div>
    )
  }
}
