import React, { Component } from 'react';
import { Grid, Cell } from 'react-md';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import { validation, Textfield, TextfieldMask, convert, Searchfield } from '../../../../components/form';
import DialogView from '../../../../components/entity/dialog.view';
import AkunPembayaranService from './AkunPembayaran.service';
import BankService from '../Bank/Bank.service';

@reduxForm({form: 'akunPembayaranDialog', destroyOnUnmount: false})
export default class AkunPembayaranDialog extends DialogView {
  service=AkunPembayaranService
  bankService=BankService

  formView() {
    return (
      <Grid style={{padding:'none'}}>
        <Field
          label='Order Type'
          name='orderType'
          className="md-cell md-cell--12"
          component={Searchfield}
          valueField="id"
          viewField="name"
          service={BankService}
          validate={validation.required}
        />

        <Field
          label='ID Pengguna'
          name='name'
          className="md-cell md-cell--12"
          component={Textfield}
          validate={validation.required}
        />

        <Field
          label='ID Perusahaan'
          name='companyId'
          className="md-cell md-cell--12"
          component={Textfield}
          validate={validation.required}
        />

        <Field
          label='Nomor Akun'
          name='accountNumber'
          className="md-cell md-cell--12"
          component={Textfield}
          validate={validation.required}
        />
      </Grid>
    )
  }
}
