import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation} from '../../../../components/form';
import NpwpService from './../../../Main/pph/NpwpService';
import FormView from '../../../../components/entity/form.view';
import PaymentNotificationService from '../PaymentNotification/PaymentNotification.service';
import iziToast from 'izitoast';

@reduxForm({form: 'PaymentNotificationOrderFileForm', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  access: state.entity.access.api.find,
  formData: getFormValues('PaymentNotificationOrderFileForm')(state)
}))
export default class PaymentNotificationOrderFileForm extends FormView {
  translate = false
  service = PaymentNotificationService

  viewType=2

  constructor(props){
    super(props);
    this.state = {
      ...this.state
    }

    this.state.data = {
      paymentAccount: {}
    }
  }

  titleHeader() {
    return `Detail File Transaksi`;
  }

  resend = async () => {
    let dialog = await this.context.showDialog((props, res, rej) =>({
      title: 'Kirim Ulang File Transaksi',
      okText: 'Kirim',
      width: 600,
      text: 'Apakah anda yakin ingin mengirim ulang transaksi?',
    }));

    if (dialog) {
      try {
        let data = this.state.data

        if(data) {
          console.log({data})
          let res = await this.service.api.resendOrderFileById(data.id)

          iziToast.success({
            title: 'Sukses',
            message: 'File transaksi sukses dikirim ulang.'
          })
        }
      } catch (e) {
        iziToast.warning({
          title: e.name,
          message: e.message
        })
      }
    }
  }

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;

    return (
      <div>
        <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>Back</Button>

        <Button raised primary onClick={()=> this.resend()}>Resend</Button>
      </div>
    )
  }

  async initData() {
    let res = await this.service.api.findOrderFileByOrderId(this.props.match.params.id);

    this.setState({data: res});
  }

  formView() {
    let data = this.state.data

    if(data) {
      return (
        <div>
          <Card>
            <CardTitle title='' style={{padding: '10px 16px'}} subtitle='Informasi Akun Pembayaran'/>

            <Divider/>

            <List>
              <ListItem
                // leftAvatar={<Avatar  suffix="green" icon={<FontIcon>done</FontIcon>} />}
                primaryText="ID Perusahaan"
                secondaryText={data.paymentAccount.companyId}
              />
              <ListItem
                primaryText="ID Pengguna"
                secondaryText={data.paymentAccount.name}
              />
              <ListItem
                primaryText="Nomor Account "
                secondaryText={data.paymentAccount.accountNumber}
              />
              <ListItem
                primaryText="Tanggal Instruksi Transaksi "
                secondaryText={data.instructionDate}
              />
              <ListItem
                primaryText="Persetujuan Transaksi "
                secondaryText={data.approval}
              />
            </List>
          </Card>

          <br/>

          <Card>
            <CardTitle title='' style={{padding: '10px 16px'}} subtitle='File dan Status'/>

            <Divider/>

            <Grid noSpacing={true}>
              <Cell size={6}>
                <List>
                  { data.billFilename !== null &&
                    <ListItem
                      primaryText="Nama File Billing"
                      secondaryText={data.billFilename}
                    />
                  }

                  { data.sspFilename !== null &&
                    <ListItem
                      primaryText="Nama File SSP"
                      secondaryText={data.sspFilename}
                    />
                  }
                </List>
              </Cell>
              <Cell size={6}>
                <List>
                  { data.billStatus !== null &&
                    <ListItem
                      primaryText="Status File Billing"
                      secondaryText={data.billStatus}
                    />
                  }

                  { data.sspStatus !== null &&
                    <ListItem
                      primaryText="Status File SSP"
                      secondaryText={data.sspStatus}
                    />
                  }
                </List>
              </Cell>
            </Grid>
          </Card>
        </div>
      )
    }
  }
}
