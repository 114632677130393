import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Chip,
  Switch,
  List,
  ListItem,
  Subheader,
  FontIcon,
  Avatar,
  Divider,
  MenuButton
} from 'react-md';

import {validation, Textfield, Searchfield, TextfieldMask, Datepicker, Radio, Checkbox} from '../../../../components/form';
import Bluebird from 'bluebird';
import { Field, reduxForm, getFormValues, change  } from 'redux-form';
// import EbillingAccountService from '../EbillingAccountService';
//import FormDialog from './Organization.dialog.view';
import UtilService from '../../../../services/utils.service';
import download from 'downloadjs';
import iziToast from 'izitoast';
import ListView from '../../../../components/entity/listView';
import PaymentNotificationService from './PaymentNotification.service';
import LogCetakPaymentNotificationService from '../log/LogCetakPaymentNotification/LogCetakPaymentNotification.service';
import LogCetakBPNService from '../log/LogCetakBPN/LogCetakBPN.service';
import LogCetakLaporanBPNService from '../log/LogCetakLaporanBPN/LogCetakLaporanBPN.service';
import moment from 'moment';

@connect((state)=> ({
  ...PaymentNotificationService.stateConnectSetting()(state),
}), PaymentNotificationService.actionConnectSetting())
export default class PaymentNotificationView extends ListView {
  service=PaymentNotificationService
  // ebillingAccountService=EbillingAccountService
  logCetakPaymentNotificationService=LogCetakPaymentNotificationService
  logCetakBPNService=LogCetakBPNService
  logCetakLaporanBPNService=LogCetakLaporanBPNService
  FormDialog=()=> <div/>

  constructor(props){
    super(props);
    this.state = {
      ...this.state
    }

    this.state.ebillingUser = {}
  }

  beforeFetch(params) {
    if(params["sortBy"] === "createdAt") {
      params["sortBy"] = "createdDate"
      params["sort"] = "DESC"
    }
  }

  addDialog=false
  editDialog=false

  _barActions = [
    {
      label:'word.delete',
      iconClassName:'mdi mdi-delete',
      onClick:() => {
        this.setState({showDialogConfirmDeleteSelected:true})
      },
      disabledFunc:() => this.props.table.selected.length === 0
    },
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => this.fetchData()
    },
  ]

  columns=[
    {isSortable: true, label: "word.noTransaksi", value: "orderNo", show: true, isSearchable: true},
    {isSortable: true, label: "word.total", type: "number", value: "amountTotal", show: true, isSearchable: true},
    {isSortable: true, label: "word.bank", value: "paymentAccount.orderType.name", show: true, isSearchable: true},
    {isSortable: true, label: "word.status", value: "status", show: true, isSearchable: true},
    /*{isSortable: true, label: "word.status", type: "func", value: (d) => {
      switch (d.status) {
        case 'WAITING':
          return <Button flat swapTheming style={{height: 24, backgroundColor: '#9e9e9e', color: 'white'}}>{d.status}</Button>
        case 'WAITING_CONFIRMATION':
          return <Button flat swapTheming style={{height: 24, backgroundColor: '#424242', color: 'white'}}>{d.status}</Button>
        case 'WAITING_VERIFICATION':
          return <Button flat swapTheming style={{height: 24, backgroundColor: '#757575', color: 'white'}}>{d.status}</Button>
        case 'DUPLICATE_SSP':
          return <Button flat swapTheming style={{height: 24, backgroundColor: '#FF9800', color: 'white'}}>{d.status}</Button>
        case 'RE_INQUIRY':
          return <Button flat swapTheming style={{height: 24, backgroundColor: '#E65100', color: 'white'}}>{d.status}</Button>
        case 'RUNNING':
          return <Button flat swapTheming style={{height: 24, backgroundColor: '#03A9F4', color: 'white'}}>{d.status}</Button>
        case 'SPECIAL_CASE':
          return <Button flat swapTheming style={{height: 24, backgroundColor: '#FB8C00', color: 'white'}}>{d.status}</Button>
        case 'VERIFIED':
          return <Button flat swapTheming style={{height: 24, backgroundColor: '#03A9F4'}}>{d.status}</Button>
        case 'REJECTED':
          return <Button flat swapTheming style={{height: 24, backgroundColor: '#F44336', color: 'white'}}>{d.status}</Button>
        case 'ON_PROCESS':
          return <Button flat swapTheming style={{height: 24, backgroundColor: '#2196F3', color: 'white'}}>{d.status}</Button>
        case 'COMPLETED':
          return <Button flat swapTheming style={{height: 24, backgroundColor: '#4CAF50', color: 'white'}}>{d.status}</Button>
        case 'EXPIRED':
          return <Button flat swapTheming style={{height: 24, backgroundColor: '#B71C1C', color: 'white'}}>{d.status}</Button>
        case 'FAILED':
          return <Button flat swapTheming style={{height: 24, backgroundColor: '#D50000', color: 'white'}}>{d.status}</Button>
        default:
          break;
      }

      // if(d.status === 'Kadaluarsa' || d.status === 'Gagal') return <Button flat swapTheming style={{height: 24, backgroundColor: '#f44336', color: 'white'}}>{d.status}</Button>
      //
      // if(d.status === 'Sedang Diproses') return <Button flat swapTheming style={{height: 24, backgroundColor: '#9e9e9e', color: 'white'}}>{d.status}</Button>
      //
      // if(d.status === 'Selesai') return <Button flat swapTheming style={{height: 24, backgroundColor: '#4caf50'}}>{d.status}</Button>
    }, show: true, isSearchable: true},*/
    {isSortable: true, label: "word.aktifSd", type: "date", value: "orderActive", show: true, isSearchable: true}
  ]

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    },
    {label:"divider", iconClassName:"-"},
    {
      label:"Cetak",
      iconClassName:"mdi mdi-printer",
      onClick:(item, callback) => this.printItem(item, callback),
      confirmation:{
        title:"sentence.custom.print",
        message:"sentence.custom.printConfirmation"
      }
    },
    {
      label:"Delete",
      iconClassName:"mdi mdi-delete",
      onClick:(item, callback) => this.deleteItem(item, callback),
      confirmation:{
        title:"sentence.custom.deleteItem",
        message:"sentence.custom.deleteItemConfirmation"
      }
    }
  ]

  async checkConnection() {
    /*let res = await this.ebillingAccountService.api.me()

    this.setState({ebillingUser: res.data});*/
  }

  async componentDidMount() {
    this.rootPath = this.props.match.url;

    try {
      this.checkConnection()
    } catch (e){
      iziToast.warning({
        title: e.name,
        message: e.message
      })
    }
  }

  tarikNtpn = async () => {
    let dialog = await this.context.showDialog((props, res, rej) =>({
      title: 'Tarik NTPN',
      okText: 'Tarik',
      width: 600,
      text: 'Apakah anda yakin akan menarik semua NTPN?',
    }));

    if (dialog) {
      try {
        let selected = this.props.table.selected

        let idList = []

        selected.forEach(item => {
          if (item.status === 'Order selesai.') {
            if(item.id) {
              idList.push(item.id)
            }
          }
        })

        if(idList.length > 0) {
          try {
            let res = await this.service.api.pullNtpn(idList)

            if(res.success) {
              let message = 'Semua NTPN sukses ditarik.'

              if(res.message) {
                message = res.message
              }

              iziToast.success({
                title: 'Sukses',
                message: message
              })
            } else {
              let message = 'Tarik NTPN gagal.'

              if(res.message) {
                message = res.message
              }

              iziToast.error({
                title: 'Gagal',
                message: message
              })
            }
          } catch (e) {
            iziToast.warning({
              title: e.name,
              message: e.message
            })
          }
        } else {
          iziToast.warning({
            title: 'Gagal',
            message: 'Anda harus menceklis minimal satu data berstatus "Order selesai".'
          })
        }
      } catch (e) {
        iziToast.warning({
          title: e.name,
          message: e.message
        })
      }
    }
  }

  printItem = async (item, callback) => {
    try {
      var report = await this.service.api.print(item.id)
      var filename = report.headers.filename

      download(report.data, filename);

      callback()
    } catch(e) {
      callback(e, e)
    }
  }

  printBulk = async () => {
    let selected = this.props.table.selected

    let idList = []

    selected.forEach(item => {
      idList.push(item.id)
    })

    if (idList.length) {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Cetak Order',
        okText: 'Cetak',
        width: 600,
        text: 'Apakah anda yakin akan mencetak order yang anda ceklis?',
      }));

      if (dialog === undefined) {
        dialog = {}
      }

      if (dialog) {
        try {
          let res = await this.logCetakPaymentNotificationService.api.generateList(idList)

          if(res.success) {
            iziToast.success({
              title: 'Sukses',
              message: 'Silahkan cek di halaman log cetak order.'
            })
          } else {
            let message = 'Cetak laporan order gagal.'

            if(res.message) {
              message = res.message
            }

            iziToast.error({
              title: 'Gagal',
              message: message
            })
          }
        } catch (e) {
          iziToast.warning({
            title: e.name,
            message: e.message
          })
        }
      }
    }
    else {
      let dialog = await this.context.showDialog((props, res, rej) =>({
        title: 'Cetak Order',
        okText: 'Cetak',
        width: 600,
        text: (
          <div className='md-grid'>
            <this.ig.Field
              label='NPWP'
              name='npwp'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            {/*<this.ig.Field
            label='Nama'
            name='name'
            className="md-cell md-cell--12"
            component={this.ig.Textfield}
            />*/}
            <this.ig.Field
              label='Jenis Pajak'
              name='jenisPajak'
              className="md-cell md-cell--6"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Jenis Setoran'
              name='jenisSetoran'
              className="md-cell md-cell--6"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Bulan 1'
              name='bulan1'
              className="md-cell md-cell--6"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Bulan 2'
              name='bulan2'
              className="md-cell md-cell--6"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Tahun Pajak'
              name='year'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />

            <div style={{height: 300}}/>
          </div>
        ),
      }));

      if (dialog === undefined) {
        dialog = {}
      }

      if (dialog) {
        try {
          /*let createdDateMulai = dialog.createdDateMulai

          if(createdDateMulai) {
            createdDateMulai = moment(createdDateMulai).format('YYYY-MM-DD')

            dialog.createdDateMulai = createdDateMulai
          }

          let createdDateAkhir = dialog.createdDateAkhir

          if(createdDateAkhir) {
            createdDateAkhir = moment(createdDateAkhir).format('YYYY-MM-DD')

            dialog.createdDateAkhir = createdDateAkhir
          }*/

          let res = await this.logCetakPaymentNotificationService.api.generate(dialog)

          if(res.success) {
            iziToast.success({
              title: 'Sukses',
              message: 'Silahkan cek di halaman log cetak laporan order.'
            })
          } else {
            let message = 'Cetak laporan order gagal.'

            if(res.message) {
              message = res.message
            }

            iziToast.error({
              title: 'Gagal',
              message: message
            })
          }
        } catch (e) {
          iziToast.warning({
            title: e.name,
            message: e.message
          })
        }
      }
    }
  }

  printBpn = async () => {
    let dialog = await this.context.showDialog((props, res, rej) =>({
      title: 'Cetak BPN',
      okText: 'Cetak',
      width: 600,
      text: (
        <div className='md-grid'>
          <this.ig.Field
            label='NPWP'
            name='npwp'
            className="md-cell md-cell--12"
            component={this.ig.Textfield}
          />
          {/*<this.ig.Field
          label='Nama'
          name='name'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
          />*/}
          <this.ig.Field
            label='Jenis Pajak'
            name='jenisPajak'
            className="md-cell md-cell--6"
            component={this.ig.Textfield}
          />
          <this.ig.Field
            label='Jenis Setoran'
            name='jenisSetoran'
            className="md-cell md-cell--6"
            component={this.ig.Textfield}
          />
          <this.ig.Field
            label='Bulan 1'
            name='bulan1'
            className="md-cell md-cell--6"
            component={this.ig.Textfield}
          />
          <this.ig.Field
            label='Bulan 2'
            name='bulan2'
            className="md-cell md-cell--6"
            component={this.ig.Textfield}
          />
          <this.ig.Field
            label='Tahun Pajak'
            name='year'
            className="md-cell md-cell--12"
            component={this.ig.Textfield}
          />

          <div style={{height: 300}}/>
        </div>
      ),
    }));

    if (dialog === undefined) {
      dialog = {}
    }

    if (dialog) {
      try {
        /*let createdDateMulai = dialog.createdDateMulai

        if(createdDateMulai) {
          createdDateMulai = moment(createdDateMulai).format('YYYY-MM-DD')

          dialog.createdDateMulai = createdDateMulai
        }

        let createdDateAkhir = dialog.createdDateAkhir

        if(createdDateAkhir) {
          createdDateAkhir = moment(createdDateAkhir).format('YYYY-MM-DD')

          dialog.createdDateAkhir = createdDateAkhir
        }*/

        let res = await this.logCetakBPNService.api.generate(dialog)

        if(res.success) {
          iziToast.success({
            title: 'Sukses',
            message: 'Silahkan cek di halaman log cetak BPN.'
          })
        } else {
          let message = 'Cetak BPN gagal.'

          if(res.message) {
            message = res.message
          }

          iziToast.error({
            title: 'Gagal',
            message: message
          })
        }
      } catch (e) {
        iziToast.warning({
          title: e.name,
          message: e.message
        })
      }
    }
  }

  printBpnGabung = async () => {
    let selected = this.props.table.selected

    let idList = []

    selected.forEach(item => {
      if(item.id && item.status === 'COMPLETED') {
        idList.push(item.id)
      }
    })

    if(idList.length > 0) {
      let data = {
        id: idList
      }

      try {
        let res = await this.service.api.printBpnGabung(data)

        iziToast.success({
          title: 'Sukses',
          message: 'Silahkan cek di halaman log cetak order'
        })
      } catch (e) {
        iziToast.warning({
          title: e.name,
          message: e.message
        })
      }
    } else {
      iziToast.warning({
        title: 'Gagal',
        message: 'Anda harus menceklis minimal satu data dengan status = "COMPLETED".'
      })
    }
  }

  /*deleteSelected = async (items, callback) => {
    try {
      let idList = []

      items.forEach(item => {
        if (item.id) {
          idList.push(item.id)
        }
      })

      let data = {
        id: idList
      }

      let res = await this.service.api.deleteSelected(data)

      callback()

      await this.fetchData()
    } catch (e) {
      callback(e, e)
    }
  }*/

  printLaporan = async ()=> {
    /*let dialog = await this.context.showDialog((props, res, rej) =>({
      title: 'Laporan BPN',
      okText: 'Cetak',
      width: 600,
      text: (
        <div className='md-grid'>
          <this.ig.Field
            label='NPWP'
            name='npwp'
            className="md-cell md-cell--12"
            component={this.ig.Textfield}
          />
          {/!*<this.ig.Field
          label='Nama'
          name='name'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
          />*!/}
          <this.ig.Field
            label='Jenis Pajak'
            name='jenisPajak'
            className="md-cell md-cell--6"
            component={this.ig.Textfield}
          />
          <this.ig.Field
            label='Jenis Setoran'
            name='jenisSetoran'
            className="md-cell md-cell--6"
            component={this.ig.Textfield}
          />
          <this.ig.Field
            label='Bulan 1'
            name='bulan1'
            className="md-cell md-cell--6"
            component={this.ig.Textfield}
          />
          <this.ig.Field
            label='Bulan 2'
            name='bulan2'
            className="md-cell md-cell--6"
            component={this.ig.Textfield}
          />
          <this.ig.Field
            label='Tahun Pajak'
            name='year'
            className="md-cell md-cell--12"
            component={this.ig.Textfield}
          />

          <div style={{height: 300}}/>
        </div>
      ),
    }));

    if (dialog === undefined) {
      dialog = {}
    }

    if (dialog) {
      try {
        /!*let createdDateMulai = dialog.createdDateMulai

        if(createdDateMulai) {
          createdDateMulai = moment(createdDateMulai).format('YYYY-MM-DD')

          dialog.createdDateMulai = createdDateMulai
        }

        let createdDateAkhir = dialog.createdDateAkhir

        if(createdDateAkhir) {
          createdDateAkhir = moment(createdDateAkhir).format('YYYY-MM-DD')

          dialog.createdDateAkhir = createdDateAkhir
        }*!/

        let res = await this.logCetakLaporanBPNService.api.generate(dialog)

        if(res.success) {
          iziToast.success({
            title: 'Sukses',
            message: 'Silahkan cek di halaman log laporan BPN.'
          })
        } else {
          let message = 'Laporan BPN gagal.'

          if(res.message) {
            message = res.message
          }

          iziToast.error({
            title: 'Gagal',
            message: message
          })
        }
      } catch (e) {
        iziToast.warning({
          title: e.name,
          message: e.message
        })
      }
    }*/


    let dialog = await this.context.showDialog((props, res, rej) =>({
      title: 'Laporan BPN',
      okText: 'Ya',
      width: 600,
      text: 'Apakah anda yakin akan generate laporan BPN?',
    }));

    if (dialog) {
      try {
        let selected = this.props.table.selected

        let data = {
          orderId: []
        }

        selected.forEach(item => {
          if (item.status === 'Order selesai.') {
            if(item.id) {
              data.orderId.push(item.id)
            }
          }
        })

        if(data.orderId.length > 0) {
          try {
            let res = await this.logCetakLaporanBPNService.api.generate(data)

            iziToast.success({
              title: 'Sukses',
              message: 'Silahkan cek halaman log laporan BPN.'
            })
          } catch (e) {
            iziToast.warning({
              title: e.name,
              message: e.message
            })
          }
        } else {
          iziToast.warning({
            title: 'Gagal',
            message: 'Anda harus menceklis minimal satu data berstatus "Order selesai".'
          })
        }
      } catch (e) {
        iziToast.warning({
          title: e.name,
          message: e.message
        })
      }
    }
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='No Transaksi'
          name='orderNo__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Status'
          name='status__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
      </this.SearchContainer>
    )
  }

  _barItem() {
    let selected = this.props.table.selected

    return (
      <div className='actions mpk-layout'>
        <Button icon tooltipLabel="Tarik NTPN" onClick={()=> this.tarikNtpn()} disabled={selected.length === 0}>chrome_reader_mode</Button>
        <Button icon tooltipLabel="Cetak Order" onClick={() => this.printBulk()}>print</Button>
        <Button icon tooltipLabel="Cetak BPN" onClick={() => this.printBpn()}>assignment</Button>
        {/*<Button icon tooltipLabel="Cetak BPN Gabung" onClick={() => this.printBpnGabung()} disabled={selected.length === 0}>description</Button>*/}
        <Button icon tooltipLabel="Laporan BPN" onClick={() => this.printLaporan()} disabled={selected.length === 0}>book</Button>

        {/*<MenuButton
          id="menu-button-2"
          icon
          menuItems={[
            <ListItem leftIcon={<FontIcon>print</FontIcon>} key={1} onClick={() => this.printBulk()} primaryText="Cetak Bulk" />,
            <ListItem leftIcon={<FontIcon>book</FontIcon>} key={2} primaryText="Cetak Laporan" />,
            <ListItem leftIcon={<FontIcon>print</FontIcon>} key={3} onClick={() => this.printBpn()} primaryText="Cetak BPN" />,
            <ListItem leftIcon={<FontIcon>print</FontIcon>} key={4} onClick={() => this.printBpnGabung()} primaryText="Cetak BPN Gabung" />,
          ]}
          centered
          anchor={{
            x: MenuButton.HorizontalAnchors.CENTER,
            y: MenuButton.VerticalAnchors.CENTER,
          }}
        >
          more_vert
        </MenuButton>*/}

        <Switch
          style={{float: "right"}}
          id="switch-table-setting"
          name="Switch Table Setting"
          label=""
          checked={this.state.showTableSetting}
          onChange={() => {
            this.setState({
              showTableSetting:!this.state.showTableSetting
            })
          }}
        />
      </div>
    )
  }

  // right bar
  tabWidth = 280
  /*tabs= ["status", "search", "column"]

  tabComponents() {
    var obj = this;
    return {
      status: this.status.bind(obj)
    }
  }

  status() {
    const { handleSubmit, submitting, valid, pristine } = this.props;
    return (
      <div className='mpk-layout column fill'>
        <div className='flex'>
          <List className="">
            <Subheader primaryText="Status Koneksi" />

            { this.state.ebillingUser.login &&
              <ListItem
                leftAvatar={<Avatar  suffix="green" icon={<FontIcon>done</FontIcon>} />}
                primaryText="Terhubung"
                secondaryText={"Terhubung sebagai " + this.state.ebillingUser.login + "."}
              />
            }

            { !this.state.ebillingUser.login &&
              <ListItem
                leftAvatar={<Avatar suffix="red" icon={<FontIcon>clear</FontIcon>} />}
                primaryText="Tidak Terhubung"
                secondaryText="Status Koneksi"
              />
            }

            {/!*<Subheader primaryText="Status Sistem" />

            <ListItem
              leftAvatar={<Avatar  suffix="green" icon={<FontIcon>done</FontIcon>} />}
              primaryText="Sistem Active"
              secondaryText="Status Sistem"
            />*!/}
          </List>
        </div>
      </div>
    )
  }*/

}
