import React, { Component } from 'react';
import { Grid, Cell, List,
  ListItem,
  ListItemControl,
  FontIcon,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn
} from 'react-md';
import moment from 'moment';
import {Timeline, TimelineEvent} from 'react-event-timeline'
import { connect } from 'react-redux';
import { Field, reduxForm , propTypes, formValueSelector , getFormValues} from 'redux-form';
import { validation, Textfield, TextfieldMask, convert, Searchfield } from '../../../../components/form';
import DialogView from '../../../../components/entity/dialog.view';
import MainFlowService from './MainFlow.service';
import UserService from '../../Administrator/User/User.service';
import _ from 'lodash';


@reduxForm({form: 'flow_history_dialog', destroyOnUnmount: true})
@connect((state) => ({
  formState: getFormValues('flow_history_dialog')(state)
}))
export default class FlowHistoryDialog extends DialogView {
  service=MainFlowService
  size='normal'
  translate=false
  titleHeader() {
   return "Flow Histories"
  }

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      histories: []
    }
  }

  async initData(props) {
    var value = {};
    if(props.formData) value = _.cloneDeep(props.formData);
    if(value.id) {
      var res = await MainFlowService.api.history({formType: this.props.formType, formId:value.id});
      this.setState({
        histories: res.data
      })

      console.log(this.state.histories, 'histories')
    }
  }

  componentWillReceiveProps(nextProps) {
    var value = {};
    var nextValue = {}
    if(this.props.formData) value = this.props.formData;
    if(nextProps.formData) nextValue = nextProps.formData;
    if(value.id != nextValue.id) {
      this.initData(nextProps)
    }
  }

  async handleSave(callback, value) {
    this.props.onSubmit(value, callback);
  }

  formView() {
    return (
      <Timeline style={{fontSize: 'inherit', fontWeight: 'inherit'}}>
        {this.state.histories.map((d, i)=> {
          var icon = 'restore';
          var color = "#2196F3";
          if(d.actionType == 'start' || d.actionType == 'Start') {
            icon = 'play_arrow'
            color = "#66BB6A";
          } else if(d.actionType == 'End') {
            icon = 'stop'
            color = "#EF5350";
          }
          return <TimelineEvent iconColor={color} title={d.user.login}
          createdAt={moment(d.createdDate).calendar()}
          icon={<i className="material-icons md-18">{icon}</i>}
          >
          {d.message}<br/>
          Note : {d.note}
        </TimelineEvent>
        })}

      </Timeline>
    )
  }


}
