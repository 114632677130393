import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  Tab,
  Tabs,
  SelectionControlGroup,
  Checkbox
} from 'react-md';

import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Radio, convert} from '../../../../../components/form';
import UtilService from '../../../../../services/utils.service'
import FormView from '../../../../../components/entity/form.view';
import DialogView from '../../../../../components/entity/dialog.view';
import OrganizationService from './Organization.service'
import moment from 'moment'
import counterpart from 'counterpart'
import UserService from './../User/User.service'

@reduxForm({form: 'OrganizationForm', destroyOnUnmount: true})
@connect((state) => ({
  formData:state.form.OrganizationForm,
  user: state.auth.user
}))
export default class OrganizationFormView extends FormView {
  service=OrganizationService
  viewType=2;

  formView() {
    return (
        <Card>
            <div className='md-grid'>
                <Field
                    label='NPWP'
                    name='npwp'
                    className="md-cell md-cell--6"
                    component={TextfieldMask}
                    maskFormat="##.###.###.#-###-###"
                    validate={validation.required}
                />
                <Field
                    label='Nama'
                    name='nama'
                    className="md-cell md-cell--6"
                    component={Textfield}
                    validate={validation.required}
                />
                {/* <Field
                    label='Active'
                    name='active'
                    className="md-cell md-cell--12"
                    component={Switch}
                /> */}
                <Field
                    label='City'
                    name='kota'
                    id="organization-npwp"
                    className="md-cell md-cell--6"
                    component={Textfield}
                />
                <Field
                    label='Postal Code'
                    name='kodePos'
                    id="organization-npwp"
                    className="md-cell md-cell--6"
                    component={Textfield}
                />
                <Field
                    label='Email'
                    name='email'
                    className="md-cell md-cell--6"
                    component={Textfield}
                />
                <Field
                    label='Phone'
                    name='noTelp'
                    className="md-cell md-cell--6"
                    component={Textfield}
                />
                <Field
                    label='Address'
                    name='alamat'
                    rows={4}
                    className="md-cell md-cell--12"
                    component={Textfield}
                />
            </div>
      </Card>
    )
  }
}
