import React from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  Tab,
  Tabs,
  SelectionControlGroup,
  Checkbox,
  Button
} from 'react-md';

import FormView from '../../../../../components/entity/form.view';
import BP21A2Service from './BP_21_A2.service';
import NomorBPService from '../nomorBP.service';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Radio} from '../../../../../components/form';
import UtilService from '../../../../../services/utils.service'
import Master21GolonganA2Service from '../Master21GolonganA2/Master21GolonganA2.service';
import Master21StatusKaryawanA2Service from '../Master21StatusKaryawanA2/Master21StatusKaryawanA2.service';
import SPT21SignerService from '../SPT21Signer/SPT21Signer.service';
import CountryCodeService from '../../../Master/CountryCode/CountryCode.service';
import NpwpService from './../../NpwpService';
import FormViewSpt from '../../../../../components/entity/form.view.spt';
import LawanTransaksiDialog from '../../../../../components/entity/LawanTransaksi.dialog';
import MasterLawanTransaksiA2Service from '../../../Master/MasterLawanTransaksiA2/MasterLawanTransaksiA2.service';
import SPT_INDUK_21Service from '../SPT_INDUK_21/SPT_INDUK_21.service';

@reduxForm({form: 'BP_21_A2_Form', destroyOnUnmount: true})
@connect((state) => ({
  access: state.entity.access.api.find,
  organization: state.authEppt.currentOrganization,
  spt: state.authEppt.currentSPT,
  formData:state.form.BP_21_A2_Form
}))
export default class BP21A2Form extends FormViewSpt {
  service=BP21A2Service
  viewType =2;


  typingTimeout=1000;
  typingObj=null;

  constructor(props) {
    super(props);

    this.state= {
      ...this.state,
      showCalculateProgress: false,
      npwpNotValid: true,

      lkDialog: false
    }
  }

  async npwpValidation(evt, value){
    if(this.typingObj) clearTimeout(this.typingObj);

    // this.typingObj = setTimeout(async ()=> {
      if(value){
        var res = await NpwpService.api.validate(value)
        if(res.valid){
          this.setState({npwpNotValid: false})

          var lk = await MasterLawanTransaksiA2Service.api.page({
            'npwp.equals': value,
            'organizationId.equals': this.props.spt.data.organization.id
          })

          if(lk.data) {
            var formData = {...this.props.formData.values};

            if(lk.data.length > 0) {
              var d = lk.data[0];
              formData.bagA = {
                "npwp": d.npwp,
                "nip": d.nip,
                "nama": d.nama,
                "pangkat": d.pangkat,
                "golongan": d.golongan,
                "alamat": d.alamat,
                "jenisKelamin": d.jenisKelamin,
                "nik": d.nik,
                "statusPtkp": d.statusPtkp,
                "jmlPtkp": d.jmlTanggungan,
                "jabatan": d.jabatan,
                "wna" : d.wpLuarNegeri,
                "domisili": d.domisili,
                "email": d.email
              };
            } else {
              formData.bagA = {
                "npwp" : value,
                "nip": "",
                "nama": "",
                "pangkat": "",
                "golongan": "",
                "alamat": "",
                "jenisKelamin": "",
                "nik": "",
                "statusPtkp": "TK",
                "jmlPtkp": 0,
                "jabatan": "",
                "wna" : null,
                "domisili": null
              }
              this.toast.info({title: "Info", message: `Lawan Transaksi dengan npwp ${value} tidak ditemukan`})
            }
            this.props.initialize(formData);
          }
        } else {
          this.setState({npwpNotValid: true})
          this.toast.warning({title: "Validasi Npwp", message: "npwp tidak valid"})
        }
      }
    // }, this.typingTimeout);
  }

  initialData={
    "nomorBuktiPotong": "",
    "masaPerolehanAwal": 1,
    "masaPerolehanAkhir": 12,
    "namaInstansi": "",
    "namaBendahara": "",
    "npwpBendahara": "000000000000000",
    grossUp: false,
    // "satuTahunKey": "",
    "bagA": {
      "npwp": null,
      "nip": "",
      "nama": "",
      "pangkat": "",
      "golongan": "",
      "alamat": "",
      "jenisKelamin": "",
      "nik": "",
      "statusPtkp": "TK",
      "jmlPtkp": 0,
      "jabatan": "",
      "wna" : null,
      "domisili": null
    },
    "bagB": {
      "kodeObjekPajak": "21-100-01",
      "gajiPokok": 0,
      "tunjanganIstri": 0,
      "tunjanganAnak": 0,
      "jmlGaji": 0,
      "tunjanganPerbaikan": 0,
      "tunjanganStruktural": 0,
      "tunjanganBeras": 0,
      "tunjanganKhusus": 0,
      "tunjanganLain": 0,
      "penghasilanTetap": 0,
      "jmlBruto": 0,
      "biayaJabatan": 0,
      "iuranPensiun": 0,
      "jmlPengurangan": 0,
      "jmlPenghasilanNetto": 0,
      "jmlPenghasilanNettoSebelumnya": 0,
      "jmlPenghasilanNettoUntukPerhitungan": 0,
      "ptkp": 0,
      "penghasilanKenaPajak": 0,
      "pph21AtasPenghasilanKenaPajak": 0,
      "pph21DipotongSebelumnya": 0,
      "pph21Terutang": 0,
      "pph21Dipotong": 0,
      "pph21DipotongAtasGaji": 0,
      "pph21DipotongAtasPenghasilanTetap": 0
    },
    "bagC": {
      "dipindahkan": false,
      "pindahan": false,
      "baru": false,
      "pensiun": false
    },
    "bagD": {
      "npwp": "000000000000000",
      "nama": "",
      "nip": "",
      "tanggal": "",
      "ttd": ""
    }
    // "status": {
    //   "code": "string",
    //   "id": 0,
    //   "name": "string"
    // },
    // "golongan": {
    //   "id": 0,
    //   "code": "string",
    //   "name": "string"
    // }
  }

  async initData() {
    if(this.props.match.params.id == 'new') {
      let nomorBP = await NomorBPService.api.get(this.props.spt.data.organization.id, "BP_21_A2")
      var signer = {};

      try {
        signer = await SPT_INDUK_21Service.api.getSigner({
          orgId: this.props.spt.data.organization.id,
          tgl  : moment().format("YYYY-MM-DD")
        })
      } catch (error) {

      }

      this.initialData = {
        ...this.initialData,
        nomorBuktiPotong: '1.2-'+UtilService.date.MM(this.props.spt.data.month)+'.'+UtilService.date.YY(this.props.spt.data.year)+'-'+nomorBP.data.no,
        bagD:{
          nama: signer.namaPenandaTanganBp,
          npwp: signer.npwpPenandaTanganBp,
          tanggal: new Date()
        },
        namaBendahara: this.props.organization.name,
        npwpBendahara: this.props.organization.npwp,
        spt21: this.props.spt.data
      }
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);
      this.props.initialize(res.data);
      // this.npwpValidation(null, res.data.bagA.npwp)
      this.setState({npwpNotValid: false})
    }
  }

  async calculate(field, value) {
    var formData = this.props.formData.values;

    if (field === 'masaPerolehanAkhir') {
      let nomorBuktiPotong = formData.nomorBuktiPotong

      let awal = nomorBuktiPotong.substr(0, 6)

      let bulan = '' + value
      bulan = bulan.padStart(2, '0')

      let replace = '1.2-' + bulan

      nomorBuktiPotong = nomorBuktiPotong.replace(awal, replace)

      formData.nomorBuktiPotong = nomorBuktiPotong;
      this.props.initialize(formData)
    }

    if(this.typingObj) clearTimeout(this.typingObj);
    this.typingObj = setTimeout(async ()=> {
      this.setState({showCalculateProgress: true})
      try {
        console.log(field, value)
        var execute = true;
        if(field) {
          if(field.search('bagA.') >= 0) {
            formData.bagA[field.replace('bagA.', '')] = value
          } else if (field.search('bagB.') >= 0) {
            if(formData.bagB[field] || formData.bagB[field] === 0) {
              formData.bagB[field] = value;
            }
          } else {
            formData[field] = value;
          }
        }

        var statusCode = null;
        if(formData.status) {
          statusCode = formData.status.code;
        }
        if(!(formData.masaPerolehanAwal === 1 && formData.masaPerolehanAkhir === 12) && !statusCode){
          execute = false;
        }

        var param = {
          objekHitungA2: {
            masaPerolehanAwal: formData.masaPerolehanAwal,
            masaPerolehanAkhir: formData.masaPerolehanAkhir,
            tanpaNpwp: false,
            status: statusCode,
            grossUp: formData.grossUp,
            partA: {
              golongan:  formData.bagA.golongan,
              pangkat:  formData.bagA.pangkat,
              statusPtkp: formData.bagA.statusPtkp,
              jmlPtkp: formData.bagA.jmlPtkp,
            },
            partB: formData.bagB
          },
          spt21: this.props.spt.data
        }


        if(formData.bagA.npwp === '000000000000000' || formData.bagA.npwp === "") param.objekHitungA2.tanpaNpwp = true;
        param.spt21.orgId = param.spt21.organization.id;

        if(execute) {
          var res = await BP21A2Service.api.calculate(param);

          if(res) {
            formData.bagB = res.partB;
            this.props.initialize(formData)
          }
        }

      } catch(e) {console.log(e)}

      this.setState({showCalculateProgress: false})
    }, this.typingTimeout);
  }

  async afterSave(res, val) {
    var formData = this.props.formData.values;
    var data = {
      ...formData.bagA,
      organization: this.props.spt.data.organization,
      jmlTanggungan: formData.bagA.jmlPtkp,
      wpLuarNegeri: formData.bagA.wna,
      npwpProfile: this.props.organization.npwp,
    }
    if(data.id) delete data.id;
    await MasterLawanTransaksiA2Service.api.findOrCreate(data)
  }

  formView() {
    var formData = {bagB: {}};
    if(this.props.formData && this.props.formData.values) formData = this.props.formData.values

    var masaOptions = [
      {id:1, name: 1}, {id:2, name: 2}, {id:3, name: 3}, {id:4, name: 4}, {id:5, name: 5}, {id:6, name: 6},
      {id:7, name: 7}, {id:8, name: 8}, {id:9, name: 9}, {id:10, name: 10}, {id:11, name: 11}, {id:12, name: 12}
    ]
    return (
      <div>

        <LawanTransaksiDialog
            spt={this.props.spt.data}
            onSelect={(d)=> {
              console.log(d, this.props.formData.values)
              var formData = {...this.props.formData.values};
              if(!d.wpLuarNegeri) d.wpLuarNegeri = false;

              formData.bagA = {
                "npwp": d.npwp,
                "nip": d.nip,
                "nama": d.nama,
                "pangkat": d.pangkat,
                "golongan": d.golongan,
                "alamat": d.alamat,
                "jenisKelamin": d.jenisKelamin,
                "nik": d.nik,
                "statusPtkp": d.statusPtkp,
                "jmlPtkp": d.jmlTanggungan,
                "jabatan": d.jabatan,
                "wna" : d.wpLuarNegeri,
                "domisili": d.domisili,
                "email": d.email
              };

              // formData.kodeNegara =  {id: d.kodeNegara};

              this.setState({npwpNotValid: false})

              this.props.initialize(formData);

            }}
            service={MasterLawanTransaksiA2Service}
            visible={this.state.lkDialog}
            onHide={()=> this.setState({lkDialog: false})}
          />
        <Card>
          <div className="md-grid">
            <Field
              label='No.Bukti Potong'
              name='nomorBuktiPotong'
              className="md-cell md-cell--3"
              component={Textfield}
              validate={validation.required}
              onDateChange = { async (d)=> {
                let signer = null;

                try {
                  signer = await SPT_INDUK_21Service.api.getSigner({
                    orgId: this.props.spt.data.organization.id,
                    tgl  : moment(d).format("YYYY-MM-DD")
                  })
                } catch (error) {
                }

                this.props.change('bagD.nama', signer.namaPenandaTanganBp)
                this.props.change('bagD.npwp', signer.npwpPenandaTanganBp)
              }}
            />
            <Field
              label='Tanggal Bukti Potong'
              name='bagD.tanggal'
              className="md-cell md-cell--3"
              component={Datepicker}
              validate={validation.required}
            />
            <Field
              label='Masa'
              name='masaPerolehanAwal'
              className="md-cell md-cell--3"
              component={Searchfield}
              validate={validation.required}
              options={masaOptions}
              onChange={(e, v)=> this.calculate('masaPerolehanAwal', v)}
            />
            <Field
              label='Sampai Masa'
              name='masaPerolehanAkhir'
              className="md-cell md-cell--3"
              component={Searchfield}
              validate={validation.required}
              options={masaOptions}
              onChange={(e, v)=> this.calculate('masaPerolehanAkhir', v)}
            />

            {!(formData.masaPerolehanAwal === 1 && formData.masaPerolehanAkhir === 12) && <Field
              label='Status'
              name='status'
              className="md-cell md-cell--12"
              component={Searchfield}
              service={Master21StatusKaryawanA2Service}
              validate={validation.required}
              onChange={async (e, v)=> {
                if(!v) v = {}
                var s = {
                  "dipindahkan": false,
                  "pindahan": false,
                  "baru": false,
                  "pensiun": false
                }

                switch (v.code) {
                  case '01':
                    s.baru = true;
                    break;
                  case '02':
                    s.dipindahkan = true;
                    break;
                  case '03':
                    s.pensiun = true;
                    break;
                  case '04':
                    s.pindahan = true;
                    break;
                  default:
                    break;
                }
                await this.props.change('bagC', s)
                await this.calculate('status', v)
              }}
              valueField='parent'
            />}

            <Field
              label='Referensi'
              name='referensi'
              className="md-cell md-cell--12"
              component={Textfield}
            />
          </div>
        </Card>
        <br/>

        {/*<Card>
          <div className='md-grid'>
            <Field
              className='md-cell md-cell--6'
              label='NPWP Bendahara'
              name='npwpBendahara'

              component={TextfieldMask}
              length={15}
              disabled={true}
              maskFormat="##.###.###.#-###-###"
              mask="_"
              validate={validation.required}
            />

            <Field
              label='Nama Instansi / Badan Lain'
              name='namaInstansi'
              className="md-cell md-cell--6"
              component={Textfield}
              validate={validation.required}
            />

            <Field
              label='Nama Bendahara'
              name='namaBendahara'
              disabled={true}
              className="md-cell md-cell--12"
              component={Textfield}
              validate={validation.required}
            />
          </div>
        </Card>
        <br/>*/}

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='A. IDENTITAS PENERIMA PENGHASILAN YANG DIPOTONG ' />
          <Divider/>
          <div className="md-grid">
            <div className='md-cell md-cell--6'>
              <div className='mpk-layout'>
                <Field
                  label='NPWP'
                  className='flex'
                  name='bagA.npwp'
                  component={TextfieldMask}
                  maskFormat="##.###.###.#-###-###"
                  mask="_"
                  length={15}
                  onChange={async (e, v) => {
                    await this.npwpValidation(e, v);
                    await this.calculate('bagA.npwp', v);
                  }}
                  validate={validation.required}
                />
                <Button icon primary swapTheming style={{marginTop: 10}} onClick={()=> this.setState({lkDialog: true})}>search</Button>
              </div>
              <Field
                label='NIP/NIRP'
                name='bagA.nip'
                disabled={this.state.npwpNotValid}
                component={Textfield}
              />
              <Field
                label='Nama'
                name='bagA.nama'
                component={Textfield}
                disabled={this.state.npwpNotValid}
                validate={validation.required}
              />
              {/* <Field
                label='Pangkat/Golongan'
                name='bagA.golongan'
                component={Searchfield}
              />
              <Field
                label='Kode POS'
                name='bagA.kodePos'
                disabled={this.state.npwpNotValid}
                component={Textfield}
              /> */}
              <Field
                label='Email'
                name='bagA.email'
                disabled={this.state.npwpNotValid}
                component={Textfield}
              />
              {/*<Field
                label='Telepon'
                name='telepon'
                disabled={this.state.npwpNotValid}
                component={Textfield}
              />*/}
              <Field
                label='Alamat'
                name='bagA.alamat'
                disabled={this.state.npwpNotValid}
                component={Textfield}
              />
            </div>
            <div className='md-cell md-cell--6'>
              <Field
                label='Jenis Kelamin'
                name='bagA.jenisKelamin'
                disabled={this.state.npwpNotValid}
                component={Searchfield}
                validate={validation.required}
                options={[{id: 'L', name: 'Laki-laki'}, {id:'P', name: 'Perempuan'}]}
              />
              <Field
                label='NIK'
                name='bagA.nik'
                disabled={this.state.npwpNotValid}
                component={Textfield}
                validate={validation.required}
              />
              <Field
                label='Status PTKP'
                name='bagA.statusPtkp'
                onChange={(e, v)=> this.calculate('bagA.statusPtkp', v)}
                id="BP21A2Form-status"
                disabled={this.state.npwpNotValid}
                component={Searchfield}
                validate={validation.required}
                options={[
                  {id: 'TK', name: 'TK'},
                  {id: 'K', name: 'K'},
                  {id: 'K/I', name: 'K/I'},
                  {id: 'HB', name: 'HB'}
                ]}
              />
              <Field
                label='Jumlah Tanggungan'
                onChange={(e, v)=> this.calculate('bagA.jmlPtkp', v)}
                name='bagA.jmlPtkp'
                disabled={this.state.npwpNotValid}
                component={Searchfield}
                options={[{id: 0, name: '0'}, {id: 1, name: '1'}, {id: 2, name: '2'}, {id: 3, name: '3'}]}
              />
              <div className="md-grid" style={{padding: 0}}>
                <Field
                  style={{margin: 0}}
                  className="md-cell md-cell--4"
                  label='Golongan'
                  name='golongan'
                  valueField='code'
                  component={Searchfield}
                  onChange={(e, v)=> {
                    this.props.change('bagA.golongan', v.code);
                    this.props.change('bagA.pangkat', v.name)
                  }}
                  valueField='parent'
                  viewField='code'
                  disabled={this.state.npwpNotValid}
                  validate={validation.required}
                  service={Master21GolonganA2Service}
                />
                <Field
                  style={{margin: "0px 10px"}}
                  className='md-cell md-cell--8'
                  label='Pangkat'
                  name='bagA.pangkat'
                  component={Textfield}
                  disabled={true}
                />
              </div>
              <Field
                label='Jabatan'
                name='bagA.jabatan'
                id="BP21A2Form-jabatan"
                disabled={this.state.npwpNotValid}
                component={Textfield}
                validate={validation.required}
              />
              {/* <Field
                label='Warga Negara Asing'
                name='bagA.wna'
                component={Switch}
              />
              <Field
                label='Kode Negara Domisili'
                name='bagA.domisili'
                component={Searchfield}
                service={CountryCodeService}
                valueField="parent"
              /> */}
              {/* <Field
                label='Penandatangan dan Kuasa'
                name='penandaTangan'
                id="BP21A1Form-penandaTangan"
                component={Switch}
              />
              <Field
                label='Aktif'
                name='aktif'
                id="BP21A1Form-aktif"
                component={Switch}
              /> */}
            </div>
          </div>
        </Card>
        <br/>

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='B. Rincian Penghasilan Dan Perhitungan PPh Pasal 21' />
          <Divider/>
          <DataTable plain>
            <TableHeader>
              <TableRow>
                <TableColumn style={{width: 5}}></TableColumn>
                <TableColumn>URAIAN</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn className='md-text-right'>JUMLAH (Rp)</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableColumn colSpan="2">
                  KODE OBJEK PAJAK:
                </TableColumn>
                <TableColumn>
                  <Field
                    component={Radio}
                    controlStyle={{display: 'inline-block'}}
                    name="bagB.kodeObjekPajak"
                    type="radio"
                    key={formData.bagB.kodeObjekPajak}
                    value={formData.bagB.kodeObjekPajak}
                    controls={[{
                      label: '21-100-01',
                      value: '21-100-01',
                    }, {
                      label: '21-100-02',
                      value: '21-100-02',
                    }]}
                  />
                </TableColumn>
                <TableColumn></TableColumn>
              </TableRow>

              <TableRow><TableColumn colSpan='4'><b>PENGHASILAN BRUTO</b></TableColumn></TableRow>

              <TableRow>
                <TableColumn>1.</TableColumn>
                <TableColumn>Gaji/Pensiun atau THT/JHT</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.gajiPokok'
                    onChange={(e, v)=> this.calculate('bagB.gajiPokok', v)}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>2.</TableColumn>
                <TableColumn>Tunjangan Isteri</TableColumn>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.tunjanganIstri'
                    onChange={(e, v)=> this.calculate('bagB.tunjanganIstri', v)}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>3.</TableColumn>
                <TableColumn>Tunjangan Anak</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.tunjanganAnak'
                    onChange={(e, v)=> this.calculate('bagB.tunjanganAnak', v)}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>4.</TableColumn>
                <TableColumn>Jumlah Gaji dan Tunjangan Keluarga ( 1 S.D. 3 )</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.jmlGaji'
                    disabled={true}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>5.</TableColumn>
                <TableColumn>Tunjangan Perbaikan Penghasilan</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.tunjanganPerbaikan'
                    onChange={(e, v)=> this.calculate('bagB.tunjanganPerbaikan', v)}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>6.</TableColumn>
                <TableColumn>Tunjangan Struktural/Fungsional</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.tunjanganStruktural'
                    onChange={(e, v)=> this.calculate('bagB.tunjanganStruktural', v)}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>7.</TableColumn>
                <TableColumn>Tunjangan Beras</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.tunjanganBeras'
                    onChange={(e, v)=> this.calculate('bagB.tunjanganBeras', v)}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>8.</TableColumn>
                <TableColumn>Tunjangan Khusus</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.tunjanganKhusus'
                    onChange={(e, v)=> this.calculate('bagB.tunjanganKhusus', v)}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>9.</TableColumn>
                <TableColumn>Tunjangan Lain-Lain</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.tunjanganLain'
                    onChange={(e, v)=> this.calculate('bagB.tunjanganLain', v)}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>10.</TableColumn>
                <TableColumn>Penghasilan Tetap dan Teratur lainnya yang Pembayarannya Terpisah dari Pembayaran Gaji</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.penghasilanTetap'
                    onChange={(e, v)=> this.calculate('bagB.penghasilanTetap', v)}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>11.</TableColumn>
                <TableColumn>Jumlah penghasilan bruto ( 4 S.D. 10 )</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.jmlBruto'
                    disabled={true}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow><TableColumn colSpan='4'><b>PENGURANGAN</b></TableColumn></TableRow>

              <TableRow>
                <TableColumn>12.</TableColumn>
                <TableColumn>Biaya Jabatan / Biaya Pensiun</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.biayaJabatan'
                    onChange={(e, v)=> this.calculate('bagB.biayaJabatan', v)}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>13.</TableColumn>
                <TableColumn>Iuran Pensiun atau iuran THT</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.iuranPensiun'
                    onChange={(e, v)=> this.calculate('bagB.iuranPensiun', v)}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>14.</TableColumn>
                <TableColumn>Jumlah Pengurangan ( 12 S.D. 14 )</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.jmlPengurangan'
                    disabled={true}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow><TableColumn colSpan='4'><b>PERHITUNGAN PPh PASAL 21</b></TableColumn></TableRow>
              <TableRow>
                <TableColumn>15.</TableColumn>
                <TableColumn>Jumlah Penghasilan Neto (11 - 14)</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.jmlPenghasilanNetto'
                    disabled={true}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>16.</TableColumn>
                <TableColumn>Jumlah Penghasilan Neto Masa Sebelumnya</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.jmlPenghasilanNettoSebelumnya'
                    disabled={true}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>17.</TableColumn>
                <TableColumn>Jumlah Penghasilan Neto untuk PPH Pasal 21 (Setahunkan/ Disetahunkan)</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.jmlPenghasilanNettoUntukPerhitungan'
                    disabled={true}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>18.</TableColumn>
                <TableColumn>Penghasilan Tidak Kena Pajak (PTKP)</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.ptkp'
                    disabled={true}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>19.</TableColumn>
                <TableColumn>Penghasilan Kena Pajak Setahun/Disetahunkan ( 17 - 18) </TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.penghasilanKenaPajak'
                    disabled={true}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>20.</TableColumn>
                <TableColumn>PPH Pasal 21 atas Penghasilan Kena Pajak Setahun/Disetahunkan</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.pph21AtasPenghasilanKenaPajak'
                    disabled={true}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>21.</TableColumn>
                <TableColumn>PPH Pasal 21 yang telah dipotong Masa sebelumnya</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.pph21DipotongSebelumnya'
                    disabled={true}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>22.</TableColumn>
                <TableColumn>PPH Pasal 21 Terhutang</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.pph21Terutang'
                    disabled={true}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>23.</TableColumn>
                <TableColumn>PPH Pasal 21 dan PPH Pasal 26 yang telah dipotong/dilunasi</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.pph21Dipotong'
                    disabled={true}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>23A. Atas Gaji dan Tunjangan</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.pph21DipotongAtasGaji'
                    //onFocus={(e, v)=> this.calculate('pph21DipotongAtasGaji', v)}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn></TableColumn>
                <TableColumn>23B. Atas Penghasilan Tetap dan Teratur lainnya yang Pembayarannya Terpisah dari Pembayaran Gaji</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>
                  <Field
                    name='bagB.pph21DipotongAtasPenghasilanTetap'
                    ///onFocus={(e, v)=> this.calculate('pph21DipotongAtasPenghasilanTetap', v)}
                    className='md-text-right'
                    component={TextfieldMask}
                    money={','}
                  />
                </TableColumn>
              </TableRow>
            </TableBody>
          </DataTable>
        </Card>
        <br/>

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='C. IDENTITAS PEMOTONG ' />
          <Divider/>

          <div className="md-grid">
            <Field
              label='NPWP'
              name='bagD.npwp'
              className="md-cell md-cell--6"
              length={15}

              component={TextfieldMask}
              maskFormat="##.###.###.#-###-###"
              mask="_"
              validate={validation.required}
            />
            <Field
              label='Nama'
              name='bagD.nama'
              className="md-cell md-cell--6"

              component={Textfield}
              validate={validation.required}
            />
            <Field
              label='NIP / NRP'
              name='bagD.nip'
              className="md-cell md-cell--6"

              component={Textfield}
              validate={validation.required}
            />

            <Field
              label='Nama Instansi / Badan Lain'
              name='bagD.namaInstansi'
              className="md-cell md-cell--6"
              component={Textfield}
              validate={validation.required}
            />
          </div>
        </Card>

      </div>
    )
  }

}
