/**
 * Created by dwiargo on 12/6/17.
 */

import React, { Component } from 'react';
import { DialogForm } from 'react-mpk';
import { TextField } from 'react-md';
import counterpart from 'counterpart';

const defaultErrorObject = () => ({
  isError:false,
  errorText:''
});

const defaultState = () => ({
  onProgress:false,
  form: {
    name: '',
    email: '',
    age: null
  },
  error:{
    name:defaultErrorObject(),
    email:defaultErrorObject(),
    age:defaultErrorObject()
  }
})

const handlerError = (container, field, message) => {
  container[field] = {
    isError: true,
    errorText: message
  }
};

const formValidation = (form, error) => {
  let isError = false;
  if(form.name === '') {
    isError = true;
    handlerError(error, 'name', counterpart.translate('sentence.form.requiredName'));
  }
  if(form.email === ''){
    isError = true;
    handlerError(error, 'email', counterpart.translate('sentence.form.requiredEmail'));
  }

  return {
    isError:isError
  };
}

class UserForm extends Component{
  constructor(){
    super();
    this.state = defaultState();
  }

  handlerChange = (field, value) => {
    let {form,error} = this.state;
    error[field] = defaultErrorObject();
    form[field] = value;
    this.setState({form:form, error:error});
  };

  onSubmit = (callback) => {
    let {form, error} = this.state;
    let newState = defaultState();
    newState.form = form;
    this.setState(newState);

    if(formValidation(form, error).isError){
      this.setState({error:error})
      callback(true, "Form is not valid");
    } else {
      setTimeout(() => {
        this.props.add(form);
        this.setState(defaultState())
        callback();
      }, 2000)
    }
  };

  onCancel = () => {
    this.setState(defaultState());
    this.props.onCancel();
  }

  render(){
    let {error} = this.state;
    return(
      <DialogForm
        title={'sentence.custom.userForm'}
        visible={this.props.visible}
        onCancel={this.onCancel}
        onSubmit={this.onSubmit}
        translate={true}
      >
        <TextField
          id="user-name"
          label={counterpart.translate("word.name")}
          value={this.state.name}
          onChange={(value) => this.handlerChange('name', value)}
          lineDirection="center"
          error={error.name.isError}
          errorText={error.name.errorText}
          disabled={this.onProgress}
          required
        />
        <TextField
          id="user-email"
          label={counterpart.translate("word.email")}
          value={this.state.email}
          onChange={(value) => this.handlerChange('email', value)}
          lineDirection="center"
          type="email"
          error={error.email.isError}
          errorText={error.email.errorText}
          disabled={this.onProgress}
          required
        />
        <TextField
          id="user-age"
          label={counterpart.translate("word.age")}
          value={this.state.age}
          onChange={(value) => this.handlerChange('age', value)}
          lineDirection="center"
          type="number"
          error={error.age.isError}
          errorText={error.age.errorText}
          disabled={this.onProgress}
          required
        />
      </DialogForm>
    )
  }
}

export default UserForm;
