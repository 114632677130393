import ApiService from '../../../../services/api.service';

class SuratSetoranService extends ApiService {
  name= 'SuratSetoran';
  path= 'ebilling/suratSetorans';

  api = {
    print: async (id) => {
      var res = await this.http.get(`${this.apiLocation + "/" + this.path}/print/${id}`, {
        responseType: 'blob'
      })

      return res;
    },
    printBulk: async (data) => {
      var res = await this.http.post(`${this.apiLocation + "/" + this.path}/print-bulk`, data)

      return res.data;
    },
    printReport: async (data) => {
      var res = await this.http.post(`${this.apiLocation + "/" + this.path}/print-report`, data)

      return res.data;
    },
    deleteSelected: async (data) => {
      var res = await this.http.post(`${this.apiLocation + "/" + this.path}/delete/checked`, data)

      return res.data;
    },
  }

  constructor() {
    super()
    this.init()
    this.initApi()
  }
}


export default new SuratSetoranService();
