import React from 'react';
import { Route, Switch } from 'react-router-dom';
import LogExportView from "../../Export/LogExport.view";

export default (props) => (
  <Switch>
    <Route path={props.match.path+'/BP_21_F/:id'} component={require('./BP_21_F/BP_21_F.form').default} />
    <Route path={props.match.path+'/BP_21_F'} component={require('./BP_21_F/BP_21_F.view').default} />
    <Route path={props.match.path+'/BP_21_TF/:id'} component={require('./BP_21_TF/BP_21_TF.form').default} />
    <Route path={props.match.path+'/BP_21_TF'} component={require('./BP_21_TF/BP_21_TF.view').default} />
    <Route path={props.match.path+'/BP_21_A1/:id'} component={require('./BP_21_A1/BP_21_A1.form').default} />
    <Route path={props.match.path+'/BP_21_A1'} component={require('./BP_21_A1/BP_21_A1.view').default} />
    <Route path={props.match.path+'/BP_21_A2/:id'} component={require('./BP_21_A2/BP_21_A2.form').default} />
    <Route path={props.match.path+'/BP_21_A2'} component={require('./BP_21_A2/BP_21_A2.view').default} />
    <Route path={props.match.path+'/BP_21_SATU_MASA/:id'} component={require('./BP_21_SATU_MASA/BP_21_SATU_MASA.form').default} />
    <Route path={props.match.path+'/BP_21_SATU_MASA'} component={require('./BP_21_SATU_MASA/BP_21_SATU_MASA.view').default} />
    <Route path={props.match.path+'/DAFTAR_BIAYA_21'} component={require('./BP_21_DAFTAR_BIAYA/BP_21_DAFTAR_BIAYA.form').default} />
    <Route path={props.match.path+'/DAFTAR_SSP_21/:id'} component={require('./BP_21_SSP/BP_21_SSP.form').default} />
    <Route path={props.match.path+'/DAFTAR_SSP_21'} component={require('./BP_21_SSP/BP_21_SSP.view').default} />
    <Route path={props.match.path+'/BP_21_SATU_TAHUN'} component={require('./BP_21_SATU_TAHUN/BP_21_SATU_TAHUN.view').default} />
    <Route path={props.match.path+'/SPT_INDUK_21'} component={require('./SPT_INDUK_21/SPT_INDUK_21.form').default} />
    <Route path={props.match.path+'/FILE_LAPOR_21'} component={require('./FileLapor21/FileLapor21.view').default} />
    <Route path={props.match.path+'/LOG_REPORT_21'} component={require('./LogReport21/LogReport21.view').default} />

    {/* IMPORT_21 */}
    <Route path={props.match.path+"/IMPORT_21"} component={require('./Import/Import.form').default} />
    <Route path={props.match.path+"/IMPORT/BP_21_TF"} component={require('./ImportBp21TF/ImportBp21TF.view').default} />
    <Route path={props.match.path+"/IMPORT/BP_21_F"} component={require('./ImportBp21F/ImportBp21F.view').default} />
    <Route path={props.match.path+"/IMPORT/BP_21_A1"} component={require('./ImportBp21A1/ImportBp21A1.view').default} />
    <Route path={props.match.path+"/IMPORT/BP_21_A1_AUTO"} component={require('./ImportBp21A1Auto/ImportBp21A1Auto.view').default} />
    <Route path={props.match.path+"/IMPORT/BP_21_A2"} component={require('./ImportBp21A2/ImportBp21A2.view').default} />
    <Route path={props.match.path+"/IMPORT/DAFTAR_BIAYA_21"} component={require('./ImportBp21DaftarBiaya/ImportBp21DaftarBiaya.view').default} />
    <Route path={props.match.path+"/IMPORT/BP_21_SATU_MASA"} component={require('./ImportBP21SatuMasa/ImportBP21SatuMasa.view').default} />
    <Route path={props.match.path+"/IMPORT/BP_21_SATU_MASA_TIDAK_LEBIH"} component={require('./ImportBP21SatuMasaTL/ImportBP21SatuMasaTL.view').default} />
    <Route path={props.match.path+"/IMPORT/SSP_21"} component={require('./ImportBp21Ssp/ImportBp21Ssp.view').default} />
    <Route path={props.match.path+"/IMPORT/INDUK_21"} component={require('./ImportBp21Induk/ImportBp21Induk.view').default} />
    {/* END IMPORT_21 */}

    {/* MASTER DATA 21_26 */}
    <Route path={props.match.path+"/master/pasal-21-upah-harian"} exact component={require('./Master21UpahHarian/Master21UpahHarian.form.view').default} />
    <Route path={props.match.path+"/master/pasal-21-upah-kumulatif"} exact component={require('./Master21UpahKumulatif/Master21UpahKumulatif.form.view').default} />
    <Route path={props.match.path+"/master/pasal-21-golongan-a2"} exact component={require('./Master21GolonganA2/Master21GolonganA2.view').default} />
    <Route path={props.match.path+"/master/pasal-21-ssp-description"} exact component={require('./Master21SspDescription/Master21SspDescription.view').default} />
    <Route path={props.match.path+"/master/pasal-21-status-karyawan-a1"} exact component={require('./Master21StatusKaryawanA1/Master21StatusKaryawanA1.view').default} />
    <Route path={props.match.path+"/master/pasal-21-status-karyawan-a2"} exact component={require('./Master21StatusKaryawanA2/Master21StatusKaryawanA2.view').default} />
    <Route path={props.match.path+"/master/pasal-21-kap"} exact component={require('./Master21Kap/Master21Kap.view').default} />
    <Route path={props.match.path+"/master/pasal-21-kjs"} exact component={require('./Master21Kjs/Master21Kjs.view').default} />
    <Route path={props.match.path+"/master/pasal-21-pasal-17-berlapis"} exact component={require('./Master21Pasal17/Master21Pasal17.view').default} />
    <Route path={props.match.path+"/master/pasal-21-pasal-17-berlapis/:id"} exact component={require('./Master21Pasal17/Master21Pasal17.form.view').default} />
    <Route path={props.match.path+"/master/pasal-21-pasal-17-berlapis/:pasal17Id/progresif"} exact component={require('./Master21Pasal17/Master21Pasal17Prog.view').default} />
    <Route path={props.match.path+"/master/pasal-21-pasal-17-berlapis/:pasal17Id/progresif/:id"} exact component={require('./Master21Pasal17/Master21Pasal17Prog.form.view').default} />
    <Route path={props.match.path+"/master/pasal-21-biaya-jabatan"} exact component={require('./Master21BiayaJabatan/Master21BiayaJabatan.form.view').default} />
    <Route path={props.match.path+"/master/pasal-21-biaya-pensiun"} exact component={require('./Master21BiayaPensiun/Master21BiayaPensiun.form.view').default} />
    <Route path={props.match.path+"/master/pasal-21-pensiun"} exact component={require('./Master21Pensiun/Master21Pensiun.form.view').default} />
    <Route path={props.match.path+"/master/pasal-21-ptkp"} exact component={require('./Master21Ptkp/Master21Ptkp.view').default} />
    <Route path={props.match.path+"/master/pasal-21-ptkp/:id"} exact component={require('./Master21Ptkp/Master21Ptkp.form.view').default} />
    <Route path={props.match.path+"/master/pasal-21-honor-pns"} exact component={require('./Master21HonorPns/Master21HonorPns.form.view').default} />
    <Route path={props.match.path+"/master/pasal-21-final-pesangon"} exact component={require('./Master21FinalPesangon/Master21FinalPesangon.view').default} />
    <Route path={props.match.path+"/master/pasal-21-final-pesangon/:id"} exact component={require('./Master21FinalPesangon/Master21FinalPesangon.form.view').default} />
    {/* END MASTER DATA 21_26 */}

    {/* EXPORT_21 */}
    <Route path={props.match.path+"/EXPORT_CSV_21"} component={require('./ExportCsv21/ExportCsv21.view').default} />
    {/* END EXPORT_21 */}

    {/* log export */}
    <Route path={props.match.path+"/logExport/:pasal"} render={(props) => (
      <LogExportView key={props.match.params.pasal} {...props} />
    )} />
    {/* END log export */}

    {/* LOG 21 */}
    <Route path={props.match.path+"/LOG_EXPORT_CSV_21"} component={require('./ExportCsv21Log/ExportCsv21Log.view').default} />
    <Route path={props.match.path+"/LOG_EMAIL"} component={require('./Email21Log/Email21Log.view').default} />
    <Route path={props.match.path+'/LOG_FILE_LAPOR_21'} component={require('./FileLapor21Log/FileLapor21Log.view').default} />
    {/* END LOG 21 */}

    {/* SETTING 21 */}
    <Route path={props.match.path+"/SETTING_NO_BUPOT_21"} component={require('./SettingNoBupot21/SettingNoBupot21.form').default} />
    {/* END SETTING 21 */}

    <Route path={props.match.path+'/'} component={function() {return <div/>}} />
  </Switch>
)
