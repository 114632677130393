import ApiService from '../../../../../services/api.service';

class Master21SspDescriptionService extends ApiService {
  name= 'Master21SspDescription';
  path= 'master/deskripsi-ssp-21';

  constructor() {
    super();
    this.init()
  }
}


export default new Master21SspDescriptionService();

