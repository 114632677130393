import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  Tab,
  Tabs,
  SelectionControlGroup,
  Checkbox,
  Avatar,
  FontIcon,
  List,
  ListItem,
  Paper,
  Button
} from 'react-md';

import {validation, Textfield, Searchfield, Searchfieldv2, TextfieldMask, Switch, Datepicker, Radio, convert} from '../../../../../../components/form';
import UtilService from '../../../../../../services/utils.service'
import FormView from '../../../../../../components/entity/form.view';
import DialogView from '../../../../../../components/entity/dialog.view';
import Service from './SPT23.service'
import OrganizationService from './../../Organization/Organization.service'
import moment from 'moment'
import counterpart from 'counterpart'
import BlokBunifikasiService from './../../../../pph/21_26/BlokBunifikasi/BlokBunifikasi.service'

@reduxForm({form: 'Espt23Form', destroyOnUnmount: true})
@connect((state) => ({
  formData:state.form.Espt23Form,
  user: state.auth.user,
  // EPPT CORE
  auth: state.auth.company
  // EPPT CORE
}))
export default class Espt23FormView extends FormView {
  service=Service
  viewType=2;

  initialData={
    status: 'CREATED',
    rev: 0,
    organization: {}
  }

  constructor(props) {
    super(props);

    var date = new Date();
    let yearOptions = [];
    for(var i=0; i<11; i++) {
      yearOptions.push({
        id: date.getFullYear()-i,
        name: date.getFullYear()-i
      })
    }

    this.state = {
      ...this.state,
      bulanOptions: [
        {id: 1, name: 'Januari'},
        {id: 2, name: 'Februari'},
        {id: 3, name: 'Maret'},
        {id: 4, name: 'April'},
        {id: 5, name: 'Mei'},
        {id: 6, name: 'Juni'},
        {id: 7, name: 'Juli'},
        {id: 8, name: 'Agustus'},
        {id: 9, name: 'September'},
        {id: 10, name: 'Oktober'},
        {id: 11, name: 'November'},
        {id: 12, name: 'Desember'}
      ],
      yearOptions
    }
  }

  componentDidMount() {
    this.initData()
  }

  async beforeSave(val) {
    val.npwp    = val.organization.npwp
    val.name    = val.organization.name
    val.address = val.organization.address
    val.phone   = val.organization.phone
    val.email   = val.organization.email
    val.city  = val.organization.city
    val.sebelumnya = " "
    val.blockBunifikasiMonth = 4
    val.blockBunifikasiYear = 2022
    try {
      let res = await BlokBunifikasiService.api.getSettingAccess(val.organization.companyId)
      if(res.data.active){
        val.blockBunifikasiMonth = res.data.bulan
        val.blockBunifikasiYear = res.data.tahun
      }
    } catch(e){}
    return val;
  }

  async initData() {
    if(this.props.match.params.sptId == 'new') {
      this.initialData.organization = this.props.organization;
      this.props.initialize(this.initialData);
    } else {
      try {
        let res = await this.service.api.findOne(this.props.match.params.sptId);
        var initialData = {}
        initialData = res.data
        var isSupport = false
        if(this.props.user && this.props.user.role && this.props.user.role.name == "SUPPORT_AGENT") isSupport = true
        if(isSupport){
          let organization = await OrganizationService.api.getAll({
            user: this.props.user,
            'npwp.equals': res.data.npwp
          })
          initialData.organization = organization.data[0]
        } else {
          let organization = await OrganizationService.api.findOne(res.data.organization.id)
          initialData.organization = organization.data
        }
        this.props.initialize(initialData);
      } catch (e){ console.log(e) }
    }
  }

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;
    window.hahaha = counterpart
    var saveWord = counterpart.translate('word.save');
    if(this.props.match.params.id == 'new') saveWord = counterpart.translate('word.create')
    if(this.props.match.params.sptId == 'new') saveWord = counterpart.translate('word.create')
    var editForm = false
    if(this.props.match.params.sptId != 'new') editForm = true

    return (
      <div>
        <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>{counterpart.translate('word.back')}</Button>
        {!editForm &&
          <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>{saveWord}</Button>
        }
      </div>
    )
  }

  formView() {
    var formData = {}
    if(this.props.formData) formData = this.props.formData.values;
    var organization = {};
    if(formData && formData.organization) organization = formData.organization;
    var org = []
    if(this.props.auth && this.props.auth.organizations) org = this.props.auth.organizations
    var isSupport = false
    if(this.props.user && this.props.user.role && this.props.user.role.name == "SUPPORT_AGENT") isSupport = true
    console.log(this.props.auth, "TELL ME")
    return (
        <Card>
          <div className="md-grid">
            {!isSupport &&
              <Field
                label     = 'Organization'
                name      = 'organization'
                id        = "spt2126-organization"
                className = "md-cell md-cell--12"
                component = {Searchfield}
                valueField = 'parent'
                options   = {org}
                validate  = {validation.required}
                itemTemplate = {function(d) {
                  let nameAndAlias = ""
                  if (d.name)
                    nameAndAlias = d.name
                  if (d.aliasName)
                    nameAndAlias += " - " + d.aliasName
                  return {
                    primaryText  : nameAndAlias,
                    secondaryText: d.npwp,
                    onClick      : () => {
                      if(!this.props.disabled){
                        this.handleItemClick(d); this.hide()
                      }
                    }
                  }
                }}
              />
              // <Field
              //     label         = 'Organization'
              //     name          = 'organization'
              //     className     = "md-cell md-cell--12"
              //     component     = {Searchfieldv2}
              //     valueField    = 'parent'
              //     remoteSearch  = {true}
              //     apiPath       = 'getOrganizationHost'
              //     service       = {OrganizationService}
              //     validate      = {validation.required}
              //     renderFirst   = {true}
              //     validate      = {validation.required}
              //     coreSearch    = {true}
              //     npwpSearch    = {true}
              //     itemTemplate  = {function(d) {
              //       let nameAndAlias = ""
              //       if (d.name)
              //         nameAndAlias = d.name
              //       if (d.aliasName)
              //         nameAndAlias += " - " + d.aliasName
              //       return {
              //         primaryText  : nameAndAlias,
              //         secondaryText: d.npwp,
              //         onClick      : () => {
              //           if(!this.props.disabled){
              //             this.handleItemClick(d); this.hide()
              //           }
              //         }
              //       }
              //     }}
              // />
            }
            {isSupport &&
              <Field
                  label         = 'Organization'
                  name          = 'organization'
                  className     = "md-cell md-cell--12"
                  component     = {Searchfieldv2}
                  valueField    = 'parent'
                  remoteSearch  = {true}
                  apiPath       = 'getAll'
                  params        = {{ user: this.props.user }}
                  service       = {OrganizationService}
                  validate      = {validation.required}
                  renderFirst   = {true}
                  validate      = {validation.required}
                  coreSearch    = {true}
                  npwpSearch    = {true}
                  itemTemplate  = {function(d) {
                    let nameAndAlias = ""
                    if (d.name)
                      nameAndAlias = d.name
                    if (d.aliasName)
                      nameAndAlias += " - " + d.aliasName
                    return {
                      primaryText  : nameAndAlias,
                      secondaryText: d.npwp,
                      onClick      : () => {
                        if(!this.props.disabled){
                          this.handleItemClick(d); this.hide()
                        }
                      }
                    }
                  }}
              />
            }
            <div className="md-cell md-cell--6">
            <Field
                label='Bulan'
                name='month'
                id="spt2126-pasal"
                component={Searchfield}
                options={this.state.bulanOptions}
                validate={validation.required}
            />
            <Field
                label='Tahun'
                name='year'
                id="spt2126-pasal"
                component={Searchfield}
                options={this.state.yearOptions}
                validate={validation.required}
            />
            </div>

            <div className="md-cell md-cell--6">

            <List className="md-paper md-paper--1" >
              <Subheader primaryText="ORGANISASI" />
              <ListItem
                secondaryText   = {organization.name}
                primaryText = "NAME"
              />
              <ListItem
                secondaryText   = {organization.npwp}
                primaryText = "NPWP"
              />
              <ListItem
                secondaryText   = {organization.city}
                primaryText = "CITY"
              />
              <ListItem
                secondaryText   = {organization.postalCode}
                primaryText = "POSTAL CODE"
              />
              <ListItem
                secondaryText   = {organization.email}
                primaryText = "EMAIL"
              />
              <ListItem
                secondaryText   = {organization.phone}
                primaryText = "PHONE"
              />
              <ListItem
                secondaryText   = {organization.address}
                primaryText = "ADDRESS"
              />
              <ListItem
                secondaryText   = {organization.picName}
                primaryText = "PIC NAME"
              />
              <ListItem
                secondaryText   = {organization.picPhone}
                primaryText = "PIC PHONE"
              />
              <ListItem
                secondaryText   = {organization.picPosition}
                primaryText = "PIC POSITION"
              />
              {/* <ListItem
                secondaryText   = {(organization.isPemungut) ? 'YES' : 'NO'}
                primaryText = "PEMUNGUT"
              /> */}
            </List>
            </div>
        </div>
      </Card>
    )
  }

}
