import React, { Component } from 'react';
import { Field, reduxForm , propTypes, formValueSelector } from 'redux-form';
import { validation, Textfield, TextfieldMask, Datepicker, convert } from '../../../../../../components/form';
import Service from './ImportSSP4a2service';
import ImportDialogTemplateView from '../../../../../../components/entity/import.dialog.template.view2';
ImportDialogTemplateView

@reduxForm({form: 'ImportSSP4a2-form', destroyOnUnmount: false})
export default class ImportSSP4a2DialogView extends ImportDialogTemplateView {
  pasal="pasal4"
  service=Service
}
