import ApiService from '../../../../../services/api.service';

class SettingEmailService extends ApiService {
  name= 'SettingEmail';
  path= 'setting/mail';
  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api = {
    getByCompany: async (companyId)=> {
      return await this.http.get(`${this.apiLocation}/${this.path}/${companyId}/company`)
    },
    updateByCompany: async (formData)=> {
      return await this.http.put(`${this.apiLocation}/${this.path}`, formData)
    }
  }
}


export default new SettingEmailService();

