import React, { Component } from 'react'; import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {TabsContainer, Tabs, Tab, Button} from 'react-md';
import { Field, initialize, reduxForm } from 'redux-form';
import {validation, FileInputNext, Searchfieldv2, Textfield, Searchfield, TextfieldMask, Datepicker, Radio, convert, CheckboxSelection, Multiselect} from '../../../../../../src/components/form';
import moment from 'moment'

import LawanTransaksi26Service from './LawanTransaksi26.service';
import ListView from '../../../../../components/entity/ListViewEbupot';
import AuthService from '../../../../../services/auth.service'
import ListViewEbupot from '../../../../../components/entity/ListViewEbupot';

@reduxForm({form: 'Lt26Ebupot', destroyOnUnmount: false})
@connect((state)=> ({
  ...LawanTransaksi26Service.stateConnectSetting()(state)
}), LawanTransaksi26Service.actionConnectSetting())
export default class LawanTransaksi26View extends ListViewEbupot {
  service=LawanTransaksi26Service;
  editDialog = false;
  addDialog = false;

  beforeFetch(params) {
    delete params.startDate
    delete params.endDate
    delete params.column
    delete params.total
    delete params.keyword
    params.sortBy = "id"
    params.sort = "id,ASC"
    // params.sortBy = 'id'
    // params.sort = 1
  }

  addItem = () => {
    if(this.addDialog) {
      this.setState({showForm:true, formData: {}})
    } else {
      if(this.rootPath) {
        this.props.history.push(this.rootPath+"/new");
      } else {
        this.props.history.push(''+'lawan-transaksi-26'+"/new");
      }

    }
  }

  editItem = (item) => {
    if(this.editDialog) {
      this.showFormDialog(item);
    } else {
      if(this.rootPath) {
        this.props.history.push(this.rootPath+"/"+item.id)
      } else {
        this.props.history.push(''+'lawan-transaksi-26'+"/"+item.id);
      }
    }
  }

  plainTable(){ 
    if(this.props.dialogMode){
      return true
    } else {
      return false
    }
  }

  _tableActionsFunc(i){
    if(this.props.dialogMode){
      return []
    } else {
      return [
        {
          label:"More Info",
          iconClassName:"mdi mdi-bell",
          onClick: (item) => this.editItem(item)
        },
      ]
    }
  }

  async handleItemClick(d){
    try { this.props.parent.props.change("nama", d.nama)               } catch(e){  }   
    try { this.props.parent.props.change("alamat", d.alamat)           } catch(e){  }   
    try { this.props.parent.props.change("tin", d.tin)                 } catch(e){  }     
    try { this.props.parent.props.change("email", d.email)             } catch(e){  }       
    try { this.props.parent.props.change("dob", d.dob)                 } catch(e){  }   
    try { this.props.parent.props.change("negara", d.negara)           } catch(e){  }         
    try { this.props.parent.props.change("noPassport", d.noPassport)   } catch(e){  }           
    try { this.props.parent.props.change("noKitas", d.noKitas)         } catch(e){  }        
    this.props.dialogProps._resolve(true)
  }

  _tableColumns(){
    var columns = this.columns
    if(this.props.dialogMode){
      var cekPilih = columns.filter((d)=> {
        return d.extended == true
      })
      if(cekPilih.length == 0){
        columns.unshift({
          extended: true, label: "word.pilih", searchField: "pilih", value: (d)=> {
            return <Button onClick={this.handleItemClick.bind(this, d)} flat primary label="Pilih"></Button>
          }, type: 'func', isDefaultSort:false, isSortable:true, show:true, isSearchable:true
        })
      }
    }
    return columns
  }

  columns=LawanTransaksi26Service.columns

  barActions(){
    var _barActions = [
      {
        label:'word.create',
        iconClassName:'mdi mdi-plus',
        onClick:() => this.addItem()
      },
      {
        label:'word.refresh',
        iconClassName:'mdi mdi-refresh',
        onClick:() => this.fetchData()
      },
    ]
    if(this.props.dialogMode) _barActions = []
    return _barActions
  }

  // NEW TAB MENU
  async hapusPencarian(){
    try {
      try {
        localStorage.removeItem(`search_${this.service.name}`)
      } catch(e){}
      var params = Object.assign({}, params=this.props.table.params)
      this.fetchData(params)
      await this.props.dispatch(initialize(this.service.name+'_search', {}));
    } catch(e){
      console.log(e, "Search Error ...")
    }
  }

  async handleSearching(values){
    try {
      var formData = this.props.tableFilter2
      var params = Object.assign({}, params=this.props.table.params)

      if(formData.nama){
        params['nama.contains'] = formData.nama
      } else { delete params['nama.contains'] }

      if(formData.dob){
        params['dob.equals'] = formData.dob
      } else { delete params['dob.equals'] }

      if(formData.email){
        params['email.contains'] = formData.email
      } else { delete params['email.contains'] }

      if(formData.tin){
        params['tin.contains'] = formData.tin
      } else { delete params['tin.contains'] }

      if(formData.tin){
        params['noKitas.contains'] = formData.noKitas
      } else { delete params['noKitas.contains'] }

      if(formData.tin){
        params['noPassport.contains'] = formData.noPassport
      } else { delete params['noPassport.contains'] }

      if(formData.negara){
        params['negara.contains'] = formData.negara
      } else { delete params['negara.contains'] }

      if(formData.alamat){
        params['alamat.contains'] = formData.alamat
      } else { delete params['alamat.contains'] }

      if(formData.createdBy){
        params['createdBy.contains'] = formData.createdBy
      } else { delete params['createdBy.contains'] }

      if(formData.lastModifiedBy){
        params['lastModifiedBy.contains'] = formData.lastModifiedBy
      } else { delete params['lastModifiedBy.contains'] }

      if(formData.createdDate1){
        params['createdDate.greaterOrEqualThan'] = moment(formData.createdDate1).format()
      } else { delete params['createdDate.greaterOrEqualThan'] }

      if(formData.createdDate2){
        params['createdDate.lessOrEqualThan'] = moment(formData.createdDate2).format()
      } else { delete params['createdDate.lessOrEqualThan'] }

      if(formData.lastModifiedDate1){
        params['lastModifiedDate.greaterOrEqualThan'] = moment(formData.lastModifiedDate1).format()
      } else { delete params['lastModifiedDate.greaterOrEqualThan'] }

      if(formData.lastModifiedDate2){
        params['lastModifiedDate.lessOrEqualThan'] = moment(formData.lastModifiedDate2).format()
      } else { delete params['lastModifiedDate.lessOrEqualThan'] }
      params.onSearch = true
      localStorage.setItem("formData_search", JSON.stringify(formData))
      this.fetchData(params)
    } catch(e){
      iziToast.info({
        title: "Pencarian",
        message: "Data tidak ditemukan, " + e.message
      })
    }
  }

  defaultSearchForm(){
    const { handleSubmit, submitting, valid, pristine } = this.props;
    return (
      <div className="md-grid" style={{ overflow: 'auto' }}>
        <Field
          label     = 'Nama'
          name      = 'nama'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'Tanggal Lahir'
          name      = 'dob'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'Email'
          name      = 'email'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'Tin'
          name      = 'tin'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'No Kitas'
          name      = 'noKitas'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'No Passport'
          name      = 'noPassport'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'Negara'
          name      = 'negara'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'Alamat'
          name      = 'alamat'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'Dibuat Oleh'
          name      = 'createdBy'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'Diubah Oleh'
          name      = 'lastModifiedBy'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'Dibuat dari'
          name      = 'createdDate1'
          className = "md-cell md-cell--12"
          component = {Datepicker}
        />
        <Field
          label     = 'Dibuat ke'
          name      = 'createdDate2'
          className = "md-cell md-cell--12"
          component = {Datepicker}
        />
        <Field
          label     = 'Diubah dari'
          name      = 'lastModifiedDate1'
          className = "md-cell md-cell--12"
          component = {Datepicker}
        />
        <Field
          label     = 'Diubah ke'
          name      = 'lastModifiedDate2'
          className = "md-cell md-cell--12"
          component = {Datepicker}
        />
        <Button raised primary className="md-cell md-cell--12" onClick={handleSubmit(this.handleSearching.bind(this))}>Cari</Button>
        <hr />
        <Button raised primary className="md-cell md-cell--12" onClick={handleSubmit(this.hapusPencarian.bind(this))}>Hapus</Button>
      </div>
    )
  }
  // yg ditambah

}
