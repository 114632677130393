import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {Field, getFormValues, reduxForm} from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../components/form';

import ImportAll15Service from './ImportAll15.service';
import ListView from '../../../components/entity/listView';
import ColumnService from '../../../services/column.service';
import axios from 'axios';
import izitoast from 'izitoast';
import download from "downloadjs";
import ListViewImport from '../../../components/entity/ListViewImport';
import formDialog from './ImportAll15.dialog.view'

@reduxForm({form: 'IMPORT_15_Form', destroyOnUnmount: true})
@connect((state) => ({
  ...ImportAll15Service.stateConnectSetting()(state),
  formData: getFormValues('IMPORT_15_Form')(state),
  user: state.auth.user,
  // EPPT CORE
  auth: state.auth.company
  // EPPT CORE
}), ImportAll15Service.actionConnectSetting())
export default class ImportAll15View extends ListViewImport {
    service=ImportAll15Service
    FormDialog=formDialog
    importPath='/pasal15/upload/csv/bp'
    pasal='pasal15'

    static contextTypes = {
        showDialog: PropTypes.func.isRequired,
    };

    componentDidMount(){
      this.props.change('no', false)
      this.props.change('migration', false)
      this.props.change('replace', false)
    }

    async handleDownloadTemplate() {
        var showDialog = await this.context.showDialog(()=> ({
          title: 'Download Template',
          initialValue: {
              category: null
          },
          width: 800,
          height: 600,
          contentStyle: {
            height: 600
          },
          okText: 'Download',
          text: (
            <div>
                <this.ig.Field
                    label='Kategori'
                    name='category'
                    className="md-cell md-cell--12"
                    component={this.ig.Searchfield}
                    options={[
                        { id: 'BP', name: 'BP 15' },
                        { id: 'BP24', name: 'BP 24' },
                        { id: 'SSP', name: 'SSP Pasal 15' },
                        { id: 'SSP-SENDIRI', name: 'SSP Sendiri Pasal 15' },
                        { id: 'PBK', name: 'PBK Pasal 15' },
                        { id: 'LT', name: 'Lawan Transaksi Pasal 15' },
                        { id: 'INDUK', name: 'Induk' },
                        { id: 'SIGNER_15', name: 'Signer Pasal 15' },
                    ]}
                    validate={validation.required}
                />
            </div>
          )
        }))
        if(showDialog){
            var report = await this.service.api.downloadTemplate(showDialog.category)
            window.open(report.data.url, `TemplatePasal15${showDialog.category}.csv`);
        }
    }

    searchForm() {
        return (
          <this.SearchContainer>
            <this.ig.Field
              label='Kategori'
              name='category__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Filename'
              name='filename__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Pesan'
              name='message__contains'
              className="md-cell md-cell--12"
              component={this.ig.Textfield}
            />
            <this.ig.Field
              label='Status'
              name='status__contains'
              className="md-cell md-cell--12"
              component={this.ig.Searchfield}
              options={[
                  { id: 'COMPLETED', name: 'COMPLETED' },
                  { id: 'FAILED', name: 'FAILED' }
              ]}
            />
          </this.SearchContainer>
        )
    }

    import(){
        var isBp = false
        var isSigner = false
        if(this.props.formData && this.props.formData.category){
            var category = this.props.formData.category
            if(category === 'BP' || category === 'BP24') isBp = true
            if(category === 'SIGNER_15') isSigner = true
        }
        const { handleSubmit, submitting, valid, pristine } = this.props;
        return (
            <div className='mpk-layout column fill'>
              <this.ImportDialogResult dataResults={this.state.importResults} visible={this.state.showImportResult} onHide={()=> {this.setState({showImportResult: false})}} />
              <div className='flex'>
                <div className='md-grid'>
                  <div className="md-cell md-cell--12">
                    <Field
                      label='Select a File'
                      name='file'
                      multiple
                      twoLines
                      component={Fileinput}
                      validate={validation.required}
                    />
                  </div>
                  <this.ig.Field
                    label='Kategori'
                    name='category'
                    className="md-cell md-cell--12"
                    component={this.ig.Searchfield}
                    options={[
                        { id: 'BP', name: 'BP 15' },
                        { id: 'BP24', name: 'BP 24' },
                        { id: 'SSP', name: 'SSP Pasal 15' },
                        { id: 'SSP-SENDIRI', name: 'SSP Sendiri Pasal 15' },
                        { id: 'PBK', name: 'PBK Pasal 15' },
                        { id: 'LT', name: 'Lawan Transaksi Pasal 15' },
                        { id: 'INDUK', name: 'Induk' },
                        { id: 'SPT', name: 'SPT' },
                        { id: 'SIGNER_15', name: 'Signer Pasal 15' },
                    ]}
                    validate={validation.required}
                    onChange={(e, val)=> {
                      this.props.change('type', val)
                    }}
                  />
                  {isBp &&
                    <Field
                      label='Aktifkan Penomoran Bukti Potong Otomatis ?'
                      name='no'
                      component={Switch2}
                      className="md-cell md-cell--12"
                    />
                  }
                  <Field
                    label='Import Migrasi'
                    name='migration'
                    component={Switch2}
                    className="md-cell md-cell--12"
                  />
                  {isSigner &&
                    <Field
                      label='Replace'
                      name='replace'
                      component={Switch2}
                      className="md-cell md-cell--12"
                    />
                  }
                  <div className="md-cell md-cell--12">
                      <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Import</Button>
                  </div>
                </div>
              </div>
              {this.state.onProgress ? (
                  <this.LinearProgress style={{margin: 0}} query={true} />
                ) : (null)}
              {this.showAutomatisTab &&
                <Tabs className='mpk-tab-white' onTabChange={this.handleTabChange.bind(this)} tabId="ImportTab">
                    <Tab label="Manual">

                    </Tab>
                    <Tab label="Otomatis">

                    </Tab>
                </Tabs>
              }
            </div>
        )
      }

}
