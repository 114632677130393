import {
  auth, table, global, toast, dialog, tmpObject
} from 'react-mpk/redux/reducers';
import { reducer as formReducer } from 'redux-form'
import image from './imageReducer';

import {combineReducers} from 'redux';

const entityReducer = combineReducers({
  ImportEbilling: require('../../modules/Main/Merge/ImportEbilling.service').default.reducer,
  ImportAll21: require('../../modules/Main/Merge/ImportAll21.service').default.reducer,
  ImportAll23: require('../../modules/Main/Merge/ImportAll23.service').default.reducer,
  ImportAll4: require('../../modules/Main/Merge/ImportAll4a2.service').default.reducer,
  ImportAll22: require('../../modules/Main/Merge/ImportAll22.service').default.reducer,
  ImportAll15: require('../../modules/Main/Merge/ImportAll15.service').default.reducer,
  LogExportPdf: require('../../modules/Main/Merge/LogExportPdf/LogExportPdf.service').default.reducer,
  LogExportPdfChecklist: require('../../modules/Main/Merge/LogExportPdfChecklist/LogExportPdfChecklist.service').default.reducer,
  LogExportEbupot: require('../../modules/Main/Merge/LogExportEbupot/LogExportEbupot.service').default.reducer,
  LogExportCsv: require('../../modules/Main/Merge/LogExportCsv/LogExportCsv.service').default.reducer,
  LogSummary: require('../../modules/Main/Merge/LogSummary/LogSummary.service').default.reducer,

  OrganizationSupport: require('../../modules/Support/Organization/Organization.service').default.reducer,
  SignerSupport: require('../../modules/Support/Signer/Signer.service').default.reducer,

  LogExport: require('../../modules/Main/Export/LogExport.service').default.reducer,
  LogEfiling: require('../../modules/Main/Efiling/LogEfiling/LogEfiling.service').default.reducer,
  LogEfiling4: require('../../modules/Main/Efiling/LogEfiling4/LogEfiling4.service').default.reducer,
  validationCsv: require('../../modules/Main/Import/ValidationCsv.service').default.reducer,
  user: require('../../modules/Main/Administrator/User/User.service').default.reducer,
  company: require('../../modules/Main/Administrator/Company/Company.service').default.reducer,
  organization: require('../../modules/Main/Administrator/Organization/Organization.service').default.reducer,
  organizationUser: require('../../modules/Main/Administrator/Organization/User/Organization.user.service').default.reducer,
  access: require('../../modules/Main/Administrator/Access/Access.service').default.reducer,
  AccessControl: require('../../modules/Main/Administrator/AccessControl/AccessControl.service').default.reducer,
  Permissions: require('../../modules/Main/Administrator/Permissions/Permissions.service').default.reducer,
  Access: require('../../modules/Main/Administrator/Access/Access.service').default.reducer,
  templateRole: require('../../modules/Main/Administrator/TemplateRole/TemplateRole.service').default.reducer,
  LoggingEvent: require('../../modules/Main/Administrator/LoggingEvent/LoggingEvent.service').default.reducer,
  LoggingEventException: require('../../modules/Main/Administrator/LoggingEvent/LoggingEventException.service').default.reducer,
  LoggingEventProperty: require('../../modules/Main/Administrator/LoggingEvent/LoggingEventProperty.service').default.reducer,
  LogApp: require('../../modules/Main/Administrator/LogApp/LogApp.service').default.reducer,

  KeyService: require('../../modules/Main/Setting/Key/Key.service').default.reducer,
  masterKpp: require('../../modules/Main/Master/MasterKpp/MasterKpp.service').default.reducer,
  masterPesangon: require('../../modules/Main/Master/MasterPesangon/MasterPesangon.service').default.reducer,
  masterPtkp: require('../../modules/Main/Master/MasterPtkp/MasterPtkp.service').default.reducer,
  masterBiayaJabatan: require('../../modules/Main/Master/MasterBiayaJabatan/MasterBiayaJabatan.service').default.reducer,
  masterTarifPasal17Berlapis: require('../../modules/Main/Master/MasterTarifPasal17Berlapis/MasterTarifPasal17Berlapis.service').default.reducer,
  masterBatasUpahHarian: require('../../modules/Main/Master/MasterBatasUpahHarian/MasterBatasUpahHarian.service').default.reducer,
  masterUUPajakPenghasilan: require('../../modules/Main/Master/MasterUUPajakPenghasilan/MasterUUPajakPenghasilan.service').default.reducer,
  masterLawanTransaksiA1: require('../../modules/Main/Master/MasterLawanTransaksiA1/MasterLawanTransaksiA1.service').default.reducer,
  masterLawanTransaksiA2: require('../../modules/Main/Master/MasterLawanTransaksiA2/MasterLawanTransaksiA2.service').default.reducer,
  masterLawanTransaksiPp: require('../../modules/Main/Master/MasterLawanTransaksiPp/MasterLawanTransaksiPp.service').default.reducer,
  masterLawanTransaksi23: require('../../modules/Main/Master/MasterLawanTransaksi23/MasterLawanTransaksi23.service').default.reducer,
  masterLawanTransaksi4a2: require('../../modules/Main/Master/MasterLawanTransaksi4a2/MasterLawanTransaksi4a2.service').default.reducer,
  masterLawanTransaksi22: require('../../modules/Main/Master/MasterLawanTransaksi22/MasterLawanTransaksi22.service').default.reducer,
  masterLawanTransaksi15: require('../../modules/Main/Master/MasterLawanTransaksi15/MasterLawanTransaksi15.service').default.reducer,

  // Module Ebupot
  //User: require('../../modules/Main/Modules/EbupotModule/User/User.service').default.reducer,
  Application: require('../../modules/Main/Modules/EbupotModule/Application/Application.service').default.reducer,
  Client: require('../../modules/Main/Modules/EbupotModule/Client/Client.service').default.reducer,
  Organization: require('../../modules/Main/Modules/EbupotModule/Organization/Organization.service').default.reducer,
  ApplicationOrganization: require('../../modules/Main/Modules/EbupotModule/ApplicationOrganization/ApplicationOrganization.service').default.reducer,
  Provinces: require('../../modules/Main/Modules/EbupotModule/Provinces/Provinces.service').default.reducer,
  KodeBuktiPotong: require('../../modules/Main/Modules/EbupotModule/KodeBuktiPotong/KodeBuktiPotong.service').default.reducer,
  KodeNegara: require('../../modules/Main/Modules/EbupotModule/KodeNegara/KodeNegara.service').default.reducer,
  Signer: require('../../modules/Main/Modules/EbupotModule/Signer/Signer.service').default.reducer,
  JenisDokumen: require('../../modules/Main/Modules/EbupotModule/JenisDokumen/JenisDokumen.service').default.reducer,
  Regencies: require('../../modules/Main/Modules/EbupotModule/Regencies/Regencies.service').default.reducer,
  Districts: require('../../modules/Main/Modules/EbupotModule/Districts/Districts.service').default.reducer,
  Villages: require('../../modules/Main/Modules/EbupotModule/Villages/Villages.service').default.reducer,
  KodeBuktiPotong26: require('../../modules/Main/Modules/EbupotModule/KodeBuktiPotong26/KodeBuktiPotong26.service').default.reducer,
  LogSubmit: require('../../modules/Main/Modules/EbupotModule/LogSubmit/LogSubmit.service').default.reducer,
  LogCheck: require('../../modules/Main/Modules/EbupotModule/LogCheck/LogCheck.service').default.reducer,
  LogFile: require('../../modules/Main/Modules/EbupotModule/LogFile/LogFile.service').default.reducer,
  LogFileCsv: require('../../modules/Main/Modules/EbupotModule/LogFile/LogFileCsv.service').default.reducer,
  LogConvertedExcel: require('../../modules/Main/Modules/EbupotModule/LogConvertedExcel/LogConvertedExcel.service').default.reducer,
  LogFileValidasi: require('../../modules/Main/Modules/EbupotModule/LogFileValidasi/LogFileValidasi.service').default.reducer,
  Espt23: require('../../modules/Main/Modules/EbupotModule/PPh/SPT23/SPT23.service').default.reducer,
  Espt23History: require('../../modules/Main/Modules/EbupotModule/PPh/SPT23History/SPT23History.service').default.reducer,
  BP23: require('../../modules/Main/Modules/EbupotModule/PPh/SPT23In/bp23/bp23.service').default.reducer,
  BP26: require('../../modules/Main/Modules/EbupotModule/PPh/SPT23In/bp26/bp26.service').default.reducer,
  Bupot23In: require('../../modules/Main/Modules/EbupotModule/PPh/SPT23In/Bupot23In/Bupot23In.service').default.reducer,
  Bupot26In: require('../../modules/Main/Modules/EbupotModule/PPh/SPT23In/Bupot26In/Bupot26In.service').default.reducer,
  Bupot23InPembetulan: require('../../modules/Main/Modules/EbupotModule/PPh/SPT23In/Bupot23InPembetulan/Bupot23InPembetulan.service').default.reducer,
  Bupot26InPembetulan: require('../../modules/Main/Modules/EbupotModule/PPh/SPT23In/Bupot26InPembetulan/Bupot26InPembetulan.service').default.reducer,
  Bupot23InEdit: require('../../modules/Main/Modules/EbupotModule/PPh/SPT23In/Bupot23InEdit/Bupot23InEdit.service').default.reducer,
  Bupot26InEdit: require('../../modules/Main/Modules/EbupotModule/PPh/SPT23In/Bupot26InEdit/Bupot26InEdit.service').default.reducer,
  Bupot23: require('../../modules/Main/Modules/EbupotModule/Bupot23/Bupot23.service').default.reducer,
  Bupot26: require('../../modules/Main/Modules/EbupotModule/Bupot26/Bupot26.service').default.reducer,
  Bupot23History: require('../../modules/Main/Modules/EbupotModule/Bupot23History/Bupot23History.service').default.reducer,
  Bupot26History: require('../../modules/Main/Modules/EbupotModule/Bupot26History/Bupot26History.service').default.reducer,
  Bupot23Email: require('../../modules/Main/Modules/EbupotModule/Bupot23Email/Bupot23Email.service').default.reducer,
  Bupot26Email: require('../../modules/Main/Modules/EbupotModule/Bupot26Email/Bupot26Email.service').default.reducer,
  LawanTransaksi23: require('../../modules/Main/Modules/EbupotModule/LawanTransaksi23/LawanTransaksi23.service').default.reducer,
  LawanTransaksi26: require('../../modules/Main/Modules/EbupotModule/LawanTransaksi26/LawanTransaksi26.service').default.reducer,
  AllEbupot23:  require('../../modules/Main/Modules/EbupotModule/AllEbupot23/AllEbupot23.service').default.reducer,
  AllEbupot26:  require('../../modules/Main/Modules/EbupotModule/AllEbupot26/AllEbupot26.service').default.reducer,
  SspEbupot: require('../../modules/Main/Modules/EbupotModule/PPh/SPT23In/ssp/ssp.service').default.reducer,
  KapEbupot: require('../../modules/Main/Modules/EbupotModule/PPh/SPT23In/ssp/masterKap.service').default.reducer,
  KjsEbupot: require('../../modules/Main/Modules/EbupotModule/PPh/SPT23In/ssp/masterKjs.service').default.reducer,
  PbkEbupot: require('../../modules/Main/Modules/EbupotModule/PPh/SPT23In/pbk/pbk.service').default.reducer,
  IndukEbupot: require('../../modules/Main/Modules/EbupotModule/PPh/SPT23In/induk/induk.service').default.reducer,
  Bpe: require('../../modules/Main/Modules/EbupotModule/PPh/Bpe/Bpe.service').default.reducer,
  // Module Ebupot

  // FLOW
  mainFlow: require('../../modules/Main/Flow/MainFlow/MainFlow.service').default.reducer,
  // END OF FLOW

  // IMPORT_USER
  ImportUser: require('../../modules/Main/Import/ImportUser/ImportUser.service').default.reducer,
  // END OF IMPORT_USER

  // IMPORT_ORGANIZATION
  ImportOrganization: require('../../modules/Main/Import/ImportOrganization/ImportOrganization.service').default.reducer,
  ImportOrganizationUser: require('../../modules/Main/Import/ImportOrganizationUser/ImportOrganizationUser.service').default.reducer,
  // END OF IMPORT_ORGANIZATION

  // ImportEfilling: require('../../modules/Main/Import/ImportEfilling/ImportEfilling.service').default.reducer,

  // 2126 ALL
  PPh4a2EnableDeposito: require('../../modules/Main/pph/21_26/UserAccess/PPh4a2EnableDeposito.service').default.reducer,
  UserAccess: require('../../modules/Main/pph/21_26/UserAccess/UserAccess.service').default.reducer,
  BlokBunifikasi: require('../../modules/Main/pph/21_26/BlokBunifikasi/BlokBunifikasi.service').default.reducer,
  ALL_BP_21_F: require('../../modules/Main/pph/21_26/ALL_BP_21_F/ALL_BP_21_F.service').default.reducer,
  ALL_BP_21_TF: require('../../modules/Main/pph/21_26/ALL_BP_21_TF/ALL_BP_21_TF.service').default.reducer,
  ALL_BP_21_A1: require('../../modules/Main/pph/21_26/ALL_BP_21_A1/ALL_BP_21_A1.service').default.reducer,
  ALL_BP_21_A2: require('../../modules/Main/pph/21_26/ALL_BP_21_A2/ALL_BP_21_A2.service').default.reducer,
  ALL_BP_21_SSP: require('../../modules/Main/pph/21_26/ALL_BP_21_SSP/ALL_BP_21_SSP.service').default.reducer,
  ALL_BP_21_SATU_MASA: require('../../modules/Main/pph/21_26/ALL_BP_21_SATU_MASA/ALL_BP_21_SATU_MASA.service').default.reducer,
  ALL_BP_21_DAFTAR_BIAYA: require('../../modules/Main/pph/21_26/ALL_BP_21_DAFTAR_BIAYA/ALL_BP_21_DAFTAR_BIAYA.service').default.reducer,
  ALL_BP_21_SATU_TAHUN: require('../../modules/Main/pph/21_26/ALL_BP_21_SATU_TAHUN/ALL_BP_21_SATU_TAHUN.service').default.reducer,
  ALL_BP_21_INDUK: require('../../modules/Main/pph/21_26/ALL_BP_21_INDUK/ALL_BP_21_INDUK.service').default.reducer,
  // 2126 ALL

  // 2126
  SPT_21: require('../../modules/Main/pph/21_26/spt/21_26.spt.service').default.reducer,
  BP_21_F: require('../../modules/Main/pph/21_26/BP_21_F/BP_21_F.service').default.reducer,
  BP_21_TF: require('../../modules/Main/pph/21_26/BP_21_TF/BP_21_TF.service').default.reducer,
  BP_21_A1: require('../../modules/Main/pph/21_26/BP_21_A1/BP_21_A1.service').default.reducer,
  BP_21_A2: require('../../modules/Main/pph/21_26/BP_21_A2/BP_21_A2.service').default.reducer,
  BP_21_SATU_MASA: require('../../modules/Main/pph/21_26/BP_21_SATU_MASA/BP_21_SATU_MASA.service').default.reducer,
  BP_21_SSP: require('../../modules/Main/pph/21_26/BP_21_SSP/BP_21_SSP.service').default.reducer,
  BP_21_SATU_TAHUN: require('../../modules/Main/pph/21_26/BP_21_SATU_TAHUN/BP_21_SATU_TAHUN.service').default.reducer,
  SPT21Signer: require('../../modules/Main/pph/21_26/SPT21Signer/SPT21Signer.service').default.reducer,
  FileLapor21: require('../../modules/Main/pph/21_26/FileLapor21/FileLapor21.service').default.reducer,
  LogReport21: require('../../modules/Main/pph/21_26/LogReport21/LogReport21.service').default.reducer,
  // END 2126

  // Import21
  Import21: require('../../modules/Main/pph/21_26/Import/Import.service').default.reducer,
  ImportBp21TF: require('../../modules/Main/pph/21_26/ImportBp21TF/ImportBp21TF.service').default.reducer,
  ImportBp21F: require('../../modules/Main/pph/21_26/ImportBp21F/ImportBp21F.service').default.reducer,
  ImportBp21A1: require('../../modules/Main/pph/21_26/ImportBp21A1/ImportBp21A1.service').default.reducer,
  ImportBp21A1Auto: require('../../modules/Main/pph/21_26/ImportBp21A1Auto/ImportBp21A1Auto.service').default.reducer,
  ImportBp21A2: require('../../modules/Main/pph/21_26/ImportBp21A2/ImportBp21A2.service').default.reducer,
  ImportBp21DaftarBiaya: require('../../modules/Main/pph/21_26/ImportBp21DaftarBiaya/ImportBp21DaftarBiaya.service').default.reducer,
  ImportBp21SatuMasa: require('../../modules/Main/pph/21_26/ImportBP21SatuMasa/ImportBP21SatuMasa.service').default.reducer,
  ImportBp21SatuMasaTL: require('../../modules/Main/pph/21_26/ImportBP21SatuMasaTL/ImportBP21SatuMasaTL.service').default.reducer,
  ImportBp21Ssp: require('../../modules/Main/pph/21_26/ImportBp21Ssp/ImportBp21Ssp.service').default.reducer,
  ImportBp21Induk: require('../../modules/Main/pph/21_26/ImportBp21Induk/ImportBp21Induk.service').default.reducer,
  // END Import21

  // Export21
  ExportCsv21: require('../../modules/Main/pph/21_26/ExportCsv21/ExportCsv21.service').default.reducer,
  // End Export21

  // Log Export21
  FileLapor21Log: require('../../modules/Main/pph/21_26/FileLapor21Log/FileLapor21Log.service').default.reducer,
  ExportCsv21Log: require('../../modules/Main/pph/21_26/ExportCsv21Log/ExportCsv21Log.service').default.reducer,
  Email21Log: require('../../modules/Main/pph/21_26/Email21Log/Email21Log.service').default.reducer,
  // End Log Export21

  // Master21
  Master21UpahHarian: require('../../modules/Main/pph/21_26/Master21UpahHarian/Master21UpahHarian.service').default.reducer,
  Master21UpahKumulatif: require('../../modules/Main/pph/21_26/Master21UpahKumulatif/Master21UpahKumulatif.service').default.reducer,
  Master21GolonganA2: require('../../modules/Main/pph/21_26/Master21GolonganA2/Master21GolonganA2.service').default.reducer,
  Master21SspDescription: require('../../modules/Main/pph/21_26/Master21SspDescription/Master21SspDescription.service').default.reducer,
  Master21StatusKaryawanA1: require('../../modules/Main/pph/21_26/Master21StatusKaryawanA1/Master21StatusKaryawanA1.service').default.reducer,
  Master21StatusKaryawanA2: require('../../modules/Main/pph/21_26/Master21StatusKaryawanA2/Master21StatusKaryawanA2.service').default.reducer,
  Master21Kap: require('../../modules/Main/pph/21_26/Master21Kap/Master21Kap.service').default.reducer,
  Master21Kjs: require('../../modules/Main/pph/21_26/Master21Kjs/Master21Kjs.service').default.reducer,
  Master21Pasal17: require('../../modules/Main/pph/21_26/Master21Pasal17/Master21Pasal17.service').default.reducer,
  Master21Pasal17Prog: require('../../modules/Main/pph/21_26/Master21Pasal17Prog/Master21Pasal17Prog.service').default.reducer,
  Master21BiayaJabatan: require('../../modules/Main/pph/21_26/Master21BiayaJabatan/Master21BiayaJabatan.service').default.reducer,
  Master21BiayaPensiun: require('../../modules/Main/pph/21_26/Master21BiayaPensiun/Master21BiayaPensiun.service').default.reducer,
  Master21Pensiun: require('../../modules/Main/pph/21_26/Master21Pensiun/Master21Pensiun.service').default.reducer,
  Master21Ptkp: require('../../modules/Main/pph/21_26/Master21Ptkp/Master21Ptkp.service').default.reducer,
  Master21HonorPns: require('../../modules/Main/pph/21_26/Master21HonorPns/Master21HonorPns.service').default.reducer,
  Master21FinalPesangon: require('../../modules/Main/pph/21_26/Master21FinalPesangon/Master21FinalPesangon.service').default.reducer,
  Master21FinalPesangonProg: require('../../modules/Main/pph/21_26/Master21FinalPesangonProg/Master21FinalPesangonProg.service').default.reducer,

  Master21Pasal17Global: require('../../modules/Main/pph/21_26/Master21Pasal17Global/Master21Pasal17Global.service').default.reducer,
  Master21Pasal17ProgGlobal: require('../../modules/Main/pph/21_26/Master21Pasal17Global/Master21Pasal17ProgGlobal.service').default.reducer,
  Master21BiayaJabatanGlobal: require('../../modules/Main/pph/21_26/Master21BiayaJabatanGlobal/Master21BiayaJabatanGlobal.service').default.reducer,
  Master21BiayaPensiunGlobal: require('../../modules/Main/pph/21_26/Master21BiayaPensiunGlobal/Master21BiayaPensiunGlobal.service').default.reducer,
  Master21PtkpGlobal: require('../../modules/Main/pph/21_26/Master21PtkpGlobal/Master21PtkpGlobal.service').default.reducer,
  Master21PensiunGlobal: require('../../modules/Main/pph/21_26/Master21PensiunGlobal/Master21PensiunGlobal.service').default.reducer,
  Master21UpahHarianGlobal: require('../../modules/Main/pph/21_26/Master21UpahHarianGlobal/Master21UpahHarianGlobal.service').default.reducer,
  Master21UpahKumulatifGlobal: require('../../modules/Main/pph/21_26/Master21UpahKumulatifGlobal/Master21UpahKumulatifGlobal.service').default.reducer,
  Master21FinalPesangonGlobal: require('../../modules/Main/pph/21_26/Master21FinalPesangonGlobal/Master21FinalPesangonGlobal.service').default.reducer,
  Master21FinalPesangonProgGlobal: require('../../modules/Main/pph/21_26/Master21FinalPesangonGlobal/Master21FinalPesangonProgGlobal.service').default.reducer,
  Master21HonorPnsGlobal: require('../../modules/Main/pph/21_26/Master21HonorPnsGlobal/Master21HonorPnsGlobal.service').default.reducer,
  // END Master21

  // 22
  BP22: require('../../modules/Main/pph/22/BP22/BP22.service').default.reducer,
  SSPPembelianBarang:require('../../modules/Main/pph/22/SSPPembelianBarang/SSPPembelianBarang.service').default.reducer,
  SSPImportBankDevisa: require('../../modules/Main/pph/22/SSPImportBankDevisa/SSPImportBankDevisa.service').default.reducer,
  SSPDibayarSendiri: require('../../modules/Main/pph/22/SSPDibayarSendiri/SSPDibayarSendiri.service').default.reducer,
  SSP22: require('../../modules/Main/pph/22/SSP/SSP22.service').default.reducer,
  PBK22: require('../../modules/Main/pph/22/PBK/PBK22.service').default.reducer,

  ExportCsv22: require('../../modules/Main/pph/22/Export/ExportCsv22/ExportCsv22.service').default.reducer,
  ExportCsv22Log: require('../../modules/Main/pph/22/Export/ExportCsv22Log/ExportCsv22Log.service').default.reducer,

  ImportBp22AtasImpor: require('../../modules/Main/pph/22/Import/ImportBP22AtasImpor/ImportBP22AtasImpor.service').default.reducer,
  ImportBp22IndustriEksportir: require('../../modules/Main/pph/22/Import/ImportBP22IndustriEksportir/ImportBP22IndustriEksportir.service').default.reducer,
  ImportBp22IndustriImportir: require('../../modules/Main/pph/22/Import/ImportBP22IndustriImportir/ImportBP22IndustriImportir.service').default.reducer,
  ImportBp22BadanTertentu: require('../../modules/Main/pph/22/Import/ImportBP22BadanTertentu/ImportBP22BadanTertentu.service').default.reducer,
  ImportSSP22DibayarSendiri: require('../../modules/Main/pph/22/Import/ImportSSP22DibayarSendiri/ImportSSP22DibayarSendiri.service').default.reducer,
  ImportSSP22BankDevisa: require('../../modules/Main/pph/22/Import/ImportSSP22BankDevisa/ImportSSP22BankDevisa.service').default.reducer,
  ImportSSP22Bendaharawan: require('../../modules/Main/pph/22/Import/ImportSSP22Bendaharawan/ImportSSP22Bendaharawan.service').default.reducer,
  ImportSSP22: require('../../modules/Main/pph/22/Import/ImportSSP/ImportSSP22.service').default.reducer,
  ImportPBK22: require('../../modules/Main/pph/22/Import/ImportPBK/ImportPBK22.service').default.reducer,

  AllBp22: require('../../modules/Main/pph/22/All/AllBp22/AllBp22.service').default.reducer,
  AllSsp22: require('../../modules/Main/pph/22/All/AllSsp/AllSsp22.service').default.reducer,
  AllSsp22DibayarSendiri: require('../../modules/Main/pph/22/All/AllSsp22DibayarSendiri/AllSsp22DibayarSendiri.service').default.reducer,
  AllSsp22ImportBankDevisa: require('../../modules/Main/pph/22/All/AllSsp22ImportBankDevisa/AllSsp22ImportBankDevisa.service').default.reducer,
  AllSsp22PembelianBarang: require('../../modules/Main/pph/22/All/AllSsp22PembelianBarang/AllSsp22PembelianBarang.service').default.reducer,
  // end 22

  // 15
  ImportBp15: require('../../modules/Main/pph/15/Import/ImportBp15/ImportBp15.service').default.reducer,
  ImportBp24: require('../../modules/Main/pph/15/Import/ImportBp24/ImportBp24.service').default.reducer,
  ImportSSP15SetorSendiri: require('../../modules/Main/pph/15/Import/ImportSSPSetorSendiri/ImportSSP15SetorSendiri.service').default.reducer,
  ImportSSP15: require('../../modules/Main/pph/15/Import/ImportSSP/ImportSSP15.service').default.reducer,
  ImportPBK15: require('../../modules/Main/pph/15/Import/ImportPBK/ImportPBK15.service').default.reducer,

  AllBP15DipotongPihakLain: require('../../modules/Main/pph/15/All/AllDipotongPihakLain/AllBP15DipotongPihakLain.service').default.reducer,
  AllBP15PihakLainDipotong: require('../../modules/Main/pph/15/All/AllPihakLainDipotong/AllBP15PihakLainDipotong.service').default.reducer,
  AllBP15Pasal24: require('../../modules/Main/pph/15/All/AllPasal24/AllBP15Pasal24.service').default.reducer,
  AllSsp15SetorSendiri: require('../../modules/Main/pph/15/All/AllSsp15SetorSendiri/AllSsp15SetorSendiri.service').default.reducer,
  AllSsp15: require('../../modules/Main/pph/15/All/AllSsp/AllSsp15.service').default.reducer,
  FileLapor22:require('../../modules/Main/pph/22/FileLapor/FileLapor22.service').default.reducer,
  // end 15

  // MASTER 23
  Master23Kap: require('../../modules/Main/pph/23_26/master/Kap/Master23Kap.service').default.reducer,
  Master23Kjs: require('../../modules/Main/pph/23_26/master/Kjs/Master23Kjs.service').default.reducer,
  Master23Tarif: require('../../modules/Main/pph/23_26/master/Tarif23/Master23Tarif.service').default.reducer,
  Master26Tarif: require('../../modules/Main/pph/23_26/master/Tarif23/Master26Tarif.service').default.reducer,
  Master23TarifLain: require('../../modules/Main/pph/23_26/master/Tarif23/Master23TarifLain.service').default.reducer,
  // END MASTER 23

  // MASTER 22
  Master22Komoditi: require('../../modules/Main/pph/22/master/komoditi/Master22Komoditi.service').default.reducer,
  Master22Tarif: require('../../modules/Main/pph/22/master/tarif/Master22Tarif.service').default.reducer,
  Tarif22: require('../../modules/Main/pph/22/master/tarif/Master22Tarif.service').default.reducer,
  // END MASTER 22

  // MASTER 15
  Master15Tarif: require('../../modules/Main/pph/15/master/tarif/Master15Tarif.service').default.reducer,
  Tarif15: require('../../modules/Main/pph/15/master/tarif/Master15Tarif.service').default.reducer,
  // END MASTER 15

  // 2326 EBUPOT
  EBUPOT_23: require('../../modules/Main/pph/23_26.Ebupot/SPT/23_26.Ebupot.spt.service').default.reducer,
  BP_23_EBUPOT: require('../../modules/Main/pph/23_26.Ebupot/BP_Ebupot_23/BP_Ebupot_23.service').default.reducer,
  BP_26_EBUPOT: require('../../modules/Main/pph/23_26.Ebupot/BP_Ebupot_26/BP_Ebupot_26.service').default.reducer,

  ALLBP23Ebupot: require('../../modules/Main/pph/23_26.Ebupot/all/ALL_BP_23_EBUPOT/ALLBP23.Ebupot.service').default.reducer,
  ALLBP26Ebupot: require('../../modules/Main/pph/23_26.Ebupot/all/ALL_BP_26_EBUPOT/ALLBP26.Ebupot.service').default.reducer,
  // END 2326 EBUPOT

  // 2326
  SPT23Signer: require('../../modules/Main/pph/23_26/SPT23Signer/SPT23Signer.service').default.reducer,

  SPT_23: require('../../modules/Main/pph/23_26/SPT/23_26.spt.service').default.reducer,

  BP_23: require('../../modules/Main/pph/23_26/BP_23/BP_23.service').default.reducer,
  BP_26: require('../../modules/Main/pph/23_26/BP_26/BP_26.service').default.reducer,
  PBK23: require('../../modules/Main/pph/23_26/PBK/PBK23.service').default.reducer,
  SSP23: require('../../modules/Main/pph/23_26/SSP/SSP23.service').default.reducer,
  SPT23Induk: require('../../modules/Main/pph/23_26/Induk/Spt23Induk.service').default.reducer,
  ExportCsv23: require('../../modules/Main/pph/23_26/Export/ExportCsv23/ExportCsv23.service').default.reducer,
  ExportCsv23Log: require('../../modules/Main/pph/23_26/Export/ExportCsv23Log/ExportCsv23Log.service').default.reducer,
  FileLapor23: require('../../modules/Main/pph/23_26/FileLapor/FileLapor23.service').default.reducer,

  ImportBp23: require('../../modules/Main/pph/23_26/Import/ImportBP23/ImportBP23.service').default.reducer,
  ImportBP26: require('../../modules/Main/pph/23_26/Import/ImportBP26/ImportBP26.service').default.reducer,
  ImportPBK23: require('../../modules/Main/pph/23_26/Import/ImportPBK/ImportPBK23.service').default.reducer,
  ImportSSP23: require('../../modules/Main/pph/23_26/Import/ImportSSP/ImportSSP23.service').default.reducer,
  ImportSigner23: require('../../modules/Main/pph/23_26/Import/ImportSigner/ImportSigner23.service').default.reducer,
  ImportSigner21: require('../../modules/Main/pph/21_26/ImportSigner/ImportSigner21.service').default.reducer,

  ALLBP23: require('../../modules/Main/pph/23_26/all/ALL_BP_23/ALLBP23.service').default.reducer,
  ALLBP26: require('../../modules/Main/pph/23_26/all/ALL_BP_26/ALLBP26.service').default.reducer,
  ALLSSP23: require('../../modules/Main/pph/23_26/all/ALL_SSP/ALLSSP23.service').default.reducer,
  ALLSPT23Induk: require('../../modules/Main/pph/23_26/all/ALL_Induk/ALLSPT23Induk.service').default.reducer,
  // END OF 2326

  // 4a2
  ImportBp4a2: require('../../modules/Main/pph/4a2/Import/ImportBP4a2/ImportBP4a2.service').default.reducer,
  ImportDaftarPemotongan4a2: require('../../modules/Main/pph/4a2/Import/ImportDaftarPemotongan4a2/ImportDaftarPemotongan4a2.service').default.reducer,
  ImportPBK4a2: require('../../modules/Main/pph/4a2/Import/ImportPBK/ImportPBK4a2.service').default.reducer,
  ImportSigner4a2: require('../../modules/Main/pph/4a2/Import/ImportSigner/ImportSigner4a2.service').default.reducer,
  ImportSSP4a2: require('../../modules/Main/pph/4a2/Import/ImportSSP/ImportSSP4a2service').default.reducer,
  ImportInduk4a2PenghasilanTertentu: require('../../modules/Main/pph/4a2/Import/ImportInduk4a2PenghasilanTertentu/ImportInduk4a2PenghasilanTertentu.service').default.reducer,

  ExportCsv4a2: require('../../modules/Main/pph/4a2/Export/ExportCsv4a2/ExportCsv4a2.service').default.reducer,
  ExportCsv4a2Log: require('../../modules/Main/pph/4a2/Export/ExportCsv4a2Log/ExportCsv4a2Log.service').default.reducer,

  SPT4Signer: require('../../modules/Main/pph/4a2/SPT4Signer/SPT4Signer.service').default.reducer,
  SPT_4a2: require('../../modules/Main/pph/4a2/SPT/Spt4a2.service').default.reducer,
  BP4a2: require('../../modules/Main/pph/4a2/BP4a2/BP4a2.service').default.reducer,
  BP4a2TabunganDeposito: require('../../modules/Main/pph/4a2/TabunganDeposito/BP4a2TabunganDeposito.service').default.reducer,
  BP4a2DSSewaTanah: require('../../modules/Main/pph/4a2/DSSewaTanah/BP4a2DSSewaTanah.service').default.reducer,
  BP4a2DSJasaKonstruksi: require('../../modules/Main/pph/4a2/DSJasaKonstruksi/BP4a2DSJasaKonstruksi.service').default.reducer,
  BP4a2DSPengalihanTanah: require('../../modules/Main/pph/4a2/DSPengalihanTanah/BP4a2DSPengalihanTanah.service').default.reducer,

  SSP4a2: require('../../modules/Main/pph/4a2/SSP/SSP4a2.service').default.reducer,
  PBK4a2: require('../../modules/Main/pph/4a2/PBK/PBK4a2.service').default.reducer,
  SPT4a2Induk: require('../../modules/Main/pph/4a2/Induk/Spt4a2Induk.service').default.reducer,
  FileLapor4a2:require('../../modules/Main/pph/4a2/FileLapor/FileLapor4a2.service').default.reducer,

  AllBp4a2: require('../../modules/Main/pph/4a2/all/AllBp4a2/AllBp4a2.service').default.reducer,
  AllSsp4a2: require('../../modules/Main/pph/4a2/all/AllSsp/AllSsp4a2.service').default.reducer,
  AllSpt4a2Induk: require('../../modules/Main/pph/4a2/all/AllInduk/AllSpt4a2Induk.service').default.reducer,
  AllPbk4a2: require('../../modules/Main/pph/4a2/all/AllPbk4a2/AllPbk4a2.service').default.reducer,

  Master4a2Tarif: require('../../modules/Main/pph/4a2/master/tarif/Tarif4a2.service').default.reducer,
  // END OF 4a2

  //15
  SPT15Signer: require('../../modules/Main/pph/15/SPT15Signer/SPT15Signer.service').default.reducer,
  SPT_15: require('../../modules/Main/pph/15/SPT/Spt15.service').default.reducer,
  BP15: require('../../modules/Main/pph/15/BP15/BP15.service').default.reducer,
  SSP15SetorSendiri: require('../../modules/Main/pph/15/SSPSetorSendiri/SSP15SetorSendiri.service').default.reducer,
  BP15PihakLainDipotong: require('../../modules/Main/pph/15/BP15/PihakLainDipotong/BP15PihakLainDipotong.service').default.reducer,
  BP15DipotongPihakLain: require('../../modules/Main/pph/15/BP15/DipotongPihakLain/BP15DipotongPihakLain.service').default.reducer,
  BP15PPHPasal24: require('../../modules/Main/pph/15/BP15/PPHPasal24/BP15PPhPasal24.service').default.reducer,
  PBK15: require('../../modules/Main/pph/15/PBK/PBK15.service').default.reducer,
  SSP15: require('../../modules/Main/pph/15/SSP/SSP15.service').default.reducer,
  SPT15Signer: require('../../modules/Main/pph/15/Signer/SPT15Signer.service').default.reducer,
  ImportSigner15: require('../../modules/Main/pph/15/Import/ImportSigner/ImportSigner15.service').default.reducer,
  ExportCsv15: require('../../modules/Main/pph/15/Export/ExportCsv15/ExportCsv15.service').default.reducer,
  FileLapor15: require('../../modules/Main/pph/15/FileLapor/FileLapor15.service').default.reducer,
  //END OF 15

  // 25
  SPT_25: require('../../modules/Main/pph/25/SPT/SPT25.service').default.reducer,
  SSP25: require('../../modules/Main/pph/25/SSP/SSP25.service').default.reducer,
  BP_25: require('../../modules/Main/pph/25/BP_25/BP_25.service').default.reducer,
  AllBP25: require('../../modules/Main/pph/25/All/All_BP_25/AllBP25.service').default.reducer,
  AllSSP25: require('../../modules/Main/pph/25/All/All_SSP25/AllSSP25.service').default.reducer,
  ExportCsv25: require('../../modules/Main/pph/25/Export/ExportCsv25/ExportCsv25.service').default.reducer,
  // 25

  // Pasal 22
  SPT22Signer: require('../../modules/Main/pph/22/SPT22Signer/SPT22Signer.service').default.reducer,
  SPT_22: require('../../modules/Main/pph/22/SPT/SPT22.service').default.reducer,
  SPT22Signer: require('../../modules/Main/pph/22/Signer/SPT22Signer.service').default.reducer,
  ImportSigner22: require('../../modules/Main/pph/22/Import/ImportSigner/ImportSigner22.service').default.reducer,
  // END OF pasal 22

  // Setting
  SettingEmail: require('../../modules/Main/pph/21_26/SettingEmail/SettingEmail.service').default.reducer,
  SettingEmailContent: require('../../modules/Main/Setting/SettingEmailContent/SettingEmailContent.service').default.reducer,
  SettingEmailSchedule: require('../../modules/Main/Setting/SettingEmailSchedule/SettingEmailSchedule.service').default.reducer,
  SettingEmailScheduleDetails: require('../../modules/Main/Setting/SettingEmailScheduleDetails/SettingEmailScheduleDetails.service').default.reducer,
  // SettingEfiling: require('../../modules/Main/pph/21_26/SettingEfiling/SettingEfiling.service').default.reducer,
  SettingImport: require('../../modules/Main/Setting/SettingImport/SettingImport.service').default.reducer,
  SettingExport: require('../../modules/Main/Setting/SettingExport/SettingExport.service').default.reducer,
  PengaturanNoBP: require('../../modules/Main/Setting/PengaturanNoBP/PengaturanNoBP.service').default.reducer,
  FileUpload: require('../../modules/Main/Setting/FileUpload/FileUpload.service').default.reducer,
  FileUpload23: require('../../modules/Main/Setting/FileUpload23/FileUpload23.service').default.reducer,
  FileUpload4: require('../../modules/Main/Setting/FileUpload4/FileUpload4.service').default.reducer,
  FileUpload22: require('../../modules/Main/Setting/FileUpload22/FileUpload22.service').default.reducer,
  FileUpload15: require('../../modules/Main/Setting/FileUpload15/FileUpload15.service').default.reducer,
  LogginIn: require('../../modules/Main/Setting/LogginIn/LogginIn.service').default.reducer,
  // Email Setting

  // eBilling
  ImportEbilling: require('../../modules/Main/Ebilling/ImportEbilling/ImportEbilling.service').default.reducer,
  ImportInquiry: require('../../modules/Main/Ebilling/ImportInquiry/ImportInquiry.service').default.reducer,
  DataInquiry: require('../../modules/Main/Ebilling/DataInquiry/DataInquiry.service').default.reducer,
  ImportEssp: require('../../modules/Main/Ebilling/ImportEssp/ImportEssp.service').default.reducer,
  DataESSP: require('../../modules/Main/Ebilling/DataESSP/DataESSP.service').default.reducer,
  DataESSPDuplicate:  require('../../modules/Main/Ebilling/DataESSP/DataESSPDuplicate.service').default.reducer,
  DataESSP4: require('../../modules/Main/Ebilling/DataESSP4/DataESSP4.service').default.reducer,
  DataESSPDuplicate4:  require('../../modules/Main/Ebilling/DataESSP4/DataESSPDuplicate4.service').default.reducer,
  DataESSP23: require('../../modules/Main/Ebilling/DataESSP23/DataESSP23.service').default.reducer,
  DataESSPDuplicate23:  require('../../modules/Main/Ebilling/DataESSP23/DataESSPDuplicate23.service').default.reducer,
  PaymentOrder: require('../../modules/Main/Ebilling/PaymentOrder/PaymentOrder.service').default.reducer,
  PaymentNotification: require('../../modules/Main/Ebilling/PaymentNotification/PaymentNotification.service').default.reducer,
  PaymentMethod: require('../../modules/Main/Ebilling/PaymentMethod/PaymentMethod.service').default.reducer,
  Image: require('../../modules/Main/Ebilling/Image/Image.service').default.reducer,
  AkunPembayaran: require('../../modules/Main/Ebilling/AkunPembayaran/AkunPembayaran.service').default.reducer,
  Bank: require('../../modules/Main/Ebilling/Bank/Bank.service').default.reducer,
  SuratSetoran: require('../../modules/Main/Ebilling/SuratSetoran/SuratSetoran.service').default.reducer,
  Billing: require('../../modules/Main/Ebilling/Billing/Billing.service').default.reducer,
  FileHeader: require('../../modules/Main/Ebilling/FileHeader/FileHeader.service').default.reducer,
  BuktiPenerimaan: require('../../modules/Main/Ebilling/BuktiPenerimaan/BuktiPenerimaan.service').default.reducer,
  // eBilling

  // eBilling Log
  LogExportDataInquiry: require('../../modules/Main/Ebilling/log/LogExportDataInquiry/LogExportDataInquiry.service').default.reducer,
  LogExportDataSSP: require('../../modules/Main/Ebilling/log/LogExportDataSSP/LogExportDataSSP.service').default.reducer,
  LogCetakPaymentOrder: require('../../modules/Main/Ebilling/log/LogCetakPaymentOrder/LogCetakPaymentOrder.service').default.reducer,
  LogCetakPaymenNotification: require('../../modules/Main/Ebilling/log/LogCetakPaymentNotification/LogCetakPaymentNotification.service').default.reducer,
  LogCetakBPN:require('../../modules/Main/Ebilling/log/LogCetakBPN/LogCetakBPN.service').default.reducer,
  LogCetakLaporanPO: require('../../modules/Main/Ebilling/log/LogCetakLaporanPO/LogCetakLaporanPO.service').default.reducer,
  LogCetakLaporanBPN: require('../../modules/Main/Ebilling/log/LogCetakLaporanBPN/LogCetakLaporanBPN.service').default.reducer,
  LogCetakLaporanPN: require('../../modules/Main/Ebilling/log/LogCetakLaporanPN/LogCetakLaporanPN.service').default.reducer,
  // END eBilling Log

  // Import Lawan Transaksi
  ImportLK4a2: require('../../modules/Main/pph/4a2/Import/ImportLK/ImportLK4a2.service').default.reducer,
  ImportLK15: require('../../modules/Main/pph/15/Import/ImportLK/ImportLK15.service').default.reducer,
  ImportLK22: require('../../modules/Main/pph/22/Import/ImportLK/ImportLK22.service').default.reducer,
  ImportLK23: require('../../modules/Main/pph/23_26/Import/ImportLK/ImportLK23.service').default.reducer,
  ImportLK21: require('../../modules/Main/pph/21_26/ImportLK/ImportLK21.service').default.reducer,
})

const rootReducer = combineReducers({
  auth, table, global, toast,
  image, dialog, tmpObject, global,
  form: formReducer,
  auth: auth,
  authEppt: require('../../services/auth.service').default.reducer,
  entity: entityReducer
});

export default rootReducer;
