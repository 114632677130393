import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Field, getFormValues, reduxForm } from 'redux-form';
import { Card, Switch, Button, LinearProgress, TabsContainer, Tabs, Tab } from 'react-md';
import {validation, Textfield, Searchfield, Datepicker, TextfieldMask, Fileinput, convert, Switch as Switch2} from '../../../../../components/form';

import ImportBp21SspService from './ImportBp21Ssp.service';
import ListView from '../../../../../components/entity/listView';
import ColumnService from '../../../../../services/column.service';
import ImportService from './../Import/Import.service';
import axios from 'axios';
import izitoast from 'izitoast';
import FormDialog from './ImportBp21Ssp.dialog.view';
import download from "downloadjs";
import ListViewImport from '../../../../../components/entity/ListViewImport';

@reduxForm({form: 'IMPORT_21_BP_SSP_Form', destroyOnUnmount: true})
@connect((state) => ({
  ...ImportBp21SspService.stateConnectSetting()(state),
  formData: getFormValues('IMPORT_21_BP_SSP_Form')(state),
  // EPPT CORE
  auth: state.auth.company
  // EPPT CORE
}), ImportBp21SspService.actionConnectSetting())
export default class ImportBp21SspView extends ListViewImport {
  service=ImportBp21SspService
  FormDialog=FormDialog

  category='SSP'
  importPath='/upload/csv'
  showAutomatisTab=false
  showPembetulan=true
  // viewType=2

  beforeFetch(params) {
    params["category.equals"] = "SSP"
  }

  async handleDownloadTemplate() {
    var report = await ImportBp21SspService.api.downloadTemplate()
    window.open(report.data.url, 'TemplateBp21Ssp.csv');
  }

}
