import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues} from 'redux-form';
import moment from 'moment';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress
} from 'react-md';

import FormView from '../../../../../components/entity/form.view';
import BP26Service from './BP_26.service';
// import NomorBPService from '../nomorBP.service';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox} from '../../../../../components/form';
import UtilService from '../../../../../services/utils.service'
// import SPT21SignerService from '../SPT21Signer/SPT21Signer.service';
import NpwpService from './../../NpwpService';
import FormViewSpt from '../../../../../components/entity/form.view.spt';
import terbilangService from '../../../../../services/terbilang.service';
import nomorBP23Service from '../nomorBP23.service';
import LawanTransaksiDialog from '../../../../../components/entity/LawanTransaksi.dialog';
import MasterLawanTransaksi23Service from '../../../Master/MasterLawanTransaksi23/MasterLawanTransaksi23.service';
import Master26TarifService from '../master/Tarif23/Master26Tarif.service';
import BlokBunifikasiService from '../../21_26/BlokBunifikasi/BlokBunifikasi.service';

@reduxForm({form: 'BP_26_Form', destroyOnUnmount: true})
@connect((state) => ({
  access      : state.entity.access.api.find,
  organization: state.authEppt.currentOrganization,
  spt         : state.authEppt.currentSPT,
  formData    : getFormValues('BP_26_Form')(state),
  auth        : state.auth.company,
}))
export default class BP26Form extends FormViewSpt {
  service  = BP26Service
  viewType = 2;

  typingTimeout=1000;
  typingObj=null;


  initialData={
    bagA: {},
    bagB: {},
    bagC: {}
  }

  constructor(props) {
    super(props);

    this.state= {
      ...this.state,
      showCalculateProgress: false,
      npwpNotValid         : true,

      lkDialog: false,
      tarif26 : {
        "dividen"                    : 20.0,
        "bunga"                      : 20.0,
        "royalti"                    : 20.0,
        "sewaDanPenghasilanLain"     : 20.0,
        "imbalan"                    : 20.0,
        "hadiahDanPenghargaan"       : 20.0,
        "pensiunDanPembayaranBerkala": 20.0,
        "premiSwap"                  : 20.0,
        "pembebasanUtang"            : 20.0,
        "penjualanHarta"             : 20.0,
        "premiAsuransi"              : 20.0,
        "penghasilanPenjualanSaham"  : 20.0,
        "penghasilanKenaPajakBUT"    : 20.0,
      }
    }
  }

  async npwpValidation(evt, value){
    if(this.typingObj) clearTimeout(this.typingObj);

    this.typingObj = setTimeout(async ()=> {
      if(value){
        var res = await NpwpService.api.validate(value)
        if(res.valid){
          this.setState({npwpNotValid: false})

          var lk = await MasterLawanTransaksi23Service.api.find({
            'npwp.equals': value,
            // 'organizationId.equals': this.props.spt.data.organization.id
          })

          if(lk.data) {
            var formData = {...this.props.formData};

            if(lk.data.length > 0) {
              var d = lk.data[0];
              formData.bagA = {
                "npwp"  : d.npwp,
                "nik"   : d.nik,
                "nama"  : d.name,
                "alamat": d.address,
                "email" : d.email
              };
            } else {
              formData.bagA = {
                "npwp" : value
              }
              this.toast.info({title: "Info", message: `Lawan Transaksi dengan npwp ${value} tidak ditemukan`})
            }
            this.props.initialize(formData);
          }
        } else {
          this.setState({npwpNotValid: true})
          this.toast.warning({title: "Validasi Npwp", message: "npwp tidak valid"})
        }
      }
    }, this.typingTimeout);
  }

  async beforeSave(value) {
    if(value.bagB.bruto == 0 || value.bagB.bruto == '') {
      this.toast.warning({title:'Warning', message:'Isi bruto harus lebih besar dari 0'})
      return false;
    }

    var date;
    if(typeof value.bagC.tanggal === 'string') {
      if(value.bagC.tanggal.search('/') != -1) {
        date = moment(value.bagC.tanggal, 'DD/MM/YYYY');
      } else {
        date = moment(value.bagC.tanggal, 'YYYY-MM-DD');
      }
    } else if(typeof value.bagC.tanggal === 'object') {
      date = moment(value.bagC.tanggal)
    }

    if(!(date.year() == this.props.spt.data.year && date.month()+1 == this.props.spt.data.month)) {
      this.toast.warning({title:'Warning', message:'Tanggal bukti potong harus sesuai dengan masa SPT'})
      return false;
    }

    // Remapping Object
    var mapping = {
      "document": {
        "dividen": {
          "gross": value.bagB.dividen.bruto,
          "pph"  : value.bagB.dividen.pph,
          "rates": value.bagB.dividen.tarif
        },
        "bunga": {
          "gross": value.bagB.bunga.bruto,
          "pph"  : value.bagB.bunga.pph,
          "rates": value.bagB.bunga.tarif
        },
        "royalti": {
          "gross": value.bagB.royalti.bruto,
          "pph"  : value.bagB.royalti.pph,
          "rates": value.bagB.royalti.tarif
        },
        "sewaDanPenghasilan": {
          "gross": value.bagB.sewaDanPenghasilan.bruto,
          "pph"  : value.bagB.sewaDanPenghasilan.pph,
          "rates": value.bagB.sewaDanPenghasilan.tarif
        },
        "imbalanJasa": {
          "gross": value.bagB.imbalan.bruto,
          "pph"  : value.bagB.imbalan.pph,
          "rates": value.bagB.imbalan.tarif
        },
        "hadiahDanPenghargaan": {
          "gross": value.bagB.hadiahDanPenghargaan.bruto,
          "pph"  : value.bagB.hadiahDanPenghargaan.pph,
          "rates": value.bagB.hadiahDanPenghargaan.tarif
        },
        "pensiun": {
          "gross": value.bagB.pensiun.bruto,
          "pph"  : value.bagB.pensiun.pph,
          "rates": value.bagB.pensiun.tarif
        },
        "premiSwap": {
          "gross": value.bagB.premiSwap.bruto,
          "pph"  : value.bagB.premiSwap.pph,
          "rates": value.bagB.premiSwap.tarif
        },   
        "keuntunganBebasUtang": {
          "gross": value.bagB.keuntunganUtang.bruto,
          "pph"  : value.bagB.keuntunganUtang.pph,
          "rates": value.bagB.keuntunganUtang.tarif
        },
        "penjualanHarta": {
          "gross": value.bagB.penjualanHarta.bruto,
          "pph"  : value.bagB.penjualanHarta.pph,
          "netto": value.bagB.penjualanHarta.netto,
          "rates": value.bagB.penjualanHarta.tarif
        },
        "premiAsuransi": {
          "gross": value.bagB.premiAsuransi.bruto,
          "pph"  : value.bagB.premiAsuransi.pph,
          "netto": value.bagB.premiAsuransi.netto,
          "rates": value.bagB.premiAsuransi.tarif
        },
        "penghasilanPenjualanSaham": {
          "gross": value.bagB.penghasilanSaham.bruto,
          "pph"  : value.bagB.penghasilanSaham.pph,
          "netto": value.bagB.penghasilanSaham.netto,
          "rates": value.bagB.penghasilanSaham.tarif
        },
        "pkpBut": {
          "gross": value.bagB.penghasilanKenaPajakBut.bruto,
          "pph"  : value.bagB.penghasilanKenaPajakBut.pph,
          "rates": value.bagB.penghasilanKenaPajakBut.tarif
        },
      },
      "address": value.bagA.alamat,
      "cuttingDate": value.bagC.tanggal,
      "email": value.bagA.email,
      "gross": value.bagB.jmlBruto,
      "name": value.bagA.nama,
      "netto": value.bagB.jmlNetto,
      "no": value.no,
      "npwp": value.bagA.npwp,
      "pph": value.bagB.jmlPph,
      "words": value.bagB.terbilang,
      "ref": value.referensi,
      "signName": value.bagC.nama,
      "signNpwp": value.bagC.npwp,
      "spt": this.props.spt.data,
    }
    if(value.id && value.id != "new") mapping.id = value.id
    if(value.consumeId) mapping.consumeId = value.consumeId
    if(typeof mapping.cuttingDate == "object") mapping.cuttingDate = moment(mapping.cuttingDate).format("YYYY-MM-DD")
    mapping.numbering = true

    value = mapping
    // Remapping Object

    value.blockBunifikasiMonth = 4;
    value.blockBunifikasiYear = 2022;
    try {
      let res = await BlokBunifikasiService.api.getSettingAccess(this.props.match.params.companyId)
      if(res.data.active){
        value.blockBunifikasiMonth = res.data.bulan
        value.blockBunifikasiYear = res.data.tahun
      }
    } catch(e){}

    return value;
  }

  async initData() {
    if(this.props.match.params.id == 'new') {
      // get tarif
      // EPPT CORE
      var tarif = {}
      try {
        // tarif = await Master26TarifService.api.byCompany({
        //   compId: this.props.organization.company.id,
        //   tgl   : moment().format("YYYY-MM-DD")
        // });
        var tarif = await Master26TarifService.api.byCompany()
        tarif.bunga                       = tarif.b1;
        tarif.dividen                     = tarif.b2;
        tarif.hadiahDanPenghargaan        = tarif.b2;
        tarif.imbalan                     = tarif.b4;
        tarif.pembebasanUtang             = tarif.b5;
        tarif.penghasilanKenaPajakBUT     = tarif.b6;
        tarif.penghasilanPenjualanSaham   = tarif.b7;
        tarif.penjualanHarta              = tarif.b8;
        tarif.pensiunDanPembayaranBerkala = tarif.b9;
        tarif.premiAsuransi               = tarif.b10;
        tarif.premiSwap                   = tarif.b11;
        tarif.royalti                     = tarif.b12
        tarif.sewaDanPenghasilanLain      = tarif.b13;
      } catch (error) {}

      if(tarif) {
        await this.setState({'tarif26': tarif});
      }

      // EPPT CORE

      //let nomorBPReq = await nomorBP23Service.api.get(this.props.spt.data.organization.id)
      let nomorBPReq = await nomorBP23Service.api.get(this.props.spt.data.id)
      var nomorBP    = "";
      if(nomorBPReq.data.mode === 'PREF_SUF') {
        nomorBP = nomorBPReq.data.prefix+nomorBPReq.data.suffix;
      } else {
        nomorBP = nomorBPReq.data.suffix+nomorBPReq.data.prefix;
      }
      let signer = {};  //await SPT21SignerService.api.getSigner(this.props.spt.data.id);

      this.initialData = {
        ...this.initialData,
        no  : nomorBP,   //.data.no,
        bagC: {
          tanggal: new Date(),
          nama   : this.props.organization.name,
          npwp   : this.props.organization.npwp,
        },
        "bagB": {
          "bunga": {
              "bruto"  : 0,
              "nama"   : "Bunga",
              "netto"  : 0,
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif26.bunga
          },
          "dividen": {
              "bruto"  : 0,
              "nama"   : "Dividen",
              "netto"  : 0,
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif26.dividen
          },
          "hadiahDanPenghargaan": {
              "bruto"  : 0,
              "nama"   : "Hadiah dan Penghargaan",
              "netto"  : 0,
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif26.hadiahDanPenghargaan
          },
          "imbalan": {
              "bruto"  : 0,
              "nama"   : "Imbalan",
              "netto"  : 0,
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif26.imbalan
          },
          "jmlBruto"       : 0,
          "jmlNetto"       : 0,
          "jmlPph"         : 0,
          "keuntunganUtang": {
              "bruto"  : 0,
              "nama"   : "Keuntungan Utang",
              "netto"  : 0,
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif26.pembebasanUtang
          },
          "penghasilanKenaPajakBut": {
              "bruto"  : 0,
              "nama"   : "Penghasilan Kena Pajak BUT",
              "netto"  : 0,
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif26.penghasilanKenaPajakBUT
          },
          "penghasilanSaham": {
              "bruto"  : 0,
              "nama"   : "Penghasilan Saham",
              "netto"  : 0,
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif26.penghasilanPenjualanSaham
          },
          "penjualanHarta": {
              "bruto"  : 0,
              "nama"   : "Penjualan Harta",
              "netto"  : 0,
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif26.penjualanHarta
          },
          "pensiun": {
              "bruto"  : 0,
              "nama"   : "Pensiun",
              "netto"  : 0,
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif26.pensiunDanPembayaranBerkala
          },
          "premiAsuransi": {
              "bruto"  : 0,
              "nama"   : "Premi Asuransi",
              "netto"  : 0,
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif26.premiAsuransi
          },
          "premiSwap": {
              "bruto"  : 0,
              "nama"   : "Premi Swap",
              "netto"  : 0,
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif26.premiSwap
          },
          "royalti": {
              "bruto"  : 0,
              "nama"   : "Royalti",
              "netto"  : 0,
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif26.royalti
          },
          "sewaDanPenghasilan": {
              "bruto"  : 0,
              "nama"   : "Sewa dan Penghasilan",
              "netto"  : 0,
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif26.sewaDanPenghasilanLain
          },
          "terbilang": ""
        },
        spt: this.props.spt.data
      }
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);

      // Remapping Object
      var remapping = {
        consumeId: res.data.consumeId,
        id: res.data.id,
        bagA: {
          alamat: res.data.address,
          email : res.data.email,
          npwp  : res.data.npwp,
          nama  : res.data.name
        },
        bagC: {
          nama: res.data.signName,
          npwp: res.data.signNpwp,
          tanggal: res.data.cuttingDate
        },
        referensi: res.data.ref,
        no       : res.data.no,
        spt      : this.props.spt.data,
        bagB: {
          jmlBruto : res.data.gross,
          jmlNetto : res.data.netto,
          jmlPph   : res.data.pph,
          terbilang: res.data.words,
          dividen: {
            bruto: res.data.document.dividen.gross,
            pph  : res.data.document.dividen.pph,
            tarif: res.data.document.dividen.rates
          },
          bunga: {
            bruto: res.data.document.bunga.gross,
            pph  : res.data.document.bunga.pph,
            tarif: res.data.document.bunga.rates
          },
          royalti: {
            bruto: res.data.document.royalti.gross,
            pph  : res.data.document.royalti.pph,
            tarif: res.data.document.royalti.rates
          },
          sewaDanPenghasilan: {
            bruto: res.data.document.sewaDanPenghasilan.gross,
            pph  : res.data.document.sewaDanPenghasilan.pph,
            tarif: res.data.document.sewaDanPenghasilan.rates
          },
          imbalan: {
            bruto: res.data.document.imbalanJasa.gross,
            pph  : res.data.document.imbalanJasa.pph,
            tarif: res.data.document.imbalanJasa.rates
          },
          hadiahDanPenghargaan: {
            bruto: res.data.document.hadiahDanPenghargaan.gross,
            pph  : res.data.document.hadiahDanPenghargaan.pph,
            tarif: res.data.document.hadiahDanPenghargaan.rates
          },
          pensiun: {
            bruto: res.data.document.pensiun.gross,
            pph  : res.data.document.pensiun.pph,
            tarif: res.data.document.pensiun.rates
          },
          premiSwap: {
            bruto: res.data.document.premiSwap.gross,
            pph  : res.data.document.premiSwap.pph,
            tarif: res.data.document.premiSwap.rates
          },
          keuntunganUtang: {
            bruto: res.data.document.keuntunganBebasUtang.gross,
            pph  : res.data.document.keuntunganBebasUtang.pph,
            tarif: res.data.document.keuntunganBebasUtang.rates
          },
          penjualanHarta: {
            bruto: res.data.document.penjualanHarta.gross,
            pph  : res.data.document.penjualanHarta.pph,
            tarif: res.data.document.penjualanHarta.rates,
            netto: res.data.document.penjualanHarta.netto
          },
          premiAsuransi: {
            bruto: res.data.document.premiAsuransi.gross,
            pph  : res.data.document.premiAsuransi.pph,
            tarif: res.data.document.premiAsuransi.rates,
            netto: res.data.document.premiAsuransi.netto
          },
          penghasilanSaham: {
            bruto: res.data.document.penghasilanPenjualanSaham.gross,
            pph  : res.data.document.penghasilanPenjualanSaham.pph,
            tarif: res.data.document.penghasilanPenjualanSaham.rates,
            netto: res.data.document.penghasilanPenjualanSaham.netto
          },
          penghasilanKenaPajakBut: {
            bruto: res.data.document.pkpBut.gross,
            pph  : res.data.document.pkpBut.pph,
            tarif: res.data.document.pkpBut.rates
          }
        }
      }
      remapping.numbering = res.data.numbering
      res.data = remapping
      console.log(remapping, "WHERE MY ID")
      // Remapping Object

      this.props.initialize(res.data);
      // this.npwpValidation(null, res.data.bagA.npwp)
      this.setState({npwpNotValid: false})
    }
  }

  async changeTarif(d, e) {
    // EPPT CORE
    // var tarif = await Master26TarifService.api.byCompany({
    //   compId: this.props.organization.company.id,
    //   tgl   : moment(d).format("YYYY-MM-DD")
    // })
    var tarif = await Master26TarifService.api.byCompany()
    tarif.bunga                       = tarif.b1;
    tarif.dividen                     = tarif.b2;
    tarif.hadiahDanPenghargaan        = tarif.b2;
    tarif.imbalan                     = tarif.b4;
    tarif.pembebasanUtang             = tarif.b5;
    tarif.penghasilanKenaPajakBUT     = tarif.b6;
    tarif.penghasilanPenjualanSaham   = tarif.b7;
    tarif.penjualanHarta              = tarif.b8;
    tarif.pensiunDanPembayaranBerkala = tarif.b9;
    tarif.premiAsuransi               = tarif.b10;
    tarif.premiSwap                   = tarif.b11;
    tarif.royalti                     = tarif.b12
    tarif.sewaDanPenghasilanLain      = tarif.b13;
    // EPPT CORE

    if(tarif) {
      await this.setState({'tarif26': tarif});
    }

    var form = {...this.props.formData};
    var bagB = form.bagB;

    if(bagB) {
      form.bagB.bunga.tarif                   = this.state.tarif26.bunga;
      form.bagB.dividen.tarif                 = this.state.tarif26.dividen;
      form.bagB.hadiahDanPenghargaan.tarif    = this.state.tarif26.hadiahDanPenghargaan;
      form.bagB.imbalan.tarif                 = this.state.tarif26.imbalan;
      form.bagB.keuntunganUtang.tarif         = this.state.tarif26.pembebasanUtang;
      form.bagB.penghasilanKenaPajakBut.tarif = this.state.tarif26.penghasilanKenaPajakBUT;
      form.bagB.penghasilanSaham.tarif        = this.state.tarif26.penghasilanPenjualanSaham;
      form.bagB.penjualanHarta.tarif          = this.state.tarif26.penjualanHarta;
      form.bagB.pensiun.tarif                 = this.state.tarif26.pensiunDanPembayaranBerkala;
      form.bagB.premiAsuransi.tarif           = this.state.tarif26.premiAsuransi;
      form.bagB.premiSwap.tarif               = this.state.tarif26.premiSwap;
      form.bagB.royalti.tarif                 = this.state.tarif26.royalti;
      form.bagB.sewaDanPenghasilan.tarif      = this.state.tarif26.sewaDanPenghasilanLain;

      await this.props.initialize(form);
      await this.reCalculate()
    }

  }

  async afterSave(res, val) {
    var formData = this.props.formData.values;

    var data = {
      ...formData.bagA,
      organization: this.props.spt.data.organization,
      npwpProfile: this.props.spt.data.organization.npwp
    }
    if(data.id) delete data.id;
    await MasterLawanTransaksi23Service.api.findOrCreate(data)
  }

  async reCalculate() {
    var form = {...this.props.formData};
    var d    = form.bagB;

    Object.keys(d).forEach((k, i) => {
      if(!(k == 'jmlBruto' || k == 'jmlPph' || k == 'terbilang' || k == 'jmlNetto' || k == 'lastModifiedBy' || k == 'lastModifiedDate' || k == 'id' || k == 'createdDate' || k == 'createdBy')) {
        var x = d[k];
        if(typeof x == 'object') {

          if(x.bruto) {
            x.pph = x.bruto*x.tarif/100

            if(k == 'penjualanHarta' || k == 'premiAsuransi' || k == 'penghasilanSaham') {
              if(!x.netto) x.netto = 0;
                 x.pph             = x.bruto*(x.netto/100)*(x.tarif/100)
            }
          }

        }
      }
    })

    console.log(form)

    await this.props.initialize(form);

    this.calculateTotal()

  }

  async calculate(e,v,pv,f, parent) {
    var form = this.props.formData;
    var d    = form.bagB[parent];

    var bruto = parseFloat(d.bruto);
    var tarif = parseFloat(d.tarif);

    if(f.search('bruto') >= 0) {
      bruto = parseFloat(v)
    } else if(f.search('tarif') >= 0) {
      tarif = parseFloat(v)
    }

    if(!bruto) bruto = 0;
    if(!tarif) tarif = 0;

    var pph = Math.floor(bruto * tarif / 100)

    await this.props.change(`bagB.${parent}.pph`, pph);
    this.calculateTotal()
  }

  async calculateSpecial(e,v,pv,f, parent) {
    var form = this.props.formData;
    var d    = form.bagB[parent];

    var bruto = parseFloat(d.bruto);
    var tarif = parseFloat(d.tarif);
    var netto = parseFloat(d.netto);

    if(f.search('bruto') >= 0) {
      bruto = parseFloat(v)
    } else if(f.search('tarif') >= 0) {
      tarif = parseFloat(v)
    }else if(f.search('netto') >= 0) {
      netto = parseFloat(v)
    }

    if(!bruto) bruto = 0;
    if(!tarif) tarif = 0;
    if(!netto) netto = 0;

    var pph = Math.floor(bruto * (netto / 100) * (tarif/100))

    await this.props.change(`bagB.${parent}.pph`, pph);
    this.calculateTotal()
  }

  async calculateTotal() {
    var form = this.props.formData;
    var d    = form.bagB;

    var jmlPph   = 0;
    var jmlBruto = 0;
    var jmlNetto = 0;
    Object.keys(d).forEach((k, i) => {
      if(!(k == 'jmlBruto' || k == 'jmlPph' || k == 'terbilang' || k == 'jmlNetto' || k == 'lastModifiedBy' || k == 'lastModifiedDate' || k == 'id' || k == 'createdDate' || k == 'createdBy')) {
        var x = d[k];

        if(typeof x == 'object') {
          jmlPph   = jmlPph+ parseFloat(x.pph);
          jmlBruto = jmlBruto+ parseFloat(x.bruto)
          jmlNetto = jmlNetto+ (parseFloat(x.netto)*parseFloat(x.bruto)/100)
        }


      }
    })

    jmlBruto = Math.floor(jmlBruto)
    jmlPph = Math.floor(jmlPph)
    jmlNetto = Math.floor(jmlNetto)

    this.props.change('bagB.jmlBruto', jmlBruto)
    this.props.change('bagB.jmlPph', jmlPph);
    this.props.change('bagB.jmlNetto', jmlNetto);
    this.props.change('bagB.terbilang', terbilangService(parseInt(jmlPph)));

  }

  // async calculate(e,v,pv,f, parent) {
  //   var form = this.props.formData;
  //   var d    = form.bagB[parent];

  //   var bruto = parseFloat(d.bruto);
  //   var tarif = parseFloat(d.tarif);

  //   if(f.search('bruto') >= 0) {
  //     bruto = parseFloat(v)
  //   } else if(f.search('tarif') >= 0) {
  //     tarif = parseFloat(v)
  //   }

  //   if(!bruto) bruto = 0;
  //   if(!tarif) tarif = 0;

  //   if(form.bagA.npwp === '000000000000000' || form.bagA.npwp == '' || !form.bagA.npwp) {
  //     await this.props.change(`bagB.${parent}.pph`, bruto*(tarif*2)/100);
  //   } else {
  //     await this.props.change(`bagB.${parent}.pph`, bruto*tarif/100);
  //   }

  //   this.calculateTotal()
  // }

  // async calculateSpecial(e,v,pv,f, parent) {
  //   var form = this.props.formData;
  //   var d    = form.bagB[parent];

  //   var bruto = parseFloat(d.bruto);
  //   var tarif = parseFloat(d.tarif);
  //   var netto = parseFloat(d.netto);

  //   if(f.search('bruto') >= 0) {
  //     bruto = parseFloat(v)
  //   } else if(f.search('tarif') >= 0) {
  //     tarif = parseFloat(v)
  //   }else if(f.search('netto') >= 0) {
  //     netto = parseFloat(v)
  //   }

  //   if(!bruto) bruto = 0;
  //   if(!tarif) tarif = 0;
  //   if(!netto) netto = 0;

  //   await this.props.change(`bagB.${parent}.pph`, bruto*(netto/100)*(tarif/100));
  //   this.calculateTotal()
  // }

  // async calculateTotal() {
  //   var form = this.props.formData;
  //   var d    = form.bagB;

  //   var jmlPph   = 0;
  //   var jmlBruto = 0;
  //   var jmlNetto = 0;
  //   Object.keys(d).forEach((k, i) => {
  //     if(!(k == 'jmlBruto' || k == 'jmlPph' || k == 'terbilang' || k == 'jmlNetto' || k == 'lastModifiedBy' || k == 'lastModifiedDate' || k == 'id' || k == 'createdDate' || k == 'createdBy')) {
  //       var x = d[k];

  //       if(typeof x == 'object') {
  //         jmlPph   = jmlPph+ parseFloat(x.pph);
  //         jmlBruto = jmlBruto+ parseFloat(x.bruto)
  //         jmlNetto = jmlNetto+ (parseFloat(x.netto)*parseFloat(x.bruto)/100)
  //       }


  //     }
  //   })

  //   this.props.change('bagB.jmlBruto', jmlBruto)
  //   this.props.change('bagB.jmlPph', jmlPph);
  //   this.props.change('bagB.jmlNetto', jmlNetto);
  //   this.props.change('bagB.terbilang', terbilangService(parseInt(jmlPph)));

  // }

  formView() {
    var formData                                                     = {bagB: {}};
    if  (this.props.formData && this.props.formData.values) formData = this.props.formData.values
    return (
      <div>
        <LawanTransaksiDialog
          spt      = {this.props.spt.data}
          onSelect = {(d)=> {
            var formData = {...this.props.formData};

            // EPPT CORE
            formData.bagA = {
              "npwp"  : d.npwp,
              "nik"   : d.nik,
              "nama"  : d.name,
              "alamat": d.address,
              "email" : d.email
            };
            // EPPT CORE

            this.setState({npwpNotValid: false})

            this.props.initialize(formData);

          }}
          service = {MasterLawanTransaksi23Service}
          visible = {this.state.lkDialog}
          onHide  = {()=> this.setState({lkDialog: false})}
        />
        <Card>
          <div className="md-grid">
            <Field
              label     = 'No.Bukti Potong'
              name      = 'no'
              className = "md-cell md-cell--6"
              component = {Textfield}
              validate  = {validation.required}
            />
            <Field
              label        = 'Tanggal Bukti Potong'
              name         = 'bagC.tanggal'
              id           = "BP21FForm-noBp"
              className    = "md-cell md-cell--6"
              component    = {Datepicker}
              validate     = {validation.required}
              // onDateChange = {async (d, e) => this.changeTarif(d)}
            />

            <Field
              label     = 'Referensi'
              name      = 'referensi'
              id        = "BP21FForm-referensi"
              className = "md-cell md-cell--12"
              component = {Textfield}
            />
          </div>
        </Card>

        <br/>

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='A. IDENTITAS PENERIMA PENGHASILAN YANG DIPOTONG ' />
          <Divider/>
          <div className="md-grid">
            <div className='md-cell md-cell--6'>
            <div className='mpk-layout'>
              <Field
                label      = 'NPWP'
                className  = 'flex'
                name       = 'bagA.npwp'
                id         = "BP21FForm-npwp"
                component  = {TextfieldMask}
                maskFormat = "##.###.###.#-###-###"
                mask       = "_"
                length     = {15}
                onChange   = {this.npwpValidation.bind(this)}
                // validate   = {validation.required}
              />
              <Button icon primary swapTheming style={{marginTop: 10}} onClick={()=> this.setState({lkDialog: true})}>search</Button>
              </div>
              <Field
                label = 'Nama'
                name  = 'bagA.nama'

                // disabled  = {this.state.npwpNotValid}
                component = {Textfield}
                // validate  = {validation.required}
              />
              {/*<Field
                label = 'NIK'
                name  = 'bagA.nik'

                disabled  = {this.state.npwpNotValid}
                component = {Textfield}
              />*/}
              {/* <Field
                label     = 'Telepon'
                name      = 'name'
                id        = "BP21FForm-name"
                component = {Textfield}
              />*/}

            </div>
            <div className='md-cell md-cell--6'>
              {/* <Field
                label     = 'Kode Poss'
                name      = 'npwp'
                id        = "BP21FForm-codePoss"
                component = {Textfield}
              /> */}
              <Field
                label = 'Alamat'
                name  = 'bagA.alamat'

                //disabled  = {this.state.npwpNotValid}
                component = {Textfield}
                // validate  = {validation.required}
              />
              <Field
                label = 'Email'
                name  = 'bagA.email'

                // disabled  = {this.state.npwpNotValid}
                component = {Textfield}
              />
            </div>
          </div>
        </Card>

        <br/>

        {this.state.showCalculateProgress && <LinearProgress id='lin_pro' style={{margin: 0}} />}
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='B. PPH PASAL 23 YANG DIPOTONG' />
          <Divider/>

          <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
            <TableHeader>
              <TableRow>
                <TableColumn>No.</TableColumn>
                <TableColumn>Jenis Penghasilan</TableColumn>
                <TableColumn className='md-text-right'>Bruto(Rp)</TableColumn>
                <TableColumn className='md-text-right'>Perkiraan <br/> Penghasilan Neto (%)</TableColumn>
                <TableColumn className='md-text-right'>Tarif(%)</TableColumn>
                <TableColumn className='md-text-right'>PPH Di Potong(Rp)</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableColumn>
                  1.
                </TableColumn>
                <TableColumn>Dividen</TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB.dividen.bruto'
                    component = {TextfieldMask}
                    money     = {','}
                    className = 'md-text-right'
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'dividen')}
                  />
                </TableColumn>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name      = 'bagB.dividen.tarif'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'dividen')}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB.dividen.pph'
                    component = {TextfieldMask}
                    money     = {','}className = 'md-text-right'
                    disabled
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  2.
                </TableColumn>
                <TableColumn>Bunga</TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB.bunga.bruto'
                    component = {TextfieldMask}
                    money     = {','}
                    className = 'md-text-right'
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'bunga')}
                  />
                </TableColumn>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name      = 'bagB.bunga.tarif'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'bunga')}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB.bunga.pph'
                    component = {TextfieldMask}
                    money     = {','}
                    className = 'md-text-right'
                    disabled
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  3.
                </TableColumn>
                <TableColumn>Royalti</TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB.royalti.bruto'
                    component = {TextfieldMask}
                    money     = {','}
                    className = 'md-text-right'
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'royalti')}
                  />
                </TableColumn>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name      = 'bagB.royalti.tarif'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'royalti')}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB.royalti.pph'
                    component = {TextfieldMask}
                    money     = {','}
                    className = 'md-text-right'
                    disabled
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  4.
                </TableColumn>
                <TableColumn>Sewa dan Penghasilan Lain sehubungan dengan penggunaan harta selain penghasilan atas pengalihan tanah dan atau bangunan</TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB.sewaDanPenghasilan.bruto'
                    component = {TextfieldMask}
                    money     = {','}
                    className = 'md-text-right'
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'sewaDanPenghasilan')}
                  />
                </TableColumn>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name      = 'bagB.sewaDanPenghasilan.tarif'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'sewaDanPenghasilan')}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB.sewaDanPenghasilan.pph'
                    component = {TextfieldMask}
                    money     = {','}
                    className = 'md-text-right'
                    disabled
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  5.
                </TableColumn>
                <TableColumn>Imbalan sehubungan dengan Jasa,Pekerjaan, dan Kegiatan</TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB.imbalan.bruto'
                    component = {TextfieldMask}
                    money     = {','}
                    className = 'md-text-right'
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'imbalan')}
                  />
                </TableColumn>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name      = 'bagB.imbalan.tarif'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'imbalan')}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB.imbalan.pph'
                    component = {TextfieldMask}
                    money     = {','}
                    className = 'md-text-right'
                    disabled
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  6.
                </TableColumn>
                <TableColumn>Hadiah dan Penghargaan</TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB.hadiahDanPenghargaan.bruto'
                    component = {TextfieldMask}
                    money     = {','}
                    className = 'md-text-right'
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'hadiahDanPenghargaan')}
                  />
                </TableColumn>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name      = 'bagB.hadiahDanPenghargaan.tarif'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'hadiahDanPenghargaan')}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB.hadiahDanPenghargaan.pph'
                    component = {TextfieldMask}
                    money     = {','}
                    className = 'md-text-right'
                    disabled
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  7.
                </TableColumn>
                <TableColumn>Pensiun dan Pembayaran Berkala</TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB.pensiun.bruto'
                    component = {TextfieldMask}
                    money     = {','}
                    className = 'md-text-right'
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'pensiun')}
                  />
                </TableColumn>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name      = 'bagB.pensiun.tarif'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'pensiun')}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB.pensiun.pph'
                    component = {TextfieldMask}
                    money     = {','}
                    className = 'md-text-right'
                    disabled
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  8.
                </TableColumn>
                <TableColumn>Premi swap dan transaksi lindung nilai</TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB.premiSwap.bruto'
                    component = {TextfieldMask}
                    money     = {','}
                    className = 'md-text-right'
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'premiSwap')}
                  />
                </TableColumn>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name      = 'bagB.premiSwap.tarif'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'premiSwap')}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB.premiSwap.pph'
                    component = {TextfieldMask}
                    money     = {','}
                    className = 'md-text-right'
                    disabled
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  9.
                </TableColumn>
                <TableColumn>Keuntungan karena pembebasan utang</TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB.keuntunganUtang.bruto'
                    component = {TextfieldMask}
                    money     = {','}
                    className = 'md-text-right'
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'keuntunganUtang')}
                  />
                </TableColumn>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name      = 'bagB.keuntunganUtang.tarif'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'keuntunganUtang')}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB.keuntunganUtang.pph'
                    component = {TextfieldMask}
                    money     = {','}
                    className = 'md-text-right'
                    disabled
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  10.
                </TableColumn>
                <TableColumn>Penjualan harta di Indonesia</TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB.penjualanHarta.bruto'
                    component = {TextfieldMask}
                    money     = {','}
                    className = 'md-text-right'
                    onChange  = {(e, v, vp, f) => this.calculateSpecial(e, v, vp, f, 'penjualanHarta')}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB.penjualanHarta.netto'
                    component = {TextfieldMask}
                    money     = {','}
                    className = 'md-text-right'
                    onChange  = {(e, v, vp, f) => this.calculateSpecial(e, v, vp, f, 'penjualanHarta')}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name      = 'bagB.penjualanHarta.tarif'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    onChange  = {(e, v, vp, f) => this.calculateSpecial(e, v, vp, f, 'penjualanHarta')}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB.penjualanHarta.pph'
                    component = {TextfieldMask}
                    money     = {','}
                    className = 'md-text-right'
                    disabled
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  11.
                </TableColumn>
                <TableColumn>Premi asuransi/reasuransi</TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB.premiAsuransi.bruto'
                    component = {TextfieldMask}
                    money     = {','}
                    className = 'md-text-right'
                    onChange  = {(e, v, vp, f) => this.calculateSpecial(e, v, vp, f, 'premiAsuransi')}
                  />
                </TableColumn>

                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB.premiAsuransi.netto'
                    component = {TextfieldMask}
                    money     = {','}
                    className = 'md-text-right'
                    onChange  = {(e, v, vp, f) => this.calculateSpecial(e, v, vp, f, 'premiAsuransi')}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name      = 'bagB.premiAsuransi.tarif'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    onChange  = {(e, v, vp, f) => this.calculateSpecial(e, v, vp, f, 'premiAsuransi')}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB.premiAsuransi.pph'
                    component = {TextfieldMask}
                    money     = {','}
                    className = 'md-text-right'
                    disabled
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  12.
                </TableColumn>
                <TableColumn>Penghasilan dari penjualan atau pengalihan saham</TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB.penghasilanSaham.bruto'
                    component = {TextfieldMask}
                    money     = {','}
                    className = 'md-text-right'
                    onChange  = {(e, v, vp, f) => this.calculateSpecial(e, v, vp, f, 'penghasilanSaham')}
                  />
                </TableColumn>

                <TableColumn>
                <Field
                    // label='Bruto'
                    name      = 'bagB.penghasilanSaham.netto'
                    component = {TextfieldMask}
                    money     = {','}
                    className = 'md-text-right'
                    onChange  = {(e, v, vp, f) => this.calculateSpecial(e, v, vp, f, 'penghasilanSaham')}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name      = 'bagB.penghasilanSaham.tarif'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    onChange  = {(e, v, vp, f) => this.calculateSpecial(e, v, vp, f, 'penghasilanSaham')}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB.penghasilanSaham.pph'
                    component = {TextfieldMask}
                    money     = {','}
                    className = 'md-text-right'
                    disabled
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  13.
                </TableColumn>
                <TableColumn>Penghasilan kena pajak BUT sesudah dikurangi pajak</TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB.penghasilanKenaPajakBut.bruto'
                    component = {TextfieldMask}
                    money     = {','}
                    className = 'md-text-right'
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'penghasilanKenaPajakBut')}
                  />
                </TableColumn>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name      = 'bagB.penghasilanKenaPajakBut.tarif'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'penghasilanKenaPajakBut')}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB.penghasilanKenaPajakBut.pph'
                    component = {TextfieldMask}
                    money     = {','}
                    className = 'md-text-right'
                    disabled
                  />
                </TableColumn>
              </TableRow>

              <TableRow style={{background: '#ddd'}}>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  Jumlah
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB.jmlBruto'
                    component = {TextfieldMask}
                    disabled
                    money     = {','}
                    className = 'md-text-right'
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB.jmlNetto'
                    component = {TextfieldMask}
                    disabled
                    money     = {','}
                    className = 'md-text-right'
                  />
                </TableColumn>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB.jmlPph'
                    component = {TextfieldMask}
                    disabled
                    money     = {','}
                    className = 'md-text-right'
                  />
                </TableColumn>
              </TableRow>

              <TableRow style={{background: '#ddd'}}>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  Terbilang
                </TableColumn>
                <TableColumn colSpan={4}>
                  <Field
                    // label='Bruto'
                    name      = 'bagB.terbilang'
                    component = {Textfield}
                    disabled
                    money = {','}
                  />
                </TableColumn>
              </TableRow>
            </TableBody>

          </DataTable>
        </Card>

        <br />

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='C. IDENTITAS PEMOTONG ' />
          <Divider/>

          <div className="md-grid">
          <Field
            label     = 'NPWP'
            name      = 'bagC.npwp'
            className = "md-cell md-cell--6"
            // disabled
            component  = {TextfieldMask}
            maskFormat = "##.###.###.#-###-###"
            mask       = "_"
            validate   = {validation.required}
          />
          <Field
            label     = 'Nama'
            name      = 'bagC.nama'
            className = "md-cell md-cell--6"
            // disabled
            component = {Textfield}
            validate  = {validation.required}
          />

          </div>
        </Card>

      </div>
    )
  }

}
